import React, { Component, Fragment, useRef } from 'react';
import { Container, Card, Table, Row, Col, Spinner, Image, Badge, Button, Form, Offcanvas, Accordion, Modal, ButtonGroup } from 'react-bootstrap';
import { t } from '../../../helpers/translation_helper';
import NavBar from '../../_partials/NavBar/_NavBar';
import Header from '../../_partials/Header/_Header';
import { connect } from 'react-redux';
import axios from 'axios';
import { toast } from 'react-toastify';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight, faCalendarAlt, faCheck, faClock, faEdit, faExternalLink, faFileAlt, faFileExcel, faGear, faLock, faLockOpen, faPaperclip, faSave, faSearch, faTimes, faTrashCan, } from '@fortawesome/free-solid-svg-icons';
import { Scrollbar } from 'react-scrollbars-custom';
import imgDescription from '../../../assets/images/description.png';
import { brand_colors } from '../../../helpers/brand_colors_helper';
import SweetAlert from 'react-bootstrap-sweetalert';
import Select from 'react-select';
import { handleFormErrors } from '../../../helpers/form_helpers';
import { GET, POST } from '../../../api';
import { Bar, Pie } from 'react-chartjs-2';
import { changeColorOpacity, DDL_ExamstypegrpTypes } from '../../../helpers/general_helpers';
import { useDownloadExcel } from 'react-export-table-to-excel';
var Chance = require('chance');
var chance = new Chance();

// API URL
const apiURL = process.env.REACT_APP_API_URL;

// Portal URL
const portalURL = process.env.REACT_APP_PORTAL_URL;

class SubjectRubricInput extends Component {
    constructor(props) {
        super(props);
        this.state = {
            centerlicData: { data: [], selected: null },
            termsData: { data: [], selected: null },
            reRenderTime: moment(),
            reportData: {
                processing: false,
                data: {},
                finished: false
            },
            examsgrade: [],
            getAbsencesData: [],
            isSaving: false,
            student_name_column: {
                column: 'child_name',
                changeColumn: (column) => this.setState({ student_name_column: { ...this.state.student_name_column, column } }, () => {
                    this.fetchReport()
                })
            },
        }
    }

    componentDidMount() {
        this.getCenterlic()
        this.getAbsences()
    }

    getCenterlic = () => {
        try {
            if (this.getCenterlicReq) {
                this.getCenterlicReq.abort();
            }
            this.getCenterlicReq = new AbortController();
            GET('report-exams/get-centerlic', {
                signal: this.getCenterlicReq.signal,
            }).then((res) => {

                if (res) {
                    let selected = res.data.find((v) => v.reg_status == 1)
                    this.setState({ centerlicData: { ...this.state.centerlicData, data: res.data, selected: { value: selected.centerlic_id, label: selected.reg_year, reg_status: selected.reg_status } } }, () => this.getTerms())
                }
            })
        } catch (err) {
            console.log('err', err)
            toast.error(t('Something went wrong while deleting!'));

        }
    }
    getTerms = () => {
        try {
            if (this.getTermsReq) {
                this.getTermsReq.abort();
            }
            this.getTermsReq = new AbortController();
            GET('report-exams/get-terms', {
                signal: this.getTermsReq.signal,
            }).then((res) => {

                if (res) {
                    let selected = []
                    res.data.map((v, i) => {
                        if (v.centerlic_id == this.state.centerlicData.selected.value && v.term_active == 1) {
                            selected = { value: v.term_id, label: v.term_name, term_active: v.term_active }
                        }
                    })
                    this.setState({ termsData: { ...this.state.termsData, data: res.data, selected: selected } })
                }
            })
        } catch (err) {
            console.log('err', err)
            toast.error(t('Something went wrong while deleting!'));

        }
    }

    fetchReport = () => {
        let reportData = this.state.reportData
        let centerlicData = this.state.centerlicData
        let selectedSubject = this.props.selectedSubject
        let termsData = this.state.termsData
        if (!this.props.selectedClass) {
            toast.error(t('No Class Selected'));
            return
        }
        if (!selectedSubject) {
            toast.error(t('No Subject Selected'));
            return
        }
        if (!centerlicData.selected) {
            toast.error(t('Please select a Year'));
            return
        }
        if (!termsData.selected) {
            toast.error(t('Please select a Term'));
            return
        }

        this.setState({ reportData: { ...reportData, processing: true, data: {} }, examsgrade: [] })

        try {
            if (this.fetchReportReq) {
                this.fetchReportReq.abort();
            }
            this.fetchReportReq = new AbortController();
            GET('academic-classmain-subject/get-exams-rubric', {
                params: {
                    class_id: this.props.selectedClass.class.class_id,
                    agegroup_id: this.props.selectedClass.class.agegroup_id,
                    acs_id: selectedSubject.acs_id,
                    centerlic_id: centerlicData.selected.value,
                    term_id: termsData.selected.value,
                    student_name_column: this.state.student_name_column.column
                },
                signal: this.fetchReportReq.signal,
            }).then((res) => {
                if (res) {
                    this.setState({ reportData: { ...reportData, processing: false, data: res.data, finished: true }, reRenderTime: moment() })
                } else {
                    this.setState({ reportData: { ...reportData, processing: false, data: {} } })
                }
            })
        } catch (err) {
            console.log('err', err)
            toast.error(t('Something went wrong while deleting!'));

            this.setState({ reportData: { ...reportData, processing: false, data: {} } })
        }
    }

    setExamGrade = (data) => {
        console.log('data', data);
        let examsgrade = JSON.parse(JSON.stringify(this.state.examsgrade))
        let findExamGrade = examsgrade.findIndex((v, i) => v.child_id == data.child_id && v.exams_id == data.exams_id)
        if (findExamGrade !== -1) {
            examsgrade[findExamGrade] = data
        } else {
            examsgrade.push(data)
        }
        this.setState({ examsgrade: examsgrade })
    }

    setMultiExamGrade = (data) => {
        let examsgrade = JSON.parse(JSON.stringify(this.state.examsgrade))
        data.map((vv, ii) => {
            let findExamGrade = examsgrade.findIndex((v, i) => v.child_id == vv.child_id && v.exams_id == vv.exams_id)
            if (findExamGrade !== -1) {
                let absence_name = null
                if (vv.absence_id && parseInt(vv.absence_id) != 0) {
                    absence_name = this.state.getAbsencesData.find((absence) => absence.absence_id == vv.absence_id)['absence_name']
                }
                examsgrade[findExamGrade] = { ...vv, absence_name: absence_name }
            } else {
                let absence_name = null
                if (vv.absence_id && parseInt(vv.absence_id) != 0) {
                    absence_name = this.state.getAbsencesData.find((absence) => absence.absence_id == vv.absence_id)['absence_name']
                }
                examsgrade.push({ ...vv, absence_name: absence_name })
            }
        })
        this.setState({ examsgrade: examsgrade })
    }

    getAbsences = async () => {
        try {
            const res = await GET('children/get-absences');
            this.setState({
                getAbsencesData: res.data.map((v, i) => { return { ...v, label: v.absence_name, value: v.absence_id } })
            });
        } catch (err) {
            console.log('err', err)
        }
    }

    updateRubricExamGrades = () => {
        this.setState({ isSaving: true })
        try {
            if (this.state.examsgrade.length <= 0) {
                toast.error(t('Nothing to update!'));
                this.setState({ isSaving: false })
                return
            }

            let ifAbsentMIssing = false
            this.state.examsgrade.map((v, i) => {
                if (v.absent && parseInt(v.absence_id) == 0) {
                    ifAbsentMIssing = true
                }
            })
            if (ifAbsentMIssing) {
                toast.error(t('Please pick Absence Type for all absents!'));
                this.setState({ isSaving: false })
                return
            }

            if (this.updateRubricExamGradesReq) {
                this.updateRubricExamGradesReq.abort();
            }
            this.updateRubricExamGradesReq = new AbortController();

            let fData = new FormData()
            fData.append('examsgrade', JSON.stringify(this.state.examsgrade))

            POST('exams/update-rubric-exam-grades', fData, {
                signal: this.updateRubricExamGradesReq.signal,
            }).then((res) => {
                if (res) {
                    let resData = res.data
                    toast[resData.messageType](t(resData.message));
                    if (resData.type) {
                        this.fetchReport()
                    }
                } else {
                    toast.error(t('Something went wrong!'));
                }
                this.setState({ isSaving: false })
            })
        } catch (err) {
            console.log('err', err)
            toast.error(t('Something went wrong!'));
            this.setState({ isSaving: false })
        }
    }

    toggleExamLock = (exams_id, exams_grade_lock) => {
        if (![1, 12, 19, 15, 22].includes(parseInt(this.props.authData.loginData.role_id))) {
            toast.warning(t('You are not allowed for this action!'));
            return
        }
        let examsgrades = []
        this.state.reportData.data.registrations.map((v, i) => {
            this.state.reportData.data.exams.map((vvvv, iiii) => {
                if (vvvv.exams_id == exams_id) {

                    let exams_child_ids = JSON.parse(vvvv.exams_child_ids)
                    let findTaggedChild = exams_child_ids.find((exams_child_id) => exams_child_id == v.child_id)

                    let examsgrade = false
                    // FIND GRADE OF CHILD
                    let examsgrade_old = vvvv.examsgrade.find((vvvvv) => vvvvv.child_id == v.child_id && vvvvv.exams_id == vvvv.exams_id)
                    // FIND GRADE OF CHILD IF EDITED
                    let examsgrade_edited = this.state.examsgrade.find((vvvvv) => vvvvv.child_id == v.child_id && vvvvv.exams_id == vvvv.exams_id)

                    if (findTaggedChild) {
                        if (examsgrade_edited) {
                            examsgrade = { ...examsgrade_edited, examsgrade_lock: exams_grade_lock }
                        } else if (examsgrade_old) {
                            examsgrade = { ...examsgrade_old, examsgrade_lock: exams_grade_lock }
                        } else {
                            examsgrade = {
                                examsgrade_id: 0,
                                exams_id: parseInt(vvvv.exams_id),
                                center_id: parseInt(vvvv.center_id),
                                child_id: parseInt(v.child_id),
                                class_id: parseInt(vvvv.class_id),
                                examsgrade_totalgrade: parseFloat(vvvv.exams_grade),
                                examsgrade_grade: 0,
                                absence_id: 0,
                                examsgrade_comment: '',
                                examsgrade_posted: 0,
                                examsgrade_hidden_std: 0,
                                examsgrade_lock: exams_grade_lock
                            }
                        }
                    }
                    if (examsgrade) {
                        examsgrades.push(examsgrade)
                    }
                }
            })
        })

        if (examsgrades.length > 0) {
            this.toggleLockExamsGrade(examsgrades, this.setMultiExamGrade)
        } else {
            toast.warning(t('There are no Grades to Lock!'));
            return
        }
    }

    toggleLockExamsGrade = (examsgrades, updateAction) => {
        if (![1, 12, 19, 15, 22].includes(parseInt(this.props.authData.loginData.role_id))) {
            toast.warning(t('You are not allowed for this action!'));
            return
        }

        this.setState({ isSaving: true })
        try {
            if (this.toggleLockExamsGradeReq) {
                this.toggleLockExamsGradeReq.abort();
            }
            this.toggleLockExamsGradeReq = new AbortController();

            let fData = new FormData()
            fData.append('examsgrades', JSON.stringify(examsgrades))

            POST('exams/toggle-lock-exams-grade', fData, {
                signal: this.toggleLockExamsGradeReq.signal,
            }).then((res) => {
                if (res) {
                    let resData = res.data
                    console.log('resData', resData);
                    toast[resData.messageType](t(resData.messageTitle + " " + resData.message));
                    if (resData.type) {
                        updateAction(resData.data)
                    }
                } else {
                    toast.error(t('Something went wrong!'));
                }
                this.setState({ isSaving: false })
            })
        } catch (err) {
            console.log('err', err)
            toast.error(t('Something went wrong!'));
            this.setState({ isSaving: false })
        }
    }
    render() {
        const { reportData, reRenderTime, centerlicData, termsData, student_name_column } = this.state
        let filteredTerms = []
        if (centerlicData.selected) {
            filteredTerms = termsData.data.filter((v, i) => v.centerlic_id == centerlicData.selected.value)
        }
        return (
            <Container fluid>
                <div id='iedu-layout'>
                    <NavBar />
                    <div id='page-content'>
                        <Header lite={true} heading={t('Input Rubric')} backBtn={true} showClassSelector={true} classSelectorFunc={[this.fetchReport]} showSubjectSelector={true} subjectSelectorFunc={[this.fetchReport]} />
                        <div className='grey-section'>
                            <Row className='justify-content-between'>
                                <Col>
                                    <Row>
                                        <Col>
                                            <Select
                                                placeholder={t('Select Year')}
                                                options={centerlicData.data.map((v, i) => { return { label: v.reg_year, value: v.centerlic_id, reg_status: v.reg_status } })}
                                                onChange={(event) => this.setState({ centerlicData: { ...centerlicData, selected: event }, termsData: { ...termsData, selected: null } })
                                                }
                                                value={centerlicData.selected}
                                                menuPortalTarget={document.body}
                                                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                                formatOptionLabel={option => (
                                                    <div className='d-flex align-items-center'>
                                                        <span style={{ fontSize: 16 }} className='me-1'>{option.label}</span>
                                                        {option.reg_status == 1 && <Badge pill bg="success">{t('Active')}</Badge>}
                                                    </div>
                                                )}
                                            />
                                        </Col>
                                        <Col md="7">
                                            <Select
                                                isClearable
                                                placeholder={t('Select Term')}
                                                options={filteredTerms.map((v, i) => { return { label: v.term_name, value: v.term_id, term_active: v.term_active } })}
                                                onChange={(event) => this.setState({ termsData: { ...termsData, selected: event } })
                                                }
                                                value={termsData.selected}
                                                menuPortalTarget={document.body}
                                                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                                formatOptionLabel={option => (
                                                    <div className='d-flex align-items-center'>
                                                        <span style={{ fontSize: 16 }} className='me-1'>{option.label}</span>
                                                        {option.term_active == 1 && <Badge pill bg="success">{t('Active')}</Badge>}
                                                    </div>
                                                )}
                                            />
                                        </Col>
                                        <Col md="auto">
                                            <div className='d-flex flex-column align-items-center'>
                                                <div className='d-flex flex-row'>
                                                    <div style={{ fontWeight: 'bold', color: (student_name_column.column == 'child_name') ? brand_colors[this.props.defaultTheme.theme_id].color4 : brand_colors[this.props.defaultTheme.theme_id].color9 }}>EN</div>
                                                    <div>/</div>
                                                    <div style={{ fontWeight: 'bold', color: (student_name_column.column != 'child_name') ? brand_colors[this.props.defaultTheme.theme_id].color4 : brand_colors[this.props.defaultTheme.theme_id].color9 }}>AR</div>
                                                </div>
                                                <Form.Check
                                                    onChange={() => student_name_column.changeColumn((student_name_column.column == 'child_name') ? 'arabic_name' : 'child_name')}
                                                    checked={student_name_column.column != 'child_name'}
                                                    type="switch"
                                                    style={{ color: brand_colors[this.props.defaultTheme.theme_id].color8 }}
                                                />
                                                <div style={{ color: brand_colors[this.props.defaultTheme.theme_id].color9, opacity: 0.8 }}>{t('Name')}</div>
                                            </div>
                                        </Col>
                                        <Col md="auto">
                                            <Button variant={'success'} onClick={() => this.fetchReport()}>
                                                {reportData.processing
                                                    ? <Spinner as='span' animation='grow' size='sm' role='status' aria-hidden='true' />
                                                    : <FontAwesomeIcon icon={faSearch} style={{ fontSize: 16, color: brand_colors[this.props.defaultTheme.theme_id].color8, }} />
                                                }
                                            </Button>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col md="auto">

                                </Col>
                            </Row>
                        </div>
                        <div className='grey-section'>
                            {(!reportData.processing && reportData.finished) &&
                                <RenderTable reportData={reportData} defaultTheme={this.props.defaultTheme} setExamGrade={this.setExamGrade} setMultiExamGrade={this.setMultiExamGrade} examsgrade={this.state.examsgrade} getAbsencesData={this.state.getAbsencesData} updateRubricExamGrades={this.updateRubricExamGrades} toggleLockExamsGrade={this.toggleLockExamsGrade} toggleExamLock={this.toggleExamLock} student_name_column={this.state.student_name_column} />
                            }
                        </div>
                    </div>
                </div>

            </Container>
        );
    }
}

const mapStateToProps = (state) => ({
    authData: state.auth.authData,
    defaultLanguage: state.language.defaultLanguage,
    selectedClass: state.selectedClass.data,
    selectedSubject: state.selectedClass.subject,
    children: state.children.list,
    defaultTheme: state.theme.defaultTheme,
});

export default connect(mapStateToProps, null)(SubjectRubricInput);

const RenderTable = (props) => {
    console.log('RenderTable', props);
    const reportData = props.reportData
    const defaultTheme = props.defaultTheme
    const student_name_column = props.student_name_column
    const tableRef = useRef(null);
    return (
        <Card>
            <Card.Header>
                <Row>
                    <Col md={12} className='text-right'>
                        <ButtonGroup size='sm' className='me-3 float-left'>
                            {/* <Button><FontAwesomeIcon icon={faFilePdf} /> {t('PDF')}</Button> */}
                            {/* <Button onClick={() => onDownload()}>
                                <FontAwesomeIcon icon={faFileExcel} /> {t('Excel')}
                            </Button> */}
                            {/* <Button><FontAwesomeIcon icon={faPrint} /> {t('Print')}</Button> */}

                            <Button variant={'warning'} onClick={() => props.updateRubricExamGrades()}>
                                <FontAwesomeIcon icon={faSave} /> {t('Update')}
                            </Button>
                        </ButtonGroup>
                    </Col>
                </Row>
            </Card.Header>
            <Card.Body >
                <div className='table-responsive' style={{ height: 500 }}>
                    <Table size='sm' striped bordered ref={tableRef} >
                        <thead className='sticky-thead'>
                            {/* EXAMS */}
                            <tr style={{ backgroundColor: brand_colors[defaultTheme.theme_id].color8 }}>
                                <th className='sticky-column' style={{ backgroundColor: brand_colors[defaultTheme.theme_id].color8 }}>{t('Students')}</th>
                                {reportData.data.exams.map((vvv, iii) => {
                                    return <th key={iii} style={{}}>
                                        <div className='d-flex flex-row justify-content-between'>
                                            <div className='d-flex flex-column align-items-start'>
                                                <div style={{ fontWeight: '600', textAlign: 'center' }}>{vvv.exams_name}</div>

                                                <div className='d-flex flex-row justify-content-around align-items-center' style={{ fontSize: 12 }}>
                                                    <div style={{ marginInline: 5 }}>{vvv.examstypegrp_type}</div>
                                                    {['Exam', 'Homework'].includes(vvv.examstypegrp_type) &&
                                                        <Badge bg={vvv.is_exampaper == 0 ? 'success' : 'info'} >{vvv.is_exampaper == 0 ? t('Online') : t('Recitation')}</Badge>
                                                    }
                                                </div>

                                                <div className='d-flex flex-row justify-content-around' style={{ fontSize: 14, textWrap: 'nowrap' }}>
                                                    <div>{moment(vvv.exams_date).format('DD-MM-YYYY')}</div>
                                                    <div className='mx-1'>({vvv.exams_grade})</div>
                                                </div>
                                            </div>
                                            <div className='d-flex flex-column align-items-end justify-content-between'>
                                                <FontAwesomeIcon icon={faLock} color={brand_colors[defaultTheme.theme_id].color11} className='cursor-pointer' title={t('Lock All')} onClick={() => props.toggleExamLock(vvv.exams_id, 1)} />
                                                <FontAwesomeIcon icon={faLockOpen} color={brand_colors[defaultTheme.theme_id].color4} className='cursor-pointer' title={t('Unlock All')} onClick={() => props.toggleExamLock(vvv.exams_id, 0)} />
                                            </div>
                                        </div>
                                    </th>
                                })}
                            </tr>
                        </thead>
                        <tbody>
                            {React.Children.toArray(
                                reportData.data.registrations.map((v, i) => {
                                    return <tr>
                                        <th className='sticky-column' style={{ backgroundColor: brand_colors[defaultTheme.theme_id].color8 }}>
                                            <div style={{ fontWeight: '600' }}>{v[student_name_column.column] || v['child_name']}</div>
                                        </th>
                                        {reportData.data.exams.map((vvvv, iiii) => {

                                            let exams_child_ids = JSON.parse(vvvv.exams_child_ids)
                                            let findTaggedChild = exams_child_ids.find((exams_child_id) => exams_child_id == v.child_id)

                                            let examsgrade = false
                                            // FIND GRADE OF CHILD
                                            let examsgrade_old = vvvv.examsgrade.find((vvvvv) => vvvvv.child_id == v.child_id && vvvvv.exams_id == vvvv.exams_id)
                                            // FIND GRADE OF CHILD IF EDITED
                                            let examsgrade_edited = props.examsgrade.find((vvvvv) => vvvvv.child_id == v.child_id && vvvvv.exams_id == vvvv.exams_id)
                                            if (examsgrade_edited) {
                                                examsgrade = examsgrade_edited
                                            } else if (examsgrade_old) {
                                                examsgrade = examsgrade_old
                                            } else {
                                                examsgrade = {
                                                    examsgrade_id: 0,
                                                    exams_id: parseInt(vvvv.exams_id),
                                                    center_id: parseInt(vvvv.center_id),
                                                    child_id: parseInt(v.child_id),
                                                    class_id: parseInt(vvvv.class_id),
                                                    examsgrade_totalgrade: parseFloat(vvvv.exams_grade),
                                                    examsgrade_grade: 0,
                                                    absence_id: 0,
                                                    examsgrade_comment: '',
                                                    examsgrade_posted: 0,
                                                    examsgrade_hidden_std: 0,
                                                    examsgrade_lock: 0
                                                }
                                            }

                                            let tdData = null

                                            let tdOldData = null
                                            if (!findTaggedChild) {
                                                tdOldData = <span title={t('Not tagged')} className='badge badge-light-warning me-1'>{t('Not tagged')}</span>
                                            } else {
                                                if (!examsgrade_old) {
                                                    tdOldData = <span title={t('Not Graded')} className='badge badge-light-info me-1'>{t('Not Graded')}</span>
                                                }
                                                else if (examsgrade_old && (examsgrade_old?.absence_id != 0 && examsgrade_old?.absence_id != null)) {
                                                    tdOldData = <span title={t('Old Value')} className='badge badge-light-danger me-1'>{(examsgrade_old) ? examsgrade_old.absence_name : t('Absent')}</span>
                                                } else {
                                                    tdOldData = <span title={t('Old Value')} className='badge badge-light-success me-1'>{examsgrade_old?.examsgrade_grade}</span>
                                                }
                                            }
                                            tdData = <Form.Group >
                                                {(examsgrade.absent || (examsgrade?.absence_id != 0 && examsgrade?.absence_id != null))
                                                    ? <Select
                                                        placeholder={t('-- Select --')}
                                                        options={props.getAbsencesData}
                                                        onChange={(obj) => props.setExamGrade({
                                                            ...examsgrade,
                                                            absence_id: obj.absence_id,
                                                            absence_name: obj.absence_name,
                                                        })}
                                                        value={(examsgrade?.absence_id != 0 && examsgrade?.absence_id != null) ? { label: examsgrade.absence_name, value: examsgrade.absence_id, absence_name: examsgrade.absence_name, value: examsgrade.absence_id.absence_id } : ''}
                                                        menuPortalTarget={document.body}
                                                        styles={{ menuPortal: (base) => ({ ...base, zIndex: 99999999999999 }) }}
                                                    />
                                                    : <Form.Control type='text' value={examsgrade?.examsgrade_grade} disabled={(!parseInt(vvvv.is_exampaper))} onChange={(e) => props.setExamGrade({
                                                        ...examsgrade,
                                                        examsgrade_grade: e.target.value,
                                                    })} />}
                                            </Form.Group>

                                            return <td style={{ position: 'relative' }}>
                                                {/* IN CASE OF LOCK */}
                                                {(examsgrade.examsgrade_lock == 1) &&
                                                    <div className='cursor-pointer' style={{ position: 'absolute', width: '100%', height: '100%', zIndex: 1, backgroundColor: changeColorOpacity(brand_colors[defaultTheme.theme_id].color11, 0.2) }} onClick={() => props.toggleLockExamsGrade(
                                                        [{ ...examsgrade, examsgrade_lock: (examsgrade.examsgrade_lock == 1) ? 0 : 1 }], props.setMultiExamGrade
                                                    )}></div>
                                                }
                                                <div style={{ position: 'relative', padding: 5 }}>
                                                    <div className='d-flex flex-row align-items-center justify-content-between'>
                                                        <div className='me-2'>
                                                            <Form.Check
                                                                type="switch"
                                                                label={t('Absent')}
                                                                onChange={() =>
                                                                    props.setExamGrade({
                                                                        ...examsgrade,
                                                                        absent: !(examsgrade.absent || (examsgrade?.absence_id != 0 && examsgrade?.absence_id != null)),
                                                                        absence_id: 0,
                                                                        examsgrade_grade: 0,
                                                                    })}
                                                                checked={examsgrade.absent || (examsgrade?.absence_id != 0 && examsgrade?.absence_id != null)}
                                                            />
                                                        </div>
                                                        <div className='d-flex flex-row align-items-center'>
                                                            {tdOldData}
                                                            <FontAwesomeIcon icon={(examsgrade.examsgrade_lock == 0) ? faLockOpen : faLock} color={(examsgrade.examsgrade_lock == 0) ? brand_colors[defaultTheme.theme_id].color4 : brand_colors[defaultTheme.theme_id].color11} className='cursor-pointer' onClick={() => props.toggleLockExamsGrade(
                                                                [{ ...examsgrade, examsgrade_lock: (examsgrade.examsgrade_lock == 1) ? 0 : 1 }], props.setMultiExamGrade
                                                            )} />
                                                        </div>
                                                    </div>
                                                    {tdData}
                                                </div>
                                            </td>
                                        })}
                                    </tr>
                                })
                            )
                            }
                        </tbody >
                    </Table>
                </div>
            </Card.Body>
        </Card>
    )
}