import React, { Component } from 'react';
import { Container, Card, Row, Col, Button, Spinner, InputGroup, Form, Table, Badge, ButtonGroup, DropdownButton, Dropdown } from 'react-bootstrap';
import { t } from '../../helpers/translation_helper';
import NavBar from '../_partials/NavBar/_NavBar';
import Header from '../_partials/Header/_Header';
import { connect } from 'react-redux';
import { brand_colors } from '../../helpers/brand_colors_helper';
import { faCalendar, faEye, faEyeSlash, faSearch, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DateRangePicker } from 'react-date-range';
import moment from 'moment';
import { GET } from '../../api';
import { numberFormat } from '../../helpers/general_helpers';
import { faCalendarAlt, faFileExcel } from '@fortawesome/free-regular-svg-icons';
import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';
import { renderTableBody, renderTableHead } from '../../helpers/table_helper';
import Pagination from '../_partials/Pagination';
import Select from 'react-select';
import { toast } from 'react-toastify';
let cancelToken;


class StudentDemography extends Component {

  state = {
    fetching: false,
    terms: [],
    term_id: '',
    classes: [],
    class_id: '',
    employeeSalary: '',
    cdt: {
      fetching: false,
      search: '',
      rows: 25,
      offset: 0,
      order: 'children.child_id',
      dir: 'DESC',
      lastPage: 0,
      page: 0,
      totalRecords: 0,
      rowsOptions: [10, 25, 50, 'All'],
      columns: [
        {
          id: 'children.child_id',
          column: 'child_name',
          name: 'Student Name',
          visibility: true,
          sortable: true
        },
      ],
      data: [],
    },

  }

  componentDidMount() {

    this.fetch({
      search: this.state.cdt.search,
      limit: this.state.cdt.rows,
      order: this.state.cdt.order,
      dir: this.state.cdt.dir,
      page: this.state.cdt.page
    });

    // this.getClasses();
    // this.getTerms();
  }

  getClasses = async () => {
    if (this.getClassesReq) {
      this.getClassesReq.abort();
    }
    this.getClassesReq = new AbortController();
    const res = await GET('student-demography/get-classes', {
      signal: this.getClassesReq.signal,
    });

    console.log(res);

    if (res) {
      let resData = res.data.map(item => {
        return {
          ...item,
          value: item.class_id,
          label: item.class_theme,
        }
      });
      this.setState({ classes: resData })
    }
  }

  getTerms = async () => {
    if (this.getTermsReq) {
      this.getTermsReq.abort();
    }
    this.getTermsReq = new AbortController();
    const res = await GET('student-demography/get-terms', {
      signal: this.getTermsReq.signal,
    });

    console.log(res);

    if (res) {
      let resData = res.data.map(item => {
        return {
          ...item,
          value: item.term_id,
          label: item.term_name,
        }
      });
      this.setState({ terms: resData })
    }
  }

  fetch = async (params) => {

    this.setState({
      showDateRange: false,
      cdt: {
        ...this.state.cdt,
        fetching: true,
      }
    });

    if (this.fetchReq) {
      this.fetchReq.abort();
    }
    this.fetchReq = new AbortController();

    try {
      const res = await GET('student-demography/get-children', {
        signal: this.fetchReq.signal,
        params: {
          search: params.search,
          order: params.order,
          dir: params.dir,
          limit: params.limit,
          offset: parseInt(params.page) * parseInt(params.limit),
          term_id: this.state.term_id.value,
          class_id: this.state.class_id.value,
        },
      });


      if (res.status === 200) {

        this.setState({
          cdt: {
            ...this.state.cdt,
            fetching: false,
            search: params.search,
            rows: parseInt(params.limit),
            offset: parseInt(params.page) * parseInt(params.limit),
            order: params.order,
            dir: params.dir,
            page: params.page,
            totalRecords: parseInt(res.data.total_count_filtered),
            lastPage: Math.ceil(parseInt(res.data.total_count_filtered) / parseInt(params.limit)),
            data: res.data.data,
          },
        });
      }

    } catch (err) {
      console.log('err', err)
      this.setState({
        cdt: {
          ...this.state.cdt,
          fetching: false,
        }
      });

    }
  }

  exportExcel = async () => {

    const excelData = this.state.cdt.data.map((item) => {
      const row = {};
      this.state.cdt.columns
        .filter((column) => column.visibility)
        .map((column) => {

          row[column.name] = item[column.column];

        });
      return row;
    });

    const fileName = 'Student Demography ' + moment().format('DD-MM-YYYY');
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const ws = XLSX.utils.json_to_sheet(excelData);

    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });

    FileSaver.saveAs(data, fileName + fileExtension);
  }

  showHideColumn = (column_index) => {
    const columns = this.state.cdt.columns;
    columns[column_index].visibility = !columns[column_index].visibility;
    this.setState({ ctd: { ...this.state.ctd, columns } })
  }

  renderCell = (column_data, column_id, column_index, dataObj) => {
    const { authData } = this.props;
    const { center_id } = authData.centerData;

    let tdData;

    tdData = column_data;

    return <td key={column_index}>{tdData}</td>;
  }

  render() {

    return (
      <Container fluid>
        <div id='iedu-layout'>
          <NavBar />
          <div id='page-content'>
            <Header lite={true} heading={t('Student Demography')} backBtn={true} />
            <div className='grey-section'>
              <Row>
                <Col md={11}>
                  <Row>
                    <Col md={3}>
                      <Select
                        placeholder={t('Registration Year')}
                        options={this.state.terms}
                        onChange={(obj) => {
                          this.setState({ term_id: obj })
                        }}
                        value={this.state.term_id}
                        menuPortalTarget={document.body}
                        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                        formatOptionLabel={option => (
                          <div>
                            <span style={{ fontSize: 14 }}>{option.label}</span>
                          </div>
                        )}
                      />
                    </Col>
                    <Col md={3}>
                      <Select
                        placeholder={t('Select Term')}
                        options={this.state.terms}
                        onChange={(obj) => {
                          this.setState({ term_id: obj })
                        }}
                        value={this.state.term_id}
                        menuPortalTarget={document.body}
                        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                        formatOptionLabel={option => (
                          <div>
                            <span style={{ fontSize: 14 }}>{option.label}</span>
                          </div>
                        )}
                      />
                    </Col>
                    <Col md={3}>
                      <Select
                        placeholder={t('Grade')}
                        options={this.state.terms}
                        onChange={(obj) => {
                          this.setState({ term_id: obj })
                        }}
                        value={this.state.term_id}
                        menuPortalTarget={document.body}
                        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                        formatOptionLabel={option => (
                          <div>
                            <span style={{ fontSize: 14 }}>{option.label}</span>
                          </div>
                        )}
                      />
                    </Col>
                    <Col md={3}>
                      <Select
                        placeholder={t('Select Class')}
                        options={this.state.classes}
                        onChange={(obj) => {
                          this.setState({ class_id: obj })
                        }}
                        value={this.state.class_id}
                        menuPortalTarget={document.body}
                        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                        formatOptionLabel={option => (
                          <div>
                            <span style={{ fontSize: 14 }}>{option.label}</span>
                          </div>
                        )}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col className='d-flex align-items-center'>
                  <Button variant={'success'}
                    onClick={() => this.fetch({
                      search: this.state.cdt.search,
                      limit: this.state.cdt.rows,
                      order: this.state.cdt.order,
                      dir: this.state.cdt.dir,
                      page: this.state.cdt.page
                    })}>
                    {this.state.fetching
                      ? <Spinner as='span' animation='grow' size='sm' role='status' aria-hidden='true' />
                      : <FontAwesomeIcon icon={faSearch} style={{ fontSize: 16, color: brand_colors[this.props.defaultTheme.theme_id].color8, }} />
                    }
                  </Button>
                </Col>
              </Row>
            </div>
            <Card
              className='border-0'
              style={{ borderRadius: '20px' }}>
              <Card.Header style={{
                backgroundColor: '#fff',
                borderTopLeftRadius: '20px',
                borderTopRightRadius: '20px',
              }}>
                <Row>
                  <Col md={6} className='d-flex align-items-center'>
                    <ButtonGroup size='sm' className='me-3 float-left'>
                      {/* <Button><FontAwesomeIcon icon={faFilePdf} /> {t('PDF')}</Button> */}
                      <Button onClick={() => this.exportExcel()}>
                        <FontAwesomeIcon icon={faFileExcel} /> {t('Excel')}
                      </Button>
                      {/* <Button><FontAwesomeIcon icon={faPrint} /> {t('Print')}</Button> */}
                      <DropdownButton
                        autoClose={'outside'}
                        size='sm'
                        as={ButtonGroup}
                        title={t('Column Visibility')}>
                        {this.state.cdt.columns.map((column, column_index) => (
                          <Dropdown.Item key={column_index} className={column.visibility ? 'column-name-item' : 'column-name-item active'} onClick={() => this.showHideColumn(column_index)}>
                            <FontAwesomeIcon icon={column.visibility ? faEye : faEyeSlash} /> {column.name}
                          </Dropdown.Item>
                        ))}
                      </DropdownButton>
                    </ButtonGroup>
                    <div style={{ display: 'inline-block' }}>
                      {t('Show')}
                      <Form.Select
                        value={this.state.cdt.rows}
                        size="sm"
                        onChange={(e) => this.fetch({
                          search: this.state.cdt.search,
                          limit: e.target.value,
                          order: this.state.cdt.order,
                          dir: this.state.cdt.dir,
                          page: this.state.cdt.page
                        })}
                        style={{ width: 70, marginInline: 5, display: 'inline-block' }}>
                        {this.state.cdt.rowsOptions.map((row, row_index) => (<option key={row_index} >{row}</option>))}
                      </Form.Select>
                      {t('Records')}
                    </div>
                  </Col>
                  <Col md={6} className='d-flex justify-content-end'>
                    <div className='cdt-search'>
                      <input type='text'
                        ref={this.searchFieldRef}
                        defaultValue={this.state.cdt.search}
                        onChange={(e) => this.fetch({
                          search: e.target.value,
                          limit: this.state.cdt.rows,
                          order: this.state.cdt.order,
                          dir: this.state.cdt.dir,
                          page: 0
                        })}
                        placeholder={t('Search...')} />
                      <button
                        onClick={() => {
                          this.fetch({
                            search: '',
                            limit: this.state.cdt.rows,
                            order: this.state.cdt.order,
                            dir: this.state.cdt.dir,
                            page: 0
                          });
                          this.searchFieldRef.current.value = '';
                        }}>
                        <FontAwesomeIcon
                          icon={this.state.cdt.search == '' ? faSearch : faXmark}
                          color={brand_colors[this.props.defaultTheme.theme_id].color18}
                        />
                      </button>
                    </div>
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body style={{ paddingTop: 8 }}>
                <Row>
                  {this.state.cdt.fetching &&
                    <Col md={12} className='py-5 d-flex flex-column align-items-center'>
                      <Spinner as='span' animation='grow' size='sm' /> {t('Loading Data')}
                    </Col>
                  }
                  {!this.state.cdt.fetching && this.state.cdt.data.length === 0 &&
                    <Col md={12} className='py-5 d-flex flex-column align-items-center'>
                      {t('No Data Found')}
                    </Col>
                  }
                  {!this.state.cdt.fetching && this.state.cdt.data.length !== 0 &&
                    <Col md={12} className='pt-2'>
                      <div id="datatable-list" className='mb-3' style={{ width: 'initial' }}>
                        <Table responsive>
                          {renderTableHead(this)}
                          {renderTableBody(this)}
                        </Table>
                      </div>
                    </Col>
                  }
                </Row>
                <Pagination
                  pageCount={this.state.cdt.lastPage || 1}
                  forcePage={this.state.cdt.page}
                  callbackParams={{
                    search: this.state.cdt.search,
                    limit: this.state.cdt.rows,
                    order: this.state.cdt.order,
                    dir: this.state.cdt.dir,
                  }}
                  callback={this.fetch}
                />
              </Card.Body>
            </Card>
          </div>
        </div>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  authData: state.auth.authData,
  selectedClass: state.selectedClass.data,
  defaultLanguage: state.language.defaultLanguage,
  defaultTheme: state.theme.defaultTheme,
});

export default connect(mapStateToProps, null)(StudentDemography);
