import { Component } from "react";
import NotFound404 from './err-404.png'
import { connect } from "react-redux";
import { unsetAuthentication } from '../redux/slices/authSlice';
import SweetAlert from "react-bootstrap-sweetalert";
import { t } from "../helpers/translation_helper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faPowerOff } from "@fortawesome/free-solid-svg-icons";
import { brand_colors } from "../helpers/brand_colors_helper";
import { Button } from "react-bootstrap";

class UnAuthorized extends Component {

  constructor(props) {
    super(props);
    this.state = {
      logoutModal: {
        show: false, action: () => {
          this.props.unsetAuthentication()
        }, toggleModal: () => this.setState({ logoutModal: { ...this.state.logoutModal, show: !this.state.logoutModal.show } })
      },
    };
  }



  render() {

    return (
      <div className="d-flex flex-column justify-content-center align-items-center" style={{ height: '100vh', }}>
        <img src={NotFound404} alt='404 - Not Found' style={{ width: '50vh' }} />
        <div className="d-flex flex-row justify-content-around align-items-center" style={{ width: '50vw' }}>
          <Button
            title={t('Go Back')}
            onClick={() => window.history.back()}
            className='btn-back ms-2'>
            <FontAwesomeIcon
              icon={faAngleLeft}
              color={brand_colors[this.props.defaultTheme.theme_id].color8}
            />
          </Button>
          <Button
            title={t('LOGOUT')}
            onClick={() => this.state.logoutModal.toggleModal()}
            className='btn-header ms-2'>
            <FontAwesomeIcon
              icon={faPowerOff}
              color={brand_colors[this.props.defaultTheme.theme_id].color8}
            />
          </Button>
        </div>

        <SweetAlert
          show={this.state.logoutModal.show}
          warning
          showCancel
          confirmBtnText={t('LOGOUT!')}
          cancelBtnText={t('Keep Logged-in!')}
          confirmBtnBsStyle='danger'
          title={(this.state.logoutModal.title) ? this.state.logoutModal.title : t('Confirm logout?')}
          onConfirm={this.state.logoutModal.action}
          onCancel={() => this.state.logoutModal.toggleModal()}
          focusCancelBtn>
        </SweetAlert>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  authData: state.auth.authData,
  defaultLanguage: state.language.defaultLanguage,
  defaultTheme: state.theme.defaultTheme,
});


const mapDispatchToProps = () => ({
  unsetAuthentication,
});

export default connect(mapStateToProps, mapDispatchToProps())(UnAuthorized);