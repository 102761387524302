import { Component } from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUser,
  faChevronDown,
  faSchool,
  faCheckCircle,
} from '@fortawesome/free-solid-svg-icons';
import { Modal, ListGroup, Image, Row, Col, Form, Badge } from 'react-bootstrap';
import { setAuthentication } from '../../../redux/slices/authSlice';
import { t } from '../../../helpers/translation_helper';
import { brand_colors } from '../../../helpers/brand_colors_helper';
import axios from 'axios';
import { toast } from 'react-toastify';
import { refreshLogin } from '../../../redux/slices/authSlice';

// API URL
const apiURL = process.env.REACT_APP_API_URL;

// Portal URL
const portalURL = process.env.REACT_APP_PORTAL_URL;

/**
 * Class Selector Partial Component
 */
class CenterSelector extends Component {
  /**
   * @constructor
   * @param {any} props
   */
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      search: ''
    };
  }

  /**
   * Component Did Mount
   */
  componentDidMount() {
    // const { selectedClass } = this.props;
    //!AHMAD  COMMENTED BECAUSE WE ARE NOT USING THIS SLICE ANYMORE
    // this.setChildrenList(selectedClass);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.authData.centerData.center_id !== this.props.authData.centerData.center_id) {
      this.setState({ modal: false, }, () => {
        if (this.props.func?.length > 0) {
          this.props.func.map((func) => {
            return func();
          });
        }
      });
    }
  }

  /**
   * Handle Modal
   */
  handleModal = () => {
    const { modal } = this.state;
    this.setState({ modal: !modal });
  };

  /**
   * Classes List
   * @returns {HTMLElement} Outputs the classes list.
   */
  centerList = () => {
    const { authData } = this.props;
    let list = [];
    if (authData.multiCenters && authData.multiCenters.length > 0) {
      list = authData.multiCenters.map((item, key) => {
        if (!(item.center_name).toLowerCase().includes(this.state.search.toLowerCase()) && !(item.center_keypass).toLowerCase().includes(this.state.search.toLowerCase()) && !(item.center_id).toString().includes(this.state.search.toLowerCase())) {
          return null
        }
        return <ListGroup.Item
          key={key}
          className='child-list-item'
          onClick={() => this.handleCenterChange(item.center_id)}
        >
          <Row style={{ flex: 1 }}>
            <Col className='d-flex'>
              <div className='child-pic list-child-pic'>
                {item.logo ? (
                  <Image
                    src={item.logo}
                    style={{
                      objectFit: 'cover',
                      width: '100%',
                      height: '100%',
                    }}
                  />
                ) : (
                  <FontAwesomeIcon icon={faSchool} color={brand_colors[this.props.defaultTheme.theme_id].color8} />
                )}
              </div>
              <div className='d-flex flex-column align-items-start'>
                <div className='class-name'>
                  {item.center_name}
                </div>
                <Badge bg='success'>{item.center_keypass}</Badge>
              </div>
            </Col>
            <Col md='auto' className='d-flex align-items-center' >
              {this.props.authData.centerData.center_id == item.center_id && (
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  color={brand_colors[this.props.defaultTheme.theme_id].color4}
                />
              )}
            </Col>
          </Row>
        </ListGroup.Item>
      });
    }
    return list;
  };

  /**
   * handleCenterChange
   * @param {number} center_id ID 
   */
  handleCenterChange = async (center_id) => {

    const { authData } = this.props;
    const { auth_key } = authData.loginData;
    this.props.refreshLogin(center_id)

    // const request_params = {
    //   center_id,
    //   auth_key: auth_key,
    //   appname: process.env.REACT_APP_NAME,
    // };

    // try {
    //   const res = await GET('login/change-center', {
    //     params: request_params,
    //   });

    //   if (res.status == 200) {
    //     this.props.setAuthentication({ ...authData, centerData: res.data });
    //     this.props.refreshLogin(auth_key, center_id)
    //   }

    // } catch (err) {
    // }

    // this.setState(
    //   {
    //     modal: false,
    //   },
    //   () => {
    //     if (this.props.func?.length > 0) {
    //       this.props.func.map((func) => {
    //         return func();
    //       });
    //     }
    //   }
    // );
  };


  /**
   * Render Component
   * @returns {HTMLElement}
   */
  render() {

    const { lang_orientation: dir } = this.props.defaultLanguage;
    const { modal, search } = this.state;
    const { authData } = this.props;
    return (
      <>
        {(this.props.minimal)
          ? <div className='selector' style={{ padding: '0 10px', cursor: 'pointer' }}>
            <div className='d-flex flex-row justify-content-center align-items-center' style={{ fontSize: 14, fontWeight: 'bold', color: brand_colors[this.props.defaultTheme.theme_id].color9, opacity: 0.8 }} onClick={() => this.handleModal()}>
              <FontAwesomeIcon
                icon={faCheckCircle}
                color={brand_colors[this.props.defaultTheme.theme_id].color4}
                className='mx-1'
              />
              <div className='mx-1'>{this.props.authData.centerData.center_name}</div>
              <FontAwesomeIcon
                icon={faChevronDown}
                color={brand_colors[this.props.defaultTheme.theme_id].color2}
                className='mx-1'
              />
            </div>
          </div>
          : <div className='selector'>
            <div className='child-pic'>
              {authData.userData.employee_picture ? (<Image src={portalURL + authData.userData.center_id + '/employees/' + authData.userData.employee_picture} style={{ objectFit: 'cover', width: '100%', height: '100%', }} />
              ) : (
                <FontAwesomeIcon icon={faUser} color={brand_colors[this.props.defaultTheme.theme_id].color8} />
              )}
            </div>
            <div>
              <h5 className='child-name'>
                <span onClick={() => this.handleModal()}>
                  {dir == 0 && t('Hi')}{' '}
                  {authData.loginData.full_name}{' '}
                  {dir == 1 && t('Hi')}
                </span>
              </h5>
              <h6 className='class-name'>
                <span onClick={() => this.handleModal()}>
                  {authData.centerData.center_name}{' '}
                  <FontAwesomeIcon
                    icon={faChevronDown}
                    color={brand_colors[this.props.defaultTheme.theme_id].color2}
                  />
                </span>
              </h6>
            </div>
          </div>
        }

        <Modal show={modal} size='md' onHide={this.handleModal}>
          <Modal.Header>
            <div className='d-flex flex-column' style={{ width: '100%' }}>
              <div className='d-flex flex-row justify-content-between align-items-center'>
                <Modal.Title>{t('Select Center')}</Modal.Title>
                <button
                  type='button'
                  className='btn-close'
                  aria-label='Close'
                  onClick={this.handleModal}
                ></button>
              </div>
              <div >
                <Form.Control
                  type='text'
                  placeholder={t('Search...')}
                  onChange={(e) => this.setState({ search: e.target.value })}
                  value={search}
                />
              </div>
            </div>
          </Modal.Header>
          <Modal.Body className='p-0 pb-2'>
            <ListGroup variant='flush'>
              {this.centerList()}
            </ListGroup>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  authData: state.auth.authData,
  selectedClass: state.selectedClass.data,
  defaultLanguage: state.language.defaultLanguage,
  defaultTheme: state.theme.defaultTheme,
});

const mapDispatchToProps = () => ({
  setAuthentication,
  refreshLogin
});

export default connect(mapStateToProps, mapDispatchToProps())(CenterSelector);
