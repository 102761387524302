import React, { Component } from 'react';
import { Container, Card, Row, Col } from 'react-bootstrap';
import { t } from '../../helpers/translation_helper';
import NavBar from '../_partials/NavBar/_NavBar';
import Header from '../_partials/Header/_Header';
import { connect } from 'react-redux';
import { GET } from '../../api';
import { Bar, Chart } from 'react-chartjs-2';
import { LoadingPlaceholder } from '../_common/Loading/Loading';

const barChartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
      position: 'bottom',
    },
  },
  scales: {
    x: {
      ticks: {
        callback: function (value) {
          return this.getLabelForValue(value).substr(0, 15) + '...';
        }
      }
    }
  }
};

class RegistrationsHistory extends Component {

  state = {
    chartData: null
  }

  componentDidMount() {
    this.getData();
  }

  getData = async () => {
    try {
      const res = await GET('registrations-history/get');
      console.log('res', res);

      let chartData = {
        labels: [],
        datasets: []
      };

      chartData.labels = res.data.data.years;

      chartData.datasets.push({
        label: 'All Registrations',
        data: res.data.data.registrations_all,
        backgroundColor: 'rgb(255, 99, 132)'
      });

      chartData.datasets.push({
        label: 'Inactive Registrations',
        data: res.data.data.registrations_inactive,
        backgroundColor: 'rgb(75, 192, 192)'
      });

      chartData.datasets.push({
        label: 'Withdrawals',
        data: res.data.data.withdrawals,
        backgroundColor: 'rgb(53, 162, 235)'
      });

      console.log('chartData', chartData);

      this.setState({ chartData });

    } catch (err) {
      console.log(err);
    }
  }

  render() {
    return (
      <Container fluid>
        <div id='iedu-layout'>
          <NavBar />
          <div id='page-content'>
            <Header lite={true} heading={t('Registrations History')} backBtn={true} />
            <div className='grey-section'>
              <Card
                className='border-0'
                style={{ borderRadius: '20px' }}>
                <Card.Body>
                  <Row>
                    <Col md={12}>
                      {this.state.chartData ? <Bar options={barChartOptions} data={this.state.chartData} height={505} /> : <LoadingPlaceholder height={505} />}
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </div>
          </div>
        </div>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  authData: state.auth.authData,
  defaultLanguage: state.language.defaultLanguage,
});

export default connect(mapStateToProps, null)(RegistrationsHistory);
