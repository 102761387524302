import { createSlice } from '@reduxjs/toolkit';

export const calendarPreviewSlice = createSlice({
  name: 'calendarPreview',
  initialState: {
    showModal: false,
    type: null,
    data: null,
  },
  reducers: {
    setCalendarPreview: (state, action) => {
      state.showModal = action.payload.showModal;
      state.type = action.payload.type;
      state.data = action.payload.data;
    },
  },
});

export const { setCalendarPreview } =
  calendarPreviewSlice.actions;
export default calendarPreviewSlice.reducer;
