import React, { Component } from 'react';
import { Container, Card, Row, Col, Button, Spinner, InputGroup, Form, Table, Badge, ButtonGroup, DropdownButton, Dropdown } from 'react-bootstrap';
import { t } from '../../helpers/translation_helper';
import NavBar from '../_partials/NavBar/_NavBar';
import Header from '../_partials/Header/_Header';
import { connect } from 'react-redux';
import { brand_colors } from '../../helpers/brand_colors_helper';
import { faCalendar, faEye, faEyeSlash, faSearch, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DateRangePicker } from 'react-date-range';
import moment from 'moment';
import { GET } from '../../api';
import { changeColorOpacity, numberFormat } from '../../helpers/general_helpers';
import { faCalendarAlt, faFileExcel } from '@fortawesome/free-regular-svg-icons';
import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';
import { renderTableBody, renderTableHead } from '../../helpers/table_helper';
import Pagination from '../_partials/Pagination';
let cancelToken;

class ReportEmployeeAttendance extends Component {

  state = {
    fetching: false,
    selectedDateRange: { startDate: new Date(), endDate: new Date(), key: 'selection' },
    showDateRange: false,
    reportData: [],
    reportTitle: [],
    cdt: {
      fetching: false,
      search: '',
      rows: 10,
      offset: 0,
      order: 'f.addservices_num',
      dir: 'DESC',
      lastPage: 0,
      page: 0,
      totalRecords: 0,
      rowsOptions: [10, 25, 50, 'All'],
      columns: [
        {
          id: 'f.addservices_num',
          column: 'addservices_num',
          name: 'Number',
          visibility: true,
          sortable: true
        },
        {
          id: 'f.addservicesheader_date',
          column: 'addservicesheader_date',
          name: 'Date',
          visibility: true,
          sortable: true
        },
        {
          id: 'c.child_name',
          column: 'child_name',
          name: 'Student',
          visibility: true,
          sortable: true
        },
        {
          id: 'cl.class_theme',
          column: 'class_theme',
          name: 'Class',
          visibility: true,
          sortable: true
        },
        {
          id: 'p.parent_name',
          column: 'parent_name',
          name: 'Parent',
          visibility: true,
          sortable: true
        },
        {
          id: 'p.account_num',
          column: 'account_num',
          name: 'Account',
          visibility: true,
          sortable: true
        },
        {
          id: 'f.addservicesheader_amount',
          column: 'addservicesheader_amount',
          name: 'Amount',
          visibility: true,
          sortable: true
        },
        {
          id: 'f.addservicesheader_vat',
          column: 'addservicesheader_vat',
          name: 'VAT',
          visibility: true,
          sortable: true
        },
        {
          id: 'total',
          column: 'total',
          name: 'Total',
          visibility: true,
          sortable: false
        },
      ],
      data: [],
    },
    excelData: [],
  }

  fetch = async (params) => {

    const { selectedDateRange, zero_vat } = this.state;

    this.setState({
      showDateRange: false,
      fetching: true,
      reportData: [],
      reportTitle: [],
      cdt: {
        ...this.state.cdt,
        fetching: true,
      }
    });

    if (cancelToken) {
      cancelToken.abort();
    }
    cancelToken = new AbortController();

    try {
      const res = await GET('employee-attendance-report/get-attendance-sheet-monthly-data', {
        signal: cancelToken.signal,
        params: {
          search: params.search,
          order: params.order,
          dir: params.dir,
          limit: params.limit,
          offset: parseInt(params.page) * parseInt(params.limit),
          from_date: moment(selectedDateRange.startDate).format('DD-MM-YYYY'),
          to_date: moment(selectedDateRange.endDate).format('DD-MM-YYYY'),
        },
      });

      const excelData = res.data.data.table_month_data.map((v, i) => {

        let work_start_time = v.work_start_time != "" && v.work_start_time != null ? moment(v.work_start_time, "hh:mm A") : null;
        let work_end_time = v.work_end_time != "" && v.work_end_time != null ? moment(v.work_end_time, "hh:mm A") : null;
        let total_min_text1 = 0;
        let total_min_text2 = 0;



        return {
          employee_code: v.empl_code,
          employee_name: v.employee_name,
          attendance_exempt: v.empl_att_exempt == 1 ? 'Yes' : 'No',
          // 
          time_early_late: total_min_text1 > 0 ? total_min_text1.toFixed(0) + " m Early" : (total_min_text1 * -1).toFixed(0) + " m Late" + ' - ' + total_min_text2 > 0 ? total_min_text2.toFixed(0) + " m OT" : (total_min_text2 * -1).toFixed(0) + " m Early"
        }
      });

      console.log('excelData', excelData);


      if (res.status === 200) {
        this.setState({
          // cdt: {
          //   ...this.state.cdt,
          //   fetching: false,
          //   search: params.search,
          //   rows: parseInt(params.limit),
          //   offset: parseInt(params.page) * parseInt(params.limit),
          //   order: params.order,
          //   dir: params.dir,
          //   page: params.page,
          //   totalRecords: parseInt(res.data.total_count_filtered),
          //   lastPage: Math.ceil(parseInt(res.data.total_count_filtered) / parseInt(params.limit)),
          //   data: res.data.data,
          // },
          fetching: false,
          reportData: res.data.data.table_month_data,
          reportTitle: res.data.data.table_month_title,
        });


      }

    } catch (err) {
      console.log('err', err)
      this.setState({
        fetching: false,
        cdt: {
          ...this.state.cdt,
        }
      });

    }
  }

  exportExcel = async () => {

    const excelData = this.state.cdt.data.map((item) => {
      const row = {};
      this.state.cdt.columns
        .filter((column) => column.visibility)
        .map((column) => {
          if (column.name == 'Total') {
            row[column.name] = (parseFloat(row['Amount']) + parseFloat(row['VAT'])).toFixed(2);
          } else {
            row[column.name] = item[column.column];
          }
        });
      return row;
    });

    const fileName = 'Employee Attendance Report ' + moment().format('DD-MM-YYYY');
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const ws = XLSX.utils.json_to_sheet(excelData);

    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });

    FileSaver.saveAs(data, fileName + fileExtension);
  }

  funcDrCr = (val) => {
    if (parseInt(val) < 0) {
      return <span class="text-danger">{val}</span>
    } else {
      return (
        <span style={{ color: '#b6e344' }}>{val}</span>
      );
    }
  }

  showHideColumn = (column_index) => {
    const columns = this.state.cdt.columns;
    columns[column_index].visibility = !columns[column_index].visibility;
    this.setState({ ctd: { ...this.state.ctd, columns } })
  }

  renderCell = (column_data, column_id, column_index, dataObj) => {
    const { authData } = this.props;
    const { center_id } = authData.centerData;

    let tdData;

    if (column_id == 'total') {
      tdData = numberFormat((parseFloat(dataObj.addservicesheader_amount) + parseFloat(dataObj.addservicesheader_vat)).toFixed(2));
    } else if (column_id == 'addservicesheader_amount' || column_id == 'addservicesheader_vat') {
      tdData = numberFormat(column_data);
    } else {
      tdData = column_data;
    }


    return <td key={column_index}>{tdData}</td>;
  }

  render() {

    return (
      <Container fluid>
        <div id='iedu-layout'>
          <NavBar />
          <div id='page-content'>
            <Header lite={true} heading={t('Employee Attendance Report')} backBtn={true} />
            <div className='grey-section'>
              <Row>
                <Col md={3} className='position-relative pe-0'>
                  <InputGroup>
                    <Form.Control
                      onClick={() => this.setState({ showDateRange: true })}
                      style={{
                        paddingLeft: 20,
                        borderTopLeftRadius: 20,
                        borderBottomLeftRadius: this.state.showDateRange ? 7 : 20,
                      }}
                      value={
                        moment(this.state.selectedDateRange.startDate).format('DD-MM-YYYY') + ' -- ' + moment(this.state.selectedDateRange.endDate).format('DD-MM-YYYY')
                      }
                      readOnly
                    />
                    <Button variant={this.state.showDateRange ? 'danger' : 'primary'}
                      style={{ width: 40 }}
                      onClick={() => this.setState({ showDateRange: !this.state.showDateRange })}
                      id="button-addon2">
                      <FontAwesomeIcon icon={this.state.showDateRange ? faXmark : faCalendarAlt} style={{ fontSize: 16, color: brand_colors[this.props.defaultTheme.theme_id].color8, }} />
                    </Button>
                  </InputGroup>
                  {this.state.showDateRange &&
                    <div style={{
                      backgroundColor: '#fff',
                      padding: 10,
                      position: 'absolute',
                      border: '#F2F2F2 2px solid',
                      zIndex: 1,
                      top: 37,
                      borderRadius: 7
                    }}>
                      <DateRangePicker
                        ranges={[this.state.selectedDateRange]}
                        onChange={(date) => {

                          this.setState({
                            selectedDateRange: { startDate: new Date(date.selection.startDate), endDate: new Date(date.selection.endDate), key: 'selection' }
                          })
                        }}
                        style={{ backgroundColor: '#fff' }}
                      />
                    </div>
                  }
                </Col>
                <Col className='d-flex align-items-center'>
                  <Button variant={'success'}
                    disabled={this.state.fetching}
                    onClick={() => this.fetch({
                      search: this.state.cdt.search,
                      limit: this.state.cdt.rows,
                      order: this.state.cdt.order,
                      dir: this.state.cdt.dir,
                      page: this.state.cdt.page
                    })}>
                    {this.state.fetching
                      ? <Spinner as='span' animation='grow' size='sm' role='status' aria-hidden='true' />
                      : <FontAwesomeIcon icon={faSearch} style={{ fontSize: 16, color: brand_colors[this.props.defaultTheme.theme_id].color8, }} />
                    }
                  </Button>
                </Col>
              </Row>
            </div>
            <Card
              className='border-0'
              style={{ borderRadius: '20px' }}>
              <Card.Header
                className='d-none'
                style={{
                  backgroundColor: '#fff',
                  borderTopLeftRadius: '20px',
                  borderTopRightRadius: '20px',
                }}>
                <Row>
                  <Col md={6} className='d-flex align-items-center'>
                    <ButtonGroup size='sm' className='me-3 float-left'>
                      {/* <Button><FontAwesomeIcon icon={faFilePdf} /> {t('PDF')}</Button> */}
                      <Button onClick={() => this.exportExcel()}>
                        <FontAwesomeIcon icon={faFileExcel} /> {t('Excel')}
                      </Button>
                      {/* <Button><FontAwesomeIcon icon={faPrint} /> {t('Print')}</Button> */}
                      {/* <DropdownButton
                        autoClose={'outside'}
                        size='sm'
                        as={ButtonGroup}
                        title={t('Column Visibility')}>
                        {this.state.cdt.columns.map((column, column_index) => (
                          <Dropdown.Item key={column_index} className={column.visibility ? 'column-name-item' : 'column-name-item active'} onClick={() => this.showHideColumn(column_index)}>
                            <FontAwesomeIcon icon={column.visibility ? faEye : faEyeSlash} /> {column.name}
                          </Dropdown.Item>
                        ))}
                      </DropdownButton> */}
                    </ButtonGroup>
                    <div
                      className='d-none'
                      style={{ display: 'inline-block' }}>
                      {t('Show')}
                      <Form.Select
                        value={this.state.cdt.rows}
                        size="sm"
                        onChange={(e) => this.fetch({
                          search: this.state.cdt.search,
                          limit: e.target.value,
                          order: this.state.cdt.order,
                          dir: this.state.cdt.dir,
                          page: this.state.cdt.page
                        })}
                        style={{ width: 70, marginInline: 5, display: 'inline-block' }}>
                        {this.state.cdt.rowsOptions.map((row, row_index) => (<option key={row_index} >{row}</option>))}
                      </Form.Select>
                      {t('Records')}
                    </div>
                  </Col>
                  <Col md={6} className='d-none d-flex justify-content-end'>
                    <div className='cdt-search'>
                      <input type='text'
                        ref={this.searchFieldRef}
                        defaultValue={this.state.cdt.search}
                        onChange={(e) => this.fetch({
                          search: e.target.value,
                          limit: this.state.cdt.rows,
                          order: this.state.cdt.order,
                          dir: this.state.cdt.dir,
                          page: 0
                        })}
                        placeholder={t('Search...')} />
                      <button
                        onClick={() => {
                          this.fetch({
                            search: '',
                            limit: this.state.cdt.rows,
                            order: this.state.cdt.order,
                            dir: this.state.cdt.dir,
                            page: 0
                          });
                          this.searchFieldRef.current.value = '';
                        }}>
                        <FontAwesomeIcon
                          icon={this.state.cdt.search == '' ? faSearch : faXmark}
                          color={brand_colors[this.props.defaultTheme.theme_id].color18}
                        />
                      </button>
                    </div>
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body style={{ paddingTop: 8 }}>
                <Row>
                  <Col md={12}>
                    <Table hover responsive className='mb-0'>
                      <thead>
                        <tr key={1 + 1}>
                          <th style={{ minWidth: 150 }}>{t('Employee Code')}</th>
                          <th style={{ minWidth: 200 }}>{t('Employee Name')}</th>
                          <th style={{ minWidth: 200 }}>{t('Attendance Exempt')}</th>
                          {
                            this.state.reportTitle.map((v, i) => {
                              return <>
                                <th style={{ minWidth: 170 }}>
                                  {v.column_title}
                                </th>
                              </>
                            })
                          }
                          <th style={{ minWidth: 180 }}>{t('Time Early/Late')}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          this.state.reportData.map((v, i) => {

                            let work_start_time = v.work_start_time != "" && v.work_start_time != null ? moment(v.work_start_time, "hh:mm A") : null;
                            let work_end_time = v.work_end_time != "" && v.work_end_time != null ? moment(v.work_end_time, "hh:mm A") : null;
                            let total_min_text1 = 0;
                            let total_min_text2 = 0;

                            return <tr>
                              <td>
                                {v.empl_code}
                              </td>
                              <td>
                                {v.employee_name} <br />
                                <span className="badge badge-light-success" style={{ paddingLeft: '10px' }}>{v.work_start_time}</span>&nbsp;
                                <span className="badge badge-light-danger" style={{ paddingLeft: '10px' }}>{v.work_end_time}</span>
                              </td>
                              <td>{v.empl_att_exempt == 1 ?
                                <Badge bg="info">{t('Yes')}</Badge>
                                :
                                <Badge bg="danger">{t('No')}</Badge>
                              }</td>
                              {

                                this.state.reportTitle.map((vt, it) => {
                                  let att_datetime_in = v[vt.field_name].att_datetime_in;
                                  let att_datetime_out = v[vt.field_name].att_datetime_out;
                                  let leaves = v[vt.field_name].leaves;
                                  let class1 = att_datetime_in == null ? "badge-outline-danger" : "badge-outline-success";
                                  let class2 = att_datetime_out == null ? "badge-outline-danger" : "badge-c-primary";
                                  let text1 = att_datetime_in == null ? "N/A" : moment(att_datetime_in, "HH:mm:ss");
                                  let text2 = att_datetime_out == null ? "N/A" : moment(att_datetime_out, "HH:mm:ss");
                                  let text1_EL = "";

                                  if (work_start_time != null && text1 != "N/A") {
                                    // EARLY OR ON TIME
                                    if (text1.isSameOrBefore(work_start_time)) {
                                      var duration = moment.duration(work_start_time.diff(text1));
                                      text1_EL = <span class="badge badge-pill badge-light-primary badge-round">{
                                        duration.asMinutes().toFixed(0) + " m Early"
                                      }</span>;
                                      total_min_text1 += parseFloat(duration.asMinutes().toFixed(0));
                                    }
                                    // LATE
                                    else if (text1.isAfter(work_start_time)) {
                                      var duration = moment.duration(text1.diff(work_start_time));
                                      text1_EL = <span class="badge badge-pill badge-light-danger badge-round">{
                                        duration.asMinutes().toFixed(0) + " m Late"
                                      }</span>;
                                      total_min_text1 -= parseFloat(duration.asMinutes().toFixed(0));
                                    }
                                  }
                                  let text2_EL = "";
                                  if (work_end_time != null && text2 != "N/A") {
                                    // EARLY OR ON TIME
                                    if (text2.isSameOrAfter(work_end_time)) {
                                      var duration = moment.duration(text2.diff(work_end_time));
                                      text2_EL = <span class="badge badge-pill badge-light-primary badge-round">{
                                        duration.asMinutes().toFixed(0) + " m OT"
                                      }</span>;
                                      total_min_text2 += parseFloat(duration.asMinutes().toFixed(0));
                                    }
                                    // LATE
                                    else if (text2.isBefore(work_end_time)) {
                                      var duration = moment.duration(work_end_time.diff(text2));
                                      text2_EL = <span class="badge badge-pill badge-light-danger badge-round">{
                                        duration.asMinutes().toFixed(0) + " m Early"
                                      }</span>;
                                      total_min_text2 -= parseFloat(duration.asMinutes().toFixed(0));
                                    }
                                  }
                                  return <>
                                    <td>
                                      {leaves ?
                                        <>
                                          {leaves.emplleave_annual_sick == 'annual_leave' &&
                                            <Badge bg="info">{t('Annual Leave')}</Badge>
                                          }
                                          {leaves.emplleave_annual_sick == 'sick_leave' &&
                                            <Badge bg="success">{t('Sick Leave')}</Badge>
                                          }
                                        </>
                                        :
                                        <>
                                          {(v[vt.field_name].emplabs_id != 0 && v[vt.field_name].emplabs_id != null) ?
                                            <Badge bg="danger">{t('Absent')}</Badge>
                                            :
                                            <>
                                              <div className={"badge " + class1} style={{ position: "relative" }}>
                                                {
                                                  text1 != "N/A" ? text1.format("hh:mm A") : text1
                                                }
                                                {text1_EL}
                                              </div>&nbsp;
                                              <div className={"badge " + class2} style={{ position: "relative" }} >
                                                {
                                                  text2 != "N/A" ? text2.format("hh:mm A") : text2
                                                }
                                                {text2_EL}
                                              </div>
                                            </>
                                          }
                                        </>
                                      }
                                    </td>
                                  </>
                                })

                              }
                              <td>
                                <div className={total_min_text1 > 0 ? "badge badge-c-success" : "badge badge-c-primary"}>
                                  {
                                    total_min_text1 > 0 ? total_min_text1.toFixed(0) + " m Early" : (total_min_text1 * -1).toFixed(0) + " m Late"
                                  }
                                </div>&nbsp;
                                <div className={total_min_text2 > 0 ? "badge badge-c-success" : "badge badge-c-primary"}>
                                  {
                                    total_min_text2 > 0 ? total_min_text2.toFixed(0) + " m OT" : (total_min_text2 * -1).toFixed(0) + " m Early"
                                  }
                                </div>

                              </td>
                            </tr>
                          })
                        }
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </div>
        </div>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  authData: state.auth.authData,
  selectedClass: state.selectedClass.data,
  defaultLanguage: state.language.defaultLanguage,
  defaultTheme: state.theme.defaultTheme,
});

export default connect(mapStateToProps, null)(ReportEmployeeAttendance);
