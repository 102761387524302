import { createSlice } from '@reduxjs/toolkit';

export const addEditStudentModalSlice = createSlice({
  name: 'addEditStudentModal',
  initialState: {
    showModal: false,
    child_id: 0,
    isEditing: false,
    selectedParent: null,
    parents: []
  },
  reducers: {
    setAddEditStudentModal: (state, action) => {
      state.showModal = action.payload.showModal;
      state.child_id = action.payload.child_id;
      state.isEditing = action.payload.isEditing;
    },
    setParents: (state, action) => {
      state.parents = action.payload
    },
    setParent: (state, action) => {
      state.selectedParent = action.payload
    }
  },
});

export const { setAddEditStudentModal, setParents, setParent } =
  addEditStudentModalSlice.actions;
export default addEditStudentModalSlice.reducer;
