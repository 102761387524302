import React, { Component } from 'react';
import { Container, Card, Row, Col, Button, Spinner, InputGroup, Form, Table, Badge } from 'react-bootstrap';
import { t } from '../../helpers/translation_helper';
import NavBar from '../_partials/NavBar/_NavBar';
import Header from '../_partials/Header/_Header';
import { connect } from 'react-redux';
import { brand_colors } from '../../helpers/brand_colors_helper';
import { faCalendar, faCheckCircle, faExclamationCircle, faFileExcel, faSearch, faTimesCircle, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DateRangePicker } from 'react-date-range';
import moment from 'moment';
import { GET } from '../../api';
import { checkRegistration, numberFormat } from '../../helpers/general_helpers';
import { faCalendarAlt } from '@fortawesome/free-regular-svg-icons';
import Select from 'react-select';
import { toast } from 'react-toastify';
import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';

class ClassAttendanceReport extends Component {

  state = {
    fetching: false,
    selectedDateRange: { startDate: new Date(), endDate: new Date(), key: 'selection' },
    showDateRange: false,
    students: [],
    months: [],
    days: [],
    workingDays: 0,
    ageGroups: [],
    classes: [],
    selectedAgeGroup: null,
    selectedClasses: null
  }

  componentDidMount() {
    this.getAgeGroups();
  }

  fetch = async () => {
    const { selectedDateRange, selectedAgeGroup, selectedClasses } = this.state;

    if (!selectedAgeGroup) {
      toast.error(t('Please select grade'));
      return;
    }

    if (!selectedClasses) {
      toast.error(t('Please select class'));
      return;
    }

    this.setState({
      fetching: true,
      showDateRange: false,
      students: [],
    });

    try {
      const res = await GET('children/report-attendance-class', {
        params: {
          class_ids: JSON.stringify([this.state.selectedClasses.value]),
          date_from: moment(selectedDateRange.startDate).format('YYYY-MM-DD'),
          date_to: moment(selectedDateRange.endDate).format('YYYY-MM-DD')
        }
      });

      const workingDays = this.getWorkingDaysFromDateRange(selectedDateRange.startDate, selectedDateRange.endDate) - this.getHolidays(res.data.holidays);

      this.setState({
        fetching: false,
        students: res.data.data,
        months: this.getMonthsFromDateRange(),
        workingDays,
        days: this.getDaysFromDateRange(selectedDateRange.startDate, selectedDateRange.endDate)
      });
    } catch (err) {
      console.log(err);
      this.setState({ fetching: false });
    }

  }

  getAgeGroups = async () => {
    try {
      const res = await GET('children/get-age-groups');
      this.setState({
        ageGroups: res.data.map(item => {
          return {
            ...item,
            value: item.agegroup_id,
            label: item.agegroup_code
          }
        })
      });
    } catch (err) {
      console.log(err);
    }
  }

  getMonthsFromDateRange = () => {
    const { selectedDateRange } = this.state;

    var start = moment(selectedDateRange.startDate).format('YYYY-MM-DD').split('-');
    var end = moment(selectedDateRange.endDate).format('YYYY-MM-DD').split('-');
    var startYear = parseInt(start[0]);
    var endYear = parseInt(end[0]);
    var months = [];

    for (var i = startYear; i <= endYear; i++) {
      var endMonth = i != endYear ? 11 : parseInt(end[1]) - 1;
      var startMon = i === startYear ? parseInt(start[1]) - 1 : 0;
      for (var j = startMon; j <= endMonth; j = j > 12 ? j % 12 || 11 : j + 1) {
        var month = j + 1;
        var displayMonth = month < 10 ? '0' + month : month;
        months.push(moment([i, displayMonth, '01'].join('-')).format('MMM/YY'));
      }
    }

    return months

  }

  getWorkingDaysFromDateRange = (start, end) => {

    var startDate = moment(start);
    var endDate = moment(end);
    let workingDays = 0;

    while (startDate <= endDate) {
      if (startDate.format('ddd') !== 'Sat' && startDate.format('ddd') !== 'Sun') {
        workingDays++;
      }
      startDate = moment(startDate).add(1, 'days');
    }

    return workingDays;
  }

  getStudentPresentsCount = (student, month) => {
    const presents = student.childattendance.filter(att => moment(att.att_date).format('MMM/YY') == month && att.absent == 0 && att.class_id == student.class_id && (att.att_datetime_in != null || att.att_datetime_out != null));
    console.log('presents', presents);
    return presents.length;
  }

  getStudentAbsentsCount = (student, month) => {
    const absents = student.childattendance.filter(att => moment(att.att_date).format('MMM/YY') == month && att.absent == 1);
    return absents.length;
  }

  getHolidays = (holidays) => {
    let holidaysCount = 0;

    holidays.map((holiday) => {
      holidaysCount += this.getWorkingDaysFromDateRange(holiday.start_datetime, holiday.end_datetime);
    });

    return holidaysCount;
  }

  handleAgeGroupChange = async (obj) => {
    this.setState({
      selectedAgeGroup: obj
    });

    try {
      const res = await GET('children/get-classes', {
        params: {
          agegroup_id: obj.agegroup_id
        }
      });

      this.setState({
        classes: res.data.map(item => {
          return {
            ...item,
            value: item.class_id,
            label: item.class_theme,
          }
        }),
        selectedClasses: []
      });
    } catch (err) {
      console.log(err);
    }

  }

  handleClassSelection = (selectedClasses) => {
    this.setState({ selectedClasses });
  }

  exportExcel = async () => {

    const excelData = this.state.students.map((student, index) => {
      const data = {};

      data['Student Name'] = student.child_name;

      this.state.days.map((day, day_index) => {

        const dayName = moment(day).format('dddd');

        const attendance = this.checkStudentAttendance(student, day, true);

        data[moment(day).format('DD-MM-YYYY') + ' (' + dayName.toUpperCase() + ')'] = attendance ? attendance : '';

      })

      return data;
    });

    const fileName = this.state.selectedClasses.label + ' - Class Attendance Report ' + moment().format('DD-MM-YYYY');
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const ws = XLSX.utils.json_to_sheet(excelData);

    // const cellStyle = {
    //   font: {
    //     bold: true,
    //   },
    // }

    // Array.from('ABCDEFGHIJKLMN').map((letter) => {
    //   ws[letter + "1"].s = cellStyle;
    // });

    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });

    FileSaver.saveAs(data, fileName + fileExtension);
  }

  getDaysFromDateRange = (start, end) => {

    var startDate = moment(start);
    var endDate = moment(end);
    let days = [];

    while (startDate <= endDate) {
      days.push(startDate);
      startDate = moment(startDate).add(1, 'days');
    }

    return days;
  }

  checkStudentAttendance = (student, day, for_export = false) => {

    let status = checkRegistration(student, day, {}, true)

    day = moment(day).format('YYYY-MM-DD');
    const attendance = student.childattendance.find(item => item.att_date == day && item.class_id == this.state.selectedClasses.value);

    if (for_export) {

      let attendanceReturn = '';

      if (attendance && attendance.absent == 1) {
        if (status === true) {
          attendanceReturn += 'R - Absent';
        }
        else {
          attendanceReturn += 'N - Absent';
        }
      }

      if (attendance && attendance.att_datetime_in && attendance.absent == 0) {

        if (status === true) {
          attendanceReturn += 'R - Checked-In';
        }
        else {
          attendanceReturn += 'N - Checked-In';
        }
      }

      if (attendance && attendance.att_datetime_out && attendance.absent == 0) {

        if (status === true) {
          attendanceReturn += 'R - Checked-Out';
        }
        else {
          attendanceReturn += 'N - Checked-Out';
        }
      }

      return attendanceReturn;
    } else {
      return (<>
        <pre style={{ textAlign: 'left', fontSize: 10, display: 'none' }}>{JSON.stringify(attendance, null, 1)}</pre>
        {attendance && attendance.absent == 1 &&
          <FontAwesomeIcon icon={faTimesCircle} style={{ fontSize: 26, color: '#dc3545' }} title={t('Absent')} />
        }
        {attendance && attendance.att_datetime_in && attendance.absent == 0 &&
          <FontAwesomeIcon icon={faCheckCircle} style={{ fontSize: 26, color: 'rgb(35 201 72)' }} title={t('Checked-In')} />
        }
        {attendance && attendance.att_datetime_out && attendance.absent == 0 &&
          <FontAwesomeIcon icon={faCheckCircle} style={{ fontSize: 26, color: '#ffc107', marginLeft: 7 }} title={t('Checked-Out')} />
        }
      </>);
    }

  }

  render() {

    return (
      <Container fluid>
        <div id='iedu-layout'>
          <NavBar />
          <div id='page-content'>
            <Header lite={true} heading={t('Class Attendance Report')} backBtn={true} />
            <div className='grey-section'>
              <Row>
                <Col md={3} className='position-relative pe-0'>
                  <InputGroup>
                    <Form.Control
                      onClick={() => this.setState({ showDateRange: true })}
                      style={{
                        paddingLeft: 20,
                        borderTopLeftRadius: 20,
                        borderBottomLeftRadius: this.state.showDateRange ? 7 : 20,
                      }}
                      value={
                        moment(this.state.selectedDateRange.startDate).format('DD-MM-YYYY') + ' -- ' + moment(this.state.selectedDateRange.endDate).format('DD-MM-YYYY')
                      }
                      readOnly
                    />
                    <Button variant={this.state.showDateRange ? 'danger' : 'primary'}
                      style={{ width: 40 }}
                      onClick={() => this.setState({ showDateRange: !this.state.showDateRange })}
                      id="button-addon2">
                      <FontAwesomeIcon icon={this.state.showDateRange ? faXmark : faCalendarAlt} style={{ fontSize: 16, color: brand_colors[this.props.defaultTheme.theme_id].color8, }} />
                    </Button>
                  </InputGroup>
                  {this.state.showDateRange &&
                    <div style={{
                      backgroundColor: '#fff',
                      padding: 10,
                      position: 'absolute',
                      border: '#F2F2F2 2px solid',
                      zIndex: 1,
                      top: 37,
                      borderRadius: 7
                    }}>
                      <DateRangePicker
                        ranges={[this.state.selectedDateRange]}
                        onChange={(date) => {

                          this.setState({
                            selectedDateRange: { startDate: new Date(date.selection.startDate), endDate: new Date(date.selection.endDate), key: 'selection' }
                          })
                        }}
                        style={{ backgroundColor: '#fff' }}
                      />
                    </div>
                  }
                </Col>
                <Col md={3} className='pe-0'>
                  <Select
                    placeholder={t('Select Grade')}
                    options={this.state.ageGroups}
                    onChange={this.handleAgeGroupChange}
                    value={this.state.selectedAgeGroup}
                    menuPortalTarget={document.body}
                    styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                    formatOptionLabel={option => (
                      <div className='d-flex align-items-center'>
                        <span style={{ fontSize: 16 }} className='me-1'>{option.label}</span>
                        {option.term_active == 1 && <Badge pill bg="success">{t('Active')}</Badge>}
                      </div>
                    )}
                  />
                </Col>
                <Col md={5} className='pe-0'>
                  <Select
                    menuPortalTarget={
                      document.body
                    }
                    styles={{
                      menuPortal: (
                        base
                      ) => ({
                        ...base,
                        zIndex: 9999,
                      }),
                    }}
                    options={this.state.classes}
                    onChange={this.handleClassSelection}
                    defaultValue={
                      this.state.selectedClasses
                    }
                    value={
                      this.state.selectedClasses
                    }
                    className='basic-multi-select'
                    classNamePrefix='select'
                  />
                </Col>
                <Col>
                  <Button variant={'success'}
                    style={{
                      width: '100%',
                      borderTopRightRadius: 20,
                      borderBottomRightRadius: 20,
                    }}
                    onClick={() => this.fetch()}>
                    {this.state.fetching
                      ? <Spinner as='span' animation='grow' size='sm' role='status' aria-hidden='true' />
                      : <FontAwesomeIcon icon={faSearch} style={{ fontSize: 16, color: brand_colors[this.props.defaultTheme.theme_id].color8, }} />
                    }
                  </Button>
                </Col>
              </Row>
            </div>
            {this.state.students.length > 0 &&
              <div className='grey-section'>
                <Card
                  className='border-0'
                  style={{ borderRadius: '20px' }}>
                  <Card.Body>
                    <Row>
                      <Col className='text-end mb-2'> <Button onClick={() => this.exportExcel()}>
                        <FontAwesomeIcon icon={faFileExcel} /> {t('Export Excel')}
                      </Button></Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <Table hover responsive bordered className='mb-0' style={{ marginRight: 1 }}>
                          <thead>
                            <tr>
                              <th style={{ textWrap: 'nowrap' }}>{t('Student Name')}</th>
                              {/* DAYS */}
                              {this.state.days.map((day, day_index) => {

                                const dayName = moment(day).format('dddd');
                                const centerWeekend = this.props.authData.centerData.weekend_days.split(',');

                                return (
                                  <th key={day_index} style={{ textAlign: 'center', textWrap: 'nowrap' }}>{moment(day).format('DD-MM-YYYY')}<br />
                                    <Badge pill bg={centerWeekend.includes(dayName.toLowerCase()) ? 'danger' : 'dark'}>{dayName.toUpperCase()}</Badge>
                                  </th>
                                )
                              })}
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.students.map((student, index) => {

                              return (
                                <tr key={index}>
                                  <td style={{ textWrap: 'nowrap' }}>{student.child_name}</td>
                                  {/* DAYS */}
                                  {this.state.days.map((day, day_index) => {

                                    const dayName = moment(day).format('dddd');
                                    const centerWeekend = this.props.authData.centerData.weekend_days.split(',');

                                    return (
                                      <td key={day_index} style={{
                                        position: 'relative',
                                        textAlign: 'center',
                                        backgroundColor: centerWeekend.includes(dayName.toLowerCase()) ? '#fff4f6' : 'transparent'
                                      }}>
                                        {checkRegistration(student, day)}
                                        {this.checkStudentAttendance(student, day)}
                                      </td>
                                    )
                                  })}
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </div>
            }
          </div>
        </div>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  authData: state.auth.authData,
  selectedClass: state.selectedClass.data,
  defaultLanguage: state.language.defaultLanguage,
  defaultTheme: state.theme.defaultTheme,
});

export default connect(mapStateToProps, null)(ClassAttendanceReport);
