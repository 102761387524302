import React, { Component } from 'react';
import { Container, Card, Row, Col, Button, Spinner, InputGroup, Form, Table, Badge } from 'react-bootstrap';
import { t } from '../../helpers/translation_helper';
import NavBar from '../_partials/NavBar/_NavBar';
import Header from '../_partials/Header/_Header';
import { connect } from 'react-redux';
import { brand_colors } from '../../helpers/brand_colors_helper';
import { faCalendar, faFileExcel, faSearch, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DateRangePicker } from 'react-date-range';
import moment from 'moment';
import { GET } from '../../api';
import { checkRegistration, getExtension } from "../../helpers/general_helpers";
import { faCalendarAlt, faCheckSquare } from '@fortawesome/free-regular-svg-icons';
import Select from 'react-select';
import { toast } from 'react-toastify';
import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';

class StudentAttendanceReport extends Component {

  state = {
    fetching: false,
    selectedDateRange: { startDate: new Date(), endDate: new Date(), key: 'selection' },
    showDateRange: false,
    students: [],
    months: [],
    workingDays: 0,
    ageGroups: [],
    classes: [],
    // days:[] ,
    studentsDDL: [],
    selectedAgeGroup: null,
    selectedClass: null,
    selectedStudent: null
  }

  componentDidMount() {
    this.getAgeGroups();
  }

  fetch = async () => {
    const { selectedDateRange, selectedAgeGroup, selectedClass, selectedStudent } = this.state;

    if (!selectedAgeGroup) {
      toast.error(t('Please select grade'));
      return;
    }

    if (!selectedClass) {
      toast.error(t('Please select class'));
      return;
    }

    if (!selectedStudent) {
      toast.error(t('Please select student'));
      return;
    }


    this.setState({
      fetching: true,
      showDateRange: false,
      students: [],
    });

    try {
      const res = await GET('children/report-attendance-student', {
        params: {
          class_id: this.state.selectedClass.value,
          student_id: this.state.selectedStudent.value,
          date_from: moment(selectedDateRange.startDate).format('YYYY-MM-DD'),
          date_to: moment(selectedDateRange.endDate).format('YYYY-MM-DD')
        }
      });

      const workingDays = this.getWorkingDaysFromDateRange(selectedDateRange.startDate, selectedDateRange.endDate) - this.getHolidays(res.data.holidays);

      this.setState({
        fetching: false,
        students: res.data.data,
        months: this.getMonthsFromDateRange(),
        workingDays,
        // days: this.getDaysFromDateRange(selectedDateRange.startDate, selectedDateRange.endDate)
      });
    } catch (err) {
      console.log(err);
      this.setState({ fetching: false });
    }

  }

  getAgeGroups = async () => {
    try {
      const res = await GET('children/get-age-groups');
      this.setState({
        ageGroups: res.data.map(item => {
          return {
            ...item,
            value: item.agegroup_id,
            label: item.agegroup_code
          }
        })
      });
    } catch (err) {
      console.log(err);
    }
  }

  getMonthsFromDateRange = () => {
    const { selectedDateRange } = this.state;

    var start = moment(selectedDateRange.startDate).format('YYYY-MM-DD').split('-');
    var end = moment(selectedDateRange.endDate).format('YYYY-MM-DD').split('-');
    var startYear = parseInt(start[0]);
    var endYear = parseInt(end[0]);
    var months = [];

    for (var i = startYear; i <= endYear; i++) {
      var endMonth = i != endYear ? 11 : parseInt(end[1]) - 1;
      var startMon = i === startYear ? parseInt(start[1]) - 1 : 0;
      for (var j = startMon; j <= endMonth; j = j > 12 ? j % 12 || 11 : j + 1) {
        var month = j + 1;
        var displayMonth = month < 10 ? 0 + month : month;
        months.push(moment([i, displayMonth, '01'].join('-')).format('MMM/YY'));
      }
    }

    return months

  }

  getWorkingDaysFromDateRange = (start, end) => {

    var startDate = moment(start);
    var endDate = moment(end);
    let workingDays = 0;

    while (startDate <= endDate) {
      if (startDate.format('ddd') !== 'Sat' && startDate.format('ddd') !== 'Sun') {
        workingDays++;
      }
      startDate = moment(startDate).add(1, 'days');
    }

    return workingDays;
  }

  getStudentPresentsCount = (student, month) => {
    const presents = student.childattendance.filter(att => moment(att.att_date).format('MMM/YY') == month && att.absent == 0 && att.class_id == student.class_id && (att.att_datetime_in != null || att.att_datetime_out != null));
    console.log('presents', presents);
    return presents;
  }

  getStudentAbsentsCount = (student, month) => {
    const absents = student.childattendance.filter(att => moment(att.att_date).format('MMM/YY') == month && att.absent == 1);
    return absents;
  }

  getHolidays = (holidays) => {
    let holidaysCount = 0;

    holidays.map((holiday) => {
      holidaysCount += this.getWorkingDaysFromDateRange(holiday.start_datetime, holiday.end_datetime);
    });

    return holidaysCount;
  }

  handleAgeGroupChange = async (obj) => {
    this.setState({
      selectedAgeGroup: obj
    });

    try {
      const res = await GET('children/get-classes', {
        params: {
          agegroup_id: obj.agegroup_id
        }
      });

      this.setState({
        classes: res.data.map(item => {
          return {
            ...item,
            value: item.class_id,
            label: item.class_theme,
          }
        }),
        selectedClass: []
      });
    } catch (err) {
      console.log(err);
    }

  }

  handleClassSelection = async (selectedClass) => {
    this.setState({ selectedClass });

    try {
      const res = await GET('children/get-students', {
        params: {
          class_id: selectedClass.value
        }
      });

      this.setState({
        studentsDDL: res.data.data.map((item) => {
          return {
            ...item,
            value: item.child_id,
            label: item.child_name
          }
        }),
        selectedStudent: null
      });

    } catch (err) {
      console.log(err);
    }
  }

  exportExcel = async () => {

    const excelData = this.state.students.map((student, index) => {
      const data = {};

      let totalPresentsCount = 0;
      let totalAbsentsCount = 0;

      data['#'] = index + 1;
      data['Student Name'] = student.child_name;
      data['Student Code'] = student.child_code;
      data['Class'] = student.class_theme;


      this.state.months.map((month, month_index) => {

        // Presents
        const presentsCount = this.getStudentPresentsCount(student, month);
        totalPresentsCount += presentsCount.length;

        // Absents
        const absentsCount = this.getStudentAbsentsCount(student, month);
        totalAbsentsCount += absentsCount.length;

        data[month] = presentsCount.length + ' / ' + absentsCount.length

      });

      data['Total Working Days'] = this.state.workingDays;
      data['Total Present Days'] = totalPresentsCount;
      data['Total Absent Days'] = totalAbsentsCount;
      data['Total Not Marked Days'] = this.state.workingDays - (totalAbsentsCount + totalPresentsCount);
      data['Present %'] = parseFloat((parseInt(totalPresentsCount) / parseInt(this.state.workingDays) * 100).toFixed(2)) + '%';

      return data;
    });

    const fileName = 'Report Attendance ' + moment().format('DD-MM-YYYY');
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const ws = XLSX.utils.json_to_sheet(excelData);

    // const cellStyle = {
    //   font: {
    //     bold: true,
    //   },
    // }

    // Array.from('ABCDEFGHIJKLMN').map((letter) => {
    //   ws[letter + "1"].s = cellStyle;
    // });

    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });

    FileSaver.saveAs(data, fileName + fileExtension);
  }

  // getDaysFromDateRange = (start, end) => {
  //
  //   var startDate = moment(start);
  //   var endDate = moment(end);
  //   let days = [];
  //
  //   while (startDate <= endDate) {
  //     days.push(startDate);
  //     startDate = moment(startDate).add(1, 'days');
  //   }
  //
  //   return days;
  // }

  render() {

    return (
      <Container fluid>
        <div id='iedu-layout'>
          <NavBar />
          <div id='page-content'>
            <Header lite={true} heading={t('Student Attendance Report')} backBtn={true} />
            <div className='grey-section'>
              <Row>
                <Col md={11}>
                  <Row>
                    <Col md={3} className='position-relative pe-0'>
                      <InputGroup>
                        <Form.Control
                          onClick={() => this.setState({ showDateRange: true })}
                          style={{
                            paddingLeft: 20,
                            borderTopLeftRadius: 20,
                            borderBottomLeftRadius: this.state.showDateRange ? 7 : 20,
                          }}
                          value={
                            moment(this.state.selectedDateRange.startDate).format('DD-MM-YYYY') + ' -- ' + moment(this.state.selectedDateRange.endDate).format('DD-MM-YYYY')
                          }
                          readOnly
                        />
                        <Button variant={this.state.showDateRange ? 'danger' : 'primary'}
                          style={{ width: 40 }}
                          onClick={() => this.setState({ showDateRange: !this.state.showDateRange })}
                          id="button-addon2">
                          <FontAwesomeIcon icon={this.state.showDateRange ? faXmark : faCalendarAlt} style={{ fontSize: 16, color: brand_colors[this.props.defaultTheme.theme_id].color8, }} />
                        </Button>
                      </InputGroup>
                      {this.state.showDateRange &&
                        <div style={{
                          backgroundColor: '#fff',
                          padding: 10,
                          position: 'absolute',
                          border: '#F2F2F2 2px solid',
                          zIndex: 1,
                          top: 37,
                          borderRadius: 7
                        }}>
                          <DateRangePicker
                            ranges={[this.state.selectedDateRange]}
                            onChange={(date) => {

                              this.setState({
                                selectedDateRange: { startDate: new Date(date.selection.startDate), endDate: new Date(date.selection.endDate), key: 'selection' }
                              })
                            }}
                            style={{ backgroundColor: '#fff' }}
                          />
                        </div>
                      }
                    </Col>
                    <Col md={3} className='pe-0'>
                      <Select
                        placeholder={t('Select Grade')}
                        options={this.state.ageGroups}
                        onChange={this.handleAgeGroupChange}
                        value={this.state.selectedAgeGroup}
                        menuPortalTarget={document.body}
                        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                        formatOptionLabel={option => (
                          <div className='d-flex align-items-center'>
                            <span style={{ fontSize: 16 }} className='me-1'>{option.label}</span>
                            {option.term_active == 1 && <Badge pill bg="success">{t('Active')}</Badge>}
                          </div>
                        )}
                      />
                    </Col>
                    <Col md={3} className='pe-0'>
                      <Select
                        placeholder={t('Select Class')}
                        menuPortalTarget={
                          document.body
                        }
                        styles={{
                          menuPortal: (
                            base
                          ) => ({
                            ...base,
                            zIndex: 9999,
                          }),
                        }}
                        options={this.state.classes}
                        // isDisabled={
                        //   disableLessons
                        // }
                        onChange={this.handleClassSelection}
                        defaultValue={
                          this.state.selectedClass
                        }
                        value={
                          this.state.selectedClass
                        }
                        className='basic-multi-select'
                        classNamePrefix='select'
                      />
                    </Col>
                    <Col md={3} className='pe-0'>
                      <Select
                        placeholder={t('Select Student')}
                        menuPortalTarget={
                          document.body
                        }
                        styles={{
                          menuPortal: (
                            base
                          ) => ({
                            ...base,
                            zIndex: 9999,
                          }),
                        }}
                        options={this.state.studentsDDL}
                        // isDisabled={
                        //   disableLessons
                        // }
                        onChange={(obj) => this.setState({
                          selectedStudent: obj
                        })}
                        defaultValue={
                          this.state.selectedStudent
                        }
                        value={
                          this.state.selectedStudent
                        }
                        className='basic-multi-select'
                        classNamePrefix='select'
                      />
                    </Col>
                  </Row>
                </Col>
                <Col>
                  <Button variant={'success'}
                    style={{
                      width: '100%',
                      borderTopRightRadius: 20,
                      borderBottomRightRadius: 20,
                    }}
                    onClick={() => this.fetch()}>
                    {this.state.fetching
                      ? <Spinner as='span' animation='grow' size='sm' role='status' aria-hidden='true' />
                      : <FontAwesomeIcon icon={faSearch} style={{ fontSize: 16, color: brand_colors[this.props.defaultTheme.theme_id].color8, }} />
                    }
                  </Button>
                </Col>
              </Row>
            </div>
            {this.state.students.length > 0 &&
              <div className='grey-section'>
                <Card
                  className='border-0'
                  style={{
                    borderRadius: '20px',
                    marginBottom: 20
                  }}>
                  <Card.Body>
                    {/* <Row>
                      <Col className='text-end mb-2'> <Button onClick={() => this.exportExcel()}>
                        <FontAwesomeIcon icon={faFileExcel} /> {t('Export Excel')}
                      </Button></Col>
                    </Row> */}
                    <Row>
                      <Col md={12}>
                        <Table hover responsive className='mb-0'>
                          <thead>
                            <tr>
                              {/* <th>{t('#')}</th> */}
                              {/* <th>{t('Student Name')}</th> */}
                              {/* <th>{t('Student Code')}</th> */}
                              {/* <th>{t('Class')}</th> */}
                              {/* MONTHS */}
                              {/* {this.state.months.map((month, month_index) => (
                                <th key={month_index} style={{ textAlign: 'center' }}>{t(month).toUpperCase()}</th>
                              ))} */}
                              <th style={{ textAlign: 'center' }}>{t('Total Working Days')}</th>
                              <th style={{ textAlign: 'center' }}>{t('Total Present Days')}</th>
                              <th style={{ textAlign: 'center' }}>{t('Total Absent Days')}</th>
                              <th style={{ textAlign: 'center' }}>{t('Total Not Marked Days')}</th>
                              <th style={{ textAlign: 'center' }}>{t('Present %')}</th>
                              <th style={{ textAlign: 'center' }}>{t('Absent %')}</th>
                              <th style={{ textAlign: 'center' }}>{t('Not Marked %')}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.students.map((student, index) => {

                              let totalPresentsCount = 0;
                              let totalAbsentsCount = 0;

                              return (
                                <tr key={index}>
                                  {/* <td>{index + 1}</td> */}
                                  {/* <td>{student.child_name}</td> */}
                                  {/* <td>{student.child_code}</td> */}
                                  {/* <td>{student.class_theme}</td> */}
                                  {/* MONTHS */}
                                  {this.state.months.map((month, month_index) => {

                                    // Presents
                                    const presentsCount = this.getStudentPresentsCount(student, month);
                                    totalPresentsCount += presentsCount.length;

                                    // Absents
                                    const absentsCount = this.getStudentAbsentsCount(student, month);
                                    totalAbsentsCount += absentsCount.length;

                                    // return (
                                    //   <td key={month_index} style={{ textAlign: 'center' }}>
                                    //     <Badge bg="success" style={{ opacity: 0.7 }}>{presentsCount}</Badge>{' '}
                                    //     <Badge bg="danger" style={{ opacity: 0.7 }}>{absentsCount}</Badge>
                                    //   </td>
                                    // )
                                  })}
                                  <td style={{ textAlign: 'center' }}>{this.state.workingDays}</td>
                                  <td style={{ textAlign: 'center' }}>
                                    <Badge bg="success" style={{ opacity: 0.7 }}>{totalPresentsCount}</Badge>
                                  </td>
                                  <td style={{ textAlign: 'center' }}>
                                    <Badge bg="danger" style={{ opacity: 0.7 }}>{totalAbsentsCount}</Badge>
                                  </td>
                                  <td style={{ textAlign: 'center' }}>
                                    <Badge bg="warning" style={{ opacity: 0.7 }}>{this.state.workingDays - (totalAbsentsCount + totalPresentsCount)}</Badge>
                                  </td>
                                  <td style={{ textAlign: 'center' }}>{
                                    parseFloat((parseInt(totalPresentsCount) / parseInt(this.state.workingDays) * 100).toFixed(2))} %
                                  </td>
                                  <td style={{ textAlign: 'center' }}>{
                                    parseFloat((parseInt(totalAbsentsCount) / parseInt(this.state.workingDays) * 100).toFixed(2))} %
                                  </td>
                                  <td style={{ textAlign: 'center' }}>{
                                    parseFloat((parseInt(this.state.workingDays - (totalAbsentsCount + totalPresentsCount)) / parseInt(this.state.workingDays) * 100).toFixed(2))} %
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
                <Row>
                  <Col md="6">
                    <Card
                      className='border-0'
                      style={{ borderRadius: '20px' }}>
                      <Card.Body>
                        <h6>{t('Present Summary')}</h6>
                        <Table size="sm">
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>{t('Date')}</th>
                              <th style={{ textAlign: 'center' }}>{t('Time-In')}</th>
                              <th style={{ textAlign: 'center' }}>{t('Time-Out')}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.students[0].childattendance
                              .filter(item => item.absent == 0 && item.class_id == this.state.students[0].class_id && (item.att_datetime_in != null || item.att_datetime_out != null))
                              .map((item, index) => (
                                <tr key={index}>
                                  <td>{index + 1}</td>
                                  <td>{moment(item.att_date).format('DD-MM-YYYY')} {checkRegistration(this.state.students[0], item.att_date)}</td>

                                  <td style={{ textAlign: 'center' }}>{!item.att_datetime_in ? '--' : moment(item.att_datetime_in).format('h:mm a')}</td>
                                  <td style={{ textAlign: 'center' }}>{!item.att_datetime_out ? '--' : moment(item.att_datetime_out).format('h:mm a')}</td>
                                </tr>
                              ))}
                          </tbody>
                        </Table>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col md={6}>
                    <Card
                      className='border-0'
                      style={{ borderRadius: '20px' }}>
                      <Card.Body>
                        <h6>{t('Absent Summary')}</h6>
                        <Table size="sm">
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>{t('Date')}</th>
                              <th style={{ textAlign: 'center' }}>{t('Absent')}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.students[0].childattendance
                              .filter(item => item.absent == 1)
                              .map((item, index) => (
                                <tr key={index}>
                                  <td>{index + 1}</td>
                                  <td>{moment(item.att_date).format('DD-MM-YYYY')} {checkRegistration(this.state.students[0], item.att_date)}</td>
                                  <td style={{ textAlign: 'center' }}>
                                    {item.absent == 1 &&
                                      <FontAwesomeIcon icon={faCheckSquare} style={{ fontSize: 16, color: '#e74c3c' }} />
                                    }
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </Table>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </div>
            }
          </div>
        </div>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  authData: state.auth.authData,
  selectedClass: state.selectedClass.data,
  defaultLanguage: state.language.defaultLanguage,
  defaultTheme: state.theme.defaultTheme,
});

export default connect(mapStateToProps, null)(StudentAttendanceReport);
