import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store, persistor } from './redux/store';
import { PersistGate } from 'redux-persist/integration/react';
import AppRoutes from './routes/routes';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import '/node_modules/flag-icons/css/flag-icons.min.css';
import './services/firebase';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'react-tooltip/dist/react-tooltip.css'
import './components/UserTimeline/user-timeline.css';
import './components/Admin/SchoolSetup/school-setup.css';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import 'flatpickr/dist/themes/material_green.css';
import './components/_partials/Chats/chats.css';
import './components/_partials/Header/header.css';
import './components/_partials/NavBar/navbar.css';
import './components/_partials/Paginate/paginate.css';
import './components/Activities/activities.css';
import './components/Calendar/calendar.css';
import './components/ClassBlog/chat-blog.css';
import './components/ClassView/classview.css';
import './components/CurriculumBuilder/curriculum-builder.css';
import './components/Dashboard/dashboard.css';
import './components/DownloadMedia/download-media.css';
import './components/Exams/exams.css';
import './components/Students/student-list.css';
import './components/Subjects/subjects.css';
import './components/Subjects/lessons.css';
import './components/WeeklyLessonPlan/weekly-lesson-plans.css';
import './components/Login/login.css';
import './assets/css/main.css';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <BrowserRouter>
                <AppRoutes />
                <ToastContainer hideProgressBar={true} theme='colored' />
                {/* <NavBar /> */}
            </BrowserRouter>
        </PersistGate>
    </Provider>
);
