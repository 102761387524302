import React, { Component } from 'react';
import { Container, Card, Row, Col, Button, Badge, Modal, Form, Spinner, ButtonGroup, DropdownButton, Dropdown } from 'react-bootstrap';
import { t } from '../../helpers/translation_helper';
import NavBar from '../_partials/NavBar/_NavBar';
import Header from '../_partials/Header/_Header';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBook, faEdit, faHomeUser, faListOl, faPencil, faPlus, faSave, faSearch, faTrashCan, faXmark } from '@fortawesome/free-solid-svg-icons';
import { setUpdateSchoolModal } from '../../redux/slices/updateSchoolModalSlice';
import axios from 'axios';
import { toast } from 'react-toastify';
import Select from 'react-select'
import GradeLevelItem from './GradeLevelItem';
import AddEditClass from '../_common/AddEditClass/_AddEditClass';
import AcademicBoard from '../CurriculumBuilder/AcademicBoard';
import imgDescription from '../../assets/images/description.png';
import { handleFormErrors, readFile } from '../../helpers/form_helpers';
import { Chance } from 'chance';
import { brand_colors } from '../../helpers/brand_colors_helper';
import { colorPalette } from '../../constants/colorsPalette';
import { Link, NavLink } from 'react-router-dom';
import { getExtension } from '../../helpers/general_helpers';
import { faThumbsDown, faThumbsUp } from '@fortawesome/free-regular-svg-icons';
import SweetAlert from 'react-bootstrap-sweetalert';
import GeneralCard from '../../helpers/GeneralCard';
import HorizontalCard from '../../helpers/HorizontalCard';
import moment from 'moment';
import { GET } from '../../api';
import { addNavigation } from '../../redux/slices/navigationSlice';

const chance = new Chance();

const apiURL = process.env.REACT_APP_API_URL;
const portalURL = process.env.REACT_APP_PORTAL_URL;


class GradesAndAcademic extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deleteModal: { show: false, id: 0, action: () => { }, toggle: (deleteModal) => this.setState({ deleteModal: deleteModal }) },
      modalMoveClassGrade: {
        show: false,
        toggle: (title = null, classData = null) => this.setState({ modalMoveClassGrade: { ...this.state.modalMoveClassGrade, show: !this.state.modalMoveClassGrade.show, title, classData, agegroup: (classData) ? { value: classData.agegroup_id, label: classData.agegroup_code } : null } }),
        title: 'Move to Grade',
        save: this.updateClassAgeGroup,
        saving: false,
        classData: null,
        agegroup: null,
        setAgeGroup: (agegroup) => this.setState({ modalMoveClassGrade: { ...this.state.modalMoveClassGrade, agegroup } })
      },
      academicBoard: {
        ab_id: null,
        toggleBoard: (ab_id) => {
          let newState = { academicBoard: { ...this.state.academicBoard, ab_id } }
          if ((ab_id == this.state.academicBoard.ab_id)) {
            newState = { academicBoard: { ...this.state.academicBoard, ab_id: null } }
          }
          this.setState(newState)
        }
      },
      academicBoardsData: {
        search: '',
        order: 'academic_boards.ab_id',
        dir: 'DESC',
        limit: 100,
        offset: 0,
        data: [],
        total_count: 0,
        total_count_filtered: 0,
        refreshing: false,
        finished: false,
      },
      modalAgeGroup: {
        show: false,
        toggle: (title = null, ageGroup = null) => this.setState({ modalAgeGroup: { ...this.state.modalAgeGroup, show: !this.state.modalAgeGroup.show, title, ageGroup } }),
        title: 'Grade',
        save: this.saveAgeGroup,
        saving: false,
        ageGroup: { agegroup_id: chance.unique(chance.integer, 1, { min: 99999999999, max: 999999999999 })[0], agegroup_code: '', agegroup_from: '', agegroup_to: '', agegroup_desc: '', agegroup_color: '#fff', ab_ids: [], open_agegroup_color: false, agegroup_type: { label: 'General', value: 'General' } }
      },
      agegroup_types: [
        { label: 'General', value: 'General' },
        { label: 'Primary Year Programme', value: 'Primary Year Programme' },
        { label: 'Middle Year Programme', value: 'Middle Year Programme' },
        { label: 'Diploma Programme', value: 'Diploma Programme' },
        { label: 'MOE', value: 'UAE MOE' },
      ],
      AgeGroupData: {
        openSearch: false,
        toggleSearch: () => this.setState({ AgeGroupData: { ...this.state.AgeGroupData, openSearch: !this.state.AgeGroupData.openSearch, search: '' } }, () => {
          if (!this.state.AgeGroupData.openSearch) {
            this.getAgeGroup()
          }
        }),
        changeSearch: (search = '') => this.setState({ AgeGroupData: { ...this.state.AgeGroupData, search } }, () => this.getAgeGroup()),
        search: '',
        orderName: 'Name ASC',
        order: 'agegroup.agegroup_code',
        dir: 'ASC',
        changeOrder: (orderName = 'Name ASC', order = 'agegroup.agegroup_code', dir = 'DESC') => this.setState({ AgeGroupData: { ...this.state.AgeGroupData, orderName, order, dir } }, () => this.getAgeGroup()),
        limit: 100,
        offset: 0,
        data: [],
        total_count: 0,
        total_count_filtered: 0,
        refreshing: false,
        finished: false,
      },

      academicSubjectsData: {
        openSearch: false,
        toggleSearch: () => this.setState({ academicSubjectsData: { ...this.state.academicSubjectsData, openSearch: !this.state.academicSubjectsData.openSearch } }),
        changeSearch: (search = '') => this.setState({ academicSubjectsData: { ...this.state.academicSubjectsData, search } }, () => this.getAcademicSubjects()),
        search: '',
        order: 'academic_subjects.as_id',
        dir: 'DESC',
        limit: 100,
        offset: 0,
        data: [],
        total_count: 0,
        total_count_filtered: 0,
        refreshing: false,
        finished: false,
        as_id: null,
        toggle: (as_id) => this.setState({ academicSubject: { ...this.state.academicSubject, as_id: (as_id == this.state.academicSubject.as_id) ? null : as_id } })
      },
      as_image: null,
      modalAcademicSubjects: {
        show: false,
        toggle: (ab_id) => this.setState({ modalAcademicSubjects: { ...this.state.modalAcademicSubjects, show: !this.state.modalAcademicSubjects.show, ab_id } }, () => { if (this.state.modalAcademicSubjects.show) { this.getAcademicSubjects() } }),
        title: 'Subject',
        save: this.saveAcademicSubject,
        saving: false,
        ab_id: 0,
        academic_subject: { as_id: chance.unique(chance.integer, 1, { min: 99999999999, max: 999999999999 })[0], ab_id: 0, as_name: '', as_desc: '', as_image: null, as_image_delete: false, as_color: '#fff', as_status: 1, edit: false, open_as_color: false }
      },
      dragAreaColor: {
        as_image: brand_colors[this.props.defaultTheme.theme_id].color18,
        toggleColor: (field, value) => this.setState({ dragAreaColor: { ...this.state.dragAreaColor, [field]: value } })
      },
      clssmainitem_grp: [],
    }
    this.as_image_ref = React.createRef();
  }

  componentDidMount() {
    this.getBoards()
    this.props.addNavigation({
      id: 'grades-academic',
      name: 'Grades & Academic',
      path: '/grades-academic',
    })
  }

  getBoards = async () => {
    let academicBoardsData = this.state.academicBoardsData
    if (academicBoardsData.refreshing) {
      return false
    }
    this.setState({
      academicBoardsData: {
        ...this.state.academicBoardsData,
        refreshing: true
      }
    })

    try {
      const res = await GET('academic-boards/get',
        {
          params: {
            search: this.state.academicBoardsData.search,
            order: this.state.academicBoardsData.order,
            dir: this.state.academicBoardsData.dir,
            limit: this.state.academicBoardsData.limit,
            offset: this.state.academicBoardsData.offset,
          },
        }
      );
      this.setState({
        academicBoardsData: {
          ...this.state.academicBoardsData,
          data: res.data.data,
          total_count: res.data.total_count,
          total_count_filtered: res.data.total_count_filtered,
          refreshing: false,
          finished: true
        }
      }, () => this.getGradeCycles());
    } catch (err) {
      console.log('err', err)
      toast.error(t('Something went wrong while fetching data!'));

    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.academicBoard.ab_id !== this.state.academicBoard.ab_id) {
      console.log('this.state.academicBoard.ab_id', this.state.academicBoard.ab_id);
      this.setState({
        AgeGroupData: {
          ...this.state.AgeGroupData,
          data: [],
          total_count: 0,
          total_count_filtered: 0,
          refreshing: false,
          finished: false,
          offset: 0
        }
      }, () => this.getAgeGroup());

    }
  }

  getAcademicSubjects = async () => {
    if (!this.state.academicBoard.ab_id) {
      return
    }
    if (this.getAcademicSubjectsReq) {
      this.getAcademicSubjectsReq.abort();
    }
    this.getAcademicSubjectsReq = new AbortController();

    this.setState({
      academicSubjectsData: {
        ...this.state.academicSubjectsData,
        refreshing: true
      }
    })

    try {
      const res = await GET('academic-subjects/get',
        {
          params: {
            ab_id: this.state.modalAcademicSubjects.ab_id,
            search: this.state.academicSubjectsData.search,
            order: this.state.academicSubjectsData.order,
            dir: this.state.academicSubjectsData.dir,
            limit: this.state.academicSubjectsData.limit,
            offset: this.state.academicSubjectsData.offset,
          },
          signal: this.getAcademicSubjectsReq.signal,
        }
      );
      this.setState({
        academicSubjectsData: {
          ...this.state.academicSubjectsData,
          data: res.data.data,
          total_count: res.data.total_count,
          total_count_filtered: res.data.total_count_filtered,
          refreshing: false,
          finished: true
        }
      });
    } catch (err) {
      console.log('err', err)
      if (axios.isCancel(err)) {

      } else {
        toast.error(t('Something went wrong while fetching data!'));

      }
    }
  };
  getAgeGroup = async () => {
    if (!this.state.academicBoard.ab_id) {
      return
    }
    if (this.getAgeGroupReq) {
      this.getAgeGroupReq.abort();
    }
    this.getAgeGroupReq = new AbortController();

    this.setState({
      AgeGroupData: {
        ...this.state.AgeGroupData,
        refreshing: true
      }
    })

    try {
      const res = await GET('age-group/get',
        {
          params: {
            ab_id: this.state.academicBoard.ab_id,
            search: this.state.AgeGroupData.search,
            order: this.state.AgeGroupData.order,
            dir: this.state.AgeGroupData.dir,
            limit: this.state.AgeGroupData.limit,
            offset: this.state.AgeGroupData.offset,
          },
          signal: this.getAgeGroupReq.signal,
        }
      );

      const clssmainitem_grp = [];
      res.data.data.map((v, i) => {
        clssmainitem_grp[v.agegroup_id] = { show: false, target: 'class', index: v.agegroup_id, classRefreshTime: moment() };
      })
      let data = res.data.data
      data = data.map((v, i) => {
        v.ab_ids = JSON.parse(v.ab_ids).map((vv) => {
          let findAb = this.state.academicBoardsData.data.find((vvv) => vvv.ab_id == vv)
          return { label: findAb.ab_name, value: findAb.ab_id }
        })
        return v
      })

      this.setState({
        AgeGroupData: {
          ...this.state.AgeGroupData,
          data: data,
          total_count: res.data.total_count,
          total_count_filtered: res.data.total_count_filtered,
          refreshing: false,
          finished: true
        },
        clssmainitem_grp
      });
    } catch (err) {
      console.log('err', err)
      if (axios.isCancel(err)) {

      } else {
        toast.error(t('Something went wrong while fetching data!'));

      }
    }
  };

  getGradeCycles = async () => {

    const { authData } = this.props;
    const { auth_key } = authData.loginData;

    try {
      const res = await GET('classmain/get-grade-cycles');

      if (res.status == 200) {
        const gradecycle = res.data.map((cycle) => {
          cycle.label = cycle.gcycle_desc;
          cycle.value = cycle.gcycle_id;
          return cycle;
        });

        this.setState({ gradecycle });

      }

    } catch (err) {
      console.log('err', err)

      toast.error('Something went wrong');
    }
  }


  updateClassAgeGroup = async () => {

    if (this.updateClassAgeGroupReq) {
      this.updateClassAgeGroupReq.abort();
    }
    this.updateClassAgeGroupReq = new AbortController();
    let modalMoveClassGrade = this.state.modalMoveClassGrade
    if (modalMoveClassGrade.classData.agegroup_id == modalMoveClassGrade.agegroup.value) {
      toast.warning('Grade not changed, Please select a grade to move Class to that Grade.');
      return
    }

    this.setState({
      modalMoveClassGrade: {
        ...this.state.modalMoveClassGrade,
        saving: true
      }
    })
    const res = await GET('classes/update-age-group', {
      params: {
        class_id: modalMoveClassGrade.classData.class_id,
        agegroup_id: modalMoveClassGrade.agegroup.value,
      },
      signal: this.updateClassAgeGroupReq.signal,
    });

    if (res) {
      console.log('res', res);
      this.setState({
        modalMoveClassGrade: {
          ...this.state.modalMoveClassGrade,
          saving: false,
          show: false
        }
      }, () => {
        this.toggleClassmainitemGroup(modalMoveClassGrade.agegroup.value, true, 'class')
        this.toggleClassmainitemGroup(modalMoveClassGrade.classData.agegroup_id, true, 'class')
      })
    }
  }


  saveAgeGroup = async () => {
    this.setState({ modalAgeGroup: { ...this.state.modalAgeGroup, saving: true } });
    const { auth_key, user_id, full_name, role_id } =
      this.props.authData.loginData;
    const { center_id, center_timezone } = this.props.authData.centerData;
    let modalAgeGroup = JSON.parse(JSON.stringify(this.state.modalAgeGroup));

    if (modalAgeGroup.ageGroup.gcycle_id) {
      modalAgeGroup.ageGroup.gcycle_id = modalAgeGroup.ageGroup.gcycle_id.gcycle_id
    }

    if (modalAgeGroup.ageGroup.ab_ids) {
      modalAgeGroup.ageGroup.ab_ids = modalAgeGroup.ageGroup.ab_ids.map((v, i) => parseInt(v.value))
    }

    if (modalAgeGroup.ageGroup.agegroup_type) {
      modalAgeGroup.ageGroup.agegroup_type = modalAgeGroup.ageGroup.agegroup_type.value
    }



    const fData = new FormData();
    fData.append('user_id', user_id);
    fData.append('center_id', center_id);
    fData.append('center_timezone', center_timezone);
    fData.append('AgeGroupData', JSON.stringify(modalAgeGroup.ageGroup));

    try {
      const res = await axios.post(
        apiURL + 'age-group/save-single',
        fData,
        {
          params: {
            auth_key: auth_key,
            appname: process.env.REACT_APP_NAME,
          },
        }
      );


      if (!res.data.type) {
        if (res.data.data != null) {
          handleFormErrors(res.data.data);
        } else {
          toast.error(t(res.data.message));
        }
      } else {
        this.setState({
          modalAgeGroup: {
            ...this.state.modalAgeGroup,
            saving: false,
            show: false
          },
          AgeGroupData: {
            ...this.state.AgeGroupData,
            data: [],
            total_count: 0,
            total_count_filtered: 0,
            refreshing: false,
            finished: false,
            offset: 0
          }
        }, () => this.getAgeGroup());
        toast.success(t(res.data.message));
      }
    } catch (err) {
      console.log('err', err)
      toast.error(t('Something went wrong while sending message!'));

    }
  };

  saveAcademicSubject = async () => {
    this.setState({ modalAcademicSubjects: { ...this.state.modalAcademicSubjects, saving: true } });
    const { auth_key, user_id, full_name, role_id } =
      this.props.authData.loginData;
    const { center_id, center_timezone } = this.props.authData.centerData;
    let modalAcademicSubjects = JSON.parse(JSON.stringify(this.state.modalAcademicSubjects));
    let as_image = this.state.as_image
    const fData = new FormData();
    fData.append('user_id', user_id);
    fData.append('center_id', center_id);
    fData.append('center_timezone', center_timezone);
    fData.append('AcademicSubjectData', JSON.stringify(modalAcademicSubjects.academic_subject));
    if (as_image) {
      fData.append('as_image', as_image.data)
    }
    try {
      const res = await axios.post(
        apiURL + 'academic-subjects/save',
        fData,
        {
          params: {
            auth_key: auth_key,
            appname: process.env.REACT_APP_NAME,
          },
        }
      );


      if (!res.data.type) {
        if (res.data.data != null) {
          handleFormErrors(res.data.data);
        } else {
          toast.error(t('Something went wrong while saving!'));
        }
        this.setState({
          modalAcademicSubjects: {
            ...this.state.modalAcademicSubjects,
            saving: false,
          }
        })
      } else {
        this.setState({
          modalAcademicSubjects: {
            ...this.state.modalAcademicSubjects,
            saving: false,
            edit: false,
            academic_subject: { as_id: chance.unique(chance.integer, 1, { min: 99999999999, max: 999999999999 })[0], ab_id: 0, as_name: '', as_desc: '', as_image: null, as_image_delete: false, as_color: '#fff', as_status: 1, open_as_color: false }
          },
          as_image: null,
          academicSubjectsData: {
            ...this.state.academicSubjectsData,
            data: [],
            total_count: 0,
            total_count_filtered: 0,
            refreshing: false,
            finished: false,
            offset: 0
          }
        }, () => this.getAcademicSubjects());
        toast.success(t(res.data.message));
      }
    } catch (err) {
      console.log('err', err)
      toast.error(t('Something went wrong while sending message!'));

    }
  };

  toggleStatusAcademicSubject = async (as_id) => {
    const { auth_key, user_id, full_name, role_id } =
      this.props.authData.loginData;
    const { center_id, center_timezone } = this.props.authData.centerData;
    const fData = new FormData();
    fData.append('center_id', center_id);
    fData.append('as_id', as_id);
    try {
      const res = await axios.post(
        apiURL + 'academic-subjects/toggle-status',
        fData,
        {
          params: {
            auth_key: auth_key,
            appname: process.env.REACT_APP_NAME,
          },
        }
      );

      if (!res.data.status) {
        toast.error(t('Something went wrong while updating!'));
      } else {
        let academicSubjectsData = this.state.academicSubjectsData
        academicSubjectsData.data = academicSubjectsData.data.map((v, i) => {
          if (v.as_id == as_id) {
            return { ...v, as_status: (v.as_status == 1) ? 0 : 1 }
          }
          return v
        })
        this.setState({ academicSubjectsData })
        toast.warning(t('Status updated'));
      }
    } catch (err) {
      console.log('err', err)
      toast.error(t('Something went wrong while updating!'));

      this.setState({ msgSending: false });
    }
  };

  deleteAcademicSubject = (as_id) => {

    this.setState({
      deleteModal: {
        ...this.state.deleteModal, show: true, action: () => {
          // DELETE FILE CALL 
          try {
            GET('academic-subjects/delete', {
              params: {
                as_id: as_id,
              },
            }).then((res) => {

              if (res.data.type) {
                let academicSubjectsData = this.state.academicSubjectsData
                academicSubjectsData.data = academicSubjectsData.data.filter((v, i) => {
                  if (v.as_id != as_id) {
                    return true
                  }
                  return false
                })
                this.setState({
                  academicSubjectsData, deleteModal: {
                    ...this.state.deleteModal, show: false
                  }
                })
                toast.warning(t(res.data.message));
              } else {
                this.setState({
                  deleteModal: {
                    ...this.state.deleteModal, show: false
                  }
                })
                toast.error(t(res.data.message));
              }
            })
          } catch (err) {
            console.log('err', err)
            toast.error(t('Something went wrong while deleting!'));

          }
        }
      }
    })
  }

  toggleClassmainitemGroup = (index, show = false, target = null) => {
    const clssmainitem_grp = this.state.clssmainitem_grp;
    clssmainitem_grp[index] = { show, target, index, classRefreshTime: moment() };
    this.setState({ clssmainitem_grp });
  }

  setStateAgeGroup = (field, value) => {
    let modalAgeGroup = this.state.modalAgeGroup
    modalAgeGroup.ageGroup = { ...modalAgeGroup.ageGroup, [field]: value, edit: true }
    this.setState({ modalAgeGroup })
  }

  setStateAcademicSubject = (field, value) => {
    let modalAcademicSubjects = this.state.modalAcademicSubjects
    let close_as_color = {}
    if (field == 'as_color') {
      close_as_color = { open_as_color: false }
    }
    modalAcademicSubjects.edit = true
    modalAcademicSubjects.academic_subject = { ...modalAcademicSubjects.academic_subject, [field]: value, ...close_as_color }
    this.setState({ modalAcademicSubjects })
  }

  handleDragFileArea = (e, name) => {
    e.preventDefault();
    e.stopPropagation();

    if (e.type === "dragover") {
      this.state.dragAreaColor.toggleColor(name, brand_colors[this.props.defaultTheme.theme_id].color1)
    } else if (e.type === "dragleave") {
      this.state.dragAreaColor.toggleColor(name, brand_colors[this.props.defaultTheme.theme_id].color18)
    }
  }
  addAttachment = (e) => {
    const selectedFiles = e.target.files;
    let allFiles = [];
    for (let i = 0; i < selectedFiles.length; i++) {
      allFiles.push(readFile(selectedFiles[i]));
    }
    Promise.all(allFiles).then((values) => {
      let attachments = e.target.multiple ? this.state[e.target.name] : null;
      let selectedFiles = values;
      selectedFiles.map((v, i) => {
        if (v.data.type) {
          if (e.target.multiple) {
            attachments.push({ url: v.url, data: v.data });
          } else {
            attachments = { url: v.url, data: v.data };
          }
        }
      });
      this.setState({ [e.target.name]: attachments, dragAreaColor: { ...this.state.dragAreaColor, [e.target.name]: brand_colors[this.props.defaultTheme.theme_id].color18 } });
    });
  };

  render() {
    const { defaultTheme } = this.props
    const { academicBoard, academicBoardsData, modalAgeGroup, modalAcademicSubjects, AgeGroupData, academicSubjectsData, as_image, deleteModal, modalMoveClassGrade } = this.state
    console.log('this.state', this.state);
    return (
      <Container fluid>
        <div id='iedu-layout'>
          <NavBar />
          <div id='page-content' style={{ width: '100%' }}>
            <Header backBtn={true} lite={true} heading={t('Grades & Academic')} />
            <div className='grey-section'>
              <Row>
                {/* ACADEMIC BOARD */}
                {academicBoardsData.data.length > 0 &&
                  <Col md={12} style={{ marginBottom: 20 }}>
                    <AcademicBoard academicBoard={academicBoard} />
                  </Col>
                }
              </Row>

              <Row>
                {/* GRADES */}
                <div className='grey-section-header mt-2 flex-row align-items-center'>
                  <h5 className='mb-0'>
                    <span>{t('Grade')}</span>
                    <span className='mx-1' style={{ opacity: 0.6, fontWeight: 'normal' }}>|</span>
                    <span className='mx-1' style={{ opacity: 0.8, fontSize: '1rem', fontWeight: 'normal' }}>{t('Please click Class or Subject to open the list')}</span>

                    <ButtonGroup>
                      <div className='cb-expandable-search' style={{ width: AgeGroupData.openSearch ? 178 : 32, borderRadius: 0 }}>
                        <input
                          ref={this.searchFieldRef}
                          onChange={(e) => AgeGroupData.changeSearch(e.target.value)}
                          value={AgeGroupData.search}
                          type='text'
                          style={{ width: AgeGroupData.openSearch ? 150 : 0, paddingLeft: AgeGroupData.openSearch ? 15 : 0, opacity: AgeGroupData.openSearch ? 1 : 0, backgroundColor: brand_colors[defaultTheme.theme_id].color8, fontSize: 14 }}
                          placeholder={t('Search')} />
                        <Button
                          style={{ border: 'inherit', }}
                          size='sm'
                          title={t('Search')}
                          variant={(AgeGroupData.search) ? 'danger' : 'primary'}
                          onClick={() => AgeGroupData.toggleSearch()}>
                          <FontAwesomeIcon
                            icon={AgeGroupData.openSearch ? faXmark : faSearch}
                            color={brand_colors[defaultTheme.theme_id].color8}
                          />
                        </Button>
                      </div>
                      <DropdownButton size='sm' as={ButtonGroup} title={'ORDER BY: ' + AgeGroupData.orderName} id="bg-nested-dropdown">
                        <Dropdown.Item onClick={() => AgeGroupData.changeOrder('Name ASC', 'agegroup.agegroup_code', 'ASC')}>Name ASC</Dropdown.Item>
                        <Dropdown.Item onClick={() => AgeGroupData.changeOrder('Name DESC', 'agegroup.agegroup_code', 'DESC')}>Name DESC</Dropdown.Item>
                        <Dropdown.Item onClick={() => AgeGroupData.changeOrder('Cycle ASC', 'agegroup.gcycle_id', 'ASC')}>Cycle ASC</Dropdown.Item>
                        <Dropdown.Item onClick={() => AgeGroupData.changeOrder('Cycle DESC', 'agegroup.gcycle_id', 'DESC')}>Cycle DESC</Dropdown.Item>
                      </DropdownButton>
                    </ButtonGroup>
                  </h5>
                  <div className='d-flex flex-row align-items-center'>
                    <Button variant="success" className='btn-sm btn-add me-2' onClick={() => {
                      if (academicBoard.ab_id) {
                        let findAb = academicBoardsData.data.find((v, i) => v.ab_id == academicBoard.ab_id)
                        modalAgeGroup.toggle('New Grade', { agegroup_id: chance.unique(chance.integer, 1, { min: 99999999999, max: 999999999999 })[0], agegroup_code: '', agegroup_from: '', agegroup_to: '', agegroup_desc: '', agegroup_color: '#000', ab_ids: [{ value: academicBoard.ab_id, label: findAb.ab_name }], open_agegroup_color: false, agegroup_type: { label: 'General', value: 'General' }, gcycle_id: (this.state.gradecycle.length > 0) ? { ...this.state.gradecycle[0], value: this.state.gradecycle[0].gcycle_id, label: this.state.gradecycle[0].gcycle_desc } : null })
                      } else {
                        toast.warning(t('Please select a Curriculum first!'))
                      }
                    }}>
                      <FontAwesomeIcon icon={faPlus} style={{ marginRight: 7 }} />
                      {t('Grade')}
                    </Button>
                    <Button variant="warning" className='btn-sm btn-add' onClick={() => (academicBoard.ab_id)
                      ? modalAcademicSubjects.toggle(academicBoard.ab_id)
                      : toast.warning(t('Please select a Curriculum first!'))}>
                      <FontAwesomeIcon icon={faListOl} style={{ marginRight: 7 }} />
                      {t('Subject')}
                    </Button>
                  </div>
                </div>
              </Row>

              {academicBoardsData.data.length > 0 && AgeGroupData.data.map((agegroup, index) => {
                let gcycle_id = null
                let agegroup_type = { label: 'General', value: 'General' }
                if (this.state.gradecycle) {
                  gcycle_id = this.state.gradecycle.find((v, i) => v.gcycle_id == agegroup.gcycle_id)
                }
                if (agegroup.agegroup_type) {
                  agegroup_type = this.state.agegroup_types.find((v, i) => v.value == agegroup.agegroup_type)
                }
                let data = { ...agegroup, gcycle_id, agegroup_type }
                return <GradeLevelItem key={index} index={agegroup.agegroup_id} last={(index == (AgeGroupData.data.length - 1)) ? true : false} data={data} modalAgeGroup={modalAgeGroup} clssmainitem_grp={this.state.clssmainitem_grp[agegroup.agegroup_id]} toggleClassmainitemGroup={this.toggleClassmainitemGroup} deleteModal={deleteModal} modalMoveClassGrade={modalMoveClassGrade} />
              })}

            </div>
          </div>
        </div>


        {/* CLASS ADD/EDIT */}
        <AddEditClass hideShowClasses={this.toggleClassmainitemGroup} />

        {/* MODAL MOVE CLASS GRADE */}
        <Modal show={modalMoveClassGrade.show} onHide={() => modalMoveClassGrade.toggle()} size="sm" backdrop="static"
        keyboard={false}>
          <Modal.Header closeButton>
            <Modal.Title>{modalMoveClassGrade.title}</Modal.Title>
          </Modal.Header>
          {modalMoveClassGrade.show &&
            <Modal.Body className='custom-styled-form'>
              {modalMoveClassGrade.show &&
                <Form.Group className='mx-1'>
                  <Form.Label>
                    <img src={imgDescription} />
                    {t('Cycle')}
                  </Form.Label>
                  <Select value={modalMoveClassGrade.agegroup} options={this.state.AgeGroupData.data.map((v, i) => {
                    return { label: v.agegroup_code, value: v.agegroup_id }
                  })} onChange={(obj) => modalMoveClassGrade.setAgeGroup(obj)} />
                </Form.Group>
              }
            </Modal.Body>
          }
          <Modal.Footer>
            <Button variant="secondary" onClick={() => modalMoveClassGrade.toggle()}>
              Close
            </Button>
            <Button variant="primary" onClick={modalMoveClassGrade.save}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>

        {/* MODAL GRADES */}
        <Modal show={modalAgeGroup.show} onHide={() => modalAgeGroup.toggle()} size="lg" backdrop="static"
        keyboard={false}>
          <Modal.Header closeButton>
            <Modal.Title>{modalAgeGroup.title}</Modal.Title>
          </Modal.Header>
          {modalAgeGroup.show &&
            <Modal.Body className='custom-styled-form'>
              {modalAgeGroup.show &&
                <div className='d-flex flex-row justify-content-between'>
                  <div style={{ flex: 1 }}>
                    <Form.Group className='mx-1'>
                      <Form.Label>
                        <img src={imgDescription} />
                        {t('Code')}
                      </Form.Label>
                      <Form.Control type='text' defaultValue={modalAgeGroup.ageGroup.agegroup_code} onBlur={(event) => this.setStateAgeGroup('agegroup_code', event.target.value)} />
                    </Form.Group>
                    <Form.Group className='mx-1'>
                      <Form.Label>
                        <img src={imgDescription} />
                        {t('Cycle')}
                      </Form.Label>
                      <Select value={modalAgeGroup.ageGroup.gcycle_id} options={this.state.gradecycle} onChange={(obj) => this.setStateAgeGroup('gcycle_id', obj)} />
                    </Form.Group>
                    <Form.Group className='mx-1'>
                      <Form.Label>
                        <img src={imgDescription} />
                        {t('Type')}
                      </Form.Label>
                      <Select value={modalAgeGroup.ageGroup.agegroup_type} options={this.state.agegroup_types} onChange={(obj) => this.setStateAgeGroup('agegroup_type', obj)} />
                    </Form.Group>
                    <Form.Group className='mx-1'>
                      <Form.Label className='no-style'>{t('Color')}</Form.Label>
                      <div className='selected-color' onClick={() => this.setStateAgeGroup('open_agegroup_color', true)}>
                        <div style={{ backgroundColor: modalAgeGroup.ageGroup.agegroup_color }}></div>
                      </div>
                      {modalAgeGroup.ageGroup.open_agegroup_color &&
                        <div className='color-palette'>
                          {colorPalette.map((color, color_i) => (
                            <div
                              key={color_i}
                              onClick={() => {
                                modalAgeGroup.ageGroup = { ...modalAgeGroup.ageGroup, agegroup_color: color.code, open_agegroup_color: false }
                                this.setState({ modalAgeGroup })
                              }}
                              style={{ backgroundColor: color.code }}
                            ></div>
                          ))}
                        </div>
                      }
                    </Form.Group>
                  </div>
                  <div style={{ flex: 2 }}>
                    <Form.Group className='mx-1'>
                      <Form.Label>
                        <img src={imgDescription} />
                        {t('Desc')}
                      </Form.Label>
                      <Form.Control as="textarea" rows={6} defaultValue={modalAgeGroup.ageGroup.agegroup_desc} onBlur={(event) => this.setStateAgeGroup('agegroup_desc', event.target.value)} />
                    </Form.Group>
                    <Form.Group className='mx-1'>
                      <Form.Label>
                        <img src={imgDescription} />
                        {t('Curriculums')}
                      </Form.Label>
                      <Select isMulti value={modalAgeGroup.ageGroup.ab_ids} options={academicBoardsData.data.map((v) => { return { label: v.ab_name, value: v.ab_id } })} onChange={(obj) => this.setStateAgeGroup('ab_ids', obj)} />
                    </Form.Group>
                  </div>
                </div>
              }
            </Modal.Body>
          }
          <Modal.Footer>
            <Button variant="secondary" onClick={() => modalAgeGroup.toggle()}>
              Close
            </Button>
            <Button variant="primary" onClick={modalAgeGroup.save}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>

        {/* MODAL ACADEMIC SUBJECTS */}
        <Modal show={modalAcademicSubjects.show} onHide={() => modalAcademicSubjects.toggle()} size="lg" backdrop="static"
        keyboard={false}>
          <Modal.Header closeButton>
            <Modal.Title>{modalAcademicSubjects.title}</Modal.Title>
          </Modal.Header>
          {modalAcademicSubjects.show &&
            <Modal.Body className='custom-styled-form'>
              {/* ADD EDIT */}
              {modalAcademicSubjects.edit &&
                <div className='custom-styled-form my-2' style={{ borderBottom: '#b7b7b7 1px solid' }}>
                  <div className='d-flex flex-row align-items-center'>
                  </div>
                  <Row>
                    <Col md="6">
                      <Row>
                        <Col md="12">
                          <Form.Group className='mb-3'>
                            <Form.Label>
                              <img src={imgDescription} />
                              {t('Curriculum')}
                            </Form.Label>
                            <Form.Control type='text' defaultValue={modalAcademicSubjects.academic_subject.as_name} onBlur={(event) => this.setStateAcademicSubject('as_name', event.target.value)} />
                          </Form.Group>
                        </Col>
                        <Col md="12">
                          <Form.Group className='mb-3'>
                            <Form.Label>
                              <img src={imgDescription} />
                              {t('Description')}
                            </Form.Label>
                            < Form.Control as="textarea" rows={3} defaultValue={modalAcademicSubjects.academic_subject.as_desc} onBlur={(event) => this.setStateAcademicSubject('as_desc', event.target.value)} />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col md="4" className='text-center'>
                          <Form.Group className="mb-3">
                            <Form.Label className='no-style'>{t('Color')}</Form.Label>
                            <div className='selected-color' onClick={() => {
                              this.setStateAcademicSubject('open_as_color', true)
                            }}>
                              <div style={{ backgroundColor: modalAcademicSubjects.academic_subject.as_color }}></div>
                            </div>
                            {modalAcademicSubjects.academic_subject.open_as_color &&
                              <div className='color-palette'>
                                {colorPalette.map((color, color_i) => (
                                  <div
                                    key={color_i}
                                    onClick={() => {
                                      this.setStateAcademicSubject('as_color', color.code)
                                    }}
                                    style={{ backgroundColor: color.code }}
                                  ></div>
                                ))}
                              </div>
                            }
                          </Form.Group>
                        </Col>
                        <Col md="8" className='text-center'>
                          <Form.Label className='no-style'>
                            {t('Status')}
                          </Form.Label>
                          <Form.Check
                            type="switch"
                            style={{}}
                            value={1}
                            checked={(modalAcademicSubjects.academic_subject.as_status == 0) ? false : true}
                            onChange={(event) => this.setStateAcademicSubject('as_status', (modalAcademicSubjects.academic_subject.as_status == 1) ? 0 : 1)}
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col md="6">
                      <div style={{ flexDirection: 'column' }} className='pic-prev d-flex justify-content-center mb-3'>
                        <div style={{ height: 175, padding: 10, overflow: 'hidden', borderStyle: 'dashed', borderColor: this.state.dragAreaColor.as_image, borderWidth: 2, borderRadius: 20, position: 'relative' }} className='d-flex justify-content-center align-items-center'
                          onDragEnter={(e) => this.handleDragFileArea(e, 'as_image')} onDragLeave={(e) => this.handleDragFileArea(e, 'as_image')} onDragOver={(e) => this.handleDragFileArea(e, 'as_image')} onDrop={(e) => {

                            e.preventDefault();
                            e.stopPropagation();
                            if (e.dataTransfer.files && e.dataTransfer.files[0]) {
                              this.addAttachment({ target: { files: e.dataTransfer.files, multiple: false, name: 'as_image' } })
                            }
                          }}>
                          {as_image && (!as_image.data.type.includes('pdf')) && (
                            <img src={as_image.url} alt={t('Preview')} style={{ height: '100%' }} />
                          )}
                          {as_image == null &&
                            <button onClick={() => this.as_image_ref.current.click()} style={{ background: 'none', border: 0, flex: 1 }}>
                              {/* <FontAwesomeIcon icon={faPlus} style={{ fontSize: 30, color: color18 }} /> */}
                              <span style={{ fontSize: 20, fontWeight: 'bold', color: brand_colors[this.props.defaultTheme.theme_id].color18 }}>{t('Image')}</span>
                            </button>
                          }

                          {as_image && (
                            <FontAwesomeIcon
                              icon={faTrashCan}
                              style={{ fontSize: 14, color: brand_colors[this.props.defaultTheme.theme_id].color11, position: 'absolute', bottom: 6, right: 6, cursor: 'pointer', backgroundColor: brand_colors[this.props.defaultTheme.theme_id].color8, borderRadius: 100, padding: 3 }}
                              onClick={() => this.setState({ as_image: null, modalAcademicSubjects: { ...modalAcademicSubjects, academic_subject: { ...modalAcademicSubjects.academic_subject, as_image_delete: true, as_image: null } } })}
                            />
                          )}
                        </div>
                        <Form.Control className='d-none' ref={this.as_image_ref} type='file' onChange={this.addAttachment} name='as_image' />
                      </div>
                    </Col>
                  </Row>
                </div>
              }
              {/* LIST */}
              <div className='grey-section-header mt-2 flex-row align-items-center justify-content-between'>
                <div className='d-flex flex-row align-items-center'>
                  <div className='cb-expandable-search mx-1' style={{ width: academicSubjectsData.openSearch ? 180 : 35 }}>
                    <input
                      ref={this.searchFieldRef}
                      onChange={(e) => academicSubjectsData.changeSearch(e.target.value)}
                      value={academicSubjectsData.search}
                      type='text'
                      style={{ width: academicSubjectsData.openSearch ? 150 : 0, paddingLeft: academicSubjectsData.openSearch ? 15 : 0, opacity: academicSubjectsData.openSearch ? 1 : 0, backgroundColor: brand_colors[defaultTheme.theme_id].color7 }}
                      placeholder={t('Search ')} />
                    <Button
                      data-tooltip-id="tooltip"
                      data-tooltip-content={t('Search')}
                      data-tooltip-place="top"
                      title={t('Search')}
                      variant={(academicSubjectsData.search) ? 'danger' : 'primary'}
                      onClick={() => academicSubjectsData.toggleSearch()}>
                      <FontAwesomeIcon
                        icon={academicSubjectsData.openSearch ? faXmark : faSearch}
                        color={brand_colors[defaultTheme.theme_id].color8}
                      />
                    </Button>
                  </div>
                </div>
                <div className='d-flex flex-row align-items-center'>
                  {!modalAcademicSubjects.edit &&
                    <Button
                      onClick={() => this.setState({
                        modalAcademicSubjects: {
                          ...modalAcademicSubjects, academic_subject:
                            { as_id: chance.unique(chance.integer, 1, { min: 99999999999, max: 999999999999 })[0], ab_id: modalAcademicSubjects.ab_id, as_name: '', as_desc: '', as_image: null, as_image_delete: false, as_color: '#fff', as_status: 1, open_as_color: false }, edit: false,
                        }
                      }, () => this.setState({ modalAcademicSubjects: { ...this.state.modalAcademicSubjects, edit: true } }))}
                      className='btn-sm btn-add'
                      variant='success'>
                      {t('Add Subject')}{' '}
                      <FontAwesomeIcon
                        icon={faPlus}
                        color={brand_colors[defaultTheme.theme_id].color8}
                        style={{
                          fontSize: 16,
                        }}
                      />
                    </Button>
                  }
                  {modalAcademicSubjects.edit &&
                    <Button variant="primary" className='btn-sm btn-add' onClick={modalAcademicSubjects.save} disabled={modalAcademicSubjects.saving}>
                      {t('Save Changes')}{' '}
                      <FontAwesomeIcon
                        icon={faSave}
                        color={brand_colors[defaultTheme.theme_id].color8}
                        style={{
                          fontSize: 16,
                        }}
                      />
                    </Button>
                  }
                </div>
              </div>
              <div>
                {/* LIST */}
                {academicSubjectsData.data.map((v, i) => {
                  return <div key={v.agegroup_id} className='scroll-list-card' style={{ backgroundColor: brand_colors[defaultTheme.theme_id].color7 }}>
                    <div className='d-flex flex-row justify-content-between align-items-center p-0 m-0' style={{ flex: 1 }}>
                      <div className='cd-div-actionbtn' style={{ width: 100 }}>
                        <div className='d-flex flex-row align-items-center all-btns' style={{ opacity: 1 }} >
                          {/* EDIT */}
                          <FontAwesomeIcon icon={faEdit} color={brand_colors[defaultTheme.theme_id].color16} className='cursor-pointer ms-3'
                            onClick={() => this.setState({ modalAcademicSubjects: { ...modalAcademicSubjects, edit: false, academic_subject: { ...v, open_as_color: false, as_image_delete: false } }, as_image: (v.as_image) ? { url: portalURL + v.center_id + '/academic_subjects/' + v.as_image, data: { type: getExtension(v.as_image) } } : null }, () => this.setState({ modalAcademicSubjects: { ...this.state.modalAcademicSubjects, edit: true, } }))} title={t('Edit')} />
                          {/* DELETE */}
                          <FontAwesomeIcon icon={faTrashCan} color={brand_colors[defaultTheme.theme_id].color11} className='cursor-pointer ms-3' onClick={() => this.deleteAcademicSubject(v.as_id)} title={t('Delete')} />
                          {/* STATUS */}
                          <FontAwesomeIcon icon={(v.as_status == 1) ? faThumbsDown : faThumbsUp} color={(v.as_status == 1) ? brand_colors[defaultTheme.theme_id].color12 : brand_colors[defaultTheme.theme_id].color11} className='cursor-pointer ms-3' title={(v.as_status == 1) ? t('DeActivate') : t('Activate')}
                            onClick={() => this.toggleStatusAcademicSubject(v.as_id)} />
                        </div>
                      </div>
                      <div style={{ flex: 1 }}>
                        <div style={{ color: v.as_color }}>{v.as_name}</div>
                      </div>
                    </div>

                  </div>
                })}
                <div>
                  {academicSubjectsData.refreshing && !academicSubjectsData.finished &&
                    <div className='d-flex mt-2 align-items-center justify-content-center'>
                      <Spinner animation='grow' variant='dark' />
                    </div>
                  }
                  {/* {!academicSubjectsData.refreshing && academicSubjectsData.finished && academicSubjectsData.data.length > 0 &&
                            <div className='d-flex mt-2 align-items-center'>
                                <div style={{ color: brand_colors[defaultTheme.theme_id].color18 }}>NO MORE DATA...</div>
                            </div>
                        } */}
                  {!academicSubjectsData.refreshing && academicSubjectsData.finished && academicSubjectsData.data.length <= 0 &&
                    <div className='d-flex mt-2 align-items-center justify-content-center'>
                      <div style={{ color: brand_colors[defaultTheme.theme_id].color18 }}>NO DATA FOUND...</div>
                    </div>
                  }
                </div>
              </div>
            </Modal.Body>
          }
        </Modal>
        <SweetAlert show={this.state.deleteModal.show} warning showCancel confirmBtnText={t('Yes, delete it!')} confirmBtnBsStyle='danger' title={t('Are you sure?')} onConfirm={this.state.deleteModal.action} onCancel={() => this.setState({ deleteModal: { ...this.state.deleteModal, show: false, id: 0, action: () => { } } })} focusCancelBtn>
          {t('Confirm Delete!')}
        </SweetAlert>
      </Container >
    )
  }

}

const mapStateToProps = (state) => ({
  authData: state.auth.authData,
  defaultLanguage: state.language.defaultLanguage,
  defaultTheme: state.theme.defaultTheme
});

const mapDispatchToProps = () => ({
  setUpdateSchoolModal,
  addNavigation
});

export default connect(mapStateToProps, mapDispatchToProps())(GradesAndAcademic);
