import { createSlice } from '@reduxjs/toolkit';

export const accountsSetupSlice = createSlice({
  name: 'accountsSetup',
  initialState: {
    mainChart: 0,
  },
  reducers: {
    setMainChart: (state, action) => {
      if (state.mainChart == action.payload) {
        state.mainChart = 0;
      } else {
        state.mainChart = action.payload;
      }
    },
  },
});

export const { setMainChart } =
  accountsSetupSlice.actions;
export default accountsSetupSlice.reducer;
