import React, { Component } from 'react';
import { Container, Card, Row, Col, Offcanvas, Form, Image, Spinner } from 'react-bootstrap';
import { t } from '../../helpers/translation_helper';
import NavBar from '../_partials/NavBar/_NavBar';
import Header from '../_partials/Header/_Header';
import { connect } from 'react-redux';
import { ifNavAllowed } from '../../helpers/general_helpers';
import { NavLink, Link } from 'react-router-dom';
import { brand_colors } from '../../helpers/brand_colors_helper';
import { GET } from '../../api';
const analysisReportsData = [
  { id: 'report-exams', name: t('Exams Report'), image: require('../../assets/images/setup/curriculumbuilder.png'), linkTo: '/report-exams' },
  { id: 'report-curriculum-result', name: t('Objective Result'), image: require('../../assets/images/setup/curriculumbuilder.png'), linkTo: '/report-curriculum-result' },
  { id: 'report-cohort-exams', name: t('Cohort Exams'), image: require('../../assets/images/setup/curriculumbuilder.png'), linkTo: '/report-cohort-exams' },
  { id: 'report-class-analysis', name: t('Report Class Analysis'), image: require('../../assets/images/setup/curriculumbuilder.png'), linkTo: '/report-class-analysis' },
  { id: 'report-subject-analysis', name: t('Report Subject Analysis'), image: require('../../assets/images/setup/curriculumbuilder.png'), linkTo: '/report-subject-analysis' },
  { id: 'report-subject-rubric-ratio', name: t('Report Subject Rubric Ratio'), image: require('../../assets/images/setup/curriculumbuilder.png'), linkTo: '/report-subject-rubric-ratio' },
  // { id: 'report-subject-rubric', name: t('Report Subject Rubric'), image: require('../../assets/images/setup/curriculumbuilder.png'), linkTo: '/report-subject-rubric' },
  { id: 'report-result-rubric', name: t('Report Result Rubric'), image: require('../../assets/images/setup/curriculumbuilder.png'), linkTo: '/report-result-rubric' },
  { id: 'report-exam-analysis', name: t('Report Exam Analysis'), image: require('../../assets/images/setup/curriculumbuilder.png'), linkTo: '/report-exam-analysis' },
]
class SubjectActions extends Component {

  constructor(props) {
    super(props);
    this.state = {
      fetching: true,
      modules: [
        [
          {
            id: 'weekly-class-plan',
            linkTo: '/weekly-class-plan',
            linkToStates: '',
            cardImage: require('../../assets/images/person-2.png'),
            name: t('WEEKLY CLASS PLAN'),
            bgColor: brand_colors[props.defaultTheme.theme_id].color4
          },
          {
            id: 'tasks-list',
            linkTo: '/tasks-list',
            linkToStates: '',
            cardImage: require('../../assets/images/person-2.png'),
            name: t('TASKS LIST'),
            bgColor: brand_colors[props.defaultTheme.theme_id].color22
          },
          {
            id: '',
            linkTo: '',
            linkToStates: '',
            cardImage: require('../../assets/images/person-2.png'),
            name: t('ANALYSIS REPORTS'),
            bgColor: brand_colors[props.defaultTheme.theme_id].color4,
            onClick: () => this.state.modalOptions.toggleModal(t('ANALYSIS REPORTS'), analysisReportsData),
          },
        ],
        [
          {
            id: 'lessons',
            linkTo: '/subject/lessons',
            linkToStates: '',
            cardImage: require('../../assets/images/person-2.png'),
            name: t('LESSONS'),
            bgColor: brand_colors[props.defaultTheme.theme_id].color4
          },
          {
            id: 'class-blog',
            linkTo: '/class-blog',
            linkToStates: '',
            cardImage: require('../../assets/images/person-2.png'),
            name: t('CLASS BLOG'),
            bgColor: brand_colors[props.defaultTheme.theme_id].color4
          },
        ],
        [
          {
            id: 'activities',
            linkTo: '/activities',
            linkToStates: '',
            cardImage: require('../../assets/images/person-3.png'),
            name: t('ACTIVITY'),
            bgColor: brand_colors[props.defaultTheme.theme_id].color1
          },
          {
            id: 'assigned-activity',
            linkTo: '/assigned-activity',
            linkToStates: '',
            cardImage: require('../../assets/images/person-4.png'),
            name: t('ASSIGNED ACTIVITY'),
            bgColor: brand_colors[props.defaultTheme.theme_id].color2
          },
          {
            id: 'exams',
            linkTo: '/exams',
            linkToStates: '',
            cardImage: require('../../assets/images/person-4.png'),
            name: t('EXAM HALL'),
            bgColor: brand_colors[props.defaultTheme.theme_id].color6
          },
          {
            id: 'exams',
            linkTo: '/homework',
            linkToStates: '',
            cardImage: require('../../assets/images/person-3.png'),
            name: t('HOMEWORK'),
            bgColor: brand_colors[props.defaultTheme.theme_id].color2
          },
          {
            id: 'exams',
            linkTo: '/project',
            linkToStates: '',
            cardImage: require('../../assets/images/person-1.png'),
            name: t('PROJECT'),
            bgColor: brand_colors[props.defaultTheme.theme_id].color1
          },
          {
            id: 'exams',
            linkTo: '/assignment',
            linkToStates: '',
            cardImage: require('../../assets/images/person-1.png'),
            name: t('ASSIGNMENT'),
            bgColor: brand_colors[props.defaultTheme.theme_id].color1
          },
          {
            id: 'exams',
            linkTo: '/other-assessment',
            linkToStates: '',
            cardImage: require('../../assets/images/person-1.png'),
            name: t('OTHER ASSESSMENTS'),
            bgColor: brand_colors[props.defaultTheme.theme_id].color1
          }
        ]
      ],
      modalOptions: {
        show: false,
        toggleModal: (title = '', data = []) => this.setState({ modalOptions: { ...this.state.modalOptions, show: !this.state.modalOptions.show, title, data } }),
        title: '',
        data: []
      }
    }

  }

  componentDidMount() {
    this.getClassmainSubjectTimetable();
  }

  getClassmainSubjectTimetable = async () => {

    this.setState({ fetching: true });

    let modules = this.state.modules;

    const findIndex = modules[1].findIndex(item => item.id == 'weekly-lesson-plans');

    if (findIndex !== -1) {
      modules[1].splice(findIndex, 1);
    }

    if (this.props.selectedSubject) {

      const { employee_id } = this.props.authData.userData;
      const { acs_id, acs_name } = this.props.selectedSubject;
      const { class_id } = this.props.selectedClass.class;

      try {
        const res = await GET('timetable/get-classmain-subject-timetable', {
          params: {
            class_id,
            employee_id,
            acs_id,
            acs_name
          }
        });

        if (res.data) {

          modules[1].push({
            id: 'weekly-lesson-plans',
            linkTo: `/weekly-lesson-plans/${acs_id}/${res.data.tp_id}/${res.data.tt_id}/${acs_name}`,
            linkToStates: '',
            cardImage: require('../../assets/images/person-1.png'),
            name: t('WEEKLY LESSONS PLANS'),
            bgColor: brand_colors[this.props.defaultTheme.theme_id].color6
          });

          this.setState({
            fetching: false,
            modules
          });
        } else {
          this.setState({
            fetching: false,
          });
        }

      } catch (err) {
        console.log(err);
        this.setState({
          fetching: false
        });
      }

    } else {
      this.setState({
        fetching: false
      });
    }

  }

  /**
   * Render Component
   * @returns {HTMLElement}
   */
  render() {
    const { lang_orientation: dir } = this.props.defaultLanguage;
    const { modalOptions } = this.state
    return (
      <Container fluid>
        <div id='iedu-layout'>
          <NavBar />
          <div id='page-content'>
            <Header lite={true} heading={t('Subject Actions')} backBtn={true}
              showSubjectSelector={true} showClassSelector={true} classSelectorFunc={[this.getClassmainSubjectTimetable]} subjectSelectorFunc={[this.getClassmainSubjectTimetable]} />
            <div className='grey-section'>
              <Card
                className='border-0'
                style={{ borderRadius: '20px' }}>
                <Card.Body>
                  {false && this.state.fetching &&
                    <Row>
                      <Col md={12} className='py-5 d-flex flex-column align-items-center'>
                        <Spinner as='span' animation='grow' size='sm' /> {t('Loading Subject Actions')}
                      </Col>
                    </Row>
                  }
                  {this.state.modules.map((mods, i) =>
                  (
                    <div key={i}>
                      <div className="d-flex flex-row  align-items-center mt-3 flex-wrap">
                        {mods.map((v, i) => {
                          if (v.id == '' || ifNavAllowed(v.id)) {
                            return <div key={i} className='one-slide mt-4 d-flex' style={{ width: '25%' }} onClick={v.onClick}>
                              <Link to={v.linkTo} style={{ width: '100%' }}>
                                <div className='slide-inner' style={{ backgroundColor: v.bgColor }}>
                                  <h3>{v.name}</h3>
                                  <div style={{ flex: 1 }}></div>
                                  <img src={v.cardImage} alt='' />
                                </div>
                              </Link>
                            </div>
                          }
                        })}
                        {i === 1 && this.state.fetching &&
                          <div
                            style={{ width: '25%' }}
                            className='one-slide mt-4 d-flex justify-content-center align-items-center'>
                            <Spinner as='span' animation='grow' size='lg' />
                          </div>
                        }
                      </div>
                      {i < (this.state.modules.length - 1) &&
                        <hr />
                      }
                    </div>
                  )
                  )}
                </Card.Body>
              </Card>
            </div>
          </div>
        </div>


        <Offcanvas
          show={modalOptions.show}
          onHide={modalOptions.toggleModal}
          placement={dir == 1 ? 'start' : 'end'}>
          <Offcanvas.Header>
            <div>
              <Offcanvas.Title>{modalOptions.title}</Offcanvas.Title>
            </div>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <div className='d-flex flex-column'>
              {!this.state.fetching && modalOptions.data.map((v, i) => {
                if (v.id == '' || ifNavAllowed(v.id)) {
                  return <div style={{
                    padding: '1rem', width: 'calc(100%-2rem)', backgroundColor: brand_colors[this.props.defaultTheme.theme_id].color8, borderRadius: '1rem', margin: '0.3rem 1rem', position: 'relative', boxShadow: '-1px 2px 16px 2px rgba(0,0,0,0.08)',
                  }}>
                    <Link to={v.linkTo} style={{ textDecoration: 'none' }}>
                      <div className='d-flex flex-row justify-content-between align-items-center'>
                        <div className='d-flex flex-row ' style={{ flex: 1 }}>
                          <div className='d-flex align-items-center me-3'>
                            <Image src={v.image} style={{ objectFit: 'cover', width: '3rem', height: '3rem', backgroundColor: brand_colors[this.props.defaultTheme.theme_id].color8, borderRadius: '100%' }} />
                          </div>
                          <div className='d-flex align-items-center me-3' style={{ flex: 1 }}>
                            <div>
                              <div>
                                <strong style={{ fontSize: '1rem', color: brand_colors[this.props.defaultTheme.theme_id].color9, opacity: '0.8' }}>{v.name}</strong>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                }
              })}
            </div>
          </Offcanvas.Body>
        </Offcanvas>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  authData: state.auth.authData,
  defaultLanguage: state.language.defaultLanguage,
  defaultTheme: state.theme.defaultTheme,
  selectedSubject: state.selectedClass.subject,
  selectedClass: state.selectedClass.data,
});

export default connect(mapStateToProps, null)(SubjectActions);
