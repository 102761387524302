import React, { Component } from 'react';
import { Container, Card, Row, Col, Button, Alert } from 'react-bootstrap';
import { t } from '../../helpers/translation_helper';
import NavBar from '../_partials/NavBar/_NavBar';
import Header from '../_partials/Header/_Header';
import { connect } from 'react-redux';
import Select from 'react-select';
import { GET, POST } from '../../api';
import SweetAlert from 'react-bootstrap-sweetalert';
import merging from '../../assets/images/merge.gif'

class MergeClasses extends Component {

  state = {
    merging: false,
    confirmStartOp: false,
    agegroups: [],
    classes: [],
    fromClass: '',
    targetClass: ''
  }

  componentDidMount() {
    this.getAgeGroups();
  }

  getAgeGroups = async () => {
    try {
      const res = await GET('merge-classes/get-age-groups');
      this.setState({
        agegroups: res.data.map(item => {
          return {
            ...item,
            value: item.agegroup_id,
            label: item.agegroup_code,
          }
        })
      });
    } catch (err) {
      console.log(err)
    }
  }

  getClasses = async (agegroup_id) => {
    try {
      const res = await GET('merge-classes/get-classes', {
        params: {
          agegroup_id
        }
      });
      this.setState({
        classes: res.data.map(item => {
          return {
            ...item,
            value: item.class_id,
            label: item.class_theme,
          }
        })
      });
    } catch (err) {
      console.log(err);
    }
  }

  selectAgeGroup = (obj) => {
    this.getClasses(obj.value);
  }

  mergeClasses = async () => {

    this.setState({
      merging: true,
      confirmStartOp: false
    });

    try {
      const fData = new FormData();
      fData.append('class_id', this.state.fromClass.value);
      fData.append('class_merge_with', this.state.targetClass.value);
      const res = await POST('merge-classes/merge', fData);
      console.log(res);
      this.setState({
        merging: false
      });
    } catch (err) {
      console.log(err);
    }
  }

  render() {
    console.log('%cSTATE ===========', 'font-size:20px;color:orange;font-weight:bold');
    console.log(this.state);
    return (
      <Container fluid>
        <div id='iedu-layout'>
          <NavBar />
          <div id='page-content'>
            <Header lite={true} heading={t('Merge Classes')} backBtn />
            <div className='grey-section'>
              <Card
                className='border-0'
                style={{ borderRadius: '20px' }}>
                <Card.Body>
                  <Row>
                    <Col className='pe-0 mb-3'>
                      <Select
                        placeholder={t('Select Grade')}
                        options={this.state.agegroups}
                        onChange={this.selectAgeGroup}
                        value={this.state.agegroup}
                        menuPortalTarget={document.body}
                        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                        formatOptionLabel={option => (
                          <div>
                            <span className='badge badge-light-primary me-1 mb-1'>{option.ab_name}</span>
                            <br />
                            <small style={{ fontWeight: 'bold', color: option.agegroup_color }} >{option.label}</small>
                          </div>
                        )}
                      />
                    </Col>
                    <Col className='pe-0 mb-3'>
                      <Select
                        placeholder={t('Select From Class')}
                        options={this.state.classes}
                        onChange={(obj) => this.setState({ fromClass: obj })}
                        value={this.state.fromClass}
                        menuPortalTarget={document.body}
                        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                      />
                    </Col>
                    <Col className='pe-0 mb-3'>
                      <Select
                        placeholder={t('Select Target Class')}
                        options={this.state.classes}
                        onChange={(obj) => this.setState({ targetClass: obj })}
                        value={this.state.targetClass}
                        menuPortalTarget={document.body}
                        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                      />
                    </Col>
                    <Col md={'auto'}>
                      <Button
                        onClick={() => this.setState({ confirmStartOp: true })}
                        disabled={!this.state.fromClass || !this.state.targetClass || this.state.merging}
                        style={{ color: '#fff' }} variant='warning'>{t('Start Operation')}</Button>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Alert className='mb-0' variant={'info'}>
                        {t('This Operation will transfer all Media files, Videos, Observations, Exams, Homework, Polls and Surveys to The Target Class. All other communication will be deleted, Then it will delete the From Class and Keep only the Target Class.')}
                      </Alert>
                    </Col>
                  </Row>
                  {this.state.merging &&
                    <Row>
                      <Col className='text-center' style={{ backgroundColor: '#FBFBFB' }}>
                        <img src={merging} alt='' />
                      </Col>
                    </Row>
                  }
                </Card.Body>
              </Card>
            </div>
          </div>
        </div>
        <SweetAlert show={this.state.confirmStartOp} warning showCancel confirmBtnText={t('Yes, confirm!')} confirmBtnBsStyle='danger' title={t('Are you sure?')} onConfirm={this.mergeClasses} onCancel={() => this.setState({ confirmStartOp: false })} focusCancelBtn>
          {t('This Operation will transfer all Media files, Videos, Observations, Exams, Homework, Polls and Surveys to The Target Class. All other communication will be deleted, Then it will delete the From Class and Keep only the Target Class.')}
        </SweetAlert>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  authData: state.auth.authData,
  defaultLanguage: state.language.defaultLanguage,
});

export default connect(mapStateToProps, null)(MergeClasses);
