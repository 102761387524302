import React, { Component } from 'react';
import { Container, Card, Row, Col, Button, Badge, Modal, Form, Spinner } from 'react-bootstrap';
import { t } from '../../../helpers/translation_helper';
import NavBar from '../../_partials/NavBar/_NavBar';
import Header from '../../_partials/Header/_Header';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBook, faEdit, faHomeUser, faListOl, faPencil, faPlus, faSave, faSearch, faTrashCan, faXmark } from '@fortawesome/free-solid-svg-icons';
import UpdateSchoolModal from '../../_common/UpdateSchoolModal/_UpdateSchoolModal';
import { setUpdateSchoolModal } from '../../../redux/slices/updateSchoolModalSlice';
import { addNavigation } from '../../../redux/slices/navigationSlice';
import axios from 'axios';
import { toast } from 'react-toastify';
import Select from 'react-select'
import AddEditClass from '../../_common/AddEditClass/_AddEditClass';
import AcademicBoard from '../../CurriculumBuilder/AcademicBoard';
import imgDescription from '../../../assets/images/description.png';
import { handleFormErrors, readFile } from '../../../helpers/form_helpers';
import { Chance } from 'chance';
import { brand_colors } from '../../../helpers/brand_colors_helper';
import { colorPalette } from '../../../constants/colorsPalette';
import { Link, NavLink } from 'react-router-dom';
import { getExtension, ifNavAllowed } from '../../../helpers/general_helpers';
import { faThumbsDown, faThumbsUp } from '@fortawesome/free-regular-svg-icons';
import SweetAlert from 'react-bootstrap-sweetalert';
import GeneralCard from '../../../helpers/GeneralCard';
import HorizontalCard from '../../../helpers/HorizontalCard';
import moment from 'moment';
var chance = new Chance();

const apiURL = process.env.REACT_APP_API_URL;
const portalURL = process.env.REACT_APP_PORTAL_URL;


class SchoolSetup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      groups: [
        {
          id: chance.guid(),
          name: 'School',
          show: true,
          modules: [
            {
              id: 'school-setup',
              linkTo: '',
              linkToStates: '',
              onClick: () => props.setUpdateSchoolModal(true),
              cardImage: require('../../../assets/images/setup/schoolsetup.png'),
              name: t('School Setup'),
              text1: props.authData.centerData.center_name
            },
            {
              id: 'grades-academic',
              linkTo: '/grades-academic',///curriculum-builder
              linkToStates: '',
              cardImage: require('../../../assets/images/setup/gradesandacademic.png'),
              name: t('Grades & Academic'),
            },
            {
              id: 'class-main',
              linkTo: '/class-main',
              linkToStates: '',
              cardImage: require('../../../assets/images/setup/feestructure.png'),
              name: t('Fee Group'),
            },
            {
              id: 'classes',
              linkTo: '/classes',
              linkToStates: '',
              cardImage: require('../../../assets/images/setup/feestructure.png'),
              name: t('Classes'),
            },
          ]
        },
        {
          id: chance.guid(),
          name: 'Operation',
          show: true,
          modules: [
            {
              id: 'registration-periods',
              linkTo: '/registration-periods',
              linkToStates: '',
              cardImage: require('../../../assets/images/setup/registrationperiods.png'),
              name: t('Registration Periods'),
            },
            {
              id: 'services',
              linkTo: '/services',
              linkToStates: '',
              cardImage: require('../../../assets/images/setup/services.png'),
              name: t('Services'),
            },
            {
              id: 'catfees',
              linkTo: '/catfees',
              linkToStates: '',
              cardImage: require('../../../assets/images/setup/feestructure.png'),
              name: t('Fee Structure'),
            },
            {
              id: 'discounts',
              linkTo: '/discounts',
              linkToStates: '',
              cardImage: require('../../../assets/images/setup/discount.png'),
              name: t('Discounts'),
            },
            {
              id: 'termpolicies',
              linkTo: '/termpolicies',
              linkToStates: '',
              cardImage: require('../../../assets/images/setup/policies.png'),
              name: t('Term Fee Policies'),
            },
          ]
        },
        {
          id: chance.guid(),
          name: 'Curriculum',
          show: true,
          modules: [
            {
              id: 'curriculum-builder',
              linkTo: '/curriculum-builder',
              linkToStates: '',
              cardImage: require('../../../assets/images/setup/curriculumbuilder.png'),
              name: t('Curriculum Builder'),
            },
            {
              id: 'exam-groups-types',
              linkTo: '/exam-groups-types',
              linkToStates: '',
              cardImage: require('../../../assets/images/setup/feestructure.png'),
              name: t('Exam Groups & Types'),
            },
          ]
        },
        {
          id: chance.guid(),
          name: 'Miscellaneous',
          show: true,
          modules: [
            {
              id: 'health-setup',
              linkTo: '/health-setup',
              linkToStates: '',
              cardImage: require('../../../assets/images/setup/feestructure.png'),
              name: t('Health Setup'),
            },
            {
              id: 'center-roles',
              linkTo: '/center-roles',
              linkToStates: '',
              cardImage: require('../../../assets/images/setup/feestructure.png'),
              name: t('Roles'),
            },
            {
              id: 'absence-types',
              linkTo: '/absence-types',
              linkToStates: '',
              cardImage: require('../../../assets/images/setup/feestructure.png'),
              name: t('Std Absence Types'),
            },
            {
              id: 'merge-classes',
              linkTo: '/merge-classes',
              linkToStates: '',
              cardImage: require('../../../assets/images/setup/feestructure.png'),
              name: t('Merge Classes'),
            },
            {
              id: 'awards-warnings',
              linkTo: '/awards-warnings',
              linkToStates: '',
              cardImage: require('../../../assets/images/setup/feestructure.png'),
              name: t('Awards/Warnings'),
            },
            {
              id: 'navigation',
              linkTo: '/navigation/nav-tree',
              linkToStates: '',
              cardImage: require('../../../assets/images/setup/feestructure.png'),
              name: t('Navigation'),
            },
          ]
        },
      ]
    }
  }

  componentDidMount() {
    this.props.addNavigation({
      id: 'admin-school-setup',
      name: 'School Setup',
      path: '/admin/school-setup',
    })
  }

  render() {
    const { defaultTheme } = this.props
    const { groups } = this.state
    return (
      <Container fluid>
        <div id='iedu-layout'>
          <NavBar />
          <div id='page-content' style={{ width: '100%' }}>
            <Header />
            {groups
              .filter(grp => grp.show)
              .map((grp, gid) => {
                return (
                  <>
                    <div className='eval-topics-sub-topics-heading mb-3'>
                      <span>{grp.name && t(grp.name)}</span>
                    </div>
                    <div className='d-flex flex-row flex-wrap mb-3'>
                      {grp.modules.map((mod, mid) => {
                        if (ifNavAllowed(mod.id)) {
                          return <GeneralCard data={mod} key={mid} />
                        }
                      })}
                    </div>
                  </>
                )
              })}
          </div>
        </div>
        {/* SCHOOL SETUP */}
        <UpdateSchoolModal />
      </Container>
    )
  }

}

const mapStateToProps = (state) => ({
  authData: state.auth.authData,
  defaultLanguage: state.language.defaultLanguage,
  defaultTheme: state.theme.defaultTheme
});

const mapDispatchToProps = () => ({
  setUpdateSchoolModal,
  addNavigation
});

export default connect(mapStateToProps, mapDispatchToProps())(SchoolSetup);
