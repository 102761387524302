import React, { Component } from 'react';
import { Container, Card, Row, Col, Button, Tab, Tabs, Table, Badge, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDashboard, faUsers, faAddressCard, faUserTie, faChevronDown, faSchool, faInfoCircle, faEye } from '@fortawesome/free-solid-svg-icons';
import { t } from '../../../../helpers/translation_helper';
import Paginate from '../../../_partials/Paginate/_Paginate';
import { Doughnut, Bar } from 'react-chartjs-2';
import { connect } from 'react-redux';
import { brand_colors } from '../../../../helpers/brand_colors_helper';
import axios from 'axios';
import { toast } from 'react-toastify';
import { LoadingPlaceholder } from '../../../_common/Loading/Loading';
import { Skeleton } from '../../../_common/Loading/Skeleton';
import moment from 'moment';
import { GET } from '../../../../api';
import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!

// API URL
const apiURL = process.env.REACT_APP_API_URL;



class OtherDashboard extends Component {
  state = {

  }

  componentDidMount() {

  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.authData.centerData.center_id !== this.props.authData.centerData.center_id) {

    }
  }

  render() {

    return (<>

    </>);
  }
}

const mapStateToProps = (state) => ({
  authData: state.auth.authData,
  defaultLanguage: state.language.defaultLanguage,
  defaultTheme: state.theme.defaultTheme,
});

export default connect(mapStateToProps, null)(OtherDashboard);