import React, { Component } from 'react';
import { Container, Card, Row, Col, Button, Spinner, InputGroup, Form, Table } from 'react-bootstrap';
import { t } from '../../helpers/translation_helper';
import NavBar from '../_partials/NavBar/_NavBar';
import Header from '../_partials/Header/_Header';
import { connect } from 'react-redux';
import { brand_colors } from '../../helpers/brand_colors_helper';
import { faCalendar, faSearch, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DateRangePicker } from 'react-date-range';
import moment from 'moment';
import { GET } from '../../api';
import { numberFormat } from '../../helpers/general_helpers';
import { faCalendarAlt } from '@fortawesome/free-regular-svg-icons';

class ReportVatInvoices extends Component {

  state = {
    fetching: false,
    selectedDateRange: { startDate: new Date(), endDate: new Date(), key: 'selection' },
    showDateRange: false,
    registrations: [],
    addservicesGrouped: null,
    grandTotal: 0,
  }

  fetch = async () => {
    const { selectedDateRange } = this.state;
    this.setState({
      fetching: true,
      showDateRange: false,
      registrations: [],
      addservicesGrouped: null,
      grandTotal: 0,
    });

    try {
      const res = await GET('admin/report-vat-invoices', {
        params: {
          from_date: moment(selectedDateRange.startDate).format('YYYY-MM-DD'),
          to_date: moment(selectedDateRange.endDate).format('YYYY-MM-DD')
        }
      });

      const registrations = res.data.registration;
      const addservices = res.data.addservices;
      const addservicesGrouped = addservices.reduce((group, service) => {
        const { services_name } = service;
        group[services_name] = group[services_name] ?? [];
        group[services_name].push(service);
        return group;
      }, {});

      console.log('registration', registrations);
      console.log('addservicesGrouped', addservicesGrouped);

      let registrationTotal = 0;
      registrations.map((item) => {

        let input_line_vat_amount = (parseFloat(item.term_amount) * parseFloat(item.reg_vat)) / 100;
        let input_line_total = (parseFloat(item.term_amount) + parseFloat(input_line_vat_amount));

        registrationTotal += parseFloat(input_line_total);
      });

      let serviceTotal = 0;
      addservices.map((service_item) => {

        let discount = parseFloat(service_item.addservices_fee) * parseFloat(service_item.addservices_discount) / 100;

        let vat = parseFloat(service_item.addservices_fee) * parseFloat(service_item.addservices_vat) / 100;

        let input_line_total = ((parseFloat(service_item.addservices_fee) * parseInt(service_item.addservices_quantity)) - discount + vat).toFixed(2);

        serviceTotal += parseFloat(input_line_total);

      });


      this.setState({
        fetching: false,
        registrations,
        addservicesGrouped,
        grandTotal: parseFloat(registrationTotal + serviceTotal).toFixed(2)
      });
    } catch (err) {
      console.log(err);
      this.setState({ fetching: false });
    }

  }

  render() {

    let addservicesGroups = [];

    if (this.state.addservicesGrouped) {
      addservicesGroups = Object.keys(this.state.addservicesGrouped);
    }

    let registrationTotal = 0;

    return (
      <Container fluid>
        <div id='iedu-layout'>
          <NavBar />
          <div id='page-content'>
            <Header lite={true} heading={t('VAT Invoices List')} backBtn={true} showClassSelector={true} showSubjectSelector={true} classSelectorFunc={[]} subjectSelectorFunc={[]} />
            <div className='grey-section'>
              <Row>
                <Col md={3} className='position-relative pe-0'>
                  <InputGroup>
                    <Form.Control
                      onClick={() => this.setState({ showDateRange: true })}
                      style={{
                        paddingLeft: 20,
                        borderTopLeftRadius: 20,
                        borderBottomLeftRadius: this.state.showDateRange ? 7 : 20,
                      }}
                      value={
                        moment(this.state.selectedDateRange.startDate).format('DD-MM-YYYY') + ' -- ' + moment(this.state.selectedDateRange.endDate).format('DD-MM-YYYY')
                      }
                      readOnly
                    />
                    <Button variant={this.state.showDateRange ? 'danger' : 'primary'}
                      style={{ width: 40 }}
                      onClick={() => this.setState({ showDateRange: !this.state.showDateRange })}
                      id="button-addon2">
                      <FontAwesomeIcon icon={this.state.showDateRange ? faXmark : faCalendarAlt} style={{ fontSize: 16, color: brand_colors[this.props.defaultTheme.theme_id].color8, }} />
                    </Button>
                  </InputGroup>
                  {this.state.showDateRange &&
                    <div style={{
                      backgroundColor: '#fff',
                      padding: 10,
                      position: 'absolute',
                      border: '#F2F2F2 2px solid',
                      zIndex: 1,
                      top: 37,
                      borderRadius: 7
                    }}>
                      <DateRangePicker
                        ranges={[this.state.selectedDateRange]}
                        onChange={(date) => {

                          this.setState({
                            selectedDateRange: { startDate: new Date(date.selection.startDate), endDate: new Date(date.selection.endDate), key: 'selection' }
                          })
                        }}
                        style={{ backgroundColor: '#fff' }}
                      />
                    </div>
                  }
                </Col>
                <Col>
                  <Button variant={'success'} onClick={() => this.fetch()}>
                    {this.state.fetching
                      ? <Spinner as='span' animation='grow' size='sm' role='status' aria-hidden='true' />
                      : <FontAwesomeIcon icon={faSearch} style={{ fontSize: 16, color: brand_colors[this.props.defaultTheme.theme_id].color8, }} />
                    }
                  </Button>
                </Col>
                <Col className='d-flex justify-content-end align-items-center pe-4'>
                  <h5 className='mb-0'>
                    {t('Grand Total:') + ' '}
                    <strong>{numberFormat(this.state.grandTotal)}</strong>
                  </h5>
                </Col>
              </Row>
            </div>
            {this.state.registrations.length > 0 &&
              <div className='grey-section'>
                <Card
                  className='border-0'
                  style={{ borderRadius: '20px' }}>
                  <Card.Body>
                    <Row>
                      <Col md={12}>
                        <h5 style={{ paddingLeft: 7 }}>{t('Registrations')}</h5>
                        <Table hover className='mb-0'>
                          <thead>
                            <tr>
                              <th style={{ width: '25%' }}>{t('Reg No.')}</th>
                              <th style={{ width: '25%' }}>{t('Child Name')}</th>
                              <th style={{ width: '25%' }}>{t('Registration Date')}</th>
                              <th style={{ textAlign: 'right', width: '25%' }}>{t('Total')}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.registrations.map((item, index) => {

                              let input_line_vat_amount = (parseFloat(item.term_amount) * parseFloat(item.reg_vat)) / 100;
                              let input_line_total = (parseFloat(item.term_amount) + parseFloat(input_line_vat_amount)).toFixed(2);

                              registrationTotal += parseFloat(input_line_total);

                              return (
                                <tr key={index}>
                                  <td>{item.reg_num}</td>
                                  <td>{item.child_name}</td>
                                  <td>{moment(item.reg_date).format('DD-MM-YYYY')}</td>
                                  <td style={{ textAlign: 'right' }}>{numberFormat(input_line_total)}</td>
                                </tr>
                              );
                            })}
                            <tr>
                              <td colSpan={3} style={{ backgroundColor: '#f2f2f2' }}>
                                <strong>{t('TOTAL')}</strong>
                              </td>
                              <td style={{ textAlign: 'right', backgroundColor: '#f2f2f2' }}>
                                <strong>{numberFormat(registrationTotal)}</strong>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </div>
            }
            {addservicesGroups.map((item, index) => {

              let groupTotal = 0;

              return (
                <div className='grey-section'>
                  <Card
                    className='border-0'
                    style={{ borderRadius: '20px' }}>
                    <Card.Body>
                      <Row>
                        <Col md={12}>
                          <h5 style={{ paddingLeft: 7 }}>{t(item)}</h5>
                          <Table hover className='mb-0'>
                            <thead>
                              <tr>
                                <th style={{ width: '25%' }}>{t('Invoice No.')}</th>
                                <th style={{ width: '25%' }}>{t('Child Name')}</th>
                                <th style={{ width: '25%' }}>{t('Date')}</th>
                                <th style={{ textAlign: 'right', width: '25%' }}>{t('Amount')}</th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.addservicesGrouped[item].map((group_item, group_index) => {

                                let discount = parseFloat(group_item.addservices_fee) * parseFloat(group_item.addservices_discount) / 100;

                                let vat = parseFloat(group_item.addservices_fee) * parseFloat(group_item.addservices_vat) / 100;

                                let input_line_total = ((parseFloat(group_item.addservices_fee) * parseInt(group_item.addservices_quantity)) - discount + vat).toFixed(2);

                                groupTotal += parseFloat(input_line_total);

                                return (
                                  <tr key={index}>
                                    <td>{group_item.addservices_num}</td>
                                    <td>{moment(group_item.addservices_date).format('DD-MM-YYYY')}</td>
                                    <td>{group_item.child_name}</td>
                                    <td style={{ textAlign: 'right' }}>{numberFormat(input_line_total)}</td>
                                  </tr>
                                );
                              })}
                              <tr>
                                <td colSpan={3} style={{ backgroundColor: '#f2f2f2' }}>
                                  <strong>{t('TOTAL')}</strong>
                                </td>
                                <td style={{ textAlign: 'right', backgroundColor: '#f2f2f2' }}>
                                  <strong>{numberFormat(groupTotal)}</strong>
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </div>
              );
            })}
          </div>
        </div>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  authData: state.auth.authData,
  defaultLanguage: state.language.defaultLanguage,
  defaultTheme: state.theme.defaultTheme,
});

export default connect(mapStateToProps, null)(ReportVatInvoices);
