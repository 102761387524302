import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';

export const addEditRegistrationAdvanceModalSlice = createSlice({
    name: 'addEditRegistrationAdvanceModal',
    initialState: {
        showModal: false,
        title: 'New Registration',
        child: null,
        registrations: [],
        todayDate: moment().format('YYYY-MM-DD'),
        addRegCount: 1,
        addservices: [],
        editMode: false,
        selectedClass: null,
        selectedSession: null,
        selectedSessionCat: null,
        fee_type: 1,
        convertMode: false,
    },
    reducers: {
        toggleRegistrationAdvanceModal: (state, action) => {
            state.showModal = !state.showModal
            state.addRegCount = (action.payload && action.payload.addRegCount) ? action.payload.addRegCount : 1
            state.title = (action.payload && action.payload.title) ? action.payload.title : 'New Registration'
            state.todayDate = (action.payload && action.payload.todayDate) ? action.payload.todayDate : moment().format('YYYY-MM-DD')
            state.child = (action.payload && action.payload.child) ? action.payload.child : null
            state.fee_type = (action.payload && action.payload.fee_type) ? action.payload.fee_type : 1
            state.registrations = (action.payload && action.payload.registrations) ? action.payload.registrations : []
            state.addservices = (action.payload && action.payload.addservices) ? action.payload.addservices : []
            state.editMode = (action.payload && action.payload.editMode) ? action.payload.editMode : false
            state.selectedClass = (action.payload && action.payload.selectedClass) ? action.payload.selectedClass : null
            state.selectedSession = (action.payload && action.payload.selectedSession) ? action.payload.selectedSession : null
            state.selectedSessionCat = (action.payload && action.payload.selectedSessionCat) ? action.payload.selectedSessionCat : null
            state.convertMode = (action.payload && action.payload.convertMode) ? action.payload.convertMode : false
        },
        toggleRegistrationProformaModal: (state, action) => {
            state.showModal = !state.showModal
            state.addRegCount = (action.payload && action.payload.addRegCount) ? action.payload.addRegCount : 1
            state.title = (action.payload && action.payload.title) ? action.payload.title : 'New Registration Proforma'
            state.todayDate = (action.payload && action.payload.todayDate) ? action.payload.todayDate : moment().format('YYYY-MM-DD')
            state.child = (action.payload && action.payload.child) ? action.payload.child : null
            state.fee_type = (action.payload && action.payload.fee_type) ? action.payload.fee_type : 32
            state.registrations = (action.payload && action.payload.registrations) ? action.payload.registrations : []
            state.addservices = (action.payload && action.payload.addservices) ? action.payload.addservices : []
            state.editMode = (action.payload && action.payload.editMode) ? action.payload.editMode : false
            state.selectedClass = (action.payload && action.payload.selectedClass) ? action.payload.selectedClass : null
            state.selectedSession = (action.payload && action.payload.selectedSession) ? action.payload.selectedSession : null
            state.selectedSessionCat = (action.payload && action.payload.selectedSessionCat) ? action.payload.selectedSessionCat : null
            state.convertMode = (action.payload && action.payload.convertMode) ? action.payload.convertMode : false
        },
        updateChild: (state, action) => {
            state.child = (action.payload) ? action.payload : null
        }
    },
});

export const { toggleRegistrationAdvanceModal, updateChild, toggleRegistrationProformaModal } = addEditRegistrationAdvanceModalSlice.actions;
export default addEditRegistrationAdvanceModalSlice.reducer;
