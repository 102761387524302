import { createSlice } from '@reduxjs/toolkit';

export const examCopySlice = createSlice({
  name: 'examCopy',
  initialState: {
    showModal: false,
    exam: {},
  },
  reducers: {
    setExamCopy: (state, action) => {
      state.showModal = action.payload.showModal;
      state.exam = action.payload.exam;
    },
  },
});

export const { setExamCopy } =
  examCopySlice.actions;
export default examCopySlice.reducer;
