import React, { Component } from 'react';
import { Container, Card, Row, Col } from 'react-bootstrap';
import { t } from '../../helpers/translation_helper';
import NavBar from '../_partials/NavBar/_NavBar';
import Header from '../_partials/Header/_Header';
import { connect } from 'react-redux';
import { changeColorOpacity, ifNavAllowed } from '../../helpers/general_helpers';
import { NavLink, Outlet } from 'react-router-dom';
import { brand_colors } from '../../helpers/brand_colors_helper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBank, faCoins, faGear, faMoneyBill, faMoneyBill1Wave, faMoneyBillTransfer, faMoneyBillWave, faMoneyCheckDollar, faReceipt, faStore, faTruckField } from '@fortawesome/free-solid-svg-icons';


class AccountsSetupNav extends Component {
  state = {
    nav: [
      {
        id: 'accounts-setup',
        name: 'Account Setup',
        path: '/accounts/accounts-setup',
        icon: faGear,
      },
      {
        id: 'vat-accounts',
        name: 'VAT Accounts',
        path: '/accounts/vat-accounts',
        icon: faMoneyBill,
      },
      {
        id: 'banks',
        name: 'Banks',
        path: '/accounts/banks',
        icon: faBank,
      },
      {
        id: 'receipts-accounts',
        name: 'Receipts Accounts',
        path: '/accounts/receipts-accounts',
        icon: faReceipt,
      },
      {
        id: 'cost-centers',
        name: 'Cost Centers',
        path: '/accounts/cost-centers',
        icon: faMoneyBillTransfer,
      },
      {
        id: 'suppliers',
        name: 'Suppliers',
        path: '/accounts/suppliers',
        icon: faTruckField,
      },
      {
        id: 'expenses-accounts',
        name: 'Expenses Accounts',
        path: '/accounts/expenses-accounts',
        icon: faMoneyBill,
      },
    ]
  }

  render() {
    const { lang_orientation: dir } = this.props.defaultLanguage;

    return (
      <Container fluid>
        <div id='iedu-layout'>
          <NavBar />
          <div id='page-content'>
            <Header lite={true} heading={t('Accounts')} backBtn showCenterSelector={true} />
            <div style={{ display: 'flex' }}>
              <div className='inner-blue-bar'
                style={{
                  backgroundColor: changeColorOpacity(brand_colors[this.props.defaultTheme.theme_id].sidebar_bg, 0.15),
                  marginRight: dir == 1 ? 0 : 7,
                  marginLeft: dir == 0 ? 0 : 7,
                  minWidth: 100,
                  maxWidth: 100
                }}>
                <ul>
                  {/* <li>
                    <NavLink activeclassname='active' to={`/finance`} style={{ color: brand_colors[this.props.defaultTheme.theme_id].color4 }} >
                      <FontAwesomeIcon icon={faCoins} />
                      <div style={{ color: brand_colors[this.props.defaultTheme.theme_id].color4 }}>{t('Finance')}</div>
                    </NavLink>
                  </li> */}
                  {
                    this.state.nav.map((item, index) => {
                      if (ifNavAllowed(item.id)) {
                        return <li key={index}>
                          <NavLink activeclassname='active' to={`${item.path}`}>
                            <FontAwesomeIcon icon={item.icon} />
                            <div>{t(item.name)}</div>
                          </NavLink>
                        </li>
                      }
                    }
                    )
                  }
                </ul>
              </div>
              <div style={{ height: 'calc(100vh - 135px)', overflowY: 'auto', flex: 1 }}>
                <Outlet />
              </div>
            </div>
          </div>
        </div>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  authData: state.auth.authData,
  defaultLanguage: state.language.defaultLanguage,
  defaultTheme: state.theme.defaultTheme
});

export default connect(mapStateToProps, null)(AccountsSetupNav);
