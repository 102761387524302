import { createSlice } from '@reduxjs/toolkit';

export const addEditClassModalSlice = createSlice({
  name: 'addEditClassModal',
  initialState: {
    showModal: false,
    agegroup_id: 0,
    gcycle_id: 0,
    class_id: 0,
    isEditing: false
  },
  reducers: {
    setAddEditClassModal: (state, action) => {
      state.showModal = action.payload.showModal;
      state.agegroup_id = action.payload.agegroup_id;
      state.gcycle_id = action.payload.gcycle_id;
      state.class_id = action.payload.class_id;
      state.isEditing = action.payload.isEditing;
    },
  },
});

export const { setAddEditClassModal } =
  addEditClassModalSlice.actions;
export default addEditClassModalSlice.reducer;
