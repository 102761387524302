import { useParams, useLocation } from 'react-router-dom';

/**
 * Work around for parsing params
 * with class base components
 * @param {*} Component
 * @returns {HTMLElement} Returns the component with params
 */
export const withParams = (Component) => {
    return (props) => (
        <Component {...props} params={useParams()} location={useLocation()} />
    );
};