import React, { Component } from 'react';
import {
  Container,
  Card,
  Row,
  Col,
  ButtonGroup,
  Button,
  Table,
  Form,
  Spinner,
  Badge,
  Modal,
  InputGroup,
  Alert,
} from 'react-bootstrap';
import { t } from '../../../helpers/translation_helper';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSearch,
  faXmark,
  faBook,
  faCircleArrowDown,
  faCircleArrowUp,
} from '@fortawesome/free-solid-svg-icons';
import { brand_colors } from '../../../helpers/brand_colors_helper';
import {
  faCalendarAlt,
  faEye,
  faEyeSlash,
  faFileExcel,
  faFilePdf,
} from '@fortawesome/free-regular-svg-icons';
import moment from 'moment';
import { GET, POST } from '../../../api';
import Select from 'react-select';
import { DateRangePicker } from 'react-date-range';
import { Bar } from 'react-chartjs-2';
import { toast } from 'react-toastify';
const portalURL = process.env.REACT_APP_PORTAL_URL;
const Chance = require('chance');
const chance = new Chance();

const barChartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
      position: 'bottom',
    },
  },
  scales: {
    x: {
      ticks: {
        callback: function (value) {
          return this.getLabelForValue(value).substr(0, 15) + '...';
        },
      },
    },
  },
};

class ReportAppraisalGroup extends Component {
  state = {
    groupData: [],
    categoryData: [],
    selectedGroup: null,
    selectedCategory: null,
    selectedDateRange: {
      startDate: new Date(moment().subtract(1, 'years')),
      endDate: new Date(),
      key: 'selection',
    },
    showDateRange: false,
    appraisalData: [],
    appraisalDataUpdated: [],
    EmployeeGradeModalStatus: false,
    EmployeeGraphModalStatus: false,
    uniqueEmployees: [],
    allAppraisalData: [],
    allEmployeeDetails: [],
    allAppraisalDataEmployee: [],
    graphData: [],
    graphEmployeeData: [],
    employeeDetails: null,
    appraisalEmployeeDataUnique: [],
    appraisalEmployeeGraph: [],
    mainTopicAndSubTopic: null,
  };

  componentDidMount() {
    this.getGroup();
  }

  fetch = async (params) => {
    const { selectedGroup, selectedCategory } = this.state;
    if (!selectedGroup || !selectedCategory) {
      toast.error(t('Please select Employee, Group, and Category '));
      return;
    }
    let endDate = moment(this.state.selectedDateRange.endDate).format(
      'YYYY-MM-DD'
    );
    let startDate = moment(this.state.selectedDateRange.startDate).format(
      'YYYY-MM-DD'
    );
    try {
      const res = await GET('report-appraisal-group/generate-report', {
        params: {
          appraisalgrp_id: this.state.selectedGroup.value,
          appraisalcat_id: this.state.selectedCategory.value,
          from_date: startDate,
          to_date: endDate,
        },
      });
      if (res) {
        this.setState(
          {
            appraisalData: res.data,
          },
          () => this.handleReportAppraisalGroup()
        );
      }
    } catch (e) {
      console.log(e);
    }
  };

  openEmployeeGradeModal = (obj) => {
    this.setState({
      EmployeeGradeModalStatus: true,
    });
    this.handleLoadDataEmployeeDetails(obj);
  };
  openEmployeeGraphModal = (obj) => {
    this.setState({
      EmployeeGraphModalStatus: true,
    });
    this.handleLoadDataEmployeeDetails(obj);
  };

  closeEmployeeGradeModal = () => {
    this.setState({
      EmployeeGradeModalStatus: false,
    });
  };
  closeEmployeeGraphModal = () => {
    this.setState({
      EmployeeGraphModalStatus: false,
    });
  };
  getGroup = async () => {
    try {
      const res = await GET('report-appraisal-group/get-appraisal-group');
      this.setState({
        groupData: res.data.map((item) => ({
          label: item.appraisalgrp_code,
          value: item.appraisalgrp_id,
        })),
      });
    } catch (e) {
      console.log(e);
    }
  };
  handleGroupChange = async (obj) => {
    this.setState({
      selectedGroup: obj,
    });
    try {
      const res = await GET('report-appraisal-group/get-appraisal-category', {
        params: {
          appraisalgrp_id: obj.value,
        },
      });
      console.log('resCategory', res);
      this.setState({
        categoryData: res.data.map((item) => ({
          label: item.appraisalcat_code,
          value: item.appraisalcat_id,
        })),
      });
    } catch (e) {
      console.log(e);
    }
  };

  handleReportAppraisalGroup = () => {
    const uniqueEmployees = [];
    const uniqueAppraisalTopicData = [];
    const allAppraisalData = [];
    const allAppraisalDataEmployee = [];
    console.log('appraisalData', this.state.appraisalData);
    this.state.appraisalData.map((employee) => {
      if (!uniqueEmployees.includes(employee.appraisalempl_emloyee_id)) {
        uniqueEmployees.push(employee.appraisalempl_emloyee_id);
      }

      employee.appraisalempldetails.map((appraisalDetail) => {
        const isDuplicateTopicData = uniqueAppraisalTopicData.some(
          (uniqueDetail) =>
            uniqueDetail.appraisaltopic_id ===
              appraisalDetail.appraisaltopic_id &&
            uniqueDetail.appraisaltopicsub_id ===
              appraisalDetail.appraisaltopicsub_id
        );

        if (!isDuplicateTopicData) {
          uniqueAppraisalTopicData.push(appraisalDetail);
        }

        allAppraisalData.push({
          ...appraisalDetail,
          appraisalempl_emloyee_id: employee.appraisalempl_emloyee_id,
          employee_name: employee.employee_name,
          appraisalempl_date: employee.appraisalempl_date,
        });

        // For Charts of Each Employee
        const employeeData = allAppraisalDataEmployee.find(
          (data) =>
            data.appraisalempl_emloyee_id === employee.appraisalempl_emloyee_id
        );

        if (employeeData) {
          employeeData.data.push({
            ...appraisalDetail,
            appraisalempl_emloyee_id: employee.appraisalempl_emloyee_id,
            employee_name: employee.employee_name,
            appraisalempl_date: employee.appraisalempl_date,
          });
        } else {
          allAppraisalDataEmployee.push({
            appraisalempl_emloyee_id: employee.appraisalempl_emloyee_id,
            employee_name: employee.employee_name,
            data: [
              {
                ...appraisalDetail,
                appraisalempl_emloyee_id: employee.appraisalempl_emloyee_id,
                employee_name: employee.employee_name,
                appraisalempl_date: employee.appraisalempl_date,
              },
            ],
          });
        }
      });
      return employee;
    });

    uniqueAppraisalTopicData.sort(
      (a, b) => a.appraisaltopic_id - b.appraisaltopic_id
    );

    // return uniqueAppraisalTopicData ;
    this.setState({
      appraisalDataUpdated: uniqueAppraisalTopicData,
      uniqueEmployees: uniqueEmployees,
      allAppraisalData: allAppraisalData,
      allAppraisalDataEmployee: allAppraisalDataEmployee,
    });
  };
  removeDuplicates = (arr) => {
    return arr.filter((item, index) => arr.indexOf(item) === index);
  };

  groupSameValueInArray = (arr) => {
    const groupedData = arr.reduce((acc, current) => {
      const existingGroup = acc.find(
        (group) => group[0].empName === current.empName
      );
      if (existingGroup) {
        existingGroup.push(current);
      } else {
        acc.push([current]);
      }
      return acc;
    }, []);
    return groupedData;
  };
  handleLoadDataEmployeeDetails = (obj) => {
    console.log('item', obj);
    const allEmployeeDetails = [];
    const employeeName = [];
    let mainTopicAndSubTopicData = [];
    this.state.uniqueEmployees.map((v, i) => {
      this.state.allAppraisalData.map((vv, ii) => {
        if (
          obj.appraisaltopic_id === vv.appraisaltopic_id &&
          obj.appraisaltopicsub_id === vv.appraisaltopicsub_id &&
          v == vv.appraisalempl_emloyee_id
        ) {
          const emp = {
            empDate: vv.appraisalempl_date,
            empName: vv.employee_name,
            empGrade: vv.appraisalempl_grade + '/' + vv.max_grade,
            empComment: vv.appraisalempl_comments,
            empGradeResult: vv.appraisalempl_grade,
            empGradeMax: vv.max_grade,
          };
          mainTopicAndSubTopicData = {
            subTopic: vv.appraisaltopicsub_code,
            mainTopic: vv.appraisaltopic_code,
          };
          employeeName.push(vv.employee_name);
          allEmployeeDetails.push(emp);
        }
      });
    });
    const uniqueEmployeeName = this.removeDuplicates(employeeName);
    const uniqueAppraisalData = this.state.allAppraisalDataEmployee.filter(
      (v) => {
        return uniqueEmployeeName.includes(v.employee_name);
      }
    );

    const dataGrouped = this.groupSameValueInArray(allEmployeeDetails);
    this.setState({
      allEmployeeDetails: allEmployeeDetails,
      appraisalEmployeeDataUnique: uniqueAppraisalData,
      appraisalEmployeeGraph: dataGrouped,
      mainTopicAndSubTopic: mainTopicAndSubTopicData,
    });
  };

  render() {
    console.log('state', this.state);
    return (
      <>
        <div className='grey-section'>
          <Row className='align-items-center'>
            <Col md={11}>
              <Row className='align-items-center'>
                {/* DATE */}
                <Col md={4} className='position-relative pe-0'>
                  <InputGroup style={{ marginTop: 20 }}>
                    <Form.Control
                      onClick={() => this.setState({ showDateRange: true })}
                      style={{
                        paddingLeft: 20,
                        borderTopLeftRadius: 20,
                        borderBottomLeftRadius: this.state.showDateRange
                          ? 7
                          : 20,
                      }}
                      value={
                        moment(this.state.selectedDateRange.startDate).format(
                          'DD-MM-YYYY'
                        ) +
                        ' -- ' +
                        moment(this.state.selectedDateRange.endDate).format(
                          'DD-MM-YYYY'
                        )
                      }
                      readOnly
                    />
                    <Button
                      variant={this.state.showDateRange ? 'danger' : 'primary'}
                      style={{ width: 40 }}
                      onClick={() =>
                        this.setState({
                          showDateRange: !this.state.showDateRange,
                        })
                      }
                      id='button-addon2'>
                      <FontAwesomeIcon
                        icon={
                          this.state.showDateRange ? faXmark : faCalendarAlt
                        }
                        style={{
                          fontSize: 16,
                          color:
                            brand_colors[this.props.defaultTheme.theme_id]
                              .color8,
                        }}
                      />
                    </Button>
                  </InputGroup>
                  {this.state.showDateRange && (
                    <div
                      style={{
                        backgroundColor: '#fff',
                        padding: 10,
                        position: 'absolute',
                        border: '#F2F2F2 2px solid',
                        zIndex: 1,
                        top: 58,
                        borderRadius: 7,
                      }}>
                      <DateRangePicker
                        ranges={[this.state.selectedDateRange]}
                        onChange={(date) => {
                          this.setState({
                            selectedDateRange: {
                              startDate: new Date(date.selection.startDate),
                              endDate: new Date(date.selection.endDate),
                              key: 'selection',
                            },
                          });
                        }}
                        style={{ backgroundColor: '#fff' }}
                      />
                    </div>
                  )}
                </Col>
                <Col md={4} className='pe-0 custom-styled-form'>
                  <Form.Label>{t('Group')}</Form.Label>
                  <Select
                    classNamePrefix='custom-styled-select'
                    placeholder={t('Select Group')}
                    options={this.state.groupData}
                    // onChange={this.handleGroupChange ,this.setState({ showDateRange: false }) }
                    onChange={(e) => {
                      this.handleGroupChange(e);
                      this.setState({ showDateRange: false });
                    }}
                    value={this.state.selectedGroup}
                    menuPortalTarget={document.body}
                    styles={{
                      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    }}
                    formatOptionLabel={(option) => (
                      <div className='d-flex align-items-center'>
                        <span style={{ fontSize: 16 }} className='me-1'>
                          {option.label}
                        </span>
                        {option.reg_status == 1 && (
                          <Badge pill bg='success'>
                            {t('Active')}
                          </Badge>
                        )}
                      </div>
                    )}
                  />
                </Col>
                <Col md={4} className='pe-0 custom-styled-form'>
                  <Form.Label>{t('Category')}</Form.Label>
                  <Select
                    classNamePrefix='custom-styled-select'
                    placeholder={t('Select Category')}
                    options={this.state.categoryData}
                    onChange={(obj) => {
                      this.setState({ selectedCategory: obj });
                    }}
                    value={this.state.selectedTerm}
                    defaultValue={this.state.selectedTerm}
                    menuPortalTarget={document.body}
                    styles={{
                      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    }}
                    formatOptionLabel={(option) => (
                      <div className='d-flex align-items-center'>
                        <span style={{ fontSize: 16 }} className='me-1'>
                          {option.label}
                        </span>
                        {option.term_active == 1 && (
                          <Badge pill bg='success'>
                            {t('Active')}
                          </Badge>
                        )}
                      </div>
                    )}
                  />
                </Col>
              </Row>
            </Col>
            <Col style={{ marginTop: 15 }}>
              <Button
                variant={'success'}
                style={{
                  width: '100%',
                  borderTopRightRadius: 20,
                  borderBottomRightRadius: 20,
                }}
                onClick={() => this.fetch()}>
                {this.state.fetching ? (
                  <Spinner
                    as='span'
                    animation='grow'
                    size='sm'
                    role='status'
                    aria-hidden='true'
                  />
                ) : (
                  <FontAwesomeIcon
                    icon={faSearch}
                    style={{
                      fontSize: 16,
                      color:
                        brand_colors[this.props.defaultTheme.theme_id].color8,
                    }}
                  />
                )}
              </Button>
            </Col>
          </Row>
        </div>

        <div className='grey-section'>
          <Card className='border-0' style={{ borderRadius: '20px' }}>
            <Card.Body>
              <Row>
                <Col md={12}>
                  <Table hover responsive className='mb-0'>
                    <thead>
                      <tr>
                        <th>{t('Action')}</th>
                        <th>{t('Topic')}</th>
                        <th>{t('SubTopic')}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.appraisalDataUpdated.length <= 0 && (
                        <tr>
                          <td colSpan={3} className='text-center'>
                            {t('No data found')}
                          </td>
                        </tr>
                      )}
                      {this.state.appraisalDataUpdated.map((item) => {
                        return (
                          <tr>
                            <td style={{ width: 86 }}>
                              <Button
                                className='me-1'
                                title={t('Employee Grade')}
                                onClick={() =>
                                  this.openEmployeeGradeModal(item)
                                }
                                size='sm'>
                                <FontAwesomeIcon
                                  icon={
                                    this.state.EmployeeGradeModalStatus
                                      ? faCircleArrowUp
                                      : faCircleArrowDown
                                  }
                                />
                              </Button>
                              <Button
                                title={t('Graph')}
                                onClick={() =>
                                  this.openEmployeeGraphModal(item)
                                }
                                variant='info'
                                size='sm'>
                                <FontAwesomeIcon color='#fff' icon={faBook} />
                              </Button>
                            </td>
                            <td>
                              <div class='d-flex flex-column justify-content-between'>
                                <div>{item.appraisaltopic_code}</div>
                                <div>{item.appraisaltopic_desc}</div>
                                <div>{item.appraisaltopic_desc2}</div>
                              </div>
                            </td>
                            <td>
                              <div class='d-flex flex-column justify-content-between'>
                                <div>{item.appraisaltopicsub_code}</div>
                                <div>{item.appraisaltopicsub_desc}</div>
                                <div>{item.appraisaltopicsub_desc2}</div>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </div>

        {/* <AddEditEmployee /> */}
        <Modal
          backdrop='static'
          keyboard={false}
          size='xl'
          show={this.state.EmployeeGradeModalStatus}
          onHide={this.closeEmployeeGradeModal}>
          <Modal.Header closeButton>
            <Modal.Title>{t('Employee Details')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className='custom-styled-form'></Row>
            <hr />
            <Table size='sm'>
              <thead>
                <tr>
                  <th width='15%'>{t('Date')}</th>
                  <th width='15%'>{t('Employee')}</th>
                  <th width='10%'>{t('Grade')}</th>
                  <th width='60%'>{t('Comment')}</th>
                </tr>
              </thead>
              <tbody>
                {this.state.allEmployeeDetails.map((emp, index) => {
                  return (
                    <tr key={index}>
                      <td style={{ width: '15%' }}>{emp.empDate}</td>
                      <td style={{ width: '15%' }}>{emp.empName}</td>
                      <td style={{ width: '10%' }}>
                        <span className='badge badge-outline-success'>
                          {emp.empGrade}
                        </span>
                      </td>
                      <td style={{ width: '60%' }}>{emp.empComment}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Modal.Body>
        </Modal>

        <Modal
          backdrop='static'
          keyboard={false}
          size='xl'
          show={this.state.EmployeeGraphModalStatus}
          onHide={this.closeEmployeeGraphModal}>
          <Modal.Header closeButton>
            <Modal.Title>{t('Graph Details')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className='custom-styled-form'>
              <strong>
                {this.state.selectedGroup && this.state.selectedGroup.label} :{' '}
                {this.state.selectedCategory &&
                  this.state.selectedCategory.label}
              </strong>
              <br />
              <strong>
                {this.state.mainTopicAndSubTopic &&
                  this.state.mainTopicAndSubTopic.mainTopic}{' '}
                :{' '}
                {this.state.mainTopicAndSubTopic &&
                  this.state.mainTopicAndSubTopic.subTopic}
              </strong>
              <strong>
                {this.state.selectedDateRange &&
                  moment(this.state.selectedDateRange.endDate).format(
                    'YYYY-MM-DD'
                  ) +
                    '---' +
                    moment(this.state.selectedDateRange.endDate).format(
                      'YYYY-MM-DD'
                    )}
              </strong>
              <br />
            </Row>

            <hr />

            <Card
              className='border-0'
              style={{ borderRadius: '20px', marginBottom: 20 }}>
              <Card.Body>
                <div className='mb-1 text-center'>
                  <strong>{t('Employee')}</strong>
                </div>
                <Row>
                  {this.state.appraisalEmployeeGraph.map((item) => {
                    let empData = item || [];
                    if (empData.length > 1) {
                      const data = {
                        labels: empData.map((v) => v.empDate),
                        datasets: [
                          {
                            label: '# Max Grade',
                            data: empData.map((v) => v.empGradeMax),
                            borderWidth: 1,
                            type: 'line',
                            borderColor:
                              brand_colors[this.props.defaultTheme.theme_id]
                                .color11,
                          },
                          {
                            label: '# Grade',
                            data: empData.map((v) => v.empGradeResult),
                            borderWidth: 1,
                            type: 'bar',
                            backgroundColor:
                              brand_colors[this.props.defaultTheme.theme_id]
                                .sidebar_bg,
                          },
                        ],
                      };
                      return (
                        <Col md={4}>
                          <span style={{ marginLeft: 100 }}>
                            <strong>{empData[0].empName}</strong>
                          </span>
                          <div>
                            <Bar
                              options={barChartOptions}
                              data={data}
                              height={305}
                            />
                          </div>
                        </Col>
                      );
                    } else {
                      const data = {
                        labels: [empData[0].empDate],
                        datasets: [
                          {
                            label: '# Max Grade',
                            data: empData[0].empGradeMax,
                            borderWidth: 1,
                            type: 'line',
                            borderColor:
                              brand_colors[this.props.defaultTheme.theme_id]
                                .color11,
                          },
                          {
                            label: '# Grade',
                            data: empData[0].empGradeResult,
                            borderWidth: 1,
                            type: 'bar',
                            backgroundColor:
                              brand_colors[this.props.defaultTheme.theme_id]
                                .sidebar_bg,
                          },
                        ],
                      };
                      return (
                        <Col md={4}>
                          <span style={{ marginLeft: 100 }}>
                            <strong>{empData[0].empName}</strong>
                          </span>
                          <div>
                            <Bar
                              options={barChartOptions}
                              data={data}
                              height={305}
                            />
                          </div>
                        </Col>
                      );
                    }
                  })}
                </Row>
              </Card.Body>
            </Card>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  authData: state.auth.authData,
  selectedClass: state.selectedClass.data,
  defaultLanguage: state.language.defaultLanguage,
  defaultTheme: state.theme.defaultTheme,
});

export default connect(mapStateToProps, null)(ReportAppraisalGroup);
