import React, { Component } from 'react';
import { Container, Card, Table, Row, Col, Spinner, Image, Badge, Button, Form, Offcanvas, Accordion, Modal, ButtonGroup } from 'react-bootstrap';
import { t } from '../../helpers/translation_helper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp, faEnvelopeOpenText, faPlus, faPrint, faSave, faTrash, } from '@fortawesome/free-solid-svg-icons';
import { brand_colors } from '../../helpers/brand_colors_helper';
import { connect } from 'react-redux';
import axios from 'axios';
import { toast } from 'react-toastify';
import AssessLogRow from './AssessLogRow';
import Paginate from '../_partials/Paginate/_Paginate';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { GET } from '../../api';

// API URL
const apiURL = process.env.REACT_APP_API_URL;


class EYRepCard extends Component {
  state = {
    working: false,
    working_assess_logs: false,
    working_more_comments: false,
    working_EmailDetailObservation: false,
    working_EmailSummaryObservation: false,
    working_EmailLearningJournal: false,
    showComments: false,
    showAssessmentLogs: false,
    assrep_comm1: this.props.data.Assrep.assrep_comm1,
    assrep_comm2: this.props.data.Assrep.assrep_comm2,
    assrep_comm3: this.props.data.Assrep.assrep_comm3,
    assrep_comm4: this.props.data.Assrep.assrep_comm4,
    assessLogs: [],
    assessLogslimit: 12,
    assessLogsTotalRecords: 0,
    showMoreCommentsModal: false,
    moreComments: null
  }

  updateAssrep = async () => {

    this.setState({ working: true });

    const { authData } = this.props;
    const { auth_key } = authData.loginData;
    const { Assrep, AssrepHeader, childData } = this.props.data;
    const { assrep_id } = Assrep;
    const { assrep_comm1, assrep_comm2, assrep_comm3, assrep_comm4 } = this.state;

    const fData = new FormData();
    fData.append('assrep_id', assrep_id);
    fData.append('assrep_comm1', assrep_comm1);
    fData.append('assrep_comm2', assrep_comm2);
    fData.append('assrep_comm3', assrep_comm3);
    fData.append('assrep_comm4', assrep_comm4);

    try {
      const res = await axios.post(apiURL + 'student-tracker/update-assrep', fData, {
        params: {
          auth_key,
          appname: process.env.REACT_APP_NAME,
        }
      });

      if (res.data.type) {
        toast.success(t(res.data.message));
      }

      this.setState({ working: false });

    } catch (err) {
      console.log('err', err)
      toast.error(t('Something went wrong!'));
      this.setState({ working: false });
    }

  }

  getAssesslogData = async (limit = this.state.assessLogslimit, offset = 0) => {

    this.setState({
      working_assess_logs: true,
      showAssessmentLogs: true,
    });

    const { authData } = this.props;
    const { auth_key } = authData.loginData;
    const { center_id } = authData.centerData;
    const { Assrep, AssrepHeader, childData } = this.props.data;
    const { assrep_id, assrepheader_id } = Assrep;

    try {
      const res = await GET('student-tracker/get-assesslog-data', {
        params: {
          search: '',
          order: null,
          dir: null,
          limit,
          offset,
          assrepheader_id,
          assrep_id,
        }
      });

      if (res.status == 200) {
        this.setState({
          working_assess_logs: false,
          assessLogs: res.data.data,
          assessLogsTotalRecords: parseInt(res.data.total_count),
        });
      }

    } catch (err) {
      console.log('err', err)

      toast.error(t('Something went wrong!'));
      this.setState({ working_assess_logs: false });
    }

  }

  getAssrepDetails = async () => {
    this.setState({
      showMoreCommentsModal: true,
      moreComments: null
    });

    const { authData } = this.props;
    const { auth_key } = authData.loginData;
    const { center_id } = authData.centerData;
    const { Assrep, AssrepHeader, childData } = this.props.data;
    const { assrep_id, assrepheader_id } = Assrep;

    try {
      const res = await GET('student-tracker/get-assrep-details', {
        params: {
          assrepheader_id,
          assrep_id,
        }
      });

      if (res.status == 200) {
        this.setState({
          moreComments: res.data
        });
      }

    } catch (err) {
      console.log('err', err)

      toast.error(t('Something went wrong!'));
    }

  }

  addComment = () => {
    const comments = [...this.state.moreComments];
    comments.push({ assrep_detail_id: 0, assrep_detail_comment: '' });
    this.setState({ moreComments: comments });
  }

  delComment = (index) => {
    const comments = [...this.state.moreComments];
    comments.splice(index, 1);
    this.setState({ moreComments: comments });
  }


  saveAssrepDetails = async () => {

    this.setState({ working_more_comments: true });

    const { authData } = this.props;
    const { auth_key, user_id } = authData.loginData;
    const { center_id, center_timezone } = authData.centerData;
    const { Assrep, AssrepHeader, childData } = this.props.data;
    const { assrep_id, assrepheader_id } = Assrep;


    const fData = new FormData();
    fData.append('center_id', center_id);
    fData.append('center_timezone', center_timezone);
    fData.append('user_id', user_id);
    fData.append('assrep_id', assrep_id);
    fData.append('assrepheader_id', assrepheader_id);
    fData.append('assrep_details', JSON.stringify(this.state.moreComments));

    try {
      const res = await axios.post(apiURL + 'student-tracker/save-assrep-details', fData, {
        params: {
          auth_key,
          appname: process.env.REACT_APP_NAME,
        }
      });

      if (res.data.type) {
        toast.success(t(res.data.message));
      }

      this.setState({ working_more_comments: false, showMoreCommentsModal: false });

    } catch (err) {
      console.log('err', err)
      toast.error(t('Something went wrong!'));
      this.setState({ working_more_comments: false });
    }

  }


  emailReport = async (type, working) => {

    this.setState({ [working]: true });

    const { authData } = this.props;
    const { auth_key } = authData.loginData;
    const { center_id } = authData.centerData;
    const { Assrep, AssrepHeader, childData } = this.props.data;
    const { assrep_id, assrepheader_id } = Assrep;
    const progass = this.props.progass.map((item) => item.value);

    try {
      const res = await GET(type, {
        params: {
          assrepheader_id,
          assrep_id,
          toggle_comment_page: this.state.showComments,
          mail: true,
          select_progass: JSON.stringify(progass),
        }
      });

      if (res.status == 200) {
        toast.success(t(res.data.message));
      }

      this.setState({ [working]: false });

    } catch (err) {
      console.log('err', err)

      toast.error(t('Something went wrong!'));
      this.setState({ [working]: false });
    }

  }

  render() {

    const { Assrep, AssrepHeader, childData } = this.props.data;
    const { child_id, child_name, parent_id, parent_name } = childData;
    const { assrep_id, assrepheader_id } = Assrep;

    const { authData } = this.props;
    const { auth_key } = authData.loginData;
    const { center_id } = authData.centerData;

    const progass = this.props.progass.map((item) => item.value);

    var qs_obj = {
      assrep_id,
      assrepheader_id,
      toggle_comment_page: this.state.showComments,
      auth_key,
      center_id
    };

    const qs1 = apiURL + 'pdfgenerator/ey-report-1?' + new URLSearchParams(qs_obj).toString() + '&select_progass=' + JSON.stringify(progass);
    const qs2 = apiURL + 'pdfgenerator/ey-report-2?' + new URLSearchParams(qs_obj).toString() + '&select_progass=' + JSON.stringify(progass);

    return (
      <Card className='ass-rep-card'>
        <Card.Body>
          <Row>
            <Col md={4} className='d-flex align-items-center'><h5 className='m-0'>{child_name}</h5></Col>
            <Col className='d-flex justify-content-end'>
              {this.props.reportType === 'EY Report 1' &&
                <ButtonGroup size="sm">
                  <Button disabled={this.state.working_EmailSummaryObservation} onClick={() => this.emailReport('pdfgenerator/ey-report-1', 'working_EmailSummaryObservation')}>
                    {this.state.working_EmailSummaryObservation ? <Spinner as='span' animation='grow' size='sm' role='status' aria-hidden='true' /> :
                      <FontAwesomeIcon icon={faEnvelopeOpenText} color={brand_colors[this.props.defaultTheme.theme_id].color8} />
                    }
                  </Button>
                  <Button variant='info' onClick={() => window.open(qs1, "PDF" + "_SummaryObservation_" + assrepheader_id + "_" + assrep_id)}><FontAwesomeIcon icon={faPrint} color={brand_colors[this.props.defaultTheme.theme_id].color8} /></Button>
                </ButtonGroup>
              }
              {this.props.reportType === 'EY Report 2' &&
                <ButtonGroup size="sm">
                  <Button disabled={this.state.working_EmailSummaryObservation} onClick={() => this.emailReport('pdfgenerator/ey-report-2', 'working_EmailSummaryObservation')}>
                    {this.state.working_EmailSummaryObservation ? <Spinner as='span' animation='grow' size='sm' role='status' aria-hidden='true' /> :
                      <FontAwesomeIcon icon={faEnvelopeOpenText} color={brand_colors[this.props.defaultTheme.theme_id].color8} />
                    }
                  </Button>
                  <Button variant='info' onClick={() => window.open(qs2, "PDF" + "_SummaryObservation_" + assrepheader_id + "_" + assrep_id)}><FontAwesomeIcon icon={faPrint} color={brand_colors[this.props.defaultTheme.theme_id].color8} /></Button>
                </ButtonGroup>
              }
            </Col>
          </Row>
        </Card.Body>
      </Card>
    );
  }
}

const mapStateToProps = (state) => ({
  authData: state.auth.authData,
  defaultLanguage: state.language.defaultLanguage,
  selectedClass: state.selectedClass.data,
  children: state.children.list,
  defaultTheme: state.theme.defaultTheme,
});

export default connect(mapStateToProps, null)(EYRepCard);