import React, { Component } from 'react';
import { Container, Card, Row, Col, Button, Badge, Modal, Form, Spinner, Offcanvas, Alert, ButtonGroup, Image } from 'react-bootstrap';
import { t } from '../../helpers/translation_helper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faHomeUser, faPencil, faPlus, faArrowAltCircleLeft, faFilePdf, faTimesCircle, faCheck, faBan, faTrash, faChevronRight, faChevronDown, faChevronUp, faCheckCircle, faCircleDot, faArrowsTurnToDots } from '@fortawesome/free-solid-svg-icons';
import { connect } from 'react-redux';
import { setAddEditClassModal } from '../../redux/slices/addEditClassModalSlice';
import axios from 'axios';
import { toast } from 'react-toastify';
import { brand_colors } from '../../helpers/brand_colors_helper';
import Subjects from '../Subjects/_Subjects';
import { faThumbsDown } from '@fortawesome/free-regular-svg-icons';
import { Link } from 'react-router-dom';
import GeneralCard from '../../helpers/GeneralCard';
import { GET } from '../../api';
import SweetAlert from 'react-bootstrap-sweetalert';
import { changeColorOpacity, trimThisDown } from '../../helpers/general_helpers';

const portalURL = process.env.REACT_APP_PORTAL_URL;
const apiURL = process.env.REACT_APP_API_URL;

class GradeLevelItem extends Component {

  state = {
    classes: null,
    addNewSubject: false,
  }

  componentDidMount() {
    if (this.props.clssmainitem_grp.show && this.props.clssmainitem_grp.target == 'class') {
      this.classesGet();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    console.log('GradeLevelItem', this.props);
    if (prevProps.clssmainitem_grp.show !== this.props.clssmainitem_grp.show) {
      if (this.props.clssmainitem_grp.show && this.props.clssmainitem_grp.target == 'class' && this.props.clssmainitem_grp.index == this.props.index) {
        console.log('here1');
        this.classesGet();
      }
    } else if (prevProps.clssmainitem_grp.classRefreshTime.unix() != this.props.clssmainitem_grp.classRefreshTime.unix() && this.props.clssmainitem_grp.show && this.props.clssmainitem_grp.target == 'class' && this.props.clssmainitem_grp.index == this.props.index) {
      console.log('here2');
      this.classesGet();
    }
  }

  classesGet = async () => {
    this.setState({ classes: null });
    const request_params = {
      limit: 20,
      offset: 0,
      agegroup_id: this.props.data.agegroup_id
    };

    try {
      const res = await GET('classes/get', {
        params: request_params,
      });

      if (res) {
        this.setState({ classes: res.data.data });
      }

    } catch (err) {
      console.log('err', err)
      toast.error(t('Something went wrong!'));

    }
  }

  edit = () => {
    this.props.modalAgeGroup.toggle('Edit Grade', { ...this.props.data })
  }

  deleteClass = () => {
    GET('classes/delete', {
      params: {
        class_id: this.props.deleteModal.id
      },
    }).then((res) => {
      if (res.data.type) {
        this.classesGet()
        this.props.deleteModal.toggle({ ...this.props.deleteModal, show: false, id: null, action: () => { } })
      }
      toast[res.data.messageType](t(res.data.message));
    })
  }

  render() {
    const { defaultTheme, last, authData } = this.props
    const { agegroup_id, agegroup_code, gcycle_id, agegroup_desc, agegroup_color, agegroup_type } = this.props.data;
    const { role_id } = authData.loginData

    let actions = [
      // { type: 'status', name: 'Status', image: null, iconName: faThumbsDown, iconColor: brand_colors[this.props.defaultTheme.theme_id].color11, onClick: () => { } },
      { type: 'edit', name: 'Edit', image: require('../../assets/images/edit.png'), iconName: null, iconColor: brand_colors[this.props.defaultTheme.theme_id].color16, onClick: () => this.edit() },
      // { type: 'delete', name: 'Delete', image: require('../../assets/images/remove-red.png'), iconName: null, iconColor: brand_colors[this.props.defaultTheme.theme_id].color11, onClick: () => { } },
    ]
    return (
      <div key={this.props.key} style={{
        padding: '1rem', minWidth: '30vw', backgroundColor: brand_colors[this.props.defaultTheme.theme_id].color8, borderRadius: '1rem', margin: '1rem 0', position: 'relative', boxShadow: '-1px 2px 16px 2px rgba(0,0,0,0.1)',
      }}>
        <div className='d-flex flex-row justify-content-between align-items-center'>
          <div className='d-flex flex-row align-items-center justify-content-between pe-2 me-2' style={{ flex: 1.5, borderRight: 'solid 3px ' + brand_colors[this.props.defaultTheme.theme_id].color7 }}>
            <div>
              <div>
                <strong style={{ fontSize: '1rem', color: agegroup_color }}>{agegroup_code}</strong>
              </div>
            </div>
            <div className='d-flex flex-row'>
              {gcycle_id && <div className='me-1' style={{ backgroundColor: brand_colors[this.props.defaultTheme.theme_id].color4_light, borderRadius: '1rem', color: brand_colors[this.props.defaultTheme.theme_id].color4, padding: '0.1rem 0.6rem', fontSize: 13 }}>{gcycle_id.gcycle_desc}</div>}
              {agegroup_type && <div style={{ backgroundColor: changeColorOpacity(brand_colors[this.props.defaultTheme.theme_id].color1, 0.1), borderRadius: '1rem', color: brand_colors[this.props.defaultTheme.theme_id].color1, padding: '0.1rem 0.6rem', fontSize: 13 }}>{agegroup_type.label}</div>}
            </div>
          </div>
          <div className='d-flex flex-row align-items-center' style={{ flex: 1, }}>
            {agegroup_desc &&
              <div style={{ fontSize: '0.8rem', color: brand_colors[this.props.defaultTheme.theme_id].color9, opacity: 0.8 }}>
                {trimThisDown(agegroup_desc, 50)}
              </div>
            }
          </div>
          <div className='d-flex justify-content-end align-items-center' style={{ flex: 1 }}>

            {/* CLASS */}
            {(([1, 8, 22].includes(role_id)) || (!gcycle_id && ![15].includes(role_id)) || (gcycle_id && gcycle_id.gcycle_id == 1 && [15].includes(role_id))) &&
              <div className='d-flex flex-row align-items-center me-3 cursor-pointer' style={{ backgroundColor: (this.props.clssmainitem_grp.show && this.props.clssmainitem_grp.target == 'class') ? brand_colors[this.props.defaultTheme.theme_id].color4_light : brand_colors[this.props.defaultTheme.theme_id].color7, borderRadius: '1rem', padding: '0.2rem 0.8rem' }} onClick={() => {

                if (this.props.clssmainitem_grp.target != 'class') {
                  this.props.toggleClassmainitemGroup(this.props.index, true, 'class')
                } else {
                  this.props.clssmainitem_grp.show ? this.props.toggleClassmainitemGroup(this.props.index, false, 'class') : this.props.toggleClassmainitemGroup(this.props.index, true, 'class')
                }
              }}>
                <div className='d-flex align-items-center justify-content-center me-2' style={{ backgroundColor: brand_colors[this.props.defaultTheme.theme_id].color8, borderRadius: '100%', height: '1.1rem', width: '1.1rem' }}>
                  {(this.props.clssmainitem_grp.show && this.props.clssmainitem_grp.target == 'class') && <FontAwesomeIcon icon={faCircleDot} color={brand_colors[this.props.defaultTheme.theme_id].color4} style={{ fontSize: '1rem', }} />}
                </div>
                <div style={{ color: (this.props.clssmainitem_grp.show && this.props.clssmainitem_grp.target == 'class') ? brand_colors[this.props.defaultTheme.theme_id].color4 : brand_colors[this.props.defaultTheme.theme_id].color18, fontWeight: '600', fontSize: '1rem' }}>
                  {t('Class')}
                </div>
              </div>
            }
            {/* SUBJECT */}
            {/* {gcycle_id && gcycle_id.gcycle_id != 1 && gcycle_id.gcycle_id != 0 && */}
            <div className='d-flex flex-row align-items-center me-3 cursor-pointer' style={{ backgroundColor: (this.props.clssmainitem_grp.show && this.props.clssmainitem_grp.target == 'subject') ? brand_colors[this.props.defaultTheme.theme_id].color4_light : brand_colors[this.props.defaultTheme.theme_id].color7, borderRadius: '1rem', padding: '0.2rem 0.8rem' }} onClick={() => {
              if (this.props.clssmainitem_grp.target != 'subject') {
                this.props.toggleClassmainitemGroup(this.props.index, true, 'subject')
              } else {
                this.props.clssmainitem_grp.show ? this.props.toggleClassmainitemGroup(this.props.index, false, 'subject') : this.props.toggleClassmainitemGroup(this.props.index, true, 'subject')
              }
            }}>
              <div className='d-flex align-items-center justify-content-center me-2' style={{ backgroundColor: brand_colors[this.props.defaultTheme.theme_id].color8, borderRadius: '100%', height: '1.1rem', width: '1.1rem' }}>
                {(this.props.clssmainitem_grp.show && this.props.clssmainitem_grp.target == 'subject') && <FontAwesomeIcon icon={faCircleDot} color={brand_colors[this.props.defaultTheme.theme_id].color4} style={{ fontSize: '1rem', }} />}
              </div>
              <div style={{ color: (this.props.clssmainitem_grp.show && this.props.clssmainitem_grp.target == 'subject') ? brand_colors[this.props.defaultTheme.theme_id].color4 : brand_colors[this.props.defaultTheme.theme_id].color18, fontWeight: '600', fontSize: '1rem' }}>
                {t('Subject')}
              </div>
            </div>
            {/* } */}

            {/* ACTIONS */}
            {(([1, 8, 22].includes(role_id)) || (!gcycle_id && ![15].includes(role_id)) || (gcycle_id && gcycle_id.gcycle_id == 1 && [15].includes(role_id))) && actions.map((v, i) => {
              return <div key={i} style={{ margin: '0 0.5rem' }} className='cursor-pointer' title={v.name} onClick={v.onClick}>
                {v.image && <Image src={v.image} style={{ width: '1.5rem', height: '1.5rem' }} />}
                {v.iconName && <FontAwesomeIcon icon={v.iconName} color={v.iconColor} style={{ fontSize: '1.45rem', }} />}
              </div>
            })}
          </div>
        </div>
        {/* SHOW SUBJECTS */}
        {this.props.clssmainitem_grp.show && this.props.clssmainitem_grp.target == 'subject' &&
          <Col md={12} className='pt-3'>
            <Subjects showAddEditForm={this.state.addNewSubject} agegroup_id={agegroup_id} />
          </Col>
        }
        {/* SHOW CLASSES */}
        {this.props.clssmainitem_grp.show && this.props.clssmainitem_grp.target == 'class' &&
          <Col md={12}>

            <Row className='my-3'>
              <Col md={12}>
                <div className='grey-section-header'>
                  <span style={{ fontWeight: 600, opacity: 0.8 }}>{t('Classes')}</span>
                  <div>
                    <Button onClick={() => {
                      if (!this.props.clssmainitem_grp.show) {
                        this.props.toggleClassmainitemGroup(this.props.index, true, 'class')
                      }
                      this.props.setAddEditClassModal({ showModal: true, agegroup_id, gcycle_id: (gcycle_id) ? gcycle_id.gcycle_id : 0, class_id: 0, isEditing: false })
                    }} className='btn-sm btn-add' variant='success'>
                      {t('Add Class')}{' '}
                      <FontAwesomeIcon icon={faPlus} color={brand_colors[this.props.defaultTheme.theme_id].color8} style={{ fontSize: 16, }} />
                    </Button>
                  </div>
                </div>
              </Col>
              {this.state.classes
                ? <>
                  {this.state.classes.length > 0
                    ? <>
                      {this.state.classes.map((v, i) => {

                        let data = {
                          allData: v,
                          name: v.class_theme,
                          text1: v.class_section,
                          status: (v.status == 1 == 1) ? true : false,
                          description: v.class_desc,
                          // linkTo: '/subject/lessons/' + v.acs_name,
                          // linkToStates: { as_id: v.as_id, acs_id: v.acs_id, ab_id: v.ab_id, },
                          cardImage: (v.class_logo) ? portalURL + v.center_id + '/classes/' + v.class_logo : require('../../assets/images/noimage.png'),
                          actions: [
                            {
                              type: 'edit', name: 'Edit', image: require('../../assets/images/edit.png'), iconName: null, iconColor: brand_colors[this.props.defaultTheme.theme_id].color16, onClick: () => this.props.setAddEditClassModal({ showModal: true, agegroup_id, gcycle_id: (gcycle_id) ? gcycle_id.gcycle_id : 0, class_id: v.class_id, isEditing: true })
                            },
                            {
                              type: 'delete', name: 'Delete', image: require('../../assets/images/remove-red.png'), iconName: null, iconColor: brand_colors[this.props.defaultTheme.theme_id].color11, onClick: () =>
                                this.props.deleteModal.toggle({ ...this.props.deleteModal, show: true, id: v.class_id, action: () => this.deleteClass() })
                            },
                            {
                              type: 'move', name: 'Move to Grade', image: null, iconName: faArrowsTurnToDots, iconColor: brand_colors[this.props.defaultTheme.theme_id].color2, onClick: () =>
                                this.props.modalMoveClassGrade.toggle('Move to Grade', v)
                            },
                          ],

                        }
                        return <GeneralCard data={data} key={i} />
                      })}
                    </>
                    : <Col md={12} className='mb-4 text-center' >
                      {t('No Classes Found')}
                    </Col>}
                </>
                : <Col md={12} className='mb-4 text-center' >
                  <Spinner as='span' animation='grow' size='sm' /> {t('Loading Classes')}
                </Col>}
            </Row>
          </Col>
        }
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  authData: state.auth.authData,
  defaultTheme: state.theme.defaultTheme,
});

const mapDispatchToProps = () => ({
  setAddEditClassModal,
});

export default connect(mapStateToProps, mapDispatchToProps())(GradeLevelItem);
