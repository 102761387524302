import React, { Component } from 'react';
import { Container, Card, Row, Col, Offcanvas, Spinner } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { t } from '../../../helpers/translation_helper';
import { connect } from 'react-redux';
import { GET } from '../../../api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowAltCircleLeft,
  faComment,
  faSearch,
} from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { trimThisDown } from '../../../helpers/general_helpers';
import { brand_colors } from '../../../helpers/brand_colors_helper';

class Notifications extends Component {

  state = {
    fetching: false,
    notifications: [],
  }

  componentDidUpdate(prevProps) {
    if (prevProps.notificationModal !== this.props.notificationModal && this.props.notificationModal) {
      this.getNotifications();
    }
  }

  getNotifications = async () => {
    this.setState({
      fetching: true,
    });
    try {
      const res = await GET('notification/get', {
        params: {
          only_count: 0
        }
      });
      this.setState({
        fetching: false,
        notifications: res.data.data.map((v, i) => {
          return { ...v, showMore: false }
        })
      });
    } catch (err) {
      console.log(err);
    }
  }

  renderNotification = (data, index) => {
    let notification;
    if (data.notification_type === 'comment') {
      notification = <div className='notification-card'>
        <div key={index} className='notification-data d-flex flex-column'>
          <small style={{ fontSize: 13, fontStyle: 'italic' }} className='text-muted' title={moment(data.notification_timestamp).format('DD-MM-YYYY - hh:mm A')}>
            {moment(data.notification_timestamp).fromNow()}
          </small>
          <NavLink to={`/user-timeline?ut_id=${data.ut_id}&t=${moment().valueOf()} `} onClick={() => this.props.handleModal('notificationModal')} >
            <strong>{data.notification_from}</strong> posted a <strong>{data.notification_type}</strong> on <strong>{data.notification_title}</strong>
          </NavLink>
          {(data.notification_data.length <= 50)
            ? <div>
              <span><em>"{data.notification_data}"</em></span>
            </div>
            : (data.showMore)
              ? <div>
                <span><em>"{data.notification_data}"</em></span>
                <span className='ms-2 badge badge-light-warning' onClick={() => {
                  let notifications = this.state.notifications
                  notifications[index]['showMore'] = !notifications[index]['showMore']
                  this.setState({ notifications })
                }}>{t('Show Less')}</span>
              </div>
              : <div>
                <span><em>"{trimThisDown(data.notification_data, 50)}"</em></span>
                <span className='ms-2 badge badge-light-info' onClick={() => {
                  let notifications = this.state.notifications
                  notifications[index]['showMore'] = !notifications[index]['showMore']
                  this.setState({ notifications })
                }}>{t('Show More')}</span>
              </div>}
        </div>
        <NavLink to={`/user-timeline?ut_id=${data.ut_id}&t=${moment().valueOf()} `} onClick={() => this.props.handleModal('notificationModal')} className='notification-type' style={{ backgroundColor: brand_colors[this.props.defaultTheme.theme_id].color4 }}>
          <FontAwesomeIcon icon={faComment} color={'#fff'} />
        </NavLink>
      </div>
    }
    return notification;
  }

  render() {
    return (
      <Offcanvas show={this.props.notificationModal} onHide={() => this.props.handleModal('notificationModal')} placement={'end'} >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>{t('Notifications')}</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body style={{
          backgroundColor: '#fdfbfb'
        }}>
          {this.state.fetching &&
            <div className='text-center'><Spinner as='span' animation='grow' size='sm' /></div>
          }
          {!this.state.fetching && this.state.notifications.length == 0 &&
            t('No new notifications')
          }

          {!this.state.fetching && this.state.notifications.length > 0 && React.Children.toArray(this.state.notifications.map((item, index) => this.renderNotification(item, index)))}
        </Offcanvas.Body>
      </Offcanvas>
    );
  }
}

const mapStateToProps = (state) => ({
  authData: state.auth.authData,
  defaultLanguage: state.language.defaultLanguage,
  defaultTheme: state.theme.defaultTheme,
});

export default connect(mapStateToProps, null)(Notifications);
