import { Component } from 'react';
import { Form, Button, Spinner, Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import {
  setAuthentication,
  setFirebaseToken,
} from '../../redux/slices/authSlice';
import {
  setLanguages,
  setDefaultLanguage,
  setLangLabels,
  refreshLangLabels,
} from '../../redux/slices/langSlice';
import { setDefaultTheme } from '../../redux/slices/themeSlice';
import { setSelectedClass } from '../../redux/slices/selectedClassSlice';
import { toast } from 'react-toastify';
import logo from '../../assets/images/logo.png';
import axios from 'axios';
import { t } from '../../helpers/translation_helper';
import { getMessaging, getToken, onMessage, isSupported } from 'firebase/messaging';
import { firebaseApp } from '../../services/firebase';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { brand_colors } from '../../helpers/brand_colors_helper';
import { themes } from '../../constants/themes';
import Slider from "react-slick";

const appName = process.env.REACT_APP_NAME;
const apiURL = process.env.REACT_APP_API_URL;
const portalURL = process.env.REACT_APP_PORTAL_URL

/**
 * Login Component
 */
class Login extends Component {
  /**
   * @constructor
   * @param {any} props
   */
  constructor(props) {
    super(props);

    this.state = {
      working: false,
      username: '',//grace
      password: '',//Ol@l@
      langLabels: [],
      langOffset: 0,
      loader: false,
      showPass: false,
      redirectToDashboard: false,
      authData: null,
      showBannerModal: false,
    };
  }

  /**
   * Component Did Mount
   */
  componentDidMount() {
    this.props.refreshLangLabels(this.state.langOffset)

    axios.get(apiURL + 'language/get-languages').then((res) => {
      if (res.data.type) {
        this.props.setLanguages(res.data.data);
        const defaultLanguage = res.data.data.find((item) => {
          return item.lang_name == 'English';
        });
        this.props.setDefaultLanguage(defaultLanguage);
      } else {
        toast.error(t('Something went wrong!'));
      }
    });
  }

  /**
   * Handle Field
   * @param {event} e Event
   * @param {string} field Field name
   */
  handleField = (e, field) => {
    this.setState({ [field]: e.target.value });
  };

  /**
   * Handle Login
   * @param {event} e Event
   */
  handleLogin = async (e) => {
    e.preventDefault();

    this.setState({ working: true });

    const { username, password } = this.state;

    if (username == '' || password == '') {
      this.setState({ working: false });
      toast.error('Username and password is required.');
      return;
    }

    try {
      const res = await axios.get(apiURL + 'login', {
        withCredentials: true,
        auth: {
          username: username,
          password: password,
        },
        params: {
          appname: process.env.REACT_APP_NAME
        }
      });

      if (res.data.type) {
        // if (res.data.data.classes.length > 0) {
        this.setState({ working: false });
        const { setAuthentication, setSelectedClass, setFirebaseToken } = this.props;

        let isBrowserSupported = await isSupported()
        if (isBrowserSupported) {
          const messaging = getMessaging(firebaseApp);
          getToken(messaging, {
            vapidKey:
              'BJ3u-j-0W2m1it06nryDvW8dV9X7uzl6i9la_lyEKPLYkmZHVxqGpCwF8l-vXCHx6sAcOa3O2WGnVdAsVbA2-Rc',
          })
            .then((currentToken) => {
              if (currentToken) {
                setFirebaseToken(currentToken);
                this.registerFirebase(currentToken, res.data.data.centerData.center_id, res.data.data.centerData.center_timezone, res.data.data.loginData.user_id, res.data.data.loginData.role_id, res.data.data.loginData.auth_key);
              } else {
                console.log(
                  'No registration token available. Request permission to generate one.'
                );
              }
            })
            .catch((err) => {
              console.log(
                'An error occurred while retrieving token. ',
                err
              );
            });
        }
        const role = res.data.data.loginData.role_id.toString();

        if (res.data.data.stdbanners || (res.data.data.educorebanner && JSON.parse(res.data.data.educorebanner.roles).includes(role))) {

          this.setState({
            authData: res.data.data,
            showBannerModal: true
          });

        } else {
          setAuthentication(res.data.data);
        }

        setSelectedClass(res.data.data.classes[0]);


        // } else {
        //   this.setState({ working: false });
        //   toast.error(
        //     'You are not assigned to any class. Please contact Admin!'
        //   );
        // }
      } else {
        this.setState({ working: false });
        toast.error(res.data.message);
      }
    } catch (err) {
      console.log('err', err)
      this.setState({ working: false });
      if (err.response) {
        let resData = err.response.data
        if (resData) {
          toast.error(resData.message);
        } else {
          toast.error('Something went wrong!');
        }
      } else {
        toast.error('Something went wrong!');
      }
    }
  };

  /**
   * Register Firebase
   * @param {String} currentToken
   * @param {Number} center_id
   * @param {Number} center_timezone
   * @param {Number} user_id
   * @param {Number} role_id
   * @param {String} auth_key
   */
  registerFirebase = async (
    currentToken,
    center_id,
    center_timezone,
    user_id,
    role_id,
    auth_key
  ) => {
    let fData = new FormData();
    fData.append('currentToken', currentToken);
    fData.append('center_id', center_id);
    fData.append('center_timezone', center_timezone);
    fData.append('user_id', user_id);
    fData.append('role_id', role_id);

    try {
      const res = await axios.post(
        apiURL + 'authentication/register-firebase',
        fData,
        {
          params: {
            auth_key: auth_key,
            appname: process.env.REACT_APP_NAME,
          },
        }
      );
      // 
    } catch (err) {
      console.log('err', err)
      toast.error(t('Something went wrong while saving token!'));

    }
  };

  /**
   * Render Component
   * @returns {HTMLElement}
   */
  render() {
    const { working, username, password, loader } = this.state;

    let bannerRole = false;

    if (this.state.authData && this.state.authData.educorebanner) {
      let bannerRoles = JSON.parse(this.state.authData.educorebanner.roles);
      const role = this.state.authData.loginData.role_id.toString();
      bannerRole = bannerRoles.includes(role);
    }

    console.log('bannerRole', bannerRole);

    var settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 3576,
      pauseOnHover: false
    };

    if (loader) {
      return (
        <div id='loader'>
          <img src={require('../../assets/images/iedu-loader.gif')} alt='iEducore' width='120' />
          {t('Please wait...')}
          <br />
          {t('Setting up Academic Portal')}
        </div>
      );
    } else {
      return (
        <div id='login-form'>
          <div className='text-center'>
            <img src={logo} alt='iEducore' width='200' />
          </div>
          <Form onSubmit={this.handleLogin}>
            <Form.Group className='mb-3' controlId='formBasicEmail'>
              <Form.Label>{t('Username')}</Form.Label>
              <Form.Control
                value={username}
                type='text'
                placeholder='User Name'
                onChange={(e) =>
                  this.handleField(e, 'username')
                }
                autoComplete={'username'}
              />
            </Form.Group>
            <Form.Group
              style={{ position: 'relative' }}
              className='mb-3'
              controlId='formBasicPassword'>
              <Form.Label>{t('Password')}</Form.Label>
              <Form.Control
                value={password}
                type={this.state.showPass ? 'text' : 'password'}
                placeholder='Password'
                onChange={(e) =>
                  this.handleField(e, 'password')
                }
                autoComplete={'password'}
              />
              <div style={{ position: 'absolute', bottom: 5, right: 10 }}>
                <FontAwesomeIcon
                  icon={(this.state.showPass) ? faEyeSlash : faEye}
                  color={(this.state.showPass) ? brand_colors[this.props.defaultTheme.theme_id].color18 : brand_colors[this.props.defaultTheme.theme_id].color4}
                  style={{ fontSize: 16, cursor: 'pointer' }}
                  onClick={() => this.setState({ showPass: !this.state.showPass })}
                />
              </div>

            </Form.Group>
            <Button
              variant='secondary'
              type='submit'
              disabled={working}>
              {t('Login')}{' '}
              {working ? (
                <Spinner
                  as='span'
                  animation='grow'
                  size='sm'
                  role='status'
                  aria-hidden='true'
                />
              ) : (
                ''
              )}
            </Button>
          </Form>
          {this.state.authData &&
            <Modal id="login-banner" size='xxl' centered show={this.state.showBannerModal}>
              <Modal.Body>
                <Slider {...settings}>
                  {this.state.authData.stdbanners &&
                    <div>
                      <div className='d-flex flex-column align-items-center'>
                        <img width="400" src={`${portalURL + this.state.authData.centerData.center_id}/stdbanners/${this.state.authData.stdbanners.picture}`} alt='Banner' /><br />
                        <strong>{this.state.authData.stdbanners.stdb_name}</strong>
                      </div>
                    </div>
                  }
                  {(this.state.authData.educorebanner && bannerRole) &&
                    <div>
                      <div className='d-flex flex-column align-items-center'>
                        <img width="400" src={`${portalURL}2/educorebanners/${this.state.authData.educorebanner.picture}`} alt='Banner' /><br />
                        <div dangerouslySetInnerHTML={{ __html: this.state.authData.educorebanner.banner_name }}></div>
                      </div>
                    </div>
                  }
                </Slider>
              </Modal.Body>
              <Modal.Footer>
                <button className='btn' style={{
                  backgroundColor: '#15bbe7',
                  color: 'white'
                }} onClick={() => this.props.setAuthentication(this.state.authData)}>
                  {t('Proceed to Dashboard')}
                </button>
              </Modal.Footer>
            </Modal>
          }
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => ({
  defaultTheme: state.theme.defaultTheme,
  langLabels: state.language.langLabels,
  langRefreshing: state.language.langRefreshing,
});

const mapDispatchToProps = () => ({
  setAuthentication,
  setLanguages,
  setDefaultLanguage,
  setDefaultTheme,
  setLangLabels,
  setSelectedClass,
  setFirebaseToken,
  refreshLangLabels,

});


export default connect(mapStateToProps, mapDispatchToProps())(Login);
