import React, { Component } from 'react';
import { Container, Card, Table, Row, Col, Spinner, Image, Badge, Button, Form, Offcanvas, Dropdown, Modal, InputGroup } from 'react-bootstrap';
import { t } from '../../helpers/translation_helper';
import NavBar from '../_partials/NavBar/_NavBar';
import Header from '../_partials/Header/_Header';
import { connect } from 'react-redux';
import axios from 'axios';
import { toast } from 'react-toastify';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight, faAnglesLeft, faAnglesRight, faCalendarAlt, faCheck, faCheckCircle, faCheckDouble, faClock, faEdit, faEllipsisV, faExternalLink, faFileAlt, faFilterCircleXmark, faGear, faGripVertical, faList, faPaperclip, faPencil, faPlus, faSearch, faTimes, faTrashAlt, faTrashCan, faXmark, } from '@fortawesome/free-solid-svg-icons';
import { Scrollbar } from 'react-scrollbars-custom';
import imgDescription from '../../assets/images/description.png';
import { brand_colors } from '../../helpers/brand_colors_helper';
import SweetAlert from 'react-bootstrap-sweetalert';
import { Link } from 'react-router-dom';
import { faCircle, faThumbsDown, faThumbsUp } from '@fortawesome/free-regular-svg-icons';
import { handleFormErrors, readFile } from '../../helpers/form_helpers';
import { colorPalette } from '../../constants/colorsPalette';
import { getExtension } from '../../helpers/general_helpers';
import HorizontalCard from '../../helpers/HorizontalCard';
import { GET, POST } from '../../api';

// API URL
const apiURL = process.env.REACT_APP_API_URL;
// Portal URL
const portalURL = process.env.REACT_APP_PORTAL_URL;

class TimetablePeriods extends Component {

  fieldData = {
    tp_type_id: 0,
    tp_type_name: '',
    tp_type_color: '#ccc',
  }

  constructor(props) {
    super(props);
    this.state = {
      deleteModal: { show: false, id: 0, action: () => { }, title: null, desc: null },
      timetablePeriodsData: {
        search: '',
        order: 'timetable_periods.tp_id',
        dir: 'DESC',
        limit: 100,
        offset: 0,
        data: [],
        total_count: 0,
        total_count_filtered: 0,
        refreshing: false,
        finished: false,
      },
      modalTimetablePeriods: {
        show: false,
        toggle: (title = 'New Timetable') => this.setState({ modalTimetablePeriods: { ...this.state.modalTimetablePeriods, show: !this.state.modalTimetablePeriods.show, title } }),
        title: '',
        save: this.saveTimetablePeriod,
        saving: false,
      },
      timetable_periods: {
        tp_id: 0,
        tp_name: '',
        centerlic_id: 0
      },
      modalPeriodType: {
        show: false,
        fetching: false,
        working: false,
        data: [],
        fieldData: this.fieldData,
        openColorPalette: false,
      },
      centerlic: {},
    }
  }

  componentDidMount() {
    this.getTimetablePeriods()
    this.handleGetPeriodTypes();
  }

  getTimetablePeriods = async () => {
    let timetablePeriodsData = this.state.timetablePeriodsData
    if (timetablePeriodsData.refreshing) {
      return false
    }
    this.setState({
      timetablePeriodsData: {
        ...this.state.timetablePeriodsData,
        refreshing: true
      }
    })

    try {
      const res = await GET('timetable/get-timetable-periods',
        {
          params: {
            search: this.state.timetablePeriodsData.search,
            order: this.state.timetablePeriodsData.order,
            dir: this.state.timetablePeriodsData.dir,
            limit: this.state.timetablePeriodsData.limit,
            offset: this.state.timetablePeriodsData.offset,
          },
        }
      );
      this.setState({
        timetablePeriodsData: {
          ...this.state.timetablePeriodsData,
          data: res.data.data,
          total_count: res.data.total_count,
          total_count_filtered: res.data.total_count_filtered,
          refreshing: false,
          finished: true
        },
        centerlic: res.data.centerlic
      });
      if (res.data.data.length > 0) {
        this.props.timetablePeriods.toggleTp(res.data.data[0].tp_id)
      }
    } catch (err) {
      console.log('err', err)
      toast.error(t('Something went wrong while fetching data!'));

    }
  };

  saveTimetablePeriod = async () => {
    if (this.saveTimetablePeriodReq) {
      this.saveTimetablePeriodReq.abort();
    }
    this.saveTimetablePeriodReq = new AbortController();

    this.setState({ modalTimetablePeriods: { ...this.state.modalTimetablePeriods, saving: true } });
    const { timetable_periods } = this.state;

    const fData = new FormData();
    fData.append('tp_id', timetable_periods.tp_id);
    fData.append('TimetablePeriodsData', JSON.stringify(timetable_periods));

    try {
      const res = await POST(
        'timetable/save-timetable-periods',
        fData,
        {
          signal: this.saveTimetablePeriodReq.signal,
        }
      );
      if (!res.data.type) {
        if (res.data.data != null) {
          handleFormErrors(res.data.data);
        } else {
          toast.error(t('Something went wrong while saving!'));
        }
      } else {
        let timetablePeriodsData = this.state.timetablePeriodsData
        if (timetable_periods.tp_id != 0) {
          timetablePeriodsData.data = timetablePeriodsData.data.map((v, i) => {
            if (v.tp_id == timetable_periods.tp_id) {
              return { ...v, ...res.data.data }
            }
            return v
          })
        } else {
          timetablePeriodsData.data = [{ ...res.data.data }, ...timetablePeriodsData.data]
        }

        this.setState({
          timetablePeriodsData,
          modalTimetablePeriods: {
            ...this.state.modalTimetablePeriods,
            show: false,
            saving: false,
          },
          timetable_periods: {
            tp_id: 0,
            tp_name: '',
            centerlic_id: 0
          },
        });
        toast.success(t(res.data.message));
      }
    } catch (err) {
      console.log('err', err)
      toast.error(t('Something went wrong while sending message!'));

    }
  };

  setStateTimetablePeriods = (field, value) => {
    this.setState({ timetable_periods: { ...this.state.timetable_periods, [field]: value } })
  }

  deleteTimetablePeriods = (tp_id) => {
    this.setState({
      deleteModal: {
        ...this.state.deleteModal, show: true, action: () => {
          GET('timetable/delete-timetable-periods', {
            params: {
              tp_id: tp_id
            },
          }).then((res) => {
            if (res.data.type) {
              let timetablePeriodsData = this.state.timetablePeriodsData
              timetablePeriodsData.data = timetablePeriodsData.data.filter((v, i) => {
                if (v.tp_id != tp_id) {
                  return true
                }
                return false
              })
              this.setState({
                timetablePeriodsData, deleteModal: {
                  ...this.state.deleteModal, show: false
                }
              })
              toast.warning(t(res.data.message));
            } else {
              this.setState({
                deleteModal: {
                  ...this.state.deleteModal, show: false
                }
              })
              toast.error(t(res.data.message));
            }
          })
        }
      }
    })
  }

  handleOpenModalPeriodType = () => {
    this.setState({
      modalPeriodType: {
        ...this.state.modalPeriodType,
        show: true,
      }
    });
  }

  handleCloseModalPeriodType = () => {
    this.setState({
      modalPeriodType: {
        ...this.state.modalPeriodType,
        show: false,
        fieldData: this.fieldData
      }
    });
  }

  handleGetPeriodTypes = async () => {

    this.setState({
      modalPeriodType: {
        ...this.state.modalPeriodType,
        fetching: true,
      }
    });

    try {
      const res = await GET('timetable-period-types/get', {
        params: {
          search: '',
          order: 'tp_type_id',
          dir: 'DESC',
          limit: -1,
          offset: 0
        }
      });

      this.setState({
        modalPeriodType: {
          ...this.state.modalPeriodType,
          fetching: false,
          data: res.data.data,
        }
      });
      this.props.setPeriodTypes(res.data.data);

    } catch (err) {
      console.log(err);
      this.setState({
        modalPeriodType: {
          ...this.state.modalPeriodType,
          fetching: false,
        }
      });
    }
  }

  handleSavePeriodType = async () => {
    this.setState({
      modalPeriodType: {
        ...this.state.modalPeriodType,
        working: true,
      }
    });
    try {
      const fData = new FormData();
      fData.append('TimetablePeriodTypesData', JSON.stringify({
        tp_type_id: this.state.modalPeriodType.fieldData.tp_type_id,
        tp_type_name: this.state.modalPeriodType.fieldData.tp_type_name,
        tp_type_color: this.state.modalPeriodType.fieldData.tp_type_color,
      }));
      const res = await POST('timetable-period-types/save', fData);
      console.log('handleSavePeriodType', res);

      if (res.data.type) {
        this.setState({
          modalPeriodType: {
            ...this.state.modalPeriodType,
            working: false,
            fieldData: this.fieldData
          }
        }, () => this.handleGetPeriodTypes());
      } else {
        handleFormErrors(res.data.data);
        this.setState({
          modalPeriodType: {
            ...this.state.modalPeriodType,
            working: false,
          }
        });
      }

    } catch (err) {
      console.log(err);
      this.setState({
        modalPeriodType: {
          ...this.state.modalPeriodType,
          working: false,
        }
      });
    }
  }

  handleDeletePeriodType = async (id) => {
    try {
      const res = await GET('timetable-period-types/delete', {
        params: {
          tp_type_id: id
        }
      });

      if (res.data.type) {
        this.handleGetPeriodTypes();
      } else {
        toast.error(res.data.message);
      }

    } catch (err) {
      console.log(err);
    }

  }

  render() {
    const { defaultTheme } = this.props;
    const { timetablePeriodsData, modalTimetablePeriods, timetable_periods } = this.state;
    let selected_tp_id = null
    if (this.props.timetablePeriods) {
      selected_tp_id = this.props.timetablePeriods.tp_id
    }



    return (<div className='academic-board'>
      <div className='grey-section-header'>
        <h5>
          <span className='me-1'>{t('Timetable')}</span>
          <span className='mx-1' style={{ opacity: 0.6, fontWeight: 'normal' }}>|</span>
          <span className='mx-1' style={{ opacity: 0.8, fontSize: '1rem', fontWeight: 'normal' }}>{t('Please select the Timetable below')}</span>
        </h5>
        <div className='d-flex flex-row align-items-center'>
          <div>
            <Button
              onClick={this.handleOpenModalPeriodType}
              className='btn-sm btn-add me-2'
              variant='warning'>
              <FontAwesomeIcon
                icon={faList}
                style={{
                  fontSize: 16,
                }}
              />
              {' '}
              {t('Period Types')}
            </Button>
            <Button
              onClick={() => this.setState({
                timetable_periods: {
                  tp_id: 0,
                  tp_name: '',
                  centerlic_id: this.state.centerlic.centerlic_id
                },
              }, () => modalTimetablePeriods.toggle())}
              className='btn-sm btn-add'
              variant='success'>
              {t('Add Timetable')}{' '}
              <FontAwesomeIcon
                icon={faPlus}
                color={brand_colors[defaultTheme.theme_id].color8}
                style={{
                  fontSize: 16,
                }}
              />
            </Button>
          </div>
        </div>
      </div>
      {/* BOARDS */}
      <div style={{ overflowX: 'auto', overflowY: 'hidden' }}>
        <div className='d-flex flex-row'>
          {timetablePeriodsData.data.map((v, i) => {
            let data = {
              allData: v,
              name: v.tp_name,
              cardImage: require('../../assets/images/timetable.png'),
              actions: [
                { type: 'selection', name: 'Selection', iconName: null, iconColor: brand_colors[this.props.defaultTheme.theme_id].color4, onClick: () => this.props.timetablePeriods.toggleTp(v.tp_id), state: selected_tp_id == v.tp_id },
                {
                  type: 'edit', name: 'Edit', image: require('../../assets/images/edit.png'), iconName: null, iconColor: brand_colors[this.props.defaultTheme.theme_id].color16, onClick: () => this.setState({
                    modalTimetablePeriods: { ...modalTimetablePeriods, show: true, title: 'Edit Timetable' },
                    timetable_periods: { ...timetable_periods, ...v },
                    ab_image: (v.ab_image) ? { url: portalURL + v.center_id + '/timetable_periods/' + v.ab_image, data: { type: getExtension(v.ab_image) } } : null,
                  })
                },
                { type: 'delete', name: 'Delete', image: require('../../assets/images/remove-red.png'), iconName: null, iconColor: brand_colors[this.props.defaultTheme.theme_id].color11, onClick: () => this.deleteTimetablePeriods(v.tp_id) },
              ]
            }
            return <HorizontalCard data={data} key={i} />
          })}

          {timetablePeriodsData.refreshing && !timetablePeriodsData.finished &&
            <div className='d-flex mt-2 align-items-center'>
              <Spinner animation='grow' variant='dark' />
            </div>
          }
          {/* {!timetablePeriodsData.refreshing && timetablePeriodsData.finished && timetablePeriodsData.data.length > 0 &&
                        <div className='d-flex mt-2 align-items-center'>
                            <div style={{ color: brand_colors[defaultTheme.theme_id].color18 }}>NO MORE DATA...</div>
                        </div>
                    } */}
          {!timetablePeriodsData.refreshing && timetablePeriodsData.finished && timetablePeriodsData.data.length <= 0 &&
            <div className='d-flex mt-2 align-items-center'>
              <div style={{ color: brand_colors[defaultTheme.theme_id].color18 }}>{t('NO DATA FOUND...')}</div>
            </div>
          }
          <div className='px-2'></div>
        </div>
      </div>


      <SweetAlert
        show={this.state.deleteModal.show}
        warning
        showCancel
        confirmBtnText={t('Yes, delete it!')}
        confirmBtnBsStyle='danger'
        title={(this.state.deleteModal.title) ? this.state.deleteModal.title : t('Are you sure?')}
        onConfirm={this.state.deleteModal.action}
        onCancel={() => this.setState({ deleteModal: { ...this.state.deleteModal, show: false, id: 0, action: () => { } } })}
        focusCancelBtn
      >
        {(this.state.deleteModal.desc) ? this.state.deleteModal.desc : t('Confirm Delete!')}
      </SweetAlert>

      {/* MODAL NEW/EDIT ACADEMIC BOARD */}
      <Modal show={modalTimetablePeriods.show} onHide={() => modalTimetablePeriods.toggle()} size="sm" backdrop="static"
        keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>{modalTimetablePeriods.title}</Modal.Title>
        </Modal.Header>
        {modalTimetablePeriods.show &&
          <Modal.Body className='custom-styled-form'>
            <Row>
              <Col md={12}>
                <Form.Group className='mb-3'>
                  <Form.Label>
                    <img src={imgDescription} />
                    {t('Name')}
                  </Form.Label>
                  <Form.Control type='text' defaultValue={timetable_periods.tp_name} onBlur={(event) => this.setStateTimetablePeriods('tp_name', event.target.value)} />
                </Form.Group>
              </Col>
              <Col md={12} className='ps-4'>
                <strong>Year: {this.state.centerlic.reg_year}</strong>
              </Col>
            </Row>
          </Modal.Body>
        }
        <Modal.Footer>
          <Button variant="secondary" onClick={() => modalTimetablePeriods.toggle()}>
            {t('Close')}
          </Button>
          <Button variant="primary" onClick={modalTimetablePeriods.save}>
            {t('Save Changes')}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={this.state.modalPeriodType.show} onHide={this.handleCloseModalPeriodType} backdrop="static"
        keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>{t('Period Types')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={8}>
              <Form.Group>
                <Form.Label className='no-style'>{t('Type')}</Form.Label>
                <Form.Control
                  style={{ boxShadow: 'none' }}
                  value={this.state.modalPeriodType.fieldData.tp_type_name}
                  onChange={(e) => this.setState({
                    modalPeriodType: {
                      ...this.state.modalPeriodType,
                      fieldData: {
                        ...this.state.modalPeriodType.fieldData,
                        tp_type_name: e.target.value
                      }
                    }
                  })}
                />
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group>
                <Form.Label className='no-style'>{t('Color')}</Form.Label>
                <div className='selected-color' onClick={() => this.setState({
                  modalPeriodType: {
                    ...this.state.modalPeriodType,
                    openColorPalette: true
                  }
                })}>
                  <div style={{ backgroundColor: this.state.modalPeriodType.fieldData.tp_type_color }}></div>
                </div>
                {this.state.modalPeriodType.openColorPalette &&
                  <div className='color-palette' style={{
                    zIndex: 1
                  }}>
                    {colorPalette.map((color, color_i) => (
                      <div
                        key={color_i}
                        onClick={() => this.setState({
                          modalPeriodType: {
                            ...this.state.modalPeriodType,
                            openColorPalette: false,
                            fieldData: {
                              ...this.state.modalPeriodType.fieldData,
                              tp_type_color: color.code
                            }
                          }
                        })}
                        style={{ backgroundColor: color.code }}
                      ></div>
                    ))}
                  </div>
                }
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={12} className='d-flex justify-content-end pt-3'>
              <Button
                className='me-2'
                disabled={this.state.modalPeriodType.fieldData.tp_type_name == ''}
                onClick={() => this.setState({
                  modalPeriodType: {
                    ...this.state.modalPeriodType,
                    fieldData: this.fieldData
                  }
                })}
                variant="danger">
                {t('Cancel')}
              </Button>
              <Button
                onClick={() => this.handleSavePeriodType()}
                disabled={this.state.modalPeriodType.working}
                variant="success">
                {this.state.modalPeriodType.working ? <Spinner animation='grow' size='sm' /> : t('Save')}
              </Button>
            </Col>
          </Row>
          <Table hover size="sm">
            <thead>
              <tr>
                <th width="90">{t('Actions')}</th>
                <th>{t('Type')}</th>
              </tr>
            </thead>
            <tbody>
              {this.state.modalPeriodType.data.length > 0 && this.state.modalPeriodType.data.map((item, index) => (
                <tr key={index}>
                  <td>
                    <Button
                      onClick={() => this.handleDeletePeriodType(item.tp_type_id)}
                      className="p-0 row-btn-sm" variant="danger" size="sm">
                      <FontAwesomeIcon
                        icon={faTrashCan}
                      />
                    </Button>
                    <Button
                      onClick={() => this.setState({
                        modalPeriodType: {
                          ...this.state.modalPeriodType,
                          fieldData: {
                            tp_type_id: item.tp_type_id,
                            tp_type_name: item.tp_type_name,
                            tp_type_color: item.tp_type_color,
                          }
                        }
                      })}
                      className="p-0 row-btn-sm" variant="warning" size="sm">
                      <FontAwesomeIcon
                        icon={faPencil}
                      />
                    </Button>
                  </td>
                  <td style={{ verticalAlign: 'middle' }}>
                    <span style={{
                      width: 25,
                      height: 25,
                      backgroundColor: item.tp_type_color,
                      display: 'block',
                      borderRadius: 5,
                      float: 'left',
                      marginRight: 6
                    }}></span> {item.tp_type_name}</td>
                </tr>
              ))}
              {this.state.modalPeriodType.data.length == 0 &&
                <tr>
                  <td colSpan={2} style={{
                    textAlign: 'center',
                    verticalAlign: 'middle'
                  }}>{t('No Data Found!')}</td>
                </tr>
              }
            </tbody>
          </Table>
        </Modal.Body>
      </Modal>
    </div>);
  }
}


const mapStateToProps = (state) => ({
  authData: state.auth.authData,
  defaultLanguage: state.language.defaultLanguage,
  selectedClass: state.selectedClass.data,
  defaultTheme: state.theme.defaultTheme,
});

export default connect(mapStateToProps, null)(TimetablePeriods);
