import { createSlice } from '@reduxjs/toolkit';

export const subLessonModalSlice = createSlice({
  name: 'subLessonModal',
  initialState: {
    showModal: false,
    asl_id: null,
  },
  reducers: {
    setSubLessonModal: (state, action) => {
      state.showModal = action.payload.showModal;
      state.asl_id = action.payload.asl_id;
    },
  },
});

export const { setSubLessonModal } = subLessonModalSlice.actions;
export default subLessonModalSlice.reducer;
