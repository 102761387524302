/**
 * Selected Class Slice
 */
import { createSlice } from '@reduxjs/toolkit';


export const selectedClassSlice = createSlice({
    name: 'selectedClass',
    initialState: {
        data: {},
        subject: {},
    },
    reducers: {
        setSelectedClass: (state, action) => {
            state.data = action.payload;
        },
        setSubject: (state, action) => {
            state.subject = action.payload;
        }
    },
});

export const { setSelectedClass, setSubject } = selectedClassSlice.actions;
export default selectedClassSlice.reducer;
