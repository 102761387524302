import React, { Component } from 'react';
import { Container, Card, Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import Groups from './Groups';
import Categories from './Categories';
import Topics from './Topics';
import SubTopics from './SubTopics';

class EvaluationSetup extends Component {
  render() {
    return (
      <Container fluid className='mt-2'>
        <div className="grey-section" style={{ borderRadius: 10 }}>
          <Row>
            <Col md={12} className='mb-4'>
              <Groups defaultTheme={this.props.defaultTheme} />
            </Col>
            <Col md={6} className='mb-4'>
              <Categories defaultTheme={this.props.defaultTheme} />
            </Col>
            <Col md={6} className='mb-4'>
              <Topics defaultTheme={this.props.defaultTheme} />
            </Col>
            <Col md={12}>
              <SubTopics defaultTheme={this.props.defaultTheme} />
            </Col>
          </Row>
        </div>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  authData: state.auth.authData,
  defaultLanguage: state.language.defaultLanguage,
});

export default connect(mapStateToProps, null)(EvaluationSetup);
