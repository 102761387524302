import React, { Component } from 'react';
import { Outlet } from 'react-router';
import { Container, Card, Row, Col, Button, Tab, Tabs } from 'react-bootstrap';
import { t } from '../../../helpers/translation_helper';
import NavBar from '../../_partials/NavBar/_NavBar';
import Header from '../../_partials/Header/_Header';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDashboard, faUsers, faAddressCard, faUserTie, faChevronDown, faBook, faUser } from '@fortawesome/free-solid-svg-icons';
import { ifNavAllowed } from '../../../helpers/general_helpers';


class AdminDashboard extends Component {

  state = {
    showCenters: false, // 👈 Change it to true to show centers car
    centers: this.props.authData.multiCenters,
    selectedCenters: [],
  }

  adminDashNav = [
    {
      id: 'admin-dashboard-main',
      path: '/admin/dashboard/main',
      icon: faDashboard,
      label: 'Dashboard',
    },
    {
      id: 'admin-dashboard-crm',
      path: '/admin/dashboard/crm',
      icon: faUsers,
      label: 'CRM',
    },
    {
      id: 'admin-dashboard-admissions',
      path: '/admin/dashboard/admissions',
      icon: faAddressCard,
      label: 'Admissions',
    },
    {
      id: 'admin-dashboard-hr',
      path: '/admin/dashboard/hr',
      icon: faUserTie,
      label: 'HR',
    },
    {
      id: 'admin-dashboard-other',
      path: '/admin/dashboard/other',
      icon: faUser,
      label: 'Other',
    }
  ];

  selectCenter = (center_id) => {
    const selectedCenters = this.state.selectedCenters;

    const findCenter = this.state.selectedCenters.find((foundCenter) => foundCenter == center_id);

    if (findCenter) {
      const findIndex = this.state.selectedCenters.findIndex((foundCenter) => foundCenter == center_id);
      selectedCenters.splice(findIndex, 1);
    } else {
      selectedCenters.push(center_id);
    }

    this.setState({ selectedCenters });
  }

  render() {
    console.log('DASHBOARDADMIN', this.state);
    return (
      <Container fluid>
        <div id='iedu-layout'>
          <NavBar />
          <div id='page-content'>
            <Header />
            <div className='grey-section'>
              {this.state.showCenters && this.state.centers.length > 1 &&
                <Card className='border-0'
                  style={{ borderRadius: '20px', marginBottom: 20 }}>
                  <Card.Body style={{ paddingBottom: 8 }}>
                    <Col md={12}>
                      <Button variant={this.state.selectedCenters.length == 0 ? 'danger' : 'outline-danger'} className='mb-2 me-2' onClick={() => this.setState({ selectedCenters: [] })}>{t('All Centers')}</Button>
                      {this.state.centers.map((center, index) =>
                        <Button key={index} variant={this.state.selectedCenters.find((foundCenter) => foundCenter == center.center_id) ? 'primary' : 'outline-info'} className='mb-2 me-2' onClick={() => this.selectCenter(center.center_id)}>{t(center.center_name)}</Button>
                      )}
                    </Col>
                  </Card.Body>
                </Card>
              }
              <Card
                className='border-0'
                style={{ borderRadius: '20px', marginBottom: 20 }}>
                <Card.Body>
                  <Row>
                    <Col md={12} className='admin-dash-nav d-flex justify-content-center'>
                      {this.adminDashNav.map((nav, nav_i) => {
                        if (ifNavAllowed(nav.id)) {
                          return <NavLink
                            key={nav_i}
                            className='btn btn-light mx-2'
                            to={`${nav.path}`}>
                            <FontAwesomeIcon
                              icon={nav.icon}
                              className='me-1'
                            />
                            {t(nav.label)}
                          </NavLink>
                        }
                      })}
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
              <Outlet />
            </div>
          </div>
        </div>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  authData: state.auth.authData,
  defaultLanguage: state.language.defaultLanguage,
  defaultTheme: state.theme.defaultTheme,
});

export default connect(mapStateToProps, null)(AdminDashboard);
