import React, { Component } from 'react';
import {
  Container,
  Card,
  Row,
  Col,
  Modal,
  Button,
  Table,
  InputGroup,
  Form,
  Spinner,
  Badge,
  ButtonGroup,
} from 'react-bootstrap';
import { t } from '../../../helpers/translation_helper';
import NavBar from '../../_partials/NavBar/_NavBar';
import Header from '../../_partials/Header/_Header';
import { connect } from 'react-redux';
import { setExamCopy } from '../../../redux/slices/examCopyModalSlice';
import { GET, POST } from '../../../api';
import { toast } from 'react-toastify';
import { brand_colors } from '../../../helpers/brand_colors_helper';
import {
  changeColorOpacity,
  DDL_ExamstypegrpTypes,
} from '../../../helpers/general_helpers';
import Select from 'react-select';

class ExamCopyModal extends Component {
  studentsField = React.createRef();

  state = {
    class_id: '',
    term_id: '',
    employee_id: '',
    child_ids: [],
    terms: [],
    teachers: [],
    // 🚩
    disableTeacher: true,
    disableStudent: true,
    fetchingStudents: false,
    fetchingTeachers: false,
    copying: false,
  };

  componentDidMount() {
    this.getTerms();
  }

  getTerms = async () => {
    try {
      const res = await GET('exams/get-terms');
      this.setState({
        terms: res.data,
      });
    } catch (err) {
      console.log(err);
    }
  };

  getChildren = async () => {
    if (!this.state.class_id || !this.state.term_id) {
      return;
    }

    const { authData } = this.props;
    const { employee_id } = authData.userData;

    this.setState({
      disableStudent: true,
      fetchingStudents: true,
      students: [],
    });

    this.studentsField.current.setValue([]);

    const request_params = {
      class_ids: [this.state.class_id.value],
      term_id: this.state.term_id.value,
      studentgroup: true,
      employee_id,
    };

    try {
      const res = await GET('exams/get-children-list', {
        params: request_params,
      });
      console.log('get-children-list', res);
      const students = [];
      res.data.map((item) => {
        students.push({
          value: item.child_id,
          label: item.child_name,
        });
      });

      this.setState({
        students,
        disableStudent: false,
        fetchingStudents: false,
      });
    } catch (err) {
      console.log('err', err);
      toast.error(t('Something went wrong while fetching students!'));
      this.setState({
        disableStudent: false,
        fetchingStudents: false,
      });
    }
  };

  getTeachers = async () => {
    const class_id = this.props.exam.class_id;
    const acs_id = this.props.exam.acs_id;

    this.setState({
      fetchingTeachers: true,
      disableTeacher: true,
    });

    try {
      const res = await GET('exams/get-teachers', {
        params: {
          class_id,
          acs_id,
        },
      });

      this.setState({
        fetchingTeachers: false,
        disableTeacher: false,
        teachers: res.data.map((item) => {
          return {
            value: item.employee_id,
            label: item.employee_name,
          };
        }),
      });
    } catch (err) {
      console.log(err);
    }
  };

  copyExam = async () => {
    if (!this.state.class_id) {
      toast.error(t('Please select class!'));
      return;
    }
    if (!this.state.term_id) {
      toast.error(t('Please select term!'));
      return;
    }
    // if (!this.state.employee_id) {
    //   toast.error(t('Please select teacher!'));
    //   return;
    // }

    this.setState({
      copying: true,
    });

    const child_ids = this.state.child_ids.map((item) => {
      return item.value;
    });

    const fData = new FormData();
    fData.append('exams_id', this.props.exam.exams_id);
    fData.append('class_id', this.state.class_id.value);
    fData.append('classmain_id', this.state.class_id.class.classmain_id);
    fData.append('term_id', this.state.term_id.value);
    fData.append('employee_id', this.state.employee_id.value);
    fData.append('child_ids', `[${child_ids}]`);

    try {
      const res = await POST('exams/copy-exam', fData);
      console.log('res', res);
      this.setState({
        copying: false,
      });
      toast.success(t('Successfully Copied!'));
      this.props.callBack();
      this.handleCloseModal();
    } catch (err) {
      console.log(err);
      this.setState({
        copying: false,
      });
      toast.error(t('Something went wrong!'));
    }
  };

  handleCloseModal = () => {
    this.setState({
      term_id: '',
      class_id: '',
      child_ids: [],
      employee_id: '',
      disableStudent: true,
      disableTeacher: true,
      fetchingStudents: false,
      copying: false,
    });
    this.props.setExamCopy({ showModal: false, exam: {} });
  };

  render() {
    const exam_class = this.props.authData.classes.find(
      (item) => item.class.class_id == this.props.exam.class_id
    );

    const exam_agegroup_id = exam_class?.class.agegroup_id;

    return (
      <Modal
        size={'lg'}
        show={this.props.examCopyModalShow}
        onHide={this.handleCloseModal}
        backdrop='static'
        keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>
            {t('Copy ') + this.props.exam.examstypegrp_type}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={6}>
              <Form.Group className='mb-3'>
                <Form.Label>{t('Class')}</Form.Label>
                <Select
                  value={this.state.class_id}
                  menuPortalTarget={document.body}
                  styles={{
                    menuPortal: (base) => ({
                      ...base,
                      zIndex: 9999,
                    }),
                  }}
                  options={this.props.authData.classes
                    .filter(
                      (item) => item.class.agegroup_id == exam_agegroup_id
                    )
                    .map((item) => {
                      return {
                        ...item,
                        value: item.class.class_id,
                        label: item.class.class_theme,
                      };
                    })}
                  onChange={(obj) =>
                    this.setState({ class_id: obj }, () => {
                      this.getChildren();
                      // this.getTeachers();
                    })
                  }
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className='mb-3'>
                <Form.Label>{t('Term')}</Form.Label>
                <Select
                  options={this.state.terms.map((item) => {
                    return {
                      ...item,
                      value: item.term_id,
                      label: item.term_name,
                    };
                  })}
                  onChange={(obj) => {
                    this.setState({ term_id: obj }, () => this.getChildren());
                  }}
                  value={this.state.term_id}
                  menuPortalTarget={document.body}
                  styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                  formatOptionLabel={(option) => (
                    <div>
                      <span style={{ fontSize: 14 }}>{option.label}</span>
                      {option.term_active == 1 && (
                        <Badge className='ms-1' bg='success'>
                          Active
                        </Badge>
                      )}
                    </div>
                  )}
                />
              </Form.Group>
            </Col>
            {/* <Col md={4}>
              <Form.Group className="mb-3">
                <Form.Label>
                  {t('Teacher')}
                  {this.state.fetchingTeachers && <Spinner animation="grow" size="sm" />}
                </Form.Label>
                <Select
                  isDisabled={this.state.disableTeacher}
                  value={this.state.employee_id}
                  menuPortalTarget={document.body}
                  styles={{
                    menuPortal: (
                      base
                    ) => ({
                      ...base,
                      zIndex: 9999,
                    }),
                  }}
                  options={this.state.teachers}
                  onChange={(obj) => this.setState({ employee_id: obj })}
                />
              </Form.Group>
            </Col> */}
            <Col md={12}>
              <div id='form_section_1.5'>
                <Form.Group className='mb-1'>
                  <Row>
                    <Col>
                      <Form.Label>
                        {t('Students')}{' '}
                        {this.state.fetchingStudents && (
                          <Spinner animation='grow' size='sm' />
                        )}
                      </Form.Label>
                    </Col>
                    <Col className='text-right'>
                      <ButtonGroup size='sm'>
                        <Button
                          variant='primary'
                          disabled={this.state.disableStudent}
                          onClick={() => {
                            this.studentsField.current.setValue(
                              this.state.students
                            );
                            this.setState({ child_ids: this.state.students });
                          }}>
                          {t('Select All')}
                        </Button>
                        <Button
                          variant='warning'
                          disabled={this.state.disableStudent}
                          onClick={() => {
                            this.studentsField.current.setValue([]);
                            this.setState({ child_ids: [] });
                          }}>
                          {t('De-Select All')}
                        </Button>
                      </ButtonGroup>
                    </Col>
                  </Row>
                  <Select
                    ref={this.studentsField}
                    menuPortalTarget={document.body}
                    styles={{
                      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    }}
                    closeMenuOnSelect={false}
                    value={this.state.child_ids}
                    isMulti
                    options={this.state.students}
                    className='basic-multi-select'
                    classNamePrefix='select'
                    onChange={(arr) => this.setState({ child_ids: arr })}
                    isDisabled={this.state.disableStudent}
                  />
                </Form.Group>
              </div>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={this.handleCloseModal}>
            {t('Close')}
          </Button>
          <Button
            variant='primary'
            disabled={this.state.copying}
            onClick={this.copyExam}>
            {t('Copy')}{' '}
            {this.state.copying && <Spinner animation='grow' size='sm' />}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  authData: state.auth.authData,
  defaultLanguage: state.language.defaultLanguage,
  selectedClass: state.selectedClass.data,
  exam: state.examCopyModal.exam,
  examCopyModalShow: state.examCopyModal.showModal,
  defaultTheme: state.theme.defaultTheme,
});

const mapDispatchToProps = () => ({
  setExamCopy,
});

export default connect(mapStateToProps, mapDispatchToProps())(ExamCopyModal);
