import React, { Component } from 'react';
import { Container, Card, Table, Row, Col, Spinner, Image, Badge, Button, Form, Offcanvas, Accordion, Modal } from 'react-bootstrap';
import { t } from '../../helpers/translation_helper';
import NavBar from '../_partials/NavBar/_NavBar';
import Header from '../_partials/Header/_Header';
import { connect } from 'react-redux';
import axios from 'axios';
import { toast } from 'react-toastify';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight, faCalendarAlt, faCheck, faClock, faEdit, faExternalLink, faFileAlt, faGear, faPaperclip, faPlus, faSave, faSearch, faTimes, faTrashCan, } from '@fortawesome/free-solid-svg-icons';
import { Scrollbar } from 'react-scrollbars-custom';
import imgDescription from '../../assets/images/description.png';
import { brand_colors } from '../../helpers/brand_colors_helper';
import SweetAlert from 'react-bootstrap-sweetalert';
import Select from 'react-select';
import { handleFormErrors } from '../../helpers/form_helpers';
import AddEditAssessmentReport from '../_common/AddEditAssessmentReport/_AddEditAssessmentReport';
import AssRepCard from './AssessRepCard';
import { GET } from '../../api';
import EYRepCard from './EYRepCard__UNUSED';

// API URL
const apiURL = process.env.REACT_APP_API_URL;

// Portal URL
const portalURL = process.env.REACT_APP_PORTAL_URL;

class StudentAssessment extends Component {

  reportTypes = [
    {
      label: 'Detailed Observation',
      value: 'Detailed Observation',
    },
    {
      label: 'Summary Observation',
      value: 'Summary Observation',
    },
    {
      label: 'Learning Journal',
      value: 'Learning Journal',
    },
    {
      label: 'EY Report 1',
      value: 'EY Report 1',
    },
    {
      label: 'EY Report 2',
      value: 'EY Report 2',
    },
    {
      label: 'Progress Report',
      value: 'Progress Report',
    },
  ];

  constructor(props) {
    super(props);
    this.state = {
      working: false,
      getAssrepHeaderData: [],
      selectedAssrepHeader: null,
      selectedClass: null,
      selectedReportType: null,
      reportType: null,
      reportData: {
        processing: false,
        data: {},
      },
      assess_curr: [],
      isSaving: false,
      reRenderTime: moment(),
      studentListAssessment: null,
      terms: [],
      selectedTerm: null
    }
    this.assess_curr = [];

    this.AddEditAssessmentReport = React.createRef();
  }

  componentDidMount() {
    this.getAssrepHeader();
    this.getTerms();
  }

  getAssrepHeader = () => {
    try {
      if (this.getAssrepHeaderReq) {
        this.getAssrepHeaderReq.abort();
      }
      this.getAssrepHeaderReq = new AbortController();
      GET('assessment-report/get-assrep-header', {
        signal: this.getAssrepHeaderReq.signal,
      }).then((res) => {

        if (res) {
          this.setState({ getAssrepHeaderData: res.data })
        }
      })
    } catch (err) {
      console.log('err', err)
      toast.error(t('Something went wrong while getting data!'));

    }
  }


  // getAcademicBoards = () => {
  //   try {
  //     if (this.getAcademicBoardsReq) {
  //       this.getAcademicBoardsReq.abort();
  //     }
  //     this.getAcademicBoardsReq = new AbortController();
  //     GET('student-tracker/get-academic-boards', {
  //       signal: this.getAcademicBoardsReq.signal,
  //     }).then((res) => {
  //       if (res) {
  //         this.setState({ getAcademicBoardsData: res.data, selectedAcademicBoards: res.data.map((v, i) => { return { label: v.ab_name, value: v.ab_id } }) })
  //       }
  //     })
  //   } catch (err) {
  //     console.log('err', err)
  //     toast.error(t('Something went wrong while getting data!'));

  //   }
  // }

  getTerms = () => {
    try {
      if (this.getTermsReq) {
        this.getTermsReq.abort();
      }
      this.getTermsReq = new AbortController();
      GET('assessment-report/get-terms', {
        signal: this.getTermsReq.signal,
      }).then((res) => {

        if (res) {
          this.setState({
            terms: res.data.map(item => {
              return {
                ...item,
                value: item.term_id,
                label: item.term_name
              }
            })
          })
        }
      })
    } catch (err) {
      console.log('err', err)
      toast.error(t('Something went wrong while getting data!'));

    }
  }

  getStudentListAssessment = async () => {

    // if (!this.props.authData.userData) {
    //   toast.error(t('Seems like you are not an employee!'));
    //   return;
    // }

    const { authData } = this.props;
    const { auth_key } = authData.loginData;
    const { employee_id } = authData.userData;
    const { center_id } = authData.centerData;
    const { selectedAssrepHeader, selectedClass, selectedReportType, selectedTerm } = this.state;

    if (!selectedTerm && selectedTerm == null) {
      toast.error(t('Please select term'));
      return;
    }

    if (!selectedAssrepHeader && selectedAssrepHeader == null) {
      toast.error(t('Please select assessment report'));
      return;
    }

    if (!selectedClass && selectedClass == null) {
      toast.error(t('Please select class'));
      return;
    }

    if (!selectedReportType && selectedReportType == null) {
      toast.error(t('Please select report type'));
      return;
    }

    this.setState({ working: true, reportType: selectedReportType.value });

    try {
      const res = await GET('student-tracker/get-student-list-assessment', {
        params: {
          employee_id,
          class_id: selectedClass.value,
          assrepheader_id: selectedAssrepHeader.value,
          term_id: selectedTerm.value,
          assrep_report_type: selectedReportType.value
        },
      });

      if (res.status === 200) {
        this.setState({ studentListAssessment: res.data });
      }

      this.setState({ working: false });

    } catch (err) {
      console.log('err', err)

      this.setState({ working: false });
      toast.error(t('Something went wrong!'));
    }

  }

  render() {
    const { selectedAssrepHeader, selectedClass, reportData, isSaving, reRenderTime, selectedAcademicBoards } = this.state

    console.log('selectedAssrepHeader', selectedAssrepHeader);


    let filteredClasses = []
    if (selectedAssrepHeader) {
      this.props.authData.classes.map((v, i) => {
        if (selectedAssrepHeader.agegroup_id == v.class.agegroup_id) {
          filteredClasses.push({ label: v.class.class_theme, value: v.class.class_id })
        }
      })
    }

    return (
      <Container fluid>
        <div id='iedu-layout'>
          <NavBar />
          <div id='page-content'>
            <Header lite={true} heading={t('Student Assessment Report')} backBtn={true} showCenterSelector={true} />
            <div className='grey-section' style={{ marginBottom: 20 }}>

              <Row className='justify-content-between'>
                <Col>
                  <Row>
                    <Col md={11} className='pe-0'>
                      <Row>
                        <Col md={3}>
                          <Select
                            placeholder={t('--TERM--')}
                            options={this.state.terms}
                            onChange={(event) => this.setState({ selectedTerm: event })
                            }
                            value={this.state.selectedTerm}
                            menuPortalTarget={document.body}
                            styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                            formatOptionLabel={option => (
                              <div>
                                {option.label}
                                {option.term_active == 1 &&
                                  <span className='badge' style={{
                                    position: 'relative',
                                    top: -1,
                                    marginLeft: 5,
                                    backgroundColor: '#00d045'
                                  }}>ACTIVE</span>
                                }
                              </div>
                            )}
                          />
                        </Col>
                        <Col md={3} className='d-flex flex-row'>
                          <div style={{ flex: 1 }}>
                            <Select
                              placeholder={t('--ASSESSMENT REPORT--')}
                              options={this.state.getAssrepHeaderData.map((v, i) => { return { ...v, label: v.assrepheader_name, value: v.assrepheader_id, } })}
                              onChange={(event) => this.setState({ selectedAssrepHeader: event, selectedClass: null })}
                              value={selectedAssrepHeader}
                              menuPortalTarget={document.body}
                              styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                              formatOptionLabel={option => (
                                <div>
                                  <span className='badge' style={{
                                    position: 'relative',
                                    top: -1,
                                    backgroundColor: option.agegroup_color
                                  }}>{option.agegroup_code}</span>
                                  <span style={{ fontSize: 16, marginLeft: 10 }}>{option.label}</span>
                                </div>
                              )}
                            />
                          </div>
                          <div>
                            <Button
                              variant={'secondary'}
                              onClick={() => this.AddEditAssessmentReport.current.handleModal()}
                              style={{ borderBottomLeftRadius: 0, borderTopLeftRadius: 0, marginLeft: -3 }}
                            >
                              <FontAwesomeIcon icon={faPlus} style={{ fontSize: 16, color: brand_colors[this.props.defaultTheme.theme_id].color8, }} />
                            </Button>
                          </div>
                        </Col>
                        <Col md={3}>
                          <Select
                            placeholder={t('--CLASS--')}
                            options={filteredClasses}
                            onChange={(event) => this.setState({ selectedClass: event })
                            }
                            value={selectedClass}
                            menuPortalTarget={document.body}
                            styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                            formatOptionLabel={option => (
                              <div>
                                <span style={{ fontSize: 16 }}>{option.label}</span>
                              </div>
                            )}
                          />
                        </Col>
                        <Col md={3}>
                          <Select
                            placeholder={t('--REPORT TYPE--')}
                            options={this.reportTypes}
                            onChange={(obj) => this.setState({ selectedReportType: obj })
                            }
                            value={this.state.selectedReportType}
                            menuPortalTarget={document.body}
                            styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                            formatOptionLabel={option => (
                              <div>
                                <span style={{ fontSize: 16 }}>{option.label}</span>
                              </div>
                            )}
                          />
                        </Col>
                        {/* <Col md={12}>
                          <Select
                            isMulti
                            placeholder={t('--CURRICULUM--')}
                            options={this.state.getAcademicBoardsData.map((v, i) => { return { label: v.ab_name, value: v.ab_id } })}
                            onChange={(event) => this.setState({ selectedAcademicBoards: event })
                            }
                            value={selectedAcademicBoards}
                            menuPortalTarget={document.body}
                            styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                            formatOptionLabel={option => (
                              <div>
                                <span style={{ fontSize: 16 }}>{option.label}</span>
                              </div>
                            )}
                          />
                        </Col> */}
                      </Row>
                    </Col>
                    <Col md={1} className='d-flex justify-content-center align-items-center'>
                      <Button style={{ width: '100%' }} disabled={this.state.working} variant={'primary'} onClick={() => this.getStudentListAssessment()}>
                        {this.state.working
                          ? <Spinner as='span' animation='grow' size='sm' role='status' aria-hidden='true' />
                          : <FontAwesomeIcon icon={faSearch} style={{ fontSize: 20, color: brand_colors[this.props.defaultTheme.theme_id].color8, }} />
                        }
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
            <div className='grey-section'>
              {this.state.working ? (
                <Row className='flex-column text-center'>
                  <Col>
                    <Spinner
                      as='span'
                      animation='grow'
                      size='lg'
                      role='status'
                      aria-hidden='true'
                      className='mb-1'
                    />
                  </Col>
                  <Col>
                    {t('Fetching Reports...')}
                  </Col>
                </Row>
              ) : (
                <>
                  {this.state.studentListAssessment && this.state.studentListAssessment.length > 0 ? (
                    <>
                      {this.state.studentListAssessment.map((item, index) =>
                        <AssRepCard key={index} data={item} />)
                      }
                    </>
                  ) : (
                    <Row className='flex-column text-center'>
                      <Col>{t('No Data Found')}</Col>
                    </Row>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
        <AddEditAssessmentReport
          ref={this.AddEditAssessmentReport}
          authData={this.props.authData}
          selectedClass={this.props.selectedClass}
          callBack={this.getAssrepHeader}
          defaultTheme={this.props.defaultTheme}
          getAssrepHeaderData={this.state.getAssrepHeaderData}
        />
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  authData: state.auth.authData,
  defaultLanguage: state.language.defaultLanguage,
  selectedClass: state.selectedClass.data,
  children: state.children.list,
  defaultTheme: state.theme.defaultTheme,
});

export default connect(mapStateToProps, null)(StudentAssessment);


// class RenderCurriculum extends Component {
//   constructor(props) {
//     super(props);
//   }
//   state = {}

//   shouldComponentUpdate(nextProps) {
//     if (moment(nextProps.reRenderTime).isSame(moment(this.props.reRenderTime))) {

//       return false
//     }

//     return true
//   }

//   render() {
//     let reportData = this.props.reportData
//     let assessmentAnsOptions = this.props.assessmentAnsOptions

//     return null
//   }
// }
