import { createSlice } from '@reduxjs/toolkit';

export const bannersModalSlice = createSlice({
  name: 'bannersModal',
  initialState: {
    showModal: false,
  },
  reducers: {
    setBannersModal: (state, action) => {
      state.showModal = action.payload.showModal;
    },
  },
});

export const { setBannersModal } =
  bannersModalSlice.actions;
export default bannersModalSlice.reducer;
