import React, { Component } from 'react';
import { Container, Card, Row, Col, ButtonGroup, Button, DropdownButton, Dropdown, Form, Modal, Spinner, Table, InputGroup, Badge, Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faCheckCircle, faSearch, faUser, faXmark, faLayerGroup, faHandsHoldingChild, faPeopleGroup, faListCheck, faPrint, faPencil, faTrash, faCheckDouble, faSortDown, faSortUp, faThumbsUp, faThumbsDown, faL, faChevronUp, faChevronDown, faTimes } from '@fortawesome/free-solid-svg-icons';
import { faCalendar, faEye, faEyeSlash, faFileExcel, faFilePdf } from '@fortawesome/free-regular-svg-icons';
import { t } from '../../helpers/translation_helper';
import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';
import Select from 'react-select';
import { connect } from 'react-redux';
import moment from 'moment';
import { brand_colors } from '../../helpers/brand_colors_helper';
import { GET, POST } from '../../api';
import { toast } from 'react-toastify';
import { handleFormErrors } from '../../helpers/form_helpers';
import { DateRangePicker } from 'react-date-range';
import axios from 'axios';
import Pagination from '../_partials/Pagination';
import SweetAlert from 'react-bootstrap-sweetalert';
import { renderTableBody, renderTableHead } from '../../helpers/table_helper';
import { DDL_ExamstypeModes, DDL_ExamstypegrpTypes, DDL_ExamstypegrpTypes2, changeColorOpacity } from '../../helpers/general_helpers';
var Chance = require('chance');
var chance = new Chance();
let cancelToken;

class ExamTypes extends Component {

  searchFieldRef = React.createRef();

  defaultFormFields = {
    examstype_id: 0,
    examstype_name: '',
    examtype_grp: null,
    examstype_final: 0,
    examstype_mode: 'Sum'
  }

  state = {
    ...this.defaultFormFields,
    examstype_modes: DDL_ExamstypeModes(),
    DDL_examstypegrp_types: DDL_ExamstypegrpTypes(),
    DDL_examstypegrp_types2: DDL_ExamstypegrpTypes2(),
    deleteModal: { show: false, id: 0, action: () => { } },
    working: false,
    isEditing: false,
    showFormModal: false,
    groups: [],
    cdt: {
      fetching: false,
      search: '',
      rows: 5,
      offset: 0,
      order: 'examstype.examstype_id',
      dir: 'DESC',
      lastPage: 0,
      page: 0,
      totalRecords: 0,
      rowsOptions: [5, 10, 25, 50, 'All'],
      columns: [
        {
          id: 'examstype.examstype_id',
          column: 'examstype_id',
          name: 'Actions',
          visibility: true
        },
        {
          id: 'examstype.examstype_name',
          column: 'examstype_name',
          name: 'Type',
          visibility: true
        },
        {
          id: 'examstypegrp.examstypegrp_name',
          column: 'examstypegrp_name',
          name: 'Group',
          visibility: true
        },
      ],
      data: [],
    },
  }

  componentDidMount() {
    this.getExamsTypeGrps()
    const { search, rows: limit, order, dir } = this.state.cdt;
    this.fetch({
      search, limit, order, dir, page: 0
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.authData.centerData.center_id != this.props.authData.centerData.center_id) {
      const {
        search,
        rows: limit,
        order,
        dir
      } = this.state.cdt;
      this.fetch({
        search, limit, order, dir, page: 0
      });
    }
  }

  fetch = async (params) => {

    this.setState({
      cdt: {
        ...this.state.cdt,
        fetching: true,
      }
    });

    if (this.fetchReq) {
      this.fetchReq.abort();
    }
    this.fetchReq = new AbortController();

    try {
      const res = await GET('exam-groups-types/get-exams-type', {
        signal: this.fetchReq.signal,
        params: {
          search: params.search,
          order: params.order,
          dir: params.dir,
          limit: params.limit,
          offset: parseInt(params.page) * parseInt(params.limit)
        },
      });

      if (res.status === 200) {
        this.setState({
          cdt: {
            ...this.state.cdt,
            fetching: false,
            search: params.search,
            rows: parseInt(params.limit),
            offset: parseInt(params.page) * parseInt(params.limit),
            order: params.order,
            dir: params.dir,
            page: params.page,
            totalRecords: parseInt(res.data.total_count_filtered),
            lastPage: Math.ceil(parseInt(res.data.total_count_filtered) / parseInt(params.limit)),
            data: res.data.data.map((item) => {
              return {
                uid: chance.guid(),
                ...item
              }
            }),
          }
        });
      }

    } catch (err) {
      console.log('err', err)
      this.setState({
        cdt: {
          ...this.state.cdt,
          fetching: false,
        }
      });

    }
  }

  getExamsTypeGrps = async () => {
    try {
      const res = await GET('exam-groups-types/get', {
        params: {
          search: '',
          order: 'examstypegrp.examstypegrp_id',
          dir: 'DESC',
          limit: -1,
          offset: 0
        }
      });

      this.setState({
        groups: res.data.data.map(item => {
          return {
            value: item.examstypegrp_id,
            label: item.examstypegrp_name,
          }
        })
      });

    } catch (err) {
      console.log(err);
    }
  }

  handleCloseFormModal = () => {
    this.setState({
      showFormModal: false,
      isEditing: false,
      ...this.defaultFormFields
    });
  }

  showHideColumn = (column_index) => {
    const columns = this.state.cdt.columns;
    columns[column_index].visibility = !columns[column_index].visibility;
    this.setState({ ctd: { ...this.state.ctd, columns } })
  }

  exportExcel = async () => {

    const excelData = this.state.cdt.data.map((item) => {
      const row = {};
      this.state.cdt.columns
        .filter((column) => column.visibility)
        .map((column) => {
          if (column.name !== 'Actions') {
            row[column.name] = item[column.column];
          }
        });
      return row;
    });

    const fileName = 'Exam Group Export ' + moment().format('DD-MM-YYYY');
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const ws = XLSX.utils.json_to_sheet(excelData);

    const cellStyle = {
      font: {
        bold: true,
      },
    }

    Array.from('A').map((letter) => {
      ws[letter + "1"].s = cellStyle;
    });

    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });

    FileSaver.saveAs(data, fileName + fileExtension);
  }

  renderCell = (column_data, column_id, column_index, dataObj) => {
    const { authData } = this.props;
    const { center_id } = authData.centerData;

    let tdData;

    // --------------------------------------------
    if (column_id == 'examstype_id') { // Action Buttons
      tdData = <>
        <Button
          onClick={() => this.delete(dataObj)}
          variant="danger" size='sm' className='me-1'>
          <FontAwesomeIcon icon={faTrash} />
        </Button>
        <Button
          onClick={() => this.edit(dataObj)}
          variant="warning" size='sm' className='me-1'>
          <FontAwesomeIcon icon={faPencil} />
        </Button>
      </>;
      // ------------------------------------------------------------------------
    } else if (column_id == 'examstype_name') {
      tdData = <div>
        {column_data}
        {(dataObj['examstype_final'] == 1) && <span className='ms-1 badge badge-light-warning'>FINAL</span>}
        <div>
          <span className='badge badge-light-info'>
            {(dataObj['examstype_mode']).replace('_', ' ')}
          </span>
        </div>
      </div>
    } else if (column_id == 'examstypegrp_name') {

      let find_type = this.state.DDL_examstypegrp_types.find((v) => v.value == dataObj['examstypegrp_type'])
      let tdData1 = <span className='me-1' style={{ fontSize: 12, fontWeight: 'bold', backgroundColor: (find_type) ? changeColorOpacity(find_type.color, 0.2) : '#fff', color: (find_type) ? find_type.color : '#000', borderRadius: 7, padding: '0.1rem 0.5rem' }}>{dataObj['examstypegrp_type']}</span>
      let find_type2 = this.state.DDL_examstypegrp_types2.find((v) => v.value == dataObj['examstypegrp_type2'])
      let tdData2 = <span className='me-1' style={{ fontSize: 12, fontWeight: 'bold', backgroundColor: (find_type2) ? changeColorOpacity(find_type2.color, 0.2) : '#fff', color: (find_type2) ? find_type2.color : '#000', borderRadius: 7, padding: '0.1rem 0.5rem' }}>{dataObj['examstypegrp_type2']}</span>

      tdData = <div className='d-flex flex-column'>
        {column_data}
        <div className=''>
          {tdData2}
          {tdData1}
        </div>
      </div>
    } else {
      tdData = column_data;
    }
    return <td key={column_index}>{tdData}</td>;
  }

  saveUpdate = async () => {

    const { authData } = this.props;
    const { center_id } = authData.centerData;

    this.setState({ working: true });

    const {
      examstype_id,
      examstype_name,
      examtype_grp,
      examstype_final,
      examstype_mode,
    } = this.state;

    const fData = new FormData();
    fData.append('center_id', center_id);
    fData.append('examstypeData', JSON.stringify({
      examstype_id,
      examstype_name,
      examtype_grp: examtype_grp.value,
      examstype_final,
      examstype_mode
    }));

    try {
      const res = await POST('exam-groups-types/save-exams-type', fData);
      if (res.data.type) {
        this.setState({ working: false });
        toast.success(t(res.data.message));
        this.fetch({
          search: '',
          limit: this.state.cdt.rows,
          order: this.state.cdt.order,
          dir: this.state.cdt.dir,
          page: 0,
        });
        this.handleCloseFormModal();
      } else {
        // toast.error(t(res.data.message));
        handleFormErrors(res.data.data)
        this.setState({ working: false });
      }
    } catch (err) {
      console.log('err', err)
      toast.error(t('Something went wrong!'));

      this.setState({ working: false });
    }

  }

  edit = (data) => {
    this.setState({
      isEditing: true,
      examstype_id: data.examstype_id,
      examstype_name: data.examstype_name,
      examstype_final: data.examstype_final,
      examtype_grp: {
        value: data.examtype_grp,
        label: data.examstypegrp_name
      },
      examstype_mode: data.examstype_mode,
      showFormModal: true
    });
  }

  delete = async (data) => {
    this.setState({
      deleteModal: {
        ...this.state.deleteModal, show: true, id: data, action: async () => {
          const res = await GET('exam-groups-types/delete-exams-type', {
            params: {
              examstype_id: data.examstype_id
            }
          });
          if (res) {
            let resData = res.data
            if (resData.type) {
              this.setState({
                cdt: {
                  ...this.state.cdt,
                  data: this.state.cdt.data.filter(item => item.uid !== data.uid)
                }, deleteModal: {
                  ...this.state.deleteModal, show: false, id: null
                }
              });
            } else {
              this.setState({
                deleteModal: {
                  ...this.state.deleteModal, show: false, id: null
                }
              });
            }
            toast[resData.messageType](t(resData.message));
          }
        }
      }
    })
  }

  render() {
    console.log('THISTYPE', this.state);
    const { cdt } = this.state
    let selected_examstype_modes = null
    if (this.state.showFormModal) {
      selected_examstype_modes = this.state.examstype_modes.find((v, i) => v.value == this.state.examstype_mode)
    }
    return (
      <>
        <div className='shadow-box mt-1' style={{
          marginInline: 5
        }}>
          <Card
            className='border-0'
            style={{ borderRadius: '20px' }}>
            <Card.Header style={{
              backgroundColor: '#fff',
              borderTopLeftRadius: '20px',
              borderTopRightRadius: '20px',
              fontWeight: '700'
            }}>
              <Row>
                <Col md={6} className='d-flex align-items-center'>
                  {t('Types')}
                </Col>
                <Col md={6} className='d-flex justify-content-end'>
                  <div className='cdt-search'>
                    <input type='text'
                      ref={this.searchFieldRef}
                      defaultValue={cdt.search}
                      onChange={(e) => this.fetch({
                        search: e.target.value,
                        limit: cdt.rows,
                        order: cdt.order,
                        dir: cdt.dir,
                        page: 0
                      })}
                      placeholder={t('Search...')} />
                    <button
                      onClick={() => {
                        this.fetch({
                          search: '',
                          limit: cdt.rows,
                          order: cdt.order,
                          dir: cdt.dir,
                          page: 0
                        });
                        this.searchFieldRef.current.value = '';
                      }}>
                      <FontAwesomeIcon
                        icon={cdt.search == '' ? faSearch : faXmark}
                        color={brand_colors[this.props.defaultTheme.theme_id].color18}
                      />
                    </button>
                  </div>
                  <button
                    onClick={() => this.setState({
                      showFormModal: true,
                      isEditing: false,
                      ...this.defaultFormFields
                    })}
                    className='btn btn-success btn-sm ms-3'><FontAwesomeIcon icon={faPlus} color={brand_colors[this.props.defaultTheme.theme_id].color8} style={{ fontSize: 12 }} /></button>
                </Col>
              </Row>
            </Card.Header>
            <Card.Body style={{ paddingTop: 8 }}>
              <Row>
                <Col md={6}>
                  <ButtonGroup size='sm' className='me-3 float-left'>
                    {/* <Button><FontAwesomeIcon icon={faFilePdf} /> {t('PDF')}</Button> */}
                    <Button onClick={() => this.exportExcel()}>
                      <FontAwesomeIcon icon={faFileExcel} /> {t('Excel')}
                    </Button>
                    {/* <Button><FontAwesomeIcon icon={faPrint} /> {t('Print')}</Button> */}
                    <DropdownButton
                      autoClose={'outside'}
                      size='sm'
                      as={ButtonGroup}
                      title={t('Column Visibility')}>
                      {cdt.columns.map((column, column_index) => (
                        <Dropdown.Item key={column_index} className={column.visibility ? 'column-name-item' : 'column-name-item active'} onClick={() => this.showHideColumn(column_index)}>
                          <FontAwesomeIcon icon={column.visibility ? faEye : faEyeSlash} /> {column.name}
                        </Dropdown.Item>
                      ))}
                    </DropdownButton>
                  </ButtonGroup>
                </Col>
                <Col md={6} className='d-flex justify-content-end'>
                  <div style={{ display: 'inline-block' }}>
                    {t('Show')}
                    <Form.Select
                      value={cdt.rows}
                      size="sm"
                      onChange={(e) => this.fetch({
                        search: cdt.search,
                        limit: e.target.value,
                        order: cdt.order,
                        dir: cdt.dir,
                        page: cdt.page
                      })}
                      style={{ width: 70, marginInline: 5, display: 'inline-block' }}>
                      {cdt.rowsOptions.map((row, row_index) => (<option key={row_index} >{row}</option>))}
                    </Form.Select>
                    {t('Records')}
                  </div>
                </Col>
              </Row>
              <Row>
                {cdt.fetching &&
                  <Col md={12} className='py-5 d-flex flex-column align-items-center'>
                    <Spinner as='span' animation='grow' size='sm' /> {t('Loading Data')}
                  </Col>
                }
                {!cdt.fetching && cdt.data.length === 0 &&
                  <Col md={12} className='py-5 d-flex flex-column align-items-center'>
                    {t('No Data Found')}
                  </Col>
                }
                {!cdt.fetching && cdt.data.length !== 0 &&
                  <Col md={12} className='pt-2'>
                    <div id="datatable-list" className='mb-3' style={{ width: 'initial' }}>
                      <Table responsive>
                        {renderTableHead(this)}
                        {renderTableBody(this)}
                      </Table>
                    </div>
                  </Col>
                }
              </Row>
              <Pagination
                pageCount={cdt.lastPage || 1}
                forcePage={cdt.page}
                callbackParams={{
                  search: cdt.search,
                  limit: cdt.rows,
                  order: cdt.order,
                  dir: cdt.dir,
                }}
                callback={this.fetch}
              />
            </Card.Body>
          </Card>
        </div>
        <Modal
         backdrop="static"
         keyboard={false}
          show={this.state.showFormModal}
          onHide={this.handleCloseFormModal}>
          <Modal.Header closeButton>
            <Modal.Title>{this.state.isEditing ? t('Edit') : t('Add')} {t('Exam Type')}</Modal.Title>
          </Modal.Header>
          {this.state.showFormModal &&
            <Modal.Body className='custom-styled-form'>
              <Row>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label>{t('Group')}</Form.Label>
                    <Select
                      value={this.state.examtype_grp}
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (
                          base
                        ) => ({
                          ...base,
                          zIndex: 9999,
                        }),
                      }}
                      options={this.state.groups}
                      onChange={(obj) => this.setState({ examtype_grp: obj })}
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label>{t('Mode')}</Form.Label>
                    <Select
                      options={this.state.examstype_modes}
                      value={selected_examstype_modes}
                      menuPortalTarget={document.body}
                      styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }), }}
                      onChange={(obj) => this.setState({ examstype_mode: obj.value })}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md="12" className='mt-2'>
                  <Alert className='p-2' variant="info">
                    <strong>{selected_examstype_modes.label}: </strong>
                    {selected_examstype_modes.desc}
                  </Alert>
                </Col>
                <Col md={8}>
                  <Form.Group>
                    <Form.Label>{t('Type')}</Form.Label>
                    <Form.Control type="text"
                      defaultValue={this.state.examstype_name}
                      onChange={(e) => this.setState({ examstype_name: e.target.value })} />
                  </Form.Group>
                </Col>
                <Col md={4} style={{ paddingTop: 30 }}>
                  <Form.Group>
                    <Form.Check
                      type="switch"
                      label={t('Final')}
                      onChange={() => this.setState({ examstype_final: (this.state.examstype_final == 1) ? 0 : 1 })}
                      checked={(this.state.examstype_final == 1) ? true : false}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Modal.Body>
          }
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleCloseFormModal}>
              {t('Close')}
            </Button>
            <Button disabled={this.state.working} variant='success' onClick={() => this.saveUpdate()}>
              {t('Save')} {this.state.working ? <Spinner as='span' animation='grow' size='sm' role='status' aria-hidden='true' /> : ''}
            </Button>
          </Modal.Footer>
        </Modal>
        <SweetAlert show={this.state.deleteModal.show} warning showCancel confirmBtnText={t('Yes, delete it!')} confirmBtnBsStyle='danger' title={t('Are you sure?')} onConfirm={this.state.deleteModal.action} onCancel={() => this.setState({ deleteModal: { ...this.state.deleteModal, show: false, id: 0, action: () => { } } })} focusCancelBtn>
          {t('Confirm Delete!')}
        </SweetAlert>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  authData: state.auth.authData,
  defaultLanguage: state.language.defaultLanguage,
  defaultTheme: state.theme.defaultTheme,
});

export default connect(mapStateToProps, null)(ExamTypes);
