import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';

export const addEditPaymentVoucherModalSlice = createSlice({
    name: 'addEditPaymentVoucherModal',
    initialState: {
        showModal: false,
        title: 'New Payment Voucher',
        // todayDate: moment().format('DD-MM-YYYY'),
        editMode: false,
        paymentvoucherdetails: [],
        paymentvoucher_type: null,
        paymentvoucher_ref: '',
        selectedJournalTypeData: null,
        selectedChartOfAccountsData: null,
        selectedChartBalanceData: null,
        paymentvoucher_desc: '',
        paymentvoucher_date: moment().format('DD-MM-YYYY'),
        paymentvoucher_value_date: moment().format('DD-MM-YYYY'),
    },
    reducers: {
        togglePaymentVoucherModal: (state, action) => {
            state.showModal = !state.showModal
            state.title = (action.payload && action.payload.title) ? action.payload.title : 'New Payment Voucher'
            state.paymentvoucher_date = (action.payload && action.payload.paymentvoucher_date) ? action.payload.paymentvoucher_date : moment().format('DD-MM-YYYY')
            state.paymentvoucher_value_date = (action.payload && action.payload.paymentvoucher_value_date) ? action.payload.paymentvoucher_value_date : moment().format('DD-MM-YYYY')
            state.editMode = (action.payload && action.payload.editMode) ? action.payload.editMode : false
            state.paymentvoucherdetails = (action.payload && action.payload.paymentvoucherdetails) ? action.payload.paymentvoucherdetails : []
            state.paymentvoucher_type = (action.payload && action.payload.paymentvoucher_type) ? action.payload.paymentvoucher_type : null
            state.paymentvoucher_ref = (action.payload && action.payload.paymentvoucher_ref) ? action.payload.paymentvoucher_ref : ''
            state.paymentvoucher_desc = (action.payload && action.payload.paymentvoucher_desc) ? action.payload.paymentvoucher_desc : ''
            state.selectedChartOfAccountsData = (action.payload && action.payload.selectedChartOfAccountsData) ? action.payload.selectedChartOfAccountsData : []
            state.selectedJournalTypeData = (action.payload && action.payload.selectedJournalTypeData) ? action.payload.selectedJournalTypeData : null
            state.selectedChartBalanceData = (action.payload && action.payload.selectedChartBalanceData) ? action.payload.selectedChartBalanceData : null
        }
    },
});

export const { togglePaymentVoucherModal, updateChild } = addEditPaymentVoucherModalSlice.actions;
export default addEditPaymentVoucherModalSlice.reducer;
