export const colorPalette = [
  { code: 'rgb(253, 183, 90)', is_text_white: false },
  { code: 'rgb(255, 117, 117)', is_text_white: false },
  { code: 'rgb(253, 172, 65)', is_text_white: false },
  { code: 'rgb(210, 255, 232)', is_text_white: false },
  { code: 'rgb(255, 222, 222)', is_text_white: false },
  { code: 'rgb(255, 238, 217)', is_text_white: false },
  { code: 'rgb(204, 245, 248)', is_text_white: false },
  { code: 'rgb(226, 236, 255)', is_text_white: false },
  { code: 'rgb(230, 234, 238)', is_text_white: false },
  { code: 'rgb(203, 209, 49)', is_text_white: false },
  { code: 'rgb(160, 189, 146)', is_text_white: false },
  { code: 'rgb(162, 161, 114)', is_text_white: false },
  { code: 'rgb(181, 184, 214)', is_text_white: false },
  { code: 'rgb(203, 98, 73)', is_text_white: false },
  { code: 'rgb(181, 88, 225)', is_text_white: false },
  { code: 'rgb(162, 239, 178)', is_text_white: false },
  { code: 'rgb(152, 74, 185)', is_text_white: false },
  { code: 'rgb(163, 98, 90)', is_text_white: false },
  { code: 'rgb(208, 176, 241)', is_text_white: false },
  { code: 'rgb(196, 19, 22)', is_text_white: false },
  { code: 'rgb(178, 69, 10)', is_text_white: true },
  { code: 'rgb(183, 167, 210)', is_text_white: false },
  { code: 'rgb(139, 202, 152)', is_text_white: false },
  { code: 'rgb(228, 197, 202)', is_text_white: false },
  { code: 'rgb(246, 40, 19)', is_text_white: false },
  { code: 'rgb(184, 232, 236)', is_text_white: false },
  { code: 'rgb(141, 50, 94)', is_text_white: true },
  { code: 'rgb(171, 37, 194)', is_text_white: false },
  { code: 'rgb(120, 200, 105)', is_text_white: false },
  { code: 'rgb(212, 214, 39)', is_text_white: false },
  { code: 'rgb(240, 109, 2)', is_text_white: false },
  { code: 'rgb(198, 98, 90)', is_text_white: false },
  { code: 'rgb(71, 95, 123)', is_text_white: true },
  { code: 'rgb(57, 218, 138)', is_text_white: true },
  { code: 'rgb(0, 207, 221)', is_text_white: true },
  { code: 'rgb(67, 125, 236)', is_text_white: true },
  { code: 'rgb(67, 197, 252)', is_text_white: true },
  { code: 'rgb(97, 21, 66)', is_text_white: true },
  { code: 'rgb(109, 196, 225)', is_text_white: true },
  { code: 'rgb(116, 19, 61)', is_text_white: true },
  { code: 'rgb(0, 72, 242)', is_text_white: true },
  { code: 'rgb(6, 202, 8)', is_text_white: true },
  { code: 'rgb(10, 52, 66)', is_text_white: true },
  { code: 'rgb(33, 5, 236)', is_text_white: true },
  { code: 'rgb(48, 48, 177)', is_text_white: true },
  { code: 'rgb(94, 139, 108)', is_text_white: true },
  { code: 'rgb(119, 60, 241)', is_text_white: true },
  { code: 'rgb(111, 226, 58)', is_text_white: true },
  { code: 'rgb(48, 124, 239)', is_text_white: true },
  { code: 'rgb(7, 98, 200)', is_text_white: true },
  { code: 'rgb(41, 188, 175)', is_text_white: true },
  { code: 'rgb(106, 141, 83)', is_text_white: true },
  { code: 'rgb(36, 146, 153)', is_text_white: true },
  { code: 'rgb(10, 211, 78)', is_text_white: true },
  { code: 'rgb(16, 210, 42)', is_text_white: true },
  { code: 'rgb(123, 55, 117)', is_text_white: true },
  { code: 'rgb(129, 44, 20)', is_text_white: true },
  { code: 'rgb(97, 173, 213)', is_text_white: true },
  { code: 'rgb(66, 153, 193)', is_text_white: true },
  { code: 'rgb(74, 255, 62)', is_text_white: true },
  { code: 'rgb(121, 109, 235)', is_text_white: true },
  { code: 'rgb(28, 176, 87)', is_text_white: true },
  { code: 'rgb(104, 181, 201)', is_text_white: true },
  { code: 'rgb(13, 17, 23)', is_text_white: true },
];