import React, { Component } from 'react';
import { Container, Card, Row, Col, Button, Spinner, InputGroup, Form, Table, Badge, ButtonGroup, DropdownButton, Dropdown } from 'react-bootstrap';
import { t } from '../../helpers/translation_helper';
import NavBar from '../_partials/NavBar/_NavBar';
import Header from '../_partials/Header/_Header';
import { connect } from 'react-redux';
import { brand_colors } from '../../helpers/brand_colors_helper';
import { faCalendar, faEye, faEyeSlash, faSearch, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DateRangePicker } from 'react-date-range';
import moment from 'moment';
import { GET } from '../../api';
import { numberFormat } from '../../helpers/general_helpers';
import { faCalendarAlt, faFileExcel } from '@fortawesome/free-regular-svg-icons';
import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';
import { renderTableBody, renderTableHead } from '../../helpers/table_helper';
import Pagination from '../_partials/Pagination';
import Select from 'react-select';
import { toast } from 'react-toastify';
let cancelToken;


class _EmployeeMasterReport extends Component {

    state = {
        fetching: false,
        showStatus: 0,
        cdt: {
            fetching: false,
            search: '',
            rows: 10,
            offset: 0,
            order: 'e.employee_name',
            dir: 'ASC',
            lastPage: 0,
            page: 0,
            totalRecords: 0,
            rowsOptions: [10, 25, 50, 'All'],
            columns: [
                {
                    id: 'e.empl_code',
                    column: 'empl_code',
                    name: 'Code',
                    visibility: true,
                    sortable: true
                },
                {
                    id: 'e.employee_name',
                    column: 'employee_name',
                    name: 'Name',
                    visibility: true,
                    sortable: true
                },
                {
                    id: 'e.gender',
                    column: 'gender',
                    name: 'Gender',
                    visibility: true,
                    sortable: true
                },
                {
                    id: 'c.name_en',
                    column: 'name_en',
                    name: 'Nationality',
                    visibility: true,
                    sortable: true
                },
                {
                    id: 'e.country_note',
                    column: 'country_note',
                    name: 'Notes',
                    visibility: true,
                    sortable: true
                },
                {
                    id: 'e.employee_status',
                    column: 'employee_status',
                    name: 'Status',
                    visibility: true,
                    sortable: true
                },
                {
                    id: 'e.phone1',
                    column: 'phone1',
                    name: 'Phone 1',
                    visibility: true,
                    sortable: true
                },
                {
                    id: 'e.phone2',
                    column: 'phone2',
                    name: 'Phone 2',
                    visibility: true,
                    sortable: true
                },
                {
                    id: 'e.email1',
                    column: 'email1',
                    name: 'Email 1',
                    visibility: true,
                    sortable: true
                },
                {
                    id: 'e.email2',
                    column: 'email2',
                    name: 'Email 2',
                    visibility: true,
                    sortable: true
                },
                {
                    id: 'e.gmail',
                    column: 'gmail',
                    name: 'Gmail',
                    visibility: true,
                    sortable: true
                },
                {
                    id: 'e.empl_dob',
                    column: 'empl_dob',
                    name: 'DOB',
                    visibility: true,
                    sortable: true
                },
                {
                    id: 'e.empl_desg_moe',
                    column: 'empl_desg_moe',
                    name: 'Designation @ Ministry',
                    visibility: true,
                    sortable: true
                },
                {
                    id: 'e.empl_desg_int',
                    column: 'empl_desg_int',
                    name: 'Designation @ Payroll',
                    visibility: true,
                    sortable: true
                },
                {
                    id: 'e.address',
                    column: 'address',
                    name: 'Address',
                    visibility: true,
                    sortable: true
                },
                {
                    id: 'e.general_area',
                    column: 'general_area',
                    name: 'Area',
                    visibility: true,
                    sortable: true
                },
                {
                    id: 'r.role_name',
                    column: 'role_name',
                    name: 'Role',
                    visibility: true,
                    sortable: true
                },
                {
                    id: 'e.marital_status',
                    column: 'marital_status',
                    name: 'Marital Status',
                    visibility: true,
                    sortable: true
                },
                {
                    id: 'e.kids_num',
                    column: 'kids_num',
                    name: 'Kids',
                    visibility: true,
                    sortable: true
                },
                {
                    id: 'e.name_ar',
                    column: 'name_ar',
                    name: 'Arabic Name',
                    visibility: true,
                    sortable: true
                },
                {
                    id: 'e.first_name',
                    column: 'first_name',
                    name: 'First Name',
                    visibility: true,
                    sortable: true
                },
                {
                    id: 'e.middle_name',
                    column: 'middle_name',
                    name: 'Middle Name',
                    visibility: true,
                    sortable: true
                },
                {
                    id: 'e.last_name',
                    column: 'last_name',
                    name: 'Last Name',
                    visibility: true,
                    sortable: true
                },
                {
                    id: 'e.religion_name',
                    column: 'religion_name',
                    name: 'Religion',
                    visibility: true,
                    sortable: true
                },
                {
                    id: 'e.local_experience',
                    column: 'local_experience',
                    name: 'Local Experience',
                    visibility: true,
                    sortable: true
                },
                {
                    id: 'e.broad_experience',
                    column: 'broad_experience',
                    name: 'Abroad Experience',
                    visibility: true,
                    sortable: true
                },
                {
                    id: 'e.emergency_contact_number',
                    column: 'emergency_contact_number',
                    name: 'Emergency Contact Number',
                    visibility: true,
                    sortable: true
                },
                {
                    id: 'e.emergency_contact_name',
                    column: 'emergency_contact_name',
                    name: 'Emergency Contact Name',
                    visibility: true,
                    sortable: true
                },
                {
                    id: 'e.work_start_time',
                    column: 'work_start_time',
                    name: 'Start Time',
                    visibility: true,
                    sortable: true
                },
                {
                    id: 'e.work_end_time',
                    column: 'work_end_time',
                    name: 'End Time',
                    visibility: true,
                    sortable: true
                },
                {
                    id: 'e.agent_id',
                    column: 'agent_id',
                    name: 'AgentID',
                    visibility: true,
                    sortable: true
                },
                {
                    id: 'e.empl_agentcode',
                    column: 'empl_agentcode',
                    name: 'Agent Code',
                    visibility: true,
                    sortable: true
                },
                {
                    id: 'e.account_num',
                    column: 'account_num',
                    name: 'Account Num',
                    visibility: true,
                    sortable: true
                },
                {
                    id: 'e.qb_account',
                    column: 'qb_account',
                    name: '3rd Party Account',
                    visibility: true,
                    sortable: true
                },
                {
                    id: 'e.bank_account',
                    column: 'bank_account',
                    name: 'Bank',
                    visibility: true,
                    sortable: true
                },
                {
                    id: 's.saltrans_name',
                    column: 'saltrans_name',
                    name: 'Salary Transfer',
                    visibility: true,
                    sortable: true
                },
                {
                    id: 'e.empl_sal_trans_detail',
                    column: 'empl_sal_trans_detail',
                    name: 'Transfer Details',
                    visibility: true,
                    sortable: true
                },
                {
                    id: 'e.empl_bank_acc',
                    column: 'empl_bank_acc',
                    name: 'Bank',
                    visibility: true,
                    sortable: true
                },
                {
                    id: 'e.empl_work_comp',
                    column: 'empl_work_comp',
                    name: 'Last Working Day',
                    visibility: true,
                    sortable: true
                },
                {
                    id: 'es.salary',
                    column: 'salary',
                    name: 'Salary',
                    visibility: true,
                    sortable: true
                },
                {
                    id: 'es.housing_allowance',
                    column: 'housing_allowance',
                    name: 'Housing',
                    visibility: true,
                    sortable: true
                },
                {
                    id: 'es.transportation_allowance',
                    column: 'transportation_allowance',
                    name: 'Transportation',
                    visibility: true,
                    sortable: true
                },
                {
                    id: 'es.other_allowance',
                    column: 'other_allowance',
                    name: 'Other Allowance',
                    visibility: true,
                    sortable: true
                },
                {
                    id: 'total_salary',
                    column: 'total_salary',
                    name: 'Total Salary',
                    visibility: true,
                    sortable: false
                },
                {
                    id: 'e.airticket_value',
                    column: 'airticket_value',
                    name: 'Airticket Value',
                    visibility: true,
                    sortable: true
                },
                {
                    id: 'e.yearly_vacation',
                    column: 'yearly_vacation',
                    name: 'Yearly Vacation',
                    visibility: true,
                    sortable: true
                },
                {
                    id: 'e.visa_sponsor',
                    column: 'visa_sponsor',
                    name: 'Sponsorship',
                    visibility: true,
                    sortable: true
                },
                {
                    id: 'e.degree',
                    column: 'degree',
                    name: 'Degree',
                    visibility: true,
                    sortable: true
                },
                {
                    id: 'e.diploma',
                    column: 'diploma',
                    name: 'Diploma',
                    visibility: true,
                    sortable: true
                },
                {
                    id: 'e.capabilities',
                    column: 'capabilities',
                    name: 'Capabilities',
                    visibility: true,
                    sortable: true
                },
                {
                    id: 'e.unified_num',
                    column: 'unified_num',
                    name: 'Unified Number',
                    visibility: true,
                    sortable: true
                },
                {
                    id: 'e.id_number',
                    column: 'id_number',
                    name: 'ID',
                    visibility: true,
                    sortable: true
                },
                {
                    id: 'e.id_validity',
                    column: 'id_validity',
                    name: 'ID Validity',
                    visibility: true,
                    sortable: true
                },
                {
                    id: 'e.passport_number',
                    column: 'passport_number',
                    name: 'Passport Num',
                    visibility: true,
                    sortable: true
                },
                {
                    id: 'e.passport_validity',
                    column: 'passport_validity',
                    name: 'Passport Validity',
                    visibility: true,
                    sortable: true
                },
                {
                    id: 'e.visa_number',
                    column: 'visa_number',
                    name: 'Visa Number',
                    visibility: true,
                    sortable: true
                },
                {
                    id: 'e.visa_date',
                    column: 'visa_date',
                    name: 'Visa Date',
                    visibility: true,
                    sortable: true
                },
                {
                    id: 'e.visa_expiry_date',
                    column: 'visa_expiry_date',
                    name: 'Visa Expiry Date',
                    visibility: true,
                    sortable: true
                },
                {
                    id: 'e.health_card',
                    column: 'health_card',
                    name: 'Health Card',
                    visibility: true,
                    sortable: true
                },
                {
                    id: 'e.labor_card',
                    column: 'labor_card',
                    name: 'Labor Card',
                    visibility: true,
                    sortable: true
                },
                {
                    id: 'e.insurance_id',
                    column: 'insurance_id',
                    name: 'Insurance ID',
                    visibility: true,
                    sortable: true
                },
                {
                    id: 'e.insurance_validity',
                    column: 'insurance_validity',
                    name: 'Insurance Validity',
                    visibility: true,
                    sortable: true
                },
                {
                    id: 'e.health_card_validity',
                    column: 'health_card_validity',
                    name: 'Health Card Validity',
                    visibility: true,
                    sortable: true
                },
                {
                    id: 'e.labor_card_validity',
                    column: 'labor_card_validity',
                    name: 'Labor Card Validity',
                    visibility: true,
                    sortable: true
                },
                {
                    id: 'e.joining_date',
                    column: 'joining_date',
                    name: 'Joining Date',
                    visibility: true,
                    sortable: true
                },
                {
                    id: 'et.empltype_name',
                    column: 'empltype_name',
                    name: 'Contract',
                    visibility: true,
                    sortable: true
                },
                {
                    id: 'e.contract_type',
                    column: 'contract_type',
                    name: 'Contract Type',
                    visibility: true,
                    sortable: true
                },
                {
                    id: 'e.contract_period_year',
                    column: 'contract_period_year',
                    name: 'Contract Period',
                    visibility: true,
                    sortable: true
                },
                {
                    id: 'e.contract_start_date',
                    column: 'contract_start_date',
                    name: 'Contract Start',
                    visibility: true,
                    sortable: true
                },
                {
                    id: 'e.contract_end_date',
                    column: 'contract_end_date',
                    name: 'Contract End',
                    visibility: true,
                    sortable: true
                },
                {
                    id: 'e.empl_cont_dur',
                    column: 'empl_cont_dur',
                    name: 'Contract Period',
                    visibility: true,
                    sortable: true
                },
                {
                    id: 'e.empl_note1',
                    column: 'empl_note1',
                    name: 'Note',
                    visibility: true,
                    sortable: true
                },
                {
                    id: 'e.empl_note2',
                    column: 'empl_note2',
                    name: 'Note',
                    visibility: true,
                    sortable: true
                },
                {
                    id: 'e.empl_lang1',
                    column: 'empl_lang1',
                    name: 'Language 1',
                    visibility: true,
                    sortable: true
                },
                {
                    id: 'e.empl_lang2',
                    column: 'empl_lang2',
                    name: 'Language 2',
                    visibility: true,
                    sortable: true
                },
                {
                    id: 'e.empl_lang3',
                    column: 'empl_lang3',
                    name: 'Language 3',
                    visibility: true,
                    sortable: true
                },

            ],
            data: [],
        },
    }

    componentDidMount() {
        this.fetch({
            showStatus: this.state.showStatus,
            search: this.state.cdt.search,
            limit: this.state.cdt.rows,
            order: this.state.cdt.order,
            dir: this.state.cdt.dir,
            page: this.state.cdt.page
        });
    }


    fetch = async (params) => {
        console.log("status---", this.state.showStatus)
        this.setState({
            showDateRange: false,
            cdt: {
                ...this.state.cdt,
                fetching: true,
            }
        });

        if (this.fetchReq) {
            this.fetchReq.abort();
        }
        this.fetchReq = new AbortController();

        try {
            const res = await GET('report-employees-master/get-employees-master-data', {
                signal: this.fetchReq.signal,
                params: {
                    showStatus: params ? params.showStatus : 0,
                    search: params ? params.search : '',
                    order: params ? params.order : '',
                    dir: params ? params.dir : '',
                    limit: params ? params.limit : '',
                    offset: parseInt(params ? params.page : '') * parseInt(params ? params.limit : ''),
                },
            });
            console.log("resData----------", res)
            if (res.status === 200) {

                this.setState({
                    cdt: {
                        ...this.state.cdt,
                        fetching: false,
                        search: params.search,
                        rows: parseInt(params.limit),
                        offset: parseInt(params.page) * parseInt(params.limit),
                        order: params.order,
                        dir: params.dir,
                        page: params.page,
                        totalRecords: parseInt(res.data.total_count_filtered),
                        lastPage: Math.ceil(parseInt(res.data.total_count_filtered) / parseInt(params.limit)),
                        data: res.data.data,
                    },
                });
            }

        } catch (err) {
            console.log('err', err)
            this.setState({
                cdt: {
                    ...this.state.cdt,
                    fetching: false,
                }
            });

        }
    }

    exportExcel = async () => {

        const excelData = this.state.cdt.data.map((item) => {
            const row = {};
            this.state.cdt.columns
                .filter((column) => column.visibility)
                .map((column) => {

                    if (
                        column.column == 'child_sen' ||
                        column.column == 'child_ea' ||
                        column.column == 'child_pp' ||
                        column.column == 'child_lac' ||
                        column.column == 'take_pictures' ||
                        column.column == 'pic_nursery' ||
                        column.column == 'pic_media' ||
                        column.column == 'pic_social' ||
                        column.column == 'staff_child' ||
                        column.column == 'session_sun' ||
                        column.column == 'session_mon' ||
                        column.column == 'session_tue' ||
                        column.column == 'session_wed' ||
                        column.column == 'session_thu' ||
                        column.column == 'session_fri' ||
                        column.column == 'session_sat' ||
                        column.column == 'visa_copy' ||
                        column.column == 'child_eid' ||
                        column.column == 'birth_certificate' ||
                        column.column == 'med_form' ||
                        column.column == 'indemnity_form' ||
                        column.column == 'child_vacc' ||
                        column.column == 'Photo'
                    ) {
                        row[column.name] = item[column.column] == 0 ? 'NO' : 'YES';
                    }
                    else {
                        row[column.name] = item[column.column];
                    }


                });
            return row;
        });

        const fileName = 'Employee Master List ' + moment().format('DD-MM-YYYY');
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';
        const ws = XLSX.utils.json_to_sheet(excelData);

        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });

        FileSaver.saveAs(data, fileName + fileExtension);
    }

    showHideColumn = (column_index) => {
        const columns = this.state.cdt.columns;
        columns[column_index].visibility = !columns[column_index].visibility;
        this.setState({ ctd: { ...this.state.ctd, columns } })
    }

    renderCell = (column_data, column_id, column_index, dataObj) => {

        const { authData } = this.props;
        const { center_id } = authData.centerData;

        let tdData;

        if (
            column_id == 'child_sen' ||
            column_id == 'child_ea' ||
            column_id == 'child_pp' ||
            column_id == 'child_lac' ||
            column_id == 'take_pictures' ||
            column_id == 'pic_nursery' ||
            column_id == 'pic_media' ||
            column_id == 'pic_social' ||
            column_id == 'staff_child' ||
            column_id == 'session_sun' ||
            column_id == 'session_mon' ||
            column_id == 'session_tue' ||
            column_id == 'session_wed' ||
            column_id == 'session_thu' ||
            column_id == 'session_fri' ||
            column_id == 'session_sat' ||
            column_id == 'visa_copy' ||
            column_id == 'child_eid' ||
            column_id == 'birth_certificate' ||
            column_id == 'med_form' ||
            column_id == 'indemnity_form' ||
            column_id == 'child_vacc' ||
            column_id == 'Photo'
        ) {
            tdData = column_data == 0 ? 'NO' : 'YES';
        } else if (column_id == 'marital_status') {
            tdData = column_data == 0 ? 'NO' : 'YES';
        }
        else if (column_id == 'total_salary') {
            if ((parseFloat(dataObj.salary) > 0 || dataObj.salary) &&
                parseFloat(dataObj.other_allowance) > 0 &&
                parseFloat(dataObj.housing_allowance) > 0 &&
                parseFloat(dataObj.transportation_allowance) > 0) {
                let sum = parseFloat(dataObj.salary) + parseFloat(dataObj.other_allowance) + parseFloat(dataObj.housing_allowance) + parseFloat(dataObj.transportation_allowance)
                tdData = sum;
            }
            else {
                dataObj.salary = '---'
                dataObj.other_allowance = '---'
                dataObj.housing_allowance = '--'
                dataObj.transportation_allowance = '--'
                tdData = '---'
            }

        }
        else if (column_id == 'employee_status') {
            tdData = column_data == 0 ? <span className="badge badge-light-danger">Inactive</span> : <span className="badge badge-c-success">Active</span>
        }
        else {
            tdData = column_data;
        }



        return <td key={column_index}>{tdData}</td>;
    }

    render() {

        return (
            <Container fluid>
                <div id='iedu-layout'>
                    <NavBar />
                    <div id='page-content'>
                        <Header lite={true} heading={t('Employee Master Report')} backBtn={true} />
                        <div className='grey-section'>
                            <Row>
                                <Col className='d-flex align-items-center justify-content-center'>
                                    <Form.Check
                                        onChange={() => this.setState({
                                            showStatus: this.state.showStatus == 0 ? 1 : 0
                                        }, () => this.fetch({
                                            showStatus: this.state.showStatus,
                                            search: this.state.cdt.search,
                                            limit: this.state.cdt.rows,
                                            order: this.state.cdt.order,
                                            dir: this.state.cdt.dir,
                                            page: 0
                                        }))}
                                        checked={this.state.showStatus == 1}
                                        type="switch"
                                        id="showStatus"
                                        label={(this.state.showStatus == 0) ? t('Showing all Employees') : t('Showing active Employees')}
                                        className='ms-3 me-4'
                                    />
                                </Col>
                            </Row>
                        </div>
                        <Card
                            className='border-0'
                            style={{ borderRadius: '20px' }}>
                            <Card.Header style={{
                                backgroundColor: '#fff',
                                borderTopLeftRadius: '20px',
                                borderTopRightRadius: '20px',
                            }}>
                                <Row>
                                    <Col md={6} className='d-flex align-items-center'>
                                        <ButtonGroup size='sm' className='me-3 float-left'>
                                            {/* <Button><FontAwesomeIcon icon={faFilePdf} /> {t('PDF')}</Button> */}
                                            <Button onClick={() => this.exportExcel()}>
                                                <FontAwesomeIcon icon={faFileExcel} /> {t('Excel')}
                                            </Button>
                                            {/* <Button><FontAwesomeIcon icon={faPrint} /> {t('Print')}</Button> */}
                                            <DropdownButton
                                                autoClose={'outside'}
                                                size='sm'
                                                as={ButtonGroup}
                                                title={t('Column Visibility')}>
                                                {this.state.cdt.columns.map((column, column_index) => (
                                                    <Dropdown.Item key={column_index} className={column.visibility ? 'column-name-item' : 'column-name-item active'} onClick={() => this.showHideColumn(column_index)}>
                                                        <FontAwesomeIcon icon={column.visibility ? faEye : faEyeSlash} /> {column.name}
                                                    </Dropdown.Item>
                                                ))}
                                            </DropdownButton>
                                        </ButtonGroup>
                                        <div style={{ display: 'inline-block' }}>
                                            {t('Show')}
                                            <Form.Select
                                                value={this.state.cdt.rows}
                                                size="sm"
                                                onChange={(e) => this.fetch({
                                                    showStatus: this.state.showStatus,
                                                    search: this.state.cdt.search,
                                                    limit: e.target.value,
                                                    order: this.state.cdt.order,
                                                    dir: this.state.cdt.dir,
                                                    page: this.state.cdt.page
                                                })}
                                                style={{ width: 70, marginInline: 5, display: 'inline-block' }}>
                                                {this.state.cdt.rowsOptions.map((row, row_index) => (<option key={row_index} >{row}</option>))}
                                            </Form.Select>
                                            {t('Records')}
                                        </div>
                                    </Col>
                                    <Col md={6} className='d-flex justify-content-end'>
                                        <div className='cdt-search'>
                                            <input type='text'
                                                ref={this.searchFieldRef}
                                                defaultValue={this.state.cdt.search}
                                                onChange={(e) => this.fetch({
                                                    showStatus: this.state.showStatus,
                                                    search: e.target.value,
                                                    limit: this.state.cdt.rows,
                                                    order: this.state.cdt.order,
                                                    dir: this.state.cdt.dir,
                                                    page: 0
                                                })}
                                                placeholder={t('Search...')} />
                                            <button
                                                onClick={() => {
                                                    this.fetch({
                                                        search: '',
                                                        limit: this.state.cdt.rows,
                                                        order: this.state.cdt.order,
                                                        dir: this.state.cdt.dir,
                                                        page: 0,
                                                        showStatus: this.state.showStatus,
                                                    });
                                                    this.searchFieldRef.current.value = '';
                                                }}>
                                                <FontAwesomeIcon
                                                    icon={this.state.cdt.search == '' ? faSearch : faXmark}
                                                    color={brand_colors[this.props.defaultTheme.theme_id].color18}
                                                />
                                            </button>
                                        </div>
                                    </Col>
                                </Row>
                            </Card.Header>
                            <Card.Body style={{ paddingTop: 8 }}>
                                <Row>
                                    {this.state.cdt.fetching &&
                                        <Col md={12} className='py-5 d-flex flex-column align-items-center'>
                                            <Spinner as='span' animation='grow' size='sm' /> {t('Loading Data')}
                                        </Col>
                                    }
                                    {!this.state.cdt.fetching && this.state.cdt.data.length === 0 &&
                                        <Col md={12} className='py-5 d-flex flex-column align-items-center'>
                                            {t('No Data Found')}
                                        </Col>
                                    }
                                    {!this.state.cdt.fetching && this.state.cdt.data.length !== 0 &&
                                        <Col md={12} className='pt-2'>
                                            <div id="datatable-list" className='mb-3' style={{ width: 'initial' }}>
                                                <Table responsive className='students-master-report'>
                                                    {renderTableHead(this)}
                                                    {renderTableBody(this)}
                                                </Table>
                                            </div>
                                        </Col>
                                    }
                                </Row>
                                <Pagination
                                    pageCount={this.state.cdt.lastPage || 1}
                                    forcePage={this.state.cdt.page}
                                    callbackParams={{
                                        showStatus: this.state.showStatus,
                                        search: this.state.cdt.search,
                                        limit: this.state.cdt.rows,
                                        order: this.state.cdt.order,
                                        dir: this.state.cdt.dir,
                                    }}
                                    callback={this.fetch}
                                />
                            </Card.Body>
                        </Card>
                    </div>
                </div>
            </Container>
        );
    }
}

const mapStateToProps = (state) => ({
    authData: state.auth.authData,
    selectedClass: state.selectedClass.data,
    defaultLanguage: state.language.defaultLanguage,
    defaultTheme: state.theme.defaultTheme,
});

export default connect(mapStateToProps, null)(_EmployeeMasterReport);
