import React, { Component, Fragment } from 'react';
import { Container, Card, Table, Row, Col, Spinner, Image, Badge, Button, Form, Offcanvas, Accordion, Modal } from 'react-bootstrap';
import { t } from '../../helpers/translation_helper';
import NavBar from '../_partials/NavBar/_NavBar';
import Header from '../_partials/Header/_Header';
import { connect } from 'react-redux';
import axios from 'axios';
import { toast } from 'react-toastify';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight, faCalendarAlt, faCheck, faClock, faEdit, faExternalLink, faFileAlt, faGear, faPaperclip, faSave, faSearch, faTimes, faTrashCan, } from '@fortawesome/free-solid-svg-icons';
import { Scrollbar } from 'react-scrollbars-custom';
import imgDescription from '../../assets/images/description.png';
import { brand_colors } from '../../helpers/brand_colors_helper';
import SweetAlert from 'react-bootstrap-sweetalert';
import Select from 'react-select';
import { handleFormErrors } from '../../helpers/form_helpers';
import { GET } from '../../api';
import { Chart } from 'react-chartjs-2';
var Chance = require('chance');
var chance = new Chance();


// API URL
const apiURL = process.env.REACT_APP_API_URL;

// Portal URL
const portalURL = process.env.REACT_APP_PORTAL_URL;

class ReportExams extends Component {
    constructor(props) {
        super(props);
        this.state = {
            centerlicData: { data: [], selected: null },
            termsData: { data: [], selected: null },
            reRenderTime: moment(),
            reportData: {
                processing: false,
                data: {},
                finished: false
            },
            modalDetails: {
                show: false,
                toggle: (data = {}) => this.setState({ modalDetails: { ...this.state.modalDetails, show: !this.state.modalDetails.show, data, details: [] } }, () => {
                    if (this.state.modalDetails.show) {
                        this.getReportDetails()
                    }
                }),
                data: {},
                details: []
            },
        }
    }

    componentDidMount() {
        this.getCenterlic()
    }

    getCenterlic = () => {
        try {
            if (this.getCenterlicReq) {
                this.getCenterlicReq.abort();
            }
            this.getCenterlicReq = new AbortController();
            GET('report-exams/get-centerlic', {
                signal: this.getCenterlicReq.signal,
            }).then((res) => {

                if (res) {
                    let selected = res.data.find((v) => v.reg_status == 1)
                    this.setState({ centerlicData: { ...this.state.centerlicData, data: res.data, selected: { value: selected.centerlic_id, label: selected.reg_year, reg_status: selected.reg_status } } }, () => this.getTerms())
                }
            })
        } catch (err) {
            console.log('err', err)
            toast.error(t('Something went wrong while deleting!'));

        }
    }
    getTerms = () => {
        try {
            if (this.getTermsReq) {
                this.getTermsReq.abort();
            }
            this.getTermsReq = new AbortController();
            GET('report-exams/get-terms', {
                signal: this.getTermsReq.signal,
            }).then((res) => {

                if (res) {
                    let selected = res.data.find((v) => v.centerlic_id == this.state.centerlicData.selected.value && v.term_active == 1)
                    let selectedObj = null
                    if (selected) {
                        selectedObj = { value: selected.term_id, label: selected.term_name, term_active: selected.term_active }
                    }
                    this.setState({ termsData: { ...this.state.termsData, data: res.data, selected: selectedObj } })
                }
            })
        } catch (err) {
            console.log('err', err)
            toast.error(t('Something went wrong while deleting!'));

        }
    }

    fetchReport = () => {
        let reportData = this.state.reportData
        let centerlicData = this.state.centerlicData
        let termsData = this.state.termsData
        if (!this.props.selectedClass) {
            toast.error(t('No Class Selected'));
            return
        }
        if (!this.props.selectedSubject) {
            toast.error(t('No Subject Selected'));
            return
        }
        if (!centerlicData.selected) {
            toast.error(t('Please select a Year'));
            return
        }
        if (!termsData.selected) {
            toast.error(t('Please select a Term'));
            return
        }


        this.setState({ reportData: { ...reportData, processing: true, data: {} } })

        try {
            if (this.fetchReportReq) {
                this.fetchReportReq.abort();
            }
            this.fetchReportReq = new AbortController();
            GET('report-exams/get-exams', {
                params: {
                    class_id: this.props.selectedClass.class.class_id,
                    acs_id: this.props.selectedSubject.acs_id,
                    centerlic_id: centerlicData.selected.value,
                    term_id: termsData.selected.value,
                    role_id: this.props.authData.loginData.role_id,
                    employee_id: this.props.authData.userData.employee_id,
                },
                signal: this.fetchReportReq.signal,
            }).then((res) => {

                if (res) {
                    this.setState({ reportData: { ...reportData, processing: false, data: res.data, finished: true }, reRenderTime: moment() })
                } else {
                    toast.error(t('Something went wrong while deleting!'));
                    this.setState({ reportData: { ...reportData, processing: false, data: {} } })
                }
            })
        } catch (err) {
            console.log('err', err)
            toast.error(t('Something went wrong while deleting!'));

            this.setState({ reportData: { ...reportData, processing: false, data: {} } })
        }
    }

    getReportDetails = () => {
        try {
            if (this.getReportDetailsReq) {
                this.getReportDetailsReq.abort();
            }
            this.getReportDetailsReq = new AbortController();
            GET('exams/get-exam-single', {
                params: {
                    class_id: this.state.modalDetails.data.class_id,
                    exams_id: this.state.modalDetails.data.exams_id,
                },
                signal: this.getReportDetailsReq.signal,
            }).then((res) => {

                if (res) {
                    this.setState({ modalDetails: { ...this.state.modalDetails, details: res.data } })
                }
            })
        } catch (err) {
            console.log('err', err)
            toast.error(t('Something went wrong while deleting!'));

        }
    }

    renderDetailsChart = () => {
        let modalDetails = this.state.modalDetails
        const labels = modalDetails.details.map((v, i) => v.child_name)
        let totalGrades = 0
        let exams_grade_low = 100000000000;
        let exams_grade_high = 0
        modalDetails.details.map((v, i) => {
            let examsgrade_grade = v.examsgrade_grade
            if (examsgrade_grade != null && exams_grade_low > parseFloat(examsgrade_grade)) {
                exams_grade_low = parseFloat(examsgrade_grade)
            }
            if (examsgrade_grade != null && exams_grade_high < parseFloat(examsgrade_grade)) {
                exams_grade_high = parseFloat(examsgrade_grade)
            }
            totalGrades += (examsgrade_grade) ? parseFloat(examsgrade_grade) : 0
        })
        let averageGrade = totalGrades / modalDetails.details.length
        let data = {
            labels,
            datasets: [
                {
                    type: 'line',
                    label: 'Lowest',
                    borderColor: brand_colors[this.props.defaultTheme.theme_id].color11,
                    borderWidth: 2,
                    fill: false,
                    data: labels.map(() => exams_grade_low),// modalDetails.data.exams_grade_low
                },
                {
                    type: 'line',
                    label: 'Highest',
                    borderColor: brand_colors[this.props.defaultTheme.theme_id].color4,
                    borderWidth: 2,
                    fill: false,
                    data: labels.map(() => exams_grade_high),//modalDetails.data.exams_grade_high
                },
                {
                    type: 'line',
                    label: 'Average',
                    borderColor: brand_colors[this.props.defaultTheme.theme_id].color16,
                    borderWidth: 2,
                    fill: false,
                    data: labels.map(() => averageGrade.toFixed(2)),
                },
                {
                    type: 'bar',
                    label: 'Students',
                    backgroundColor: brand_colors[this.props.defaultTheme.theme_id].color1,
                    data: modalDetails.details.map((v, i) => v.examsgrade_grade),
                    borderColor: 'white',
                    borderWidth: 2,
                },
            ],
        }


        return <div style={{ width: '100%' }}>
            <Chart type='bar' data={data} />
        </div>

    }

    render() {

        const { reportData, reRenderTime, centerlicData, termsData, modalDetails } = this.state
        let filteredTerms = []
        if (centerlicData.selected) {
            filteredTerms = termsData.data.filter((v, i) => v.centerlic_id == centerlicData.selected.value)
        }

        return (
            <Container fluid>
                <div id='iedu-layout'>
                    <NavBar />
                    <div id='page-content'>
                        <Header lite={true} heading={t('Exams Report')} backBtn={true} showClassSelector={true} showSubjectSelector={true} classSelectorFunc={[this.fetchReport]} subjectSelectorFunc={[this.fetchReport]} />
                        <div className='grey-section'>

                            <Row className='justify-content-between'>
                                <Col>
                                    <Row>
                                        <Col>
                                            <Select
                                                placeholder={t('Select Year')}
                                                options={centerlicData.data.map((v, i) => { return { label: v.reg_year, value: v.centerlic_id, reg_status: v.reg_status } })}
                                                onChange={(event) => this.setState({ centerlicData: { ...centerlicData, selected: event }, termsData: { ...termsData, selected: null } })
                                                }
                                                value={centerlicData.selected}
                                                menuPortalTarget={document.body}
                                                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                                formatOptionLabel={option => (
                                                    <div className='d-flex align-items-center'>
                                                        <span style={{ fontSize: 16 }} className='me-1'>{option.label}</span>
                                                        {option.reg_status == 1 && <Badge pill bg="success">{t('Active')}</Badge>}
                                                    </div>
                                                )}
                                            />
                                        </Col>
                                        <Col>
                                            <Select
                                                placeholder={t('Select Term')}
                                                options={filteredTerms.map((v, i) => { return { label: v.term_name, value: v.term_id, term_active: v.term_active } })}
                                                onChange={(event) => this.setState({ termsData: { ...termsData, selected: event } })
                                                }
                                                value={termsData.selected}
                                                menuPortalTarget={document.body}
                                                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                                formatOptionLabel={option => (
                                                    <div className='d-flex align-items-center'>
                                                        <span style={{ fontSize: 16 }} className='me-1'>{option.label}</span>
                                                        {option.term_active == 1 && <Badge pill bg="success">{t('Active')}</Badge>}
                                                    </div>
                                                )}
                                            />
                                        </Col>
                                        <Col>
                                            <Button variant={'success'} onClick={() => this.fetchReport()}>
                                                {reportData.processing
                                                    ? <Spinner as='span' animation='grow' size='sm' role='status' aria-hidden='true' />
                                                    : <FontAwesomeIcon icon={faSearch} style={{ fontSize: 16, color: brand_colors[this.props.defaultTheme.theme_id].color8, }} />
                                                }
                                            </Button>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col md="auto">

                                </Col>
                            </Row>
                        </div>
                        <div className='grey-section'>

                            {reportData.processing
                                ? <div className='text-center'>
                                    <Spinner as='span' animation='grow' size='sm' role='status' aria-hidden='true' />
                                </div>
                                : <RenderReport reportData={reportData} defaultTheme={this.props.defaultTheme} reRenderTime={reRenderTime} modalDetails={modalDetails} />
                            }
                        </div>
                    </div>
                </div>

                <Modal show={modalDetails.show} onHide={() => modalDetails.toggle()} size="lg" backdrop="static"
        keyboard={false}>
                    <Modal.Header closeButton>
                        <Modal.Title>{modalDetails.data.exams_name}</Modal.Title>
                    </Modal.Header>
                    {modalDetails.show &&
                        <Modal.Body className='custom-styled-form'>
                            {this.renderDetailsChart()}
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>{t('Name')}</th>
                                        <th>{t('Status')}</th>
                                        <th>{t('Grade')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {modalDetails.details.map((v, i) => {
                                        return <tr>
                                            <td>{v.child_name}</td>
                                            <td>
                                                {
                                                    (v.is_exampaper == 1)
                                                        ? <Badge pill bg="warning">{t('Exam Recitation')}</Badge>
                                                        : (v.start_datetime != null || v.end_datetime != null)
                                                            ? (v.end_datetime != null)
                                                                ? <Badge pill bg="success">{t('Completed')}</Badge>
                                                                : <Badge pill bg="warning">{t('In Progress')}</Badge>
                                                            : <Badge pill bg="danger">{t('Not Attempted')}</Badge>
                                                }
                                            </td>
                                            <td>
                                                {(v.examsgrade_grade == null)
                                                    ? <Badge pill bg="danger">{t('Not Graded')}</Badge>
                                                    : <Badge pill bg="success">{v.examsgrade_grade} / {v.examsgrade_totalgrade}</Badge>
                                                }
                                            </td>
                                        </tr>
                                    })}
                                </tbody>
                            </Table>
                        </Modal.Body>
                    }
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => modalDetails.toggle()}>
                            {t('Close')}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </Container>
        );
    }
}

const mapStateToProps = (state) => ({
    authData: state.auth.authData,
    defaultLanguage: state.language.defaultLanguage,
    selectedClass: state.selectedClass.data,
    selectedSubject: state.selectedClass.subject,
    children: state.children.list,
    defaultTheme: state.theme.defaultTheme,
});

export default connect(mapStateToProps, null)(ReportExams);


class RenderReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }


    shouldComponentUpdate(nextProps) {
        if (moment(nextProps.reRenderTime).isSame(moment(this.props.reRenderTime))) {

            return false
        }

        return true
    }

    render() {
        let reportData = this.props.reportData
        if (!reportData.finished) {
            return null
        }
        let modalDetails = this.props.modalDetails
        return (
            <div>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>{t('Actions')}</th>
                            <th>{t('Name')}</th>
                            <th>{t('Type')}</th>
                            <th>{t('Date')}</th>
                            <th>{t('Total-Grade')}</th>
                            <th>{t('Highest-Grade')}</th>
                            <th>{t('Lowest-Grade')}</th>
                            <th>{t('Grade-Posted')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {reportData.data.map((v, i) => {
                            return (<Fragment key={i}>
                                <tr className='cursor-pointer'>
                                    <td>
                                        <Button size='sm' variant={'success'} className='me-1' onClick={() => modalDetails.toggle(v)}>
                                            {t('View')}
                                        </Button>
                                    </td>
                                    <td>
                                        {v.exams_name}
                                    </td>
                                    <td>{v.examstype_name}</td>
                                    <td>{moment(v.exams_date).format("DD-MM-YYYY")}</td>
                                    <td>{v.exams_grade}</td>
                                    <td>{(v.exams_grade_posted == 0) ? t('Appear after Posting') : v.exams_grade_high}</td>
                                    <td>{(v.exams_grade_posted == 0) ? t('Appear after Posting') : v.exams_grade_low}</td>
                                    <td>{(v.exams_grade_posted == 1) ? <Badge pill bg="success">{t('Yes')}</Badge> : <Badge pill bg="warning">{t('No')}</Badge>}</td>
                                </tr>
                            </Fragment>
                            )
                        })}
                    </tbody>
                </Table>
            </div>
        )
    }
}
