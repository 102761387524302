import React, { Component } from 'react';
import { Container, Card, Row, Col, Modal, Button, Table, InputGroup, Form, Spinner, Badge } from 'react-bootstrap';
import { t } from '../../../helpers/translation_helper';
import NavBar from '../../_partials/NavBar/_NavBar';
import Header from '../../_partials/Header/_Header';
import { connect } from 'react-redux';
import { setExamGrades } from '../../../redux/slices/examGradesModalSlice';
import { GET, POST } from '../../../api';
import { toast } from 'react-toastify';
import { brand_colors } from '../../../helpers/brand_colors_helper';
import { changeColorOpacity, DDL_ExamstypegrpTypes } from '../../../helpers/general_helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTimesCircle, faLock, faLockOpen } from '@fortawesome/free-solid-svg-icons';
import Select from 'react-select';
import { faEye, faEyeSlash } from '@fortawesome/free-regular-svg-icons';

class ExamGradesModal extends Component {

  state = {
    DDL_examstypegrp_types: DDL_ExamstypegrpTypes(),
    fetching: false,
    working: false,
    canUnpublishAllGrades: false,
    getAbsencesData: [],
    examsgrades: [],
    isSaving: false,
  }

  componentDidMount() {
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.exam?.exams_id !== prevProps.exam?.exams_id && this.props.examGradesModalShow) {
      if (this.state.getAbsencesData.length <= 0) {
        this.getAbsences()
      }
      this.getExamSingle()
    }
  }

  getAbsences = async () => {
    try {
      const res = await GET('children/get-absences');
      this.setState({
        getAbsencesData: res.data.map((v, i) => { return { ...v, label: v.absence_name, value: v.absence_id } })
      });
    } catch (err) {
      console.log('err', err)

    }
  }

  getExamSingle = async () => {
    this.setState({
      fetching: true,
      examsgrades: [],
    });
    const { exams_id, class_id } = this.props.exam;
    try {
      const res = await GET('exams/get-exam-single', {
        params: {
          class_id,
          exams_id
        }
      });

      this.setState({
        fetching: false,
        examsgrades: res.data.map((v, i) => {
          if (!v.examsgrade_id) {
            return {
              ...v, examsgrade_id: 0,
              examsgrade_grade: 0,
              absence_id: 0,
              examsgrade_comment: '',
              examsgrade_posted: 0,
              examsgrade_hidden_std: 0,
              examsgrade_lock: 0
            }
          }
          return v
        }),
      });
    } catch (err) {
      console.log('err', err)
      this.setState({
        fetching: false,
      });
    }
  }

  setExamGrade = (data) => {
    console.log('data', data);
    let examsgrades = JSON.parse(JSON.stringify(this.state.examsgrades))
    let findExamGrade = examsgrades.findIndex((v, i) => v.child_id == data.child_id && v.exams_id == data.exams_id)
    if (findExamGrade !== -1) {
      examsgrades[findExamGrade] = data
    } else {
      examsgrades.push(data)
    }
    this.setState({ examsgrades: examsgrades })
  }

  setMultiExamGrade = (data) => {
    let examsgrades = JSON.parse(JSON.stringify(this.state.examsgrades))
    data.map((vv, ii) => {
      let findExamGrade = examsgrades.findIndex((v, i) => v.child_id == vv.child_id && v.exams_id == vv.exams_id)
      if (findExamGrade !== -1) {
        let absence_name = null
        if (vv.absence_id && parseInt(vv.absence_id) != 0) {
          absence_name = this.state.getAbsencesData.find((absence) => absence.absence_id == vv.absence_id)['absence_name']
        }
        examsgrades[findExamGrade] = { ...vv, absence_name: absence_name }
      } else {
        let absence_name = null
        if (vv.absence_id && parseInt(vv.absence_id) != 0) {
          absence_name = this.state.getAbsencesData.find((absence) => absence.absence_id == vv.absence_id)['absence_name']
        }
        examsgrades.push({ ...vv, absence_name: absence_name })
      }
    })
    this.setState({ examsgrades: examsgrades })
  }

  toggleLockExamsGrade = (examsgrades, updateAction) => {
    if (![1, 12, 19, 15, 22].includes(parseInt(this.props.authData.loginData.role_id))) {
      toast.warning(t('You are not allowed for this action!'));
      return
    }
    this.setState({ isSaving: true })
    try {
      if (this.toggleLockExamsGradeReq) {
        this.toggleLockExamsGradeReq.abort();
      }
      this.toggleLockExamsGradeReq = new AbortController();

      let fData = new FormData()
      fData.append('examsgrades', JSON.stringify(examsgrades))

      POST('exams/toggle-lock-exams-grade', fData, {
        signal: this.toggleLockExamsGradeReq.signal,
      }).then((res) => {
        if (res) {
          let resData = res.data
          console.log('resData', resData);
          toast[resData.messageType](t(resData.messageTitle + " " + resData.message));
          if (resData.type) {
            if (examsgrades.length == 1) {
              updateAction([{ ...examsgrades[0], ...resData.data }])
            } else {
              updateAction(examsgrades)
            }
          }
        } else {
          toast.error(t('Something went wrong!'));
        }
        this.setState({ isSaving: false })
      })
    } catch (err) {
      console.log('err', err)
      toast.error(t('Something went wrong!'));
      this.setState({ isSaving: false })
    }
  }

  updateRubricExamGrades = () => {
    this.setState({ isSaving: true })
    try {
      if (this.state.examsgrades.length <= 0) {
        toast.error(t('Nothing to update!'));
        this.setState({ isSaving: false })
        return
      }

      let ifAbsentMIssing = false
      this.state.examsgrades.map((v, i) => {
        if (v.absent && parseInt(v.absence_id) == 0) {
          ifAbsentMIssing = true
        }
      })
      if (ifAbsentMIssing) {
        toast.error(t('Please pick Absence Type for all absents!'));
        this.setState({ isSaving: false })
        return
      }

      if (this.updateRubricExamGradesReq) {
        this.updateRubricExamGradesReq.abort();
      }
      this.updateRubricExamGradesReq = new AbortController();

      let fData = new FormData()
      fData.append('examsgrade', JSON.stringify(this.state.examsgrades))

      POST('exams/update-rubric-exam-grades', fData, {
        signal: this.updateRubricExamGradesReq.signal,
      }).then((res) => {
        if (res) {
          let resData = res.data
          toast[resData.messageType](t(resData.message));
          if (resData.type) {
            this.getExamSingle()
          } else {

          }
        } else {
          toast.error(t('Something went wrong!'));
        }
        this.setState({ isSaving: false })
      })
    } catch (err) {
      console.log('err', err)
      toast.error(t('Something went wrong!'));
      this.setState({ isSaving: false })
    }
  }


  render() {
    if (!this.props.examGradesModalShow) {
      return null
    }
    const { role_id } = this.props.authData.loginData;

    console.log('this.props.exam', this.props, this.state);

    let examstypegrp_type = this.state.DDL_examstypegrp_types.find((v, i) => v.value == this.props.exam.examstypegrp_type)

    return (
      <Modal show={this.props.examGradesModalShow} size='xl' onHide={() => this.props.setExamGrades({ showModal: false, exam: {} })} backdrop="static"
      keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>

            <div className='d-flex flex-row align-items-center'>
              <div className='d-flex flex-column me-5'>
                <span style={{ fontSize: 14, fontWeight: 'bold', backgroundColor: (examstypegrp_type) ? changeColorOpacity(examstypegrp_type.color, 0.2) : '#fff', color: (examstypegrp_type) ? examstypegrp_type.color : '#000', borderRadius: 10, padding: '0.1rem 0.5rem', textAlign: 'center' }}>{examstypegrp_type?.label}</span>
                {this.props.exam?.exams_name}
              </div>
              <div className='d-flex flex-column'>
                <Badge bg={'warning'} className='mb-1' style={{ fontSize: 12 }}>{this.props.exam.examstype_name}</Badge>
                {['Exam', 'Homework'].includes(this.props.exam.examstypegrp_type) &&
                  <Badge bg={this.props.exam.is_exampaper == 0 ? 'success' : 'info'} style={{ fontSize: 12 }}>{this.props.exam.is_exampaper == 0 ? t('Online') : t('Recitation')}</Badge>
                }
              </div>
            </div>

          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.state.fetching &&
            <Row>
              <Col className='py-5 d-flex flex-column align-items-center'>
                <Spinner animation="grow" className='mb-2' />
                {t('Loading Grades')}
              </Col>
            </Row>
          }
          {!this.state.fetching &&
            <Table size='sm'>
              <thead>
                <tr>
                  <th width='2%'>#</th>
                  <th width='20%'>{t('Student')}</th>
                  <th width='30%'>{t('Grade')}</th>
                  <th width='25%'>{t('Comment')}</th>
                  <th style={{ textAlign: 'center' }} title={t('Lock the Grades so only authorized users can change it')}>{t('Grade Lock')}</th>
                  <th style={{ textAlign: 'center' }} title={t('Hide Grades from the Student, Only the parent can see')}>{t('Hide Grade')}</th>
                  <th style={{ textAlign: 'center' }} title={t('Publish grades for the Parents/Students')}>{t('Grade Published')}</th>
                </tr>
              </thead>
              <tbody>
                {React.Children.toArray(
                  this.state.examsgrades.map((item, index) => {
                    return <tr style={{ position: 'relative' }}>
                      <td style={{ position: 'relative' }}>
                        {/* IF LOCKED */}
                        {parseInt(item.examsgrade_lock) == 1 &&
                          <div className='cursor-pointer' style={{
                            position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', zIndex: 1, backgroundColor: changeColorOpacity
                              (brand_colors[this.props.defaultTheme.theme_id].color11, 0.2)
                          }}></div>
                        }

                        {index + 1}
                      </td>
                      <td style={{ position: 'relative' }}>
                        {/* IF LOCKED */}
                        {parseInt(item.examsgrade_lock) == 1 &&
                          <div className='cursor-pointer' style={{
                            position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', zIndex: 1, backgroundColor: changeColorOpacity
                              (brand_colors[this.props.defaultTheme.theme_id].color11, 0.2)
                          }}></div>
                        }
                        {item.child_name}
                      </td>

                      <td style={{ position: 'relative' }}>
                        {/* IF LOCKED */}
                        {parseInt(item.examsgrade_lock) == 1 &&
                          <div className='cursor-pointer' style={{
                            position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', zIndex: 1, backgroundColor: changeColorOpacity
                              (brand_colors[this.props.defaultTheme.theme_id].color11, 0.2)
                          }}></div>
                        }
                        <div className='d-flex flex-row align-items-start'>
                          <div className='me-2'>
                            <Form.Check
                              type="switch"
                              label={t('Absent')}
                              onChange={() =>
                                this.setExamGrade({
                                  ...item,
                                  absent: !(item.absent || (item?.absence_id != 0 && item?.absence_id != null)),
                                  absence_id: 0,
                                  item_grade: 0,
                                })}
                              checked={item.absent || (item?.absence_id != 0 && item?.absence_id != null)}
                            />
                          </div>
                          <Form.Group style={{ width: '100%' }}>
                            {(item.absent || (item?.absence_id != 0 && item?.absence_id != null))
                              ? <Select
                                size="sm"
                                placeholder={t('-- Select --')}
                                options={this.state.getAbsencesData}
                                onChange={(obj) => this.setExamGrade({
                                  ...item,
                                  absence_id: obj.absence_id,
                                  absence_name: obj.absence_name,
                                })}
                                style={{}}
                                value={(item?.absence_id != 0 && item?.absence_id != null) ? { label: item.absence_name, value: item.absence_id, absence_name: item.absence_name, value: item.absence_id.absence_id } : ''}
                                menuPortalTarget={document.body}
                                styles={{
                                  menuPortal: (base) => ({ ...base, zIndex: 99999999999999 }), container: (base) => ({
                                    ...base,
                                    width: "max-content",
                                    minWidth: "100%",
                                  }),
                                }}
                              />
                              : <InputGroup>
                                <Form.Control type='text' value={item?.examsgrade_grade} disabled={(!parseInt(item.is_exampaper))} onChange={(e) => this.setExamGrade({
                                  ...item,
                                  examsgrade_grade: e.target.value,
                                })} />
                                <InputGroup.Text>{item?.examsgrade_totalgrade}</InputGroup.Text>
                              </InputGroup>}
                          </Form.Group>
                        </div>
                      </td>
                      <td style={{ position: 'relative' }}>
                        {/* IF LOCKED */}
                        {parseInt(item.examsgrade_lock) == 1 &&
                          <div className='cursor-pointer' style={{
                            position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', zIndex: 1, backgroundColor: changeColorOpacity
                              (brand_colors[this.props.defaultTheme.theme_id].color11, 0.2)
                          }}></div>
                        }
                        <Form.Control type="text"
                          onChange={(e) => this.setExamGrade({ ...item, 'examsgrade_comment': e.target.value })}
                          value={item.examsgrade_comment} />
                      </td>
                      <td style={{ textAlign: 'center' }}>
                        <FontAwesomeIcon className='cursor-pointer'
                          style={{ color: (!parseInt(item.examsgrade_lock)) ? brand_colors[this.props.defaultTheme.theme_id].color4 : brand_colors[this.props.defaultTheme.theme_id].color11 }}
                          icon={(!parseInt(item.examsgrade_lock)) ? faLockOpen : faLock} title={(!parseInt(item.examsgrade_lock)) ? t('Lock Now') : t('Un-Lock Now')}
                          onClick={() => {
                            if (![1, 12, 19, 15, 22].includes(parseInt(this.props.authData.loginData.role_id))) {
                              toast.warning(t('You are not allowed for this action!'));
                              return
                            }
                            this.setExamGrade({ ...item, 'examsgrade_lock': (!parseInt(item.examsgrade_lock)) ? 1 : 0 })
                          }}
                        />
                      </td>
                      <td style={{ textAlign: 'center' }}>
                        <FontAwesomeIcon className='cursor-pointer'
                          style={{ color: (!parseInt(item.examsgrade_hidden_std)) ? brand_colors[this.props.defaultTheme.theme_id].color4 : brand_colors[this.props.defaultTheme.theme_id].color11 }}
                          icon={(!parseInt(item.examsgrade_hidden_std)) ? faEye : faEyeSlash} title={(!parseInt(item.examsgrade_hidden_std)) ? t('Hide Now') : t('Show Now')}
                          onClick={() => this.setExamGrade({ ...item, 'examsgrade_hidden_std': (!parseInt(item.examsgrade_hidden_std)) ? 1 : 0 })}
                        />
                      </td>
                      <td style={{ textAlign: 'center' }}>
                        <FontAwesomeIcon className='cursor-pointer'
                          style={{ color: (!parseInt(item.examsgrade_posted)) ? brand_colors[this.props.defaultTheme.theme_id].color11 : brand_colors[this.props.defaultTheme.theme_id].color4 }}
                          icon={(!parseInt(item.examsgrade_posted)) ? faTimesCircle : faCheckCircle} title={(!parseInt(item.examsgrade_posted)) ? t('Publish Now') : t('Un-Publish Now')}
                          onClick={() => {
                            if (![1, 12, 19, 15, 22].includes(parseInt(this.props.authData.loginData.role_id))) {
                              toast.warning(t('You are not allowed for this action!'));
                              return
                            }
                            this.setExamGrade({ ...item, 'examsgrade_posted': (!parseInt(item.examsgrade_posted)) ? 1 : 0, 'examsgrade_lock': (!parseInt(item.examsgrade_posted)) ? 1 : 0 })
                          }}
                        />
                      </td>
                    </tr>
                  })
                )}
              </tbody>
            </Table>
          }
        </Modal.Body>
        <Modal.Footer>
          <div className='d-flex flex-row justify-content-between' style={{ width: '100%' }}>
            <Button variant="warning" onClick={() => this.updateRubricExamGrades()}
              disabled={this.state.isSaving}>
              {t('Update All')}
            </Button>
            <div className='d-flex flex-row'>

              {/* LOCK GRADES */}
              {([1, 12, 19, 15, 22].includes(parseInt(this.props.authData.loginData.role_id))) &&
                <div className='d-flex flex-column' style={{ border: 'solid 1px ' + brand_colors[this.props.defaultTheme.theme_id].color1, borderRadius: 5, marginLeft: 10 }}>
                  <div className='d-flex flex-row align-items-center p-1 cursor-pointer' style={{ flex: 1, backgroundColor: changeColorOpacity(brand_colors[this.props.defaultTheme.theme_id].color4, 0.2) }} onClick={() => {
                    if (![1, 12, 19, 15, 22].includes(parseInt(this.props.authData.loginData.role_id))) {
                      toast.warning(t('You are not allowed for this action!'));
                      return
                    }
                    let examsgrades = this.state.examsgrades.map((item) => {
                      return { ...item, 'examsgrade_lock': 0 }
                    })
                    this.setState({ examsgrades }, () => this.updateRubricExamGrades())
                  }}>
                    <FontAwesomeIcon
                      style={{ color: brand_colors[this.props.defaultTheme.theme_id].color4 }}
                      icon={faLockOpen} title={t('Un-Lock All')}
                    />
                    <div style={{ marginLeft: 3 }}>{t('Un-Lock All')}</div>
                  </div>
                  <div className='d-flex flex-row align-items-center p-1 cursor-pointer' style={{ borderTop: 'solid 1px ' + brand_colors[this.props.defaultTheme.theme_id].color1, flex: 1, backgroundColor: changeColorOpacity(brand_colors[this.props.defaultTheme.theme_id].color11, 0.2) }} onClick={() => {
                    if (![1, 12, 19, 15, 22].includes(parseInt(this.props.authData.loginData.role_id))) {
                      toast.warning(t('You are not allowed for this action!'));
                      return
                    }
                    let examsgrades = this.state.examsgrades.map((item) => {
                      return { ...item, 'examsgrade_lock': 1 }
                    })
                    this.setState({ examsgrades }, () => this.updateRubricExamGrades())
                  }}>
                    <FontAwesomeIcon
                      style={{ color: brand_colors[this.props.defaultTheme.theme_id].color11 }}
                      icon={faLock} title={t('Lock All')}
                    />
                    <div style={{ marginLeft: 3 }}>{t('Lock All')}</div>
                  </div>
                </div>
              }

              {/* POST GRADES */}
              {([1, 12, 19, 15, 22].includes(parseInt(this.props.authData.loginData.role_id))) &&
                <div className='d-flex flex-column' style={{ border: 'solid 1px ' + brand_colors[this.props.defaultTheme.theme_id].color1, borderRadius: 5, marginLeft: 10 }}>
                  <div className='d-flex flex-row align-items-center p-1 cursor-pointer' style={{ flex: 1, backgroundColor: changeColorOpacity(brand_colors[this.props.defaultTheme.theme_id].color11, 0.2) }} onClick={() => {
                    if (![1, 12, 19, 15, 22].includes(parseInt(this.props.authData.loginData.role_id))) {
                      toast.warning(t('You are not allowed for this action!'));
                      return
                    }
                    let examsgrades = this.state.examsgrades.map((item) => {
                      return { ...item, 'examsgrade_posted': 0, 'examsgrade_lock': 0 }
                    })
                    this.setState({ examsgrades }, () => this.updateRubricExamGrades())
                  }}>
                    <FontAwesomeIcon
                      style={{ color: brand_colors[this.props.defaultTheme.theme_id].color11 }}
                      icon={faTimesCircle} title={t('Un-Publish All')}
                    />
                    <div style={{ marginLeft: 3 }}>{t('Un-Publish All')}</div>
                  </div>
                  <div className='d-flex flex-row align-items-center p-1 cursor-pointer' style={{ borderTop: 'solid 1px ' + brand_colors[this.props.defaultTheme.theme_id].color1, flex: 1, backgroundColor: changeColorOpacity(brand_colors[this.props.defaultTheme.theme_id].color4, 0.2) }} onClick={() => {
                    if (![1, 12, 19, 15, 22].includes(parseInt(this.props.authData.loginData.role_id))) {
                      toast.warning(t('You are not allowed for this action!'));
                      return
                    }
                    let examsgrades = this.state.examsgrades.map((item) => {
                      return { ...item, 'examsgrade_posted': 1, 'examsgrade_lock': 1 }
                    })
                    this.setState({ examsgrades }, () => this.updateRubricExamGrades())
                  }}>
                    <FontAwesomeIcon
                      style={{ color: brand_colors[this.props.defaultTheme.theme_id].color4 }}
                      icon={faCheckCircle} title={t('Publish All')}
                    />
                    <div style={{ marginLeft: 3 }}>{t('Publish All')}</div>
                  </div>
                </div>
              }

            </div>
          </div>
        </Modal.Footer>
      </Modal >
    );
  }
}

const mapStateToProps = (state) => ({
  authData: state.auth.authData,
  defaultLanguage: state.language.defaultLanguage,
  selectedClass: state.selectedClass.data,
  exam: state.examGradesModal.exam,
  examGradesModalShow: state.examGradesModal.showModal,
  defaultTheme: state.theme.defaultTheme,
});

const mapDispatchToProps = () => ({
  setExamGrades,
});

export default connect(mapStateToProps, mapDispatchToProps())(ExamGradesModal);
