import React, { Component } from 'react';
import { Container, Card, Row, Col, Button, Spinner, InputGroup, Form, Table, Badge, ButtonGroup, DropdownButton, Dropdown } from 'react-bootstrap';
import { t } from '../../helpers/translation_helper';
import NavBar from '../_partials/NavBar/_NavBar';
import Header from '../_partials/Header/_Header';
import { connect } from 'react-redux';
import { brand_colors } from '../../helpers/brand_colors_helper';
import { faCalendar, faEye, faEyeSlash, faSearch, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DateRangePicker } from 'react-date-range';
import moment from 'moment';
import { GET } from '../../api';
import { generateColors, numberFormat } from '../../helpers/general_helpers';
import { faCalendarAlt, faFileExcel } from '@fortawesome/free-regular-svg-icons';
import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';
import { renderTableBody, renderTableHead } from '../../helpers/table_helper';
import Pagination from '../_partials/Pagination';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { Doughnut } from "react-chartjs-2";
let cancelToken;
const doughnutChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    cutout: 90,
    plugins: {
        legend: {
            position: 'bottom',
        },
    },
};

class ReportEmployeeDemography extends Component {

    state = {
        fetching: false,
        terms: [],
        classes: [],
        nationality: [],
        cycleData: [],
        grades: [],
        subjects: [],
        selectedCycle: null,
        selectedGrade: null,
        selectedSubject: null,
        cdt: {
            fetching: false,
            search: '',
            rows: 10,
            offset: 0,
            order: 'employees.employee_id',
            dir: 'ASC',
            lastPage: 0,
            page: 0,
            totalRecords: 0,
            rowsOptions: [10, 25, 50, 'All'],
            // I Think there is mistake in some id_column name
            columns: [
                {
                    id: 'employees.employee_id',
                    column: 'employee_id',
                    name: 'No',
                    visibility: true,
                    sortable: true
                },
                {
                    id: 'employees.employee_name',
                    column: 'employee_name',
                    name: 'Employee Name',
                    visibility: true,
                    sortable: true
                },
                {
                    id: 'employees.name_ar',
                    column: 'name_ar',
                    name: 'Arabic name',
                    visibility: true,
                    sortable: true
                },
                {
                    id: 'employees.capabilities',
                    column: 'capabilities',
                    name: 'Degrees',
                    visibility: true,
                    sortable: true
                },
                {
                    id: 'countries.name_en',
                    column: 'name_en',
                    name: 'Nationality',
                    visibility: true,
                    sortable: true
                },
                {
                    id: 'roles.role_name',
                    column: 'role_name',
                    name: 'Role',
                    visibility: true,
                    sortable: true
                },
                {
                    id: 'uniqueEmployeeClasses',
                    column: 'uniqueEmployeeClasses',
                    name: 'Class Assignment',
                    visibility: true,
                    sortable: false
                },

            ],
            data: [],
        },
    }

    componentDidMount() {
        this.fetch({
            search: this.state.cdt.search,
            limit: this.state.cdt.rows,
            order: this.state.cdt.order,
            dir: this.state.cdt.dir,
            page: this.state.cdt.page
        });
        this.getCycle()
    }
    getCycle = async () => {
        try {
            const res = await GET('report-employee-demography/get-cycle')
            console.log("datares", res)
            this.setState({
                cycleData: res.data.map(item => {
                    return {
                        ...item,
                        value: item.gcycle_id,
                        label: item.gcycle_desc
                    }
                })
            })
        }
        catch (e) {

        }
    }
    handleCycleChange = async (obje) => {
        this.setState({
            selectedCycle: obje
        })
        try {
            const res = await GET('report-employee-demography/get-class-main', {
                params: {
                    gcycle_id: obje.gcycle_id
                }
            })
            console.log("")
            this.setState({
                grades: res.data.data.map(item => {
                    return {
                        ...item,
                        value: item.agegroup_id,
                        label: item.agegroup_code
                    }
                }),
            });
        }
        catch (e) {
            console.log(e)

        }
    }
    handleSubjectSelection = async (selectedGrade) => {
        console.log("handleSubjectSelection", selectedGrade)
        this.setState({ selectedGrade })
        try {
            const res = await GET('report-employee-demography/get-subject', {
                params: {
                    agegroup_id: selectedGrade.agegroup_id
                }
            })
            console.log("resSelele", res)
            this.setState({
                subjects: res.data.map(item => {
                    return {
                        ...item,
                        value: item.acs_id,
                        label: item.acs_name
                    }
                }),
                selectedSubject: null,
            })
        }
        catch (e) {
            console.log(e)
        }
    }


    fetch = async (params) => {
        const { selectedCycle, selectedGrade, selectedSubject } = this.state
        this.setState({
            showDateRange: false,
            cdt: {
                ...this.state.cdt,
                fetching: true,
            }
        });

        if (this.fetchReq) {
            this.fetchReq.abort();
        }
        this.fetchReq = new AbortController();

        try {
            const res = await GET('report-employee-demography/get-employees', {
                signal: this.fetchReq.signal,
                params: {
                    country_id: this.props.authData.centerData.country_id,
                    gcycle_id: selectedCycle ? selectedCycle.gcycle_id : null,
                    agegroup_id: selectedGrade ? selectedGrade.agegroup_id : null,
                    acs_id: selectedSubject ? selectedSubject.acs_id : null,
                    search: params.search,
                    order: params.order,
                    dir: params.dir,
                    limit: params.limit,
                    offset: parseInt(params.page) * parseInt(params.limit),
                },
            });
            console.log("res", res)
            if (res.status === 200) {

                this.setState({
                    cdt: {
                        ...this.state.cdt,
                        fetching: false,
                        search: params.search,
                        rows: parseInt(params.limit),
                        offset: parseInt(params.page) * parseInt(params.limit),
                        order: params.order,
                        dir: params.dir,
                        page: params.page,
                        totalRecords: parseInt(res.data.total_count_filtered),
                        lastPage: Math.ceil(parseInt(res.data.total_count_filtered) / parseInt(params.limit)),
                        data: res.data.data,
                    },
                    nationality: res.data.data
                });
            }

        } catch (err) {
            console.log('err', err)
            this.setState({
                cdt: {
                    ...this.state.cdt,
                    fetching: false,
                }
            });

        }
    }

    exportExcel = async () => {

        const excelData = this.state.cdt.data.map((item) => {
            const row = {};
            this.state.cdt.columns
                .filter((column) => column.visibility)
                .map((column) => {

                    if (
                        column.column == 'child_sen' ||
                        column.column == 'child_ea' ||
                        column.column == 'child_pp' ||
                        column.column == 'child_lac' ||
                        column.column == 'take_pictures' ||
                        column.column == 'pic_nursery' ||
                        column.column == 'pic_media' ||
                        column.column == 'pic_social' ||
                        column.column == 'staff_child' ||
                        column.column == 'session_sun' ||
                        column.column == 'session_mon' ||
                        column.column == 'session_tue' ||
                        column.column == 'session_wed' ||
                        column.column == 'session_thu' ||
                        column.column == 'session_fri' ||
                        column.column == 'session_sat' ||
                        column.column == 'visa_copy' ||
                        column.column == 'child_eid' ||
                        column.column == 'birth_certificate' ||
                        column.column == 'med_form' ||
                        column.column == 'indemnity_form' ||
                        column.column == 'child_vacc' ||
                        column.column == 'Photo'
                    ) {
                        row[column.name] = item[column.column] == 0 ? 'NO' : 'YES';
                    }
                    else {
                        row[column.name] = item[column.column];
                    }


                });
            return row;
        });

        const fileName = 'Employee Demography' + moment().format('DD-MM-YYYY');
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';
        const ws = XLSX.utils.json_to_sheet(excelData);

        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });

        FileSaver.saveAs(data, fileName + fileExtension);
    }

    showHideColumn = (column_index) => {
        const columns = this.state.cdt.columns;
        columns[column_index].visibility = !columns[column_index].visibility;
        this.setState({ ctd: { ...this.state.ctd, columns } })
    }

    renderCell = (column_data, column_id, column_index, dataObj) => {
        //console.log("dataObj",dataObj)
        const { authData } = this.props;
        const { center_id } = authData.centerData;

        let tdData;

        if (
            column_id == 'child_sen' ||
            column_id == 'child_ea' ||
            column_id == 'child_pp' ||
            column_id == 'child_lac' ||
            column_id == 'take_pictures' ||
            column_id == 'pic_nursery' ||
            column_id == 'pic_media' ||
            column_id == 'pic_social' ||
            column_id == 'staff_child' ||
            column_id == 'session_sun' ||
            column_id == 'session_mon' ||
            column_id == 'session_tue' ||
            column_id == 'session_wed' ||
            column_id == 'session_thu' ||
            column_id == 'session_fri' ||
            column_id == 'session_sat' ||
            column_id == 'visa_copy' ||
            column_id == 'child_eid' ||
            column_id == 'birth_certificate' ||
            column_id == 'med_form' ||
            column_id == 'indemnity_form' ||
            column_id == 'child_vacc' ||
            column_id == 'Photo'
        ) {
            tdData = column_data == 0 ? 'NO' : 'YES';
        } else if (column_id == 'uniqueEmployeeClasses') {
            let classes = dataObj.classes;

            if (classes.length > 0) {

                classes.map((v, i) => {
                    let assignment = v.academic_classmain_subject;
                    tdData = <span className="badge badge-light-success" style={{ padding: '14px' }}>
                        {
                            v.class.class_theme
                        }
                        {
                            assignment.map((vv, ii) => {
                                {
                                    return <span className="badge badge-c-primary" style={{ marginRight: '5px', marginLeft: '10px' }}>{vv.as_name}</span>
                                }
                            })
                        }

                    </span>

                })
            }

        }
        else {
            tdData = column_data;
        }



        return <td key={column_index}>{tdData}</td>;
    }

    render() {
        const DATA_COUNT = 5;
        const NUMBER_CFG = { count: DATA_COUNT, min: 0, max: 100 };
        let nationality_all = [];
        console.log(" this.state.nationality", this.state.nationality)
        this.state.nationality.map((v, i) => {
            nationality_all[i] = v.name_en;
        })
        const countryCounts = nationality_all.reduce((acc, country) => {
            acc[country] = (acc[country] || 0) + 1;
            return acc;
        }, {});

        let uniq = [...new Set(nationality_all)];
        const dataGraphNationality = {
            labels: uniq,
            datasets: [
                {
                    data: Object.values(countryCounts),
                    backgroundColor: generateColors(nationality_all.length),
                    hoverBackgroundColor: generateColors(nationality_all.length),

                }
            ]
        };



        let NationalityLocalData = [];
        this.state.nationality.map((v, i) => {
            if (v.country_id == this.props.authData.centerData.country_id) {
                NationalityLocalData[v.name_en + " (National)"] = (NationalityLocalData[v.name_en + " (National)"] || 0) + 1;
            }
            else {
                NationalityLocalData["Expatriate"] = (NationalityLocalData["Expatriate"] || 0) + 1;
            }
        })
        console.log("uniqueData", NationalityLocalData)
        let NationalityLocalDataCount = Object.keys(NationalityLocalData).length
        const dataGraphNationalityLocal = {
            labels: Object.keys(NationalityLocalData),
            datasets: [
                {
                    data: Object.values(NationalityLocalData),
                    backgroundColor: generateColors(NationalityLocalDataCount),
                    hoverBackgroundColor: generateColors(NationalityLocalDataCount),
                }
            ]
        };
        let RoleGraphData = [];
        this.state.nationality.map((v, i) => {
            RoleGraphData[v.role_name] = (RoleGraphData[v.role_name] || 0) + 1;
        })

        let RoleGraphDataCount = Object.keys(RoleGraphData).length
        const dataGraphRole = {
            labels: Object.keys(RoleGraphData),
            datasets: [
                {
                    data: Object.values(RoleGraphData),
                    backgroundColor: generateColors(RoleGraphDataCount),
                    hoverBackgroundColor: generateColors(RoleGraphDataCount),
                }
            ]
        };
        return (
            <Container fluid>
                <div id='iedu-layout'>
                    <NavBar />
                    <div id='page-content'>
                        <Header lite={true} heading={t('Employee Demography')} backBtn={true} />
                        <div className='grey-section'>
                            <Row>
                                <Col md={11}>
                                    <Row>
                                        <Col md={4} className='pe-0 custom-styled-form'>
                                            <Form.Label>{t('Cycle')}</Form.Label>
                                            <Select
                                                isClearable={true}
                                                placeholder={t('Cycle')}
                                                options={this.state.cycleData}
                                                onChange={this.handleCycleChange}
                                                value={this.state.selectedCycle}
                                                menuPortalTarget={document.body}
                                                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                                formatOptionLabel={option => (
                                                    <div className='d-flex align-items-center'>
                                                        <span style={{ fontSize: 16 }} className='me-1'>{option.label}</span>
                                                        {option.reg_status == 1 && <Badge pill bg="success">{t('Active')}</Badge>}
                                                    </div>
                                                )}
                                            />
                                        </Col>
                                        <Col md={4} className='pe-0 custom-styled-form'>
                                            <Form.Label>{t('Grade')}</Form.Label>
                                            <Select
                                                isClearable={true}
                                                placeholder={t('Select grade')}
                                                options={this.state.grades}
                                                onChange={this.handleSubjectSelection}
                                                value={this.state.selectedTerm}
                                                defaultValue={this.state.selectedTerm}
                                                menuPortalTarget={document.body}
                                                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                                formatOptionLabel={option => (
                                                    <div className='d-flex align-items-center'>
                                                        <span style={{ fontSize: 16 }} className='me-1'>{option.label}</span>
                                                        {option.term_active == 1 && <Badge pill bg="success">{t('Active')}</Badge>}
                                                    </div>
                                                )}
                                            />
                                        </Col>
                                        <Col md={4} className='pe-0 custom-styled-form'>
                                            <Form.Label>{t('Subjects')}</Form.Label>
                                            <Select
                                                isClearable={true}
                                                placeholder={t('Select subject')}
                                                menuPortalTarget={
                                                    document.body
                                                }
                                                styles={{
                                                    menuPortal: (
                                                        base
                                                    ) => ({
                                                        ...base,
                                                        zIndex: 9999,
                                                    }),
                                                }}
                                                options={this.state.subjects}

                                                onChange={(obj) => { this.setState({ selectedSubject: obj }) }}
                                                defaultValue={
                                                    this.state.selectedSubject
                                                }
                                                value={
                                                    this.state.selectedSubject
                                                }
                                                className='basic-multi-select'
                                                classNamePrefix='select'
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col style={{ marginTop: 22 }}>
                                    <Button variant={'success'}
                                        style={{
                                            width: '100%',
                                            borderTopRightRadius: 20,
                                            borderBottomRightRadius: 20,
                                        }}
                                        onClick={() => this.fetch({
                                            search: this.state.cdt.search,
                                            limit: this.state.cdt.rows,
                                            order: this.state.cdt.order,
                                            dir: this.state.cdt.dir,
                                            page: this.state.cdt.page
                                        })}>
                                        {this.state.fetching
                                            ? <Spinner as='span' animation='grow' size='sm' role='status' aria-hidden='true' />
                                            : <FontAwesomeIcon icon={faSearch} style={{ fontSize: 16, color: brand_colors[this.props.defaultTheme.theme_id].color8, }} />
                                        }
                                    </Button>
                                </Col>
                            </Row>
                        </div>
                        <div className='grey-section'>
                            <Row>
                                <Col md="4">
                                    <div>
                                        <h3 style={{ textAlign: 'center' }}>Nationality</h3>
                                    </div>
                                    <Card
                                        className='border-0'
                                        style={{ borderRadius: '20px' }}>
                                        <Card.Body>
                                            <div>
                                                <Doughnut
                                                    data={dataGraphNationality}
                                                    options={doughnutChartOptions}
                                                    height={278}
                                                />
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md="4">
                                    <div>
                                        <h3 style={{ textAlign: 'center' }}>Local Nationality</h3>
                                    </div>
                                    <Card
                                        className='border-0'
                                        style={{ borderRadius: '20px' }}>
                                        <Card.Body>
                                            <div>
                                                <Doughnut
                                                    data={dataGraphNationalityLocal}
                                                    options={doughnutChartOptions}
                                                    height={278}
                                                />
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md="4">
                                    <div>
                                        <h3 style={{ textAlign: 'center' }}>Role</h3>
                                    </div>
                                    <Card
                                        className='border-0'
                                        style={{ borderRadius: '20px' }}>
                                        <Card.Body>
                                            <div>
                                                <Doughnut
                                                    data={dataGraphRole}
                                                    options={doughnutChartOptions}
                                                    height={278}
                                                />
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </div>
                        <Card
                            className='border-0'
                            style={{ borderRadius: '20px' }}>
                            <Card.Header style={{
                                backgroundColor: '#fff',
                                borderTopLeftRadius: '20px',
                                borderTopRightRadius: '20px',
                            }}>
                                <Row>
                                    <Col md={6} className='d-flex align-items-center'>
                                        <ButtonGroup size='sm' className='me-3 float-left'>
                                            {/* <Button><FontAwesomeIcon icon={faFilePdf} /> {t('PDF')}</Button> */}
                                            <Button onClick={() => this.exportExcel()}>
                                                <FontAwesomeIcon icon={faFileExcel} /> {t('Excel')}
                                            </Button>
                                            {/* <Button><FontAwesomeIcon icon={faPrint} /> {t('Print')}</Button> */}
                                            <DropdownButton
                                                autoClose={'outside'}
                                                size='sm'
                                                as={ButtonGroup}
                                                title={t('Column Visibility')}>
                                                {this.state.cdt.columns.map((column, column_index) => (
                                                    <Dropdown.Item key={column_index} className={column.visibility ? 'column-name-item' : 'column-name-item active'} onClick={() => this.showHideColumn(column_index)}>
                                                        <FontAwesomeIcon icon={column.visibility ? faEye : faEyeSlash} /> {column.name}
                                                    </Dropdown.Item>
                                                ))}
                                            </DropdownButton>
                                        </ButtonGroup>
                                        <div style={{ display: 'inline-block' }}>
                                            {t('Show')}
                                            <Form.Select
                                                value={this.state.cdt.rows}
                                                size="sm"
                                                onChange={(e) => this.fetch({
                                                    search: this.state.cdt.search,
                                                    limit: e.target.value,
                                                    order: this.state.cdt.order,
                                                    dir: this.state.cdt.dir,
                                                    page: this.state.cdt.page
                                                })}
                                                style={{ width: 70, marginInline: 5, display: 'inline-block' }}>
                                                {this.state.cdt.rowsOptions.map((row, row_index) => (<option key={row_index} >{row}</option>))}
                                            </Form.Select>
                                            {t('Records')}
                                        </div>
                                    </Col>
                                    <Col md={6} className='d-flex justify-content-end'>
                                        <div className='cdt-search'>
                                            <input type='text'
                                                ref={this.searchFieldRef}
                                                defaultValue={this.state.cdt.search}
                                                onChange={(e) => this.fetch({
                                                    search: e.target.value,
                                                    limit: this.state.cdt.rows,
                                                    order: this.state.cdt.order,
                                                    dir: this.state.cdt.dir,
                                                    page: 0
                                                })}
                                                placeholder={t('Search...')} />
                                            <button
                                                onClick={() => {
                                                    this.fetch({
                                                        search: '',
                                                        limit: this.state.cdt.rows,
                                                        order: this.state.cdt.order,
                                                        dir: this.state.cdt.dir,
                                                        page: 0
                                                    });
                                                    this.searchFieldRef.current.value = '';
                                                }}>
                                                <FontAwesomeIcon
                                                    icon={this.state.cdt.search == '' ? faSearch : faXmark}
                                                    color={brand_colors[this.props.defaultTheme.theme_id].color18}
                                                />
                                            </button>
                                        </div>
                                    </Col>
                                </Row>
                            </Card.Header>
                            <Card.Body style={{ paddingTop: 8 }}>
                                <Row>
                                    {this.state.cdt.fetching &&
                                        <Col md={12} className='py-5 d-flex flex-column align-items-center'>
                                            <Spinner as='span' animation='grow' size='sm' /> {t('Loading Data')}
                                        </Col>
                                    }
                                    {!this.state.cdt.fetching && this.state.cdt.data.length === 0 &&
                                        <Col md={12} className='py-5 d-flex flex-column align-items-center'>
                                            {t('No Data Found')}
                                        </Col>
                                    }
                                    {!this.state.cdt.fetching && this.state.cdt.data.length !== 0 &&
                                        <Col md={12} className='pt-2'>
                                            <div id="datatable-list" className='mb-3' style={{ width: 'initial' }}>
                                                <Table responsive className='students-master-report'>
                                                    {renderTableHead(this)}
                                                    {renderTableBody(this)}
                                                </Table>
                                            </div>
                                        </Col>
                                    }
                                </Row>
                                {/*<Pagination*/}
                                {/*    pageCount={this.state.cdt.lastPage || 1}*/}
                                {/*    forcePage={this.state.cdt.page}*/}
                                {/*    callbackParams={{*/}
                                {/*        search: this.state.cdt.search,*/}
                                {/*        limit: this.state.cdt.rows,*/}
                                {/*        order: this.state.cdt.order,*/}
                                {/*        dir: this.state.cdt.dir,*/}
                                {/*    }}*/}
                                {/*    callback={this.fetch}*/}
                                {/*/>*/}
                            </Card.Body>
                        </Card>
                    </div>
                </div>
            </Container>
        );
    }
}

const mapStateToProps = (state) => ({
    authData: state.auth.authData,
    selectedClass: state.selectedClass.data,
    defaultLanguage: state.language.defaultLanguage,
    defaultTheme: state.theme.defaultTheme,
});

export default connect(mapStateToProps, null)(ReportEmployeeDemography);
