import { createSlice } from '@reduxjs/toolkit';
export const generalSlice = createSlice({
    name: 'general',
    initialState: {
        notificationsCount: 0
    },
    reducers: {
        updateNotificationsCount: (state, action) => {
            state.notificationsCount = action.payload
        },
    },
});

export const { updateNotificationsCount } = generalSlice.actions;
export default generalSlice.reducer;
