import React, { Component } from 'react';
import { Container, Card, Row, Col, Image, Spinner, ButtonGroup, Button, Offcanvas, Form, Badge, Breadcrumb, Modal, Tabs, Tab, Table } from 'react-bootstrap';
import { t } from '../../helpers/translation_helper';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
} from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

class QuestionsGrades extends Component {
  state = {}

  eqa = (data) => {

    let TRs = data.exam_answers_children.map((eqa, eqa_i) => {

      const percentage = parseFloat(eqa.confirmed_answer_grade) / parseFloat(data.examsquest_grade) * 100;
      const gradeLevel = this.getGradeLevel(percentage);

      return (
        <tr key={eqa_i}>
          <td>{eqa.child_name}</td>
          <td>{parseFloat(data.examsquest_grade).toFixed(2)}/{parseFloat(eqa.confirmed_answer_grade).toFixed(2)} ({percentage}%)</td>
          <td><strong style={{ color: gradeLevel.gradeslevel_color }}>{gradeLevel.gradeslevel_name}</strong></td>
        </tr>
      );
    });

    return TRs;
  }

  getGradeLevel = (percentage) => {
    const { graphData } = this.props;

    const findGradeLevel = graphData.gradeslevel.find(gradelvl => parseFloat(percentage) >= parseFloat(gradelvl.gradeslevel_from) && parseFloat(percentage) <= parseFloat(gradelvl.gradeslevel_to));

    return findGradeLevel;
  }

  getGradeLevelColors = () => {
    const { graphData } = this.props;
    if (graphData) {
      return graphData.gradeslevel.map(level => level.gradeslevel_color);
    }
  }

  getGradeLevelLabels = () => {
    const { graphData } = this.props;
    if (graphData) {
      return graphData.gradeslevel.map(level => level.gradeslevel_name);
    }
  }

  graphData = (data) => {
    const { graphData } = this.props;
    const examGradeLevelsLabel = this.getGradeLevelLabels();

    if (graphData) {

      const gradeData = [];

      data.exam_answers_children.map((eqa, eqa_i) => {

        const percentage = parseFloat(eqa.confirmed_answer_grade) / parseFloat(data.examsquest_grade) * 100;
        const gradeLevel = this.getGradeLevel(percentage);

        gradeData.push({
          percentage,
          grade_level: gradeLevel.gradeslevel_name
        });

      });



      let examGradesLevelData = [];

      examGradeLevelsLabel.map((levelLabel, levelLabel_i) => {
        examGradesLevelData.push(gradeData.reduce((a, b) => {
          let c = 0;
          if (b.grade_level == levelLabel) {
            c = b.percentage;
          }
          return a += parseFloat(c);
        }, 0));
      });



      return examGradesLevelData;
    }

  }

  render() {

    const { graphData } = this.props;

    // Chart options
    const options = {
      plugins: {
        legend: {
          display: true,
        },
      },
    };

    if (graphData) {
      return (
        <>
          {graphData.examsgrade_question.map((eq, eq_i) => (
            <div key={eq_i}>
              <Row>
                <Col md={12}>
                  <h5>{eq.q_no}. {eq.eqtype_name}</h5>
                </Col>
                <Col md={10}>
                  <Table bordered hover size="sm">
                    <thead>
                      <tr>
                        <th width="34%">{t('Name')}</th>
                        <th width="33%">{t('Grade/Total')}</th>
                        <th width="33%">{t('Grade Level')}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.eqa(eq)}
                    </tbody>
                  </Table>
                </Col>
                <Col md={2}>
                  <Doughnut
                    data={{
                      labels: this.getGradeLevelLabels(),
                      datasets: [
                        {
                          data: this.graphData(eq),
                          backgroundColor: this.getGradeLevelColors(),
                        }
                      ]
                    }}
                    options={options}
                  />
                </Col>
              </Row>
              <hr />
            </div>
          )
          )}
        </>
      );
    } else {
      return (<Spinner size='sm' animation='grow' />);
    }

  }
}

export default QuestionsGrades;