import React, { Component, Fragment, useRef } from 'react';
import {
  Container,
  Card,
  Table,
  Row,
  Col,
  Spinner,
  Image,
  Badge,
  Button,
  Form,
  Offcanvas,
  Accordion,
  Modal,
  ButtonGroup,
} from 'react-bootstrap';
import { t } from '../../helpers/translation_helper';
import NavBar from '../_partials/NavBar/_NavBar';
import Header from '../_partials/Header/_Header';
import { connect } from 'react-redux';
import axios from 'axios';
import { toast } from 'react-toastify';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleLeft,
  faAngleRight,
  faCalendarAlt,
  faCheck,
  faClock,
  faEdit,
  faExternalLink,
  faFileAlt,
  faFileExcel,
  faGear,
  faPaperclip,
  faPieChart,
  faSave,
  faSearch,
  faTimes,
  faTrashCan,
} from '@fortawesome/free-solid-svg-icons';
import { Scrollbar } from 'react-scrollbars-custom';
import imgDescription from '../../assets/images/description.png';
import { brand_colors, colorsArray } from '../../helpers/brand_colors_helper';
import SweetAlert from 'react-bootstrap-sweetalert';
import Select from 'react-select';
import { handleFormErrors } from '../../helpers/form_helpers';
import { GET } from '../../api';
import { Bar, Pie } from 'react-chartjs-2';
import {
  changeColorOpacity,
  DDL_ExamstypegrpTypes,
} from '../../helpers/general_helpers';
import { useDownloadExcel } from 'react-export-table-to-excel';
var Chance = require('chance');
var chance = new Chance();

// API URL
const apiURL = process.env.REACT_APP_API_URL;

// Portal URL
const portalURL = process.env.REACT_APP_PORTAL_URL;

class ReportSubjectRubricRatio extends Component {
  constructor(props) {
    super(props);
    this.state = {
      centerlicData: { data: [], selected: null },
      termsData: { data: [], selected: [] },
      reRenderTime: moment(),
      reportData: {
        processing: false,
        data: {},
        finished: false,
      },
      modalGraph: {
        show: false,
        data: null,
        toggle: (data = null) =>
          this.setState({
            modalGraph: {
              ...this.state.modalGraph,
              show: !this.state.modalGraph.show,
              data: data,
            },
          }),
      },
    };
  }

  componentDidMount() {
    this.getCenterlic();
  }

  getCenterlic = () => {
    try {
      if (this.getCenterlicReq) {
        this.getCenterlicReq.abort();
      }
      this.getCenterlicReq = new AbortController();
      GET('report-exams/get-centerlic', {
        signal: this.getCenterlicReq.signal,
      }).then((res) => {
        if (res) {
          let selected = res.data.find((v) => v.reg_status == 1);
          this.setState(
            {
              centerlicData: {
                ...this.state.centerlicData,
                data: res.data,
                selected: {
                  value: selected.centerlic_id,
                  label: selected.reg_year,
                  reg_status: selected.reg_status,
                },
              },
            },
            () => this.getTerms()
          );
        }
      });
    } catch (err) {
      console.log('err', err);
      toast.error(t('Something went wrong while deleting!'));
    }
  };
  getTerms = () => {
    try {
      if (this.getTermsReq) {
        this.getTermsReq.abort();
      }
      this.getTermsReq = new AbortController();
      GET('report-exams/get-terms', {
        signal: this.getTermsReq.signal,
      }).then((res) => {
        if (res) {
          let selected = [];
          res.data.map((v, i) => {
            if (v.centerlic_id == this.state.centerlicData.selected.value) {
              selected.push({
                value: v.term_id,
                label: v.term_name,
                term_active: v.term_active,
              });
            }
          });
          this.setState({
            termsData: {
              ...this.state.termsData,
              data: res.data,
              selected: selected,
            },
          });
        }
      });
    } catch (err) {
      console.log('err', err);
      toast.error(t('Something went wrong while deleting!'));
    }
  };

  fetchReport = () => {
    let reportData = this.state.reportData;
    let centerlicData = this.state.centerlicData;
    let selectedSubject = this.props.selectedSubject;
    let termsData = this.state.termsData;
    if (!this.props.selectedClass) {
      toast.error(t('No Class Selected'));
      return;
    }
    if (!selectedSubject) {
      toast.error(t('No Subject Selected'));
      return;
    }
    if (!centerlicData.selected) {
      toast.error(t('Please select a Year'));
      return;
    }
    if (termsData.selected.length <= 0) {
      toast.error(t('Please select a Term'));
      return;
    }

    this.setState({
      reportData: { ...reportData, processing: true, data: {} },
    });

    try {
      if (this.fetchReportReq) {
        this.fetchReportReq.abort();
      }
      this.fetchReportReq = new AbortController();
      GET('academic-classmain-subject/get-academic-classmain-subject-rubric2', {
        params: {
          class_id: this.props.selectedClass.class.class_id,
          agegroup_id: this.props.selectedClass.class.agegroup_id,
          acs_ids: JSON.stringify([selectedSubject.acs_id]),
          centerlic_id: centerlicData.selected.value,
          term_ids: JSON.stringify(termsData.selected.map((v, i) => v.value)),
        },
        signal: this.fetchReportReq.signal,
      }).then((res) => {
        if (res) {
          let dataRubric = res.data.dataRubric;
          res.data.dataRubric = dataRubric.map((v, i) => {
            let exams = v.exams;
            let term_d_id = v.term.term_d_id;
            let examstypegrps = [];

            if (exams.length > 0) {
              exams.map((vv) => {
                let findetg = examstypegrps.findIndex(
                  (vvv) =>
                    vvv.examstype_id == vv.examstype_id &&
                    vvv.examstypegrp_id == vv.examstypegrp_id &&
                    vvv.examstypegrp_type == vv.examstypegrp_type
                );

                let term_linked_agegroup_assessment_detail =
                  res.data.examstypegrps.find(
                    (vvv) =>
                      vv.examstype_id == vvv.examstype_id &&
                      vv.examstypegrp_id == vvv.examstypegrp_id
                  );

                if (
                  term_linked_agegroup_assessment_detail.term_linked_agegroup_assessment_detail
                ) {
                  let tlaa_d_percentage =
                    term_linked_agegroup_assessment_detail
                      .term_linked_agegroup_assessment_detail[
                      'tlaa_d_percentage_' + term_d_id
                    ];
                  if (tlaa_d_percentage != null && tlaa_d_percentage != 0) {
                    if (findetg === -1) {
                      examstypegrps.push({
                        examstype_id: vv.examstype_id,
                        examstype_name: vv.examstype_name,
                        examstypegrp_id: vv.examstypegrp_id,
                        examstypegrp_name: vv.examstypegrp_name,
                        examstypegrp_type: vv.examstypegrp_type,
                        ...term_linked_agegroup_assessment_detail,
                        count: 1,
                      });
                    } else {
                      examstypegrps[findetg].count =
                        examstypegrps[findetg].count + 1;
                    }
                  }
                }
              });
              v['examstypegrps'] = examstypegrps;
            }
            return v;
          });

          this.setState({
            reportData: {
              ...reportData,
              processing: false,
              data: res.data,
              finished: true,
            },
            reRenderTime: moment(),
          });
        } else {
          this.setState({
            reportData: { ...reportData, processing: false, data: {} },
          });
        }
      });
    } catch (err) {
      console.log('err', err);
      toast.error(t('Something went wrong while deleting!'));

      this.setState({
        reportData: { ...reportData, processing: false, data: {} },
      });
    }
  };

  render() {
    const { reportData, reRenderTime, centerlicData, termsData, modalGraph } =
      this.state;
    let filteredTerms = [];
    if (centerlicData.selected) {
      filteredTerms = termsData.data.filter(
        (v, i) => v.centerlic_id == centerlicData.selected.value
      );
    }
    return (
      <Container fluid>
        <div id='iedu-layout'>
          <NavBar />
          <div id='page-content'>
            <Header
              lite={true}
              heading={t('Subject Rubric Ratio')}
              backBtn={true}
              showClassSelector={true}
              classSelectorFunc={[this.fetchReport]}
              showSubjectSelector={true}
              subjectSelectorFunc={[this.fetchReport]}
            />
            <div className='grey-section'>
              <Row className='justify-content-between'>
                <Col>
                  <Row>
                    <Col>
                      <Select
                        placeholder={t('Select Year')}
                        options={centerlicData.data.map((v, i) => {
                          return {
                            label: v.reg_year,
                            value: v.centerlic_id,
                            reg_status: v.reg_status,
                          };
                        })}
                        onChange={(event) =>
                          this.setState({
                            centerlicData: {
                              ...centerlicData,
                              selected: event,
                            },
                            termsData: { ...termsData, selected: [] },
                          })
                        }
                        value={centerlicData.selected}
                        menuPortalTarget={document.body}
                        styles={{
                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        }}
                        formatOptionLabel={(option) => (
                          <div className='d-flex align-items-center'>
                            <span style={{ fontSize: 16 }} className='me-1'>
                              {option.label}
                            </span>
                            {option.reg_status == 1 && (
                              <Badge pill bg='success'>
                                {t('Active')}
                              </Badge>
                            )}
                          </div>
                        )}
                      />
                    </Col>
                    <Col md='7'>
                      <Select
                        isMulti
                        isClearable
                        placeholder={t('Select Term')}
                        options={filteredTerms.map((v, i) => {
                          return {
                            label: v.term_name,
                            value: v.term_id,
                            term_active: v.term_active,
                          };
                        })}
                        onChange={(event) =>
                          this.setState({
                            termsData: { ...termsData, selected: event },
                          })
                        }
                        value={termsData.selected}
                        menuPortalTarget={document.body}
                        styles={{
                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        }}
                        formatOptionLabel={(option) => (
                          <div className='d-flex align-items-center'>
                            <span style={{ fontSize: 16 }} className='me-1'>
                              {option.label}
                            </span>
                            {option.term_active == 1 && (
                              <Badge pill bg='success'>
                                {t('Active')}
                              </Badge>
                            )}
                          </div>
                        )}
                      />
                    </Col>
                    <Col>
                      <Button
                        variant={'success'}
                        onClick={() => this.fetchReport()}>
                        {reportData.processing ? (
                          <Spinner
                            as='span'
                            animation='grow'
                            size='sm'
                            role='status'
                            aria-hidden='true'
                          />
                        ) : (
                          <FontAwesomeIcon
                            icon={faSearch}
                            style={{
                              fontSize: 16,
                              color:
                                brand_colors[this.props.defaultTheme.theme_id]
                                  .color8,
                            }}
                          />
                        )}
                      </Button>
                    </Col>
                  </Row>
                </Col>
                <Col md='auto'></Col>
              </Row>
            </div>
            <div className='grey-section'>
              {reportData.processing ? (
                <div className='text-center'>
                  <Spinner
                    as='span'
                    animation='grow'
                    size='sm'
                    role='status'
                    aria-hidden='true'
                  />
                </div>
              ) : (
                <RenderReport
                  reportData={reportData}
                  termsData={termsData}
                  authData={this.props.authData}
                  defaultTheme={this.props.defaultTheme}
                  reRenderTime={reRenderTime}
                  modalGraph={modalGraph}
                />
              )}
            </div>
          </div>
        </div>

        <Modal
          size='xl'
          show={modalGraph.show}
          onHide={modalGraph.toggle}
          backdrop='static'
          keyboard={false}>
          <Modal.Header closeButton>
            <Modal.Title></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {modalGraph.show && (
              <div className='d-flex flex-column'>
                {modalGraph.data.map((v) => {
                  return (
                    <div>
                      <Bar height={300} options={v.options} data={v.data} />
                    </div>
                  );
                })}
              </div>
            )}
          </Modal.Body>
        </Modal>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  authData: state.auth.authData,
  defaultLanguage: state.language.defaultLanguage,
  selectedClass: state.selectedClass.data,
  selectedSubject: state.selectedClass.subject,
  children: state.children.list,
  defaultTheme: state.theme.defaultTheme,
});

export default connect(mapStateToProps, null)(ReportSubjectRubricRatio);

class RenderReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      DDL_examstypegrp_types: DDL_ExamstypegrpTypes(),
    };
  }

  shouldComponentUpdate(nextProps) {
    if (
      moment(nextProps.reRenderTime).isSame(moment(this.props.reRenderTime))
    ) {
      return false;
    }

    return true;
  }

  render() {
    let reportData = this.props.reportData;
    if (!reportData.finished) {
      return null;
    }
    console.log('reportData', reportData);
    // let totalColspan = []
    // reportData.data.dataRubric.map((v) => {
    //     if (v.exams.length <= 0) {
    //         return null
    //     }
    //     v.examstypegrp.map((vv) => {
    //         if (vv.count <= 0) {
    //             return null
    //         }
    //         v.exams.map((vvv) => {
    //             if (vvv.examstypegrp_type == vv.examstypegrp_type) {
    //                 totalColspan.push(1)
    //             }
    //         })
    //         totalColspan.push(1)
    //     })
    // })
    // totalColspan={totalColspan}
    return (
      <div>
        <RenderTable
          reportData={reportData}
          modalGraph={this.props.modalGraph}
        />
      </div>
    );
  }
}

const RenderTable = (props) => {
  const totalColspan = props.totalColspan;
  const reportData = props.reportData;
  const DDL_examstypegrp_types = DDL_ExamstypegrpTypes();

  const tableRef = useRef(null);

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: '(Subject-Rubric)',
    sheet: 'S1',
  });

  var graphLabels = [];
  var graphData = [];
  let backgroundColor = colorsArray[0].color_code;
  graphData.push({
    label: 'Total Average',
    data: [],
    backgroundColor: backgroundColor,
  });
  const generateGraph = () => {
    console.log('generateGraph', graphLabels, graphData);
    let data = [];
    data.push({
      data: {
        labels: graphLabels,
        datasets: graphData,
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            position: 'bottom',
          },
          title: {
            display: true,
            text: 'Total Average',
          },
        },
      },
    });
    props.modalGraph.toggle(data);
  };

  return (
    <Card>
      <Card.Header>
        <Row>
          <Col md={12}>
            <ButtonGroup size='sm' className='me-3 float-left'>
              {/* <Button><FontAwesomeIcon icon={faFilePdf} /> {t('PDF')}</Button> */}
              <Button onClick={() => onDownload()}>
                <FontAwesomeIcon icon={faFileExcel} /> {t('Excel')}
              </Button>
              <Button onClick={() => generateGraph()}>
                <FontAwesomeIcon icon={faPieChart} /> {t('Graph')}
              </Button>
            </ButtonGroup>
          </Col>
        </Row>
      </Card.Header>
      <Card.Body>
        <Table size='sm' striped responsive bordered ref={tableRef}>
          <thead>
            <tr>
              <th rowSpan={'3'}>
                <div style={{ fontSize: 20 }}>{t('Student')}</div>
              </th>
              {/* TERM */}
              {reportData.data.dataRubric.map((v, i) => {
                let term_d_id = v.term.term_d_id;

                let count = 0;
                reportData.data.examstypegrps.map((vv) => {
                  if (
                    vv.term_linked_agegroup_assessment_detail &&
                    vv.term_linked_agegroup_assessment_detail[
                      'tlaa_d_percentage_' + term_d_id
                    ] != null &&
                    vv.term_linked_agegroup_assessment_detail[
                      'tlaa_d_percentage_' + term_d_id
                    ] != 0
                  ) {
                    count++;
                  }
                });

                if (v.examstypegrps) {
                  v.examstypegrps.map((vv, ii) => {
                    count += vv.count;
                  });
                }

                return (
                  <th key={i} colSpan={count}>
                    {v.term['term_name']}
                  </th>
                );
              })}
              <th rowSpan={'3'}>
                <div style={{ fontSize: 20, textWrap: 'nowrap' }}>
                  {t('Total Average')}
                </div>
              </th>
            </tr>
            {/* GRP TYPE */}
            <tr>
              {reportData.data.dataRubric.map((v, i) => {
                let term_d_id = v.term['term_d_id'];

                return reportData.data.examstypegrps.map((vv, ii) => {
                  if (
                    vv.term_linked_agegroup_assessment_detail &&
                    vv.term_linked_agegroup_assessment_detail[
                      'tlaa_d_percentage_' + term_d_id
                    ] != null &&
                    vv.term_linked_agegroup_assessment_detail[
                      'tlaa_d_percentage_' + term_d_id
                    ] != 0
                  ) {
                    let count = 1;
                    if (v.examstypegrps) {
                      v.examstypegrps.map((vvv) => {
                        if (
                          vvv.examstype_id == vv.examstype_id &&
                          vvv.examstypegrp_id == vv.examstypegrp_id
                        ) {
                          count += vvv.count;
                        }
                      });
                    }

                    let this_examstypegrp_type = DDL_examstypegrp_types.find(
                      (v_examstypegrp_types) =>
                        v_examstypegrp_types.value == vv.examstypegrp_type
                    );

                    let term_linked_agegroup_assessment_detail =
                      vv.term_linked_agegroup_assessment_detail;
                    let tlaa_d_percentage = 0;
                    if (term_linked_agegroup_assessment_detail) {
                      tlaa_d_percentage =
                        term_linked_agegroup_assessment_detail[
                          'tlaa_d_percentage_' + term_d_id
                        ];
                    }

                    return (
                      <th
                        key={ii}
                        colSpan={count}
                        style={{
                          backgroundColor: changeColorOpacity(
                            this_examstypegrp_type?.color,
                            0.2
                          ),
                        }}>
                        <div>
                          <div>
                            {vv.examstype_name} ({vv.examstypegrp_name})
                          </div>
                          <div>
                            {vv.examstypegrp_type} ({tlaa_d_percentage})
                          </div>
                        </div>
                      </th>
                    );
                  }
                });
              })}
            </tr>
            {/* EXAMS */}
            <tr>
              {reportData.data.dataRubric.map((v, i) => {
                let term_d_id = v.term['term_d_id'];

                return reportData.data.examstypegrps.map((vv, ii) => {
                  if (
                    vv.term_linked_agegroup_assessment_detail &&
                    vv.term_linked_agegroup_assessment_detail[
                      'tlaa_d_percentage_' + term_d_id
                    ] != null &&
                    vv.term_linked_agegroup_assessment_detail[
                      'tlaa_d_percentage_' + term_d_id
                    ] != 0
                  ) {
                    let this_examstypegrp_type = DDL_examstypegrp_types.find(
                      (v_examstypegrp_types) =>
                        v_examstypegrp_types.value == vv.examstypegrp_type
                    );
                    let total_exams_grade = 0;

                    return (
                      <>
                        {v.exams.map((vvv, iii) => {
                          if (
                            vvv.examstype_id == vv.examstype_id &&
                            vvv.examstypegrp_id == vv.examstypegrp_id &&
                            vvv.examstypegrp_type == vv.examstypegrp_type
                          ) {
                            total_exams_grade += parseFloat(vvv.exams_grade);
                            return (
                              <td
                                key={iii}
                                style={{
                                  backgroundColor: changeColorOpacity(
                                    this_examstypegrp_type?.color,
                                    0.1
                                  ),
                                }}>
                                <div className='d-flex flex-column'>
                                  <div
                                    style={{
                                      fontWeight: '600',
                                      textAlign: 'center',
                                    }}>
                                    {vvv.exams_name}
                                  </div>
                                  <div
                                    className='d-flex flex-row justify-content-around'
                                    style={{
                                      fontSize: 14,
                                      textWrap: 'nowrap',
                                    }}>
                                    <div>
                                      {moment(vvv.exams_date).format(
                                        'DD-MM-YYYY'
                                      )}
                                    </div>
                                    <div className='mx-1'>
                                      ({vvv.exams_grade})
                                    </div>
                                  </div>
                                </div>
                              </td>
                            );
                          }
                        })}
                        <th
                          style={{
                            backgroundColor: changeColorOpacity(
                              this_examstypegrp_type?.color,
                              0.1
                            ),
                          }}>
                          <div
                            className='d-flex flex-column'
                            style={{ textAlign: 'center' }}>
                            <div>{t('Average')}</div>
                            {/* <div>({total_exams_grade})</div> */}
                          </div>
                        </th>
                      </>
                    );
                  }
                });
              })}
            </tr>
          </thead>
          <tbody>
            {reportData.data.registrations.map((v, i) => {
              graphLabels.push(v.child_name);
              let totalCalcAverage = [];

              reportData.data.dataRubric.map((vv, ii) => {
                let term_d_id = vv.term['term_d_id'];

                reportData.data.examstypegrps.map((vvv, iii) => {
                  if (
                    vvv.term_linked_agegroup_assessment_detail &&
                    vvv.term_linked_agegroup_assessment_detail[
                      'tlaa_d_percentage_' + term_d_id
                    ] != null &&
                    vvv.term_linked_agegroup_assessment_detail[
                      'tlaa_d_percentage_' + term_d_id
                    ] != 0
                  ) {
                    let term_linked_agegroup_assessment_detail =
                      vvv.term_linked_agegroup_assessment_detail;
                    let tlaa_d_percentage = 0;
                    if (term_linked_agegroup_assessment_detail) {
                      tlaa_d_percentage =
                        term_linked_agegroup_assessment_detail[
                          'tlaa_d_percentage_' + term_d_id
                        ];
                    }

                    let calc_average = 0;
                    let count_all_exams = 0;

                    vv.exams.map((vvvv, iiii) => {
                      if (
                        vvvv.examstype_id == vvv.examstype_id &&
                        vvvv.examstypegrp_id == vvv.examstypegrp_id &&
                        vvvv.examstypegrp_type == vvv.examstypegrp_type
                      ) {
                        let examsgrade = vvvv.examsgrade.find(
                          (vvvvv) =>
                            vvvvv.child_id == v.child_id &&
                            vvvvv.exams_id == vvvv.exams_id
                        );
                        if (
                          !examsgrade ||
                          (examsgrade?.absence_id != 0 &&
                            examsgrade?.absence_id != null) ||
                          vvvv.exams_grade <= 0
                        ) {
                          // calc_average += parseFloat(0) / parseFloat(vvvv.exams_grade)
                        } else {
                          count_all_exams++;
                          calc_average +=
                            parseFloat(examsgrade?.examsgrade_grade) /
                            parseFloat(vvvv.exams_grade);
                        }
                      }
                    });
                    totalCalcAverage.push(
                      count_all_exams > 0
                        ? (calc_average / count_all_exams) *
                            100 *
                            (tlaa_d_percentage / 100)
                        : 0
                    );
                  }
                });
              });

              graphData[0].data.push(
                totalCalcAverage.length > 0
                  ? totalCalcAverage.reduce((p, c) => p + c).toFixed(2)
                  : 0
              );

              return (
                <tr key={i}>
                  <td>
                    <div style={{ fontWeight: '600' }}>{v.child_name}</div>
                  </td>
                  {reportData.data.dataRubric.map((vv, ii) => {
                    let term_d_id = vv.term['term_d_id'];

                    return reportData.data.examstypegrps.map((vvv, iii) => {
                      if (
                        vvv.term_linked_agegroup_assessment_detail &&
                        vvv.term_linked_agegroup_assessment_detail[
                          'tlaa_d_percentage_' + term_d_id
                        ] != null &&
                        vvv.term_linked_agegroup_assessment_detail[
                          'tlaa_d_percentage_' + term_d_id
                        ] != 0
                      ) {
                        let this_examstypegrp_type =
                          DDL_examstypegrp_types.find(
                            (v_examstypegrp_types) =>
                              v_examstypegrp_types.value ==
                              vvv.examstypegrp_type
                          );

                        let term_linked_agegroup_assessment_detail =
                          vvv.term_linked_agegroup_assessment_detail;
                        let tlaa_d_percentage = 0;
                        if (term_linked_agegroup_assessment_detail) {
                          tlaa_d_percentage =
                            term_linked_agegroup_assessment_detail[
                              'tlaa_d_percentage_' + term_d_id
                            ];
                        }

                        let calc_average = 0;
                        let count_all_exams = 0;
                        // console.log('calc_average, count_all_exams', calc_average, count_all_exams, tlaa_d_percentage);
                        return (
                          <>
                            {vv.exams.map((vvvv, iiii) => {
                              if (
                                vvvv.examstype_id == vvv.examstype_id &&
                                vvvv.examstypegrp_id == vvv.examstypegrp_id &&
                                vvvv.examstypegrp_type == vvv.examstypegrp_type
                              ) {
                                let examsgrade = vvvv.examsgrade.find(
                                  (vvvvv) =>
                                    vvvvv.child_id == v.child_id &&
                                    vvvvv.exams_id == vvvv.exams_id
                                );

                                if (
                                  !examsgrade ||
                                  (examsgrade?.absence_id != 0 &&
                                    examsgrade?.absence_id != null) ||
                                  vvvv.exams_grade <= 0
                                ) {
                                  // calc_average += parseFloat(0) / parseFloat(vvvv.exams_grade)
                                } else {
                                  count_all_exams++;
                                  calc_average +=
                                    parseFloat(examsgrade?.examsgrade_grade) /
                                    parseFloat(vvvv.exams_grade);
                                }

                                if (
                                  !examsgrade ||
                                  (examsgrade?.absence_id != 0 &&
                                    examsgrade?.absence_id != null)
                                ) {
                                  return (
                                    <td>
                                      <Badge bg={'danger'}>
                                        {examsgrade
                                          ? examsgrade.absence_name
                                          : t('Absent')}
                                      </Badge>
                                    </td>
                                  );
                                } else {
                                  return (
                                    <td>{examsgrade?.examsgrade_grade}</td>
                                  );
                                }
                              }
                            })}

                            <td
                              style={{
                                backgroundColor: changeColorOpacity(
                                  this_examstypegrp_type?.color,
                                  0.05
                                ),
                                fontSize: 16,
                                fontWeight: '600',
                              }}>
                              <div style={{ textWrap: 'nowrap' }}>
                                {count_all_exams > 0
                                  ? (
                                      (calc_average / count_all_exams) *
                                      100 *
                                      (tlaa_d_percentage / 100)
                                    ).toFixed(2)
                                  : '0.00'}{' '}
                                %
                              </div>
                            </td>
                          </>
                        );
                      }
                    });
                  })}
                  <td>
                    {/* {totalCalcAverage.length > 0 ? (totalCalcAverage.reduce((p, c) => p + c)).toFixed(2) : '0.00'} */}
                    <div
                      style={{
                        fontWeight: '600',
                        textAlign: 'center',
                        fontSize: 18,
                      }}>
                      {totalCalcAverage.length > 0
                        ? totalCalcAverage.reduce((p, c) => p + c).toFixed(2)
                        : '0.00'}{' '}
                      %
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
};
