import React, { Component } from 'react';
import { Container, Card, Row, Col, Button, Spinner, InputGroup, Form, Table, Badge, ButtonGroup, DropdownButton, Dropdown } from 'react-bootstrap';
import { t } from '../../helpers/translation_helper';
import NavBar from '../_partials/NavBar/_NavBar';
import Header from '../_partials/Header/_Header';
import { connect } from 'react-redux';
import { brand_colors } from '../../helpers/brand_colors_helper';
import { faCalendar, faEye, faEyeSlash, faSearch, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DateRangePicker } from 'react-date-range';
import moment from 'moment';
import { GET } from '../../api';
import { numberFormat } from '../../helpers/general_helpers';
import { faCalendarAlt, faFileExcel } from '@fortawesome/free-regular-svg-icons';
import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';
import { renderTableBody, renderTableHead } from '../../helpers/table_helper';
import Pagination from '../_partials/Pagination';
import Select from 'react-select';
import { toast } from 'react-toastify';

class ActiveStudents extends Component {

    state = {
        cdt: {
            fetching: false,
            search: '',
            rows: 10,
            offset: 0,
            order: 'ch.child_name',
            dir: 'ASC',
            lastPage: 0,
            page: 0,
            totalRecords: 0,
            rowsOptions: [10, 25, 50, 'All'],
            columns: [
                {
                    id: 'ch.child_id',
                    column: 'child_id',
                    name: 'ID',
                    visibility: true,
                    sortable: true,
                    width: 150
                },
                {
                    id: 'ch.child_name',
                    column: 'child_name',
                    name: 'Student Name',
                    visibility: true,
                    sortable: true
                },
                {
                    id: 'ch.first_name_en',
                    column: 'first_name_en',
                    name: 'First Name',
                    visibility: true,
                    sortable: true
                },
                {
                    id: 'ch.last_name_en',
                    column: 'last_name_en',
                    name: 'Last Name',
                    visibility: true,
                    sortable: true
                },
                {
                    id: 'rl.religion_name',
                    column: 'religion_name',
                    name: 'Religion',
                    visibility: true,
                    sortable: true
                },
                {
                    id: 'ch.child_name_en',
                    column: 'child_name_en',
                    name: 'Nationality',
                    visibility: true,
                    sortable: true
                },
                {
                    id: 'char.acc_num',
                    column: 'acc_num',
                    name: 'Account Number',
                    visibility: true,
                    sortable: true
                },
                {
                    id: 'ch.gender',
                    column: 'gender',
                    name: 'Gender',
                    visibility: true,
                    sortable: true
                },
                {
                    id: 'ch.child_code',
                    column: 'child_code',
                    name: 'Code',
                    visibility: true,
                    sortable: true
                },
                {
                    id: 'ch.birth_date',
                    column: 'birth_date',
                    name: 'Birth Date',
                    visibility: true,
                    sortable: true
                },
                {
                    id: 'cn.name_en',
                    column: 'name_en',
                    name: 'Nationality',
                    visibility: true,
                    sortable: true
                },
                {
                    id: 'p.account_num',
                    column: 'account_num',
                    name: 'Parent Number',
                    visibility: true,
                    sortable: true,
                },
                {
                    id: 'p.parent_name',
                    column: 'parent_name',
                    name: 'Parent Name',
                    visibility: true,
                    sortable: true
                },
                {
                    id: 'p.id_number',
                    column: 'id_number',
                    name: 'Parent ID Num',
                    visibility: true,
                    sortable: true
                },
                {
                    id: 'p.phone1',
                    column: 'phone1',
                    name: 'Phone 1',
                    visibility: true,
                    sortable: true
                },
                {
                    id: 'p.email1',
                    column: 'email1',
                    name: 'Email 1',
                    visibility: true,
                    sortable: true
                },
                {
                    id: 'p.parent_role',
                    column: 'parent_role',
                    name: 'Role',
                    visibility: true,
                    sortable: true
                },
            ],
            data: [],
        },
    }

    componentDidMount() {
        this.fetch({
            search: this.state.cdt.search,
            limit: this.state.cdt.rows,
            order: this.state.cdt.order,
            dir: this.state.cdt.dir,
            page: this.state.cdt.page
        });
    }


    fetch = async (params) => {
        this.setState({
            // showDateRange: false,
            cdt: {
                ...this.state.cdt,
                fetching: true,
            }
        });

        if (this.fetchReq) {
            this.fetchReq.abort();
        }
        this.fetchReq = new AbortController();

        try {
            const res = await GET('students-master-report/get-active-students', {
                signal: this.fetchReq.signal,
                params: {
                    search: params.search,
                    order: params.order,
                    dir: params.dir,
                    limit: params.limit,
                    offset: parseInt(params.page) * parseInt(params.limit),
                },
            });

            console.log("registeration", res)
            if (res.status === 200) {

                this.setState({
                    cdt: {
                        ...this.state.cdt,
                        fetching: false,
                        search: params.search,
                        rows: parseInt(params.limit),
                        offset: parseInt(params.page) * parseInt(params.limit),
                        order: params.order,
                        dir: params.dir,
                        page: params.page,
                        totalRecords: parseInt(res.data.total_count_filtered),
                        lastPage: Math.ceil(parseInt(res.data.total_count_filtered) / parseInt(params.limit)),
                        data: res.data.data,
                    },
                });
            }

        } catch (err) {
            console.log('err', err)
            this.setState({
                cdt: {
                    ...this.state.cdt,
                    fetching: false,
                }
            });

        }
    }

    exportExcel = async () => {

        const excelData = this.state.cdt.data.map((item, key) => {

            const row = {};
            this.state.cdt.columns
                .filter((column) => column.visibility)
                .map((column) => {

                    if (column.column == 'child_id') {
                        row[column.name] = item.center_id + item.acc_num
                    }
                    else if (column.column == 'account_num') {
                        row[column.name] = item.center_id + item.account_num
                    }
                    else {
                        row[column.name] = item[column.column];
                    }
                });
            return row;
        });

        const fileName = 'Active Students' + moment().format('DD-MM-YYYY');
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';
        const ws = XLSX.utils.json_to_sheet(excelData);

        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });

        FileSaver.saveAs(data, fileName + fileExtension);
    }

    showHideColumn = (column_index) => {
        const columns = this.state.cdt.columns;
        columns[column_index].visibility = !columns[column_index].visibility;
        this.setState({ ctd: { ...this.state.ctd, columns } })
    }

    renderCell = (column_data, column_id, column_index, dataObj) => {
        const { authData } = this.props;
        const { center_id } = authData.centerData;

        let tdData = column_data;

        if (column_id == 'child_id') {
            tdData = String(dataObj.center_id) + String(dataObj.acc_num)
        } else if (column_id == 'account_num') {
            tdData = String(dataObj.center_id) + String(dataObj.account_num)
        }

        return <td key={column_index}>{tdData}</td>;
    }

    render() {
        // let activeTab = this.props.location.state.activeTab

        return (
            <Container fluid>
                <div id='iedu-layout'>
                    <NavBar />
                    <div id='page-content'>
                        <Header lite={true} heading={t('Active Students')} backBtn={true} />
                        <Card
                            className='border-0'
                            style={{ borderRadius: '20px' }}>
                            <Card.Header style={{
                                backgroundColor: '#fff',
                                borderTopLeftRadius: '20px',
                                borderTopRightRadius: '20px',
                            }}>
                                <Row>
                                    <Col md={6} className='d-flex align-items-center'>
                                        <ButtonGroup size='sm' className='me-3 float-left'>
                                            {/* <Button><FontAwesomeIcon icon={faFilePdf} /> {t('PDF')}</Button> */}
                                            <Button onClick={() => this.exportExcel()}>
                                                <FontAwesomeIcon icon={faFileExcel} /> {t('Excel')}
                                            </Button>
                                            {/* <Button><FontAwesomeIcon icon={faPrint} /> {t('Print')}</Button> */}
                                            <DropdownButton
                                                autoClose={'outside'}
                                                size='sm'
                                                as={ButtonGroup}
                                                title={t('Column Visibility')}>
                                                {this.state.cdt.columns.map((column, column_index) => (
                                                    <Dropdown.Item key={column_index} className={column.visibility ? 'column-name-item' : 'column-name-item active'} onClick={() => this.showHideColumn(column_index)}>
                                                        <FontAwesomeIcon icon={column.visibility ? faEye : faEyeSlash} /> {column.name}
                                                    </Dropdown.Item>
                                                ))}
                                            </DropdownButton>
                                        </ButtonGroup>
                                        <div style={{ display: 'inline-block' }}>
                                            {t('Show')}
                                            <Form.Select
                                                value={this.state.cdt.rows}
                                                size="sm"
                                                onChange={(e) => this.fetch({
                                                    search: this.state.cdt.search,
                                                    limit: e.target.value,
                                                    order: this.state.cdt.order,
                                                    dir: this.state.cdt.dir,
                                                    page: this.state.cdt.page
                                                })}
                                                style={{ width: 70, marginInline: 5, display: 'inline-block' }}>
                                                {this.state.cdt.rowsOptions.map((row, row_index) => (<option key={row_index} >{row}</option>))}
                                            </Form.Select>
                                            {t('Records')}
                                        </div>
                                    </Col>
                                    <Col md={6} className='d-flex justify-content-end'>
                                        <div className='cdt-search'>
                                            <input type='text'
                                                ref={this.searchFieldRef}
                                                defaultValue={this.state.cdt.search}
                                                onChange={(e) => this.fetch({
                                                    search: e.target.value,
                                                    limit: this.state.cdt.rows,
                                                    order: this.state.cdt.order,
                                                    dir: this.state.cdt.dir,
                                                    page: 0
                                                })}
                                                placeholder={t('Search...')} />
                                            <button
                                                onClick={() => {
                                                    this.fetch({
                                                        search: '',
                                                        limit: this.state.cdt.rows,
                                                        order: this.state.cdt.order,
                                                        dir: this.state.cdt.dir,
                                                        page: 0
                                                    });
                                                    this.searchFieldRef.current.value = '';
                                                }}>
                                                <FontAwesomeIcon
                                                    icon={this.state.cdt.search == '' ? faSearch : faXmark}
                                                    color={brand_colors[this.props.defaultTheme.theme_id].color18}
                                                />
                                            </button>
                                        </div>
                                    </Col>
                                </Row>
                            </Card.Header>
                            <Card.Body style={{ paddingTop: 8 }}>
                                <Row>
                                    {this.state.cdt.fetching &&
                                        <Col md={12} className='py-5 d-flex flex-column align-items-center'>
                                            <Spinner as='span' animation='grow' size='sm' /> {t('Loading Data')}
                                        </Col>
                                    }
                                    {!this.state.cdt.fetching && this.state.cdt.data.length === 0 &&
                                        <Col md={12} className='py-5 d-flex flex-column align-items-center'>
                                            {t('No Data Found')}
                                        </Col>
                                    }
                                    {!this.state.cdt.fetching && this.state.cdt.data.length !== 0 &&
                                        <Col md={12} className='pt-2'>
                                            <div id="datatable-list" className='mb-3' style={{ width: 'initial' }}>
                                                <Table responsive>
                                                    {renderTableHead(this)}
                                                    {renderTableBody(this)}
                                                </Table>
                                            </div>
                                        </Col>
                                    }
                                </Row>
                                <Pagination
                                    pageCount={this.state.cdt.lastPage || 1}
                                    forcePage={this.state.cdt.page}
                                    callbackParams={{
                                        search: this.state.cdt.search,
                                        limit: this.state.cdt.rows,
                                        order: this.state.cdt.order,
                                        dir: this.state.cdt.dir,
                                    }}
                                    callback={this.fetch}
                                />
                            </Card.Body>
                        </Card>
                    </div>
                </div>
            </Container>
        );
    }
}

const mapStateToProps = (state) => ({
    authData: state.auth.authData,
    selectedClass: state.selectedClass.data,
    defaultLanguage: state.language.defaultLanguage,
    defaultTheme: state.theme.defaultTheme,
});

export default connect(mapStateToProps, null)(ActiveStudents);
