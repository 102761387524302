import React, { Component } from 'react';
import { Container, Card, Row, Col, ButtonGroup, Button, DropdownButton, Dropdown, Form, Modal, Spinner, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faCheckCircle, faSearch, faUser, faXmark, faLayerGroup, faHandsHoldingChild, faPeopleGroup, faListCheck, faPrint, faPencil, faTrash, faCheckDouble, faSortDown, faSortUp, faThumbsUp, faThumbsDown } from '@fortawesome/free-solid-svg-icons';
import { faEye, faEyeSlash, faFileExcel, faFilePdf } from '@fortawesome/free-regular-svg-icons';
import { t } from '../../helpers/translation_helper';
import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';
import NavBar from '../_partials/NavBar/_NavBar';
import Header from '../_partials/Header/_Header';
import { connect } from 'react-redux';
import moment from 'moment';
import { brand_colors } from '../../helpers/brand_colors_helper';
import { GET, POST } from '../../api';
import { toast } from 'react-toastify';
import { handleFormErrors } from '../../helpers/form_helpers';
import { renderTableBody, renderTableHead } from '../../helpers/table_helper';
let cancelToken;

class Discounts extends Component {

  searchFieldRef = React.createRef();

  defaultFormFields = {
    discount_id: 0,
    discount_name: '',
    discount_desc: '',
    discount_rate: ''
  }

  state = {
    ...this.defaultFormFields,
    isEditing: false,
    working: false,
    cdt: {
      fetching: false,
      search: '',
      rows: 5,
      offset: 0,
      order: 'discount.discount_id',
      dir: 'DESC',
      lastPage: 0,
      page: 0,
      totalRecords: 0,
      rowsOptions: [5, 10, 25, 50, 'All'],
      columns: [
        {
          id: 'discount.discount_id',
          column: 'discount_id',
          name: 'Actions',
          visibility: true
        },
        {
          id: 'discount.discount_name',
          column: 'discount_name',
          name: 'Name',
          visibility: true
        },
        {
          id: 'discount.discount_rate',
          column: 'discount_rate',
          name: 'Rate',
          visibility: true
        },
        {
          id: 'discount.discount_desc',
          column: 'discount_desc',
          name: 'Description',
          visibility: true
        },
      ],
      data: [],
    },
    showFormModal: false,
  }

  componentDidMount() {
    const {
      search,
      rows: limit,
      order,
      dir
    } = this.state.cdt;
    this.fetch(search, limit, order, dir, 0);
  }

  fetch = async (search, limit, order, dir, page) => {

    this.setState({
      cdt: {
        ...this.state.cdt,
        fetching: true,
      }
    });

    if (cancelToken) {
      cancelToken.abort();
    }
    cancelToken = new AbortController();

    try {
      const res = await GET('discount/get', {
        signal: cancelToken.signal,
        params: {
          search,
          order,
          dir,
          limit,
          offset: parseInt(page) * parseInt(limit)
        },
      });

      if (res.status === 200) {
        this.setState({
          cdt: {
            ...this.state.cdt,
            fetching: false,
            search,
            rows: parseInt(limit),
            offset: parseInt(page) * parseInt(limit),
            order,
            dir,
            page,
            totalRecords: parseInt(res.data.total_count_filtered),
            lastPage: Math.ceil(parseInt(res.data.total_count_filtered) / parseInt(limit)),
            data: res.data.data,
          }
        });
      }

    } catch (err) {
      console.log('err', err)
      this.setState({
        cdt: {
          ...this.state.cdt,
          fetching: false,
        }
      });

    }
  }

  showHideColumn = (column_index) => {
    const columns = this.state.cdt.columns;
    columns[column_index].visibility = !columns[column_index].visibility;
    this.setState({ ctd: { ...this.state.ctd, columns } })
  }

  handleOpenFormModal = () => {
    this.setState({
      showFormModal: true
    });
  }

  handleCloseFormModal = () => {
    this.setState({
      showFormModal: false,
      isEditing: false,
      ...this.defaultFormFields
    });
  }

  saveUpdate = async () => {

    const { authData } = this.props;
    const { center_id } = authData.centerData;

    this.setState({ working: true });

    const {
      discount_id,
      discount_name,
      discount_desc,
      discount_rate
    } = this.state;

    const fData = new FormData();
    fData.append('center_id', center_id);
    fData.append('discount_id', discount_id);
    fData.append('DiscountData', JSON.stringify({
      discount_name,
      discount_desc,
      discount_rate
    }));

    try {
      const res = await POST('discount/save', fData);
      if (res.data.type) {
        this.setState({ working: false });
        toast.success(t(res.data.message));
        this.fetch('', this.state.cdt.rows, this.state.cdt.order, this.state.cdt.dir, 0);
        this.handleCloseFormModal();
      } else {
        // toast.error(t(res.data.message));
        handleFormErrors(res.data.data)
        this.setState({ working: false });
      }
    } catch (err) {
      console.log('err', err)
      toast.error(t('Something went wrong!'));

      this.setState({ working: false });
    }

  }

  edit = (data) => {
    this.setState({
      ...this.state,
      ...data,
      isEditing: true,
    });

    this.handleOpenFormModal();
  }

  delete = async (discount_id) => {
    const { authData } = this.props;
    const { center_id } = authData.centerData;

    const fData = new FormData();
    fData.append('center_id', center_id);
    fData.append('discount_id', discount_id);

    try {
      const res = await POST('discount/delete', fData);
      if (res.data.type) {
        toast.success(t(res.data.message));
        this.fetch(this.state.cdt.search, this.state.cdt.rows, this.state.cdt.order, this.state.cdt.dir, this.state.cdt.page);
      } else {
        toast.error(t(res.data.message));
      }
    } catch (err) {
      console.log('err', err)
      toast.error(t('Something went wrong!'));

      this.setState({ working: false });
    }
  }

  exportExcel = async () => {

    const excelData = this.state.cdt.data.map((item) => {
      const row = {};
      this.state.cdt.columns
        .filter((column) => column.visibility)
        .map((column) => {
          if (column.name !== 'Actions') {
            row[column.name] = item[column.column];
          }
        });
      return row;
    });

    const fileName = 'Discounts Export ' + moment().format('DD-MM-YYYY');
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const ws = XLSX.utils.json_to_sheet(excelData);

    const cellStyle = {
      font: {
        bold: true,
      },
    }

    Array.from('ABC').map((letter) => {
      ws[letter + "1"].s = cellStyle;
    });

    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });

    FileSaver.saveAs(data, fileName + fileExtension);
  }

  renderCell = (column_data, column_id, column_index, dataObj) => {
    const { authData } = this.props;
    const { center_id } = authData.centerData;

    let tdData;

    // ----------------------------------------------------------
    if (column_id == 'discount_id') { // Action Buttons
      tdData = <>
        <Button
          onClick={() => this.edit(dataObj)}
          variant="warning" size='sm' className='me-1'>
          <FontAwesomeIcon icon={faPencil} />
        </Button>
        <Button
          onClick={() => this.delete(column_data)}
          variant="danger" size='sm' className='me-1'>
          <FontAwesomeIcon icon={faTrash} />
        </Button>
      </>;
      // -----------------------------------------------------------
    } else {

      tdData = column_data;
    }


    return <td key={column_index}>{tdData}</td>;
  }

  render() {
    const { cdt } = this.state
    return (
      <Container fluid>
        <div id='iedu-layout'>
          <NavBar />
          <div id='page-content'>
            <Header lite={true} heading={t('Discounts')} backBtn={true} />
            <div className='grey-section'>
              <Card
                className='border-0'
                style={{ borderRadius: '20px' }}>
                <Card.Body>
                  <Row>
                    <Col md={6}>
                      <ButtonGroup size='sm' className='me-3 float-left'>
                        {/* <Button><FontAwesomeIcon icon={faFilePdf} /> {t('PDF')}</Button> */}
                        <Button onClick={() => this.exportExcel()}>
                          <FontAwesomeIcon icon={faFileExcel} /> {t('Excel')}
                        </Button>
                        {/* <Button><FontAwesomeIcon icon={faPrint} /> {t('Print')}</Button> */}
                        <DropdownButton
                          autoClose={'outside'}
                          size='sm'
                          as={ButtonGroup}
                          title={t('Column Visibility')}>
                          {this.state.cdt.columns.map((column, column_index) => (
                            <Dropdown.Item key={column_index} className={column.visibility ? 'column-name-item' : 'column-name-item active'} onClick={() => this.showHideColumn(column_index)}>
                              <FontAwesomeIcon icon={column.visibility ? faEye : faEyeSlash} /> {column.name}
                            </Dropdown.Item>
                          ))}
                        </DropdownButton>
                      </ButtonGroup>
                      <div style={{ display: 'inline-block' }}>
                        {t('Show')}
                        <Form.Select
                          value={this.props.employeesListRows}
                          size="sm"
                          onChange={(e) => this.fetch(this.state.cdt.search, e.target.value, this.state.cdt.order, this.state.cdt.dir, this.state.cdt.page)}
                          style={{ width: 70, marginInline: 5, display: 'inline-block' }}>
                          {this.state.cdt.rowsOptions.map((row, row_index) => (<option key={row_index} >{row}</option>))}
                        </Form.Select>
                        {t('Records')}
                      </div>
                    </Col>
                    <Col md={6} className='d-flex justify-content-end'>
                      <div className='cdt-search'>
                        <input type='text'
                          ref={this.searchFieldRef}
                          defaultValue={this.state.cdt.search}
                          onChange={(e) => this.fetch(e.target.value, this.state.cdt.rows, this.state.cdt.order, this.state.cdt.dir, 0)}
                          placeholder={t('Search...')} />
                        <button
                          onClick={() => {
                            this.fetch('', this.state.cdt.rows, this.state.cdt.order, this.state.cdt.dir, 0);
                            this.searchFieldRef.current.value = '';
                          }}>
                          <FontAwesomeIcon
                            icon={this.state.cdt.search == '' ? faSearch : faXmark}
                            color={brand_colors[this.props.defaultTheme.theme_id].color18}
                          />
                        </button>
                      </div>
                      <button
                        onClick={this.handleOpenFormModal}
                        className='btn btn-success btn-sm ms-3'><FontAwesomeIcon icon={faPlus} color={brand_colors[this.props.defaultTheme.theme_id].color8} style={{ fontSize: 12 }} />{' '}{t('Add Discount')}</button>
                    </Col>
                    {this.state.cdt.fetching &&
                      <Col md={12} className='py-5 d-flex flex-column align-items-center'>
                        <Spinner as='span' animation='grow' size='sm' /> {t('Loading Data')}
                      </Col>
                    }
                    {!this.state.cdt.fetching && this.state.cdt.data.length === 0 &&
                      <Col md={12} className='py-5 d-flex flex-column align-items-center'>
                        {t('No Data Found')}
                      </Col>
                    }
                    {!this.state.cdt.fetching && this.state.cdt.data.length !== 0 &&
                      <Col md={12} className='pt-2'>
                        <div id="datatable-list" className='mb-3'>
                          <Table responsive>
                            {renderTableHead(this)}
                            {renderTableBody(this)}
                          </Table>
                        </div>
                        {this.state.cdt.rows < this.state.cdt.totalRecords &&
                          <ButtonGroup className='pagination'>
                            {Array.from({ length: this.state.cdt.lastPage }).map((_, index) => (
                              <Button
                                key={index}
                                variant="light"
                                onClick={() => this.fetch(this.state.cdt.search, this.state.cdt.rows, this.state.cdt.order, this.state.cdt.dir, index)}
                                className={this.state.cdt.page === index ? 'active' : ''}>{index + 1}</Button>
                            ))}
                          </ButtonGroup>
                        }
                      </Col>
                    }
                  </Row>
                </Card.Body>
              </Card>
            </div>
          </div>
        </div>
        <Modal show={this.state.showFormModal} onHide={this.handleCloseFormModal} backdrop="static"
        keyboard={false}>
          <Modal.Header closeButton>
            <Modal.Title>{this.state.isEditing ? t('Edit') : t('Add')} {t('Discount')}</Modal.Title>
          </Modal.Header>
          <Modal.Body className='custom-styled-form'>
            <Row>
              <Col md={8}>
                <Form.Group className="mb-3">
                  <Form.Label>{t('Name')}</Form.Label>
                  <Form.Control type="text"
                    defaultValue={this.state.discount_name}
                    onBlur={(e) => this.setState({ discount_name: e.target.value })} />
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group className="mb-3">
                  <Form.Label>{t('Rate')}</Form.Label>
                  <Form.Control type="text"
                    defaultValue={this.state.discount_rate}
                    onBlur={(e) => this.setState({ discount_rate: e.target.value })}
                    placeholder='0.00'
                  />
                </Form.Group>
              </Col>
              <Col md={12}>
                <Form.Group className="mb-3">
                  <Form.Label>{t('Description')}</Form.Label>
                  <Form.Control type="text"
                    defaultValue={this.state.discount_desc}
                    onBlur={(e) => this.setState({ discount_desc: e.target.value })}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleCloseFormModal}>
              {t('Close')}
            </Button>
            <Button disabled={this.state.working} variant='success' onClick={() => this.saveUpdate()}>
              {t('Save')} {this.state.working ? <Spinner as='span' animation='grow' size='sm' role='status' aria-hidden='true' /> : ''}
            </Button>
          </Modal.Footer>
        </Modal>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  authData: state.auth.authData,
  defaultLanguage: state.language.defaultLanguage,
  defaultTheme: state.theme.defaultTheme,
});

export default connect(mapStateToProps, null)(Discounts);
