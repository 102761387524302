import { createSlice } from '@reduxjs/toolkit';

export const addEditEmployeeModalSlice = createSlice({
  name: 'addEditEmployeeModal',
  initialState: {
    showModal: false,
    employee: {},
    isEditing: false,
  },
  reducers: {
    setAddEditEmployeeModal: (state, action) => {
      state.showModal = action.payload.showModal;
      state.employee = action.payload.employee;
      state.isEditing = action.payload.isEditing;
    },
  },
});

export const { setAddEditEmployeeModal } =
  addEditEmployeeModalSlice.actions;
export default addEditEmployeeModalSlice.reducer;
