import React, { Component } from 'react';
import { Container, Card, Row, Col, Offcanvas, Spinner, Button, Form, ButtonGroup, Badge, Breadcrumb } from 'react-bootstrap';
import { t } from '../../helpers/translation_helper';
import NavBar from '../_partials/NavBar/_NavBar';
import Header from '../_partials/Header/_Header';
import { connect } from 'react-redux';
import moment from 'moment';
import axios from 'axios';
import { toast } from 'react-toastify';
import { brand_colors } from '../../helpers/brand_colors_helper';
import { faAngleLeft, faAngleRight, faArrowRight, faAudioDescription, faChevronDown, faChevronUp, faClock, faPlus, faRefresh, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleLeft, faCheckCircle, faCircle, faFileAudio, faNoteSticky, faTrashCan } from '@fortawesome/free-regular-svg-icons';
import Select from 'react-select';

import pdfAttachment from '../../assets/images/pdf.png';
import audioAttachment from '../../assets/images/audio.png';
import imgDescription from '../../assets/images/description.png';
import { handleFormErrors } from '../../helpers/form_helpers';
import SweetAlert from 'react-bootstrap-sweetalert';
import { getUrlParam, renderChildName } from '../../helpers/general_helpers';
import { DateRangePicker } from 'react-date-range';
import { GET, POST } from '../../api';
import Flatpickr from 'react-flatpickr';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from 'ckeditor5-custom-build/build/ckeditor';

import { Chance } from 'chance';
import { sendCloudMessage } from '../../helpers/cloud_message';
import GeneralCard from '../../helpers/GeneralCard';
const chance = new Chance();

const apiURL = process.env.REACT_APP_API_URL;
const portalURL = process.env.REACT_APP_PORTAL_URL;

class AssignedActivity extends Component {
  constructor(props) {
    super(props);

    this.formSections_AcademicActivity = [
      {
        uid: '1',
        active: true,
        completed: false,
        name: 'Tag Student',
        desc: null,
        include_ut_type: ['Academic Activity'],
        data: [
          {
            uid: '1.1',
            active: false,
            completed: false,
            name: 'Class',
            desc: null,
            image: require('../../assets/images/lesson/lesson-focus.png'),
            include_ut_type: ['Academic Activity'],
          },
          {
            uid: '1.2',
            active: false,
            completed: false,
            name: 'Students',
            desc: null,
            image: require('../../assets/images/lesson/lesson-focus.png'),
            include_ut_type: ['Academic Activity'],
          },
        ]
      },
      {
        uid: '2',
        active: true,
        completed: false,
        name: 'Basic',
        desc: null,
        include_ut_type: ['Academic Activity'],
        data: [

          {
            uid: '2.1',
            active: false,
            completed: false,
            name: 'Title',
            desc: null,
            image: require('../../assets/images/lesson/lesson-focus.png'),
            include_ut_type: ['Academic Activity'],
          },
          {
            uid: '2.2',
            active: false,
            completed: false,
            name: 'Description',
            desc: null,
            image: require('../../assets/images/lesson/lesson-focus.png'),
            include_ut_type: ['Academic Activity'],
          },
        ],
      },
      {
        uid: '12',
        active: false,
        completed: false,
        name: 'Activity',
        desc: null,
        include_ut_type: ['Academic Activity'],
        data: [
          {
            uid: '12.1',
            active: false,
            completed: false,
            name: 'Activity',
            desc: null,
            image: require('../../assets/images/lesson/lesson-focus.png'),
            include_ut_type: ['Academic Activity'],
          },
          {
            uid: '12.2',
            active: false,
            completed: false,
            name: 'Start DateTime',
            desc: null,
            image: require('../../assets/images/lesson/lesson-focus.png'),
            include_ut_type: ['Academic Activity'],
          },
          {
            uid: '12.3',
            active: false,
            completed: false,
            name: 'End DateTime',
            desc: null,
            image: require('../../assets/images/lesson/lesson-focus.png'),
            include_ut_type: ['Academic Activity'],
          },
          {
            uid: '12.4',
            active: false,
            completed: false,
            name: 'Subjects',
            desc: null,
            image: require('../../assets/images/lesson/lesson-focus.png'),
            include_ut_type: ['Academic Activity'],
          },
        ]
      },
    ]
    this.AcademicActivityTimelineType = {
      uid: chance.unique(chance.integer, 1, { min: 99999999999, max: 999999999999 })[0],
      name: "Activity",
      ut_type: "Academic Activity",
      picture: require('../../assets/images/user-timeline/activity.png'),
      active: true,
      show: true,
      classmain_types: [0, 1, 2, 3, 4],
    }

    this.state = {
      utWorking: false,
      startDate: '',
      endDate: '',
      userTimelineData: [],
      offset: 0,
      limit: 10,
      finishedData: false,
      refreshingData: false,
      subjects: null,
      activityData: null,
      filter: {
        show: false,
        toggleFilter: (show) => this.setState({ filter: { ...this.state.filter, show: (this.state.filter.show == show) ? false : show } }),
        setData: (key, value) => {
          this.setState({ filter: { ...this.state.filter, [key]: value } })
        },
        selectedDateRange: { startDate: moment(new Date()).subtract(1, 'y')._d, endDate: new Date(), key: 'selection' },
        keyword: '',
        allStudents: props.children,
        all_lesson_focus: [],
        selectedLessonFocus: null
      },
      activityModal: {
        show: false,
        modalTitle: 'Assigned Activity',
        closeModal: () => this.setState({ activityModal: { ...this.state.activityModal, show: false } }),
        toggleModal: (data = null) => this.setState({
          activityModal: { ...this.state.activityModal, show: !this.state.activityModal.show, }, formSectionActive: {
            parent: '1',
            child: '1.1',
          }
        }, () => {
          if (this.state.activityModal.show) {
            this.activityData(data)
            let formSections = this.state.formSections
            formSections = formSections.map((v, i) => {
              if (v.uid == 2) {
                v.data = data.user_timeline_tagged.map((vv, ii) => {
                  return {
                    uid: '2_' + (ii + 1),
                    active: false,
                    completed: false,
                    name: vv.child_name,
                    image: portalURL + vv.center_id + '/children/' + vv.picture,
                    data: vv,
                  }
                })
              }
              return v
            })
            this.setState({
              formSections: formSections
            }, () => {
              this.getActivityResponses({ ut_id: data.ut_id, user_timeline_tagged: data.user_timeline_tagged })
            })
          }
        }),
        showModal: () => this.setState({ activityModal: { ...this.state.activityModal, show: true } }),
      },
      academicLessonAttributes: [],
      assessmentMenuData: {},
      deleteModal: { show: false, id: 0, action: () => { } },
      formSectionActive: {
        parent: '1',
        child: '1.1',
      },
      formSections: [
        {
          uid: '1',
          active: true,
          completed: true,
          name: 'Activity Details',
          desc: null,
          data: [
            {
              uid: '1.1',
              active: false,
              completed: true,
              name: 'Details',
              desc: null,
              image: require('../../assets/images/lesson/lesson-focus.png'),
            },
          ]
        },
        {
          uid: '2',
          active: true,
          completed: false,
          name: 'Tagged',
          desc: null,
          data: []
        }
      ],
      // 
      user_timeline: {
        ut_id: null,
        ut_title: '',
        ut_description: '',
        ut_url: '',
        ut_pintotop: 0,
        ut_publish_on: null,
        ut_publish_status: 1,
        ut_type: 'Assessment',
        utc_type: 'Teacher Parent',
        class_ids: {},
        ut_type_table_id: null,
        ut_type_table: null,
      },
      user_timeline_metadata: {
        academic_activity_ut_id: null,
        description2: '',
        start_datetime: '',
        end_datetime: '',
        acs_ids: [],
        term_ids: [],
        class_ids: [],
        assmt_id: null
      },
      user_timeline_tagged: [],
      user_timeline_media: [],//utm_name, utm_url
      assess_aspect: [],//assaspect_id, center_id, assesslog_id, lrnaspect_id, agegroup_id, assagegroup_value, assagegroup_value_num
      assess_curr: [],//asscurr_id, assesslog_id, curriculum_id, center_id, curr_aspect_id, curr_agegroup_id, asscurr_value, asscurr_value_num,
      academic_activity_resource_assessment: [],// aara_id, center_id, ut_id, aa_id, aar_id, assmt_id, assesslog_id, utt_id

      userTimelineModal: {
        show: false,
        toggleModal: (user_timeline = {}) => this.setState({
          userTimelineModal: { ...this.state.userTimelineModal, show: (user_timeline.name) ? true : false, user_timeline },
        }, () => {
          if (user_timeline.name) {
            this.resetUserTimelineForm()
          }
        }),
        user_timeline: {},
        saving: false
      },
      allAcademicActivities: [],
      allTerms: [],
      selectedTerm: {},
      user_timeline_tagged_all: true,
      allStudents: [],
      allStudentsRefreshing: false,
      formSections_AcademicActivity: [],
      processingEdit: false,
      renderActivityTabs: {
        tabs: [
          { name: 'Assessment' },
          { name: 'Reflections' },
          { name: 'Activity Questions' },
        ],
        activeTab: { name: 'Assessment' },
        toggleTab: (activeTab) => this.setState({ renderActivityTabs: { ...this.state.renderActivityTabs, activeTab } })
      }
    };
    this.filePicker = React.createRef();
    this.ut_title_Ref = React.createRef()
    this.ut_description_Ref = React.createRef()
    this.description2_Ref = React.createRef()
    this.ref_form_section_1_2 = React.createRef()
  }


  componentDidMount() {
    this.getTimeline()
    this.getLessonAttributes()
    this.getAssessmentMenu()


    let getUrlParamData = getUrlParam()
    if (getUrlParamData) {
      if (getUrlParamData.id) {
        this.editDataAll(getUrlParamData.id)
      }
    }

    this.getAcademicActivities()
    this.getTerms()
  }


  getAcademicActivities = () => {
    if (this.getAcademicActivitiesReq) {
      this.getAcademicActivitiesReq.abort();
    }
    this.getAcademicActivitiesReq = new AbortController();
    GET('academic-activities/get-academic-activities', {
      signal: this.getAcademicActivitiesReq.signal
    })
      .then((res) => {
        this.setState({ allAcademicActivities: res.data })
      })
      .catch((err) => {
        if (axios.isCancel(err)) {

        } else {
          toast.error(
            'Something went wrong while fetching chats and contacts3!'
          );
        }
      });
  }
  getTerms = () => {
    if (this.getTermsRequest) {
      this.getTermsRequest.abort();
    }
    this.getTermsRequest = new AbortController();
    GET('user-timeline/get-terms', {
      signal: this.getTermsRequest.signal,
    })
      .then((res) => {
        let selectedTerm = null
        res.data.find((v) => { if (v.term_active == 1) { selectedTerm = v } })
        this.setState({ allTerms: res.data, selectedTerm: (selectedTerm) ? { label: selectedTerm.term_name, value: selectedTerm.term_id, active: (selectedTerm.term_active == 1) ? true : false } : null })
      })
      .catch((err) => {
        if (axios.isCancel(err)) {

        } else {
          toast.error(
            'Something went wrong while fetching chats and contacts6!'
          );

        }
      });
  }
  getClassesChildren = (classes, actionType) => {
    if (classes.length <= 0) {
      if (this.ref_form_section_1_2.current) {
        this.ref_form_section_1_2.current.setValue([])
      }
      this.setState({ allStudents: [], allStudentsRefreshing: true })
    } else if (actionType == 'newTimeline' && this.state.selectedTerm.value == undefined) {
      if (this.ref_form_section_1_2.current) {
        this.ref_form_section_1_2.current.setValue([])
      }
      this.setState({ allStudents: [], allStudentsRefreshing: true })
    } else {
      // if (this.ref_form_section_1_2.current) {
      //   this.ref_form_section_1_2.current.setValue([])
      // }
      this.setState({ allStudents: [], allStudentsRefreshing: true })
    }


    const fData = new FormData()
    fData.append('center_id', this.props.authData.centerData.center_id)
    fData.append('class_ids', JSON.stringify(classes.map((v, i) => v.class_id)))
    if (actionType == 'newTimeline') {
      fData.append('term_id', this.state.selectedTerm.value)
    }
    if (this.getClassesChildrenRequest) {
      this.getClassesChildrenRequest.abort();
    }
    this.getClassesChildrenRequest = new AbortController();
    POST('children/get-classes-children', fData, {
      signal: this.getClassesChildrenRequest.signal,
    })
      .then((res) => {

        let resData = res.data
        if (Array.isArray(resData)) {
          this.setState({ allStudents: resData, allStudentsRefreshing: false })
        }
      })
      .catch((err) => {
        if (axios.isCancel(err)) {

        } else {
          toast.error(
            'Something went wrong while fetching chats and contacts1!'
          );

        }
      });
  }
  extractClassSubjects = () => {
    let class_ids = this.state.user_timeline.class_ids

    if (class_ids.length == 1) {
      let classData = this.props.authData.classes.find((v, i) => v.class.class_id == class_ids[0].class_id)
      if (classData != undefined) {
        return classData.academic_classmain_subject.map((v) => {
          return { value: v.acs_id, label: v.acs_name, picture: v.acs_image, center_id: v.center_id, as_color: v.as_color, as_name: v.as_name }
        })
      }
      return []
    }
    return []
  }



  resetUserTimelineForm = () => {
    this.setState({
      formSectionActive: {
        parent: '1',
        child: '1.1',
      },
      user_timeline: {
        ut_id: null,
        ut_title: '',
        ut_description: '',
        ut_url: '',
        ut_pintotop: 0,
        ut_publish_on: null,
        ut_publish_status: 1,
        ut_type: 'Assessment',
        utc_type: 'Teacher Parent',
        class_ids: [],
        ut_type_table_id: null,
        ut_type_table: null,
      },
      user_timeline_metadata: {
        academic_activity_ut_id: null,
        description2: '',
        start_datetime: '',
        end_datetime: '',
        acs_ids: [],
        term_ids: [],
        class_ids: [],
      },
      user_timeline_media: [],
      user_timeline_tagged: [],
      assess_aspect: [],
      assess_curr: [],
      formSections_AcademicActivity: this.formSections_AcademicActivity
    })
  }



  /**
    * Preview Upload File
    * @returns
    */
  readFile = (selectedFile) => {
    return new Promise(function (resolve, reject) {
      let reader = new FileReader();
      const allowedFiles = ['application/pdf', 'image/jpeg', 'image/jpg', 'image/png', 'audio/x-m4a', 'audio/mp3', 'audio/mp4'];
      const allowedFilesDisplay = ['PDF', 'Image'];
      const allowedSize = 5000000;

      if (selectedFile.size) {
        if (selectedFile.size >= allowedSize) {
          toast.error(t('Max file size allowed is ' + allowedSize / 1000000 + ' MB!'));
          resolve({ type: 'error' });
          return;
        } else if (!allowedFiles.includes(selectedFile.type)) {
          toast.error(t('Allowed file type are ' + allowedFilesDisplay.toString()));
          resolve({ type: 'error' });
          return;
        } else {
          if (selectedFile.type.includes('image')) {
            reader.readAsDataURL(selectedFile);
          } else if (selectedFile.type.includes('audio')) {
            reader.readAsArrayBuffer(selectedFile);
          } else {
            reader.readAsText(selectedFile);
          }
        }

        reader.onload = async (readerEvent) => {
          resolve({ type: 'success', data: selectedFile, url: readerEvent.target.result });
        };
        reader.onerror = (error) => {
          resolve({ type: 'error' });
        };
      } else {
        resolve({ type: 'error' });
      }
    });
  };
  addAttachment = (e) => {

    const selectedFiles = e.target.files;
    let allFiles = [];

    for (let i = 0; i < selectedFiles.length; i++) {
      allFiles.push(this.readFile(selectedFiles[i]));
    }

    Promise.all(allFiles).then((values) => {
      let attachments = e.target.multiple ? this.state[e.target.name] : null;
      let selectedFiles = values;
      selectedFiles.map((v, i) => {
        if (v.data.type) {
          if (e.target.multiple) {
            attachments.push({ url: v.url, data: v.data });
          } else {
            attachments = { url: v.url, data: v.data };
          }
        }
      });
      this.setState({ [e.target.name]: attachments });
    });
  };

  getTimeline = async (reset = false) => {
    if (this.state.finishedData) {
      return false
    }
    this.setState({
      refreshingData: true,
    });
    // if (this.state.offset === 0) {
    //   this.setState({ utWorking: true });
    // }

    if (this.getTimelineReq) this.getTimelineReq.abort();

    const { userTimelineData } = this.state;
    const { authData, selectedClass } = this.props;

    const { center_id, center_timezone } = authData.centerData;
    const { user_id, auth_key } = authData.loginData;

    const fData = new FormData();
    fData.append('user_id', user_id);
    fData.append('center_id', center_id);
    fData.append('center_timezone', center_timezone);
    this.props.authData.classes.map((v, i) => fData.append('class_id[]', v.class.class_id))
    // fData.append('child_id', child_id);
    fData.append('selectedTimelineTypes', JSON.stringify([{ ut_type: 'Academic Activity', active: true }]));
    fData.append('offset', this.state.offset);
    fData.append('limit', this.state.limit);
    fData.append('activeFilterDateFrom', (this.state.filter.selectedDateRange.startDate) ? moment(this.state.filter.selectedDateRange.startDate).format('YYYY-MM-DD') + ' 00:00:00' : null)
    fData.append('activeFilterDateTo', (this.state.filter.selectedDateRange.endDate) ? moment(this.state.filter.selectedDateRange.endDate).format('YYYY-MM-DD') + ' 23:59:59' : null)

    if (this.state.filter.selectedLessonFocus) {
      fData.append('ala_id', this.state.filter.selectedLessonFocus.value)
    }

    if (this.state.filter.keyword && this.state.filter.keyword != '') {
      fData.append('paginate_search', this.state.filter.keyword)
    }

    this.getTimelineReq = new AbortController();
    try {
      const res = await POST('user-timeline', fData, {
        signal: this.getTimelineReq.signal,
      });

      if (res) {
        let resData = res.data
        console.log('resData', resData);
        let data = [];
        if (reset) {
          data = res.data.data;
        } else {
          data = [...userTimelineData, ...resData.data];
        }

        this.setState({
          // utWorking: false,
          userTimelineData: data,
          offset: this.state.offset + this.state.limit,
          refreshingData: false,
          finishedData: (resData.data.length <= 0) ? true : false
        });
      }
    } catch (err) {
      console.log('err', err)
      if (axios.isCancel(err)) {

      } else {
        toast.error(
          t('Something went wrong while fetching news feed!')
        );

      }
    }
  };

  getAssessmentMenu = () => {
    if (typeof this.getAssessmentMenuRequest != typeof undefined) {
      this.getAssessmentMenuRequest.abort();
    }

    this.getAssessmentMenuRequest = new AbortController();

    if (typeof this.getAllCurriculumRequest != typeof undefined) {
      this.getAllCurriculumRequest.abort();
    }

    this.getAllCurriculumRequest = new AbortController();

    axios
      .all([
        GET('assesslog/get-assessment-menu', {
          signal: this.getAssessmentMenuRequest.signal,
        }),
        GET('assesslog/get-all-curriculum', {
          signal: this.getAllCurriculumRequest.signal,
        }),
      ])
      .then(
        axios.spread((assessmentMenuData, getAllCurriculumData) => {
          this.setState({ assessmentMenuData: { ...assessmentMenuData.data, allCurriculum: getAllCurriculumData.data } })
        }))
      .catch((err) => {
        if (axios.isCancel(err)) {

        } else {
          toast.error(
            'Something went wrong while fetching chats and contacts!'
          );

        }
      });
  }

  getLessonAttributes = async () => {
    const { authData } = this.props;
    const { center_id } = authData.centerData;
    const { auth_key } = authData.loginData;
    try {
      const res = await GET('academic-subject-lessons/get-lesson-attributes');

      if (res.status == 200) {
        this.setState({ academicLessonAttributes: res.data, filter: { ...this.state.filter, all_lesson_focus: res.data.filter((v, i) => (v.ala_type == 'lesson_focus') && v.ala_parent_id == 0) } });
      }

    } catch (err) {
      console.log('err', err)
      toast.error(
        t('Something went wrong while fetching Lesson Attributes!')
      );

    }
  };

  getActivityResponses = (data) => {
    const { authData } = this.props;
    const { center_id } = authData.centerData;
    const { auth_key } = authData.loginData;
    let fData = new FormData();
    fData.append('center_id', center_id)
    fData.append('ut_id', data.ut_id)
    fData.append('user_timeline_tagged', JSON.stringify(data.user_timeline_tagged))
    try {
      axios.post(
        apiURL + 'assesslog/get-academic-activity-responses',
        fData,
        {
          params: {
            auth_key,
            appname: process.env.REACT_APP_NAME,
          },
        }
      ).then((res) => {
        if (res.status == 200) {
          let formSections = this.state.formSections
          formSections = formSections.map((vv, ii) => {
            if (vv.uid == '2') {
              vv.data = vv.data.map((vvv, iii) => {
                let findData = res.data.find((v, i) => v.utt_id == vvv.data.utt_id)
                if (findData) {
                  vvv['completed'] = true
                  vvv['filledData'] = findData
                }
                return vvv
              })
            }
            return vv
          })
          this.setState({ formSections: formSections, utWorking: false })
        }
      })
    } catch (err) {
      console.log('err', err)
      toast.error(
        t('Something went wrong while fetching Lesson Attributes!')
      );

    }
  }

  // INCASE THE ACTIVITY IS OPENED FROM USERTIMELINE
  editDataAll = async (ut_id) => {
    const { authData } = this.props;
    const { auth_key } = authData.loginData;
    const { center_id } = authData.centerData;
    try {
      const res = await GET('academic-activities/edit-data-all', {
        params: {
          ut_id: ut_id,
        },
      });
      if (res.status == 200) {
        let resData = res.data
        if (resData.type) {
          let user_timeline_tagged = resData.data.user_timeline.user_timeline_tagged
          this.setState({
            activityModal: { ...this.state.activityModal, show: !this.state.activityModal.show, }, formSectionActive: {
              parent: '1',
              child: '1.1',
            },
            activityData: resData.data
          }, () => {
            if (this.state.activityModal.show) {
              let formSections = this.state.formSections
              formSections = formSections.map((v, i) => {
                if (v.uid == 2) {
                  v.data = user_timeline_tagged.map((vv, ii) => {
                    return {
                      uid: '2_' + (ii + 1),
                      active: false,
                      completed: false,
                      name: vv.child_name,
                      image: portalURL + vv.center_id + '/children/' + vv.picture,
                      data: vv,
                    }
                  })
                }
                return v
              })
              this.setState({
                formSections: formSections
              }, () => {
                this.getActivityResponses({ ut_id: ut_id, user_timeline_tagged: user_timeline_tagged })
              })
            }
          })


        } else {
          toast.error(t(resData.message));
        }
      }
    } catch (err) {
      console.log('err', err)
      toast.error(t('Something went wrong while fetching total coefficient!'));

    }
  };

  activityData = async (data) => {
    const { authData } = this.props;
    const { auth_key } = authData.loginData;
    const { center_id } = authData.centerData;
    try {
      const res = await GET('academic-activities/edit-data', {
        params: {
          aa_id: data.academic_activities.aa_id,
        },
      });
      if (res.status == 200) {
        this.setState({
          activityData: { ...res.data, user_timeline: data }
        });
      }
    } catch (err) {
      console.log('err', err)
      toast.error(t('Something went wrong while fetching total coefficient!'));

    }
  };

  saveUserTimeline = () => {
    if (this.state.utWorking) {
      return
    }
    this.setState({ utWorking: true });

    let thisChild = null
    this.state.formSections.map((v, i) => {
      if (v.uid == this.state.formSectionActive.parent) {
        thisChild = v.data.find((vv, ii) => vv.uid == this.state.formSectionActive.child)
      }
    })

    let user_timeline = JSON.parse(JSON.stringify(this.state.user_timeline))
    let user_timeline_metadata = JSON.parse(JSON.stringify(this.state.user_timeline_metadata))
    let academic_activity_resource_assessment = JSON.parse(JSON.stringify(this.state.academic_activity_resource_assessment))
    academic_activity_resource_assessment = academic_activity_resource_assessment.map((aara) => {
      if (aara.assmt_id) {
        return { ...aara, assmt_id: aara.assmt_id.assmt_id }
      }
    })

    let { user_timeline_media } = this.state
    user_timeline.class_ids = [thisChild.data.class_id]
    user_timeline_metadata.academic_activity_ut_id = this.state.activityData.user_timeline.ut_id
    user_timeline_metadata.assmt_id = (user_timeline_metadata.assmt_id) ? user_timeline_metadata.assmt_id.assmt_id : null

    let fData = new FormData()
    fData.append('center_id', this.props.authData.centerData.center_id)
    fData.append('class_id', thisChild.data.class_id)
    fData.append('user_timeline', JSON.stringify(user_timeline))
    fData.append('user_timeline_metadata', JSON.stringify(user_timeline_metadata))
    fData.append('academic_activity_resource_assessment', JSON.stringify(academic_activity_resource_assessment))

    fData.append('user_timeline_tagged', JSON.stringify([{
      child_id: thisChild.data.child_id, parent_id: thisChild.data.parent_id, child_name: thisChild.data.child_name, family_name: thisChild.family_name, family_name_2: thisChild.family_name_2,
    }]))
    fData.append('ut_id', user_timeline.ut_id)
    fData.append('ut_type', user_timeline.ut_type)
    fData.append('user_id', this.props.authData.loginData.user_id)
    fData.append('center_timezone', this.props.authData.centerData.center_timezone)
    fData.append('employee_id', this.props.authData.userData.employee_id)
    fData.append('employee_photo_approve', this.props.authData.userData.employee_photo_approve)
    // FORCED OVERSIDE SHARE
    fData.append('curriculumshare', (this.props.authData.userData) ? this.props.authData.userData.curriculumshare : 1)
    if (user_timeline_media.length > 0) {
      user_timeline_media.map((v, i) => {
        if (v.data) {
          fData.append('user_timeline_media[]', v.data)
        }
      })
    }
    fData.append('assess_aspect', JSON.stringify(this.state.assess_aspect))
    fData.append('assess_curr', JSON.stringify(this.state.assess_curr))

    // return
    if (this.saveUserTimelineReq != undefined) {
      this.saveUserTimelineReq.abort();
    }
    this.saveUserTimelineReq = new AbortController();

    let URL = 'assesslog/save-assesslog'

    axios.post(apiURL + URL, fData, {
      params: {
        auth_key: this.props.authData.loginData.auth_key,
      },
      signal: this.saveUserTimelineReq.signal,
    })
      .then((res) => {
        if (res.data.type) {
          toast.success(t(res.data.message));

          this.getActivityResponses({ ut_id: this.state.activityData.user_timeline.ut_id, user_timeline_tagged: this.state.activityData.user_timeline.user_timeline_tagged })

        } else {
          this.setState({ utWorking: false });
          if (res.data.data != null) {
            handleFormErrors(res.data.data);
          } else {
            toast.error(t(res.data.message));
          }
        }
      })
      .catch((err) => {
        this.setState({ utWorking: false });
        if (axios.isCancel(err)) {

        } else {
          toast.error(
            'Something went wrong while saving'
          );

        }
      });

  }

  renderFormAcademicActivity = () => {
    const { userTimelineModal } = this.state
    if (!userTimelineModal.show) {
      return null
    }
    const { user_timeline, user_timeline_metadata, user_timeline_media, user_timeline_tagged, ut_attachment_1, ut_attachment_2 } = this.state
    const dir = this.props.defaultLanguage.lang_orientation
    const { assessmentMenu, assessmentMenuData } = this.state
    let activeUserTimeline = userTimelineModal.user_timeline

    {/* NEW TIMELINE */ }
    return <Offcanvas show={userTimelineModal.show} style={{ height: '100%' }} placement='bottom' onHide={userTimelineModal.toggleModal}>
      <Offcanvas.Header id='iedu-header' style={{ marginTop: '1rem', marginRight: '1rem', marginLeft: '1rem', marginBottom: 0 }}>
        <div className='d-flex flex-row  align-items-center justify-content-center'>
          <span className='d-flex align-items-center justify-content-center btn-back-white' onClick={userTimelineModal.toggleModal}>
            <FontAwesomeIcon icon={(dir == 1) ? faAngleRight : faAngleLeft} color={brand_colors[this.props.defaultTheme.theme_id].color9} />
          </span>
          <Offcanvas.Title>
            <div className='d-flex flex-row align-items-center justify-content-center title-back-color8' >
              <div className='avatar'>
                <img src={userTimelineModal.user_timeline.picture} width="38" height="38" />
              </div>
              <span style={{ fontSize: 20, margin: '0 10px' }}>{userTimelineModal.user_timeline.name}</span>
            </div>
          </Offcanvas.Title>
        </div>
        <div className='custom-styled-form'>
          <div className='d-flex flex-row justify-content-between align-items-end'>
            {/* Publish Later */}
            <>
              <div style={{ margin: '0 20px', backgroundColor: brand_colors[this.props.defaultTheme.theme_id].color8, textAlign: 'center', padding: '5px 10px', borderRadius: 20 }}>
                <span style={{ fontSize: 13, color: brand_colors[this.props.defaultTheme.theme_id].color9, opacity: 0.7, fontWeight: 'bold' }}>
                  {t('Share Now')}
                </span>
                <Form.Check
                  type="switch"
                  value={1}
                  checked={(user_timeline.ut_publish_status)}
                  onChange={(event) => {
                    this.setState({ user_timeline: { ...this.state.user_timeline, ['ut_publish_on']: (event.target.checked) ? null : moment().add(1, 'days').toDate(), ut_publish_status: event.target.checked } })
                  }}
                />
              </div>
              <div style={{ margin: '0 10px' }}>
                <Form.Group>
                  <Form.Label>
                    <img src={imgDescription} />
                    {t('Publish Later')}
                  </Form.Label>
                  <Flatpickr
                    className='form-control'
                    data-enable-time
                    value={(user_timeline.ut_publish_on) ? moment(user_timeline.ut_publish_on).format('DD-MM-YYYY HH:mm:ss') : null}
                    onClose={([date]) => {

                      if (date) {
                        this.setState({ user_timeline: { ...this.state.user_timeline, ['ut_publish_on']: date, ut_publish_status: 0 } })
                      } else {
                        this.setState({ user_timeline: { ...this.state.user_timeline, ['ut_publish_on']: null, ut_publish_status: 1 } })
                      }
                    }}
                    options={{
                      dateFormat: 'd-m-Y H:i:S'
                    }}
                  />
                </Form.Group>
              </div>
            </>

            <Button disabled={this.state.userTimelineModal.saving} variant={user_timeline.ut_publish_status ? 'success' : 'warning'} onClick={() => setTimeout(() => {
              this.saveUserTimeline_AcademicActivityMulti()
            }, 300)}>
              {(user_timeline.ut_publish_status) ? t('Publish Now') : t('Publish Later')} {this.state.userTimelineModal.saving ? <Spinner as='span' animation='grow' size='sm' role='status' aria-hidden='true' /> : ''}
            </Button>
          </div>
        </div>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <div
          className={'d-flex flex-row grey-section'}
          style={{
            overflow: 'initial',
            backgroundImage: 'url(https://academic.educore.io/static/media/pegboard-bg.9b84e4936acef29bbcd9.png)',
            backgroundRepeat: 'repeat',
            backgroundPosition: '10px 12px',
            overflow: 'hidden',
          }}
        >
          <div style={{ backgroundColor: brand_colors[this.props.defaultTheme.theme_id].color8, marginLeft: 10, marginRight: 10, borderRadius: '20px', width: '250px', height: '100%', top: '5px', position: 'sticky' }}>
            <div style={{ paddingRight: '1.5rem', paddingBottom: '1.5rem', paddingTop: '1rem' }}>
              {this.state.formSections_AcademicActivity.map((v, i) => {

                return (
                  <div key={i}>
                    <div className='d-flex flex-row align-items-center' style={{ cursor: 'pointer', borderRadius: '20px', paddingLeft: '5px', paddingRight: '5px', zIndex: 1, position: 'relative' }} onClick={() => this.changeToFromSection(v.uid, v.uid + '.1')}>
                      <span style={{ fontSize: 18, fontWeight: '600', color: brand_colors[this.props.defaultTheme.theme_id].color9, opacity: 0.7 }}>{v.name}</span>
                    </div>

                    <div style={{ paddingLeft: '1rem', borderLeft: 'solid 1px color18', marginLeft: '1.5rem' }}>
                      {v.data.map((vv, ii) => {
                        return (
                          <div key={ii} className='d-flex flex-row align-items-center' style={{ marginBottom: 5, marginTop: 2, cursor: 'pointer' }} onClick={() => this.changeToFromSection(v.uid, vv.uid)}>
                            <FontAwesomeIcon icon={vv.completed ? faCheckCircle : faCircle} color={vv.completed ? brand_colors[this.props.defaultTheme.theme_id].color4 : brand_colors[this.props.defaultTheme.theme_id].color18} style={{ fontSize: 12, margin: '0 5px' }} />
                            <span style={{ fontSize: 14, color: vv.completed ? brand_colors[this.props.defaultTheme.theme_id].color4 : brand_colors[this.props.defaultTheme.theme_id].color18 }}>{vv.name}</span>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <Col className='custom-styled-form hide-scrollbar' style={{ backgroundColor: brand_colors[this.props.defaultTheme.theme_id].color8, marginLeft: 10, marginRight: 10, borderRadius: '20px', padding: '1rem', overflow: 'auto', height: '70vh' }}>
            {/* SECTION 1 */}
            {['Academic Activity'].includes(activeUserTimeline.ut_type) && (
              <div id='form_section_1'>
                <div className={'d-flex flex-row align-items-center justify-content-between section-heading'}>
                  <span className='heading'>{'Tag Students'}</span>
                </div>
                <Row>
                  <Col md="4">
                    {/* CLASS */}
                    <div id='form_section_1.1'>
                      <Form.Group className='mb-3'>
                        <Form.Label>{t('Class')}</Form.Label>
                        <Select
                          isMulti
                          closeMenuOnSelect={false}
                          options={this.props.authData.classes.map((v, i) => { return { label: v.class.class_theme, value: v.class.class_id, ...v.class } })}
                          onChange={(event) => {
                            this.setStateUserTimeline('class_ids', event)
                          }}
                          defaultValue={user_timeline.class_ids}
                          menuPortalTarget={document.body}
                          styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                          formatOptionLabel={option => (
                            <div className="d-flex flex-row">
                              {option.class_logo ? <img src={portalURL + option.center_id + '/classes/' + option.class_logo} style={{ width: 25, height: 25, objectFit: 'cover', marginRight: 5 }} /> : ''}
                              <div style={{ fontSize: 14 }}>
                                <div >{option.label}</div>
                                <div>
                                  <Badge bg="info">{option.agegroup_code}</Badge>
                                </div>
                              </div>
                            </div>
                          )}
                        />
                      </Form.Group>
                    </div>
                    {/* Term */}
                    <div>
                      <Form.Group className='mb-3'>
                        <Form.Label>{t('Term')}</Form.Label>
                        <Select
                          options={this.state.allTerms.map((v, i) => { return { label: v.term_name, value: v.term_id, active: (v.term_active == 1) ? true : false } })}
                          onChange={(event) =>
                            this.setState({ selectedTerm: event }, () => {
                              // this.getClassesChildren((this.state.user_timeline.class_ids.value) ? [this.state.user_timeline.class_ids] : [], 'newTimeline')
                            })
                          }
                          defaultValue={this.state.selectedTerm}
                          menuPortalTarget={document.body}
                          styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                          formatOptionLabel={option => (
                            <div>
                              <span style={{ fontSize: 20 }}>
                                {option.label}
                                {option.active &&
                                  <Badge bg='success'>Active</Badge>
                                }
                              </span>
                            </div>
                          )}
                        />
                      </Form.Group>
                    </div>
                  </Col>
                  <Col>
                    {/* STUDENTS */}
                    <div id='form_section_1.2'>
                      {!['Assessment'].includes(activeUserTimeline.ut_type) && (
                        <Form.Group >
                          <Form.Check type="switch" label={t('Select all Students in selected classes')} checked={this.state.user_timeline_tagged_all} onChange={() => {
                            if (this.setStateTimer) {
                              clearTimeout(this.setStateTimer)
                            }
                            this.setStateTimer = setTimeout(() => {
                              this.setState({ user_timeline_tagged: [], user_timeline_tagged_all: !this.state.user_timeline_tagged_all }, () => {
                                this.ref_form_section_1_2.current.setValue([])
                                this.checkCompletedFormSections()
                              })
                            }, 500);
                          }} />
                        </Form.Group>
                      )}
                      <Form.Group className='mb-3'>
                        <Form.Label>{t('Tag Students')} ( {(this.state.user_timeline_tagged_all) ? 'ALL' : user_timeline_tagged.length} )</Form.Label>
                        {/* <ButtonGroup size="sm" className='mb-1' style={{ left: 7, top: 8 }}>
                          <Button variant="primary" onClick={() => {
                            let user_timeline_tagged_new = this.state.allStudents.map((v, i) => { return { label: v.child_name, value: v.child_id, picture: v.picture, center_id: v.center_id, parent_id: v.parent_id, child_user_id: v.child_user_id, parent_user_id: v.parent_user_id, arabic_name: v.arabic_name, family_name: v.family_name, family_name_2: v.family_name_2 } })
                            this.ref_form_section_1_2.current.setValue(user_timeline_tagged_new)
                            this.setState({ user_timeline_tagged: user_timeline_tagged_new }, () => {
                              this.checkCompletedFormSections()
                            })
                          }}>{this.state.allStudentsRefreshing
                            ? <Spinner as='span' animation='grow' size='sm' role='status' aria-hidden='true' variant='primary' />
                            : 'Select All'}</Button>
                          <Button variant="warning" onClick={() => {
                            this.ref_form_section_1_2.current.setValue([])
                            this.setState({ user_timeline_tagged: [] }, () => this.checkCompletedFormSections())
                          }}>{this.state.allStudentsRefreshing
                            ? <Spinner as='span' animation='grow' size='sm' role='status' aria-hidden='true' variant='danger' />
                            : 'De-Select All'} </Button>
                        </ButtonGroup> */}
                        <Select
                          closeMenuOnSelect={false}
                          isMulti={true}
                          ref={this.ref_form_section_1_2}
                          options={this.state.allStudents.map((v, i) => { return { label: v.child_name, value: v.child_id, ...v } })}
                          onChange={(event) => {
                            if (this.setStateTimer) {
                              clearTimeout(this.setStateTimer)
                            }
                            this.setStateTimer = setTimeout(() => {
                              this.setState({ user_timeline_tagged: event }, () => {
                                this.checkCompletedFormSections()
                              })
                            }, 2000);
                          }}
                          defaultValue={user_timeline_tagged}
                          menuPortalTarget={document.body}
                          styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                          formatOptionLabel={option => (
                            <div>
                              {option.picture ? <img src={portalURL + option.center_id + '/children/' + option.picture} style={{ width: 25, height: 25, objectFit: 'cover', marginRight: 5, borderRadius: 10 }} /> : ''}
                              <span>{option.label}</span>
                              {user_timeline.class_ids.length > 0 && <Badge bg="info" className='ms-1'>{option.class_theme}</Badge>}
                            </div>
                          )}
                          autoload={false}
                          isLoading={this.state.allStudentsRefreshing}
                          onFocus={() => this.getClassesChildren(user_timeline.class_ids, 'newTimeline')}
                        />
                        {/* <Select
                          ref={this.ref_form_section_1_2}
                          isMulti={true}
                          closeMenuOnSelect={false}
                          options={this.state.allStudents.map((v, i) => { return { label: v.child_name, value: v.child_id, picture: v.picture, center_id: v.center_id, parent_id: v.parent_id, child_user_id: v.child_user_id, parent_user_id: v.parent_user_id, arabic_name: v.arabic_name, family_name: v.family_name, family_name_2: v.family_name_2 } })}
                          onChange={(event) => {

                            if (this.setStateTimer) {
                              clearTimeout(this.setStateTimer)
                            }
                            this.setStateTimer = setTimeout(() => {
                              this.setState({ user_timeline_tagged: event }, () => {
                                if (activeUserTimeline.ut_type == 'Assessment') {
                                  this.getLastOverallAssessmentAnswers()
                                  this.getLastAssessmentAnswers()
                                }
                                this.checkCompletedFormSections()
                              })
                            }, 2000);
                          }
                          }
                          defaultValue={user_timeline_tagged}
                          menuPortalTarget={document.body}
                          styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                          formatOptionLabel={option => (
                            <div>
                              {option.picture ? <img src={portalURL + option.center_id + '/children/' + option.picture} style={{ width: 30, height: 30, objectFit: 'cover', marginRight: 5, borderRadius: 10 }} /> : ''}
                              <span style={{ fontSize: 16 }}>{option.label}</span>
                            </div>
                          )}
                        /> */}
                      </Form.Group>
                    </div>
                  </Col>
                </Row>
              </div>
            )}
            {/* SECTION 12 */}
            {['Academic Activity'].includes(activeUserTimeline.ut_type) && (<>
              <div className={'d-flex flex-row align-items-center justify-content-between section-heading'} id='form_section_12'>
                <span className='heading'>{'Assign Activity'}</span>
                <Button variant={'primary'} size='sm' onClick={() => window.open(window.location.origin + "/activities")}>
                  <span style={{ marginRight: 10 }}><FontAwesomeIcon icon={faPlus} /></span>
                  <span>{t('New Activity')}</span>
                </Button>
              </div>
              <Row>
                {/* Activity */}
                <Col id='form_section_12.1'>
                  <Form.Group className='mb-3'>
                    <Form.Label>
                      <img src={imgDescription} />
                      {t('Activity')}
                    </Form.Label>
                    <ButtonGroup size="sm" className='mb-1' style={{ left: 7, top: 8 }}>
                      <Button variant="warning" onClick={() => this.getAcademicActivities()}>
                        <FontAwesomeIcon icon={faRefresh} />
                      </Button>
                    </ButtonGroup>
                    <Select
                      options={this.state.allAcademicActivities.map((v, i) => { return { label: v.aa_name, value: v.aa_id, center_id: v.center_id, picture: v.aa_image, aa_desc: v.aa_desc } })}
                      onChange={(event) => this.setState({ user_timeline: { ...user_timeline, ut_type_table_id: (event) ? event : null, ut_type_table: (event) ? 'academic_activities' : null, ut_title: event.label, ut_description: event.aa_desc } })}
                      value={user_timeline.ut_type_table_id}
                      menuPortalTarget={document.body}
                      styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                      formatOptionLabel={option => (
                        <div>
                          {option.picture ? <img src={portalURL + option.center_id + '/academic_activities/' + option.picture} style={{ width: 30, height: 30, objectFit: 'cover', marginRight: 5 }} /> : ''}
                          <span style={{ fontSize: 16 }}>{option.label}</span>
                        </div>
                      )}
                    />
                  </Form.Group>
                </Col>
                <Col id='form_section_12.2'>
                  {/* START DATETIME */}
                  <Form.Group className='mb-3'>
                    <Form.Label>
                      <img src={imgDescription} />
                      {t('Start DateTime')}
                    </Form.Label>
                    <Flatpickr
                      className='form-control'
                      data-enable-time
                      defaultValue={(user_timeline_metadata.start_datetime) ? moment(user_timeline_metadata.start_datetime).format('DD-MM-YYYY HH:mm:ss') : null}
                      // value={(user_timeline_metadata.start_datetime) ? moment(user_timeline_metadata.start_datetime).format('DD-MM-YYYY HH:mm:ss') : null}
                      onClose={([date]) => this.setStateUserTimelineMetadata('start_datetime', (date) ? date : null)}
                      options={{
                        dateFormat: 'd-m-Y H:i:S',
                        time_24hr: true
                      }}
                    />
                  </Form.Group>
                </Col>
                <Col id='form_section_12.3'>
                  {/* END DATETIME */}
                  <Form.Group className='mb-3'>
                    <Form.Label>
                      <img src={imgDescription} />
                      {t('End DateTime')}
                    </Form.Label>
                    <Flatpickr
                      className='form-control'
                      data-enable-time
                      defaultValue={(user_timeline_metadata.end_datetime) ? moment(user_timeline_metadata.end_datetime).format('DD-MM-YYYY HH:mm:ss') : null}
                      // value={(user_timeline_metadata.end_datetime) ? moment(user_timeline_metadata.end_datetime).format('DD-MM-YYYY HH:mm:ss') : null}
                      onClose={([date]) => this.setStateUserTimelineMetadata('end_datetime', (date) ? date : null)}
                      options={{
                        dateFormat: 'd-m-Y H:i:S',
                        time_24hr: true
                      }}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col id='form_section_12.4'>
                  <Form.Group className='mb-3'>
                    <Form.Label>{t('Subjects')}</Form.Label>
                    <Select
                      isMulti
                      closeMenuOnSelect={false}
                      options={this.extractClassSubjects()}
                      onChange={(event) => this.setStateUserTimelineMetadata('acs_ids', event)}
                      value={user_timeline_metadata.acs_ids}
                      menuPortalTarget={document.body}
                      styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                      formatOptionLabel={option => (
                        <div>
                          {option.picture ? <img src={portalURL + option.center_id + '/academic_classmain_subject/' + option.picture} style={{ width: 30, height: 30, objectFit: 'cover', marginRight: 5 }} /> : ''}
                          <span style={{ fontSize: 16, color: (option.as_color) ? option.as_color : 'initial' }}>{option.label} ({option.as_name})</span>
                        </div>
                      )}
                    />
                  </Form.Group>
                </Col>
              </Row>

            </>)}
            {/* SECTION 2 */}
            {['Academic Activity'].includes(activeUserTimeline.ut_type) && (
              <div id='form_section_2'>
                <div className={'d-flex flex-row align-items-center justify-content-between section-heading'}>
                  <span className='heading'>{'Form'}</span>
                </div>
                <Row>
                  <Col>
                    {/* ut_title */}
                    {['Notification', 'Event', 'Newsletter', 'Upload', 'Media', 'Daily Report', 'Note', 'Assignment', 'Online Class', 'Assessment', 'Video', 'Exam', 'Agenda', 'Resources', 'Incident Report', 'Exam Retake', 'Poll', 'Survey', 'Online Homework', 'Academic Activity'].includes(activeUserTimeline.ut_type) && (
                      <div id='form_section_2.1'>
                        <Form.Group className='mb-3'>
                          <Form.Label>
                            <img src={imgDescription} />
                            {t('Title')}
                          </Form.Label>
                          <Form.Control type='text' defaultValue={user_timeline.ut_title} onBlur={(event) => this.setStateUserTimeline('ut_title', event.target.value)} />
                        </Form.Group>
                      </div>
                    )}

                    {/* ut_description */}
                    {['Notification', 'Event', 'Upload', 'Media', 'Daily Report', 'Note', 'Assignment', 'Online Class', 'Video', 'Exam', 'Agenda', 'Resources', 'Incident Report', 'Exam Retake', 'Poll', 'Survey', 'Online Homework', 'Academic Activity'].includes(activeUserTimeline.ut_type) && (
                      <div md={12} id='form_section_2.2'>
                        <Form.Group className='mb-3'>
                          <Form.Label>
                            <img src={imgDescription} />
                            {t('Description')}
                          </Form.Label>
                          < Form.Control as="textarea" rows={3} defaultValue={user_timeline.ut_description} onBlur={(event) => this.setStateUserTimeline('ut_description', event.target.value)} />
                        </Form.Group>
                      </div>
                    )}
                    {['Newsletter'].includes(activeUserTimeline.ut_type) && (
                      <div md={12} id='form_section_2.2'>
                        <Form.Group className='mb-3'>
                          <Form.Label>
                            <img src={imgDescription} />
                            {t('Description')}
                          </Form.Label>
                          <CKEditor style={{ height: 140 }} editor={Editor} data={user_timeline.ut_description} onBlur={(event, editor) => this.setStateUserTimeline('ut_description', editor.getData())} />
                        </Form.Group>
                      </div>
                    )}
                    {/* ut_url */}
                    {['Notification', 'Event', 'Note', 'Resources', 'Assignment', 'Online Class', 'Newsletter'].includes(activeUserTimeline.ut_type) &&
                      <div id='form_section_2.5'>
                        <Form.Group className='mb-3'>
                          <Form.Label>
                            <img src={imgDescription} />
                            {t('URL')}
                          </Form.Label>
                          <Form.Control type='text' defaultValue={user_timeline.ut_url} onBlur={(event) => this.setStateUserTimeline('ut_url', event.target.value)} />
                        </Form.Group>
                      </div>
                    }
                  </Col>
                  <Col md='4'>
                    {/* ut_attachment_1 */}
                    {['Notification', 'Event', 'Newsletter', 'Upload', 'Daily Report', 'Note', 'Assignment', 'Online Class', 'Exam', 'Resources', 'Incident Report', 'Exam Retake', 'Poll', 'Survey', 'Online Homework'].includes(activeUserTimeline.ut_type) &&
                      <div style={{ flexDirection: 'column' }} className='pic-prev d-flex justify-content-center mb-3' id='form_section_2.3'>
                        <div style={{ height: 175, padding: 10, overflow: 'hidden', borderStyle: 'dashed', borderColor: this.state.dragAreaColor.ut_attachment_1, borderWidth: 2, borderRadius: 20, position: 'relative' }} className='d-flex justify-content-center align-items-center'
                          onDragEnter={(e) => this.handleDragFileArea(e, 'ut_attachment_1')} onDragLeave={(e) => this.handleDragFileArea(e, 'ut_attachment_1')} onDragOver={(e) => this.handleDragFileArea(e, 'ut_attachment_1')} onDrop={(e) => {

                            e.preventDefault();
                            e.stopPropagation();
                            if (e.dataTransfer.files && e.dataTransfer.files[0]) {
                              this.addAttachment({ target: { files: e.dataTransfer.files, multiple: false, name: 'ut_attachment_1' } })
                            }
                          }}>
                          {ut_attachment_1 && (!ut_attachment_1.data.type.includes('pdf')) && (
                            <img src={ut_attachment_1.url} alt={t('Preview')} style={{ height: '100%' }} />
                          )}
                          {ut_attachment_1 && (ut_attachment_1.data.type.includes('pdf')) && (
                            <img src={require('../../assets/images/pdf.png')} alt={t('PDF')} style={{ height: '100%' }} />
                          )}
                          {ut_attachment_1 == null &&
                            <button onClick={() => this.ut_attachment_1_ref.current.click()} style={{ background: 'none', border: 0, flex: 1 }}>
                              {/* <FontAwesomeIcon icon={faPlus} style={{ fontSize: 30, color: color18 }} /> */}
                              <span style={{ fontSize: 20, fontWeight: 'bold', color: brand_colors[this.props.defaultTheme.theme_id].color18 }}>{t('Custom Icon')}</span>
                            </button>
                          }

                          {ut_attachment_1 && (
                            <FontAwesomeIcon
                              icon={faTrashCan}
                              style={{ fontSize: 14, color: brand_colors[this.props.defaultTheme.theme_id].color11, position: 'absolute', bottom: 6, right: 6, cursor: 'pointer', backgroundColor: brand_colors[this.props.defaultTheme.theme_id].color8, borderRadius: 100, padding: 3 }}
                              onClick={() => this.deleteUserTimelineAttachment('ut_attachment_1')}
                            />
                          )}
                        </div>
                        <Form.Control className='d-none' ref={this.ut_attachment_1_ref} type='file' onChange={this.addAttachment} name='ut_attachment_1' />
                      </div>
                    }
                    {/* ut_attachment_2 */}
                    {['Notification', 'Event', 'Upload', 'Daily Report', 'Note', 'Online Class', 'Exam', 'Resources', 'Incident Report', 'Exam Retake', 'Online Homework', 'Newsletter'].includes(activeUserTimeline.ut_type) &&
                      <div style={{ flexDirection: 'column' }} className='pic-prev d-flex justify-content-center' id='form_section_2.4'>
                        <div style={{ height: 175, padding: 10, overflow: 'hidden', borderStyle: 'dashed', borderColor: this.state.dragAreaColor.ut_attachment_2, borderWidth: 2, borderRadius: 20, position: 'relative' }} className='d-flex justify-content-center align-items-center' onDragEnter={(e) => this.handleDragFileArea(e, 'ut_attachment_2')} onDragLeave={(e) => this.handleDragFileArea(e, 'ut_attachment_2')} onDragOver={(e) => this.handleDragFileArea(e, 'ut_attachment_2')} onDrop={(e) => {

                          e.preventDefault();
                          e.stopPropagation();
                          if (e.dataTransfer.files && e.dataTransfer.files[0]) {
                            this.addAttachment({ target: { files: e.dataTransfer.files, multiple: false, name: 'ut_attachment_2' } })
                          }
                        }}>
                          {ut_attachment_2 && (!ut_attachment_2.data.type.includes('pdf')) && (
                            <img src={ut_attachment_2.url} alt={t('Preview')} style={{ height: '100%' }} />
                          )}
                          {ut_attachment_2 && (ut_attachment_2.data.type.includes('pdf')) && (
                            <img src={require('../../assets/images/pdf.png')} alt={t('PDF')} style={{ height: '100%' }} />
                          )}
                          {ut_attachment_2 == null &&
                            (
                              <button onClick={() => this.ut_attachment_2_ref.current.click()} style={{ background: 'none', border: 0, flex: 1 }}>
                                {/* <FontAwesomeIcon icon={faPlus} style={{ fontSize: 30, color: color18 }} /> */}
                                <span style={{ fontSize: 20, fontWeight: 'bold', color: brand_colors[this.props.defaultTheme.theme_id].color18 }}>{t('Attachment')}</span>
                              </button>
                            )
                          }
                          {ut_attachment_2 && (
                            <FontAwesomeIcon
                              icon={faTrashCan}
                              style={{ fontSize: 14, color: brand_colors[this.props.defaultTheme.theme_id].color11, position: 'absolute', bottom: 6, right: 6, cursor: 'pointer', backgroundColor: brand_colors[this.props.defaultTheme.theme_id].color8, borderRadius: 100, padding: 3 }}
                              onClick={() => this.deleteUserTimelineAttachment('ut_attachment_2')}
                            />
                          )}
                        </div>
                        <Form.Control className='d-none' ref={this.ut_attachment_2_ref} type='file' onChange={this.addAttachment} name='ut_attachment_2' />
                      </div>
                    }
                  </Col>
                </Row>
              </div>
            )}
            {/* EXTRA PADDING FROM BOTTOM */}
            <div style={{ height: 400 }}></div>
          </Col>
        </div>
      </Offcanvas.Body >
    </Offcanvas >
  }

  deleteUserTimeline_AcademicActivity = (ut_id) => {
    this.setState({
      deleteModal: {
        ...this.state.deleteModal, show: true, action: () => {
          if (this.deleteUserTimeline_AcademicActivityReq) {
            this.deleteUserTimeline_AcademicActivityReq.abort();
          }

          this.deleteUserTimeline_AcademicActivityReq = new AbortController();
          GET('academic-activities/delete-user-timeline-academic-activity', {
            params: {
              ut_id: ut_id,
            },
            signal: this.deleteUserTimeline_AcademicActivityReq.signal,
          })
            .then((res) => {
              if (res) {
                let resData = res.data
                if (resData.type) {
                  toast[resData.messageType](
                    resData.message
                  );
                  this.setState(
                    {
                      offset: 0,
                      finishedData: false,
                      refreshingData: false,
                    },
                    () => this.getTimeline(true)
                  );
                } else {
                  if (resData.data) {
                    handleFormErrors(resData.data)
                  } else {
                    toast[resData.messageType](
                      resData.message
                    );
                  }
                }
                this.setState({
                  deleteModal: {
                    ...this.state.deleteModal, show: false
                  }
                })
              }
            })
        }
      }
    })

  }
  editUserTimeline_AcademicActivity = (ut_id) => {

    this.setState({ processingEdit: true })

    if (this.editUserTimeline_AcademicActivityReq) {
      this.editUserTimeline_AcademicActivityReq.abort();
    }

    this.editUserTimeline_AcademicActivityReq = new AbortController();
    GET('user-timeline/edit-user-timeline', {
      params: {
        ut_id: ut_id,
      },
      signal: this.editUserTimeline_AcademicActivityReq.signal,
    })
      .then((res) => {

        if (res.data) {

          let user_timeline = res.data.user_timeline
          let user_timeline_metadata = (res.data.user_timeline_metadata) ? res.data.user_timeline_metadata : {}
          let user_timeline_tagged = res.data.user_timeline_tagged
          let class_ids = JSON.parse(user_timeline.class_ids)
          let class_id = class_ids[0]

          if (user_timeline.ut_type == 'Academic Activity') {
            let selectedActivity = this.state.allAcademicActivities.map((v, i) => {
              if (v.aa_id == user_timeline.ut_type_table_id && user_timeline.ut_type_table == 'academic_activities') {
                return { label: v.aa_name, value: v.aa_id, center_id: v.center_id, picture: v.aa_image, aa_desc: v.aa_desc }
              }
            })
            user_timeline.ut_type_table_id = selectedActivity
          }

          let subjects = []
          let classData = this.props.authData.classes.find((v, i) => v.class.class_id == class_id)
          if (classData != undefined) {
            subjects = classData.academic_classmain_subject.map((v) => {
              return { value: v.acs_id, label: v.acs_name, picture: v.acs_image, center_id: v.center_id, as_color: v.as_color, as_name: v.as_name }
            })
          }
          let acs_ids = (user_timeline_metadata.acs_ids) ? JSON.parse(user_timeline_metadata.acs_ids) : []
          if (acs_ids.length > 0) {
            user_timeline_metadata.acs_ids = subjects.filter((v, i) => acs_ids.includes(parseInt(v.value)))
          } else {
            user_timeline_metadata.acs_ids = []
          }

          user_timeline.class_ids = [classData.class]

          user_timeline_metadata.start_datetime = (user_timeline_metadata && user_timeline_metadata.start_datetime) ? moment(user_timeline_metadata.start_datetime, "YYYY-MM-DD HH:mm:ss") : null
          user_timeline_metadata.end_datetime = (user_timeline_metadata && user_timeline_metadata.end_datetime) ? moment(user_timeline_metadata.end_datetime, "YYYY-MM-DD HH:mm:ss") : null
          user_timeline.ut_publish_status = (user_timeline.ut_publish_status == 1) ? true : false

          // POPULATE FROM
          this.setState({
            processingEdit: false,
            userTimelineModal: {
              ...this.state.userTimelineModal,
              show: true,
              user_timeline: this.AcademicActivityTimelineType
            },
            formSectionActive: {
              parent: '1',
              child: '1.1',
            },
            formSections_AcademicActivity: this.formSections_AcademicActivity,
            user_timeline: {
              ut_id: null,
              ut_title: '',
              ut_description: '',
              ut_url: '',
              ut_pintotop: 0,
              ut_publish_on: null,
              ut_publish_status: 1,
              ut_type: null,
              utc_type: 'Teacher Parent',
              class_ids: [],
              ut_type_table_id: null,
              ut_type_table: null,
              ...user_timeline,
              // class_ids: class_ids[0],
            },
            user_timeline_tagged_all: true,
            user_timeline_tagged: user_timeline_tagged.map((v, i) => { return { label: v.child_name, value: v.child_id, ...v } }),
            user_timeline_metadata: {
              academic_activity_ut_id: null,
              description2: '',
              start_datetime: '',
              end_datetime: '',
              acs_ids: [],
              term_ids: [],
              class_ids: [],
              ...user_timeline_metadata
            },
          }, () => this.checkCompletedFormSections())
        }
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
        } else {
          console.log('err', err);
          toast.error(
            'Something went wrong while fetching!'
          );
        }
        this.setState({ processingEdit: false })
      });
  }


  saveUserTimeline_AcademicActivityMulti = () => {

    let userTimelineModal = this.state.userTimelineModal
    let { ut_attachment_1, ut_attachment_2, user_timeline_media } = this.state
    this.setState({
      userTimelineModal: {
        ...this.state.userTimelineModal,
        saving: true
      }
    })
    if (this.state.userTimelineModal.saving) {
      return
    }

    let user_timeline_metadata = JSON.parse(JSON.stringify(this.state.user_timeline_metadata))
    let user_timeline_tagged = this.state.user_timeline_tagged
    let user_timeline = JSON.parse(JSON.stringify(this.state.user_timeline))
    let class_ids = user_timeline.class_ids
    user_timeline.ut_type = userTimelineModal.user_timeline.ut_type
    user_timeline.ut_publish_status = (user_timeline.ut_publish_status) ? 1 : 0
    user_timeline.ut_publish_on = (user_timeline.ut_publish_on) ? moment(user_timeline.ut_publish_on).format('YYYY-MM-DD HH:mm:ss') : null
    user_timeline_metadata.term_ids = user_timeline_metadata.term_ids.map((v, i) => v.value)
    user_timeline_metadata.class_ids = user_timeline_metadata.class_ids.map((v, i) => parseInt(v.class_id))
    user_timeline_metadata.acs_ids = user_timeline_metadata.acs_ids.map((v, i) => parseInt(v.value))
    user_timeline_metadata.start_datetime = (user_timeline_metadata.start_datetime) ? moment(user_timeline_metadata.start_datetime).format('YYYY-MM-DD HH:mm:ss') : null
    user_timeline_metadata.end_datetime = (user_timeline_metadata.end_datetime) ? moment(user_timeline_metadata.end_datetime).format('YYYY-MM-DD HH:mm:ss') : null
    user_timeline.ut_type_table_id = (user_timeline.ut_type_table_id) ? user_timeline.ut_type_table_id.value : null

    let allRequests = []
    class_ids.map((v) => {
      user_timeline.class_ids = [v.class_id]
      allRequests.push(this.saveUserTimeline_AcademicActivity_Promise(user_timeline, user_timeline_metadata, user_timeline_tagged, userTimelineModal))
    })

    Promise.all(allRequests).then((values) => {
      console.log('values', values);
      let anyErrors = false

      values.map((resData) => {
        if (resData.type) {
          if (user_timeline.ut_publish_on == null) {
            let ut_type = user_timeline.ut_type
            let ut_id = user_timeline.ut_id
            // MEDIA(RESTRICTED ACCESS) & OTHER TYPES
            resData.user_timeline_tagged.map((v, i) => {
              let body = user_timeline.ut_title
              let data = { 'ut_id': resData.data.ut_id, 'actionType': 'user_timeline' }
              if (v.child_user_id) {
                let title = (ut_id) ? 'Updated: ' + ut_type : 'New: ' + ut_type
                sendCloudMessage(v.child_user_id, title, body, data)
              }
              if (v.parent_user_id) {
                let title = (ut_id) ? 'Updated: ' + ut_type + ' for ' + renderChildName(v.child_name, v.arabic_name, v.family_name, v.family_name_2) : 'New: ' + ut_type + ' for ' + renderChildName(v.child_name, v.arabic_name, v.family_name, v.family_name_2)
                sendCloudMessage(v.parent_user_id, title, body, data)
              }
            })
          }
        } else {
          anyErrors = true
          if (resData.data != null) {
            handleFormErrors(resData.data);
          } else {
            toast.error(t(resData.message));
          }
        }
      })

      if (!anyErrors) {
        // RESET FROM
        this.setState({
          userTimelineModal: {
            ...this.state.userTimelineModal,
            show: false,
            user_timeline: {},
            saving: false
          },
          formSectionActive: {
            parent: '1',
            child: '1.1',
          },
          formSections_AcademicActivity: this.formSections_AcademicActivity,
          user_timeline: {
            ut_id: null,
            ut_title: '',
            ut_description: '',
            ut_url: '',
            ut_pintotop: 0,
            ut_publish_on: null,
            ut_publish_status: 1,
            ut_type: null,
            utc_type: 'Teacher Parent',
            class_ids: [],
            ut_type_table_id: null,
            ut_type_table: null,
          },
          user_timeline_tagged: [],
          user_timeline_tagged_all: true,
          user_timeline_metadata: {
            academic_activity_ut_id: null,
            description2: '',
            start_datetime: '',
            end_datetime: '',
            acs_ids: [],
            term_ids: [],
            class_ids: [],
          },
        }, () => this.resetFilter())

        toast.success(t('Saved Successfully'));
      } else {
        toast.success(t('Something went wrong'));
      }
    }).catch(function (error) {
      console.log('error', error);
      toast.success(t('Something went wrong'));
    })
  }

  saveUserTimeline_AcademicActivity_Promise = (user_timeline, user_timeline_metadata, user_timeline_tagged, userTimelineModal) => {

    return new Promise((resolve, reject) => {


      console.log('user_timeline', user_timeline);
      let fData = new FormData()
      fData.append('center_id', this.props.authData.centerData.center_id)
      fData.append('class_id', 'undefined')
      fData.append('user_timeline', JSON.stringify(user_timeline))
      fData.append('user_timeline_metadata', JSON.stringify(user_timeline_metadata))
      fData.append('user_timeline_tagged_all', this.state.user_timeline_tagged_all)
      fData.append('term_id', this.state.selectedTerm.value)
      fData.append('user_timeline_tagged', JSON.stringify(user_timeline_tagged))
      fData.append('ut_id', user_timeline.ut_id)
      fData.append('ut_type', userTimelineModal.user_timeline.ut_type)
      fData.append('user_id', this.props.authData.loginData.user_id)
      fData.append('center_timezone', this.props.authData.centerData.center_timezone)
      fData.append('employee_id', this.props.authData.userData.employee_id)
      fData.append('employee_photo_approve', this.props.authData.userData.employee_photo_approve)

      // return
      // if (this.saveUserTimelineReq) {
      //   this.saveUserTimelineReq.abort();
      // }
      this.saveUserTimelineReq = new AbortController();

      let URL = 'user-timeline/save-user-timeline'
      POST(URL, fData, {
        signal: this.saveUserTimelineReq.signal,
      })
        .then((res) => {
          if (res) {
            resolve(res.data)
          }
        })
    })
  }

  // saveUserTimeline_AcademicActivity = async () => {
  //   let user_timeline = JSON.parse(JSON.stringify(this.state.user_timeline))
  //   let user_timeline_metadata = JSON.parse(JSON.stringify(this.state.user_timeline_metadata))
  //   let user_timeline_tagged = this.state.user_timeline_tagged
  //   let userTimelineModal = this.state.userTimelineModal

  //   let { ut_attachment_1, ut_attachment_2, user_timeline_media } = this.state

  //   user_timeline.ut_type = userTimelineModal.user_timeline.ut_type

  //   if (['Academic Activity'].includes(user_timeline.ut_type) && user_timeline.class_ids.length > 1) {
  //     toast.error(t('Only one class should be selected.'));
  //     return
  //   }

  //   this.setState({
  //     userTimelineModal: {
  //       ...this.state.userTimelineModal,
  //       saving: true
  //     }
  //   })
  //   if (this.state.userTimelineModal.saving) {
  //     return
  //   }

  //   user_timeline.ut_publish_status = (user_timeline.ut_publish_status) ? 1 : 0
  //   user_timeline.ut_publish_on = (user_timeline.ut_publish_on) ? moment(user_timeline.ut_publish_on).format('YYYY-MM-DD HH:mm:ss') : null
  //   user_timeline.class_ids = user_timeline.class_ids.map((v, i) => parseInt(v.class_id))

  //   user_timeline_metadata.term_ids = user_timeline_metadata.term_ids.map((v, i) => v.value)
  //   user_timeline_metadata.class_ids = user_timeline_metadata.class_ids.map((v, i) => parseInt(v.class_id))
  //   user_timeline_metadata.acs_ids = user_timeline_metadata.acs_ids.map((v, i) => parseInt(v.value))
  //   user_timeline_metadata.start_datetime = (user_timeline_metadata.start_datetime) ? moment(user_timeline_metadata.start_datetime).format('YYYY-MM-DD HH:mm:ss') : null
  //   user_timeline_metadata.end_datetime = (user_timeline_metadata.end_datetime) ? moment(user_timeline_metadata.end_datetime).format('YYYY-MM-DD HH:mm:ss') : null
  //   user_timeline.ut_type_table_id = (user_timeline.ut_type_table_id) ? user_timeline.ut_type_table_id.value : null
  //   console.log('user_timeline', user_timeline);
  //   let fData = new FormData()
  //   fData.append('center_id', this.props.authData.centerData.center_id)
  //   fData.append('class_id', 'undefined')
  //   fData.append('user_timeline', JSON.stringify(user_timeline))
  //   fData.append('user_timeline_metadata', JSON.stringify(user_timeline_metadata))
  //   fData.append('user_timeline_tagged_all', this.state.user_timeline_tagged_all)
  //   fData.append('term_id', this.state.selectedTerm.value)
  //   fData.append('user_timeline_tagged', JSON.stringify(user_timeline_tagged))
  //   fData.append('ut_id', user_timeline.ut_id)
  //   fData.append('ut_type', userTimelineModal.user_timeline.ut_type)
  //   fData.append('user_id', this.props.authData.loginData.user_id)
  //   fData.append('center_timezone', this.props.authData.centerData.center_timezone)
  //   fData.append('employee_id', this.props.authData.userData.employee_id)
  //   fData.append('employee_photo_approve', this.props.authData.userData.employee_photo_approve)

  //   // return
  //   if (this.saveUserTimelineReq) {
  //     this.saveUserTimelineReq.abort();
  //   }
  //   this.saveUserTimelineReq = new AbortController();

  //   let URL = 'user-timeline/save-user-timeline'
  //   POST(URL, fData, {
  //     signal: this.saveUserTimelineReq.signal,
  //   })
  //     .then((res) => {

  //       if (res.data.type) {

  //         // SEND NOTIFICATIONS
  //         if (user_timeline.ut_publish_on == null && userTimelineModal.user_timeline.ut_type != 'Policy') {
  //           let ut_type = user_timeline.ut_type
  //           let ut_id = user_timeline.ut_id


  //           // MEDIA(RESTRICTED ACCESS) & OTHER TYPES
  //           if (ut_type != 'Media' || (ut_type == 'Media' && this.props.authData.userData.employee_photo_approve == 1)) {
  //             res.data.user_timeline_tagged.map((v, i) => {
  //               let body = user_timeline.ut_title
  //               let data = { 'ut_id': res.data.data.ut_id, 'actionType': 'user_timeline' }
  //               if (v.child_user_id) {
  //                 let title = (ut_id) ? 'Updated: ' + ut_type : 'New: ' + ut_type
  //                 sendCloudMessage(v.child_user_id, title, body, data)
  //               }
  //               if (v.parent_user_id) {
  //                 let title = (ut_id) ? 'Updated: ' + ut_type + ' for ' + renderChildName(v.child_name, v.arabic_name, v.family_name, v.family_name_2) : 'New: ' + ut_type + ' for ' + renderChildName(v.child_name, v.arabic_name, v.family_name, v.family_name_2)
  //                 sendCloudMessage(v.parent_user_id, title, body, data)
  //               }
  //             })
  //           }
  //         }

  //         // RESET FROM
  //         this.setState({
  //           userTimelineModal: {
  //             ...this.state.userTimelineModal,
  //             show: false,
  //             user_timeline: {},
  //             saving: false
  //           },
  //           formSectionActive: {
  //             parent: '1',
  //             child: '1.1',
  //           },
  //           formSections_AcademicActivity: this.formSections_AcademicActivity,
  //           user_timeline: {
  //             ut_id: null,
  //             ut_title: '',
  //             ut_description: '',
  //             ut_url: '',
  //             ut_pintotop: 0,
  //             ut_publish_on: null,
  //             ut_publish_status: 1,
  //             ut_type: null,
  //             utc_type: 'Teacher Parent',
  //             class_ids: [],
  //             ut_type_table_id: null,
  //             ut_type_table: null,
  //           },
  //           user_timeline_tagged: [],
  //           user_timeline_tagged_all: true,
  //           user_timeline_metadata: {
  //             academic_activity_ut_id: null,
  //             description2: '',
  //             start_datetime: '',
  //             end_datetime: '',
  //             acs_ids: [],
  //             term_ids: [],
  //             class_ids: [],
  //           },
  //         }, () => this.resetFilter())

  //         toast.success(t(res.data.message));

  //       } else {
  //         this.setState({
  //           userTimelineModal: {
  //             ...this.state.userTimelineModal,
  //             saving: false
  //           }
  //         })
  //         if (res.data.data != null) {
  //           handleFormErrors(res.data.data);
  //         } else {
  //           toast.error(t(res.data.message));
  //         }
  //       }
  //     })
  // }

  changeToFromSection = (parent, child) => {
    this.setState(
      {
        formSectionActive: { parent: parent, child: child },
      },
      () => {
        if (parent == 2) {
          let thisChild = this.state.formSections[1].data.find((v, i) => v.uid == child)
          console.log('changeToFromSection.thisChild', thisChild);

          setTimeout(() => {
            this.ut_title_Ref.current.value = (thisChild.filledData) ? thisChild.filledData.ut_title : ''
            this.ut_description_Ref.current.value = (thisChild.filledData) ? thisChild.filledData.ut_description : ''
            this.description2_Ref.current.value = (thisChild.filledData) ? thisChild.filledData.description2 : ''
          }, 300);

          if (thisChild.filledData) {

            // LEARNER PROFILE ASSESSMENTS
            let academic_activity_resource_assessment = thisChild.filledData.academic_activity_resource_assessment.map((aara) => {
              let assessmentFind = this.state.assessmentMenuData.assessment.find((vv, ii) => vv.assmt_id == aara.assmt_id)
              if (assessmentFind) {
                return { ...aara, assmt_id: { ...assessmentFind, label: assessmentFind.assmt_code, value: assessmentFind.assmt_id } }
              }
              return aara
            })

            //  ASSESSMENTS FOR ASSESSLOG
            let assmt_id = ''
            if (thisChild.filledData.assmt_id) {
              let assessmentFind = this.state.assessmentMenuData.assessment.find((vv, ii) => vv.assmt_id == thisChild.filledData.assmt_id)
              if (assessmentFind) {
                assmt_id = { ...assessmentFind, label: assessmentFind.assmt_code, value: assessmentFind.assmt_id }
              }
            }

            this.setState({
              user_timeline: {
                ut_id: thisChild.filledData.ut_id,
                ut_title: thisChild.filledData.ut_title,
                ut_description: thisChild.filledData.ut_description,
                ut_url: thisChild.filledData.ut_url,
                ut_pintotop: thisChild.filledData.ut_pintotop,
                ut_publish_on: thisChild.filledData.ut_publish_on,
                ut_publish_status: parseInt(thisChild.filledData.ut_publish_status),
                ut_type: 'Assessment',
                utc_type: 'Teacher Parent',
                class_ids: thisChild.filledData.class_ids,
                ut_type_table_id: thisChild.filledData.ut_type_table_id,
                ut_type_table: thisChild.filledData.ut_type_table,
              },
              user_timeline_metadata: {
                academic_activity_ut_id: thisChild.filledData.academic_activity_ut_id,
                description2: thisChild.filledData.description2,
                assmt_id: assmt_id
              },
              user_timeline_media: thisChild.filledData.user_timeline_media.map((v) => {
                return { data: { type: "image" }, url: portalURL + v.center_id + '/user_timeline_media/' + v.utm_name, utm_id: v.utm_id }
              }),
              assess_aspect: thisChild.filledData.assess_aspect,
              assess_curr: thisChild.filledData.assess_curr,
              academic_activity_resource_assessment: academic_activity_resource_assessment
            })
          } else {
            this.setState({
              user_timeline: {
                ut_id: null,
                ut_title: '',
                ut_description: '',
                ut_url: '',
                ut_pintotop: 0,
                ut_publish_on: moment().add(10, 'years').toDate(),
                ut_publish_status: 0,
                ut_type: 'Assessment',
                utc_type: 'Teacher Parent',
                class_ids: {},
                ut_type_table_id: null,
                ut_type_table: null,
              },
              user_timeline_metadata: {
                academic_activity_ut_id: null,
                description2: '',
                assmt_id: ''
              },
              user_timeline_media: [],
              assess_aspect: [],
              assess_curr: [],
              academic_activity_resource_assessment: []

            })
          }
        }
        setTimeout(() => {
          const element = document.getElementById('form_section_' + child);
          if (element) {
            // 👇 Will scroll smoothly to the top of the next section
            element.scrollIntoView({ behavior: 'smooth' });
          }
        }, 300);
      }
    );
  };

  selectAssessment = (assmt_id, curriculum_id, lrnaspect_id, agegroup_id, learnarea_id, progass_id) => {
    let assess_aspect = this.state.assess_aspect

    // INSERT assess_aspect
    let assess_aspectFindIndex = assess_aspect.findIndex((v, i) => (v.lrnaspect_id == lrnaspect_id && v.agegroup_id == agegroup_id))
    if (assess_aspectFindIndex == -1) {
      assess_aspect.push({
        assaspect_id: 0,
        center_id: this.props.authData.centerData.center_id,
        assesslog_id: 0,
        lrnaspect_id: lrnaspect_id,
        agegroup_id: agegroup_id,
        assagegroup_value: 0,
        assagegroup_value_num: 0,
      })
    } else {
      // assess_aspect[assess_aspectFindIndex].assagegroup_value = assmt_id
    }
    // INSERT assess_curr
    let assess_curr = this.state.assess_curr
    let assess_currFindIndex = assess_curr.findIndex((v, i) => (v.curr_aspect_id == lrnaspect_id && v.curr_agegroup_id == agegroup_id && v.curriculum_id == curriculum_id))

    if (assess_currFindIndex == -1) {
      assess_curr.push({
        asscurr_id: 0,
        assesslog_id: 0,
        curriculum_id: curriculum_id,
        center_id: this.props.authData.centerData.center_id,
        curr_aspect_id: lrnaspect_id,
        curr_agegroup_id: agegroup_id,
        asscurr_value: assmt_id,
        asscurr_value_num: 0,
      })
    } else {
      assess_curr[assess_currFindIndex].asscurr_value = assmt_id
    }
    this.setState({ assess_aspect: assess_aspect, assess_curr: assess_curr })
  }

  selectResourceAssessment = (data) => {
    // data = aa_id, aar_id, assmt_id

    let academic_activity_resource_assessment = this.state.academic_activity_resource_assessment
    let findi_aara = academic_activity_resource_assessment.findIndex((v, i) => v.aar_id == data.aar_id)
    if (findi_aara !== -1) {
      academic_activity_resource_assessment[findi_aara].assmt_id = data.assmt_id
    } else {
      academic_activity_resource_assessment.push({
        aara_id: 0,
        aa_id: data.aa_id,
        aar_id: data.aar_id,
        assmt_id: data.assmt_id,
      })
    }
    this.setState({ academic_activity_resource_assessment })
  }

  renderAssessmentList = () => {

    const { defaultTheme } = this.props;

    let render_assess_curr = this.state.activityData.academic_activities.curriculums
    let render_assess_aspect = []
    render_assess_curr.map((v, i) => {
      let findAssessAspect = render_assess_aspect.find((vv, ii) => vv.lrnaspect_id == v.lrnaspect_id && vv.agegroup_id == v.agegroup_id)
      if (findAssessAspect == undefined) {
        render_assess_aspect.push({
          lrnaspect_id: v.lrnaspect_id,
          agegroup_id: v.agegroup_id,
        })
      }
    })

    let assessmentMenuData = this.state.assessmentMenuData

    return <div>
      {render_assess_aspect.map((v, i) => {
        let activeLrnaspectData = assessmentMenuData.lrnaspect.find((vv, ii) => vv.lrnaspect_id == v.lrnaspect_id)
        let activeAgegroupData = assessmentMenuData.agegroup.find((vv, ii) => vv.agegroup_id == v.agegroup_id)
        let activelLearnareaData = assessmentMenuData.learnarea.find((vv, ii) => vv.learnarea_id == activeLrnaspectData.learnarea_id)
        let activeProgassData = assessmentMenuData.progass.find((vv, ii) => vv.progass_id == activelLearnareaData.progass_id)
        let assessmentFind = assessmentMenuData.assessment.find((vv, ii) => vv.assmt_id == v.assagegroup_value)

        return <div key={i}>
          <div style={{ margin: '5px 0 5px 0', backgroundColor: brand_colors[defaultTheme.theme_id].color19, padding: '5px 10px 5px 10px' }}>

            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end', justifyContent: 'space-between', }}>
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start', }}>
                <div>
                  <span style={{ fontSize: 14, fontWeight: 'bold', color: brand_colors[defaultTheme.theme_id].color1 }}>{activeProgassData?.progass_code}</span>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <div style={{ margin: '0 10px' }}>
                    <FontAwesomeIcon icon={faArrowRight} style={{ fontSize: 12, color: brand_colors[defaultTheme.theme_id].color4 }} />
                  </div>
                  <span style={{ fontSize: 14, fontWeight: 'bold', color: activeAgegroupData.agegroup_color }}>{activeAgegroupData.agegroup_code}</span>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <div style={{ margin: '0 10px' }}>
                    <FontAwesomeIcon icon={faArrowRight} style={{ fontSize: 12, color: brand_colors[defaultTheme.theme_id].color4 }} />
                  </div>
                  <span style={{ fontSize: 14, fontWeight: 'bold', color: activelLearnareaData.learnarea_color }}>{activelLearnareaData.learnarea_code}</span>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <div style={{ margin: '0 10px' }}>
                    <FontAwesomeIcon icon={faArrowRight} style={{ fontSize: 12, color: brand_colors[defaultTheme.theme_id].color4 }} />
                  </div>
                  <span style={{ fontSize: 14, fontWeight: 'bold', color: brand_colors[defaultTheme.theme_id].color1 }}>{activeLrnaspectData.lrnaspect_code}</span>
                </div>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end' }}>
                {(assessmentFind) ? <span style={{ fontSize: 14, color: (assessmentFind.assmt_color) ? assessmentFind.assmt_color : brand_colors[defaultTheme.theme_id].color1, fontWeight: 'bold' }}>{assessmentFind.assmt_code}</span> : null}
              </div>
            </div>
          </div>
          <div>
            {render_assess_curr.map((vv, ii) => {
              if (vv.lrnaspect_id == activeLrnaspectData.lrnaspect_id && vv.agegroup_id
                == activeAgegroupData.agegroup_id) {

                let find_assess_curr = this.state.assess_curr.find((vvv, iii) => vvv.curriculum_id == vv.curriculum_id)
                let assessmentFind2 = null
                if (find_assess_curr) {
                  assessmentFind2 = assessmentMenuData.assessment.find((vvv, ii) => vvv.assmt_id == find_assess_curr.asscurr_value)
                }


                return <div key={ii} style={{ paddingVertical: 5, position: 'relative', padding: '5px 10px 5px 10px', borderBottom: '1px solid ' + brand_colors[defaultTheme.theme_id].color18 }}>

                  <div style={{ alignItems: 'center', justifyContent: 'space-between', display: 'flex', flexDirection: 'row' }}>
                    <Col md="4" style={{}}>
                      {/* <span style={{ fontSize: 14, color: (assessmentFind2.assmt_color) ? assessmentFind2.assmt_color : color1, fontWeight: 'bold' }}>{assessmentFind2.assmt_code}</span> */}
                      <Form.Group>
                        <Select
                          options={assessmentMenuData.assessment.map((v, i) => { return { label: v.assmt_code, value: v.assmt_id, assmt_color: v.assmt_color } })}
                          onChange={(event) => {
                            this.selectAssessment(event.value, vv.curriculum_id, activeLrnaspectData.lrnaspect_id, activeAgegroupData.agegroup_id, activelLearnareaData.learnarea_id, activeProgassData.progass_id)
                          }}
                          value={assessmentFind2 ? { label: assessmentFind2.assmt_code, value: assessmentFind2.assmt_id, assmt_color: assessmentFind2.assmt_color } : ''}
                          menuPortalTarget={document.body}
                          styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999, }), control: (base) => ({ ...base }) }}
                          formatOptionLabel={option => (
                            <div>
                              <span style={{ color: option.assmt_color, fontSize: 14 }}>{option.label}</span>
                            </div>
                          )}
                        />
                      </Form.Group>
                    </Col>
                    <Col style={{ textAlign: 'right' }}>
                      <span style={{ fontSize: 14, color: brand_colors[defaultTheme.theme_id].color1, fontWeight: 'bold', opacity: 0.8 }}>#{vv.curriculum_ref_num}</span>
                    </Col>
                  </div>
                  <div style={{}}>
                    <span style={{ fontSize: 14, color: brand_colors[defaultTheme.theme_id].color9, opacity: 0.8 }}>{vv.curriculum_code}</span>
                  </div>
                </div>
              }
            })}
          </div >
        </div >
      })}
    </div >
  }

  setStateUserTimeline = (field, value) => {
    if (this.setStateTimer) {
      clearTimeout(this.setStateTimer)
    }
    this.setStateTimer = setTimeout(() => {
      this.setState({ user_timeline: { ...this.state.user_timeline, [field]: value } }, () => {
        this.checkCompletedFormSections()
      });
    }, 100);
  };

  // ONLY FOR ASSIGN ACTIVITY userTimelineModal
  checkCompletedFormSections = () => {
    let { user_timeline, user_timeline_metadata, user_timeline_tagged, user_timeline_tagged_all, userTimelineModal } = this.state;
    console.log('userTimelineModal', userTimelineModal);
    if (!userTimelineModal.show) {
      return false
    }

    if (this.checkCompletedFormSectionsTimer) {
      clearTimeout(this.checkCompletedFormSectionsTimer)
    }
    this.checkCompletedFormSectionsTimer = setTimeout(() => {
      let formSections_AcademicActivity = this.state.formSections_AcademicActivity;
      formSections_AcademicActivity = formSections_AcademicActivity.map((v, i) => {
        let data = v.data;
        let sectionComplete = true;
        data = data.map((vv, ii) => {
          if (vv.uid == '1.1') {
            let subSectionComplete = true;
            if (user_timeline.class_ids.length > 0) {
              subSectionComplete = true;
            } else {
              subSectionComplete = false;
              sectionComplete = false;
            }
            return { ...vv, completed: subSectionComplete };
          } else if (vv.uid == '1.2') {
            let subSectionComplete = true;
            if (user_timeline_tagged.length > 0 || user_timeline_tagged_all) {
              subSectionComplete = true;
            } else {
              subSectionComplete = false;
              sectionComplete = false;
            }
            return { ...vv, completed: subSectionComplete };
          } else if (vv.uid == '2.1') {
            let subSectionComplete = true;
            if (user_timeline.ut_title) {
              subSectionComplete = true;
            } else {
              subSectionComplete = false;
              sectionComplete = false;
            }
            return { ...vv, completed: subSectionComplete };
          } else if (vv.uid == '2.2' || vv.uid == '7.1') {
            let subSectionComplete = true;
            if (user_timeline.ut_description) {
              subSectionComplete = true;
            } else {
              subSectionComplete = false;
              sectionComplete = false;
            }
            return { ...vv, completed: subSectionComplete };
          } else if (vv.uid == '5.2' || vv.uid == '9.1' || vv.uid == '8.2' || vv.uid == '12.2' || vv.uid == '10.1' || vv.uid == '11.1') {
            let subSectionComplete = true;
            if (user_timeline_metadata.start_datetime) {
              subSectionComplete = true;
            } else {
              subSectionComplete = false;
              sectionComplete = false;
            }
            return { ...vv, completed: subSectionComplete };
          } else if (vv.uid == '5.3' || vv.uid == '6.1' || vv.uid == '12.3' || vv.uid == '10.2' || vv.uid == '11.2') {
            let subSectionComplete = true;
            if (user_timeline_metadata.end_datetime) {
              subSectionComplete = true;
            } else {
              subSectionComplete = false;
              sectionComplete = false;
            }
            return { ...vv, completed: subSectionComplete };
          }
          else if (vv.uid == '8.1' || vv.uid == '6.3' || vv.uid == '12.4') {
            let subSectionComplete = true;
            if (user_timeline_metadata.acs_ids.length > 0) {
              subSectionComplete = true;
            } else {
              subSectionComplete = false;
              sectionComplete = false;
            }
            return { ...vv, completed: subSectionComplete };
          }
          else if (vv.uid == '12.1') {
            let subSectionComplete = true;
            if (user_timeline.ut_type_table_id) {
              subSectionComplete = true;
            } else {
              subSectionComplete = false;
              sectionComplete = false;
            }
            return { ...vv, completed: subSectionComplete };
          }
          else {
            sectionComplete = false;
            return { ...vv };
          }
        });
        return { ...v, data, completed: sectionComplete };
      });
      this.setState({ formSections_AcademicActivity: formSections_AcademicActivity });
    }, 1000);

  };

  setStateUserTimelineMetadata = (field, value) => {
    this.setState({ user_timeline_metadata: { ...this.state.user_timeline_metadata, [field]: value } }, () => {
      this.checkCompletedFormSections()
    });
  };

  deleteAssignedActivityMedia = (v, i) => {
    let user_timeline_media = this.state.user_timeline_media
    if (v.utm_id) {
      this.setState({
        deleteModal: {
          ...this.state.deleteModal, show: true, action: () => {
            // DELETE FILE CALL 
            try {
              GET('user-timeline/delete-user-timeline-media', {
                params: {
                  key: v.utm_id,
                  field: 'utm_name',
                },
              }).then((res) => {
                if (res) {
                  user_timeline_media.splice(i, 1);
                  this.setState({
                    user_timeline_media, deleteModal: {
                      ...this.state.deleteModal, show: false
                    }
                  });
                } else {
                  toast.error(t('Something went wrong while deleting!'));
                }
              })
            } catch (err) {
              console.log('err', err)
              toast.error(t('Something went wrong while deleting!'));

            }
          }
        }
      })
    } else {
      user_timeline_media.splice(i, 1);
      this.setState({ user_timeline_media });
    }
  }

  renderActivity = () => {
    const { activityModal, formSections, formSectionActive, working, activityData, academicLessonAttributes, user_timeline, user_timeline_metadata, assess_aspect, assess_curr, user_timeline_media, academic_activity_resource_assessment, renderActivityTabs } = this.state;

    const { defaultTheme } = this.props;

    if (formSectionActive.parent == '1') {
      const transdisciplinary_theme = activityData.academic_activity_resources.find((v, i) => v.aar_type == 'transdisciplinary_theme')
      return (<>
        <Row>
          <Col md="8">
            <div style={{ fontSize: 16, fontWeight: 'bold', color: brand_colors[defaultTheme.theme_id].color9, opacity: 0.7 }}>
              {activityData.user_timeline.ut_title}
            </div>
            <div style={{ fontSize: 13, color: brand_colors[defaultTheme.theme_id].color9, opacity: 0.7 }}>
              {activityData.user_timeline.ut_description}
            </div>
          </Col>
          <Col>
            <div style={{ fontSize: 12, color: brand_colors[defaultTheme.theme_id].color9, opacity: 0.7 }}><strong style={{ marginRight: 5, color: brand_colors[defaultTheme.theme_id].color4 }}>Start:</strong>{moment(activityData.user_timeline.start_datetime).format('DD-MM-YYYY HH:mm:ss')}</div>
            <div style={{ fontSize: 12, color: brand_colors[defaultTheme.theme_id].color9, opacity: 0.7 }}><strong style={{ marginRight: 5, color: brand_colors[defaultTheme.theme_id].color11 }}>End:</strong>{moment(activityData.user_timeline.end_datetime).format('DD-MM-YYYY HH:mm:ss')}</div>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col md={3}>
            <div style={{ padding: '10px 10px 10px 0' }}>
              <img src={(activityData.academic_activities.aa_image) ? portalURL + activityData.academic_activities.center_id + '/academic_activities/' + activityData.academic_activities.aa_image : 'https://via.placeholder.com/300x150?text=No+Thumbnail'} alt={t('Preview')} style={{ width: '100%' }} />
            </div>
          </Col>
          <Col>
            <div className={'d-flex flex-column align-items-center'}>
              <div className={'d-flex flex-row align-items-center'}>
                <span style={{ fontSize: 16, color: brand_colors[defaultTheme.theme_id].color9, opacity: 0.8 }}>{activityData.academic_activities.aa_name}</span>
              </div>
              <div style={{ fontSize: 14, color: brand_colors[defaultTheme.theme_id].color9, opacity: 0.7, width: '100%', textAlign: 'left' }}>
                <strong>{t('Activity Design') + ': '}</strong>
                {activityData.academic_activities.aa_desc}
              </div>
              <div style={{ fontSize: 14, color: brand_colors[defaultTheme.theme_id].color9, opacity: 0.7, width: '100%', textAlign: 'left' }}>
                <strong>{t('Central Idea') + ': '}</strong>
                {activityData.academic_activities.aa_desc_cidea}
              </div>
              <div style={{ fontSize: 14, color: brand_colors[defaultTheme.theme_id].color9, opacity: 0.7, width: '100%', textAlign: 'left' }}>
                <strong>{t('General Description') + ': '}</strong>
                {activityData.academic_activities.aa_desc_general}
              </div>
              <div style={{ fontSize: 14, color: brand_colors[defaultTheme.theme_id].color9, opacity: 0.7, width: '100%', textAlign: 'left' }}>
                <strong>{t('Unit') + ': '}</strong>
                {activityData.academic_activities.aa_desc_unit}
              </div>
            </div>
          </Col>
          <Col md={3}>
            <div className='div-box-theme' style={{ marginRight: 0 }}>
              {transdisciplinary_theme && academicLessonAttributes.map((vvv, iii) => {
                return (vvv.ala_id == transdisciplinary_theme.aar_type_id && vvv.ala_type == 'lesson_focus') &&
                  <div key={iii} className='d-flex flex-row align-items-center'>
                    <div className='div_image'>
                      <img src={portalURL + vvv.center_id + '/academic_lesson_attributes/' + vvv.ala_description_2} className="image" />
                    </div>
                    <div className='heading'>{vvv.ala_title}</div>
                  </div>

              })}

              <div className='d-flex flex-row flex-wrap' style={{ marginTop: '10px' }}>
                {transdisciplinary_theme && academicLessonAttributes.map((vvv, iii) => {
                  let academicSubjectLessonResourcesFind = activityData.academic_activity_resources
                    .find((vvvv, iiii) => {
                      return (vvvv.aar_type == 'transdisciplinary_theme_focus' && vvvv.aar_id == vvv.ala_id)
                    })
                  return (vvv.ala_type == 'lesson_focus' && academicSubjectLessonResourcesFind)
                    ? (<div key={iii} className="d-flex flex-row justify-content-center">
                      <FontAwesomeIcon icon={faCircle} color={brand_colors[defaultTheme.theme_id].color18} style={{ fontSize: 12, padding: '8px' }} />
                      <span className='title'>{vvv.ala_title}</span>
                    </div>) : null
                })}
              </div>
            </div>
            <div style={{ marginTop: 10 }}>
              {activityData.academic_activities.aa_audio && <audio src={activityData.academic_activities.aa_audio} controls style={{ width: '100%' }} />}
            </div>
          </Col>
        </Row>
        <Row>
          {/* ALL ATTACHMENTS */}
          {activityData.academic_activity_attachments.map((v, i) => {
            return (
              <Col key={i} md='3' className='mb-1'>
                <a href={portalURL + v.center_id + '/academic_activity_attachments/' + v.aa_attach_name} target='_blank' style={{ position: 'relative', borderRadius: 5, borderStyle: 'solid', borderWidth: 1, borderColor: '#ced4da', height: 100, padding: 10 }} className='d-flex justify-content-center align-items-center'>
                  {/* IMAGE */}
                  {v.aa_attach_type.includes('image') && (
                    <>
                      <img src={portalURL + v.center_id + '/academic_activity_attachments/' + v.aa_attach_name} alt={t('Preview')} style={{ height: '100%' }} />
                    </>
                  )}
                  {/* AUDIO */}
                  {v.aa_attach_type.includes('audio') && (
                    <>
                      <img src={audioAttachment} alt={t('Preview')} style={{ height: '100%' }} />
                    </>
                  )}
                  {/* PDF */}
                  {v.aa_attach_type.includes('application') && (
                    <>
                      <img src={pdfAttachment} alt={t('Preview')} style={{ height: '100%' }} />
                    </>
                  )}
                </a>
              </Col>
            );
          })}
        </Row>
        <Row>
          <Col>
            <div className={'d-flex flex-row align-items-center justify-content-between section-heading'}>
              <span className='heading'>{'Objectives'}</span>
            </div>
            <div className='d-flex flex-row flex-wrap' style={{ marginTop: '10px' }}>
              {activityData.academic_activities.curriculums.map((v, i) => {
                return (
                  <div key={i} className='d-flex flex-row justify-content-center align-items-center listgroup'>
                    <span className='title'>{v.curriculum_code}</span>
                  </div>
                )
              })}
            </div>
          </Col>
        </Row>
        {/* Skills */}
        <Row>
          <Col>
            <div className={'d-flex flex-row align-items-center justify-content-between section-heading'}>
              <span className='heading'>{'Skills'}</span>
            </div>
            <div className='d-flex flex-row flex-wrap' style={{ marginTop: '10px' }}>
              {activityData.academic_activity_resources.map((v, i) => {
                return v.aar_type == 'skill' ? (
                  <div key={i} className='d-flex flex-row justify-content-center align-items-center listgroup'>
                    <span className='title'>{v.aar_title}</span>
                  </div>
                ) : null;
              })}
            </div>
          </Col>
        </Row>
        {/* Goals & Success Criteria */}
        <Row>
          <Col>
            <div className={'d-flex flex-row align-items-center justify-content-between section-heading'}>
              <span className='heading'>{'Goals & Success Criteria'}</span>
            </div>
            <div className='d-flex flex-row flex-wrap' style={{ marginTop: '10px' }}>
              {activityData.academic_activity_resources.map((v, i) => {
                return v.aar_type == 'success' ? (
                  <div key={i} className='d-flex flex-row justify-content-center align-items-center listgroup'>
                    <span className='title'>{v.aar_title}</span>
                  </div>
                ) : null;
              })}
            </div>
          </Col>
        </Row>
        {/* Key Concepts lesson_key_concept */}
        <Row>
          <Col>
            <div className={'d-flex flex-row align-items-center justify-content-between section-heading'}>
              <span className='heading'>{'Key Concepts'}</span>
            </div>
            <div className='d-flex flex-row flex-wrap' style={{ marginTop: '10px' }}>
              {this.state.academicLessonAttributes.map((vvv, iii) => {
                let findIndexSelectedData = activityData.academic_activity_resources.findIndex((vvvv) => (vvvv.aar_type_id == vvv.ala_id && vvvv.aar_type == 'lesson_key_concept'))
                {
                  return (findIndexSelectedData !== -1 && vvv.ala_type == 'lesson_key_concept' && vvv.ala_parent_id == 0) &&
                    <div className='div-box-theme' style={{ flex: 'none', width: '47%' }}>
                      <div className='heading'>
                        {vvv.ala_title}
                      </div>
                      <div className='description'>{vvv.ala_description_2}</div>
                      <Form.Group className='mb-3' style={{ width: '100%' }}>
                        <Form.Label>
                          <img src={imgDescription} />
                          {vvv.ala_description_1}
                        </Form.Label>
                        <div className='p-2' style={{ border: 'solid 1px ' + brand_colors[defaultTheme.theme_id].color10, borderRadius: 10 }}>
                          {activityData.academic_activity_resources[findIndexSelectedData].aar_desc}
                        </div>
                      </Form.Group>
                    </div>
                }
              })}
            </div>
          </Col>
        </Row>
        {/* Lines of Enquiry */}
        <Row>
          <Col>
            <div className={'d-flex flex-row align-items-center justify-content-between section-heading'}>
              <span className='heading'>{'Lines of Enquiry'}</span>
            </div>
            <div className='d-flex flex-row flex-wrap' style={{ marginTop: '10px' }}>
              {activityData.academic_activity_resources.map((v, i) => {
                return v.aar_type == 'lines_of_enquiry' ? (
                  <div key={i} className='d-flex flex-row justify-content-center align-items-center listgroup'>
                    <span className='title'>{v.aar_title}</span>
                  </div>
                ) : null;
              })}
            </div>
          </Col>
        </Row>
        {/* Approaches to learning */}
        <Row>
          <Col md="12">
            <div className={'d-flex flex-row align-items-center justify-content-between section-heading'}>
              <span className='heading'>{'Approaches to learning'}</span>
            </div>
          </Col>
          <Col md="8">
            {this.state.academicLessonAttributes.map((vvv, iii) => {
              if (vvv.ala_type == 'approaches_to_learning' && vvv.ala_parent_id == 0) {
                let allChildren = this.state.academicLessonAttributes.filter((vvvv, iiii) => vvvv.ala_parent_id == vvv.ala_id)
                let selectedChildren = []
                allChildren.map((vvvv, iiii) => {
                  let find = activityData.academic_activity_resources.find((vvvvv, iiiii) => ('approaches_to_learning' == vvvvv.aar_type && vvvv.ala_id == vvvvv.aar_type_id))
                  if (find) {
                    selectedChildren.push(find)
                  }
                })
                if (selectedChildren.length <= 0) {
                  return null
                }

                return <div className='div-box-theme'>
                  <div className='d-flex flex-row' >
                    <div className='heading'>{vvv.ala_title}</div>
                  </div>
                  <div style={{ fontSize: '13px', color: brand_colors[this.props.defaultTheme.theme_id].color9, opacity: '0.7' }}>{vvv.ala_description_1}</div>
                  {(selectedChildren.length > 0)
                    && <div style={{ borderTopStyle: 'solid', borderTopWidth: 1, borderTopColor: brand_colors[this.props.defaultTheme.theme_id].color18, marginTop: '5px', paddingTop: '5px', width: '100%' }}>
                      {selectedChildren.map((vvvv, iiii) => {
                        return <div className='d-flex flex-row justify-content-between' style={{ color: brand_colors[this.props.defaultTheme.theme_id].color9, opacity: 0.7 }}>
                          <div style={{ marginTop: '10px', fontSize: '14px', color: brand_colors[this.props.defaultTheme.theme_id].color9, opacity: 0.7 }}><span style={{ fontWeight: '600' }}>{vvvv.aar_title}</span> : {vvvv.aar_desc}</div>
                        </div>
                      })}
                    </div>
                  }
                </div>
              }
              return null
            })}
          </Col>
        </Row>
        {/* Learner Profile */}
        <Row>
          <Col md="12">
            <div className={'d-flex flex-row align-items-center justify-content-between section-heading'}>
              <span className='heading'>{'Learner Profile'}</span>
            </div>
          </Col>
          <Col md={12} className='d-flex flex-row flex-wrap'>
            {this.state.academicLessonAttributes.map((vvv, iii) => {
              let findIndexSelectedData = activityData.academic_activity_resources.findIndex((vvvv) => (vvvv.aar_type_id == vvv.ala_id && vvvv.aar_type == 'learner_profile'))
              if (findIndexSelectedData !== -1 && vvv.ala_type == 'learner_profile' && vvv.ala_parent_id == 0) {
                return <div className='div-box-theme cursor-pointer' style={{ flex: 'none', width: '47%' }}>
                  <div className='check'>
                    <FontAwesomeIcon
                      icon={(findIndexSelectedData !== -1) ? faCheckCircle : faCircle}
                      color={(findIndexSelectedData !== -1) ? brand_colors[this.props.defaultTheme.theme_id].color4 : brand_colors[this.props.defaultTheme.theme_id].color9}
                      style={{ fontSize: 14, opacity: (findIndexSelectedData !== -1) ? 1 : 0.3 }}
                    />
                  </div>
                  <div className='heading'>{vvv.ala_title}</div>
                  <div className='description'>{vvv.ala_description_1}</div>
                </div>
              }
            })}
          </Col>
        </Row>
      </>)
    }
    // EACH STUDENT
    else if (formSectionActive.parent == '2') {

      let thisChild = null
      formSections.map((v, i) => {
        if (v.uid == formSectionActive.parent) {
          thisChild = v.data.find((vv, ii) => vv.uid == formSectionActive.child)
        }
      })

      console.log('thisChild', thisChild, academic_activity_resource_assessment);

      return (<>
        <div className={'d-flex flex-row align-items-center justify-content-between section-heading'}>
          <div className='d-flex flex-row align-items-end justify-content-center mb-1'>
            <div style={{ position: 'relative', borderRadius: 5, borderStyle: 'solid', borderWidth: 1, borderColor: '#ced4da', height: 60, padding: 10, marginRight: 10 }} className='d-flex justify-content-center align-items-center'>
              <img src={thisChild.image} alt={t('Preview')} style={{ height: '100%' }} />
            </div>
            <span className='heading'>{thisChild.name}</span>
          </div>
          <div>
            <ButtonGroup size="sm">
              {renderActivityTabs.tabs.map((v) => {
                let selectedTab = (renderActivityTabs.activeTab.name == v.name)
                return <Button variant={selectedTab ? "success" : "outline-success"} onClick={() => renderActivityTabs.toggleTab(v)}>{v.name}</Button>
              })}
            </ButtonGroup>
          </div>
          <div className='d-flex flex-row align-items-center'>
            <div className='d-flex flex-column'>
              {(this.props.authData.userData && (parseInt(this.props.authData.userData.curriculumshare) == 0))
                && <div style={{ backgroundColor: brand_colors[this.props.defaultTheme.theme_id].color8, textAlign: 'center', padding: '5px 10px', borderRadius: 20 }}>
                  <span style={{ fontSize: 13, color: brand_colors[this.props.defaultTheme.theme_id].color11, opacity: 0.7, fontWeight: 'bold' }}>
                    {t('You are not allowed to share with parents')}
                  </span>
                </div>
              }
              <div style={{ margin: '0 20px', backgroundColor: brand_colors[this.props.defaultTheme.theme_id].color8, textAlign: 'center', padding: '5px 10px', borderRadius: 20 }}>
                <span style={{ fontSize: 13, color: brand_colors[this.props.defaultTheme.theme_id].color9, opacity: 0.7, fontWeight: 'bold' }}>
                  {t('Share with parent')}
                </span>
                <Form.Check
                  disabled={this.props.authData.userData && (parseInt(this.props.authData.userData.curriculumshare) == 0)}
                  type="switch"
                  value={1}
                  checked={user_timeline.ut_publish_status}
                  onChange={(event) => {
                    this.setState({ user_timeline: { ...this.state.user_timeline, ['ut_publish_on']: (event.target.checked) ? null : moment().add(10, 'years').toDate(), ut_publish_status: event.target.checked } })
                  }}
                />
              </div>
            </div>
            <Button variant='warning' onClick={() => setTimeout(() => {
              this.saveUserTimeline()
            }, 300)}>{(this.state.utWorking) ? <Spinner as='span' animation='grow' size='sm' role='status' aria-hidden='true' /> : 'Update'}</Button>
          </div>
        </div>

        {renderActivityTabs.activeTab.name == 'Assessment' &&
          <div>
            <Row>
              <Col md={6}>
                <Form.Group className='mb-3'>
                  <Form.Label>
                    <img src={imgDescription} />
                    {t('Title')}
                  </Form.Label>
                  <Form.Control type='text' value={user_timeline.ut_title} onChange={(event) => this.setStateUserTimeline('ut_title', event.target.value)} ref={this.ut_title_Ref} />
                </Form.Group>
              </Col>
              <Col>
                <Form.Label>
                  <img src={imgDescription} />
                  {t('Attainment')}
                </Form.Label>
                <Form.Group>
                  <Select
                    isClearable
                    options={this.state.assessmentMenuData.assessment.map((v, i) => { return { ...v, label: v.assmt_code, value: v.assmt_id, assmt_color: v.assmt_color } })}
                    onChange={(event) => this.setStateUserTimelineMetadata('assmt_id', event)}
                    value={user_timeline_metadata.assmt_id}
                    menuPortalTarget={document.body}
                    styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999, }), control: (base) => ({ ...base, }) }}
                    formatOptionLabel={option => (
                      <div>
                        <span style={{ color: option.assmt_color, fontSize: 14 }}>{option.label}</span>
                      </div>
                    )}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              {/* Observation */}
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>{t('Observation')}</Form.Label>
                  <Form.Control as="textarea" rows={3} value={user_timeline.ut_description} onChange={(event, editor) => this.setStateUserTimeline('ut_description', event.target.value)} ref={this.ut_description_Ref} />
                </Form.Group>
              </Col>
              {/* Next Steps */}
              <Col >
                <Form.Group className="mb-3">
                  <Form.Label>{t('Next Steps')}</Form.Label>
                  <Form.Control as="textarea" rows={3} value={user_timeline_metadata.description2} onChange={(event, editor) => this.setStateUserTimelineMetadata('description2', event.target.value)} ref={this.description2_Ref} />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              {/* ADD ATTACHMENT */}
              <Col md='3' className='mb-1' id='form_section_3.2'>
                <div style={{ flexDirection: 'column' }} className='pic-prev d-flex justify-content-center'>
                  <div style={{ height: 100, display: 'flex', overflow: 'hidden', borderStyle: 'dashed', borderColor: brand_colors[defaultTheme.theme_id].color18, borderWidth: 2, borderRadius: 20 }}>
                    <button onClick={() => this.filePicker.current.click()} style={{ background: 'none', border: 0, flex: 1 }}>
                      <FontAwesomeIcon icon={faPlus} style={{ fontSize: 30, color: brand_colors[defaultTheme.theme_id].color18 }} />
                    </button>
                  </div>
                  <Form.Control className='d-none' ref={this.filePicker} type='file' multiple onChange={this.addAttachment} name='user_timeline_media' />
                </div>
              </Col>
              {/* ALL ATTACHMENTS */}
              {user_timeline_media.map((v, i) => {
                return (
                  <Col key={i} md='3' className='mb-1'>
                    <div style={{ position: 'relative', borderRadius: 5, borderStyle: 'solid', borderWidth: 1, borderColor: '#ced4da', height: 100, padding: 10 }} className='d-flex justify-content-center align-items-center'>
                      {/* IMAGE */}
                      {v.data.type.includes('image') && (
                        <>
                          <img src={v.url} alt={t('Preview')} style={{ height: '100%' }} />
                        </>
                      )}
                      <button
                        onClick={() => this.deleteAssignedActivityMedia(v, i)}
                        style={{ background: 'none', border: 0 }}
                      >
                        <FontAwesomeIcon icon={faTrashCan} style={{ fontSize: 12, color: brand_colors[defaultTheme.theme_id].color11, position: 'absolute', bottom: 5, right: 5, cursor: 'pointer' }} />
                      </button>
                    </div>
                  </Col>
                );
              })}
            </Row>
            <div className={'d-flex flex-row align-items-center justify-content-between section-heading'}>
              <span className='heading'>{t('Observation')}</span>
            </div>
            <Row>
              <Col>
                {this.renderAssessmentList()}
              </Col>
            </Row>
            {/* Approaches to learning */}
            <Row>
              <Col md="12">
                <div className={'d-flex flex-row align-items-center justify-content-between section-heading'}>
                  <span className='heading'>{'Approaches to learning'}</span>
                </div>
              </Col>
              <Col md="8">
                {this.state.academicLessonAttributes.map((vvv, iii) => {
                  if (vvv.ala_type == 'approaches_to_learning' && vvv.ala_parent_id == 0) {
                    let allChildren = this.state.academicLessonAttributes.filter((vvvv, iiii) => vvvv.ala_parent_id == vvv.ala_id)
                    let selectedChildren = []
                    allChildren.map((vvvv, iiii) => {
                      let find = activityData.academic_activity_resources.find((vvvvv, iiiii) => ('approaches_to_learning' == vvvvv.aar_type && vvvv.ala_id == vvvvv.aar_type_id))
                      if (find) {
                        selectedChildren.push(find)
                      }
                    })
                    if (selectedChildren.length <= 0) {
                      return null
                    }

                    return <div className='div-box-theme'>
                      <div className='d-flex flex-row' >
                        <div className='heading'>{vvv.ala_title}</div>
                      </div>
                      <div style={{ fontSize: '13px', color: brand_colors[this.props.defaultTheme.theme_id].color9, opacity: '0.7' }}>{vvv.ala_description_1}</div>
                      {(selectedChildren.length > 0)
                        && <div style={{ borderTopStyle: 'solid', borderTopWidth: 1, borderTopColor: brand_colors[this.props.defaultTheme.theme_id].color18, marginTop: '5px', paddingTop: '5px', width: '100%' }}>
                          {selectedChildren.map((vvvv, iiii) => {
                            let selected_aara = academic_activity_resource_assessment.find((aara) => aara.aar_id == vvvv.aar_id)

                            return <div className='d-flex flex-column mb-2' style={{ fontSize: 13, color: brand_colors[this.props.defaultTheme.theme_id].color9, opacity: 0.8 }}>
                              <div className='d-flex flex-row justify-content-between align-items-center'>
                                <div style={{ fontWeight: '600', fontSize: 14 }}>{vvvv.aar_title}</div>
                                <div style={{ width: '50%' }}>
                                  <Form.Group>
                                    <Select
                                      isClearable
                                      options={this.state.assessmentMenuData.assessment.map((v, i) => { return { ...v, label: v.assmt_code, value: v.assmt_id, assmt_color: v.assmt_color } })}
                                      onChange={(event) => this.selectResourceAssessment({ aa_id: vvvv.aa_id, aar_id: vvvv.aar_id, assmt_id: event })}
                                      value={selected_aara ? { label: selected_aara.assmt_id.assmt_code, value: selected_aara.assmt_id.assmt_id, assmt_color: selected_aara.assmt_id.assmt_color } : ''}
                                      menuPortalTarget={document.body}
                                      styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999, }), control: (base) => ({ ...base, }) }}
                                      formatOptionLabel={option => (
                                        <div>
                                          <span style={{ color: option.assmt_color, fontSize: 14 }}>{option.label}</span>
                                        </div>
                                      )}
                                    />
                                  </Form.Group>
                                </div>
                              </div>
                              <div>
                                {vvvv.aar_desc}
                              </div>
                            </div>
                          })}
                        </div>
                      }
                    </div>
                  }
                  return null
                })}
              </Col>
            </Row>
            {/* Learner Profile */}
            <Row>
              <Col md="12">
                <div className={'d-flex flex-row align-items-center justify-content-between section-heading'}>
                  <span className='heading'>{'Learner Profile'}</span>
                </div>
              </Col>
              <Col md={12} className='d-flex flex-row flex-wrap'>
                {this.state.academicLessonAttributes.map((vvv, iii) => {
                  let findIndexSelectedData = activityData.academic_activity_resources.findIndex((vvvv) => (vvvv.aar_type_id == vvv.ala_id && vvvv.aar_type == 'learner_profile'))
                  if (findIndexSelectedData !== -1 && vvv.ala_type == 'learner_profile' && vvv.ala_parent_id == 0) {
                    let selected_aara = academic_activity_resource_assessment.find((aara) => aara.aar_id == activityData.academic_activity_resources[findIndexSelectedData].aar_id)

                    console.log('selected_aara', selected_aara);

                    return <div className='div-box-theme' style={{ flex: 'none', width: '47%' }}>
                      <div className='d-flex flex-row justify-content-between align-items-center' style={{ width: '100%', fontSize: 13, color: brand_colors[this.props.defaultTheme.theme_id].color9, opacity: 0.8 }}>
                        <div className='heading' style={{ fontWeight: '600', fontSize: 14 }}>{vvv.ala_title}</div>
                        <div style={{ width: '50%' }}>
                          <Form.Group>
                            <Select
                              isClearable
                              options={this.state.assessmentMenuData.assessment.map((v, i) => { return { ...v, label: v.assmt_code, value: v.assmt_id, assmt_color: v.assmt_color } })}
                              onChange={(event) => this.selectResourceAssessment({ aa_id: activityData.academic_activity_resources[findIndexSelectedData].aa_id, aar_id: activityData.academic_activity_resources[findIndexSelectedData].aar_id, assmt_id: event })}
                              value={selected_aara ? { label: selected_aara.assmt_id.assmt_code, value: selected_aara.assmt_id.assmt_id, assmt_color: selected_aara.assmt_id.assmt_color } : ''}
                              menuPortalTarget={document.body}
                              styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999, }), control: (base) => ({ ...base, }) }}
                              formatOptionLabel={option => (
                                <div>
                                  <span style={{ color: option.assmt_color, fontSize: 14 }}>{option.label}</span>
                                </div>
                              )}
                            />
                          </Form.Group>
                        </div>
                      </div>
                      <div className='description' style={{ fontSize: 13, color: brand_colors[this.props.defaultTheme.theme_id].color9, opacity: 0.8 }}>{vvv.ala_description_1}</div>
                    </div>
                  }
                })}
              </Col>
            </Row>
          </div>
        }
        {/* Reflection */}
        {renderActivityTabs.activeTab.name == 'Reflection' &&
          <div>
            {'Still in Development'}
          </div>
        }
        {/* Activity Questions */}
        {renderActivityTabs.activeTab.name == 'Activity Questions' &&
          <div>
            {'Still in Development'}
          </div>
        }

        <div style={{ height: 400 }}></div>
      </>
      )
    }
  }


  resetFilter = () => {
    this.setState(
      {
        offset: 0,
        finishedData: false,
        refreshingData: false,
      },
      () => this.getTimeline(true)
    );
  };

  render() {
    console.log('ASSIGNEDACTIVITY', this.state, this.props);
    const { activityModal, formSections, formSectionActive, working, activityData, academicLessonAttributes } = this.state
    const { defaultTheme } = this.props;
    return (
      <Container fluid>
        <div id='iedu-layout'>
          <NavBar />
          <div id='page-content'>
            <Header lite={true} heading={t('Class Activity')} showClassSelector={false} showSubjectSelector={false} backBtn={true} classSelectorFunc={[]} />
            <div className='grey-section'>
              {/* FILTER BAR */}
              <div className="filter-bar">
                <div className="filter">
                  <strong className="filter-title">{t('Keyword')}</strong>
                  <input type="text" className="paginate_search" placeholder="Enter Keyword..." defaultValue={this.state.filter.keyword} onClick={() => this.state.filter.toggleFilter(false)} onBlur={(e) => this.state.filter.setData('keyword', e.target.value)} />
                </div>
                {/* DATE */}
                <div className="filter filter-date" >
                  <div className="filter-trigger" onClick={() => this.state.filter.toggleFilter('date_range_picker')}>
                    <FontAwesomeIcon
                      icon={(this.state.filter.show == 'date_range_picker') ? faChevronUp : faChevronDown}
                      color={brand_colors[defaultTheme.theme_id].color18}
                      className="filter-arrow"

                    />
                    <strong className="filter-title">{t('Date Range')}</strong>
                    <span className="selected-types">{moment(this.state.filter.selectedDateRange.startDate).format('DD-MM-YYYY')} -- {moment(this.state.filter.selectedDateRange.endDate).format('DD-MM-YYYY')}</span>
                  </div>
                  {(this.state.filter.show == 'date_range_picker')
                    && <div className='filter-box' style={{ display: (this.state.filter.show == 'date_range_picker') ? 'initial' : 'none' }}>
                      <DateRangePicker
                        ranges={[this.state.filter.selectedDateRange]}
                        onChange={(date) => {

                          this.state.filter.setData('selectedDateRange', { startDate: new Date(date.selection.startDate), endDate: new Date(date.selection.endDate), key: 'selection' })
                        }}
                        // className='filter-date-picker'
                        style={{ backgroundColor: '#fff' }}
                      />
                    </div>
                  }
                </div>

                {/* TRANSDISCIPLINARY THEME */}
                <div className="filter">
                  <div className="filter-trigger" onClick={() => this.state.filter.toggleFilter('theme')}>
                    <FontAwesomeIcon
                      icon={(this.state.filter.show == 'theme') ? faChevronUp : faChevronDown}
                      color={brand_colors[defaultTheme.theme_id].color18}
                      className="filter-arrow"
                    />
                    <strong className="filter-title">{t('Transdisciplinary Theme')}</strong>
                    <span className="selected-types"><span>{(this.state.filter.selectedLessonFocus) ? this.state.filter.selectedLessonFocus.label : 'Select'}</span></span>
                  </div>
                  <div className="filter-box" style={{ display: (this.state.filter.show == 'theme') ? 'initial' : 'none' }}>
                    <Select
                      isClearable
                      options={this.state.filter.all_lesson_focus.map((v, i) => {
                        return { label: v.ala_title, value: v.ala_id, picture: v.ala_description_2, center_id: v.center_id }
                      })}
                      onChange={(event) => this.state.filter.setData('selectedLessonFocus', event)}
                      value={this.state.filter.selectedLessonFocus}
                      menuPortalTarget={document.body}
                      styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                      formatOptionLabel={option => (
                        <div>
                          {option.picture ? <img src={portalURL + option.center_id + '/academic_lesson_attributes/' + option.picture} style={{ width: 30, height: 30, objectFit: 'cover', marginRight: 5 }} /> : ''}
                          <span style={{ fontSize: 16 }}>{option.label}</span>
                        </div>
                      )}
                    />
                  </div>
                </div>
                {/* BTN SEARCH */}
                <div className="filter-btn d-flex align-items-center" style={{ marginLeft: 10 }}>
                  <button type="button" className="btn btn-primary" id="btn_filter_modal_apply" style={{ width: 50, height: 50, textAlign: 'center' }} onClick={() => { this.setState({ filter: { ...this.state.filter, show: false } }, () => this.resetFilter()) }} title={t('Filter Activities')}>
                    {this.state.utWorking ? (
                      <Spinner size='sm' animation='grow' variant='light' />

                    ) : <FontAwesomeIcon
                      icon={faSearch}
                      color={brand_colors[defaultTheme.theme_id].color8}
                      style={{ fontSize: '24px', }}
                    />}
                  </button>

                  {/* BTN SEARCH */}
                  <div className="filter-btn d-flex align-items-center" style={{ marginLeft: 10 }}>
                    <button type="button" className="btn btn-success" style={{ width: 50, height: 50, textAlign: 'center' }} onClick={() => this.state.userTimelineModal.toggleModal(this.AcademicActivityTimelineType)} title={t('Assign New Activity')}>
                      <FontAwesomeIcon
                        icon={faPlus}
                        color={brand_colors[defaultTheme.theme_id].color8}
                        style={{ fontSize: '24px', }}
                      />
                    </button>
                  </div>
                </div>
              </div>

              <Card
                className='border-0'
                style={{ borderRadius: '20px' }}>
                <Card.Body>
                  <Row>
                    {this.state.userTimelineData.map((v, i) => {

                      let data = {
                        allData: v,
                        name: v.ut_title,
                        text1: null,
                        text2: <span>
                          <Badge bg='primary' className='me-1'>{moment(v.start_datetime).format('DD-MM-YYYY HH:mm:ss')}</Badge>
                          <Badge bg='danger'>{moment(v.end_datetime).format('DD-MM-YYYY HH:mm:ss')}</Badge>
                        </span>,
                        description: <div>
                          <Badge bg='info' style={{ fontSize: 14 }}>{v.classes[0].class_theme}</Badge>
                          <div>{t('Students Tagged: ') + v.user_timeline_tagged.length}</div>
                        </div>,
                        // linkTo: '/subject/lessons/' + v.acs_name,
                        // linkToStates: { as_id: v.as_id, acs_id: v.acs_id, ab_id: v.ab_id, },
                        cardImage: (v.ut_attachment_1) ? portalURL + v.center_id + '/' + v.ut_folder + '/' + v.ut_attachment_1 : require('../../assets/images/noimage.png'),
                        actions: [
                          { type: 'makeobs', name: 'Make Observations', image: null, iconName: faNoteSticky, iconColor: brand_colors[this.props.defaultTheme.theme_id].color1, onClick: () => this.state.activityModal.toggleModal(v) },

                          { type: 'edit', name: 'Edit', image: require('../../assets/images/edit.png'), iconName: null, iconColor: brand_colors[this.props.defaultTheme.theme_id].color16, onClick: () => this.editUserTimeline_AcademicActivity(v.ut_id) },

                          { type: 'delete', name: 'Delete', image: require('../../assets/images/remove-red.png'), iconName: null, iconColor: brand_colors[this.props.defaultTheme.theme_id].color9, onClick: () => this.deleteUserTimeline_AcademicActivity(v.ut_id) },
                        ]
                      }
                      return <GeneralCard data={data} key={i} />
                    })}

                    <Col md={12} className='d-flex align-items-center justify-content-center'>
                      {(this.state.userTimelineData.length <= 0 && this.state.finishedData) && t('No Assigned Activity found!')}

                      {this.state.refreshingData && (
                        <Spinner size='lg' animation='grow' variant='dark' />

                      )}
                      {(this.state.userTimelineData.length > 0 && this.state.finishedData) && t('No more Assigned Activity found!')}
                    </Col>
                  </Row>

                  {(!this.state.finishedData && !this.state.refreshingData) &&
                    <Row>
                      <Col md={12} className='d-flex align-items-center justify-content-center'>
                        <Button variant="light" onClick={() => this.getTimeline()} style={{ width: '200px' }}>
                          {t('Load More')}
                        </Button>
                      </Col>
                    </Row>
                  }

                </Card.Body>
              </Card>
            </div>
          </div>
        </div>

        {/* ACTIVITY MODAL - Ahmad  */}
        <Offcanvas show={activityModal.show} onHide={activityModal.closeModal} style={{ height: '100%' }} placement='bottom'>
          <Offcanvas.Header id='iedu-header' style={{ marginTop: '1rem', marginRight: '1rem', marginLeft: '1rem', marginBottom: 0 }}>
            <div className='d-flex flex-row'>
              <span className='header-back-btn' onClick={activityModal.closeModal} style={{ marginRight: 10 }}>
                <FontAwesomeIcon icon={faArrowAltCircleLeft} color={brand_colors[defaultTheme.theme_id].color9} />
              </span>
              <Offcanvas.Title>{t(activityModal.modalTitle)}</Offcanvas.Title>
            </div>
            <div></div>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <div
              className={'d-flex flex-row grey-section'}
              style={{ overflow: 'initial', backgroundImage: 'url(https://academic.educore.io/static/media/peg9b84e4936acef29bbcd9.png)', backgroundRepeat: 'repeat', backgroundPosition: '10px 12px', overflow: 'hidden', }} >
              <div style={{ backgroundColor: brand_colors[defaultTheme.theme_id].color8, marginLeft: 10, marginRight: 10, borderRadius: '20px', width: '250px', height: '100%', top: '5px', position: 'sticky' }}>
                <div style={{ paddingRight: '1.5rem', paddingBottom: '1.5rem', paddingTop: '1rem' }}>
                  {formSections.map((v, i) => {
                    return (
                      <div key={i}>
                        <div className='d-flex flex-row align-items-center' style={{ cursor: 'pointer', borderRadius: '20px', paddingLeft: '5px', paddingRight: '5px', backgroundColor: brand_colors[defaultTheme.theme_id].color8, zIndex: 1, position: 'relative' }}>
                          <span style={{ fontSize: 18, fontWeight: '600', color: brand_colors[defaultTheme.theme_id].color9, opacity: 0.7 }}>{v.name}</span>
                        </div>

                        <div style={{ paddingLeft: '1rem', borderLeft: 'solid 1px ' + brand_colors[defaultTheme.theme_id].color18, marginLeft: '1.5rem' }}>
                          {v.data.map((vv, ii) => {
                            return (
                              <div key={ii} className='d-flex flex-row align-items-center' style={{ marginBottom: 5, marginTop: 2, cursor: 'pointer' }} onClick={() => this.changeToFromSection(v.uid, vv.uid)}>
                                <FontAwesomeIcon icon={vv.completed ? faCheckCircle : faCircle} color={vv.completed ? brand_colors[defaultTheme.theme_id].color4 : brand_colors[defaultTheme.theme_id].color18} style={{ fontSize: 12, marginRight: 5 }} />
                                <span style={{ fontSize: 14, color: vv.completed ? brand_colors[defaultTheme.theme_id].color4 : brand_colors[defaultTheme.theme_id].color18 }}>{vv.name}</span>
                              </div>
                            )
                          })}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <Col className='custom-styled-form hide-scrollbar' style={{ backgroundColor: brand_colors[defaultTheme.theme_id].color8, marginLeft: 10, marginRight: 10, borderRadius: '20px', padding: '1rem', overflow: 'auto', height: '75vh' }}>
                {activityData &&
                  this.renderActivity()
                }
              </Col>
            </div>
          </Offcanvas.Body>
        </Offcanvas>

        {/* ACADEMIC-ACTIVITY ASSIGN */}
        {this.renderFormAcademicActivity()}

        <SweetAlert show={this.state.deleteModal.show} warning showCancel confirmBtnText={t('Yes, delete it!')} confirmBtnBsStyle='danger' title={t('Are you sure?')} onConfirm={this.state.deleteModal.action} onCancel={() => this.setState({ deleteModal: { ...this.state.deleteModal, show: false, id: 0, action: () => { } } })} focusCancelBtn>
          {t('Confirm Delete!')}
        </SweetAlert>

      </Container >
    );
  }
}

const mapStateToProps = (state) => ({
  authData: state.auth.authData,
  defaultLanguage: state.language.defaultLanguage,
  selectedClass: state.selectedClass.data,
  children: state.children.list,
  defaultTheme: state.theme.defaultTheme,
  selectedSubject: state.selectedClass.subject,
});

export default connect(mapStateToProps, null)(AssignedActivity);
