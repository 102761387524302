import React, { Component } from 'react';
import {
  Container,
  Card,
  Row,
  Col,
  Button,
  Badge,
  Modal,
  Form,
  Spinner,
} from 'react-bootstrap';
import { t } from '../../helpers/translation_helper';
import NavBar from '../_partials/NavBar/_NavBar';
import Header from '../_partials/Header/_Header';
import { connect } from 'react-redux';
import { setUpdateSchoolModal } from '../../redux/slices/updateSchoolModalSlice';
import Allergies from './Allergies';
import Illnesses from './Illnesses';
import Vaccines from './Vaccines';
import SENs from './SENs';

class HealthSetup extends Component {
  render() {
    return (
      <Container fluid>
        <div id='iedu-layout'>
          <NavBar />
          <div id='page-content' style={{ width: '100%' }}>
            <Header lite={true} heading={t('Health Setup')} backBtn={true} />
            <Row className='mb-4'>
              <Col className='px-3'>
                <Allergies />
              </Col>
              <Col className='pe-4'>
                <Illnesses />
              </Col>
            </Row>
            <Row className='mb-4'>
              <Col className='px-3'>
                <Vaccines />
              </Col>
              <Col className='pe-4'>
                <SENs />
              </Col>
            </Row>
          </div>
        </div>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  authData: state.auth.authData,
  defaultLanguage: state.language.defaultLanguage,
  defaultTheme: state.theme.defaultTheme,
});

const mapDispatchToProps = () => ({
  setUpdateSchoolModal,
});

export default connect(mapStateToProps, mapDispatchToProps())(HealthSetup);
