import React, { Component } from 'react';
import { Container, Card, Row, Col, Button, Spinner, InputGroup, Form, Table, Badge, ButtonGroup, DropdownButton, Dropdown } from 'react-bootstrap';
import { t } from '../../helpers/translation_helper';
import NavBar from '../_partials/NavBar/_NavBar';
import Header from '../_partials/Header/_Header';
import { connect } from 'react-redux';
import { brand_colors } from '../../helpers/brand_colors_helper';
import { faCalendar, faEye, faEyeSlash, faSearch, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DateRangePicker } from 'react-date-range';
import moment from 'moment';
import { GET } from '../../api';
import { numberFormat } from '../../helpers/general_helpers';
import { faCalendarAlt, faFileExcel } from '@fortawesome/free-regular-svg-icons';
import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';
import { renderTableBody, renderTableHead } from '../../helpers/table_helper';
import Pagination from '../_partials/Pagination';
import Select from 'react-select';
import { toast } from 'react-toastify';
let cancelToken;


class ClassesAnalysisReport extends Component {

  state = {
    fetching: false,
    terms: [],
    term_id: '',
    employeeSalary: '',
    cdt: {
      fetching: false,
      search: '',
      rows: 10,
      offset: 0,
      order: 'class_theme',
      dir: 'ASC',
      lastPage: 0,
      page: 0,
      totalRecords: 0,
      rowsOptions: [10, 25, 50, 'All'],
      columns: [
        {
          id: 'class_theme',
          column: 'class_theme',
          name: 'Class',
          visibility: true,
          sortable: false
        },
        {
          id: 'registration_count',
          column: 'registration_count',
          name: 'Total Registration',
          visibility: true,
          sortable: false
        },
        {
          id: 'teacher_ratio',
          column: 'teacher_ratio',
          name: 'Teacher Ratio',
          visibility: true,
          sortable: false
        },
        {
          id: 'assigned_teachers',
          column: 'assigned_teachers',
          name: 'Assigned Teachers',
          visibility: true,
          sortable: false
        },
        {
          id: 'suggested_teachers',
          column: 'suggested_teachers',
          name: 'Suggested Teachers',
          visibility: true,
          sortable: false
        },
        {
          id: 'revenue',
          column: 'revenue',
          name: 'Revenue',
          visibility: true,
          sortable: false
        },
        {
          id: 'expenses',
          column: 'expenses',
          name: 'Expenses',
          visibility: true,
          sortable: false
        },
      ],
      data: [],
    },
  }

  componentDidMount() {
    this.getTerms();
  }

  getTerms = async () => {
    if (this.getTermsReq) {
      this.getTermsReq.abort();
    }
    this.getTermsReq = new AbortController();
    const res = await GET('classes-analysis-report/get-terms', {
      signal: this.getTermsReq.signal,
    });

    if (res) {
      let resData = res.data.map(item => {
        return {
          ...item,
          value: item.term_id,
          label: item.term_name,
        }
      });
      this.setState({ terms: resData })
    }
  }

  fetch = async (params) => {

    if (this.state.term_id == '') {
      toast.error(t('Please select term!'));
      return;
    }

    this.setState({
      showDateRange: false,
      cdt: {
        ...this.state.cdt,
        fetching: true,
      }
    });

    if (cancelToken) {
      cancelToken.abort();
    }
    cancelToken = new AbortController();

    try {
      const res = await GET('classes-analysis-report/get', {
        signal: cancelToken.signal,
        params: {
          search: params.search,
          order: params.order,
          dir: params.dir,
          limit: params.limit,
          offset: parseInt(params.page) * parseInt(params.limit),
          term_id: this.state.term_id.value,
        },
      });

      if (res.status === 200) {

        console.log('res.data', res.data);

        this.setState({
          cdt: {
            ...this.state.cdt,
            fetching: false,
            search: params.search,
            rows: parseInt(params.limit),
            offset: parseInt(params.page) * parseInt(params.limit),
            order: params.order,
            dir: params.dir,
            page: params.page,
            totalRecords: parseInt(res.data.total_count_filtered),
            lastPage: Math.ceil(parseInt(res.data.total_count_filtered) / parseInt(params.limit)),
            data: res.data.data,
          },
          employeeSalary: res.data.employeeSalary
        });
      }

    } catch (err) {
      console.log('err', err)
      this.setState({
        cdt: {
          ...this.state.cdt,
          fetching: false,
        }
      });

    }
  }

  exportExcel = async () => {

    const excelData = this.state.cdt.data.map((item) => {
      const row = {};
      this.state.cdt.columns
        .filter((column) => column.visibility)
        .map((column) => {

          if (column.name == 'Expenses') {
            let revenue = 0;
            item['assigned_teachers'].map((vv, ii) => {
              let findEmployeeSalary = this.state.employeeSalary[vv.employee_id]
              if (findEmployeeSalary.total_salary > 0) {
                revenue += findEmployeeSalary.total_salary / findEmployeeSalary.count
              }
            })
            row[column.name] = numberFormat(revenue) + ' per month';
          } else if (column.name == 'Revenue') {
            row[column.name] = (item['registration_sum'] != "" && item['registration_sum'] != null) ? numberFormat(item['registration_sum']) : 0;;
          } else if (column.name == 'Assigned Teachers') {
            row[column.name] = item[column.column].length;
          } else if (column.name == 'Suggested Teachers') {
            let suggestedTeachers = Math.ceil(item['registration_count'] / item['teacher_ratio']);
            row[column.name] = suggestedTeachers = suggestedTeachers || 0;
          } else {
            row[column.name] = item[column.column];
          }

        });
      return row;
    });

    const fileName = 'Classes Analysis Report ' + moment().format('DD-MM-YYYY');
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const ws = XLSX.utils.json_to_sheet(excelData);

    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });

    FileSaver.saveAs(data, fileName + fileExtension);
  }

  showHideColumn = (column_index) => {
    const columns = this.state.cdt.columns;
    columns[column_index].visibility = !columns[column_index].visibility;
    this.setState({ ctd: { ...this.state.ctd, columns } })
  }

  renderCell = (column_data, column_id, column_index, dataObj) => {
    const { authData } = this.props;
    const { center_id } = authData.centerData;

    let tdData;

    if (column_id == 'revenue') {
      tdData = (dataObj.registration_sum != "" && dataObj.registration_sum != null) ? numberFormat(dataObj.registration_sum) : 0;
    } else if (column_id == 'expenses') {
      let revenue = 0;
      dataObj.assigned_teachers.map((vv, ii) => {
        let findEmployeeSalary = this.state.employeeSalary[vv.employee_id]
        if (findEmployeeSalary.total_salary > 0) {
          revenue += findEmployeeSalary.total_salary / findEmployeeSalary.count
        }
      })
      tdData = numberFormat(revenue) + ' per month';
    } else if (column_id == 'assigned_teachers') {
      tdData = column_data.length;
    } else if (column_id == 'suggested_teachers') {
      let suggestedTeachers = Math.ceil(dataObj.registration_count / dataObj.teacher_ratio)
      tdData = (suggestedTeachers = suggestedTeachers || 0) > dataObj.assigned_teachers.length ? <Badge bg="danger">{suggestedTeachers}</Badge> : <Badge bg="success">{suggestedTeachers}</Badge>;
    } else {
      tdData = column_data;
    }


    return <td key={column_index}>{tdData}</td>;
  }

  render() {

    return (
      <Container fluid>
        <div id='iedu-layout'>
          <NavBar />
          <div id='page-content'>
            <Header lite={true} heading={t('Classes Analysis Report')} backBtn={true} />
            <div className='grey-section'>
              <Row>
                <Col md={3}>
                  <Select
                    placeholder={t('Select Term')}
                    options={this.state.terms}
                    onChange={(obj) => {
                      this.setState({ term_id: obj })
                    }}
                    value={this.state.term_id}
                    menuPortalTarget={document.body}
                    styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                    formatOptionLabel={option => (
                      <div>
                        <span style={{ fontSize: 14 }}>{option.label}</span>
                        {option.term_active == 1 && <Badge className='ms-1' bg='success'>Active</Badge>}
                      </div>
                    )}
                  />
                </Col>
                <Col className='d-flex align-items-center'>
                  <Button variant={'success'}
                    onClick={() => this.fetch({
                      search: this.state.cdt.search,
                      limit: this.state.cdt.rows,
                      order: this.state.cdt.order,
                      dir: this.state.cdt.dir,
                      page: this.state.cdt.page
                    })}>
                    {this.state.fetching
                      ? <Spinner as='span' animation='grow' size='sm' role='status' aria-hidden='true' />
                      : <FontAwesomeIcon icon={faSearch} style={{ fontSize: 16, color: brand_colors[this.props.defaultTheme.theme_id].color8, }} />
                    }
                  </Button>
                </Col>
              </Row>
            </div>
            <Card
              className='border-0'
              style={{ borderRadius: '20px' }}>
              <Card.Header style={{
                backgroundColor: '#fff',
                borderTopLeftRadius: '20px',
                borderTopRightRadius: '20px',
              }}>
                <Row>
                  <Col md={6} className='d-flex align-items-center'>
                    <ButtonGroup size='sm' className='me-3 float-left'>
                      {/* <Button><FontAwesomeIcon icon={faFilePdf} /> {t('PDF')}</Button> */}
                      <Button onClick={() => this.exportExcel()}>
                        <FontAwesomeIcon icon={faFileExcel} /> {t('Excel')}
                      </Button>
                      {/* <Button><FontAwesomeIcon icon={faPrint} /> {t('Print')}</Button> */}
                      <DropdownButton
                        autoClose={'outside'}
                        size='sm'
                        as={ButtonGroup}
                        title={t('Column Visibility')}>
                        {this.state.cdt.columns.map((column, column_index) => (
                          <Dropdown.Item key={column_index} className={column.visibility ? 'column-name-item' : 'column-name-item active'} onClick={() => this.showHideColumn(column_index)}>
                            <FontAwesomeIcon icon={column.visibility ? faEye : faEyeSlash} /> {column.name}
                          </Dropdown.Item>
                        ))}
                      </DropdownButton>
                    </ButtonGroup>
                    <div style={{ display: 'inline-block' }}>
                      {t('Show')}
                      <Form.Select
                        value={this.state.cdt.rows}
                        size="sm"
                        onChange={(e) => this.fetch({
                          search: this.state.cdt.search,
                          limit: e.target.value,
                          order: this.state.cdt.order,
                          dir: this.state.cdt.dir,
                          page: this.state.cdt.page
                        })}
                        style={{ width: 70, marginInline: 5, display: 'inline-block' }}>
                        {this.state.cdt.rowsOptions.map((row, row_index) => (<option key={row_index} >{row}</option>))}
                      </Form.Select>
                      {t('Records')}
                    </div>
                  </Col>
                  <Col md={6} className='d-flex justify-content-end'>
                    <div className='cdt-search'>
                      <input type='text'
                        ref={this.searchFieldRef}
                        defaultValue={this.state.cdt.search}
                        onChange={(e) => this.fetch({
                          search: e.target.value,
                          limit: this.state.cdt.rows,
                          order: this.state.cdt.order,
                          dir: this.state.cdt.dir,
                          page: 0
                        })}
                        placeholder={t('Search...')} />
                      <button
                        onClick={() => {
                          this.fetch({
                            search: '',
                            limit: this.state.cdt.rows,
                            order: this.state.cdt.order,
                            dir: this.state.cdt.dir,
                            page: 0
                          });
                          this.searchFieldRef.current.value = '';
                        }}>
                        <FontAwesomeIcon
                          icon={this.state.cdt.search == '' ? faSearch : faXmark}
                          color={brand_colors[this.props.defaultTheme.theme_id].color18}
                        />
                      </button>
                    </div>
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body style={{ paddingTop: 8 }}>
                <Row>
                  {this.state.cdt.fetching &&
                    <Col md={12} className='py-5 d-flex flex-column align-items-center'>
                      <Spinner as='span' animation='grow' size='sm' /> {t('Loading Data')}
                    </Col>
                  }
                  {!this.state.cdt.fetching && this.state.cdt.data.length === 0 &&
                    <Col md={12} className='py-5 d-flex flex-column align-items-center'>
                      {t('No Data Found')}
                    </Col>
                  }
                  {!this.state.cdt.fetching && this.state.cdt.data.length !== 0 &&
                    <Col md={12} className='pt-2'>
                      <div id="datatable-list" className='mb-3' style={{ width: 'initial' }}>
                        <Table responsive>
                          {renderTableHead(this)}
                          {renderTableBody(this)}
                        </Table>
                      </div>
                    </Col>
                  }
                </Row>
                <Pagination
                  pageCount={this.state.cdt.lastPage || 1}
                  forcePage={this.state.cdt.page}
                  callbackParams={{
                    search: this.state.cdt.search,
                    limit: this.state.cdt.rows,
                    order: this.state.cdt.order,
                    dir: this.state.cdt.dir,
                  }}
                  callback={this.fetch}
                />
              </Card.Body>
            </Card>
          </div>
        </div>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  authData: state.auth.authData,
  selectedClass: state.selectedClass.data,
  defaultLanguage: state.language.defaultLanguage,
  defaultTheme: state.theme.defaultTheme,
});

export default connect(mapStateToProps, null)(ClassesAnalysisReport);
