import React, { Component } from 'react';
import { Container, Card, Table, Row, Col, Spinner, Image, Badge, Button, Form, Offcanvas, Accordion, Modal } from 'react-bootstrap';
import { t } from '../../../helpers/translation_helper';
import { connect } from 'react-redux';
import axios from 'axios';
import { toast } from 'react-toastify';
import Select from 'react-select';
import { DateRangePicker } from 'react-date-range';
import { faChevronUp, faChevronDown, faTrash, faPencil, } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { brand_colors } from '../../../helpers/brand_colors_helper';
import moment from 'moment';
import { handleFormErrors } from '../../../helpers/form_helpers';
import { GET, POST } from '../../../api';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from 'ckeditor5-custom-build/build/ckeditor';

// API URL
const apiURL = process.env.REACT_APP_API_URL;

class AddEditAssessmentReport extends Component {
  state = {
    working: false,
    show: false,
    agegroups: [],
    isEditing: false,
    editingData: {},
    showDateRange: false,
    dateRange: { startDate: new Date(), endDate: new Date() },
    assrepheader_id: 0,
    assrepheader_name: '',
    assrepheader_from: '',
    assrepheader_to: '',
    agegroup_id: [],
    assrepheader_note: '',
  }

  componentDidMount() {
    this.getAgeGroup();
  }

  handleModal = (isEditing = this.state.isEditing, editingData = {}) => {
    this.resetForm();
    this.setState({
      show: true,
      isEditing,
    });
  }

  getAgeGroup = async () => {
    try {
      const res = await GET('student-tracker/get-active-ab-age-groups');
      if (res) {
        this.setState({
          agegroups: res.data.map((item) => {
            return {
              ...item,
              value: item.agegroup_id,
              label: item.agegroup_code
            }
          })
        });
      }
    } catch (err) {
      console.log('err', err)
    }
  }

  saveAssrepHeader = async () => {

    const { authData } = this.props;
    const { auth_key } = authData.loginData;
    const { center_id } = authData.centerData;
    const { assrepheader_id, assrepheader_name, assrepheader_from, assrepheader_to, agegroup_id, assrepheader_note, isEditing } = this.state;

    if (!assrepheader_name) {
      toast.error(t('Name is required!'));
      return;
    }

    if (!agegroup_id) {
      toast.error(t('Please select grade!'));
      return;
    }

    this.setState({ working: true });

    const fData = new FormData();
    fData.append('center_id', center_id);
    fData.append('assrepheader_id', assrepheader_id);
    fData.append('assrepheader_name', assrepheader_name);
    fData.append('assrepheader_from', moment(assrepheader_from, 'DD-MM-YYYY').format('YYYY-MM-DD'));
    fData.append('assrepheader_to', moment(assrepheader_to, 'DD-MM-YYYY').format('YYYY-MM-DD'));

    if (isEditing) {
      fData.append('agegroup_id', agegroup_id.value);
    } else {
      fData.append('agegroup_id', agegroup_id.map(item => item.value));
    }

    fData.append('assrepheader_note', assrepheader_note);

    try {
      const res = await POST('student-tracker/save-assrep-header', fData, {
      });

      if (res.data.type) {
        // this.setState({ show: false });
        this.props.callBack();
        toast.success(t('Saved successfully!'));
        this.resetForm();
      } else {
        handleFormErrors(res.data.data);
      }

      this.setState({ working: false });

    } catch (err) {
      console.log('err', err)

      this.setState({ working: false });
      toast.error(t('Something went wrong!'));
    }

  }

  edit = async (data) => {

    this.setState({
      isEditing: true,
      assrepheader_id: data.assrepheader_id,
      assrepheader_name: data.assrepheader_name,
      assrepheader_from: moment(data.assrepheader_from).format('DD-MM-YYYY'),
      assrepheader_to: moment(data.assrepheader_to).format('DD-MM-YYYY'),
      agegroup_id: {
        value: data.agegroup_id,
        label: data.agegroup_code,
        agegroup_color: data.agegroup_color,
        ab_ids: data.ab_ids,
        ab_ids_data: data.ab_ids_data
      },
      dateRange: { startDate: new Date(data.assrepheader_from), endDate: new Date(data.assrepheader_to) },
      assrepheader_note: data.assrepheader_note || '',
    });
  }

  resetForm = () => {
    this.setState({
      showDateRange: false,
      assrepheader_id: 0,
      assrepheader_name: '',
      assrepheader_from: moment(new Date()).format('DD-MM-YYYY'),
      assrepheader_to: moment(new Date()).format('DD-MM-YYYY'),
      agegroup_id: null,
      assrepheader_note: '',
      isEditing: false,
    });
  }

  render() {

    const { authData } = this.props;
    const { auth_key } = authData.loginData;

    return (
      <Modal show={this.state.show} onHide={() => this.setState({ show: false })} size='xxl'>
        <Modal.Header style={{ display: 'block' }}>
          <Row>
            <Col>
              <Modal.Title>{this.state.isEditing ? t('Update Assessment Report') : t('Create Assessment Report')}</Modal.Title>
            </Col>
            <Col className='d-flex justify-content-end'>
              <Button className='me-2' variant="secondary" onClick={() => this.setState({ show: false, isEditing: false })}>
                {t('Close')}
              </Button>
              <Button disabled={this.state.working} variant={(!this.state.isEditing) ? "primary" : "warning"} onClick={() => this.saveAssrepHeader()}>
                {(!this.state.isEditing) ? t('Save') : t('Update')}
                {this.state.working && (
                  <>
                    {' '}
                    <Spinner
                      as='span'
                      animation='grow'
                      size='sm'
                      role='status'
                      aria-hidden='true'
                    />
                  </>
                )}
              </Button>
            </Col>
          </Row>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={4}>
              <Row>
                <Col md={12} className='mb-3'>
                  <Form.Label>{t('Name')}</Form.Label>
                  <Form.Control
                    type="text"
                    value={this.state.assrepheader_name}
                    onChange={(e) => this.setState({ assrepheader_name: e.target.value })}
                  />
                </Col>
                <Col md={12} className='position-relative mb-3'>
                  <Form.Label>{t('From / To Date')}</Form.Label>
                  <Form.Control
                    type="text"
                    readOnly
                    value={this.state.assrepheader_from + ' - ' + this.state.assrepheader_to}
                  />
                  <FontAwesomeIcon
                    icon={this.state.showDateRange ? faChevronUp : faChevronDown}
                    color={brand_colors[this.props.defaultTheme.theme_id].color18}
                    className="filter-arrow"
                    style={{ position: 'absolute', top: 43, right: 24, cursor: 'pointer' }}
                    onClick={() => this.setState({ showDateRange: !this.state.showDateRange })}
                  />
                  {this.state.showDateRange &&
                    <div className='date-range-box' style={{ background: '#fff', zIndex: 1 }}>
                      <DateRangePicker
                        ranges={[this.state.dateRange]}
                        onChange={(date) => {
                          this.setState({
                            dateRange: {
                              startDate: new Date(date.range1.startDate),
                              endDate: new Date(date.range1.endDate)
                            },
                            assrepheader_from: moment(new Date(date.range1.startDate)).format('DD-MM-YYYY'),
                            assrepheader_to: moment(new Date(date.range1.endDate)).format('DD-MM-YYYY'),
                          })
                        }}
                        style={{ backgroundColor: '#fff' }}
                      />
                    </div>
                  }
                </Col>
                <Col md={12} className='mb-3'>
                  <Form.Label>{t('Grade')}</Form.Label>
                  <Select
                    options={this.state.agegroups}
                    onChange={(obj) => this.setState({
                      agegroup_id: obj
                    })
                    }
                    isMulti={!this.state.isEditing}
                    value={this.state.agegroup_id}
                    menuPortalTarget={document.body}
                    styles={{ menuPortal: (base) => ({ ...base, zIndex: 99999999999999 }) }}
                    formatOptionLabel={option => (
                      <div>
                        <span className='badge badge-light-info me-1 mb-1'>{option.ab_name}</span>
                        <br />
                        <small style={{ fontWeight: 'bold', color: option.agegroup_color }} >{option.label}</small>
                      </div>
                    )}
                  />
                </Col>
              </Row>
            </Col>
            <Col md={8}>
              <Form.Label>{t('Note')}</Form.Label>
              <CKEditor
                config={{
                  ckfinder: {
                    uploadUrl: apiURL + '/authentication/ckeditor-upload?auth_key=' + auth_key
                  },
                  removePlugins: ['Title'],
                  toolbar: {
                    items: [
                      'heading',
                      '|',
                      'bold',
                      'italic',
                      'alignment',
                      '|',
                      'numberedList',
                      'bulletedList',
                      '|',
                      'indent',
                      'outdent',
                      '|',
                      'undo',
                      'redo'
                    ]
                  }
                }}
                style={{ height: 200 }}
                editor={Editor}
                data={this.state.assrepheader_note}
                onChange={(
                  event,
                  editor
                ) => {
                  const assrepheader_note = editor.getData();
                  this.setState({ assrepheader_note });
                }}
              />
            </Col>
          </Row>
          <hr />
          <Table size="sm">
            <thead>
              <tr>
                <th width='33%'>{t('Name')}</th>
                <th width='32%'>{t('Date Range')}</th>
                <th width='28%'>{t('Grade')}</th>
                <th width='7%'></th>
              </tr>
            </thead>
            <tbody>
              {this.props.getAssrepHeaderData.map((item, index) => {
                return <tr key={index}>
                  <td>{item.assrepheader_name}</td>
                  <td>{moment(item.assrepheader_from).format('DD-MM-YYYY')} - {moment(item.assrepheader_to).format('DD-MM-YYYY')}</td>
                  <td>
                    <div>
                      {item.ab_ids_data.map((v) => { return <span className='badge badge-light-primary me-1 mb-1'>{v.ab_name}</span> })}
                      <br />
                      <span style={{ color: item.agegroup_color }}>{item.agegroup_code}</span>
                    </div>
                  </td>
                  <td style={{ textAlign: 'right' }}>
                    {/* <Button variant='danger' size='sm' className='me-1'>
                      <FontAwesomeIcon color="#fff" icon={faTrash} />
                    </Button> */}
                    <Button onClick={() => this.edit(item)}
                      variant='warning' size='sm'>
                      <FontAwesomeIcon color="#fff" icon={faPencil} />
                    </Button>
                  </td>
                </tr>
              })}
            </tbody>
          </Table>
        </Modal.Body>
      </Modal >
    );
  }
}

export default AddEditAssessmentReport;