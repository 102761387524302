import React, { Component } from 'react';
import { Container, Card, Row, Col, ButtonGroup, Button, DropdownButton, Dropdown, Form, Modal, Spinner, Table, InputGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faCheckCircle, faSearch, faUser, faXmark, faLayerGroup, faHandsHoldingChild, faPeopleGroup, faListCheck, faPrint, faPencil, faTrash, faCheckDouble, faSortDown, faSortUp, faThumbsUp, faThumbsDown, faL, faChevronUp, faChevronDown, faTimes } from '@fortawesome/free-solid-svg-icons';
import { faCalendar, faEye, faEyeSlash, faFileExcel, faFilePdf } from '@fortawesome/free-regular-svg-icons';
import { t } from '../../helpers/translation_helper';
import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';
import Select from 'react-select';
import NavBar from '../_partials/NavBar/_NavBar';
import Header from '../_partials/Header/_Header';
import { connect } from 'react-redux';
import moment from 'moment';
import { brand_colors } from '../../helpers/brand_colors_helper';
import { GET, POST } from '../../api';
import { toast } from 'react-toastify';
import { handleFormErrors } from '../../helpers/form_helpers';
import { DateRangePicker } from 'react-date-range';
import axios from 'axios';
import Pagination from '../_partials/Pagination';
import { renderTableBody, renderTableHead } from '../../helpers/table_helper';
var Chance = require('chance');
var chance = new Chance();
let cancelToken;

class Terms extends Component {

  searchFieldRef = React.createRef();

  defaultFormFields = {
    term_id: 0,
    term_name: '',
    term_daterange: { startDate: new Date(), endDate: new Date(), key: 'selection' },
    term_from: moment().format('YYYY-MM-DD'),
    term_to: moment().format('YYYY-MM-DD'),
    centerlic_id: '',
    term_months: 1,
    term_d_id: '',
    term_fee_type: { value: 'none', label: 'None' },
    withdrawal_fee_type: { value: 'none', label: 'None' },
    term_policy: '',
    withdrawal_policy: '',
    RegistrationData: [],
    WithdrawalData: [],
  }

  state = {
    ...this.defaultFormFields,
    working: false,
    isEditing: false,
    centerlicYears: [],
    defaultTerms: [],
    termPolicies: [],
    termPoliciesWithdrawal: [],
    policiesOptions: [
      { value: 'none', label: 'None' },
      { value: 'prorated', label: 'Prorated' },
      { value: 'policy', label: 'Policy' },
    ],
    cdt: {
      fetching: false,
      search: '',
      rows: 5,
      offset: 0,
      order: 'terms.term_id',
      dir: 'DESC',
      lastPage: 0,
      page: 0,
      totalRecords: 0,
      rowsOptions: [5, 10, 25, 50, 'All'],
      columns: [
        {
          id: 'terms.term_id',
          column: 'term_id',
          name: 'Actions',
          visibility: true
        },
        {
          id: 'terms.term_name',
          column: 'term_name',
          name: 'Name',
          visibility: true
        },
        {
          id: 'terms.term_from',
          column: 'term_from',
          name: 'From',
          visibility: true
        },
        {
          id: 'terms.term_to',
          column: 'term_to',
          name: 'To',
          visibility: true
        },
        {
          id: 'centerlic.reg_year',
          column: 'reg_year',
          name: 'Year',
          visibility: true
        },
        {
          id: 'terms.term_months',
          column: 'term_months',
          name: 'Months',
          visibility: true
        },
      ],
      data: [],
    },
    showFormModal: false,
    showDateRange: false,
  }

  componentDidMount() {
    const {
      search,
      rows: limit,
      order,
      dir
    } = this.state.cdt;
    this.fetch({
      search, limit, order, dir, page: 0
    });
    this.getSetDDLs();
  }

  getSetDDLs = async () => {

    try {
      const response = await axios.all([
        GET('terms/get-centerlic-years'),
        GET('terms/get-terms-default'),
        GET('terms/get-term-policies'),
        GET('terms/get-term-policies-withdrawal'),
      ]);

      // Centerlic Years
      const centerlicYears = response[0].data.map((item) => {
        return {
          value: item.centerlic_id,
          label: item.reg_year,
        }
      });

      // Default Terms
      const defaultTerms = response[1].data.map((item) => {
        return {
          value: item.term_d_id,
          label: item.term_d_name,
        }
      });

      // Term Policies
      const termPolicies = response[2].data.map((item) => {
        return {
          value: item.tp_id,
          label: item.tp_name,
        }
      });

      // Term Policies Withdrawal
      const termPoliciesWithdrawal = response[3].data.map((item) => {
        return {
          value: item.tp_id,
          label: item.tp_name,
        }
      });


      this.setState({
        centerlicYears,
        defaultTerms,
        termPolicies,
        termPoliciesWithdrawal,
      });

    } catch (err) {
      console.log('err', err)

    }


  }

  fetch = async (params) => {

    this.setState({
      cdt: {
        ...this.state.cdt,
        fetching: true,
      }
    });

    if (cancelToken) {
      cancelToken.abort();
    }
    cancelToken = new AbortController();

    try {
      const res = await GET('terms/get', {
        signal: cancelToken.signal,
        params: {
          search: params.search,
          order: params.order,
          dir: params.dir,
          limit: params.limit,
          offset: parseInt(params.page) * parseInt(params.limit)
        },
      });

      if (res.status === 200) {
        this.setState({
          cdt: {
            ...this.state.cdt,
            fetching: false,
            search: params.search,
            rows: parseInt(params.limit),
            offset: parseInt(params.page) * parseInt(params.limit),
            order: params.order,
            dir: params.dir,
            page: params.page,
            totalRecords: parseInt(res.data.total_count_filtered),
            lastPage: Math.ceil(parseInt(res.data.total_count_filtered) / parseInt(params.limit)),
            data: res.data.data,
          }
        });
      }

    } catch (err) {
      console.log('err', err)
      this.setState({
        cdt: {
          ...this.state.cdt,
          fetching: false,
        }
      });

    }
  }

  showHideColumn = (column_index) => {
    const columns = this.state.cdt.columns;
    columns[column_index].visibility = !columns[column_index].visibility;
    this.setState({ ctd: { ...this.state.ctd, columns } })
  }

  handleOpenFormModal = () => {
    this.setState({
      showFormModal: true
    });
  }

  handleCloseFormModal = () => {
    this.setState({
      showFormModal: false,
      isEditing: false,
      ...this.defaultFormFields
    });
  }

  saveUpdate = async () => {

    const { authData } = this.props;
    const { center_id } = authData.centerData;

    this.setState({ working: true });

    const {
      term_id,
      term_name,
      term_from,
      term_to,
      centerlic_id,
      term_months,
      term_d_id,
      term_fee_type,
      withdrawal_fee_type,
      RegistrationData,
      WithdrawalData,
    } = this.state;

    const fData = new FormData();
    fData.append('center_id', center_id);
    fData.append('term_id', term_id);
    fData.append('TermsData', JSON.stringify({
      term_name,
      term_from,
      term_to,
      centerlic_id: centerlic_id.value,
      term_year: centerlic_id.label,
      term_months,
      term_d_id: term_d_id.value,
      term_fee_type: term_fee_type.value,
      withdrawal_fee_type: withdrawal_fee_type.value,
    }));
    fData.append('TermLinkedPolicyData', JSON.stringify([
      ...RegistrationData,
      ...WithdrawalData
    ]));

    try {
      const res = await POST('terms/save', fData);
      if (res.data.type) {
        this.setState({ working: false });
        toast.success(t(res.data.message));
        this.fetch({
          search: '',
          limit: this.state.cdt.rows,
          order: this.state.cdt.order,
          dir: this.state.cdt.dir,
          page: 0,
        });
        this.handleCloseFormModal();
      } else {
        // toast.error(t(res.data.message));
        handleFormErrors(res.data.data)
        this.setState({ working: false });
      }
    } catch (err) {
      console.log('err', err)
      toast.error(t('Something went wrong!'));

      this.setState({ working: false });
    }

  }

  edit = async (data) => {
    try {
      const res = await GET('terms/get-term-linked-policy', {
        params: {
          term_id: data.term_id
        }
      });
      if (res) {
        console.log('data', data);
        this.setState({
          ...this.state,
          ...data,
          centerlic_id: { value: data.centerlic_id, label: data.reg_year },
          term_d_id: { value: data.term_d_id, label: data.term_d_name },
          term_fee_type: { value: data.term_fee_type, label: data.term_fee_type.charAt(0).toUpperCase() + data.term_fee_type.slice(1) },
          withdrawal_fee_type: { value: data.withdrawal_fee_type, label: data.withdrawal_fee_type.charAt(0).toUpperCase() + data.withdrawal_fee_type.slice(1) },
          isEditing: true,
          RegistrationData: res.data.filter(item => item.tlp_type === 'Registration').map((item) => {
            return {
              ...item,
              uuid: chance.guid()
            }
          }),
          WithdrawalData: res.data.filter(item => item.tlp_type === 'Withdrawal').map((item) => {
            return {
              ...item,
              uuid: chance.guid()
            }
          }),
          term_daterange: { startDate: new Date(data.term_from), endDate: new Date(data.term_to), key: 'selection' },
          showDateRange: false,
          showFormModal: true
        });
      } else {
        toast.error(t('Something went wrong!'));
      }
    } catch (err) {
      console.log('err', err)
      toast.error(t('Something went wrong!'));
    }
  }

  termActivate = async (term_id) => {
    const { authData } = this.props;
    const { user_id } = authData.loginData;
    const { center_id, center_timezone } = authData.centerData;

    const fData = new FormData();
    fData.append('center_id', center_id);
    fData.append('center_timezone', center_timezone);
    fData.append('user_id', user_id);
    fData.append('term_id', term_id);

    try {
      const res = await POST('terms/activate', fData);
      if (res.data.type) {
        toast.success(t(res.data.message));
        this.fetch({
          search: this.state.cdt.search,
          limit: this.state.cdt.rows,
          order: this.state.cdt.order,
          dir: this.state.cdt.dir,
          page: this.state.cdt.page
        });
      } else {
        toast.error(t(res.data.message));
      }
    } catch (err) {
      console.log('err', err)
      toast.error(t('Something went wrong!'));

    }

  }

  onChangePolicy = async (tp_id, tp_type) => {
    try {
      const res = await GET('terms/get-policy-details', {
        params: {
          tp_id
        }
      });

      const PolicyNewData = res.data.map((item) => {
        return {
          uuid: chance.guid(),
          tlp_id: item.tp_id,
          tlp_days_start: item.tpd_days_start,
          tlp_discount: item.tpd_discount,
          tlp_type: tp_type
        }
      });

      this.setState({
        [tp_type + 'Data']: [
          ...this.state[tp_type + 'Data'],
          ...PolicyNewData
        ]
      });
    } catch (err) {
      console.log('err', err)

    }
  }

  addTLPData = (tlp_type) => {
    this.setState({
      [tlp_type + 'Data']: [
        ...this.state[tlp_type + 'Data'],
        {
          uuid: chance.guid(),
          tlp_id: 0,
          tlp_days_start: '',
          tlp_discount: '',
          tlp_type
        }
      ]
    })
  }

  setTLPData = (key, uuid, value, tlp_type) => {
    let PolicyData = this.state[tlp_type + 'Data'];
    let index = PolicyData.findIndex(item => item.uuid === uuid);
    PolicyData[index][key] = value;
    this.setState({ [tlp_type + 'Data']: PolicyData });
  }

  deleteTLPData = (uuid, tlp_type) => {
    let PolicyData = this.state[tlp_type + 'Data'];
    let index = PolicyData.findIndex(item => item.uuid === uuid);
    PolicyData.splice(index, 1);
    this.setState({ [tlp_type + 'Data']: PolicyData });
  }

  exportExcel = async () => {

    const excelData = this.state.cdt.data.map((item) => {
      const row = {};
      this.state.cdt.columns
        .filter((column) => column.visibility)
        .map((column) => {
          if (column.name !== 'Actions') {
            row[column.name] = item[column.column];
          }
        });
      return row;
    });

    const fileName = 'Terms Export ' + moment().format('DD-MM-YYYY');
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const ws = XLSX.utils.json_to_sheet(excelData);

    const cellStyle = {
      font: {
        bold: true,
      },
    }

    Array.from('ABCDE').map((letter) => {
      ws[letter + "1"].s = cellStyle;
    });

    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });

    FileSaver.saveAs(data, fileName + fileExtension);
  }

  renderCell = (column_data, column_id, column_index, dataObj) => {
    const { authData } = this.props;
    const { center_id } = authData.centerData;

    let tdData;

    // --------------------------------------------
    if (column_id == 'term_id') { // Action Buttons
      tdData = <>
        <Button
          onClick={() => this.edit(dataObj)}
          variant="warning" size='sm' className='me-1'>
          <FontAwesomeIcon icon={faPencil} />
        </Button>
        <Button
          onClick={() => dataObj.term_active == 0 ? this.termActivate(column_data) : null}
          variant={dataObj.term_active == 1 ? 'success' : 'danger'} size='sm'>
          <FontAwesomeIcon icon={dataObj.term_active == 1 ? faThumbsUp : faThumbsDown} />
        </Button>
      </>;
      // ------------------------------------------------------------------------
    } else if (column_id == 'term_from' || column_id == 'term_to') { // From - To
      tdData = moment(column_data).format('DD-MM-YYYY');
      // ---
    } else {
      tdData = column_data;
    }


    return <td key={column_index}>{tdData}</td>;
  }

  render() {

    const days_count = Math.floor(moment.duration(moment(this.state.term_daterange.endDate).diff(moment(this.state.term_daterange.startDate))).asDays()) + 1;
    const { cdt } = this.state
    console.log('term', this.state);
    return (
      <div className='shadow-box'>
        <Card
          className='border-0'
          style={{ borderRadius: '20px' }}>
          <Card.Header style={{
            backgroundColor: '#fff',
            borderTopLeftRadius: '20px',
            borderTopRightRadius: '20px',
            fontWeight: '700'
          }}>
            <Row>
              <Col md={6} className='d-flex align-items-center'>
                {t('Periods')}
              </Col>
              <Col md={6} className='d-flex justify-content-end'>
                <div className='cdt-search'>
                  <input type='text'
                    ref={this.searchFieldRef}
                    defaultValue={this.state.cdt.search}
                    onChange={(e) => this.fetch({
                      search: e.target.value,
                      limit: this.state.cdt.rows,
                      order: this.state.cdt.order,
                      dir: this.state.cdt.dir,
                      page: 0
                    })}
                    placeholder={t('Search...')} />
                  <button
                    onClick={() => {
                      this.fetch({
                        search: '',
                        limit: this.state.cdt.rows,
                        order: this.state.cdt.order,
                        dir: this.state.cdt.dir,
                        page: 0
                      });
                      this.searchFieldRef.current.value = '';
                    }}>
                    <FontAwesomeIcon
                      icon={this.state.cdt.search == '' ? faSearch : faXmark}
                      color={brand_colors[this.props.defaultTheme.theme_id].color18}
                    />
                  </button>
                </div>
                <button
                  onClick={this.handleOpenFormModal}
                  className='btn btn-success btn-sm ms-3'><FontAwesomeIcon icon={faPlus} color={brand_colors[this.props.defaultTheme.theme_id].color8} style={{ fontSize: 12 }} /></button>
              </Col>
            </Row>
          </Card.Header>
          <Card.Body style={{ paddingTop: 8 }}>
            <Row>
              <Col md={6}>
                <ButtonGroup size='sm' className='me-3 float-left'>
                  {/* <Button><FontAwesomeIcon icon={faFilePdf} /> {t('PDF')}</Button> */}
                  <Button onClick={() => this.exportExcel()}>
                    <FontAwesomeIcon icon={faFileExcel} /> {t('Excel')}
                  </Button>
                  {/* <Button><FontAwesomeIcon icon={faPrint} /> {t('Print')}</Button> */}
                  <DropdownButton
                    autoClose={'outside'}
                    size='sm'
                    as={ButtonGroup}
                    title={t('Column Visibility')}>
                    {this.state.cdt.columns.map((column, column_index) => (
                      <Dropdown.Item key={column_index} className={column.visibility ? 'column-name-item' : 'column-name-item active'} onClick={() => this.showHideColumn(column_index)}>
                        <FontAwesomeIcon icon={column.visibility ? faEye : faEyeSlash} /> {column.name}
                      </Dropdown.Item>
                    ))}
                  </DropdownButton>
                </ButtonGroup>
              </Col>
              <Col md={6} className='d-flex justify-content-end'>
                <div style={{ display: 'inline-block' }}>
                  {t('Show')}
                  <Form.Select
                    value={this.state.cdt.rows}
                    size="sm"
                    onChange={(e) => this.fetch({
                      search: this.state.cdt.search,
                      limit: e.target.value,
                      order: this.state.cdt.order,
                      dir: this.state.cdt.dir,
                      page: this.state.cdt.page
                    })}
                    style={{ width: 70, marginInline: 5, display: 'inline-block' }}>
                    {this.state.cdt.rowsOptions.map((row, row_index) => (<option key={row_index} >{row}</option>))}
                  </Form.Select>
                  {t('Records')}
                </div>
              </Col>
              {this.state.cdt.fetching &&
                <Col md={12} className='py-5 d-flex flex-column align-items-center'>
                  <Spinner as='span' animation='grow' size='sm' /> {t('Loading Data')}
                </Col>
              }
              {!this.state.cdt.fetching && this.state.cdt.data.length === 0 &&
                <Col md={12} className='py-5 d-flex flex-column align-items-center'>
                  {t('No Data Found')}
                </Col>
              }
              {!this.state.cdt.fetching && this.state.cdt.data.length !== 0 &&
                <Col md={12} className='pt-2'>
                  <div id="datatable-list" className='mb-3' style={{ width: 'initial' }}>
                    <Table responsive>
                      {renderTableHead(this)}
                      {renderTableBody(this)}
                    </Table>
                  </div>
                </Col>
              }
            </Row>
            <Pagination
              pageCount={this.state.cdt.lastPage}
              forcePage={this.state.cdt.page}
              callbackParams={{
                search: this.state.cdt.search,
                limit: this.state.cdt.rows,
                order: this.state.cdt.order,
                dir: this.state.cdt.dir,
              }}
              callback={this.fetch}
            />
          </Card.Body>
        </Card>
        <Modal
         backdrop="static"
         keyboard={false}
          size='lg'
          show={this.state.showFormModal}
          onHide={this.handleCloseFormModal}>
          <Modal.Header closeButton>
            <Modal.Title>{this.state.isEditing ? t('Edit') : t('Add')} {t('Period')}</Modal.Title>
          </Modal.Header>
          <Modal.Body className='custom-styled-form'>
            <Row>
              <Col md={5}>
                <Form.Group className="mb-3">
                  <Form.Label>{t('Name')}</Form.Label>
                  <Form.Control type="text"
                    defaultValue={this.state.term_name}
                    onBlur={(e) => this.setState({ term_name: e.target.value })} />
                </Form.Group>
              </Col>
              <Col md={5} className='position-relative'>
                <Form.Group className="mb-3">
                  <Form.Label>{t('From - To')}</Form.Label>
                  <button
                    onClick={() => this.setState({
                      showDateRange: !this.state.showDateRange
                    })}
                    style={{
                      padding: '.375rem .75rem',
                      fontSize: '1rem',
                      color: '#212529',
                      border: '1px solid #ced4da',
                      borderRadius: '.375rem',
                      display: 'flex',
                      width: '100%',
                      justifyContent: 'space-between',
                      backgroundColor: '#ffffff'
                    }}>
                    <span>{moment(this.state.term_daterange.startDate).format('DD-MM-YYYY') + ' -- ' + moment(this.state.term_daterange.endDate).format('DD-MM-YYYY')}</span>
                    <span>
                      <FontAwesomeIcon
                        icon={this.state.showDateRange ? faTimes : faCalendar}
                        color={brand_colors[this.props.defaultTheme.theme_id].color18}
                        style={{ fontSize: 12 }}
                      />
                    </span>
                  </button>
                </Form.Group>
                {this.state.showDateRange &&
                  <div style={{
                    position: 'absolute',
                    top: 65,
                    left: '50%',
                    transform: 'translateX(-50%)',
                    border: '1px solid #ced4da',
                    borderRadius: '.375rem',
                    overflow: 'hidden',
                    zIndex: 8,
                    backgroundColor: '#fff'
                  }}>
                    <DateRangePicker
                      ranges={[this.state.term_daterange]}
                      onChange={(date) => {
                        this.setState({
                          term_daterange: {
                            startDate: new Date(date.selection.startDate),
                            endDate: new Date(date.selection.endDate),
                            key: 'selection'
                          },
                          term_from: moment(new Date(date.selection.startDate)).format('YYYY-MM-DD'),
                          term_to: moment(new Date(date.selection.endDate)).format('YYYY-MM-DD'),
                        })
                      }}
                      style={{ backgroundColor: '#fff' }}
                    />
                    <div style={{
                      padding: 5,
                      textAlign: 'right'
                    }}>
                      <Button
                        onClick={() => this.setState({
                          showDateRange: !this.state.showDateRange
                        })}
                        variant="danger">{t('Close')}</Button>
                    </div>
                  </div>
                }
              </Col>
              <Col md={2}>
                <div style={{
                  paddingTop: 30,
                  fontWeight: 700
                }} className='text-center'>{days_count} DAY{days_count > 1 && 'S'}</div>
              </Col>
              <Col md={5}>
                <Form.Group className='mb-3'>
                  <Form.Label>{t('Term Year')} <span className='text-danger'>*</span></Form.Label>
                  <Select
                    value={this.state.centerlic_id}
                    menuPortalTarget={document.body}
                    styles={{
                      menuPortal: (
                        base
                      ) => ({
                        ...base,
                        zIndex: 9999,
                      }),
                    }}
                    options={this.state.centerlicYears}
                    onChange={(obj) => this.setState({ centerlic_id: obj })}
                  />
                </Form.Group>
              </Col>
              <Col md={2}>
                <Form.Group className="mb-3">
                  <Form.Label>{t('Months')}</Form.Label>
                  <Form.Control type="number"
                    defaultValue={this.state.term_months}
                    min={1}
                    max={12}
                    onChange={(e) => this.setState({ term_months: e.target.value })} />
                </Form.Group>
              </Col>
              <Col md={5}>
                <Form.Group className='mb-3'>
                  <Form.Label>{t('Link To Default Term')} <span className='text-danger'>*</span></Form.Label>
                  <Select
                    value={this.state.term_d_id}
                    menuPortalTarget={document.body}
                    styles={{
                      menuPortal: (
                        base
                      ) => ({
                        ...base,
                        zIndex: 9999,
                      }),
                    }}
                    options={this.state.defaultTerms}
                    onChange={(obj) => this.setState({ term_d_id: obj })}
                  />
                </Form.Group>
              </Col>
              <Col md={12}><hr /></Col>
              <Col md={12}>
                <Row>
                  <Col><h5 className='mb-0'>{t('Term Policies')}</h5></Col>
                  {this.state.term_fee_type.value == 'policy' &&
                    <Col md='auto'>
                      <button
                        onClick={() => this.addTLPData('Registration')}
                        style={{ height: 20, lineHeight: '10px' }}
                        className='btn btn-success btn-sm'><FontAwesomeIcon icon={faPlus} color={brand_colors[this.props.defaultTheme.theme_id].color8} style={{ fontSize: 12 }} /></button>
                    </Col>
                  }
                </Row>
              </Col>
              <Col md={4}>
                <Form.Group className='mb-3'>
                  <Form.Label>{t('Term Policies')}</Form.Label>
                  <Select
                    value={this.state.term_fee_type}
                    menuPortalTarget={document.body}
                    styles={{
                      menuPortal: (
                        base
                      ) => ({
                        ...base,
                        zIndex: 9999,
                      }),
                    }}
                    options={this.state.policiesOptions}
                    onChange={(obj) => this.setState({ term_fee_type: obj })}
                  />
                </Form.Group>
              </Col>
              {this.state.term_fee_type.value == 'policy' &&
                <>
                  <Col md={4}>
                    <Form.Group className='mb-3'>
                      <Form.Label>{t('Policy')}</Form.Label>
                      <Select
                        value={this.state.term_policy}
                        menuPortalTarget={document.body}
                        styles={{
                          menuPortal: (
                            base
                          ) => ({
                            ...base,
                            zIndex: 9999,
                          }),
                        }}
                        options={this.state.termPolicies}
                        onChange={(obj) => {
                          this.setState({ term_policy: obj });
                          this.onChangePolicy(obj.value, 'Registration');
                        }
                        }
                      />
                    </Form.Group>
                  </Col>
                  <Col md={12}>
                    <Table size='sm'>
                      <thead>
                        <tr>
                          <th width='50%'>{t('Days (Start)')}</th>
                          <th width='45%'>{t('Discount %')}</th>
                          <th width='5%'></th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.RegistrationData.length > 0 && this.state.RegistrationData
                          .map((item, index) => (
                            <tr key={index}>
                              <td>
                                <Form.Control
                                  size="sm"
                                  type="text"
                                  value={this.state.RegistrationData[index].tlp_days_start}
                                  onChange={(e) => this.setTLPData('tlp_days_start', item.uuid, e.target.value, 'Registration')}
                                />
                              </td>
                              <td>
                                <Form.Control
                                  size="sm"
                                  type="text"
                                  value={this.state.RegistrationData[index].tlp_discount}
                                  onChange={(e) => this.setTLPData('tlp_discount', item.uuid, e.target.value, 'Registration')}
                                />
                              </td>
                              <td align='right'>
                                <button
                                  onClick={() => this.deleteTLPData(item.uuid, 'Registration')}
                                  style={{ width: 20, height: 20, lineHeight: '10px', padding: 0 }}
                                  className='btn btn-danger btn-sm'><FontAwesomeIcon icon={faTrash} color={brand_colors[this.props.defaultTheme.theme_id].color8} style={{ fontSize: 12 }} /></button>
                              </td>
                            </tr>
                          ))
                        }
                      </tbody>
                    </Table>
                  </Col>
                </>
              }
              <Col md={12}><hr /></Col>
              <Col md={12}>
                <Row>
                  <Col><h5 className='mb-0'>{t('Withdrawal Policies')}</h5></Col>
                  {this.state.withdrawal_fee_type.value == 'policy' &&
                    <Col md='auto'>
                      <button
                        onClick={() => this.addTLPData('Withdrawal')}
                        style={{ height: 20, lineHeight: '10px' }}
                        className='btn btn-success btn-sm'><FontAwesomeIcon icon={faPlus} color={brand_colors[this.props.defaultTheme.theme_id].color8} style={{ fontSize: 12 }} /></button>
                    </Col>
                  }
                </Row>
              </Col>
              <Col md={4}>
                <Form.Group className='mb-3'>
                  <Form.Label>{t('Withdrawal Policies')}</Form.Label>
                  <Select
                    value={this.state.withdrawal_fee_type}
                    menuPortalTarget={document.body}
                    styles={{
                      menuPortal: (
                        base
                      ) => ({
                        ...base,
                        zIndex: 9999,
                      }),
                    }}
                    options={this.state.policiesOptions}
                    onChange={(obj) => this.setState({ withdrawal_fee_type: obj })}
                  />
                </Form.Group>
              </Col>
              {this.state.withdrawal_fee_type.value == 'policy' &&
                <>
                  <Col md={4}>
                    <Form.Group className='mb-3'>
                      <Form.Label>{t('Withdrawal Policy')}</Form.Label>
                      <Select
                        value={this.state.withdrawal_policy}
                        menuPortalTarget={document.body}
                        styles={{
                          menuPortal: (
                            base
                          ) => ({
                            ...base,
                            zIndex: 9999,
                          }),
                        }}
                        options={this.state.termPoliciesWithdrawal}
                        onChange={(obj) => {
                          this.setState({ withdrawal_policy: obj });
                          this.onChangePolicy(obj.value, 'Withdrawal');
                        }
                        }
                      />
                    </Form.Group>
                  </Col>
                  <Col md={12}>
                    <Table size='sm'>
                      <thead>
                        <tr>
                          <th width='50%'>{t('Days (Remaining)')}</th>
                          <th width='45%'>{t('Discount %')}</th>
                          <th width='5%'></th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.WithdrawalData.length > 0 && this.state.WithdrawalData
                          .map((item, index) => (
                            <tr key={index}>
                              <td>
                                <Form.Control
                                  size="sm"
                                  type="text"
                                  value={this.state.WithdrawalData[index].tlp_days_start}
                                  onChange={(e) => this.setTLPData('tlp_days_start', item.uuid, e.target.value, 'Withdrawal')}
                                />
                              </td>
                              <td>
                                <Form.Control
                                  size="sm"
                                  type="text"
                                  value={this.state.WithdrawalData[index].tlp_discount}
                                  onChange={(e) => this.setTLPData('tlp_discount', item.uuid, e.target.value, 'Withdrawal')}
                                />
                              </td>
                              <td align='right'>
                                <button
                                  onClick={() => this.deleteTLPData(item.uuid, 'Withdrawal')}
                                  style={{ width: 20, height: 20, lineHeight: '10px', padding: 0 }}
                                  className='btn btn-danger btn-sm'><FontAwesomeIcon icon={faTrash} color={brand_colors[this.props.defaultTheme.theme_id].color8} style={{ fontSize: 12 }} /></button>
                              </td>
                            </tr>
                          ))
                        }
                      </tbody>
                    </Table>
                  </Col>
                </>
              }
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleCloseFormModal}>
              {t('Close')}
            </Button>
            <Button disabled={this.state.working} variant='success' onClick={() => this.saveUpdate()}>
              {t('Save')} {this.state.working ? <Spinner as='span' animation='grow' size='sm' role='status' aria-hidden='true' /> : ''}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  authData: state.auth.authData,
  defaultLanguage: state.language.defaultLanguage,
  defaultTheme: state.theme.defaultTheme,
});

export default connect(mapStateToProps, null)(Terms);
