/**
 * Themes Slice
 */
import { createSlice } from '@reduxjs/toolkit';
export const preferencesSlice = createSlice({
    name: 'preferences',
    initialState: {
        child_name_column: 0,
        concat_family_name: 0,
    },
    reducers: {
        CHANGE_CHILD_NAME_COLUMN: (state, action) => {
            state.child_name_column = (state.child_name_column == 1) ? 0 : 1
        },
        CHANGE_CONCAT_FAMILYNAME: (state, action) => {
            state.concat_family_name = (state.concat_family_name == 1) ? 0 : 1
        },
    },
});

export const { CHANGE_CHILD_NAME_COLUMN, CHANGE_CONCAT_FAMILYNAME } = preferencesSlice.actions;
export default preferencesSlice.reducer;
