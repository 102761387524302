import React, { Component } from 'react';
import { Container, Card, Row, Col, Button, Badge, Modal, Form, Spinner, Offcanvas, Alert, Table } from 'react-bootstrap';
import { t } from '../../../helpers/translation_helper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faHomeUser, faPencil, faPlus, faArrowAltCircleLeft, faFilePdf, faTimesCircle, faCheck, faBan, faTrash, faUserGraduate, faFileMedical, faScaleBalanced, faFileLines, faTable, faFileInvoiceDollar, faAsterisk, faFolder, faUserGroup, faCar, faUser, faArrowUpRightFromSquare, faPhone, faLink } from '@fortawesome/free-solid-svg-icons';
import { faCircle, faCheckCircle, faCircleDot, faComments } from '@fortawesome/free-regular-svg-icons';
import Select from 'react-select';
import { connect } from 'react-redux';
import { brand_colors } from '../../../helpers/brand_colors_helper';
import { toast } from 'react-toastify';
import { setAddEditParentModal } from '../../../redux/slices/addEditParentModalSlice';
import { setParents, setParent, setAddEditStudentModal } from '../../../redux/slices/addEditStudentModalSlice';
import { getStudents } from '../../../redux/slices/studentListSlice';
import { getParents } from '../../../redux/slices/parentsListSlice';
import { handleFormErrors } from '../../../helpers/form_helpers';
import Flatpickr from 'react-flatpickr';

import axios from 'axios';
import moment from 'moment';
import { isTeacher } from '../../../helpers/general_helpers';
import { GET, POST } from '../../../api';
var Chance = require('chance');
var chance = new Chance();

const apiURL = process.env.REACT_APP_API_URL;
const portalURL = process.env.REACT_APP_PORTAL_URL;


class AddEditParent extends Component {

  person_picture = React.createRef();
  person_document = React.createRef();
  attachments_parent_child_temp = React.createRef();

  formSections = [
    {
      uid: chance.guid(),
      active: true,
      name: 'Parent Details',
      icon: faHomeUser
    },
    {
      uid: chance.guid(),
      active: true,
      name: 'Other Contact',
      icon: faUser
    },
    {
      uid: chance.guid(),
      active: true,
      name: 'Legal',
      icon: faScaleBalanced
    },
    {
      uid: chance.guid(),
      active: true,
      name: 'Family',
      icon: faUserGroup
    },
    {
      uid: chance.guid(),
      active: true,
      name: 'Pickup Persons',
      icon: faCar
    },
    {
      uid: chance.guid(),
      active: true,
      name: 'Documents',
      icon: faFolder
    },
  ];

  state = {
    firstLoad: true,
    formSections: this.formSections,
    formSectionActive: {
      parent: this.formSections[0].uid,
    },
    // Modal
    modal: {
      config: {
        name: '',
        size: '',
        show: false,
        getDataFromKey: '',
        setDataToKey: '',
      },
      initialData: {},
      pickupPersonData: {
        child_pickup_id: 0,
        person_pickup: '',
        person_contact: '',
        person_picture: '',
        user_name: t('Auto Generated'),
        password: t('Auto Generated'),
        person_picture_prev: '',
        person_document: '',
        person_document_prev: '',
        person_relation: '',
        approved: 1
      },
    },
    // DDLs
    centerRoles: [],
    chapters: [],
    countries: [],
    fatherPositions: [],
    motherPositions: [],
    parentRoles: [
      { value: 'Father', label: 'Father' },
      { value: 'Mother', label: 'Mother' },
      { value: 'Guardian', label: 'Guardian' },
      { value: 'Other', label: 'Other' },
    ],
    relationshipStatuses: [
      { value: 'Married', label: 'Married' },
      { value: 'Widowed', label: 'Widowed' },
      { value: 'Divorced', label: 'Divorced' },
      { value: 'Single', label: 'Single' },
    ],
    // Parent Details
    parent_name: '',
    parent_name_2: '',
    phone1: '',
    email1: '',
    id_number: '',
    country_id: '',
    parent_role: '',
    parent_relationship_status: '',
    crole_id: '',
    parent_status: 1,
    usemail: 1,
    useapps: 1,
    user_name: '',
    user_pass: '',
    chapt_id: '',
    account_num: '',
    qb_account: '',
    // Other Contact
    phone2: '',
    email2: '',
    address: '',
    parent_education_level: '',
    position_id_father: '',
    employer_father: '',
    grandfather: '',
    grandfather_2: '',
    spouse_role: '',
    spouse_name: '',
    spouse_phone: '',
    spouse_email: '',
    spouse_id_number: '',
    spouse_country: '',
    position_id_mother: '',
    Parents_employer_mother: '',
    spouse_education_level: '',
    emergency_contact_name: '',
    emergency_phone1: '',
    emergency_phone2: '',
    // Legal
    passport_number: '',
    passport_validity: '',
    sponsor_name: '',
    residency_number: '',
    residency_validity: '',
    // Family
    children: [],
    // Pickup Person
    pickupPersons: [],
    attachments_parent_child: [],
    attachments_parent_child_temp: { prev: '', data: '' },
    documentTypes: {
      selected: null,
      data: [
        { label: 'Father Passport', value: 'father_passport' },
        { label: 'Father Visa', value: 'father_visa' },
        { label: 'Father ID 1', value: 'father_id_1' },
        { label: 'Father ID 2', value: 'father_id_2' },
        { label: 'Mother Passport', value: 'mother_passport' },
        { label: 'Mother Visa', value: 'mother_visa' },
        { label: 'Mother ID 1', value: 'mother_id_1' },
        { label: 'Mother ID 2', value: 'mother_id_2' },
      ]
    }
  }

  componentDidMount() {
    // this.getSetDDLs();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.parent_id !== this.props.parent_id && this.props.parent_id !== 0) {
      if (this.state.firstLoad) {
        this.setState({ firstLoad: false }, () => this.getSetDDLs())
      } else {
        this.edit();
      }
    } else if (this.props.addEditParentModal && this.state.firstLoad) {
      this.setState({ firstLoad: false }, () => this.getSetDDLs())
    }

    if (this.props.showModal !== prevProps.showModal && !this.props.isEditing && this.props.showModal) {

      console.log('New Parent!!!');

      let default_country = this.state.countries[0];

      if (this.props.authData.centerData.country_id) {
        default_country = this.state.countries.find(item => item.value == this.props.authData.centerData.country_id);
      }

      this.setState({
        chapt_id: this.state.chapters[0],
        country_id: default_country,
        parent_role: this.state.parentRoles[0],
        parent_relationship_status: this.state.relationshipStatuses[0],
        // crole_id: this.state.centerRoles[0],
      });
    }
  }

  getStudents = () => {
    this.props.getStudents({});
  }

  getSetDDLs = async () => {
    try {
      const response = await axios.all([
        GET('parents/get-center-roles'),
        GET('parents/get-countries'),
        GET('parents/get-chapters'),
        GET('parents/get-father-position'),
        GET('parents/get-mother-position'),
      ]);

      // Center Roles
      const centerRoles = response[0].data.map((item) => {
        return {
          value: item.crole_id,
          label: item.crole_name,
        }
      });

      // Countries
      const countries = response[1].data.map((item) => {
        return {
          value: item.country_id,
          label: item.name_en,
        }
      });

      // Chapters
      const chapters = response[2].data.map((item) => {
        return {
          value: item.chapt_id,
          label: item.chapt_desc,
        }
      });


      // Father Positions
      const fatherPositions = response[3].data.map((item) => {
        return {
          value: item.position_id,
          label: item.position_name,
        }
      });

      // Mother Positions
      const motherPositions = response[4].data.map((item) => {
        return {
          value: item.positionm_id,
          label: item.positionm_name,
        }
      });

      let default_country = countries[0];

      if (this.props.authData.centerData.country_id) {
        default_country = countries.find(item => item.value == this.props.authData.centerData.country_id);
      }

      this.setState({
        centerRoles,
        countries,
        chapters,
        fatherPositions,
        motherPositions,
        chapt_id: chapters[0],
        country_id: default_country,
        parent_role: this.state.parentRoles[0],
        parent_relationship_status: this.state.relationshipStatuses[0],
        // crole_id: centerRoles[0],
      }, () => {
        // AHMAD
        this.edit();
      });

    } catch (err) {
      console.log('err', err)

    }

  }

  changeToFromSection = (parent, child) => {
    this.setState(
      {
        formSectionActive: { parent: parent, child: child },
      }
    );
  };

  handleClose = () => {
    this.props.setAddEditParentModal({ showModal: false, parent_id: 0, isEditing: false });
    this.setState({
      formSections: this.formSections,
      formSectionActive: {
        parent: this.formSections[0].uid,
      },
      // Student Details
      parent_name: '',
      parent_name_2: '',
      phone1: '',
      email1: '',
      id_number: '',
      country_id: '',
      parent_role: '',
      parent_relationship_status: '',
      crole_id: '',
      parent_status: 1,
      usemail: 1,
      useapps: 1,
      user_name: '',
      user_pass: '',
      chapt_id: '',
      account_num: '',
      qb_account: '',
      // Other Contact
      phone2: '',
      email2: '',
      address: '',
      parent_education_level: '',
      position_id_father: '',
      employer_father: '',
      grandfather: '',
      grandfather_2: '',
      spouse_role: '',
      spouse_name: '',
      spouse_phone: '',
      spouse_email: '',
      spouse_id_number: '',
      spouse_country: '',
      position_id_mother: '',
      Parents_employer_mother: '',
      spouse_education_level: '',
      emergency_contact_name: '',
      emergency_phone1: '',
      emergency_phone2: '',
      // Legal
      passport_number: '',
      passport_validity: '',
      sponsor_name: '',
      residency_number: '',
      residency_validity: '',
      // Family
      children: [],
      // Pickup Person
      pickupPersons: [],
      attachments_parent_child: [],
      attachments_parent_child_temp: { prev: '', data: '' },
    });
  }

  handleOpenModal = (name, size, getDataFromKey, setDataToKey) => {
    this.setState({
      modal: {
        ...this.state.modal,
        config: {
          name,
          size,
          show: true,
          getDataFromKey,
          setDataToKey,
        },
        initialData: {
          ...this.state.modal[getDataFromKey]
        },
      }
    });
  }

  handleCloseModal = () => {
    this.setState({
      modal: {
        ...this.state.modal,
        config: {
          ...this.state.modal.config,
          show: false,
        },
        initialData: {},
        [this.state.modal.config.getDataFromKey]: {
          ...this.state.modal.initialData
        }
      }
    });
  }

  addModalItem = () => {

    let data = this.state.modal[this.state.modal.config.getDataFromKey];
    data.uuid = chance.guid();

    // If Health
    if (
      this.state.modal.config.name === 'Chronic Illness' ||
      this.state.modal.config.name === 'Allergy' ||
      this.state.modal.config.name === 'Illness' ||
      this.state.modal.config.name === 'Condition' ||
      this.state.modal.config.name === 'Vaccination'
    ) {
      data.child_health_type = this.state.modal.config.name;
    }

    let list = [...this.state[this.state.modal.config.setDataToKey]];
    list.push(data);
    this.setState({ [this.state.modal.config.setDataToKey]: list });

    this.handleCloseModal();
  }

  deleteModalItem = (listName, uuid) => {
    const list = this.state[listName];
    const index = list.findIndex(item => item.uuid === uuid);
    list.splice(index, 1);
    this.setState({ [listName]: list });
  }


  previewFile = (e) => {
    const reader = new FileReader();
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      reader.readAsDataURL(selectedFile);
    }
    if (!selectedFile.type.includes('image') && !selectedFile.type.includes('pdf')) {
      toast.error(t('Only image or PDF file is allowed!'));
      return;
    }
    reader.onload = (readerEvent) => {
      this.setState({
        [e.target.name]: { prev: (e.target.files[0].type.includes('pdf')) ? 'pdf' : readerEvent.target.result, data: e.target.files[0] },
      })
    }
  };

  previewFileModal = (modal) => (e) => {

    const reader = new FileReader();

    const selectedFile = e.target.files[0];

    if (selectedFile) {
      reader.readAsDataURL(selectedFile);
    }

    if (
      !selectedFile.type.includes('image') &&
      !selectedFile.type.includes('pdf')
    ) {
      toast.error(t('Only image or PDF file is allowed!'));
      return;
    }

    reader.onload = (readerEvent) => {
      this.setState({
        modal: {
          ...this.state.modal,
          [modal]: {
            ...this.state.modal[modal],
            [e.target.name]: e.target.files[0],
            [e.target.name + '_prev']: selectedFile.type.includes('image') ? readerEvent.target.result : 'pdf',
          }
        }
      });
    };
  };

  changePickupPersonStatus = (index) => {
    const { pickupPersons } = this.state;
    pickupPersons[index].approved = pickupPersons[index].approved == 1 ? 0 : 1;
    this.setState({ pickupPersons });
  }

  saveUpdate = async () => {

    this.setState({ working: true });

    const { authData } = this.props;
    const { auth_key } = authData.loginData;
    const { center_id, center_timezone } = authData.centerData;

    const {
      // Parent Details
      parent_name,
      parent_name_2,
      phone1,
      email1,
      id_number,
      country_id,
      parent_role,
      parent_relationship_status,
      crole_id,
      parent_status,
      usemail,
      useapps,
      user_name,
      user_pass,
      chapt_id,
      account_num,
      qb_account,
      // Other Contact
      phone2,
      email2,
      address,
      parent_education_level,
      position_id_father,
      employer_father,
      grandfather,
      grandfather_2,
      spouse_role,
      spouse_name,
      spouse_phone,
      spouse_email,
      spouse_id_number,
      spouse_country,
      position_id_mother,
      Parents_employer_mother,
      spouse_education_level,
      emergency_contact_name,
      emergency_phone1,
      emergency_phone2,
      // Legal
      passport_number,
      passport_validity,
      sponsor_name,
      residency_number,
      residency_validity,
      // Pickup Persons
      pickupPersons,
      attachments_parent_child
    } = this.state;

    const fData = new FormData();

    fData.append('center_id', center_id);
    fData.append('parent_id', this.props.parent_id);

    const data = {
      // Parent Details
      parent_name,
      parent_name_2,
      phone1,
      email1,
      id_number,
      country_id: country_id.value,
      parent_role: parent_role.value,
      parent_relationship_status: parent_relationship_status.value,
      crole_id: crole_id ? crole_id.value : null,
      parent_status,
      usemail,
      useapps,
      user_name,
      user_pass,
      chapt_id: chapt_id.value,
      account_num,
      qb_account,
      // Other Contact
      phone2,
      email2,
      address,
      parent_education_level,
      position_id_father: position_id_father.value,
      employer_father,
      grandfather,
      grandfather_2,
      spouse_role: spouse_role.value,
      spouse_name,
      spouse_phone,
      spouse_email,
      spouse_id_number,
      spouse_country: spouse_country.value,
      position_id_mother: position_id_mother.value,
      Parents_employer_mother,
      spouse_education_level,
      emergency_contact_name,
      emergency_phone1,
      emergency_phone2,
      // Legal
      passport_number,
      passport_validity,
      sponsor_name,
      residency_number,
      residency_validity,
      // Pickup Persons
      Childpickup: pickupPersons.map((item) => {
        return {
          child_pickup_id: item.child_pickup_id,
          person_pickup: item.person_pickup,
          person_contact: item.person_contact,
          person_relation: item.person_relation,
          approved: item.approved
        }
      }),
      attachments_parent_child: attachments_parent_child.map((item) => {
        return {
          apc_attachment_type: item.apc_attachment_type,
          apc_id: item.apc_id,
        }
      })
    }

    pickupPersons.map((item, index) => {
      fData.append(`person_picture[${index}]`, item.person_picture);
      fData.append(`person_document[${index}]`, item.person_document);
    });


    attachments_parent_child.map((item, index) => {
      if (item.apc_attachment_name.type) {
        fData.append(`attachments_parent_child[${index}]`, item.apc_attachment_name, item.apc_attachment_name.name);
      }
    });

    fData.append('ParentData', JSON.stringify(data));

    try {
      const res = await POST('parents/save', fData);

      if (res.data.type) {
        this.setState({ working: false });
        toast.success(t(res.data.message));
        if (this.props.isEditing) {
          this.getStudents();
        } else {

          const newParent = {
            value: res.data.data.parent_id,
            label: res.data.data.parent_name
          }

          let parents = [...this.props.parents];
          parents.push(newParent);

          this.props.setParents(parents);
          this.props.setParent(newParent);
        }

        this.props.getParents({});
        this.handleClose();
      } else {
        // toast.error(t(res.data.message));
        handleFormErrors(res.data.data)
        this.setState({ working: false });
      }
    } catch (err) {
      console.log('err', err)
      toast.error(t('Something went wrong!'));

      this.setState({ working: false });
    }

  }

  edit = async () => {
    if (!this.props.parent_id || this.props.parent_id == 0) {
      return
    }
    const { authData } = this.props;
    const { auth_key } = authData.loginData;
    const { center_id } = authData.centerData;

    const request_params = {
      parent_id: this.props.parent_id,
    };

    try {
      const res = await GET('parents/edit', {
        params: request_params,
      });

      if (res.status == 200) {


        const {
          // Student Details
          parent_name,
          parent_name_2,
          phone1,
          email1,
          id_number,
          country_id,
          name_en,
          parent_role,
          parent_relationship_status,
          crole_id,
          crole_name,
          parent_status,
          usemail,
          useapps,
          user_name,
          user_pass,
          chapt_id,
          chapt_desc,
          account_num,
          qb_account,
          // Other Contact
          phone2,
          email2,
          address,
          parent_education_level,
          position_id_father,
          position_name,
          employer_father,
          grandfather,
          grandfather_2,
          spouse_role,
          spouse_name,
          spouse_phone,
          spouse_email,
          spouse_id_number,
          spouse_country,
          spouse_country_name,
          position_id_mother,
          positionm_name,
          Parents_employer_mother,
          spouse_education_level,
          emergency_contact_name,
          emergency_phone1,
          emergency_phone2,
          // Legal
          passport_number,
          passport_validity,
          sponsor_name,
          residency_number,
          residency_validity,
          // Family
          children,
          // Pickup Persons
          childpickup,
          // attachments_parent_child
          attachments_parent_child
        } = res.data

        const pickupPersons = childpickup.map((item) => {
          return {
            ...item,
            person_picture_prev: portalURL + center_id + '/childpickup/' + item.person_picture,
            person_document_prev: portalURL + center_id + '/childpickup/' + item.person_document,
          }
        });

        this.setState(
          {
            // Student Details
            parent_name,
            parent_name_2,
            phone1,
            email1,
            id_number,
            country_id: {
              value: country_id,
              label: name_en
            },
            parent_role: {
              value: parent_role,
              label: parent_role,
            },
            parent_relationship_status: {
              value: parent_relationship_status,
              label: parent_relationship_status
            },
            crole_id: crole_id ? {
              value: crole_id,
              label: crole_name
            } : null,
            parent_status,
            usemail,
            useapps,
            user_name,
            user_pass,
            chapt_id: {
              value: chapt_id,
              label: chapt_desc
            },
            account_num,
            qb_account,
            // Other Contact
            phone2,
            email2,
            address,
            parent_education_level,
            position_id_father: {
              value: position_id_father,
              label: position_name
            },
            employer_father,
            grandfather,
            grandfather_2,
            spouse_role: {
              value: spouse_role,
              label: spouse_role
            },
            spouse_name,
            spouse_phone,
            spouse_email,
            spouse_id_number,
            spouse_country: {
              value: spouse_country,
              label: spouse_country_name
            },
            position_id_mother: {
              label: positionm_name,
              value: position_id_mother,
            },
            Parents_employer_mother,
            spouse_education_level,
            emergency_contact_name,
            emergency_phone1,
            emergency_phone2,
            // Legal
            passport_number,
            passport_validity,
            sponsor_name,
            residency_number,
            residency_validity,
            // Family
            children,
            // Pickup Persons
            pickupPersons,
            attachments_parent_child
          }
        );

      }

    } catch (err) {
      console.log('err', err)
      toast.error(t('Something went wrong!'));

    }

  }

  render() {
    console.log('ADDEDITPARENT', this.state);
    return (
      <>
        <Offcanvas
          show={this.props.addEditParentModal}
          onHide={this.handleClose}
          placement='bottom'
          style={{ height: '100%' }}>
          <Offcanvas.Header id='iedu-header' style={{ marginTop: '1rem', marginRight: '1rem', marginLeft: '1rem', marginBottom: 0 }}>
            <div className='d-flex flex-row'>
              <span className='header-back-btn' onClick={this.handleClose} style={{ marginRight: 10 }}>
                <FontAwesomeIcon icon={faArrowAltCircleLeft} color={brand_colors[this.props.defaultTheme.theme_id].color9} />
              </span>
              <Offcanvas.Title>{this.props.isEditing ? t('Edit Parent') : t('Add Parent')} {this.props.isEditing && '(' + this.state.parent_name + ')'}</Offcanvas.Title>
            </div>
            <div>
              <Button disabled={this.state.working} variant='success' onClick={() => this.saveUpdate()}>
                {t('Save')} {this.state.working ? <Spinner as='span' animation='grow' size='sm' role='status' aria-hidden='true' /> : ''}
              </Button>
            </div>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <div className={'d-flex flex-row grey-section'} style={{
              backgroundImage: 'url(https://academic.educore.io/static/media/pegboard-bg.9b84e4936acef29bbcd9.png)',
              backgroundRepeat: 'repeat',
              backgroundPosition: '10px 12px',
              overflow: 'hidden',
            }}>
              <div style={{ backgroundColor: brand_colors[this.props.defaultTheme.theme_id].color8, marginRight: 10, borderRadius: '20px', width: '250px', height: '100%', position: 'sticky', padding: 20 }}>
                {this.state.formSections && this.state.formSections
                  .map((v, i) => {

                    var tab = <div key={i} className='d-flex flex-row align-items-center' style={{ cursor: 'pointer', borderRadius: 10, padding: 15, paddingBlock: 5, backgroundColor: v.uid == this.state.formSectionActive.parent ? brand_colors[this.props.defaultTheme.theme_id].color19 : brand_colors[this.props.defaultTheme.theme_id].color8, zIndex: 1, position: 'relative' }} onClick={() => this.changeToFromSection(v.uid, v.uid + '.1')}>
                      <div style={{ width: 20 }}>
                        <FontAwesomeIcon
                          icon={v.icon}
                          color={v.uid == this.state.formSectionActive.parent ? brand_colors[this.props.defaultTheme.theme_id].color20 : v.completed ? brand_colors[this.props.defaultTheme.theme_id].color4 : brand_colors[this.props.defaultTheme.theme_id].color9}
                          style={{
                            fontSize: 13,
                            position: 'relative',
                            top: -1
                          }}
                        />
                      </div>
                      <span style={{ fontSize: 18, fontWeight: '600', color: v.uid == this.state.formSectionActive.parent ? brand_colors[this.props.defaultTheme.theme_id].color20 : brand_colors[this.props.defaultTheme.theme_id].color9, opacity: v.uid == this.state.formSectionActive.parent ? 1 : 0.7 }}>{t(v.name)}</span>
                    </div>;

                    return tab;
                  })}
              </div>
              <Col className='custom-styled-form hide-scrollbar' style={{ backgroundColor: brand_colors[this.props.defaultTheme.theme_id].color8, marginLeft: 10, borderRadius: '20px', padding: '1rem', overflow: 'auto', height: '75vh' }}>
                {/* Parent Details */}
                {this.state.formSectionActive.parent == this.formSections[0].uid && (
                  <div id={`form_section_${this.formSections[0].uid}`}>
                    <h5>{t(this.formSections[0].name)}</h5>
                    <Row>
                      <Col md={3}>
                        <Form.Group className="mb-3">
                          <Form.Label>{t('Name')} <span className='text-danger'>*</span></Form.Label>
                          <Form.Control type="text"
                            defaultValue={this.state.parent_name}
                            onBlur={(e) => this.setState({ parent_name: e.target.value })} />
                        </Form.Group>
                      </Col>
                      <Col md={3}>
                        <Form.Group className="mb-3">
                          <Form.Label>{t('Name 2')}</Form.Label>
                          <Form.Control type="text"
                            defaultValue={this.state.parent_name_2}
                            onBlur={(e) => this.setState({ parent_name_2: e.target.value })} />
                        </Form.Group>
                      </Col>
                      <Col md={3}>
                        <Form.Group className="mb-3">
                          <Form.Label>{t('Phone 1')} <span className='text-danger'>*</span></Form.Label>
                          <Form.Control type="text"
                            defaultValue={this.state.phone1}
                            onBlur={(e) => this.setState({ phone1: e.target.value })} />
                        </Form.Group>
                      </Col>
                      <Col md={3}>
                        <Form.Group className="mb-3">
                          <Form.Label>{t('Email 1')} <span className='text-danger'>*</span></Form.Label>
                          <Form.Control type="text"
                            defaultValue={this.state.email1}
                            onBlur={(e) => this.setState({ email1: e.target.value })} />
                        </Form.Group>
                      </Col>
                      <Col md={3}>
                        <Form.Group className="mb-3">
                          <Form.Label>{t('ID Number')} <span className='text-danger'>*</span></Form.Label>
                          <Form.Control type="text"
                            defaultValue={this.state.id_number}
                            onBlur={(e) => this.setState({ id_number: e.target.value })} />
                        </Form.Group>
                      </Col>
                      <Col md={3}>
                        <Form.Group className="mb-3">
                          <Form.Label>{t('Country')}</Form.Label>
                          <Select
                            value={this.state.country_id}
                            menuPortalTarget={document.body}
                            styles={{
                              menuPortal: (
                                base
                              ) => ({
                                ...base,
                                zIndex: 9999,
                              }),
                            }}
                            options={this.state.countries}
                            onChange={(obj) => this.setState({ country_id: obj })}
                          />
                        </Form.Group>
                      </Col>
                      <Col md={3}>
                        <Form.Group className="mb-3">
                          <Form.Label>{t('Parent Role')} <span className='text-danger'>*</span></Form.Label>
                          <Select
                            value={this.state.parent_role}
                            menuPortalTarget={document.body}
                            styles={{
                              menuPortal: (
                                base
                              ) => ({
                                ...base,
                                zIndex: 9999,
                              }),
                            }}
                            options={this.state.parentRoles}
                            onChange={(obj) => this.setState({ parent_role: obj })}
                          />
                        </Form.Group>
                      </Col>
                      <Col md={3}>
                        <Form.Group className="mb-3">
                          <Form.Label>{t('Relationship Status')} <span className='text-danger'>*</span></Form.Label>
                          <Select
                            value={this.state.parent_relationship_status}
                            menuPortalTarget={document.body}
                            styles={{
                              menuPortal: (
                                base
                              ) => ({
                                ...base,
                                zIndex: 9999,
                              }),
                            }}
                            options={this.state.relationshipStatuses}
                            onChange={(obj) => this.setState({ parent_relationship_status: obj })}
                          />
                        </Form.Group>
                      </Col>
                      <Col md={3}>
                        <Form.Group className="mb-3">
                          <Form.Label>{t('Center Role')}</Form.Label>
                          <Select
                            value={this.state.crole_id}
                            menuPortalTarget={document.body}
                            styles={{
                              menuPortal: (
                                base
                              ) => ({
                                ...base,
                                zIndex: 9999,
                              }),
                            }}
                            options={this.state.centerRoles}
                            onChange={(obj) => this.setState({ crole_id: obj })}
                          />
                        </Form.Group>
                      </Col>
                      <Col md={2}>
                        <Form.Group className="mb-3">
                          <Form.Label>{t('Parent Status')}</Form.Label>
                          <div className='p-3 pb-0'>
                            <Form.Check
                              type="switch"
                              id="parent_status"
                              label={false}
                              onChange={() => this.setState({ parent_status: !this.state.parent_status })}
                              checked={this.state.parent_status == 1 ? true : false}
                            />
                          </div>
                        </Form.Group>
                      </Col>
                      <Col md={2}>
                        <Form.Group className="mb-3">
                          <Form.Label>{t('Use Email')}</Form.Label>
                          <div className='p-3 pb-0'>
                            <Form.Check
                              type="switch"
                              id="usemail"
                              label={false}
                              onChange={() => this.setState({ usemail: !this.state.usemail })}
                              checked={this.state.usemail == 1 ? true : false}
                            />
                          </div>
                        </Form.Group>
                      </Col>
                      <Col md={2}>
                        <Form.Group className="mb-3">
                          <Form.Label>{t('Use Mobile App')}</Form.Label>
                          <div className='p-3 pb-0'>
                            <Form.Check
                              type="switch"
                              id="useapps"
                              label={false}
                              onChange={() => this.setState({ useapps: !this.state.useapps })}
                              checked={this.state.useapps == 1 ? true : false}
                            />
                          </div>
                        </Form.Group>
                      </Col>
                      <Col md={12}><hr /></Col>
                      <Col md={12}>
                        <h6 className='mb-0'>{t('Security Details')}</h6>
                      </Col>
                      <Col md={3}>
                        <Form.Group className="mb-3">
                          <Form.Label>{t('Username')}</Form.Label>
                          <Form.Control type="text"
                            defaultValue={this.state.user_name}
                            autoComplete={'new-password'}
                            onBlur={(e) => this.setState({ user_name: e.target.value })} />
                        </Form.Group>
                      </Col>
                      <Col md={3}>
                        <Form.Group className="mb-3">
                          <Form.Label>{t('Password')}</Form.Label>
                          <Form.Control type="password"
                            defaultValue={this.state.user_pass}
                            autoComplete={'new-password'}
                            onBlur={(e) => this.setState({ user_pass: e.target.value })} />
                        </Form.Group>
                      </Col>
                      <Col md={6}></Col>
                      <Col md={3}>
                        <Form.Group className="mb-3">
                          <Form.Label>{t('Chart')} <span className='text-danger'>*</span></Form.Label>
                          <Select
                            value={this.state.chapt_id}
                            menuPortalTarget={document.body}
                            styles={{
                              menuPortal: (
                                base
                              ) => ({
                                ...base,
                                zIndex: 9999,
                              }),
                            }}
                            options={this.state.chapters}
                            onChange={(obj) => this.setState({ chapt_id: obj })}
                          />
                        </Form.Group>
                      </Col>
                      <Col md={3}>
                        <Form.Group className="mb-3">
                          <Form.Label>{t('Account Number')}</Form.Label>
                          <Form.Control type="text"
                            defaultValue={this.state.account_num}
                            onBlur={(e) => this.setState({ account_num: e.target.value })} />
                        </Form.Group>
                      </Col>
                      <Col md={3}>
                        <Form.Group className="mb-3">
                          <Form.Label>{t('3rd Party Account')}</Form.Label>
                          <Form.Control type="text"
                            defaultValue={this.state.qb_account}
                            onBlur={(e) => this.setState({ qb_account: e.target.value })} />
                        </Form.Group>
                      </Col>
                    </Row>
                  </div>)}
                {/* Other Contact */}
                {this.state.formSectionActive.parent == this.formSections[1].uid && (
                  <div id={`form_section_${this.formSections[1].uid}`}>
                    <h5 className='mb-3'>{t(this.formSections[1].name)}</h5>
                    <Row>
                      <Col md={3}>
                        <Form.Group className="mb-3">
                          <Form.Label>{t('Phone 2')}</Form.Label>
                          <Form.Control type="text"
                            defaultValue={this.state.phone2}
                            onBlur={(e) => this.setState({ phone2: e.target.value })} />
                        </Form.Group>
                      </Col>
                      <Col md={3}>
                        <Form.Group className="mb-3">
                          <Form.Label>{t('Email 2')}</Form.Label>
                          <Form.Control type="text"
                            defaultValue={this.state.email2}
                            onBlur={(e) => this.setState({ email2: e.target.value })} />
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label>{t('Address')}</Form.Label>
                          <Form.Control type="text"
                            defaultValue={this.state.address}
                            onBlur={(e) => this.setState({ address: e.target.value })} />
                        </Form.Group>
                      </Col>
                      <Col md={3}>
                        <Form.Group className="mb-3">
                          <Form.Label>{t('Education')}</Form.Label>
                          <Form.Control type="text"
                            defaultValue={this.state.parent_education_level}
                            onBlur={(e) => this.setState({ parent_education_level: e.target.value })} />
                        </Form.Group>
                      </Col>
                      <Col md={3}>
                        <Form.Group className="mb-3">
                          <Form.Label>{t('Father Position')}</Form.Label>
                          <Select
                            value={this.state.position_id_father}
                            menuPortalTarget={document.body}
                            styles={{
                              menuPortal: (
                                base
                              ) => ({
                                ...base,
                                zIndex: 9999,
                              }),
                            }}
                            options={this.state.fatherPositions}
                            onChange={(obj) => this.setState({ position_id_father: obj })}
                          />
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label>{t('Father Employer')}</Form.Label>
                          <Form.Control type="text"
                            defaultValue={this.state.employer_father}
                            onBlur={(e) => this.setState({ employer_father: e.target.value })} />
                        </Form.Group>
                      </Col>
                      <Col md={3}>
                        <Form.Group className="mb-3">
                          <Form.Label>{t('Grandfather Name')}</Form.Label>
                          <Form.Control type="text"
                            defaultValue={this.state.grandfather}
                            onBlur={(e) => this.setState({ grandfather: e.target.value })} />
                        </Form.Group>
                      </Col>
                      <Col md={3}>
                        <Form.Group className="mb-3">
                          <Form.Label>{t('Grandfather Name 2')}</Form.Label>
                          <Form.Control type="text"
                            defaultValue={this.state.grandfather_2}
                            onBlur={(e) => this.setState({ grandfather_2: e.target.value })} />
                        </Form.Group>
                      </Col>
                      <Col md={12}><hr /></Col>
                      <Col md={12}>
                        <h6 className='mb-0'>{t('Spouse')}</h6>
                      </Col>
                      <Col md={3}>
                        <Form.Group className="mb-3">
                          <Form.Label>{t('Role')}</Form.Label>
                          <Select
                            value={this.state.spouse_role}
                            menuPortalTarget={document.body}
                            styles={{
                              menuPortal: (
                                base
                              ) => ({
                                ...base,
                                zIndex: 9999,
                              }),
                            }}
                            options={this.state.parentRoles}
                            onChange={(obj) => this.setState({ spouse_role: obj })}
                          />
                        </Form.Group>
                      </Col>
                      <Col md={3}>
                        <Form.Group className="mb-3">
                          <Form.Label>{t('Name')}</Form.Label>
                          <Form.Control type="text"
                            defaultValue={this.state.spouse_name}
                            onBlur={(e) => this.setState({ spouse_name: e.target.value })} />
                        </Form.Group>
                      </Col>
                      <Col md={3}>
                        <Form.Group className="mb-3">
                          <Form.Label>{t('Phone')}</Form.Label>
                          <Form.Control type="text"
                            defaultValue={this.state.spouse_phone}
                            onBlur={(e) => this.setState({ spouse_phone: e.target.value })} />
                        </Form.Group>
                      </Col>
                      <Col md={3}>
                        <Form.Group className="mb-3">
                          <Form.Label>{t('Email')}</Form.Label>
                          <Form.Control type="text"
                            defaultValue={this.state.spouse_email}
                            onBlur={(e) => this.setState({ spouse_email: e.target.value })} />
                        </Form.Group>
                      </Col>
                      <Col md={3}>
                        <Form.Group className="mb-3">
                          <Form.Label>{t('ID Number')}</Form.Label>
                          <Form.Control type="text"
                            defaultValue={this.state.spouse_id_number}
                            onBlur={(e) => this.setState({ spouse_id_number: e.target.value })} />
                        </Form.Group>
                      </Col>
                      <Col md={3}>
                        <Form.Group className="mb-3">
                          <Form.Label>{t('Country')}</Form.Label>
                          <Select
                            value={this.state.spouse_country}
                            menuPortalTarget={document.body}
                            styles={{
                              menuPortal: (
                                base
                              ) => ({
                                ...base,
                                zIndex: 9999,
                              }),
                            }}
                            options={this.state.countries}
                            onChange={(obj) => this.setState({ spouse_country: obj })}
                          />
                        </Form.Group>
                      </Col>
                      <Col md={3}>
                        <Form.Group className="mb-3">
                          <Form.Label>{t('Mother Position')}</Form.Label>
                          <Select
                            value={this.state.position_id_mother}
                            menuPortalTarget={document.body}
                            styles={{
                              menuPortal: (
                                base
                              ) => ({
                                ...base,
                                zIndex: 9999,
                              }),
                            }}
                            options={this.state.motherPositions}
                            onChange={(obj) => this.setState({ position_id_mother: obj })}
                          />
                        </Form.Group>
                      </Col>
                      <Col md={3}>
                        <Form.Group className="mb-3">
                          <Form.Label>{t('Mother Employer')}</Form.Label>
                          <Form.Control type="text"
                            defaultValue={this.state.Parents_employer_mother}
                            onBlur={(e) => this.setState({ Parents_employer_mother: e.target.value })} />
                        </Form.Group>
                      </Col>
                      <Col md={3}>
                        <Form.Group className="mb-3">
                          <Form.Label>{t('Education')}</Form.Label>
                          <Form.Control type="text"
                            defaultValue={this.state.spouse_education_level}
                            onBlur={(e) => this.setState({ spouse_education_level: e.target.value })} />
                        </Form.Group>
                      </Col>
                      <Col md={12}><hr /></Col>
                      <Col md={12}>
                        <h6 className='mb-0'>{t('Emergency')}</h6>
                      </Col>
                      <Col md={3}>
                        <Form.Group className="mb-3">
                          <Form.Label>{t('Emergency Contact Name')}</Form.Label>
                          <Form.Control type="text"
                            defaultValue={this.state.emergency_contact_name}
                            onBlur={(e) => this.setState({ emergency_contact_name: e.target.value })} />
                        </Form.Group>
                      </Col>
                      <Col md={3}>
                        <Form.Group className="mb-3">
                          <Form.Label>{t('Emergency Phone 1')}</Form.Label>
                          <Form.Control type="text"
                            defaultValue={this.state.emergency_phone1}
                            onBlur={(e) => this.setState({ emergency_phone1: e.target.value })} />
                        </Form.Group>
                      </Col>
                      <Col md={3}>
                        <Form.Group className="mb-3">
                          <Form.Label>{t('Emergency Phone 2')}</Form.Label>
                          <Form.Control type="text"
                            defaultValue={this.state.emergency_phone2}
                            onBlur={(e) => this.setState({ emergency_phone2: e.target.value })} />
                        </Form.Group>
                      </Col>
                    </Row>
                  </div>)}
                {/* Legal */}
                {this.state.formSectionActive.parent == this.formSections[2].uid && (
                  <div id={`form_section_${this.formSections[2].uid}`}>
                    <h5>{t(this.formSections[2].name)}</h5>
                    <Row>
                      <Col md={3}>
                        <Form.Group className="mb-3">
                          <Form.Label>{t('Passport Number')}</Form.Label>
                          <Form.Control type="text"
                            defaultValue={this.state.passport_number}
                            onBlur={(e) => this.setState({ passport_number: e.target.value })} />
                        </Form.Group>
                      </Col>
                      <Col md={3}>
                        <Form.Group className="mb-3">
                          <Form.Label>{t('Passport Validity')}</Form.Label>
                          <Flatpickr
                            className='form-control'
                            value={this.state.passport_validity}
                            onChange={([
                              date,
                            ]) => {
                              this.setState(
                                {
                                  passport_validity:
                                    moment(
                                      date
                                    ).format(
                                      'YYYY-MM-DD'
                                    ),
                                }
                              );
                            }}
                          />
                        </Form.Group>
                      </Col>
                      <Col md={6}></Col>
                      <Col md={3}>
                        <Form.Group className="mb-3">
                          <Form.Label>{t('Sponsor Name')}</Form.Label>
                          <Form.Control type="text"
                            defaultValue={this.state.sponsor_name}
                            onBlur={(e) => this.setState({ sponsor_name: e.target.value })} />
                        </Form.Group>
                      </Col>
                      <Col md={3}>
                        <Form.Group className="mb-3">
                          <Form.Label>{t('Residency Number')}</Form.Label>
                          <Form.Control type="text"
                            defaultValue={this.state.residency_number}
                            onBlur={(e) => this.setState({ residency_number: e.target.value })} />
                        </Form.Group>
                      </Col>
                      <Col md={3}>
                        <Form.Group className="mb-3">
                          <Form.Label>{t('Residency Validity')}</Form.Label>
                          <Flatpickr
                            className='form-control'
                            value={this.state.residency_validity}
                            onChange={([
                              date,
                            ]) => {
                              this.setState(
                                {
                                  residency_validity:
                                    moment(
                                      date
                                    ).format(
                                      'YYYY-MM-DD'
                                    ),
                                }
                              );
                            }}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </div>)}
                {/* Family */}
                {this.state.formSectionActive.parent == this.formSections[3].uid && (
                  <div id={`form_section_${this.formSections[3].uid}`}>
                    <h5 className='mb-3'>{t(this.formSections[3].name)}</h5>
                    <Row>
                      {this.state.children.length == 0 &&
                        <Col className='text-center py-5 text-muted'>
                          {t('No data found!')}
                        </Col>
                      }
                      {this.state.children.length > 0 && this.state.children.map((item, index) => (
                        <Col key={index} md={2} className='mb-3 d-flex flex-column align-items-center'>
                          <div
                            onClick={() => {
                              this.handleClose();
                              this.props.setAddEditStudentModal({ showModal: true, child_id: item.child_id, isEditing: true });
                            }}
                            style={{
                              cursor: 'pointer',
                              backgroundImage: `url(${(item.picture) ? portalURL + item.center_id + '/children/' + item.picture : 'https://via.placeholder.com/150x150?text=Student'})`,
                              width: 60,
                              height: 60,
                              backgroundSize: 'cover',
                              backgroundPosition: 'center center',
                              borderRadius: 30
                            }}></div>
                          <strong
                            onClick={() => {
                              this.handleClose();
                              this.props.setAddEditStudentModal({ showModal: true, child_id: item.child_id, isEditing: true });
                            }}
                            className='text-center'>{item.child_name}</strong>
                        </Col>
                      ))}
                    </Row>
                  </div>)}
                {/* Child Pickup Person */}
                {this.state.formSectionActive.parent == this.formSections[4].uid && (
                  <div id={`form_section_${this.formSections[4].uid}`}>
                    <Row>
                      <Col>
                        <h5 className='mb-3'>{t(this.formSections[4].name)}</h5>
                      </Col>
                      <Col md='auto'>
                        <button
                          onClick={() => this.handleOpenModal('Pickup Person', 'lg', 'pickupPersonData', 'pickupPersons')}
                          style={{ height: 20, lineHeight: '10px' }}
                          className='btn btn-success btn-sm'><FontAwesomeIcon icon={faPlus} color={brand_colors[this.props.defaultTheme.theme_id].color8} style={{ fontSize: 12 }} /></button>
                      </Col>
                    </Row>
                    {this.state.pickupPersons.length == 0 &&
                      <Col className='text-center py-5 text-muted'>
                        {t('No data found!')}
                      </Col>
                    }
                    {this.state.pickupPersons.length > 0 && this.state.pickupPersons.map((item, index) => (
                      <Card key={index} className='pickup-person'>
                        <Card.Body>
                          <Row>
                            <Col md='auto'>
                              <div style={{
                                backgroundImage: `url(${(item.person_picture_prev) ? item.person_picture_prev : 'https://via.placeholder.com/150x150?text=Student'})`,
                                width: 60,
                                height: 60,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center center',
                                borderRadius: 10
                              }}></div>
                            </Col>
                            <Col md={2} className='d-flex flex-column justify-content-center'>
                              <strong>{item.person_pickup}</strong>
                              <div>{item.person_relation}</div>
                            </Col>
                            <Col md={3} className='d-flex flex-column justify-content-center'>
                              <div><strong>{t('Username:')}</strong> {item.user_name}</div>
                              <div><strong>{t('Password:')}</strong> {item.password}</div>
                            </Col>
                            <Col md={2} className='d-flex flex-column justify-content-center align-items-center'>
                              {item.person_contact &&
                                <div>
                                  <FontAwesomeIcon icon={faPhone} style={{ fontSize: 12 }} /> {item.person_contact}
                                </div>
                              }
                            </Col>
                            <Col className='d-flex align-items-center justify-content-center p-0'>
                              {item.person_document &&
                                <span
                                  onClick={() => window.open(item.person_document_prev)}
                                  style={{
                                    cursor: 'pointer'
                                  }}
                                  className='text-info'>
                                  <FontAwesomeIcon icon={faArrowUpRightFromSquare} style={{ fontSize: 12, marginRight: 5 }} />
                                  {t('Consent Document')}
                                </span>
                              }
                            </Col>
                            <Col className='d-flex justify-content-center align-items-center'>
                              <Form.Check
                                onChange={() => this.changePickupPersonStatus(index)}
                                type="switch"
                                id={`approved-${index}`}
                                label={t('Approved')}
                                checked={item.approved == 1}
                              />
                            </Col>
                            <Col md='auto' className='d-flex align-items-center justify-content-end'>
                              <button
                                onClick={() => this.deletePickupPerson(index)}
                                style={{ width: 20, height: 20, lineHeight: '10px', padding: 0 }}
                                className='btn btn-danger btn-sm'><FontAwesomeIcon icon={faTrash} color={brand_colors[this.props.defaultTheme.theme_id].color8} style={{ fontSize: 12 }} /></button>
                            </Col>
                          </Row>
                        </Card.Body>
                      </Card>
                    ))}
                  </div>)}

                {/* Documents */}
                {this.state.formSectionActive.parent == this.formSections[5].uid && (
                  <div id={`form_section_${this.formSections[5].uid}`}>
                    <Row>
                      <Col>
                        <h5 className='mb-3'>{t(this.formSections[5].name)}</h5>
                      </Col>
                      <Col md='auto'>
                      </Col>
                    </Row>

                    {/* ADD NEW */}
                    <Row>
                      <Col md="auto">
                        <div style={{ flexDirection: 'column', width: 200, height: 200 }} className='pic-prev d-flex justify-content-center'>
                          <div style={{ borderRadius: 5, borderStyle: 'dashed', borderWidth: 2, borderColor: brand_colors[this.props.defaultTheme.theme_id].color10, height: 348, display: 'flex', overflow: 'hidden', }}>

                            {this.state.attachments_parent_child_temp.prev
                              ? <div className='d-flex justify-content-center align-items-center'
                                style={{ flex: 1, position: 'relative' }}>
                                <button
                                  style={{ padding: 3, border: 3, background: 'none', position: 'absolute', top: 0, right: 0, }}
                                  onClick={() => {
                                    this.setState({ attachments_parent_child_temp: { prev: null, data: null } })
                                  }}>
                                  <FontAwesomeIcon icon={faTimesCircle} color={brand_colors[this.props.defaultTheme.theme_id].color11} style={{ fontSize: 20, }} />
                                </button>
                                {(this.state.attachments_parent_child_temp.data.type.includes('pdf'))
                                  ? (<FontAwesomeIcon icon={faFilePdf} style={{ fontSize: 20, color: brand_colors[this.props.defaultTheme.theme_id].color11 }} />)
                                  : (<img src={this.state.attachments_parent_child_temp.prev} onClick={() => window.open(this.state.attachments_parent_child_temp.prev)} alt={t('Preview')} style={{ width: '100%', height: '100%', objectFit: 'cover' }} className="cursor-pointer" />)}
                              </div>
                              : <div className='d-flex justify-content-center align-items-center' style={{ flex: 1, position: 'relative', }} >
                                <button onClick={() => this.attachments_parent_child_temp.current.click()} style={{ background: 'none', border: 0, flex: 1, }}>
                                  <FontAwesomeIcon icon={faPlus} style={{ fontSize: 30, }} />
                                  <Form.Control className='d-none' ref={this.attachments_parent_child_temp} type='file' onChange={this.previewFile} name='attachments_parent_child_temp' accept='.jpg, .jpeg, .png, .pdf' />
                                </button>
                              </div>
                            }

                          </div>
                        </div>
                      </Col>
                      <Col md="auto" >
                        <div className='d-flex flex-column' style={{ width: 200 }}></div>
                        <div className='mb-1'>
                          <Select
                            options={this.state.documentTypes.data.filter((v, i) => {
                              let find_attachments_parent_child = this.state.attachments_parent_child.find((vv, ii) => vv.apc_attachment_type == v.value)
                              if (find_attachments_parent_child) {
                                return false
                              }
                              return true
                            })}
                            value={this.state.documentTypes.selected}
                            menuPortalTarget={document.body}
                            styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999, }), }}
                            onChange={(obj) => this.setState({ documentTypes: { ...this.state.documentTypes, selected: obj } })}
                          />
                        </div>
                        <div className='d-grid'>
                          <Button onClick={() => {
                            let documentTypes = this.state.documentTypes
                            let attachments_parent_child_temp = this.state.attachments_parent_child_temp
                            if (!documentTypes.selected) {
                              toast.error(t('Please select File Type!'));
                              return
                            }
                            if (!attachments_parent_child_temp.prev) {
                              toast.error(t('Please select a File!'));
                              return
                            }
                            let attachments_parent_child = this.state.attachments_parent_child
                            attachments_parent_child.push({
                              apc_attachment_name_prev: this.state.attachments_parent_child_temp.prev,
                              apc_attachment_name: this.state.attachments_parent_child_temp.data,
                              apc_attachment_type: documentTypes.selected.value,
                              apc_folder: null,
                              apc_id: 0,
                              apc_table_name: 'parents',
                            })
                            this.setState({ attachments_parent_child, documentTypes: { ...documentTypes, selected: null }, attachments_parent_child_temp: { prev: null, data: null } })
                          }}>
                            <FontAwesomeIcon icon={faPlus} />
                          </Button>
                        </div>
                      </Col>
                    </Row>

                    {/* attachments_parent_child DATA */}
                    <Row>
                      {this.state.attachments_parent_child.length == 0 &&
                        <Col className='text-center py-5 text-muted'>
                          {t('No data found!')}
                        </Col>
                      }
                      {this.state.attachments_parent_child.length > 0 && this.state.attachments_parent_child.map((item, index) => {

                        let apc_attachment_name_URL = (item.apc_attachment_name_prev)
                          ? item.apc_attachment_name_prev
                          : (item.apc_folder)
                            ? portalURL + item.center_id + '/' + item.apc_folder + '/' + item.apc_attachment_name
                            : portalURL + item.center_id + '/attachments_parent_child/' + item.apc_attachment_name

                        let apc_attachment_type = this.state.documentTypes.data.find((dt) => dt.value == item.apc_attachment_type)

                        return <Col md={3}>
                          <Form.Group className="mb-3">
                            <Form.Label>{apc_attachment_type.label}</Form.Label>
                            <div style={{ flexDirection: 'column' }} className='pic-prev d-flex justify-content-center'>
                              <div style={{ borderRadius: 5, borderStyle: 'dashed', borderWidth: 2, borderColor: brand_colors[this.props.defaultTheme.theme_id].color10, height: 200, display: 'flex', overflow: 'hidden', }}>
                                {<div className='d-flex justify-content-center align-items-center'
                                  style={{ flex: 1, position: 'relative' }}>
                                  <button style={{ padding: 3, border: 3, background: 'none', position: 'absolute', top: 0, right: 0, }} >
                                    <FontAwesomeIcon icon={faLink} color={brand_colors[this.props.defaultTheme.theme_id].color4} style={{ fontSize: 20, }} className='me-1' onClick={() => window.open(apc_attachment_name_URL)} />
                                    <FontAwesomeIcon icon={faTimesCircle} color={brand_colors[this.props.defaultTheme.theme_id].color11} style={{ fontSize: 20, }} onClick={() => {
                                      let attachments_parent_child = this.state.attachments_parent_child
                                      attachments_parent_child.splice(index, 1)
                                      this.setState({ attachments_parent_child })
                                    }} />
                                  </button>
                                  {((item.apc_attachment_name.type && item.apc_attachment_name.type.includes('pdf')) || (!Array.isArray(item.apc_attachment_name) && item.apc_attachment_name.toString().toLowerCase().indexOf(".pdf") >= 0))
                                    ? (apc_attachment_name_URL != 'pdf')
                                      ? <embed src={apc_attachment_name_URL} style={{ width: '100%', height: '100%' }} />
                                      : <FontAwesomeIcon icon={faFilePdf} style={{ fontSize: 20, color: brand_colors[this.props.defaultTheme.theme_id].color11 }} />
                                    : (<img src={apc_attachment_name_URL} onClick={() => window.open(apc_attachment_name_URL)} alt={t('Preview')} style={{ width: '100%', height: '100%', objectFit: 'cover' }} className="cursor-pointer" />)}
                                </div>}
                              </div>
                            </div>
                          </Form.Group>
                        </Col>
                      })}
                    </Row>
                  </div>)}
              </Col>
            </div>
          </Offcanvas.Body>
        </Offcanvas>
        <Modal
         backdrop="static"
         keyboard={false}
          show={this.state.modal.config.show}
          onHide={this.handleCloseModal}
          size={this.state.modal.config.size}>
          <Modal.Header closeButton>
            <Modal.Title>{t('Add') + ' ' + t(this.state.modal.config.name)}</Modal.Title>
          </Modal.Header>
          <Modal.Body className='custom-styled-form'>
            <Row>
              <Col md={4}>
                <Form.Group className="mb-3">
                  <Form.Label>{t('Name')}</Form.Label>
                  <Form.Control type="text"
                    defaultValue={this.state.modal.pickupPersonData.person_pickup}
                    onBlur={(e) => this.setState({
                      modal: {
                        ...this.state.modal,
                        pickupPersonData: {
                          ...this.state.modal.pickupPersonData,
                          person_pickup: e.target.value
                        }
                      }
                    })} />
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group className="mb-3">
                  <Form.Label>{t('Phone')}</Form.Label>
                  <Form.Control type="text"
                    defaultValue={this.state.modal.pickupPersonData.person_contact}
                    onBlur={(e) => this.setState({
                      modal: {
                        ...this.state.modal,
                        pickupPersonData: {
                          ...this.state.modal.pickupPersonData,
                          person_contact: e.target.value
                        }
                      }
                    })} />
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group className="mb-3">
                  <Form.Label>{t('Relation')}</Form.Label>
                  <Form.Control type="text"
                    defaultValue={this.state.modal.pickupPersonData.person_relation}
                    onBlur={(e) => this.setState({
                      modal: {
                        ...this.state.modal,
                        pickupPersonData: {
                          ...this.state.modal.pickupPersonData,
                          person_relation: e.target.value
                        }
                      }
                    })} />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>{t('Picture')}</Form.Label>
                  <div
                    style={{
                      flexDirection:
                        'column',
                    }}
                    className='pic-prev d-flex justify-content-center'>
                    <div
                      style={{
                        borderRadius: 5,
                        borderStyle:
                          'dashed',
                        borderWidth: 2,
                        borderColor:
                          brand_colors[this.props.defaultTheme.theme_id].color10,
                        height: 150,
                        display: 'flex',
                        overflow:
                          'hidden',
                      }}>
                      {this.state.modal.pickupPersonData.person_picture !==
                        '' &&
                        this.state.modal.pickupPersonData.person_picture_prev !==
                        '' ? (
                        <div
                          className='d-flex justify-content-center align-items-center'
                          style={{
                            flex: 1,
                            position:
                              'relative',
                          }}>
                          <button
                            style={{
                              padding: 3,
                              border: 3,
                              background:
                                'none',
                              position:
                                'absolute',
                              top: 0,
                              right: 0,
                            }}
                            onClick={() => {
                              this.setState({
                                modal: {
                                  ...this.state.modal,
                                  pickupPersonData: {
                                    ...this.state.modal.pickupPersonData,
                                    person_picture: '',
                                    person_picture_prev: '',
                                  }
                                }
                              });
                              this.person_picture.current.value =
                                null;
                            }}>
                            <FontAwesomeIcon
                              icon={
                                faTimesCircle
                              }
                              color={brand_colors[this.props.defaultTheme.theme_id].color11}
                              style={{
                                fontSize: 20,
                              }}
                            />
                          </button>
                          <img
                            src={
                              this.state.modal.pickupPersonData.person_picture_prev
                            }
                            alt={t(
                              'Preview'
                            )}
                            width='100'
                          />
                        </div>
                      ) : (
                        <button
                          onClick={() =>
                            this.person_picture.current.click()
                          }
                          style={{
                            background:
                              'none',
                            border: 0,
                            flex: 1,
                          }}>
                          <FontAwesomeIcon
                            icon={
                              faPlus
                            }
                            style={{
                              fontSize: 30,
                            }}
                          />
                        </button>
                      )}
                    </div>
                    <Form.Control
                      className='d-none'
                      ref={
                        this
                          .person_picture
                      }
                      type='file'
                      onChange={this.previewFileModal('pickupPersonData')}
                      name='person_picture'
                      accept='.jpg, .jpeg, .png'
                    />
                  </div>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>{t('Consent Document')}</Form.Label>
                  <div
                    style={{
                      flexDirection:
                        'column',
                    }}
                    className='pic-prev d-flex justify-content-center'>
                    <div
                      style={{
                        borderRadius: 5,
                        borderStyle:
                          'dashed',
                        borderWidth: 2,
                        borderColor:
                          brand_colors[this.props.defaultTheme.theme_id].color10,
                        height: 150,
                        display: 'flex',
                        overflow:
                          'hidden',
                      }}>
                      {this.state.modal.pickupPersonData.person_document !==
                        '' &&
                        this.state.modal.pickupPersonData.person_document_prev !==
                        '' ? (
                        <div
                          className='d-flex justify-content-center align-items-center'
                          style={{
                            flex: 1,
                            position:
                              'relative',
                          }}>
                          <button
                            style={{
                              padding: 3,
                              border: 3,
                              background:
                                'none',
                              position:
                                'absolute',
                              top: 0,
                              right: 0,
                            }}
                            onClick={() => {
                              this.setState({
                                modal: {
                                  ...this.state.modal,
                                  pickupPersonData: {
                                    ...this.state.modal.pickupPersonData,
                                    person_document: '',
                                    person_document_prev: '',
                                  }
                                }
                              });
                              this.person_document.current.value =
                                null;
                            }}>
                            <FontAwesomeIcon
                              icon={
                                faTimesCircle
                              }
                              color={brand_colors[this.props.defaultTheme.theme_id].color11}
                              style={{
                                fontSize: 20,
                              }}
                            />
                          </button>
                          {this.state.modal.pickupPersonData.person_document_prev ==
                            'pdf' ? (
                            <FontAwesomeIcon
                              icon={
                                faFilePdf
                              }
                              style={{
                                fontSize: 60,
                              }}
                            />
                          ) : (
                            <img
                              src={
                                this.state.modal.pickupPersonData.person_document_prev
                              }
                              alt={t(
                                'Preview'
                              )}
                              width='12'
                            />
                          )}
                        </div>
                      ) : (
                        <button
                          onClick={() =>
                            this.person_document.current.click()
                          }
                          style={{
                            background:
                              'none',
                            border: 0,
                            flex: 1,
                          }}>
                          <FontAwesomeIcon
                            icon={
                              faPlus
                            }
                            style={{
                              fontSize: 30,
                            }}
                          />
                        </button>
                      )}
                    </div>
                    <Form.Control
                      className='d-none'
                      ref={
                        this
                          .person_document
                      }
                      type='file'
                      onChange={this.previewFileModal('pickupPersonData')}
                      name='person_document'
                      accept='.jpg, .jpeg, .png, .pdf'
                    />
                  </div>
                </Form.Group>
              </Col>
              <Col md={12}>
                <Form.Group className="mb-3">
                  <Form.Check
                    type="switch"
                    id="approved"
                    label={t('Approved')}
                    onChange={() => this.setState({
                      modal: {
                        ...this.state.modal,
                        pickupPersonData: {
                          ...this.state.modal.pickupPersonData,
                          approved: this.state.modal.pickupPersonData.approved == 1 ? 0 : 1
                        }
                      }
                    })}
                    checked={this.state.modal.pickupPersonData.approved == 1}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleCloseModal}>
              {t('Close')}
            </Button>
            <Button variant="primary" onClick={() => this.addModalItem()}>
              {t('Add')}
            </Button>
          </Modal.Footer>
        </Modal>
      </>);
  }
}

const mapStateToProps = (state) => ({
  authData: state.auth.authData,
  defaultTheme: state.theme.defaultTheme,
  addEditParentModal: state.addEditParentModal.showModal,
  parent_id: state.addEditParentModal.parent_id,
  isEditing: state.addEditParentModal.isEditing,
  showModal: state.addEditParentModal.showModal,
  parents: state.addEditStudentModal.parents,
  studentListSearch: state.studentList.query,
  studentListRows: state.studentList.rows,
  studentListOffset: state.studentList.offset,
  studentListStatus: state.studentList.status,
  studentListOrder: state.studentList.order,
  studentListDir: state.studentList.dir,
  studentListPage: state.studentList.page,
});

const mapDispatchToProps = () => ({
  setAddEditParentModal,
  setAddEditStudentModal,
  getStudents,
  setParents,
  getParents,
  setParent
});

export default connect(mapStateToProps, mapDispatchToProps())(AddEditParent);
