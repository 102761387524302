import React, { Component } from 'react';
import {
  Col,
  Container,
  Row,
  Modal,
  Form,
  Button,
  Spinner,
} from 'react-bootstrap';
import TimelineCard from './TimelineCard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faXmark } from '@fortawesome/free-solid-svg-icons';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import { connect } from 'react-redux';
import { t } from '../../helpers/translation_helper';
import { brand_colors } from '../../helpers/brand_colors_helper';

/**
 * Timeline Component
 */
class Timeline extends Component {
  //  State
  state = {
    modal: false,
  };

  /**
   * @contructor
   * @param {any} props
   */
  constructor(props) {
    super(props);
    this.UTList = React.createRef();
  }

  /**
   * Handle Modal
   */
  handleModal = () => {
    const { modal } = this.state;
    this.setState({
      modal: !modal,
    });
  };

  /**
   * Loads more user timeline posts when reaches the end.
   */
  onScroll = () => {
    if (this.UTList.current) {
      const { scrollTop, scrollHeight, clientHeight } = this.UTList.current;
      let fetchBeforeListEnds = 200
      if (scrollTop + clientHeight + fetchBeforeListEnds >= scrollHeight) {
        this.props.getTimeline();
      } else {
      }
    }
  };

  /**
   * Render Component
   * @returns {HTMLElement}
   */
  render() {
    // Language Direction
    const { lang_orientation: dir } = this.props.defaultLanguage;

    // State
    const { modal } = this.state;

    // Props
    const {
      utWorking,
      startDate,
      endDate,
      handleUtDateRange,
      utTypes,
      selectedUtTypes,
      handleSelectUtTypes,
      getTimeline,
      resetFilter,
      applyFilter,
      userTimelineData,
      authData,
      loadMore,
      children,
      defaultTheme
    } = this.props;
    console.log('this.propsTIMELINE', this.props);
    return (
      <>
        <div id='timeline' style={{ [(dir == 1) ? 'left' : 'right']: 0 }}>
          <div className='timeline-inner'>
            <Container>
              <Row>
                <Col>
                  <h4 style={{ color: brand_colors[this.props.defaultTheme.theme_id].color8 }}>{t('News Feed')}</h4>
                </Col>
                <Col className='d-flex justify-content-end'>
                  <FontAwesomeIcon
                    onClick={() => this.handleModal()}
                    className='btn-filter mx-3'
                    icon={faFilter}
                    color={(startDate || endDate || selectedUtTypes.length != utTypes.length) ? brand_colors[this.props.defaultTheme.theme_id].color9 : brand_colors[this.props.defaultTheme.theme_id].color8}
                  />
                  <FontAwesomeIcon
                    onClick={() => this.props.handleCloseUT()}
                    className='btn-filter'
                    icon={faXmark}
                    color={brand_colors[this.props.defaultTheme.theme_id].color8}
                    style={{
                      marginTop: 1,
                      fontSize: 28,
                    }}
                  />
                </Col>
              </Row>
            </Container>

            <div
              className='timeline-reel'
              onScroll={this.onScroll}
              ref={this.UTList}>
              {userTimelineData.length > 0 && (
                <>
                  {userTimelineData.map((ut, key) => (
                    <TimelineCard
                      key={key}
                      data={ut}
                      authData={authData}
                      dir={dir}
                      children={children}
                      defaultTheme={defaultTheme}
                    />
                  ))}
                  {loadMore && (
                    <div className='reel-load-more'>
                      <span className='me-1'>
                        {t('Fetching more...')}
                      </span>
                      <Spinner
                        animation='grow'
                        variant='light'
                      />
                    </div>
                  )}
                </>
              )}

              {utWorking && (
                <div className='text-center p-4'>
                  <Spinner animation='grow' variant='light' />
                  <br />
                  <span style={{ color: brand_colors[this.props.defaultTheme.theme_id].color8 }}>
                    {t('Fetching posts...')}
                  </span>
                </div>
              )}
              {userTimelineData.length <= 0 && !utWorking &&
                <div className='text-center p-4'>
                  <span style={{ color: brand_colors[this.props.defaultTheme.theme_id].color8 }}>
                    {t('No posts found!')}
                  </span>
                </div>
              }

            </div>

          </div>
        </div>
        <Modal show={modal} onHide={() => this.handleModal()} backdrop="static"
        keyboard={false}>
          <Modal.Header closeButton>
            <Modal.Title>{t('Filter')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col>
                <Form.Group className='mb-3'>
                  <Form.Label>{t('Start Date')}</Form.Label>
                  <DatePicker
                    selected={startDate}
                    onChange={(date) =>
                      handleUtDateRange('startDate', date)
                    }
                    selectsStart
                    startDate={startDate}
                    endDate={endDate}
                    dateFormat="dd-MM-yyyy"
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className='mb-3'>
                  <Form.Label>{t('End Date')}</Form.Label>
                  <DatePicker
                    selected={endDate}
                    onChange={(date) =>
                      handleUtDateRange('endDate', date)
                    }
                    selectsEnd
                    startDate={startDate}
                    endDate={endDate}
                    minDate={startDate}
                    dateFormat="dd-MM-yyyy"
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className='mb-3'>
                  <Form.Label>{t('Types')}</Form.Label>
                  <Select
                    closeMenuOnSelect={false}
                    defaultValue={selectedUtTypes}
                    isMulti
                    name='ut_types'
                    options={utTypes}
                    className='basic-multi-select'
                    classNamePrefix='select'
                    onChange={handleSelectUtTypes}
                    formatOptionLabel={option => (
                      <div>
                        {option.picture ? <img src={option.picture} style={{ width: 20, height: 20, objectFit: 'cover', marginRight: 5 }} /> : ''}
                        <span style={{ fontSize: 13 }}>{option.label}</span>
                      </div>
                    )}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant='danger'
              onClick={() => {
                resetFilter();
                this.handleModal();
              }}>
              {t('Reset Filter')}
            </Button>
            <Button
              variant='primary'
              onClick={() => {
                applyFilter();
                this.handleModal();
              }}>
              {t('Apply Filter')}
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  authData: state.auth.authData,
  defaultLanguage: state.language.defaultLanguage,
  defaultTheme: state.theme.defaultTheme,
  children: state.children.list,
});

export default connect(mapStateToProps, null)(Timeline);
