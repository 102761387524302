import React, { Component, Fragment, useRef } from 'react';
import {
  Container,
  Card,
  Table,
  Row,
  Col,
  Spinner,
  Image,
  Badge,
  Button,
  Form,
  Offcanvas,
  Accordion,
  Modal,
  ButtonGroup,
} from 'react-bootstrap';
import { t } from '../../helpers/translation_helper';
import NavBar from '../_partials/NavBar/_NavBar';
import Header from '../_partials/Header/_Header';
import { connect } from 'react-redux';
import axios from 'axios';
import { toast } from 'react-toastify';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleLeft,
  faAngleRight,
  faCalendarAlt,
  faCheck,
  faClock,
  faEdit,
  faExternalLink,
  faFileAlt,
  faFileExcel,
  faGear,
  faPaperclip,
  faPieChart,
  faSave,
  faSearch,
  faTimes,
  faTrashCan,
} from '@fortawesome/free-solid-svg-icons';
import { Scrollbar } from 'react-scrollbars-custom';
import imgDescription from '../../assets/images/description.png';
import {
  brand_colors,
  colorsArray,
  generateColor,
} from '../../helpers/brand_colors_helper';
import SweetAlert from 'react-bootstrap-sweetalert';
import Select from 'react-select';
import { handleFormErrors } from '../../helpers/form_helpers';
import { GET } from '../../api';
import { Bar } from 'react-chartjs-2';
import {
  changeColorOpacity,
  DDL_ExamstypegrpTypes,
  DDL_ExamstypeModes,
  generateColors,
} from '../../helpers/general_helpers';
import { useDownloadExcel } from 'react-export-table-to-excel';
import themeSlice from '../../redux/slices/themeSlice';

var Chance = require('chance');
var chance = new Chance();

// API URL
const apiURL = process.env.REACT_APP_API_URL;

// Portal URL
const portalURL = process.env.REACT_APP_PORTAL_URL;

class ReportResultRubric extends Component {
  constructor(props) {
    super(props);
    this.state = {
      centerlicData: { data: [], selected: null },
      termsData: { data: [], selected: [] },
      reRenderTime: moment(),
      reportData: {
        processing: false,
        data: {},
        finished: false,
      },
      filter_examstypegrp_type2: {
        data: [
          { name: 'Show All', value: 'All' },
          { name: 'Only Internal', value: 'Internal' },
          { name: 'Only External', value: 'External' },
        ],
        selected: { name: 'Show All', value: 'All' },
      },
      filter_result_type: {
        data: [
          { name: 'By Grade', value: 'Grade' },
          { name: 'By Rate', value: 'Rate' },
        ],
        selected: { name: 'By Grade', value: 'Grade' },
      },
      student_name_column: {
        column: 'child_name',
        changeColumn: (column) =>
          this.setState({
            student_name_column: { ...this.state.student_name_column, column },
          }),
      },
      modalGraph: {
        show: false,
        data: null,
        toggle: (data = null) =>
          this.setState({
            modalGraph: {
              ...this.state.modalGraph,
              show: !this.state.modalGraph.show,
              data: data,
            },
          }),
      },
    };
  }

  componentDidMount() {
    this.getCenterlic();
  }

  getCenterlic = () => {
    try {
      if (this.getCenterlicReq) {
        this.getCenterlicReq.abort();
      }
      this.getCenterlicReq = new AbortController();
      GET('report-exams/get-centerlic', {
        signal: this.getCenterlicReq.signal,
      }).then((res) => {
        if (res) {
          let selected = res.data.find((v) => v.reg_status == 1);
          this.setState(
            {
              centerlicData: {
                ...this.state.centerlicData,
                data: res.data,
                selected: {
                  value: selected.centerlic_id,
                  label: selected.reg_year,
                  reg_status: selected.reg_status,
                },
              },
            },
            () => this.getTerms()
          );
        }
      });
    } catch (err) {
      console.log('err', err);
      toast.error(t('Something went wrong while deleting!'));
    }
  };
  getTerms = () => {
    try {
      if (this.getTermsReq) {
        this.getTermsReq.abort();
      }
      this.getTermsReq = new AbortController();
      GET('report-exams/get-terms', {
        signal: this.getTermsReq.signal,
      }).then((res) => {
        if (res) {
          let selected = [];
          res.data.map((v, i) => {
            if (v.centerlic_id == this.state.centerlicData.selected.value) {
              selected.push({
                value: v.term_id,
                label: v.term_name,
                term_active: v.term_active,
              });
            }
          });
          this.setState({
            termsData: {
              ...this.state.termsData,
              data: res.data,
              selected: selected,
            },
          });
        }
      });
    } catch (err) {
      console.log('err', err);
      toast.error(t('Something went wrong while deleting!'));
    }
  };

  fetchReport = () => {
    let {
      reportData,
      centerlicData,
      termsData,
      filter_examstypegrp_type2,
      filter_result_type,
    } = this.state;
    let selectedSubject = this.props.selectedSubject;
    if (!this.props.selectedClass) {
      toast.error(t('No Class Selected'));
      return;
    }
    if (!selectedSubject) {
      toast.error(t('No Subject Selected'));
      return;
    }
    if (!centerlicData.selected) {
      toast.error(t('Please select a Year'));
      return;
    }
    if (termsData.selected.length <= 0) {
      toast.error(t('Please select a Term'));
      return;
    }

    this.setState({
      reportData: { ...reportData, processing: true, data: {} },
    });

    try {
      if (this.fetchReportReq) {
        this.fetchReportReq.abort();
      }
      this.fetchReportReq = new AbortController();
      GET('academic-classmain-subject/get-academic-classmain-subject-rubric2', {
        params: {
          class_id: this.props.selectedClass.class.class_id,
          agegroup_id: this.props.selectedClass.class.agegroup_id,
          acs_ids: JSON.stringify([selectedSubject.acs_id]),
          centerlic_id: centerlicData.selected.value,
          filter_examstypegrp_type2: filter_examstypegrp_type2.selected.value,
          filter_result_type: filter_result_type.selected.value,
          term_ids: JSON.stringify(termsData.selected.map((v, i) => v.value)),
          student_name_column: this.state.student_name_column.column,
        },
        signal: this.fetchReportReq.signal,
      }).then((res) => {
        if (res) {
          let dataRubric = res.data.dataRubric;
          res.data.dataRubric = dataRubric.map((v, i) => {
            let exams = v.exams;
            let term_d_id = v.term.term_d_id;
            let examstypegrps = [];

            if (exams.length > 0) {
              exams.map((vv) => {
                let findetg = examstypegrps.findIndex(
                  (vvv) =>
                    vvv.examstype_id == vv.examstype_id &&
                    vvv.examstypegrp_id == vv.examstypegrp_id &&
                    vvv.examstypegrp_type == vv.examstypegrp_type
                );

                let term_linked_agegroup_assessment_detail =
                  res.data.examstypegrps.find(
                    (vvv) =>
                      vv.examstype_id == vvv.examstype_id &&
                      vv.examstypegrp_id == vvv.examstypegrp_id
                  );

                // if (term_linked_agegroup_assessment_detail.term_linked_agegroup_assessment_detail) {
                // let tlaa_d_percentage = term_linked_agegroup_assessment_detail.term_linked_agegroup_assessment_detail['tlaa_d_percentage_' + term_d_id]
                if (findetg === -1) {
                  examstypegrps.push({
                    examstype_id: vv.examstype_id,
                    examstype_name: vv.examstype_name,
                    examstypegrp_id: vv.examstypegrp_id,
                    examstypegrp_name: vv.examstypegrp_name,
                    examstypegrp_type: vv.examstypegrp_type,
                    ...term_linked_agegroup_assessment_detail,
                    count: 1,
                  });
                } else {
                  examstypegrps[findetg].count =
                    examstypegrps[findetg].count + 1;
                }
                // }
              });
              v['examstypegrps'] = examstypegrps;
            }
            return v;
          });

          this.setState({
            reportData: {
              ...reportData,
              processing: false,
              data: res.data,
              finished: true,
            },
            reRenderTime: moment(),
          });
        } else {
          this.setState({
            reportData: { ...reportData, processing: false, data: {} },
          });
        }
      });
    } catch (err) {
      console.log('err', err);
      toast.error(t('Something went wrong while deleting!'));

      this.setState({
        reportData: { ...reportData, processing: false, data: {} },
      });
    }
  };

  render() {
    console.log('thisREPORT', this.state);

    const {
      reportData,
      reRenderTime,
      centerlicData,
      termsData,
      filter_examstypegrp_type2,
      filter_result_type,
      student_name_column,
      modalGraph,
    } = this.state;
    let filteredTerms = [];
    if (centerlicData.selected) {
      filteredTerms = termsData.data.filter(
        (v, i) => v.centerlic_id == centerlicData.selected.value
      );
    }
    return (
      <Container fluid>
        <div id='iedu-layout'>
          <NavBar />
          <div id='page-content'>
            <Header
              lite={true}
              heading={t('Result Rubric')}
              backBtn={true}
              showClassSelector={true}
              classSelectorFunc={[this.fetchReport]}
              showSubjectSelector={true}
              subjectSelectorFunc={[this.fetchReport]}
            />
            <div className='grey-section'>
              <Row className='justify-content-between'>
                <Col md='8'>
                  <Row>
                    <Col md='4'>
                      <Select
                        placeholder={t('Select Year')}
                        options={centerlicData.data.map((v, i) => {
                          return {
                            label: v.reg_year,
                            value: v.centerlic_id,
                            reg_status: v.reg_status,
                          };
                        })}
                        onChange={(event) =>
                          this.setState({
                            centerlicData: {
                              ...centerlicData,
                              selected: event,
                            },
                            termsData: { ...termsData, selected: [] },
                          })
                        }
                        value={centerlicData.selected}
                        menuPortalTarget={document.body}
                        styles={{
                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        }}
                        formatOptionLabel={(option) => (
                          <div className='d-flex align-items-center'>
                            <span style={{ fontSize: 16 }} className='me-1'>
                              {option.label}
                            </span>
                            {option.reg_status == 1 && (
                              <Badge pill bg='success'>
                                {t('Active')}
                              </Badge>
                            )}
                          </div>
                        )}
                      />
                    </Col>
                    <Col md='8'>
                      <Select
                        isMulti
                        isClearable
                        placeholder={t('Select Term')}
                        options={filteredTerms.map((v, i) => {
                          return {
                            label: v.term_name,
                            value: v.term_id,
                            term_active: v.term_active,
                          };
                        })}
                        onChange={(event) =>
                          this.setState({
                            termsData: { ...termsData, selected: event },
                          })
                        }
                        value={termsData.selected}
                        menuPortalTarget={document.body}
                        styles={{
                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        }}
                        formatOptionLabel={(option) => (
                          <div className='d-flex align-items-center'>
                            <span style={{ fontSize: 16 }} className='me-1'>
                              {option.label}
                            </span>
                            {option.term_active == 1 && (
                              <Badge pill bg='success'>
                                {t('Active')}
                              </Badge>
                            )}
                          </div>
                        )}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col md='4'>
                  <Row>
                    <Col className='d-flex flex-column'>
                      <ButtonGroup size='sm' className='mb-2'>
                        {filter_examstypegrp_type2.data.map((v, i) => {
                          return (
                            <Button
                              onClick={() =>
                                this.setState({
                                  filter_examstypegrp_type2: {
                                    ...filter_examstypegrp_type2,
                                    selected: v,
                                  },
                                })
                              }
                              variant={
                                filter_examstypegrp_type2.selected.value ==
                                v.value
                                  ? 'success'
                                  : 'light'
                              }>
                              {t(v.name)}
                            </Button>
                          );
                        })}
                      </ButtonGroup>

                      <ButtonGroup size='sm'>
                        {filter_result_type.data.map((v, i) => {
                          return (
                            <Button
                              onClick={() =>
                                this.setState({
                                  filter_result_type: {
                                    ...filter_result_type,
                                    selected: v,
                                  },
                                })
                              }
                              variant={
                                filter_result_type.selected.value == v.value
                                  ? 'success'
                                  : 'light'
                              }>
                              {t(v.name)}
                            </Button>
                          );
                        })}
                      </ButtonGroup>
                    </Col>
                    <Col md='auto'>
                      <div className='d-flex flex-column align-items-center'>
                        <div className='d-flex flex-row'>
                          <div
                            style={{
                              fontWeight: 'bold',
                              color:
                                student_name_column.column == 'child_name'
                                  ? brand_colors[
                                      this.props.defaultTheme.theme_id
                                    ].color4
                                  : brand_colors[
                                      this.props.defaultTheme.theme_id
                                    ].color9,
                            }}>
                            EN
                          </div>
                          <div>/</div>
                          <div
                            style={{
                              fontWeight: 'bold',
                              color:
                                student_name_column.column != 'child_name'
                                  ? brand_colors[
                                      this.props.defaultTheme.theme_id
                                    ].color4
                                  : brand_colors[
                                      this.props.defaultTheme.theme_id
                                    ].color9,
                            }}>
                            AR
                          </div>
                        </div>
                        <Form.Check
                          onChange={() =>
                            student_name_column.changeColumn(
                              student_name_column.column == 'child_name'
                                ? 'arabic_name'
                                : 'child_name'
                            )
                          }
                          checked={student_name_column.column != 'child_name'}
                          type='switch'
                          style={{
                            color:
                              brand_colors[this.props.defaultTheme.theme_id]
                                .color8,
                          }}
                        />
                        <div
                          style={{
                            color:
                              brand_colors[this.props.defaultTheme.theme_id]
                                .color9,
                            opacity: 0.8,
                          }}>
                          {t('Name')}
                        </div>
                      </div>
                    </Col>
                    <Col md='auto'>
                      <Button
                        variant={'success'}
                        onClick={() => this.fetchReport()}>
                        {reportData.processing ? (
                          <Spinner
                            as='span'
                            animation='grow'
                            size='sm'
                            role='status'
                            aria-hidden='true'
                          />
                        ) : (
                          <FontAwesomeIcon
                            icon={faSearch}
                            style={{
                              fontSize: 16,
                              color:
                                brand_colors[this.props.defaultTheme.theme_id]
                                  .color8,
                            }}
                          />
                        )}
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
            <div className='grey-section'>
              {reportData.processing ? (
                <div className='text-center'>
                  <Spinner
                    as='span'
                    animation='grow'
                    size='sm'
                    role='status'
                    aria-hidden='true'
                  />
                </div>
              ) : (
                <RenderReport
                  reportData={reportData}
                  termsData={termsData}
                  authData={this.props.authData}
                  defaultTheme={this.props.defaultTheme}
                  reRenderTime={reRenderTime}
                  filter_result_type={filter_result_type}
                  student_name_column={this.state.student_name_column}
                  selectedClass={this.props.selectedClass}
                  selectedSubject={this.props.selectedSubject}
                  modalGraph={modalGraph}
                />
              )}
            </div>
          </div>
        </div>

        <Modal
          size='xl'
          show={modalGraph.show}
          onHide={modalGraph.toggle}
          backdrop='static'
          keyboard={false}>
          <Modal.Header closeButton>
            <Modal.Title></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {modalGraph.show && (
              <div className='d-flex flex-column'>
                {modalGraph.data.map((v) => {
                  return (
                    <div>
                      <Bar height={300} options={v.options} data={v.data} />
                    </div>
                  );
                })}
              </div>
            )}
          </Modal.Body>
        </Modal>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  authData: state.auth.authData,
  defaultLanguage: state.language.defaultLanguage,
  selectedClass: state.selectedClass.data,
  selectedSubject: state.selectedClass.subject,
  children: state.children.list,
  defaultTheme: state.theme.defaultTheme,
});

export default connect(mapStateToProps, null)(ReportResultRubric);

class RenderReport extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  shouldComponentUpdate(nextProps) {
    if (
      moment(nextProps.reRenderTime).isSame(moment(this.props.reRenderTime))
    ) {
      return false;
    }
    return true;
  }

  render() {
    let reportData = this.props.reportData;
    if (!reportData.finished) {
      return null;
    }
    console.log('reportData', reportData);
    return (
      <div>
        <RenderTable
          defaultTheme={this.props.defaultTheme}
          reportData={reportData}
          filter_result_type={this.props.filter_result_type}
          student_name_column={this.props.student_name_column}
          selectedClass={this.props.selectedClass}
          selectedSubject={this.props.selectedSubject}
          modalGraph={this.props.modalGraph}
        />
      </div>
    );
  }
}

const RenderTable = (props) => {
  const totalColspan = props.totalColspan;
  const filter_result_type = props.filter_result_type;
  const student_name_column = props.student_name_column;
  const reportData = props.reportData;
  const DDL_examstypegrp_types = DDL_ExamstypegrpTypes();
  const DDL_ExamstypeModes_DATA = DDL_ExamstypeModes();
  const tableRef = useRef(null);

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename:
      props.selectedClass.class.class_theme +
      ' - ' +
      props.selectedSubject.acs_name +
      ' - (Result-Rubric)',
    sheet: 'S1',
  });

  const calculateTypeTotal = (
    total_exams_grade,
    total_examsgrade_grade,
    examstype_mode
  ) => {
    console.log('examstype_mode', examstype_mode);
    let rData = { grade: 0, total_grade: 0 };
    if (examstype_mode == 'Sum') {
      rData['grade'] = total_examsgrade_grade.reduce((p, c) => p + c, 0);
      rData['total_grade'] = total_exams_grade.reduce((p, c) => p + c, 0);
    } else if (examstype_mode == 'Average_Grade') {
      let HCM = total_exams_grade.reduce((p, c) => p * c, 1);
      console.log('HCM', total_exams_grade, total_examsgrade_grade, HCM);
      let total = 0;
      total_examsgrade_grade.map((v, i) => {
        total += (v * HCM) / total_exams_grade[i]; // 3*125/5
      });
      //total=>325
      rData['grade'] =
        (total / total_exams_grade.length / HCM) *
        Math.max(...total_exams_grade); // 325
      rData['total_grade'] = Math.max(...total_exams_grade);
    } else if (examstype_mode == 'Highest_Grade') {
      total_exams_grade.map((v, i) => {
        let divide = total_examsgrade_grade[i] / v;
        let divideR = rData['grade'] / rData['total_grade'];
        console.log('divideR', divideR);
        if (divide > divideR || rData['total_grade'] == 0) {
          rData['grade'] = total_examsgrade_grade[i];
          rData['total_grade'] = v;
        }
      });
    } else if (examstype_mode == 'Highest_Multiplied') {
      total_exams_grade.map((v, i) => {
        let divide = total_examsgrade_grade[i] / v;
        let divideR = rData['grade'] / rData['total_grade'];
        if (divide > divideR || rData['total_grade'] == 0) {
          rData['grade'] = total_examsgrade_grade[i];
          rData['total_grade'] = v;
        }
      });
      rData['grade'] = rData['grade'] * total_exams_grade.length;
      rData['total_grade'] = rData['total_grade'] * total_exams_grade.length;
    }
    return rData;
  };
  var tCount = 0;

  var graphLabels = [];
  var graphDataInternal = [];
  var graphDataExternal = [];

  const generateGraph = () => {
    console.log(
      'generateGraph',
      graphLabels,
      graphDataInternal,
      graphDataExternal
    );
    let data = [];
    data.push({
      data: {
        labels: graphLabels,
        datasets: graphDataInternal,
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            position: 'bottom',
          },
          title: {
            display: true,
            text: 'Internal Exams',
          },
        },
      },
    });
    let uniqueGroups = [];
    graphDataExternal.map((v, i) => {
      v.data.map((vv, ii) => {
        if (!uniqueGroups[vv.label]) {
          uniqueGroups[vv.label] = {
            name: vv.label + ' (External) ',
            data: [
              { ...vv, label: v.label, backgroundColor: v.backgroundColor },
            ],
          };
        } else {
          uniqueGroups[vv.label].data.push({
            ...vv,
            label: v.label,
            backgroundColor: v.backgroundColor,
          });
        }
      });
    });
    Object.entries(uniqueGroups).map((v, i) => {
      data.push({
        data: {
          labels: graphLabels,
          datasets: v[1].data,
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              position: 'bottom',
            },
            title: {
              display: true,
              text: v[1].name,
            },
          },
        },
      });
    });
    props.modalGraph.toggle(data);
  };

  return (
    <Card>
      <Card.Header>
        <Row>
          <Col md={12}>
            <ButtonGroup size='sm' className='me-3 float-left'>
              {/* <Button><FontAwesomeIcon icon={faFilePdf} /> {t('PDF')}</Button> */}
              <Button onClick={() => onDownload()}>
                <FontAwesomeIcon icon={faFileExcel} /> {t('Excel')}
              </Button>
              <Button onClick={() => generateGraph()}>
                <FontAwesomeIcon icon={faPieChart} /> {t('Graph')}
              </Button>
            </ButtonGroup>
          </Col>
        </Row>
      </Card.Header>
      <Card.Body>
        <Table size='sm' striped responsive bordered ref={tableRef}>
          <thead>
            {/* CLASS AND SUBJECT */}
            <tr>
              <th rowSpan={'5'}>
                <div className='d-flex flex-row align-items justify-content-center'>
                  <div style={{ fontSize: 20 }}>{t('No')}.</div>
                </div>
              </th>
              <th rowSpan={'5'} style={{ minWidth: 200 }}>
                <div className='d-flex flex-row align-items justify-content-center'>
                  <div style={{ fontSize: 20 }}>{t('Student')}</div>
                </div>
              </th>
              {reportData.data.dataRubric.map((v, i) => {
                let term_d_id = v.term.term_d_id;
                let checkInternalExists = false;
                if (v.examstypegrps) {
                  v.examstypegrps.map((vv, ii) => {
                    if (vv.count > 0) {
                      if (vv.examstypegrp_type2 == 'Internal') {
                        checkInternalExists = true;
                      }
                      tCount++;
                    }
                    tCount += vv.count;
                  });
                }

                if (checkInternalExists) {
                  tCount++;
                }
              })}
              <th colSpan={tCount} style={{}}>
                <div style={{ fontSize: 16, textWrap: 'nowrap' }}>
                  {props.selectedClass.class.class_theme} -{' '}
                  {props.selectedSubject.acs_name}
                </div>
              </th>
            </tr>
            <tr>
              {/* TERM */}
              {reportData.data.dataRubric.map((v, i) => {
                let term_d_id = v.term.term_d_id;

                let count = 0;
                let checkInternalExists = false;
                if (v.examstypegrps) {
                  v.examstypegrps.map((vv, ii) => {
                    if (vv.count > 0) {
                      if (vv.examstypegrp_type2 == 'Internal') {
                        checkInternalExists = true;
                      }
                      count++;
                    }
                    count += vv.count;
                  });
                }

                if (checkInternalExists) {
                  count++;
                }

                return (
                  count > 0 && (
                    <th
                      key={i}
                      colSpan={count}
                      style={{
                        backgroundColor:
                          i % 2 == 0
                            ? brand_colors[props.defaultTheme.theme_id].color7
                            : brand_colors[props.defaultTheme.theme_id].color8,
                      }}>
                      <div style={{ fontSize: 16, textWrap: 'nowrap' }}>
                        {v.term['term_name']}
                      </div>
                    </th>
                  )
                );
              })}
            </tr>
            {/* INTERNAL / EXTERNAL */}
            <tr>
              {reportData.data.dataRubric.map((v, i) => {
                let term_d_id = v.term['term_d_id'];
                let total_count_internal = 0;
                let total_count_external = 0;

                reportData.data.examstypegrps.map((vv, ii) => {
                  if (vv.examstypegrp_type2 == 'Internal') {
                    let count = 1;
                    if (v.examstypegrps) {
                      v.examstypegrps.map((vvv) => {
                        if (
                          vvv.examstype_id == vv.examstype_id &&
                          vvv.examstypegrp_id == vv.examstypegrp_id
                        ) {
                          count += vvv.count;
                        }
                      });
                    }
                    if (count > 1) {
                      total_count_internal += count;
                    }
                  }
                  if (vv.examstypegrp_type2 == 'External') {
                    let count = 1;
                    if (v.examstypegrps) {
                      v.examstypegrps.map((vvv) => {
                        if (
                          vvv.examstype_id == vv.examstype_id &&
                          vvv.examstypegrp_id == vv.examstypegrp_id
                        ) {
                          count += vvv.count;
                        }
                      });
                    }
                    if (count > 1) {
                      total_count_external += count;
                    }
                  }
                });
                if (total_count_internal > 0) {
                  let backgroundColor = colorsArray[term_d_id].color_code;
                  graphDataInternal.push({
                    label: v.term['term_name'],
                    data: [],
                    backgroundColor: backgroundColor,
                  });
                }

                return (
                  <>
                    {total_count_internal > 0 && (
                      <th
                        colSpan={total_count_internal + 1}
                        style={{
                          backgroundColor:
                            brand_colors[props.defaultTheme.theme_id].color7,
                        }}>
                        <div style={{ fontSize: 16, textWrap: 'nowrap' }}>
                          {t('Internal')}
                        </div>
                      </th>
                    )}
                    {total_count_external > 0 && (
                      <th
                        colSpan={total_count_external}
                        style={{
                          backgroundColor:
                            brand_colors[props.defaultTheme.theme_id].color8,
                        }}>
                        <div style={{ fontSize: 16, textWrap: 'nowrap' }}>
                          {t('External')}
                        </div>
                      </th>
                    )}
                  </>
                );
              })}
            </tr>
            {/* GRP TYPE */}
            <tr>
              {reportData.data.dataRubric.map((v, i) => {
                let term_d_id = v.term['term_d_id'];
                let total_count_internal = 0;
                return (
                  <>
                    {/* ONLY INTERNAL */}
                    {reportData.data.examstypegrps.map((vv, ii) => {
                      if (vv.examstypegrp_type2 != 'Internal') {
                        return;
                      }
                      let count = 1;
                      if (v.examstypegrps) {
                        v.examstypegrps.map((vvv) => {
                          if (
                            vvv.examstype_id == vv.examstype_id &&
                            vvv.examstypegrp_id == vv.examstypegrp_id
                          ) {
                            count += vvv.count;
                          }
                        });
                      }

                      let this_examstypegrp_type = DDL_examstypegrp_types.find(
                        (v_examstypegrp_types) =>
                          v_examstypegrp_types.value == vv.examstypegrp_type
                      );

                      if (count > 1) {
                        total_count_internal += count;
                      }

                      let selected_examstype_modes =
                        DDL_ExamstypeModes_DATA.find(
                          (v, i) => v.value == vv.examstype_mode
                        );

                      return (
                        count > 1 && (
                          <th
                            key={ii}
                            colSpan={count}
                            style={{
                              whiteSpace: 'nowrap',
                              backgroundColor: changeColorOpacity(
                                this_examstypegrp_type?.color,
                                0.2
                              ),
                            }}>
                            <div>
                              <div>
                                {vv.examstype_name}{' '}
                                <span
                                  className='badge badge-light-info'
                                  title={
                                    t('Calculation Mode') +
                                    ': ' +
                                    selected_examstype_modes.desc
                                  }>
                                  {t(vv.examstype_mode.replace('_', ' '))}
                                </span>
                              </div>
                              <div
                                style={{ fontSize: 14, fontWeight: 'normal' }}>
                                {vv.examstypegrp_name} - ({vv.examstypegrp_type}
                                )
                              </div>
                            </div>
                          </th>
                        )
                      );
                    })}

                    {/* GRAND TOTAL OF INTERNALS */}
                    {total_count_internal > 0 && (
                      <th rowSpan={'2'}>
                        <div style={{ fontSize: 18, textWrap: 'nowrap' }}>
                          {t('Total Rounded')}
                        </div>
                      </th>
                    )}

                    {/* ONLY EXTERNAL */}
                    {reportData.data.examstypegrps.map((vv, ii) => {
                      if (vv.examstypegrp_type2 != 'External') {
                        return;
                      }
                      let count = 1;
                      if (v.examstypegrps) {
                        v.examstypegrps.map((vvv) => {
                          if (
                            vvv.examstype_id == vv.examstype_id &&
                            vvv.examstypegrp_id == vv.examstypegrp_id
                          ) {
                            count += vvv.count;
                          }
                        });
                      }

                      let this_examstypegrp_type = DDL_examstypegrp_types.find(
                        (v_examstypegrp_types) =>
                          v_examstypegrp_types.value == vv.examstypegrp_type
                      );

                      if (count > 0) {
                        let data = [];
                        for (let index = 1; index < count; index++) {
                          data.push({
                            label: vv.examstype_name,
                            data: [],
                            backgroundColor: generateColor(5),
                          });
                        }
                        if (data.length > 0) {
                          graphDataExternal.push({
                            label: v.term['term_name'],
                            backgroundColor: colorsArray[term_d_id].color_code,
                            data: data,
                          });
                        }
                      }

                      return (
                        count > 1 && (
                          <th
                            key={ii}
                            colSpan={count}
                            style={{
                              backgroundColor: changeColorOpacity(
                                this_examstypegrp_type?.color,
                                0.2
                              ),
                            }}>
                            <div>
                              <div>
                                {vv.examstype_name} ({vv.examstypegrp_name})
                              </div>
                              <div>
                                {vv.examstypegrp_type} -{' '}
                                <small>{vv.examstypegrp_type2}</small>
                              </div>
                            </div>
                          </th>
                        )
                      );
                    })}
                  </>
                );
              })}
            </tr>
            {/* EXAMS */}
            <tr>
              {reportData.data.dataRubric.map((v, i) => {
                let term_d_id = v.term['term_d_id'];

                return reportData.data.examstypegrps.map((vv, ii) => {
                  let this_examstypegrp_type = DDL_examstypegrp_types.find(
                    (v_examstypegrp_types) =>
                      v_examstypegrp_types.value == vv.examstypegrp_type
                  );
                  let total_exams_grade = 0;
                  let count_all_exams = 0;

                  return (
                    <>
                      {v.exams.map((vvv, iii) => {
                        if (
                          vvv.examstype_id == vv.examstype_id &&
                          vvv.examstypegrp_id == vv.examstypegrp_id &&
                          vvv.examstypegrp_type == vv.examstypegrp_type
                        ) {
                          count_all_exams++;
                          total_exams_grade += parseFloat(vvv.exams_grade);
                          return (
                            <td
                              key={iii}
                              style={{
                                backgroundColor: changeColorOpacity(
                                  this_examstypegrp_type?.color,
                                  0.1
                                ),
                              }}>
                              <div className='d-flex flex-column'>
                                <div
                                  style={{
                                    fontWeight: '600',
                                    textAlign: 'center',
                                  }}>
                                  {vvv.exams_name}
                                </div>
                                <div
                                  className='d-flex flex-row justify-content-around'
                                  style={{ fontSize: 14, textWrap: 'nowrap' }}>
                                  <div>
                                    (
                                    {moment(vvv.exams_date).format(
                                      'DD-MM-YYYY'
                                    )}
                                    )
                                  </div>
                                  <div className='mx-1'>
                                    ({t('Total')}: {parseFloat(vvv.exams_grade)}
                                    )
                                  </div>
                                </div>
                              </div>
                            </td>
                          );
                        }
                      })}
                      {count_all_exams > 0 && (
                        <th
                          style={{
                            backgroundColor: changeColorOpacity(
                              this_examstypegrp_type?.color,
                              0.1
                            ),
                          }}>
                          <div
                            className='d-flex flex-column'
                            style={{ textAlign: 'center' }}>
                            <div>{t('Total')}</div>
                          </div>
                        </th>
                      )}
                    </>
                  );
                });
              })}
            </tr>
          </thead>
          <tbody>
            {reportData.data.registrations.map((v, i) => {
              graphLabels.push(
                v[student_name_column.column] || v['child_name']
              );
              return (
                <tr key={i}>
                  <td>{i + 1}</td>
                  <td>
                    <div style={{ fontWeight: '600' }}>
                      {v[student_name_column.column] || v['child_name']}
                    </div>
                  </td>
                  {reportData.data.dataRubric.map((vv, ii) => {
                    let term_d_id = vv.term['term_d_id'];
                    let totalInternalByGrade = [];
                    let totalInternalByGradeT = [];
                    let num_all_exams_t = 0;
                    let graphDataExternalIndex = graphDataExternal.findIndex(
                      (vvv) => vvv.label == vv.term['term_name']
                    );
                    let graphDataInternalIndex = graphDataInternal.findIndex(
                      (vvv) => vvv.label == vv.term['term_name']
                    );
                    console.log(
                      'graphDataExternalIndex',
                      graphDataExternalIndex
                    );

                    reportData.data.examstypegrps.map((vvv, iii) => {
                      if (vvv.examstypegrp_type2 != 'Internal') {
                        return;
                      }
                      let total_exams_grade = [];
                      let total_examsgrade_grade = [];
                      let num_all_exams = 0;

                      vv.exams.map((vvvv, iiii) => {
                        if (
                          vvvv.examstype_id == vvv.examstype_id &&
                          vvvv.examstypegrp_id == vvv.examstypegrp_id &&
                          vvvv.examstypegrp_type == vvv.examstypegrp_type
                        ) {
                          num_all_exams++;
                          num_all_exams_t++;
                          let examsgrade = vvvv.examsgrade.find(
                            (vvvvv) =>
                              vvvvv.child_id == v.child_id &&
                              vvvvv.exams_id == vvvv.exams_id
                          );
                          if (
                            !examsgrade ||
                            (examsgrade?.absence_id != 0 &&
                              examsgrade?.absence_id != null)
                          ) {
                          } else {
                            total_exams_grade.push(
                              parseFloat(vvvv.exams_grade)
                            );
                            total_examsgrade_grade.push(
                              parseFloat(examsgrade?.examsgrade_grade)
                            );
                          }
                        }
                      });
                      let calculateTypeTotalData = { grade: 0, total_grade: 0 };
                      if (total_exams_grade.length > 0) {
                        calculateTypeTotalData = calculateTypeTotal(
                          total_exams_grade,
                          total_examsgrade_grade,
                          vvv.examstype_mode
                        );
                        totalInternalByGrade.push(
                          calculateTypeTotalData['grade']
                        );
                        totalInternalByGradeT.push(
                          calculateTypeTotalData['total_grade']
                        );
                      }
                    });

                    if (
                      filter_result_type.selected.value == 'Grade' &&
                      num_all_exams_t > 0
                    ) {
                      graphDataInternal[graphDataInternalIndex].data.push(
                        Math.round(
                          totalInternalByGrade.reduce((p, c) => p + c, 0)
                        )
                      );
                    } else if (
                      filter_result_type.selected.value == 'Rate' &&
                      num_all_exams_t > 0
                    ) {
                      graphDataInternal[graphDataInternalIndex].data.push(
                        (
                          (totalInternalByGrade.reduce((p, c) => p + c, 0) /
                            totalInternalByGradeT.reduce((p, c) => p + c, 0)) *
                          100
                        ).toFixed(2)
                      );
                    }

                    if (
                      filter_result_type.selected.value == 'Rate' &&
                      num_all_exams_t > 0
                    ) {
                      // MB & AHMAD
                      // var byRateGTotal = calculateTypeTotal(totalInternalByGradeT, totalInternalByGrade, 'Average_Grade')
                    }

                    return (
                      <>
                        {/* ONLY INTERNAL */}
                        {reportData.data.examstypegrps.map((vvv, iii) => {
                          if (vvv.examstypegrp_type2 != 'Internal') {
                            return;
                          }
                          let this_examstypegrp_type =
                            DDL_examstypegrp_types.find(
                              (v_examstypegrp_types) =>
                                v_examstypegrp_types.value ==
                                vvv.examstypegrp_type
                            );

                          let total_exams_grade = [];
                          let total_examsgrade_grade = [];
                          let num_all_exams = 0;

                          vv.exams.map((vvvv, iiii) => {
                            if (
                              vvvv.examstype_id == vvv.examstype_id &&
                              vvvv.examstypegrp_id == vvv.examstypegrp_id &&
                              vvvv.examstypegrp_type == vvv.examstypegrp_type
                            ) {
                              num_all_exams++;
                              num_all_exams_t++;
                              let examsgrade = vvvv.examsgrade.find(
                                (vvvvv) =>
                                  vvvvv.child_id == v.child_id &&
                                  vvvvv.exams_id == vvvv.exams_id
                              );
                              if (
                                !examsgrade ||
                                (examsgrade?.absence_id != 0 &&
                                  examsgrade?.absence_id != null)
                              ) {
                              } else {
                                total_exams_grade.push(
                                  parseFloat(vvvv.exams_grade)
                                );
                                total_examsgrade_grade.push(
                                  parseFloat(examsgrade?.examsgrade_grade)
                                );
                              }
                            }
                          });
                          let calculateTypeTotalData = {
                            grade: 0,
                            total_grade: 0,
                          };
                          if (total_exams_grade.length > 0) {
                            calculateTypeTotalData = calculateTypeTotal(
                              total_exams_grade,
                              total_examsgrade_grade,
                              vvv.examstype_mode
                            );
                          }

                          return (
                            <>
                              {vv.exams.map((vvvv, iiii) => {
                                if (
                                  vvvv.examstype_id == vvv.examstype_id &&
                                  vvvv.examstypegrp_id == vvv.examstypegrp_id &&
                                  vvvv.examstypegrp_type ==
                                    vvv.examstypegrp_type
                                ) {
                                  let examsgrade = vvvv.examsgrade.find(
                                    (vvvvv) =>
                                      vvvvv.child_id == v.child_id &&
                                      vvvvv.exams_id == vvvv.exams_id
                                  );

                                  if (
                                    !examsgrade ||
                                    (examsgrade?.absence_id != 0 &&
                                      examsgrade?.absence_id != null)
                                  ) {
                                    // || vvvv.exams_grade <= 0
                                    return (
                                      <td>
                                        <Badge bg={'danger'}>
                                          {examsgrade
                                            ? examsgrade.absence_name
                                            : t('Absent')}
                                        </Badge>
                                      </td>
                                    );
                                  } else {
                                    return (
                                      <td>{examsgrade?.examsgrade_grade}</td>
                                    );
                                  }
                                }
                              })}

                              {num_all_exams > 0 && (
                                <td
                                  style={{
                                    backgroundColor: changeColorOpacity(
                                      this_examstypegrp_type?.color,
                                      0.05
                                    ),
                                    fontSize: 16,
                                    fontWeight: '600',
                                  }}>
                                  {/* BY GRADE */}
                                  {filter_result_type.selected.value ==
                                    'Grade' && (
                                    <div style={{ textWrap: 'nowrap' }}>
                                      {calculateTypeTotalData['grade'].toFixed(
                                        2
                                      )}{' '}
                                      /{' '}
                                      {calculateTypeTotalData[
                                        'total_grade'
                                      ].toFixed(2)}
                                    </div>
                                  )}
                                  {/* BY RATE */}
                                  {filter_result_type.selected.value ==
                                    'Rate' && (
                                    <div style={{ textWrap: 'nowrap' }}>
                                      {(
                                        (calculateTypeTotalData['grade'] /
                                          calculateTypeTotalData[
                                            'total_grade'
                                          ]) *
                                        100
                                      ).toFixed(2)}
                                      %
                                    </div>
                                  )}
                                </td>
                              )}
                            </>
                          );
                        })}

                        {/* TOTAL INTERNAL */}
                        {/* BY GRADE */}
                        {filter_result_type.selected.value == 'Grade' &&
                          num_all_exams_t > 0 && (
                            <td>
                              <div
                                style={{
                                  fontWeight: '600',
                                  textAlign: 'center',
                                  fontSize: 18,
                                }}>
                                {
                                  Math.round(
                                    totalInternalByGrade.reduce(
                                      (p, c) => p + c,
                                      0
                                    )
                                  )
                                  // + '/' +
                                  // (totalInternalByGradeT.reduce((p, c) => p + c, 0)).toFixed(2) + ')'
                                }
                              </div>
                            </td>
                          )}
                        {/* TOTAL INTERNAL */}
                        {/* BY RATE */}
                        {filter_result_type.selected.value == 'Rate' &&
                          num_all_exams_t > 0 && (
                            <td>
                              <div
                                style={{
                                  fontWeight: '600',
                                  textAlign: 'center',
                                  fontSize: 18,
                                }}>
                                {/* {((byRateGTotal['grade'] / byRateGTotal['total_grade']) * 100).toFixed(2)}% */}
                                {(
                                  (totalInternalByGrade.reduce(
                                    (p, c) => p + c,
                                    0
                                  ) /
                                    totalInternalByGradeT.reduce(
                                      (p, c) => p + c,
                                      0
                                    )) *
                                  100
                                ).toFixed(2)}
                                %
                              </div>
                            </td>
                          )}

                        {/* ONLY EXTERNAL */}
                        {reportData.data.examstypegrps.map((vvv, iii) => {
                          if (vvv.examstypegrp_type2 != 'External') {
                            return;
                          }
                          let this_examstypegrp_type =
                            DDL_examstypegrp_types.find(
                              (v_examstypegrp_types) =>
                                v_examstypegrp_types.value ==
                                vvv.examstypegrp_type
                            );

                          let total_exams_grade = 0;
                          let total_examsgrade_grade = 0;
                          let num_all_exams = 0;

                          let graphDataExternalDataIndex = -1;
                          if (graphDataExternalIndex != -1) {
                            graphDataExternalDataIndex = graphDataExternal[
                              graphDataExternalIndex
                            ].data.findIndex(
                              (vvvv, iiii) => vvvv.label == vvv.examstype_name
                            );
                          }

                          vv.exams.map((vvvv, iiii) => {
                            if (
                              vvvv.examstype_id == vvv.examstype_id &&
                              vvvv.examstypegrp_id == vvv.examstypegrp_id &&
                              vvvv.examstypegrp_type == vvv.examstypegrp_type
                            ) {
                              num_all_exams++;
                              let examsgrade = vvvv.examsgrade.find(
                                (vvvvv) =>
                                  vvvvv.child_id == v.child_id &&
                                  vvvvv.exams_id == vvvv.exams_id
                              );
                              if (
                                !examsgrade ||
                                (examsgrade?.absence_id != 0 &&
                                  examsgrade?.absence_id != null) ||
                                vvvv.exams_grade <= 0
                              ) {
                                total_exams_grade += parseFloat(
                                  vvvv.exams_grade
                                );
                              } else {
                                total_exams_grade += parseFloat(
                                  vvvv.exams_grade
                                );
                                total_examsgrade_grade += parseFloat(
                                  examsgrade?.examsgrade_grade
                                );
                              }
                            }
                          });

                          if (
                            graphDataExternalIndex != -1 &&
                            graphDataExternalDataIndex != -1
                          ) {
                            if (
                              num_all_exams > 0 &&
                              filter_result_type.selected.value == 'Grade'
                            ) {
                              graphDataExternal[graphDataExternalIndex].data[
                                graphDataExternalDataIndex
                              ].data.push(total_examsgrade_grade);
                            } else if (
                              num_all_exams > 0 &&
                              filter_result_type.selected.value == 'Rate'
                            ) {
                              graphDataExternal[graphDataExternalIndex].data[
                                graphDataExternalDataIndex
                              ].data.push(
                                (
                                  (total_examsgrade_grade / total_exams_grade) *
                                  100
                                ).toFixed(2)
                              );
                            }
                          }

                          return (
                            <>
                              {vv.exams.map((vvvv, iiii) => {
                                if (
                                  vvvv.examstype_id == vvv.examstype_id &&
                                  vvvv.examstypegrp_id == vvv.examstypegrp_id &&
                                  vvvv.examstypegrp_type ==
                                    vvv.examstypegrp_type
                                ) {
                                  let examsgrade = vvvv.examsgrade.find(
                                    (vvvvv) =>
                                      vvvvv.child_id == v.child_id &&
                                      vvvvv.exams_id == vvvv.exams_id
                                  );

                                  if (
                                    !examsgrade ||
                                    (examsgrade?.absence_id != 0 &&
                                      examsgrade?.absence_id != null)
                                  ) {
                                    return (
                                      <td>
                                        <Badge bg={'danger'}>
                                          {examsgrade
                                            ? examsgrade.absence_name
                                            : t('Absent')}
                                        </Badge>
                                      </td>
                                    );
                                  } else {
                                    return (
                                      <td>{examsgrade?.examsgrade_grade}</td>
                                    );
                                  }
                                }
                              })}

                              {num_all_exams > 0 && (
                                <td
                                  style={{
                                    backgroundColor: changeColorOpacity(
                                      this_examstypegrp_type?.color,
                                      0.05
                                    ),
                                    fontSize: 16,
                                    fontWeight: '600',
                                  }}>
                                  {/* BY GRADE */}
                                  {filter_result_type.selected.value ==
                                    'Grade' && (
                                    <div style={{ textWrap: 'nowrap' }}>
                                      {num_all_exams > 0
                                        ? '(' +
                                          total_examsgrade_grade +
                                          '/' +
                                          total_exams_grade +
                                          ')'
                                        : '--'}
                                    </div>
                                  )}
                                  {/* BY RATE */}
                                  {filter_result_type.selected.value ==
                                    'Rate' && (
                                    <div style={{ textWrap: 'nowrap' }}>
                                      {num_all_exams > 0
                                        ? (
                                            (total_examsgrade_grade /
                                              total_exams_grade) *
                                            100
                                          ).toFixed(2) + '%'
                                        : '--'}
                                    </div>
                                  )}
                                </td>
                              )}
                            </>
                          );
                        })}
                      </>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
};
