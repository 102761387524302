import React, { Component } from 'react';
import { Container, Card, Row, Col } from 'react-bootstrap';
import { t } from '../../helpers/translation_helper';
import NavBar from '../_partials/NavBar/_NavBar';
import Header from '../_partials/Header/_Header';
import { connect } from 'react-redux';
import { Outlet } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faCheckCircle, faSearch, faUser, faXmark, faLayerGroup, faHandsHoldingChild, faPeopleGroup, faListCheck, faUserShield, faChartPie, faIdCardClip, faHouseUser, faDashboard } from '@fortawesome/free-solid-svg-icons';
import { brand_colors } from '../../helpers/brand_colors_helper';
import { Tooltip as Tooltiper } from 'react-tooltip';
import { NavLink } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import { changeColorOpacity, ifNavAllowed, isTeacher } from '../../helpers/general_helpers';
import { faAddressCard, faCalendarCheck, faIdCard } from '@fortawesome/free-regular-svg-icons';
import StudentsSidebar from './StudentsSidebar';

class Students extends Component {

  componentDidMount() {
  }

  render() {
    const { lang_orientation: dir } = this.props.defaultLanguage;
    return (
      <Container fluid>
        <div id='iedu-layout'>
          <NavBar />
          <div id='page-content' className='student-list'>
            <Header lite={true} heading={t('Students')} classSelectorFunc={[]} showClassSelector={true} showCenterSelector={true} backBtn={true} />
            <div style={{ display: 'flex' }}>

              <StudentsSidebar />

              <div style={{
                flex: 1,
                height: 'calc(100vh - 113px)',
                overflowY: 'auto',
                overflowX: 'hidden',
                paddingBottom: 10,
              }}>
                <div className='grey-section' style={{
                  padding: 5,
                  borderRadius: 25
                }}>
                  <div id='student-list-header' style={{
                    marginBottom: 5
                  }}>
                    <Card
                      className='border-0'
                      style={{ borderRadius: '20px' }}>
                      <Card.Body className='d-flex flex-row'>
                        {(ifNavAllowed('student-information') || ifNavAllowed('students')) &&
                          <div className='d-flex flex-row me-4'>
                            {ifNavAllowed('student-information') &&
                              <NavLink
                                className='nav-icon-btn'
                                data-tooltip-id="tooltip"
                                data-tooltip-content={t('Information')}
                                data-tooltip-place="top"
                                to={`/students/student-information`}>
                                <FontAwesomeIcon
                                  icon={faUser} color={brand_colors[this.props.defaultTheme.theme_id].color4}
                                />
                              </NavLink>
                            }
                            {ifNavAllowed('students') &&
                              <NavLink
                                className='nav-icon-btn'
                                data-tooltip-id="tooltip"
                                data-tooltip-content={t('Information')}
                                data-tooltip-place="top"
                                to={`/students/information`}>
                                <FontAwesomeIcon
                                  icon={faUser} color={brand_colors[this.props.defaultTheme.theme_id].color4}
                                />
                              </NavLink>
                            }
                          </div>
                        }

                        {(ifNavAllowed('parents')) &&
                          <div className='d-flex flex-row  me-4'>
                            {ifNavAllowed('parents') &&
                              <NavLink
                                className='nav-icon-btn'
                                data-tooltip-id="tooltip"
                                data-tooltip-content={t('Parents')}
                                data-tooltip-place="top"
                                to={`/students/parents`}>
                                <FontAwesomeIcon
                                  icon={faHouseUser} color={brand_colors[this.props.defaultTheme.theme_id].color1}
                                />
                              </NavLink>
                            }
                          </div>
                        }

                        {(ifNavAllowed('student-pastoral-care') || ifNavAllowed('student-groups') || ifNavAllowed('student-print-cards') || ifNavAllowed('student-reports')) &&
                          <div className='d-flex flex-row  me-4'>
                            {ifNavAllowed('student-pastoral-care') &&
                              <NavLink
                                className='nav-icon-btn'
                                data-tooltip-id="tooltip"
                                data-tooltip-content={t('Pastoral Care')}
                                data-tooltip-place="top"
                                to={`/students/pastoral-care`}>
                                <FontAwesomeIcon
                                  icon={faHandsHoldingChild} color={brand_colors[this.props.defaultTheme.theme_id].color2}
                                />
                              </NavLink>
                            }
                            {ifNavAllowed('student-groups') &&
                              <NavLink
                                className='nav-icon-btn'
                                data-tooltip-id="tooltip"
                                data-tooltip-content={t('Groups')}
                                data-tooltip-place="top"
                                to={`/students/groups`}>
                                <FontAwesomeIcon
                                  icon={faPeopleGroup} color={brand_colors[this.props.defaultTheme.theme_id].color2}
                                />
                              </NavLink>
                            }
                            {ifNavAllowed('student-print-cards') &&
                              <NavLink
                                className='nav-icon-btn'
                                data-tooltip-id="tooltip"
                                data-tooltip-content={t('Print Cards')}
                                data-tooltip-place="top"
                                to={`/students/student-print-cards`}>
                                <FontAwesomeIcon
                                  icon={faIdCard} color={brand_colors[this.props.defaultTheme.theme_id].color2}
                                />
                              </NavLink>
                            }
                            {ifNavAllowed('student-reports') &&
                              <NavLink
                                className='nav-icon-btn'
                                data-tooltip-id="tooltip"
                                data-tooltip-content={t('Reports')}
                                data-tooltip-place="top"
                                to={`/students/student-reports`}>
                                <FontAwesomeIcon
                                  icon={faChartPie} color={brand_colors[this.props.defaultTheme.theme_id].color2}
                                />
                              </NavLink>
                            }
                          </div>
                        }

                        {(ifNavAllowed('registrations-proforma-list') || ifNavAllowed('student-registration-simple') || ifNavAllowed('student-registration-advance')) &&
                          <div className='d-flex flex-row  me-4'>
                            {ifNavAllowed('student-registration-simple') &&
                              <NavLink
                                className='nav-icon-btn'
                                data-tooltip-id="tooltip"
                                data-tooltip-content={t('Simple Registration')}
                                data-tooltip-place="top"
                                to={`/students/student-registration-simple`}>
                                <FontAwesomeIcon
                                  icon={faIdCardClip} color={brand_colors[this.props.defaultTheme.theme_id].color22}
                                />
                              </NavLink>
                            }
                            {ifNavAllowed('student-registration-advance') &&
                              <NavLink
                                className='nav-icon-btn'
                                data-tooltip-id="tooltip"
                                data-tooltip-content={t('Advance Registration')}
                                data-tooltip-place="top"
                                to={`/students/student-registration-advance`}>
                                <FontAwesomeIcon
                                  icon={faIdCardClip} color={brand_colors[this.props.defaultTheme.theme_id].color22}
                                />
                              </NavLink>
                            }
                            {ifNavAllowed('registrations-proforma-list') &&
                              <NavLink
                                className='nav-icon-btn'
                                data-tooltip-id="tooltip"
                                data-tooltip-content={t('Proforma Registration')}
                                data-tooltip-place="top"
                                to={`/students/registrations-proforma-list`}>
                                <FontAwesomeIcon
                                  icon={faIdCardClip} color={brand_colors[this.props.defaultTheme.theme_id].color22}
                                />
                              </NavLink>
                            }
                          </div>
                        }


                        {(ifNavAllowed('student-attendance') || ifNavAllowed('student-custom-attendance')) &&
                          <div className='d-flex flex-row  me-4'>
                            {ifNavAllowed('student-attendance') &&
                              <NavLink
                                className='nav-icon-btn'
                                data-tooltip-id="tooltip"
                                data-tooltip-content={t('Attendance')}
                                data-tooltip-place="top"
                                to={`/students/attendance`}>
                                <FontAwesomeIcon
                                  icon={faListCheck} color={brand_colors[this.props.defaultTheme.theme_id].color16}
                                />
                              </NavLink>
                            }
                            {ifNavAllowed('student-custom-attendance') &&
                              <NavLink
                                className='nav-icon-btn'
                                data-tooltip-id="tooltip"
                                data-tooltip-content={t('Custom Attendance')}
                                data-tooltip-place="top"
                                to={`/students/custom-attendance`}>
                                <FontAwesomeIcon
                                  icon={faCalendarCheck} color={brand_colors[this.props.defaultTheme.theme_id].color16}
                                />
                              </NavLink>
                            }
                          </div>
                        }
                      </Card.Body>
                    </Card>
                  </div>
                  <Outlet />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Tooltiper id="tooltip" />
      </Container >
    );
  }
}

const mapStateToProps = (state) => ({
  authData: state.auth.authData,
  defaultLanguage: state.language.defaultLanguage,
  defaultTheme: state.theme.defaultTheme,
});

const mapDispatchToProps = () => ({

});

export default connect(mapStateToProps, mapDispatchToProps())(Students);
