import React, { Component } from 'react';
import { Container, Card, Table, Row, Col, Spinner, Image, Badge, Button, Form, Offcanvas, Accordion, Modal, ButtonGroup } from 'react-bootstrap';
import { t } from '../../helpers/translation_helper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp, faEnvelopeOpenText, faEye, faEyeSlash, faPlus, faPrint, faSave, faTrash, } from '@fortawesome/free-solid-svg-icons';
import { brand_colors } from '../../helpers/brand_colors_helper';
import { connect } from 'react-redux';
import axios from 'axios';
import { toast } from 'react-toastify';
import AssessLogRow from './AssessLogRow';
import Paginate from '../_partials/Paginate/_Paginate';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { GET } from '../../api';
import moment from 'moment';
import SweetAlert from 'react-bootstrap-sweetalert';
import { sendCloudMessage } from '../../helpers/cloud_message';

// API URL
const apiURL = process.env.REACT_APP_API_URL;


class AssessRepCard extends Component {
  state = {
    working: false,
    working_assess_logs: false,
    working_more_comments: false,
    workingEmail: false,
    working_EmailSummaryObservation: false,
    working_EmailLearningJournal: false,
    assrep_comm_visible: (this.props.data.Assrep.assrep_comm_visible == 1) ? true : false,
    showAssessmentLogs: false,
    assrep_comm1: this.props.data.Assrep.assrep_comm1,
    assrep_comm2: this.props.data.Assrep.assrep_comm2,
    assrep_comm3: this.props.data.Assrep.assrep_comm3,
    assrep_comm4: this.props.data.Assrep.assrep_comm4,
    assessLogs: [],
    assessLogslimit: 12,
    assessLogsTotalRecords: 0,
    showMoreCommentsModal: false,
    moreComments: null,
    assrep_posted: null,
    confirmModal: { show: false, id: 0, action: () => { }, message: '' },
  }

  componentDidMount() {
    const { assrep_posted } = this.props.data.Assrep;
    this.setState({
      assrep_posted: assrep_posted
    });
  }

  updateAssrep = async () => {

    this.setState({ working: true });

    const { authData } = this.props;
    const { auth_key } = authData.loginData;
    const { Assrep, AssrepHeader, childData } = this.props.data;
    const { assrep_id } = Assrep;
    const { assrep_comm1, assrep_comm2, assrep_comm3, assrep_comm4, assrep_comm_visible } = this.state;

    const fData = new FormData();
    fData.append('assrep_id', assrep_id);
    fData.append('assrep_comm1', assrep_comm1);
    fData.append('assrep_comm2', assrep_comm2);
    fData.append('assrep_comm3', assrep_comm3);
    fData.append('assrep_comm4', assrep_comm4);
    fData.append('assrep_comm_visible', (assrep_comm_visible) ? 1 : 0);

    try {
      const res = await axios.post(apiURL + 'student-tracker/update-assrep', fData, {
        params: {
          auth_key,
          appname: process.env.REACT_APP_NAME,
        }
      });

      if (res.data.type) {
        toast.success(t(res.data.message));
      }

      this.setState({ working: false });

    } catch (err) {
      console.log('err', err)
      toast.error(t('Something went wrong!'));
      this.setState({ working: false });
    }

  }

  getAssesslogData = async (limit = this.state.assessLogslimit, offset = 0) => {

    this.setState({
      working_assess_logs: true,
      showAssessmentLogs: true,
    });

    const { authData } = this.props;
    const { auth_key } = authData.loginData;
    const { center_id } = authData.centerData;
    const { Assrep, AssrepHeader, childData } = this.props.data;
    const { assrep_id, assrepheader_id } = Assrep;

    try {
      const res = await GET('student-tracker/get-assesslog-data', {
        params: {
          search: '',
          order: null,
          dir: null,
          limit,
          offset,
          assrepheader_id,
          assrep_id,
        }
      });

      if (res.status == 200) {
        this.setState({
          working_assess_logs: false,
          assessLogs: res.data.data,
          assessLogsTotalRecords: parseInt(res.data.total_count),
        });
      }

    } catch (err) {
      console.log('err', err)

      toast.error(t('Something went wrong!'));
      this.setState({ working_assess_logs: false });
    }

  }

  getAssrepDetails = async () => {
    this.setState({
      showMoreCommentsModal: true,
      moreComments: null
    });

    const { authData } = this.props;
    const { auth_key } = authData.loginData;
    const { center_id } = authData.centerData;
    const { Assrep, AssrepHeader, childData } = this.props.data;
    const { assrep_id, assrepheader_id } = Assrep;

    try {
      const res = await GET('student-tracker/get-assrep-details', {
        params: {
          assrepheader_id,
          assrep_id,
        }
      });

      if (res.status == 200) {
        this.setState({
          moreComments: res.data
        });
      }

    } catch (err) {
      console.log('err', err)

      toast.error(t('Something went wrong!'));
    }

  }

  addComment = () => {
    const comments = [...this.state.moreComments];
    comments.push({ assrep_detail_id: 0, assrep_detail_comment: '' });
    this.setState({ moreComments: comments });
  }

  delComment = (index) => {
    const comments = [...this.state.moreComments];
    comments.splice(index, 1);
    this.setState({ moreComments: comments });
  }


  saveAssrepDetails = async () => {

    this.setState({ working_more_comments: true });

    const { authData } = this.props;
    const { auth_key, user_id } = authData.loginData;
    const { center_id, center_timezone } = authData.centerData;
    const { Assrep, AssrepHeader, childData } = this.props.data;
    const { assrep_id, assrepheader_id } = Assrep;


    const fData = new FormData();
    fData.append('center_id', center_id);
    fData.append('center_timezone', center_timezone);
    fData.append('user_id', user_id);
    fData.append('assrep_id', assrep_id);
    fData.append('assrepheader_id', assrepheader_id);
    fData.append('assrep_details', JSON.stringify(this.state.moreComments));

    try {
      const res = await axios.post(apiURL + 'student-tracker/save-assrep-details', fData, {
        params: {
          auth_key,
          appname: process.env.REACT_APP_NAME,
        }
      });

      if (res.data.type) {
        toast.success(t(res.data.message));
      }

      this.setState({ working_more_comments: false, showMoreCommentsModal: false });

    } catch (err) {
      console.log('err', err)
      toast.error(t('Something went wrong!'));
      this.setState({ working_more_comments: false });
    }

  }

  emailReport = async (type, working) => {
    this.setState({
      [working]: true,
      confirmModal: {
        ...this.state.confirmModal, show: true, id: this.props.data.Assrep.assrep_id, message: 'Publish and send Email', action: async () => {
          const { assrep_id, assrep_report_type, assrepheader_id } = this.props.data.Assrep;
          const { child_id, parent_id } = this.props.data.childData;

          let cDateTime = moment().format('YYYY-MM-DD HH:mm:ss');
          let q_url = ''
          if (assrep_report_type == 'Detailed Observation') {
            q_url = 'pdfgenerator/assessment-detail-observation'
          }
          if (assrep_report_type == 'Summary Observation') {
            q_url = 'pdfgenerator/assessment-summary-observation'
          }
          if (assrep_report_type == 'Learning Journal') {
            q_url = 'pdfgenerator/assessment-learning-journal'
          }
          if (assrep_report_type == 'EY Report 1') {
            q_url = 'pdfgenerator/ey-report-1'
          }
          if (assrep_report_type == 'EY Report 2') {
            q_url = 'pdfgenerator/ey-report-2'
          }
          if (assrep_report_type == 'Progress Report') {
            q_url = 'pdfgenerator/ib-report'
          }

          try {
            const res = await GET('student-tracker/toggle-posting', {
              params: {
                child_id,
                parent_id,
                assrep_id,
                lang: this.props.defaultLanguage.lang_abv,
                assrep_posted: cDateTime,
                q_url
              }
            });
            if (res) {
              console.log('student-tracker/toggle-posting', res);
              if (res.data.type) {

                // SEND MAIL
                const res2 = await GET(type, {
                  params: {
                    assrepheader_id,
                    assrep_id,
                    lang: this.props.defaultLanguage.lang_abv,
                    toggle_comment_page: this.state.assrep_comm_visible,
                    mail: true,
                  }
                });
                this.setState({
                  [working]: false,
                  assrep_posted: cDateTime, confirmModal: {
                    ...this.state.confirmModal, show: false
                  }
                });
                if (cDateTime) {
                  res.data.sendCloudMessages.map((v, i) => {
                    sendCloudMessage(v.user_id, v.title, v.body, v.data)
                  })
                }
              } else {
                this.setState({
                  [working]: false,
                  confirmModal: {
                    ...this.state.confirmModal, show: false
                  }
                });
              }
              toast[res.data.messagetype](t(res.data.message));
            }
          } catch (err) {
            console.log(err);
            toast.error(t('Something went wrong!'));
            this.setState({
              [working]: false,
              confirmModal: {
                ...this.state.confirmModal, show: false
              }
            })
          }
        }
      }
    })
  }


  postingToggle = async () => {
    const { assrep_posted } = this.state;
    this.setState({
      confirmModal: {
        ...this.state.confirmModal, show: true, id: this.props.data.Assrep.assrep_id, message: (assrep_posted) ? 'Confirm Un-Publish' : 'Confirm Publish', action: async () => {
          const { assrep_id, assrep_report_type } = this.props.data.Assrep;
          const { child_id, parent_id } = this.props.data.childData;

          let cDateTime = null;
          if (assrep_posted) {
            cDateTime = null;
          } else {
            cDateTime = moment().format('YYYY-MM-DD HH:mm:ss');
          }
          let q_url = ''
          if (assrep_report_type == 'Detailed Observation') {
            q_url = 'pdfgenerator/assessment-detail-observation'
          }
          if (assrep_report_type == 'Summary Observation') {
            q_url = 'pdfgenerator/assessment-summary-observation'
          }
          if (assrep_report_type == 'Learning Journal') {
            q_url = 'pdfgenerator/assessment-learning-journal'
          }
          if (assrep_report_type == 'EY Report 1') {
            q_url = 'pdfgenerator/ey-report-1'
          }
          if (assrep_report_type == 'EY Report 2') {
            q_url = 'pdfgenerator/ey-report-2'
          }
          if (assrep_report_type == 'Progress Report') {
            q_url = 'pdfgenerator/ib-report'
          }

          try {
            const res = await GET('student-tracker/toggle-posting', {
              params: {
                child_id,
                parent_id,
                assrep_id,
                assrep_posted: cDateTime,
                q_url
              }
            });
            if (res) {
              console.log('student-tracker/toggle-posting', res);
              if (res.data.type) {
                this.setState({
                  assrep_posted: cDateTime, confirmModal: {
                    ...this.state.confirmModal, show: false
                  }
                });
                if (cDateTime) {
                  res.data.sendCloudMessages.map((v, i) => {
                    sendCloudMessage(v.user_id, v.title, v.body, v.data)
                  })
                }
              } else {
                this.setState({
                  confirmModal: {
                    ...this.state.confirmModal, show: false
                  }
                });
              }
              toast[res.data.messagetype](t(res.data.message));
            }
          } catch (err) {
            console.log(err);
            toast.error(t('Something went wrong!'));
            this.setState({
              confirmModal: {
                ...this.state.confirmModal, show: false
              }
            })
          }
        }
      }
    })
  }



  render() {
    const { assrep_posted } = this.state;
    const { Assrep, AssrepHeader, childData } = this.props.data;
    const { child_id, child_name, parent_id, parent_name } = childData;
    const { assrep_id, assrepheader_id, assrep_report_type } = Assrep;

    const { authData } = this.props;
    const { auth_key } = authData.loginData;
    const { center_id, center_uuid } = authData.centerData;


    var qs_obj = {
      assrep_id,
      assrepheader_id,
      auth_key,
      center_uuid,
      lang: this.props.defaultLanguage.lang_abv,
    };
    let qs = ''
    let qs2 = ''
    if (assrep_report_type == 'Detailed Observation') {
      qs = apiURL + 'pdfgenerator/assessment-detail-observation?' + new URLSearchParams(qs_obj).toString()
      qs2 = 'pdfgenerator/assessment-detail-observation'
    }
    if (assrep_report_type == 'Summary Observation') {
      qs = apiURL + 'pdfgenerator/assessment-summary-observation?' + new URLSearchParams(qs_obj).toString();
      qs2 = 'pdfgenerator/assessment-summary-observation'
    }
    if (assrep_report_type == 'Learning Journal') {
      qs = apiURL + 'pdfgenerator/assessment-learning-journal?' + new URLSearchParams(qs_obj).toString();
      qs2 = 'pdfgenerator/assessment-learning-journal'
    }
    if (assrep_report_type == 'EY Report 1') {
      qs = apiURL + 'pdfgenerator/ey-report-1?' + new URLSearchParams(qs_obj).toString();
      qs2 = 'pdfgenerator/ey-report-1'
    }
    if (assrep_report_type == 'EY Report 2') {
      qs = apiURL + 'pdfgenerator/ey-report-2?' + new URLSearchParams(qs_obj).toString();
      qs2 = 'pdfgenerator/ey-report-2'
    }
    if (assrep_report_type == 'Progress Report') {
      qs = apiURL + 'pdfgenerator/ib-report?' + new URLSearchParams(qs_obj).toString();
      qs2 = 'pdfgenerator/ib-report'
    }


    return (
      <Card className='ass-rep-card'>
        <Card.Body>
          <Row>
            <Col md={3} className='d-flex align-items-center'><h5 className='m-0'>{child_name}</h5></Col>
            <Col md={2} className='d-flex justify-content-end'>
              <ButtonGroup size="sm">
                <Button variant='info' onClick={() => window.open(qs, "PDF" + "_" + assrep_report_type + "_" + assrepheader_id + "_" + assrep_id)}><FontAwesomeIcon icon={faPrint} color={brand_colors[this.props.defaultTheme.theme_id].color8} /></Button>

                <Button disabled={this.state.workingEmail} onClick={() => this.emailReport(qs2, 'workingEmail')}>
                  {this.state.workingEmail ? <Spinner as='span' animation='grow' size='sm' role='status' aria-hidden='true' /> :
                    <FontAwesomeIcon icon={faEnvelopeOpenText} color={brand_colors[this.props.defaultTheme.theme_id].color8} />
                  }
                </Button>
              </ButtonGroup>
            </Col>
            <Col className='auto d-flex justify-content-end'>
              {['Detailed Observation', 'Summary Observation', 'Learning Journal', 'EY Report 1', 'EY Report 2'].includes(assrep_report_type) &&
                <>
                  <button className='btn btn-sm btn-warning me-2' onClick={() => this.state.showAssessmentLogs ? this.setState({ showAssessmentLogs: false }) : this.getAssesslogData()}>
                    {t('Assessments')} <FontAwesomeIcon icon={this.state.showAssessmentLogs ? faChevronUp : faChevronDown} color={brand_colors[this.props.defaultTheme.theme_id].color8} style={{ fontSize: 12 }} />
                  </button>
                  <button className='btn btn-sm btn-primary me-3' onClick={() => this.getAssrepDetails()}>
                    <FontAwesomeIcon icon={faPlus} color={brand_colors[this.props.defaultTheme.theme_id].color8} /> {t('Comments')}
                  </button>
                  <Form style={{ paddingTop: 5, marginRight: 10 }}>
                    <Form.Check
                      style={{ fontSize: 14, margin: 0 }}
                      type="switch"
                      id="custom-switch"
                      label={t('Comments')}
                      reverse={true}
                      defaultChecked={this.state.assrep_comm_visible}
                      onChange={() => this.setState({ assrep_comm_visible: !this.state.assrep_comm_visible }, () => this.updateAssrep())}
                    />
                  </Form>
                </>
              }
            </Col>
            <Col md={1} className='d-flex align-items-center justify-content-end'>
              {assrep_posted === null ?
                <FontAwesomeIcon
                  onClick={() => this.postingToggle()}
                  icon={faEyeSlash}
                  style={{
                    fontSize: 20,
                    cursor: 'pointer'
                  }}
                  color={brand_colors[this.props.defaultTheme.theme_id].color11} />
                :
                <FontAwesomeIcon
                  title={moment(assrep_posted).format('DD-MM-YYYY HH:mm:ss')}
                  onClick={() => this.postingToggle()}
                  icon={faEye}
                  style={{
                    fontSize: 20,
                    cursor: 'pointer'
                  }}
                  color={brand_colors[this.props.defaultTheme.theme_id].color4} />
              }
            </Col>
          </Row>
          {(this.state.assrep_comm_visible) &&
            <Row>
              <Col md={12}>
                <hr />
                <div className='d-flex flex-row justify-content-end'>
                  <button disabled={this.state.working || !this.state.assrep_comm_visible} className='btn btn-warning'
                    onClick={() => this.updateAssrep()}>
                    {this.state.working
                      ? <Spinner as='span' animation='grow' size='sm' role='status' aria-hidden='true' />
                      : <FontAwesomeIcon icon={faSave} color={brand_colors[this.props.defaultTheme.theme_id].color8} />
                    }
                  </button>
                </div>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>{t('Comment 1')}</Form.Label>
                  <Form.Control as="textarea" rows={3}
                    defaultValue={this.state.assrep_comm1}
                    onBlur={(e) => this.setState({ assrep_comm1: e.target.value })}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>{t('Comment 2')}</Form.Label>
                  <Form.Control as="textarea" rows={3}
                    defaultValue={this.state.assrep_comm2}
                    onBlur={(e) => this.setState({ assrep_comm2: e.target.value })}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>{t('Comment 3')}</Form.Label>
                  <Form.Control as="textarea" rows={3}
                    defaultValue={this.state.assrep_comm3}
                    onBlur={(e) => this.setState({ assrep_comm3: e.target.value })}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>{t('Comment 4')}</Form.Label>
                  <Form.Control as="textarea" rows={3}
                    defaultValue={this.state.assrep_comm4}
                    onBlur={(e) => this.setState({ assrep_comm4: e.target.value })}
                  />
                </Form.Group>
              </Col>
            </Row>
          }
          {this.state.showAssessmentLogs &&
            <>
              <hr />
              {this.state.working_assess_logs ? (
                <Row className='text-center'>
                  <Col className='d-flex align-items-center justify-content-center'>
                    <Spinner
                      as='span'
                      animation='grow'
                      size='sm'
                      role='status'
                      aria-hidden='true'
                      className='me-2'
                    />
                    {t('Fetching Assessments Logs...')}
                  </Col>
                </Row>
              ) : (
                <>
                  {this.state.assessLogs.length > 0 ?
                    <Row>
                      <Col md={12}>
                        <Table>
                          <thead>
                            <tr>
                              <th>{t('Date')}</th>
                              <th>{t('Assessment Name')}</th>
                              <th>{t('Parent View')}</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.assessLogs.map((log, log_i) => <AssessLogRow key={log_i} data={log} />)}
                          </tbody>
                        </Table>
                      </Col>
                    </Row>
                    :
                    <Row className='text-center'>
                      <Col className='d-flex align-items-center justify-content-center'>
                        {t('No Assessment Logs Found')}
                      </Col>
                    </Row>
                  }
                </>
              )}
              <Paginate
                limit={this.state.assessLogslimit}
                totalRecords={this.state.assessLogsTotalRecords}
                pageChange={this.getAssesslogData}
              />
            </>
          }
        </Card.Body>


        <Modal show={this.state.showMoreCommentsModal} onHide={() => this.setState({ showMoreCommentsModal: false })} size='lg'>
          <Modal.Header className='d-block'>
            <Row>
              <Col><Modal.Title>{t('More Comments')}</Modal.Title></Col>
              <Col className='d-flex justify-content-end'>
                <Button variant='success' onClick={() => this.addComment()}>
                  <FontAwesomeIcon icon={faPlus} color={brand_colors[this.props.defaultTheme.theme_id].color8} /> {t('Add Comment')}
                </Button>
              </Col>
            </Row>
          </Modal.Header>
          <Modal.Body className='more-comments'>
            {!this.state.moreComments &&
              <Row className='flex-column text-center'>
                <Col>
                  <Spinner
                    as='span'
                    animation='grow'
                    size='lg'
                    role='status'
                    aria-hidden='true'
                    className='mb-1'
                  />
                </Col>
                <Col>
                  {t('Fetching comments...')}
                </Col>
              </Row>
            }
            {this.state.moreComments && this.state.moreComments.length == 0 &&
              <Row className='flex-column text-center'>
                <Col>
                  {t('No Comments Found')}
                </Col>
              </Row>
            }
            {this.state.moreComments && this.state.moreComments.length > 0 && this.state.moreComments.map((comment, ci) => (
              <div className='mb-3 position-relative'>
                <Button variant='danger' style={{ position: 'absolute', top: 0, right: 0, zIndex: 99999, height: 39, borderRadius: 0 }} onClick={() => this.delComment(ci)}>
                  <FontAwesomeIcon icon={faTrash} color={brand_colors[this.props.defaultTheme.theme_id].color8} />
                </Button>
                <CKEditor
                  key={ci}
                  config={{
                    ckfinder: {
                      uploadUrl: apiURL + '/authentication/ckeditor-upload?auth_key=' + auth_key
                    },
                    removePlugins: ['Title'],
                    toolbar: {
                      items: [
                        'heading',
                        '|',
                        'bold',
                        'italic',
                        'alignment',
                        '|',
                        'numberedList',
                        'bulletedList',
                        '|',
                        'indent',
                        'outdent',
                        '|',
                        'link',
                        'blockQuote',
                        'imageUpload',
                        'insertTable',
                        '|',
                        'undo',
                        'redo'
                      ]
                    }
                  }}
                  style={{ height: 200 }}
                  editor={Editor}
                  data={this.state.moreComments[ci].assrep_detail_comment}
                  onChange={(
                    event,
                    editor
                  ) => {
                    const data = editor.getData();
                    const moreComments = [...this.state.moreComments];
                    moreComments[ci] = {
                      assrep_detail_id: comment.assrep_detail_id,
                      assrep_detail_comment: data
                    }

                    this.setState({ moreComments });
                  }}
                />
              </div>
            ))}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => this.setState({ showMoreCommentsModal: false })}>
              {t('Close')}
            </Button>
            <Button disabled={this.state.working_more_comments} variant="primary" onClick={() => this.saveAssrepDetails()}>
              {t('Save')}{' '}
              {this.state.working_more_comments
                && <Spinner as='span' animation='grow' size='sm' role='status' aria-hidden='true' />
              }
            </Button>
          </Modal.Footer>
        </Modal>

        <SweetAlert show={this.state.confirmModal.show} warning showCancel confirmBtnText={t('Yes, confirm !')} confirmBtnBsStyle='danger' title={t('Are you sure?')} onConfirm={this.state.confirmModal.action} onCancel={() => this.setState({ confirmModal: { ...this.state.confirmModal, show: false, id: 0, action: () => { } } })} focusCancelBtn>
          {t(this.state.confirmModal.message)}
        </SweetAlert>

      </Card>
    );
  }
}

const mapStateToProps = (state) => ({
  authData: state.auth.authData,
  defaultLanguage: state.language.defaultLanguage,
  selectedClass: state.selectedClass.data,
  children: state.children.list,
  defaultTheme: state.theme.defaultTheme,
});

export default connect(mapStateToProps, null)(AssessRepCard);