import React, { Component } from 'react';
import { Container, Card, Row, Col } from 'react-bootstrap';
import { t } from '../../helpers/translation_helper';
import NavBar from '../_partials/NavBar/_NavBar';
import Header from '../_partials/Header/_Header';
import { connect } from 'react-redux';
import { changeColorOpacity, ifNavAllowed } from '../../helpers/general_helpers';
import { brand_colors } from '../../helpers/brand_colors_helper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserShield, faDashboard, faUsers, faGraduationCap, faDownload, faAward } from '@fortawesome/free-solid-svg-icons';
import { NavLink } from 'react-router-dom';

class StudentsSidebar extends Component {

  state = {
    activeTab: {
      id: 0
    },
    nav: [
      {
        id: 'students-dashboard',
        name: 'Dashboard',
        icon: faDashboard,
        path: '/students-dashboard'
      },
      {
        id: ([3, 4, 15].includes(this.props.authData.loginData.role_id))
          ? 'students'
          : (ifNavAllowed('student-information')) ? 'student-information' : 'students',
        name: 'Students Information',
        icon: faUsers,
        path: ([3, 4, 15].includes(this.props.authData.loginData.role_id))
          ? '/students/information'
          : (ifNavAllowed('student-information')) ? '/students/student-information' : '/students/information',
      },
      {
        id: 'admin-reports',
        name: 'Admin Reports',
        icon: faUserShield,
        path: '/admin-reports'
      },
      {
        id: 'alumni-students',
        name: 'Alumni Students',
        icon: faGraduationCap,
        path: '/alumni-students'
      },
      {
        id: 'download-media',
        name: 'Download Media',
        icon: faDownload,
        path: '/download-media'
      },
      {
        id: 'student-promotion',
        name: 'Student Promotion',
        icon: faAward,
        path: '/student-promotion'
      },
    ],
  }

  render() {

    const { lang_orientation: dir } = this.props.defaultLanguage;

    return (
      <div className='inner-blue-bar'
        style={{
          backgroundColor: changeColorOpacity(brand_colors[this.props.defaultTheme.theme_id].sidebar_bg, 0.15),
          marginRight: dir == 1 ? 0 : 15,
          marginLeft: dir == 0 ? 0 : 15,
        }}>
        <ul>
          {this.state.nav.map((item, index) => (
            (ifNavAllowed(item.id))
              ? <li key={index}>
                <NavLink
                  activeclassname='active'
                  to={item.path}
                >
                  <FontAwesomeIcon
                    icon={item.icon}
                  />
                  <div className='px-2'>{t(item.name)}</div>
                </NavLink>
              </li>
              : null
          )
          )}
        </ul>
      </div>

    );
  }
}

const mapStateToProps = (state) => ({
  authData: state.auth.authData,
  defaultLanguage: state.language.defaultLanguage,
  defaultTheme: state.theme.defaultTheme,
});

export default connect(mapStateToProps, null)(StudentsSidebar);
