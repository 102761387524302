import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';

export const addEditRegistrationSimpleModalSlice = createSlice({
    name: 'addEditRegistrationSimpleModal',
    initialState: {
        showModal: false,
        title: 'New Registration',
        child: null,
        registrations: [],
        reg_date: moment().format('YYYY-MM-DD')
    },
    reducers: {
        toggleRegistrationSimpleModal: (state, action) => {
            state.showModal = !state.showModal;
            state.title = (action.payload && action.payload.title) ? action.payload.title : 'New Registration'
            state.reg_date = (action.payload && action.payload.reg_date) ? action.payload.reg_date : moment().format('YYYY-MM-DD')
            state.child = (action.payload && action.payload.child) ? action.payload.child : null
            state.registrations = (action.payload && action.payload.registrations) ? action.payload.registrations : []
        }
    },
});

export const { toggleRegistrationSimpleModal, updateRegistrationSimpleModal, addRegistration } = addEditRegistrationSimpleModalSlice.actions;
export default addEditRegistrationSimpleModalSlice.reducer;
