import React, { Component } from 'react';
import { Container, Card, Row, Col, Button, Spinner, InputGroup, Form, Table, Badge, Modal, Image, Offcanvas } from 'react-bootstrap';
import { t } from '../../../helpers/translation_helper';
import NavBar from '../../_partials/NavBar/_NavBar';
import Header from '../../_partials/Header/_Header';
import { connect } from 'react-redux';
import { brand_colors } from '../../../helpers/brand_colors_helper';
import { faArrowAltCircleLeft, faBorderNone, faBusinessTime, faCalendar, faCalendarTimes, faCheck, faChevronDown, faChevronUp, faCircle, faCircleArrowRight, faCircleCheckfaDatabase, faEnvelopeOpenText, faEye, faFilePdf, faPencil, faPlus, faPrint, faSearch, faShapes, faSpaghettiMonsterFlying, faTimeline, faTimes, faTimesCircle, faTrash, faUserTimes, faVolumeTimes, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DateRangePicker } from 'react-date-range';
import moment from 'moment';
import { GET, POST } from '../../../api';
import { numberFormat, uniqueByAttribute2 } from '../../../helpers/general_helpers';
import { faCalendarAlt, faCircleCheck, faFileExcel } from '@fortawesome/free-regular-svg-icons';
import { toast } from 'react-toastify';
import Select from 'react-select';
import Pagination from '../../_partials/Pagination';
import GeneralCard from "../../../helpers/GeneralCard";
import { Bar } from "react-chartjs-2";
var Chance = require('chance');
var chance = new Chance();
let cancelToken;

const apiURL = process.env.REACT_APP_API_URL;
const portalURL2 = process.env.REACT_APP_API_URL;

const barChartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
      position: 'bottom',
    },
  },
  scales: {
    x: {
      ticks: {
        callback: function (value) {
          return this.getLabelForValue(value).substr(0, 15) + '...';
        }
      }
    }
  }
};


class EmployeeEvaluationReport extends Component {

  state = {
    fetching: false,
    selectedDateRange: { startDate: new Date(moment().subtract(1, 'years')), endDate: new Date(), key: 'selection' },
    showDateRange: false,
    employees: [],
    showModalPerformance: false,
    showModal: false,
    showModalInfo: false,
    isEditing: false,
    evaluationDetail: null,
    evaluationPerformance: {
      details: [],
      subtopics: []
    },
    subTopics: [],
    topics: [],
    filter: {
      show: false,
      toggleFilter: (show) => this.setState({
        filter: {
          ...this.state.filter,
          show: (this.state.filter.show == show) ? false : show
        }
      }),
      setData: (key, value) => {
        this.setState({ filter: { ...this.state.filter, [key]: value }, showDateRange: false }, () => {
          // if (key == 'selectedClasses') {
          //   this.getClassesChildren(this.state.filter.selectedClasses, 'filter')
          // }
        })
      },
      selectedDateRange: { startDate: new Date(moment().subtract(1, 'years')), endDate: new Date(), key: 'selection' },
      selectedEmployee: [],
      keyword: '',
    },
    evaluations: null,
    employee: null,
    employeeList: [],
    appraisalgrp: null,
    appraisalcat: null,
    appraisaltopic: null,
    appraisaltopicsub: null,
    appraisalempldetails: [],
    attainments: [],
    appraisalemplData: {
      appraisalempl_date: null,
      employee_create: null,
      appraisalempl_id: null,
    },
    termData: [],
    employeeClassData: [],
    classSubjectsData: [],
    selectedClass: null,
    selectedTerm: null,
    selectedSubject: null,
    evaluationDetails: null,
    areasOfStrengthValue: null,
    areasToBeImprovedValue: null,
  }

  componentDidMount() {
    this.getEmployees();
    this.fetch({ getAllEmployees: true })
    this.handleGetAllTerm();
    this.getAttainments()
  }

  /***
   *
   * @returns {Promise<void>}
   */
  getEmployees = async () => {
    try {
      const res = await GET('employee-evaluation-report/get-all-employees-list',);
      console.log("Employees", res)
      if (res) {
        this.setState({ employeeList: res.data });
      }
    } catch (err) {
      console.log();
    }
  }

  /****
   *
   * @param params
   * @returns {Promise<void>}
   */
  fetch = async (params) => {
    const { selectedDateRange } = this.state;

    let params2 = {
      date_from: moment(selectedDateRange.startDate).format('YYYY-MM-DD') ?? null,
      date_to: moment(selectedDateRange.endDate).format('YYYY-MM-DD') ?? null,
      get_all_employees: params.getAllEmployees,
      created_by: this.props.authData.userData.employee_id,
    }
    if (this.state.filter.selectedEmployee.length > 0) {
      params2['employee_id'] = this.state.filter.selectedEmployee[0].value
    }
    console.log("paramsparamsparams", params2)
    if (this.fetchReq) {
      this.fetchReq.abort();
    }
    this.fetchReq = new AbortController();

    try {
      const res = await GET('employee-evaluation-report/get-employees', {
        signal: this.fetchReq.signal,
        params: params2,
      });
      console.log("resFeat", res)
      if (res.status === 200) {
        this.setState({
          fetching: false,
          employees: res.data
        });
      }

    } catch (err) {
      console.log('err', err)
    }
  }


  handleOpenModalPerformance = () => {
    this.setState({
      showModalPerformance: true
    });
  }

  handleCloseModalPerformance = () => {
    this.setState({
      showModalPerformance: false,
    });
  }



  getEvaluationPerformance = async (employee_id, date) => {


    let start_date = moment(date.startDate).format('YYYY-MM-DD');
    let end_date = moment(date.endDate).format('YYYY-MM-DD')

    try {
      const res = await GET('employee-evaluation-report/get-evaluation-performance', {
        params: {
          employee_id: employee_id,
          start_date: start_date,
          end_date: end_date,
          created_by: this.props.authData.userData.employee_id,
        }
      });
      console.log("getEvaluationPerformance", res.data)
      if (res.data) {
        this.setState({
          evaluationPerformance: res.data
        })
      }
    }
    catch (e) {
      console.log(e)
    }
  }

  handleCloseModal = () => {
    this.setState({
      showModal: false,
      showModalInfo: false,
      isEditing: false,
      employee: null,
      appraisalgrp: null,
      appraisalcat: null,
      appraisaltopic: null,
      appraisaltopicsub: null,
      appraisalempldetails: [],
      evaluation: null,
      evaluationDetails: null,
    });
  }

  handleOpenModal = async (data = null, isEditing = false) => {
    console.log('handleOpenModal Data', data);
    if (isEditing) {
      try {
        const res = await GET('appraisal/edit-appraisalempl', {
          params: {
            appraisalempl_id: data.appraisalempl_id
          }
        });
        const {
          appraisalempl_emloyee_id,
          employee_name,
          appraisalgrp_id,
          appraisalgrp_code,
          appraisalassessment,
          appraisalcat_id,
          appraisalcat_code,
          class_id,
          acs_id,
          term_id,
          class_theme,
          acs_name,
          term_name,
          appraisalempl_areas_of_strength,
          appraisalempl_areas_to_be_improved,
        } = res.data.appraisalempl;
        const classObject = { value: class_id, label: class_theme };
        this.setState({
          topics: res.data.topics,
          subTopics: res.data.sub_topics,
          employee: {
            value: appraisalempl_emloyee_id,
            label: employee_name,
          },
          appraisalgrp: {
            value: appraisalgrp_id,
            label: appraisalgrp_code,
            appraisalassessment,
          },
          appraisalcat: {
            value: appraisalcat_id,
            label: appraisalcat_code,
          },
          appraisalempldetails: res.data.appraisalempldetails,
          selectedClass: (class_id) ? classObject : null,
          selectedSubject: (acs_id) ? { value: acs_id, label: acs_name } : null,
          selectedTerm: (term_id) ? { value: term_id, label: term_name } : null,
          areasOfStrengthValue: appraisalempl_areas_of_strength,
          areasToBeImprovedValue: appraisalempl_areas_to_be_improved,
          evaluation: data,
          showModal: true,
          isEditing
        }, () => {
          this.handleGetAllEmployeeClass(appraisalempl_emloyee_id)
          if (class_id) {
            this.handleGetAllClassSubject(classObject, appraisalempl_emloyee_id)
          }
        });
      } catch (err) {
        console.log(err);
      }
    } else {
      this.setState({
        evaluation: data,
        showModal: true,
        isEditing
      });
    }

  }

  handleOpenModalInfo = async (data = null, isEditing = false) => {
    console.log('handleOpenModalInfo Data', data);

    this.setState({
      evaluation: data,
      showModalInfo: true,
      isEditing
    });

    if (isEditing) {
      try {
        const res = await GET('appraisal/edit-appraisalempl', {
          params: {
            appraisalempl_id: data.appraisalempl_id
          }
        });

        console.log('handleOpenModalInfo Async Data', res.data);

        const {
          appraisalempl_emloyee_id,
          employee_name,
          appraisalgrp_id,
          appraisalgrp_code,
          appraisalassessment,
          appraisalcat_id,
          appraisalcat_code,
          appraisalempl_date,
          employee_create,
          appraisalempl_id,
          class_theme,
          acs_name,
          term_name,
          appraisalempl_areas_of_strength,
          appraisalempl_areas_to_be_improved,
        } = res.data.appraisalempl;

        this.setState({
          topics: res.data.topics,
          subTopics: res.data.sub_topics,
          appraisalempldetails: res.data.appraisalempldetails,
          appraisalemplData: {
            appraisalempl_date,
            employee_create,
            appraisalempl_id,
            class_theme,
            acs_name,
            term_name,
            appraisalempl_areas_of_strength,
            appraisalempl_areas_to_be_improved,
          },
          employee: {
            value: appraisalempl_emloyee_id,
            label: employee_name,
          },
          appraisalgrp: {
            value: appraisalgrp_id,
            label: appraisalgrp_code,
            appraisalassessment,
          },
          appraisalcat: {
            value: appraisalcat_id,
            label: appraisalcat_code,
          },

        });

      } catch (err) {
        console.log(err);
      }
    }

  }

  saveEvaluation = async () => {
    console.log("saveEvaluation", this.state);
    this.setState({
      working: true
    });

    const {
      authData
    } = this.props;

    const {
      evaluation,
      employee,
      appraisalgrp,
      appraisalcat,
      isEditing,
      appraisalempldetails
    } = this.state;

    const appraisalempldetails_filtered = appraisalempldetails.filter(item => item.appraisalempl_comments || item.appraisalempl_grade || item.apprassmt_id);


    try {
      let class_id = this.state.selectedClass ? this.state.selectedClass.value : 0
      let term_id = this.state.selectedTerm ? this.state.selectedTerm.value : 0
      let acs_id = this.state.selectedSubject ? this.state.selectedSubject.value : 0
      let areas_of_strength = this.state.areasOfStrengthValue
      let areas_to_be_improved = this.state.areasToBeImprovedValue
      const fData = new FormData();
      fData.append('center_id', authData.centerData.center_id);
      fData.append('appraisalgrp_id', appraisalgrp.value);
      fData.append('appraisalcat_id', appraisalcat.value);
      fData.append('appraisalempl_emloyee_id', employee.value);
      fData.append('class_id', class_id)
      fData.append('term_id', term_id)
      fData.append('acs_id', acs_id)
      fData.append('areas_of_strength', areas_of_strength)
      fData.append('areas_to_be_improved', areas_to_be_improved)
      if (evaluation) {
        fData.append('appraisalempl_id', evaluation.appraisalempl_id);
      } else {
        fData.append('appraisalempl_id', 0);
      }

      if (authData.userData) {
        fData.append('employee_id', authData.userData.employee_id);
      } else {
        fData.append('employee_id', 0);
      }

      fData.append('appraisalempldetails', JSON.stringify(appraisalempldetails_filtered));

      const res = await POST('appraisal/save-appraisal', fData);
      console.log('saveEvaluation', res);

      if (res.data.type) {
        toast.success(t(res.data.message));
        this.setState({
          working: false,
          showModal: false,
        });

      } else {
        toast.error(t(res.data.message));
        this.setState({
          working: false
        });

      }
    } catch (err) {
      toast.error('Something went wrong!');
      this.setState({
        working: false
      });
      console.log(err);
    }

  }

  getAttainments = async () => {
    try {
      const res = await GET('appraisal/get-appraisalassessment');
      console.log('getAttainments', res);
      if (res.status == 200) {
        this.setState({
          attainments: res.data
        });
      }
    } catch (err) {
      console.log(err);
    }
  }

  saveEvaluationDetails = (field, value, sub, topic) => {
    const { appraisaltopicsub, appraisalempldetails } = this.state;
    const findEvaluationIndex = appraisalempldetails.findIndex(item => item.appraisaltopic_id == topic && item.appraisaltopicsub_id == sub);
    appraisalempldetails[findEvaluationIndex][field] = value;

    this.setState({
      appraisalempldetails,
    });

  }

  handleSumGrade = () => {
    let employeeGrade = 0;
    let maxGrade = 0;
    let results = 0;
    this.state.topics.map((item_topic, index) => {
      this.state.subTopics.map((sub_item, index_sub) => {
        let subTopicLength = this.state.subTopics.length;
        if (sub_item.appraisaltopic_id === item_topic.appraisaltopic_id) {
          this.state.appraisalempldetails.map((details, index_details) => {
            if (details.appraisaltopicsub_id == sub_item.appraisaltopicsub_id) {
              employeeGrade += parseInt(details.appraisalempl_grade);
              maxGrade += parseInt(sub_item.max_grade);
            }
          })
        }
      })
    })
    results = !isNaN((employeeGrade / maxGrade) * 100) ? (employeeGrade / maxGrade) * 100 : 0;
    return results.toFixed(2);
  }

  handleGetAllTerm = async () => {
    try {
      const res = await GET('appraisal/get-terms-centerlic')
      console.log("TermceternLic", res.data)
      if (res) {
        this.setState({
          termData: res.data.map(term => {
            return {
              label: term.term_name,
              value: term.term_id,
            }
          })
        })
      }
    }
    catch (e) {
      console.log(e)
    }
  }

  handleGetRoleId = (employee_id) => {
    let emp = this.state.employeeList.find(item => item.employee_id == employee_id)
    return emp.role_id;
  }

  handleGetAllClassSubject = async (obj, employee_id) => {
    this.setState({ selectedClass: obj })
    try {
      const res = await GET('appraisal/get-assigned-subjects', {
        params: {
          class_id: obj.value,
          employee_id: employee_id,
          role_id: this.handleGetRoleId(employee_id)
        }
      })
      console.log("handleGetAllClassSubject", res.data)
      if (res) {
        let uniqueSubject = uniqueByAttribute2(res.data, ['acs_id']);
        this.setState({
          classSubjectsData: uniqueSubject.map(subject => {
            return {
              label: subject.acs_name,
              value: subject.acs_id
            }
          })
        })
      }
    }
    catch (e) {
      console.log(e)
    }
  }

  handleGetAllEmployeeClass = async (employee_id) => {
    let employeeId;
    if (this.state.isEditing) {
      employeeId = employee_id
    }
    else {
      employeeId = this.state.employee ? this.state.employee.employee_id : 0
    }

    try {
      const res = await GET('appraisal/get-assigned-classes', {
        params: {
          employee_id: employeeId
        }
      })
      this.setState({
        employeeClassData: res.data.map(item => {
          return {
            label: item.class_theme,
            value: item.class_id
          }
        })
      })
      console.log("employeeClass", res.data)
    }
    catch (e) {
      console.log(e)
    }
  }

  openPDF = (lang) => {
    let appraisalEmployee = {
      appraisalempl_id: this.state.appraisalemplData.appraisalempl_id,
      topics: this.state.topics,
      auth_key: this.props.authData.loginData.auth_key,
      center_id: this.props.authData.centerData.center_id,
      sum_grades: this.handleSumGrade(),
      lang: lang
    };
    const qURL = portalURL2 + 'appraisal/evaluation-employee-export?' + new URLSearchParams(appraisalEmployee).toString()
    window.open(qURL, "PDF" + "_EmployeeEvaluation_")
  }

  render() {
    console.log('STATES', this.state);
    return (
      <Container fluid className='mt-2'>
        <div className='grey-section'>
          <Row>
            <Col md={3} className='position-relative pe-0'>
              <InputGroup>
                <Form.Control
                  onClick={() => this.setState({ showDateRange: true })}
                  style={{
                    paddingLeft: 20,
                    borderTopLeftRadius: 20,
                    borderBottomLeftRadius: this.state.showDateRange ? 7 : 20,
                  }}
                  value={
                    moment(this.state.selectedDateRange.startDate).format('DD-MM-YYYY') + ' -- ' + moment(this.state.selectedDateRange.endDate).format('DD-MM-YYYY')
                  }
                  readOnly
                />
                <Button variant={this.state.showDateRange ? 'success' : 'primary'}
                  style={{ width: 40 }}
                  onClick={() => this.setState({ showDateRange: !this.state.showDateRange })}>
                  <FontAwesomeIcon icon={this.state.showDateRange ? faCheck : faCalendarAlt} style={{ fontSize: 16, color: brand_colors[this.props.defaultTheme.theme_id].color8, }} />
                </Button>
              </InputGroup>
              {this.state.showDateRange &&
                <div style={{
                  backgroundColor: '#fff',
                  padding: 10,
                  position: 'absolute',
                  border: '#F2F2F2 2px solid',
                  zIndex: 1,
                  top: 37,
                  borderRadius: 7
                }}>
                  <DateRangePicker
                    ranges={[this.state.selectedDateRange]}
                    onChange={(date) => {
                      this.setState({
                        selectedDateRange: { startDate: new Date(date.selection.startDate), endDate: new Date(date.selection.endDate), key: 'selection' }
                      })
                    }}
                    style={{ backgroundColor: '#fff' }}
                  />
                </div>
              }
            </Col>
            <Col md={3} className='pe-0'>

              <div className="filter filter-students">
                <div className="filter-trigger d-none"
                  onClick={() => this.state.filter.toggleFilter('employee')}>
                  <FontAwesomeIcon
                    icon={faSearch}
                    color={brand_colors[this.props.defaultTheme.theme_id].color18}
                    className="filter-arrow"
                  />
                </div>
                <div className="filter-box" style={{ display: 'initial' }}>
                  <Select
                    isClearable
                    menuPortalTarget={document.body}
                    onChange={(event) => { this.state.filter.setData('selectedEmployee', event ? [event] : []) }}
                    styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999, }), }}
                    options={this.state.employeeList.map((v, i) => { return { value: v.employee_id, label: v.employee_name } })}
                  />
                </div>
              </div>
            </Col>
            <Col>
              <Button variant={'success'} onClick={() => this.fetch({ getAllEmployees: false })}>
                {this.state.fetching
                  ? <Spinner as='span' animation='grow' size='sm' role='status' aria-hidden='true' />
                  : <FontAwesomeIcon icon={faSearch} style={{ fontSize: 16, color: brand_colors[this.props.defaultTheme.theme_id].color8, }} />
                }
              </Button>
            </Col>
          </Row>
        </div>
        <Card
          className='border-0'
          style={{ borderRadius: '20px' }}>
          <Card.Body>
            <div>
              {this.state.employees.map((v, i) => {
                if (v.appraisal && v.appraisal.length <= 0) {
                  return null
                }
                return <div className={'d-flex flex-row mb-1 p-1'} style={{ width: '100%', borderBottom: 'solid 1px #ccc' }}>
                  <div style={{ width: '20%' }} className={'me-1 pe-1'}>
                    <strong>
                      {v.employee.label}
                    </strong>
                    <div style={{ position: "relative", marginTop: 40, marginLeft: -15 }}>
                      {/*<span style={{ borderTop: "1px solid #ccc", padding: 10, width: 50 }}></span>*/}
                      <div style={{ position: "absolute", bottom: '-3%', left: 11 }}>
                        <Button variant={'success'} size="sm" onClick={() => { this.handleOpenModalPerformance(); this.getEvaluationPerformance(v.employee.value, this.state.selectedDateRange) }}>
                          Performance
                        </Button>
                      </div>
                    </div>
                  </div>

                  <div className={'d-flex flex-row flex-wrap ms-1 ps-1'} style={{ flex: 1, borderLeft: 'solid 1px #ccc' }}>
                    {v.appraisal &&
                      v.appraisal.map((vv, ii) => {
                        let data = {
                          name: vv.appraisalgrp_code,
                          text2: <><br />{vv.appraisalempl_date}</>,
                          text1: vv.appraisalcat_code,
                          actions: [{
                            type: 'edit',
                            name: 'Edit',
                            image: require('../../../assets/images/edit.png'),
                            onClick: () => this.handleOpenModal(vv, true)
                          },
                          {
                            type: 'report',
                            name: 'Report',
                            image: require('../../../assets/images/info-slim-yellow.png'),
                            onClick: () => this.handleOpenModalInfo(vv, true)
                          },],
                          description: <div>
                            <div>{vv.term_name != 'null' ? vv.term_name : '---'}</div>
                            <div>{vv.class_theme != 'null' ? vv.class_theme : '---'}</div>
                            <div>{(vv.acs_name != 'null' ? vv.acs_name : '---')}</div>
                          </div>,
                        }
                        return <GeneralCard data={data} />
                      })
                    }


                  </div>
                </div>
              })}
            </div>
          </Card.Body>
        </Card>
        <Offcanvas show={this.state.showModal} onHide={this.handleCloseModal} placement="bottom" style={{ height: '100%', width: '60%' }}>
          <Offcanvas.Header closeButton className='pb-0'>
            <Offcanvas.Title>{t('Evaluation')}</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <div className='d-flex flex-column' style={{ height: '100%' }}>
              <div className='custom-styled-form' style={{ flex: 1 }}>
                {/* Add Mode Start*/}
                {/*{!this.state.isEditing &&*/}
                {/*<div style={{backgroundColor:brand_colors[this.props.defaultTheme.theme_id].header_bg}} className="filter-bar">*/}

                {/*</div>*/}

                {/*}*/}
                {this.state.isEditing &&
                  <>
                    <div className='text-center mb-3'>
                      <h5 className='mb-0'>{this.state.employee?.label}</h5>
                      {this.state.appraisalgrp?.label}<br />
                      {this.state.appraisalcat?.label}
                    </div>
                    {/* New Design Start */}
                    {this.state.topics &&
                      <>
                        <div className='eval-topics-sub-topics-heading'>
                        </div>
                        <Row>
                          {/*DATE */}
                          <Col md={4} className='position-relative pe-0'>
                            <Form.Label>{t('Term')}</Form.Label>
                            <Select
                              placeholder={t('Select Term')}
                              options={this.state.termData}
                              onChange={(event) => this.setState({ selectedTerm: event })}
                              value={this.state.selectedTerm}
                              menuPortalTarget={document.body}
                              styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                              formatOptionLabel={option => (
                                <div className='d-flex align-items-center'>
                                  <span style={{ fontSize: 16 }} className='me-1'>{option.label}</span>
                                  {option.reg_status == 1 &&
                                    <Badge pill bg="success">{t('Active')}</Badge>}
                                </div>
                              )}
                            />
                          </Col>
                          <Col md={4} className='pe-0 custom-styled-form'>
                            <Form.Label>{t('Class')}</Form.Label>
                            <Select
                              placeholder={t('Select Class')}
                              options={this.state.employeeClassData}
                              onChange={this.handleGetAllClassSubject}
                              value={this.state.selectedClass}
                              menuPortalTarget={document.body}
                              styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                              formatOptionLabel={option => (
                                <div className='d-flex align-items-center'>
                                  <span style={{ fontSize: 16 }} className='me-1'>{option.label}</span>
                                  {option.reg_status == 1 &&
                                    <Badge pill bg="success">{t('Active')}</Badge>}
                                </div>
                              )}
                            />
                          </Col>
                          <Col md={4} className='pe-0 custom-styled-form'>
                            <Form.Label>{t('Subject')}</Form.Label>
                            <Select
                              placeholder={t('Select Subject')}
                              options={this.state.classSubjectsData}
                              onChange={(obj) => {
                                this.setState({ selectedSubject: obj })
                              }}
                              value={this.state.selectedSubject}
                              menuPortalTarget={document.body}
                              styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                              formatOptionLabel={option => (
                                <div className='d-flex align-items-center'>
                                  <span style={{ fontSize: 16 }} className='me-1'>{option.label}</span>
                                  {option.term_active == 1 &&
                                    <Badge pill bg="success">{t('Active')}</Badge>}
                                </div>
                              )}
                            />
                          </Col>
                        </Row>


                        <div className='eval-topics-sub-topics1 mb-3'>
                          {this.state.topics.map((item_topic, index) => {
                            return <>
                              <Row style={{ marginBottom: 20 }}>
                                <Col key={index} md={12}>
                                  <span key={index} style={{
                                    backgroundColor: '#F2F2F2',
                                    borderColor: '#D1F6DA',
                                    padding: 10,
                                    borderRadius: 10,
                                    fontWeight: "bold",
                                    fontSize: 20
                                  }}
                                  >
                                    {item_topic.appraisaltopic_code}
                                  </span>
                                  {
                                    this.state.subTopics.map((sub_item, index_sub) => {
                                      let subTopicLength = this.state.subTopics.length;
                                      if (sub_item.appraisaltopic_id === item_topic.appraisaltopic_id) {
                                        return this.state.appraisalempldetails.map((details, index_details) => (
                                          (details.appraisaltopicsub_id == sub_item.appraisaltopicsub_id) &&
                                          <>
                                            <Row key={index_sub}>
                                              <Col md={12} style={{ marginLeft: 20, marginTop: 24 }}>
                                                <span key={index}
                                                  style={{
                                                    backgroundColor: '#F2F2F2',
                                                    borderColor: '#F2F2F2',
                                                    borderRadius: 10,
                                                    marginBottom: 10,
                                                    padding: 10
                                                  }}
                                                >
                                                  {sub_item.appraisaltopicsub_code}
                                                </span>
                                              </Col>
                                            </Row>
                                            <Row className='mb-2' style={{ marginLeft: 20, marginTop: 24 }}>
                                              <Col md={12} >
                                                <div style={{
                                                  backgroundColor: '#F2F2F2',
                                                  borderRadius: 10,
                                                  padding: 20,
                                                  minHeight: 114
                                                }}>
                                                  {sub_item.appraisaltopicsub_desc &&
                                                    <p>{sub_item.appraisaltopicsub_desc}</p>
                                                  }
                                                  {sub_item.appraisaltopicsub_desc2 &&
                                                    <p className="mb-0">{sub_item.appraisaltopicsub_desc2}</p>
                                                  }
                                                </div>
                                                <div>
                                                  <Form.Group className="mb-3">
                                                    <Form.Label>{t('Comment')}</Form.Label>
                                                    <Form.Control as="textarea" style={{
                                                      backgroundColor: '#F2F2F2',
                                                      borderColor: '#D1F6DA',
                                                    }} rows={4}
                                                      defaultValue={details.appraisalempl_comments}
                                                      onChange={(e) => this.saveEvaluationDetails('appraisalempl_comments', e.target.value, sub_item.appraisaltopicsub_id, sub_item.appraisaltopic_id)} />
                                                  </Form.Group>
                                                </div>
                                              </Col>
                                              <Col md={12}></Col>
                                            </Row>
                                            <Row style={{ marginLeft: 20, marginTop: 24 }}>
                                              <Col>

                                                {this.state.appraisalgrp &&
                                                  this.state.appraisalgrp.appraisalassessment == 1 ?
                                                  <div>
                                                    {this.state.attainments.map((item, index) => {

                                                      let bg = 'transparent';
                                                      let color = item.apprassmt_color;
                                                      const matchingDetail = this.state.appraisalempldetails.find(details => details.apprassmt_id === item.apprassmt_id && details.appraisaltopicsub_id == sub_item.appraisaltopicsub_id &&
                                                        details.appraisaltopic_id == sub_item.appraisaltopic_id)

                                                      if (matchingDetail) {
                                                        bg = item.apprassmt_color;
                                                        color = '#fff';
                                                      }

                                                      return (
                                                        <button key={index}
                                                          onClick={() => this.saveEvaluationDetails('apprassmt_id', item.apprassmt_id, sub_item.appraisaltopicsub_id, sub_item.appraisaltopic_id)}
                                                          style={{
                                                            borderColor: item.apprassmt_color,
                                                            borderWidth: 2,
                                                            borderStyle: 'solid',
                                                            color: color,
                                                            height: 35,
                                                            borderRadius: 10,
                                                            fontSize: 15,
                                                            marginRight: 10,
                                                            backgroundColor: bg,
                                                            paddingInline: 15
                                                          }}>{item.apprassmt_code}</button>
                                                      );
                                                    })}
                                                  </div>
                                                  :
                                                  <div>
                                                    {Array.from({ length: (parseInt(sub_item.max_grade) + 1) }).map((_, index) => {

                                                      let bg = '#F2F2F2';
                                                      let color = '#7B7B7B';

                                                      let grade = index.toString();

                                                      if (details.appraisalempl_grade == grade) {
                                                        bg = brand_colors[this.props.defaultTheme.theme_id].color1;
                                                        color = '#fff';
                                                      }


                                                      return (

                                                        <button key={index}
                                                          onClick={() => this.saveEvaluationDetails('appraisalempl_grade', grade, sub_item.appraisaltopicsub_id, sub_item.appraisaltopic_id)}
                                                          style={{
                                                            backgroundColor: bg,
                                                            color: color,
                                                            border: 0,
                                                            width: 40,
                                                            height: 40,
                                                            borderRadius: 10,
                                                            fontSize: 17,
                                                            marginRight: 25
                                                          }}>{index}</button>
                                                      )
                                                    })}
                                                  </div>
                                                }
                                              </Col>
                                            </Row>
                                            <hr className="hr-text" data-content={"Sub Topic"} />
                                          </>
                                        ))
                                      }
                                    })
                                  }
                                </Col>
                              </Row>
                              <hr className="hr-text" data-content={"Main Topic" + '-' + parseInt(index + 1)} />

                            </>
                          })
                          }
                        </div>
                        <Row>
                          <Col md={6}>
                            <Form.Group className="mb-3">
                              <Form.Label>{t('Areas of strength')}</Form.Label>
                              <Form.Control as="textarea" style={{
                                backgroundColor: '#F2F2F2',
                                borderColor: '#D1F6DA',
                              }} rows={2}
                                value={this.state.areasOfStrengthValue}
                                onChange={(e) => this.setState({ areasOfStrengthValue: e.target.value })} />
                            </Form.Group>
                          </Col>
                          <Col md={6}>
                            <Form.Group className="mb-3">
                              <Form.Label>{t('Areas to be improved')}</Form.Label>
                              <Form.Control as="textarea" style={{
                                backgroundColor: '#F2F2F2',
                                borderColor: '#D1F6DA',
                              }} rows={2}
                                value={this.state.areasToBeImprovedValue}
                                onChange={(e) => this.setState({ areasToBeImprovedValue: e.target.value })} />
                            </Form.Group>
                          </Col>
                        </Row>
                      </>
                    }

                  </>
                }
              </div>
              <div className='text-end'>
                <Button disabled={this.state.working} variant={this.state.isEditing ? "success" : ""} onClick={this.state.isEditing ? this.saveEvaluation : ''}>
                  {this.state.isEditing ? t('Save') : ''}
                  {this.state.working &&
                    <Spinner as='span' animation='grow' size='sm' role='status' aria-hidden='true' />
                  }
                </Button>
              </div>
            </div>
          </Offcanvas.Body>
        </Offcanvas>
        {/* Add Mode End*/}

        {/* Info Mode Start */}
        <Offcanvas show={this.state.showModalInfo} onHide={this.handleCloseModal} placement="bottom" style={{ height: '100%' }}>
          <Offcanvas.Header closeButton className='pb-0'>
            <Offcanvas.Title style={{ textAlign: "center" }}>{t('Evaluation Info')}</Offcanvas.Title>
            <div className="d-flex justify-content-center">
              <Button className='me-1' onClick={() => this.openPDF(0)}>{t('Export En')}</Button>
              <Button onClick={() => this.openPDF(1)}>{t('Export AR')}</Button>
            </div>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <div className='d-flex flex-column' style={{ height: '100%' }}>
              <div className='custom-styled-form' style={{ flex: 1 }}>
                <>
                  <div>
                    <Row>
                      <Col md={6}>
                        <img style={{ width: 135 }} src={this.props.authData.centerData.logo}></img>
                      </Col>
                      <Col md={6}>
                        <h1 style={{ fontSize: 22, paddingLeft: 263 }}>{this.props.authData.centerData.center_name}</h1>
                      </Col>
                    </Row>
                    <Row style={{ textAlign: "center" }}>
                      <Col md={3} className="ml-10">
                        <h3 className='mb-0'>{this.state.employee?.label}</h3>
                        {this.state.appraisalgrp?.label}<br />
                        {this.state.appraisalcat?.label}
                      </Col>
                      <Col md={3}>
                        {this.state.appraisalemplData.employee_create ? this.state.appraisalemplData.employee_create : '----'}
                      </Col>
                      <Col md={3}>
                        {this.state.appraisalemplData.appraisalempl_date}
                      </Col>
                      <Col md={3}>
                        {this.handleSumGrade() + '%'}
                      </Col>
                    </Row>
                  </div>
                  <div className='text-center mb-3'>
                  </div>
                  {this.state.topics &&
                    <>
                      <div className='eval-topics-sub-topics-heading'>
                      </div>
                      <Row>
                        <Col md={4}>
                          <Form.Group className="mb-3">
                            <Form.Label>{t('Term')}</Form.Label>
                            <Form.Control type="text"
                              disabled={true}
                              Value={this.state.appraisalemplData.term_name ?? '---'}
                            />
                          </Form.Group>
                        </Col>
                        <Col md={4}>
                          <Form.Group className="mb-3">
                            <Form.Label>{t('Class')}</Form.Label>
                            <Form.Control type="text"
                              disabled={true}
                              Value={this.state.appraisalemplData.class_theme ?? '---'}
                            />
                          </Form.Group>
                        </Col>
                        <Col md={4}>
                          <Form.Group className="mb-3">
                            <Form.Label>{t('Subject')}</Form.Label>
                            <Form.Control type="text"
                              disabled={true}
                              Value={this.state.appraisalemplData.acs_name ?? '---'}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <div>
                        {this.state.topics.map((topic_item, index) => (
                          <Row>
                            <Col key={index} style={{
                              borderRadius: '10px'
                            }}
                            >
                              {
                                this.state.subTopics.map((subtopic_item, index) => {
                                  if (subtopic_item.appraisaltopic_id === topic_item.appraisaltopic_id) {
                                    return this.state.appraisalempldetails.map((details, index) => (
                                      (details.appraisaltopicsub_id == subtopic_item.appraisaltopicsub_id) &&
                                      <div key={index} className="mb-2">
                                        <Row>
                                          <Col md={12}>
                                            <span className="badge badge-light-primary"
                                              style={{
                                                fontSize: '17px',
                                                marginBottom: '14px',
                                                marginTop: '12px'
                                              }}>
                                              {topic_item.appraisaltopic_code}
                                            </span>
                                            <div style={{ display: 'flex', alignItems: 'center', paddingLeft: 18 }}>
                                              <div className="badge badge-light-success">
                                                {subtopic_item.appraisaltopicsub_code}
                                              </div>
                                              {/*<div style={{ padding: 10 }}>*/}
                                              {/*  <span className="badge badge-outline-success" style={{ marginLeft: 7, fontSize: 14 }}>{details.appraisalempl_grade}</span>*/}
                                              {/*</div>*/}
                                              {this.state.appraisalgrp.appraisalassessment === 1 ? (
                                                <div style={{ padding: 10 }}>
                                                  <span style={{
                                                    borderColor: details.apprassmt_color,
                                                    borderWidth: 2,
                                                    borderStyle: 'solid',
                                                    color: '#fff',
                                                    height: 35,
                                                    borderRadius: 10,
                                                    fontSize: 15,
                                                    marginRight: 10,
                                                    backgroundColor: details.apprassmt_color,
                                                    paddingInline: 15,
                                                    padding: 10
                                                  }}>
                                                    {details.apprassmt_desc}
                                                  </span>
                                                </div>

                                              ) : (
                                                <div style={{ padding: 10 }}>
                                                  <span className="badge badge-outline-success" style={{ marginLeft: 7, fontSize: 14 }}>
                                                    {details.appraisalempl_grade}
                                                  </span>
                                                </div>
                                              )}
                                            </div>
                                            <div style={{ paddingLeft: 68 }}>
                                              <div>
                                                <p>{subtopic_item.appraisaltopicsub_desc}</p><br />
                                                <p>{subtopic_item.appraisaltopicsub_desc2}</p>
                                              </div><br />
                                              <div>
                                                <p><strong>Comment :</strong>  {details.appraisalempl_comments}</p>
                                              </div>
                                            </div>
                                          </Col>
                                        </Row>
                                        <hr className="hr-text" data-content={"Sub Topic"} />
                                      </div>
                                    ));
                                  }

                                  //return null; // If the condition is not met
                                })
                              }
                            </Col>
                          </Row>
                        ))}
                        <Row>
                          <Col md={3}>
                            <Form.Group className="mb-3">
                              <Form.Label>{t('Area Of Strength')}</Form.Label>
                              <Form.Control type="text"


                                disabled={true}
                                Value={this.state.appraisalemplData.appraisalempl_areas_of_strength ?? '---'}

                              />
                            </Form.Group>
                          </Col>
                          <Col md={3}>
                            <Form.Group className="mb-3">
                              <Form.Label>{t('Areas To Be Improved')}</Form.Label>
                              <Form.Control type="text"
                                disabled={true}
                                Value={this.state.appraisalemplData.appraisalempl_areas_to_be_improved ?? '---'}

                              />
                            </Form.Group>
                          </Col>
                        </Row>
                      </div>
                    </>
                  }
                  {this.state.subTopics && this.state.appraisaltopic &&
                    <>
                      <div className='eval-topics-sub-topics-heading'>
                        <span>{t('Sub-Topics')}</span>
                      </div>
                      <div className='eval-topics-sub-topics mb-3'>
                        {this.state.subTopics
                          .filter(item => item.appraisaltopic_id == this.state.appraisaltopic.appraisaltopic_id)
                          .map((item, index) => (
                            <button key={index} style={{
                              color: this.subTopicCheck(item) ? '#48CF48' : '#7B7B7B',
                              backgroundColor: this.subTopicCheck(item) ? '#D1F6DA' : '#F2F2F2',
                              borderColor: this.subTopicCheck(item) ? '#D1F6DA' : '#F2F2F2',
                            }}
                            >
                              <FontAwesomeIcon icon={this.subTopicCheck(item
                              ) ? faCircleCheck : faCircle} color={this.subTopicCheck(item
                              ) ? '#48CF48' : '#fff'} className='me-1' /> {item.appraisaltopicsub_code}</button>
                          ))}

                      </div>
                    </>
                  }
                  {this.state.appraisaltopicsub &&
                    <>
                      <div className='eval-topics-sub-topics-heading mb-3'>
                        <span>{t('Details')}</span>
                      </div>
                      <Row className='mb-2'>
                        <Col md={6}>
                          <div style={{
                            backgroundColor: '#F2F2F2',
                            borderRadius: 10,
                            padding: 20,
                            minHeight: 114
                          }}>
                            {this.state.appraisaltopicsub.appraisaltopicsub_desc &&
                              <p>{this.state.appraisaltopicsub.appraisaltopicsub_desc}</p>
                            }
                            {this.state.appraisaltopicsub.appraisaltopicsub_desc2 &&
                              <p className="mb-0">{this.state.appraisaltopicsub.appraisaltopicsub_desc2}</p>
                            }
                          </div>
                        </Col>
                        <Col md={6} style={{ marginTop: -22 }}>
                          <Form.Group className="mb-3">
                            <Form.Label>{t('Comment')}</Form.Label>
                            <Form.Control as="textarea" rows={4}
                              value={this.state.evaluationDetails ? this.state.evaluationDetails.appraisalempl_comments : ''}
                            />
                          </Form.Group>
                        </Col>
                        <Col md={12}></Col>
                      </Row>
                      <div className='eval-topics-sub-topics-heading mb-2'>
                        <span>
                          {this.state.appraisalgrp.appraisalassessment == 1 ? t('Attainments') : t('Grades')}
                        </span>
                      </div>
                      {this.state.appraisalgrp.appraisalassessment == 1 ?
                        <div>
                          {this.state.attainments.map((item, index) => {

                            let bg = 'transparent';
                            let color = item.apprassmt_color;

                            if (
                              this.state.evaluationDetails &&
                              this.state.evaluationDetails.apprassmt_id == item.apprassmt_id
                            ) {
                              bg = item.apprassmt_color;
                              color = '#fff';
                            }

                            return (
                              <button key={index}
                                style={{
                                  borderColor: item.apprassmt_color,
                                  borderWidth: 2,
                                  borderStyle: 'solid',
                                  color: color,
                                  height: 35,
                                  borderRadius: 10,
                                  fontSize: 15,
                                  marginRight: 10,
                                  backgroundColor: bg,
                                  paddingInline: 15
                                }}>{item.apprassmt_code}</button>
                            );
                          })}
                        </div>
                        :
                        <div>
                          {Array.from({ length: (parseInt(this.state.appraisaltopicsub.max_grade) + 1) }).map((_, index) => {

                            let bg = '#F2F2F2';
                            let color = '#7B7B7B';

                            let grade = index.toString();

                            if (
                              this.state.evaluationDetails &&
                              this.state.evaluationDetails.appraisalempl_grade == grade
                            ) {
                              bg = brand_colors[this.props.defaultTheme.theme_id].color1;
                              color = '#fff';
                            }

                            return (
                              <button key={index}
                                style={{
                                  backgroundColor: bg,
                                  color: color,
                                  border: 0,
                                  width: 40,
                                  height: 40,
                                  borderRadius: 10,
                                  fontSize: 17,
                                  marginRight: 10
                                }}>{index}</button>
                            )
                          })}
                        </div>
                      }

                    </>
                  }
                </>

              </div>
            </div>
          </Offcanvas.Body>

        </Offcanvas>
        {/* Info Mode End*/}

        {/* modal evaluation performance start*/}
        <Offcanvas
          show={this.state.showModalPerformance}
          onHide={this.handleCloseModalPerformance}
          placement='bottom'
          style={{ height: '100%' }}>
          <Offcanvas.Header id='iedu-header' style={{ marginTop: '1rem', marginRight: '1rem', marginLeft: '1rem', marginBottom: 0 }}>
            <div className='d-flex flex-row'>
              <span className='header-back-btn' onClick={this.handleCloseModalPerformance} style={{ marginRight: 10 }}>
                <FontAwesomeIcon icon={faArrowAltCircleLeft} color={brand_colors[this.props.defaultTheme.theme_id].color9} />
              </span>
              <Offcanvas.Title>{t('Evaluation Performance')} </Offcanvas.Title>
            </div>
            <div>
              {/*<Button disabled={this.state.working} variant='success' onClick={() => this.saveUpdate()}>*/}
              {/*  {t('Save')} {this.state.working ? <Spinner as='span' animation='grow' size='sm' role='status' aria-hidden='true' /> : ''}*/}
              {/*</Button>*/}

            </div>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <div className={'d-flex flex-row grey-section'} style={{
              backgroundImage: 'url(https://academic.educore.io/static/media/pegboard-bg.9b84e4936acef29bbcd9.png)',
              backgroundRepeat: 'repeat',
              backgroundPosition: '10px 12px',
              overflow: 'hidden',
            }}>

              <Col className='custom-styled-form hide-scrollbar' style={{ backgroundColor: brand_colors[this.props.defaultTheme.theme_id].color8, marginLeft: 10, borderRadius: '20px', padding: '1rem', overflow: 'auto', height: '75vh' }}>
                <Row>
                  <Col md={9}>
                    <div>
                      <span><strong>Evaluation  </strong></span>
                      {
                        this.state.evaluationPerformance.subtopics.map((sub, index) => {
                          const matchingDetails = this.state.evaluationPerformance.details.filter(details => sub.appraisaltopicsub_id === details.appraisaltopicsub_id);
                          if (matchingDetails.length > 0) {
                            console.log("matchingDetails", matchingDetails)
                            return (
                              <div key={index} className="d-flex justify-content-sm-between ">
                                <div style={{ float: "left", width: '75%' }}>

                                  <div className="sub-topic">
                                    <Row>
                                      <Col>
                                        <div style={{ paddingBottom: 18 }}>
                                          <strong className="button-55">{sub.appraisaltopicsub_code}</strong>
                                        </div>
                                        <div>
                                          <p>{sub.appraisaltopicsub_desc}</p>
                                          <p>{sub.appraisaltopicsub_desc2}</p>
                                        </div>
                                      </Col>
                                    </Row>
                                    {/*<hr className="hr-text" data-content="Sub Topic" />*/}
                                  </div>
                                  {/*<hr className="hr-text" data-content={`Report-${index + 1}-`} />*/}
                                  <div className="details">
                                    {
                                      matchingDetails.map((d, i) => {
                                        const data = {

                                          labels: [d.appraisalempl_date],
                                          datasets: [{
                                            label: '# Grade',
                                            data: [d.appraisalempl_grade, d.max_grade],
                                            borderWidth: 1
                                          }]
                                        }
                                        return <Row>
                                          <Col md={3}>
                                            <strong>{d.appraisalempl_date}</strong>
                                          </Col>
                                          <Col md={3}>
                                            <div>
                                              {d.appraisalempl_comments}
                                            </div>
                                          </Col>
                                          <Col md={3}>
                                            <div className="badge badge-outline-danger">
                                              {
                                                d.appraisalempl_grade + '/' + d.max_grade
                                              }
                                            </div>
                                          </Col>
                                          <Col md="3">
                                            <Card
                                              className='border-0'
                                              style={{ borderRadius: '20px', marginBottom: 20 }}>
                                              <Card.Body>
                                                <div className='mb-1 text-center'>
                                                  <strong>{t('Performance')}</strong>
                                                </div>
                                                <div>
                                                  <Bar options={barChartOptions} data={data} height={305} />
                                                </div>
                                              </Card.Body>
                                            </Card>
                                          </Col>
                                        </Row>
                                      })
                                    }
                                  </div>
                                </div>
                                <div style={{ float: "right" }}>
                                </div>
                              </div>
                            );
                          }
                        })
                      }
                    </div>
                  </Col>
                  <Col md={3}>

                  </Col>
                </Row>
              </Col>
            </div>
          </Offcanvas.Body >
        </Offcanvas >



      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  authData: state.auth.authData,
  selectedClass: state.selectedClass.data,
  defaultLanguage: state.language.defaultLanguage,
  defaultTheme: state.theme.defaultTheme,
});

export default connect(mapStateToProps, null)(EmployeeEvaluationReport);
