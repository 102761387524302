import React, { Component } from 'react';
import { Container, Card, Row, Col } from 'react-bootstrap';
import { t } from '../../helpers/translation_helper';
import NavBar from '../_partials/NavBar/_NavBar';
import Header from '../_partials/Header/_Header';
import { connect } from 'react-redux';
import { ifNavAllowed } from '../../helpers/general_helpers';
import GeneralCard from '../../helpers/GeneralCard';
var Chance = require('chance');
var chance = new Chance();

class EarlyChildhood extends Component {

  state = {
    groups: [
      {
        id: chance.guid(),
        name: '',
        show: true,
        modules: [
          {
            id: 'observation',
            linkTo: '/observation',
            linkToStates: '',
            cardImage: require('../../assets/images/setup/services.png'),
            name: t('Observation'),
          },
          {
            id: 'observations-review',
            linkTo: '/observations-review',
            linkToStates: '',
            cardImage: require('../../assets/images/setup/policies.png'),
            name: t('Observations Review'),
          },
          {
            id: 'progress-analysis',
            linkTo: '/progress-analysis',
            linkToStates: '',
            cardImage: require('../../assets/images/setup/policies.png'),
            name: t('Progress Analysis'),
          },
          {
            id: 'student-journal',
            linkTo: '/student-assessment',
            linkToStates: '',
            cardImage: require('../../assets/images/setup/policies.png'),
            name: t('Journal'),
          },
        ]
      },
      {
        id: chance.guid(),
        name: '',
        show: true,
        modules: [
          {
            id: 'student-attainment',
            linkTo: '/student-attainment',
            linkToStates: '',
            cardImage: require('../../assets/images/setup/curriculumbuilder.png'),
            name: t('Student Attainment'),
          },
          {
            id: 'assessment-coverage',
            linkTo: '/assessment-coverage',
            linkToStates: '',
            cardImage: require('../../assets/images/setup/gradesandacademic.png'),
            name: t('Assessment Coverage'),
          },
          {
            id: 'student-tracker',
            linkTo: '/student-tracker',
            linkToStates: '',
            cardImage: require('../../assets/images/setup/services.png'),
            name: t('Student Tracker'),
          },
          {
            id: 'early-learning-goals-tracker',
            linkTo: '/early-learning-goals-tracker',
            linkToStates: '',
            cardImage: require('../../assets/images/setup/gradesandacademic.png'),
            name: t('Learning Goals Tracker'),
          },
        ]
      },
      {
        id: chance.guid(),
        name: '',
        show: true,
        modules: [
          {
            id: 'activities',
            linkTo: '/activities',
            linkToStates: '',
            cardImage: require('../../assets/images/setup/services.png'),
            name: t('Activity Design'),
          },
          {
            id: 'assigned-activity',
            linkTo: '/assigned-activity',
            linkToStates: '',
            cardImage: require('../../assets/images/setup/services.png'),
            name: t('Class Activity'),
          },
          {
            id: 'curriculum-builder',
            linkTo: '/curriculum-builder',
            linkToStates: '',
            cardImage: require('../../assets/images/setup/curriculumbuilder.png'),
            name: t('Curriculum Builder'),
          },
        ]
      },
    ],
  }

  render() {
    const { groups } = this.state
    return (
      <Container fluid>
        <div id='iedu-layout'>
          <NavBar />
          <div id='page-content'>
            <Header lite={true} heading={t('Early Childhood')} backBtn={true} showCenterSelector={true} />

            {groups
              .filter(grp => grp.show)
              .map((grp, gid) => {
                return (
                  <>
                    <div className='eval-topics-sub-topics-heading mb-3'>
                      <span>{grp.name && t(grp.name)}</span>
                    </div>
                    <div className='d-flex flex-row flex-wrap mb-3'>
                      {grp.modules.map((mod, mid) => {
                        if (ifNavAllowed(mod.id)) {
                          return <GeneralCard data={mod} key={mid} />
                        }
                      })}
                    </div>
                  </>
                )
              })}
          </div>
        </div>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  authData: state.auth.authData,
  defaultLanguage: state.language.defaultLanguage,
});

export default connect(mapStateToProps, null)(EarlyChildhood);
