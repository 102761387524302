/**
 * Lesson Planner Slice
 */
import { createSlice } from '@reduxjs/toolkit';

export const navigationSlice = createSlice({
    name: 'navigation',
    initialState: {
        data: [],
    },
    reducers: {
        addNavigation: (state, action) => {
            state.data = [...state.data, action.payload];
        },
    },
});

export const { addNavigation } = navigationSlice.actions;
export default navigationSlice.reducer;
