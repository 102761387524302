import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { GET } from '../../api';
import axios from 'axios';
import { store } from '../store';
let getEmployeesReq;

export const getEmployees = createAsyncThunk('users/getEmployees', async ({ auth_key, center_id, user_id, role_id, employee_status, search, order, dir, limit, page }) => {

    let state = store.getState()
    let employeesList = state.employeesList
    console.log('employeesList', employeesList);

    if (search == undefined) {
        search = employeesList.query
    }
    if (employee_status == undefined) {
        employee_status = employeesList.status
    }
    if (order == undefined) {
        order = employeesList.order
    }
    if (dir == undefined) {
        dir = employeesList.dir
    }
    if (limit == undefined) {
        limit = employeesList.rows
    }
    if (page == undefined) {
        page = employeesList.page
    }



    if (getEmployeesReq) {
        getEmployeesReq.abort();
    }
    getEmployeesReq = new AbortController();

    const request_params = {
        user_id,
        role_id,
        employee_status,
        search,
        order,
        dir,
        limit,
        offset: page * limit,
    };

    try {

        const response = await GET('employees/get', {
            params: request_params,
            signal: getEmployeesReq.signal,
        });

        return {
            page,
            order,
            dir,
            search,
            limit,
            status: employee_status,
            data: response.data.data,
            total_records: response.data.total_count_filtered,
            total_count: response.data.total_count,
        };

    } catch (error) {
        if (axios.isCancel(error)) {

        } else {

        }
    }

})

export const employeesListSlice = createSlice({
    name: 'employeesList',
    initialState: {
        fetching: false,
        query: '',
        rows: 10,
        data: [],
        order: 'employees.employee_name',
        dir: 'ASC',
        status: true,
        total_records: 0,
        total_count: 0,
        last_page: 0,
        page: 0,
    },
    reducers: {
        setEmployees: (state, action) => {
            state.data = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getEmployees.pending, (state, action) => {
            if (state.fetching == false) {
                state.fetching = true;
            }
        })
        builder.addCase(getEmployees.fulfilled, (state, action) => {
            console.log('state.fetching', state.fetching);
            if (state.fetching === true && action.payload) {
                state.data = action.payload.data;
                state.order = action.payload.order;
                state.dir = action.payload.dir;
                state.query = action.payload.search;
                state.rows = action.payload.limit;
                state.status = action.payload.status;
                state.total_records = parseInt(action.payload.total_records);
                state.total_count = parseInt(action.payload.total_count);
                state.page = parseInt(action.payload.page);
                state.last_page = Math.ceil(parseInt(action.payload.total_records) / parseInt(action.payload.limit));
                state.fetching = false;
            }
        })
        builder.addCase(getEmployees.rejected, (state, action) => {
            if (state.fetching === true) {
                state.fetching = false;
                state.error = 'Error occurred';
            }
        })
    }
});

export const { setEmployees } = employeesListSlice.actions;
export default employeesListSlice.reducer;
