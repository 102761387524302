import React, { Component } from 'react';
import {
    Container,
    Card,
    Row,
    Col,
    ButtonGroup,
    Button,
    DropdownButton,
    Dropdown,
    Form,
    Modal,
    Spinner,
    Table,
    InputGroup,
    Badge
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faCheckCircle, faSearch, faUser, faXmark, faLayerGroup, faHandsHoldingChild, faPeopleGroup, faListCheck, faPrint, faPencil, faTrash, faCheckDouble, faSortDown, faSortUp, faThumbsUp, faThumbsDown, faL, faChevronUp, faChevronDown, faTimes } from '@fortawesome/free-solid-svg-icons';
import { faCalendar, faEye, faEyeSlash, faFileExcel, faFilePdf } from '@fortawesome/free-regular-svg-icons';
import { t } from '../../helpers/translation_helper';
import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';
import { connect } from 'react-redux';
import moment from 'moment';
import { brand_colors } from '../../helpers/brand_colors_helper';
import { GET, POST } from '../../api';
import { toast } from 'react-toastify';
import { handleFormErrors } from '../../helpers/form_helpers';
import { DateRangePicker } from 'react-date-range';
import axios from 'axios';
import Pagination from '../_partials/Pagination';
import { renderTableBody, renderTableHead } from '../../helpers/table_helper';
var Chance = require('chance');
var chance = new Chance();
let cancelToken;

class Centerlic extends Component {

    searchFieldRef = React.createRef();

    state = {
        working: false,
        isEditing: false,
        centerlic_id: 0,
        reg_year: '',
        reg_status:0 ,
        lock:0,
        centerlic_daterange: { startDate: new Date(), endDate: new Date(), key: 'selection' },
        date_from: moment().format('YYYY-MM-DD'),
        date_to: moment().format('YYYY-MM-DD'),
        cdt: {
            fetching: false,
            search: '',
            rows: 5,
            offset: 0,
            order: 'centerlic.centerlic_id',
            dir: 'DESC',
            lastPage: 0,
            page: 0,
            totalRecords: 0,
            rowsOptions: [5, 10, 25, 50, 'All'],
            columns: [
                {
                    id: 'centerlic.centerlic_id',
                    column: 'centerlic_id',
                    name: 'Actions',
                    visibility: true
                },
                {
                    id: 'centerlic.reg_year',
                    column: 'reg_year',
                    name: 'Register Year',
                    visibility: true
                },
                {
                    id: 'centerlic.reg_status',
                    column: 'reg_status',
                    name: 'Register Status',
                    visibility: true
                },
                {
                    id: 'centerlic.lock',
                    column: 'lock',
                    name: 'Lock',
                    visibility: true
                },
                {
                    id: 'centerlic.date_from',
                    column: 'date_from',
                    name: 'From',
                    visibility: true
                },
                {
                    id: 'centerlic.date_to',
                    column: 'date_to',
                    name: 'To',
                    visibility: true
                },
            ],
            data: [],
        },
        showFormModal: false,
        showDateRange: false,
    }

    componentDidMount() {
        const {
            search,
            rows: limit,
            order,
            dir
        } = this.state.cdt;
        this.fetch({
            search, limit, order, dir, page: 0
        });

    }

    fetch = async (params) => {

        this.setState({
            cdt: {
                ...this.state.cdt,
                fetching: true,
            }
        });

        if (cancelToken) {
            cancelToken.abort();
        }
        cancelToken = new AbortController();

        try {
            const res = await GET('centerlic/get', {
                signal: cancelToken.signal,
                params: {
                    search: params.search,
                    order: params.order,
                    dir: params.dir,
                    limit: params.limit,
                    offset: parseInt(params.page) * parseInt(params.limit)
                },
            });
            if (res.status === 200) {
                this.setState({
                    cdt: {
                        ...this.state.cdt,
                        fetching: false,
                        search: params.search,
                        rows: parseInt(params.limit),
                        offset: parseInt(params.page) * parseInt(params.limit),
                        order: params.order,
                        dir: params.dir,
                        page: params.page,
                        totalRecords: parseInt(res.data.total_count_filtered),
                        lastPage: Math.ceil(parseInt(res.data.total_count_filtered) / parseInt(params.limit)),
                        data: res.data.data,
                    }
                });
            }

        } catch (err) {
            console.log('err', err)
            this.setState({
                cdt: {
                    ...this.state.cdt,
                    fetching: false,
                }
            });

        }
    }

    showHideColumn = (column_index) => {
        const columns = this.state.cdt.columns;
        columns[column_index].visibility = !columns[column_index].visibility;
        this.setState({ ctd: { ...this.state.ctd, columns } })
    }

    handleOpenFormModal = () => {
        this.setState({
            showFormModal: true,
            centerlic_id: 0,
            reg_year: '',
            reg_status:0 ,
            lock:0,
            centerlic_daterange: { startDate: new Date(), endDate: new Date(), key: 'selection' },
            date_from: moment().format('YYYY-MM-DD'),
            date_to: moment().format('YYYY-MM-DD'),
        });
    }

    handleCloseFormModal = () => {
        this.setState({
            showFormModal: false,
            isEditing: false,
            ...this.defaultFormFields
        });
    }

    saveUpdate = async () => {

        const { authData } = this.props;
        const { center_id } = authData.centerData;

        this.setState({ working: true });

        const {
            centerlic_id,
            date_from,
            date_to,
            lock,
            reg_status,
            reg_year,
        } = this.state;

        const fData = new FormData();
        fData.append('center_id', center_id);
        fData.append('centerlic_id', centerlic_id);
        fData.append('CenterlicData', JSON.stringify({
            reg_year,
            date_from,
            date_to,
            lock,
            reg_status,
        }));
        try {
            const res = await POST('centerlic/save', fData);
            if (res.data.type) {
                this.setState({ working: false });
                toast.success(t(res.data.message));
                this.fetch({
                    search: '',
                    limit: this.state.cdt.rows,
                    order: this.state.cdt.order,
                    dir: this.state.cdt.dir,
                    page: 0,
                });
                this.handleCloseFormModal();
            } else {
                handleFormErrors(res.data.data)
                this.setState({ working: false });
            }
        } catch (err) {
            console.log('err', err)
            toast.error(t('Something went wrong!'));

            this.setState({ working: false });
        }

    }

    edit = async (data) => {
        try {
            if (data) {
                this.setState({
                    ...this.state,
                    ...data,
                    isEditing: true,
                    centerlic_daterange: { startDate: new Date(data.date_from), endDate: new Date(data.date_to), key: 'selection' },
                    showDateRange: false,
                    showFormModal: true
                });
            } else {
                toast.error(t('Something went wrong!'));
            }
        } catch (err) {
            console.log('err', err)
            toast.error(t('Something went wrong!'));
        }
    }

    exportExcel = async () => {

        const excelData = this.state.cdt.data.map((item) => {
            const row = {};
            this.state.cdt.columns
                .filter((column) => column.visibility)
                .map((column) => {
                    if (column.name !== 'Actions') {
                        row[column.name] = item[column.column];
                    }
                });
            return row;
        });

        const fileName = 'Centerlic Export ' + moment().format('DD-MM-YYYY');
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';
        const ws = XLSX.utils.json_to_sheet(excelData);

        const cellStyle = {
            font: {
                bold: true,
            },
        }

        Array.from('ABCDE').map((letter) => {
            ws[letter + "1"].s = cellStyle;
        });

        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });

        FileSaver.saveAs(data, fileName + fileExtension);
    }

    renderCell = (column_data, column_id, column_index, dataObj) => {
        const { authData } = this.props;
        const { center_id } = authData.centerData;

        let tdData;

        // --------------------------------------------
        if (column_id == 'centerlic_id') { // Action Buttons
            tdData = <>
                <Button
                    onClick={() => this.edit(dataObj)}
                    variant="warning" size='sm' className='me-1'>
                    <FontAwesomeIcon icon={faPencil} />
                </Button>
                {/*<Button*/}
                {/*    onClick={() => dataObj.term_active == 0 ? this.termActivate(column_data) : null}*/}
                {/*    variant={dataObj.term_active == 1 ? 'success' : 'danger'} size='sm'>*/}
                {/*    <FontAwesomeIcon icon={dataObj.term_active == 1 ? faThumbsUp : faThumbsDown} />*/}
                {/*</Button>*/}
            </>;
            // ------------------------------------------------------------------------
        }else if (column_id == 'date_from' || column_id == 'date_to') { // From - To
            if(column_data !==null)
            {
                tdData = moment(column_data).format('DD-MM-YYYY');
            }

        }
        else if(column_id == 'reg_status' || column_id == 'lock')
        {
            tdData = column_data == 0 ? <Badge pill bg="danger">Inactive</Badge> : <Badge pill bg="success">Active</Badge>

        }
        else {
            tdData = column_data;
        }


        return <td key={column_index}>{tdData}</td>;
    }

    render() {
        const { cdt } = this.state
        return (
            <div className='shadow-box'>
                <Card
                    className='border-0'
                    style={{ borderRadius: '20px' }}>
                    <Card.Header style={{
                        backgroundColor: '#fff',
                        borderTopLeftRadius: '20px',
                        borderTopRightRadius: '20px',
                        fontWeight: '700'
                    }}>
                        <Row>
                            <Col md={6} className='d-flex align-items-center'>
                                {t('Centerlic')}
                            </Col>
                            <Col md={6} className='d-flex justify-content-end'>
                                <div className='cdt-search'>
                                    <input type='text'
                                           ref={this.searchFieldRef}
                                           defaultValue={this.state.cdt.search}
                                           onChange={(e) => this.fetch({
                                               search: e.target.value,
                                               limit: this.state.cdt.rows,
                                               order: this.state.cdt.order,
                                               dir: this.state.cdt.dir,
                                               page: 0
                                           })}
                                           placeholder={t('Search...')} />
                                    <button
                                        onClick={() => {
                                            this.fetch({
                                                search: '',
                                                limit: this.state.cdt.rows,
                                                order: this.state.cdt.order,
                                                dir: this.state.cdt.dir,
                                                page: 0
                                            });
                                            this.searchFieldRef.current.value = '';
                                        }}>
                                        <FontAwesomeIcon
                                            icon={this.state.cdt.search == '' ? faSearch : faXmark}
                                            color={brand_colors[this.props.defaultTheme.theme_id].color18}
                                        />
                                    </button>
                                </div>
                                <button
                                    onClick={this.handleOpenFormModal}
                                    className='btn btn-success btn-sm ms-3'><FontAwesomeIcon icon={faPlus} color={brand_colors[this.props.defaultTheme.theme_id].color8} style={{ fontSize: 12 }} /></button>
                            </Col>
                        </Row>
                    </Card.Header>
                    <Card.Body style={{ paddingTop: 8 }}>
                        <Row>
                            <Col md={6}>
                                <ButtonGroup size='sm' className='me-3 float-left'>
                                    {/* <Button><FontAwesomeIcon icon={faFilePdf} /> {t('PDF')}</Button> */}
                                    <Button onClick={() => this.exportExcel()}>
                                        <FontAwesomeIcon icon={faFileExcel} /> {t('Excel')}
                                    </Button>
                                    {/* <Button><FontAwesomeIcon icon={faPrint} /> {t('Print')}</Button> */}
                                    <DropdownButton
                                        autoClose={'outside'}
                                        size='sm'
                                        as={ButtonGroup}
                                        title={t('Column Visibility')}>
                                        {this.state.cdt.columns.map((column, column_index) => (
                                            <Dropdown.Item key={column_index} className={column.visibility ? 'column-name-item' : 'column-name-item active'} onClick={() => this.showHideColumn(column_index)}>
                                                <FontAwesomeIcon icon={column.visibility ? faEye : faEyeSlash} /> {column.name}
                                            </Dropdown.Item>
                                        ))}
                                    </DropdownButton>
                                </ButtonGroup>
                            </Col>
                            <Col md={6} className='d-flex justify-content-end'>
                                <div style={{ display: 'inline-block' }}>
                                    {t('Show')}
                                    <Form.Select
                                        value={this.state.cdt.rows}
                                        size="sm"
                                        onChange={(e) => this.fetch({
                                            search: this.state.cdt.search,
                                            limit: e.target.value,
                                            order: this.state.cdt.order,
                                            dir: this.state.cdt.dir,
                                            page: this.state.cdt.page
                                        })}
                                        style={{ width: 70, marginInline: 5, display: 'inline-block' }}>
                                        {this.state.cdt.rowsOptions.map((row, row_index) => (<option key={row_index} >{row}</option>))}
                                    </Form.Select>
                                    {t('Records')}
                                </div>
                            </Col>
                            {this.state.cdt.fetching &&
                            <Col md={12} className='py-5 d-flex flex-column align-items-center'>
                                <Spinner as='span' animation='grow' size='sm' /> {t('Loading Data')}
                            </Col>
                            }
                            {!this.state.cdt.fetching && this.state.cdt.data.length === 0 &&
                            <Col md={12} className='py-5 d-flex flex-column align-items-center'>
                                {t('No Data Found')}
                            </Col>
                            }
                            {!this.state.cdt.fetching && this.state.cdt.data.length !== 0 &&
                            <Col md={12} className='pt-2'>
                                <div id="datatable-list" className='mb-3' style={{ width: 'initial' }}>
                                    <Table responsive>
                                        {renderTableHead(this)}
                                        {renderTableBody(this)}
                                    </Table>
                                </div>
                            </Col>
                            }
                        </Row>
                        <Pagination
                            pageCount={this.state.cdt.lastPage}
                            forcePage={this.state.cdt.page}
                            callbackParams={{
                                search: this.state.cdt.search,
                                limit: this.state.cdt.rows,
                                order: this.state.cdt.order,
                                dir: this.state.cdt.dir,
                            }}
                            callback={this.fetch}
                        />
                    </Card.Body>
                </Card>
                <Modal
                 backdrop="static"
                 keyboard={false}
                    size='lg'
                    show={this.state.showFormModal}
                    onHide={this.handleCloseFormModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>{this.state.isEditing ? t('Edit') : t('Add')} {t('Centerlic')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className='custom-styled-form'>
                        <Row>
                            <Col md={5}>
                                <Form.Group className="mb-3">
                                    <Form.Label>{t('Name')}</Form.Label>
                                    <Form.Control type="text"
                                                  defaultValue={this.state.reg_year}
                                                  onBlur={(e) => this.setState({ reg_year: e.target.value })} />
                                </Form.Group>
                            </Col>
                            <Col md={5} className='position-relative'>
                                <Form.Group className="mb-3">
                                    <Form.Label>{t('From - To')}</Form.Label>
                                    <button
                                        onClick={() => this.setState({
                                            showDateRange: !this.state.showDateRange
                                        })}
                                        style={{
                                            padding: '.375rem .75rem',
                                            fontSize: '1rem',
                                            color: '#212529',
                                            border: '1px solid #ced4da',
                                            borderRadius: '.375rem',
                                            display: 'flex',
                                            width: '100%',
                                            justifyContent: 'space-between',
                                            backgroundColor: '#ffffff'
                                        }}>
                                        <span>{moment(this.state.centerlic_daterange.startDate).format('DD-MM-YYYY') + ' -- ' + moment(this.state.centerlic_daterange.endDate).format('DD-MM-YYYY')}</span>
                                        <span>
                                          <FontAwesomeIcon
                                              icon={this.state.showDateRange ? faTimes : faCalendar}
                                              color={brand_colors[this.props.defaultTheme.theme_id].color18}
                                              style={{ fontSize: 12 }}
                                          />
                                        </span>
                                    </button>
                                </Form.Group>
                                {this.state.showDateRange &&
                                <div style={{
                                    position: 'absolute',
                                    top: 65,
                                    left: '50%',
                                    transform: 'translateX(-50%)',
                                    border: '1px solid #ced4da',
                                    borderRadius: '.375rem',
                                    overflow: 'hidden',
                                    zIndex: 8,
                                    backgroundColor: '#fff'
                                }}>
                                    <DateRangePicker
                                        ranges={[this.state.centerlic_daterange]}
                                        onChange={(date) => {
                                            this.setState({
                                                centerlic_daterange: {
                                                    startDate: new Date(date.selection.startDate),
                                                    endDate: new Date(date.selection.endDate),
                                                    key: 'selection'
                                                },
                                                date_from: moment(new Date(date.selection.startDate)).format('YYYY-MM-DD'),
                                                date_to: moment(new Date(date.selection.endDate)).format('YYYY-MM-DD'),
                                            })
                                        }}
                                        style={{ backgroundColor: '#fff' }}
                                    />
                                    <div style={{
                                        padding: 5,
                                        textAlign: 'right'
                                    }}>
                                        <Button
                                            onClick={() => this.setState({
                                                showDateRange: !this.state.showDateRange
                                            })}
                                            variant="danger">{t('Close')}</Button>
                                    </div>
                                </div>
                                }
                            </Col>
                        </Row>
                        <Row>
                            <Col md={4}>
                                <Form.Group className="mb-3">
                                    <Form.Label>{t('Register Status')}</Form.Label>
                                    <div className='p-3 pb-0'>
                                        <Form.Check
                                            type="switch"
                                            id="reg_status"
                                            label={false}
                                            onChange={() => this.setState({ reg_status: this.state.reg_status == 0 ? 1 : 0 })}
                                            checked={this.state.reg_status == 1 ? true : false}
                                        />
                                    </div>
                                </Form.Group>
                            </Col>
                            <Col md={4}>
                                <Form.Group className="mb-3">
                                    <Form.Label>{t('Lock')}</Form.Label>
                                    <div className='p-3 pb-0'>
                                        <Form.Check
                                            type="switch"
                                            id="timesch_base"
                                            label={false}
                                            onChange={() => this.setState({ lock: this.state.lock == 0 ? 1 : 0 })}
                                            checked={this.state.lock == 1 ? true : false}
                                        />
                                    </div>
                                </Form.Group>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleCloseFormModal}>
                            {t('Close')}
                        </Button>
                        <Button disabled={this.state.working} variant='success' onClick={() => this.saveUpdate()}>
                            {t('Save')} {this.state.working ? <Spinner as='span' animation='grow' size='sm' role='status' aria-hidden='true' /> : ''}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    authData: state.auth.authData,
    defaultLanguage: state.language.defaultLanguage,
    defaultTheme: state.theme.defaultTheme,
});

export default connect(mapStateToProps, null)(Centerlic);