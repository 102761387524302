import React, { Component } from 'react';
import { Container, Card, Row, Col, Button, Spinner, InputGroup, Form, Table, Badge } from 'react-bootstrap';
import { t } from '../../helpers/translation_helper';
import NavBar from '../_partials/NavBar/_NavBar';
import Header from '../_partials/Header/_Header';
import { connect } from 'react-redux';
import { brand_colors } from '../../helpers/brand_colors_helper';
import { faCalendar, faSearch, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DateRangePicker } from 'react-date-range';
import moment from 'moment';
import { GET } from '../../api';
import { numberFormat } from '../../helpers/general_helpers';
import { faCalendarAlt, faFileExcel } from '@fortawesome/free-regular-svg-icons';
import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';

class SchoolAbsentReport extends Component {

  state = {
    fetching: false,
    selectedDateRange: { startDate: new Date(), endDate: new Date(), key: 'selection' },
    showDateRange: false,
    students: [],
    dates: []
  }

  fetch = async () => {
    const { selectedDateRange } = this.state;


    this.setState({
      fetching: true,
      showDateRange: false,
      students: [],
      dates: this.getDates()
    });

    try {
      const res = await GET('children/report-attendance-class', {
        params: {
          // class_id: this.props.selectedClass.class.class_id,
          date_from: moment(selectedDateRange.startDate).format('YYYY-MM-DD'),
          date_to: moment(selectedDateRange.endDate).format('YYYY-MM-DD')
        }
      });

      this.setState({
        fetching: false,
        students: res.data.data,
      });
    } catch (err) {
      console.log(err);
      this.setState({ fetching: false });
    }

  }

  getDates = () => {
    const { selectedDateRange } = this.state;

    var start = moment(selectedDateRange.startDate);
    var end = moment(selectedDateRange.endDate);
    var dates = [];

    start = start.subtract(1, 'days');

    while (start.format('M/D/YYYY') !== end.format('M/D/YYYY')) {
      start = start.add(1, 'days');
      dates.push(start.toDate());
    }

    return dates;

  }

  findAbsent = (student) => {
    return student.childattendance.findIndex(att => att.absent == 1);
  }

  isAbsent = (student, date) => {
    return student.childattendance.findIndex(att => att.att_date == date && att.absent == 1);
  }

  exportExcel = async () => {

    let serial = 0;

    const excelData = this.state.students.map((student, index) => {
      const data = {};

      if (this.findAbsent(student) !== -1) {

        serial += 1;

        data['#'] = serial;
        data['Student Name'] = student.child_name;
        data['Student Code'] = student.child_code;
        data['Class'] = student.class_theme;
        this.state.dates.map(date => {
          const isAbsent = this.isAbsent(student, moment(date).format('YYYY-MM-DD'));
          data[moment(date).format('DD-MM-YYYY')] = isAbsent !== -1 ? 'ABSENT' : '';
        });

        return data;

      }
    }).filter(item => item);

    const fileName = 'School Absent Report ' + moment().format('DD-MM-YYYY');
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const ws = XLSX.utils.json_to_sheet(excelData);

    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });

    FileSaver.saveAs(data, fileName + fileExtension);
  }

  render() {

    let serial = 0;

    return (
      <Container fluid>
        <div id='iedu-layout'>
          <NavBar />
          <div id='page-content'>
            <Header lite={true} heading={t('School Absent Report')} backBtn={true} />
            <div className='grey-section'>
              <Row>
                <Col md={3} className='position-relative pe-0'>
                  <InputGroup>
                    <Form.Control
                      onClick={() => this.setState({ showDateRange: true })}
                      style={{
                        paddingLeft: 20,
                        borderTopLeftRadius: 20,
                        borderBottomLeftRadius: this.state.showDateRange ? 7 : 20,
                      }}
                      value={
                        moment(this.state.selectedDateRange.startDate).format('DD-MM-YYYY') + ' -- ' + moment(this.state.selectedDateRange.endDate).format('DD-MM-YYYY')
                      }
                      readOnly
                    />
                    <Button variant={this.state.showDateRange ? 'danger' : 'primary'}
                      style={{ width: 40 }}
                      onClick={() => this.setState({ showDateRange: !this.state.showDateRange })}
                      id="button-addon2">
                      <FontAwesomeIcon icon={this.state.showDateRange ? faXmark : faCalendarAlt} style={{ fontSize: 16, color: brand_colors[this.props.defaultTheme.theme_id].color8, }} />
                    </Button>
                  </InputGroup>
                  {this.state.showDateRange &&
                    <div style={{
                      backgroundColor: '#fff',
                      padding: 10,
                      position: 'absolute',
                      border: '#F2F2F2 2px solid',
                      zIndex: 1,
                      top: 37,
                      borderRadius: 7
                    }}>
                      <DateRangePicker
                        ranges={[this.state.selectedDateRange]}
                        onChange={(date) => {

                          this.setState({
                            selectedDateRange: { startDate: new Date(date.selection.startDate), endDate: new Date(date.selection.endDate), key: 'selection' }
                          })
                        }}
                        style={{ backgroundColor: '#fff' }}
                      />
                    </div>
                  }
                </Col>
                <Col>
                  <Button variant={'success'} onClick={() => this.fetch()}>
                    {this.state.fetching
                      ? <Spinner as='span' animation='grow' size='sm' role='status' aria-hidden='true' />
                      : <FontAwesomeIcon icon={faSearch} style={{ fontSize: 16, color: brand_colors[this.props.defaultTheme.theme_id].color8, }} />
                    }
                  </Button>
                </Col>
              </Row>
            </div>
            {this.state.students.length > 0 &&
              <div className='grey-section'>
                <Card
                  className='border-0'
                  style={{ borderRadius: '20px' }}>
                  <Card.Body>
                    <Row>
                      <Col className='text-end mb-2'> <Button onClick={() => this.exportExcel()}>
                        <FontAwesomeIcon icon={faFileExcel} /> {t('Export Excel')}
                      </Button></Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <Table hover responsive className='mb-0'>
                          <thead>
                            <tr>
                              <th style={{ width: 30 }}>{t('#')}</th>
                              <th style={{ textWrap: 'nowrap' }}>{t('Student Name')}</th>
                              <th style={{ textWrap: 'nowrap' }}>{t('Student Code')}</th>
                              <th style={{ textWrap: 'nowrap' }}>{t('Class')}</th>
                              {this.state.dates.map(date => (
                                <th style={{ textAlign: 'center', textWrap: 'nowrap' }}>{moment(date).format('DD-MM-YYYY')}</th>
                              ))}
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.students.map((student, index) => {

                              if (this.findAbsent(student) !== -1) {

                                serial += 1;

                                return (
                                  <tr key={index}>
                                    <td><strong>{serial}</strong></td>
                                    <td style={{ textWrap: 'nowrap' }}>{student.child_name}</td>
                                    <td style={{ textWrap: 'nowrap' }}>{student.child_code}</td>
                                    <td style={{ textWrap: 'nowrap' }}>{student.class_theme}</td>
                                    {this.state.dates.map(date => {

                                      const isAbsent = this.isAbsent(student, moment(date).format('YYYY-MM-DD'));

                                      return (
                                        <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
                                          {isAbsent !== -1 && <Badge bg="danger">{t('ABSENT')}</Badge>}
                                        </td>
                                      )
                                    })}
                                  </tr>
                                );

                              }
                            })}
                          </tbody>
                        </Table>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </div>
            }
          </div>
        </div>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  authData: state.auth.authData,
  selectedClass: state.selectedClass.data,
  defaultLanguage: state.language.defaultLanguage,
  defaultTheme: state.theme.defaultTheme,
});

export default connect(mapStateToProps, null)(SchoolAbsentReport);
