import React, { Component } from 'react';
import { Container, Card, Row, Col, Button, Table, Badge } from 'react-bootstrap';
import { t } from '../../helpers/translation_helper';
import NavBar from '../_partials/NavBar/_NavBar';
import Header from '../_partials/Header/_Header';
import { connect } from 'react-redux';
import { GET, POST } from '../../api';
import Select from 'react-select';
import { Bar, Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import moment from 'moment';

ChartJS.register(ArcElement, Tooltip, Legend);

class ConsolidateDashboard extends Component {

  state = {
    tab: 0,
    centers: [],
    centerlics: [],
    centerRegistrations: {
      centers: [],
      data: []
    },
    employees: {
      centers: [],
      employees: [],
      employees_list: [],
      roles: []
    },
    byNationalities: [],
    byRoles: [],
    revenueChart: [],
    selectedCenters: ['all'],
    selectedCenterlic: '',
  }

  componentDidMount() {
    this.getZoneCenters();
    this.getCenterlic();
  }

  getZoneCenters = async () => {
    try {
      const { authData } = this.props;
      const res = await GET('consolidate-dashboard/zone-centers', {
        params: {
          center_tags: authData.loginData.center_tags
        }
      });

      const centers = [
        {
          center_id: 'all',
          center_keypass: null,
          center_name: 'All Centers'
        },
      ];

      res.data.map(I => I.allCenters.map(II => centers.push(II)));

      this.setState({ centers });

    } catch (err) {
      console.log(err);
    }
  }

  getCenterlic = async () => {
    try {
      const res = await GET('consolidate-dashboard/get-centerlic');

      const activeCenterlic = res.data.find(item => item.reg_status == 1);

      this.setState({
        selectedCenterlic: {
          value: activeCenterlic.centerlic_id,
          label: activeCenterlic.reg_year
        },
        centerlics: res.data.map(item => {
          return {
            value: item.centerlic_id,
            label: item.reg_year
          }
        }),
      }, () => this.combo());
    } catch (err) {
      console.log(err);
    }
  }

  combo = () => {
    this.getCenterRegistrations();
    this.getEmployees();
    this.getRevenueChart();
  }

  getCenterRegistrations = async () => {
    try {
      const { authData } = this.props;

      const fData = new FormData();

      fData.append('reg_year', this.state.selectedCenterlic.label);

      this.state.selectedCenters.map(center_id =>
        fData.append('center_ids[]', center_id));

      const res = await POST('consolidate-dashboard/get-center-registrations', fData, {
        params: {
          center_tags: authData.loginData.center_tags,
        }
      });

      this.setState({
        centerRegistrations: res.data
      });

    } catch (err) {
      console.log(err);
    }
  }

  getEmployees = async () => {
    try {
      const { authData } = this.props;

      const fData = new FormData();

      this.state.selectedCenters.map(center_id =>
        fData.append('center_ids[]', center_id));

      const res = await POST('consolidate-dashboard/get-employees', fData, {
        params: {
          center_tags: authData.loginData.center_tags,
        }
      });

      let byNationalities = [];
      let byRoles = [];

      res.data.employees.map(item => {

        // By Nationalities
        const countryIndex = byNationalities.findIndex(a => a.country == item.name_en);
        if (countryIndex !== -1) {

          byNationalities[countryIndex].count = byNationalities[countryIndex].count + 1;

        } else {
          byNationalities.push({
            country: item.name_en,
            count: 1
          });
        }

        // By Roles
        const roleIndex = byRoles.findIndex(a => a.role == item.role_name);

        if (roleIndex !== -1) {

          byRoles[roleIndex].count = byRoles[roleIndex].count + 1;

        } else {
          byRoles.push({
            role: item.role_name,
            count: 1
          });
        }

      });

      this.setState({
        employees: res.data,
        byNationalities,
        byRoles
      });

    } catch (err) {
      console.log(err);
    }
  }

  getRevenueChart = async () => {
    try {
      const { authData } = this.props;

      const fData = new FormData();

      fData.append('reg_year', this.state.selectedCenterlic.label);

      this.state.selectedCenters.map(center_id =>
        fData.append('center_ids[]', center_id));

      const res = await POST('consolidate-dashboard/get-revenue-chart', fData, {
        params: {
          center_tags: authData.loginData.center_tags,
        }
      });

      this.setState({
        revenueChart: res.data
      });

    } catch (err) {
      console.log(err);
    }
  }

  selectCenter = (center_id) => {
    let { selectedCenters } = this.state;

    if (center_id === 'all') {
      selectedCenters = ['all'];
    } else {
      if (selectedCenters.includes(String(center_id))) {
        selectedCenters = selectedCenters.filter(item => item != center_id);
      } else {
        selectedCenters
          .push(String(center_id));
      }
      if (selectedCenters.length == 0) {
        selectedCenters = ['all'];
      } else {
        selectedCenters = selectedCenters.filter(item => item != 'all');
      }
    }

    this.setState({ selectedCenters }, () => this.combo());

  }

  selectCenterlic = (obj) => {
    this.setState({ selectedCenterlic: obj }, () => this.combo());
  }

  render() {

    console.log('this.state', this.state);

    const barChartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          // display: false,
          position: 'bottom',
        },
      },
    };

    const pieChartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          // display: false,
          position: 'right',
        },
      },
    };

    const bgColors = ['#ee3a81', '#8ac53f', '#0cbbe7', '#ee3c37', '#5bba46', '#3c63ae'];

    const registrationsPerCenter = {
      labels: this.state.centerRegistrations.centers.map(item => item.center_name),
      datasets: this.state.centerRegistrations.data.map((term, term_index) => {
        return {
          label: term.term_name,
          data: term.centersData.map(center => center.totalRegistrations),
          backgroundColor: bgColors[term_index],
        }
      }),
    };

    const summaryPerCenter = {
      labels: this.state.revenueChart.map(item => item.center_name),
      datasets: [
        {
          label: t('Revenues'),
          data: this.state.revenueChart.map(item => item.chartData.Revenues),
          backgroundColor: bgColors[0],
        },
        {
          label: t('Cost of Goods'),
          data: this.state.revenueChart.map(item => item.chartData.CostOfGoods),
          backgroundColor: bgColors[2],
        },
        {
          label: t('Expenses'),
          data: this.state.revenueChart.map(item => item.chartData.Expenses),
          backgroundColor: bgColors[3],
        },
        {
          label: t('Profit'),
          data: this.state.revenueChart.map(item => item.chartData.Profit),
          backgroundColor: bgColors[1],
        }
      ]
    };

    const total = this.state.employees.employees_list.reduce((total, sum) => (parseFloat(total) + parseFloat(sum.housing_allowance) + parseFloat(sum.other_allowance) + parseFloat(sum.transportation_allowance) + parseFloat(sum.salary)), 0);

    const employeesByNationalities = {
      labels: this.state.byNationalities.map(item => item.country),
      datasets: [
        {
          label: '# of Employees',
          data: this.state.byNationalities.map(item => item.count),
          backgroundColor: bgColors,
          borderWidth: 1,
        },
      ],
    };

    const employeesByRoles = {
      labels: this.state.byRoles.map(item => item.role),
      datasets: [
        {
          label: '# of Employees',
          data: this.state.byRoles.map(item => item.count),
          backgroundColor: bgColors,
          borderWidth: 1,
        },
      ],
    };

    return (
      <Container fluid>
        <div id='iedu-layout'>
          <NavBar />
          <div id='page-content'>
            <Header lite={true} heading={t('Consolidate Dashboard')} />
            <div className='grey-section'>
              {/* Centers List */}
              <Card
                className='border-0'
                style={{ borderRadius: 20, marginBottom: 20 }}>
                <Card.Body style={{ paddingBottom: 8 }}>
                  <Row>
                    <Col md={12}>
                      {this.state.centers.map((item, index) => {
                        if (index === 0) {
                          return (<Button className='me-2 mb-2'
                            onClick={() => this.selectCenter(item.center_id)}
                            variant={this.state.selectedCenters.includes(String(item.center_id)) ? 'danger' : 'outline-danger'}>{item.center_name}</Button>)
                        } else {
                          return (<Button className='me-2 mb-2'
                            onClick={() => this.selectCenter(item.center_id)}
                            variant={this.state.selectedCenters.includes(String(item.center_id)) ? 'info' : 'outline-info'}>{item.center_name}</Button>)
                        }
                      })}
                    </Col>
                  </Row>
                </Card.Body>
              </Card>

              {/* Years and Tabs */}
              <Card className='border-0'
                style={{ borderRadius: 20, marginBottom: 20 }}>
                <Card.Body>
                  <Row>
                    <Col md={4}>
                      <Select
                        placeholder={t('Select Year')}
                        value={this.state.selectedCenterlic}
                        menuPortalTarget={document.body}
                        styles={{
                          menuPortal: (
                            base
                          ) => ({
                            ...base,
                            zIndex: 9999,
                          }),
                        }}
                        options={this.state.centerlics}
                        onChange={(obj) => this.selectCenterlic(obj)}
                      />
                    </Col>
                    <Col md={4} className='ps-1'>
                      <Button style={{ width: '100%' }}
                        onClick={() => this.setState({ tab: 0 })}
                        variant={this.state.tab === 0 ? 'info' : 'outline-info'}>{t('Registrations')}</Button>
                    </Col>
                    <Col md={4} className='ps-1'>
                      <Button style={{ width: '100%' }}
                        onClick={() => this.setState({ tab: 1 })}
                        variant={this.state.tab === 1 ? 'info' : 'outline-info'}>{t('HR')}</Button>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>

              {this.state.tab == 0 &&
                <div>
                  {/* Number Of Registrations Per Center */}
                  <Card
                    className='border-0'
                    style={{ borderRadius: 20, marginBottom: 20 }}>
                    <Card.Body>
                      <strong className='d-block mb-3'>{t('Number Of Registrations Per Center')}</strong>
                      <div>
                        <Bar options={barChartOptions} data={registrationsPerCenter} height={305} />
                      </div>
                      <Table responsive>
                        <thead>
                          <tr>
                            <th>{t('Terms')}</th>
                            {this.state.centerRegistrations.centers.map((item, index) =>
                              <th style={{ textAlign: 'center' }} key={index}>{item.center_name}</th>)}
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.centerRegistrations.data.map((item, index) =>
                            <tr key={index}>
                              <td>{item.term_name}</td>
                              {item.centersData.map((cData, cIndex) =>
                                <td style={{ textAlign: 'center' }}>
                                  <Badge bg={cData.totalRegistrations > 0 ? 'success' : 'danger'}>{cData.totalRegistrations}</Badge>
                                </td>)}
                            </tr>)}
                        </tbody>
                      </Table>
                    </Card.Body>
                  </Card>

                  {/* Summary Per Center */}
                  <Card
                    className='border-0'
                    style={{ borderRadius: 20 }}>
                    <Card.Body>
                      <strong className='d-block mb-3'>{t('Summary Per Center')}</strong>
                      <div>
                        <Bar options={barChartOptions} data={summaryPerCenter} height={305} />
                      </div>
                      <Table responsive>
                        <thead>
                          <tr>
                            <th>{t('Summary')}</th>
                            {this.state.revenueChart.map((item, index) =>
                              <th style={{ textAlign: 'center' }} key={index}>{item.center_name}</th>)}
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{t('Revenues')}</td>
                            {this.state.revenueChart.map((item, index) =>
                              <td style={{ textAlign: 'center' }} key={index}>{parseFloat(item.chartData.Revenues).toFixed(2)}</td>)}
                          </tr>
                          <tr>
                            <td>{t('Cost of Goods')}</td>
                            {this.state.revenueChart.map((item, index) =>
                              <td style={{ textAlign: 'center' }} key={index}>{parseFloat(item.chartData.CostOfGoods).toFixed(2)}</td>)}
                          </tr>
                          <tr>
                            <td>{t('Expenses')}</td>
                            {this.state.revenueChart.map((item, index) =>
                              <td style={{ textAlign: 'center' }} key={index}>{parseFloat(item.chartData.Expenses).toFixed(2)}</td>)}
                          </tr>
                          <tr>
                            <td>{t('Profit')}</td>
                            {this.state.revenueChart.map((item, index) =>
                              <td style={{ textAlign: 'center' }} key={index}>{parseFloat(item.chartData.Profit).toFixed(2)}</td>)}
                          </tr>
                        </tbody>
                      </Table>
                    </Card.Body>
                  </Card>
                </div>
              }

              {this.state.tab == 1 &&
                <Row>
                  <Col md={5}>
                    <Card
                      className='border-0'
                      style={{ borderRadius: 20, marginBottom: 20 }}>
                      <Card.Body>
                        <strong className='d-block mb-3'>{t('Employees By Nationalities')}</strong>
                        <div style={{ height: 150 }}>
                          <Pie options={pieChartOptions} data={employeesByNationalities} />
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col md={7}>
                    <Card
                      className='border-0'
                      style={{ borderRadius: 20, marginBottom: 20 }}>
                      <Card.Body>
                        <strong className='d-block mb-3'>{t('Employees By Roles')}</strong>
                        <div style={{ height: 150 }}>
                          <Pie options={pieChartOptions} data={employeesByRoles} width={300} />
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col md={12}>
                    <Card
                      className='border-0'
                      style={{ borderRadius: 20 }}>
                      <Card.Body>
                        <Row>
                          <Col><strong className='d-block mb-2'>{t('Employees List')}</strong></Col>
                          <Col className='text-end'><strong>{t('Total:')}</strong>  <Badge bg="success" style={{ fontSize: 15 }}>{total.toFixed(2)}</Badge></Col>
                        </Row>
                        <hr style={{ margin: '10px 0' }} />
                        <Table size="sm" className='cdash-employees-list'>
                          <thead>
                            <tr>
                              <th>{t('Employee')}</th>
                              <th>{t('Code')}</th>
                              <th>{t('Joining Date')}</th>
                              <th style={{ textAlign: 'center' }}>{t('Housing')}</th>
                              <th style={{ textAlign: 'center' }}>{t('Transportation')}</th>
                              <th style={{ textAlign: 'center' }}>{t('Other')}</th>
                              <th style={{ textAlign: 'center' }}>{t('Salary')}</th>
                              <th style={{ textAlign: 'center' }}>{t('Total')}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.employees.employees_list.map((item, index) =>
                              <tr>
                                <td>{item.employee_name}</td>
                                <td>{item.empl_code}</td>
                                <td>{item.joining_date && moment(item.joining_date).format('DD-MM-YYYY')}</td>
                                <td style={{ textAlign: 'center' }}>{parseFloat(item.housing_allowance).toFixed(2)}</td>
                                <td style={{ textAlign: 'center' }}>{parseFloat(item.transportation_allowance).toFixed(2)}</td>
                                <td style={{ textAlign: 'center' }}>{parseFloat(item.other_allowance).toFixed(2)}</td>
                                <td style={{ textAlign: 'center' }}>{parseFloat(item.salary).toFixed(2)}</td>
                                <td style={{ textAlign: 'center' }}>{(parseFloat(item.housing_allowance) + parseFloat(item.transportation_allowance) + parseFloat(item.other_allowance) + parseFloat(item.salary)).toFixed(2)}</td>
                              </tr>)}
                          </tbody>
                        </Table>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              }
            </div>
          </div>
        </div>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  authData: state.auth.authData,
  defaultLanguage: state.language.defaultLanguage,
});

export default connect(mapStateToProps, null)(ConsolidateDashboard);
