import React, { Component } from 'react';
import { Container, Card, Table, Row, Col, Spinner, Image, Badge, Button, Form, Offcanvas, Dropdown, Modal, ButtonGroup, DropdownButton, } from 'react-bootstrap';
import { t } from '../../helpers/translation_helper';
import NavBar from '../_partials/NavBar/_NavBar';
import Header from '../_partials/Header/_Header';
import { connect } from 'react-redux';
import axios from 'axios';
import { toast } from 'react-toastify';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight, faAnglesLeft, faAnglesRight, faCalendarAlt, faCheck, faCheckCircle, faCheckDouble, faClock, faEdit, faEllipsisV, faExternalLink, faFileAlt, faFilterCircleXmark, faGear, faGripVertical, faListOl, faPaperclip, faPlus, faSearch, faTimes, faTrashAlt, faTrashCan, faXmark, } from '@fortawesome/free-solid-svg-icons';
import { Scrollbar } from 'react-scrollbars-custom';
import imgDescription from '../../assets/images/description.png';
import { brand_colors } from '../../helpers/brand_colors_helper';
import SweetAlert from 'react-bootstrap-sweetalert';
import { Link } from 'react-router-dom';
import { faCircle, faThumbsDown, faThumbsUp } from '@fortawesome/free-regular-svg-icons';
import { handleFormErrors } from '../../helpers/form_helpers';
import { Chance } from 'chance';
import { colorPalette } from '../../constants/colorsPalette';
import Select from 'react-select';
import { GET } from '../../api';
const chance = new Chance();

// API URL
const apiURL = process.env.REACT_APP_API_URL;

// Portal URL
const portalURL = process.env.REACT_APP_PORTAL_URL;

class Curriculum extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deleteModal: { show: false, id: 0, action: () => { }, title: null, desc: null },
      termsDefault: [],
      curriculumData: {
        openSearch: false,
        toggleSearch: () => this.setState({ curriculumData: { ...this.state.curriculumData, openSearch: !this.state.curriculumData.openSearch, search: '' } }, () => {
          if (!this.state.curriculumData.openSearch) {
            this.setState({
              curriculumData: { ...this.state.curriculumData, data: [], total_count: 0, total_count_filtered: 0, refreshing: false, finished: false, offset: 0, }
            }, () => this.getCurriculum())
          }
        }),
        changeSearch: (search = '') => this.setState({
          curriculumData: { ...this.state.curriculumData, search, data: [], total_count: 0, total_count_filtered: 0, refreshing: false, finished: false, offset: 0, }
        }, () => this.getCurriculum()),
        search: '',
        order: 'curriculum.curriculum_id',
        dir: 'DESC',
        orderName: 'Newest First',
        changeOrder: (orderName = 'Newest First', order = 'curriculum.curriculum_id', dir = 'DESC') => this.setState({ curriculumData: { ...this.state.curriculumData, orderName, order, dir, data: [], total_count: 0, total_count_filtered: 0, refreshing: false, finished: false, offset: 0, } }, () => this.getCurriculum()),
        limit: 50,
        offset: 0,
        data: [],
        total_count: 0,
        total_count_filtered: 0,
        refreshing: false,
        finished: false,
      },
      modalCurriculum: {
        show: false,
        toggle: (title = 'New Objective') => this.setState({ modalCurriculum: { ...this.state.modalCurriculum, show: !this.state.modalCurriculum.show, title } }),
        title: '',
        save: this.saveCurriculum,
        saving: false,
      },
      curriculum: {
        curriculum_id: 0,
        curriculum_ref_num: '0',
        curriculum_code: '',
        curriculum_desc: '',
        curriculum_desc2: '',
        curriculum_status: 1,
        ab_id: props.academicBoard.ab_id,
        learnarea_id: props.learnArea.learnarea_id,
        lrnaspect_id: props.learnAspect.lrnaspect_id,
        agegroup_id: 0,
        term_d_ids: []
      },
      curriculumDetailsData: {
        openSearch: false,
        toggleSearch: () => this.setState({ curriculumDetailsData: { ...this.state.curriculumDetailsData, openSearch: !this.state.curriculumDetailsData.openSearch, search: (this.state.curriculumDetailsData.openSearch) ? '' : this.state.curriculumDetailsData.search } }, () => this.getCurriculumDetails()),
        changeSearch: (search = '') => this.setState({ curriculumDetailsData: { ...this.state.curriculumDetailsData, search } }, () => this.getCurriculumDetails()),
        search: '',
        order: 'curriculum_details.curriculum_d_id',
        dir: 'DESC',
        limit: 20,
        offset: 0,
        data: [],
        total_count: 0,
        total_count_filtered: 0,
        refreshing: false,
        finished: false,
      },
      modalCurriculumDetails: {
        show: false,
        toggle: (curriculum_id) => this.setState({ modalCurriculumDetails: { ...this.state.modalCurriculumDetails, show: !this.state.modalCurriculumDetails.show, curriculum_id } }, () => { if (this.state.modalCurriculumDetails.show) { this.getCurriculumDetails() } }),
        title: 'Objective Details',
        save: this.saveCurriculumDetails,
        saving: false,
        curriculum_id: 0
      },
      // ATTAINMENTS
      assessmentData: {
        openSearch: false,
        toggleSearch: () => this.setState({ assessmentData: { ...this.state.assessmentData, openSearch: !this.state.assessmentData.openSearch } }),
        changeSearch: (search = '') => this.setState({ assessmentData: { ...this.state.assessmentData, search } }, () => this.getAssessment()),
        search: '',
        order: 'assessment.assmt_id',
        dir: 'DESC',
        limit: 20,
        offset: 0,
        data: [],
        total_count: 0,
        total_count_filtered: 0,
        refreshing: false,
        finished: false,
      },
      modalAssessment: {
        show: false,
        toggle: (ab_id) => this.setState({ modalAssessment: { ...this.state.modalAssessment, show: !this.state.modalAssessment.show, ab_id } }, () => { if (this.state.modalAssessment.show) { this.getAssessment() } }),
        title: 'Attainment',
        save: this.saveAssessment,
        saving: false,
        ab_id: 0
      },
      // GRADES
      ageGroup: {
        agegroup_id: null,
        toggle: (agegroup_id) => this.setState({ ageGroup: { ...this.state.ageGroup, agegroup_id: (agegroup_id == this.state.ageGroup.agegroup_id) ? null : agegroup_id } })
      },
      AgeGroupData: {
        openSearch: false,
        toggleSearch: () => this.setState({ AgeGroupData: { ...this.state.AgeGroupData, openSearch: !this.state.AgeGroupData.openSearch } }),
        changeSearch: (search = '') => this.setState({ AgeGroupData: { ...this.state.AgeGroupData, search } }, () => this.getAgeGroup()),
        search: '',
        order: 'agegroup.agegroup_code',
        dir: 'DESC',
        limit: 20,
        offset: 0,
        data: [],
        total_count: 0,
        total_count_filtered: 0,
        refreshing: false,
        finished: false,
      },
      modalAgeGroup: {
        show: false,
        toggle: (ab_id) => this.setState({ modalAgeGroup: { ...this.state.modalAgeGroup, show: !this.state.modalAgeGroup.show, ab_id } }, () => { if (this.state.modalAgeGroup.show) { this.getAgeGroup() } }),
        title: 'Grades',
        save: this.saveAgeGroup,
        saving: false,
        ab_id: 0
      },
    }
  }

  componentDidMount() {
    if (this.props.academicBoard.ab_id) {
      this.getCurriculum()
      this.getAgeGroup()
    }
    this.getTermsDefault()
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.academicBoard.ab_id != prevProps.academicBoard.ab_id || this.props.learnArea.learnarea_id != prevProps.learnArea.learnarea_id || this.props.learnAspect.lrnaspect_id != prevProps.learnAspect.lrnaspect_id) {
      this.setState({
        curriculumData: {
          ...this.state.curriculumData,
          data: [],
          total_count: 0,
          total_count_filtered: 0,
          refreshing: false,
          finished: false,
          offset: 0,
        },
        AgeGroupData: {
          ...this.state.AgeGroupData,
          data: [],
          total_count: 0,
          total_count_filtered: 0,
          refreshing: false,
          finished: false
        },
        curriculum: {
          curriculum_id: 0,
          curriculum_ref_num: '0',
          curriculum_code: '',
          curriculum_desc: '',
          curriculum_desc2: '',
          curriculum_status: 1,
          ab_id: this.props.academicBoard.ab_id,
          learnarea_id: this.props.learnArea.learnarea_id,
          lrnaspect_id: this.props.learnAspect.lrnaspect_id,
          agegroup_id: 0
        },
        ageGroup: {
          ...this.state.ageGroup,
          agegroup_id: this.props.learnAspect.agegroup_id,
        },
      }, () => {
        this.getCurriculum()
        this.getAgeGroup()
      });
    }
    else if (this.state.ageGroup.agegroup_id != prevState.ageGroup.agegroup_id) {
      this.setState({
        curriculumData: {
          ...this.state.curriculumData,
          data: [],
          total_count: 0,
          total_count_filtered: 0,
          refreshing: false,
          finished: false,
          offset: 0,
        },
        curriculum: {
          curriculum_id: 0,
          curriculum_ref_num: '0',
          curriculum_code: '',
          curriculum_desc: '',
          curriculum_desc2: '',
          curriculum_status: 1,
          ab_id: this.props.academicBoard.ab_id,
          learnarea_id: this.props.learnArea.learnarea_id,
          lrnaspect_id: this.props.learnAspect.lrnaspect_id,
          agegroup_id: 0
        },
      }, () => {
        this.getCurriculum()
      });
    }

  }

  getTermsDefault = async () => {
    if (this.getTermsDefaultReq) {
      this.getTermsDefaultReq.abort();
    }
    this.getTermsDefaultReq = new AbortController();

    try {
      const res = await GET('curriculum/get-terms-default',
        {
          params: {
            auth_key: this.props.authData.loginData.auth_key,
            appname: process.env.REACT_APP_NAME,
          },
          signal: this.getTermsDefaultReq.signal,
        }
      );
      this.setState({
        termsDefault: res.data
      });
    } catch (err) {
      console.log('err', err)
      if (axios.isCancel(err)) {

      } else {
        toast.error(t('Something went wrong while fetching data!'));

      }
    }
  };

  getCurriculum = async () => {
    if (!this.props.academicBoard.ab_id) {
      return
    }

    if (this.getCurriculumReq) {
      this.getCurriculumReq.abort();
    }
    this.getCurriculumReq = new AbortController();

    let curriculumData = this.state.curriculumData
    this.setState({
      curriculumData: {
        ...curriculumData,
        refreshing: true
      }
    })

    try {
      const res = await GET('curriculum/get',
        {
          params: {
            center_id: this.props.authData.centerData.center_id,
            ab_id: this.props.academicBoard.ab_id,
            learnarea_id: this.props.learnArea.learnarea_id,
            lrnaspect_id: this.props.learnAspect.lrnaspect_id,
            agegroup_id: this.state.ageGroup.agegroup_id,
            search: this.state.curriculumData.search,
            order: this.state.curriculumData.order,
            dir: this.state.curriculumData.dir,
            limit: this.state.curriculumData.limit,
            offset: this.state.curriculumData.offset,
            auth_key: this.props.authData.loginData.auth_key,
            appname: process.env.REACT_APP_NAME,
          },
          signal: this.getCurriculumReq.signal,
        }
      );
      this.setState({
        curriculumData: {
          ...curriculumData,
          data: curriculumData.data.concat(res.data.data),
          total_count: res.data.total_count,
          total_count_filtered: res.data.total_count_filtered,
          refreshing: false,
          finished: (res.data.data.length < this.state.curriculumData.limit) ? true : false
        }
      });
    } catch (err) {
      console.log('err', err)
      if (axios.isCancel(err)) {

      } else {
        toast.error(t('Something went wrong while fetching data!'));

      }
    }
  };

  getAssessment = async () => {
    if (!this.state.modalAssessment.ab_id) {
      return
    }

    if (this.getAssessmentReq) {
      this.getAssessmentReq.abort();
    }
    this.getAssessmentReq = new AbortController();

    let assessmentData = this.state.assessmentData
    this.setState({
      assessmentData: {
        ...assessmentData,
        refreshing: true
      }
    })

    try {
      const res = await GET('assessment/get',
        {
          params: {
            ab_id: this.state.modalAssessment.ab_id,
            search: this.state.assessmentData.search,
            order: this.state.assessmentData.order,
            dir: this.state.assessmentData.dir,
            limit: this.state.assessmentData.limit,
            offset: this.state.assessmentData.offset,
          },
          signal: this.getAssessmentReq.signal,
        }
      );
      this.setState({
        assessmentData: {
          ...assessmentData,
          data: res.data.data,
          total_count: res.data.total_count,
          total_count_filtered: res.data.total_count_filtered,
          refreshing: false,
          finished: (res.data.data.length < this.state.assessmentData.limit) ? true : false
        }
      });
    } catch (err) {
      console.log('err', err)
      if (axios.isCancel(err)) {

      } else {
        toast.error(t('Something went wrong while fetching data!'));

      }
    }
  };


  getCurriculumDetails = async () => {
    if (!this.state.modalCurriculumDetails.show) {
      return
    }

    if (this.getCurriculumDetailsReq) {
      this.getCurriculumDetailsReq.abort();
    }
    this.getCurriculumDetailsReq = new AbortController();

    this.setState({
      curriculumDetailsData: {
        ...this.state.curriculumDetailsData,
        refreshing: true
      }
    })

    try {
      const res = await GET(
        'curriculum/get-curriculum-details',
        {
          params: {
            curriculum_id: this.state.modalCurriculumDetails.curriculum_id,
            search: this.state.curriculumDetailsData.search,
            order: this.state.curriculumDetailsData.order,
            dir: this.state.curriculumDetailsData.dir,
            limit: this.state.curriculumDetailsData.limit,
            offset: this.state.curriculumDetailsData.offset,
          },
          signal: this.getCurriculumDetailsReq.signal,
        }
      );
      this.setState({
        curriculumDetailsData: {
          ...this.state.curriculumDetailsData,
          data: res.data.data,
          total_count: res.data.total_count,
          total_count_filtered: res.data.total_count_filtered,
          refreshing: false,
          finished: true
        }
      });
    } catch (err) {
      console.log('err', err)
      if (axios.isCancel(err)) {

      } else {
        toast.error(t('Something went wrong while fetching data!'));

      }
    }
  };

  getAgeGroup = async () => {
    if (!this.props.academicBoard.ab_id) {
      return
    }
    if (this.getAgeGroupReq) {
      this.getAgeGroupReq.abort();
    }
    this.getAgeGroupReq = new AbortController();

    this.setState({
      AgeGroupData: {
        ...this.state.AgeGroupData,
        refreshing: true
      }
    })

    try {
      const res = await GET('age-group/get',
        {
          params: {
            ab_id: this.props.academicBoard.ab_id,
            search: this.state.AgeGroupData.search,
            order: this.state.AgeGroupData.order,
            dir: this.state.AgeGroupData.dir,
            limit: this.state.AgeGroupData.limit,
            offset: this.state.AgeGroupData.offset,
          },
          signal: this.getAgeGroupReq.signal,
        }
      );
      this.setState({
        AgeGroupData: {
          ...this.state.AgeGroupData,
          data: res.data.data.map((v) => { return { ...v, ab_ids: JSON.parse(v.ab_ids) } }),
          total_count: res.data.total_count,
          total_count_filtered: res.data.total_count_filtered,
          refreshing: false,
          finished: true
        }
      });
    } catch (err) {
      console.log('err', err)
      if (axios.isCancel(err)) {

      } else {
        toast.error(t('Something went wrong while fetching data!'));

      }
    }
  };

  saveCurriculum = async () => {
    this.setState({ modalCurriculum: { ...this.state.modalCurriculum, saving: true } });
    const { auth_key, user_id, full_name, role_id } =
      this.props.authData.loginData;
    const { center_id, center_timezone } = this.props.authData.centerData;
    let curriculum = JSON.parse(JSON.stringify(this.state.curriculum));

    if (curriculum.term_d_ids.length > 0) {
      curriculum.term_d_ids = curriculum.term_d_ids.map(item => parseInt(item.value));
    }


    const fData = new FormData();
    fData.append('user_id', user_id);
    fData.append('center_id', center_id);
    fData.append('center_timezone', center_timezone);
    fData.append('curriculum_id', curriculum.curriculum_id);
    fData.append('CurriculumData', JSON.stringify(curriculum));

    try {
      const res = await axios.post(
        apiURL + 'curriculum/save',
        fData,
        {
          params: {
            auth_key: auth_key,
            appname: process.env.REACT_APP_NAME,
          },
        }
      );


      if (!res.data.type) {
        if (res.data.data != null) {
          handleFormErrors(res.data.data);
        } else {
          toast.error(t('Something went wrong while saving!'));
        }
        this.setState({ modalCurriculum: { ...this.state.modalCurriculum, saving: false } });
      } else {
        let curriculumData = this.state.curriculumData
        if (curriculum.curriculum_id != 0) {
          curriculumData.data = curriculumData.data.map((v, i) => {
            if (v.curriculum_id == curriculum.curriculum_id) {
              return { ...v, ...res.data.data }
            }
            return v
          })
        } else {
          curriculumData.data = [res.data.data, ...curriculumData.data]
        }

        this.setState({
          curriculumData,
          modalCurriculum: {
            ...this.state.modalCurriculum,
            show: false,
            saving: false,
          },
          curriculum: {
            curriculum_id: 0,
            curriculum_ref_num: '0',
            curriculum_code: '',
            curriculum_desc: '',
            curriculum_desc2: '',
            curriculum_status: 1,
            ab_id: this.props.academicBoard.ab_id,
            learnarea_id: this.props.learnArea.learnarea_id,
            lrnaspect_id: this.props.learnAspect.lrnaspect_id,
            agegroup_id: 0,
            term_d_ids: []
          },
        });
        toast.success(t(res.data.message));
      }
    } catch (err) {
      console.log('err', err)
      toast.error(t('Something went wrong while sending message!'));
      this.setState({ modalCurriculum: { ...this.state.modalCurriculum, saving: false } });
    }
  };

  saveCurriculumDetails = async () => {
    this.setState({ modalCurriculumDetails: { ...this.state.modalCurriculumDetails, saving: true } });
    const { auth_key, user_id, full_name, role_id } =
      this.props.authData.loginData;
    const { center_id, center_timezone } = this.props.authData.centerData;
    const { curriculumDetailsData } = this.state;

    const fData = new FormData();
    fData.append('user_id', user_id);
    fData.append('center_id', center_id);
    fData.append('center_timezone', center_timezone);
    fData.append('curriculum_id', this.state.modalCurriculumDetails.curriculum_id);
    fData.append('CurriculumDetailsData', JSON.stringify(curriculumDetailsData.data));

    try {
      const res = await axios.post(
        apiURL + 'curriculum/save-curriculum-details',
        fData,
        {
          params: {
            auth_key: auth_key,
            appname: process.env.REACT_APP_NAME,
          },
        }
      );


      if (!res.data.type) {
        if (res.data.data != null) {
          handleFormErrors(res.data.data);
        } else {
          toast.error(t('Something went wrong while saving!'));
        }
      } else {
        this.setState({
          modalCurriculum: {
            ...this.state.modalCurriculum,
            saving: false,
          },
        }, () => this.getCurriculumDetails());
        toast.success(t(res.data.message));

      }
    } catch (err) {
      console.log('err', err)
      toast.error(t('Something went wrong while sending message!'));

    }
  };

  saveAssessment = async () => {
    this.setState({ modalAssessment: { ...this.state.modalAssessment, saving: true } });
    const { auth_key, user_id, full_name, role_id } =
      this.props.authData.loginData;
    const { center_id, center_timezone } = this.props.authData.centerData;
    const { assessmentData } = this.state;

    const fData = new FormData();
    fData.append('user_id', user_id);
    fData.append('center_id', center_id);
    fData.append('center_timezone', center_timezone);
    fData.append('ab_id', this.state.modalAssessment.ab_id);
    fData.append('AssessmentData', JSON.stringify(assessmentData.data));

    try {
      const res = await axios.post(
        apiURL + 'assessment/save',
        fData,
        {
          params: {
            auth_key: auth_key,
            appname: process.env.REACT_APP_NAME,
          },
        }
      );


      if (!res.data.type) {
        if (res.data.data != null) {
          handleFormErrors(res.data.data);
        } else {
          toast.error(t('Something went wrong while saving!'));
        }
      } else {
        this.setState({
          modalAssessment: {
            ...this.state.modalAssessment,
            saving: false,
          },
        }, () => this.getAssessment());
        toast.success(t(res.data.message));
      }
    } catch (err) {
      console.log('err', err)
      toast.error(t('Something went wrong while sending message!'));

    }
  };

  saveAgeGroup = async () => {
    this.setState({ modalAgeGroup: { ...this.state.modalAgeGroup, saving: true } });
    const { auth_key, user_id, full_name, role_id } =
      this.props.authData.loginData;
    const { center_id, center_timezone } = this.props.authData.centerData;
    const { AgeGroupData } = this.state;

    const fData = new FormData();
    fData.append('user_id', user_id);
    fData.append('center_id', center_id);
    fData.append('center_timezone', center_timezone);
    fData.append('ab_id', this.state.modalAgeGroup.ab_id);
    fData.append('AgeGroupData', JSON.stringify(AgeGroupData.data));

    try {
      const res = await axios.post(
        apiURL + 'age-group/save',
        fData,
        {
          params: {
            auth_key: auth_key,
            appname: process.env.REACT_APP_NAME,
          },
        }
      );


      if (!res.data.type) {
        if (res.data.data != null) {
          handleFormErrors(res.data.data);
        } else {
          toast.error(t(res.data.message));
        }
      } else {
        this.setState({
          modalAgeGroup: {
            ...this.state.modalAgeGroup,
            saving: false,
          },
        }, () => this.getAgeGroup());
        toast.success(t(res.data.message));
      }
    } catch (err) {
      console.log('err', err)
      toast.error(t('Something went wrong while sending message!'));

    }
  };

  toggleStatusCurriculum = async (curriculum_id) => {
    const { auth_key, user_id, full_name, role_id } =
      this.props.authData.loginData;
    const { center_id, center_timezone } = this.props.authData.centerData;
    const fData = new FormData();
    fData.append('center_id', center_id);
    fData.append('curriculum_id', curriculum_id);
    try {
      const res = await axios.post(
        apiURL + 'curriculum/toggle-status',
        fData,
        {
          params: {
            auth_key: auth_key,
            appname: process.env.REACT_APP_NAME,
          },
        }
      );

      if (!res.data.status) {
        toast.error(t('Something went wrong while updating!'));
      } else {
        let curriculumData = this.state.curriculumData
        curriculumData.data = curriculumData.data.map((v, i) => {
          if (v.curriculum_id == curriculum_id) {
            return { ...v, curriculum_status: (v.curriculum_status == 1) ? 0 : 1 }
          }
          return v
        })
        this.setState({ curriculumData })
        toast.warning(t('Status updated'));
      }
    } catch (err) {
      console.log('err', err)
      toast.error(t('Something went wrong while updating!'));

      this.setState({ msgSending: false });
    }
  };
  setStateCurriculum = (field, value) => {
    this.setState({ curriculum: { ...this.state.curriculum, [field]: value } })
  }
  deleteCurriculum = (curriculum_id) => {
    this.setState({
      deleteModal: {
        ...this.state.deleteModal, show: true, action: () => {
          // DELETE FILE CALL 
          try {
            GET('curriculum/delete', {
              params: {
                curriculum_id: curriculum_id,
              },
            }).then((res) => {

              if (res.data.type) {
                let curriculumData = this.state.curriculumData
                curriculumData.data = curriculumData.data.filter((v, i) => {
                  if (v.curriculum_id != curriculum_id) {
                    return true
                  }
                  return false
                })
                this.setState({
                  curriculumData, deleteModal: {
                    ...this.state.deleteModal, show: false
                  }
                })
                toast.warning(t(res.data.message));
              } else {
                this.setState({
                  deleteModal: {
                    ...this.state.deleteModal, show: false
                  }
                })
                toast.error(t(res.data.message));
              }
            })
          } catch (err) {
            console.log('err', err)
            toast.error(t('Something went wrong while deleting!'));

          }
        }
      }
    })
  }

  setStateCurriculumDetails = (curriculum_d_id, value) => {
    let curriculumDetailsData = this.state.curriculumDetailsData
    curriculumDetailsData.data = curriculumDetailsData.data.map((v, i) => {
      if (v.curriculum_d_id == curriculum_d_id) {
        return { ...v, curriculum_d_desc: value, edit: true }
      }
      return v
    })
    this.setState({ curriculumDetailsData })
  }

  deleteCurriculumDetails = (curriculum_d_id) => {
    let curriculumDetailsData = this.state.curriculumDetailsData
    curriculumDetailsData.data = curriculumDetailsData.data.filter((v, i) => {
      if (v.curriculum_d_id != curriculum_d_id) {
        return v
      }
    })
    this.setState({ curriculumDetailsData })
  }

  deleteAssessment = (assmt_id) => {
    this.setState({
      deleteModal: {
        ...this.state.deleteModal, show: true, action: () => {
          // DELETE FILE CALL 
          try {
            GET('assessment/delete', {
              params: {
                assmt_id: assmt_id,
              },
            }).then((res) => {

              if (res.data.type) {
                let assessmentData = this.state.assessmentData
                assessmentData.data = assessmentData.data.filter((v, i) => {
                  if (v.assmt_id != assmt_id) {
                    return v
                  }
                })
                this.setState({
                  assessmentData, deleteModal: {
                    ...this.state.deleteModal, show: false
                  }
                })
              } else {
                this.setState({
                  deleteModal: {
                    ...this.state.deleteModal, show: false
                  }
                })
                toast.error(t(res.data.message));
              }
            })
          } catch (err) {
            console.log('err', err)
            toast.error(t('Something went wrong while deleting!'));

          }
        }
      }
    })

  }

  deleteAgeGroup = (agegroup_id) => {
    this.setState({
      deleteModal: {
        ...this.state.deleteModal, show: true, action: () => {
          // DELETE FILE CALL 
          try {
            GET('age-group/delete', {
              params: {
                agegroup_id: agegroup_id,
              },
            }).then((res) => {

              if (res.data.type) {
                let AgeGroupData = this.state.AgeGroupData
                AgeGroupData.data = AgeGroupData.data.filter((v, i) => {
                  if (v.agegroup_id != agegroup_id) {
                    return v
                  }
                })
                this.setState({
                  AgeGroupData, deleteModal: {
                    ...this.state.deleteModal, show: false
                  }
                })
              } else {
                this.setState({
                  deleteModal: {
                    ...this.state.deleteModal, show: false
                  }
                })
                toast.error(t(res.data.message));
              }
            })
          } catch (err) {
            console.log('err', err)
            toast.error(t('Something went wrong while deleting!'));

          }
        }
      }
    })
  }

  setStateAssessment = (assmt_id, field, value) => {
    let assessmentData = this.state.assessmentData
    assessmentData.data = assessmentData.data.map((v, i) => {
      if (v.assmt_id == assmt_id) {
        return { ...v, [field]: value, edit: true }
      }
      return v
    })
    this.setState({ assessmentData })
  }

  setStateAgeGroup = (agegroup_id, field, value) => {
    let AgeGroupData = this.state.AgeGroupData
    AgeGroupData.data = AgeGroupData.data.map((v, i) => {
      if (v.agegroup_id == agegroup_id) {
        return { ...v, [field]: value, edit: true }
      }
      return v
    })
    this.setState({ AgeGroupData })
  }


  render() {

    const { defaultTheme, academicBoard, learnArea, learnAspect } = this.props;
    const { curriculumData, curriculumDetailsData, AgeGroupData, ageGroup, modalCurriculum, curriculum, modalCurriculumDetails, assessmentData, modalAssessment, modalAgeGroup } = this.state;


    return (<div>
      <div className='grey-section-header mt-2 flex-row align-items-center'>
        <div className='d-flex flex-row align-items-center' style={{ flex: 1 }}>
          <h5 className='mb-0 me-5'>
            <div>
              {t('Objectives')}
            </div>
            <div style={{ fontSize: 12, color: brand_colors[defaultTheme.theme_id].color18 }}>Showing {curriculumData.data.length} {curriculumData.search && ', Filtered ' + curriculumData.total_count_filtered} entries</div>
            <div style={{ fontSize: 12, color: brand_colors[defaultTheme.theme_id].color18 }}>Total {curriculumData.total_count} entries</div>
          </h5>
          {/* AGEGROUPS FILTER*/}
          <div className='d-flex flex-row align-items-center flex-wrap'>
            {AgeGroupData.data.map((v, i) => {
              if (this.props.learnAspect.agegroup_id == null || this.props.learnAspect.agegroup_id == v.agegroup_id) {
                return <div key={i} className='badge cursor-pointer me-1 mb-1' style={{
                  border: 'solid 1px ' + v.agegroup_color,
                  color: v.agegroup_color
                }} onClick={() => ageGroup.toggle(v.agegroup_id)}>
                  {v.agegroup_code}

                  <FontAwesomeIcon icon={ageGroup.agegroup_id == v.agegroup_id ? faCheckCircle : faCircle} color={v.agegroup_color} className='ms-2 cursor-pointer' onClick={() => ageGroup.toggle(v.agegroup_id)} title={t('Toggle Select')} />
                </div>
              }
            })}
          </div>

        </div>
        <div className='d-flex flex-row align-items-center'>

          <ButtonGroup className='mx-1'>
            <div className='cb-expandable-search' style={{ width: curriculumData.openSearch ? 178 : 32, borderRadius: 0 }}>
              <input
                ref={this.searchFieldRef}
                onChange={(e) => curriculumData.changeSearch(e.target.value)}
                value={curriculumData.search}
                type='text'
                style={{ width: curriculumData.openSearch ? 150 : 0, paddingLeft: curriculumData.openSearch ? 15 : 0, opacity: curriculumData.openSearch ? 1 : 0, backgroundColor: brand_colors[defaultTheme.theme_id].color8, fontSize: 14 }}
                placeholder={t('Search')} />
              <Button
                style={{ border: 'inherit', }}
                size='sm'
                title={t('Search')}
                variant={(curriculumData.search) ? 'danger' : 'primary'}
                onClick={() => curriculumData.toggleSearch()}>
                <FontAwesomeIcon
                  icon={curriculumData.openSearch ? faXmark : faSearch}
                  color={brand_colors[defaultTheme.theme_id].color8}
                />
              </Button>
            </div>
            <DropdownButton size='sm' as={ButtonGroup} title={'ORDER BY: ' + curriculumData.orderName} id="bg-nested-dropdown">
              <Dropdown.Item onClick={() => curriculumData.changeOrder('Newest First', 'curriculum.curriculum_id', 'DESC')}>Newest First</Dropdown.Item>
              <Dropdown.Item onClick={() => curriculumData.changeOrder('Oldest First', 'curriculum.curriculum_id', 'ASC')}>Oldest First</Dropdown.Item>
              <Dropdown.Item onClick={() => curriculumData.changeOrder('Code ASC', 'curriculum.curriculum_code', 'ASC')}>Code ASC</Dropdown.Item>
              <Dropdown.Item onClick={() => curriculumData.changeOrder('Code DESC', 'curriculum.curriculum_code', 'DESC')}>Code DESC</Dropdown.Item>
              <Dropdown.Item onClick={() => curriculumData.changeOrder('Desc ASC', 'curriculum.curriculum_desc', 'ASC')}>Desc ASC</Dropdown.Item>
              <Dropdown.Item onClick={() => curriculumData.changeOrder('Desc DESC', 'curriculum.curriculum_desc', 'DESC')}>Desc DESC</Dropdown.Item>
            </DropdownButton>
          </ButtonGroup>

          <div className='d-flex flex-row align-items-center'>
            <div className='me-1'>
              <Button
                className='btn-sm btn-add'
                variant='success'
                onClick={() => (academicBoard.ab_id)
                  ? modalAgeGroup.toggle(academicBoard.ab_id)
                  : toast.warning(t('Please select a Curriculum first!'))}>
                {t('Grades')}{' '}
                <FontAwesomeIcon
                  icon={faPlus}
                  color={brand_colors[defaultTheme.theme_id].color8}
                  style={{
                    fontSize: 16,
                  }}
                />
              </Button>
            </div>
            <div className='me-1'>
              <Button
                className='btn-sm btn-add'
                variant='success'
                onClick={() => (academicBoard.ab_id)
                  ? modalAssessment.toggle(academicBoard.ab_id)
                  : toast.warning(t('Please select a Curriculum first!'))}>
                {t('Attainments')}{' '}
                <FontAwesomeIcon
                  icon={faPlus}
                  color={brand_colors[defaultTheme.theme_id].color8}
                  style={{
                    fontSize: 16,
                  }}
                />
              </Button>
            </div>
            <Button
              onClick={() => (learnArea.learnarea_id && learnAspect.lrnaspect_id && ageGroup.agegroup_id)
                ? this.setState({
                  curriculum: {
                    curriculum_id: 0,
                    curriculum_ref_num: '0',
                    curriculum_code: '',
                    curriculum_desc: '',
                    curriculum_desc2: '',
                    curriculum_status: 1,
                    ab_id: academicBoard.ab_id,
                    learnarea_id: learnArea.learnarea_id,
                    lrnaspect_id: learnAspect.lrnaspect_id,
                    agegroup_id: ageGroup.agegroup_id,
                    term_d_ids: []
                  },
                }, () => modalCurriculum.toggle())
                : toast.warning(t('Please select a Main Domain, Sub Domain & Grade first!'))}
              className='btn-sm btn-add'
              variant='success'>
              {t('Add Objective')}{' '}
              <FontAwesomeIcon
                icon={faPlus}
                color={brand_colors[defaultTheme.theme_id].color8}
                style={{
                  fontSize: 16,
                }}
              />
            </Button>
          </div>
        </div>
      </div>
      <div style={{}}>

        {!academicBoard.ab_id
          && <div className='d-flex mt-2 align-items-center'>
            <div style={{ color: brand_colors[defaultTheme.theme_id].color18 }}>{t('Please select a Curriculum')}</div>
          </div>
        }

        {academicBoard.ab_id &&
          curriculumData.data.map((v, i) => {
            return <div key={i} className='scroll-list-card'>
              <div className='d-flex flex-row justify-content-between align-items-center p-0 m-0'
                style={{ flex: 1 }}>
                <div className='cd-div-actionbtn' style={{ width: this.state.searchExpanded ? 130 : 14 }}>
                  <FontAwesomeIcon icon={faGripVertical} color={brand_colors[defaultTheme.theme_id].color18} className='cursor-pointer' onClick={() => this.setState({ searchExpanded: !this.state.searchExpanded })} />
                  <div className='d-flex flex-row align-items-center all-btns' style={{ opacity: this.state.searchExpanded ? 1 : 0 }} >

                    {/* EDIT */}
                    <FontAwesomeIcon icon={faEdit} color={brand_colors[defaultTheme.theme_id].color16} className='cursor-pointer ms-3'
                      onClick={() => {
                        let term_d_ids = []
                        let selected_term_d_ids = JSON.parse(v.term_d_ids)
                        this.state.termsDefault.map((vv) => {
                          if (selected_term_d_ids.includes(parseInt(vv.term_d_id))) {
                            term_d_ids.push({ label: vv.term_d_name, value: vv.term_d_id })
                          }
                        })
                        this.setState({
                          modalCurriculum: { ...modalCurriculum, show: true, title: 'Edit Objective' },
                          curriculum: { ...curriculum, ...v, term_d_ids },
                        })
                      }} title={t('Edit')} />
                    {/* DELETE */}
                    <FontAwesomeIcon icon={faTrashCan} color={brand_colors[defaultTheme.theme_id].color11} className='cursor-pointer ms-3' onClick={() => this.deleteCurriculum(v.curriculum_id)} title={t('Delete')} />
                    {/* STATUS */}
                    <FontAwesomeIcon icon={(v.curriculum_status == 1) ? faThumbsDown : faThumbsUp} color={(v.curriculum_status == 1) ? brand_colors[defaultTheme.theme_id].color12 : brand_colors[defaultTheme.theme_id].color11} className='cursor-pointer ms-3' title={(v.curriculum_status == 1) ? t('DeActivate') : t('Activate')}
                      onClick={() => this.toggleStatusCurriculum(v.curriculum_id)} />

                    {/* Details */}
                    <FontAwesomeIcon icon={faListOl} color={brand_colors[defaultTheme.theme_id].color16} className='cursor-pointer ms-3'
                      onClick={() => modalCurriculumDetails.toggle(v.curriculum_id)} title={t('Details')} />
                  </div>
                </div>

                <table width="100%">
                  <tr>
                    <td width="5%" style={{ fontWeight: 600 }}>#{v.curriculum_ref_num}</td>
                    <td width="25%">{v.curriculum_code}</td>
                    <td width="25%">{v.curriculum_desc}</td>
                    <td width="25%">{v.curriculum_desc2}</td>
                    <td width="20%">{v.terms && v.terms.map(item => item.term_d_name).join(', ')}</td>
                  </tr>
                </table>
              </div>
              <div>
                <div className='badge cursor-pointer' style={{
                  border: 'solid 1px ' + v.agegroup_color,
                  color: v.agegroup_color
                }}>
                  {v.agegroup_code}
                </div>
              </div>
            </div>
          })
        }
        <div>
          {curriculumData.refreshing && !curriculumData.finished &&
            <div className='d-flex mt-2 align-items-center justify-content-center'>
              <Spinner animation='grow' variant='dark' />
            </div>
          }
          {/* {!curriculumData.refreshing && curriculumData.finished && curriculumData.data.length > 0 &&
                            <div className='d-flex mt-2 align-items-center'>
                                <div style={{ color: brand_colors[defaultTheme.theme_id].color18 }}>NO MORE DATA...</div>
                            </div>
                        } */}
          {!curriculumData.refreshing && curriculumData.finished && curriculumData.data.length <= 0 &&
            <div className='d-flex mt-2 align-items-center justify-content-center'>
              <div style={{ color: brand_colors[defaultTheme.theme_id].color18 }}>NO DATA FOUND...</div>
            </div>
          }
          {!curriculumData.refreshing && !curriculumData.finished && curriculumData.data.length > 0 &&
            <div className='d-flex mt-2 align-items-center justify-content-center' onClick={() => this.setState({
              curriculumData: {
                ...curriculumData,
                offset: curriculumData.offset + curriculumData.limit,
              },
            }, () => this.getCurriculum())}>
              <div className='cursor-pointer' style={{ color: brand_colors[defaultTheme.theme_id].color18 }}>Load More...</div>
            </div>
          }
        </div>
      </div>

      <SweetAlert
        show={this.state.deleteModal.show}
        warning
        showCancel
        confirmBtnText={t('Yes, delete it!')}
        confirmBtnBsStyle='danger'
        title={(this.state.deleteModal.title) ? this.state.deleteModal.title : t('Are you sure?')}
        onConfirm={this.state.deleteModal.action}
        onCancel={() => this.setState({ deleteModal: { ...this.state.deleteModal, show: false, id: 0, action: () => { } } })}
        focusCancelBtn>
        {(this.state.deleteModal.desc) ? this.state.deleteModal.desc : t('Confirm Delete!')}
      </SweetAlert>

      {/* MODAL NEW/EDIT ACADEMIC BOARD */}
      <Modal show={modalCurriculum.show} onHide={() => modalCurriculum.toggle()} size="lg" backdrop="static"
        keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>{modalCurriculum.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body className='custom-styled-form'>
          <Row>
            <Col md="8">
              <Row>
                <Col md="4">
                  <Form.Group className='mb-3'>
                    <Form.Label>
                      <img src={imgDescription} />
                      {t('Ref Num')}
                    </Form.Label>
                    <Form.Control type='text' defaultValue={curriculum.curriculum_ref_num} onBlur={(event) => this.setStateCurriculum('curriculum_ref_num', event.target.value)} />
                  </Form.Group>
                </Col>
                <Col md="8">
                  <Form.Group className='mb-3'>
                    <Form.Label>
                      <img src={imgDescription} />
                      {t('Code')}
                    </Form.Label>
                    <Form.Control type='text' defaultValue={curriculum.curriculum_code} onBlur={(event) => this.setStateCurriculum('curriculum_code', event.target.value)} />
                  </Form.Group>
                </Col>
                <Col md="12">
                  <Form.Group className='mb-3'>
                    <Form.Label>
                      <img src={imgDescription} />
                      {t('Description')}
                    </Form.Label>
                    < Form.Control as="textarea" rows={3} defaultValue={curriculum.curriculum_desc} onBlur={(event) => this.setStateCurriculum('curriculum_desc', event.target.value)} />
                  </Form.Group>
                </Col>
                <Col md="12">
                  <Form.Group className='mb-3'>
                    <Form.Label>
                      <img src={imgDescription} />
                      {t('Description2')}
                    </Form.Label>
                    < Form.Control as="textarea" rows={3} defaultValue={curriculum.curriculum_desc2} onBlur={(event) => this.setStateCurriculum('curriculum_desc2', event.target.value)} />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md="8" >
                  <Form.Group className='mb-3'>
                    <Form.Label>{t('Terms')}</Form.Label>
                    <Select
                      isMulti
                      closeMenuOnSelect={false}
                      options={this.state.termsDefault.map((v, i) => { return { label: v.term_d_name, value: v.term_d_id } })}
                      onChange={(event) => {
                        this.setStateCurriculum('term_d_ids', event)
                      }}
                      value={curriculum.term_d_ids}
                      menuPortalTarget={document.body}
                      styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                    />
                  </Form.Group>
                </Col>
                <Col md="4" className='text-center'>
                  <Form.Label className='no-style'>
                    {t('Status')}
                  </Form.Label>
                  <Form.Check
                    type="switch"
                    style={{}}
                    value={1}
                    checked={(curriculum.curriculum_status == 0) ? false : true}
                    onChange={(event) => this.setStateCurriculum('curriculum_status', (curriculum.curriculum_status == 1) ? 0 : 1)}
                  />
                </Col>

              </Row>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => modalCurriculum.toggle()}>
            Close
          </Button>
          <Button disabled={this.state.modalCurriculum.saving} variant="primary" onClick={modalCurriculum.save}>
            Save Changes {this.state.modalCurriculum.saving ? <Spinner as='span' animation='grow' size='sm' role='status' aria-hidden='true' /> : ''}
          </Button>
        </Modal.Footer>
      </Modal>

      {/* MODAL Curriculum Details */}
      <Modal show={modalCurriculumDetails.show} onHide={() => modalCurriculumDetails.toggle()} size="lg" backdrop="static"
        keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>{modalCurriculumDetails.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body className='custom-styled-form'>
          <div className='grey-section-header mt-2 flex-row align-items-center'>
            <div className='d-flex flex-row align-items-center'>
              {/* <div className='cb-expandable-search mx-1' style={{ width: curriculumDetailsData.openSearch ? 180 : 35 }}>
                                <input
                                    ref={this.searchFieldRef}
                                    onChange={(e) => curriculumDetailsData.changeSearch(e.target.value)}
                                    value={curriculumDetailsData.search}
                                    type='text'
                                    style={{ width: curriculumDetailsData.openSearch ? 150 : 0, paddingLeft: curriculumDetailsData.openSearch ? 15 : 0, opacity: curriculumDetailsData.openSearch ? 1 : 0, backgroundColor: brand_colors[defaultTheme.theme_id].color7 }}
                                    placeholder={t('Search ')} />
                                <Button
                                    data-tooltip-id="tooltip"
                                    data-tooltip-content={t('Search')}
                                    data-tooltip-place="top"
                                    title={t('Search')}
                                    onClick={() => curriculumDetailsData.toggleSearch()}>
                                    <FontAwesomeIcon
                                        icon={curriculumDetailsData.openSearch ? faXmark : faSearch}
                                        color={brand_colors[defaultTheme.theme_id].color8}
                                    />
                                </Button>
                            </div> */}
              <div className='d-flex flex-row align-items-center'>
                <Button
                  onClick={() => this.setState({
                    curriculumDetailsData: {
                      ...curriculumDetailsData, data: [
                        { curriculum_d_id: chance.unique(chance.integer, 1, { min: 99999999999, max: 999999999999 })[0], curriculum_id: modalCurriculumDetails.curriculum_id, curriculum_d_desc: '', edit: true },
                        ...curriculumDetailsData.data
                      ]
                    }
                  })}
                  className='btn-sm btn-add'
                  variant='success'>
                  {t('Add Objective Details')}{' '}
                  <FontAwesomeIcon
                    icon={faPlus}
                    color={brand_colors[defaultTheme.theme_id].color8}
                    style={{
                      fontSize: 16,
                    }}
                  />
                </Button>
              </div>
            </div>
          </div>
          <div>
            {curriculumDetailsData.data.map((v, i) => {
              return <div key={v.curriculum_d_id} className='scroll-list-card' style={{ backgroundColor: brand_colors[defaultTheme.theme_id].color7 }}>
                <div className='d-flex flex-row justify-content-between align-items-center p-0 m-0' style={{ flex: 1 }}>
                  <div className='cd-div-actionbtn' style={{ width: 70 }}>
                    <div className='d-flex flex-row align-items-center all-btns' style={{ opacity: 1 }} >
                      {/* EDIT */}
                      {!v.edit && <FontAwesomeIcon icon={faEdit} color={brand_colors[defaultTheme.theme_id].color16} className='cursor-pointer ms-3'
                        onClick={() => this.setStateCurriculumDetails(v.curriculum_d_id, v.curriculum_d_desc)} title={t('Edit')} />}
                      {/* DELETE */}
                      <FontAwesomeIcon icon={faTrashCan} color={brand_colors[defaultTheme.theme_id].color11} className='cursor-pointer ms-3' onClick={() => this.deleteCurriculumDetails(v.curriculum_d_id)} title={t('Delete')} />
                    </div>
                  </div>
                  <div style={{ flex: 1 }}>
                    {v.edit
                      ? <Form.Group >
                        <Form.Control as="textarea" rows={2} defaultValue={v.curriculum_d_desc} onBlur={(event) => this.setStateCurriculumDetails(v.curriculum_d_id, event.target.value)} />
                      </Form.Group>
                      : v.curriculum_d_desc
                    }
                  </div>
                </div>

              </div>
            })}
            <div>
              {curriculumDetailsData.refreshing && !curriculumDetailsData.finished &&
                <div className='d-flex mt-2 align-items-center justify-content-center'>
                  <Spinner animation='grow' variant='dark' />
                </div>
              }
              {/* {!curriculumDetailsData.refreshing && curriculumDetailsData.finished && curriculumDetailsData.data.length > 0 &&
                                    <div className='d-flex mt-2 align-items-center'>
                                        <div style={{ color: brand_colors[defaultTheme.theme_id].color18 }}>NO MORE DATA...</div>
                                    </div>
                                } */}
              {!curriculumDetailsData.refreshing && curriculumDetailsData.finished && curriculumDetailsData.data.length <= 0 &&
                <div className='d-flex mt-2 align-items-center justify-content-center'>
                  <div style={{ color: brand_colors[defaultTheme.theme_id].color18 }}>NO DATA FOUND...</div>
                </div>
              }
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => modalCurriculumDetails.toggle()}>
            Close
          </Button>
          <Button variant="primary" onClick={modalCurriculumDetails.save}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>

      {/* MODAL ATTAINMENTS */}
      <Modal show={modalAssessment.show} onHide={() => modalAssessment.toggle()} size="lg" backdrop="static"
        keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>{modalAssessment.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body className='custom-styled-form'>
          <div className='grey-section-header mt-2 flex-row align-items-center'>
            <div className='d-flex flex-row align-items-center'>
              {/* <div className='cb-expandable-search mx-1' style={{ width: curriculumDetailsData.openSearch ? 180 : 35 }}>
                                <input
                                    ref={this.searchFieldRef}
                                    onChange={(e) => curriculumDetailsData.changeSearch(e.target.value)}
                                    value={curriculumDetailsData.search}
                                    type='text'
                                    style={{ width: curriculumDetailsData.openSearch ? 150 : 0, paddingLeft: curriculumDetailsData.openSearch ? 15 : 0, opacity: curriculumDetailsData.openSearch ? 1 : 0, backgroundColor: brand_colors[defaultTheme.theme_id].color7 }}
                                    placeholder={t('Search ')} />
                                <Button
                                    data-tooltip-id="tooltip"
                                    data-tooltip-content={t('Search')}
                                    data-tooltip-place="top"
                                    title={t('Search')}
                                    onClick={() => curriculumDetailsData.toggleSearch()}>
                                    <FontAwesomeIcon
                                        icon={curriculumDetailsData.openSearch ? faXmark : faSearch}
                                        color={brand_colors[defaultTheme.theme_id].color8}
                                    />
                                </Button>
                            </div> */}
              <div className='d-flex flex-row align-items-center'>
                <Button
                  onClick={() => this.setState({
                    assessmentData: {
                      ...assessmentData, data: [
                        { assmt_id: chance.unique(chance.integer, 1, { min: 99999999999, max: 999999999999 })[0], assmt_code: '', assmt_code2: '', assmt_desc: '', assmt_desc2: '', assmt_value: 0, assmt_color: '#fff', ab_id: modalAssessment.ab_id, edit: true, open_assmt_color: false, },
                        ...assessmentData.data
                      ]
                    }
                  })}
                  className='btn-sm btn-add'
                  variant='success'>
                  {t('Add Attainment')}{' '}
                  <FontAwesomeIcon
                    icon={faPlus}
                    color={brand_colors[defaultTheme.theme_id].color8}
                    style={{
                      fontSize: 16,
                    }}
                  />
                </Button>
              </div>
            </div>
          </div>
          <div>
            {assessmentData.data.map((v, i) => {
              return <div key={v.assmt_id} className='scroll-list-card' style={{ backgroundColor: brand_colors[defaultTheme.theme_id].color7 }}>
                <div className='d-flex flex-row justify-content-between align-items-center p-0 m-0' style={{ flex: 1 }}>
                  <div className='cd-div-actionbtn' style={{ width: 70 }}>
                    <div className='d-flex flex-row align-items-center all-btns' style={{ opacity: 1 }} >
                      {/* EDIT */}
                      {!v.edit && <FontAwesomeIcon icon={faEdit} color={brand_colors[defaultTheme.theme_id].color16} className='cursor-pointer ms-3'
                        onClick={() => this.setStateAssessment(v.assmt_id, 'edit', true)} title={t('Edit')} />}
                      {/* DELETE */}
                      <FontAwesomeIcon icon={faTrashCan} color={brand_colors[defaultTheme.theme_id].color11} className='cursor-pointer ms-3' onClick={() => this.deleteAssessment(v.assmt_id)} title={t('Delete')} />
                    </div>
                  </div>
                  <div style={{ flex: 1 }}>
                    {v.edit
                      ? <div className='d-flex flex-column'>
                        <div className='d-flex flex-row justify-content-between'>
                          <Form.Group className='mx-1' style={{ flex: 2, }}>
                            <Form.Label>
                              <img src={imgDescription} />
                              {t('Code')}
                            </Form.Label>
                            <Form.Control type='text' defaultValue={v.assmt_code} onBlur={(event) => this.setStateAssessment(v.assmt_id, 'assmt_code', event.target.value)} />
                          </Form.Group>
                          <Form.Group className='mx-1' style={{ flex: 2, }}>
                            <Form.Label>
                              <img src={imgDescription} />
                              {t('Code 2')}
                            </Form.Label>
                            <Form.Control type='text' defaultValue={v.assmt_code2} onBlur={(event) => this.setStateAssessment(v.assmt_id, 'assmt_code2', event.target.value)} />
                          </Form.Group>

                        </div>
                        <div className='d-flex flex-row justify-content-between'>
                          <Form.Group className='mx-1' style={{ flex: 2, }}>
                            <Form.Label>
                              <img src={imgDescription} />
                              {t('Desc')}
                            </Form.Label>
                            <Form.Control as="textarea" rows={2} defaultValue={v.assmt_desc} onBlur={(event) => this.setStateAssessment(v.assmt_id, 'assmt_desc', event.target.value)} />
                          </Form.Group>
                          <Form.Group className='mx-1' style={{ flex: 2, }}>
                            <Form.Label>
                              <img src={imgDescription} />
                              {t('Desc 2')}
                            </Form.Label>
                            <Form.Control as="textarea" rows={2} defaultValue={v.assmt_desc2} onBlur={(event) => this.setStateAssessment(v.assmt_id, 'assmt_desc2', event.target.value)} />
                          </Form.Group>
                        </div>
                        <div className='d-flex flex-row justify-content-between'>
                          <Form.Group className='mx-1' style={{ flex: 1, }}>
                            <Form.Label className='no-style'>{t('Color')}</Form.Label>
                            <div className='selected-color' onClick={() => this.setStateAssessment(v.assmt_id, 'open_assmt_color', true)}>
                              <div style={{ backgroundColor: v.assmt_color }}></div>
                            </div>
                            {v.open_assmt_color &&
                              <div className='color-palette'>
                                {colorPalette.map((color, color_i) => (
                                  <div
                                    key={color_i}
                                    onClick={() => {
                                      assessmentData.data = assessmentData.data.map((vv, ii) => {

                                        if (v.assmt_id == vv.assmt_id) {
                                          return { ...vv, assmt_color: color.code, open_assmt_color: false }
                                        }
                                        return vv
                                      })
                                      this.setState({ assessmentData })
                                    }}
                                    style={{ backgroundColor: color.code }}
                                  ></div>
                                ))}
                              </div>
                            }
                          </Form.Group>
                        </div>
                      </div>
                      :

                      <div style={{ padding: '5px 10px', borderRadius: 4, display: 'inline-block', borderColor: v.assmt_color, borderWidth: 2, borderStyle: 'solid', color: v.assmt_color }}>{v.assmt_code}</div>



                    }
                  </div>
                </div>

              </div>
            })}
            <div>
              {assessmentData.refreshing && !assessmentData.finished &&
                <div className='d-flex mt-2 align-items-center justify-content-center'>
                  <Spinner animation='grow' variant='dark' />
                </div>
              }
              {/* {!assessmentData.refreshing && assessmentData.finished && assessmentData.data.length > 0 &&
                                    <div className='d-flex mt-2 align-items-center'>
                                        <div style={{ color: brand_colors[defaultTheme.theme_id].color18 }}>NO MORE DATA...</div>
                                    </div>
                                } */}
              {!assessmentData.refreshing && assessmentData.finished && assessmentData.data.length <= 0 &&
                <div className='d-flex mt-2 align-items-center justify-content-center'>
                  <div style={{ color: brand_colors[defaultTheme.theme_id].color18 }}>NO DATA FOUND...</div>
                </div>
              }
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => modalAssessment.toggle()}>
            Close
          </Button>
          <Button disabled={this.state.modalAssessment.saving} variant="primary" onClick={modalAssessment.save}>
            Save Changes {this.state.modalAssessment.saving ? <Spinner as='span' animation='grow' size='sm' role='status' aria-hidden='true' /> : ''}
          </Button>
        </Modal.Footer>
      </Modal>

      {/* MODAL GRADES */}
      <Modal show={modalAgeGroup.show} onHide={() => modalAgeGroup.toggle()} size="lg" backdrop="static"
        keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>{modalAgeGroup.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body className='custom-styled-form'>
          <div className='grey-section-header mt-2 flex-row align-items-center'>
            <div className='d-flex flex-row align-items-center'>
              {/* <div className='cb-expandable-search mx-1' style={{ width: curriculumDetailsData.openSearch ? 180 : 35 }}>
                                <input
                                    ref={this.searchFieldRef}
                                    onChange={(e) => curriculumDetailsData.changeSearch(e.target.value)}
                                    value={curriculumDetailsData.search}
                                    type='text'
                                    style={{ width: curriculumDetailsData.openSearch ? 150 : 0, paddingLeft: curriculumDetailsData.openSearch ? 15 : 0, opacity: curriculumDetailsData.openSearch ? 1 : 0, backgroundColor: brand_colors[defaultTheme.theme_id].color7 }}
                                    placeholder={t('Search ')} />
                                <Button
                                    data-tooltip-id="tooltip"
                                    data-tooltip-content={t('Search')}
                                    data-tooltip-place="top"
                                    title={t('Search')}
                                    onClick={() => curriculumDetailsData.toggleSearch()}>
                                    <FontAwesomeIcon
                                        icon={curriculumDetailsData.openSearch ? faXmark : faSearch}
                                        color={brand_colors[defaultTheme.theme_id].color8}
                                    />
                                </Button>
                            </div> */}
              <div className='d-flex flex-row align-items-center'>
                <Button
                  onClick={() => this.setState({
                    AgeGroupData: {
                      ...AgeGroupData, data: [
                        { agegroup_id: chance.unique(chance.integer, 1, { min: 99999999999, max: 999999999999 })[0], agegroup_code: '', agegroup_from: '', agegroup_to: '', agegroup_desc: '', agegroup_color: '#000', ab_ids: [parseInt(modalAgeGroup.ab_id)], edit: true, open_agegroup_color: false },
                        ...AgeGroupData.data
                      ]
                    }
                  })}
                  className='btn-sm btn-add'
                  variant='success'>
                  {t('Add Grade')}{' '}
                  <FontAwesomeIcon
                    icon={faPlus}
                    color={brand_colors[defaultTheme.theme_id].color8}
                    style={{
                      fontSize: 16,
                    }}
                  />
                </Button>
              </div>
            </div>
          </div>
          <div>
            {AgeGroupData.data.map((v, i) => {
              return <div key={v.agegroup_id} className='scroll-list-card' style={{ backgroundColor: brand_colors[defaultTheme.theme_id].color7 }}>
                <div className='d-flex flex-row justify-content-between align-items-center p-0 m-0' style={{ flex: 1 }}>
                  <div className='cd-div-actionbtn' style={{ width: 70 }}>
                    <div className='d-flex flex-row align-items-center all-btns' style={{ opacity: 1 }} >
                      {/* EDIT */}
                      {!v.edit && <FontAwesomeIcon icon={faEdit} color={brand_colors[defaultTheme.theme_id].color16} className='cursor-pointer ms-3'
                        onClick={() => this.setStateAgeGroup(v.agegroup_id, 'edit', true)} title={t('Edit')} />}
                      {/* DELETE */}
                      <FontAwesomeIcon icon={faTrashCan} color={brand_colors[defaultTheme.theme_id].color11} className='cursor-pointer ms-3' onClick={() => this.deleteAgeGroup(v.agegroup_id)} title={t('Delete')} />
                    </div>
                  </div>
                  <div style={{ flex: 1 }}>
                    {v.edit
                      ? <div className='d-flex flex-row justify-content-between'>
                        <Form.Group className='mx-1' style={{ flex: 2, }}>
                          <Form.Label>
                            <img src={imgDescription} />
                            {t('Code')}
                          </Form.Label>
                          <Form.Control type='text' defaultValue={v.agegroup_code} onBlur={(event) => this.setStateAgeGroup(v.agegroup_id, 'agegroup_code', event.target.value)} />
                        </Form.Group>
                        <Form.Group className='mx-1' style={{ flex: 2, }}>
                          <Form.Label>
                            <img src={imgDescription} />
                            {t('Desc')}
                          </Form.Label>
                          <Form.Control as="textarea" rows={2} defaultValue={v.agegroup_desc} onBlur={(event) => this.setStateAgeGroup(v.agegroup_id, 'agegroup_desc', event.target.value)} />
                        </Form.Group>
                        <Form.Group className='mx-1' style={{ flex: 1, }}>
                          <Form.Label className='no-style'>{t('Color')}</Form.Label>
                          <div className='selected-color' onClick={() => this.setStateAgeGroup(v.agegroup_id, 'open_agegroup_color', true)}>
                            <div style={{ backgroundColor: v.agegroup_color }}></div>
                          </div>
                          {v.open_agegroup_color &&
                            <div className='color-palette'>
                              {colorPalette.map((color, color_i) => (
                                <div
                                  key={color_i}
                                  onClick={() => {
                                    AgeGroupData.data = AgeGroupData.data.map((vv, ii) => {
                                      if (v.agegroup_id == vv.agegroup_id) {
                                        return { ...vv, agegroup_color: color.code, open_agegroup_color: false }
                                      }
                                      return vv
                                    })
                                    this.setState({ AgeGroupData })
                                  }}
                                  style={{ backgroundColor: color.code }}
                                ></div>
                              ))}
                            </div>
                          }
                        </Form.Group>
                      </div>
                      : <div style={{ color: v.agegroup_color }}>{v.agegroup_code}</div>
                    }
                  </div>
                </div>
              </div>

            })}
            <div>
              {AgeGroupData.refreshing && !AgeGroupData.finished &&
                <div className='d-flex mt-2 align-items-center justify-content-center'>
                  <Spinner animation='grow' variant='dark' />
                </div>
              }
              {/* {!AgeGroupData.refreshing && AgeGroupData.finished && AgeGroupData.data.length > 0 &&
                                    <div className='d-flex mt-2 align-items-center'>
                                        <div style={{ color: brand_colors[defaultTheme.theme_id].color18 }}>NO MORE DATA...</div>
                                    </div>
                                } */}
              {!AgeGroupData.refreshing && AgeGroupData.finished && AgeGroupData.data.length <= 0 &&
                <div className='d-flex mt-2 align-items-center justify-content-center'>
                  <div style={{ color: brand_colors[defaultTheme.theme_id].color18 }}>NO DATA FOUND...</div>
                </div>
              }
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => modalAgeGroup.toggle()}>
            Close
          </Button>
          <Button disabled={this.state.modalAgeGroup.saving} variant="primary" onClick={modalAgeGroup.save}>
            Save Changes {this.state.modalAgeGroup.saving ? <Spinner as='span' animation='grow' size='sm' role='status' aria-hidden='true' /> : ''}
          </Button>
        </Modal.Footer>
      </Modal>

    </div>);
  }
}


const mapStateToProps = (state) => ({
  authData: state.auth.authData,
  defaultLanguage: state.language.defaultLanguage,
  selectedClass: state.selectedClass.data,
  defaultTheme: state.theme.defaultTheme,
});

export default connect(mapStateToProps, null)(Curriculum);
