import React, { Component } from 'react';
import { Container, Card, Row, Col, Button, Spinner, Offcanvas, Badge, Form } from 'react-bootstrap';
import { t } from '../../helpers/translation_helper';
import NavBar from '../_partials/NavBar/_NavBar';
import Header from '../_partials/Header/_Header';
import { connect } from 'react-redux';
import axios from 'axios';
import { toast } from 'react-toastify';
import Paginate from '../_partials/Paginate/_Paginate';
import AddEditExam from '../_common/AddEditExam/_AddEditExam';
import ExamGradesModal from '../_common/ExamGradesModal/_ExamGradesModal';
import ExamCopyModal from '../_common/ExamCopyModal/_ExamCopyModal';
import { sendCloudMessage } from '../../helpers/cloud_message';
import PreviewExam from './PreviewExam';
import { setSubject } from "../../redux/slices/selectedClassSlice";
import { GET, POST } from '../../api';
import SweetAlert from 'react-bootstrap-sweetalert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { brand_colors } from '../../helpers/brand_colors_helper';
import { faArrowAltCircleLeft, faAward, faBook, faCalendar, faCheckCircle, faCheckDouble, faCircle, faCopy, faListCheck, faLock, faLockOpen, faQuestionCircle, faTachometer, faTh } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import Select from 'react-select';
import { setExamGrades } from '../../redux/slices/examGradesModalSlice';
import { setExamCopy } from '../../redux/slices/examCopyModalSlice';
import GeneralCard from '../../helpers/GeneralCard';
import { faEye, faThumbsDown, faThumbsUp, faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import Pagination from '../_partials/Pagination';
import { changeColorOpacity } from '../../helpers/general_helpers';

const apiURL = process.env.REACT_APP_API_URL;
const portalURL = process.env.REACT_APP_PORTAL_URL;

/**
 * Exams Component
 */
class Exams extends Component {
  constructor(props) {
    super(props);
    // States
    this.state = {
      filterActiveCenterlic: true,
      deleteModal: { show: false, id: 0, action: () => { } },
      // General states
      exams: [],
      isRefreshing: false,
      editingQuestionId: undefined,
      // --------------
      // Exam form data
      exams_id: 0,
      selectedClass: null,
      shuffleQs: 0,
      resubmitAllowed: 0,
      showCorrectAnswer: 0,
      retakenExam: 0,
      is_exampaper: 0,
      // ------------------
      // Question form data
      uuid: '',
      examsquest_id: 0,
      exams_notes: '',
      sub_obj: '',
      examsquest_order: 0,
      classmaterialsub_id: '',
      examsquest_audio: '',
      // ------------------
      // Paginate
      limit: 8,
      totalRecords: 0,
      page: 0,
      examData: null,
      studentExamData: null,
      studentExamDataAnswers: null,
      unlockAlert: false,
      examModal: {
        show: false,
        modalTitle: this.props.examstypegrp_type,
        closeModal: () => this.setState({ examModal: { ...this.state.examModal, show: false } }),
        toggleModal: (data = null) => this.setState({
          examModal: { ...this.state.examModal, show: !this.state.examModal.show, }, formSectionActive: {
            parent: '1',
            child: '1.1',
          }
        }, () => {
          if (this.state.examModal.show) {
            this.examData(data);

            let formSectionsExam = this.state.formSectionsExam
            formSectionsExam = formSectionsExam.map((v, i) => {
              if (v.uid == 2) {
                v.data = data.user_timeline_tagged.map((vv, ii) => {
                  return {
                    uid: '2_' + (ii + 1),
                    active: false,
                    completed: false,
                    name: vv.child_name,
                    image: portalURL + vv.center_id + '/children/' + vv.picture,
                    data: vv,
                  }
                })
              }
              return v
            })
            this.setState({
              formSectionsExam: formSectionsExam
            }, () => {
              this.getExam(data)
            })
          }
        }),
        showModal: () => this.setState({ examModal: { ...this.state.examModal, show: true } }),
      },
      formSectionsExam: [
        {
          uid: '1',
          active: true,
          completed: true,
          name: 'Details',
          desc: null,
          data: [
            {
              uid: '1.1',
              active: false,
              completed: true,
              name: 'Details',
              desc: null,
              image: require('../../assets/images/lesson/lesson-focus.png'),
            },
          ]
        },
        {
          uid: '2',
          active: true,
          completed: false,
          name: 'Students',
          desc: null,
          data: []
        }
      ],
    };

    this.AddEditExam = React.createRef();
    this.PreviewExam = React.createRef();
    this.examsgrade_comment = React.createRef()
  }


  /**
   * Component Did Mount
   */
  componentDidMount() {
    this.getExams({
      limit: this.state.limit,
      page: 0
    });
  }

  /**
   * Get Exams
   */
  getExams = async (params) => {
    this.setState({ isRefreshing: true })
    const { authData } = this.props;
    const { auth_key, role_id } = authData.loginData;
    const { employee_id } = authData.userData;
    const { center_id } = authData.centerData;

    const request_params = {
      employee_id,
      role_id,
      examstypegrp_type: this.props.examstypegrp_type,
      limit: params.limit,
      offset: parseInt(params.page) * parseInt(params.limit),
      acs_id: (this.props.selectedSubject) ? this.props.selectedSubject.acs_id : null,
      class_id: (this.props.selectedClass) ? this.props.selectedClass.class.class_id : null,
      filterActiveCenterlic: (this.state.filterActiveCenterlic) ? 1 : 0
    };

    try {
      const res = await GET('exams/fetch-exams-table', {
        params: request_params,
      });

      this.setState({
        exams: res.data.data,
        page: params.page,
        totalRecords: Math.ceil(parseInt(res.data.total_count_filtered) / parseInt(params.limit)),
        isRefreshing: false
      });
    } catch (err) {
      console.log('err', err)
      toast.error(t('Something went wrong while fetching exams!'));

      this.setState({ isRefreshing: false })
    }
  };


  handleExamStatus = async (current_exams_status, exams) => {

    if (![1, 12, 19, 15, 22].includes(parseInt(this.props.authData.loginData.role_id)) && exams.exams_grade_lock == 1) {
      toast.warning(t('You are not allowed for this action!'));
      return
    }

    this.setState({ isRefreshing: true })

    const { auth_key, user_id } = this.props.authData.loginData;
    const { center_id, center_timezone } = this.props.authData.centerData;

    const fData = new FormData();

    fData.append('center_id', center_id);
    fData.append('center_timezone', center_timezone);
    fData.append('user_id', user_id);
    fData.append('exams_id', exams.exams_id);
    fData.append('current_exams_status', current_exams_status);

    if (this.handleExamStatusReq) {
      this.handleExamStatusReq.abort()
    }
    this.handleExamStatusReq = new AbortController();

    const res = await POST('exams/publish-exam', fData, {
      signal: this.handleExamStatusReq.signal
    });
    if (res) {
      if (res.data.type) {
        if (current_exams_status == 0) {
          res.data.data.map((v, i) => {
            sendCloudMessage(v.user_id, v.title, v.body, v.data)
          })
        }
        let examsNew = this.state.exams.map((v, i) => {
          if (v.exams_id == exams.exams_id) {
            return { ...v, exams_status: res.data.exams_status }
          }
          return v
        })
        this.setState({ exams: examsNew, isRefreshing: false })
        toast[res.data.messageType](t(res.data.message));
      } else {
        toast.error(t(res.data.message));
        this.setState({ isRefreshing: false })
      }
    }

  }

  verifyAnswers = (studentExamData) => {
    const { authData } = this.props;
    const { center_id, center_timezone } = authData.centerData;
    const { examData } = this.state;
    const { formSectionsExam, formSectionActive } = this.state;
    const studentExamDataAnswers = [];

    let thisChild = null
    formSectionsExam.map((v, i) => {
      if (v.uid == formSectionActive.parent) {
        thisChild = v.data.find((vv, ii) => vv.uid == formSectionActive.child)
      }
    })




    const commonDataSet = {
      center_id: center_id,
      child_id: thisChild.data.child_id,
      parent_id: thisChild.data.parent_id,
      class_id: thisChild.data.class_id,
      exams_id: examData.exams_id,
    };


    if (studentExamData) {
      studentExamData.Examsquest.map((quest, quest_i) => {

        if (quest.examsquesttype.eqtype_id != '9') {

          if (studentExamData.ExamAnswers && studentExamData.ExamAnswers.length > 0) {

            const foundAnswer = studentExamData.ExamAnswers.find(item => item.examsquest_id == quest.examsquest_id);



            if (foundAnswer.is_correct == 'correct') {
              studentExamDataAnswers.push({
                ...commonDataSet,
                exam_ans_id: foundAnswer.exam_ans_id,
                examsquest_id: quest.examsquest_id,
                answer: null,
                attachment: null,
                examsquest_duration: 0,
                is_time_over: "0",
                answer_grade: foundAnswer.answer_grade,
                is_correct: "correct",
                checked_by_employee: null,
                checked_by_datetime: null,
                confirmed_answer_grade: foundAnswer.answer_grade,
                examAns: foundAnswer.examAns,
              });
            } else if (foundAnswer.is_correct == 'wrong') {
              studentExamDataAnswers.push({
                ...commonDataSet,
                exam_ans_id: foundAnswer.exam_ans_id,
                examsquest_id: quest.examsquest_id,
                answer: null,
                attachment: null,
                examsquest_duration: 0,
                is_time_over: "0",
                answer_grade: foundAnswer.answer_grade,
                is_correct: "wrong",
                checked_by_employee: null,
                checked_by_datetime: null,
                confirmed_answer_grade: foundAnswer.confirmed_answer_grade,
                examAns: foundAnswer.examAns,
              });
            } else if (foundAnswer.is_correct == 'partial correct') {
              studentExamDataAnswers.push({
                ...commonDataSet,
                exam_ans_id: foundAnswer.exam_ans_id,
                examsquest_id: quest.examsquest_id,
                answer: null,
                attachment: null,
                examsquest_duration: 0,
                is_time_over: "0",
                answer_grade: foundAnswer.answer_grade,
                is_correct: "partial correct",
                checked_by_employee: null,
                checked_by_datetime: null,
                confirmed_answer_grade: foundAnswer.confirmed_answer_grade,
                examAns: foundAnswer.examAns,
              });
            } else if (foundAnswer.is_correct == '') {
              studentExamDataAnswers.push({
                ...commonDataSet,
                exam_ans_id: foundAnswer.exam_ans_id,
                examsquest_id: quest.examsquest_id,
                answer: null,
                attachment: null,
                examsquest_duration: 0,
                is_time_over: "0",
                answer_grade: foundAnswer.answer_grade,
                is_correct: "",
                checked_by_employee: null,
                checked_by_datetime: null,
                confirmed_answer_grade: foundAnswer.confirmed_answer_grade,
                examAns: foundAnswer.examAns,
              });
            }

          } else {

            studentExamDataAnswers.push({
              ...commonDataSet,
              exam_ans_id: 0,
              examsquest_id: quest.examsquest_id,
              answer: null,
              attachment: null,
              examsquest_duration: 0,
              is_time_over: "0",
              answer_grade: "0",
              is_correct: "",
              checked_by_employee: null,
              checked_by_datetime: null,
              confirmed_answer_grade: "0",
              examAns: [],
            });

          }
        }

      });
    }



    return studentExamDataAnswers;


  }

  renderExam = () => {
    const { examModal, formSectionsExam, formSectionActive, working, examData } = this.state;

    if (formSectionActive.parent == '1') {

      const exam = examData;


      return (<>
        <Row>
          <Col>
            <h5>{exam.exams_name}</h5>
          </Col>
          <Col className='text-end'>
            <p className='mb-1'>
              <FontAwesomeIcon
                icon={faCalendar}
                className='me-1'
              />{' '}
              {moment(
                exam.exams_date,
                'YYYY-MM-DD'
              ).format('dddd, MMMM Do YYYY')}
            </p>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col>
            <p className='mb-1'>
              <FontAwesomeIcon
                icon={faTh}
                className='me-1'
              />{' '}
              {exam.examstype_name} (
              {exam.examstypegrp_name})
            </p>
          </Col>
          <Col className='text-center'>
            <p className='mb-1'>
              <FontAwesomeIcon
                icon={faBook}
                className='me-1'
                color={brand_colors[this.props.defaultTheme.theme_id].color4}
              />{' '}
              {exam.acs_name}
            </p>
          </Col>
          <Col className='text-center'>
            <p className='mb-1'>
              <FontAwesomeIcon
                icon={faTachometer}
                className='me-1'
              />{' '}
              <span
                style={{
                  color: exam
                    .eqdifficulty_color,
                }}>
                {exam.eqdifficulty_name}
              </span>
            </p>
          </Col>
          <Col className='text-center'>
            <p className='mb-1'>
              <FontAwesomeIcon
                icon={faQuestionCircle}
                className='me-1'
              />{' '}
              {exam.total_questions}
            </p>
          </Col>
          <Col className='text-end'>
            <p className='mb-1'>
              <FontAwesomeIcon
                icon={faAward}
                className='me-1'
              />{' '}
              {exam.exams_grade} ({t('Total Grade')}
              )
            </p>
          </Col>
        </Row></>)
    }
    else if (formSectionActive.parent == '2') {

      let thisChild = null
      formSectionsExam.map((v, i) => {
        if (v.uid == formSectionActive.parent) {
          thisChild = v.data.find((vv, ii) => vv.uid == formSectionActive.child)
        }
      })

      let examsgrade_comment = '';

      const { studentExamData, studentExamDataAnswers, working } = this.state;

      if (studentExamData) {
        const { Examsgrade } = studentExamData;
        examsgrade_comment = Examsgrade.examsgrade_comment;
      }

      console.log('studentExamData', studentExamData);

      // 

      const { current_datetime } = examData;
      const { exams_date, close_time } = examData;
      const start = moment(exams_date + ' ' + close_time + ':00');
      const end = moment(current_datetime);
      const diff = start.diff(end, 'seconds');

      const is_correct_opts = [
        { value: '', label: '--Select--' },
        { value: 'correct', label: 'Correct' },
        { value: 'wrong', label: 'Wrong' },
        { value: 'partial correct', label: 'Partial Correct' },
      ];

      return (<div>
        <Row className='mb-3'>
          <Col><Form.Control ref={this.examsgrade_comment} defaultValue={examsgrade_comment} type="text" placeholder={t('Teacher comment...')} /></Col>
          <Col md={2} style={{ textAlign: 'right' }}>
            <div className="d-grid"><Button variant="warning" onClick={() => this.saveExamCorrections()} disabled={diff >= 0}>{working ? <Spinner as='span' animation='grow' size='sm' /> : t('Save Correction')}</Button></div></Col>
        </Row>
        {studentExamData && studentExamData.Examsquest.map((quest, quest_i) => {

          if (quest.examsquesttype.eqtype_id != '9') {

            const foundVerifiedAnswer = studentExamDataAnswers.find(item => item.examsquest_id == quest.examsquest_id);
            const foundVerifiedAnswerIndex = studentExamDataAnswers.findIndex(item => item.examsquest_id == quest.examsquest_id);
            const is_correct = foundVerifiedAnswer.is_correct;

            return (
              <Card
                key={quest_i}
                className='mb-3 q-card'>
                <Card.Body>
                  <div className='q-card-inner'>
                    <div className='d-flex flex-row'>
                      <Badge bg="dark">
                        {quest.examsquesttype.eqtype_name}
                      </Badge>
                      <Badge bg="primary" className='ms-1'>
                        {quest.eqtype_id.label}
                      </Badge>
                      <Badge bg="info" className='ms-1'>
                        {t('Duration: ') + quest.examsquest_duration + ' ' + t('Minutes')}
                      </Badge>
                      <span className='ms-1' style={{ fontSize: 12, fontWeight: 'bold', backgroundColor: changeColorOpacity(quest.examsquestdifficulty?.eqdifficulty_color, 0.2), color: quest.examsquestdifficulty?.eqdifficulty_color, borderRadius: 10, padding: '0.1rem 0.5rem' }}>{t('Difficulty: ') + quest.examsquestdifficulty?.eqdifficulty_name}</span>
                    </div>
                    <h5>
                      <strong>
                        <small>
                          {quest.examsquest_desc1.replace(
                            /(<([^>]+)>)/gi,
                            ' '
                          )}
                        </small>
                      </strong>
                    </h5>
                    <div className='quest-answers'>
                      {quest.examsquestoptions.map((item2, index) => {

                        let badgeClass = ''

                        if (item2.is_checked == 1 && foundVerifiedAnswer && is_correct == "") {
                          badgeClass = 'badge badge-light-primary'
                        }
                        if (item2.is_checked == 1 && foundVerifiedAnswer && is_correct == "correct") {
                          badgeClass = 'badge badge-light-success'
                        }
                        if (item2.is_checked == 1 && foundVerifiedAnswer && is_correct == "wrong") {
                          badgeClass = 'badge badge-light-danger'
                        }

                        return (
                          <div key={index}>
                            <div><span className={badgeClass} style={{ fontSize: 16 }}>{index + 1} : {quest
                              .eqtype_id.value == 8 ? item2.option_value : item2.option_text}</span></div>
                          </div>
                        )
                      }
                      )}
                    </div>
                    <hr />
                    <Row>
                      <Col>
                        <span className="badge badge-light-danger me-2">{'Wrong picked answer'}</span>
                        <span className="badge badge-light-success me-2">{'Correct picked answer'}</span>
                        <span className="badge badge-light-primary">{'Correct answer'}</span>
                      </Col>
                    </Row>
                    <hr />
                    <Row>
                      <Col md={2}>
                        <Badge bg="warning" style={{ fontSize: 13, display: 'block', height: 37, lineHeight: '24px' }}>
                          {t('Total Marks: ') + quest.examsquest_grade}
                        </Badge>
                      </Col>
                      <Col md={2}>
                        <Select onChange={this.updateVerifiedAnswer(foundVerifiedAnswerIndex, 'correction')} value={{ value: foundVerifiedAnswer.is_correct, label: foundVerifiedAnswer.is_correct ? foundVerifiedAnswer.is_correct : '--Select--' }} options={is_correct_opts}>
                        </Select>
                      </Col>
                      <Col md={1}>
                        <Form.Control className='examgrades text-center' type="number" defaultValue={foundVerifiedAnswer.confirmed_answer_grade} onChange={this.updateVerifiedAnswer(foundVerifiedAnswerIndex, 'grades', quest.examsquest_grade)} />
                      </Col>
                    </Row>
                  </div>
                </Card.Body>
              </Card>
            )
          }
        }
        )}
      </div>);
    }
  }

  getExam = (data) => {
    const { authData, selectedClass } = this.props;
    const { center_id } = authData.centerData;
    const { auth_key } = authData.loginData;

    try {
      GET(
        'exams/get-exam-single',
        {
          params: {
            exams_id: data.exams_id,
            class_id: data.class_id
          },
        }
      ).then((res) => {
        // 
        if (res.status == 200) {
          let formSectionsExam = this.state.formSectionsExam
          formSectionsExam = formSectionsExam.map((vv, ii) => {
            if (vv.uid == '2') {
              vv.data = vv.data.map((vvv, iii) => {
                let foundData = res.data.find((v, i) => v.child_id == vvv.data.child_id);
                vvv['completed'] = foundData.exam_attempt_id ? true : false;
                vvv['is_locked'] = foundData.is_locked == 1 ? true : false;
                vvv['exam_attempt_id'] = foundData.exam_attempt_id;
                vvv['filledData'] = true;
                return vvv
              })
            }
            return vv
          })
          this.setState({ formSectionsExam: formSectionsExam, utWorking: false })
        }
      })
    } catch (err) {
      console.log('err', err)
      toast.error(
        t('Something went wrong while fetching exam details!')
      );

    }
  }

  showExam = (parent, child) => {

    const { formSectionsExam } = this.state;

    const indexOfChild = formSectionsExam[1].data.findIndex((item) => item.uid == child);


    let formSectionsExam_LOCK_UPDATED = formSectionsExam;

    if (formSectionsExam[1].data[indexOfChild].exam_attempt_id) {
      formSectionsExam[1].data[indexOfChild].is_locked = true;
      formSectionsExam_LOCK_UPDATED = [{ ...formSectionsExam[0] }, { ...formSectionsExam[1] }];
    }

    this.setState(
      {
        formSectionActive: { parent: parent, child: child },
        studentExamData: null,
        studentExamDataAnswers: null,
        formSectionsExam: formSectionsExam_LOCK_UPDATED
      },
      () => {
        if (parent == 2) {

          const { examData } = this.state;

          let thisChild = this.state.formSectionsExam[1].data.find((v, i) => v.uid == child)
          // 
          // 

          const { authData } = this.props;
          const { center_id, center_timezone } = authData.centerData;
          const { auth_key } = authData.loginData;
          let fData = new FormData();
          fData.append('center_id', center_id);
          fData.append('center_timezone', center_timezone);
          fData.append('exams_id', examData.exams_id);
          fData.append('child_id', thisChild.data.child_id);
          fData.append('parent_id', thisChild.data.parent_id);
          fData.append('class_id', thisChild.data.class_id);

          try {
            axios.post(
              apiURL + 'examcorrection/get-exam-details',
              fData,
              {
                params: {
                  auth_key,
                  appname: process.env.REACT_APP_NAME,
                },
              }
            ).then((res) => {


              if (res.status) {




                this.setState({
                  studentExamData: res.data.data,
                  studentExamDataAnswers: this.verifyAnswers(res.data.data)
                });
              }

            });
          } catch (err) {
            console.log('err', err)
            toast.error(
              t('Something went wrong while fetching student exam!')
            );

          }


        }
        setTimeout(() => {
          const element = document.getElementById('form_section_' + child);
          if (element) {
            // 👇 Will scroll smoothly to the top of the next section
            element.scrollIntoView({ behavior: 'smooth' });
          }
        }, 300);
      }
    );
  };

  examData = async (data) => {
    this.setState({
      examData: data
    });
  };

  updateVerifiedAnswer = (index, type, grade) => (obj) => {
    const { studentExamDataAnswers } = this.state;



    if (type == 'correction') {
      studentExamDataAnswers[index].is_correct = obj.value;
      this.setState({ studentExamDataAnswers });
    }

    if (type == 'grades') {
      studentExamDataAnswers[index].confirmed_answer_grade = obj.target.value;
      this.setState({ studentExamDataAnswers });
    }

  }

  unlockExamConfirm = (exam_attempt_id, uid) => {
    this.setState({
      unlockAlert: true,
      exam_unlock_data: { exam_attempt_id, uid }
    });
  }

  unlockExam = () => {

    const { exam_unlock_data, formSectionsExam } = this.state;

    const { authData } = this.props;
    const { center_id } = authData.centerData;
    const { auth_key } = authData.loginData;
    let fData = new FormData();
    fData.append('is_locked', 0)
    fData.append('exam_attempt_id', exam_unlock_data.exam_attempt_id)
    try {
      axios.post(
        apiURL + 'examcorrection/exam-lock',
        fData,
        {
          params: {
            auth_key,
            appname: process.env.REACT_APP_NAME,
          },
        }
      ).then((res) => {
        // 

        if (res.status == 200 && res.data.type) {

          const indexOfChild = formSectionsExam[1].data.findIndex((item) => item.uid == exam_unlock_data.uid);
          formSectionsExam[1].data[indexOfChild].is_locked = false;
          let formSectionsExam_LOCK_UPDATED = [{ ...formSectionsExam[0] }, { ...formSectionsExam[1] }];
          this.setState({ formSectionsExam: formSectionsExam_LOCK_UPDATED });

        }
      });
    } catch (err) {
      console.log('err', err)
      toast.error(
        t('Something went wrong while unlocking exam!')
      );

    }


    this.setState({
      unlockAlert: false,
      exam_attempt_id: 0
    });
  }

  saveExamCorrections = () => {

    this.setState({ working: true });

    const { authData } = this.props;
    const { auth_key } = authData.loginData;
    const { center_id, center_timezone } = authData.centerData;
    const { examData, studentExamDataAnswers, formSectionsExam, formSectionActive } = this.state;


    let thisChild = null
    formSectionsExam.map((v, i) => {
      if (v.uid == formSectionActive.parent) {
        thisChild = v.data.find((vv, ii) => vv.uid == formSectionActive.child)
      }
    });

    // 
    // 
    // 
    // 
    // 
    // 
    // 
    // 
    // console.log('newExamAnswers', JSON.stringify(studentExamDataAnswers));

    let fData = new FormData();
    fData.append('center_id', center_id);
    fData.append('center_timezone', center_timezone);
    fData.append('exams_id', examData.exams_id);
    fData.append('child_id', thisChild.data.child_id);
    fData.append('parent_id', thisChild.data.parent_id);
    fData.append('class_id', thisChild.data.class_id);
    fData.append('employee_id', authData.userData.employee_id);
    fData.append('examsgrade_comment', this.examsgrade_comment.current.value);
    fData.append('newExamAnswers', JSON.stringify(studentExamDataAnswers));

    try {
      axios.post(
        apiURL + 'examcorrection/save-exam-attempt-correction',
        fData,
        {
          params: {
            auth_key,
            appname: process.env.REACT_APP_NAME,
          },
        }
      ).then((res) => {

        this.setState({ working: false });
        toast.success(res.data.message);
      });
    } catch (err) {
      console.log('err', err)
      toast.error(
        t('Something went wrong while saving exam corrections!')
      );
      this.setState({ working: false });

    }

  }


  delete = async (data, index) => {
    this.setState({
      deleteModal: {
        ...this.state.deleteModal, show: true, id: data, action: async () => {
          const res = await GET('exams/delete', {
            params: {
              exams_id: data.exams_id
            }
          });
          if (res) {
            let resData = res.data
            if (resData.type) {
              this.setState({
                exams: this.state.exams.filter(item => item.exams_id !== data.exams_id),
                deleteModal: {
                  ...this.state.deleteModal, show: false, id: null
                }
              });
            } else {
              this.setState({
                deleteModal: {
                  ...this.state.deleteModal, show: false, id: null
                }
              });
            }
            toast[resData.messageType](t(resData.message));
          }
        }
      }
    })
  }

  /**
   * Render Component
   * @returns {HTMLElement}
   */
  render() {
    console.log('EXAM STATE', this.state);
    const {
      exams,
      limit,
      totalRecords,
      isRefreshing
    } = this.state;

    return (
      <Container fluid>
        <div id='iedu-layout'>
          <NavBar />
          <div id='page-content'>
            <Header lite={true} heading={this.props.examstypegrp_type} backBtn={true} showClassSelector={true} showSubjectSelector={true} classSelectorFunc={[]} subjectSelectorFunc={[() => this.getExams({ limit: this.state.limit, page: this.state.page })]} />
            <Row
              style={{ justifyContent: 'space-between' }}>
              <Col md={6}>
                <Button
                  variant='success'
                  onClick={() => this.AddEditExam.current.handleShowCreateUpdateExamCanvas()}>
                  {t('Create New')}
                </Button>
              </Col>

              <Col className='d-flex flex-row justify-content-end'>
                <div>
                  <Form.Group >
                    <Form.Check
                      type="switch"
                      label={t('Active Year')}
                      onChange={() => this.setState({ filterActiveCenterlic: !this.state.filterActiveCenterlic }, () => this.getExams({
                        limit: this.state.limit,
                        page: 0
                      }))}
                      checked={this.state.filterActiveCenterlic}
                    />
                  </Form.Group>
                </div>
              </Col>

            </Row>
            {isRefreshing &&
              <div className='d-flex justify-content-center align-items-center'>
                <Spinner as='span' animation='grow' size='lg' role='status' aria-hidden='true' className='mb-1' />
              </div>
            }
            <Row>
              {exams.length > 0 ? (
                <>
                  {exams.map((v, i) => {
                    let actions = []
                    // !NEED TO DEFINE DELETE FUNCTION
                    if (this.state.filterActiveCenterlic) {
                      if (v.is_exampaper == 1) {
                        if (v.exams_status == 0) {
                          actions.push({ type: 'edit', name: 'Edit', image: require('../../assets/images/edit.png'), iconName: null, iconColor: brand_colors[this.props.defaultTheme.theme_id].color16, onClick: () => this.AddEditExam.current.handleShowCreateUpdateExamCanvas(true, v) })
                          actions.push({ type: 'delete', name: 'Delete', image: null, iconName: faTrashAlt, iconColor: brand_colors[this.props.defaultTheme.theme_id].color11, onClick: () => this.delete(v, i) })
                        }
                        actions.push({ type: 'publish', name: (v.exams_status == 0) ? 'Publish' : 'Un-Publish', image: null, iconName: (v.exams_status == 0) ? faThumbsUp : faThumbsDown, iconColor: (v.exams_status == 0) ? brand_colors[this.props.defaultTheme.theme_id].color4 : brand_colors[this.props.defaultTheme.theme_id].color11, onClick: () => this.handleExamStatus(v.exams_status, v) })
                      } else {
                        actions.push({ type: 'publish', name: (v.exams_status == 0) ? 'Publish' : 'Un-Publish', image: null, iconName: (v.exams_status == 0) ? faThumbsUp : faThumbsDown, iconColor: (v.exams_status == 0) ? brand_colors[this.props.defaultTheme.theme_id].color4 : brand_colors[this.props.defaultTheme.theme_id].color11, onClick: () => this.handleExamStatus(v.exams_status, v) })
                        actions.push({ type: 'preview', name: 'Preview', image: null, iconName: faEye, iconColor: brand_colors[this.props.defaultTheme.theme_id].color16, onClick: () => this.PreviewExam.current.handleShowExamPreview(v) })
                        if (v.exams_status == 0) {
                          actions.push({ type: 'edit', name: 'Edit', image: require('../../assets/images/edit.png'), iconName: null, iconColor: brand_colors[this.props.defaultTheme.theme_id].color16, onClick: () => this.AddEditExam.current.handleShowCreateUpdateExamCanvas(true, v) })
                          actions.push({ type: 'delete', name: 'Delete', image: null, iconName: faTrashAlt, iconColor: brand_colors[this.props.defaultTheme.theme_id].color11, onClick: () => this.delete(v, i) })
                        } else {
                          actions.push({ type: 'correction', name: 'Correction', image: null, iconName: faCheckDouble, iconColor: brand_colors[this.props.defaultTheme.theme_id].color16, onClick: () => this.state.examModal.toggleModal(v) })
                        }
                      }
                      if (v.exams_status == 1) {
                        actions.push({ type: 'grading', name: 'Grading', image: null, iconName: faListCheck, iconColor: brand_colors[this.props.defaultTheme.theme_id].color9, onClick: () => this.props.setExamGrades({ showModal: true, exam: v }) })
                      }
                    }

                    let exams_date = <Badge pill bg="warning">{moment(v.exams_date).format('DD-MM-YYYY')}</Badge>
                    if (v.is_exampaper == 0) {
                      exams_date = <Badge pill bg="warning">{moment(v.exams_date).format('DD-MM-YYYY')} {v.open_time + '-' + v.close_time}</Badge>
                    }


                    let cardImage = require('../../assets/images/noimage.png')
                    if (v.exams_attachment) {
                      cardImage = portalURL + v.center_id + '/exams/' + v.exams_attachment
                    } else if (v.acs_image) {
                      cardImage = portalURL + v.center_id + '/academic_classmain_subject/' + v.acs_image
                    }

                    actions.push({
                      type: 'copy',
                      name: 'Copy',
                      image: null,
                      iconName: faCopy,
                      iconColor: brand_colors[this.props.defaultTheme.theme_id].color10,
                      onClick: () => this.props.setExamCopy({ showModal: true, exam: v })
                    });

                    let data = {
                      allData: v,
                      name: v.exams_name,
                      code: (v.is_exampaper == 1) ? '| ' + t('Recitation') : '| ' + t('Online'),
                      text1: <>
                        <Badge pill bg="info" className='me-1 mb-1' style={{ whiteSpace: 'normal' }}>{v.term_name}</Badge>
                        <Badge pill bg="info" className='me-1' style={{ whiteSpace: 'normal' }}>{v.examstype_name}</Badge>
                        <br />
                      </>,
                      text2: exams_date,
                      status: (v.exams_status == 1) ? { bg: 'success', name: 'Published' } : { bg: 'danger', name: 'Not Published' },
                      description: v.exams_notes,
                      // linkTo: '/subject/lessons/' + v.acs_name,
                      // linkToStates: { as_id: v.as_id, acs_id: v.acs_id, ab_id: v.ab_id, },
                      cardImage: cardImage,
                      actions: actions
                    }
                    return <GeneralCard data={data} key={i} />
                  }
                  )}
                </>
              ) : (
                <Col
                  md={12}
                  className='pt-5 pb-4 pb-0 d-flex align-items-center flex-column'>
                  {t('No ' + this.props.examstypegrp_type + ' Found')}
                </Col>
              )}
              <Pagination
                pageCount={totalRecords || 1}
                forcePage={this.state.page}
                callbackParams={{
                  limit: this.state.limit,
                }}
                callback={this.getExams}
              />
            </Row>
          </div>
        </div>
        <AddEditExam ref={this.AddEditExam} authData={this.props.authData} examstypegrp_type={this.props.examstypegrp_type}
          selectedClass={this.props.selectedClass}
          selectedSubject={this.props.selectedSubject}
          callBack={() => this.getExams({ limit: this.state.limit, page: this.state.page })} defaultTheme={this.props.defaultTheme} />
        <PreviewExam ref={this.PreviewExam} authData={this.props.authData} selectedClass={this.props.selectedClass} defaultTheme={this.props.defaultTheme} examstypegrp_type={this.props.examstypegrp_type} />
        {this.state.examModal.show &&
          <Offcanvas show={this.state.examModal.show} onHide={this.state.examModal.closeModal} style={{ height: '100%' }} placement='bottom'>
            <Offcanvas.Header id='iedu-header' style={{ marginTop: '1rem', marginRight: '1rem', marginLeft: '1rem', marginBottom: 0 }}>
              <div className='d-flex flex-row'>
                <span className='header-back-btn' onClick={this.state.examModal.closeModal} style={{ marginRight: 10 }}>
                  <FontAwesomeIcon icon={faArrowAltCircleLeft} color={brand_colors[this.props.defaultTheme.theme_id].color9} />
                </span>
                <Offcanvas.Title>{t(this.state.examModal.modalTitle)}</Offcanvas.Title>
              </div>
              <div>

              </div>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <div
                className={'d-flex flex-row grey-section'}
                style={{
                  overflow: 'initial',
                  backgroundImage: 'url(https://academic.educore.io/static/media/pegboard-bg.9b84e4936acef29bbcd9.png)',
                  backgroundRepeat: 'repeat',
                  backgroundPosition: '10px 12px',
                  overflow: 'hidden',
                }}
              >
                <div style={{ backgroundColor: brand_colors[this.props.defaultTheme.theme_id].color8, marginLeft: 10, marginRight: 10, borderRadius: '20px', width: '250px', height: '100%', top: '5px', position: 'sticky' }}>
                  <div style={{ paddingRight: '1.5rem', paddingBottom: '1.5rem', paddingTop: '1rem' }}>
                    {this.state.formSectionsExam.map((v, i) => {
                      return (
                        <div key={i}>
                          <div className='d-flex flex-row align-items-center' style={{ cursor: 'pointer', borderRadius: '20px', paddingLeft: '5px', paddingRight: '5px', backgroundColor: brand_colors[this.props.defaultTheme.theme_id].color8, zIndex: 1, position: 'relative' }}>
                            <span style={{ fontSize: 18, fontWeight: '600', color: brand_colors[this.props.defaultTheme.theme_id].color9, opacity: 0.7 }}>{v.name}</span>
                          </div>

                          <div style={{ paddingLeft: '1rem', borderLeft: 'solid 1px color18', marginLeft: '1.5rem' }}>
                            {v.data.map((vv, ii) => {
                              return (
                                <Row key={ii} className='d-flex flex-row align-items-center' style={{ marginBottom: 5, marginTop: 2 }}>
                                  <Col>
                                    <div onClick={() => vv.is_locked ? this.unlockExamConfirm(vv.exam_attempt_id, vv.uid) : this.showExam(v.uid, vv.uid)} style={{ cursor: 'pointer' }}>
                                      <FontAwesomeIcon icon={vv.completed ? faCheckCircle : faCircle} color={vv.completed ? brand_colors[this.props.defaultTheme.theme_id].color4 : brand_colors[this.props.defaultTheme.theme_id].color18} style={{ fontSize: 12, marginRight: 5 }} />
                                      <span style={{ fontSize: 14, color: vv.completed ? brand_colors[this.props.defaultTheme.theme_id].color4 : brand_colors[this.props.defaultTheme.theme_id].color18 }}>{vv.name}</span>
                                    </div>
                                  </Col>
                                  {v.uid != 1 &&
                                    <Col md={1} className='text-center p-0'><FontAwesomeIcon icon={vv.is_locked ? faLock : faLockOpen} color={vv.is_locked ? brand_colors[this.props.defaultTheme.theme_id].color11 : brand_colors[this.props.defaultTheme.theme_id].color4} style={{ fontSize: 12, marginRight: 5, cursor: vv.is_locked ? 'pointer' : 'default' }} onClick={() => vv.is_locked ? this.unlockExamConfirm(vv.exam_attempt_id, vv.uid) : console.log('do nothing')} /></Col>
                                  }
                                </Row>
                              )
                            })}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <Col className='custom-styled-form hide-scrollbar' style={{ backgroundColor: brand_colors[this.props.defaultTheme.theme_id].color8, marginLeft: 10, marginRight: 10, borderRadius: '20px', padding: '1rem', overflow: 'auto', height: '75vh' }}>
                  {this.state.examData &&
                    this.renderExam()
                  }
                </Col>
              </div>
            </Offcanvas.Body>
          </Offcanvas>
        }
        <ExamGradesModal />
        <ExamCopyModal callBack={() => this.getExams({ limit: this.state.limit, page: 0 })} />
        <SweetAlert
          show={this.state.unlockAlert}
          warning
          showCancel
          confirmBtnText={t('Yes, unlock it!')}
          confirmBtnBsStyle='danger'
          title={t('Exam is locked!')}
          onConfirm={() => this.unlockExam()}
          onCancel={() =>
            this.setState({ unlockAlert: false })
          }
          focusCancelBtn>
          {t('You want to unlock this exam!')}
        </SweetAlert>
        <SweetAlert show={this.state.deleteModal.show} warning showCancel confirmBtnText={t('Yes, delete it!')} confirmBtnBsStyle='danger' title={t('Are you sure?')} onConfirm={this.state.deleteModal.action} onCancel={() => this.setState({ deleteModal: { ...this.state.deleteModal, show: false, id: 0, action: () => { } } })} focusCancelBtn>
          {t('Confirm Delete!')}
        </SweetAlert>
      </Container >
    );
  }
}

const mapStateToProps = (state) => ({
  authData: state.auth.authData,
  selectedClass: state.selectedClass.data,
  selectedSubject: state.selectedClass.subject,
  defaultLanguage: state.language.defaultLanguage,
  defaultTheme: state.theme.defaultTheme,
});
const mapDispatchToProps = () => ({
  setSubject,
  setExamGrades,
  setExamCopy
});
export default connect(mapStateToProps, mapDispatchToProps())(Exams);
