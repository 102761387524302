import React, { Component, Fragment } from 'react';
import { Container, Card, Table, Row, Col, Spinner, Image, Badge, Button, Form, Offcanvas, Accordion, Modal } from 'react-bootstrap';
import { t } from '../../helpers/translation_helper';
import NavBar from '../_partials/NavBar/_NavBar';
import Header from '../_partials/Header/_Header';
import { connect } from 'react-redux';
import axios from 'axios';
import { toast } from 'react-toastify';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight, faCalendarAlt, faCheck, faClock, faEdit, faExternalLink, faFileAlt, faGear, faPaperclip, faSave, faSearch, faTimes, faTrashCan, } from '@fortawesome/free-solid-svg-icons';
import { Scrollbar } from 'react-scrollbars-custom';
import imgDescription from '../../assets/images/description.png';
import { brand_colors } from '../../helpers/brand_colors_helper';
import SweetAlert from 'react-bootstrap-sweetalert';
import Select from 'react-select';
import { handleFormErrors } from '../../helpers/form_helpers';
import { GET } from '../../api';
import { changeColorOpacity } from '../../helpers/general_helpers';


// API URL
const apiURL = process.env.REACT_APP_API_URL;

// Portal URL
const portalURL = process.env.REACT_APP_PORTAL_URL;

class ReportCurriculumResult extends Component {
    constructor(props) {
        super(props);
        this.state = {
            centerlicData: { data: [], selected: null },
            termsData: { data: [], selected: [] },
            reRenderTime: moment(),
            reportData: {
                processing: false,
                data: {},
                finished: false
            },
        }
    }

    componentDidMount() {
        this.getCenterlic()
    }

    getCenterlic = () => {
        try {
            if (this.getCenterlicReq) {
                this.getCenterlicReq.abort();
            }
            this.getCenterlicReq = new AbortController();
            GET('report-exams/get-centerlic', {
                signal: this.getCenterlicReq.signal,
            }).then((res) => {

                if (res) {
                    let selected = res.data.find((v) => v.reg_status == 1)
                    this.setState({ centerlicData: { ...this.state.centerlicData, data: res.data, selected: { value: selected.centerlic_id, label: selected.reg_year, reg_status: selected.reg_status } } }, () => this.getTerms())
                }
            })
        } catch (err) {
            console.log('err', err)
            toast.error(t('Something went wrong while deleting!'));

        }
    }
    getTerms = () => {
        try {
            if (this.getTermsReq) {
                this.getTermsReq.abort();
            }
            this.getTermsReq = new AbortController();
            GET('report-exams/get-terms', {
                signal: this.getTermsReq.signal,
            }).then((res) => {

                if (res) {
                    let selected = []
                    res.data.map((v, i) => {
                        if (v.centerlic_id == this.state.centerlicData.selected.value) {
                            selected.push({ value: v.term_id, label: v.term_name, term_active: v.term_active })
                        }
                    })
                    this.setState({ termsData: { ...this.state.termsData, data: res.data, selected: selected } })
                }
            })
        } catch (err) {
            console.log('err', err)
            toast.error(t('Something went wrong while deleting!'));

        }
    }

    fetchReport = () => {
        let reportData = this.state.reportData
        let centerlicData = this.state.centerlicData
        let termsData = this.state.termsData
        if (!this.props.selectedClass) {
            toast.error(t('No Class Selected'));
            return
        }
        if (!this.props.selectedSubject) {
            toast.error(t('No Subject Selected'));
            return
        }
        if (!centerlicData.selected) {
            toast.error(t('Please select a Year'));
            return
        }
        if (termsData.selected.length <= 0) {
            toast.error(t('Please select a Term'));
            return
        }




        this.setState({ reportData: { ...reportData, processing: true, data: {} } })

        try {
            if (this.fetchReportReq) {
                this.fetchReportReq.abort();
            }
            this.fetchReportReq = new AbortController();
            GET('exams/report-curriculum-result', {
                params: {
                    class_id: this.props.selectedClass.class.class_id,
                    agegroup_id: this.props.selectedClass.class.agegroup_id,
                    acs_id: this.props.selectedSubject.acs_id,
                    centerlic_id: centerlicData.selected.value,
                    term_ids: JSON.stringify(termsData.selected.map((v, i) => v.value)),
                },
                signal: this.fetchReportReq.signal,
            }).then((res) => {

                if (res) {
                    this.setState({ reportData: { ...reportData, processing: false, data: res.data, finished: true }, reRenderTime: moment() })
                } else {
                    toast.error(t('Something went wrong while deleting!'));
                    this.setState({ reportData: { ...reportData, processing: false, data: {} } })
                }
            })
        } catch (err) {
            console.log('err', err)
            toast.error(t('Something went wrong while deleting!'));

            this.setState({ reportData: { ...reportData, processing: false, data: {} } })
        }
    }

    render() {

        const { reportData, reRenderTime, centerlicData, termsData } = this.state
        let filteredTerms = []
        if (centerlicData.selected) {
            filteredTerms = termsData.data.filter((v, i) => v.centerlic_id == centerlicData.selected.value)
        }
        return (
            <Container fluid>
                <div id='iedu-layout'>
                    <NavBar />
                    <div id='page-content'>
                        <Header lite={true} heading={t('Objective Result')} backBtn={true} showClassSelector={true} showSubjectSelector={true} classSelectorFunc={[this.fetchReport]} subjectSelectorFunc={[this.fetchReport]} />
                        <div className='grey-section'>

                            <Row className='justify-content-between'>
                                <Col>
                                    <Row>
                                        <Col>
                                            <Select
                                                placeholder={t('Select Year')}
                                                options={centerlicData.data.map((v, i) => { return { label: v.reg_year, value: v.centerlic_id, reg_status: v.reg_status } })}
                                                onChange={(event) => this.setState({ centerlicData: { ...centerlicData, selected: event }, termsData: { ...termsData, selected: [] } })
                                                }
                                                value={centerlicData.selected}
                                                menuPortalTarget={document.body}
                                                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                                formatOptionLabel={option => (
                                                    <div className='d-flex align-items-center'>
                                                        <span style={{ fontSize: 16 }} className='me-1'>{option.label}</span>
                                                        {option.reg_status == 1 && <Badge pill bg="success">{t('Active')}</Badge>}
                                                    </div>
                                                )}
                                            />
                                        </Col>
                                        <Col md="6">
                                            <Select
                                                isMulti
                                                isClearable
                                                placeholder={t('Select Term')}
                                                options={filteredTerms.map((v, i) => { return { label: v.term_name, value: v.term_id, term_active: v.term_active } })}
                                                onChange={(event) => this.setState({ termsData: { ...termsData, selected: event } })
                                                }
                                                value={termsData.selected}
                                                menuPortalTarget={document.body}
                                                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                                formatOptionLabel={option => (
                                                    <div className='d-flex align-items-center'>
                                                        <span style={{ fontSize: 16 }} className='me-1'>{option.label}</span>
                                                        {option.term_active == 1 && <Badge pill bg="success">{t('Active')}</Badge>}
                                                    </div>
                                                )}
                                            />
                                        </Col>
                                        <Col>
                                            <Button variant={'success'} onClick={() => this.fetchReport()}>
                                                {reportData.processing
                                                    ? <Spinner as='span' animation='grow' size='sm' role='status' aria-hidden='true' />
                                                    : <FontAwesomeIcon icon={faSearch} style={{ fontSize: 16, color: brand_colors[this.props.defaultTheme.theme_id].color8, }} />
                                                }
                                            </Button>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col md="auto">

                                </Col>
                            </Row>
                        </div>
                        <div className='grey-section'>

                            {reportData.processing
                                ? <div className='text-center'>
                                    <Spinner as='span' animation='grow' size='sm' role='status' aria-hidden='true' />
                                </div>
                                : <RenderReport reportData={reportData} defaultTheme={this.props.defaultTheme} reRenderTime={reRenderTime} />
                            }
                        </div>
                    </div>
                </div>

            </Container>
        );
    }
}

const mapStateToProps = (state) => ({
    authData: state.auth.authData,
    defaultLanguage: state.language.defaultLanguage,
    selectedClass: state.selectedClass.data,
    selectedSubject: state.selectedClass.subject,
    children: state.children.list,
    defaultTheme: state.theme.defaultTheme,
});

export default connect(mapStateToProps, null)(ReportCurriculumResult);


class RenderReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }


    shouldComponentUpdate(nextProps) {
        if (moment(nextProps.reRenderTime).isSame(moment(this.props.reRenderTime))) {

            return false
        }

        return true
    }

    render() {
        let reportData = this.props.reportData
        if (!reportData.finished) {
            return null
        }
        return (
            <div>
                <Table striped bordered hover responsive>
                    <thead>
                        <tr>
                            <th >{t('Objectives')}</th>
                            {reportData.data.children.map((v, i) => {
                                return <th>{v.child_name}</th>
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {reportData.data.rowsData.map((v, i) => {
                            return (<Fragment key={i}>
                                <tr className='cursor-pointer'>
                                    <td style={{ width: '30rem' }}>
                                        {v.curriculum_desc}
                                    </td>
                                    {v.childReport.map((vv, ii) => {
                                        console.log('exam_competancy', vv.exam_competancy);

                                        if (vv.exam_competancy.length > 0) {
                                            let total_confirmed_answer_grade = 0;
                                            let total_examsquest_grade = 0;
                                            vv.exam_competancy.map((vvv) => {
                                                total_confirmed_answer_grade += parseFloat(vvv.confirmed_answer_grade)
                                                total_examsquest_grade += parseFloat(vvv.examsquest_grade)
                                            })
                                            let average = (total_confirmed_answer_grade / total_examsquest_grade) * 100
                                            let gradeslevelData = reportData.data.gradeslevel.find((vvv, iiii) => (average >= vvv.gradeslevel_from && average <= vvv.gradeslevel_to))
                                            return <td key={ii}>
                                                <div>{parseFloat(average).toFixed(2)}%</div>
                                                <div className="badge badge-success" style={{ backgroundColor: changeColorOpacity(gradeslevelData.gradeslevel_color, 0.2), color: gradeslevelData.gradeslevel_color }}>{gradeslevelData.gradeslevel_name}</div>
                                            </td>
                                        }
                                        return <td key={ii}>{'--'}</td>

                                        // reportData.rowsData.map((vv, ii) => {
                                        //     if (v.cmmaspect_id == vv.cmmaspect_id) {
                                        //         tbody += '<tr><td className="table-active"><span style="min-width:400px">' + vv.cmmobj_name + '</span></td>'
                                        //         vv.childReport.map((vvv, iii) => {
                                        //             let totalSum = 0
                                        //             let totalChildSum = 0
                                        //             vvv.exam_competancy.map((vvvv, iiii) => {
                                        //                 totalChildSum += parseFloat(vvvv.confirmed_answer_grade)
                                        //                 totalSum += parseFloat(vvvv.examsquest_grade)
                                        //             })

                                        //             let average = (totalChildSum / totalSum) * 100
                                        //             if (average) {
                                        //                 let gradeslevelData = reportData.gradeslevel.find((vvvv, iiii) => (average >= vvvv.gradeslevel_from && average <= vvvv.gradeslevel_to))
                                        //                 

                                        //                 tbody += `<td>${parseFloat(average).toFixed(2)}% <span style="color:${gradeslevelData.gradeslevel_color}">${gradeslevelData.gradeslevel_name}</span></td>`
                                        //             } else {
                                        //                 tbody += `<td>--</td>`
                                        //             }
                                        //         })
                                        //         tbody += '</tr>'
                                        //     }
                                        // })
                                    })}
                                </tr>
                            </Fragment>)
                        })}
                    </tbody>
                </Table>
            </div>
        )
    }
}
