import { createSlice } from '@reduxjs/toolkit';

export const userTimelineCommentsModalSlice = createSlice({
  name: 'userTimelineCommentsModal',
  initialState: {
    showModal: false,
  },
  reducers: {
    setUserTimelineCommentsModal: (state, action) => {
      state.showModal = action.payload.showModal;
    },
  },
});

export const { setUserTimelineCommentsModal } =
  userTimelineCommentsModalSlice.actions;
export default userTimelineCommentsModalSlice.reducer;
