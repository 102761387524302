import { createSlice } from '@reduxjs/toolkit';

export const updateSchoolModalSlice = createSlice({
  name: 'updateSchoolModal',
  initialState: {
    showModal: false
  },
  reducers: {
    setUpdateSchoolModal: (state, action) => {
      state.showModal = action.payload;
    },
  },
});

export const { setUpdateSchoolModal } =
  updateSchoolModalSlice.actions;
export default updateSchoolModalSlice.reducer;
