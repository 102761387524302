import { createSlice } from '@reduxjs/toolkit';

export const userTimelineListModalSlice = createSlice({
  name: 'userTimelineListModal',
  initialState: {
    showModal: false,
    utType: ''
  },
  reducers: {
    setUserTimelineListModal: (state, action) => {
      state.showModal = action.payload.showModal;
      state.utType = action.payload.utType;
    },
  },
});

export const { setUserTimelineListModal } =
  userTimelineListModalSlice.actions;
export default userTimelineListModalSlice.reducer;
