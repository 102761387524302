import React, { Component, Fragment } from 'react';
import { Container, Card, Table, Row, Col, Spinner, Image, Badge, Button, Form, Offcanvas, Accordion, Modal } from 'react-bootstrap';
import { t } from '../../helpers/translation_helper';
import NavBar from '../_partials/NavBar/_NavBar';
import Header from '../_partials/Header/_Header';
import { connect } from 'react-redux';
import axios from 'axios';
import { toast } from 'react-toastify';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight, faCalendarAlt, faCheck, faClock, faEdit, faExternalLink, faFileAlt, faGear, faPaperclip, faSave, faSearch, faTimes, faTrashCan, } from '@fortawesome/free-solid-svg-icons';
import { Scrollbar } from 'react-scrollbars-custom';
import imgDescription from '../../assets/images/description.png';
import { brand_colors } from '../../helpers/brand_colors_helper';
import SweetAlert from 'react-bootstrap-sweetalert';
import Select from 'react-select';
import { handleFormErrors } from '../../helpers/form_helpers';
import { GET } from '../../api';
import { Bar, Pie } from 'react-chartjs-2';
import { changeColorOpacity } from '../../helpers/general_helpers';
var Chance = require('chance');
var chance = new Chance();

// API URL
const apiURL = process.env.REACT_APP_API_URL;

// Portal URL
const portalURL = process.env.REACT_APP_PORTAL_URL;

class ReportSubjectAnalysis extends Component {
    constructor(props) {
        super(props);
        this.state = {
            centerlicData: { data: [], selected: null },
            termsData: { data: [], selected: [] },
            reRenderTime: moment(),
            reportData: {
                processing: false,
                data: {},
                finished: false
            },
        }
    }

    componentDidMount() {
        this.getCenterlic()
    }

    getCenterlic = () => {
        try {
            if (this.getCenterlicReq) {
                this.getCenterlicReq.abort();
            }
            this.getCenterlicReq = new AbortController();
            GET('report-exams/get-centerlic', {
                signal: this.getCenterlicReq.signal,
            }).then((res) => {

                if (res) {
                    let selected = res.data.find((v) => v.reg_status == 1)
                    this.setState({ centerlicData: { ...this.state.centerlicData, data: res.data, selected: { value: selected.centerlic_id, label: selected.reg_year, reg_status: selected.reg_status } } }, () => this.getTerms())
                }
            })
        } catch (err) {
            console.log('err', err)
            toast.error(t('Something went wrong while deleting!'));

        }
    }
    getTerms = () => {
        try {
            if (this.getTermsReq) {
                this.getTermsReq.abort();
            }
            this.getTermsReq = new AbortController();
            GET('report-exams/get-terms', {
                signal: this.getTermsReq.signal,
            }).then((res) => {

                if (res) {
                    let selected = []
                    res.data.map((v, i) => {
                        if (v.centerlic_id == this.state.centerlicData.selected.value) {
                            selected.push({ value: v.term_id, label: v.term_name, term_active: v.term_active })
                        }
                    })
                    this.setState({ termsData: { ...this.state.termsData, data: res.data, selected: selected } })
                }
            })
        } catch (err) {
            console.log('err', err)
            toast.error(t('Something went wrong while deleting!'));

        }
    }

    fetchReport = () => {
        let reportData = this.state.reportData
        let centerlicData = this.state.centerlicData
        let selectedSubject = this.props.selectedSubject
        let termsData = this.state.termsData
        if (!this.props.selectedClass) {
            toast.error(t('No Class Selected'));
            return
        }
        if (!selectedSubject) {
            toast.error(t('No Subject Selected'));
            return
        }
        if (!centerlicData.selected) {
            toast.error(t('Please select a Year'));
            return
        }
        if (termsData.selected.length <= 0) {
            toast.error(t('Please select a Term'));
            return
        }



        this.setState({ reportData: { ...reportData, processing: true, data: {} } })

        try {
            if (this.fetchReportReq) {
                this.fetchReportReq.abort();
            }
            this.fetchReportReq = new AbortController();
            GET('academic-classmain-subject/get-academic-classmain-subject-analysis', {
                params: {
                    class_id: this.props.selectedClass.class.class_id,
                    agegroup_id: this.props.selectedClass.class.agegroup_id,
                    acs_ids: JSON.stringify([selectedSubject.acs_id]),
                    centerlic_id: centerlicData.selected.value,
                    term_ids: JSON.stringify(termsData.selected.map((v, i) => v.value)),
                },
                signal: this.fetchReportReq.signal,
            }).then((res) => {

                if (res) {
                    this.setState({ reportData: { ...reportData, processing: false, data: res.data, finished: true }, reRenderTime: moment() })
                } else {
                    toast.error(t('Something went wrong while deleting!'));
                    this.setState({ reportData: { ...reportData, processing: false, data: {} } })
                }
            })
        } catch (err) {
            console.log('err', err)
            toast.error(t('Something went wrong while deleting!'));

            this.setState({ reportData: { ...reportData, processing: false, data: {} } })
        }
    }

    render() {

        const { reportData, reRenderTime, centerlicData, termsData } = this.state
        let filteredTerms = []
        if (centerlicData.selected) {
            filteredTerms = termsData.data.filter((v, i) => v.centerlic_id == centerlicData.selected.value)
        }
        return (
            <Container fluid>
                <div id='iedu-layout'>
                    <NavBar />
                    <div id='page-content'>
                        <Header lite={true} heading={t('Subject Analysis')} backBtn={true} showClassSelector={true} classSelectorFunc={[this.fetchReport]} showSubjectSelector={true} subjectSelectorFunc={[this.fetchReport]} />
                        <div className='grey-section'>

                            <Row className='justify-content-between'>
                                <Col>
                                    <Row>
                                        <Col>
                                            <Select
                                                placeholder={t('Select Year')}
                                                options={centerlicData.data.map((v, i) => { return { label: v.reg_year, value: v.centerlic_id, reg_status: v.reg_status } })}
                                                onChange={(event) => this.setState({ centerlicData: { ...centerlicData, selected: event }, termsData: { ...termsData, selected: [] } })
                                                }
                                                value={centerlicData.selected}
                                                menuPortalTarget={document.body}
                                                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                                formatOptionLabel={option => (
                                                    <div className='d-flex align-items-center'>
                                                        <span style={{ fontSize: 16 }} className='me-1'>{option.label}</span>
                                                        {option.reg_status == 1 && <Badge pill bg="success">{t('Active')}</Badge>}
                                                    </div>
                                                )}
                                            />
                                        </Col>
                                        <Col md="4">
                                            <Select
                                                isMulti
                                                isClearable
                                                placeholder={t('Select Term')}
                                                options={filteredTerms.map((v, i) => { return { label: v.term_name, value: v.term_id, term_active: v.term_active } })}
                                                onChange={(event) => this.setState({ termsData: { ...termsData, selected: event } })
                                                }
                                                value={termsData.selected}
                                                menuPortalTarget={document.body}
                                                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                                formatOptionLabel={option => (
                                                    <div className='d-flex align-items-center'>
                                                        <span style={{ fontSize: 16 }} className='me-1'>{option.label}</span>
                                                        {option.term_active == 1 && <Badge pill bg="success">{t('Active')}</Badge>}
                                                    </div>
                                                )}
                                            />
                                        </Col>
                                        <Col>
                                            <Button variant={'success'} onClick={() => this.fetchReport()}>
                                                {reportData.processing
                                                    ? <Spinner as='span' animation='grow' size='sm' role='status' aria-hidden='true' />
                                                    : <FontAwesomeIcon icon={faSearch} style={{ fontSize: 16, color: brand_colors[this.props.defaultTheme.theme_id].color8, }} />
                                                }
                                            </Button>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col md="auto">

                                </Col>
                            </Row>
                        </div>
                        <div className='grey-section'>

                            {reportData.processing
                                ? <div className='text-center'>
                                    <Spinner as='span' animation='grow' size='sm' role='status' aria-hidden='true' />
                                </div>
                                : <RenderReport reportData={reportData} termsData={termsData} authData={this.props.authData} defaultTheme={this.props.defaultTheme} reRenderTime={reRenderTime} />
                            }
                        </div>
                    </div>
                </div>

            </Container>
        );
    }
}

const mapStateToProps = (state) => ({
    authData: state.auth.authData,
    defaultLanguage: state.language.defaultLanguage,
    selectedClass: state.selectedClass.data,
    selectedSubject: state.selectedClass.subject,
    children: state.children.list,
    defaultTheme: state.theme.defaultTheme,
});

export default connect(mapStateToProps, null)(ReportSubjectAnalysis);


class RenderReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }


    shouldComponentUpdate(nextProps) {
        if (moment(nextProps.reRenderTime).isSame(moment(this.props.reRenderTime))) {

            return false
        }

        return true
    }

    render() {
        let reportData = this.props.reportData
        if (!reportData.finished) {
            return null
        }
        const data = {
            labels: this.props.termsData.selected.map((v) => v.label),
            datasets: reportData.data.allGradesLevel.map((v, i) => {
                return {
                    label: v.gradeslevel_name,
                    data: this.props.termsData.selected.map((vv) => {
                        let termData = reportData.data.subjectAnalysis.filter((vvv, iii) => {
                            return (vvv.term_id == vv.value && parseFloat(vvv.totalGradeAverage) >= v.gradeslevel_from && parseFloat(vvv.totalGradeAverage) <= v.gradeslevel_to)
                        })
                        return termData.length
                    }),
                    backgroundColor: v.gradeslevel_color,
                    barThickness: 40,
                }
            }),
        }

        const data_gender_M = {
            labels: reportData.data.allGradesLevel.map((v, i) => v.gradeslevel_name),
            datasets: [{
                data: reportData.data.allGradesLevel.map((v) => {
                    let termData = reportData.data.subjectAnalysis.filter((vvv, iii) => {
                        return (vvv.gender == 'M' && parseFloat(vvv.totalGradeAverage) >= v.gradeslevel_from && parseFloat(vvv.totalGradeAverage) <= v.gradeslevel_to)
                    })
                    return termData.length
                }),
                backgroundColor: reportData.data.allGradesLevel.map((v, i) => changeColorOpacity(v.gradeslevel_color, 0.5)),
                borderColor: reportData.data.allGradesLevel.map((v, i) => v.gradeslevel_color),
                borderWidth: 1,
            }],
        }
        const data_gender_F = {
            labels: reportData.data.allGradesLevel.map((v, i) => v.gradeslevel_name),
            datasets: [{
                data: reportData.data.allGradesLevel.map((v) => {
                    let termData = reportData.data.subjectAnalysis.filter((vvv, iii) => {
                        return (vvv.gender == 'F' && parseFloat(vvv.totalGradeAverage) >= v.gradeslevel_from && parseFloat(vvv.totalGradeAverage) <= v.gradeslevel_to)
                    })
                    return termData.length
                }),
                backgroundColor: reportData.data.allGradesLevel.map((v, i) => changeColorOpacity(v.gradeslevel_color, 0.5)),
                borderColor: reportData.data.allGradesLevel.map((v, i) => v.gradeslevel_color),
                borderWidth: 1,
            }],
        }
        const data_child_sen = {
            labels: reportData.data.allGradesLevel.map((v, i) => v.gradeslevel_name),
            datasets: [{
                data: reportData.data.allGradesLevel.map((v) => {
                    let termData = reportData.data.subjectAnalysis.filter((vvv, iii) => {
                        return (vvv.child_sen == 1 && parseFloat(vvv.totalGradeAverage) >= v.gradeslevel_from && parseFloat(vvv.totalGradeAverage) <= v.gradeslevel_to)
                    })
                    return termData.length
                }),
                backgroundColor: reportData.data.allGradesLevel.map((v, i) => changeColorOpacity(v.gradeslevel_color, 0.5)),
                borderColor: reportData.data.allGradesLevel.map((v, i) => v.gradeslevel_color),
                borderWidth: 1,
            }],
        }
        // const data_child_lac = {
        //     labels: reportData.data.allGradesLevel.map((v, i) => v.gradeslevel_name),
        //     datasets: [{
        //         data: reportData.data.allGradesLevel.map((v) => {
        //             let termData = reportData.data.subjectAnalysis.filter((vvv, iii) => {
        //                 return (vvv.child_lac == 1 && parseFloat(vvv.totalGradeAverage) >= v.gradeslevel_from && parseFloat(vvv.totalGradeAverage) <= v.gradeslevel_to)
        //             })
        //             return termData.length
        //         }),
        //         backgroundColor: reportData.data.allGradesLevel.map((v, i) => changeColorOpacity(v.gradeslevel_color, 0.5)),
        //         borderColor: reportData.data.allGradesLevel.map((v, i) => v.gradeslevel_color),
        //         borderWidth: 1,
        //     }],
        // }
        // const data_child_pp = {
        //     labels: reportData.data.allGradesLevel.map((v, i) => v.gradeslevel_name),
        //     datasets: [{
        //         data: reportData.data.allGradesLevel.map((v) => {
        //             let termData = reportData.data.subjectAnalysis.filter((vvv, iii) => {
        //                 return (vvv.child_pp == 1 && parseFloat(vvv.totalGradeAverage) >= v.gradeslevel_from && parseFloat(vvv.totalGradeAverage) <= v.gradeslevel_to)
        //             })
        //             return termData.length
        //         }),
        //         backgroundColor: reportData.data.allGradesLevel.map((v, i) => changeColorOpacity(v.gradeslevel_color, 0.5)),
        //         borderColor: reportData.data.allGradesLevel.map((v, i) => v.gradeslevel_color),
        //         borderWidth: 1,
        //     }],
        // }
        const data_child_nationals = {
            labels: reportData.data.allGradesLevel.map((v, i) => v.gradeslevel_name),
            datasets: [{
                data: reportData.data.allGradesLevel.map((v) => {
                    let termData = reportData.data.subjectAnalysis.filter((vvv, iii) => {
                        return (vvv.country_id == this.props.authData.centerData.country_id && parseFloat(vvv.totalGradeAverage) >= v.gradeslevel_from && parseFloat(vvv.totalGradeAverage) <= v.gradeslevel_to)
                    })
                    return termData.length
                }),
                backgroundColor: reportData.data.allGradesLevel.map((v, i) => changeColorOpacity(v.gradeslevel_color, 0.5)),
                borderColor: reportData.data.allGradesLevel.map((v, i) => v.gradeslevel_color),
                borderWidth: 1,
            }],
        }
        console.log('data_child_nationals', data_child_nationals);

        return (
            <div>
                <div className='d-flex justify-content-center' >
                    <Card style={{ width: '100%' }}>
                        <Card.Header>Year Group Overview</Card.Header>
                        <Card.Body>
                            <Bar height={70} options={{
                                responsive: true,
                                scales: {
                                    x: {
                                        stacked: true,
                                    },
                                    y: {
                                        stacked: true,
                                    },
                                },
                            }} data={data} />
                        </Card.Body>
                    </Card>
                </div>
                <div className='d-flex flex-row'>
                    <div style={{ width: '25%' }}>
                        <Card>
                            <Card.Header>Boys</Card.Header>
                            <Card.Body> <Pie data={data_gender_M} /> </Card.Body>
                        </Card>
                    </div>
                    <div style={{ width: '25%' }}>
                        <Card>
                            <Card.Header>Girls</Card.Header>
                            <Card.Body> <Pie data={data_gender_F} /> </Card.Body>
                        </Card>
                    </div>
                    <div style={{ width: '25%' }}>
                        <Card>
                            <Card.Header>Nationals</Card.Header>
                            <Card.Body> <Pie data={data_child_nationals} /> </Card.Body>
                        </Card>
                    </div>
                    <div style={{ width: '25%' }}>
                        <Card>
                            <Card.Header>SEN</Card.Header>
                            <Card.Body> <Pie data={data_child_sen} /> </Card.Body>
                        </Card>
                    </div>
                </div>
            </div>
        )
    }
}
