import React, { Component } from 'react';
import { Container, Card, Row, Col, ButtonGroup, Button, DropdownButton, Dropdown, Table, Form, Spinner, Modal, Tabs, Tab, Alert } from 'react-bootstrap';
import { t } from '../../helpers/translation_helper';
import NavBar from '../_partials/NavBar/_NavBar';
import Header from '../_partials/Header/_Header';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faCheckCircle, faSearch, faUser, faXmark, faLayerGroup, faHandsHoldingChild, faPeopleGroup, faListCheck, faPrint, faBus, faInfoCircle, faUserShield, faChartArea, faChartPie, faEllipsis, faAngleRight, faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { NavLink } from 'react-router-dom';
import { ifNavAllowed, isTeacher } from '../../helpers/general_helpers';
import { getStudents } from '../../redux/slices/studentListSlice';
import ReactPaginate from 'react-paginate';
import Pagination from '../_partials/Pagination';
import { GET } from '../../api';
import axios from 'axios';
import { toast } from 'react-toastify';
import { brand_colors } from '../../helpers/brand_colors_helper';
import GeneralCard from '../../helpers/GeneralCard';
import moment from 'moment';
const portalURL = process.env.REACT_APP_PORTAL_URL;
const apiURL = process.env.REACT_APP_API_URL;

class StudentPrintCards extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchExpanded: false,
            cdt: {
                order: { column: 'child_id', type: 'DESC' },
                rows: [10, 25, 50, 'All'],
            },
            modalPrintCards: {
                show: false,
                toggle: (title = 'Print Card', child_id, pc_id) => this.setState({ modalPrintCards: { ...this.state.modalPrintCards, show: !this.state.modalPrintCards.show, title, child_id, pc_id } }, () => {
                    if (this.state.modalPrintCards.show) {
                        this.getChildRegistrations(this.state.modalPrintCards.child_id)
                    }
                }),
                pc_id: 0,
                child_id: 0,
                refreshing: false,
                data: [],
                registrations: [],
            }
        }
        this.searchFieldRef = React.createRef();
    }


    componentDidMount() {
        this.getStudents({
            rows: this.props.studentListRows,
            page: 0,
            search: '',
            status: this.props.studentListStatus
        });
        this.getPrintCards()
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.selectedClass && prevProps.selectedClass.class.class_id !== this.props.selectedClass.class.class_id) {
            this.getStudents({
                rows: this.props.studentListRows,
                page: 0,
                search: '',
                status: this.props.studentListStatus
            });
            this.setState({ searchExpanded: false });
            this.searchFieldRef.current.value = '';
        }
    }

    getStudents = (params) => {
        this.props.getStudents({
            child_status: params.status,
            search: params.search,
            limit: params.rows,
            page: params.page,
        });
    }

    getPrintCards = () => {
        if (this.getPrintCardsReq) {
            this.getPrintCardsReq.abort();
        }
        this.getPrintCardsReq = new AbortController();

        let modalPrintCards = this.state.modalPrintCards
        this.setState({
            modalPrintCards: {
                ...modalPrintCards,
                refreshing: true,
                data: [],
            }
        }, async () => {
            try {
                const res = await GET('children/get-print-cards', {
                    signal: this.getPrintCardsReq.signal,
                }
                );
                console.log('children/get-print-cards', res)
                if (res) {
                    let resData = res.data
                    modalPrintCards = this.state.modalPrintCards
                    this.setState({
                        modalPrintCards: {
                            ...modalPrintCards,
                            refreshing: false,
                            data: resData,
                        }
                    })
                }
            } catch (err) {
                console.log('err', err)
                if (axios.isCancel(err)) {

                } else {
                    toast.error(t('Something went wrong while fetching data!'));
                }
            }
        })
    }

    getChildRegistrations = (child_id) => {
        if (this.getChildRegistrationsReq) {
            this.getChildRegistrationsReq.abort();
        }
        this.getChildRegistrationsReq = new AbortController();

        let modalPrintCards = this.state.modalPrintCards
        this.setState({
            modalPrintCards: {
                ...modalPrintCards,
                refreshing: true,
                registrations: [],
            }
        }, async () => {
            try {
                const res = await GET('children/get-child-registrations', {
                    params: {
                        child_id: child_id
                    },
                    signal: this.getChildRegistrationsReq.signal,
                }
                );
                console.log('children/get-child-registrations', res)
                let resData = res.data
                let cFutureRegistrations = []
                let uniqueClasses = []
                // TODO APPLY CONDITION FOR CYCLES HERE
                resData.data.map((v, i) => {
                    if (moment(v.date_to).isAfter(moment(resData.date)) && !uniqueClasses.includes(v.class_id)) {
                        cFutureRegistrations.push(v)
                        uniqueClasses.push(v.class_id)
                    }
                })
                modalPrintCards = this.state.modalPrintCards
                this.setState({
                    modalPrintCards: {
                        ...modalPrintCards,
                        refreshing: false,
                        registrations: cFutureRegistrations,
                    }
                })
            } catch (err) {
                console.log('err', err)
                if (axios.isCancel(err)) {

                } else {
                    toast.error(t('Something went wrong while fetching data!'));

                }
            }
        })
    };



    render() {
        let { modalPrintCards } = this.state
        return (
            <>
                <Card
                    className='border-0'
                    style={{ borderRadius: '20px' }}>
                    <Card.Body>
                        <Row>
                            <Col md={12} className='d-flex flex-column'>
                                <div className='d-flex flex-row align-items-center mb-2'>
                                    <strong>{t("Print Cards")}</strong>
                                    <div className='expandable-search ms-2' style={{ width: this.state.searchExpanded ? 200 : 35 }}>
                                        <input
                                            ref={this.searchFieldRef}
                                            defaultValue={this.props.studentListSearch}
                                            onChange={(e) => this.getStudents({
                                                rows: this.props.studentListRows,
                                                page: 0,
                                                search: e.target.value,
                                                status: this.props.studentListStatus
                                            })}
                                            type='text'
                                            style={{ width: this.state.searchExpanded ? 165 : 0, paddingLeft: this.state.searchExpanded ? 15 : 0, opacity: this.state.searchExpanded ? 1 : 0 }}
                                            placeholder={t('Search Student...')} />
                                        <button
                                            data-tooltip-id="tooltip"
                                            data-tooltip-content={t('Search Student')}
                                            data-tooltip-place="top"
                                            onClick={() => {
                                                this.setState({ searchExpanded: !this.state.searchExpanded });
                                                this.props.studentListSearch != '' && this.state.searchExpanded && this.getStudents({
                                                    rows: this.props.studentListRows,
                                                    page: 0,
                                                    search: '',
                                                    status: this.props.studentListStatus
                                                });
                                                this.searchFieldRef.current.value = '';
                                                !this.state.searchExpanded && this.searchFieldRef.current.focus();
                                            }}>
                                            <FontAwesomeIcon
                                                icon={this.state.searchExpanded ? faXmark : faSearch}
                                                color={brand_colors[this.props.defaultTheme.theme_id].color18}
                                            />
                                        </button>
                                    </div>
                                </div>
                                <div className='d-flex flex-row align-items-center'>
                                    <div>
                                        {t('Show')}
                                        <Form.Select
                                            value={this.props.studentListRows}
                                            size="sm"
                                            onChange={(e) => this.getStudents({
                                                rows: e.target.value,
                                                page: 0,
                                                search: this.props.studentListSearch,
                                                status: this.props.studentListStatus
                                            })}
                                            style={{ width: 70, marginInline: 5, display: 'inline-block' }}>
                                            {this.state.cdt.rows.map((row, row_index) => (<option key={row_index} >{row}</option>))}
                                        </Form.Select>
                                        {t('Records')}
                                    </div>
                                </div>
                            </Col>
                            {this.props.studentListFetching &&
                                <Col md={12} className='py-5 d-flex flex-column align-items-center'>
                                    <Spinner as='span' animation='grow' size='sm' /> {t('Loading Students')}
                                </Col>
                            }
                            {!this.props.studentListFetching &&
                                <Col md={12} className='pt-1 student-attendance-list'>
                                    {this.props.studentListData.map((student, student_index) => (
                                        <Row key={student_index} className='student-attendance-list-item'>
                                            <Col md={3} className='d-flex align-items-center'>
                                                <div style={{
                                                    backgroundImage: `url(${(student.picture) ? portalURL + student.center_id + '/children/' + student.picture : 'https://via.placeholder.com/150x150?text=' + student.child_name[0].toUpperCase()})`,
                                                    width: 60,
                                                    height: 60,
                                                    backgroundSize: 'cover',
                                                    backgroundPosition: 'center center',
                                                    borderRadius: 30,
                                                    marginRight: 15
                                                }}></div>
                                                {student.child_name}
                                            </Col>
                                            <Col>
                                                <div className='d-flex flex-row'>
                                                    {modalPrintCards.data.map((v, i) => {
                                                        let data = {
                                                            allData: v,
                                                            name: v.pc_name,
                                                            cardImage: (v.pc_bg_image) ? portalURL + v.center_id + '/print_cards/' + v.pc_bg_image : 'https://via.placeholder.com/150x150?text=' + (v.pc_name).toUpperCase(),
                                                            actions: [{ type: 'print', name: 'Print', image: null, iconName: faPrint, iconColor: brand_colors[this.props.defaultTheme.theme_id].color16, onClick: () => modalPrintCards.toggle('Print Card', student.child_id, v.pc_id) }]
                                                        }
                                                        return <GeneralCard data={data} key={i} />
                                                    })}
                                                </div>
                                            </Col>
                                        </Row>
                                    ))}
                                </Col>
                            }
                        </Row>
                        <Pagination
                            pageCount={this.props.studentListLastPage}
                            forcePage={this.props.studentListPage}
                            callbackParams={{
                                rows: this.props.studentListRows,
                                status: this.props.studentListStatus,
                                search: this.props.studentListSearch
                            }}
                            callback={this.getStudents}
                        />
                    </Card.Body>
                </Card>

                {/* MODAL CARD PRINT */}
                <Modal show={modalPrintCards.show} onHide={() => modalPrintCards.toggle()} backdrop="static"
        keyboard={false}>
                    <Modal.Header closeButton>
                        <Modal.Title>{modalPrintCards.title}</Modal.Title>
                    </Modal.Header>
                    {modalPrintCards.show &&
                        <Modal.Body>
                            {modalPrintCards.refreshing &&
                                <Spinner as='span' animation='grow' size='sm' role='status' aria-hidden='true' />
                            }
                            {!modalPrintCards.refreshing && modalPrintCards.registrations.length <= 0 &&
                                <Alert variant='danger'>No Registration found
                                    <div className='d-flex flex-column align-items-center ms-1 me-2 p-1 cursor-pointer' style={{ border: 'solid 1px ' + brand_colors[this.props.defaultTheme.theme_id].color16, borderRadius: 10, position: 'relative' }} onClick={() => {
                                        let encodedURL = apiURL + 'children/print-card?child_id=' + encodeURIComponent(modalPrintCards.child_id) + '&center_id=' + encodeURIComponent(this.props.authData.loginData.center_id) + '&class_theme=' + encodeURIComponent('No Class') + '&pc_id=' + encodeURIComponent(modalPrintCards.pc_id) + '&auth_key=' + encodeURIComponent(this.props.authData.loginData.auth_key)
                                        window.open(encodedURL)
                                    }}>
                                        <div style={{ fontSize: 13, color: brand_colors[this.props.defaultTheme.theme_id].color9, opacity: 0.8, fontWeight: 'bold' }}>{t('No Class')}</div>
                                        <div style={{}}>
                                            <FontAwesomeIcon icon={faPrint} style={{ color: brand_colors[this.props.defaultTheme.theme_id].color16 }} />
                                        </div>
                                    </div>
                                </Alert>
                            }
                            <div className='d-flex flex-row'>
                                {modalPrintCards.registrations.map((v, i) => {
                                    let encodedURL = apiURL + 'children/print-card?child_id=' + encodeURIComponent(modalPrintCards.child_id) + '&center_id=' + encodeURIComponent(v.center_id) + '&class_theme=' + encodeURIComponent(v.class_theme) + '&pc_id=' + encodeURIComponent(modalPrintCards.pc_id) + '&auth_key=' + encodeURIComponent(this.props.authData.loginData.auth_key)

                                    return <div className='d-flex flex-column align-items-center ms-1 me-2 p-1 cursor-pointer' style={{ border: 'solid 1px ' + brand_colors[this.props.defaultTheme.theme_id].color4, borderRadius: 10, position: 'relative' }} onClick={() => window.open(encodedURL)}>
                                        <div style={{ fontSize: 13, color: brand_colors[this.props.defaultTheme.theme_id].color9, opacity: 0.8, fontWeight: 'bold' }}>{v.class_theme}</div>
                                        <div style={{ fontSize: 12, color: brand_colors[this.props.defaultTheme.theme_id].color9, opacity: 0.7 }}>{v.reg_year}</div>
                                        <div style={{}}>
                                            <FontAwesomeIcon icon={faPrint} style={{ color: brand_colors[this.props.defaultTheme.theme_id].color4 }} />
                                        </div>
                                    </div>
                                })}
                            </div>
                        </Modal.Body>
                    }
                </Modal>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    authData: state.auth.authData,
    selectedClass: state.selectedClass.data,
    defaultLanguage: state.language.defaultLanguage,
    defaultTheme: state.theme.defaultTheme,
    studentListFetching: state.studentList.fetching,
    studentListData: state.studentList.data,
    studentListSearch: state.studentList.query,
    studentListRows: state.studentList.rows,
    studentListOffset: state.studentList.offset,
    studentListStatus: state.studentList.status,
    studentListOrder: state.studentList.order,
    studentListDir: state.studentList.dir,
    studentListLastPage: state.studentList.last_page,
    studentListPage: state.studentList.page,
    studentListTotalRecords: state.studentList.total_records,
});

const mapDispatchToProps = () => ({
    getStudents
});

export default connect(mapStateToProps, mapDispatchToProps())(StudentPrintCards);
