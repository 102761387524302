import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Button, Modal, Form, Tab, Tabs } from 'react-bootstrap';
import { setSubLessonModal } from '../../../redux/slices/subLessonModalSlice';
import { t } from '../../../helpers/translation_helper';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { brand_colors } from '../../../helpers/brand_colors_helper';
import { faEdit, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { GET, POST } from '../../../api';
import { toast } from 'react-toastify';
import { handleFormErrors } from '../../../helpers/form_helpers';

const apiURL = process.env.REACT_APP_API_URL;

class AddEditSubLesson extends Component {
  defaultFormFields = {
    assl_id: 0,
    assl_name: '',
    assl_description: '',
    subLessons: [],
    assl_usefulness: '',
    assl_preparation: '',
    assl_warmup: '',
    assl_steps: '',
    assl_ending: '',
    assl_differentiation: '',
    assl_objectives_description: '',
    assl_homeworks: '',
    assl_technology: '',
  };

  state = {
    ...this.defaultFormFields,
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.subLessonModal.showModal !== this.props.subLessonModal.showModal
    ) {
      if (this.props.subLessonModal.showModal) {
        this.getSubLessons();
      }
    }
  }

  getSubLessons = async () => {
    const { asl_id } = this.props.subLessonModal;
    try {
      const res = await GET(
        'academic-subject-lessons/get-academic-subject-sub-lessons',
        {
          params: { asl_id },
        }
      );

      this.setState({
        subLessons: res.data,
      });
    } catch (err) {
      console.log(err);
    }
  };

  insertSubLesson = async () => {
    let {
      assl_id,
      assl_name,
      assl_description,
      assl_usefulness,
      assl_preparation,
      assl_warmup,
      assl_steps,
      assl_ending,
      assl_differentiation,
      assl_objectives_description,
      assl_homeworks,
      assl_technology,
    } = this.state;

    if (!assl_name) {
      toast.warning(t('Title is required!'));
      return;
    }
    if (!assl_description) {
      toast.warning(t('Description is required!'));
      return;
    }

    const fData = new FormData();
    fData.append(
      'AcademicSubjectSubLessons',
      JSON.stringify({
        asl_id: this.props.subLessonModal.asl_id,
        assl_id,
        assl_name,
        assl_description,
        assl_usefulness,
        assl_preparation,
        assl_warmup,
        assl_steps,
        assl_ending,
        assl_differentiation,
        assl_objectives_description,
        assl_homeworks,
        assl_technology,
      })
    );

    try {
      if (this.insertAcademicSubjectSubLessons) {
        this.insertAcademicSubjectSubLessons.abort();
      }
      this.insertAcademicSubjectSubLessons = new AbortController();

      const res = await POST(
        'academic-subject-lessons/save-academic-subject-sub-lesson',
        fData,
        { signal: this.insertAcademicSubjectSubLessons.signal }
      );

      if (res) {
        if (res.data.type) {
          let newAcademicSubjectSubLessons = res.data.data;
          let { subLessons } = this.state;

          if (assl_id != 0) {
            const findIndex = subLessons.findIndex(
              (item) => item.assl_id == assl_id
            );
            subLessons[findIndex].assl_name = assl_name;
            subLessons[findIndex].assl_description = assl_description;
            subLessons[findIndex].assl_usefulness = assl_usefulness;
            subLessons[findIndex].assl_preparation = assl_preparation;
            subLessons[findIndex].assl_warmup = assl_warmup;
            subLessons[findIndex].assl_steps = assl_steps;
            subLessons[findIndex].assl_ending = assl_ending;
            subLessons[findIndex].assl_differentiation = assl_differentiation;
            subLessons[findIndex].assl_objectives_description =
              assl_objectives_description;
            subLessons[findIndex].assl_homeworks = assl_homeworks;
            subLessons[findIndex].assl_technology = assl_technology;
          } else {
            subLessons.unshift(newAcademicSubjectSubLessons);
          }

          this.setState({
            subLessons,
            assl_id: 0,
            assl_name: '',
            assl_description: '',
            assl_usefulness: '',
            assl_preparation: '',
            assl_warmup: '',
            assl_steps: '',
            assl_ending: '',
            assl_differentiation: '',
            assl_objectives_description: '',
            assl_homeworks: '',
            assl_technology: '',
          });
          toast.success(t('Saved successfully!'));
        } else {
          handleFormErrors(res.data.data);
        }
      }
    } catch (err) {
      console.log('err', err);
      toast.error(t('Something went wrong while saving Sub-Lesson!'));
      this.setState({
        uploadingAcademicSubjectSubLessons: false,
      });
    }
  };

  editSubLesson = (data) => {
    this.setState({
      assl_id: data.assl_id,
      assl_name: data.assl_name,
      assl_description: data.assl_description,
      assl_usefulness: data.assl_usefulness,
      assl_preparation: data.assl_preparation,
      assl_warmup: data.assl_warmup,
      assl_steps: data.assl_steps,
      assl_ending: data.assl_ending,
      assl_differentiation: data.assl_differentiation,
      assl_objectives_description: data.assl_objectives_description,
      assl_homeworks: data.assl_homeworks,
      assl_technology: data.assl_technology,
    });
  };

  deleteAcademicSubjectSubLessons = async (assl_id) => {
    const fData = new FormData();
    fData.append('assl_id', assl_id);
    try {
      const res = await POST(
        'academic-subject-lessons/delete-academic-subject-sub-lessons',
        fData
      );
      if (res) {
        if (res.data.type) {
          toast.warning(t('Deleted successfully!'));
          this.setState({
            subLessons: this.state.subLessons.filter(
              (item) => item.assl_id !== assl_id
            ),
            assl_id: 0,
            assl_name: '',
            assl_description: '',
            assl_usefulness: '',
            assl_preparation: '',
            assl_warmup: '',
            assl_steps: '',
            assl_ending: '',
            assl_differentiation: '',
            assl_objectives_description: '',
            assl_homeworks: '',
            assl_technology: '',
          });
        } else {
          handleFormErrors(res.data.data);
        }
      }
    } catch (err) {
      console.log('err', err);
      toast.error(t('Something went wrong while deleting Sub-Lesson!'));
    }
  };

  render() {
    return (
      <Modal
        show={this.props.subLessonModal.showModal}
        backdrop='static'
        keyboard={false}
        onHide={() => {
          this.setState({ ...this.defaultFormFields });
          this.props.setSubLessonModal({ showModal: false, asl_id: null });
        }}
        size='lg'>
        <Modal.Header closeButton>
          <Row style={{ flex: 1 }}>
            <Col>
              <Modal.Title>{t('Sub-Lessons')}</Modal.Title>
            </Col>
            <Col className='text-end pe-4'>
              <Button variant='success' onClick={() => this.insertSubLesson()}>
                Save
              </Button>
            </Col>
          </Row>
        </Modal.Header>
        <Modal.Body>
          <Tabs
            defaultActiveKey='sub-lesson'
            id='fill-tab-example'
            className='mb-3'
            fill>
            <Tab eventKey='sub-lesson' title={t('Sub Lesson')}>
              <Row className='custom-styled-form'>
                <Col md={12}>
                  <Form.Group>
                    <Form.Label>{t('Title')}</Form.Label>
                    <Form.Control
                      type='text'
                      value={this.state.assl_name}
                      onChange={(e) =>
                        this.setState({
                          assl_name: e.target.value,
                        })
                      }
                    />
                  </Form.Group>
                </Col>
                <Col md={12} className='mb-2'>
                  <Form.Group className='mb-1'>
                    <Form.Label>{t('Description')}</Form.Label>
                    <CKEditor
                      config={{
                        ckfinder: {
                          uploadUrl:
                            apiURL +
                            '/authentication/ckeditor-upload?auth_key=' +
                            this.props.authData.authKey,
                        },
                        removePlugins: ['Title'],
                        toolbar: {
                          items: [
                            'heading',
                            '|',
                            'bold',
                            'italic',
                            'alignment',
                            '|',
                            'numberedList',
                            'bulletedList',
                            '|',
                            'indent',
                            'outdent',
                            '|',
                            'undo',
                            'redo',
                          ],
                        },
                      }}
                      style={{ height: 200 }}
                      editor={Editor}
                      data={this.state.assl_description ?? ''}
                      onChange={(event, editor) => {
                        const assl_description = editor.getData();
                        this.setState({ assl_description });
                      }}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <hr />
              {this.state.subLessons.length == 0 && (
                <div className='text-center'>{t('No Data Found!')}</div>
              )}
              {this.state.subLessons.length > 0 && (
                <div
                  // className='d-flex flex-row flex-wrap'
                  style={{ marginTop: '10px' }}>
                  {this.state.subLessons.map((sl, sl_i) => {
                    return (
                      <div key={sl_i} className='d-flex flex-column listgroup'>
                        <Row className='mb-2'>
                          <Col>
                            {sl.created_by_name && (
                              <div className='badge badge-light-primary me-2'>
                                {sl.created_by_name}
                              </div>
                            )}
                            {sl.created_on && (
                              <div className='badge bg-dark'>
                                {sl.created_on}
                              </div>
                            )}
                          </Col>
                          <Col>
                            <div className='d-flex flex-row justify-content-end mb-1'>
                              <FontAwesomeIcon
                                icon={faEdit}
                                color={
                                  brand_colors[this.props.defaultTheme.theme_id]
                                    .color16
                                }
                                style={{
                                  fontSize: 16,
                                  cursor: 'pointer',
                                  marginLeft: '10px',
                                  marginRight: '10px',
                                }}
                                onClick={() => this.editSubLesson(sl)}
                              />
                              <FontAwesomeIcon
                                icon={faTimesCircle}
                                color={
                                  brand_colors[this.props.defaultTheme.theme_id]
                                    .color11
                                }
                                style={{ fontSize: 16, cursor: 'pointer' }}
                                onClick={() =>
                                  this.deleteAcademicSubjectSubLessons(
                                    sl.assl_id
                                  )
                                }
                              />
                            </div>
                          </Col>
                        </Row>
                        <div
                          className='d-flex flex-column justify-content-between'
                          style={{ fontSize: 14 }}>
                          <div style={{ fontWeight: '600' }}>
                            {sl.assl_name}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </Tab>
            <Tab eventKey='lesson-sequence' title='Lesson Sequence'>
              <Row>
                <Col md={6} className='custom-styled-form'>
                  <Form.Group className='mb-3'>
                    <Form.Label>
                      {t('Introduction / Explain it to students in brief')}
                    </Form.Label>
                    <Form.Control
                      as='textarea'
                      style={{ height: 200 }}
                      value={this.state.assl_usefulness}
                      onChange={(e) =>
                        this.setState({
                          assl_usefulness: e.target.value,
                        })
                      }
                    />
                  </Form.Group>
                  {/* Preparation */}
                  <Form.Group className='mb-3'>
                    <Form.Label>{t('Instruction / Preparation')}</Form.Label>
                    <Form.Control
                      as='textarea'
                      style={{ height: 200 }}
                      value={this.state.assl_preparation}
                      onChange={(e) =>
                        this.setState({
                          assl_preparation: e.target.value,
                        })
                      }
                    />
                  </Form.Group>
                  {/* Warm up */}
                  <Form.Group className='mb-3'>
                    <Form.Label>{t('Guided Practice / Warm up')}</Form.Label>
                    <Form.Control
                      as='textarea'
                      style={{ height: 200 }}
                      value={this.state.assl_warmup}
                      onChange={(e) =>
                        this.setState({
                          assl_warmup: e.target.value,
                        })
                      }
                    />
                  </Form.Group>
                  {/* Lesson Steps */}
                  <Form.Group className='mb-3'>
                    <Form.Label>{t('Practice / Lesson Steps')}</Form.Label>
                    <Form.Control
                      as='textarea'
                      style={{ height: 200 }}
                      value={this.state.assl_steps}
                      onChange={(e) =>
                        this.setState({
                          assl_steps: e.target.value,
                        })
                      }
                    />
                  </Form.Group>
                  {/* Closing */}
                  <Form.Group className='mb-3'>
                    <Form.Label>{t('Closing')}</Form.Label>
                    <Form.Control
                      as='textarea'
                      style={{ height: 200 }}
                      value={this.state.assl_ending}
                      onChange={(e) =>
                        this.setState({
                          assl_ending: e.target.value,
                        })
                      }
                    />
                  </Form.Group>
                </Col>
                {/* Explain it to students in brief / Related Concepts */}
                <Col md={6}>
                  {/* Differentiation */}
                  <Form.Group className='mb-3'>
                    <Form.Label>{t('Differentiation')}</Form.Label>
                    <CKEditor
                      config={{
                        removePlugins: ['Title'],
                        toolbar: {
                          items: [
                            'bold',
                            'italic',
                            '|',
                            'numberedList',
                            'bulletedList',
                            '|',
                            'undo',
                            'redo',
                          ],
                        },
                      }}
                      editor={Editor}
                      data={this.state.assl_differentiation ?? ''}
                      onChange={(event, editor) => {
                        const assl_differentiation = editor.getData();
                        this.setState({ assl_differentiation });
                      }}
                    />
                  </Form.Group>
                  {/* Competence Goals */}
                  <Form.Group className='mb-3'>
                    <Form.Label>{t('Objective')}</Form.Label>
                    <CKEditor
                      config={{
                        removePlugins: ['Title'],
                        toolbar: {
                          items: [
                            'bold',
                            'italic',
                            '|',
                            'numberedList',
                            'bulletedList',
                            '|',
                            'undo',
                            'redo',
                          ],
                        },
                      }}
                      editor={Editor}
                      data={this.state.assl_objectives_description ?? ''}
                      onChange={(event, editor) => {
                        const assl_objectives_description = editor.getData();
                        this.setState({ assl_objectives_description });
                      }}
                    />
                  </Form.Group>

                  {/* Homeworks */}
                  <Form.Group className='mb-3'>
                    <Form.Label>{t('Activities')}</Form.Label>
                    <CKEditor
                      config={{
                        removePlugins: ['Title'],
                        toolbar: {
                          items: [
                            'bold',
                            'italic',
                            '|',
                            'numberedList',
                            'bulletedList',
                            '|',
                            'undo',
                            'redo',
                          ],
                        },
                      }}
                      editor={Editor}
                      data={this.state.assl_homeworks ?? ''}
                      onChange={(event, editor) => {
                        const assl_homeworks = editor.getData();
                        this.setState({ assl_homeworks });
                      }}
                    />
                  </Form.Group>

                  {/* Technology */}
                  <Form.Group className='mb-3'>
                    <Form.Label>{t('Technolody')}</Form.Label>
                    <CKEditor
                      config={{
                        removePlugins: ['Title'],
                        toolbar: {
                          items: [
                            'bold',
                            'italic',
                            '|',
                            'numberedList',
                            'bulletedList',
                            '|',
                            'undo',
                            'redo',
                          ],
                        },
                      }}
                      editor={Editor}
                      data={this.state.assl_technology ?? ''}
                      onChange={(event, editor) => {
                        const assl_technology = editor.getData();
                        this.setState({ assl_technology });
                      }}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Tab>
          </Tabs>
        </Modal.Body>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  authData: state.auth.authData,
  defaultTheme: state.theme.defaultTheme,
  subLessonModal: state.subLessonModal,
});

const mapDispatchToProps = () => ({
  setSubLessonModal,
});

export default connect(mapStateToProps, mapDispatchToProps())(AddEditSubLesson);
