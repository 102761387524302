import React, { Component } from 'react';
import { Container, Card, Table, Row, Col, Spinner, Image, Badge, Button, Form, Offcanvas, Accordion } from 'react-bootstrap';
import { t } from '../../helpers/translation_helper';
import NavBar from '../_partials/NavBar/_NavBar';
import Header from '../_partials/Header/_Header';
import { connect } from 'react-redux';
import axios from 'axios';
import { toast } from 'react-toastify';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight, faCalendarAlt, faCheck, faCheckCircle, faClock, faEdit, faExternalLink, faFileAlt, faGear, faPaperclip, faSave, faSearch, faTimes, faTrashCan, } from '@fortawesome/free-solid-svg-icons';
import { Scrollbar } from 'react-scrollbars-custom';
import imgDescription from '../../assets/images/description.png';
import { brand_colors } from '../../helpers/brand_colors_helper';
import SweetAlert from 'react-bootstrap-sweetalert';
import Select from 'react-select';
import { handleFormErrors } from '../../helpers/form_helpers';
import { GET } from '../../api';


// API URL
const apiURL = process.env.REACT_APP_API_URL;

// Portal URL
const portalURL = process.env.REACT_APP_PORTAL_URL;

class StudentAttainment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedStudent: null,
      selectedClass: null,
      selectedAB: null,
      reportData: {
        processing: false,
        data: [],
      },
      academicBoards: [],
      assess_curr: [],
      isSaving: false,
      reRenderTime: moment(),
      activeAccordion: {
        stage1: null,
        stage2: null,
        stage3: null,
        setStage: (key, value) => this.setState({ activeAccordion: { ...this.state.activeAccordion, [key]: String(value) }, reRenderTime: moment() })
      },
      students: [],
    }
    this.assess_curr = []
  }

  componentDidMount() {
    // this.getStudents();
    this.getAcademicBoards();
  }

  // componentDidUpdate(prevProps) {
  //   if (prevProps.selectedClass.class.class_id !== this.props.selectedClass.class.class_id) {
  //     this.setState({
  //       students: [],
  //       selectedStudent: null,
  //       reportData: {
  //         processing: false,
  //         data: {},
  //         finished: false,
  //       },
  //       reRenderTime: moment(),
  //     }, () => this.getStudents());
  //   }
  // }

  getStudents = () => {

    try {
      if (this.getStudentsReq) {
        this.getStudentsReq.abort();
      }
      this.getStudentsReq = new AbortController();
      GET('student-tracker/get-children-list', {
        signal: this.getStudentsReq.signal,
        params: {
          class_ids: this.state.selectedClass.value,
        }
      }).then((res) => {

        if (res) {
          console.log('res', res);
          this.setState({
            students: res.data
          })
        } else {

        }
      })
    } catch (err) {
      console.log('err', err)
      toast.error(t('Something went wrong!'));

    }
  }

  getAcademicBoards = async () => {
    try {
      const res = await GET('student-tracker/get-academic-boards');

      this.setState({
        academicBoards: res.data.map(item => {
          return {
            label: item.ab_name,
            value: item.ab_id,
          };
        })
      });

    } catch (err) {
      console.log('err', err)
      toast.error(t('Something went wrong!'));
    }
  }

  fetchReport = () => {
    let reportData = this.state.reportData
    let selectedStudent = this.state.selectedStudent
    if (selectedStudent == null) {
      toast.error(t('Please select a Student'));
      return
    }
    this.setState({ reportData: { ...reportData, processing: true, data: {} } })

    try {
      if (this.fetchReportReq) {
        this.fetchReportReq.abort();
      }
      this.fetchReportReq = new AbortController();
      GET('student-tracker/get-assessment-menu', {
        params: {
          child_id: selectedStudent.value,
          ab_id: this.state.selectedAB.value,
        },
        signal: this.fetchReportReq.signal,
      }).then((res) => {

        if (res) {
          this.setState({ reportData: { ...reportData, processing: false, data: res.data }, reRenderTime: moment() })
        } else {
          toast.error(t('Something went wrong while deleting!'));
          this.setState({ reportData: { ...reportData, processing: false, data: {} } })
        }
      })
    } catch (err) {
      console.log('err', err)
      toast.error(t('Something went wrong while deleting!'));

      this.setState({ reportData: { ...reportData, processing: false, data: {} } })
    }
  }

  selectAssessmentAnsOption = (agegroup_id, lrnaspect_id, curriculum_id, event) => {

    let assess_curr = this.assess_curr
    let findAssessCurr = assess_curr.findIndex((v, i) => v.curr_agegroup_id == agegroup_id && v.curr_aspect_id == lrnaspect_id && v.curriculum_id == curriculum_id)

    if (event) {
      if (findAssessCurr != -1) {
        assess_curr[findAssessCurr]['asscurr_value'] = event.value
      } else {
        assess_curr.push({
          curr_agegroup_id: agegroup_id,
          curr_aspect_id: lrnaspect_id,
          curriculum_id: curriculum_id,
          asscurr_value: event.value
        })
      }
    } else {
      if (findAssessCurr != -1) {
        assess_curr.splice(findAssessCurr, 1)
      }
    }

    // this.setState({ assess_curr })
  }

  saveObservation = () => {
    if (this.state.isSaving) {
      return
    }
    this.setState({ isSaving: true }, async () => {
      const { authData } = this.props;
      const { center_id, center_timezone } = authData.centerData;
      const { auth_key } = authData.loginData;
      let selectedStudent = this.state.selectedStudent
      if (selectedStudent == null) {
        toast.error(t('Please select a Student!'));
        this.setState({ isSaving: false })
        return
      }
      if (this.assess_curr.length <= 0) {
        toast.error(t('No attainments selected!'));
        this.setState({ isSaving: false })
        return
      }

      let assess_aspect = []
      let assess_curr = []

      this.assess_curr.map((v, i) => {
        let findAssessAspect = assess_aspect.findIndex((vv, ii) => vv.lrnaspect_id == v.curr_aspect_id && vv.agegroup_id == v.curr_agegroup_id)
        if (findAssessAspect == -1) {
          assess_aspect.push({
            lrnaspect_id: v.curr_aspect_id,
            agegroup_id: v.curr_agegroup_id,
            assagegroup_value: 0,
          })
        }

        assess_curr.push({
          curriculum_id: v.curriculum_id,
          curr_aspect_id: v.curr_aspect_id,
          curr_agegroup_id: v.curr_agegroup_id,
          asscurr_value: v.asscurr_value,
        })
      })

      const fData = new FormData();
      fData.append('center_id', center_id);
      fData.append('employee_id', this.props.authData.userData.employee_id);
      fData.append('user_id', this.props.authData.loginData.user_id);
      fData.append('center_timezone', center_timezone);
      fData.append('child_id', selectedStudent.value);
      fData.append('class_id', this.props.selectedClass.class.class_id);
      fData.append('assess_aspect', JSON.stringify(assess_aspect));
      fData.append('assess_curr', JSON.stringify(assess_curr));

      if (this.saveObservationReq) this.saveObservationReq.abort();
      this.saveObservationReq = new AbortController();

      try {
        const res = await axios.post(
          apiURL + 'student-tracker/save-observation',
          fData,
          {
            params: {
              auth_key: auth_key,
              appname: process.env.REACT_APP_NAME,
            },
            signal: this.saveObservationReq.signal,
          }
        );


        if (res) {
          let resData = res.data
          if (resData.type) {
            this.assess_curr = []
            toast[resData.messageType](t(resData.message));
            this.setState({
              selectedStudent: null,
              reportData: {
                processing: false,
                data: {},
              },
              isSaving: false,
              reRenderTime: moment()
            })
          } else {
            if (resData.data == null) {
              toast[resData.messageType](t(resData.message));
            } else {
              handleFormErrors(resData.data)
            }
            this.setState({ isSaving: false })
          }
        } else {
          toast.error(t('Something went wrong while saving!'));
          this.setState({ isSaving: false })
        }
      } catch (err) {
        console.log('err', err)
        if (axios.isCancel(err)) {
          // 
        }
        toast.error(t('Something went wrong while saving!'));

      }
    })
  };

  render() {
    const { selectedStudent, reportData, isSaving, reRenderTime, activeAccordion } = this.state

    var assessmentAnsOptions = []

    if (reportData.data.assessmentAns) {
      assessmentAnsOptions = reportData.data.assessmentAns.map((vvvvvv, iiiiii) => {
        return {
          label: vvvvvv.assmt_code, value: vvvvvv.assmt_id, color: vvvvvv.assmt_color
        }
      })
    }

    console.log('%cSTATE', 'color: orange; font-size: 20px; font-weight: bold;', this.state);

    return (
      <Container fluid>
        <div id='iedu-layout'>
          <NavBar />
          <div id='page-content'>
            <Header lite={true} heading={t('Student Attainment')} backBtn={true} />
            <div className='grey-section'>
              <Row className='justify-content-between'>
                <Col>
                  <Row>
                    <Col md={3} className='pe-0'>
                      <Select
                        placeholder={t('Select Class')}
                        options={this.props.authData.classes.map((v, i) => { return { ...v.class, label: v.class.class_theme, value: v.class.class_id } })}
                        onChange={(event) => this.setState({ selectedClass: event }, () => this.getStudents())
                        }
                        value={this.state.selectedClass}
                        menuPortalTarget={document.body}
                        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                      />
                    </Col>
                    <Col md={3} className='pe-0'>
                      <Select
                        placeholder={t('Select Student')}
                        options={this.state.students.map((v, i) => { return { label: v.child_name, value: v.child_id, picture: v.picture, center_id: v.center_id, parent_id: v.parent_id } })}
                        onChange={(event) => this.setState({ selectedStudent: event })
                        }
                        value={selectedStudent}
                        menuPortalTarget={document.body}
                        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                        formatOptionLabel={option => (
                          <div>
                            {option.picture ? <img src={portalURL + option.center_id + '/children/' + option.picture} style={{ width: 30, height: 30, objectFit: 'cover', marginRight: 5, borderRadius: 10 }} /> : ''}
                            <span style={{ fontSize: 16 }}>{option.label}</span>
                          </div>
                        )}
                      />
                    </Col>
                    <Col md={3} className="pe-0">
                      <Select
                        placeholder={t('Select Academic Board')}
                        options={this.state.academicBoards}
                        onChange={(event) => this.setState({ selectedAB: event })
                        }
                        value={this.state.selectedAB}
                        menuPortalTarget={document.body}
                        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                      />
                    </Col>
                    <Col>
                      <Button variant={'primary'} onClick={() => this.fetchReport()}>
                        {reportData.processing
                          ? <Spinner as='span' animation='grow' size='sm' role='status' aria-hidden='true' />
                          : <FontAwesomeIcon icon={faSearch} style={{ fontSize: 16, color: brand_colors[this.props.defaultTheme.theme_id].color8, }} />
                        }
                      </Button>
                    </Col>
                  </Row>
                </Col>
                <Col md="auto">
                  <Button variant={'success'} onClick={() => this.saveObservation()}>
                    {isSaving ? <Spinner as='span' animation='grow' size='sm' role='status' aria-hidden='true' /> : <FontAwesomeIcon icon={faSave} style={{ fontSize: 16, color: brand_colors[this.props.defaultTheme.theme_id].color8, }} />}
                  </Button>
                </Col>
              </Row>
            </div>
            <div className='grey-section'>
              {reportData.processing
                ? <div className='text-center'>
                  <Spinner as='span' animation='grow' size='sm' role='status' aria-hidden='true' />
                </div>
                : <RenderCurriculum assessmentAnsOptions={assessmentAnsOptions} reportData={reportData} selectAssessmentAnsOption={this.selectAssessmentAnsOption} defaultTheme={this.props.defaultTheme} reRenderTime={reRenderTime} activeAccordion={activeAccordion} selectedClass={this.state.selectedClass} />
              }
            </div>
          </div>
        </div>
      </Container >
    );
  }
}

const mapStateToProps = (state) => ({
  authData: state.auth.authData,
  defaultLanguage: state.language.defaultLanguage,
  selectedClass: state.selectedClass.data,
  children: state.children.list,
  defaultTheme: state.theme.defaultTheme,
});

export default connect(mapStateToProps, null)(StudentAttainment);

class RenderCurriculum extends Component {
  state = {}

  shouldComponentUpdate(nextProps) {
    if (moment(nextProps.reRenderTime).isSame(moment(this.props.reRenderTime))) {
      return false
    }
    return true
  }

  render() {

    let activeAccordion = this.props.activeAccordion

    let reportData = this.props.reportData

    return (<Accordion onSelect={(activeIndex) => activeAccordion.setStage('stage1', activeIndex)}>

      {reportData.data.length > 0 &&

        reportData.data.map((v, i) => {
          return v.agegroup.map((vv, ii) => {

            // ab & AGEGROUP
            let ab_id = v.ab_id;
            let agegroup_id = vv.agegroup_id;
            return (
              <Accordion.Item
                key={v.ab_id + '^' + vv.agegroup_id}
                eventKey={v.ab_id + '^' + vv.agegroup_id}>
                <Accordion.Header>
                  {/* {v.ab_desc || v.ab_name} */}
                  <div style={{ fontSize: 12, color: brand_colors[this.props.defaultTheme.theme_id].color8, backgroundColor: vv.agegroup_color, padding: '5px 10px', borderRadius: 10, margin: '0 10px 0 0' }}>{vv.agegroup_code}</div>
                  {vv.agegroup_id == this.props.selectedClass.agegroup_id && <FontAwesomeIcon icon={faCheckCircle} style={{ fontSize: 16, color: '#47BCE8' }} />}
                </Accordion.Header>
                {activeAccordion.stage1 && activeAccordion.stage1.includes(v.ab_id + '^' + vv.agegroup_id) &&
                  <Accordion.Body>
                    {/* AREA */}
                    <Accordion onSelect={(activeIndex) => activeAccordion.setStage('stage2', activeIndex)}>
                      {v.learnarea && v.learnarea.map((vvv, iii) => {
                        if (vvv.ab_id != ab_id) {
                          return null
                        }
                        return (
                          <Accordion.Item
                            key={vvv.learnarea_id}
                            eventKey={vvv.learnarea_id}>
                            <Accordion.Header>
                              <div style={{ color: vvv.learnarea_color, }}>{vvv.learnarea_desc || vvv.learnarea_code}</div>
                            </Accordion.Header>
                            {activeAccordion.stage2 && activeAccordion.stage2.includes(vvv.learnarea_id) &&
                              <Accordion.Body>
                                {/* ASPECT */}
                                <Accordion onSelect={(activeIndex) => activeAccordion.setStage('stage3', activeIndex)}>
                                  {v.lrnaspect && v.lrnaspect.map((vvvv, iiii) => {
                                    let lrnaspect_id = vvvv.lrnaspect_id

                                    let findCurriculums = undefined
                                    if (v.curriculum) {
                                      findCurriculums = v.curriculum.find((vvvvv, iiiii) => {
                                        if (vvvvv.lrnaspect_id == lrnaspect_id && vvvvv.agegroup_id == agegroup_id) {
                                          return vvvvv
                                        }
                                      })
                                    }


                                    return (
                                      <Accordion.Item
                                        key={lrnaspect_id}
                                        eventKey={lrnaspect_id}>
                                        <Accordion.Header>
                                          <div style={{}}>{vvvv.lrnaspect_desc || vvvv.lrnaspect_code}</div>
                                        </Accordion.Header>
                                        {activeAccordion.stage3 && activeAccordion.stage3.includes(lrnaspect_id) &&
                                          <Accordion.Body>
                                            {findCurriculums == undefined && <div>
                                              <div style={{ color: brand_colors[this.props.defaultTheme.theme_id].color11, fontSize: 12, fontWeight: 'bold' }}>No Objectives found...</div>
                                            </div>}

                                            {/* CURRICULUM */}
                                            {findCurriculums != undefined && v.curriculum && v.curriculum.map((vvvvv, iiiii) => {
                                              if (vvvvv.lrnaspect_id != lrnaspect_id || vvvvv.agegroup_id != agegroup_id) {
                                                return null
                                              }
                                              let curriculum_id = vvvvv.curriculum_id
                                              return <div key={curriculum_id} className='d-flex flex-row justify-content-between'>
                                                <div className='d-flex flex-column'>
                                                  <div className='d-flex flex-row'>
                                                    <div style={{ fontSize: 12, fontWeight: 'bold', color: brand_colors[this.props.defaultTheme.theme_id].color9, opacity: 0.7, marginRight: 10 }}>
                                                      #{vvvvv.curriculum_ref_num}
                                                    </div>
                                                    <div style={{ fontSize: 12, color: brand_colors[this.props.defaultTheme.theme_id].color9, opacity: 0.7 }}>
                                                      {vvvvv.curriculum_desc || vvvvv.curriculum_code}
                                                    </div>
                                                  </div>
                                                  <div className='d-flex flex-row flex-wrap'>
                                                    {v.curriculum_ans && v.curriculum_ans.map((vvvvvv, iiiiii) => {
                                                      if (vvvvvv.curr_agegroup_id != agegroup_id || vvvvvv.curr_aspect_id != lrnaspect_id || vvvvvv.curriculum_id != curriculum_id) {
                                                        return null
                                                      }
                                                      return (
                                                        <div className='d-flex flex-column justify-content-center align-items-center' style={{ fontSize: 12, color: brand_colors[this.props.defaultTheme.theme_id].color8, backgroundColor: vvvvvv.assmt_color, padding: '5px 10px', borderRadius: 10, margin: '5px 10px' }}>
                                                          <div>{vvvvvv.assesslog_date}</div>
                                                          <div>{vvvvvv.assmt_code || vvvvvv.assmt_desc}</div>
                                                        </div>
                                                      )
                                                    })}
                                                  </div>
                                                </div>
                                                <Col md="auto">
                                                  <Select
                                                    isClearable
                                                    options={v.assessmentAns.map((vvvvvvv, iiiiiii) => {
                                                      return {
                                                        ...vvvvvvv,
                                                        label: vvvvvvv.assmt_code, value: vvvvvvv.assmt_id, color: vvvvvvv.assmt_color
                                                      }
                                                    })}
                                                    onChange={(event) => this.props.selectAssessmentAnsOption(agegroup_id, lrnaspect_id, curriculum_id, event)
                                                    }
                                                    // defaultValue={null}
                                                    menuPortalTarget={document.body}
                                                    styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                                    formatOptionLabel={option => (
                                                      <div>
                                                        <span style={{ fontSize: 16, color: option.color }}>{option.label}</span>
                                                      </div>
                                                    )}
                                                  />
                                                </Col>
                                              </div>
                                            })}

                                          </Accordion.Body>
                                        }
                                      </Accordion.Item>
                                    )
                                  })}
                                </Accordion>
                              </Accordion.Body>
                            }
                          </Accordion.Item>
                        )
                      })}
                    </Accordion>
                  </Accordion.Body>
                }
              </Accordion.Item>
            )
          })
        })}

    </Accordion>);
  }
}
