import React, { Component } from 'react';
import { Container, Card, Row, Col, Spinner, Modal, Button, Form, InputGroup } from 'react-bootstrap';
import { t } from '../../helpers/translation_helper';
import NavBar from '../_partials/NavBar/_NavBar';
import Header from '../_partials/Header/_Header';
import { connect } from 'react-redux';
import moment from 'moment';
import { GET, POST } from '../../api';
import { toast } from 'react-toastify';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from '@fullcalendar/list';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { brand_colors } from '../../helpers/brand_colors_helper';
import { faCalendarAlt, faTrashAlt, faXmark } from '@fortawesome/free-solid-svg-icons';
import { DateRangePicker } from 'react-date-range';
import { handleFormErrors } from '../../helpers/form_helpers';


class Calendar extends Component {

  calendarEntryDefaultFields = {
    cc_id: 0,
    title: '',
    body: '',
    selectedDateRange: {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    },
    add_type: '',
    class_id: 0,
    weekly_lesson_plan: 0,
    acs_id: '',
  };

  state = {
    events: null,
    modal: {
      show: false,
      title: '',
      data: ''
    },
    calendarEntryModal: {
      ...this.calendarEntryDefaultFields,
      modalShow: false,
      showDateRange: false,
    },
  };

  componentDidMount() {
    this.getCalendar();
  }

  getCalendar = async (arg) => {
    if (arg == undefined) {
    } else {
      this.dateStart = arg.start
      this.dateEnd = arg.end
    }
    this.setState({
      events: null,
    });
    const { class_id } = this.props.selectedClass.class;

    let request_params = {
      class_id,
      startDate: moment(this.dateStart).format('YYYY-MM-DD') + ' 00:00:00',
      endDate: moment(this.dateEnd).format('YYYY-MM-DD') + ' 23:59:59',
    };

    try {
      const res = await GET('center-calendar/get-center-calendar', {
        params: {
          ...request_params
        }
      });

      const eventsModified = res.data.map((item) => {
        const doc = new DOMParser().parseFromString(item.body, 'text/html');

        let title = item.title;

        if (item.acs_name) {
          title = item.title + ' - ' + item.acs_name + ' - ' + doc.body.textContent;
        }

        return {
          ...item,
          title,
          start: item.start_datetime,
          end: item.end_datetime,
          allDay: (moment(item.start_datetime).format('HH:mm') == '00:00' && moment(item.end_datetime).format('HH:mm') == '23:59') ? true : false
        }
      });

      this.setState({
        events: eventsModified,
      });
    } catch (err) {
      console.log('err', err)
      toast.error('Something went wrong while fetching calendar!');

    }
  };

  eventClick = (e) => {

    if (e.event.extendedProps.inserted_employee_id == this.props.authData.loginData.user_id) {
      this.handleOpenCalendarEntryModal(e)
    } else {
      this.setState({
        modal: {
          show: true,
          title: e.event.title,
          data: e.event.extendedProps.body
        }
      });
    }

  }

  handleClose = () => {
    this.setState({
      modal: {
        show: false,
        title: '',
        data: ''
      }
    })
  }

  handleOpenCalendarEntryModal = (info) => {
    let calendarEntryModal;

    if (info.event) {
      calendarEntryModal = {
        cc_id: info.event.extendedProps.cc_id,
        title: info.event.title,
        body: info.event.extendedProps.body,
        add_type: info.event.extendedProps.add_type,
        acs_id: info.event.extendedProps.acs_id,
        class_id: 0,
        weekly_lesson_plan: 0,
        selectedDateRange: {
          startDate: new Date(info.event.extendedProps.start_datetime),
          endDate: new Date(info.event.extendedProps.end_datetime),
          key: 'selection',
        },
        modalShow: true,
      };
    } else {
      calendarEntryModal = {
        ...this.calendarEntryDefaultFields,
        selectedDateRange: {
          startDate: new Date(info.dateStr),
          endDate: new Date(info.dateStr),
          key: 'selection',
        },
        modalShow: true,
      };
    }

    this.setState({
      calendarEntryModal,
    });
  };

  handleCloseCalendarEntryModal = () => {
    this.setState({
      calendarEntryModal: {
        ...this.calendarEntryDefaultFields,
        modalShow: false,
        showDateRange: false,
      },
    });
  };

  createCalendarEntry = async () => {
    const {
      cc_id,
      title,
      body,
      selectedDateRange,
      add_type,
      class_id,
      weekly_lesson_plan,
      acs_id
    } = this.state.calendarEntryModal;

    const employee_classplan_approve = this.props.authData.userData.employee_classplan_approve;
    // const employee_classplan_approve = 0;

    const calendarEntryObject = {
      cc_id,
      title,
      body,
      add_type,
      class_id,
      weekly_lesson_plan,
      date: moment(selectedDateRange.startDate).format('YYYY-MM-DD'),
      start_datetime:
        moment(selectedDateRange.startDate).format('YYYY-MM-DD') + ' 00:00:00',
      end_datetime:
        moment(selectedDateRange.endDate).format('YYYY-MM-DD') + ' 23:59:59',
      class_ids: JSON.stringify([this.props.selectedClass.class.class_id]),
      acs_id,
      employee_classplan_approve,
    };

    const fData = new FormData();
    fData.append('CenterCalendarData', JSON.stringify(calendarEntryObject));

    try {
      const res = await POST('center-calendar/save-center-calendar', fData);

      if (res.data.type) {
        this.handleCloseCalendarEntryModal();

        const prevEvents = this.state.events.filter(
          (item) => item.cc_id != res.data.data.cc_id
        );

        let data = res.data.data;

        if (data.acs_name) {
          data.title = data.title + ' - ' + data.acs_name + ' - ' + data.body;
        }

        this.setState({
          events: [...prevEvents, res.data.data],
        });
      } else {
        toast.error(t('Something went wrong!'));
        handleFormErrors(res.data.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  deleteCalendarEntry = async () => {
    const { cc_id } = this.state.calendarEntryModal;

    const fData = new FormData();
    fData.append('cc_id', cc_id);

    try {
      const res = await POST('center-calendar/delete-center-calendar', fData);

      if (res.data.type) {
        this.handleCloseCalendarEntryModal();

        const prevEvents = this.state.events.filter(
          (item) => item.cc_id != cc_id
        );

        this.setState({
          events: prevEvents,
        });
      } else {
        toast.error(t('Something went wrong!'));
        handleFormErrors(res.data.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  render() {
    // Language Direction
    const { lang_orientation: dir, lang_flag } = this.props.defaultLanguage;

    const subjects = [];

    if (this.props.selectedClass.academic_classmain_subject.length > 0) {
      this.props.selectedClass.academic_classmain_subject.map((item) => {
        subjects.push({
          value: item.acs_id,
          label: item.acs_name,
        });
      });
    }

    return (
      <Container fluid>
        <div id='iedu-layout'>
          <NavBar />
          <div id='page-content'>
            <Header lite={true} heading={t('Calendar')}
              backBtn={true}
              showClassSelector={true}
              classSelectorFunc={[this.getCalendar]} />
            <div className='grey-section'>
              <Card
                className='border-0'
                style={{ borderRadius: '20px' }}>
                <Card.Body className='p-4 position-relative' style={{ overflow: 'hidden' }}>
                  {this.state.events == null && (
                    <div className='loading-overlay'>
                      <span className='mb-1'>
                        <Spinner
                          animation='grow'
                          variant='dark'
                        />
                      </span>
                      {t('Fetching events...')}
                    </div>
                  )}
                  <FullCalendar
                    height={'auto'}
                    plugins={[
                      dayGridPlugin,
                      interactionPlugin,
                      timeGridPlugin,
                      listPlugin
                    ]}
                    headerToolbar={{
                      left: "today prev,next",
                      center: "title",
                      right: "dayGridMonth,timeGridWeek,timeGridDay,listWeek",
                    }}
                    initialView='dayGridMonth'
                    datesSet={this.getCalendar}
                    events={this.state.events}
                    locale={dir == 1 ? 'ar-kw' : 'en'}
                    direction={dir == 1 ? 'rtl' : 'ltr'}
                    eventClick={this.eventClick}
                    dateClick={this.handleOpenCalendarEntryModal}
                  />
                </Card.Body>
              </Card>
            </div>
          </div>
        </div>

        <Modal show={this.state.modal.show} onHide={this.handleClose} backdrop="static"
          keyboard={false}>
          <Modal.Header closeButton>
            <Modal.Title>{this.state.modal.title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div
              dangerouslySetInnerHTML={{ __html: this.state.modal.data }}
            />
          </Modal.Body>
        </Modal>

        <Modal
          size='lg'
          show={this.state.calendarEntryModal.modalShow}
          onHide={this.handleCloseCalendarEntryModal}
          backdrop='static'
          keyboard={false}>
          <Modal.Header closeButton>
            <Modal.Title>{t('Calendar Entry')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col md={6}>
                <Form.Group className='mb-3'>
                  <Form.Label>{t('Title')}</Form.Label>
                  <Form.Control
                    type='text'
                    defaultValue={this.state.calendarEntryModal.title}
                    onBlur={(e) =>
                      this.setState({
                        calendarEntryModal: {
                          ...this.state.calendarEntryModal,
                          title: e.target.value,
                        },
                      })
                    }
                    onClick={() =>
                      this.setState({
                        calendarEntryModal: {
                          ...this.state.calendarEntryModal,
                          showDateRange: false,
                        },
                      })
                    }
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className='mb-3'>
                  <Form.Label>{t('Subject')}</Form.Label>
                  <Form.Select onChange={(e) => {
                    this.setState({
                      calendarEntryModal: {
                        ...this.state.calendarEntryModal,
                        acs_id: e.target.value,
                      },
                    });
                  }} value={this.state.calendarEntryModal.acs_id}>
                    <option>{t('Select Subject')}</option>
                    {subjects.map((item) => (
                      <option key={item.value} value={item.value}>
                        {item.label}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col md={6} className='position-relative'>
                <Form.Label>{t('From-To Date')}</Form.Label>
                <InputGroup className='mb-3'>
                  <Form.Control
                    onClick={() =>
                      this.setState({
                        calendarEntryModal: {
                          ...this.state.calendarEntryModal,
                          showDateRange: true,
                        },
                      })
                    }
                    style={{
                      paddingLeft: 20,
                    }}
                    value={
                      moment(
                        this.state.calendarEntryModal.selectedDateRange
                          .startDate
                      ).format('DD-MM-YYYY') +
                      ' -- ' +
                      moment(
                        this.state.calendarEntryModal.selectedDateRange.endDate
                      ).format('DD-MM-YYYY')
                    }
                    readOnly
                  />
                  <Button
                    variant={
                      this.state.calendarEntryModal.showDateRange
                        ? 'danger'
                        : 'primary'
                    }
                    style={{ width: 40 }}
                    onClick={() =>
                      this.setState({
                        calendarEntryModal: {
                          ...this.state.calendarEntryModal,
                          showDateRange:
                            !this.state.calendarEntryModal.showDateRange,
                        },
                      })
                    }
                    id='button-addon2'>
                    <FontAwesomeIcon
                      icon={
                        this.state.calendarEntryModal.showDateRange
                          ? faXmark
                          : faCalendarAlt
                      }
                      style={{
                        fontSize: 16,
                        color:
                          brand_colors[this.props.defaultTheme.theme_id].color8,
                      }}
                    />
                  </Button>
                </InputGroup>
                {this.state.calendarEntryModal.showDateRange && (
                  <div
                    style={{
                      backgroundColor: '#fff',
                      padding: 10,
                      position: 'absolute',
                      border: '#F2F2F2 2px solid',
                      zIndex: 1,
                      top: 68,
                      left: -45,
                      borderRadius: 7,
                    }}>
                    <DateRangePicker
                      ranges={[this.state.calendarEntryModal.selectedDateRange]}
                      onChange={(date) => {
                        this.setState({
                          calendarEntryModal: {
                            ...this.state.calendarEntryModal,
                            selectedDateRange: {
                              startDate: new Date(date.selection.startDate),
                              endDate: new Date(date.selection.endDate),
                              key: 'selection',
                            },
                          },
                        });
                      }}
                      style={{ backgroundColor: '#fff' }}
                    />
                  </div>
                )}
              </Col>
              <Col md={6}>
                <Form.Group className='mb-3'>
                  <Form.Label>{t('Type')}</Form.Label>
                  <Form.Select onChange={(e) => {
                    this.setState({
                      calendarEntryModal: {
                        ...this.state.calendarEntryModal,
                        add_type: e.target.value,
                      },
                    });
                  }} value={this.state.calendarEntryModal.add_type}>
                    <option>{t('Select Type')}</option>
                    <option value="exams">{t('Exams')}</option>
                    <option value="homework">{t('Homework')}</option>
                    <option value="classwork">{t('Classwork')}</option>
                    <option value="agenda">{t('Agenda')}</option>
                    <option value="academic_activities">{t('Academic Activities')}</option>
                    <option value="academic_subject_lessons">{t('Academic Subject Lessons')}</option>
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col md={12}>
                <Form.Group className='mb-3'>
                  <Form.Label>{t('Description')}</Form.Label>
                  <Form.Control
                    type='text'
                    defaultValue={this.state.calendarEntryModal.body}
                    onBlur={(e) =>
                      this.setState({
                        calendarEntryModal: {
                          ...this.state.calendarEntryModal,
                          body: e.target.value,
                        },
                      })
                    }
                  />
                </Form.Group>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Row style={{ flex: 1 }}>
              <Col className='p-0'>
                {(
                  this.state.calendarEntryModal.cc_id != 0
                ) && (
                    <Button variant='danger' onClick={this.deleteCalendarEntry}>
                      <FontAwesomeIcon icon={faTrashAlt} /> {t('Delete')}
                    </Button>
                  )}
              </Col>
              <Col className='p-0 d-flex justify-content-end'>
                <Button
                  className='me-2'
                  variant='secondary'
                  onClick={this.handleCloseCalendarEntryModal}>
                  {t('Close')}
                </Button>
                <Button variant='primary' onClick={this.createCalendarEntry}>
                  {t('Save')}
                </Button>
              </Col>
            </Row>
          </Modal.Footer>
        </Modal>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  authData: state.auth.authData,
  defaultLanguage: state.language.defaultLanguage,
  selectedClass: state.selectedClass.data,
  defaultTheme: state.theme.defaultTheme,
});

export default connect(mapStateToProps, null)(Calendar);
