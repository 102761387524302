import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { GET } from '../../api';
import { store } from '../store';
let getParentsReq;

export const getParents = createAsyncThunk('users/getParents', async ({ parent_status, search, order, dir, limit, page }) => {

    if (getParentsReq) {
        getParentsReq.abort();
    }
    getParentsReq = new AbortController();

    let state = store.getState()

    let parentsList = state.parentsList
    if (search == undefined) {
        search = parentsList.query
    }
    if (parent_status == undefined) {
        parent_status = parentsList.status
    }
    if (order == undefined) {
        order = parentsList.order
    }
    if (dir == undefined) {
        dir = parentsList.dir
    }
    if (limit == undefined) {
        limit = parentsList.rows
    }
    if (page == undefined) {
        page = parentsList.page
    }
    try {

        const response = await GET('parents/get', {
            params: {
                parent_status,
                search,
                order,
                dir,
                limit,
                offset: page * limit,
            },
            signal: getParentsReq.signal,
        });

        return {
            page,
            order,
            dir,
            search,
            limit,
            status: parent_status,
            data: response.data.data,
            total_records: response.data.total_count_filtered,
            total_count: response.data.total_count,
        };

    } catch (error) {
        if (axios.isCancel(error)) {

        } else {

        }
    }

})

export const parentsListSlice = createSlice({
    name: 'parentsList',
    initialState: {
        fetching: false,
        query: '',
        rows: 10,
        data: [],
        order: 'p.parent_name',
        dir: 'ASC',
        status: true,
        total_records: 0,
        total_count: 0,
        last_page: 0,
        page: 0,
    },
    reducers: {
        setParents: (state, action) => {
            state.data = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getParents.pending, (state, action) => {
            if (state.fetching === false) {
                state.fetching = true;
            }
        })
        builder.addCase(getParents.fulfilled, (state, action) => {
            if (state.fetching === true && action.payload) {
                state.data = action.payload.data;
                state.order = action.payload.order;
                state.dir = action.payload.dir;
                state.query = action.payload.search;
                state.rows = action.payload.limit;
                state.status = action.payload.status;
                state.total_records = parseInt(action.payload.total_records);
                state.total_count = parseInt(action.payload.total_count);
                state.page = parseInt(action.payload.page);
                state.last_page = Math.ceil(parseInt(action.payload.total_records) / parseInt(action.payload.limit));
                state.fetching = false;
            }
        })
        builder.addCase(getParents.rejected, (state, action) => {
            if (state.fetching === true) {
                state.fetching = false;
                state.error = 'Error occurred';
            }
        })
    }
});

export const { setParents } = parentsListSlice.actions;
export default parentsListSlice.reducer;
