import React, { Component } from 'react';
import { Container, Card, Row, Col, ButtonGroup, Button, DropdownButton, Dropdown, Form, Modal, Spinner, Table, Badge } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faCheckCircle, faSearch, faUser, faXmark, faLayerGroup, faHandsHoldingChild, faPeopleGroup, faListCheck, faPrint, faPencil, faTrash, faCheckDouble, faSortDown, faSortUp, faThumbsUp, faThumbsDown } from '@fortawesome/free-solid-svg-icons';
import { faEye, faEyeSlash, faFileExcel, faFilePdf } from '@fortawesome/free-regular-svg-icons';
import { t } from '../../helpers/translation_helper';
import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';
import NavBar from '../_partials/NavBar/_NavBar';
import Header from '../_partials/Header/_Header';
import { connect } from 'react-redux';
import moment from 'moment';
import { brand_colors } from '../../helpers/brand_colors_helper';
import { GET, POST } from '../../api';
import { toast } from 'react-toastify';
import { handleFormErrors } from '../../helpers/form_helpers';
import Select from 'react-select';
import axios from 'axios';
import Pagination from '../_partials/Pagination';
import { renderTableBody, renderTableHead } from '../../helpers/table_helper';
let cancelToken;

class SessionCategories extends Component {

  searchFieldRef = React.createRef();

  defaultFormFields = {
    sessioncat_id: 0,
    sessioncat_name: '',
    vat_rate: 0.00,
    expats_only: 1,
    chapt_id: '',
    acc_num: '',
    qb_account: '',
    uchapt_id: '',
    uacc_num: '',
    uqb_account: ''
  }

  state = {
    ...this.defaultFormFields,
    isEditing: false,
    working: false,
    cdt: {
      fetching: false,
      search: '',
      rows: 3,
      offset: 0,
      order: 'sessioncat.sessioncat_id',
      dir: 'DESC',
      lastPage: 0,
      page: 0,
      totalRecords: 0,
      rowsOptions: [3, 10, 25, 50, 'All'],
      columns: [
        {
          id: 'sessioncat.sessioncat_id',
          column: 'sessioncat_id',
          name: 'Actions',
          visibility: true
        },
        {
          id: 'sessioncat.sessioncat_name',
          column: 'sessioncat_name',
          name: 'Name',
          visibility: true
        },
        {
          id: 'sessioncat.acc_num',
          column: 'acc_num',
          name: 'Account',
          visibility: true
        },
        {
          id: 'sessioncat.chapt_num',
          column: 'chapt_num',
          name: 'Chart',
          visibility: true
        },
        {
          id: 'sessioncat.chapt_desc',
          column: 'chapt_desc',
          name: 'Chart Description',
          visibility: true
        },
        {
          id: 'sessioncat.qb_account',
          column: 'qb_account',
          name: '3rd Party Account',
          visibility: true
        },
        {
          id: 'sessioncat.uacc_num',
          column: 'uacc_num',
          name: 'Unearned Account',
          visibility: true
        },
        {
          id: 'sessioncat.uchapt_num',
          column: 'uchapt_num',
          name: 'Unearned Chart',
          visibility: true
        },
        {
          id: 'sessioncat.uchapt_desc',
          column: 'uchapt_desc',
          name: 'Unearned Chart Description',
          visibility: true
        },
        {
          id: 'sessioncat.uqb_account',
          column: 'uqb_account',
          name: 'Unearned 3rd Party Account',
          visibility: true
        },
        {
          id: 'sessioncat.vat_rate',
          column: 'vat_rate',
          name: 'VAT',
          visibility: true
        },
        {
          id: 'sessioncat.expats_only',
          column: 'expats_only',
          name: 'Expats Only',
          visibility: true
        },
      ],
      data: [],
    },
    showFormModal: false,
    chapters: [],
    unearnedChapters: []
  }

  componentDidMount() {
    this.getSetDDLs();
    const {
      search,
      rows: limit,
      order,
      dir
    } = this.state.cdt;
    this.fetch({
      search, limit, order, dir, page: 0
    });
  }

  getSetDDLs = async () => {

    try {
      const response = await axios.all([
        GET('sessioncat/get-chapters'),
        GET('sessioncat/get-un-earned-chapters'),
      ]);

      // Chapters
      const chapters = response[0].data.map((item) => {
        return {
          value: item.chapt_id,
          label: item.chapt_desc,
        }
      });

      // Unearned Chapters
      const unearnedChapters = response[1].data.map((item) => {
        return {
          value: item.chapt_id,
          label: item.chapt_desc,
        }
      });

      this.setState({
        chapters,
        unearnedChapters,
      });

    } catch (err) {
      console.log('err', err)

    }


  }

  fetch = async (params) => {

    this.setState({
      cdt: {
        ...this.state.cdt,
        fetching: true,
      }
    });

    if (cancelToken) {
      cancelToken.abort();
    }
    cancelToken = new AbortController();

    try {
      const res = await GET('sessioncat/get', {
        signal: cancelToken.signal,
        params: {
          search: params.search,
          order: params.order,
          dir: params.dir,
          limit: params.limit,
          offset: parseInt(params.page) * parseInt(params.limit)
        },
      });

      if (res.status === 200) {
        this.setState({
          cdt: {
            ...this.state.cdt,
            fetching: false,
            search: params.search,
            rows: parseInt(params.limit),
            offset: parseInt(params.page) * parseInt(params.limit),
            order: params.order,
            dir: params.dir,
            page: params.page,
            totalRecords: parseInt(res.data.total_count_filtered),
            lastPage: Math.ceil(parseInt(res.data.total_count_filtered) / parseInt(params.limit)),
            data: res.data.data,
          }
        });
      }

    } catch (err) {
      console.log('err', err)
      this.setState({
        cdt: {
          ...this.state.cdt,
          fetching: false,
        }
      });

    }
  }

  showHideColumn = (column_index) => {
    const columns = this.state.cdt.columns;
    columns[column_index].visibility = !columns[column_index].visibility;
    this.setState({ ctd: { ...this.state.ctd, columns } })
  }

  handleOpenFormModal = () => {
    this.setState({
      showFormModal: true
    });
  }

  handleCloseFormModal = () => {
    this.setState({
      showFormModal: false,
      isEditing: false,
      ...this.defaultFormFields
    });
  }

  saveUpdate = async () => {

    const { authData } = this.props;
    const { center_id } = authData.centerData;

    this.setState({ working: true });

    const {
      sessioncat_id,
      sessioncat_name,
      vat_rate,
      expats_only,
      chapt_id,
      acc_num,
      qb_account,
      uchapt_id,
      uacc_num,
      uqb_account
    } = this.state;

    const fData = new FormData();
    fData.append('center_id', center_id);
    fData.append('sessioncat_id', sessioncat_id);
    fData.append('SessioncatData', JSON.stringify({
      sessioncat_name,
      vat_rate,
      expats_only,
      chapt_id: chapt_id.value,
      acc_num,
      qb_account,
      uchapt_id: uchapt_id.value,
      uacc_num,
      uqb_account
    }));

    try {
      const res = await POST('sessioncat/save', fData);
      if (res.data.type) {
        this.setState({ working: false });
        toast.success(t(res.data.message));
        this.fetch({
          search: '',
          limit: this.state.cdt.rows,
          order: this.state.cdt.order,
          dir: this.state.cdt.dir,
          page: 0
        });
        this.handleCloseFormModal();
      } else {
        handleFormErrors(res.data.data)
        this.setState({ working: false });
      }
    } catch (err) {
      console.log('err', err)
      toast.error(t('Something went wrong!'));

      this.setState({ working: false });
    }

  }

  edit = (data) => {

    this.setState({
      ...this.state,
      ...data,
      chapt_id: { value: data.chapt_id, label: data.chapt_desc },
      uchapt_id: { value: data.uchapt_id, label: data.uchapt_desc },
      isEditing: true,
    });

    this.handleOpenFormModal();
  }

  exportExcel = async () => {

    const excelData = this.state.cdt.data.map((item) => {
      const row = {};
      this.state.cdt.columns
        .filter((column) => column.visibility)
        .map((column) => {
          if (column.name !== 'Actions') {
            row[column.name] = item[column.column];
          }
        });
      return row;
    });

    const fileName = 'Session Categories Export ' + moment().format('DD-MM-YYYY');
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const ws = XLSX.utils.json_to_sheet(excelData);

    const cellStyle = {
      font: {
        bold: true,
      },
    }

    Array.from('ABCEFGHIJK').map((letter) => {
      ws[letter + "1"].s = cellStyle;
    });

    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });

    FileSaver.saveAs(data, fileName + fileExtension);
  }

  renderCell = (column_data, column_id, column_index, dataObj) => {
    const { authData } = this.props;
    const { center_id } = authData.centerData;

    let tdData;

    // ----------------------------------------------------------
    if (column_id == 'sessioncat_id') { // Action Buttons
      tdData = <>
        <Button
          onClick={() => this.edit(dataObj)}
          variant="warning" size='sm' className='me-1'>
          <FontAwesomeIcon icon={faPencil} />
        </Button>
      </>;
    } else if (column_id == 'expats_only') {
      tdData = column_data == 1 ?
        <Badge bg="info">{t('Yes')}</Badge>
        :
        <Badge bg="danger">{t('No')}</Badge>;
      // ---
    } else {

      tdData = column_data;
    }


    return <td key={column_index}>{tdData}</td>;
  }

  render() {
    const { cdt } = this.state
    return (
      <div className='shadow-box'>
        <Card
          className='border-0'
          style={{ borderRadius: '20px' }}>
          <Card.Header style={{
            backgroundColor: '#fff',
            borderTopLeftRadius: '20px',
            borderTopRightRadius: '20px',
            fontWeight: '700'
          }}>
            <Row>
              <Col md={6} className='d-flex align-items-center'>
                {t('Session Categories')}
              </Col>
              <Col md={6} className='d-flex justify-content-end'>
                <div className='cdt-search'>
                  <input type='text'
                    ref={this.searchFieldRef}
                    defaultValue={this.state.cdt.search}
                    onChange={(e) => this.fetch({
                      search: e.target.value,
                      limit: this.state.cdt.rows,
                      order: this.state.cdt.order,
                      dir: this.state.cdt.dir,
                      page: 0
                    })}
                    placeholder={t('Search...')} />
                  <button
                    onClick={() => {
                      this.fetch({
                        search: '',
                        limit: this.state.cdt.rows,
                        order: this.state.cdt.order,
                        dir: this.state.cdt.dir,
                        page: 0
                      });
                      this.searchFieldRef.current.value = '';
                    }}>
                    <FontAwesomeIcon
                      icon={this.state.cdt.search == '' ? faSearch : faXmark}
                      color={brand_colors[this.props.defaultTheme.theme_id].color18}
                    />
                  </button>
                </div>
                <button
                  onClick={this.handleOpenFormModal}
                  className='btn btn-success btn-sm ms-3'><FontAwesomeIcon icon={faPlus} color={brand_colors[this.props.defaultTheme.theme_id].color8} style={{ fontSize: 12 }} /></button>
              </Col>
            </Row>
          </Card.Header>
          <Card.Body style={{ paddingTop: 8 }}>
            <Row>
              <Col md={6}>
                <ButtonGroup size='sm' className='me-3 float-left'>
                  {/* <Button><FontAwesomeIcon icon={faFilePdf} /> {t('PDF')}</Button> */}
                  <Button onClick={() => this.exportExcel()}>
                    <FontAwesomeIcon icon={faFileExcel} /> {t('Excel')}
                  </Button>
                  {/* <Button><FontAwesomeIcon icon={faPrint} /> {t('Print')}</Button> */}
                  <DropdownButton
                    autoClose={'outside'}
                    size='sm'
                    as={ButtonGroup}
                    title={t('Column Visibility')}>
                    {this.state.cdt.columns.map((column, column_index) => (
                      <Dropdown.Item key={column_index} className={column.visibility ? 'column-name-item' : 'column-name-item active'} onClick={() => this.showHideColumn(column_index)}>
                        <FontAwesomeIcon icon={column.visibility ? faEye : faEyeSlash} /> {column.name}
                      </Dropdown.Item>
                    ))}
                  </DropdownButton>
                </ButtonGroup>
              </Col>
              <Col md={6} className='d-flex justify-content-end'>
                <div style={{ display: 'inline-block' }}>
                  {t('Show')}
                  <Form.Select
                    value={this.props.employeesListRows}
                    size="sm"
                    onChange={(e) => this.fetch({
                      search: this.state.cdt.search,
                      limit: e.target.value,
                      order: this.state.cdt.order,
                      dir: this.state.cdt.dir,
                      page: this.state.cdt.page
                    })}
                    style={{ width: 70, marginInline: 5, display: 'inline-block' }}>
                    {this.state.cdt.rowsOptions.map((row, row_index) => (<option key={row_index} >{row}</option>))}
                  </Form.Select>
                  {t('Records')}
                </div>
              </Col>

              {this.state.cdt.fetching &&
                <Col md={12} className='py-5 d-flex flex-column align-items-center'>
                  <Spinner as='span' animation='grow' size='sm' /> {t('Loading Data')}
                </Col>
              }
              {!this.state.cdt.fetching && this.state.cdt.data.length === 0 &&
                <Col md={12} className='py-5 d-flex flex-column align-items-center'>
                  {t('No Data Found')}
                </Col>
              }
              {!this.state.cdt.fetching && this.state.cdt.data.length !== 0 &&
                <Col md={12} className='pt-2'>
                  <div id="datatable-list" style={{ width: 'initial' }} className='mb-3'>
                    <Table responsive>
                      {renderTableHead(this)}
                      {renderTableBody(this)}
                    </Table>
                  </div>
                </Col>
              }
            </Row>
            <Pagination
              pageCount={this.state.cdt.lastPage}
              forcePage={this.state.cdt.page}
              callbackParams={{
                search: this.state.cdt.search,
                limit: this.state.cdt.rows,
                order: this.state.cdt.order,
                dir: this.state.cdt.dir,
              }}
              callback={this.fetch}
            />
          </Card.Body>
        </Card>
        <Modal size='lg' backdrop="static"
        keyboard={false}
          show={this.state.showFormModal} onHide={this.handleCloseFormModal}>
          <Modal.Header closeButton>
            <Modal.Title>{this.state.isEditing ? t('Edit') : t('Add')} {t('Session Category')}</Modal.Title>
          </Modal.Header>
          <Modal.Body className='custom-styled-form'>
            <Row>
              <Col md={4}>
                <Form.Group className="mb-3">
                  <Form.Label>{t('Name')}</Form.Label>
                  <Form.Control type="text"
                    defaultValue={this.state.sessioncat_name}
                    onBlur={(e) => this.setState({ sessioncat_name: e.target.value })} />
                </Form.Group>
              </Col>
              <Col md={2}>
                <Form.Group className="mb-3">
                  <Form.Label>{t('VAT Rate')}</Form.Label>
                  <Form.Control type="text"
                    defaultValue={this.state.vat_rate}
                    onBlur={(e) => this.setState({ vat_rate: e.target.value })}
                    placeholder='0.00'
                  />
                </Form.Group>
              </Col>
              <Col md={2} className='ps-0'>
                <Form.Group className="mb-3">
                  <Form.Label>{t('Expats Only')}</Form.Label>
                  <div className='p-3 pb-0'>
                    <Form.Check
                      type="switch"
                      id="expats_only"
                      label={false}
                      onChange={() => this.setState({ expats_only: this.state.expats_only == 1 ? 0 : 1 })}
                      checked={this.state.expats_only == 1}
                    />
                  </div>
                </Form.Group>
              </Col>
              <Col md={12}><hr /></Col>
              <Col md={12}><h5 className='mb-0'>{t('Earned Account')}</h5></Col>
              <Col md={4}>
                <Form.Group className="mb-3">
                  <Form.Label>{t('Chart')}</Form.Label>
                  <Select
                    value={this.state.chapt_id}
                    menuPortalTarget={document.body}
                    styles={{
                      menuPortal: (
                        base
                      ) => ({
                        ...base,
                        zIndex: 9999,
                      }),
                    }}
                    options={this.state.chapters}
                    onChange={(obj) => this.setState({ chapt_id: obj })}
                  />
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group className="mb-3">
                  <Form.Label>{t('Account')}</Form.Label>
                  <Form.Control type="text"
                    defaultValue={this.state.acc_num}
                    onBlur={(e) => this.setState({ acc_num: e.target.value })}
                    readOnly
                    placeholder={t('Auto-Generate')}
                  />
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group className="mb-3">
                  <Form.Label>{t('3rd Party Account')}</Form.Label>
                  <Form.Control type="text"
                    defaultValue={this.state.qb_account}
                    onBlur={(e) => this.setState({ qb_account: e.target.value })}
                  />
                </Form.Group>
              </Col>
              <Col md={12}><hr /></Col>
              <Col md={12}><h5 className='mb-0'>{t('Unearned Account')}</h5></Col>
              <Col md={4}>
                <Form.Group className="mb-3">
                  <Form.Label>{t('Unearned Chart')}</Form.Label>
                  <Select
                    value={this.state.uchapt_id}
                    menuPortalTarget={document.body}
                    styles={{
                      menuPortal: (
                        base
                      ) => ({
                        ...base,
                        zIndex: 9999,
                      }),
                    }}
                    options={this.state.unearnedChapters}
                    onChange={(obj) => this.setState({ uchapt_id: obj })}
                  />
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group className="mb-3">
                  <Form.Label>{t('Unearned Account')}</Form.Label>
                  <Form.Control type="text"
                    defaultValue={this.state.uacc_num}
                    onBlur={(e) => this.setState({ uacc_num: e.target.value })}
                    readOnly
                    placeholder={t('Auto-Generate')}
                  />
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group className="mb-3">
                  <Form.Label>{t('Unearned 3rd Party Account')}</Form.Label>
                  <Form.Control type="text"
                    defaultValue={this.state.uqb_account}
                    onBlur={(e) => this.setState({ uqb_account: e.target.value })}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleCloseFormModal}>
              {t('Close')}
            </Button>
            <Button disabled={this.state.working} variant='success' onClick={() => this.saveUpdate()}>
              {t('Save')} {this.state.working ? <Spinner as='span' animation='grow' size='sm' role='status' aria-hidden='true' /> : ''}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  authData: state.auth.authData,
  defaultLanguage: state.language.defaultLanguage,
  defaultTheme: state.theme.defaultTheme,
});

export default connect(mapStateToProps, null)(SessionCategories);
