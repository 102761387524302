import React, { Component } from 'react';
import { Container, Card, Row, Col } from 'react-bootstrap';
import { t } from '../../helpers/translation_helper';
import NavBar from '../_partials/NavBar/_NavBar';
import Header from '../_partials/Header/_Header';
import { connect } from 'react-redux';
import { faChartBar, faFileInvoice, faReceipt, faUserCheck, faFileInvoiceDollar, faUsers, faMagnifyingGlassChart, faAward, faUserGear, faUserTie, faClipboardUser, faUsersLine } from '@fortawesome/free-solid-svg-icons';
import { brand_colors } from '../../helpers/brand_colors_helper';
import { NavLink } from 'react-router-dom';
import { changeColorOpacity, getUrlParam, ifNavAllowed } from '../../helpers/general_helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAddressCard } from '@fortawesome/free-regular-svg-icons';
import Evaluations from './_Evaluations';
import EvaluationSetup from './EvaluationSetup/_EvaluationSetup';
import EmployeeEvaluationReport from './EmployeeEvaluationReport/_EmployeeEvaluationReport';
import ReportAppraisalGroup from "./EmployeePerforma/ReportAppraisalGroup";
import EvaluationAnalysisReport from "./EvaluationAnalysisReport/EvaluationAnalysisReport";
import TopicReport from "./TopicReport/TopicReport";



class EvaluationMenu extends Component {

    state = {
        activeTab: 0,
        nav: [
            {
                id: 'evaluation-setup',
                title: t('Evaluation Setup'),
                cardImage: require('../../assets/images/setup/curriculumbuilder.png'),
                icon: faUserGear,
                modules: <EvaluationSetup />,
            },
            {
                id: 'evaluations',
                title: t('Evaluations'),
                cardImage: require('../../assets/images/setup/curriculumbuilder.png'),
                icon: faUserCheck,
                modules: <Evaluations />,
            },
            {
                id: 'employee-evaluation-report',
                title: t('Employee Evaluation Report'),
                cardImage: require('../../assets/images/setup/curriculumbuilder.png'),
                icon: faClipboardUser,
                modules: <EmployeeEvaluationReport />,
            },
            {
                id: 'report-appraisal-group',
                title: t('Report Appraisal Group'),
                cardImage: require('../../assets/images/setup/curriculumbuilder.png'),
                icon: faClipboardUser,
                modules: <ReportAppraisalGroup />,
            },
            {
                id: 'evaluation-analysis-report',
                title: t('Evaluation Analysis Report'),
                cardImage: require('../../assets/images/setup/curriculumbuilder.png'),
                icon: faClipboardUser,
                modules: <EvaluationAnalysisReport />,
            },
            {
                id: 'topic-report',
                title: t('Topic Report'),
                cardImage: require('../../assets/images/setup/curriculumbuilder.png'),
                icon: faClipboardUser,
                modules: <TopicReport />,
            },
        ],
    }


    componentDidMount() {
        let getUrlParamData = getUrlParam()
        if (getUrlParamData) {
            if (getUrlParamData.activeTab) {
                let findNav = this.state.nav.find((v, i) => v.id == getUrlParamData.activeTab)
                this.setState({ activeTab: findNav })
            }
        }
    }

    render() {
        const { lang_orientation: dir } = this.props.defaultLanguage;

        let allNav = this.state.nav

        return (
            <Container fluid>
                <div id='iedu-layout'>
                    <NavBar />
                    <div id='page-content'>
                        <Header lite={true} heading={t('Evaluation')} backBtn={true} showCenterSelector={true} />
                        <div style={{ display: 'flex' }}>
                            <div className='inner-blue-bar'
                                style={{
                                    backgroundColor: changeColorOpacity(brand_colors[this.props.defaultTheme.theme_id].sidebar_bg, 0.15),
                                    marginRight: dir == 1 ? 0 : 7,
                                    marginLeft: dir == 0 ? 0 : 7,
                                }}>
                                <ul>
                                    {allNav.map((item, index) => (
                                        (ifNavAllowed(item.id)) &&
                                        <li key={index}>
                                            <button
                                                style={{ backgroundColor: item.id === this.state.activeTab.id ? changeColorOpacity(brand_colors[this.props.defaultTheme.theme_id].sidebar_bg, 0.5) : 'transparent' }}
                                                onClick={() => this.setState({ activeTab: item })}>
                                                <FontAwesomeIcon
                                                    icon={item.icon}
                                                    color={item.id === this.state.activeTab.id ? brand_colors[this.props.defaultTheme.theme_id].color8 : changeColorOpacity(brand_colors[this.props.defaultTheme.theme_id].sidebar_bg, 0.7)}
                                                />
                                                <div style={{
                                                    color: item.id === this.state.activeTab.id ? brand_colors[this.props.defaultTheme.theme_id].color8 : brand_colors[this.props.defaultTheme.theme_id].sidebar_bg
                                                }}>{t(item.title)}</div>
                                            </button>
                                        </li>
                                    )
                                    )}
                                </ul>
                            </div>
                            <div style={{ flex: 1, height: 'calc(100vh - 113px)', overflowY: 'auto', overflowX: 'hidden', paddingBottom: 10 }}>
                                <div>
                                    {this.state.activeTab.modules}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        );
    }
}

const mapStateToProps = (state) => ({
    authData: state.auth.authData,
    defaultLanguage: state.language.defaultLanguage,
    defaultTheme: state.theme.defaultTheme
});

export default connect(mapStateToProps, null)(EvaluationMenu);
