import React, { Component } from 'react';
import { Container, Card, Table, Row, Col, Spinner, Image, Badge, Button, Form, Offcanvas, } from 'react-bootstrap';
import { t } from '../../helpers/translation_helper';
import NavBar from '../_partials/NavBar/_NavBar';
import Header from '../_partials/Header/_Header';
import { connect } from 'react-redux';
import axios from 'axios';
import { toast } from 'react-toastify';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight, faCalendarAlt, faCheck, faClock, faEdit, faExternalLink, faFileAlt, faGear, faPaperclip, faTimes, faTrashCan, } from '@fortawesome/free-solid-svg-icons';
import { Scrollbar } from 'react-scrollbars-custom';
import imgDescription from '../../assets/images/description.png';
import { brand_colors } from '../../helpers/brand_colors_helper';
import SweetAlert from 'react-bootstrap-sweetalert';
import { GET, POST } from '../../api';

// API URL
const apiURL = process.env.REACT_APP_API_URL;

// Portal URL
const portalURL = process.env.REACT_APP_PORTAL_URL;

/**
 * Class Blog Component
 */
class ClassBlog extends Component {
  /**
   * Constructor
   */
  constructor(props) {
    super(props);
    this.state = {
      chatBlogListParams: {
        limit: 10,
        offset: 0,
        favorite: null,
        filterFavorite: (favorite = null) => this.setState({ chatBlogListParams: { ...this.state.chatBlogListParams, favorite: (this.state.chatBlogListParams.favorite == favorite) ? null : favorite } }),
        refreshing: false,
      },
      chatBlogList: [],
      chatBlogDetails: {
        data: [],
        offset: 0,
        limit: 10,
        refreshing: false,
        finished: false,
      },
      selectedBlog: null,
      msgSending: false,
      message: '',
      attachment: null,
      search: '',
      preferencesModal: {
        show: false,
        toggleModal: () => this.setState({
          preferencesModal: { ...this.state.preferencesModal, show: !this.state.preferencesModal.show },
          cb_image: (!this.state.preferencesModal.show && this.state.selectedBlog.cb_image) ? { data: { type: 'image' }, url: portalURL + this.state.selectedBlog.center_id + '/chat_blog/' + this.state.selectedBlog.cb_image } : null
        }),
        saving: false
      },
      dragAreaColor: {
        cb_image: brand_colors[props.defaultTheme.theme_id].color18,
        toggleColor: (field, value) => this.setState({ dragAreaColor: { ...this.state.dragAreaColor, [field]: value } })
      },
      deleteModal: { show: false, id: 0, action: () => { }, title: null, desc: null },
      saving: false,
      cb_image: null,

    }
    this.chatEnd = React.createRef();
    this.cb_image_ref = React.createRef();
  }

  /**
   * Component Did Mount
   */
  componentDidMount() {
    this.getListChatBlogs();
  }


  updateChatBlog = async () => {
    this.setState({ saving: true })
    const { auth_key, user_id } = this.props.authData.loginData;
    const { center_id, center_timezone } = this.props.authData.centerData;
    const { selectedBlog, cb_image } = this.state
    const fData = new FormData();
    fData.append('cb_id', selectedBlog.cb_id)
    fData.append('cb_description', selectedBlog.cb_description)
    fData.append('cb_name', selectedBlog.cb_name)
    fData.append('favorite', selectedBlog.favorite)
    fData.append('status', (selectedBlog.status) ? 1 : 0)
    fData.append('user_id', user_id)
    fData.append('center_id', center_id)
    fData.append('center_timezone', center_timezone)
    fData.append('chat_blog_preferences', JSON.stringify(selectedBlog.chat_blog_preferences))

    if (cb_image && cb_image.data) {
      fData.append('cb_image', cb_image.data)
    }
    try {
      const res = await axios.post(
        apiURL + 'dashboard/update-chat-blog',
        fData,
        {
          params: {
            auth_key: auth_key,
            appname: process.env.REACT_APP_NAME,
          },
        }
      );
      let resData = res.data

      if (resData.type) {
        let chatBlogList = this.state.chatBlogList
        chatBlogList = chatBlogList.map((v, i) => {
          if (v.cb_id == selectedBlog.cb_id) {
            return { ...selectedBlog, cb_image: resData.data.cb_image }
          }
          return v
        })
        this.setState({
          chatBlogList: chatBlogList,
          saving: false,
          selectedBlog: { ...selectedBlog, cb_image: resData.data.cb_image },
          preferencesModal: { ...this.state.preferencesModal, show: false }
        });
      }
      this.setState({
        saving: false
      });
    } catch (err) {
      console.log('err', err)
      toast.error(t('Something went wrong while fetching class blog!'));

    }
  }


  /**
   * Get List Chat Blogs
   */
  getListChatBlogs = async () => {
    let chatBlogListParams = this.state.chatBlogListParams
    const { auth_key, user_id } = this.props.authData.loginData;
    const { employee_id } = this.props.authData.userData;
    const { class_id } = this.props.selectedClass.class;
    const { academic_classmain_subject } = this.props.selectedClass;

    const fData = new FormData();
    fData.append('class_id', class_id);
    // fData.append('academic_classmain_subject', JSON.stringify(academic_classmain_subject));
    fData.append('acs_id', (this.props.selectedSubject) ? this.props.selectedSubject.acs_id : null);
    fData.append('user_id', user_id);
    fData.append('employee_id', employee_id);
    fData.append('search', '');
    fData.append('search_favorites', '');
    fData.append('blog_limit', this.state.chatBlogListParams.limit);
    fData.append('blog_offset', this.state.chatBlogListParams.offset);
    if (this.state.chatBlogListParams.favorite) {
      fData.append('favorite', this.state.chatBlogListParams.favorite);
    }

    try {
      const res = await POST(
        'dashboard/get-list-chat-blogs',
        fData,
        {

        }
      );
      this.setState({
        chatBlogList: res.data.data,
        chatBlogListParams: { ...chatBlogListParams, refreshing: false }
      });
    } catch (err) {
      console.log('err', err)
      toast.error(t('Something went wrong while fetching class blog!'));

    }
  };

  /**
   * Render Chat Blog List
   * @returns {HTMLElement}
   */
  renderChatBlogList = () => {
    const { defaultTheme } = this.props;
    const { chatBlogList, chatBlogListParams } = this.state;
    return chatBlogList.map((item, index) => {
      if (
        this.state.search == '' ||
        ((item.cb_name && ((item.cb_name).toLowerCase()).includes((this.state.search).toLowerCase()))) ||
        ((item.acs_name && ((item.acs_name).toLowerCase()).includes((this.state.search).toLowerCase()))) ||
        ((item.asl_name && ((item.asl_name).toLowerCase()).includes((this.state.search).toLowerCase()))) ||
        ((item.cb_description && ((item.cb_description).toLowerCase()).includes((this.state.search).toLowerCase()))
        )) {

        if (chatBlogListParams.favorite != null && chatBlogListParams.favorite != item.favorite) {
          return null
        }

        let favColor = null
        if (item.favorite == 1) {
          favColor = brand_colors[defaultTheme.theme_id].color1
        }
        if (item.favorite == "2") {
          favColor = brand_colors[defaultTheme.theme_id].color13
        }
        if (item.favorite == "3") {
          favColor = brand_colors[defaultTheme.theme_id].color16
        }
        if (item.favorite == "4") {
          favColor = brand_colors[defaultTheme.theme_id].color11
        }

        let hasImage = null
        if (item.cb_image !== '' && item.cb_image !== null) {
          hasImage = portalURL + item.center_id + '/chat_blog/' + item.cb_image
        } else if (item.asl_attach !== '' && item.asl_attach !== null) {
          hasImage = portalURL + item.center_id + '/academic_subject_lessons/' + item.asl_attach
        } else if (item.acs_image !== '' && item.acs_image !== null) {
          hasImage = portalURL + item.center_id + '/academic_classmain_subject/' + item.acs_image
        } else {
          hasImage = 'https://via.placeholder.com/80?text=No+Thumb'
        }

        return (
          <div
            key={index}
            className='chat-blog-item d-flex'
            onClick={() => this.handleSelectedBlog(item)}
            style={{ margin: 10 }}>
            <div>
              <Image src={hasImage} thumbnail={true} width={80} />
            </div>
            <div className='ps-2'>
              <div className='d-flex flex-column pb-1'>
                <div style={{ fontSize: 13, fontWeight: 'bold', color: brand_colors[defaultTheme.theme_id].color9, opacity: 0.7 }} title={item.cb_description}>{item.cb_name}</div>
                <div style={{ fontSize: 13, color: brand_colors[defaultTheme.theme_id].color9, opacity: 0.7 }}>{item.acs_name}</div>
                <div style={{ fontSize: 11, color: brand_colors[defaultTheme.theme_id].color17, opacity: 0.6 }}>
                  <FontAwesomeIcon icon={faClock} />{' '}
                  {(item.cb_datetime_updated) ? moment(item.cb_datetime_updated).format('DD-MM-YYYY - h:mm a') : moment(item.cb_datetime).format('DD-MM-YYYY - h:mm a')}
                </div>
              </div>
              <div className='d-flex flex-row' >
                <Badge bg={item.status == 0 ? 'danger' : 'success'}>
                  {item.status == 0 ? t('INACTIVE') : t('ACTIVE')}
                </Badge>
                <div className='d-flex flex-row' style={{ marginLeft: 10 }}>
                  {favColor &&
                    <div style={{ backgroundColor: favColor, height: 20, width: 20, cursor: 'pointer', borderRadius: 5 }}></div>
                  }
                </div>
              </div>
            </div>
          </div>
        );
      }
      return null
    });
  };

  /**
   * Handle Selected Blog
   * @param {Object} selectedBlog
   */
  handleSelectedBlog = (selectedBlog) => {
    this.setState({ selectedBlog, chatBlogDetails: { ...this.state.chatBlogDetails, data: [], offset: 0, refreshing: false, finished: false } }, () => {
      this.getChatBlogDetails(selectedBlog.cb_id);
    });
  };

  /**
   * Get Chat Blog Details
   * @param {Number} cb_id
   */
  getChatBlogDetails = async (cb_id = this.state.selectedBlog.cb_id) => {
    let chatBlogDetails = this.state.chatBlogDetails

    console.log('chatBlogDetails', chatBlogDetails);
    if (chatBlogDetails.refreshing || chatBlogDetails.finished) {
      return false
    }

    this.setState({ chatBlogDetails: { ...chatBlogDetails, refreshing: true } }, async () => {
      const { auth_key, user_id } = this.props.authData.loginData;
      const { center_id, center_timezone } = this.props.authData.centerData;

      const fData = new FormData();
      fData.append('cb_id', cb_id);
      fData.append('user_id', user_id);
      fData.append('center_id', center_id);
      fData.append('chat_blog_details_limit', chatBlogDetails.limit);
      fData.append('chat_blog_details_offset', chatBlogDetails.offset);

      if (this.getChatBlogDetailsReq) {
        this.getChatBlogDetailsReq.abort();
      }
      this.getChatBlogDetailsReq = new AbortController();

      try {
        const res = await POST(
          'dashboard/get-chat-blog-details',
          fData,
          {
            signal: this.getChatBlogDetailsReq.signal,
          }
        );
        let data = res.data.data

        this.setState({ chatBlogDetails: { ...chatBlogDetails, data: ((data).reverse()).concat(chatBlogDetails.data), offset: chatBlogDetails.offset + chatBlogDetails.limit, refreshing: false, finished: (data.length < chatBlogDetails.limit) ? true : false }, }, () => {
          if (chatBlogDetails.offset == 0) {
            this.chatEnd.current.scrollToBottom();
          }
        });
      } catch (err) {
        console.log('err', err)
        toast.error(t('Something went wrong while fetching class blog!'));

      }
    })
  };

  scrollToBottom = () => {
    this.chatEnd.current.scrollIntoView({ behavior: 'smooth' });
  };

  /**
   * Render Chat Message
   * @returns {HTMLElement}
   */
  renderChatMessage = () => {
    const { defaultTheme } = this.props;
    const { chatBlogDetails, selectedBlog } = this.state;
    if (selectedBlog == null) {
      return null
    }
    return <div>
      <div className='d-flex flex-row justify-content-center align-items-center'>  {chatBlogDetails.refreshing &&
        <Spinner as='span' animation='grow' size='sm' role='status' aria-hidden='true' />
      }
        {chatBlogDetails.finished &&
          <div
            className='chat-message-message'
            style={{
              borderRadius: 15,
              backgroundColor: brand_colors[defaultTheme.theme_id].color19,
            }}>
            {t('Start of the Blog Conversation')}
          </div>
        }
      </div>
      {chatBlogDetails.data.map((item, index) => {
        return (
          <div
            key={index}
            className='chat-message'
            style={{
              textAlign: item.isOther ? 'left' : 'right',
              padding: '0 10px'
            }}>
            <div className='chat-message-name text-muted'>
              {item.display_name}
            </div>
            <div
              className='chat-message-message'
              style={{
                borderTopLeftRadius: item.isOther ? 0 : 15,
                borderTopRightRadius: item.isOther ? 15 : 0,
                backgroundColor: item.isOther
                  ? ''
                  : 'rgb(226 243 228)',
              }}>
              {item.message}
              {item.attachment && (
                <div>
                  <a
                    href={
                      portalURL +
                      item.center_id +
                      '/chat_blog/' +
                      item.attachment
                    }
                    style={{
                      textDecoration: 'none',
                      color: '#6c757d',
                      fontSize: 13,
                    }}
                    target='_blank'>
                    <FontAwesomeIcon icon={faPaperclip} />{' '}
                    {item.attachment}
                  </a>
                </div>
              )}
            </div>
            <div
              className='chat-message-timestamp'
              style={{
                paddingLeft: item.isOther ? 10 : 0,
                paddingRight: item.isOther ? 0 : 10,
              }}>
              <span title={moment(item.cbd_datetime).format('DD-MM-YYYY - h:mm a')}>{moment(item.cbd_datetime).fromNow(selectedBlog.cb_datetime)}</span>
            </div>
          </div>
        );
      })}
    </div>
  };

  /**
   * Send Message
   */
  sendMessage = async () => {
    this.setState({ msgSending: true });
    const { auth_key, user_id, full_name, role_id } =
      this.props.authData.loginData;
    const { center_id, center_timezone } = this.props.authData.centerData;
    const { selectedBlog, message, attachment } = this.state;

    const fData = new FormData();
    fData.append('user_id', user_id);
    fData.append('full_name', full_name);
    fData.append('role_id', role_id);
    fData.append('center_id', center_id);
    fData.append('center_timezone', center_timezone);
    fData.append('cb_id', selectedBlog.cb_id);
    fData.append('chat_blog_message', message);
    fData.append('chat_blog_file', attachment);

    try {
      const res = await POST(
        'dashboard/send-chat-blog-details',
        fData,
        {

        }
      );
      if (res) {
        console.log('res', res);
        let resData = res.data
        if (!resData.type) {
          toast.error(resData.message);
          this.setState({ msgSending: false });
        } else {
          let chatBlogDetails = this.state.chatBlogDetails
          this.setState({
            message: '',
            attachment: null,
            msgSending: false,
            chatBlogDetails: { ...chatBlogDetails, data: chatBlogDetails.data.concat([resData.data]) }
          }, () => this.chatEnd.current.scrollToBottom());
          // this.setState({
          //   message: '',
          //   attachment: null,
          //   msgSending: false,
          //   chatBlogDetails: {
          //     ...this.state.chatBlogDetails, finished: false, refreshing: false, offset: 0,
          //     limit: 10, data: []
          //   }
          // }, () => {
          //   this.getChatBlogDetails(selectedBlog.cb_id);
          // });
        }
      }
    } catch (err) {
      console.log('err', err)
      toast.error(t('Something went wrong while sending message!'));

      this.setState({ msgSending: false });
    }
  };

  setStateChatBlog = (field, value) => {
    if (this.setStateTimer) {
      clearTimeout(this.setStateTimer)
    }
    this.setStateTimer = setTimeout(() => {
      this.setState({ selectedBlog: { ...this.state.selectedBlog, [field]: value } })
    })
  }


  /**
 * Preview Upload File
 * @returns
 */
  readFile = (selectedFile) => {
    return new Promise(function (resolve, reject) {
      let reader = new FileReader();
      const allowedFiles = ['application/pdf', 'image/jpeg', 'image/jpg', 'image/png', 'audio/x-m4a', 'audio/mp3', 'audio/mp4'];
      const allowedFilesDisplay = ['PDF', 'Image'];
      const allowedSize = 5000000;

      if (selectedFile.size) {

        if (selectedFile.size >= allowedSize) {
          toast.error(t('Max file size allowed is ' + allowedSize / 1000000 + ' MB!'));
          resolve({ type: 'error' });
          return;
        } else if (!allowedFiles.includes(selectedFile.type)) {
          toast.error(t('Allowed file type are ' + allowedFilesDisplay.toString()));
          resolve({ type: 'error' });
          return;
        } else {
          if (selectedFile.type.includes('image')) {
            reader.readAsDataURL(selectedFile);
          } else if (selectedFile.type.includes('audio')) {
            reader.readAsArrayBuffer(selectedFile);
          } else {
            reader.readAsText(selectedFile);
          }
        }

        reader.onload = async (readerEvent) => {
          resolve({ type: 'success', data: selectedFile, url: readerEvent.target.result });
        };
        reader.onerror = (error) => {
          resolve({ type: 'error' });
        };
      } else {
        resolve({ type: 'error' });
      }
    });
  };
  addAttachment = (e) => {
    const { defaultTheme } = this.props;
    const selectedFiles = e.target.files;
    let allFiles = [];

    for (let i = 0; i < selectedFiles.length; i++) {
      allFiles.push(this.readFile(selectedFiles[i]));
    }

    Promise.all(allFiles).then((values) => {
      let attachments = e.target.multiple ? this.state[e.target.name] : null;
      let selectedFiles = values;
      selectedFiles.map((v, i) => {
        if (v.data.type) {
          if (e.target.multiple) {
            attachments.push({ url: v.url, data: v.data });
          } else {
            attachments = { url: v.url, data: v.data };
          }
        }
      });
      this.setState({ [e.target.name]: attachments, dragAreaColor: { ...this.state.dragAreaColor, [e.target.name]: brand_colors[defaultTheme.theme_id].color18 } });
    });
  };

  deleteCbImage = (field, actionFun = null) => {
    let selectedBlog = this.state.selectedBlog
    if (selectedBlog.cb_id != null) {
      this.setState({
        deleteModal: {
          ...this.state.deleteModal, show: true, action: () => {
            // DELETE FILE CALL 
            try {
              GET('dashboard/delete-chat-blog-image', {
                params: {
                  key: selectedBlog.cb_id,
                  field: field,
                },
              }).then((res) => {
                if (res) {
                  if (actionFun) {
                    actionFun()
                  }
                  selectedBlog[field] = null

                  this.setState({
                    deleteModal: {
                      ...this.state.deleteModal, show: false
                    },
                    selectedBlog,
                    cb_image: null
                  });
                } else {
                  toast.error(t('Something went wrong while deleting!'));
                }
              })
            } catch (err) {
              console.log('err', err)
              toast.error(t('Something went wrong while deleting!'));

            }
          }
        }
      })
    } else {
      selectedBlog[field] = null
      this.setState({ [field]: null, selectedBlog, cb_image: null });
      if (actionFun) {
        actionFun()
      }
    }
  }

  /**
   * Render Component
   * @returns {HTMLElement}
   */
  render() {
    const { defaultTheme } = this.props;
    // State
    const {
      chatBlogList,
      selectedBlog,
      msgSending,
      message,
      search,
      preferencesModal,
      chatBlogListParams
    } = this.state;
    const dir = this.props.defaultLanguage.lang_orientation

    let favColor = null
    if (selectedBlog) {
      if (selectedBlog.favorite == 1) {
        favColor = brand_colors[defaultTheme.theme_id].color1
      }
      if (selectedBlog.favorite == "2") {
        favColor = brand_colors[defaultTheme.theme_id].color13
      }
      if (selectedBlog.favorite == "3") {
        favColor = brand_colors[defaultTheme.theme_id].color16
      }
      if (selectedBlog.favorite == "4") {
        favColor = brand_colors[defaultTheme.theme_id].color11
      }
    }

    let selectedBlogHasImage = null
    if (selectedBlog) {
      if (selectedBlog.cb_image !== '' && selectedBlog.cb_image !== null) {
        selectedBlogHasImage = portalURL + selectedBlog.center_id + '/chat_blog/' + selectedBlog.cb_image
      } else if (selectedBlog.asl_attach !== '' && selectedBlog.asl_attach !== null) {
        selectedBlogHasImage = portalURL + selectedBlog.center_id + '/academic_subject_lessons/' + selectedBlog.asl_attach
      } else if (selectedBlog.acs_image !== '' && selectedBlog.acs_image !== null) {
        selectedBlogHasImage = portalURL + selectedBlog.center_id + '/academic_classmain_subject/' + selectedBlog.acs_image
      } else {
        selectedBlogHasImage = 'https://via.placeholder.com/80?text=No+Thumb'
      }
    }

    return (
      <Container fluid>
        <div id='iedu-layout'>
          <NavBar />
          <div id='page-content'>
            <Header lite={true} heading={t('Class Blog')} backBtn={true} classSelectorFunc={[this.getListChatBlogs]} subjectSelectorFunc={[this.getListChatBlogs]} showClassSelector={true} showSubjectSelector={true} />
            <div className='grey-section'>
              <Row>
                <Col md={3}>
                  <div className='d-flex flex-row' style={{
                    padding: 5, marginBottom: 10, backgroundColor: brand_colors[defaultTheme.theme_id].color8, borderRadius: 5,
                  }}>
                    <Form.Control
                      type='text'
                      placeholder={t('Search...')}
                      onChange={(e) => this.setState({ search: e.target.value })}
                      style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                    />
                    <div className='d-flex flex-row flex-wrap' style={{ width: 55, borderTopRightRadius: 10, borderBottomRightRadius: 10, overflow: 'hidden' }}>
                      <div className="d-flex justify-content-center align-items-center" style={{ backgroundColor: brand_colors[defaultTheme.theme_id].color1, height: 20, width: 20, cursor: 'pointer' }} title="Favorite Blue" onClick={() => chatBlogListParams.filterFavorite(1)}>
                        {chatBlogListParams.favorite == 1 &&
                          <FontAwesomeIcon icon={faCheck} style={{ fontSize: 12 }} />}
                      </div>
                      <div className="d-flex justify-content-center align-items-center" style={{ backgroundColor: brand_colors[defaultTheme.theme_id].color13, height: 20, width: 20, cursor: 'pointer' }} title="Favorite Green" onClick={() => chatBlogListParams.filterFavorite(2)}>
                        {chatBlogListParams.favorite == 2 &&
                          <FontAwesomeIcon icon={faCheck} style={{ fontSize: 12 }} />}
                      </div>
                      <div className="d-flex justify-content-center align-items-center" style={{ backgroundColor: brand_colors[defaultTheme.theme_id].color16, height: 20, width: 20, cursor: 'pointer' }} title="Favorite Yellow" onClick={() => chatBlogListParams.filterFavorite(3)}>
                        {chatBlogListParams.favorite == 3 &&
                          <FontAwesomeIcon icon={faCheck} style={{ fontSize: 12 }} />}
                      </div>
                      <div className="d-flex justify-content-center align-items-center" style={{ backgroundColor: brand_colors[defaultTheme.theme_id].color11, height: 20, width: 20, cursor: 'pointer' }} title="Favorite Red" onClick={() => chatBlogListParams.filterFavorite(4)}>
                        {chatBlogListParams.favorite == 4 &&
                          <FontAwesomeIcon icon={faCheck} style={{ fontSize: 12 }} />}
                      </div>
                    </div>
                  </div>
                  <Scrollbar style={{ height: '65vh', backgroundColor: brand_colors[defaultTheme.theme_id].color8 }} className='border-0 chat-blog-list'>
                    {chatBlogListParams.refreshing ? (
                      <div
                        className='d-flex align-items-center justify-content-center'
                        style={{
                          flexDirection: 'column',
                        }}>
                        <div className='mb-1'>
                          <Spinner
                            animation='grow'
                            variant='dark'
                          />
                        </div>
                        {t(
                          'Fetching class blogs list...'
                        )}
                      </div>
                    ) : (
                      <>
                        {chatBlogList.length > 0 ? (
                          this.renderChatBlogList()
                        ) : (
                          <div
                            className='d-flex align-items-center justify-content-center'
                            style={{
                              flexDirection:
                                'column',
                              height: 400,
                            }}>
                            {t(
                              'No class blogs list found!'
                            )}
                          </div>
                        )}
                      </>
                    )}
                  </Scrollbar>
                </Col>
                <Col md={9}>
                  {selectedBlog &&
                    <Card
                      className='border-0 card-blog-details'
                      style={{
                        borderTopRightRadius: 20,
                        borderBottomRightRadius: 20,
                        height: '73vh',
                      }}>
                      <Card.Header>
                        <div className='py-2'>
                          <div className='d-flex justify-content-between' >
                            <div className='d-flex'>
                              <div>
                                {selectedBlog.cb_image &&
                                  <Image src={portalURL + selectedBlog.center_id + '/chat_blog/' + selectedBlog.cb_image} thumbnail={true} width={80}
                                  />
                                }
                                {!selectedBlog.cb_image && selectedBlog.asl_attach &&
                                  <Image src={portalURL + selectedBlog.center_id + '/academic_subject_lessons/' + selectedBlog.asl_attach} thumbnail={true} width={80}
                                  />
                                }
                                {!selectedBlog.asl_attach && selectedBlog.acs_image &&
                                  <Image src={portalURL + selectedBlog.center_id + '/academic_classmain_subject/' + selectedBlog.acs_image} thumbnail={true} width={80}
                                  />
                                }
                              </div>
                              <div className='ps-2'>
                                <div className='d-flex flex-column pb-1'>
                                  <div style={{ fontSize: 13, fontWeight: 'bold', color: brand_colors[defaultTheme.theme_id].color9, opacity: 0.7 }} title={selectedBlog.cb_description}>{selectedBlog.cb_name}</div>
                                  <div style={{ fontSize: 13, color: brand_colors[defaultTheme.theme_id].color9, opacity: 0.7 }}>{selectedBlog.acs_name}</div>
                                  <div style={{ fontSize: 11, color: brand_colors[defaultTheme.theme_id].color17, opacity: 0.6 }}>
                                    <FontAwesomeIcon icon={faClock} />{' '}
                                    {(selectedBlog.cb_datetime_updated) ? moment(selectedBlog.cb_datetime_updated).format('DD-MM-YYYY - h:mm a') : moment(selectedBlog.cb_datetime).format('DD-MM-YYYY - h:mm a')}
                                  </div>
                                </div>
                                <div className='d-flex flex-row' >
                                  <Badge bg={selectedBlog.status == 0 ? 'danger' : 'success'}>
                                    {selectedBlog.status == 0 ? t('INACTIVE') : t('ACTIVE')}
                                  </Badge>
                                  <div className='d-flex flex-row' style={{ marginLeft: 10 }}>
                                    {favColor &&
                                      <div style={{ backgroundColor: favColor, height: 20, width: 20, borderRadius: 5 }}></div>
                                    }
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div>
                              <div onClick={() => preferencesModal.toggleModal()}>
                                <FontAwesomeIcon icon={faGear} className='cursor-pointer' style={{ color: brand_colors[defaultTheme.theme_id].color16, fontSize: 16 }} title={t('Edit Preferences')} />
                              </div>
                            </div>
                          </div>
                        </div>
                      </Card.Header>
                      <Card.Body>
                        <Scrollbar ref={this.chatEnd} className='chat-blog-details' onScroll={(scrollValues, prevScrollValues) => {
                          if (this.chatEnd.current.scrollTop < 20) {
                            this.getChatBlogDetails()
                          }
                        }}>
                          {this.renderChatMessage()}
                        </Scrollbar>
                      </Card.Body>
                      {selectedBlog && (
                        <Card.Footer>
                          <div
                            style={{ gap: 10, display: 'flex', padding: '10px 0', }}>
                            <div
                              style={{ fontSize: 20, display: 'flex', alignItems: 'center', }}>
                              <FontAwesomeIcon icon={faPaperclip} />
                            </div>
                            <div style={{ flex: 1 }}>
                              <Form.Control type='text' placeholder={t('Type your message here...')}
                                onChange={(e) => this.setState({ message: e.target.value, })}
                                value={message}
                              />
                            </div>
                            <div>
                              <Button disabled={msgSending}
                                onClick={() => this.sendMessage()}>
                                {t('SEND')}
                              </Button>
                            </div>
                          </div>
                        </Card.Footer>
                      )}
                    </Card>
                  }
                </Col>
              </Row>
            </div>
          </div>
        </div>



        <Offcanvas show={preferencesModal.show} style={{ height: '100%' }} placement='bottom' onHide={preferencesModal.toggleModal}>
          {selectedBlog &&
            <Offcanvas.Header style={{ marginTop: '1rem', marginRight: '1rem', marginLeft: '1rem', marginBottom: 0 }}>
              <div className='d-flex flex-row  align-items-center justify-content-center'>
                <span className='d-flex align-items-center justify-content-center btn-back-white' onClick={preferencesModal.toggleModal}>
                  <FontAwesomeIcon icon={(dir == 1) ? faAngleRight : faAngleLeft} color={brand_colors[defaultTheme.theme_id].color9} />
                </span>
                <Offcanvas.Title>
                  <div className='d-flex flex-row align-items-center justify-content-center title-back-color8' >
                    <div className='avatar'>
                      <Image src={
                        selectedBlog.cb_image
                          ? portalURL + selectedBlog.center_id + '/chat_blog/' + selectedBlog.cb_image
                          : 'https://via.placeholder.com/50?text=No+Thumb'
                      } thumbnail={true} width="38" height="38"
                      />
                    </div>
                    <span style={{ fontSize: 20, margin: '0 10px' }} title={selectedBlog.cb_description}>{selectedBlog.cb_name}</span>
                  </div>
                </Offcanvas.Title>
              </div>
              <div className='custom-styled-form'>
                <div className='d-flex flex-row justify-content-between align-items-end'>
                  <Button disabled={this.state.preferencesModal.saving} variant={'warning'} onClick={() => this.updateChatBlog()}>
                    {this.state.saving &&
                      <Spinner as='span' animation='grow' size='sm' role='status' aria-hidden='true' />
                    }
                    {t('Update')}
                  </Button>
                </div>
              </div>
            </Offcanvas.Header>
          }
          {selectedBlog &&
            <Offcanvas.Body>
              <div
                className={'d-flex flex-row grey-section'}
                style={{
                  overflow: 'initial',
                  backgroundImage: 'url(https://academic.educore.io/static/media/pegboard-bg.9b84e4936acef29bbcd9.png)',
                  backgroundRepeat: 'repeat',
                  backgroundPosition: '10px 12px',
                  overflow: 'hidden',
                }}
              >
                <Col className='custom-styled-form hide-scrollbar' style={{ backgroundColor: brand_colors[defaultTheme.theme_id].color8, marginLeft: 10, marginRight: 10, borderRadius: '20px', padding: '1rem', overflow: 'auto', height: '70vh' }}>

                  <Row>
                    <Col md="8">
                      <Form.Group className='mb-3'>
                        <Form.Label>
                          <img src={imgDescription} />
                          {t('Name')}
                        </Form.Label>
                        <Form.Control type='text' defaultValue={selectedBlog.cb_name} onBlur={(event) => this.setStateChatBlog('cb_name', event.target.value)} readOnly />
                      </Form.Group>
                      <Form.Group className='mb-3'>
                        <Form.Label>
                          <img src={imgDescription} />
                          {t('Description')}
                        </Form.Label>
                        <Form.Control as="textarea" rows={3} defaultValue={selectedBlog.cb_description || ''} onBlur={(event) => this.setStateChatBlog('cb_description', event.target.value)} />
                      </Form.Group>
                      <div className='d-flex flex-row justify-content-around align-items-center'>
                        <div className='d-flex flex-column' style={{}}>
                          <span>
                            {t('Blog Color')}
                          </span>
                          <div className='d-flex flex-row flex-wrap' style={{}}>
                            <div style={{ backgroundColor: (selectedBlog.favorite == 1) ? brand_colors[defaultTheme.theme_id].color1 : brand_colors[defaultTheme.theme_id].color8, borderColor: (selectedBlog.favorite == 1) ? brand_colors[defaultTheme.theme_id].color8 : brand_colors[defaultTheme.theme_id].color1, borderWidth: (selectedBlog.favorite == 1) ? 0 : 3, borderStyle: 'solid', height: 20, width: 20, cursor: 'pointer' }} title="Favorite Blue" onClick={() => this.setStateChatBlog('favorite', 1)}></div>
                            <div style={{ backgroundColor: (selectedBlog.favorite == "2") ? brand_colors[defaultTheme.theme_id].color13 : brand_colors[defaultTheme.theme_id].color8, borderColor: (selectedBlog.favorite == "2") ? brand_colors[defaultTheme.theme_id].color8 : brand_colors[defaultTheme.theme_id].color13, borderWidth: (selectedBlog.favorite == "2") ? 0 : 3, borderStyle: 'solid', height: 20, width: 20, cursor: 'pointer' }} title="Favorite Green" onClick={() => this.setStateChatBlog('favorite', 2)}></div>
                            <div style={{ backgroundColor: (selectedBlog.favorite == "3") ? brand_colors[defaultTheme.theme_id].color16 : brand_colors[defaultTheme.theme_id].color8, borderColor: (selectedBlog.favorite == "3") ? brand_colors[defaultTheme.theme_id].color8 : brand_colors[defaultTheme.theme_id].color16, borderWidth: (selectedBlog.favorite == "3") ? 0 : 3, borderStyle: 'solid', height: 20, width: 20, cursor: 'pointer' }} title="Favorite Yellow" onClick={() => this.setStateChatBlog('favorite', 3)}></div>
                            <div style={{ backgroundColor: (selectedBlog.favorite == "4") ? brand_colors[defaultTheme.theme_id].color11 : brand_colors[defaultTheme.theme_id].color8, borderColor: (selectedBlog.favorite == "4") ? brand_colors[defaultTheme.theme_id].color8 : brand_colors[defaultTheme.theme_id].color11, borderWidth: (selectedBlog.favorite == "4") ? 0 : 3, borderStyle: 'solid', height: 20, width: 20, cursor: 'pointer' }} title="Favorite Red" onClick={() => this.setStateChatBlog('favorite', 4)}></div>
                          </div>
                        </div>
                        <div className="d-flex flex-column col-md-4 mb-1 align-items-center">
                          <Form.Label className='no-style'>
                            {t('Status')}
                          </Form.Label>
                          <Form.Check
                            type="switch"
                            value={1}
                            checked={selectedBlog.status}
                            onChange={(event) => {
                              this.setStateChatBlog('status', !selectedBlog.status)
                            }}
                          />
                        </div>
                      </div>
                    </Col>
                    <Col md="4">
                      <div style={{ flexDirection: 'column' }} className='pic-prev d-flex justify-content-center mb-3' id='form_section_2.3'>
                        <div style={{ height: 175, padding: 10, overflow: 'hidden', borderStyle: 'dashed', borderColor: this.state.dragAreaColor.cb_image, borderWidth: 2, borderRadius: 20, position: 'relative' }} className='d-flex justify-content-center align-items-center'
                          onDragEnter={(e) => this.handleDragFileArea(e, 'cb_image')} onDragLeave={(e) => this.handleDragFileArea(e, 'cb_image')} onDragOver={(e) => this.handleDragFileArea(e, 'cb_image')} onDrop={(e) => {

                            e.preventDefault();
                            e.stopPropagation();
                            if (e.dataTransfer.files && e.dataTransfer.files[0]) {
                              this.addAttachment({ target: { files: e.dataTransfer.files, multiple: false, name: 'cb_image' } })
                            }
                          }}>
                          {this.state.cb_image && (!this.state.cb_image.data.type.includes('pdf')) && (
                            <img src={this.state.cb_image.url} alt={t('Preview')} style={{ height: '100%' }} />
                          )}
                          {this.state.cb_image == null &&
                            <button onClick={() => this.cb_image_ref.current.click()} style={{ background: 'none', border: 0, flex: 1 }}>
                              <span style={{ fontSize: 20, fontWeight: 'bold', color: brand_colors[defaultTheme.theme_id].color18 }}>{t('Blog Logo')}</span>
                            </button>
                          }

                          {this.state.cb_image && (
                            <FontAwesomeIcon
                              icon={faTrashCan}
                              style={{ fontSize: 14, color: brand_colors[defaultTheme.theme_id].color11, position: 'absolute', bottom: 6, right: 6, cursor: 'pointer', backgroundColor: brand_colors[defaultTheme.theme_id].color8, borderRadius: 100, padding: 3 }}
                              onClick={() => this.deleteCbImage('cb_image')}
                            />
                          )}
                        </div>
                        <Form.Control className='d-none' ref={this.cb_image_ref} type='file' onChange={this.addAttachment} name='cb_image' />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div>
                        <span>
                          {t('Blocked Users')}
                        </span>
                        <div className='d-flex flex-row flex-wrap'>
                          {selectedBlog.chat_blog_preferences.map((v, i) => {
                            if (v.is_blocked == "0") {
                              return null
                            }
                            return <Badge bg="danger" style={{ margin: '0 10px 10px 0', cursor: 'pointer' }} onClick={() => {
                              let chat_blog_preferences = selectedBlog.chat_blog_preferences
                              chat_blog_preferences = chat_blog_preferences.filter((vv) => v.cbp_id != vv.cbp_id)
                              this.setState({ selectedBlog: { ...selectedBlog, chat_blog_preferences: chat_blog_preferences } })
                            }}>
                              <span style={{ fontSize: 16, marginRight: 5 }}>{v.full_name}</span>
                              <FontAwesomeIcon icon={faTimes} />
                            </Badge>
                          })}

                        </div>
                      </div>
                    </Col>
                  </Row>

                </Col>
              </div>
            </Offcanvas.Body>
          }
        </Offcanvas>
        <SweetAlert
          show={this.state.deleteModal.show}
          warning
          showCancel
          confirmBtnText={t('Yes, delete it!')}
          confirmBtnBsStyle='danger'
          title={(this.state.deleteModal.title) ? this.state.deleteModal.title : t('Are you sure?')}
          onConfirm={this.state.deleteModal.action}
          onCancel={() => this.setState({ deleteModal: { ...this.state.deleteModal, show: false, id: 0, action: () => { } } })}
          focusCancelBtn>
          {(this.state.deleteModal.desc) ? this.state.deleteModal.desc : t('Confirm Delete!')}
        </SweetAlert>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  authData: state.auth.authData,
  defaultLanguage: state.language.defaultLanguage,
  selectedClass: state.selectedClass.data,
  children: state.children.list,
  defaultTheme: state.theme.defaultTheme,
  selectedSubject: state.selectedClass.subject,
});

const mapDispatchToProps = () => ({
});
export default connect(mapStateToProps, mapDispatchToProps())(ClassBlog);
