import React, { Component } from 'react';
import {
  Container,
  Card,
  Row,
  Col,
  Button,
  Tab,
  Tabs,
  Table,
  Badge,
  Modal,
  InputGroup,
  Form,
} from 'react-bootstrap';
import 'chart.js/auto';
import Select from 'react-select';
import { Doughnut, Bar, Chart } from 'react-chartjs-2';
import { connect } from 'react-redux';
import { t } from '../../../helpers/translation_helper';
import { brand_colors } from '../../../helpers/brand_colors_helper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip as Tooltiper } from 'react-tooltip';
import {
  faDashboard,
  faUsers,
  faAddressCard,
  faUserTie,
  faChevronDown,
  faSchool,
  faInfoCircle,
  faEye,
  faXmark,
  faFileExcel,
} from '@fortawesome/free-solid-svg-icons';
import FullCalendar from '@fullcalendar/react'; // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin!
import interactionPlugin from '@fullcalendar/interaction';
import axios from 'axios';
import { toast } from 'react-toastify';
import { parse } from '@fortawesome/fontawesome-svg-core';
import { LoadingPlaceholder } from '../../_common/Loading/Loading';
import moment from 'moment';
import { faker } from '@faker-js/faker';
import { GET, POST } from '../../../api';
import { DateRangePicker } from 'react-date-range';
import { faCalendarAlt } from '@fortawesome/free-regular-svg-icons';
import { handleFormErrors } from '../../../helpers/form_helpers';
import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';

// API URL
const apiURL = process.env.REACT_APP_API_URL;
const doughnutChartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  cutout: 80,
  plugins: {
    legend: {
      position: 'bottom',
    },
  },
};

class SchoolAttendanceWidget extends Component {
  constructor(props) {
    super(props);
    this.state = {
      totalNotMarked: [],
      totalPresent: [],
      totalAbsent: [],
      chartData: null,
      widgetModal: {
        show: false,
        title: 'School Attendance',
        toggle: () =>
          this.setState({
            widgetModal: {
              ...this.state.widgetModal,
              show: !this.state.widgetModal.show,
            },
          }),
      },
    };
  }

  componentDidMount() {
    this.getData();
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.authData.centerData.center_id !==
      this.props.authData.centerData.center_id
    ) {
      this.getData();
    }
  }

  getData = async () => {
    this.setState({
      chartData: null,
      totalNotMarked: [],
      totalPresent: [],
      totalAbsent: [],
    });
    if (this.getDataReq) {
      this.getDataReq.abort();
    }
    this.getDataReq = new AbortController();

    const res = await GET('admin/report-total-attendance', {
      signal: this.getDataReq.signal,
    });

    if (res) {
      let resData = res.data;
      console.log('resData', resData);
      let chartData = {
        labels: [
          t('Total Present') +
          ' (' +
          (
            (resData.total_present.length /
              resData.total_registrations.length) *
            100
          ).toFixed(2) +
          '%)',
          t('Total Absent') +
          ' (' +
          (
            (resData.total_absent.length /
              resData.total_registrations.length) *
            100
          ).toFixed(2) +
          '%)',
          t('Total Not Marked') +
          ' (' +
          (
            (resData.total_not_marked.length /
              resData.total_registrations.length) *
            100
          ).toFixed(2) +
          '%)',
        ],
        datasets: [
          {
            data: [],
            backgroundColor: [
              brand_colors[this.props.defaultTheme.theme_id].color1,
              brand_colors[this.props.defaultTheme.theme_id].color2,
              brand_colors[this.props.defaultTheme.theme_id].color4,
            ],
          },
        ],
      };

      chartData.datasets[0].data = [
        parseInt(resData.total_present.length),
        parseInt(resData.total_absent.length),
        parseInt(resData.total_not_marked.length),
      ];

      this.setState({
        chartData,
        totalNotMarked: resData.total_not_marked,
        totalPresent: resData.total_present,
        totalAbsent: resData.total_absent,
      });
    }
  };

  exportExcel = async () => {
    const excelData = this.state.totalAbsent.map((item) => {
      return {
        'Class Name': item.class_theme,
        'ID Number': item.id_number,
        'Child Name': item.child_name,
        'Absent Reason': item.attendance?.absence_name,
      }
    });

    const fileName = 'School Attendance - Total Absents - ' + moment().format('DD-MM-YYYY');
    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const ws = XLSX.utils.json_to_sheet(excelData);

    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });

    FileSaver.saveAs(data, fileName + fileExtension);
  };

  render() {
    console.log('SchoolAttendanceWidget', this.state);
    let { chartData, totalAbsent, totalNotMarked, totalPresent, widgetModal } =
      this.state;
    return (
      <>
        <Card
          className='border-0'
          style={{ borderRadius: 20, marginBottom: 20 }}>
          <Card.Body>
            <Row>
              <Col>
                <div className='mb-2'>
                  <strong>{t(widgetModal.title)}</strong>
                </div>
              </Col>
              <Col md='auto'>
                <Button
                  variant='primary'
                  size='sm'
                  onClick={() => widgetModal.toggle()}
                  style={{ fontSize: 13, padding: '0 5px' }}>
                  {t('View Details')}
                </Button>
              </Col>
            </Row>
            <div>
              {chartData ? (
                <Doughnut
                  data={chartData}
                  options={doughnutChartOptions}
                  height={278}
                />
              ) : (
                <LoadingPlaceholder height={278} />
              )}
            </div>
          </Card.Body>
        </Card>
        {/* Detail Attendance Modal */}
        <Modal
          size='xl'
          show={widgetModal.show}
          onHide={() => widgetModal.toggle()}
          backdrop='static'
          keyboard={false}>
          <Modal.Header closeButton>
            <Modal.Title>{t(widgetModal.title)}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Tabs
              defaultActiveKey='total_present'
              id='att-tabs'
              className='mb-3 att-tabs-style'
              fill>
              <Tab
                eventKey='total_present'
                style={{}}
                title={t('Total Presents') + ' (' + totalPresent.length + ')'}>
                <Table>
                  <thead>
                    <tr>
                      <th width='34%'>{t('Class')}</th>
                      <th width='33%'>{t('ID Number')}</th>
                      <th width='33%'>{t('Child')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {totalPresent.map((item, index) => {
                      let backgroundColor = '#48CF48';
                      let title = 'Checked-In';

                      if (item.attendance.att_datetime_out != null) {
                        backgroundColor = 'orange';
                        title = 'Checked-Out';
                      }

                      return (
                        <tr key={index}>
                          <td>{item?.class_theme}</td>
                          <td>{item?.id_number}</td>
                          <td>
                            <span
                              title={t(title)}
                              style={{
                                float: 'left',
                                width: 16,
                                height: 16,
                                display: 'block',
                                backgroundColor,
                                borderRadius: 8,
                                marginRight: 10,
                                marginTop: 4,
                              }}></span>
                            {item?.child_name}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Tab>
              <Tab
                eventKey='total_absent'
                title={t('Total Absents') + ' (' + totalAbsent.length + ')'}>
                <Row>
                  <Col className='text-end'>
                    <Button onClick={() => this.exportExcel()}
                      variant='info' size="sm"><FontAwesomeIcon icon={faFileExcel} className='me-1' />{t('Export Excel')}</Button>
                  </Col>
                </Row>
                <Table>
                  <thead>
                    <tr>
                      <th width='25%'>{t('Class')}</th>
                      <th width='25%'>{t('ID Number')}</th>
                      <th width='25%'>{t('Child')}</th>
                      <th width='25%'>{t('Absent Reason')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {totalAbsent.map((item, index) => (
                      <tr key={index}>
                        <td>{item?.class_theme}</td>
                        <td>{item?.id_number}</td>
                        <td>{item?.child_name}</td>
                        <td>{item?.attendance?.absence_name}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Tab>
              <Tab
                eventKey='total_not_marked'
                title={
                  t('Total Not Marked') + ' (' + totalNotMarked.length + ')'
                }>
                <Table>
                  <thead>
                    <tr>
                      <th width='34%'>{t('Class')}</th>
                      <th width='33%'>{t('ID Number')}</th>
                      <th width='33%'>{t('Child')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {totalNotMarked.map((item, index) => (
                      <tr key={index}>
                        <td>{item?.class_theme}</td>
                        <td>{item?.id_number}</td>
                        <td>{item?.child_name}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Tab>
            </Tabs>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  authData: state.auth.authData,
  defaultLanguage: state.language.defaultLanguage,
  defaultTheme: state.theme.defaultTheme,
});

export default connect(mapStateToProps, null)(SchoolAttendanceWidget);
