/**
 * Brand Colors
 */

export const brand_colors = {
  'primary_theme': {
    color1: '#15bbe7',
    color2: '#ee3a81',
    color3: '#f1478b',
    color4: '#00d045',
    color5: '#18e05b',
    color6: '#3c63ae',
    color7: '#F2F2F2',
    color8: '#ffffff',
    color9: '#000000',
    color10: '#c9c9c9',
    color11: '#F45959',
    color12: '#45BCE8',
    color13: '#4DD951',
    color14: '#2E72FD',
    color15: '#154689',
    color16: '#F39400',
    color17: '#4A607B',
    color18: '#7b7b7b',
    color19: '#EDF9FF',
    color20: '#71A3C2',
    color21: '#E6F7FF',
    color22: '#8864ed',
    color23: '#E6F7FF',
    header_bg: '#F2F2F2',
    sidebar_bg: '#15bbe7',
    sidebar_hover: '#5ccef9',
    timeline_bg: '#00d045',
    color4_light: '#CCF6DA',
  },
  'purple_theme': {
    color1: '#15bbe7',
    color2: '#ee3a81',
    color3: '#f1478b',
    color4: '#00d045',
    color5: '#18e05b',
    color6: '#3c63ae',
    color7: '#F2F2F2',
    color8: '#ffffff',
    color9: '#000000',
    color10: '#c9c9c9',
    color11: '#F45959',
    color12: '#45BCE8',
    color13: '#4DD951',
    color14: '#2E72FD',
    color15: '#154689',
    color16: '#F39400',
    color17: '#4A607B',
    color18: '#7b7b7b',
    color19: '#EDF9FF',
    color20: '#71A3C2',
    color21: '#E6F7FF',
    color22: '#8864ed',
    color23: '#E6F7FF',
    header_bg: '#F2F2F2',
    sidebar_bg: '#6441c7',
    sidebar_hover: '#8864ed',
    timeline_bg: '#9fdb4a',
    color4_light: '#B3F1C7',
  },
  'navy_theme': {
    color1: '#15bbe7',
    color2: '#d466c6',
    color3: '#f1478b',
    color4: '#2d7ed2',
    color5: '#18e05b',
    color6: '#6441c7',
    color7: '#F2F2F2',
    color8: '#ffffff',
    color9: '#000000',
    color10: '#c9c9c9',
    color11: '#F45959',
    color12: '#45BCE8',
    color13: '#4DD951',
    color14: '#2E72FD',
    color15: '#154689',
    color16: '#F39400',
    color17: '#4A607B',
    color18: '#7b7b7b',
    color19: '#EDF9FF',
    color20: '#71A3C2',
    color21: '#E6F7FF',
    color22: '#00c0fe',
    color23: '#E6F7FF',
    header_bg: '#958ea1',
    sidebar_bg: '#0a2240',
    sidebar_hover: '#153d6e',
    timeline_bg: '#6441c7',
    color4_light: '#B3F1C7',
  }
};


export const colorsArray = [
  { color_code: 'rgb(253, 183, 90)', is_text_white: false },
  { color_code: 'rgb(67, 197, 252)', is_text_white: true },
  { color_code: 'rgb(255, 117, 117)', is_text_white: false },
  { color_code: 'rgb(139, 202, 152)', is_text_white: false },
  { color_code: 'rgb(119, 60, 241)', is_text_white: true },
  { color_code: 'rgb(212, 214, 39)', is_text_white: false },
  { color_code: 'rgb(240, 109, 2)', is_text_white: false },
  { color_code: 'rgb(210, 255, 232)', is_text_white: false },
  { color_code: 'rgb(255, 222, 222)', is_text_white: false },
  { color_code: 'rgb(255, 238, 217)', is_text_white: false },
  { color_code: 'rgb(204, 245, 248)', is_text_white: false },
  { color_code: 'rgb(226, 236, 255)', is_text_white: false },
  { color_code: 'rgb(230, 234, 238)', is_text_white: false },
  { color_code: 'rgb(203, 209, 49)', is_text_white: false },
  { color_code: 'rgb(160, 189, 146)', is_text_white: false },
  { color_code: 'rgb(162, 161, 114)', is_text_white: false },
  { color_code: 'rgb(181, 184, 214)', is_text_white: false },
  { color_code: 'rgb(203, 98, 73)', is_text_white: false },
  { color_code: 'rgb(181, 88, 225)', is_text_white: false },
  { color_code: 'rgb(162, 239, 178)', is_text_white: false },
  { color_code: 'rgb(152, 74, 185)', is_text_white: false },
  { color_code: 'rgb(163, 98, 90)', is_text_white: false },
  { color_code: 'rgb(208, 176, 241)', is_text_white: false },
  { color_code: 'rgb(196, 19, 22)', is_text_white: false },
  { color_code: 'rgb(178, 69, 10)', is_text_white: false },
  { color_code: 'rgb(183, 167, 210)', is_text_white: false },
  { color_code: 'rgb(228, 197, 202)', is_text_white: false },
  { color_code: 'rgb(246, 40, 19)', is_text_white: false },
  { color_code: 'rgb(184, 232, 236)', is_text_white: false },
  { color_code: 'rgb(141, 50, 94)', is_text_white: false },
  { color_code: 'rgb(171, 37, 194)', is_text_white: false },
  { color_code: 'rgb(120, 200, 105)', is_text_white: false },
  { color_code: 'rgb(198, 98, 90)', is_text_white: false },
  { color_code: 'rgb(71, 95, 123)', is_text_white: true },
  { color_code: 'rgb(57, 218, 138)', is_text_white: true },
  { color_code: 'rgb(0, 207, 221)', is_text_white: true },
  { color_code: 'rgb(67, 125, 236)', is_text_white: true },
  { color_code: 'rgb(97, 21, 66)', is_text_white: true },
  { color_code: 'rgb(109, 196, 225)', is_text_white: true },
  { color_code: 'rgb(116, 19, 61)', is_text_white: true },
  { color_code: 'rgb(0, 72, 242)', is_text_white: true },
  { color_code: 'rgb(6, 202, 8)', is_text_white: true },
  { color_code: 'rgb(10, 52, 66)', is_text_white: true },
  { color_code: 'rgb(33, 5, 236)', is_text_white: true },
  { color_code: 'rgb(48, 48, 177)', is_text_white: true },
  { color_code: 'rgb(94, 139, 108)', is_text_white: true },
  { color_code: 'rgb(111, 226, 58)', is_text_white: true },
  { color_code: 'rgb(48, 124, 239)', is_text_white: true },
  { color_code: 'rgb(7, 98, 200)', is_text_white: true },
  { color_code: 'rgb(41, 188, 175)', is_text_white: true },
  { color_code: 'rgb(106, 141, 83)', is_text_white: true },
  { color_code: 'rgb(36, 146, 153)', is_text_white: true },
  { color_code: 'rgb(10, 211, 78)', is_text_white: true },
  { color_code: 'rgb(16, 210, 42)', is_text_white: true },
  { color_code: 'rgb(123, 55, 117)', is_text_white: true },
  { color_code: 'rgb(129, 44, 20)', is_text_white: true },
  { color_code: 'rgb(97, 173, 213)', is_text_white: true },
  { color_code: 'rgb(66, 153, 193)', is_text_white: true },
  { color_code: 'rgb(74, 255, 62)', is_text_white: true },
  { color_code: 'rgb(121, 109, 235)', is_text_white: true },
  { color_code: 'rgb(28, 176, 87)', is_text_white: true },
  { color_code: 'rgb(104, 181, 201)', is_text_white: true },
  { color_code: 'rgb(13, 17, 23)', is_text_white: true },
];

export const generateColor = (brightness) => {
  // Six levels of brightness from 0 to 5, 0 being the darkest
  var rgb = [Math.random() * 256, Math.random() * 256, Math.random() * 256];
  var mix = [brightness * 51, brightness * 51, brightness * 51]; //51 => 255/5
  var mixedrgb = [rgb[0] + mix[0], rgb[1] + mix[1], rgb[2] + mix[2]].map(function (x) { return Math.round(x / 2.0) })
  return "rgb(" + mixedrgb.join(",") + ")";
}
