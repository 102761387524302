import axios from 'axios';
import moment, { weekdays } from 'moment';
import React, { Component } from 'react';
import { Button, Spinner, Table, Row, Col, Dropdown, Badge, Offcanvas, Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { t } from '../../helpers/translation_helper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare, faBook, faGlobe } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { brand_colors } from '../../helpers/brand_colors_helper';
const ContrastColor = require('contrast-color');

// API URL
const apiURL = process.env.REACT_APP_API_URL;

let controller;

/**
 * Dashboard Timetable
 */
class Timetable extends Component {
  // State
  state = {
    timetable: [],
    timetable_periods: [],
    weekdays: [
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
      'Sunday',
    ],
    selectedDay: moment().format('dddd'),
    selectedDate: moment().format('YYYY-MM-DD'),
    showFullWeekTT: false,
    fullWeekTT: null,
    fullWeekTTPeriods: []
  };

  /**
   * Component Did Update
   */
  componentDidMount() {
    this.getTimetable();
  }

  /**
   * Component Did Update
   */
  componentDidUpdate(prevProps, prevState) {
    const { selectedDay, selectedDate } = this.state;

    if (prevState.selectedDay !== selectedDay) {

      this.setState({ timetable: null });
      this.getTimetable(selectedDay);
    }
  }

  /**
   * Get Timetable
   * @param {string} dayname Name of the day
   * @param {string} thatDate Date (YYYY-MM-DD)
   */
  getTimetable = async (dayname = moment().format('dddd')) => {
    const source = axios.CancelToken.source();
    const cancelToken = source.token;
    const { authData } = this.props;
    const { center_id } = authData.centerData;
    const { auth_key } = authData.loginData;

    const fData = new FormData();
    fData.append('center_id', center_id);
    fData.append('dayname', dayname);
    fData.append('classes', JSON.stringify(authData.classes));
    fData.append('employee_id', this.props.authData.userData.employee_id);

    if (controller) controller.abort();
    controller = new AbortController();

    try {
      const res = await axios.post(
        apiURL + 'dashboard/get-timetable',
        fData,
        {
          params: {
            auth_key: auth_key,
            appname: process.env.REACT_APP_NAME,
          },
          signal: controller.signal,
        }
      );

      this.setState({
        timetable: res.data.timetable,
        timetable_periods:
          res.data.timetable_periods.length > 0
            ? res.data.timetable_periods
            : [],
        selectedDay: dayname,
      });
    } catch (err) {
      console.log('err', err)
      if (axios.isCancel(err)) {
        // 
      }
      toast.error(t('Something went wrong while fetching timetable!'));

    }
  };


  getFullWeekTimetable = async () => {

    this.setState({ fullWeekTT: null });

    const source = axios.CancelToken.source();
    const cancelToken = source.token;
    const { authData } = this.props;
    const { center_id } = authData.centerData;
    const { auth_key } = authData.loginData;

    const fData = new FormData();
    fData.append('center_id', center_id);
    fData.append('classes', JSON.stringify(authData.classes));
    fData.append('employee_id', this.props.authData.userData.employee_id);

    if (controller) controller.abort();
    controller = new AbortController();

    try {
      const res = await axios.post(
        apiURL + 'dashboard/get-complete-timetable',
        fData,
        {
          params: {
            auth_key: auth_key,
            appname: process.env.REACT_APP_NAME,
          },
          signal: controller.signal,
        }
      );

      if (res.status == 200) {
        this.setState({
          fullWeekTT: res.data.timetable,
          fullWeekTTPeriods:
            res.data.timetable_periods.length > 0
              ? res.data.timetable_periods
              : [],
        });
      }


    } catch (err) {
      console.log('err', err)
      if (axios.isCancel(err)) {
        // 
      }
      toast.error(t('Something went wrong while fetching timetable!'));

    }
  };

  /**
   * Render Weekdays Buttons
   * @returns {HTMLElement} Outputs the buttons of weekdays
   */
  renderWeekdayButtons = () => {
    const { weekdays, selectedDay } = this.state;

    const weekdayButtons = weekdays.map((item, index) => {
      return (
        <Button
          key={index}
          variant={selectedDay == item ? 'primary' : 'light'}
          className='weekday-btn'
          onClick={() =>
            this.handleDayDate(
              item,
              moment(moment().startOf('isoweek'))
                .add(index, 'd')
                .format('YYYY-MM-DD')
            )
          }
          size='sm'>
          {t(item)}
        </Button>
      );
    });

    return weekdayButtons;
  };

  /**
   * Render Timetable Time Fields
   * @returns {HTMLElement} Outputs the th of timetable table header
   */
  renderTimetableTimeFields = (tp_id) => {
    const { timetable } = this.state;

    const THs = [];

    for (var i in timetable) {
      if (tp_id == timetable[i].tp_id) {
        THs.push(
          <th key={i}>
            {moment(timetable[i].time_start, 'HH:mm:ss').format(
              'HH:mm'
            )}{' '}
            -
            {moment(timetable[i].time_end, 'HH:mm:ss').format(
              'HH:mm'
            )}{' '}
            {timetable[i].tp_type_name &&
              <span style={{
                borderColor: timetable[i].tp_type_color,
                borderWidth: 1,
                borderStyle: 'solid',
                color: timetable[i].tp_type_color,
                textTransform: 'uppercase',
                borderRadius: 5,
                paddingInline: 4,
                paddingBlock: 2,
                display: 'inline-block'
              }}>
                {timetable[i].tp_type_name}
              </span>
            }
          </th>
        );
      }
    }

    return THs;
  };

  /**
   * Render Timetable Material Fields
   * @returns {HTMLElement} Outputs the td of timetable table body
   */
  renderTimetableMaterialFields = (tp_id) => {
    // Language Direction
    const { lang_orientation: dir } = this.props.defaultLanguage;
    const { timetable } = this.state;

    const TDs = [];

    for (var i in timetable) {
      if (tp_id == timetable[i].tp_id) {
        TDs.push(
          <td
            key={i}
            style={{
              backgroundColor: brand_colors[this.props.defaultTheme.theme_id].color8,
            }}>
            {timetable[i].details.map((item, i) => {
              const cc = new ContrastColor({
                bgColor: item.as_color,
                threshold: 200,
              });
              const defaultFgColor = cc.contrastColor();

              return (
                <div key={i} className='d-flex flex-column mb-1'>
                  <div className='me-1' style={{ backgroundColor: item.as_color, color: defaultFgColor, display: 'inline-block', padding: '5px 10px', borderRadius: 5, marginBottom: 3, }}>
                    <div>{item.acs_name}</div>
                    <div className='mb-1'>{item.class_theme}</div>
                    {item.class_online_url !== null && item.class_online_url !== '' && (
                      <div className='d-grid'>
                        <Button
                          style={{ marginTop: '-3px' }}
                          variant='tertiary'
                          size='sm'
                          title={t('Online Class')}
                          onClick={() =>
                            window.open(
                              item.class_online_url,
                              '_blank',
                              'noopener,noreferrer'
                            )
                          }>
                          <FontAwesomeIcon
                            icon={faGlobe}
                            color={brand_colors[this.props.defaultTheme.theme_id].color8}
                          />
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </td>
        );
      }
    }

    return TDs;

  };

  handleOpenFullWeekTT = () => {
    this.setState({
      showFullWeekTT: true
    });
    this.getFullWeekTimetable();
  }
  handleCloseFullWeekTT = () => {
    this.setState({
      showFullWeekTT: false
    });
  }

  /**
   * Handle Day & Date Selection
   * @param {string} selectedDay Day name of the week
   * @param {string} selectedDate Date (YYYY-MM-DD)
   */
  handleDayDate = (selectedDay, selectedDate) => {
    this.setState({
      selectedDay,
      selectedDate,
    });
  };


  renderFullWeekTTItem = (day, tp_id, time_start, time_end) => {
    const { fullWeekTT } = this.state;

    for (var i in fullWeekTT[day]) {
      if (
        fullWeekTT[day][i].tp_id == tp_id &&
        fullWeekTT[day][i].time_start == time_start &&
        fullWeekTT[day][i].time_end == time_end
      ) {
        if (fullWeekTT[day][i].details.length > 0) {

          return fullWeekTT[day][i].details.map((item, i) => {
            const cc = new ContrastColor({
              bgColor: item.as_color,
              threshold: 200,
            });
            const defaultFgColor = cc.contrastColor();
            return (<div key={i} className='d-flex flex-column mb-1'>
              <div className='me-1' style={{ backgroundColor: item.as_color, color: defaultFgColor, display: 'inline-block', padding: '5px 10px', borderRadius: 5, marginBottom: 3, }}>
                <div>{item.acs_name}</div>
                <div className='mb-1'>{item.class_theme}</div>
                {item.class_online_url !== null && item.class_online_url !== '' && (
                  <div className='d-grid'>
                    <Button
                      style={{ marginTop: '-3px' }}
                      variant='tertiary'
                      size='sm'
                      title={t('Online Class')}
                      onClick={() =>
                        window.open(
                          item.class_online_url,
                          '_blank',
                          'noopener,noreferrer'
                        )
                      }>
                      <FontAwesomeIcon
                        icon={faGlobe}
                        color={brand_colors[this.props.defaultTheme.theme_id].color8}
                      />
                    </Button>
                  </div>
                )}
              </div>
            </div>
              // <span key={i}>
              //     ahmad
              //     <span
              //       style={{
              //         backgroundColor:
              //           item.as_color,
              //         color: defaultFgColor,
              //         display: 'inline-block',
              //         padding: '5px 10px',
              //         borderRadius: 5,
              //         marginBottom: 3,
              //       }}>
              //       {item.acs_name}
              //     </span>{' '}
              //     {item.class_online_url !== null && item.class_online_url !== '' && (
              //       <Button
              //         style={{ marginTop: '-3px' }}
              //         variant='tertiary'
              //         size='sm'
              //         title={t('Online Class')}
              //         onClick={() =>
              //           window.open(
              //             item.class_online_url,
              //             '_blank',
              //             'noopener,noreferrer'
              //           )
              //         }>
              //         <FontAwesomeIcon
              //           icon={faGlobe}
              //           color={brand_colors[this.props.defaultTheme.theme_id].color8}
              //         />
              //       </Button>
              //     )}
              //     <br />
              //   </span>
            )
          });
        }
      }
    }
  }

  /**
   * Render Component
   * @returns {HTMLElement}
   */
  render() {
    // State
    const {
      timetable,
      timetable_periods,
      showFullWeekTT,
      fullWeekTT,
      fullWeekTTPeriods,
      weekdays
    } = this.state;
    if (timetable_periods.length <= 0) {
      return null
    }
    return (
      <div
        style={{ padding: 5, borderRadius: 20 }}
        className='dashboard-timetable grey-section mb-3'>
        <div className='weekday-btns'>
          {this.renderWeekdayButtons()}
          <Button
            variant='warning'
            className='weekday-btn'
            onClick={() => this.handleOpenFullWeekTT()}
            size='sm'>
            {t('Full Week') + ' '}
            <FontAwesomeIcon
              icon={faArrowUpRightFromSquare}
              color={brand_colors[this.props.defaultTheme.theme_id].color8}
            />
          </Button>
        </div>
        {timetable_periods.map((tp_item, tp_index) => (
          <div className='timetable' key={tp_index}>
            <div className='timetable-header' key={tp_index}>
              {tp_item.tp_name}
            </div>
            <div className='dashboard-timetable-table-wrap'>
              <Table className='dashboard-timetable-table text-center'>
                <thead>
                  <tr>
                    {this.renderTimetableTimeFields(
                      tp_item.tp_id
                    )}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    {this.renderTimetableMaterialFields(
                      tp_item.tp_id
                    )}
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>
        ))}
        <Modal className='full-week-tt-popup' show={showFullWeekTT} onHide={this.handleCloseFullWeekTT} size="xl" backdrop="static"
        keyboard={false}>
          <Modal.Header closeButton>
            <Modal.Title>{t('Full Week Timetables')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {fullWeekTT ? (
              <div>
                {fullWeekTTPeriods.map((period, periodIndex) => (
                  <div key={periodIndex}>
                    <h5 className='m-0 p-1 text-center' style={{ backgroundColor: brand_colors[this.props.defaultTheme.theme_id].color12, color: brand_colors[this.props.defaultTheme.theme_id].color8 }}>{period.tp_name}</h5>
                    <Table bordered hover size="sm">
                      <thead>
                        <tr>
                          <th width="150"></th>
                          {period.timetable_period_timings.map((timing, timingIndex) => (
                            <th className='text-center' key={timingIndex}>
                              {moment(timing.time_start, 'HH:mm:ss').format(
                                'HH:mm'
                              )}{' '}
                              -
                              {moment(timing.time_end, 'HH:mm:ss').format(
                                'HH:mm'
                              )}{' '}
                              {timing.tp_type_name &&
                                <span style={{
                                  borderColor: timing.tp_type_color,
                                  borderWidth: 1,
                                  borderStyle: 'solid',
                                  color: timing.tp_type_color,
                                  textTransform: 'uppercase',
                                  borderRadius: 5,
                                  paddingInline: 4,
                                  paddingBlock: 2,
                                  display: 'inline-block'
                                }}>
                                  {timing.tp_type_name}
                                </span>
                              }
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {weekdays.map((day, dayIndex) => (
                          <tr key={dayIndex}>
                            <td style={{ verticalAlign: 'middle' }}>
                              <strong>{day}</strong>
                            </td>
                            {period.timetable_period_timings.map((timing, timingIndex) => (
                              <td className='text-center' style={{ verticalAlign: 'middle' }} key={timingIndex}>
                                {this.renderFullWeekTTItem(day, period.tp_id, timing.time_start, timing.time_end)}
                              </td>
                            ))}
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                ))}
              </div>
            ) : (
              <div className="text-center" style={{ position: 'relative', top: '37%' }}>
                <Spinner
                  animation='grow'
                  variant='dark'
                />
              </div>
            )}
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  authData: state.auth.authData,
  languages: state.language.languages,
  defaultLanguage: state.language.defaultLanguage,
  defaultTheme: state.theme.defaultTheme,
});

export default connect(mapStateToProps, null)(Timetable);
