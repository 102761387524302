import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';

export const addEditInvoiceSlice = createSlice({
    name: 'addEditInvoice',
    initialState: {
        showModal: false,
        title: 'New Invoice',
        child: null,
        todayDate: moment().format('YYYY-MM-DD'),
        addservices: [],
        editMode: false,
    },
    reducers: {
        toggleInvoiceModal: (state, action) => {
            state.showModal = !state.showModal
            state.title = (action.payload && action.payload.title) ? action.payload.title : 'New Invoice'
            state.todayDate = (action.payload && action.payload.todayDate) ? action.payload.todayDate : moment().format('YYYY-MM-DD')
            state.child = (action.payload && action.payload.child) ? action.payload.child : null
            state.addservices = (action.payload && action.payload.addservices) ? action.payload.addservices : []
            state.editMode = (action.payload && action.payload.editMode) ? action.payload.editMode : false
        },
        updateChild: (state, action) => {
            state.child = (action.payload) ? action.payload : null
        }
    },
});

export const { toggleInvoiceModal, updateChild } = addEditInvoiceSlice.actions;
export default addEditInvoiceSlice.reducer;
