import React, { Component } from 'react';
import { Container, Card, Row, Col, Button, Table, Alert, Spinner } from 'react-bootstrap';
import { t } from '../../helpers/translation_helper';
import NavBar from '../_partials/NavBar/_NavBar';
import Header from '../_partials/Header/_Header';
import { connect } from 'react-redux';
import { withParams } from '../../helpers/params_helper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faFlag } from '@fortawesome/free-regular-svg-icons';
import { faCaretLeft, faCaretRight, faSearch, faXmark } from '@fortawesome/free-solid-svg-icons';
import { faC } from '@fortawesome/free-solid-svg-icons';
import { brand_colors } from '../../helpers/brand_colors_helper';
import Dragula from 'react-dragula';
import axios from 'axios';
import { toast } from 'react-toastify';
import moment from 'moment';
import { GET } from '../../api';
const apiURL = process.env.REACT_APP_API_URL;

class Planner extends Component {

  drake = 'test';

  state = {
    working: false,
    loading: true,
    weekdays: ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'],
    search_lesson: '',
    lessons: [],
    periods: [],
    timetable_lessons: [],
    default_timetable_lessons: [],
    currentDate: null,
    weekStart: null,
    weekEnd: null,
    week: null,
    loader: false
  }

  componentDidMount() {
    this.getData();

    // Week start/end
    const currentDate = moment();
    const weekStart = currentDate.clone().startOf('isoWeek');
    const weekEnd = currentDate.clone().endOf('isoWeek');

    this.setState({ currentDate, weekStart, weekEnd, week: this.genWeek(weekStart) });

  }

  genWeek = (weekStart) => {
    var week = [];
    for (var i = 0; i <= 6; i++) {
      week.push(moment(weekStart).add(i, 'd').format("YYYY-MM-DD"));
    }
    return week;
  }

  getData = async () => {
    this.setState({ loader: true });
    const { authData, selectedClass, params } = this.props;
    const { auth_key } = authData.loginData;
    const { center_id } = authData.centerData;
    const { class_id } = selectedClass.class;
    const { acs_id, tp_id, tt_id } = params;

    try {
      const res = await GET('timetable/get-class-subject-timetable', {
        params: {
          class_id,
          tt_id,
          tp_id,
          acs_id,
        },
      });

      if (res.status == 200) {
        this.setState({
          lessons: res.data.academic_subject_lessons_data.academic_subject_lessons,
          periods: res.data.timetable_period_timings,
          timetable_details: res.data.timetable_details,
          timetable_lessons: res.data.timetable_lessons,
          default_timetable_lessons: res.data.timetable_lessons,
          loading: false,
          loader: false
        });
      }
    } catch (err) {
      console.log('err', err)
      toast.error(t('Something went wrong while fetching lessons!'));

      this.setState({ loading: false, loader: false });
    }
  }

  Dragula = () => {
    const $this = this;
    const { lessons } = $this.state;
    let timetable_lessons = [...this.state.timetable_lessons];
    if (document.getElementById('lessons-reel')) {

      this.drake = Dragula([document.getElementById('lessons-reel')].concat(
        Array.from(document.querySelectorAll('.drop-target'))
      ), {
        accepts: function (el, target, source, sibling) {
          return target.classList[0] === 'drop-target';
        },
        copy: function (el, source) {
          return source.classList[0] !== 'drop-target';
        },
        moves: function (el, container, handle, siblings) {
          if (container.classList[0] == 'lessons-reel') {
            return true;
          }
          if (container.classList[0] == 'drop-target') {
            return true;
          }
        },
        removeOnSpill: true,
      });


      this.drake.on('drop', function (el, target, source) {
        const asl_id = el.dataset.asl_id;
        const day = target.dataset.day;
        const date = target.dataset.date;
        const tpt_id = target.dataset.tpt_id;
        const ttd_id = target.dataset.ttd_id;
        const source_date = source.dataset.date;
        const source_tpt_id = source.dataset.tpt_id;
        const index = lessons.findIndex((lesson) => lesson.asl_id == asl_id);


        if (source.classList[0] == 'lessons-reel') {
          lessons[index].asl_period = parseInt(lessons[index].asl_period) - 1;

          timetable_lessons.push({
            ...lessons[index],
            ttl_id: lessons[index].ttl_id || 0,
            ttl_date: date,
            tpt_id,
            ttd_id,
            day,
          });
          $this.setState({ lessons, timetable_lessons });
          return true;
        }

        if (source.classList[0] == 'drop-target') {
          const ttl_index = timetable_lessons.findIndex((ttl) => ttl.asl_id == asl_id && ttl.tpt_id == source_tpt_id && ttl.ttl_date == source_date);
          timetable_lessons.splice(ttl_index, 1);
          timetable_lessons.push({
            ...lessons[index],
            ttl_id: lessons[index].ttl_id || 0,
            ttl_date: date,
            tpt_id,
            ttd_id,
            day,
          });
          $this.setState({ lessons, timetable_lessons });
        }
      });

      this.drake.on('remove', function (el, container, source) {
        const asl_id = el.dataset.asl_id;
        const date = container.dataset.date;
        const tpt_id = container.dataset.tpt_id;
        const index = lessons.findIndex((lesson) => lesson.asl_id == asl_id);
        const ttl_index = timetable_lessons.findIndex((ttl) => ttl.asl_id == asl_id && ttl.tpt_id == tpt_id && ttl.ttl_date == date);
        timetable_lessons.splice(ttl_index, 1);
        lessons[index].asl_period = parseInt(lessons[index].asl_period) + 1;
        $this.setState({ lessons, timetable_lessons });
      });

      this.populateLessons();

    }
  };

  populateLessons = () => {
    const { timetable_lessons } = this.state;
    this.drake.containers.map((container) => {
      if (container.classList[0] == 'drop-target') {

        container.innerHTML = '';

        const lessons = timetable_lessons.filter((lesson) => lesson.ttd_id == container.dataset.ttd_id && lesson.ttl_date == container.dataset.date);

        if (lessons.length > 0) {
          lessons.map((lesson) => {
            const lessonNode = document.createElement("div");
            lessonNode.className = 'lesson-reel-item';
            lessonNode.setAttribute('id', 'lesson-' + lesson.asl_id);
            lessonNode.dataset.asl_id = lesson.asl_id;
            lessonNode.innerHTML = `<div className='mb-1'>${lesson.asl_name}</div>`;
            container.appendChild(lessonNode);
          });
        }
      }
    });
  }

  dropBox = (day, tpt_id) => {
    const { timetable_details } = this.state;
    const { authData } = this.props;
    const { weekend_days } = authData.centerData;

    const findDetail = timetable_details.find((ttd) => {
      return ttd.tpt_id == tpt_id && ttd.dayname.toLowerCase() == day;
    });

    if (findDetail) {
      return {
        className: 'drop-target',
        ttd_id: findDetail.ttd_id
      };
    }

    if (weekend_days.includes(day)) {
      return {
        className: 'no-drop weekend'
      }
    }
    return {
      className: 'no-drop'
    };
  }

  savePlanner = async () => {
    this.setState({ working: true });
    const { authData, selectedClass, params } = this.props;
    const { auth_key } = authData.loginData;
    const { center_id } = authData.centerData;
    const { class_id } = selectedClass.class;
    const { acs_id } = params;
    const { timetable_lessons } = this.state;

    const fData = new FormData();
    fData.append('center_id', center_id);
    fData.append('class_id', class_id);
    fData.append('acs_id', acs_id);
    fData.append('timetable_lessons', JSON.stringify(timetable_lessons));

    try {
      const res = await axios.post(apiURL + '/timetable/save-timetable-lessons', fData, {
        params: {
          auth_key: auth_key,
          appname: process.env.REACT_APP_NAME,
        },
      });


      if (res.status == 200) {
        this.setState({ working: false });
        toast.success(t('Planner saved successfully!'));
        this.getData();
      }
    } catch (err) {
      console.log('err', err)
      toast.error(t('Something went wrong while saving planner!'));

      this.setState({ working: false });
    }
  }

  updateLessonStatus = async (lesson) => {

    const { authData, params, selectedClass } = this.props;
    const { timetable_lessons } = this.state;
    const { auth_key, user_id } = authData.loginData;
    const { center_id, center_timezone } = authData.centerData;
    const { acs_id } = params;

    const fData = new FormData();
    fData.append('center_id', center_id);
    fData.append('class_id', selectedClass.class.class_id);
    fData.append('user_id', user_id);
    fData.append('center_timezone', center_timezone);
    fData.append('acs_id', acs_id);
    fData.append('asl_id', lesson.asl_id);
    fData.append('is_activated', lesson.activation_date ? 0 : 1);

    if (lesson.activation_date) {
      timetable_lessons.map((lessonItem) => {
        if (lessonItem.asl_id == lesson.asl_id) {
          lessonItem.activation_date = null
        }
      });
    } else {
      timetable_lessons.map((lessonItem) => {
        if (lessonItem.asl_id == lesson.asl_id) {
          lessonItem.activation_date = 'date'
        }
      });
    }

    this.setState({ timetable_lessons });
    try {
      const res = await axios.post(apiURL + '/timetable/update-lesson-status', fData, {
        params: {
          auth_key: auth_key,
          appname: process.env.REACT_APP_NAME,
        },
      });

      if (res.status == 200) {
        if (res.data.type) {
          toast[res.data.messageType](t(res.data.message));
          this.getData();
        } else {
          toast[res.data.messageType](t(res.data.message));
        }
      }
    } catch (err) {
      console.log('err', err)
      toast.error(t('Something went wrong while updating lesson status!'));


    }

  }

  timeTravel = (time_in_space) => {
    const { week } = this.state;
    if (time_in_space === 'future') {
      const go_one_more_day_in_future = moment(week[6]).add(1, 'd').format("YYYY-MM-DD");
      const future_week = this.genWeek(go_one_more_day_in_future);
      this.setState({ week: future_week }, () => this.populateLessons());
    }
    if (time_in_space === 'past') {

      const currentDate = moment().format('YYYY-MM-DD');

      if (week.includes(currentDate)) {
        toast.error(t('Cannot go to past week!'));
        return;
      }

      const go_back_one_day_in_future = moment(week[0]).subtract(1, 'd').format("YYYY-MM-DD");
      const day_from_that_week = moment(go_back_one_day_in_future);
      const start_of_that_week = day_from_that_week.startOf('isoWeek');
      const past_week = this.genWeek(start_of_that_week);
      this.setState({ week: past_week }, () => this.populateLessons());
    }
  }

  render() {
    const { working, loading, lessons, periods, weekdays, currentDate, weekStart, weekEnd, timetable_lessons, default_timetable_lessons, week, search_lesson } = this.state;
    const { params, authData, defaultTheme } = this.props;
    const { acs_name } = params;

    console.log('this.state', this.state);
    let weeklyLessonPlanChanged = false
    timetable_lessons.map((v, i) => {
      let findDefault = default_timetable_lessons.find((vv, ii) => vv.asl_id == v.asl_id && vv.tpt_id == v.tpt_id && vv.ttd_id == v.ttd_id && vv.ttl_date == v.ttl_date)
      if (!findDefault) {
        weeklyLessonPlanChanged = true
      }
    })

    return (
      <Container fluid>
        <div id='iedu-layout'>
          <NavBar />
          <div id='page-content' className='lesson-planner'>
            <Header
              lite={true}
              heading={t('Weekly Lesson Planner')}
              backBtn={true}
            />
            <Row>
              <Col md={12}>
                <Row className='mb-4 align-items-center'>
                  <Col md={4} className='d-flex flex-row align-items-center'>
                    <h5 className='mb-0'>
                      {this.props.selectedClass.class.class_theme} | {acs_name}
                    </h5>
                    <div className='ms-2 cb-expandable-search d-flex flex-row align-items-center' style={{ width: 178, borderRadius: 10 }}>
                      <input ref={this.searchFieldRef} type='text' placeholder={t('Search Lesson')}
                        onChange={(e) => this.setState({ search_lesson: e.target.value })}
                        value={search_lesson}
                        style={{ width: 150, opacity: 1, padding: '5px 10px', backgroundColor: brand_colors[defaultTheme.theme_id].color7, fontSize: 14 }} />
                      <FontAwesomeIcon
                        icon={search_lesson ? faXmark : faSearch}
                        color={brand_colors[defaultTheme.theme_id].color9}
                        className='cursor-pointer'
                        onClick={() => this.setState({ search_lesson: '' })}
                      />
                    </div>
                  </Col>
                  <Col className='d-flex flex-column align-items-center'>
                    <Alert className='m-0 p-1 px-2 d-inline-block' variant='warning'>{t('Drag and drop lessons to the table to add and drag out to remove.')}</Alert>
                    {weeklyLessonPlanChanged && <Alert className='m-0 p-1 px-2 d-inline-block' variant='danger'>{t('Weekly lesson plan changed, Please save.')}</Alert>}
                  </Col>
                  <Col md={3} className='text-end'>
                    <Button variant="success" size="lg" disabled={working || !weeklyLessonPlanChanged} onClick={this.savePlanner}>{t('Update')}</Button>
                  </Col>
                </Row>
                {loading ? (
                  <Col md={12} className='py-5 d-flex align-items-center flex-column'>
                    <Spinner as='span' animation='grow' size='lg' role='status' aria-hidden='true' className='mb-1' />
                    {t('Loading Lesson Planner...')}
                  </Col>
                ) : (
                  <>
                    <div className='lessons-reel-wrapper mb-4'>
                      <div className='lessons-reel' id='lessons-reel' ref={this.Dragula}>
                        {lessons.map((lesson, lesson_i) => {
                          if (search_lesson == '' || (lesson.asl_name).toLowerCase().includes((search_lesson).toLowerCase())) {
                            return <div key={lesson_i} className='lesson-reel-item' id={'lesson-' + lesson.asl_id} data-asl_id={lesson.asl_id}>
                              <div className='mb-1'>
                                {lesson.asl_name}
                              </div>
                              <Row>
                                <Col md={4}>
                                  <FontAwesomeIcon
                                    icon={faCircle}
                                    color={lesson.usedPeriods == 0 ? brand_colors[this.props.defaultTheme.theme_id].color11 : brand_colors[this.props.defaultTheme.theme_id].color13}
                                    style={{
                                      fontSize: 16,
                                      marginRight: 10
                                    }}
                                  />
                                  <button className='activation-button' title={lesson.activation_date ? 'De-Activate Lesson' : 'Activate Lesson'} onClick={() => this.updateLessonStatus(lesson)}>
                                    <FontAwesomeIcon
                                      icon={faFlag}
                                      color={lesson.activation_date ? brand_colors[this.props.defaultTheme.theme_id].color12 : brand_colors[this.props.defaultTheme.theme_id].color18}
                                      style={{
                                        fontSize: 16,
                                      }}
                                    />
                                  </button>
                                </Col>
                                <Col md={8} className='text-end'>
                                  <small className='periods-left'>{parseInt(lesson.asl_period) - parseInt(lesson.usedPeriods)} {t('Periods Left')}</small>
                                </Col>
                              </Row>
                            </div>
                          }
                          return null
                        })}
                      </div>
                    </div>
                    <Table bordered className='planner-table mb-0'>
                      <thead>
                        <tr>
                          <th className='text-center'>{t('PERIODS')}</th>
                          {weekdays.map((day, day_i) =>
                            <th key={day_i}>
                              {day_i == 0 &&
                                <button className='week-switcher' onClick={() => this.timeTravel('past')}>
                                  <FontAwesomeIcon
                                    icon={faCaretLeft}
                                    color={brand_colors[this.props.defaultTheme.theme_id].color8}
                                    style={{
                                      fontSize: 16,
                                    }}
                                  />
                                </button>
                              }
                              {day_i == 6 &&
                                <button className='week-switcher' onClick={() => this.timeTravel('future')}>
                                  <FontAwesomeIcon
                                    icon={faCaretRight}
                                    color={brand_colors[this.props.defaultTheme.theme_id].color8}
                                    style={{
                                      fontSize: 16,
                                    }}
                                  />
                                </button>
                              }
                              <div>{day.toUpperCase()}</div>
                              <small>{moment(week[day_i]).format('DD-MM-YYYY')}</small>
                            </th>
                          )}
                        </tr>
                      </thead>
                      <tbody>
                        {periods.map((period, period_i) =>
                          <tr key={period_i}>
                            <td>
                              <div className='table-time'>
                                <span>{period.time_start}</span>
                                <span>{period.time_end}</span>
                              </div>
                            </td>
                            {weekdays.map((day, day_i) =>
                              <td key={day_i} className={this.dropBox(day, period.tpt_id).className} data-date={week[day_i]} data-tpt_id={period.tpt_id} data-ttd_id={this.dropBox(day, period.tpt_id).ttd_id} data-day={day}>
                              </td>
                            )}
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </>
                )}
              </Col>
            </Row>
          </div>
        </div>
        {this.state.loader &&
          <div style={{ height: '100vh', width: '100vw', position: 'fixed', backgroundColor: 'rgba(0,0,0,0.5)', top: 0, left: 0, justifyContent: 'center', alignItems: 'center', display: 'flex', zIndex: 99 }}>
            <img src={require('../../assets/images/iedu-loader.gif')} alt='iEducore' width='120' />
          </div>
        }
      </Container>
    )
  }
}

const mapStateToProps = (state) => ({
  authData: state.auth.authData,
  defaultLanguage: state.language.defaultLanguage,
  selectedClass: state.selectedClass.data,
  defaultTheme: state.theme.defaultTheme,
});

export default connect(mapStateToProps, null)(withParams(Planner));
