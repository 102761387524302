import { Component } from "react";
import UnAuthorized from "./UnAuthorized";
import NotFound from "./NotFound";
import { connect } from "react-redux";
import { unsetAuthentication } from '../redux/slices/authSlice';

class Error extends Component {
    componentDidMount() {
        document.getElementsByTagName('body')[0].setAttribute(
            'class',
            this.props.defaultTheme.theme_id
        );
    }
    render() {
        console.log('window.location.pathname', window.location.pathname);
        const pathFind = this.props.NavigationData.find((nav) => '/' + nav.id === window.location.pathname);

        if (pathFind) {
            return (<UnAuthorized />);
        } else {
            return (<NotFound />);
        }
    }
}

const mapStateToProps = (state) => ({
    authData: state.auth.authData,
    defaultLanguage: state.language.defaultLanguage,
    defaultTheme: state.theme.defaultTheme,
});


const mapDispatchToProps = () => ({
    unsetAuthentication,
});

export default connect(mapStateToProps, mapDispatchToProps)(Error);