import React, { Component } from 'react';
import { Container, Card, Row, Col, ButtonGroup, Button, DropdownButton, Dropdown, Table, Form, Spinner, Modal, Tabs, Tab, Badge, Offcanvas, ToggleButton, Image } from 'react-bootstrap';
import { t } from '../../../helpers/translation_helper';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faCheckCircle, faSearch, faUser, faXmark, faLayerGroup, faHandsHoldingChild, faPeopleGroup, faListCheck, faPrint, faBus, faInfoCircle, faUserShield, faChartArea, faChartPie, faEllipsis, faAngleRight, faAngleLeft, faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { NavLink } from 'react-router-dom';
import { changeColorOpacity, getUrlParam, ifNavAllowed, isTeacher } from '../../../helpers/general_helpers';
import { getStudents } from '../../../redux/slices/studentListSlice';
import { brand_colors } from '../../../helpers/brand_colors_helper';
import moment from 'moment';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import { GET, POST } from '../../../api';
import { toast } from 'react-toastify';
import { DateRangePicker } from 'react-date-range';
import Flatpickr from 'react-flatpickr';
import { Fragment } from 'react';
import { faEdit, faTrashCan } from '@fortawesome/free-regular-svg-icons';
import SweetAlert from 'react-bootstrap-sweetalert';
import { toggleRegistrationSimpleModal } from '../../../redux/slices/addEditRegistrationSimpleModalSlice';
import { handleFormErrors } from '../../../helpers/form_helpers';
import { getExtension } from '../../../helpers/general_helpers';
var Chance = require('chance');
var chance = new Chance();

const apiURL = process.env.REACT_APP_API_URL;
const portalURL = process.env.REACT_APP_PORTAL_URL;


class AddEditRegistrationSimple extends Component {

  constructor(props) {
    super(props);
    this.state = {
      deleteModal: { show: false, id: 0, action: () => { } },
      getTermsData: [],
      getClassesData: { data: [], loading: false },
      getSessionsData: { data: [], loading: false },
      getSessioncatData: [],
      days: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
      registrations: [],
      reg_date: moment().format('YYYY-MM-DD'),
      saving: false,
      defaultStudentOptions: []
    }
  }

  componentDidMount() {
    // this.getTerms()
    // this.getSessioncat()
    this.loadStudentOptions();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.addEditRegistrationSimpleModal.showModal != this.props.addEditRegistrationSimpleModal.showModal && this.props.addEditRegistrationSimpleModal.showModal) {
      this.getTerms()
      this.getSessioncat()
      this.setState({
        registrations: JSON.parse(JSON.stringify(this.props.addEditRegistrationSimpleModal.registrations)),
        reg_date: this.props.addEditRegistrationSimpleModal.reg_date,
      })
    }
  }

  setStateRegistration = (i, field, value) => {
    let registrations = this.state.registrations
    registrations[i][field] = value
    this.setState({ registrations })
  }

  loadStudentOptions = async (search, callback) => {
    console.log('search, callback', search, callback);
    if (this.loadStudentOptionsReq) {
      this.loadStudentOptionsReq.abort();
    }
    this.loadStudentOptionsReq = new AbortController();
    const { user_id, role_id } = this.props.authData.loginData;
    const { employee_id } = this.props.authData.userData;
    let class_ids = false;
    if ([3, 4, 15].includes(role_id)) {
      class_ids = this.props.selectedClass.class.class_id
    }

    const res = await GET('admin/children-list-paginate', {
      signal: this.loadStudentOptionsReq.signal,
      params: {
        search,
        limit: 20,
        offset: 0,
        user_id,
        employee_id,
        role_id,
        class_ids,
        registrations: true
      },
    });

    if (res) {
      let resData = res.data

      if (typeof callback === 'function') {
        callback(resData.data.map((v, i) => {
          return { label: v.child_name, value: v.child_id, ...v }
        }))
      } else {
        this.setState({
          defaultStudentOptions: resData.data.map((v, i) => {
            return { label: v.child_name, value: v.child_id, ...v }
          })
        });
      }

    }
  }

  getSessioncat = async () => {
    if (this.getSessioncatReq) {
      this.getSessioncatReq.abort();
    }
    this.getSessioncatReq = new AbortController();
    const res = await GET('registration-simple/get-sessioncat', {
      signal: this.getSessioncatReq.signal,
    });

    if (res) {
      let resData = res.data
      this.setState({ getSessioncatData: resData })
    }
  }
  getTerms = async () => {
    if (this.getTermsReq) {
      this.getTermsReq.abort();
    }
    this.getTermsReq = new AbortController();
    const res = await GET('registration-simple/get-terms', {
      signal: this.getTermsReq.signal,
    });

    if (res) {
      let resData = res.data
      this.setState({ getTermsData: resData })
    }
  }

  getClasses = async (term_id, child_id, reg_id = null) => {
    if (term_id == 0 || child_id == 0) {
      toast['warning']('Please select a Student and Term First.')
      this.setState({ getClassesData: { data: [], loading: false } })
      return false
    }
    this.setState({ getClassesData: { data: [], loading: true } })
    if (this.getClassesReq) {
      this.getClassesReq.abort();
    }
    this.getClassesReq = new AbortController();
    const res = await GET('registration-simple/get-classes', {
      params: {
        term_id: term_id.value,
        child_id: child_id.value,
        reg_id: (reg_id == 0) ? null : reg_id,
      },
      signal: this.getClassesReq.signal,
    });

    if (res) {
      let resData = res.data
      this.setState({ getClassesData: { data: resData, loading: false } })
    }
  }

  getSessions = async (term_id, child_id, class_id, sessioncat_id, reg_id = null) => {
    if (term_id == 0 || child_id == 0 || sessioncat_id == 0 || class_id == 0) {
      toast['warning']('Please select a Student, Term, Class and Category First.')
      this.setState({ getSessionsData: { data: [], loading: false } })
      return false
    }
    this.setState({ getSessionsData: { data: [], loading: true } })
    if (this.getSessionsReq) {
      this.getSessionsReq.abort();
    }
    this.getSessionsReq = new AbortController();
    const res = await GET('registration-simple/get-sessions', {
      params: {
        term_id: term_id.value,
        child_id: child_id.value,
        sessioncat_id: sessioncat_id.value,
        class_id: class_id.value,
        reg_id: (reg_id == 0) ? null : reg_id,
      },
      signal: this.getSessionsReq.signal,
    });

    if (res) {
      let resData = res.data
      this.setState({ getSessionsData: { data: resData, loading: false } })
    }
  }

  saveRegistration = async () => {
    let registrations = JSON.parse(JSON.stringify(this.state.registrations))
    if (registrations.length <= 0) {
      toast['warning']('Please create at-least one registration.')
      return false
    }
    let reg_date = this.state.reg_date
    let checkError = false
    registrations = registrations.map((v, i) => {
      if (v.term_id == 0 || v.child == 0 || v.sessioncat_id == 0 || v.class_id == 0) {
        toast['warning']('Record ' + (i + 1) + ': All fields are required.')
        checkError = true
        return
      }

      let session_day_number = v.session_id.session_day_number
      let verify_session_day_number = 0;
      this.state.days.map((vv) => {
        let sessionDayName = 'session_' + vv.slice(0, 3).toLowerCase()
        if (v[sessionDayName] == 1 || v[sessionDayName] == true) {
          v[sessionDayName] = 1
          verify_session_day_number++
        } else {
          v[sessionDayName] = 0
        }
      })
      console.log(v.session_id.session_day_number, verify_session_day_number);
      if (parseInt(v.session_id.session_day_number) != verify_session_day_number) {
        toast['warning']('Record ' + (i + 1) + ': ' + session_day_number + ' Day(s) must be selected.')
        checkError = true
        return
      }

      if (!v.date_from || !v.date_to) {
        toast['warning']('Record ' + (i + 1) + ': Date Range must be selected')
        checkError = true
        return
      }

      v.term_id = v.term_id.value
      v.child_id = v.child.value
      v.child = null
      v.sessioncat_id = v.sessioncat_id.value
      v.class_id = v.class_id.value
      v.session_id = v.session_id.value
      v.reg_date = reg_date

      return v
    })
    if (checkError) {
      return
    }
    this.setState({
      saving: true,
    })
    if (this.saveRegistrationReq) {
      this.saveRegistrationReq.abort()
    }

    this.saveRegistrationReq = new AbortController();
    let fData = new FormData()
    fData.append('registrationData', JSON.stringify(registrations))
    let res = await POST('registration-simple/save-registration', fData, {
      signal: this.saveRegistrationReq.signal
    })
    console.log('res', res);
    if (res) {
      let resData = res.data
      if (resData.type) {
        if (this.props.callbackFunction) {
          this.props.callbackFunction()
        }
        this.props.toggleRegistrationSimpleModal()
      }
      this.setState({ saving: false })
      toast[resData.messageType](resData.message)
    }
  }

  deleteRegistration = (v, i) => {
    if (v.reg_id == 0) {
      let registrations = this.state.registrations
      registrations.splice(i, 1)
      this.setState({ registrations })
    } else {
      this.setState({
        deleteModal: {
          ...this.state.deleteModal, show: true, id: v.reg_id, action: async () => {
            if (this.deleteRegistrationReq) {
              this.deleteRegistrationReq.abort()
            }

            this.deleteRegistrationReq = new AbortController();
            let fData = new FormData()
            fData.append('reg_id', v.reg_id)
            let res = await POST('registration-simple/delete-registration', fData, {
              signal: this.deleteRegistrationReq.signal
            })
            console.log('res', res);
            if (res) {
              let resData = res.data
              if (resData.type) {
                this.setState({
                  deleteModal: {
                    ...this.state.deleteModal, show: false,
                  }
                })
              }
              toast[resData.messageType](resData.message)
            }
          }
        }
      })
    }
  }

  render() {

    console.log('this.state.defaultStudentOptions', this.state.defaultStudentOptions);

    let addEditRegistrationSimpleModal = this.props.addEditRegistrationSimpleModal
    if (!addEditRegistrationSimpleModal.showModal) {
      return null
    }

    let { getTermsData, getClassesData, getSessioncatData, getSessionsData, days, registrations, reg_date, saving } = this.state
    const dir = this.props.defaultLanguage.lang_orientation
    let weekend_days = this.props.authData.centerData.weekend_days
    let defaultTheme = this.props.defaultTheme
    if (weekend_days) {
      weekend_days = weekend_days.split(',')
    }
    console.log('ADDEDITREGSIMPLEMODAL', this.state, this.props);

    return (<Offcanvas show={addEditRegistrationSimpleModal.showModal} style={{ height: '100%', zIndex: 9990 }} placement='bottom' onHide={() => this.props.toggleRegistrationSimpleModal()
    }>
      <Offcanvas.Header id='iedu-header' style={{ marginTop: '1rem', marginRight: '1rem', marginLeft: '1rem', marginBottom: 0 }}>
        <div className='d-flex flex-row  align-items-center justify-content-center'>
          <span className='d-flex align-items-center justify-content-center btn-back-white' onClick={() => this.props.toggleRegistrationSimpleModal()}>
            <FontAwesomeIcon icon={(dir == 1) ? faAngleRight : faAngleLeft} color={brand_colors[defaultTheme.theme_id].color9} />
          </span>
          <Offcanvas.Title>
            <div className='d-flex flex-row align-items-center justify-content-center title-back-color8'>
              <span style={{ fontSize: 20, margin: '0 10px' }}>{addEditRegistrationSimpleModal.title}</span>
            </div>
          </Offcanvas.Title>
        </div>
        <div className='custom-styled-form'>
          <div className='d-flex flex-row justify-content-between align-items-end'>
            <Button disabled={saving} variant={'success'} onClick={() => setTimeout(() => {
              this.saveRegistration()
            }, 300)}>
              {t('Save')} {saving ? <Spinner as='span' animation='grow' size='sm' role='status' aria-hidden='true' /> : ''}
            </Button>
          </div>
        </div>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <div
          className={'d-flex flex-row grey-section'}
          style={{
            overflow: 'initial',
            backgroundImage: 'url(https://academic.educore.io/static/media/pegboard-bg.9b84e4936acef29bbcd9.png)',
            backgroundRepeat: 'repeat',
            backgroundPosition: '10px 12px',
            overflow: 'hidden',
          }}
        >
          <Col className='custom-styled-form hide-scrollbar' style={{ backgroundColor: brand_colors[defaultTheme.theme_id].color8, marginLeft: 10, marginRight: 10, borderRadius: '20px', padding: '1rem', overflow: 'auto', height: '75vh' }}>
            <div className='d-flex flex-row mb-2 align-items-end'>
              <div>
                <Button variant='success' size='sm' onClick={() => {
                  let registrations = this.state.registrations
                  registrations.push({
                    reg_id: 0,
                    center_id: this.props.authData.loginData.center_id,
                    child: (addEditRegistrationSimpleModal.child) ? { label: addEditRegistrationSimpleModal.child.child_name, value: addEditRegistrationSimpleModal.child.child_id, ...addEditRegistrationSimpleModal.child } : null,
                    class_id: 0,
                    session_id: 0,
                    sessioncat_id: 0,
                    term_id: 0,
                    centerlic_id: 0,
                    reg_year: 0,
                    session_mon: weekend_days.includes('monday') ? false : true,
                    session_tue: weekend_days.includes('tuesday') ? false : true,
                    session_wed: weekend_days.includes('wednesday') ? false : true,
                    session_thu: weekend_days.includes('thursday') ? false : true,
                    session_fri: weekend_days.includes('friday') ? false : true,
                    session_sat: weekend_days.includes('saturday') ? false : true,
                    session_sun: weekend_days.includes('sunday') ? false : true,
                    reg_date: moment().format('YYYY-MM-DD'),
                    reg_num: 0,
                    date_from: moment().format('YYYY-MM-DD'),
                    date_to: moment().format('YYYY-MM-DD'),
                  })
                  this.setState({ registrations })
                }}>
                  <FontAwesomeIcon icon={faPlus} color={brand_colors[defaultTheme.theme_id].color8} />
                </Button>
              </div>
              <div className='ms-3'>
                <Form.Label>{t('Registration Date')}</Form.Label>
                <Flatpickr
                  className='form-control'
                  value={reg_date}
                  onChange={([date]) => this.setState({ reg_date: moment(date).format('YYYY-MM-DD') })}
                />
              </div>
            </div>
            <div>
              <Table hover size='sm'>
                <thead>
                  <tr>
                    <th>{t('Student')}</th>
                    <th>{t('Term')}</th>
                    <th>{t('Class')}</th>
                    <th>{t('Category')}</th>
                    <th>{t('Session')}</th>
                  </tr>
                </thead>
                <tbody>
                  {registrations.map((v, i) => {
                    return <Fragment key={i}>
                      <tr>
                        <td colSpan="5">
                          <div className='d-flex flex-row align-items-center'>
                            <Badge>{t('Record')}: {i + 1}</Badge>
                            <div className='ms-4 d-flex flex-column align-items-center justify-content-center cursor-pointer' style={{ padding: '0.3rem 0.3rem', }} onClick={() => this.deleteRegistration(v, i)} >
                              <Image title={t('Remove')} src={require('../../../assets/images/remove-red.png')} style={{ width: '1.2rem', height: '1.2rem' }} />
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <AsyncSelect
                            placeholder={t('Type to Search Child')}
                            autoload={true}
                            cacheOptions
                            defaultOptions={this.state.defaultStudentOptions}
                            loadOptions={this.loadStudentOptions}
                            onChange={(event) => this.setStateRegistration(i, 'child', event)}
                            value={v.child}
                            menuPortalTarget={document.body}
                            styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                            formatOptionLabel={option => (
                              <div>
                                {option.picture ? <img src={portalURL + option.center_id + '/children/' + option.picture} style={{ width: 30, height: 30, objectFit: 'cover', marginRight: 5 }} /> : ''}
                                <span style={{ fontSize: 14 }}>{option.label}</span><br />
                                <Badge bg='secondary'>{option.parent_name}</Badge>
                              </div>
                            )}
                          />
                        </td>
                        <td>
                          <Select
                            options={getTermsData.map((vv) => { return { label: vv.term_name, value: vv.term_id, ...vv } })}
                            onChange={(event) => {
                              let registrations = this.state.registrations
                              registrations[i]['term_id'] = event
                              registrations[i]['date_from'] = event.term_from
                              registrations[i]['date_to'] = event.term_to
                              this.setState({
                                registrations
                              })
                            }}
                            value={v.term_id}
                            menuPortalTarget={document.body}
                            styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                            formatOptionLabel={option => (
                              <div>
                                <span style={{ fontSize: 14 }}>{option.label}</span>
                                {option.term_active == 1 && <br />}
                                {option.term_active == 1 && <Badge bg='success'>Active</Badge>}
                              </div>
                            )}
                          />
                        </td>
                        <td>
                          <Select
                            options={getClassesData.data.map((vv) => { return { label: vv.class_theme, value: vv.class_id, ...vv } })}
                            onChange={(event) => this.setStateRegistration(i, 'class_id', event)}
                            value={v.class_id}
                            menuPortalTarget={document.body}
                            styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                            formatOptionLabel={option => (
                              <div>
                                <span style={{ fontSize: 14 }}>{option.label}</span>
                                {option.remaining_reg && <br />}
                                {option.remaining_reg && <Badge bg='warning'>{('Remaining Seats')}: {option.remaining_reg}</Badge>}
                              </div>
                            )}
                            autoload={false}
                            isLoading={getClassesData.loading}
                            onFocus={() => this.getClasses(v.term_id, v.child, v.reg_id)}
                          />
                        </td>
                        <td>
                          <Select
                            options={getSessioncatData.map((vv) => { return { label: vv.sessioncat_name, value: vv.sessioncat_id, ...vv } })}
                            onChange={(event) => this.setStateRegistration(i, 'sessioncat_id', event)}
                            value={v.sessioncat_id}
                            menuPortalTarget={document.body}
                            styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                            formatOptionLabel={option => (
                              <div>
                                <span style={{ fontSize: 14 }}>{option.label}</span>
                                {option.vat_rate && <br />}
                                {option.vat_rate && <Badge bg='success'>VAT={option.vat_rate}</Badge>}
                              </div>
                            )}
                          />
                        </td>
                        <td>
                          <Select
                            options={getSessionsData.data.map((vv) => { return { label: vv.session_name, value: vv.session_id, ...vv } })}
                            onChange={(event) => {
                              let registrations = this.state.registrations
                              registrations[i]['session_id'] = event
                              let no = 0
                              days.map((vv) => {
                                let sessionDayName = 'session_' + vv.slice(0, 3).toLowerCase()
                                if (!weekend_days.includes(vv.toLowerCase())) {
                                  if (no < parseInt(event.session_day_number)) {
                                    registrations[i][sessionDayName] = true
                                    no++
                                  } else {
                                    registrations[i][sessionDayName] = false
                                  }
                                } else {
                                  registrations[i][sessionDayName] = false
                                }
                              })
                              this.setState({
                                registrations
                              })
                            }}
                            value={v.session_id}
                            menuPortalTarget={document.body}
                            styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                            formatOptionLabel={option => (
                              <div>
                                <span style={{ fontSize: 14 }}>{option.label}</span>
                                {option.session_day_number && <br />}
                                {option.session_day_number && <Badge bg='warning'>{('Days')}: {option.session_day_number}</Badge>}
                              </div>
                            )}
                            autoload={false}
                            isLoading={getSessionsData.loading}
                            onFocus={() => this.getSessions(v.term_id, v.child, v.class_id, v.sessioncat_id, v.reg_id)}
                          />
                        </td>
                      </tr >
                      <tr>
                        <td colSpan="2" className='position-relative'>
                          <Form.Label>{t('From - To Date')}</Form.Label>
                          <Form.Control
                            type="text"
                            readOnly
                            value={v.date_from + ' - ' + v.date_to}
                          />
                          <FontAwesomeIcon
                            icon={v.showDateRange ? faChevronUp : faChevronDown}
                            color={brand_colors[defaultTheme.theme_id].color18}
                            className="filter-arrow"
                            style={{ position: 'absolute', top: 40, right: 24, cursor: 'pointer' }}
                            onClick={() => this.setStateRegistration(i, 'showDateRange', !v.showDateRange)}
                          />
                          {v.showDateRange &&
                            <DateRangePicker
                              ranges={[{ startDate: new Date(v.date_from), endDate: new Date(v.date_to) }]}
                              onChange={(date) => {
                                console.log('date', date);
                                let registrations = this.state.registrations
                                registrations[i]['date_from'] = moment(new Date(date.range1.startDate)).format('YYYY-MM-DD')
                                registrations[i]['date_to'] = moment(new Date(date.range1.endDate)).format('YYYY-MM-DD')
                                this.setState({
                                  registrations
                                })
                              }}
                              style={{ backgroundColor: '#fff' }}
                            />
                          }
                        </td>
                        <td colSpan="3">
                          <div className='d-flex flex-row align-items-center justify-content-center' style={{ height: '60px' }}>
                            <ButtonGroup size="sm">
                              {days.map((vv) => {
                                let sessionDayName = 'session_' + vv.slice(0, 3).toLowerCase()
                                let disabled = false
                                if (weekend_days.includes(vv.toLowerCase())) {
                                  disabled = true
                                }
                                let checked = false

                                console.log(sessionDayName);
                                if (v[sessionDayName] == 1 || v[sessionDayName] == true) {
                                  checked = true
                                }
                                return <Button variant={checked ? "success" : "danger"} disabled={disabled} onClick={(e) => this.setStateRegistration(i, sessionDayName, !v['session_' + vv.slice(0, 3).toLowerCase()])}>
                                  {vv}
                                </Button>
                              })}
                            </ButtonGroup>
                          </div>
                        </td>
                      </tr>
                    </Fragment>
                  })}
                </tbody>
              </Table>
            </div>
          </Col>
        </div>
      </Offcanvas.Body >

      <SweetAlert show={this.state.deleteModal.show} warning showCancel confirmBtnText={t('Yes, delete it!')} confirmBtnBsStyle='danger' title={t('Are you sure?')} onConfirm={this.state.deleteModal.action} onCancel={() => this.setState({ deleteModal: { ...this.state.deleteModal, show: false, id: 0, action: () => { } } })} focusCancelBtn>
        {t('Confirm Delete!')}
      </SweetAlert>

    </Offcanvas>)
  }
}

const mapStateToProps = (state) => ({
  authData: state.auth.authData,
  defaultTheme: state.theme.defaultTheme,
  selectedClass: state.selectedClass.data,
  defaultLanguage: state.language.defaultLanguage,
  addEditRegistrationSimpleModal: state.addEditRegistrationSimpleModal,
});

const mapDispatchToProps = () => ({
  toggleRegistrationSimpleModal,
});

export default connect(mapStateToProps, mapDispatchToProps())(AddEditRegistrationSimple);
