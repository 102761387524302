import React, { Component } from 'react';
import {
  Container,
  Card,
  Row,
  Col,
  ButtonGroup,
  Button,
  DropdownButton,
  Dropdown,
  Form,
  Modal,
  Spinner,
  Table,
  InputGroup,
  Badge,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlus,
  faCheckCircle,
  faSearch,
  faUser,
  faXmark,
  faLayerGroup,
  faHandsHoldingChild,
  faPeopleGroup,
  faListCheck,
  faPrint,
  faPencil,
  faTrash,
  faCheckDouble,
  faSortDown,
  faSortUp,
  faThumbsUp,
  faThumbsDown,
  faL,
  faChevronUp,
  faChevronDown,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import {
  faCalendar,
  faEye,
  faEyeSlash,
  faFileExcel,
  faFilePdf,
} from '@fortawesome/free-regular-svg-icons';
import { t } from '../../helpers/translation_helper';
import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';
import Select from 'react-select';
import NavBar from '../_partials/NavBar/_NavBar';
import Header from '../_partials/Header/_Header';
import { connect } from 'react-redux';
import moment from 'moment';
import { brand_colors } from '../../helpers/brand_colors_helper';
import { GET, POST } from '../../api';
import { toast } from 'react-toastify';
import { handleFormErrors } from '../../helpers/form_helpers';
import { DateRangePicker } from 'react-date-range';
import axios from 'axios';
import Pagination from '../_partials/Pagination';
import { renderTableBody, renderTableHead } from '../../helpers/table_helper';
var Chance = require('chance');
var chance = new Chance();
let cancelToken;

class CenterRoles extends Component {
  searchFieldRef = React.createRef();

  defaultFormFields = {
    crole_id: 0,
    crole_name: '',
    role_id: null,
    crole_status: true,
  };

  state = {
    ...this.defaultFormFields,
    working: false,
    isEditing: false,
    showFormModal: false,
    roles: [],
    cdt: {
      fetching: false,
      search: '',
      rows: 10,
      offset: 0,
      order: 'center_roles.crole_id',
      dir: 'DESC',
      lastPage: 0,
      page: 0,
      totalRecords: 0,
      rowsOptions: [10, 25, 50, 'All'],
      columns: [
        {
          id: 'center_roles.crole_id',
          column: 'crole_id',
          name: 'Actions',
          visibility: true,
          sortable: true,
        },
        {
          id: 'center_roles.crole_name',
          column: 'crole_name',
          name: 'Center Role',
          visibility: true,
          sortable: true,
        },
        {
          id: 'roles.role_name',
          column: 'role_name',
          name: 'Role',
          visibility: true,
          sortable: true,
        },
        {
          id: 'center_roles.crole_status',
          column: 'crole_status',
          name: 'Status',
          visibility: true,
          sortable: true,
        },
      ],
      data: [],
    },
  };

  componentDidMount() {
    const { search, rows: limit, order, dir } = this.state.cdt;
    this.fetch({
      search,
      limit,
      order,
      dir,
      page: 0,
    });

    this.getDDLs();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.authData.centerData.center_id !=
      this.props.authData.centerData.center_id
    ) {
      const { search, rows: limit, order, dir } = this.state.cdt;
      this.fetch({
        search,
        limit,
        order,
        dir,
        page: 0,
      });
    }
  }

  fetch = async (params) => {
    this.setState({
      cdt: {
        ...this.state.cdt,
        fetching: true,
      },
    });

    if (cancelToken) {
      cancelToken.abort();
    }
    cancelToken = new AbortController();

    try {
      const res = await GET('center-roles/get', {
        signal: cancelToken.signal,
        params: {
          search: params.search,
          order: params.order,
          dir: params.dir,
          limit: params.limit,
          offset: parseInt(params.page) * parseInt(params.limit),
        },
      });

      if (res.status === 200) {
        this.setState({
          cdt: {
            ...this.state.cdt,
            fetching: false,
            search: params.search,
            rows: parseInt(params.limit),
            offset: parseInt(params.page) * parseInt(params.limit),
            order: params.order,
            dir: params.dir,
            page: params.page,
            totalRecords: parseInt(res.data.total_count_filtered),
            lastPage: Math.ceil(
              parseInt(res.data.total_count_filtered) / parseInt(params.limit)
            ),
            data: res.data.data.map((item) => {
              return {
                uid: chance.guid(),
                ...item,
              };
            }),
          },
        });
      }
    } catch (err) {
      console.log('err', err);
      this.setState({
        cdt: {
          ...this.state.cdt,
          fetching: false,
        },
      });
    }
  };

  getDDLs = async () => {
    try {
      const res = await GET('center-roles/get-roles');
      this.setState({
        roles: res.data.map((item) => {
          return {
            value: item.role_id,
            label: item.role_name,
          };
        }),
      });
    } catch (err) {
      console.log(err);
    }
  };

  handleOpenFormModal = () => {
    this.setState({
      showFormModal: true,
    });
  };

  handleCloseFormModal = () => {
    this.setState({
      showFormModal: false,
      isEditing: false,
      ...this.defaultFormFields,
    });
  };

  showHideColumn = (column_index) => {
    const columns = this.state.cdt.columns;
    columns[column_index].visibility = !columns[column_index].visibility;
    this.setState({ ctd: { ...this.state.ctd, columns } });
  };

  exportExcel = async () => {
    const excelData = this.state.cdt.data.map((item) => {
      const row = {};
      this.state.cdt.columns
        .filter((column) => column.visibility)
        .map((column) => {
          if (column.name !== 'Actions') {
            row[column.name] = item[column.column];
          }
        });
      return row;
    });

    const fileName = 'Center Roles Export ' + moment().format('DD-MM-YYYY');
    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const ws = XLSX.utils.json_to_sheet(excelData);

    const cellStyle = {
      font: {
        bold: true,
      },
    };

    Array.from('AB').map((letter) => {
      ws[letter + '1'].s = cellStyle;
    });

    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });

    FileSaver.saveAs(data, fileName + fileExtension);
  };

  renderCell = (column_data, column_id, column_index, dataObj) => {
    const { authData } = this.props;
    const { center_id } = authData.centerData;

    let tdData;

    // --------------------------------------------
    if (column_id == 'crole_id') {
      // Action Buttons
      tdData = (
        <>
          <Button
            onClick={() => this.delete(dataObj)}
            variant='danger'
            size='sm'
            className='me-1'>
            <FontAwesomeIcon icon={faTrash} />
          </Button>
          <Button
            onClick={() => this.edit(dataObj)}
            variant='warning'
            size='sm'
            className='me-1'>
            <FontAwesomeIcon icon={faPencil} />
          </Button>
        </>
      );
      // ------------------------------------------------------------------------
    } else if (column_id == 'crole_status') {
      tdData =
        dataObj.crole_status == 0 ? (
          <Badge bg='danger'>{t('Disabled')}</Badge>
        ) : (
          <Badge bg='success'>{t('Enabled')}</Badge>
        );
    } else {
      tdData = column_data;
    }

    return <td key={column_index}>{tdData}</td>;
  };

  saveUpdate = async () => {
    const { authData } = this.props;
    const { center_id } = authData.centerData;

    this.setState({ working: true });

    const { crole_id, crole_name, role_id, crole_status } = this.state;

    const fData = new FormData();
    fData.append('center_id', center_id);
    fData.append(
      'croleData',
      JSON.stringify({
        crole_id,
        crole_name,
        role_id: role_id.value,
        crole_status: crole_status ? 1 : 0,
      })
    );

    try {
      const res = await POST('center-roles/save', fData);
      if (res.data.type) {
        this.setState({ working: false });
        toast.success(t(res.data.message));
        this.fetch({
          search: '',
          limit: this.state.cdt.rows,
          order: this.state.cdt.order,
          dir: this.state.cdt.dir,
          page: 0,
        });
        this.handleCloseFormModal();
      } else {
        // toast.error(t(res.data.message));
        handleFormErrors(res.data.data);
        this.setState({ working: false });
      }
    } catch (err) {
      console.log('err', err);
      toast.error(t('Something went wrong!'));

      this.setState({ working: false });
    }
  };

  edit = (data) => {
    console.log('data', data);
    this.setState({
      isEditing: true,
      crole_id: data.crole_id,
      crole_name: data.crole_name,
      crole_status: data.crole_status == 0 ? false : true,
      role_id: data.role_id
        ? {
            value: data.role_id,
            label: data.role_name,
          }
        : null,
    });
    this.handleOpenFormModal();
  };

  delete = async (data) => {
    try {
      const res = await GET('center-roles/delete', {
        params: {
          crole_id: data.crole_id,
        },
      });
      this.setState({
        cdt: {
          ...this.state.cdt,
          data: this.state.cdt.data.filter((item) => item.uid !== data.uid),
        },
      });
      toast.success(t(res.data.message));
    } catch (err) {
      console.log(err);
      toast.error(t('Something went wrong!'));
    }
  };

  render() {
    console.log('this.state', this.state);
    const { cdt } = this.state;
    return (
      <Container fluid>
        <div id='iedu-layout'>
          <NavBar />
          <div id='page-content' className='employees-list'>
            <Header
              lite={true}
              heading={t('Roles')}
              centerSelectorFunc={[]}
              showClassSelector={false}
              showCenterSelector={true}
              backBtn={true}
            />
            <div
              className='shadow-box'
              style={{
                marginInline: 5,
              }}>
              <Card className='border-0' style={{ borderRadius: '20px' }}>
                <Card.Header
                  style={{
                    backgroundColor: '#fff',
                    borderTopLeftRadius: '20px',
                    borderTopRightRadius: '20px',
                  }}>
                  <Row>
                    <Col md={6} className='d-flex align-items-center'>
                      <ButtonGroup size='sm' className='me-3 float-left'>
                        {/* <Button><FontAwesomeIcon icon={faFilePdf} /> {t('PDF')}</Button> */}
                        <Button onClick={() => this.exportExcel()}>
                          <FontAwesomeIcon icon={faFileExcel} /> {t('Excel')}
                        </Button>
                        {/* <Button><FontAwesomeIcon icon={faPrint} /> {t('Print')}</Button> */}
                        <DropdownButton
                          autoClose={'outside'}
                          size='sm'
                          as={ButtonGroup}
                          title={t('Column Visibility')}>
                          {this.state.cdt.columns.map(
                            (column, column_index) => (
                              <Dropdown.Item
                                key={column_index}
                                className={
                                  column.visibility
                                    ? 'column-name-item'
                                    : 'column-name-item active'
                                }
                                onClick={() =>
                                  this.showHideColumn(column_index)
                                }>
                                <FontAwesomeIcon
                                  icon={column.visibility ? faEye : faEyeSlash}
                                />{' '}
                                {column.name}
                              </Dropdown.Item>
                            )
                          )}
                        </DropdownButton>
                      </ButtonGroup>
                      <div style={{ display: 'inline-block' }}>
                        {t('Show')}
                        <Form.Select
                          value={this.state.cdt.rows}
                          size='sm'
                          onChange={(e) =>
                            this.fetch({
                              search: this.state.cdt.search,
                              limit: e.target.value,
                              order: this.state.cdt.order,
                              dir: this.state.cdt.dir,
                              page: this.state.cdt.page,
                            })
                          }
                          style={{
                            width: 70,
                            marginInline: 5,
                            display: 'inline-block',
                          }}>
                          {this.state.cdt.rowsOptions.map((row, row_index) => (
                            <option key={row_index}>{row}</option>
                          ))}
                        </Form.Select>
                        {t('Records')}
                      </div>
                    </Col>
                    <Col md={6} className='d-flex justify-content-end'>
                      <div className='cdt-search'>
                        <input
                          type='text'
                          ref={this.searchFieldRef}
                          defaultValue={this.state.cdt.search}
                          onChange={(e) =>
                            this.fetch({
                              search: e.target.value,
                              limit: this.state.cdt.rows,
                              order: this.state.cdt.order,
                              dir: this.state.cdt.dir,
                              page: 0,
                            })
                          }
                          placeholder={t('Search...')}
                        />
                        <button
                          onClick={() => {
                            this.fetch({
                              search: '',
                              limit: this.state.cdt.rows,
                              order: this.state.cdt.order,
                              dir: this.state.cdt.dir,
                              page: 0,
                            });
                            this.searchFieldRef.current.value = '';
                          }}>
                          <FontAwesomeIcon
                            icon={
                              this.state.cdt.search == '' ? faSearch : faXmark
                            }
                            color={
                              brand_colors[this.props.defaultTheme.theme_id]
                                .color18
                            }
                          />
                        </button>
                      </div>
                      <button
                        onClick={this.handleOpenFormModal}
                        className='btn btn-success btn-sm ms-3'>
                        <FontAwesomeIcon
                          icon={faPlus}
                          color={
                            brand_colors[this.props.defaultTheme.theme_id]
                              .color8
                          }
                          style={{ fontSize: 12 }}
                        />
                      </button>
                    </Col>
                  </Row>
                </Card.Header>
                <Card.Body style={{ paddingTop: 8 }}>
                  <Row>
                    {cdt.fetching && (
                      <Col
                        md={12}
                        className='py-5 d-flex flex-column align-items-center'>
                        <Spinner as='span' animation='grow' size='sm' />{' '}
                        {t('Loading Data')}
                      </Col>
                    )}
                    {!cdt.fetching && cdt.data.length === 0 && (
                      <Col
                        md={12}
                        className='py-5 d-flex flex-column align-items-center'>
                        {t('No Data Found')}
                      </Col>
                    )}
                    {!cdt.fetching && cdt.data.length !== 0 && (
                      <Col md={12} className='pt-2'>
                        <div
                          id='datatable-list'
                          className='mb-3'
                          style={{ width: 'initial' }}>
                          <Table responsive>
                            {renderTableHead(this)}
                            {renderTableBody(this)}
                          </Table>
                        </div>
                      </Col>
                    )}
                  </Row>
                  <Pagination
                    pageCount={this.state.cdt.lastPage || 1}
                    forcePage={this.state.cdt.page}
                    callbackParams={{
                      search: this.state.cdt.search,
                      limit: this.state.cdt.rows,
                      order: this.state.cdt.order,
                      dir: this.state.cdt.dir,
                    }}
                    callback={this.fetch}
                  />
                </Card.Body>
              </Card>
            </div>
          </div>
        </div>
        <Modal
          backdrop='static'
          keyboard={false}
          show={this.state.showFormModal}
          onHide={this.handleCloseFormModal}>
          <Modal.Header closeButton>
            <Modal.Title>
              {this.state.isEditing ? t('Edit') : t('Add')} {t('Role')}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className='custom-styled-form'>
            <Row>
              <Col md={6}>
                <Form.Group className='mb-3'>
                  <Form.Label>{t('Name')}</Form.Label>
                  <Form.Control
                    type='text'
                    defaultValue={this.state.crole_name}
                    onChange={(e) =>
                      this.setState({ crole_name: e.target.value })
                    }
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className='mb-3'>
                  <Form.Label>{t('Role')}</Form.Label>
                  <Select
                    value={this.state.role_id}
                    menuPortalTarget={document.body}
                    styles={{
                      menuPortal: (base) => ({
                        ...base,
                        zIndex: 9999,
                      }),
                    }}
                    options={this.state.roles}
                    onChange={(obj) => this.setState({ role_id: obj })}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group className='mb-3'>
                  <Form.Check
                    type='switch'
                    label={t('Status')}
                    onChange={() =>
                      this.setState({ crole_status: !this.state.crole_status })
                    }
                    checked={this.state.crole_status}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' onClick={this.handleCloseFormModal}>
              Close
            </Button>
            <Button
              disabled={this.state.working}
              variant='success'
              onClick={() => this.saveUpdate()}>
              {t('Save')}{' '}
              {this.state.working ? (
                <Spinner
                  as='span'
                  animation='grow'
                  size='sm'
                  role='status'
                  aria-hidden='true'
                />
              ) : (
                ''
              )}
            </Button>
          </Modal.Footer>
        </Modal>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  authData: state.auth.authData,
  defaultLanguage: state.language.defaultLanguage,
  defaultTheme: state.theme.defaultTheme,
});

export default connect(mapStateToProps, null)(CenterRoles);
