import React, { Component } from 'react';
import { Container, Card, Table, Row, Col, Button, Offcanvas, Modal, Form, Spinner, Tabs, Tab, Badge, Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleLeft, faFilePdf, faPlus, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { faCircle, faCheckCircle, faCircleDot, faComments } from '@fortawesome/free-regular-svg-icons';
import { brand_colors } from '../../../helpers/brand_colors_helper';
import { t } from '../../../helpers/translation_helper';
import { connect } from 'react-redux';
import { setUpdateSchoolModal } from '../../../redux/slices/updateSchoolModalSlice';
import { setAuthentication } from '../../../redux/slices/authSlice';
import axios from 'axios';
import { toast } from 'react-toastify';
import Select from 'react-select';
import Flatpickr from 'react-flatpickr';
import moment from 'moment';
import { GET } from '../../../api';

// API URL
const apiURL = process.env.REACT_APP_API_URL;

class UpdateSchoolModal extends Component {

  logo = React.createRef();
  center_stamp = React.createRef();
  ministry_logo = React.createRef();

  formSections = [
    {
      uid: '1',
      active: true,
      completed: false,
      name: 'General',
      desc: null,
      data: [
        {
          uid: '1.1',
          active: false,
          completed: false,
          name: 'Center Name',
          key: 'center_name',
          desc: null,
          image: require('../../../assets/images/lesson/lesson-focus.png'),
        },
        {
          uid: '1.2',
          active: false,
          completed: false,
          name: 'Contact Title',
          key: 'contact_title',
          desc: null,
          image: require('../../../assets/images/lesson/lesson-focus.png'),
        },
        {
          uid: '1.3',
          active: false,
          completed: false,
          name: 'Contact Name',
          key: 'contact_name',
          desc: null,
          image: require('../../../assets/images/lesson/lesson-focus.png'),
        },
        {
          uid: '1.4',
          active: false,
          completed: false,
          name: 'Capacity',
          key: 'capacity',
          desc: null,
          image: require('../../../assets/images/lesson/lesson-focus.png'),
        },
        {
          uid: '1.5',
          active: false,
          completed: false,
          name: 'Center Description',
          key: 'center_desc',
          desc: null,
          image: require('../../../assets/images/lesson/lesson-focus.png'),
        },
        {
          uid: '1.6',
          active: false,
          completed: false,
          name: 'Phone 1',
          key: 'phone1',
          desc: null,
          image: require('../../../assets/images/lesson/lesson-focus.png'),
        },
        {
          uid: '1.7',
          active: false,
          completed: false,
          name: 'Phone 2',
          key: 'phone2',
          desc: null,
          image: require('../../../assets/images/lesson/lesson-focus.png'),
        },
        {
          uid: '1.8',
          active: false,
          completed: false,
          name: 'Phone 3',
          key: 'phone3',
          desc: null,
          image: require('../../../assets/images/lesson/lesson-focus.png'),
        },
        {
          uid: '1.9',
          active: false,
          completed: false,
          name: 'Fax 1',
          key: 'fax1',
          desc: null,
          image: require('../../../assets/images/lesson/lesson-focus.png'),
        },
        {
          uid: '1.10',
          active: false,
          completed: false,
          name: 'Fax 2',
          key: 'fax2',
          desc: null,
          image: require('../../../assets/images/lesson/lesson-focus.png'),
        },
        {
          uid: '1.11',
          active: false,
          completed: false,
          name: 'Fax 3',
          key: 'fax3',
          desc: null,
          image: require('../../../assets/images/lesson/lesson-focus.png'),
        },
        {
          uid: '1.12',
          active: false,
          completed: false,
          name: 'Email 1',
          key: 'email1',
          desc: null,
          image: require('../../../assets/images/lesson/lesson-focus.png'),
        },
        {
          uid: '1.13',
          active: false,
          completed: false,
          name: 'Email 2',
          key: 'email2',
          desc: null,
          image: require('../../../assets/images/lesson/lesson-focus.png'),
        },
        {
          uid: '1.14',
          active: false,
          completed: false,
          name: 'Email 3',
          key: 'email3',
          desc: null,
          image: require('../../../assets/images/lesson/lesson-focus.png'),
        },
        {
          uid: '1.15',
          active: false,
          completed: false,
          name: 'Country',
          key: 'country_id',
          desc: null,
          image: require('../../../assets/images/lesson/lesson-focus.png'),
        },
        {
          uid: '1.16',
          active: false,
          completed: false,
          name: 'Currency Code',
          key: 'currencycode',
          desc: null,
          image: require('../../../assets/images/lesson/lesson-focus.png'),
        },
        {
          uid: '1.17',
          active: false,
          completed: false,
          name: 'GMT Timezone',
          key: 'center_timezone',
          desc: null,
          image: require('../../../assets/images/lesson/lesson-focus.png'),
        },
        {
          uid: '1.18',
          active: false,
          completed: false,
          name: 'Address',
          key: 'address',
          desc: null,
          image: require('../../../assets/images/lesson/lesson-focus.png'),
        },
        {
          uid: '1.19',
          active: false,
          completed: false,
          name: 'Class Image',
          key: 'logo',
          desc: null,
          image: require('../../../assets/images/lesson/lesson-focus.png'),
        },
        {
          uid: '1.20',
          active: false,
          completed: false,
          name: 'Start',
          key: 'chat_timing_start',
          desc: null,
          image: require('../../../assets/images/lesson/lesson-focus.png'),
        },
        {
          uid: '1.21',
          active: false,
          completed: false,
          name: 'End',
          key: 'chat_timing_end',
          desc: null,
          image: require('../../../assets/images/lesson/lesson-focus.png'),
        },
      ],
    },
    {
      uid: '3',
      active: false,
      completed: false,
      name: 'Social Media',
      desc: null,
      data: [
        {
          uid: '3.1',
          active: false,
          completed: false,
          name: 'Website',
          key: 'urlwww',
          desc: null,
          image: require('../../../assets/images/lesson/lesson-focus.png'),
        },
        {
          uid: '3.2',
          active: false,
          completed: false,
          name: 'Facebook',
          key: 'urlfb',
          desc: null,
          image: require('../../../assets/images/lesson/lesson-focus.png'),
        },
        {
          uid: '3.3',
          active: false,
          completed: false,
          name: 'Twitter',
          key: 'urltw',
          desc: null,
          image: require('../../../assets/images/lesson/lesson-focus.png'),
        },
        {
          uid: '3.4',
          active: false,
          completed: false,
          name: 'Instagram',
          key: 'urlinstagram',
          desc: null,
          image: require('../../../assets/images/lesson/lesson-focus.png'),
        },
        {
          uid: '3.5',
          active: false,
          completed: false,
          name: 'Youtube',
          key: 'urlyoutube',
          desc: null,
          image: require('../../../assets/images/lesson/lesson-focus.png'),
        },
        {
          uid: '3.6',
          active: false,
          completed: false,
          name: 'Cloud Folder',
          key: 'urlcloud',
          desc: null,
          image: require('../../../assets/images/lesson/lesson-focus.png'),
        },
      ]
    },
    {
      uid: '5',
      active: false,
      completed: false,
      name: 'Financial',
      desc: null,
      data: [
        {
          uid: '5.1',
          active: false,
          completed: false,
          name: 'Establishment # For WPS',
          key: 'establishment_num',
          desc: null,
          image: require('../../../assets/images/lesson/lesson-focus.png'),
        },
        {
          uid: '5.2',
          active: false,
          completed: false,
          name: 'Bank Code For WPS',
          key: 'bank_code',
          desc: null,
          image: require('../../../assets/images/lesson/lesson-focus.png'),
        },
        {
          uid: '5.3',
          active: false,
          completed: false,
          name: 'VAT TRN Number',
          key: 'vat_num',
          desc: null,
          image: require('../../../assets/images/lesson/lesson-focus.png'),
        },
        {
          uid: '5.4',
          active: false,
          completed: false,
          name: 'Invoice Print Text',
          key: 'print_invoice_text',
          desc: null,
          image: require('../../../assets/images/lesson/lesson-focus.png'),
        },
        {
          uid: '5.5',
          active: false,
          completed: false,
          name: 'Receipt Print Text',
          key: 'print_receipt_text',
          desc: null,
          image: require('../../../assets/images/lesson/lesson-focus.png'),
        },
        {
          uid: '5.6',
          active: false,
          completed: false,
          name: 'Bank Details',
          key: 'bank_details',
          desc: null,
          image: require('../../../assets/images/lesson/lesson-focus.png'),
        },
      ]
    },
    {
      uid: '6',
      active: false,
      completed: false,
      name: 'Map',
      desc: null,
      data: [
        {
          uid: '6.1',
          active: false,
          completed: false,
          name: 'Latitude',
          key: 'latitude',
          desc: null,
          image: require('../../../assets/images/lesson/lesson-focus.png'),
        },
        {
          uid: '6.2',
          active: false,
          completed: false,
          name: 'Longitude',
          key: 'longitude',
          desc: null,
          image: require('../../../assets/images/lesson/lesson-focus.png'),
        },
      ]
    },
    {
      uid: '8',
      active: false,
      completed: false,
      name: 'License',
      desc: null,
      data: [
        {
          uid: '8.1',
          active: false,
          completed: false,
          name: 'Start Date',
          key: 'center_contract_startdate',
          desc: null,
          image: require('../../../assets/images/lesson/lesson-focus.png'),
        },
        {
          uid: '8.2',
          active: false,
          completed: false,
          name: 'End Date',
          key: 'center_contract_expiry_date',
          desc: null,
          image: require('../../../assets/images/lesson/lesson-focus.png'),
        },
      ]
    }
  ];

  state = {
    working: false,
    formSectionActive: {
      parent: '1',
      child: '1.1',
    },
    counties: null,
    currencycodes: null,
    formSections: this.formSections,
    // 👇 Form Fields
    center_id: 0,
    center_name: '',
    contact_title: '',
    contact_name: '',
    capacity: '',
    center_desc: '',
    phone1: '',
    phone2: '',
    phone3: '',
    fax1: '',
    fax2: '',
    fax3: '',
    email1: '',
    email2: '',
    email3: '',
    urlwww: '',
    urlfb: '',
    urltw: '',
    urlinstagram: '',
    urlyoutube: '',
    urlcloud: '',
    country_id: '',
    currencycode: '',
    center_timezone: '',
    address: '',
    logo: '',
    center_stamp: '',
    ministry_logo: '',
    establishment_num: '',
    bank_code: '',
    vat_num: '',
    print_invoice_text: '',
    print_receipt_text: '',
    bank_details: '',
    latitude: '',
    longitude: '',
    chat_timing_start: '',
    chat_timing_end: '',
    center_contract_startdate: '',
    center_contract_expiry_date: '',
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
    sunday: false,
  }

  componentDidMount() {
    this.getCountries();
    this.getCurrencycodes();
    this.updateCenterDataFields();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.authData.centerData.center_id !== this.props.authData.centerData.center_id) {
      this.updateCenterDataFields();
    }
  }

  updateCenterDataFields = () => {
    const centerData = this.props.authData.centerData;

    console.log('centerData', centerData);

    var centerWeekend = centerData.weekend_days.split(',');

    this.setState({
      center_id: centerData.center_id,
      center_name: centerData.center_name || '',
      contact_title: centerData.contact_title || '',
      contact_name: centerData.contact_name || '',
      capacity: centerData.capacity || '',
      center_desc: centerData.center_desc || '',
      phone1: centerData.phone1 || '',
      phone2: centerData.phone2 || '',
      phone3: centerData.phone3 || '',
      fax1: centerData.fax1 || '',
      fax2: centerData.fax2 || '',
      fax3: centerData.fax3 || '',
      email1: centerData.email1 || '',
      email2: centerData.email2 || '',
      email3: centerData.email3 || '',
      urlwww: centerData.urlwww || '',
      urlfb: centerData.erlfb || '',
      urltw: centerData.urltw || '',
      urlinstagram: centerData.urlinstagram || '',
      urlyoutube: centerData.urlyoutube || '',
      urlcloud: centerData.urlcloud || '',
      country_id: centerData.country_id ? { label: centerData.name_en, value: centerData.country_id } : '',
      currencycode: centerData.currencycode ? { label: centerData.currencycode, value: centerData.currencycode } : '',
      center_timezone: centerData.center_timezone || '',
      address: centerData.address || '',
      logo: centerData.logo || '',
      logo_prev: centerData.logo || '',
      center_stamp: centerData.center_stamp || '',
      center_stamp_prev: centerData.center_stamp || '',
      ministry_logo: centerData.ministry_logo || '',
      ministry_logo_prev: centerData.ministry_logo || '',
      establishment_num: centerData.establishment_num || '',
      bank_code: centerData.bank_code || '',
      vat_num: centerData.vat_num || '',
      print_invoice_text: centerData.print_invoice_text || '',
      print_receipt_text: centerData.print_receipt_text || '',
      bank_details: centerData.bank_details || '',
      latitude: centerData.latitude || '',
      longitude: centerData.longitude || '',
      chat_timing_start: centerData.chat_timing.split(' - ')[0] || '',
      chat_timing_end: centerData.chat_timing.split(' - ')[1] || '',
      center_contract_startdate: centerData.center_contract_startdate || '',
      center_contract_expiry_date: centerData.center_contract_expiry_date || '',
      monday: centerWeekend.includes('monday') ? true : false,
      tuesday: centerWeekend.includes('tuesday') ? true : false,
      wednesday: centerWeekend.includes('wednesday') ? true : false,
      thursday: centerWeekend.includes('thursday') ? true : false,
      friday: centerWeekend.includes('friday') ? true : false,
      saturday: centerWeekend.includes('saturday') ? true : false,
      sunday: centerWeekend.includes('sunday') ? true : false,
    }, () => this.checkCompletedFormSections());
  }

  getCountries = async () => {

    const { authData } = this.props;
    const { auth_key, role_id } = authData.loginData;
    const { center_id } = authData.centerData;

    try {
      const res = await GET('admin/get-countries');

      if (res.status == 200) {
        const countries = res.data.map((countryData) => {
          countryData.label = countryData.name_en;
          countryData.value = countryData.country_id;
          return countryData;
        });

        this.setState({ countries });
      }

    } catch (err) {
      console.log('err', err)

      toast.error('Something went wrong');
    }
  }

  getCurrencycodes = async () => {

    const { authData } = this.props;
    const { auth_key, role_id } = authData.loginData;
    const { center_id } = authData.centerData;

    try {
      const res = await GET('admin/get-currencycodes');

      if (res.status == 200) {
        const currencycodes = res.data.map((cc) => {
          cc.label = cc.currencycode;
          cc.value = cc.currencycode;
          return cc;
        });

        this.setState({ currencycodes });
      }

    } catch (err) {
      console.log('err', err)

      toast.error('Something went wrong');
    }
  }

  handleClose = () => {
    this.props.setUpdateSchoolModal(false);
    // this.setState({ formSections: this.formSections }, () => this.checkCompletedFormSections());
  }

  // MARK FORM SECTIONS AS COMPLETE AFTER VERIFYING THE REQUIRED FIELDS DATA
  checkCompletedFormSections = () => {
    let formSections = this.state.formSections;
    formSections = formSections.map((v, i) => {
      let data = v.data;
      let sectionComplete = true;
      data = data.map((vv, ii) => {

        let subSectionComplete = false;
        if (this.state[vv.key] != '') {
          subSectionComplete = true;
        } else {
          subSectionComplete = false;
          sectionComplete = false;
        }
        return { ...vv, completed: subSectionComplete };

      });
      return { ...v, data, completed: sectionComplete };
    });
    this.setState({ formSections: formSections });
  };
  // CHANGE AND SCROLL TO SECTION
  changeToFromSection = (parent, child) => {
    this.setState(
      {
        formSectionActive: { parent: parent, child: child },
      },
      () => {
        setTimeout(() => {
          const element = document.getElementById('form_section_' + parent);
          if (element) {
            // 👇 Will scroll smoothly to the top of the next section
            element.scrollIntoView({ behavior: 'smooth' });
          }
        }, 300);
      }
    );
  };

  previewFile = (e) => {
    const reader = new FileReader();

    const selectedFile = e.target.files[0];

    if (selectedFile) {
      reader.readAsDataURL(selectedFile);
    }

    if (
      !selectedFile.type.includes('image')
    ) {
      toast.error(t('Only image file is allowed!'));
      return;
    }

    reader.onload = (readerEvent) => {
      if (selectedFile.type.includes('image')) {
        this.setState({
          [e.target.name + '_prev']: readerEvent.target.result,
          [e.target.name]: e.target.files[0],
        });
      } else if (selectedFile.type.includes('pdf')) {
        this.setState({
          [e.target.name + '_prev']: 'pdf',
          [e.target.name]: e.target.files[0],
        });
      }
    };
  };

  updateCenter = async () => {
    this.setState({ working: true });

    const { auth_key } = this.props.authData.loginData;
    const { center_id, center_name, contact_title, contact_name, capacity, center_desc, phone1, phone2, phone3, fax1, fax2, fax3, email1, email2, email3, urlwww, urlfb, urltw, urlinstagram, urlyoutube, urlcloud, country_id, currencycode, center_timezone, address, logo, center_stamp, ministry_logo, establishment_num, bank_code, vat_num, print_invoice_text, print_receipt_text, bank_details, latitude, longitude, chat_timing_start, chat_timing_end, center_contract_startdate, center_contract_expiry_date } = this.state;

    var days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

    var weekend_days = days.filter(day => this.state[day]);

    if (logo == '') {
      toast.error(t('Logo is required!'));
      this.setState({ working: false });
      return;
    }

    console.log('this.state.logo', this.state.logo);
    console.log('this.state.center_stamp', this.state.center_stamp);
    console.log('this.state.ministry_logo', this.state.ministry_logo);

    const fData = new FormData();

    fData.append('center_id', center_id);
    fData.append('Center[center_id]', center_id);
    fData.append('Center[center_name]', center_name);
    fData.append('Center[contact_title]', contact_title);
    fData.append('Center[contact_name]', contact_name);
    fData.append('Center[capacity]', capacity);
    fData.append('Center[center_desc]', center_desc);
    fData.append('Center[phone1]', phone1);
    fData.append('Center[phone2]', phone2);
    fData.append('Center[phone3]', phone3);
    fData.append('Center[fax1]', fax1);
    fData.append('Center[fax2]', fax2);
    fData.append('Center[fax3]', fax3);
    fData.append('Center[email1]', email1);
    fData.append('Center[email2]', email2);
    fData.append('Center[email3]', email3);
    fData.append('Center[urlwww]', urlwww);
    fData.append('Center[urlfb]', urlfb);
    fData.append('Center[urltw]', urltw);
    fData.append('Center[urlinstagram]', urlinstagram);
    fData.append('Center[urlyoutube]', urlyoutube);
    fData.append('Center[urlcloud]', urlcloud);
    fData.append('Center[country_id]', country_id.value);
    fData.append('Center[currencycode]', currencycode.value);
    fData.append('Center[center_timezone]', center_timezone);
    fData.append('Center[address]', address);
    fData.append('Center[logo]', logo);
    fData.append('Center[center_stamp]', center_stamp);
    fData.append('Center[ministry_logo]', ministry_logo);
    fData.append('Center[establishment_num]', establishment_num);
    fData.append('Center[bank_code]', bank_code);
    fData.append('Center[vat_num]', vat_num);
    fData.append('Center[print_invoice_text]', print_invoice_text);
    fData.append('Center[print_receipt_text]', print_receipt_text);
    fData.append('Center[bank_details]', bank_details);
    fData.append('Center[latitude]', latitude);
    fData.append('Center[longitude]', longitude);
    fData.append('Center[chat_timing_start]', chat_timing_start);
    fData.append('Center[chat_timing_end]', chat_timing_end);
    fData.append('Center[center_contract_startdate]', center_contract_startdate);
    fData.append('Center[center_contract_expiry_date]', center_contract_expiry_date);
    fData.append('Center[weekend_days]', weekend_days.join(','));

    if (this.state.logo != '') {
      fData.append('file', this.state.logo);
    }

    if (this.state.center_stamp != '') {
      fData.append('center_stamp', this.state.center_stamp);
    }

    if (this.state.ministry_logo != '') {
      fData.append('ministry_logo', this.state.ministry_logo);
    }

    try {
      const res = await axios.post(apiURL + 'center/update-center', fData, {
        params: {
          auth_key: auth_key,
          appname: process.env.REACT_APP_NAME,
        },
      });

      console.log('res', res);

      if (res.data.type) {
        toast.success(res.data.message);
        this.props.setAuthentication({
          ...this.props.authData,
          // centerData: {
          //   ...this.props.authData.centerData, center_id, center_name, contact_title, contact_name, capacity, center_desc, phone1, phone2, phone3, fax1, fax2, fax3, email1, email2, email3, urlwww, urlfb, urltw, urlinstagram, urlyoutube, urlcloud,
          //   country_id: country_id.value,
          //   currencycode: currencycode.value,
          //   center_timezone, address,
          //   logo,
          //   center_stamp, ministry_logo, establishment_num, bank_code, vat_num, print_invoice_text, print_receipt_text, latitude, longitude, chat_timing_start, chat_timing_end, center_contract_startdate, center_contract_expiry_date,
          //   weekend_days: weekend_days.join(',')
          // }
          centerData: {
            ...res.data.data
          }
        });
      }

      this.setState({ working: false });

    } catch (err) {
      console.log('err', err)

      this.setState({ working: false });
      toast.error('Something went wrong!');
    }
  }

  render() {
    return (<Offcanvas
      show={this.props.updateSchoolModal}
      onHide={this.handleClose}
      placement='bottom'
      style={{ height: '100%' }}>
      <Offcanvas.Header id='iedu-header' style={{ marginTop: '1rem', marginRight: '1rem', marginLeft: '1rem', marginBottom: 0 }}>
        <div className='d-flex flex-row'>
          <span className='header-back-btn' onClick={this.handleClose} style={{ marginRight: 10 }}>
            <FontAwesomeIcon icon={faArrowAltCircleLeft} color={brand_colors[this.props.defaultTheme.theme_id].color9} />
          </span>
          <Offcanvas.Title>{t('Update School Info')}</Offcanvas.Title>
        </div>
        <div>
          <Button disabled={this.state.working} variant='warning' onClick={() => this.updateCenter()}>
            {t('Update')} {this.state.working ? <Spinner as='span' animation='grow' size='sm' role='status' aria-hidden='true' /> : ''}
          </Button>
        </div>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <div
          className={'d-flex flex-row grey-section'}
          style={{
            overflow: 'initial',
            backgroundImage: 'url(https://academic.educore.io/static/media/pegboard-bg.9b84e4936acef29bbcd9.png)',
            backgroundRepeat: 'repeat',
            backgroundPosition: '10px 12px',
            overflow: 'hidden',
          }}
        >
          <div style={{ backgroundColor: brand_colors[this.props.defaultTheme.theme_id].color8, marginRight: 10, borderRadius: '20px', width: '250px', height: '78vh', top: '5px', position: 'sticky', paddingLeft: 20, overflowY: 'auto' }}>
            <div style={{ paddingRight: '1.5rem', paddingBottom: '1.5rem', paddingTop: '1rem' }}>
              {this.state.formSections && this.state.formSections.map((v, i) => {
                return (
                  <div key={i}>
                    <div className='d-flex flex-row align-items-center' style={{ cursor: 'pointer', borderRadius: '20px', paddingLeft: '5px', paddingRight: '5px', backgroundColor: v.uid == this.state.formSectionActive.parent ? brand_colors[this.props.defaultTheme.theme_id].color19 : brand_colors[this.props.defaultTheme.theme_id].color8, zIndex: 1, position: 'relative' }} onClick={() => this.changeToFromSection(v.uid, v.uid + '.1')}>
                      <FontAwesomeIcon
                        icon={v.completed ? faCheckCircle : faCircle}
                        color={v.uid == this.state.formSectionActive.parent ? brand_colors[this.props.defaultTheme.theme_id].color20 : v.completed ? brand_colors[this.props.defaultTheme.theme_id].color4 : brand_colors[this.props.defaultTheme.theme_id].color9}
                        style={{
                          fontSize: 12,
                          marginRight: 5,
                          opacity: v.uid == this.state.formSectionActive.parent || v.completed ? 1 : 0.7,
                        }}
                      />
                      <span style={{ fontSize: 18, fontWeight: '600', color: v.uid == this.state.formSectionActive.parent ? brand_colors[this.props.defaultTheme.theme_id].color20 : brand_colors[this.props.defaultTheme.theme_id].color9, opacity: v.uid == this.state.formSectionActive.parent ? 1 : 0.7 }}>{v.name}</span>
                    </div>

                    <div style={{ paddingLeft: '1rem', borderLeft: 'solid 1px brand_colors[this.props.defaultTheme.theme_id].color18', marginLeft: '1.5rem' }}>
                      {v.data.map((vv, ii) => {
                        return (
                          <div key={ii} className='d-flex flex-row align-items-center' style={{ marginBottom: 5, marginTop: 2, cursor: 'pointer' }} onClick={() => this.changeToFromSection(v.uid, vv.uid)}>
                            <FontAwesomeIcon icon={vv.completed ? faCheckCircle : faCircle} color={vv.completed ? brand_colors[this.props.defaultTheme.theme_id].color4 : brand_colors[this.props.defaultTheme.theme_id].color18} style={{ fontSize: 12, marginRight: 5 }} />
                            <span style={{ fontSize: 14, color: vv.completed ? brand_colors[this.props.defaultTheme.theme_id].color4 : brand_colors[this.props.defaultTheme.theme_id].color18 }}>{vv.name}</span>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <Col className='custom-styled-form hide-scrollbar' style={{ backgroundColor: brand_colors[this.props.defaultTheme.theme_id].color8, marginLeft: 10, borderRadius: '20px', padding: '1rem', overflow: 'auto', height: '78vh' }}>
            {this.state.formSectionActive.parent == '1' && (
              <div id='form_section_1'>
                <h5>{t('General')}</h5>
                <Row>
                  <Col md={3}>
                    <Form.Group className="mb-3">
                      <Form.Label>{t('Center Name')}</Form.Label>
                      <Form.Control type="text"
                        defaultValue={this.state.center_name}
                        onBlur={(e) => this.setState({ center_name: e.target.value }, () => this.checkCompletedFormSections())} />
                    </Form.Group>
                  </Col>
                  <Col md={3}>
                    <Form.Group className="mb-3">
                      <Form.Label>{t('Contact Title')}</Form.Label>
                      <Form.Control type="text"
                        defaultValue={this.state.contact_title}
                        onBlur={(e) => this.setState({ contact_title: e.target.value }, () => this.checkCompletedFormSections())} />
                    </Form.Group>
                  </Col>
                  <Col md={3}>
                    <Form.Group className="mb-3">
                      <Form.Label>{t('Contact Name')}</Form.Label>
                      <Form.Control type="text"
                        defaultValue={this.state.contact_name}
                        onBlur={(e) => this.setState({ contact_name: e.target.value }, () => this.checkCompletedFormSections())} />
                    </Form.Group>
                  </Col>
                  <Col md={3}>
                    <Form.Group className="mb-3">
                      <Form.Label>{t('Capacity')}</Form.Label>
                      <Form.Control type="text"
                        defaultValue={this.state.capacity}
                        onBlur={(e) => this.setState({ capacity: e.target.value }, () => this.checkCompletedFormSections())} />
                    </Form.Group>
                  </Col>
                  <Col md={12}>
                    <Form.Group className="mb-3">
                      <Form.Label>{t('Center Description')}</Form.Label>
                      <Form.Control as="textarea" rows={3}
                        defaultValue={this.state.center_desc}
                        onBlur={(e) => this.setState({ center_desc: e.target.value }, () => this.checkCompletedFormSections())} />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <Form.Group className="mb-3">
                      <Form.Label>{t('Phone 1')}</Form.Label>
                      <Form.Control type="text"
                        defaultValue={this.state.phone1}
                        onBlur={(e) => this.setState({ phone1: e.target.value }, () => this.checkCompletedFormSections())} />
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group className="mb-3">
                      <Form.Label>{t('Phone 2')}</Form.Label>
                      <Form.Control type="text"
                        defaultValue={this.state.phone2}
                        onBlur={(e) => this.setState({ phone2: e.target.value }, () => this.checkCompletedFormSections())} />
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group className="mb-3">
                      <Form.Label>{t('Phone 3')}</Form.Label>
                      <Form.Control type="text"
                        defaultValue={this.state.phone3}
                        onBlur={(e) => this.setState({ phone3: e.target.value }, () => this.checkCompletedFormSections())} />
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group className="mb-3">
                      <Form.Label>{t('Fax 1')}</Form.Label>
                      <Form.Control type="text"
                        defaultValue={this.state.fax1}
                        onBlur={(e) => this.setState({ fax1: e.target.value }, () => this.checkCompletedFormSections())} />
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group className="mb-3">
                      <Form.Label>{t('Fax 2')}</Form.Label>
                      <Form.Control type="text"
                        defaultValue={this.state.fax2}
                        onBlur={(e) => this.setState({ fax2: e.target.value }, () => this.checkCompletedFormSections())} />
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group className="mb-3">
                      <Form.Label>{t('Fax 3')}</Form.Label>
                      <Form.Control type="text"
                        defaultValue={this.state.fax3}
                        onBlur={(e) => this.setState({ fax3: e.target.value }, () => this.checkCompletedFormSections())} />
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group className="mb-3">
                      <Form.Label>{t('Email 1')}</Form.Label>
                      <Form.Control type="text"
                        defaultValue={this.state.email1}
                        onBlur={(e) => this.setState({ email1: e.target.value }, () => this.checkCompletedFormSections())} />
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group className="mb-3">
                      <Form.Label>{t('Email 2')}</Form.Label>
                      <Form.Control type="text"
                        defaultValue={this.state.email2}
                        onBlur={(e) => this.setState({ email2: e.target.value }, () => this.checkCompletedFormSections())} />
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group className="mb-3">
                      <Form.Label>{t('Email 3')}</Form.Label>
                      <Form.Control type="text"
                        defaultValue={this.state.email3}
                        onBlur={(e) => this.setState({ email3: e.target.value }, () => this.checkCompletedFormSections())} />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={3}>
                    <Form.Group className="mb-3">
                      <Form.Label>{t('Country')}</Form.Label>
                      <Select options={this.state.countries}
                        value={this.state.country_id}
                        menuPortalTarget={document.body}
                        styles={{
                          menuPortal: (
                            base
                          ) => ({
                            ...base,
                            zIndex: 9999,
                          }),
                        }}
                        onChange={(obj) => this.setState({ country_id: obj }, () => this.checkCompletedFormSections())}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={3}>
                    <Form.Group className="mb-3">
                      <Form.Label>{t('Currency Code')}</Form.Label>
                      <Select options={this.state.currencycodes}
                        menuPortalTarget={document.body}
                        value={this.state.currencycode}
                        styles={{
                          menuPortal: (
                            base
                          ) => ({
                            ...base,
                            zIndex: 9999,
                          }),
                        }}
                        onChange={(obj) => this.setState({ currencycode: obj }, () => this.checkCompletedFormSections())}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={3}>
                    <Form.Group className="mb-3">
                      <Form.Label>{t('GMT Timezone')}</Form.Label>
                      <Form.Control type="text"
                        defaultValue={this.state.center_timezone}
                        onBlur={(e) => this.setState({ center_timezone: e.target.value }, () => this.checkCompletedFormSections())} />
                    </Form.Group>
                  </Col>
                  <Col md={3}>
                    <Form.Group className="mb-3">
                      <Form.Label>{t('Address')}</Form.Label>
                      <Form.Control type="text"
                        defaultValue={this.state.address}
                        onBlur={(e) => this.setState({ address: e.target.value }, () => this.checkCompletedFormSections())} />
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group className="mb-3">
                      <Form.Label>{t('Center Logo')}</Form.Label>
                      <div
                        style={{
                          flexDirection:
                            'column',
                        }}
                        className='pic-prev d-flex justify-content-center'>
                        <div
                          style={{
                            borderRadius: 5,
                            borderStyle:
                              'dashed',
                            borderWidth: 2,
                            borderColor:
                              '#ced4da',
                            height: 158,
                            display: 'flex',
                            overflow:
                              'hidden',
                          }}>
                          {this.state.logo !==
                            '' &&
                            this.state.logo_prev !==
                            '' ? (
                            <div
                              className='d-flex justify-content-center align-items-center'
                              style={{
                                flex: 1,
                                position:
                                  'relative',
                              }}>
                              <button
                                style={{
                                  padding: 3,
                                  border: 3,
                                  background:
                                    'none',
                                  position:
                                    'absolute',
                                  top: 0,
                                  right: 0,
                                }}
                                onClick={() => {
                                  this.setState(
                                    {
                                      logo:
                                        '',
                                      logo_prev:
                                        '',
                                    }
                                  );
                                  this.logo.current.value =
                                    null;
                                }}>
                                <FontAwesomeIcon
                                  icon={
                                    faTimesCircle
                                  }
                                  color='rgb(220, 53, 69)'
                                  style={{
                                    fontSize: 20,
                                  }}
                                />
                              </button>
                              {this.state.logo_prev ==
                                'pdf' ? (
                                <FontAwesomeIcon
                                  icon={
                                    faFilePdf
                                  }
                                  style={{
                                    fontSize: 60,
                                  }}
                                />
                              ) : (
                                <img
                                  src={
                                    this.state.logo_prev
                                  }
                                  alt={t(
                                    'Preview'
                                  )}
                                  width='100'
                                />
                              )}
                            </div>
                          ) : (
                            <button
                              onClick={() =>
                                this.logo.current.click()
                              }
                              style={{
                                background:
                                  'none',
                                border: 0,
                                flex: 1,
                              }}>
                              <FontAwesomeIcon
                                icon={
                                  faPlus
                                }
                                style={{
                                  fontSize: 30,
                                }}
                              />
                            </button>
                          )}
                        </div>
                        <Form.Control
                          className='d-none'
                          ref={
                            this
                              .logo
                          }
                          type='file'
                          onChange={
                            this.previewFile
                          }
                          name='logo'
                          accept='.jpg, .jpeg, .png'
                        />
                      </div>
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group className="mb-3">
                      <Form.Label>{t('Center Stamp')}</Form.Label>
                      <div
                        style={{
                          flexDirection:
                            'column',
                        }}
                        className='pic-prev d-flex justify-content-center'>
                        <div
                          style={{
                            borderRadius: 5,
                            borderStyle:
                              'dashed',
                            borderWidth: 2,
                            borderColor:
                              '#ced4da',
                            height: 158,
                            display: 'flex',
                            overflow:
                              'hidden',
                          }}>
                          {this.state.center_stamp !==
                            '' &&
                            this.state.center_stamp_prev !==
                            '' ? (
                            <div
                              className='d-flex justify-content-center align-items-center'
                              style={{
                                flex: 1,
                                position:
                                  'relative',
                              }}>
                              <button
                                style={{
                                  padding: 3,
                                  border: 3,
                                  background:
                                    'none',
                                  position:
                                    'absolute',
                                  top: 0,
                                  right: 0,
                                }}
                                onClick={() => {
                                  this.setState(
                                    {
                                      center_stamp:
                                        '',
                                      center_stamp_prev:
                                        '',
                                    }
                                  );
                                  this.center_stamp.current.value =
                                    null;
                                }}>
                                <FontAwesomeIcon
                                  icon={
                                    faTimesCircle
                                  }
                                  color='rgb(220, 53, 69)'
                                  style={{
                                    fontSize: 20,
                                  }}
                                />
                              </button>
                              {this.state.center_stamp_prev ==
                                'pdf' ? (
                                <FontAwesomeIcon
                                  icon={
                                    faFilePdf
                                  }
                                  style={{
                                    fontSize: 60,
                                  }}
                                />
                              ) : (
                                <img
                                  src={
                                    this.state.center_stamp_prev
                                  }
                                  alt={t(
                                    'Preview'
                                  )}
                                  width='100'
                                />
                              )}
                            </div>
                          ) : (
                            <button
                              onClick={() =>
                                this.center_stamp.current.click()
                              }
                              style={{
                                background:
                                  'none',
                                border: 0,
                                flex: 1,
                              }}>
                              <FontAwesomeIcon
                                icon={
                                  faPlus
                                }
                                style={{
                                  fontSize: 30,
                                }}
                              />
                            </button>
                          )}
                        </div>
                        <Form.Control
                          className='d-none'
                          ref={
                            this
                              .center_stamp
                          }
                          type='file'
                          onChange={
                            this.previewFile
                          }
                          name='center_stamp'
                          accept='.jpg, .jpeg, .png'
                        />
                      </div>
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group className="mb-3">
                      <Form.Label>{t('Ministry Logo')}</Form.Label>
                      <div
                        style={{
                          flexDirection:
                            'column',
                        }}
                        className='pic-prev d-flex justify-content-center'>
                        <div
                          style={{
                            borderRadius: 5,
                            borderStyle:
                              'dashed',
                            borderWidth: 2,
                            borderColor:
                              '#ced4da',
                            height: 158,
                            display: 'flex',
                            overflow:
                              'hidden',
                          }}>
                          {this.state.ministry_logo !==
                            '' &&
                            this.state.ministry_logo_prev !==
                            '' ? (
                            <div
                              className='d-flex justify-content-center align-items-center'
                              style={{
                                flex: 1,
                                position:
                                  'relative',
                              }}>
                              <button
                                style={{
                                  padding: 3,
                                  border: 3,
                                  background:
                                    'none',
                                  position:
                                    'absolute',
                                  top: 0,
                                  right: 0,
                                }}
                                onClick={() => {
                                  this.setState(
                                    {
                                      ministry_logo:
                                        '',
                                      ministry_logo_prev:
                                        '',
                                    }
                                  );
                                  this.ministry_logo.current.value =
                                    null;
                                }}>
                                <FontAwesomeIcon
                                  icon={
                                    faTimesCircle
                                  }
                                  color='rgb(220, 53, 69)'
                                  style={{
                                    fontSize: 20,
                                  }}
                                />
                              </button>
                              {this.state.ministry_logo_prev ==
                                'pdf' ? (
                                <FontAwesomeIcon
                                  icon={
                                    faFilePdf
                                  }
                                  style={{
                                    fontSize: 60,
                                  }}
                                />
                              ) : (
                                <img
                                  src={
                                    this.state.ministry_logo_prev
                                  }
                                  alt={t(
                                    'Preview'
                                  )}
                                  width='100'
                                />
                              )}
                            </div>
                          ) : (
                            <button
                              onClick={() =>
                                this.ministry_logo.current.click()
                              }
                              style={{
                                background:
                                  'none',
                                border: 0,
                                flex: 1,
                              }}>
                              <FontAwesomeIcon
                                icon={
                                  faPlus
                                }
                                style={{
                                  fontSize: 30,
                                }}
                              />
                            </button>
                          )}
                        </div>
                        <Form.Control
                          className='d-none'
                          ref={
                            this
                              .ministry_logo
                          }
                          type='file'
                          onChange={
                            this.previewFile
                          }
                          name='ministry_logo'
                          accept='.jpg, .jpeg, .png'
                        />
                      </div>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}><hr /></Col>
                  <Col md={4}>
                    <Row>
                      <Col md={12}>
                        <h5 className='mb-0'>{t('Allowed Chat Times')}</h5>
                      </Col>
                      <Col md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label>{t('Start')}</Form.Label>
                          <Form.Control type="time"
                            defaultValue={this.state.chat_timing_start}
                            onBlur={(e) => this.setState({ chat_timing_start: e.target.value }, () => this.checkCompletedFormSections())} />
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label>{t('End')}</Form.Label>
                          <Form.Control type="time"
                            defaultValue={this.state.chat_timing_end}
                            onBlur={(e) => this.setState({ chat_timing_end: e.target.value }, () => this.checkCompletedFormSections())} />
                        </Form.Group>
                      </Col>
                    </Row>
                  </Col>
                  <Col md={8}>
                    <Row>
                      <Col md={12}>
                        <h5 className='mb-0'>{t('Weekend Days')}</h5>
                      </Col>
                      <Col>
                        <Form.Group className="mb-2">
                          <Form.Label>{t('Sunday')}</Form.Label>
                          <div style={{
                            paddingLeft: 10
                          }}
                            className='p-3 pb-0'>
                            <Form.Check
                              type="switch"
                              id="sunday"
                              label={false}
                              onChange={() => this.setState({ sunday: !this.state.sunday })}
                              checked={this.state.sunday}
                            />
                          </div>
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className="mb-2">
                          <Form.Label>{t('Monday')}</Form.Label>
                          <div style={{
                            paddingLeft: 10
                          }}
                            className='p-3 pb-0'>
                            <Form.Check
                              type="switch"
                              id="monday"
                              label={false}
                              onChange={() => this.setState({ monday: !this.state.monday })}
                              checked={this.state.monday}
                            />
                          </div>
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className="mb-2">
                          <Form.Label>{t('Tuesday')}</Form.Label>
                          <div style={{
                            paddingLeft: 10
                          }}
                            className='p-3 pb-0'>
                            <Form.Check
                              type="switch"
                              id="tuesday"
                              label={false}
                              onChange={() => this.setState({ tuesday: !this.state.tuesday })}
                              checked={this.state.tuesday}
                            />
                          </div>
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className="mb-2">
                          <Form.Label>{t('Wednesday')}</Form.Label>
                          <div style={{
                            paddingLeft: 10
                          }}
                            className='p-3 pb-0'>
                            <Form.Check
                              type="switch"
                              id="wednesday"
                              label={false}
                              onChange={() => this.setState({ wednesday: !this.state.wednesday })}
                              checked={this.state.wednesday}
                            />
                          </div>
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className="mb-2">
                          <Form.Label>{t('Thursday')}</Form.Label>
                          <div style={{
                            paddingLeft: 10
                          }}
                            className='p-3 pb-0'>
                            <Form.Check
                              type="switch"
                              id="thursday"
                              label={false}
                              onChange={() => this.setState({ thursday: !this.state.thursday })}
                              checked={this.state.thursday}
                            />
                          </div>
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className="mb-2">
                          <Form.Label>{t('Friday')}</Form.Label>
                          <div style={{
                            paddingLeft: 10
                          }}
                            className='p-3 pb-0'>
                            <Form.Check
                              type="switch"
                              id="friday"
                              label={false}
                              onChange={() => this.setState({ friday: !this.state.friday })}
                              checked={this.state.friday}
                            />
                          </div>
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className="mb-2">
                          <Form.Label>{t('Saturday')}</Form.Label>
                          <div style={{
                            paddingLeft: 10
                          }}
                            className='p-3 pb-0'>
                            <Form.Check
                              type="switch"
                              id="saturday"
                              label={false}
                              onChange={() => this.setState({ saturday: !this.state.saturday })}
                              checked={this.state.saturday}
                            />
                          </div>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>)}
            {this.state.formSectionActive.parent == '3' && (
              <div id='form_section_3'>
                <h5>{t('Social Media')}</h5>
                <Row>
                  <Col md={4}>
                    <Form.Group className="mb-3">
                      <Form.Label>{t('Website')}</Form.Label>
                      <Form.Control type="text"
                        defaultValue={this.state.urlwww}
                        onBlur={(e) => this.setState({ urlwww: e.target.value }, () => this.checkCompletedFormSections())} />
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group className="mb-3">
                      <Form.Label>{t('Facebook')}</Form.Label>
                      <Form.Control type="text"
                        defaultValue={this.state.urlfb}
                        onBlur={(e) => this.setState({ urlfb: e.target.value }, () => this.checkCompletedFormSections())} />
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group className="mb-3">
                      <Form.Label>{t('Twitter')}</Form.Label>
                      <Form.Control type="text"
                        defaultValue={this.state.urltw}
                        onBlur={(e) => this.setState({ urltw: e.target.value }, () => this.checkCompletedFormSections())} />
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group className="mb-3">
                      <Form.Label>{t('Instagram')}</Form.Label>
                      <Form.Control type="text"
                        defaultValue={this.state.urlinstagram}
                        onBlur={(e) => this.setState({ urlinstagram: e.target.value }, () => this.checkCompletedFormSections())} />
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group className="mb-3">
                      <Form.Label>{t('Youtube')}</Form.Label>
                      <Form.Control type="text"
                        defaultValue={this.state.urlyoutube}
                        onBlur={(e) => this.setState({ urlyoutube: e.target.value }, () => this.checkCompletedFormSections())} />
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group className="mb-3">
                      <Form.Label>{t('Cloud Folder')}</Form.Label>
                      <Form.Control type="text"
                        defaultValue={this.state.urlcloud}
                        onBlur={(e) => this.setState({ urlcloud: e.target.value }, () => this.checkCompletedFormSections())} />
                    </Form.Group>
                  </Col>
                </Row>
              </div>)}
            {this.state.formSectionActive.parent == '5' && (
              <div id='form_section_5'>
                <h5>{t('Financial')}</h5>
                <Row>
                  <Col md={4}>
                    <Form.Group className="mb-3">
                      <Form.Label>{t('Establishment # For WPS')}</Form.Label>
                      <Form.Control type="text"
                        defaultValue={this.state.establishment_num}
                        onBlur={(e) => this.setState({ establishment_num: e.target.value }, () => this.checkCompletedFormSections())} />
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group className="mb-3">
                      <Form.Label>{t('Bank Code For WPS')}</Form.Label>
                      <Form.Control type="text"
                        defaultValue={this.state.bank_code}
                        onBlur={(e) => this.setState({ bank_code: e.target.value }, () => this.checkCompletedFormSections())} />
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group className="mb-3">
                      <Form.Label>{t('VAT TRN Number')}</Form.Label>
                      <Form.Control type="text"
                        defaultValue={this.state.vat_num}
                        onBlur={(e) => this.setState({ vat_num: e.target.value }, () => this.checkCompletedFormSections())} />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>{t('Invoice Print Text')}</Form.Label>
                      <Form.Control type="text"
                        defaultValue={this.state.print_invoice_text}
                        onBlur={(e) => this.setState({ print_invoice_text: e.target.value }, () => this.checkCompletedFormSections())} />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>{t('Receipt Print Text')}</Form.Label>
                      <Form.Control type="text"
                        defaultValue={this.state.print_receipt_text}
                        onBlur={(e) => this.setState({ print_receipt_text: e.target.value }, () => this.checkCompletedFormSections())} />
                    </Form.Group>
                  </Col>
                  <Col md={12}>
                    <Form.Group className="mb-3">
                      <Form.Label>{t('Bank Details')}</Form.Label>
                      <Form.Control type="text"
                        defaultValue={this.state.bank_details}
                        onBlur={(e) => this.setState({ bank_details: e.target.value }, () => this.checkCompletedFormSections())} />
                    </Form.Group>
                  </Col>
                </Row>
              </div>)}
            {this.state.formSectionActive.parent == '6' && (
              <div id='form_section_6'>
                <h5>{t('Map')}</h5>
                <Row>
                  <Col md={4}>
                    <Form.Group className="mb-3">
                      <Form.Label>{t('Latitude')}</Form.Label>
                      <Form.Control type="text"
                        defaultValue={this.state.latitude}
                        onBlur={(e) => this.setState({ latitude: e.target.value }, () => this.checkCompletedFormSections())} />
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group className="mb-3">
                      <Form.Label>{t('Longitude')}</Form.Label>
                      <Form.Control type="text"
                        defaultValue={this.state.longitude}
                        onBlur={(e) => this.setState({ longitude: e.target.value }, () => this.checkCompletedFormSections())} />
                    </Form.Group>
                  </Col>
                </Row>
              </div>)}
            {this.state.formSectionActive.parent == '8' && (
              <div id='form_section_8'>
                <h5>{t('License')}</h5>
                <Row>
                  <Col md={4}>
                    <Form.Group className="mb-3">
                      <Form.Label>{t('Start Date')}</Form.Label>
                      <Flatpickr
                        className='form-control'
                        value={this.state.center_contract_startdate}
                        onChange={([
                          date,
                        ]) => {
                          this.setState(
                            {
                              center_contract_startdate:
                                moment(
                                  date
                                ).format(
                                  'YYYY-MM-DD'
                                ),
                            },
                            () => {
                              this.checkCompletedFormSections();
                            }
                          );
                        }}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group className="mb-3">
                      <Form.Label>{t('End Date')}</Form.Label>
                      <Flatpickr
                        className='form-control'
                        value={this.state.center_contract_expiry_date}
                        onChange={([
                          date,
                        ]) => {
                          this.setState(
                            {
                              center_contract_expiry_date:
                                moment(
                                  date
                                ).format(
                                  'YYYY-MM-DD'
                                ),
                            },
                            () => {
                              this.checkCompletedFormSections();
                            }
                          );
                        }}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </div>)}
          </Col>
        </div>
      </Offcanvas.Body>
    </Offcanvas>);
  }
}

const mapStateToProps = (state) => ({
  authData: state.auth.authData,
  defaultTheme: state.theme.defaultTheme,
  updateSchoolModal: state.updateSchoolModal.showModal
});

const mapDispatchToProps = () => ({
  setUpdateSchoolModal,
  setAuthentication
});

export default connect(mapStateToProps, mapDispatchToProps())(UpdateSchoolModal);


