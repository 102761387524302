import React, { Component } from 'react';
import { Outlet } from 'react-router';
import { Container, Card, Row, Col, Button, Tab, Tabs, Badge, Spinner } from 'react-bootstrap';
import { t } from '../../helpers/translation_helper';
import NavBar from '../_partials/NavBar/_NavBar';
import Header from '../_partials/Header/_Header';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDashboard, faUsers, faAddressCard, faUserTie, faChevronDown, faBook, faListCheck, faThumbsDown, faThumbsUp } from '@fortawesome/free-solid-svg-icons';
import { ifNavAllowed } from '../../helpers/general_helpers';
import Subjects from '../Subjects/_Subjects';
import Paginate from '../_partials/Paginate/_Paginate';
import { brand_colors } from '../../helpers/brand_colors_helper';
import moment from 'moment';
import { toast } from 'react-toastify';
import { sendCloudMessage } from '../../helpers/cloud_message';
import axios from 'axios';
import { GET } from '../../api';
import ExamsHomeworksWidget from '../_partials/Widgets/GradeBookWidget';

const apiURL = process.env.REACT_APP_API_URL;

class LMS extends Component {
  constructor(props) {
    super(props);

    this.state = {

    }
  }

  componentDidMount() {
  }

  render() {
    const { authData } = this.props;
    const { role_id } = authData.loginData;
    let center_tags = JSON.parse(authData.loginData.center_tags)

    return (
      <Container fluid>
        <div id='iedu-layout'>
          <NavBar />
          <div id='page-content'>
            <Header lite={true} heading={t('LMS')} backBtn showClassSelector />
            <div className='grey-section'>
              <div>
                {[1, 3, 12, 15, 19, 22].includes(role_id) &&
                  <ExamsHomeworksWidget size={'lg'} />
                }

                {role_id != 27 &&
                  <div className='slider-wrap' style={{ padding: '10px 0' }} >
                    <Subjects hideHeader={true} agegroup_id={this.props.selectedClass.class.agegroup_id} />
                  </div>
                }
              </div>


            </div>
          </div>
        </div>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  authData: state.auth.authData,
  defaultLanguage: state.language.defaultLanguage,
  defaultTheme: state.theme.defaultTheme,
  selectedClass: state.selectedClass.data,
});

export default connect(mapStateToProps, null)(LMS);
