import React, { Component } from 'react';
import {
  Card, Row, Col, ButtonGroup,
  Button,
  DropdownButton,
  Dropdown,
  Table,
  Form,
  Spinner,
  Badge,
  Modal,
} from 'react-bootstrap';
import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';
import { t } from '../../helpers/translation_helper';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faCheckCircle, faSearch, faXmark, faPencil, faTrash, faThumbsUp, faThumbsDown, faBook, faCircleDot, faIdCardClip, faMailForward, faEdit, faTrashCan, faPrint } from '@fortawesome/free-solid-svg-icons';
import { brand_colors } from '../../helpers/brand_colors_helper';
import { Link } from 'react-router-dom';
import { faCircle, faEye, faEyeSlash, faFileExcel, faIdCard } from '@fortawesome/free-regular-svg-icons';
import { getStudents, setStudents } from '../../redux/slices/studentListSlice';
import { setAddEditStudentModal } from '../../redux/slices/addEditStudentModalSlice';
import { setAddEditParentModal } from '../../redux/slices/addEditParentModalSlice';
import moment from 'moment';
import { generateQRCode, ifNavAllowed, isTeacher } from '../../helpers/general_helpers';
import axios from 'axios';
import { toast } from 'react-toastify';
import AddEditStudent from '../_common/AddEditStudent/_AddEditStudent';
import AddEditParent from '../_common/AddEditParent/_AddEditParent';
import SweetAlert from 'react-bootstrap-sweetalert';
import { GET, POST } from '../../api';
import { Scrollbar } from 'react-scrollbars-custom';
import Pagination from '../_partials/Pagination';
import { renderTableHead } from '../../helpers/table_helper';
import { toggleRegistrationSimpleModal } from '../../redux/slices/addEditRegistrationSimpleModalSlice';
import AddEditRegistrationSimple from '../_common/AddEditRegistrationSimple/_AddEditRegistrationSimple';
import { toggleRegistrationAdvanceModal, toggleRegistrationProformaModal } from '../../redux/slices/addEditRegistrationAdvanceModalSlice';
import AddEditRegistrationAdvance from '../_common/AddEditRegistrationAdvance/_AddEditRegistrationAdvance';

const portalURL = process.env.REACT_APP_PORTAL_URL;

class StudentInformationAdmin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchExpanded: false,
      deleteModal: { show: false, id: 0, action: () => { }, title: null, desc: null },
      cdt: {
        rowsOptions: [10, 25, 50, 'All'],
        columns: [
          { id: 'children.child_id', column: 'child_id', name: 'Actions', visibility: true, sortable: true, width: 170 },
          { id: 'children.child_name', column: 'child_name', name: 'Name', visibility: true, sortable: true },
          { id: 'children.id_number', column: 'id_number', name: 'ID Number', visibility: true, sortable: true },
          { id: 'children.child_code', column: 'child_code', name: 'Student Code', visibility: true, sortable: true },
          { id: 'children.registrations', column: 'registrations', name: 'Registrations', visibility: true },
          // { id: 'children.picture', column: 'picture', name: 'Picture', visibility: true, sortable: true },
          // { id: 'children.child_status', column: 'child_status', name: 'Status', visibility: true, sortable: true },
          { id: 'parent_user_name', column: 'parent_user_name', name: 'Parent Username', visibility: true, sortable: true },
          { id: 'parent_password', column: 'parent_password', name: 'Parent Password', visibility: true, sortable: true },
          { id: 'children.user_name', column: 'user_name', name: 'Student Username', visibility: true, sortable: true },
          { id: 'children.user_pass', column: 'user_pass', name: 'Student Password', visibility: true, sortable: true },
          { id: 'children.birth_date', column: 'birth_date', name: 'Birthday', visibility: true, sortable: true },
          { id: 'children.gender', column: 'gender', name: 'Gender', visibility: true, sortable: true },
          { id: 'p.parent_name', column: 'parent_name', name: 'Parent Name', visibility: true, sortable: true },
          { id: 'p.phone1', column: 'phone1', name: 'Phone 1', visibility: true, sortable: true },
          { id: 'p.phone2', column: 'phone2', name: 'Phone 2', visibility: true, sortable: true },
          { id: 'p.email1', column: 'email1', name: 'Email 1', visibility: true, sortable: true },
          { id: 'p.email2', column: 'email2', name: 'Email 2', visibility: true, sortable: true },
          { id: 'p.spouse_name', column: 'spouse_name', name: 'Spouse Name', visibility: true, sortable: true },
          { id: 'p.spouse_phone', column: 'spouse_phone', name: 'Spouse Phone', visibility: true, sortable: true },
          { id: 'p.spouse_email', column: 'spouse_email', name: 'Spouse Email', visibility: true, sortable: true },
          { id: 'p.emergency_contact_name', column: 'emergency_contact_name', name: 'Emergency Name', visibility: true, sortable: true },
          { id: 'p.emergency_phone1', column: 'emergency_phone1', name: 'Emergency Phone', visibility: true, sortable: true },
          { id: 'children.arabic_name', column: 'arabic_name', name: 'Arabic Name', visibility: true, sortable: true },
          { id: 'children.subscription_end', column: 'subscription_end', name: 'Date Joined', visibility: true, sortable: true },
          { id: 'children.take_pictures', column: 'take_pictures', name: 'Pic On Application (Privacy)', visibility: true, sortable: true },
          { id: 'children.pic_social', column: 'pic_social', name: 'Pic On Advertising (Privacy)', visibility: true, sortable: true },
          { id: 'children.pic_media', column: 'pic_media', name: 'Pic On Social (Privacy)', visibility: true, sortable: true },
          { id: 'children.pic_nursery', column: 'pic_nursery', name: 'Pic On Institution (Privacy)', visibility: true, sortable: true },
        ]
      },
      modalStdSubjects: {
        show: false,
        toggle: (title = 'Subject Selection', child_id) => this.setState({ modalStdSubjects: { ...this.state.modalStdSubjects, show: !this.state.modalStdSubjects.show, title, child_id } }, () => {
          if (this.state.modalStdSubjects.show) {
            this.getChildRegistrations(this.state.modalStdSubjects.child_id)
          }
        }),
        child_id: 0,
        title: '',
        save: this.saveAcademicClassmainSubjectsStd,
        saving: false,
        refreshing: false,
        registrations: [],
        selectedRegistration: -1,
        academic_classmain_subject: [],
        academic_classmain_subjects_std: [],
        activeTab: 'all'//all | selected
      },
      showAllClasses: [1, 2, 5, 8, 12, 22].includes(props.authData.loginData.role_id) ? true : false,
      registrationsModal: {
        show: false,
        data: null
      }
    }
    this.searchFieldRef = React.createRef();
  }


  componentDidMount() {
    this.fetch({
      limit: this.props.studentListRows,
      order: this.props.studentListOrder,
      dir: this.props.studentListDir,
      page: 0,
      search: '',
      status: this.props.studentListStatus
    })
  }

  componentDidUpdate(prevProps, prevState) {
    if ((prevProps.authData.centerData.center_id !== this.props.authData.centerData.center_id) || (this.props.selectedClass && prevProps.selectedClass.class.class_id !== this.props.selectedClass.class.class_id)) {
      this.fetch({
        limit: this.props.studentListRows,
        order: this.props.studentListOrder,
        dir: this.props.studentListDir,
        page: 0,
        search: '',
        status: this.props.studentListStatus
      });
      this.setState({ searchExpanded: false });
      this.searchFieldRef.current.value = '';
    }
  }

  fetch = (params) => {

    this.setState({
      cdt: {
        ...this.state.cdt,
        order: params.order,
        dir: params.dir,
      }
    });

    this.props.getStudents({
      child_status: params.status,
      search: params.search,
      limit: params.limit,
      page: params.page,
      order: params.order,
      dir: params.dir,
      showAllClasses: this.state.showAllClasses,
      registrations: true,
    });
  }

  getStudents = (params) => {
    this.props.getStudents({
      child_status: params.status,
      search: params.search,
      limit: params.rows,
      page: params.page,
      registrations: true,
      showAllClasses: this.state.showAllClasses,
    });
  }

  getChildRegistrations = (child_id) => {
    if (this.getChildRegistrationsReq) {
      this.getChildRegistrationsReq.abort();
    }
    this.getChildRegistrationsReq = new AbortController();

    let modalStdSubjects = this.state.modalStdSubjects
    this.setState({
      modalStdSubjects: {
        ...modalStdSubjects,
        refreshing: true,
        registrations: [],
        selectedRegistration: -1,
        academic_classmain_subject: [],
        academic_classmain_subjects_std: []
      }
    }, async () => {
      try {
        const res = await GET('children/get-child-registrations', {
          params: {
            child_id: child_id
          },
          signal: this.getChildRegistrationsReq.signal,
        }
        );
        console.log('children/get-child-registrations', res)
        let resData = res.data
        let cFutureRegistrations = []
        let uniqueClasses = []
        // TODO APPLY CONDITION FOR CYCLES HERE
        resData.data.map((v, i) => {
          if (moment(v.date_to).isAfter(moment(resData.date)) && !uniqueClasses.includes(v.class_id)) {
            cFutureRegistrations.push(v)
            uniqueClasses.push(v.class_id)
          }
        })
        modalStdSubjects = this.state.modalStdSubjects
        this.setState({
          modalStdSubjects: {
            ...modalStdSubjects,
            refreshing: false,
            registrations: cFutureRegistrations,
            selectedRegistration: (cFutureRegistrations.length > 0) ? 0 : -1
          }
        }, () => {
          if (this.state.modalStdSubjects.show && this.state.modalStdSubjects.selectedRegistration != -1) {
            this.getAcademicClassmainSubjectsStd()
          }
        })
      } catch (err) {
        console.log('err', err)
        if (axios.isCancel(err)) {

        } else {
          toast.error(t('Something went wrong while fetching data!'));

        }
      }
    })
  };

  getAcademicClassmainSubjectsStd = async () => {
    if (this.getAcademicClassmainSubjectsStdReq) {
      this.getAcademicClassmainSubjectsStdReq.abort();
    }
    this.getAcademicClassmainSubjectsStdReq = new AbortController();

    let modalStdSubjects = this.state.modalStdSubjects
    this.setState({
      modalStdSubjects: {
        ...modalStdSubjects,
        refreshing: true,
        academic_classmain_subject: [],
        academic_classmain_subjects_std: []
      }
    })

    try {
      const res = await GET('children/get-academic-classmain-subjects-std', {
        params: {
          class_id: modalStdSubjects.registrations[modalStdSubjects.selectedRegistration].class_id,
          agegroup_id: modalStdSubjects.registrations[modalStdSubjects.selectedRegistration].agegroup_id,
          child_id: modalStdSubjects.registrations[modalStdSubjects.selectedRegistration].child_id,
          centerlic_id: modalStdSubjects.registrations[modalStdSubjects.selectedRegistration].centerlic_id,
        },
        signal: this.getAcademicClassmainSubjectsStdReq.signal,
      }
      );
      console.log('children/get-academic-classmain-subjects-std', res)
      let resData = res.data
      let academic_classmain_subjects_std = {
        acs_std_id: 0,
        acs_ids: [],
        class_id: modalStdSubjects.registrations[modalStdSubjects.selectedRegistration].class_id,
        agegroup_id: modalStdSubjects.registrations[modalStdSubjects.selectedRegistration].agegroup_id,
        child_id: modalStdSubjects.registrations[modalStdSubjects.selectedRegistration].child_id,
        centerlic_id: modalStdSubjects.registrations[modalStdSubjects.selectedRegistration].centerlic_id,
      }
      if (resData.academic_classmain_subjects_std) {
        academic_classmain_subjects_std = resData.academic_classmain_subjects_std
        academic_classmain_subjects_std.acs_ids = JSON.parse(academic_classmain_subjects_std.acs_ids)
      }
      this.setState({
        modalStdSubjects: {
          ...modalStdSubjects,
          refreshing: false,
          academic_classmain_subject: resData.academic_classmain_subject,
          academic_classmain_subjects_std: academic_classmain_subjects_std
        }
      })

    } catch (err) {
      console.log('err', err)
      if (axios.isCancel(err)) {

      } else {
        toast.error(t('Something went wrong while fetching data!'));

      }
    }
  };


  saveAcademicClassmainSubjectsStd = async () => {

    if (this.saveAcademicClassmainSubjectsStdReq) {
      this.saveAcademicClassmainSubjectsStdReq.abort();
    }
    this.saveAcademicClassmainSubjectsStdReq = new AbortController();

    let modalStdSubjects = this.state.modalStdSubjects
    this.setState({
      modalStdSubjects: {
        ...modalStdSubjects,
        saving: true,
      }
    })

    let AcademicClassmainSubjectsStdData = modalStdSubjects.academic_classmain_subjects_std

    let fData = new FormData
    fData.append('AcademicClassmainSubjectsStdData', JSON.stringify(AcademicClassmainSubjectsStdData))

    try {
      const res = await POST('children/save-academic-classmain-subjects-std', fData, {
        signal: this.saveAcademicClassmainSubjectsStdReq.signal,
      }
      );
      console.log('children/saveAcademicClassmainSubjectsStd', res)
      let resData = res.data
      if (resData.type) {
        toast.success(t(resData.message));
      }


    } catch (err) {
      console.log('err', err)
      if (axios.isCancel(err)) {

      } else {
        toast.error(t('Something went wrong while fetching data!'));

      }
    }
  }


  showHideColumn = (column_index) => {
    const columns = this.state.cdt.columns;
    columns[column_index].visibility = !columns[column_index].visibility;
    this.setState({ ctd: { ...this.state.ctd, columns } })
  }

  exportExcel = async () => {

    const excelData = this.props.studentListData.map((student) => {
      const studentRow = {};
      this.state.cdt.columns
        .filter((column) => column.visibility)
        .map((column) => {

          if (column.name === 'Actions') {
            studentRow['Status'] = student['child_status'] == 1 ? 'Active' : 'In-Active';
          } else if (column.column === 'gender') {
            studentRow['Gender'] = student[column.column] == 'M' ? 'Male' : 'Female';
          } else if (column.column === 'birth_date') {
            studentRow[column.name] = moment(student[column.column]).format('DD-MM-YYYY');
          } else if (column.column === 'registrations') {
            const regs = student[column.column].map((v, i) => {
              if (v.term_active == 1) {
                return v.class_theme
              }
            });
            studentRow[column.name] = regs.join(',');
          } else if (
            column.column === 'take_pictures' ||
            column.column === 'pic_social' ||
            column.column === 'pic_media' ||
            column.column === 'pic_nursery') {
            studentRow[column.name] = student[column.column] == 1 ? 'Yes' : 'No';
          } else if (column.name !== 'Picture') {
            studentRow[column.name] = student[column.column];
          }

        });
      return studentRow;
    });

    const fileName = 'Students Export ' + moment().format('DD-MM-YYYY');
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const ws = XLSX.utils.json_to_sheet(excelData);

    const cellStyle = {
      font: {
        bold: true,
      },
    }

    Array.from('ABCDEFGHIJKLMNOPQRSTUVWXY').map((letter) => {
      ws[letter + "1"].s = cellStyle;
    });

    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });

    FileSaver.saveAs(data, fileName + fileExtension);
  }

  toggleStudentStatus = async (child_id, studentData) => {
    this.setState({
      deleteModal: {
        ...this.state.deleteModal, show: true, id: child_id, title: (studentData.child_status == 1) ? 'De-Activate Student' : 'Activate Student', desc: (studentData.child_status == 1) ? 'You want to De-Activate Student' : 'You want to Activate Student', action: async () => {
          const { authData, studentListData, studentListStatus } = this.props;
          const { center_id } = authData.centerData;
          const { auth_key } = authData.loginData;

          try {
            const res = await GET('children/toggle-status', {
              params: {
                auth_key,
                center_id,
                child_id,
                appname: process.env.REACT_APP_NAME,
              },
            });

            if (res.status === 200 && res.data.type) {
              let updatedStudentsList = [...studentListData];
              const findIndex = studentListData.findIndex((student) => student.child_id == studentData.child_id);

              let updatedStudent;
              updatedStudent = { ...studentData, child_status: studentData.child_status == 1 ? 0 : 1 }

              if (studentListStatus == 1) {
                updatedStudentsList.splice(findIndex, 1);
              } else {
                updatedStudentsList[findIndex] = updatedStudent;
              }


              this.props.setStudents(updatedStudentsList);
              this.setState({
                deleteModal: {
                  ...this.state.deleteModal, show: false,
                }
              })
              toast.success(t('Student status updated successfully!'));
            } else {
              this.setState({
                deleteModal: {
                  ...this.state.deleteModal, show: false,
                }
              })
            }

          } catch (err) {
            console.log('err', err)
            this.setState({
              deleteModal: {
                ...this.state.deleteModal, show: false,
              }
            })
          }
        }
      }
    })
  }

  deleteStudent = async (child_id, studentData) => {
    this.setState({
      deleteModal: {
        ...this.state.deleteModal, show: true, id: child_id, title: 'Delete Student', desc: 'Confirm delete student!', action: async () => {
          const { authData, studentListData, studentListStatus } = this.props;
          const { center_id } = authData.centerData;
          const { auth_key } = authData.loginData;

          try {
            const res = await GET('children/delete', {
              params: {
                auth_key,
                center_id,
                child_id,
                appname: process.env.REACT_APP_NAME,
              },
            });

            if (res.status === 200 && res.data.type) {
              let updatedStudentsList = [...studentListData];
              const findIndex = studentListData.findIndex((student) => student.child_id == studentData.child_id);

              updatedStudentsList.splice(findIndex, 1);

              this.props.setStudents(updatedStudentsList);

              toast.warning(t('Student deleted successfully!'));
              this.setState({
                deleteModal: {
                  ...this.state.deleteModal, show: false,
                }
              })
            } else {
              toast.error(t(res.data.message));
            }

          } catch (err) {
            console.log('err', err)

          }
        }
      }
    })
  }

  mailCredentials = async (child_id, studentData) => {

    const { authData } = this.props;

    const res = await GET('children/send-parent-credentials', {
      params: {
        parent_id: studentData.parent_id,
        center_email: authData.centerData.email1,
        center_name: authData.centerData.center_name,
      },
    });

    if (res.data.type) {
      toast.warning(t('Credentials sent successfully!'));
    } else {
      toast.error(t(res.data.message));
    }
  }

  deactivateStudents = async () => {
    this.setState({
      deleteModal: {
        ...this.state.deleteModal, show: true, title: 'De-Activate Students', desc: 'You want to De-Activate all Parents & Students who are not registered in any term.', action: async () => {
          const { authData, studentListRows, studentListStatus, studentListOrder, studentListDir, studentListPage, studentListSearch } = this.props;
          const { center_id } = authData.centerData;
          const { auth_key } = authData.loginData;

          try {
            const res = await GET('children/deactivate-students', {
              params: {
                auth_key,
                center_id,
                appname: process.env.REACT_APP_NAME,
              },
            });

            if (res.status === 200 && res.data.type) {
              this.fetch({
                limit: studentListRows,
                order: studentListOrder,
                dir: studentListDir,
                page: studentListPage,
                search: studentListSearch,
                status: studentListStatus
              });
              toast.success(t(res.data.message));
              this.setState({ deleteModal: { ...this.state.deleteModal, show: false, title: null, desc: null } })
            } else {
              toast.error(t(res.data.message));
              this.setState({ deleteModal: { ...this.state.deleteModal, show: false, title: null, desc: null } })
            }
          } catch (err) {
            console.log('err', err)
          }
        }
      }
    })
  }

  deleteRegistration = (reg_id) => {
    this.setState({
      deleteModal: {
        ...this.state.deleteModal, show: true, id: reg_id, action: async () => {
          if (this.deleteRegistrationReq) {
            this.deleteRegistrationReq.abort()
          }

          this.deleteRegistrationReq = new AbortController();
          let fData = new FormData()
          fData.append('reg_id', reg_id)
          let res = await POST('registration-simple/delete-registration', fData, {
            signal: this.deleteRegistrationReq.signal
          })
          console.log('res', res);
          if (res) {
            let resData = res.data
            if (resData.type) {

              this.setState({
                deleteModal: {
                  ...this.state.deleteModal, show: false,
                },
              }, () => {
                this.getStudents({
                  rows: this.props.studentListRows,
                  page: this.props.studentListPage,
                  search: this.props.studentListSearch,
                  status: true,
                });
              })
            }
            toast[resData.messageType](resData.message)
          }
        }
      }
    })
  }


  handleRegistrationsModalOpen = (data) => {
    console.log('data', data);
    this.setState({
      registrationsModal: {
        show: true,
        data
      }
    });
  }

  handleRegistrationsModalClose = () => {
    this.setState({
      registrationsModal: {
        show: false,
        data: null
      }
    });
  }


  renderStudentRow = (column_data, column_id, column_index, studentData) => {

    const { authData } = this.props;
    const { center_id } = authData.centerData;
    let { modalStdSubjects } = this.state

    let tdData;
    // ------------------------------------------------------
    if (column_id == 'children.child_id') { // Action Buttons
      tdData = <>
        <Button
          style={{ width: 31, paddingLeft: 0, paddingRight: 0 }}
          onClick={() => this.props.setAddEditStudentModal({ showModal: true, child_id: column_data, isEditing: true })}
          variant="warning" size='sm' className='me-1 mb-1' title={t('Edit')}>
          <FontAwesomeIcon icon={faPencil} />
        </Button>
        <Button
          style={{ width: 31, paddingLeft: 0, paddingRight: 0 }}
          onClick={() => this.deleteStudent(column_data, studentData)}
          variant="danger" size='sm' className='me-1 mb-1' title={t('Delete')}>
          <FontAwesomeIcon icon={faTrash} />
        </Button>
        <Button
          style={{ width: 31, paddingLeft: 0, paddingRight: 0 }}
          onClick={() => this.mailCredentials(column_data, studentData)}
          variant="warning" size='sm' className='me-1 mb-1' title={t('Mail Credentials')}>
          <FontAwesomeIcon icon={faMailForward} />
        </Button>
        <Button
          style={{ width: 31, paddingLeft: 0, paddingRight: 0 }}
          onClick={() => this.toggleStudentStatus(column_data, studentData)}
          variant={studentData.child_status == 1 ? 'success' : 'danger'} size='sm' className='me-1 mb-1' title={(studentData.child_status == 1) ? t('Disable') : t('Activate')}>
          <FontAwesomeIcon icon={studentData.child_status == 1 ? faThumbsUp : faThumbsDown} />
        </Button>
        {[1, 12, 15, 19, 8, 22].includes(authData.loginData.role_id) &&
          <Button
            style={{ width: 31, paddingLeft: 0, paddingRight: 0 }}
            onClick={() => modalStdSubjects.toggle('Subject Selection', column_data)} size='sm' title={t('Subject Selection')} className='me-1 mb-1'>
            <FontAwesomeIcon icon={faBook} />
          </Button>
        }
        {[8, 2, 1, 5, 19, 12, 22].includes(authData.loginData.role_id) && ifNavAllowed('student-registration-simple') &&
          <Link
            style={{ width: 31, paddingLeft: 0, paddingRight: 0 }}
            to={"/students/student-registration-simple?id=" + studentData.child_id}
            className='btn btn-success btn-sm me-1 mb-1' title={t('Create Registration')}>
            <FontAwesomeIcon icon={faIdCardClip} />
          </Link>
        }
        {[8, 2, 1, 5, 19, 12, 22].includes(authData.loginData.role_id) && ifNavAllowed('student-registration-advance') &&
          <Link
            style={{ width: 31, paddingLeft: 0, paddingRight: 0 }}
            to={"/students/student-registration-advance?id=" + studentData.child_id}
            className='btn btn-success btn-sm me-1 mb-1' title={t('Create Registration Advance')}>
            <FontAwesomeIcon icon={faIdCardClip} />
          </Link>
        }
        {[8, 2, 1, 5, 19, 12, 22].includes(authData.loginData.role_id) &&
          <Button
            style={{ width: 31, paddingLeft: 0, paddingRight: 0 }}
            onClick={() => this.handleRegistrationsModalOpen(studentData)}
            variant="info" size='sm' className='me-1 mb-1' title={t('Registrations')}>
            <FontAwesomeIcon icon={faIdCard} />
          </Button>
        }
      </>;
      // -------------------------------------------------
    } else if (column_id == 'children.picture') { // Picture
      tdData = <div style={{
        backgroundImage: `url(${(column_data) ? portalURL + center_id + '/children/' + column_data : 'https://via.placeholder.com/150x150?text=' + studentData.child_name[0].toUpperCase()})`,
        width: 60,
        height: 60,
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        borderRadius: 30
      }}></div>;
      // -----------------------------------------------------
    } else if (column_id == 'children.child_status') { // Status
      tdData = column_data == 1 ?
        <Badge bg="info">{t('Active')}</Badge>
        :
        <Badge bg="danger">{t('In-Active')}</Badge>;
      // ----------------------------------------------
    } else if (column_id == 'children.birth_date' || column_id == 'reg_date') { // Birth Date
      tdData = moment(column_data).format('DD-MM-YYYY');

      // -----------------------------------------------
    } else if (column_id == 'children.gender') { // Gender
      tdData = column_data == 'M'
        ? <div class="badge badge-light-info">{t('M')}</div>
        : <div class="badge badge-light-danger">{t('F')}</div>;

      // --------------------------------------------------
    } else if (column_id == 'p.parent_name') { // Parent Name
      tdData = <div>
        <span
          title={'Edit ' + column_data}
          className='text-info'
          style={{ textDecoration: 'underline', cursor: 'pointer' }}
          onClick={() => this.props.setAddEditParentModal({ showModal: true, parent_id: studentData.parent_id, isEditing: true })}
        ><FontAwesomeIcon icon={faPencil} style={{ fontSize: 12, marginRight: 5 }} />
          {column_data}</span>
      </div>
      // ----------------
    } else if ( // Consents
      column_id == 'children.take_pictures' ||
      column_id == 'children.pic_social' ||
      column_id == 'children.pic_media' ||
      column_id == 'children.pic_nursery'
    ) {
      tdData = column_data == 1
        ? <div class="badge badge-light-info">{t('Yes')}</div>
        : <div class="badge badge-light-danger">{t('No')}</div>;
    } else if (column_id == 'children.registrations') {
      tdData = null
      if (column_data) {
        tdData = <div className='d-flex flex-row'>
          {column_data.map((v, i) => {
            if (v.term_active == 1) {
              return <div class="badge badge-light-info mx-1" key={i}>
                {v.class_theme}
              </div>
            }
          })}
        </div>
      }
    } else if (column_id == 'parent_user_name' || column_id == 'parent_password') {
      tdData = <div class="badge badge-light-danger">{column_data}</div>;

      // --------------------------------------------------
    } else { // Default
      tdData = column_data;
    }

    return <td key={column_index}>{tdData}</td>;
  }

  printRegistration = async (registration, mail = false) => {
    if (this.printRegistrationReq) {
      this.printRegistrationReq.abort();
    }
    this.printRegistrationReq = new AbortController();
    const res = await GET('registration-advance/edit-registration', {
      params: {
        child_id: registration.child_id,
        reg_num: registration.reg_num,
        fee_type: registration.reg_type,
      },
      signal: this.printRegistrationReq.signal,
    });

    if (res) {
      let rowdata = res.data;
      let centerData = this.props.authData.centerData;
      let generateQRCodeData = generateQRCode(
        centerData.center_name,
        centerData.vat_num,
        moment(rowdata.addservicesheaderData.addservicesheader_date).format(),
        (
          parseFloat(rowdata.addservicesheaderData.addservicesheader_amount) +
          parseFloat(rowdata.addservicesheaderData.addservicesheader_vat)
        ).toFixed(2),
        parseFloat(rowdata.addservicesheaderData.addservicesheader_vat).toFixed(
          2
        )
      );

      let user_id = this.props.authData.loginData.user_id;

      if (mail) {
        let URL =
          'registration-advance/print-registrationadvance?' +
          'auth_key=' + this.props.authData.authKey +
          '&reg_num=' + rowdata.addservicesheaderData.addservices_num +
          '&fee_type=' + rowdata.addservicesheaderData.addservices_type +
          '&addservicesheader_date=' + rowdata.addservicesheaderData.addservicesheader_date +
          '&qr=' + generateQRCodeData +
          '&center_id=' + rowdata.addservicesheaderData.center_id +
          '&mail=true' +
          '&user_id=' + user_id +
          '&child_id=' + rowdata.addservicesheaderData.child_id;
        try {
          const res = await GET(URL);
          toast.success('Email sent successfully!');
        } catch (err) {
          console.log(err);
        }
      } else {
        let URL =
          process.env.REACT_APP_API_URL +
          'registration-advance/print-registrationadvance?' +
          'auth_key=' +
          this.props.authData.authKey +
          '&reg_num=' + rowdata.addservicesheaderData.addservices_num +
          '&fee_type=' + rowdata.addservicesheaderData.addservices_type +
          '&addservicesheader_date=' + rowdata.addservicesheaderData.addservicesheader_date +
          '&qr=' + generateQRCodeData +
          '&center_id=' + rowdata.addservicesheaderData.center_id +
          '&user_id=' + user_id +
          '&child_id=' + rowdata.addservicesheaderData.child_id;

        window.open(
          URL,
          'PDF' +
          '_' +
          rowdata.addservicesheaderData.addservices_num +
          '_' +
          rowdata.addservicesheaderData.addservices_type
        );
      }
    }
  };

  render() {
    let { modalStdSubjects, showAllClasses } = this.state;

    let registrations = [];

    if (this.state.registrationsModal.data) {
      const index = this.props.studentListData.findIndex(item => item.child_id == this.state.registrationsModal.data.child_id);
      registrations = this.props.studentListData[index].registrations;
    }

    return (
      <>
        <Card
          className='border-0'
          style={{ borderRadius: '20px' }}>
          <Card.Body>
            <Row>
              <Col md={4} className='d-flex flex-column'>
                <div className='d-flex flex-row align-items-center mb-2'>
                  <ButtonGroup size='sm'>
                    {/* <Button><FontAwesomeIcon icon={faFilePdf} /> {t('PDF')}</Button> */}
                    <Button onClick={() => this.exportExcel()}>
                      <FontAwesomeIcon icon={faFileExcel} /> {t('Excel')}
                    </Button>
                    {/* <Button><FontAwesomeIcon icon={faPrint} /> {t('Print')}</Button> */}
                    <DropdownButton
                      autoClose={'outside'}
                      size='sm'
                      as={ButtonGroup}
                      title={t('Column Visibility')}>
                      {this.state.cdt.columns.map((column, column_index) => (
                        <Dropdown.Item key={column_index} className={column.visibility ? 'column-name-item' : 'column-name-item active'} onClick={() => this.showHideColumn(column_index)}>
                          <FontAwesomeIcon icon={column.visibility ? faEye : faEyeSlash} /> {column.name}
                        </Dropdown.Item>
                      ))}
                    </DropdownButton>
                  </ButtonGroup>
                  <div className='expandable-search ms-2' style={{ width: this.state.searchExpanded ? 200 : 35 }}>
                    <input
                      ref={this.searchFieldRef}
                      defaultValue={this.props.studentListSearch}
                      onChange={(e) => this.fetch({
                        limit: this.props.studentListRows,
                        order: this.props.studentListOrder,
                        dir: this.props.studentListDir,
                        page: 0,
                        search: e.target.value,
                        status: this.props.studentListStatus
                      })}
                      type='text'
                      style={{ width: this.state.searchExpanded ? 165 : 0, paddingLeft: this.state.searchExpanded ? 15 : 0, opacity: this.state.searchExpanded ? 1 : 0 }}
                      placeholder={t('Search Student...')} />
                    <button
                      data-tooltip-id="tooltip"
                      data-tooltip-content={t('Search Student')}
                      data-tooltip-place="top"
                      onClick={() => {
                        this.setState({ searchExpanded: !this.state.searchExpanded });
                        this.props.studentListSearch != '' && this.state.searchExpanded && this.fetch({
                          limit: this.props.studentListRows,
                          order: this.props.studentListOrder,
                          dir: this.props.studentListDir,
                          page: 0,
                          search: '',
                          status: this.props.studentListStatus
                        });
                        this.searchFieldRef.current.value = '';
                        !this.state.searchExpanded && this.searchFieldRef.current.focus();
                      }}>
                      <FontAwesomeIcon
                        icon={this.state.searchExpanded ? faXmark : faSearch}
                        color={brand_colors[this.props.defaultTheme.theme_id].color18}
                      />
                    </button>
                  </div>
                </div>
                <div className='d-flex flex-row align-items-center'>
                  <div>
                    {t('Show')}
                    <Form.Select
                      value={this.props.studentListRows}
                      size="sm"
                      onChange={(e) => this.fetch({
                        limit: e.target.value,
                        status: this.props.studentListStatus,
                        order: this.props.studentListOrder,
                        dir: this.props.studentListDir,
                        page: 0,
                        search: this.props.studentListSearch,
                      })}
                      style={{ width: 70, marginInline: 5, display: 'inline-block' }}>
                      {this.state.cdt.rowsOptions.map((row, row_index) => (<option key={row_index} >{row}</option>))}
                    </Form.Select>
                    {t('Records')}
                  </div>
                </div>
              </Col>
              <Col md={8} className='d-flex justify-content-end align-items-start'>
                <Badge bg='info' className='me-3' style={{ marginTop: 6 }}>{t('Total Students: ')} {this.props.studentListTotalCount}</Badge>
                <Button onClick={() => this.deactivateStudents()} variant="danger" size='sm' className='me-3'>{t('De-Activate Students')}</Button>
                {[1, 2, 5, 8, 12, 22].includes(this.props.authData.loginData.role_id) &&
                  <Button onClick={() => this.setState({ showAllClasses: !showAllClasses }, () => {
                    this.fetch({
                      limit: this.props.studentListRows,
                      status: this.props.studentListStatus,
                      order: this.props.studentListOrder,
                      dir: this.props.studentListDir,
                      page: 0,
                      search: this.props.studentListSearch
                    })
                  })} variant={(showAllClasses) ? "success" : "outline-success"} size='sm' className='me-3' title={(showAllClasses) ? t('Show Selected Class only') : t('Show All Classes')}>
                    {showAllClasses && <FontAwesomeIcon icon={faCheckCircle} color={brand_colors[this.props.defaultTheme.theme_id].color8} className='me-1' />}
                    {(showAllClasses) ? t('Showing All Classes') : t('Showing Selected Class')}
                  </Button>
                }
                <ButtonGroup size='sm'>
                  <Button
                    onClick={() => this.fetch({
                      limit: this.props.studentListRows,
                      status: true,
                      order: this.props.studentListOrder,
                      dir: this.props.studentListDir,
                      page: 0,
                      search: this.props.studentListSearch
                    })}
                    variant={this.props.studentListStatus ? 'primary' : 'light'}
                  >{t('Only Active')}</Button>
                  <Button
                    onClick={() => this.fetch({
                      limit: this.props.studentListRows,
                      status: false,
                      order: this.props.studentListOrder,
                      dir: this.props.studentListDir,
                      page: 0,
                      search: this.props.studentListSearch
                    })}
                    variant={this.props.studentListStatus ? 'light' : 'primary'}
                  >{t('Show All')}</Button>
                </ButtonGroup>
                <button
                  onClick={() => this.props.setAddEditStudentModal({ showModal: true, child_id: 0, isEditing: false })}
                  className='btn btn-success btn-sm ms-3'><FontAwesomeIcon icon={faPlus} color={brand_colors[this.props.defaultTheme.theme_id].color8} style={{ fontSize: 12 }} />{' '}{t('Add Student')}</button>
              </Col>
              {this.props.studentListFetching &&
                <Col md={12} className='py-5 d-flex flex-column align-items-center'>
                  <Spinner as='span' animation='grow' size='sm' /> {t('Loading Students')}
                </Col>
              }
              {!this.props.studentListFetching &&
                <Col md={12} className='pt-1'>
                  <div id="datatable-list" className='mb-3 datatable-list-w-sidebar'>
                    <Table responsive>
                      {renderTableHead(this)}
                      <tbody>
                        {this.props.studentListData.map((student, student_index) => (
                          <tr key={student_index}>
                            {this.state.cdt.columns.filter((column) => column.visibility).map((column, column_index) => this.renderStudentRow(student[column.column], column.id, column_index, student))}
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </Col>
              }
            </Row>
            <Pagination
              pageCount={this.props.studentListLastPage}
              forcePage={this.props.studentListPage}
              callbackParams={{
                limit: this.props.studentListRows,
                status: this.props.studentListStatus,
                order: this.props.studentListOrder,
                dir: this.props.studentListDir,
                search: this.props.studentListSearch
              }}
              callback={this.fetch}
            />
          </Card.Body>
        </Card>
        <AddEditStudent />
        <AddEditParent />
        <SweetAlert
          show={this.state.deleteModal.show}
          warning
          showCancel
          confirmBtnText={t('Yes!')}
          confirmBtnBsStyle='danger'
          title={(this.state.deleteModal.title) ? this.state.deleteModal.title : t('Are you sure?')}
          onConfirm={this.state.deleteModal.action}
          onCancel={() => this.setState({ deleteModal: { ...this.state.deleteModal, show: false, id: 0, action: () => { } } })}
          focusCancelBtn
        >
          {(this.state.deleteModal.desc) ? this.state.deleteModal.desc : t('Confirm Delete!')}
        </SweetAlert>

        {/* MODAL NEW/EDIT Subject Selection */}
        <Modal show={modalStdSubjects.show} onHide={() => modalStdSubjects.toggle()} size="lg" backdrop="static"
          keyboard={false}>
          <Modal.Header closeButton>
            <Modal.Title>{modalStdSubjects.title}</Modal.Title>
          </Modal.Header>
          {modalStdSubjects.show &&
            <Modal.Body className='custom-styled-form'>
              <div className='d-flex flex-row'>
                {modalStdSubjects.registrations.map((v, i) => {
                  let selected = false
                  if (modalStdSubjects.selectedRegistration == i) {
                    selected = true
                  }
                  return <div className='d-flex flex-column align-items-center ms-1 me-2 p-1 cursor-pointer' style={{ border: (selected) ? 'solid 1px ' + brand_colors[this.props.defaultTheme.theme_id].color4 : 'solid 1px ' + brand_colors[this.props.defaultTheme.theme_id].color10, borderRadius: 10, position: 'relative' }} onClick={() => this.setState({ modalStdSubjects: { ...modalStdSubjects, selectedRegistration: i } }, () => this.getAcademicClassmainSubjectsStd())}>
                    <div style={{ fontSize: 13, color: brand_colors[this.props.defaultTheme.theme_id].color9, opacity: 0.8, fontWeight: 'bold' }}>{v.class_theme}</div>
                    <div style={{ fontSize: 12, color: brand_colors[this.props.defaultTheme.theme_id].color9, opacity: 0.7 }}>{v.reg_year}</div>
                    {selected &&
                      <div style={{ position: 'absolute', top: -10, right: -10 }}>
                        <FontAwesomeIcon icon={faCheckCircle} style={{ color: brand_colors[this.props.defaultTheme.theme_id].color4 }} />
                      </div>
                    }
                  </div>
                })}
              </div>
              <div className='d-flex flex-column align-items-center ms-1 me-2 p-1 cursor-pointer mt-2' style={{ border: 'solid 1px ' + brand_colors[this.props.defaultTheme.theme_id].color10, borderRadius: 10, position: 'relative' }}>
                <div className='d-flex flex-row'>
                  <div className='mx-2 align-items-center justify-content-center p-1 cursor-pointer' style={{ color: (modalStdSubjects.activeTab == 'all') ? brand_colors[this.props.defaultTheme.theme_id].color4 : brand_colors[this.props.defaultTheme.theme_id].color18 }} onClick={() => this.setState({ modalStdSubjects: { ...modalStdSubjects, activeTab: 'all' } })}>{t('All')}</div>
                  <div className='mx-2 align-items-center justify-content-center p-1 cursor-pointer' style={{ color: (modalStdSubjects.activeTab == 'selected') ? brand_colors[this.props.defaultTheme.theme_id].color4 : brand_colors[this.props.defaultTheme.theme_id].color18 }} onClick={() => this.setState({ modalStdSubjects: { ...modalStdSubjects, activeTab: 'selected' } })}>{t('Selected')}</div>
                </div>

                <div style={{ flex: 1, width: '100%' }} className='d-flex'>
                  <Scrollbar style={{ width: '100%', height: '50vh' }} >
                    {modalStdSubjects.academic_classmain_subject.map((v, i) => {
                      if (v.parent_acs_id == null) {
                        let selectedSubject = false
                        if (modalStdSubjects.academic_classmain_subjects_std) {
                          selectedSubject = modalStdSubjects.academic_classmain_subjects_std.acs_ids.findIndex((vv, ii) => vv == v.acs_id)
                        }

                        if (modalStdSubjects.activeTab == 'all' || (modalStdSubjects.activeTab == 'selected' && (selectedSubject != -1 || v.acs_mandatory == 1))) {
                          return <div key={i} >
                            <div className='scroll-list-card'>
                              <div className='d-flex flex-row justify-content-between align-items-center p-0 m-0'>
                                <div className='me-2' style={{ color: brand_colors[this.props.defaultTheme.theme_id].color9 }}>{v.acs_code}</div>
                                <div>{v.acs_name}</div>
                              </div>
                              <div>
                                {/* TOGGLE CHECK*/}
                                {v.acs_mandatory == 1
                                  // MANDATORY
                                  ? <FontAwesomeIcon icon={faCircleDot} color={brand_colors[this.props.defaultTheme.theme_id].color4} className='cursor-pointer me-3' title={t('Mandatory')} />
                                  // ELECTIVE
                                  : <FontAwesomeIcon icon={(selectedSubject != -1) ? faCheckCircle : faCircle} color={brand_colors[this.props.defaultTheme.theme_id].color16} className='cursor-pointer me-3' onClick={() => {
                                    let academic_classmain_subjects_std = modalStdSubjects.academic_classmain_subjects_std
                                    if (selectedSubject != -1) {
                                      academic_classmain_subjects_std.acs_ids.splice(selectedSubject, 1)
                                    } else {
                                      academic_classmain_subjects_std.acs_ids.push(parseInt(v.acs_id))
                                    }

                                    this.setState({ modalStdSubjects: { ...modalStdSubjects, academic_classmain_subjects_std } })
                                  }} title={t('Toggle Select')} />
                                }
                              </div>
                            </div>
                            {modalStdSubjects.academic_classmain_subject.map((vv, ii) => {
                              if (vv.parent_acs_id == v.acs_id) {
                                let selectedFocus = false
                                if (modalStdSubjects.academic_classmain_subjects_std) {
                                  selectedFocus = modalStdSubjects.academic_classmain_subjects_std.acs_ids.findIndex((vvv) => vvv == vv.acs_id)
                                }

                                if (modalStdSubjects.activeTab == 'all' || (modalStdSubjects.activeTab == 'selected' && (selectedFocus != -1 || vv.acs_mandatory == 1))) {
                                  return <div key={ii} className='scroll-list-card mx-4'>
                                    <div className='d-flex flex-row justify-content-between align-items-center p-0 m-0'>
                                      <div className='me-2' style={{ color: brand_colors[this.props.defaultTheme.theme_id].color9 }}>{vv.acs_code}</div>
                                      <div className='me-2'>{vv.acs_name}</div>
                                      <Badge bg='success'>Focus</Badge>
                                    </div>
                                    {(selectedSubject != -1 || v.acs_mandatory == 1) &&
                                      <div>
                                        {/* TOGGLE CHECK*/}
                                        {vv.acs_mandatory == 1
                                          // MANDATORY
                                          ? <FontAwesomeIcon icon={faCircleDot} color={brand_colors[this.props.defaultTheme.theme_id].color4} className='cursor-pointer me-3' title={t('Mandatory')} />
                                          // ELECTIVE
                                          : <FontAwesomeIcon icon={(selectedFocus != -1) ? faCheckCircle : faCircle} color={brand_colors[this.props.defaultTheme.theme_id].color16} className='cursor-pointer me-3' onClick={() => {
                                            let academic_classmain_subjects_std = modalStdSubjects.academic_classmain_subjects_std
                                            if (selectedFocus != -1) {
                                              academic_classmain_subjects_std.acs_ids.splice(selectedFocus, 1)
                                            } else {
                                              academic_classmain_subjects_std.acs_ids.push(parseInt(vv.acs_id))
                                            }

                                            this.setState({ modalStdSubjects: { ...modalStdSubjects, academic_classmain_subjects_std } })
                                          }} title={t('Toggle Select')} />
                                        }
                                      </div>
                                    }
                                  </div>
                                }
                              }
                            })}
                          </div>
                        }
                      }
                    })}
                  </Scrollbar>
                </div>
              </div>
            </Modal.Body>
          }
          <Modal.Footer>
            <Button variant="secondary" onClick={() => modalStdSubjects.toggle()}>
              Close
            </Button>
            <Button variant="primary" onClick={modalStdSubjects.save}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>


        {/* Simple Registration Modal */}
        <AddEditRegistrationSimple
          callbackFunction={() => this.getStudents({
            rows: this.props.studentListRows,
            page: this.props.studentListPage,
            search: this.props.studentListSearch,
            status: true,
          })} />

        {/* Advance Registration Modal */}
        <AddEditRegistrationAdvance callbackFunction={() => this.getStudents({
          rows: this.props.studentListRows,
          page: this.props.studentListPage,
          search: this.props.studentListSearch,
          status: true,
        })} />

        {/* Registrations Modal */}
        <Modal show={this.state.registrationsModal.show} backdrop="static"
          keyboard={false}
          size="xl"
          onHide={this.handleRegistrationsModalClose}>
          <Modal.Header closeButton>
            <Row style={{ flex: 1 }}>
              <Col md="auto">
                <Modal.Title>{t('Registrations of')} {this.state.registrationsModal.data?.child_name}</Modal.Title>
              </Col>
              <Col>
                {ifNavAllowed('student-registration-simple') &&
                  <Button
                    onClick={() => {

                      let weekend_days = this.props.authData.centerData.weekend_days
                      if (weekend_days) {
                        weekend_days = weekend_days.split(',')
                      }

                      let newRegistration = [{
                        reg_id: 0,
                        center_id: this.props.authData.loginData.center_id,
                        child: { label: this.state.registrationsModal.data?.child_name, value: this.state.registrationsModal.data?.child_id, ...this.state.registrationsModal.data },
                        class_id: 0,
                        session_id: 0,
                        sessioncat_id: 0,
                        term_id: 0,
                        centerlic_id: 0,
                        reg_year: 0,
                        session_mon: weekend_days.includes('monday') ? false : true,
                        session_tue: weekend_days.includes('tuesday') ? false : true,
                        session_wed: weekend_days.includes('wednesday') ? false : true,
                        session_thu: weekend_days.includes('thursday') ? false : true,
                        session_fri: weekend_days.includes('friday') ? false : true,
                        session_sat: weekend_days.includes('saturday') ? false : true,
                        session_sun: weekend_days.includes('sunday') ? false : true,
                        reg_date: moment().format('YYYY-MM-DD'),
                        reg_num: 0,
                        date_from: moment().format('YYYY-MM-DD'),
                        date_to: moment().format('YYYY-MM-DD'),
                      }]

                      this.props.toggleRegistrationSimpleModal({ title: 'New Registration', child: this.state.registrationsModal.data, registrations: newRegistration, reg_date: moment().format('YYYY-MM-DD') });

                    }}
                    className='me-2' variant='success' size='sm'>
                    <FontAwesomeIcon icon={faPlus} className='me-1' /> {t('Add Simple Registration')}
                  </Button>
                }
                {ifNavAllowed('registrations-proforma-list') &&
                  <Button onClick={() => this.props.toggleRegistrationProformaModal({ title: 'New Proforma Registration', child: { label: this.state.registrationsModal.data?.child_name, value: this.state.registrationsModal.data?.child_id, ...this.state.registrationsModal.data }, registrations: [], todayDate: moment().format('YYYY-MM-DD'), addRegCount: 1, addservices: [] })}
                    className='me-2' variant='success' size='sm'>
                    <FontAwesomeIcon icon={faPlus} className='me-1' /> {t('Add Proforma Registration')}
                  </Button>
                }
                {ifNavAllowed('student-registration-advance') &&
                  <Button onClick={() => this.props.toggleRegistrationAdvanceModal({ title: 'New Registration', child: { label: this.state.registrationsModal.data?.child_name, value: this.state.registrationsModal.data?.child_id, ...this.state.registrationsModal.data }, registrations: [], todayDate: moment().format('YYYY-MM-DD'), addRegCount: 1, addservices: [] })}
                    variant='success' size='sm'>
                    <FontAwesomeIcon icon={faPlus} className='me-1' /> {t('Add Advance Registration')}
                  </Button>
                }
              </Col>
            </Row>
          </Modal.Header>
          <Modal.Body>
            <Table>
              <tbody>
                {registrations.map((v, i) => {
                  return <tr>
                    <td>
                      {/* <FontAwesomeIcon icon={faEdit} color={brand_colors[this.props.defaultTheme.theme_id].color16} className='cursor-pointer ms-3'
                        onClick={() => this.props.toggleRegistrationSimpleModal({
                          title: 'Update Registration', child: this.state.registrationsModal.data, registrations: [{
                            ...v,
                            child: { label: this.state.registrationsModal.data?.child_name, value: this.state.registrationsModal.data?.child_id, ...this.state.registrationsModal.data },
                            class_id: { label: v.class_theme, value: v.class_id },
                            session_id: { label: v.session_name, value: v.session_id, session_day_number: v.session_day_number },
                            sessioncat_id: { label: v.sessioncat_name, value: v.sessioncat_id },
                            term_id: { label: v.term_name, value: v.term_id, term_active: v.term_active },
                          }], reg_date: v.reg_date
                        })
                        }
                      /> */}
                      {/* DELETE */}
                      {/* <FontAwesomeIcon icon={faTrashCan} color={brand_colors[this.props.defaultTheme.theme_id].color11} className='cursor-pointer ms-3' onClick={() => this.deleteRegistration(v.reg_id)} /> */}

                      {/* PRINT */}
                      <FontAwesomeIcon icon={faPrint} color={brand_colors[this.props.defaultTheme.theme_id].color4}
                        style={{
                          cursor: v.reg_num == 0 ? 'not-allowed' : 'pointer',
                          opacity: v.reg_num == 0 ? 0.3 : 1
                        }}
                        className='cursor-pointer ms-3' onClick={() => v.reg_num == 0 ? null : this.printRegistration(v)} />
                    </td>
                    <td>
                      <strong>{v.class_theme}</strong>
                    </td>
                    <td>
                      {moment(v.reg_date, 'YYYY-MM-DD').format('YYYY-MM-DD')}
                    </td>
                    <td>
                      {v.reg_year} - {v.term_name}
                    </td>
                    <td>
                      {v.sessioncat_name}
                    </td>
                    <td>
                      {v.session_name}
                    </td>
                    <td>
                      <Badge bg="success">{moment(v.date_from).format('YYYY-MM-DD')}</Badge>
                      <Badge className='ms-1' bg="warning">{moment(v.date_to).format('YYYY-MM-DD')}</Badge>
                    </td>
                  </tr>
                })}
              </tbody>
            </Table>
          </Modal.Body>
        </Modal>

      </>
    );
  }
}

const mapStateToProps = (state) => ({
  authData: state.auth.authData,
  selectedClass: state.selectedClass.data,
  defaultLanguage: state.language.defaultLanguage,
  defaultTheme: state.theme.defaultTheme,
  studentListFetching: state.studentList.fetching,
  studentListData: state.studentList.data,
  studentListSearch: state.studentList.query,
  studentListRows: state.studentList.rows,
  studentListOffset: state.studentList.offset,
  studentListStatus: state.studentList.status,
  studentListOrder: state.studentList.order,
  studentListDir: state.studentList.dir,
  studentListLastPage: state.studentList.last_page,
  studentListPage: state.studentList.page,
  studentListTotalRecords: state.studentList.total_records,
  studentListTotalCount: state.studentList.total_count,
});

const mapDispatchToProps = () => ({
  getStudents,
  setStudents,
  setAddEditStudentModal,
  setAddEditParentModal,
  toggleRegistrationSimpleModal,
  toggleRegistrationAdvanceModal,
  toggleRegistrationProformaModal
});

export default connect(mapStateToProps, mapDispatchToProps())(StudentInformationAdmin);

