import React, { Component } from 'react';
import {
  Row,
  Col,
  Button,
  Spinner,
  Modal,
  Form,
  Table,
  Alert,
} from 'react-bootstrap';
import { t } from '../../../helpers/translation_helper';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCircle,
  faCircleCheck,
  faPencil,
  faPlus,
  faTrash,
  faPenToSquare,
  faBan,
  faSave,
} from '@fortawesome/free-solid-svg-icons';
import { GET, POST } from '../../../api';
import {
  setEvalGroup,
  setEvalCat,
  setEvalTopic,
} from '../../../redux/slices/evaluationSlice';
import { toast } from 'react-toastify';
import { handleFormErrors } from '../../../helpers/form_helpers';
import { colorPalette } from '../../../constants/colorsPalette';
import { brand_colors } from '../../../helpers/brand_colors_helper';
import SweetAlert from 'react-bootstrap-sweetalert';
import Select from 'react-select';

class Groups extends Component {
  defaultFormFields = {
    appraisalgrp_id: 0,
    appraisalgrp_code: '',
    appraisalgrp_desc: '',
    appraisalgrp_desc2: '',
    appraisalassessment: 0,
  };

  defaultAttainmentFormFields = {
    apprassmt_id: 0,
    apprassmt_code: '',
    apprassmt_desc: '',
    apprassmt_value: '',
    apprassmt_color: '#ccc',
    att_appraisalgrp_id: '',
  };

  state = {
    ...this.defaultFormFields,
    ...this.defaultAttainmentFormFields,
    deleteModal: { show: false, id: 0, action: () => {} },
    groups: null,
    attainments: null,
    working: false,
    isEditing: false,
    workingAtt: false,
    isEditingAtt: false,
    showFormModal: false,
    showAttainmentFormModal: false,
  };

  componentDidMount() {
    this.init();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.authData.centerData.center_id !==
      this.props.authData.centerData.center_id
    ) {
      this.init();
    }
  }

  init = () => {
    this.props.setEvalGroup(null);
    this.props.setEvalCat(null);
    this.props.setEvalTopic(null);
    this.getGroups();
    this.getAttainments();
  };

  getGroups = async () => {
    try {
      const res = await GET('evaluation-setup/get-groups');

      if (res.status == 200) {
        this.setState({
          groups: res.data,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  getAttainments = async () => {
    try {
      const res = await GET('evaluation-setup/get-attainments');

      if (res.status == 200) {
        this.setState({
          attainments: res.data,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  groupCheck = (id) => {
    return this.props.evalGroup == id;
  };

  handleOpenFormModal = (modal) => {
    this.setState({
      [modal]: true,
    });
    console.log('modalData', this.state);
  };

  handleCloseFormModal = () => {
    this.setState({
      showFormModal: false,
      showAttainmentFormModal: false,
      isEditing: false,
      ...this.defaultFormFields,
      ...this.defaultAttainmentFormFields,
    });
  };

  saveUpdate = async () => {
    const { authData } = this.props;
    const { center_id } = authData.centerData;

    this.setState({ working: true });

    const {
      appraisalgrp_id,
      appraisalgrp_code,
      appraisalgrp_desc,
      appraisalgrp_desc2,
      appraisalassessment,
    } = this.state;

    const fData = new FormData();
    fData.append('center_id', center_id);
    fData.append(
      'evalGroupData',
      JSON.stringify({
        appraisalgrp_id,
        appraisalgrp_code,
        appraisalgrp_desc,
        appraisalgrp_desc2,
        appraisalassessment,
      })
    );

    try {
      const res = await POST('evaluation-setup/save-group', fData);
      if (res.data.type) {
        this.setState({ working: false });
        toast.success(t(res.data.message));
        this.getGroups();
        this.handleCloseFormModal();
      } else {
        // toast.error(t(res.data.message));
        handleFormErrors(res.data.data);
        this.setState({ working: false });
      }
    } catch (err) {
      console.log('err', err);
      toast.error(t('Something went wrong!'));

      this.setState({ working: false });
    }
  };

  edit = (data) => {
    this.setState({
      isEditing: true,
      appraisalgrp_id: data.appraisalgrp_id,
      appraisalgrp_code: data.appraisalgrp_code,
      appraisalgrp_desc: data.appraisalgrp_desc,
      appraisalgrp_desc2: data.appraisalgrp_desc2,
      appraisalassessment: data.appraisalassessment,
    });
    this.handleOpenFormModal('showFormModal');
  };

  delete = async (data) => {
    this.setState({
      deleteModal: {
        ...this.state.deleteModal,
        show: true,
        id: data,
        action: async () => {
          try {
            const res = await GET('evaluation-setup/delete-group', {
              params: {
                appraisalgrp_id: data.appraisalgrp_id,
              },
            });
            if (res.data.type) {
              const groups = this.state.groups.filter(
                (item) => item.appraisalgrp_id !== data.appraisalgrp_id
              );
              this.setState({
                groups,
                deleteModal: { ...this.state.deleteModal, show: false },
              });
              toast.success(t(res.data.message));
              this.props.setEvalGroup(null);
            } else {
              this.setState({
                deleteModal: { ...this.state.deleteModal, show: false },
              });
              toast.error(t(res.data.message));
            }
          } catch (err) {
            this.setState({
              deleteModal: { ...this.state.deleteModal, show: false },
            });
            console.log(err);
            toast.error(t('Something went wrong!'));
          }
        },
      },
    });
  };

  saveUpdateAttainment = async () => {
    const { authData } = this.props;
    const { center_id } = authData.centerData;

    this.setState({ workingAtt: true });

    const {
      apprassmt_id,
      apprassmt_code,
      apprassmt_desc,
      apprassmt_value,
      apprassmt_color,
      appraisalgrp_id,
    } = this.state;

    const fData = new FormData();
    fData.append('center_id', center_id);
    fData.append(
      'evalAttData',
      JSON.stringify({
        apprassmt_id,
        apprassmt_code,
        apprassmt_desc,
        apprassmt_value,
        apprassmt_color,
        appraisalgrp_id,
      })
    );

    try {
      const res = await POST('evaluation-setup/save-attainment', fData);
      if (res.data.type) {
        this.setState({
          workingAtt: false,
          isEditingAtt: false,
          ...this.defaultAttainmentFormFields,
        });
        toast.success(t(res.data.message));
        this.getAttainments();
      } else {
        // toast.error(t(res.data.message));
        handleFormErrors(res.data.data);
        this.setState({ workingAtt: false });
      }
    } catch (err) {
      console.log('err', err);
      toast.error(t('Something went wrong!'));

      this.setState({ workingAtt: false });
    }
  };

  editAttainment = (data) => {
    this.setState({
      isEditing: true,
      apprassmt_id: data.apprassmt_id,
      apprassmt_code: data.apprassmt_code,
      apprassmt_desc: data.apprassmt_desc,
      apprassmt_value: data.apprassmt_value,
      apprassmt_color: data.apprassmt_color,
    });
  };

  deleteAttainment = async (data) => {
    try {
      const res = await GET('evaluation-setup/delete-attainment', {
        params: {
          apprassmt_id: data.apprassmt_id,
        },
      });
      if (res.data.type) {
        const attainments = this.state.attainments.filter(
          (item) => item.apprassmt_id !== data.apprassmt_id
        );
        this.setState({ attainments });
        toast.success(t(res.data.message));
        this.props.setEvalGroup(null);
      } else {
        toast.error(t(res.data.message));
      }
    } catch (err) {
      console.log(err);
      toast.error(t('Something went wrong!'));
    }
  };

  render() {
    console.log(
      '%cSTATE ===========',
      'font-size:20px;color:orange;font-weight:bold'
    );
    console.log(this.state);

    return (
      <div>
        <div className='grey-section-header px-0'>
          <h5>
            <span className='me-1'>{t('Evaluation Groups')}</span>
          </h5>
          <div>
            <Button
              className='me-2'
              variant='success'
              size='sm'
              title={t('Add Group')}
              onClick={() => this.handleOpenFormModal('showFormModal')}>
              <FontAwesomeIcon icon={faPlus} /> {t('Group')}
            </Button>
          </div>
        </div>
        <div className='group-reel p-3 py-4'>
          {this.state.groups == null && (
            <div
              className='text-center p-5'
              style={{
                flex: 1,
              }}>
              <Spinner animation='grow' size='sm' className='me-2' />
              {t('Loading')}
            </div>
          )}
          {this.state.groups && this.state.groups.length == 0 && (
            <div
              className='text-center p-5'
              style={{
                flex: 1,
              }}>
              {t('No Data Found')}
            </div>
          )}
          {this.state.groups &&
            this.state.groups.map((grp, grp_i) => (
              <div className='eval-group' key={grp_i}>
                <div>{grp.appraisalgrp_code}</div>
                <div>
                  <Button
                    variant='light'
                    size='sm'
                    style={{
                      borderRadius: 15,
                      marginRight: 5,
                      color: this.groupCheck(grp.appraisalgrp_id)
                        ? brand_colors[this.props.defaultTheme.theme_id].color4
                        : brand_colors[this.props.defaultTheme.theme_id]
                            .color18,
                      backgroundColor: this.groupCheck(grp.appraisalgrp_id)
                        ? brand_colors[this.props.defaultTheme.theme_id]
                            .color4_light
                        : brand_colors[this.props.defaultTheme.theme_id].color7,
                      borderColor: this.groupCheck(grp.appraisalgrp_id)
                        ? brand_colors[this.props.defaultTheme.theme_id]
                            .color4_light
                        : brand_colors[this.props.defaultTheme.theme_id].color7,
                    }}
                    onClick={() => {
                      this.props.setEvalGroup(
                        this.groupCheck(grp.appraisalgrp_id)
                          ? null
                          : grp.appraisalgrp_id
                      );
                      this.props.setEvalCat(null);
                      this.props.setEvalTopic(null);
                    }}>
                    <FontAwesomeIcon
                      icon={
                        this.groupCheck(grp.appraisalgrp_id)
                          ? faCircleCheck
                          : faCircle
                      }
                      color={
                        this.groupCheck(grp.appraisalgrp_id)
                          ? brand_colors[this.props.defaultTheme.theme_id]
                              .color4
                          : brand_colors[this.props.defaultTheme.theme_id]
                              .color8
                      }
                      className='me-1'
                    />
                    {t('Preview')}
                  </Button>
                  <Button
                    variant='warning'
                    size='sm'
                    title={t('Edit Group')}
                    style={{
                      borderRadius: 15,
                      marginRight: 5,
                    }}
                    onClick={() => this.edit(grp)}>
                    <FontAwesomeIcon icon={faPencil} />
                  </Button>
                  <Button
                    variant='danger'
                    size='sm'
                    title={t('Delete Group')}
                    style={{ borderRadius: 15 }}
                    onClick={() => this.delete(grp)}>
                    <FontAwesomeIcon icon={faTrash} />
                  </Button>
                </div>
              </div>
            ))}
        </div>

        {/* Group Modal */}
        <Modal
          backdrop='static'
          keyboard={false}
          size={'xl'}
          show={this.state.showFormModal}
          onHide={this.handleCloseFormModal}>
          <Modal.Header closeButton>
            <Modal.Title>
              {this.state.isEditing ? t('Edit') : t('Add')} {t('Group')}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className='custom-styled-form'>
            <Row>
              <Col md={4}>
                <Form.Group className='mb-3'>
                  <Form.Label>{t('Group')}</Form.Label>
                  <Form.Control
                    type='text'
                    defaultValue={this.state.appraisalgrp_code}
                    onChange={(e) =>
                      this.setState({ appraisalgrp_code: e.target.value })
                    }
                  />
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group className='mb-3'>
                  <Form.Label>{t('Description')}</Form.Label>
                  <Form.Control
                    type='text'
                    defaultValue={this.state.appraisalgrp_desc}
                    onChange={(e) =>
                      this.setState({ appraisalgrp_desc: e.target.value })
                    }
                  />
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group className='mb-3'>
                  <Form.Label>{t('Notes')}</Form.Label>
                  <Form.Control
                    type='text'
                    defaultValue={this.state.appraisalgrp_desc2}
                    onChange={(e) =>
                      this.setState({ appraisalgrp_desc2: e.target.value })
                    }
                  />
                </Form.Group>
              </Col>
              <Col md={12} className='ps-0'>
                <Form.Group className='mb-3'>
                  <Form.Label>
                    <strong>
                      {t(
                        'It will allow the use of Grades / Attainment while doing the Evaluation'
                      )}
                    </strong>
                  </Form.Label>
                  <div className='p-3 pb-0'>
                    <Button
                      size='sm'
                      className='me-2'
                      onClick={() => this.setState({ appraisalassessment: 0 })}
                      variant={
                        this.state.appraisalassessment == 1 ? 'dark' : 'success'
                      }>
                      {t('Grades')}
                    </Button>
                    <Button
                      size='sm'
                      onClick={() => this.setState({ appraisalassessment: 1 })}
                      variant={
                        this.state.appraisalassessment == 0 ? 'dark' : 'success'
                      }>
                      {t('Attainment')}
                    </Button>
                  </div>
                </Form.Group>
              </Col>
            </Row>
            {!this.state.isEditing && this.state.appraisalassessment == 1 && (
              <Alert className='text-center mb-0' variant={'info'}>
                {t('Please save the Group before adding Attainments!')}
              </Alert>
            )}
            {this.state.isEditing && this.state.appraisalassessment == 1 && (
              <>
                <Row className='mb-2 custom-styled-form'>
                  <Col>
                    <Row>
                      <Col md={4}>
                        <Form.Group className='mb-3'>
                          <Form.Label>{t('Code')}</Form.Label>
                          <Form.Control
                            type='text'
                            value={this.state.apprassmt_code}
                            onChange={(e) =>
                              this.setState({ apprassmt_code: e.target.value })
                            }
                          />
                        </Form.Group>
                      </Col>
                      <Col md={1}>
                        <Form.Group className='mb-3'>
                          <Form.Label>{t('Color')}</Form.Label>
                          <div
                            style={{ marginTop: -10 }}
                            className='selected-color'
                            onClick={() =>
                              this.setState({
                                openColorPalette: !this.state.openColorPalette,
                              })
                            }>
                            <div
                              style={{
                                backgroundColor: this.state.apprassmt_color,
                              }}></div>
                          </div>
                          {this.state.openColorPalette && (
                            <div
                              className='color-palette'
                              style={{
                                zIndex: 1,
                              }}>
                              {colorPalette.map((color, color_i) => (
                                <div
                                  key={color_i}
                                  onClick={() =>
                                    this.setState({
                                      apprassmt_color: color.code,
                                      openColorPalette: false,
                                    })
                                  }
                                  style={{ backgroundColor: color.code }}></div>
                              ))}
                            </div>
                          )}
                        </Form.Group>
                      </Col>
                      <Col md={4}>
                        <Form.Group className='mb-3'>
                          <Form.Label>{t('Description')}</Form.Label>
                          <Form.Control
                            type='text'
                            value={this.state.apprassmt_desc}
                            onChange={(e) =>
                              this.setState({ apprassmt_desc: e.target.value })
                            }
                          />
                        </Form.Group>
                      </Col>
                      <Col md={3}>
                        <Form.Group className='mb-3'>
                          <Form.Label>{t('Value')}</Form.Label>
                          <Form.Control
                            type='text'
                            value={this.state.apprassmt_value}
                            onChange={(e) =>
                              this.setState({ apprassmt_value: e.target.value })
                            }
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </Col>
                  <Col md={'auto'} style={{ paddingTop: 13 }}>
                    <Button
                      disabled={this.state.workingAtt}
                      onClick={() => this.saveUpdateAttainment()}
                      style={{ width: 40 }}
                      variant={'success'}>
                      {this.state.workingAtt ? (
                        <Spinner
                          as='span'
                          animation='grow'
                          size='sm'
                          role='status'
                          aria-hidden='true'
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faSave}
                          color={
                            brand_colors[this.props.defaultTheme.theme_id]
                              .color8
                          }
                        />
                      )}
                    </Button>
                  </Col>
                </Row>
                <hr />
                {this.state.attainments == null && (
                  <div
                    className='text-center p-5'
                    style={{
                      flex: 1,
                    }}>
                    <Spinner animation='grow' size='sm' className='me-2' />
                    {t('Loading')}
                  </div>
                )}
                {this.state.attainments &&
                  this.state.attainments.length == 0 && (
                    <div
                      className='text-center p-5'
                      style={{
                        flex: 1,
                      }}>
                      {t('No Data Found')}
                    </div>
                  )}
                {this.state.attainments &&
                  this.state.attainments.length > 0 && (
                    <Table size='sm'>
                      <tbody>
                        {this.state.attainments
                          .filter(
                            (item) =>
                              item.appraisalgrp_id ==
                                this.state.appraisalgrp_id &&
                              item.appraisalgrp_id != 0
                          )
                          .map((att, att_i) => (
                            <tr key={att_i}>
                              <td
                                style={{
                                  width: 370,
                                  padding: '10px 0',
                                  verticalAlign: 'middle',
                                }}>
                                <span
                                  style={{
                                    color: att.apprassmt_color,
                                    borderColor: att.apprassmt_color,
                                    borderWidth: 2,
                                    borderRadius: 5,
                                    borderStyle: 'solid',
                                    padding: '3px 7px',
                                  }}>
                                  {att.apprassmt_code}
                                </span>
                              </td>
                              <td
                                style={{
                                  width: 280,
                                  verticalAlign: 'middle',
                                }}>
                                {att.apprassmt_desc}
                              </td>
                              <td
                                style={{
                                  width: 165,
                                  verticalAlign: 'middle',
                                }}>
                                {att.apprassmt_value}
                              </td>
                              <td
                                style={{
                                  width: 80,
                                  verticalAlign: 'middle',
                                  textAlign: 'right',
                                }}>
                                <FontAwesomeIcon
                                  title={t('Edit Attainment')}
                                  onClick={() => this.editAttainment(att)}
                                  style={{
                                    cursor: 'pointer',
                                  }}
                                  icon={faPenToSquare}
                                  color={
                                    brand_colors[
                                      this.props.defaultTheme.theme_id
                                    ].color16
                                  }
                                  className='me-3'
                                />
                                <FontAwesomeIcon
                                  title={t('Delete Attainment')}
                                  onClick={() => this.deleteAttainment(att)}
                                  style={{
                                    cursor: 'pointer',
                                  }}
                                  icon={faTrash}
                                  color={
                                    brand_colors[
                                      this.props.defaultTheme.theme_id
                                    ].color11
                                  }
                                />
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  )}
              </>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' onClick={this.handleCloseFormModal}>
              {t('Close')}
            </Button>
            <Button
              disabled={this.state.working}
              variant='success'
              onClick={() => this.saveUpdate()}>
              {t('Save')}{' '}
              {this.state.working ? (
                <Spinner
                  as='span'
                  animation='grow'
                  size='sm'
                  role='status'
                  aria-hidden='true'
                />
              ) : (
                ''
              )}
            </Button>
          </Modal.Footer>
        </Modal>

        <SweetAlert
          show={this.state.deleteModal.show}
          warning
          showCancel
          confirmBtnText={t('Yes, delete it!')}
          confirmBtnBsStyle='danger'
          title={t('Are you sure?')}
          onConfirm={this.state.deleteModal.action}
          onCancel={() =>
            this.setState({
              deleteModal: {
                ...this.state.deleteModal,
                show: false,
                id: 0,
                action: () => {},
              },
            })
          }
          focusCancelBtn>
          {t('Confirm Delete!')}
        </SweetAlert>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  authData: state.auth.authData,
  defaultLanguage: state.language.defaultLanguage,
  evalGroup: state.evaluation.group,
  defaultTheme: state.theme.defaultTheme,
});

const mapDispatchToProps = () => ({
  setEvalGroup,
  setEvalCat,
  setEvalTopic,
});

export default connect(mapStateToProps, mapDispatchToProps())(Groups);
