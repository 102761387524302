/**
 * Themes Slice
 */
import { createSlice } from '@reduxjs/toolkit';

export const themeSlice = createSlice({
  name: 'theme',
  initialState: {
    defaultTheme: {
      theme_name: 'Primary Theme',
      theme_id: 'primary_theme'
    },
  },
  reducers: {
    setDefaultTheme: (state, action) => {
      document.getElementsByTagName('body')[0].setAttribute('class', action.payload.theme_id);
      state.defaultTheme = action.payload;
    },
  },
});

export const { setDefaultTheme } =
  themeSlice.actions;
export default themeSlice.reducer;
