import React, { Component } from 'react';
import {
  Container, Card, Row, Col, ButtonGroup,
  Button,
  DropdownButton,
  Dropdown,
  Table,
  Form,
  Spinner,
  Badge,
  Modal,
  Image
} from 'react-bootstrap';
import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';
import { t } from '../../helpers/translation_helper';
import NavBar from '../_partials/NavBar/_NavBar';
import Header from '../_partials/Header/_Header';
import { connect } from 'react-redux';
import ReactPaginate from 'react-paginate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faCheckCircle, faSearch, faUser, faXmark, faLayerGroup, faHandsHoldingChild, faPeopleGroup, faListCheck, faPrint, faPencil, faTrash, faCheckDouble, faSortDown, faSortUp, faThumbsUp, faThumbsDown, faBook, faCircleDot, faAngleLeft, faAngleRight, faEllipsis, faIdCardClip, faMailForward } from '@fortawesome/free-solid-svg-icons';
import { brand_colors } from '../../helpers/brand_colors_helper';
import { Tooltip as Tooltiper } from 'react-tooltip';
import { Link, NavLink } from 'react-router-dom';
import { faCircle, faEye, faEyeSlash, faFileExcel, faFilePdf, faIdCard } from '@fortawesome/free-regular-svg-icons';
import { getParents, setParents } from '../../redux/slices/parentsListSlice';
import { setAddEditParentModal } from '../../redux/slices/addEditParentModalSlice';
import moment from 'moment';
import { ifNavAllowed, isTeacher } from '../../helpers/general_helpers';
import axios from 'axios';
import { toast } from 'react-toastify';
import AddEditParent from '../_common/AddEditParent/_AddEditParent';
import SweetAlert from 'react-bootstrap-sweetalert';
import { GET, POST } from '../../api';
import { Scrollbar } from 'react-scrollbars-custom';
import Pagination from '../_partials/Pagination';
import GeneralCard from '../../helpers/GeneralCard';
import { renderTableHead } from '../../helpers/table_helper';

const portalURL = process.env.REACT_APP_PORTAL_URL;
const apiURL = process.env.REACT_APP_API_URL;

class Parents extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchExpanded: false,
      deleteModal: { show: false, id: 0, action: () => { }, title: null, desc: null },
      cdt: {
        fetching: false,
        search: '',
        offset: 0,
        rows: this.props.parentsListRows,
        order: this.props.parentsListOrder,
        dir: this.props.parentsListDir,
        lastPage: 0,
        page: 0,
        totalRecords: 0,
        rowsOptions: [10, 25, 50, 'All'],
        columns: [
          { id: 'p.parent_id', column: 'parent_id', name: 'Actions', visibility: true, sortable: true },
          { id: 'p.parent_name', column: 'parent_name', name: 'Name', visibility: true, sortable: true },
          { id: 'p.spouse_name', column: 'spouse_name', name: 'Spouse Name', visibility: true, sortable: true },
          { id: 'p.user_name', column: 'user_name', name: 'Username', visibility: true, sortable: true },
          { id: 'p.user_pass', column: 'user_pass', name: 'Password', visibility: true, sortable: true },
          { id: 'p.parent_pin', column: 'parent_pin', name: 'Pin', visibility: true, sortable: true },
          { id: 'p.phone1', column: 'phone1', name: 'Phone 1', visibility: true, sortable: true },
          { id: 'p.email1', column: 'email1', name: 'Email 1', visibility: true, sortable: true },
          { id: 'p.parenttype_name', column: 'parenttype_name', name: 'Parent Type', visibility: true, sortable: true },
          { id: 'p.name_en', column: 'name_en', name: 'Nationality', visibility: true, sortable: true },
        ]
      },
      modalStdSubjects: {
        show: false,
        toggle: (title = 'Subject Selection', parent_id) => this.setState({ modalStdSubjects: { ...this.state.modalStdSubjects, show: !this.state.modalStdSubjects.show, title, parent_id } }, () => {
          if (this.state.modalStdSubjects.show) {
            this.getChildRegistrations(this.state.modalStdSubjects.parent_id)
          }
        }),
        parent_id: 0,
        title: '',
        save: this.saveAcademicClassmainSubjectsStd,
        saving: false,
        refreshing: false,
        registrations: [],
        selectedRegistration: -1,
        academic_classmain_subject: [],
        academic_classmain_subjects_std: [],
        activeTab: 'all'//all | selected
      },
      showAllClasses: [1, 2, 8, 12, 22].includes(props.authData.loginData.role_id) ? true : false
    }
    this.searchFieldRef = React.createRef();
  }

  componentDidMount() {
    this.fetch({
      limit: this.props.parentsListRows,
      order: this.props.parentsListOrder,
      dir: this.props.parentsListDir,
      page: 0,
      search: '',
      status: this.props.parentsListStatus
    })
  }

  componentDidUpdate(prevProps, prevState) {
    if ((prevProps.authData.centerData.center_id !== this.props.authData.centerData.center_id) || (this.props.selectedClass && prevProps.selectedClass.class.class_id !== this.props.selectedClass.class.class_id)) {
      this.fetch({
        limit: this.props.parentsListRows,
        order: this.props.parentsListOrder,
        dir: this.props.parentsListDir,
        page: 0,
        search: '',
        status: this.props.parentsListStatus
      });
      this.setState({ searchExpanded: false });
      this.searchFieldRef.current.value = '';
    }
  }

  fetch = (params) => {

    this.setState({
      cdt: {
        ...this.state.cdt,
        order: params.order,
        dir: params.dir,
      }
    });

    this.props.getParents({
      parent_status: params.status,
      search: params.search,
      limit: params.limit,
      page: params.page,
      order: params.order,
      dir: params.dir,
      showAllClasses: this.state.showAllClasses,
      registrations: true,
    });
  }

  getChildRegistrations = (parent_id) => {
    if (this.getChildRegistrationsReq) {
      this.getChildRegistrationsReq.abort();
    }
    this.getChildRegistrationsReq = new AbortController();

    let modalStdSubjects = this.state.modalStdSubjects
    this.setState({
      modalStdSubjects: {
        ...modalStdSubjects,
        refreshing: true,
        registrations: [],
        selectedRegistration: -1,
        academic_classmain_subject: [],
        academic_classmain_subjects_std: []
      }
    }, async () => {
      try {
        const res = await GET('children/get-child-registrations', {
          params: {
            parent_id: parent_id
          },
          signal: this.getChildRegistrationsReq.signal,
        }
        );
        console.log('children/get-child-registrations', res)
        let resData = res.data
        let cFutureRegistrations = []
        let uniqueClasses = []
        // TODO APPLY CONDITION FOR CYCLES HERE
        resData.data.map((v, i) => {
          if (moment(v.date_to).isAfter(moment(resData.date)) && !uniqueClasses.includes(v.class_id)) {
            cFutureRegistrations.push(v)
            uniqueClasses.push(v.class_id)
          }
        })
        modalStdSubjects = this.state.modalStdSubjects
        this.setState({
          modalStdSubjects: {
            ...modalStdSubjects,
            refreshing: false,
            registrations: cFutureRegistrations,
            selectedRegistration: (cFutureRegistrations.length > 0) ? 0 : -1
          }
        }, () => {
          if (this.state.modalStdSubjects.show && this.state.modalStdSubjects.selectedRegistration != -1) {
            this.getAcademicClassmainSubjectsStd()
          }
        })
      } catch (err) {
        console.log('err', err)
        if (axios.isCancel(err)) {

        } else {
          toast.error(t('Something went wrong while fetching data!'));

        }
      }
    })
  };

  getAcademicClassmainSubjectsStd = async () => {
    if (this.getAcademicClassmainSubjectsStdReq) {
      this.getAcademicClassmainSubjectsStdReq.abort();
    }
    this.getAcademicClassmainSubjectsStdReq = new AbortController();

    let modalStdSubjects = this.state.modalStdSubjects
    this.setState({
      modalStdSubjects: {
        ...modalStdSubjects,
        refreshing: true,
        academic_classmain_subject: [],
        academic_classmain_subjects_std: []
      }
    })

    try {
      const res = await GET('children/get-academic-classmain-subjects-std', {
        params: {
          class_id: modalStdSubjects.registrations[modalStdSubjects.selectedRegistration].class_id,
          agegroup_id: modalStdSubjects.registrations[modalStdSubjects.selectedRegistration].agegroup_id,
          parent_id: modalStdSubjects.registrations[modalStdSubjects.selectedRegistration].parent_id,
          centerlic_id: modalStdSubjects.registrations[modalStdSubjects.selectedRegistration].centerlic_id,
        },
        signal: this.getAcademicClassmainSubjectsStdReq.signal,
      }
      );
      console.log('children/get-academic-classmain-subjects-std', res)
      let resData = res.data
      let academic_classmain_subjects_std = {
        acs_std_id: 0,
        acs_ids: [],
        class_id: modalStdSubjects.registrations[modalStdSubjects.selectedRegistration].class_id,
        agegroup_id: modalStdSubjects.registrations[modalStdSubjects.selectedRegistration].agegroup_id,
        parent_id: modalStdSubjects.registrations[modalStdSubjects.selectedRegistration].parent_id,
        centerlic_id: modalStdSubjects.registrations[modalStdSubjects.selectedRegistration].centerlic_id,
      }
      if (resData.academic_classmain_subjects_std != undefined) {

        academic_classmain_subjects_std = resData.academic_classmain_subjects_std
        academic_classmain_subjects_std.acs_ids = JSON.parse(academic_classmain_subjects_std.acs_ids)
      }
      this.setState({
        modalStdSubjects: {
          ...modalStdSubjects,
          refreshing: false,
          academic_classmain_subject: resData.academic_classmain_subject,
          academic_classmain_subjects_std: academic_classmain_subjects_std
        }
      })

    } catch (err) {
      console.log('err', err)
      if (axios.isCancel(err)) {

      } else {
        toast.error(t('Something went wrong while fetching data!'));

      }
    }
  };


  saveAcademicClassmainSubjectsStd = async () => {

    if (this.saveAcademicClassmainSubjectsStdReq) {
      this.saveAcademicClassmainSubjectsStdReq.abort();
    }
    this.saveAcademicClassmainSubjectsStdReq = new AbortController();

    let modalStdSubjects = this.state.modalStdSubjects
    this.setState({
      modalStdSubjects: {
        ...modalStdSubjects,
        saving: true,
      }
    })

    let AcademicClassmainSubjectsStdData = modalStdSubjects.academic_classmain_subjects_std

    let fData = new FormData
    fData.append('AcademicClassmainSubjectsStdData', JSON.stringify(AcademicClassmainSubjectsStdData))

    try {
      const res = await POST('children/save-academic-classmain-subjects-std', fData, {
        signal: this.saveAcademicClassmainSubjectsStdReq.signal,
      }
      );
      console.log('children/saveAcademicClassmainSubjectsStd', res)
      let resData = res.data
      if (resData.type) {
        toast.success(t(resData.message));
      }


    } catch (err) {
      console.log('err', err)
      if (axios.isCancel(err)) {

      } else {
        toast.error(t('Something went wrong while fetching data!'));

      }
    }
  }


  showHideColumn = (column_index) => {
    const columns = this.state.cdt.columns;
    columns[column_index].visibility = !columns[column_index].visibility;
    this.setState({ ctd: { ...this.state.ctd, columns } })
  }

  exportExcel = async () => {

    const excelData = this.props.parentsListData.map((parent) => {
      const parentRow = {};
      this.state.cdt.columns
        .filter((column) => column.visibility)
        .map((column) => {

          if (column.name === 'Actions') {
            parentRow['Status'] = parent['parent_status'] == 1 ? 'Active' : 'In-Active';
          } else if (column.column === 'gender') {
            parentRow['Gender'] = parent[column.column] == 'M' ? 'Male' : 'Female';
          } else if (column.column === 'birth_date') {
            parentRow[column.name] = moment(parent[column.column]).format('DD-MM-YYYY');
          } else if (column.column === 'registrations') {
            const regs = parent[column.column].map((v, i) => {
              if (v.term_active == 1) {
                return v.class_theme
              }
            });
            parentRow[column.name] = regs.join(',');
          } else if (
            column.column === 'take_pictures' ||
            column.column === 'pic_social' ||
            column.column === 'pic_media' ||
            column.column === 'pic_nursery') {
            parentRow[column.name] = parent[column.column] == 1 ? 'Yes' : 'No';
          } else if (column.name !== 'Picture') {
            parentRow[column.name] = parent[column.column];
          }

        });
      return parentRow;
    });

    const fileName = 'Parents Export ' + moment().format('DD-MM-YYYY');
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const ws = XLSX.utils.json_to_sheet(excelData);

    const cellStyle = {
      font: {
        bold: true,
      },
    }

    Array.from('ABCDEFGHI').map((letter) => {
      ws[letter + "1"].s = cellStyle;
    });

    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });

    FileSaver.saveAs(data, fileName + fileExtension);
  }

  toggleParentStatus = async (parent_id, data) => {
    this.setState({
      deleteModal: {
        ...this.state.deleteModal, show: true, id: parent_id, title: (data.parent_status == 1) ? 'De-Activate Parent' : 'Activate Parent', desc: (data.parent_status == 1) ? 'You want to De-Activate Parent' : 'You want to Activate Parent', action: async () => {
          const { authData, parentsListData, parentsListStatus } = this.props;
          const { center_id } = authData.centerData;
          const { auth_key } = authData.loginData;

          try {
            const res = await GET('parents/toggle-status', {
              params: {
                auth_key,
                center_id,
                parent_id,
                appname: process.env.REACT_APP_NAME,
              },
            });

            // if (res.status === 200 && res.data.type) {
            let updatedParentsList = [...parentsListData];
            const findIndex = parentsListData.findIndex((parent) => parent.parent_id == data.parent_id);

            let updatedParent;
            updatedParent = { ...data, parent_status: data.parent_status == 1 ? 0 : 1 }

            if (parentsListStatus == 1) {
              updatedParentsList.splice(findIndex, 1);
            } else {
              updatedParentsList[findIndex] = updatedParent;
            }


            this.props.setParents(updatedParentsList);
            this.setState({
              deleteModal: {
                ...this.state.deleteModal, show: false,
              }
            })
            toast.success(t('Parent status updated successfully!'));
            // } else {
            //   this.setState({
            //     deleteModal: {
            //       ...this.state.deleteModal, show: false,
            //     }
            //   })
            // }

          } catch (err) {
            console.log('err', err)
            this.setState({
              deleteModal: {
                ...this.state.deleteModal, show: false,
              }
            })
          }
        }
      }
    })
  }

  deleteParent = async (parent_id, data) => {
    this.setState({
      deleteModal: {
        ...this.state.deleteModal, show: true, id: parent_id, title: 'Delete Parent', desc: 'Confirm delete parent!', action: async () => {
          const { authData, parentsListData, parentsListStatus } = this.props;
          const { center_id } = authData.centerData;
          const { auth_key } = authData.loginData;

          try {
            const res = await GET('parents/delete', {
              params: {
                auth_key,
                center_id,
                parent_id,
                chart_id: data.chart_id,
                appname: process.env.REACT_APP_NAME,
              },
            });

            if (res.status === 200 && res.data.type) {
              let updatedParentsList = [...parentsListData];
              const findIndex = parentsListData.findIndex((parent) => parent.parent_id == data.parent_id);

              updatedParentsList.splice(findIndex, 1);

              this.props.setParents(updatedParentsList);

              toast.warning(t('Parent deleted successfully!'));
              this.setState({
                deleteModal: {
                  ...this.state.deleteModal, show: false,
                }
              })
            } else {
              toast.error(t(res.data.message));
            }

          } catch (err) {
            console.log('err', err)

          }
        }
      }
    })
  }

  mailCredentials = async (parent_id, data) => {

    const { authData } = this.props;

    const res = await GET('children/send-parent-credentials', {
      params: {
        parent_id: data.parent_id,
        center_email: authData.centerData.center_email,
        center_name: authData.centerData.center_name,
      },
    });

    if (res.data.type) {
      toast.warning(t('Credentials sent successfully!'));
    } else {
      toast.error(t(res.data.message));
    }
  }

  renderRow = (column_data, column_id, column_index, data) => {

    const { authData } = this.props;
    const { center_id } = authData.centerData;

    let tdData;
    // ------------------------------------------------------
    if (column_id == 'p.parent_id') { // Action Buttons
      tdData = <>
        <Button
          onClick={() => this.props.setAddEditParentModal({ showModal: true, parent_id: data.parent_id, isEditing: true })}
          variant="warning" size='sm' className='me-1 mb-1' title={t('Edit')}>
          <FontAwesomeIcon icon={faPencil} />
        </Button>
        <Button
          onClick={() => this.deleteParent(column_data, data)}
          variant="danger" size='sm' className='me-1 mb-1' title={t('Delete')}>
          <FontAwesomeIcon icon={faTrash} />
        </Button>
        <Button
          onClick={() => this.toggleParentStatus(column_data, data)}
          variant={data.parent_status == 1 ? 'success' : 'danger'} size='sm' className='me-1 mb-1' title={(data.parent_status == 1) ? t('Disable') : t('Activate')}>
          <FontAwesomeIcon icon={data.parent_status == 1 ? faThumbsUp : faThumbsDown} />
        </Button>
      </>;
      // -------------------------------------------------
    } else if (column_id == 'p.picture') { // Picture
      tdData = <div style={{
        backgroundImage: `url(${(column_data) ? portalURL + center_id + '/children/' + column_data : 'https://via.placeholder.com/150x150?text=' + data.parent_name[0].toUpperCase()})`,
        width: 60,
        height: 60,
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        borderRadius: 30
      }}></div>;
      // -----------------------------------------------------
    } else if (column_id == 'p.parent_status') { // Status
      tdData = column_data == 1 ?
        <Badge bg="info">{t('Active')}</Badge>
        :
        <Badge bg="danger">{t('In-Active')}</Badge>;
      // ----------------------------------------------
    } else if (column_id == 'p.birth_date' || column_id == 'reg_date') { // Birth Date
      tdData = moment(column_data).format('DD-MM-YYYY');

      // -----------------------------------------------
    } else if (column_id == 'p.gender') { // Gender
      tdData = column_data == 'M'
        ? <Badge bg="info">{t('M')}</Badge>
        : <Badge bg="danger">{t('F')}</Badge>;

      // --------------------------------------------------
    } else if ( // Consents
      column_id == 'p.take_pictures' ||
      column_id == 'p.pic_social' ||
      column_id == 'p.pic_media' ||
      column_id == 'p.pic_nursery'
    ) {
      tdData = column_data == 1 ?
        <Badge bg="info">{t('Yes')}</Badge>
        :
        <Badge bg="danger">{t('No')}</Badge>;
    } else if (column_id == 'p.registrations') {
      tdData = null
      if (column_data) {
        tdData = <div className='d-flex flex-row'>
          {column_data.map((v, i) => {
            if (v.term_active == 1) {
              return <Badge key={i} bg="secondary" className='mx-1'>
                {v.class_theme}
              </Badge>
            }
          })}
        </div>
      }
    } else if (column_id == 'parent_user_name' || column_id == 'parent_password') {
      tdData = <Badge bg="danger">{column_data}</Badge>;

      // --------------------------------------------------
    } else { // Default
      tdData = column_data;
    }

    return <td key={column_index}>{tdData}</td>;
  }

  render() {
    let { modalStdSubjects, showAllClasses, cdt } = this.state;
    return (
      <>
        <Card
          className='border-0'
          style={{ borderRadius: '20px' }}>
          <Card.Body>
            <Row>
              <Col md={6} className='d-flex flex-column'>
                <div className='d-flex flex-row align-items-center mb-2'>
                  <strong>{t("Parents")}</strong>
                  <ButtonGroup size='sm' className='ms-2'>
                    {/* <Button><FontAwesomeIcon icon={faFilePdf} /> {t('PDF')}</Button> */}
                    <Button onClick={() => this.exportExcel()}>
                      <FontAwesomeIcon icon={faFileExcel} /> {t('Excel')}
                    </Button>
                    {/* <Button><FontAwesomeIcon icon={faPrint} /> {t('Print')}</Button> */}
                    <DropdownButton
                      autoClose={'outside'}
                      size='sm'
                      as={ButtonGroup}
                      title={t('Column Visibility')}>
                      {this.state.cdt.columns.map((column, column_index) => (
                        <Dropdown.Item key={column_index} className={column.visibility ? 'column-name-item' : 'column-name-item active'} onClick={() => this.showHideColumn(column_index)}>
                          <FontAwesomeIcon icon={column.visibility ? faEye : faEyeSlash} /> {column.name}
                        </Dropdown.Item>
                      ))}
                    </DropdownButton>
                  </ButtonGroup>
                  <div className='expandable-search ms-2' style={{ width: this.state.searchExpanded ? 200 : 35 }}>
                    <input
                      ref={this.searchFieldRef}
                      defaultValue={this.props.parentsListSearch}
                      onChange={(e) => this.fetch({
                        limit: this.props.parentsListRows,
                        order: this.props.parentsListOrder,
                        dir: this.props.parentsListDir,
                        page: 0,
                        search: e.target.value,
                        status: this.props.parentsListStatus
                      })}
                      type='text'
                      style={{ width: this.state.searchExpanded ? 165 : 0, paddingLeft: this.state.searchExpanded ? 15 : 0, opacity: this.state.searchExpanded ? 1 : 0 }}
                      placeholder={t('Search Parent...')} />
                    <button
                      data-tooltip-id="tooltip"
                      data-tooltip-content={t('Search Parent')}
                      data-tooltip-place="top"
                      onClick={() => {
                        this.setState({ searchExpanded: !this.state.searchExpanded });
                        this.props.parentsListSearch != '' && this.state.searchExpanded && this.fetch({
                          limit: this.props.parentsListRows,
                          order: this.props.parentsListOrder,
                          dir: this.props.parentsListDir,
                          page: 0,
                          search: '',
                          status: this.props.parentsListStatus
                        });
                        this.searchFieldRef.current.value = '';
                        !this.state.searchExpanded && this.searchFieldRef.current.focus();
                      }}>
                      <FontAwesomeIcon
                        icon={this.state.searchExpanded ? faXmark : faSearch}
                        color={brand_colors[this.props.defaultTheme.theme_id].color18}
                      />
                    </button>
                  </div>
                </div>
                <div className='d-flex flex-row align-items-center'>
                  <div>
                    {t('Show')}
                    <Form.Select
                      value={this.props.parentsListRows}
                      size="sm"
                      onChange={(e) => this.fetch({
                        limit: e.target.value,
                        status: this.props.parentsListStatus,
                        order: this.props.parentsListOrder,
                        dir: this.props.parentsListDir,
                        page: 0,
                        search: this.props.parentsListSearch,
                      })}
                      style={{ width: 70, marginInline: 5, display: 'inline-block' }}>
                      {this.state.cdt.rowsOptions.map((row, row_index) => (<option key={row_index} >{row}</option>))}
                    </Form.Select>
                    {t('Records')}
                  </div>
                </div>
              </Col>
              <Col md={6} className='d-flex justify-content-end align-items-start'>
                <Badge bg='info' className='me-3' style={{ marginTop: 6 }}>{t('Total Parents: ')} {this.props.parentsListTotalRecords}</Badge>
                <ButtonGroup size='sm'>
                  <Button
                    onClick={() => this.fetch({
                      limit: this.props.parentsListRows,
                      status: true,
                      order: this.props.parentsListOrder,
                      dir: this.props.parentsListDir,
                      page: 0,
                      search: this.props.parentsListSearch
                    })}
                    variant={this.props.parentsListStatus ? 'primary' : 'light'}
                  >{t('Only Active')}</Button>
                  <Button
                    onClick={() => this.fetch({
                      limit: this.props.parentsListRows,
                      status: false,
                      order: this.props.parentsListOrder,
                      dir: this.props.parentsListDir,
                      page: 0,
                      search: this.props.parentsListSearch
                    })}
                    variant={this.props.parentsListStatus ? 'light' : 'primary'}
                  >{t('Show All')}</Button>
                </ButtonGroup>
                <button
                  onClick={() => this.props.setAddEditParentModal({ showModal: true, parent_id: 0, isEditing: false })}
                  className='btn btn-success btn-sm ms-3'><FontAwesomeIcon icon={faPlus} color={brand_colors[this.props.defaultTheme.theme_id].color8} style={{ fontSize: 12 }} />{' '}{t('Add Parent')}</button>
              </Col>
              {this.props.parentsListFetching &&
                <Col md={12} className='py-5 d-flex flex-column align-items-center'>
                  <Spinner as='span' animation='grow' size='sm' /> {t('Loading Parents')}
                </Col>
              }
              {!this.props.parentsListFetching &&
                <Col md={12} className='pt-1'>
                  <div id="datatable-list" className='mb-3 datatable-list-w-sidebar'>
                    <Table responsive>
                      {renderTableHead(this)}
                      <tbody>
                        {this.props.parentsListData.map((parent, parent_index) => (
                          <tr key={parent_index}>
                            {this.state.cdt.columns.filter((column) => column.visibility).map((column, column_index) => this.renderRow(parent[column.column], column.id, column_index, parent))}
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </Col>
              }
            </Row >
            <Pagination
              pageCount={this.props.parentsListLastPage}
              forcePage={this.props.parentsListPage}
              callbackParams={{
                limit: this.props.parentsListRows,
                status: this.props.parentsListStatus,
                order: this.props.parentsListOrder,
                dir: this.props.parentsListDir,
                search: this.props.parentsListSearch
              }}
              callback={this.fetch}
            />
          </Card.Body>
        </Card>
        <AddEditParent />
        <SweetAlert
          show={this.state.deleteModal.show}
          warning
          showCancel
          confirmBtnText={t('Yes!')}
          confirmBtnBsStyle='danger'
          title={(this.state.deleteModal.title) ? this.state.deleteModal.title : t('Are you sure?')}
          onConfirm={this.state.deleteModal.action}
          onCancel={() => this.setState({ deleteModal: { ...this.state.deleteModal, show: false, id: 0, action: () => { } } })}
          focusCancelBtn
        >
          {(this.state.deleteModal.desc) ? this.state.deleteModal.desc : t('Confirm Delete!')}
        </SweetAlert>

        {/* MODAL NEW/EDIT Subject Selection */}
        <Modal show={modalStdSubjects.show} onHide={() => modalStdSubjects.toggle()} size="lg" backdrop="static"
        keyboard={false}>
          <Modal.Header closeButton>
            <Modal.Title>{modalStdSubjects.title}</Modal.Title>
          </Modal.Header>
          {modalStdSubjects.show &&
            <Modal.Body className='custom-styled-form'>
              <div className='d-flex flex-row'>
                {modalStdSubjects.registrations.map((v, i) => {
                  let selected = false
                  if (modalStdSubjects.selectedRegistration == i) {
                    selected = true
                  }
                  return <div className='d-flex flex-column align-items-center ms-1 me-2 p-1 cursor-pointer' style={{ border: (selected) ? 'solid 1px ' + brand_colors[this.props.defaultTheme.theme_id].color4 : 'solid 1px ' + brand_colors[this.props.defaultTheme.theme_id].color10, borderRadius: 10, position: 'relative' }} onClick={() => this.setState({ modalStdSubjects: { ...modalStdSubjects, selectedRegistration: i } }, () => this.getAcademicClassmainSubjectsStd())}>
                    <div style={{ fontSize: 13, color: brand_colors[this.props.defaultTheme.theme_id].color9, opacity: 0.8, fontWeight: 'bold' }}>{v.class_theme}</div>
                    <div style={{ fontSize: 12, color: brand_colors[this.props.defaultTheme.theme_id].color9, opacity: 0.7 }}>{v.reg_year}</div>
                    {selected &&
                      <div style={{ position: 'absolute', top: -10, right: -10 }}>
                        <FontAwesomeIcon icon={faCheckCircle} style={{ color: brand_colors[this.props.defaultTheme.theme_id].color4 }} />
                      </div>
                    }
                  </div>
                })}
              </div>
              <div className='d-flex flex-column align-items-center ms-1 me-2 p-1 cursor-pointer mt-2' style={{ border: 'solid 1px ' + brand_colors[this.props.defaultTheme.theme_id].color10, borderRadius: 10, position: 'relative' }}>
                <div className='d-flex flex-row'>
                  <div className='mx-2 align-items-center justify-content-center p-1 cursor-pointer' style={{ color: (modalStdSubjects.activeTab == 'all') ? brand_colors[this.props.defaultTheme.theme_id].color4 : brand_colors[this.props.defaultTheme.theme_id].color18 }} onClick={() => this.setState({ modalStdSubjects: { ...modalStdSubjects, activeTab: 'all' } })}>{t('All')}</div>
                  <div className='mx-2 align-items-center justify-content-center p-1 cursor-pointer' style={{ color: (modalStdSubjects.activeTab == 'selected') ? brand_colors[this.props.defaultTheme.theme_id].color4 : brand_colors[this.props.defaultTheme.theme_id].color18 }} onClick={() => this.setState({ modalStdSubjects: { ...modalStdSubjects, activeTab: 'selected' } })}>{t('Selected')}</div>
                </div>

                <div style={{ flex: 1, width: '100%' }} className='d-flex'>
                  <Scrollbar style={{ width: '100%', height: '50vh' }} >
                    {modalStdSubjects.academic_classmain_subject.map((v, i) => {
                      if (v.parent_acs_id == null) {
                        let selectedSubject = false
                        if (modalStdSubjects.academic_classmain_subjects_std) {
                          selectedSubject = modalStdSubjects.academic_classmain_subjects_std.acs_ids.findIndex((vv, ii) => vv == v.acs_id)
                        }

                        if (modalStdSubjects.activeTab == 'all' || (modalStdSubjects.activeTab == 'selected' && (selectedSubject != -1 || v.acs_mandatory == 1))) {
                          return <div key={i} >
                            <div className='scroll-list-card'>
                              <div className='d-flex flex-row justify-content-between align-items-center p-0 m-0'>
                                <div className='me-2' style={{ color: brand_colors[this.props.defaultTheme.theme_id].color9 }}>{v.acs_code}</div>
                                <div>{v.acs_name}</div>
                              </div>
                              <div>
                                {/* TOGGLE CHECK*/}
                                {v.acs_mandatory == 1
                                  // MANDATORY
                                  ? <FontAwesomeIcon icon={faCircleDot} color={brand_colors[this.props.defaultTheme.theme_id].color4} className='cursor-pointer me-3' title={t('Mandatory')} />
                                  // ELECTIVE
                                  : <FontAwesomeIcon icon={(selectedSubject != -1) ? faCheckCircle : faCircle} color={brand_colors[this.props.defaultTheme.theme_id].color16} className='cursor-pointer me-3' onClick={() => {
                                    let academic_classmain_subjects_std = modalStdSubjects.academic_classmain_subjects_std
                                    if (selectedSubject != -1) {
                                      academic_classmain_subjects_std.acs_ids.splice(selectedSubject, 1)
                                    } else {
                                      academic_classmain_subjects_std.acs_ids.push(v.acs_id)
                                    }

                                    this.setState({ modalStdSubjects: { ...modalStdSubjects, academic_classmain_subjects_std } })
                                  }} title={t('Toggle Select')} />
                                }
                              </div>
                            </div>
                            {modalStdSubjects.academic_classmain_subject.map((vv, ii) => {
                              if (vv.parent_acs_id == v.acs_id) {
                                let selectedFocus = false
                                if (modalStdSubjects.academic_classmain_subjects_std) {
                                  selectedFocus = modalStdSubjects.academic_classmain_subjects_std.acs_ids.findIndex((vvv) => vvv == vv.acs_id)
                                }

                                if (modalStdSubjects.activeTab == 'all' || (modalStdSubjects.activeTab == 'selected' && (selectedFocus != -1 || vv.acs_mandatory == 1))) {
                                  return <div key={ii} className='scroll-list-card mx-4'>
                                    <div className='d-flex flex-row justify-content-between align-items-center p-0 m-0'>
                                      <div className='me-2' style={{ color: brand_colors[this.props.defaultTheme.theme_id].color9 }}>{vv.acs_code}</div>
                                      <div className='me-2'>{vv.acs_name}</div>
                                      <Badge bg='success'>Focus</Badge>
                                    </div>
                                    {(selectedSubject != -1 || v.acs_mandatory == 1) &&
                                      <div>
                                        {/* TOGGLE CHECK*/}
                                        {vv.acs_mandatory == 1
                                          // MANDATORY
                                          ? <FontAwesomeIcon icon={faCircleDot} color={brand_colors[this.props.defaultTheme.theme_id].color4} className='cursor-pointer me-3' title={t('Mandatory')} />
                                          // ELECTIVE
                                          : <FontAwesomeIcon icon={(selectedFocus != -1) ? faCheckCircle : faCircle} color={brand_colors[this.props.defaultTheme.theme_id].color16} className='cursor-pointer me-3' onClick={() => {
                                            let academic_classmain_subjects_std = modalStdSubjects.academic_classmain_subjects_std
                                            if (selectedFocus != -1) {
                                              academic_classmain_subjects_std.acs_ids.splice(selectedFocus, 1)
                                            } else {
                                              academic_classmain_subjects_std.acs_ids.push(vv.acs_id)
                                            }

                                            this.setState({ modalStdSubjects: { ...modalStdSubjects, academic_classmain_subjects_std } })
                                          }} title={t('Toggle Select')} />
                                        }
                                      </div>
                                    }
                                  </div>
                                }
                              }
                            })}
                          </div>
                        }
                      }
                    })}
                  </Scrollbar>
                </div>
              </div>
            </Modal.Body>
          }
          <Modal.Footer>
            <Button variant="secondary" onClick={() => modalStdSubjects.toggle()}>
              Close
            </Button>
            <Button variant="primary" onClick={modalStdSubjects.save}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  authData: state.auth.authData,
  selectedClass: state.selectedClass.data,
  defaultLanguage: state.language.defaultLanguage,
  defaultTheme: state.theme.defaultTheme,
  parentsListFetching: state.parentsList.fetching,
  parentsListData: state.parentsList.data,
  parentsListSearch: state.parentsList.query,
  parentsListRows: state.parentsList.rows,
  parentsListOffset: state.parentsList.offset,
  parentsListStatus: state.parentsList.status,
  parentsListOrder: state.parentsList.order,
  parentsListDir: state.parentsList.dir,
  parentsListLastPage: state.parentsList.last_page,
  parentsListPage: state.parentsList.page,
  parentsListTotalRecords: state.parentsList.total_records,
  parentsListTotalCount: state.parentsList.total_count,
});

const mapDispatchToProps = () => ({
  getParents,
  setParents,
  setAddEditParentModal,
});

export default connect(mapStateToProps, mapDispatchToProps())(Parents);

