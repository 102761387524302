import { createSlice } from '@reduxjs/toolkit';

export const evaluationSlice = createSlice({
  name: 'evaluation',
  initialState: {
    group: null,
    cat: null,
    topic: null
  },
  reducers: {
    setEvalGroup: (state, action) => {
      state.group = action.payload;
    },
    setEvalCat: (state, action) => {
      state.cat = action.payload;
    },
    setEvalTopic: (state, action) => {
      state.topic = action.payload;
    },
  },
});

export const { setEvalGroup, setEvalCat, setEvalTopic } =
  evaluationSlice.actions;
export default evaluationSlice.reducer;
