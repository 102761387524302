import { createSlice } from '@reduxjs/toolkit';

export const mediaApprovalModalSlice = createSlice({
    name: 'mediaApprovalModal',
    initialState: {
        showModal: false,
    },
    reducers: {
        setMediaApprovalModal: (state, action) => {
            state.showModal = action.payload.showModal;
        },
    },
});

export const { setMediaApprovalModal } =
    mediaApprovalModalSlice.actions;
export default mediaApprovalModalSlice.reducer;
