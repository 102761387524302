import React, { Component } from 'react';
import {
  Container,
  Card,
  Table,
  Row,
  Col,
  Button,
  Offcanvas,
  Modal,
  Form,
  Spinner,
  Tabs,
  Tab,
  Badge,
  ButtonGroup
} from 'react-bootstrap';
import { t } from '../../helpers/translation_helper';
import { connect } from 'react-redux';
import Select from 'react-select';
import axios from 'axios';
import { toast } from 'react-toastify';
import Flatpickr from 'react-flatpickr';
import moment from 'moment';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { TagsInput } from 'react-tag-input-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowAltCircleLeft,
  faCircleCheck,
  faEdit,
  faEye,
  faFilePdf,
  faPencil,
  faPlus,
  faSquare,
  faSquareCheck,
  faTimes,
  faTimesCircle,
  faTrashCan,
} from '@fortawesome/free-solid-svg-icons';
import {
  faCircle,
  faCheckCircle,
  faCircleDot,
  faComments,
} from '@fortawesome/free-regular-svg-icons';
import {
  brand_colors
} from '../../helpers/brand_colors_helper';
import ClassSelector from '../_partials/Header/ClassSelector';
import Paginate from '../_partials/Paginate/_Paginate';
import { GET } from '../../api';
var Chance = require('chance');
var chance = new Chance();

// API URL
const apiURL = process.env.REACT_APP_API_URL;

class PreviewExam extends Component {

  state = {
    // General states
    exams: [],
    showCreateUpdateExamCanvas: false,
    showCreateUpdateQuestionModal: false,
    working: false,
    classes: [],
    terms: [],
    subjects: [],
    lessons: [],
    children: [],
    // examTypes: [],
    questionTypes: [],
    difficultyTypes: [],
    disableLessons: true,
    isEditingQuestion: false,
    editingQuestionId: undefined,
    // --------------
    // Exam form data
    exams_id: 0,
    selectedClass: null,
    selectedTerm: null,
    selectedSubject: null,
    selectedLessons: null,
    selectedExamType: null,
    examName: '',
    weight: '',
    examNote: '',
    examDate: '',
    endDate: '',
    openTime: '',
    closeTime: '',
    shuffleQs: 0,
    resubmitAllowed: 0,
    showCorrectAnswer: 0,
    retakenExam: 0,
    is_exampaper: 0,
    eqtype_id: 0,
    // ------------------
    // Question form data
    uuid: '',
    examsquest_id: 0,
    examsquest_desc1: '',
    examsquest_desc2: '',
    examsquest_desc3: '',
    exams_notes: '',
    sub_obj: '',
    examsquest_grade: '',
    examsquest_order: 0,
    examsquest_difficulty: null,
    examsquest_duration: '',
    eqtype_id: null,
    classmaterialsub_id: '',
    is_mandatory: 0,
    shuffle_allowed: 0,
    examsquest_file1: '',
    examsquest_file2: '',
    examsquest_file3: '',
    examsquest_file4: '',
    examsquest_file5: '',
    examsquest_file1_prev: '',
    examsquest_file2_prev: '',
    examsquest_file3_prev: '',
    examsquest_file4_prev: '',
    examsquest_file5_prev: '',
    examsquest_audio: '',
    examsquestoptions: [],
    // ------------------
    // Group of questions
    questions: [],
    // Paginate
    limit: 12,
    totalRecords: 0,
    // Form sections
    formSectionActive: {
      parent: '1',
      child: '1.1',
    },
    formSections: [
      {
        uid: '1',
        active: true,
        completed: false,
        name: 'Exam',
        desc: null,
        data: [
          {
            uid: '1.2',
            active: false,
            completed: false,
            name: 'Terms',
            desc: null,
            image: require('../../assets/images/lesson/lesson-focus.png'),
          },
          {
            uid: '1.3',
            active: false,
            completed: false,
            name: 'Course',
            desc: null,
            image: require('../../assets/images/lesson/lesson-focus.png'),
          },
          {
            uid: '1.4',
            active: false,
            completed: false,
            name: 'Lessons',
            desc: null,
            image: require('../../assets/images/lesson/lesson-focus.png'),
          },
          {
            uid: '1.5',
            active: false,
            completed: false,
            name: 'Tag Students',
            desc: null,
            image: require('../../assets/images/lesson/lesson-focus.png'),
          },
          {
            uid: '1.6',
            active: false,
            completed: false,
            name: 'Exam Name',
            desc: null,
            image: require('../../assets/images/lesson/lesson-focus.png'),
          },
          {
            uid: '1.7',
            active: false,
            completed: false,
            name: 'Exam Type',
            desc: null,
            image: require('../../assets/images/lesson/lesson-focus.png'),
          },
          {
            uid: '1.8',
            active: false,
            completed: false,
            name: 'Weight',
            desc: null,
            image: require('../../assets/images/lesson/lesson-focus.png'),
          },
          {
            uid: '1.9',
            active: false,
            completed: false,
            name: 'Exam Date',
            desc: null,
            image: require('../../assets/images/lesson/lesson-focus.png'),
          },
          {
            uid: '1.10',
            active: false,
            completed: false,
            name: 'Open Time',
            desc: null,
            image: require('../../assets/images/lesson/lesson-focus.png'),
          },
          {
            uid: '1.11',
            active: false,
            completed: false,
            name: 'Close Time',
            desc: null,
            image: require('../../assets/images/lesson/lesson-focus.png'),
          },
          {
            uid: '1.12',
            active: false,
            completed: false,
            name: 'Exam Note',
            desc: null,
            image: require('../../assets/images/lesson/lesson-focus.png'),
          },
          {
            uid: '1.13',
            active: false,
            completed: false,
            name: 'Shuffle Question',
            desc: null,
            image: require('../../assets/images/lesson/lesson-focus.png'),
          },
        ],
      },
    ],

    questionsImport: {
      modal: {
        show: false
      },
      examsquest_ids: [],
      questionsForImport: [],
      limit: 12,
      totalRecords: 0,
      working: false
    }

  };

  constructor(props) {
    super(props);
    this.examsquest_file1 = React.createRef();
    this.examsquest_file2 = React.createRef();
    this.examsquest_file3 = React.createRef();
    this.examsquest_file4 = React.createRef();
    this.examsquest_file5 = React.createRef();
    this.tagStudent = React.createRef();
  }

  componentDidMount() {
    const { homework } = this.props;
    if (homework) {
      this.setState({
        formSections: [
          {
            uid: '1',
            active: true,
            completed: false,
            name: 'Homework',
            desc: null,
            data: [
              {
                uid: '1.2',
                active: false,
                completed: false,
                name: 'Terms',
                desc: null,
                image: require('../../assets/images/lesson/lesson-focus.png'),
              },
              {
                uid: '1.3',
                active: false,
                completed: false,
                name: 'Course',
                desc: null,
                image: require('../../assets/images/lesson/lesson-focus.png'),
              },
              {
                uid: '1.4',
                active: false,
                completed: false,
                name: 'Lessons',
                desc: null,
                image: require('../../assets/images/lesson/lesson-focus.png'),
              },
              {
                uid: '1.5',
                active: false,
                completed: false,
                name: 'Tag Students',
                desc: null,
                image: require('../../assets/images/lesson/lesson-focus.png'),
              },
              {
                uid: '1.6',
                active: false,
                completed: false,
                name: 'Homework Name',
                desc: null,
                image: require('../../assets/images/lesson/lesson-focus.png'),
              },
              {
                uid: '1.7',
                active: false,
                completed: false,
                name: 'Homework Type',
                desc: null,
                image: require('../../assets/images/lesson/lesson-focus.png'),
              },
              {
                uid: '1.8',
                active: false,
                completed: false,
                name: 'Weight',
                desc: null,
                image: require('../../assets/images/lesson/lesson-focus.png'),
              },
              {
                uid: '1.9',
                active: false,
                completed: false,
                name: 'Start Date',
                desc: null,
                image: require('../../assets/images/lesson/lesson-focus.png'),
              },
              {
                uid: '1.10',
                active: false,
                completed: false,
                name: 'End Date',
                desc: null,
                image: require('../../assets/images/lesson/lesson-focus.png'),
              },
              {
                uid: '1.11',
                active: false,
                completed: false,
                name: 'Open Time',
                desc: null,
                image: require('../../assets/images/lesson/lesson-focus.png'),
              },
              {
                uid: '1.12',
                active: false,
                completed: false,
                name: 'Close Time',
                desc: null,
                image: require('../../assets/images/lesson/lesson-focus.png'),
              },
              {
                uid: '1.13',
                active: false,
                completed: false,
                name: 'Homework Note',
                desc: null,
                image: require('../../assets/images/lesson/lesson-focus.png'),
              },
              {
                uid: '1.14',
                active: false,
                completed: false,
                name: 'Shuffle Question',
                desc: null,
                image: require('../../assets/images/lesson/lesson-focus.png'),
              },
            ],
          },
        ]
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.showCreateUpdateExamCanvas != this.state.showCreateUpdateExamCanvas && this.state.showCreateUpdateExamCanvas) {
      // if (this.state.subjects.length <= 0) {
      // this.getSubjects();
      // }
      // if (this.state.terms.length <= 0) {
      // this.getTerms();
      // }
      // this.getExamTypes();
      // if (this.state.difficultyTypes.length <= 0) {
      // this.getDifficultyTypes();
      // }
      // if (this.state.questionTypes.length <= 0) {
      // this.getQuestionTypes();
      // }
    }
  }

  handleShowExamPreview = async (
    editingData = {}
  ) => {
    this.setState(
      {
        isEditing: false,
        exams_id: 0,
        selectedClass: null,
        selectedTerm: null,
        selectedSubject: null,
        selectedLessons: null,
        selectedChildren: null,
        selectedExamType: null,
        examName: '',
        weight: '',
        examNote: '',
        examDate: '',
        openTime: '',
        closeTime: '',
        shuffleQs: 0,
        questions: [],
        disableLessons: true,
        showCreateUpdateExamCanvas: true,
      }, async () => {
        const { authData, homework } = this.props;
        const { auth_key } = authData.loginData;
        const { employee_id } = authData.userData;
        const { center_id } = authData.centerData;

        try {
          const res = await GET('exams/edit-exam', {
            params: {
              employee_id,
              exams_id: editingData.exams_id,
            },
          });

          if (res.status == 200) {
            const {
              center_id,
              class_id,
              class_theme,
              acs_code,
              acs_id,
              acs_name,
              asl_ids,
              asl_data,
              close_time: closeTime,
              due_date,
              employee_id,
              exams_coefficient: weight,
              exams_date: examDate,
              end_date: endDate,
              exams_grade,
              exams_id,
              exams_name: examName,
              exams_notes: examNote,
              exams_post_date,
              exams_status,
              examsquest,
              examstype_id,
              examstype_name,
              is_exampaper,
              is_homework,
              open_time: openTime,
              term_id,
              term_name,
              trophy_id,
              exams_child_ids_data,
              shuffle_questions
            } = res.data;

            // Lessons
            const selectedLessons = asl_data.map(
              (item) => {
                return {
                  value: item.asl_id,
                  label: item.asl_name,
                };
              }
            );

            // Tagged students
            const taggedStudents = exams_child_ids_data.map((item) => {
              return {
                value: item.child_id,
                label: item.child_name,
              };
            });

            // this.getSubjects();

            const examsquest2 = examsquest.map((item) => {

              item['uuid'] = chance.guid();

              item['eqtype_id'] = {
                value: item.eqtype_id,
                label: item.eqtype_name
              };

              item['examsquest_difficulty'] = {
                value: item.eqdifficulty_value,
                label: item.eqdifficulty_name
              };

              const examsquestoptions2 = item.examsquestoptions.map((item2) => {
                item2['uuid'] = chance.guid();
                return item2;
              });


              item['examsquestoptions'] = examsquestoptions2;


              return item;
            });

            this.setState(
              {
                exams_id,
                selectedClass: {
                  label: class_theme,
                  value: class_id,
                },
                selectedTerm: {
                  label: term_name,
                  value: term_id,
                },
                selectedSubject: {
                  label: acs_name,
                  value: acs_id,
                },
                selectedExamType: {
                  label: examstype_name,
                  value: examstype_id,
                },
                examName,
                weight,
                examNote,
                examDate,
                endDate,
                openTime,
                closeTime,
                shuffleQs: shuffle_questions,
                resubmitAllowed: 0,
                showCorrectAnswer: 0,
                retakenExam: 0,
                is_exampaper: 0,
                is_homework: homework ? 1 : 0,
                questions: examsquest2,
                disableLessons: false,
                selectedChildren: taggedStudents,
                selectedLessons: selectedLessons,
              },
              () => {
                // this.getChildren();
                // this.getLessons();
                this.checkCompletedFormSections();
                // this.getImportQuestions();
              }
            );
          }
        } catch (err) {
          console.log('err', err)
          toast.error(
            t('Something went wrong while fetching exam!')
          );
        }
      });
  }

  handleCloseExamPreview = () => {
    this.setState({ showCreateUpdateExamCanvas: false });
  };

  resetExamForm = () => {
    this.setState(
      {
        isEditing: false,
        exams_id: 0,
        selectedClass: null,
        selectedTerm: null,
        selectedSubject: null,
        selectedLessons: null,
        selectedChildren: null,
        selectedExamType: null,
        examName: '',
        weight: '',
        examNote: '',
        examDate: '',
        openTime: '',
        closeTime: '',
        shuffleQs: 0,
        questions: [],
        disableLessons: true,
      },
      () => {
        this.checkCompletedFormSections();
      }
    );
  };

  // getTerms = async () => {
  //   const { authData } = this.props;
  //   const { auth_key } = authData.loginData;
  //   const { center_id } = authData.centerData;

  //   try {
  //     const res = await GET('exams/get-terms');
  //     const terms = [];

  //     res.data.map((item) => {
  //       terms.push({
  //         value: item.term_id,
  //         label: item.term_name,
  //       });
  //     });

  //     this.setState({ terms });
  //   } catch (err) {
  //     console.log('err', err)
  //     toast.error(t('Something went wrong while fetching terms!'));

  //   }
  // };

  // getExamTypes = async () => {
  //   const res = await GET('exams/get-exam-types', {
  //     params: {
  //       examstypegrp_type: this.props.examstypegrp_type
  //     }
  //   });
  //   if (res) {
  //     const examTypes = [];

  //     res.data.map((item) => {
  //       examTypes.push({
  //         value: item.examstype_id,
  //         label: item.examstype_name,
  //       });
  //     });

  //     this.setState({ examTypes });
  //   }
  // };

  // getDifficultyTypes = async () => {
  //   const { authData } = this.props;
  //   const { auth_key } = authData.loginData;
  //   const { center_id } = authData.centerData;

  //   try {
  //     const res = await GET('exams/get-question-difficulty');

  //     const difficultyTypes = [];

  //     res.data.map((item) => {
  //       difficultyTypes.push({
  //         value: item.eqdifficulty_id,
  //         label: item.eqdifficulty_name,
  //       });
  //     });

  //     this.setState({ difficultyTypes });
  //   } catch (err) {
  //     console.log('err', err)
  //     toast.error(
  //       t('Something went wrong while fetching question difficulties!')
  //     );

  //   }
  // };

  // getSubjects = async () => {
  //   const { selectedClass } = this.props;
  //   const { academic_classmain_subject } = selectedClass;

  //   this.setState({ selectedSubject: null });

  //   const subjects = [];

  //   academic_classmain_subject.map((item) => {
  //     subjects.push({
  //       value: item.acs_id,
  //       label: item.acs_name,
  //     });
  //   });

  //   this.setState({ subjects });

  // };

  // getChildren = async () => {
  //   const { authData, selectedClass } = this.props;
  //   const { center_id } = authData.centerData;
  //   const { auth_key } = authData.loginData;
  //   const { selectedTerm } = this.state;

  //   this.setState({ disableChildren: true, selectedChildren: [] });

  //   if (selectedClass && selectedTerm) {

  //     const request_params = {
  //       class_id: selectedClass.class.class_id,
  //       term_id: selectedTerm.value,
  //     };

  //     try {
  //       const res = await GET('exams/get-class-children',
  //         {
  //           params: request_params,
  //         }
  //       );

  //       const children = [];

  //       res.data.map((item) => {
  //         children.push({
  //           value: item.child_id,
  //           label: item.child_name,
  //         });
  //       });

  //       this.setState({ children, disableChildren: false });
  //     } catch (err) {
  //       console.log('err', err)
  //       toast.error(t('Something went wrong while fetching students!'));

  //     }
  //   }
  // };

  // getLessons = async () => {
  //   const { authData, selectedClass } = this.props;
  //   const { auth_key } = authData.loginData;
  //   const { center_id } = authData.centerData;
  //   const { selectedSubject } = this.state;

  //   this.setState({ disableLessons: true, selectedLessons: null });

  //   const fData = new FormData();
  //   fData.append('center_id', center_id);
  //   fData.append('class_id', selectedClass.class.class_id);
  //   fData.append('acs_id', selectedSubject.value);
  //   fData.append('limit', 1000);
  //   fData.append('offset', 0);

  //   try {
  //     const res = await axios.post(apiURL + 'academic-subject-lessons/get', fData, {
  //       params: {
  //         auth_key: auth_key,
  //         appname: process.env.REACT_APP_NAME,
  //       },
  //     });
  //     if (res.status == 200) {
  //       const lessons = [];
  //       res.data.data.map((item) => {
  //         lessons.push({
  //           value: item.asl_id,
  //           label: item.asl_name,
  //         });
  //       });

  //       this.setState({ lessons, disableLessons: false }, () =>
  //         this.checkCompletedFormSections()
  //       );
  //     }
  //   } catch (err) {
  //     console.log('err', err)
  //     toast.error(t('Something went wrong while fetching lessons!'));

  //   }

  // };

  // getImportQuestions = async (limit = this.state.questionsImport.limit, offset = 0) => {

  //   this.setState({
  //     questionsImport: {
  //       ...this.state.questionsImport,
  //       questionsForImport: [],
  //     }
  //   });

  //   const { authData, selectedClass } = this.props;
  //   const { auth_key } = authData.loginData;
  //   const { center_id } = authData.centerData;
  //   const { selectedSubject } = this.state;

  //   const request_params = {
  //     limit,
  //     offset,
  //     search: '',
  //     acs_id: selectedSubject.value,
  //   };

  //   try {
  //     const res = await GET('exams/get-import-questions', {
  //       params: request_params,
  //     });

  //     if (res.status == 200) {
  //       this.setState({
  //         questionsImport: {
  //           ...this.state.questionsImport,
  //           questionsForImport: res.data.data,
  //           totalRecords: res.data.total_count
  //         }
  //       });
  //     }

  //   } catch (err) {
  //     console.log('err', err)
  //     toast.error(t('Something went wrong!'));

  //   }

  // }

  handleImportQuestionModal = (status = true) => {

    if (this.state.selectedSubject == null && status) {
      toast.error(t('Please select subject first!'));
      return;
    }

    this.setState({
      questionsImport: {
        ...this.state.questionsImport,
        examsquest_ids: [],
        modal: {
          show: status
        }
      }
    });
  }

  checkUncheckQuestion = (qid) => {
    const findIndex = this.state.questionsImport.examsquest_ids.findIndex((q) => q == qid);

    const qids = this.state.questionsImport.examsquest_ids;

    if (findIndex == -1) {
      qids.push(qid);
    } else {
      qids.splice(findIndex, 1);
    }

    this.setState({
      questionsImport: {
        ...this.state.questionsImport,
        examsquest_ids: qids
      }
    });
  }

  insertImportQuestions = async () => {

    this.setState({
      questionsImport: {
        ...this.state.questionsImport,
        working: true
      }
    });

    const { authData } = this.props;
    const { auth_key } = authData.loginData;
    const { center_id } = authData.centerData;
    const { exams_id, questionsImport } = this.state;
    const { examsquest_ids } = questionsImport;

    const fData = new FormData();
    fData.append('center_id', center_id);
    fData.append('exams_id', exams_id);
    fData.append('examsquest_ids', JSON.stringify(examsquest_ids));

    try {
      const res = await axios.post(apiURL + 'exams/insert-import-questions', fData, {
        params: {
          auth_key: auth_key,
          appname: process.env.REACT_APP_NAME,
        },
      });

      if (res.data.type) {

        toast.success(t(res.data.message));

        const examsquest2 = res.data.data.map((item) => {

          item['uuid'] = chance.guid();

          item['eqtype_id'] = {
            value: item.eqtype_id,
            label: item.eqtype_name
          };

          item['examsquest_difficulty'] = {
            value: item.eqdifficulty_value,
            label: item.eqdifficulty_name
          };

          const examsquestoptions2 = item.examsquestoptions.map((item2) => {
            item2['uuid'] = chance.guid();
            return item2;
          });


          item['examsquestoptions'] = examsquestoptions2;


          return item;
        });


        this.setState({
          questions: [
            ...this.state.questions,
            ...examsquest2
          ],
          questionsImport: {
            ...this.state.questionsImport,
            working: false,
            modal: {
              show: false
            }
          }
        });
      } else {
        toast.error(t(res.data.message));
        this.setState({
          questionsImport: {
            ...this.state.questionsImport,
            working: false
          }
        });
      }


    } catch (err) {
      console.log('err', err)
      toast.error(t('Something went wrong!'));
      this.setState({
        questionsImport: {
          ...this.state.questionsImport,
          working: false
        }
      });

    }

  }

  checkCompletedFormSections = () => {
    let formSections = this.state.formSections;
    formSections = formSections.map((v, i) => {
      let data = v.data;
      let sectionComplete = true;
      data = data.map((vv, ii) => {
        if (vv.uid == '1.1') {
          let selectedClass = this.state.selectedClass;
          let subSectionComplete = true;
          if (selectedClass) {
            subSectionComplete = true;
          } else {
            subSectionComplete = false;
            sectionComplete = false;
          }
          return { ...vv, completed: subSectionComplete };
        } else if (vv.uid == '1.2') {
          let selectedTerm = this.state.selectedTerm;
          let subSectionComplete = true;
          if (selectedTerm) {
            subSectionComplete = true;
          } else {
            subSectionComplete = false;
            sectionComplete = false;
          }
          return { ...vv, completed: subSectionComplete };
        } else if (vv.uid == '1.3') {
          let selectedSubject = this.state.selectedSubject;
          let subSectionComplete = true;
          if (selectedSubject) {
            subSectionComplete = true;
          } else {
            subSectionComplete = false;
            sectionComplete = false;
          }
          return { ...vv, completed: subSectionComplete };
        } else if (vv.uid == '1.4') {
          let selectedLessons = this.state.selectedLessons;
          let subSectionComplete = true;
          if (selectedLessons) {
            subSectionComplete = true;
          } else {
            subSectionComplete = false;
            sectionComplete = false;
          }
          return { ...vv, completed: subSectionComplete };
        } else if (vv.uid == '1.5') {
          let selectedChildren = this.state.selectedChildren;
          let subSectionComplete = true;
          if (selectedChildren && selectedChildren.length > 0) {
            subSectionComplete = true;
          } else {
            subSectionComplete = false;
            sectionComplete = false;
          }
          return { ...vv, completed: subSectionComplete };
        } else if (vv.uid == '1.6') {
          let examName = this.state.examName;
          let subSectionComplete = true;
          if (examName) {
            subSectionComplete = true;
          } else {
            subSectionComplete = false;
            sectionComplete = false;
          }
          return { ...vv, completed: subSectionComplete };
        } else if (vv.uid == '1.7') {
          let selectedExamType = this.state.selectedExamType;
          let subSectionComplete = true;
          if (selectedExamType) {
            subSectionComplete = true;
          } else {
            subSectionComplete = false;
            sectionComplete = false;
          }
          return { ...vv, completed: subSectionComplete };
        } else if (vv.uid == '1.8') {
          let weight = this.state.weight;
          let subSectionComplete = true;
          if (weight) {
            subSectionComplete = true;
          } else {
            subSectionComplete = false;
            sectionComplete = false;
          }
          return { ...vv, completed: subSectionComplete };
        } else if (vv.uid == '1.9') {
          let examDate = this.state.examDate;
          let subSectionComplete = true;
          if (examDate) {
            subSectionComplete = true;
          } else {
            subSectionComplete = false;
            sectionComplete = false;
          }
          return { ...vv, completed: subSectionComplete };
        } else if (vv.uid == '1.10') {
          let openTime = this.state.openTime;
          let subSectionComplete = true;
          if (openTime) {
            subSectionComplete = true;
          } else {
            subSectionComplete = false;
            sectionComplete = false;
          }
          return { ...vv, completed: subSectionComplete };
        } else if (vv.uid == '1.11') {
          let closeTime = this.state.closeTime;
          let subSectionComplete = true;
          if (closeTime) {
            subSectionComplete = true;
          } else {
            subSectionComplete = false;
            sectionComplete = false;
          }
          return { ...vv, completed: subSectionComplete };
        } else if (vv.uid == '1.12') {
          let examNote = this.state.examNote;
          let subSectionComplete = true;
          if (examNote) {
            subSectionComplete = true;
          } else {
            subSectionComplete = false;
            sectionComplete = false;
          }
          return { ...vv, completed: subSectionComplete };
        } else if (vv.uid == '1.13') {
          let shuffleQs = this.state.shuffleQs;
          let subSectionComplete = true;
          if (shuffleQs == 1) {
            subSectionComplete = true;
          } else {
            subSectionComplete = false;
            sectionComplete = false;
          }
          return { ...vv, completed: subSectionComplete };
        } else {
          sectionComplete = false;
          return { ...vv };
        }
      });
      return { ...v, data, completed: sectionComplete };
    });
    this.setState({ formSections: formSections });
  };

  changeToFromSection = (parent, child) => {
    this.setState(
      {
        formSectionActive: { parent: parent, child: child },
      },
      () => {
        setTimeout(() => {
          const element = document.getElementById(
            'form_section_' + child
          );
          if (element) {
            // 👇 Will scroll smoothly to the top of the next section
            element.scrollIntoView({ behavior: 'smooth' });
          }
        }, 300);
      }
    );
  };

  handleSelectField = (field_name) => (e) => {
    this.setState(
      {
        [field_name]: e,
      },
      () => {
        this.checkCompletedFormSections();
      }
    );

    if (field_name == 'eqtype_id') {
      this.setState({ examsquestoptions: [] }, () => {
        this.checkCompletedFormSections();
      });
    }
  };

  previewFile = (e) => {
    const reader = new FileReader();

    const selectedFile = e.target.files[0];

    if (selectedFile) {
      reader.readAsDataURL(selectedFile);
    }

    if (
      !selectedFile.type.includes('image') &&
      !selectedFile.type.includes('pdf')
    ) {
      toast.error(t('Only image or PDF file is allowed!'));
      return;
    }

    reader.onload = (readerEvent) => {
      if (selectedFile.type.includes('image')) {
        this.setState({
          [e.target.name + '_prev']: readerEvent.target.result,
          [e.target.name]: e.target.files[0],
        });
      } else if (selectedFile.type.includes('pdf')) {
        this.setState({
          [e.target.name + '_prev']: 'pdf',
          [e.target.name]: e.target.files[0],
        });
      }
    };
  };

  handleSaveForm = async () => {
    const { auth_key } = this.props.authData.loginData;
    const { selectedClass, homework } = this.props;
    const { employee_id } = this.props.authData.userData;
    const { center_id } = this.props.authData.centerData;
    const {
      selectedTerm,
      selectedSubject,
      selectedExamType,
      selectedLessons,
      selectedChildren,
      examName,
      examNote,
      weight,
      examDate,
      endDate,
      shuffleQs,
      questions,
      openTime,
      closeTime,
      exams_id,
    } = this.state;

    // Validation
    if (!selectedTerm) {
      toast.error(t('Term is required!'));
      return;
    }
    if (!selectedExamType) {
      toast.error(t('Exam Type is required!'));
      return;
    }
    if (!selectedLessons) {
      toast.error(t('Lessons are required!'));
      return;
    }
    if (!selectedChildren) {
      toast.error(t('Tag Students are required!'));

      return;
    }
    if (examName == '') {
      toast.error(t('Exam name is required!'));
      return;
    }
    if (homework) {
      if (examDate == '') {
        toast.error(t('Start date is required!'));
        return;
      }
      if (endDate == '') {
        toast.error(t('End date is required!'));
        return;
      }
    } else {
      if (examDate == '') {
        toast.error(t('Exam date is required!'));
        return;
      }
    }
    if (openTime == '') {
      toast.error(t('Open time is required!'));
      return;
    }
    if (closeTime == '') {
      toast.error(t('Close time is required!'));
      return;
    }

    this.setState({ working: true });

    const classmaterialsub_id = selectedLessons.map((item) => {
      return item.value;
    });
    const exams_child_ids = selectedChildren.map((item) => {
      return item.value;
    });

    const fData = new FormData();
    fData.append(
      'classmain_id',
      this.props.selectedClass.class.classmain_id
    );
    if (exams_id != 0) {
      fData.append('exams_id', exams_id);
      fData.append('Exams[exams_id]', exams_id);
    }
    fData.append('Exams[center_id]', center_id);
    fData.append('Exams[employee_id]', employee_id);
    fData.append('Exams[class_id]', selectedClass.class.class_id);
    fData.append('Exams[term_id]', selectedTerm.value);
    fData.append('Exams[acs_id]', selectedSubject.value);
    fData.append('Exams[examstype_id]', selectedExamType.value);
    fData.append(
      'Exams[asl_ids]',
      JSON.stringify(classmaterialsub_id)
    );
    fData.append('Exams[exams_name]', examName);
    fData.append('Exams[exams_notes]', examNote);
    fData.append('Exams[exams_coefficient]', weight);
    fData.append('Exams[exams_date]', examDate);
    fData.append('Exams[end_date]', endDate);
    fData.append('Exams[exams_child_ids]', JSON.stringify(exams_child_ids));
    fData.append('Exams[open_time]', openTime);
    fData.append('Exams[close_time]', closeTime);
    fData.append('Exams[is_homework]', homework ? 1 : 0);
    fData.append('Exams[shuffle_questions]', shuffleQs);
    fData.append('Exams[resubmit_allowed]', 0);
    fData.append('Exams[show_correct_answer]', 0);
    fData.append('Exams[retake_exam]', 0);
    fData.append('Exams[is_exampaper]', 0);
    fData.append('Exams[exams_status]', 0);

    // Questions
    if (questions.length > 0) {
      questions.map((quest, quest_i) => {
        fData.append(
          'Examsquest[' + quest.uuid + '][uuid]',
          quest.uuid
        );
        fData.append(
          'Examsquest[' + quest.uuid + '][examsquest_id]',
          quest.examsquest_id
        );
        fData.append(
          'Examsquest[' + quest.uuid + '][examsquest_desc1]',
          quest.examsquest_desc1
        );
        fData.append(
          'Examsquest[' + quest.uuid + '][examsquest_desc2]',
          quest.examsquest_desc2
        );
        fData.append(
          'Examsquest[' + quest.uuid + '][examsquest_desc3]',
          quest.examsquest_desc3
        );
        fData.append(
          'Examsquest[' + quest.uuid + '][exams_notes]',
          quest.exams_notes
        );
        fData.append(
          'Examsquest[' + quest.uuid + '][examsquest_grade]',
          quest.examsquest_grade
        );
        fData.append(
          'Examsquest[' + quest.uuid + '][examsquest_order]',
          quest.examsquest_order
        );
        fData.append(
          'Examsquest[' + quest.uuid + '][examsquest_difficulty]',
          quest.examsquest_difficulty !== null
            ? quest.examsquest_difficulty.value
            : null
        );
        fData.append(
          'Examsquest[' + quest.uuid + '][examsquest_duration]',
          quest.examsquest_duration
        );
        fData.append(
          'Examsquest[' + quest.uuid + '][eqtype_id]',
          quest.eqtype_id !== null ? quest.eqtype_id.value : null
        );
        fData.append(
          'Examsquest[' + quest.uuid + '][is_mandatory]',
          quest.is_mandatory
        );
        fData.append(
          'Examsquest[' + quest.uuid + '][shuffle_allowed]',
          quest.shuffle_allowed
        );
        fData.append(
          'examsquest_file1[' + quest.uuid + ']',
          quest.examsquest_file1
        );
        fData.append(
          'examsquest_file2[' + quest.uuid + ']',
          quest.examsquest_file2
        );
        fData.append(
          'examsquest_file3[' + quest.uuid + ']',
          quest.examsquest_file3
        );
        fData.append(
          'examsquest_file4[' + quest.uuid + ']',
          quest.examsquest_file4
        );
        fData.append(
          'examsquest_file5[' + quest.uuid + ']',
          quest.examsquest_file5
        );

        // Options
        if (quest.examsquestoptions.length > 0) {
          quest.examsquestoptions.map((opt, opt_i) => {
            fData.append(
              'Examsquest[' +
              quest.uuid +
              '][examsquestoptions][' +
              opt.uuid +
              '][uuid]',
              opt.uuid
            );
            fData.append(
              'Examsquest[' +
              quest.uuid +
              '][examsquestoptions][' +
              opt.uuid +
              '][examsquestoptions_id]',
              opt.examsquestoptions_id
            );
            fData.append(
              'Examsquest[' +
              quest.uuid +
              '][examsquestoptions][' +
              opt.uuid +
              '][option_text]',
              opt.option_text
            );
            fData.append(
              'Examsquest[' +
              quest.uuid +
              '][examsquestoptions][' +
              opt.uuid +
              '][option_value]',
              opt.option_value
            );
            fData.append(
              'Examsquest[' +
              quest.uuid +
              '][examsquestoptions][' +
              opt.uuid +
              '][is_checked]',
              opt.is_checked
            );
            fData.append(
              'option_text_picture[' + opt.uuid + ']',
              opt.option_text_picture
            );
            fData.append(
              'option_value_picture[' + opt.uuid + ']',
              opt.option_value_picture
            );
          });
        }
      });
    }

    try {
      const res = await axios.post(apiURL + 'exams/save-exam', fData, {
        params: {
          auth_key: auth_key,
          appname: process.env.REACT_APP_NAME,
        },
      });


      if (res.data.type) {



        this.setState({ working: false, showCreateUpdateExamCanvas: false }, () => {
          this.props.callBack();
        });
      } else {
        toast.error(t(res.data.message));
        this.setState({ working: false });
      }


    } catch (err) {
      console.log('err', err)
      toast.error(t('Something went wrong while saving exam!'));
      this.setState({ working: false });

    }
  };

  handleClassChange = () => {
    // this.getSubjects();
    // this.setState({ selectedClass: obj }, () => {
    // this.getChildren();
    this.checkCompletedFormSections();
    // });
  };

  handleTermChange = (obj) => {
    this.setState({ selectedTerm: obj }, () => {
      // this.getChildren();
      this.checkCompletedFormSections();
    });
  };

  handleSubjectChange = (obj) => {
    this.setState({ selectedSubject: obj }, () => {
      // this.getLessons();
      this.checkCompletedFormSections();
      // this.getImportQuestions();
    });
  };

  handleChangeTextField = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => {
        this.checkCompletedFormSections();
      }
    );
  };

  handleShowCreateUpdateQuestionModal = () => {
    this.resetQuestionForm();
    this.setState({ showCreateUpdateQuestionModal: true }, () => {
      const modal = document.querySelector('.modal');
      modal.removeAttribute('tabindex');

    });
  };
  handleCloseCreateUpdateQuestionModal = () => {
    this.setState({ showCreateUpdateQuestionModal: false });
  };

  // getQuestionTypes = async () => {
  //   const { authData } = this.props;
  //   const { auth_key } = authData.loginData;
  //   const { center_id } = authData.centerData;

  //   try {
  //     const res = await GET('exams/get-question-types');

  //     const questionTypes = [];

  //     res.data.map((item) => {
  //       questionTypes.push({
  //         value: item.eqtype_id,
  //         label: item.eqtype_name,
  //       });
  //     });

  //     this.setState({ questionTypes });
  //   } catch (err) {
  //     console.log('err', err)
  //     toast.error(
  //       t('Something went wrong while fetching question types!')
  //     );

  //   }
  // };

  resetQuestionForm = () => {
    this.setState({
      isEditingQuestion: false,
      editingQuestionId: undefined,
      uuid: '',
      examsquest_id: 0,
      examsquest_desc1: '',
      examsquest_desc2: '',
      examsquest_desc3: '',
      exam_notes: '',
      sub_obj: '',
      examsquest_grade: '',
      examsquest_order: 0,
      examsquest_difficulty: null,
      examsquest_duration: '',
      eqtype_id: null,
      classmaterialsub_id: '',
      is_mandatory: 0,
      shuffle_allowed: 0,
      examsquest_file1: '',
      examsquest_file2: '',
      examsquest_file3: '',
      examsquest_file4: '',
      examsquest_file5: '',
      examsquest_file1_prev: '',
      examsquest_file2_prev: '',
      examsquest_file3_prev: '',
      examsquest_file4_prev: '',
      examsquest_file5_prev: '',
      examsquest_audio: '',
      examsquestoptions: [],
    });
  };

  handleChangeCheckField = (e) => {
    this.setState(
      {
        [e.target.name]: this.state[e.target.name] == 0 ? 1 : 0,
      },
      () => {
        this.checkCompletedFormSections();
      }
    );
  };

  handleOptionCheckChange = (index, state_name, select_single = false) => {
    const { examsquestoptions } = this.state;

    if (select_single) {
      for (var i = 0; i < examsquestoptions.length; i++) {
        examsquestoptions[i][state_name] = 0;
      }
    }

    examsquestoptions[index][state_name] =
      examsquestoptions[index][state_name] == 0 ? 1 : 0;
    this.setState({ examsquestoptions });
  };

  handleOptionTagsChange = (index, name) => (e) => {
    const { examsquestoptions } = this.state;
    examsquestoptions[index][name] = e;
    this.setState({ examsquestoptions });
  };

  handleOptionFileChange = (index) => (e) => {
    const { examsquestoptions } = this.state;
    examsquestoptions[index][e.target.name] = e.target.files[0];
    this.setState({ examsquestoptions });
  };

  handleOptionTextChange = (index) => (e) => {
    const { examsquestoptions } = this.state;
    examsquestoptions[index][e.target.name] = e.target.value;
    this.setState({ examsquestoptions });
  };

  addUpdateQuestion = () => {
    const {
      isEditingQuestion,
      editingQuestionId,
      uuid,
      examsquest_id,
      examsquest_desc1,
      examsquest_desc2,
      examsquest_desc3,
      exams_notes,
      sub_obj,
      examsquest_grade,
      examsquest_order,
      examsquest_difficulty,
      examsquest_duration,
      eqtype_id,
      classmaterialsub_id,
      is_mandatory,
      shuffle_allowed,
      examsquest_file1,
      examsquest_file2,
      examsquest_file3,
      examsquest_file4,
      examsquest_file5,
      examsquest_file1_prev,
      examsquest_file2_prev,
      examsquest_file3_prev,
      examsquest_file4_prev,
      examsquest_file5_prev,
      examsquest_audio,
      examsquestoptions,
      questions,
    } = this.state;

    // Question difficulty is required
    if (examsquest_difficulty == null) {
      toast.error(t('Question difficulty is required!'));
      return;
    }

    // Question type is required
    if (eqtype_id == null) {
      toast.error(t('Question type is required!'));
      return;
    }

    // Don't let user add question
    // if question type is true/false
    // and option are less than 2
    if (eqtype_id.value == 3 && examsquestoptions.length < 2) {
      toast.error(t('Please add two options for true/false type!'));
      return;
    }

    if (isEditingQuestion) {
      questions[editingQuestionId] = {
        uuid,
        examsquest_id,
        examsquest_desc1,
        examsquest_desc2,
        examsquest_desc3,
        exams_notes,
        sub_obj,
        examsquest_grade,
        examsquest_order,
        examsquest_difficulty,
        examsquest_duration,
        eqtype_id,
        classmaterialsub_id,
        is_mandatory,
        shuffle_allowed,
        examsquest_file1,
        examsquest_file2,
        examsquest_file3,
        examsquest_file4,
        examsquest_file5,
        examsquest_file1_prev,
        examsquest_file2_prev,
        examsquest_file3_prev,
        examsquest_file4_prev,
        examsquest_file5_prev,
        examsquest_audio,
        examsquestoptions,
      };
    } else {
      questions.push({
        uuid: chance.guid(),
        examsquest_id,
        examsquest_desc1,
        examsquest_desc2,
        examsquest_desc3,
        exams_notes,
        sub_obj,
        examsquest_grade,
        examsquest_order,
        examsquest_difficulty,
        examsquest_duration,
        eqtype_id,
        classmaterialsub_id,
        is_mandatory,
        shuffle_allowed,
        examsquest_file1,
        examsquest_file2,
        examsquest_file3,
        examsquest_file4,
        examsquest_file5,
        examsquest_file1_prev,
        examsquest_file2_prev,
        examsquest_file3_prev,
        examsquest_file4_prev,
        examsquest_file5_prev,
        examsquest_audio,
        examsquestoptions,
      });
    }

    this.setState({ questions });

    this.handleCloseCreateUpdateQuestionModal();
  };

  editQuestion = (id) => {
    const { questions } = this.state;

    this.handleShowCreateUpdateQuestionModal();

    this.setState({
      ...questions[id],
      isEditingQuestion: true,
      editingQuestionId: id,
    });
  };

  deleteQuestion = (id) => {
    const { questions } = this.state;
    questions.splice(id, 1);
    this.setState({ questions });
  };

  addOption = () => {
    const { examsquestoptions, eqtype_id } = this.state;

    // Don't let user add more than two options
    // if question type is true/false
    if (eqtype_id.value == 3 && examsquestoptions.length === 2) {
      toast.error(
        t('Only two options can be added for true/false type!')
      );
      return;
    }

    const option = {
      uuid: chance.guid(),
      examsquestoptions_id: 0,
      option_text: '',
      option_value: '',
      option_text_picture: '',
      option_value_picture: '',
      is_checked: 0,
    };

    examsquestoptions.push(option);

    this.setState(examsquestoptions);
  };

  deleteOption = (id) => {
    const { examsquestoptions } = this.state;
    examsquestoptions.splice(id, 1);
    this.setState({ examsquestoptions });
  };


  render() {

    const {
      showCreateUpdateExamCanvas,
      showCreateUpdateQuestionModal,
      working,
      isEditingQuestion,
      classes,
      terms,
      subjects,
      lessons,
      // examTypes,
      children,
      disableLessons,
      selectedClass,
      selectedTerm,
      selectedExamType,
      selectedSubject,
      selectedLessons,
      selectedChildren,
      examName,
      weight,
      examNote,
      examDate,
      endDate,
      openTime,
      closeTime,
      examsquest_desc1,
      examsquest_desc2,
      examsquest_desc3,
      exam_notes,
      examsquest_grade,
      examsquest_duration,
      difficultyTypes,
      questionTypes,
      examsquest_difficulty,
      eqtype_id,
      examsquest_file1,
      examsquest_file1_prev,
      examsquest_file2,
      examsquest_file2_prev,
      examsquest_file3,
      examsquest_file3_prev,
      examsquest_file4,
      examsquest_file4_prev,
      examsquest_file5,
      examsquest_file5_prev,
      is_mandatory,
      shuffle_allowed,
      questions,
      examsquestoptions,
      formSectionActive,
      formSections,
      isEditing,
    } = this.state;

    const { homework } = this.props;

    const { authData } = this.props;
    const { auth_key } = authData.loginData;

    return (
      <>
        <Offcanvas
          show={showCreateUpdateExamCanvas}
          onHide={this.handleCloseExamPreview}
          style={{ height: '100%' }}
          placement='bottom'>
          <Offcanvas.Header
            id='iedu-header'
            style={{
              marginTop: '1rem',
              marginRight: '1rem',
              marginLeft: '1rem',
              marginBottom: 0,
            }}>
            <div className='d-flex flex-row'>
              <span
                className='header-back-btn'
                onClick={() =>
                  this.handleCloseExamPreview()
                }
                style={{ marginRight: 10 }}>
                <FontAwesomeIcon
                  icon={faArrowAltCircleLeft}
                  color={brand_colors[this.props.defaultTheme.theme_id].color9}
                />
              </span>
              <Offcanvas.Title>
                {homework ? (
                  <>
                    {t('Homework Preview')}
                  </>) : (<>
                    {t('Exam Preview')}
                  </>
                )}
              </Offcanvas.Title>
            </div>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <div
              className={'d-flex flex-row grey-section'}
              style={{
                overflow: 'initial',
                backgroundImage:
                  'url(https://academic.educore.io/static/media/pegboard-bg.9b84e4936acef29bbcd9.png)',
                backgroundRepeat: 'repeat',
                backgroundPosition: '10px 12px',
                overflow: 'hidden',
              }}>
              <div
                style={{
                  backgroundColor: brand_colors[this.props.defaultTheme.theme_id].color8,
                  marginLeft: 10,
                  marginRight: 10,
                  borderRadius: '20px',
                  width: '250px',
                  height: '100%',
                  top: '5px',
                  position: 'sticky',
                }}>
                <div
                  style={{
                    paddingRight: '1.5rem',
                    paddingBottom: '1.5rem',
                    paddingTop: '1rem',
                  }}>
                  {formSections.map((v, i) => {
                    return (
                      <div key={i}>
                        <div
                          className='d-flex flex-row align-items-center'
                          style={{
                            cursor: 'pointer',
                            borderRadius: '20px',
                            paddingLeft: '5px',
                            paddingRight: '5px',
                            backgroundColor:
                              v.uid ==
                                formSectionActive.parent
                                ? brand_colors[this.props.defaultTheme.theme_id].color19
                                : brand_colors[this.props.defaultTheme.theme_id].color8,
                            zIndex: 1,
                            position: 'relative',
                          }}
                          onClick={() =>
                            this.changeToFromSection(
                              v.uid,
                              v.uid + '.1'
                            )
                          }>
                          <FontAwesomeIcon
                            icon={
                              v.completed
                                ? faCheckCircle
                                : faCircle
                            }
                            color={
                              v.uid ==
                                formSectionActive.parent
                                ? brand_colors[this.props.defaultTheme.theme_id].color20
                                : v.completed
                                  ? brand_colors[this.props.defaultTheme.theme_id].color4
                                  : brand_colors[this.props.defaultTheme.theme_id].color9
                            }
                            style={{
                              fontSize: 12,
                              marginRight: 5,
                              opacity:
                                v.uid ==
                                  formSectionActive.parent ||
                                  v.completed
                                  ? 1
                                  : 0.7,
                            }}
                          />
                          <span
                            style={{
                              fontSize: 18,
                              fontWeight: '600',
                              color:
                                v.uid ==
                                  formSectionActive.parent
                                  ? brand_colors[this.props.defaultTheme.theme_id].color20
                                  : brand_colors[this.props.defaultTheme.theme_id].color9,
                              opacity:
                                v.uid ==
                                  formSectionActive.parent
                                  ? 1
                                  : 0.7,
                            }}>
                            {v.name}
                          </span>
                        </div>

                        <div
                          style={{
                            paddingLeft: '1rem',
                            borderLeft:
                              'solid 1px color18',
                            marginLeft: '1.5rem',
                          }}>
                          {v.data.map((vv, ii) => {
                            return (
                              <div
                                key={ii}
                                className='d-flex flex-row align-items-center'
                                style={{
                                  marginBottom: 5,
                                  marginTop: 2,
                                  cursor: 'pointer',
                                }}
                                onClick={() =>
                                  this.changeToFromSection(
                                    v.uid,
                                    vv.uid
                                  )
                                }>
                                <FontAwesomeIcon
                                  icon={
                                    vv.completed
                                      ? faCheckCircle
                                      : faCircle
                                  }
                                  color={
                                    vv.completed
                                      ? brand_colors[this.props.defaultTheme.theme_id].color4
                                      : brand_colors[this.props.defaultTheme.theme_id].color18
                                  }
                                  style={{
                                    fontSize: 12,
                                    marginRight: 5,
                                  }}
                                />
                                <span
                                  style={{
                                    fontSize: 14,
                                    color: vv.completed
                                      ? brand_colors[this.props.defaultTheme.theme_id].color4
                                      : brand_colors[this.props.defaultTheme.theme_id].color18,
                                  }}>
                                  {vv.name}
                                </span>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <Col
                className='custom-styled-form hide-scrollbar'
                style={{
                  backgroundColor: brand_colors[this.props.defaultTheme.theme_id].color8,
                  marginLeft: 10,
                  marginRight: 10,
                  borderRadius: '20px',
                  padding: '1rem',
                  overflow: 'auto',
                  height: '75vh',
                }}>
                <Row>
                  <Col md={12}>
                    <Row>
                      <Col md={4}>
                        <div id='form_section_1.2'>
                          <div className='mb-3'>
                            <div><strong>{t('Term')}</strong></div>
                            <div>{selectedTerm && selectedTerm.label}</div>
                          </div>
                        </div>
                      </Col>
                      <Col md={4}>
                        <div id='form_section_1.3'>
                          <div className='mb-3'>
                            <div><strong>{t('Subjects')}</strong></div>
                            <div>{selectedSubject && selectedSubject.label}</div>
                          </div>
                        </div>
                      </Col>
                      <Col md={4}>
                        <div id='form_section_1.4'>
                          <div className='mb-3'>
                            <div>
                              <strong>{t('Lessons')}</strong>
                            </div>
                            <div>
                              {selectedLessons && selectedLessons.map((item, index) => <Badge variant='light' className='me-2'>{item.label}</Badge>)}
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col md={12}>
                        <div id='form_section_1.5'>
                          <div className='mb-3'>
                            <div>
                              <strong>{t('Tag Students')}</strong>
                            </div>
                            <div>
                              {selectedChildren && selectedChildren.map((item, index) => <Badge variant='light' className='me-2'>{item.label}</Badge>)}
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col md={6}>
                        <div id='form_section_1.6'>
                          <div className='mb-3'>
                            <div>
                              <strong>{homework ? t('Homework Name') : t('Exam Name')}</strong>
                            </div>
                            <div>{examName && examName}</div>
                          </div>
                        </div>
                      </Col>
                      <Col md={6}>
                        <div id='form_section_1.7'>
                          <div className='mb-3'>
                            <div>
                              <strong>{homework ? t('Homework Type') : t('Exam Type')}</strong>
                            </div>
                            <div>
                              {selectedExamType && selectedExamType.label}
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col md={6}>
                        <div id='form_section_1.8'>
                          <div className='mb-3'>
                            <div>
                              <strong>{t('Weight')}</strong>
                            </div>
                            <div>{weight && weight}</div>
                          </div>
                        </div>
                      </Col>
                      {homework ? (<>
                        <Col md={3}>
                          <div id='form_section_1.9'>
                            <div className='mb-3'>
                              <div>
                                <strong>{t('Start Date')}</strong>
                              </div>
                              <div>{examDate && examDate}</div>
                            </div>
                          </div>
                        </Col>
                        <Col md={3}>
                          <div id='form_section_1.9'>
                            <div className='mb-3'>
                              <div>
                                <strong>{t('End Date')}</strong>
                              </div>
                              <div>{endDate && endDate}</div>
                            </div>
                          </div>
                        </Col>
                      </>) : (<Col md={6}>
                        <div id='form_section_1.9'>
                          <div className='mb-3'>
                            <div>
                              <strong>{t('Exam Date')}</strong>
                            </div>
                            <div>{examDate && examDate}</div>
                          </div>
                        </div>
                      </Col>)}
                      <Col md={6}>
                        <div id='form_section_1.10'>
                          <div className='mb-3'>
                            <div>
                              <strong>{t('Open Time')}</strong>
                            </div>
                            <div>{openTime && openTime}</div>
                          </div>
                        </div>
                      </Col>
                      <Col md={6}>
                        <div id='form_section_1.11'>
                          <div className='mb-3'>
                            <div>
                              <strong>{t('Close Time')}</strong>
                            </div>
                            <div>{closeTime && closeTime}</div>
                          </div>
                        </div>
                      </Col>
                      <Col md={12}>
                        <div id='form_section_1.12'>
                          <div className='mb-3'>
                            <div>
                              <strong>{homework ? t('Homework Note') : t('Exam Note')}</strong>
                            </div>
                            <div>{examNote && examNote}</div>
                          </div>
                        </div>
                      </Col>
                      <Col md={12}>
                        <div id='form_section_1.13'>
                          <strong>{t('Shuffle Questions')}: {this.state.shuffleQs && this.state.shuffleQs == 0 ? t('No') : t('Yes')}</strong>
                        </div>
                      </Col>
                    </Row>
                    <hr />
                  </Col>
                  <Col md={12}>
                    <div className='d-flex justify-content-between align-items-center mb-4'>
                      <h6 className='mb-0'>
                        {homework ? t('Homework Questions') : t('Exam Questions')}
                      </h6>
                    </div>
                    <div className='questions'>
                      {questions.map((item, index) => (
                        <Card
                          key={index}
                          className='mb-3 q-card'>
                          <Card.Body>
                            <div className='q-card-inner'>
                              <div>
                                <Badge bg="primary">
                                  {
                                    item
                                      .eqtype_id
                                      .label
                                  }
                                </Badge>{' '}
                                <Badge bg="warning">
                                  {t(
                                    'Marks: '
                                  ) +
                                    item.examsquest_grade
                                  }
                                </Badge>
                                {' '}
                                <Badge bg="info">
                                  {t(
                                    'Duration: '
                                  ) +
                                    item.examsquest_duration + ' ' + t('Minutes')
                                  }
                                </Badge>
                                {' '}
                                <Badge bg="danger">
                                  {t(
                                    'Difficulty: '
                                  ) +
                                    item.eqdifficulty_name
                                  }
                                </Badge>
                              </div>
                              <h3>
                                <strong>
                                  <small>
                                    {item.examsquest_desc1.replace(
                                      /(<([^>]+)>)/gi,
                                      ' '
                                    )}
                                  </small>
                                </strong>
                              </h3>
                              <div className='quest-answers'>
                                {item.examsquestoptions.map((item2, index) => (
                                  <div key={index}>
                                    {item2.is_checked == 1 ? <div><Badge bg="success">{index + 1}: {item2.option_text}</Badge></div> : <div>
                                      <strong>{index + 1}: {item
                                        .eqtype_id.value == 8 ? item2.option_value : item2.option_text}</strong>
                                    </div>}
                                  </div>
                                ))}
                              </div>
                            </div>
                          </Card.Body>
                        </Card>
                      ))}
                    </div>
                  </Col>
                </Row>
              </Col>
            </div>
          </Offcanvas.Body>
        </Offcanvas>
      </>
    );
  }
}

export default PreviewExam;