import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import logo from '../../../assets/images/logo-thumb.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHomeUser, faCommentAlt, faDownload, faUser, faTimes, faPaperclip, faTrash, faTrashCan, faTimesCircle, faUserShield, faFilePdf, faCheck, faCheckDouble } from '@fortawesome/free-solid-svg-icons';
import { t } from '../../../helpers/translation_helper';
import { Spinner, Offcanvas, Card, Form, Button, Image } from 'react-bootstrap';
import { connect } from 'react-redux';
import axios from 'axios';
import ChatContactItem from './ChatContactItem';
import { toast } from 'react-toastify';
import { brand_colors } from '../../../helpers/brand_colors_helper';
import moment from 'moment';
import SweetAlert from 'react-bootstrap-sweetalert';
import { firebaseApp, onMessageListener } from '../../../services/firebase';
import { getMessaging, getToken, isSupported, onMessage } from 'firebase/messaging';
import { setToggleChat, setChatsCount } from '../../../redux/slices/chatsSlice';
import { sendCloudMessage } from '../../../helpers/cloud_message';
import { Scrollbar } from 'react-scrollbars-custom';
import { POST } from '../../../api';

// API URL
const apiURL = process.env.REACT_APP_API_URL;

// Portal URL
const portalURL = process.env.REACT_APP_PORTAL_URL;

let controller; // For axios abort controller

/**
 * Chats Partial Component
 */
class Chats extends Component {
  // State
  state = {
    show: false,
    chats: null,
    contacts: null,
    currentChat: null,
    message: '',
    attachment: null,
    msgSending: false,
    search: '',
    chat: null,
    chatID: 0,
    chatDetails: null,
    delID: 0,
    delAlert: false,
    chatOffset: 0,
    chatLimit: 10,
    chatRefreshing: false,
    chatFinished: false,
  };

  /**
   * Constructor
   */
  constructor() {
    super();
    this.chatEnd = React.createRef();
    this.chatAttach = React.createRef();
  }

  componentDidMount() {
    this.getChatsCount();

    this.subscribeToMessage()

    // On Browser Tab Focus
    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState == 'visible') {
        const { currentChat } = this.state;
        if (currentChat !== null) {
          this.getNewMessage();
        } else {
          this.getChatsCount();
        }
      }
    });

  }

  subscribeToMessage = async () => {
    let isBrowserSupported = await isSupported()
    if (isBrowserSupported) {
      const messaging = getMessaging(firebaseApp);
      onMessage(messaging, (payload) => {
        const { currentChat } = this.state;
        console.log('subscribeToMessage.payload', payload);
        if (currentChat !== null && currentChat.chat_id == payload.data.chat_id && payload.data.actionType == 'newMessage') {
          this.getNewMessage();
        } else if (currentChat !== null && currentChat.chat_id == payload.data.chat_id && payload.data.actionType == 'deleteMessage') {
          this.triggerDeleteChatDetails(payload.data.chat_id, payload.data.chat_detail_id);
        } else {
          this.getChatsCount();
          toast.info(payload.data.title);
        }
      });
    }
  }

  triggerDeleteChatDetails = (chat_id, chat_detail_id) => {
    console.log('triggerDeleteChatDetails', chat_detail_id);
    let chatDetails = this.state.chatDetails
    let chats = this.state.chats
    if (chatDetails) {
      chatDetails = chatDetails.map((v) => {
        if (v.chat_detail_id == chat_detail_id) {
          return { ...v, is_chat_detail_deleted: 1 }
        }
        return v
      })
      if (chatDetails[0].chat_detail_id == chat_detail_id) {
        chats = chats.map((v) => {
          if (v.chat_id == chat_id) {
            return { ...v, lastChatDetail: { ...v.lastChatDetail, is_chat_detail_deleted: 1 } }
          }
          return v
        })
      }
      this.setState({ chats, chatDetails })
    }
  }

  componentWillUnmount() {
    document.removeEventListener('visibilitychange', () => { })
  }

  componentDidUpdate(prevProps) {
    if (this.props.toggleChat != prevProps.toggleChat && this.props.toggleChat) {
      this.getChatsData();
    } else if (this.props.toggleChat != prevProps.toggleChat && !this.props.toggleChat) {
      this.setState({
        currentChat: null,
        search: '',
        message: '',
        chat: null,
        chatOffset: 0,
        chatRefreshing: false,
        chatFinished: false,
      })
    }
  }

  getNewMessage = () => {
    const { auth_key, user_id } = this.props.authData.loginData;
    const { center_id, center_timezone } = this.props.authData.centerData;
    let { chatOffset, chatLimit, chatDetails, chatRefreshing, chatFinished, currentChat, chats } = this.state;

    if (chatRefreshing) {
      return
    }
    this.setState({ chatRefreshing: true, }, async () => {
      if (this.getNewMessageReq) {
        this.getNewMessageReq.abort();
      }
      this.getNewMessageReq = new AbortController();

      const fData = new FormData();
      const chat_detail_id = (chatDetails) ? chatDetails[0].chat_detail_id : 10000000000
      fData.append('chat_id', currentChat.chat_id)
      fData.append('chat_detail_id', chat_detail_id)
      fData.append('center_timezone', center_timezone)
      fData.append('user_id', user_id)
      fData.append('center_id', center_id)

      try {
        const res = await axios.post(apiURL + 'chats/get-new-message', fData, {
          params: {
            auth_key: auth_key,
            appname: process.env.REACT_APP_NAME,
          },
          signal: this.getNewMessageReq.signal,
        });


        if (res) {
          // WHEN NEW MESSAGE IS RECEIVED ALL OLD MESSAGES WILL BE CHANGED TO READ
          if (res.data.data.chatDetails.length > 0) {
            chatDetails = chatDetails.map((v, i) => {
              if (v.sender_id == user_id) {
                return { ...v, chat_detail_read: 1 }
              }
              return v
            })
            chats = chats.map((v) => {
              if (currentChat.chat_id == v.chat_id) {
                return { ...v, lastChatDetail: res.data.data.chatDetails[0] }
              }
              return v
            })
          }

          this.setState(
            {
              chatDetails: (chatDetails) ? res.data.data.chatDetails.concat(chatDetails) : res.data.data.chatDetails,
              chatID: res.data.data.chat_id,
              chatRefreshing: false,
              chats
            },
            () => {
              if (chatOffset == 0) {
                this.scrollToBottom();
              }
            }
          );
        }
      } catch (err) {
        console.log('err', err)
        // toast.error(t('Something went wrong while fetching chat!'));

      }
    })
  }

  /**
   * Get Chats and Contacts
   */
  getChatsData = () => {
    this.setState({
      chats: null,
      contacts: null,
    });

    const { auth_key, user_id, full_name, role_id } =
      this.props.authData.loginData;
    const { center_id, center_timezone } = this.props.authData.centerData;
    const { search } = this.state;

    const fDataChats = new FormData();
    fDataChats.append('center_id', center_id);
    fDataChats.append('user_id', user_id);
    fDataChats.append('search', search);
    fDataChats.append('role_id', role_id);
    fDataChats.append('limit', 20);
    fDataChats.append('offset', 0);

    const fDataContacts = new FormData();
    fDataContacts.append('center_id', center_id);
    fDataContacts.append('user_id', user_id);
    fDataContacts.append('search', search);
    fDataContacts.append('limit', 20);
    fDataContacts.append('offset', 0);


    if (this.getChatsDataReq) {
      this.getChatsDataReq.abort()
    }
    this.getChatsDataReq = new AbortController();

    if (this.getChatsDataReq2) {
      this.getChatsDataReq2.abort()
    }
    this.getChatsDataReq2 = new AbortController();

    axios
      .all([
        axios.post(apiURL + 'chats/get-chats', fDataChats, {
          params: {
            auth_key: auth_key,
            appname: process.env.REACT_APP_NAME,
          },
          signal: this.getChatsDataReq.signal,
        }),
        axios.post(apiURL + 'chats/get-chat-contacts', fDataContacts, {
          params: {
            auth_key: auth_key,
            appname: process.env.REACT_APP_NAME,
          },
          signal: this.getChatsDataReq2.signal,
        }),
      ])
      .then(
        axios.spread((chats, contacts) => {
          this.setState({
            chats: chats.data.data,
            contacts: contacts.data.data,
          });
        })
      )
      .catch((err) => {
        if (axios.isCancel(err)) {

        } else {
          toast.error(
            'Something went wrong while fetching chats and contacts!'
          );

        }
      });
  };

  /**
   * Open Chat
   * @param {Object} currentChat
   */
  openChat = (currentChat) => {
    const { chats } = this.state;

    const chatIndex = chats.findIndex((item) => {
      return item.chat_id == currentChat.chat_id;
    });

    if (chats[chatIndex] !== undefined) {
      chats[chatIndex].newmessages = 0;
    }

    this.setState(
      {
        currentChat,
        chatDetails: null,
        chatID: 0,
        chatsCount: [],
        chats,
        chatOffset: 0,
        chatRefreshing: false,
        chatFinished: false,
      },
      () => {
        this.getChatMessages(currentChat);
      }
    );
  };

  /**
   * Get Chats Count
   */
  getChatsCount = async () => {
    if (this.getChatsCountRequest) {
      this.getChatsCountRequest.abort();
    }
    this.getChatsCountRequest = new AbortController();
    const { auth_key, user_id, role_id } = this.props.authData.loginData;
    const { center_id } = this.props.authData.centerData;

    const fData = new FormData();
    fData.append('center_id', center_id);
    fData.append('user_id', user_id);
    fData.append('role_id', role_id);

    try {
      const res = await axios.post(
        apiURL + 'chats/get-new-messages-count',
        fData,
        {
          params: {
            auth_key: auth_key,
            appname: process.env.REACT_APP_NAME,
          },
          signal: this.getChatsCountRequest.signal,
        }
      ).catch((error) => {
        if (axios.isCancel(error)) {

        }
      });

      this.props.setChatsCount(res.data.length)

      if (res.data.length > 0 && this.state.chats) {
        let chats = this.state.chats.map((v, i) => {
          let findChat = res.data.find((vv, ii) => vv.chat_id == v.chat_id)
          if (findChat) {
            let newmessages = v.newmessages
            if (parseInt(findChat.user_id_1) == parseInt(user_id)) {
              newmessages = findChat.user_id_1_newmessages
            } else if (parseInt(findChat.user_id_2) == parseInt(user_id)) {
              newmessages = findChat.user_id_2_newmessages
            }

            return {
              ...v,
              chat_datetime_modified: findChat.chat_datetime_modified,
              user_id_1_newmessages: findChat.user_id_1_newmessages,
              user_id_2_newmessages: findChat.user_id_2_newmessages,
              newmessages
            }
          }
          return v
        })
        this.setState({ chats: chats })
      }
    } catch (err) {
      console.log('err', err)

    }
  };

  /**
   * Get Chat Message
   * @param {Object} currentChat
   */
  getChatMessages = async (currentChat) => {
    const { auth_key, user_id } = this.props.authData.loginData;
    const { center_id, center_timezone } = this.props.authData.centerData;
    const { chatOffset, chatLimit, chatDetails, chatRefreshing, chatFinished } = this.state;

    if (chatRefreshing || chatFinished) {
      return
    }
    this.setState({ chatRefreshing: true, chatOffset: chatOffset + chatLimit }, async () => {
      if (this.getChatMessagesReq) {
        this.getChatMessagesReq.abort();
      }
      this.getChatMessagesReq = new AbortController();

      const fData = new FormData();
      const chat_detail_id = (chatDetails) ? chatDetails[chatDetails.length - 1].chat_detail_id : 10000000000
      if (currentChat.chat_id) {
        // OLD CHAT
        fData.append('center_id', center_id)
        fData.append('user_id', user_id)
        fData.append('center_timezone', center_timezone)
        fData.append('chat_limit', chatLimit)
        fData.append('chat_offset', chatOffset)
        fData.append('chat_id', currentChat.chat_id)
        fData.append('chat_detail_id', chat_detail_id)
      } else {
        // NEW CHAT
        fData.append('center_id', center_id);
        fData.append('center_timezone', center_timezone);
        fData.append('user_id', user_id);
        fData.append('user_id_2', currentChat.user_id);
        fData.append('chat_limit', chatLimit);
        fData.append('chat_offset', chatOffset);
      }

      try {
        const res = await POST((currentChat.chat_id) ? 'chats/get-more-messages' : 'chats/start-chat', fData, {
          signal: this.getChatMessagesReq.signal,
        });

        if (res) {
          this.setState(
            {
              chatDetails: (chatDetails) ? chatDetails.concat(res.data.data.chatDetails) : res.data.data.chatDetails,
              chatID: res.data.data.chat_id,
              chatRefreshing: false,
              chatFinished: (res.data.data.chatDetails.length <= 0) ? true : false
            },
            () => {
              if (chatOffset == 0) {
                this.scrollToBottom();
              } else {
                this.chatEnd.current.scrollTo(0, 600);
              }
            }
          );
        }
      } catch (err) {
        console.log('err', err)
        // toast.error(t('Something went wrong while fetching chat!'));

      }
    })
  };

  /**
   * Handle Chat & Contacts Search
   * @param {Object} e
   */
  handleSearch = (e) => {
    this.setState(
      {
        search: e.target.value,
      },
      () => {
        this.getChatsData();
      }
    );
  };

  /**
   * Send Firebase Cloud Message
   * @param {Number} recipient_id
   * @param {String} title
   * @param {String} body
   * @param {Object} data
   * @param {String} auth_key
   */
  sendFCM = async (recipient_id, title, body, data, auth_key) => {
    try {
      const fData = new FormData();
      fData.append('recipient_id', recipient_id);
      fData.append('title', title);
      fData.append('body', body);
      fData.append('data', JSON.stringify(data));

      const res = await axios.post(
        apiURL + 'authentication/send-cloud-message',
        fData,
        {
          params: {
            auth_key: auth_key,
            appname: process.env.REACT_APP_NAME,
          },
        }
      );
    } catch (err) {
      console.log('err', err)

    }
  };

  /**
   * Render Chat Message
   * @returns {HTMLElement}
   */
  renderChatMessages = () => {
    const { chatDetails } = this.state;
    const { user_id } = this.props.authData.loginData;
    return chatDetails.map((item, index) => {
      let isOther = (parseInt(item.sender_id) !== parseInt(user_id))
      let chat_detail_read = parseInt(item.chat_detail_read)
      return (
        <div key={index} className='chat-message' style={{ textAlign: (isOther) ? 'left' : 'right' }}>
          <div className='chat-message-message' style={{ position: 'relative', borderTopLeftRadius: (isOther) ? 0 : 15, borderTopRightRadius: (isOther) ? 15 : 0, backgroundColor: (isOther) ? '' : 'rgb(226 243 228)' }}>
            {parseInt(item.is_chat_detail_deleted) == 1 ? (
              <div className='text-muted' style={{ fontStyle: 'italic', fontSize: 13 }}>
                {t('Message deleted')}
              </div>
            ) : (
              <div style={{ fontSize: 14, }}>
                {item.chat_detail_message}
                {(!isOther) && <FontAwesomeIcon icon={(chat_detail_read) ? faCheckDouble : faCheck} style={{ position: 'absolute', bottom: 5, right: 5, fontSize: 12, color: (chat_detail_read) ? brand_colors[this.props.defaultTheme.theme_id].color4 : brand_colors[this.props.defaultTheme.theme_id].color10 }} />}
              </div>
            )}
            {(item.chat_detail_attachment && parseInt(item.is_chat_detail_deleted) !== 1) &&
              (
                <div className='py-2'>
                  <a href={portalURL + item.center_id + '/chat_details/' + item.chat_detail_attachment}
                    style={{ textDecoration: 'none', color: '#6c757d', fontSize: 13, }}
                    target='_blank' rel='noreferrer'>
                    {item.chat_detail_attachment.search('.pdf') == -1 ?
                      <Image thumbnail={true} src={portalURL + item.center_id + '/chat_details/' + item.chat_detail_attachment} />
                      :
                      <FontAwesomeIcon icon={faFilePdf} style={{ fontSize: 30 }} color='rgb(220, 53, 69)' />
                    }
                  </a>
                </div>
              )}
          </div>
          <div className='chat-message-timestamp' style={{ paddingLeft: (isOther) ? 10 : 0, paddingRight: (isOther) ? 0 : (item.is_chat_detail_deleted) == 1 ? 10 : 3, display: 'flex', justifyContent: (isOther) ? '' : 'end', alignItems: 'center' }}>
            <div>
              {moment(item.chat_detail_datetime).format('DD-MM-YYYY - h:mm a')}
            </div>
            {(item.sender_id == user_id) &&
              (item.is_chat_detail_deleted == 0 || item.is_chat_detail_deleted == null) && (
                <button className='chat-del-btn' onClick={() =>
                  this.setState({ delAlert: true, delID: item.chat_detail_id })
                }>
                  <FontAwesomeIcon icon={faTrashCan} color='rgb(220, 53, 69)' style={{ fontSize: 11 }} />
                </button>
              )}
          </div>
        </div>
      );
    });
  };

  /**
   * Scroll To Bottom
   */
  scrollToBottom = () => {
    if (this.chatEnd.current) {
      this.chatEnd.current.scrollToBottom();
    }
  };

  /**
   * Send Message
   */
  sendMessage = async () => {
    this.setState({ msgSending: true });
    const { auth_key, user_id, full_name } = this.props.authData.loginData;
    const { center_id, center_timezone } = this.props.authData.centerData;
    const { message, attachment, currentChat, chatID } = this.state;

    if (message == '' && attachment == null) {
      toast.error(t('Cannot send empty message'));
      this.setState({ msgSending: false });
      return;
    }

    const fData = new FormData();
    fData.append('sender_id', user_id);
    fData.append('recipient_id', currentChat.user_id);
    fData.append('center_id', center_id);
    fData.append('center_timezone', center_timezone);
    fData.append('chat_id', chatID);
    fData.append('chat_detail_message', message);
    fData.append('full_name', full_name);
    if (attachment !== null) {
      fData.append('file', attachment);
    }

    try {
      const res = await axios.post(
        apiURL + 'chats/insert-chat-details',
        fData,
        {
          params: {
            auth_key: auth_key,
            appname: process.env.REACT_APP_NAME,
          },
        }
      );

      if (!res.data.type) {
        toast.error(res.data.message);
      } else {
        let chatDetails = this.state.chatDetails
        let chatsNew = this.state.chats.map((v) => {
          if (v.chat_id == chatID) {
            return { ...v, lastChatDetail: res.data.data }
          }
          return v
        })
        this.setState({
          message: '',
          attachment: null,
          chats: chatsNew,
          chatDetails: (chatDetails) ? [res.data.data].concat(chatDetails) : [res.data.data]
        });
        //  Send FCM
        sendCloudMessage(
          currentChat.user_id,
          `New message from ${full_name}`,
          res.data.firebaseData.body,
          res.data.firebaseData.data
        );
      }

      this.setState({ msgSending: false });
    } catch (err) {
      console.log('err', err)
      toast.error(t('Something went wrong while sending message!'));

      this.setState({ msgSending: false });
    }
  };

  /**
   * Delete Chat
   */
  deleteChat = async () => {
    let { delID, currentChat, chats } = this.state;
    const { auth_key, user_id, full_name } = this.props.authData.loginData;
    const { center_id } = this.props.authData.centerData;

    const fData = new FormData();
    fData.append('center_id', center_id);
    fData.append('user_id', user_id);
    fData.append('chat_detail_id', delID);
    fData.append('chat_id', currentChat.chat_id);

    try {

      const res = await POST('chats/remove-chat-details', fData);

      if (res.data.type) {
        let chatDetails = this.state.chatDetails.map((v, i) => {
          if (v.chat_detail_id == delID) {
            return { ...v, is_chat_detail_deleted: 1 }
          }
          return v
        });

        if (chatDetails[0].chat_detail_id == delID) {
          chats = this.state.chats.map((v) => {
            if (v.chat_id == currentChat.chat_id) {
              return { ...v, lastChatDetail: { ...v.lastChatDetail, is_chat_detail_deleted: 1 } }
            }
            return v
          })
        }

        this.setState({
          chatDetails,
          delID: 0,
          delAlert: false,
          chats
        });

        sendCloudMessage(
          currentChat.user_id,
          `Message removed by ${full_name}`,
          res.data.firebaseData.body,
          res.data.firebaseData.data
        );

      } else {
        toast.error(t(res.data.message));
      }

    } catch (err) {
      console.log('err', err)
      toast.error(t('Something went wrong while deleting chat!'));

    }
  };

  /**
   * Set Chat Attachment
   */
  setChatAttach = () => {
    this.chatAttach.current.click();
  };

  /**
   * Upload File Handler
   */
  uploadFile = () => {
    const file = document.querySelector('#chat-attach').files[0];

    console.log('file', file);

    if (file.type == 'image/jpeg' || file.type == 'image/png' || file.type == 'application/pdf') {
      this.setState({
        attachment: file,
      });
    } else {
      toast.error(t('Only image or PDF file is allowed in attachment!'));
    }
  };

  loadMoreChatMsgs = () => { };

  // Render Component
  render() {

    const { show, chats, contacts, currentChat, message, attachment, msgSending, search, chat, delAlert, chatDetails, chatOffset, chatLimit, chatFinished, chatRefreshing } = this.state;
    console.log('CHATS', this.state);

    const { lang_orientation: dir } = this.props.defaultLanguage;

    return (
      <>
        <Offcanvas
          id='chat-drawer'
          show={this.props.toggleChat}
          onHide={this.props.setToggleChat}
          placement={dir == 1 ? 'start' : 'end'}>
          <Offcanvas.Header>
            <div className='chat-drawer-header'>
              <Offcanvas.Title>{t('Chats')}</Offcanvas.Title>
              <button
                type='button'
                className='btn-close'
                aria-label='Close'
                onClick={this.props.setToggleChat}></button>
            </div>
            <div className='chat-drawer-search'>
              <Form.Control
                type='text'
                placeholder={t('Search...')}
                onChange={this.handleSearch}
                value={search}
              />
            </div>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <h6>{t('Chats')}</h6>
            {chats == null ? (
              <div className='d-flex flex-column py-5 align-items-center'>
                <span className='mb-1'>
                  <Spinner animation='grow' variant='dark' />
                </span>
                {t('Fetching chats...')}
              </div>
            ) : (
              <>
                {chats.length > 0 ? (
                  <>
                    {chats.map((item, index) => (
                      <div
                        key={index}
                        onClick={() =>
                          this.openChat(item)
                        }>
                        <ChatContactItem data={item} defaultTheme={this.props.defaultTheme} loginData={this.props.authData.loginData} />
                      </div>
                    ))}
                  </>
                ) : (
                  <div className='d-flex flex-column py-5 align-items-center'>
                    {t('No chats found...')}
                  </div>
                )}
              </>
            )}
            <hr />
            <h6>{t('Contacts')}</h6>
            {contacts == null ? (
              <div className='d-flex flex-column py-5 align-items-center'>
                <span className='mb-1'>
                  <Spinner animation='grow' variant='dark' />
                </span>
                {t('Fetching contacts...')}
              </div>
            ) : (
              <>
                {contacts.length > 0 ? (
                  <>
                    {contacts.map((item, index) => (
                      <div
                        key={index}
                        onClick={() =>
                          this.openChat(item)
                        }>
                        <ChatContactItem data={item} defaultTheme={this.props.defaultTheme} loginData={this.props.authData.loginData} />
                      </div>
                    ))}
                  </>
                ) : (
                  <div className='d-flex flex-column py-5 align-items-center'>
                    {t('No contacts found...')}
                  </div>
                )}
              </>
            )}
          </Offcanvas.Body>
          {currentChat !== null && (
            <Card id='chat-box'>
              <Card.Header className='d-flex'>
                <div style={{ flex: 1 }}>
                  <strong>
                    {currentChat.full_name}
                  </strong>
                  {currentChat.other_chat_name &&
                    <div className='d-flex flex-row align-items-center'>
                      <FontAwesomeIcon icon={faUserShield} color={brand_colors[this.props.defaultTheme.theme_id].color16} style={{ fontSize: 16 }} />
                      <div
                        style={{ fontSize: 12, marginLeft: 5, color: brand_colors[this.props.defaultTheme.theme_id].color16, fontWeight: 'bold' }}>
                        {t('Chat with') + ' ' + currentChat.other_chat_name}
                      </div>
                    </div>
                  }
                </div>
                <button
                  onClick={() =>
                    this.setState({ currentChat: null })
                  }
                  className='text-muted'
                  style={{ border: 0, background: 'none' }}>
                  {' '}
                  <FontAwesomeIcon icon={faTimes} />
                </button>
              </Card.Header>
              <Card.Body className='py-0 px-0'>
                <Scrollbar ref={this.chatEnd} noScrollX={true} style={{ height: attachment !== null ? 'calc(80vh - 130px)' : 'calc(80vh - 102px)' }} onScroll={(scrollValues, prevScrollValues) => {

                  if (this.chatEnd.current.scrollTop < 200) {
                    //  MORE CHAT DETAILS
                    this.getChatMessages(currentChat)

                  }
                }}>
                  <div className='chat-window-details pt-3 px-2'>

                    {chatDetails !== null &&
                      chatDetails.length > 0 &&
                      this.renderChatMessages()
                    }

                    {/* NO MESSAGES FOUND */}
                    {chatDetails == null && chatFinished && !chatRefreshing &&
                      <div className='d-flex align-items-center justify-content-center' >
                        {t('No messages found')}
                      </div>
                    }
                    {/* LOADING MORE MESSAGES */}
                    {chatDetails == null && !chatFinished && chatRefreshing &&
                      <div className='d-flex align-items-center justify-content-center' >
                        <Spinner animation='grow' variant='dark' />
                      </div>
                    }
                    {/* FINISHED MORE MESSAGES */}
                    {chatDetails != null && chatFinished && !chatRefreshing &&
                      <div className='d-flex align-items-center justify-content-center' >
                        {t('START OF CHAT')}
                      </div>
                    }
                  </div>
                </Scrollbar>
              </Card.Body>
              {currentChat.other_chat_name
                ? <Card.Footer>
                  <div style={{ fontSize: 12, textAlign: 'center', fontWeight: 'bold' }}>
                    {t('You are not allowed to send messages in Others Chat')}
                  </div>
                </Card.Footer>
                : <Card.Footer>
                  {attachment !== null && (
                    <div style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      marginBottom: 5,
                      fontSize: 14
                    }}>
                      <FontAwesomeIcon icon={faPaperclip} style={{ marginRight: 5 }} />
                      {attachment.name}
                    </div>
                  )}
                  <div
                    style={{
                      gap: 10,
                      display: 'flex',
                    }}>
                    <input
                      type='file'
                      ref={this.chatAttach}
                      style={{ display: 'none' }}
                      id='chat-attach'
                      onChange={() => this.uploadFile()}
                    />
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}>
                      {attachment !== null ? (
                        <button
                          style={{
                            fontSize: 20,
                            border: 0,
                            backgroundColor:
                              'transparent',
                          }}
                          onClick={() => {
                            this.chatAttach.current.value =
                              null;
                            this.setState({
                              attachment: null,
                            });
                          }
                          }>
                          <FontAwesomeIcon
                            icon={faTimesCircle}
                            color='rgb(220, 53, 69)'
                          />
                        </button>
                      ) : (
                        <button
                          style={{
                            fontSize: 20,
                            border: 0,
                            backgroundColor:
                              'transparent',
                          }}
                          onClick={this.setChatAttach}>
                          <FontAwesomeIcon
                            icon={faPaperclip}
                          />
                        </button>
                      )}
                    </div>
                    <div style={{ flex: 1 }}>
                      <Form.Control type='text' placeholder={t('Type your message here...')}
                        onChange={(e) => this.setState({ message: e.target.value })}
                        onKeyDown={(e) => {
                          if (e.nativeEvent.key == 'Enter') {
                            this.sendMessage()
                          }
                        }} value={message}
                      />
                    </div>
                    <div>
                      <Button
                        disabled={msgSending}
                        onClick={() => this.sendMessage()}>
                        {t('SEND')}
                      </Button>
                    </div>
                  </div>
                </Card.Footer>}
            </Card>
          )}
        </Offcanvas>
        <SweetAlert
          show={delAlert}
          warning
          showCancel
          confirmBtnText={t('Yes, delete it!')}
          confirmBtnBsStyle='danger'
          title={t('Are you sure?')}
          onConfirm={() => this.deleteChat()}
          onCancel={() =>
            this.setState({ delAlert: false, delID: 0 })
          }
          focusCancelBtn>
          {t('You will not be able to recover this message!')}
        </SweetAlert>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  authData: state.auth.authData,
  selectedClass: state.selectedClass.data,
  languages: state.language.languages,
  defaultLanguage: state.language.defaultLanguage,
  defaultTheme: state.theme.defaultTheme,
  toggleChat: state.chats.toggleChat,
});


const mapDispatchToProps = () => ({
  setToggleChat,
  setChatsCount
});

export default connect(mapStateToProps, mapDispatchToProps())(Chats);
