import { createSlice } from '@reduxjs/toolkit';

export const examGradesSlice = createSlice({
  name: 'examGrades',
  initialState: {
    showModal: false,
    exam: {},
  },
  reducers: {
    setExamGrades: (state, action) => {
      state.showModal = action.payload.showModal;
      state.exam = action.payload.exam;
    },
  },
});

export const { setExamGrades } =
  examGradesSlice.actions;
export default examGradesSlice.reducer;
