import React, { Component } from 'react';
import { Container, Card, Row, Col, Spinner, Button, Table, Badge, Form, Image, Modal } from 'react-bootstrap';
import { t } from '../../helpers/translation_helper';
import NavBar from '../_partials/NavBar/_NavBar';
import Header from '../_partials/Header/_Header';
import { connect } from 'react-redux';
import axios from 'axios';
import { toast } from 'react-toastify';
import { GET, POST } from '../../api';
import imgDescription from '../../assets/images/description.png';
import { brand_colors } from '../../helpers/brand_colors_helper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faPencil, faPlus, faSearch, faXmark } from '@fortawesome/free-solid-svg-icons';
import Select from 'react-select';
import moment from 'moment';
import { handleFormErrors } from '../../helpers/form_helpers';
import { firebaseApp } from '../../services/firebase';
import { getMessaging, isSupported, onMessage } from 'firebase/messaging';

const apiURL = process.env.REACT_APP_API_URL;
const portalURL = process.env.REACT_APP_PORTAL_URL;

class StudentPickDrop extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cDate: moment(),
            student_pick_drop: [],
            showAllClasses: [1, 2, 8, 12, 22].includes(props.authData.loginData.role_id) ? true : false
        }
    }

    componentDidMount() {
        this.getStudentPickDrop()
        this.subscribeToMessage()
    }

    subscribeToMessage = async () => {
        let isBrowserSupported = await isSupported()
        if (isBrowserSupported) {
            const messaging = getMessaging(firebaseApp);
            onMessage(messaging, (payload) => {
                console.log('payload.data', payload.data);
                // console.log('Message received payload. ', payload);
                let actionType = payload.data.actionType
                if (actionType == 'StudentPickDrop') {
                    // spd_id
                    let actionId = payload.data.actionId
                    let actionType2 = payload.data.actionType2

                    // IN CASE OF MULTIPLE CHILDREN OF ONE PARENT IT WAS NOT DISPLAYING ALL CHILDREN
                    // refreshOne(actionId, actionType2)

                    this.getStudentPickDrop()
                }
            });
        }
    }

    getStudentPickDrop = async () => {
        if (this.getStudentPickDropReq) {
            this.getStudentPickDropReq.abort();
        }

        this.getStudentPickDropReq = new AbortController();
        let showAllClasses = this.state.showAllClasses

        let res = await GET('student-pick-drop/get-student-pick-drop', {
            params: {
                class_id: (showAllClasses) ? null : this.props.selectedClass.class.class_id
            },
            signal: this.getStudentPickDropReq.signal,
        })

        if (res) {
            let resData = res.data
            console.log('resData', resData);
            this.setState({
                cDate: resData.CDate,
                student_pick_drop: resData.student_pick_drop
            })
        }
    }


    render() {
        console.log('this.state', this.state);

        const { cDate, student_pick_drop, showAllClasses } = this.state;
        const { defaultTheme } = this.props
        return (
            <Container fluid>
                <div id='iedu-layout'>
                    <NavBar />
                    <div id='page-content'>
                        <Header lite={true} heading={t('Student Pick Drop Requests')} backBtn={true} showCenterSelector={true} showClassSelector={true} classSelectorFunc={[this.getStudentPickDrop]} />
                        <div className='grey-section'>
                            <div className='grey-section-header'>
                                <h5>
                                    <span className='me-1'>{t('Live Updates')}</span>
                                    <span className='mx-1' style={{ opacity: 0.6, fontWeight: 'normal' }}>|</span>
                                    <span className='mx-1' style={{ opacity: 0.8, fontSize: '1rem', fontWeight: 'normal' }}>{moment(cDate).format('dddd, MMMM DD, YYYY')}</span>
                                </h5>
                                <div className='d-flex flex-row align-items-center px-1 justify-content-end' style={{ flex: 1 }}>
                                    {[1, 2, 8, 12, 22].includes(this.props.authData.loginData.role_id) &&
                                        <Form.Check type="switch" label={(showAllClasses) ? "Showing All Classes" : "Showing Selected Class"} onChange={() => this.setState({ showAllClasses: !showAllClasses })} checked={showAllClasses} />
                                    }
                                </div>
                            </div>
                            <div>
                                <Card>
                                    <Card.Body>
                                        <Table hover striped bordered size="sm">
                                            <thead>
                                                <tr>
                                                    <th>{t('Name')}</th>
                                                    <th>
                                                        {t('Drop-off')}
                                                        <small className='ms-1' style={{ opacity: 0.7 }}>{t('PARENT IS HERE TO DROP HIS CHILD')}</small>
                                                    </th>
                                                    <th>
                                                        {t('PICKUP')}
                                                        <small className='ms-1' style={{ opacity: 0.7 }}>{t('PARENT IS PICKUP HIS CHILD')}</small>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {student_pick_drop.map((v, i) => {
                                                    let apns_in = v.apns_in
                                                    let apns_out = v.apns_out

                                                    return <tr key={i}>
                                                        <td>
                                                            <div className='d-flex flex-column justify-content-center align-items-center'>
                                                                <img src={(v.picture) ? portalURL + v.center_id + '/children/' + v.picture : 'https://via.placeholder.com/150x150?text=' + v.child_name[0].toUpperCase()} style={{ width: '60px', height: '60px', objectFit: 'cover', borderRadius: '20px' }} />
                                                                <strong>{v.child_name}</strong>
                                                                {(showAllClasses) && <div style={{ fontSize: 12, fontWeight: 'bold', opacity: 0.8 }}>{v.class_theme}</div>}
                                                            </div>
                                                        </td>
                                                        <td>{(apns_in) &&
                                                            <div className="d-flex flex-column">
                                                                <span className="d-flex flex-row align-items-center">
                                                                    <FontAwesomeIcon icon={faClock} className='me-1' style={{ opacity: 0.8 }} />
                                                                    {moment(v.std_datetime_in).format('hh:mm:ss A')}
                                                                </span>
                                                                <span className="text-muted font-small-4">
                                                                    {apns_in.name}
                                                                    {(apns_in.relation) && <Badge bg='warning ms-1'>{apns_in.relation}</Badge>}
                                                                </span>
                                                                <span className="text-muted font-small-2">{apns_in.devicename} ({apns_in.devicemodel})</span>
                                                            </div>
                                                        }</td>
                                                        <td>{(apns_out) &&
                                                            <div className="d-flex flex-column">
                                                                <span className="d-flex flex-row align-items-center">
                                                                    <FontAwesomeIcon icon={faClock} className='me-1' style={{ opacity: 0.8 }} />
                                                                    {moment(v.std_datetime_out).format('hh:mm:ss A')}
                                                                </span>
                                                                <span className="text-muted font-small-4">
                                                                    {apns_out.name}
                                                                    {(apns_out.relation) && <Badge bg='warning ms-1'>{apns_out.relation}</Badge>}
                                                                </span>
                                                                <span className="text-muted font-small-2">{apns_out.devicename} ({apns_out.devicemodel})</span>
                                                            </div>
                                                        }</td>
                                                    </tr>
                                                })}
                                            </tbody>
                                        </Table>
                                    </Card.Body>
                                </Card>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        );
    }
}

const mapStateToProps = (state) => ({
    authData: state.auth.authData,
    defaultLanguage: state.language.defaultLanguage,
    selectedClass: state.selectedClass.data,
    defaultTheme: state.theme.defaultTheme,
});


const mapDispatchToProps = () => ({
});
export default connect(mapStateToProps, mapDispatchToProps())(StudentPickDrop);
