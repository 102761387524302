import React, { Component } from 'react';
import { Container, Card, Row, Col, ButtonGroup, Button, DropdownButton, Dropdown, Form, Modal, Spinner, Table, InputGroup, Badge, Image } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faCheckCircle, faSearch, faUser, faXmark, faLayerGroup, faHandsHoldingChild, faPeopleGroup, faListCheck, faPrint, faPencil, faTrash, faCheckDouble, faSortDown, faSortUp, faThumbsUp, faThumbsDown, faL, faChevronUp, faChevronDown, faTimes } from '@fortawesome/free-solid-svg-icons';
import { faCalendar, faEye, faEyeSlash, faFileExcel, faFilePdf } from '@fortawesome/free-regular-svg-icons';
import { t } from '../../helpers/translation_helper';
import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';
import Select from 'react-select';
import { connect } from 'react-redux';
import moment from 'moment';
import { brand_colors } from '../../helpers/brand_colors_helper';
import { GET, POST } from '../../api';
import { toast } from 'react-toastify';
import { handleFormErrors } from '../../helpers/form_helpers';
import axios from 'axios';
import Pagination from '../_partials/Pagination';
import SweetAlert from 'react-bootstrap-sweetalert';
import { colorPalette } from '../../constants/colorsPalette';
import { renderTableBody, renderTableHead } from '../../helpers/table_helper';
var Chance = require('chance');
var chance = new Chance();
let cancelToken;

class Gradeslevel extends Component {
    constructor(props) {
        super(props);

        this.searchFieldRef = React.createRef();
        this.fData = {
            edit: false,
            agegroup_id: [],
            gradeslevel: [
                {
                    gradeslevel_id: '',
                    gradeslevel_from: '',
                    gradeslevel_to: '',
                    gradeslevel_color: '',
                    gradeslevel_name: '',
                    gradeslevel_desc: '',
                    gradeslevel_code: '',
                    gradeslevel_grade: '',
                    open_colorpicker: false
                }
            ]
        }
        this.state = {
            ...this.defaultFormFields,
            deleteModal: { show: false, id: 0, action: () => { } },
            working: false,
            isEditing: false,
            showFormModal: false,
            modalForm: {
                show: false,
                title: 'New Definition',
                toggle: (fData = this.fData) => this.setState({ modalForm: { ...this.state.modalForm, show: !this.state.modalForm.show, fData: { ...fData, open_colorpicker: false }, title: (fData.edit) ? 'Update Definition' : 'New Definition' } }, () => {
                    if (this.state.modalForm.show && !fData.edit) {
                        this.getAgegroups()
                    }
                }),
                save: () => this.saveUpdate(),
                fData: this.fData,
                setState: (i, key, value) => {
                    let modalForm = this.state.modalForm
                    modalForm.fData.gradeslevel[i][key] = value
                    this.setState({ modalForm: modalForm })
                }
            },
            agegroups: [],

            cdt: {
                fetching: false,
                search: '',
                rows: 5,
                offset: 0,
                order: 'gradeslevel.gradeslevel_id',
                dir: 'DESC',
                lastPage: 0,
                page: 0,
                totalRecords: 0,
                rowsOptions: [5, 10, 25, 50, 'All'],
                columns: [
                    {
                        id: 'gradeslevel.gradeslevel_id',
                        column: 'gradeslevel_id',
                        name: 'Actions',
                        visibility: true
                    },
                    {
                        id: 'agegroup.agegroup_code',
                        column: 'agegroup_code',
                        name: 'Grade',
                        visibility: true
                    },
                ],
                data: [],
            },
        }
    }

    componentDidMount() {
        const {
            search,
            rows: limit,
            order,
            dir
        } = this.state.cdt;
        this.fetch({
            search, limit, order, dir, page: 0
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.authData.centerData.center_id != this.props.authData.centerData.center_id) {
            const {
                search,
                rows: limit,
                order,
                dir
            } = this.state.cdt;
            this.fetch({
                search, limit, order, dir, page: 0
            });
        }
    }

    fetch = async (params) => {

        this.setState({
            cdt: {
                ...this.state.cdt,
                fetching: true,
            }
        });

        if (cancelToken) {
            cancelToken.abort();
        }
        cancelToken = new AbortController();

        try {
            const res = await GET('gradeslevel/get', {
                signal: cancelToken.signal,
                params: {
                    search: params.search,
                    order: params.order,
                    dir: params.dir,
                    limit: params.limit,
                    offset: parseInt(params.page) * parseInt(params.limit)
                },
            });

            if (res.status === 200) {
                this.setState({
                    cdt: {
                        ...this.state.cdt,
                        fetching: false,
                        search: params.search,
                        rows: parseInt(params.limit),
                        offset: parseInt(params.page) * parseInt(params.limit),
                        order: params.order,
                        dir: params.dir,
                        page: params.page,
                        totalRecords: parseInt(res.data.total_count_filtered),
                        lastPage: Math.ceil(parseInt(res.data.total_count_filtered) / parseInt(params.limit)),
                        data: res.data.data.map((item) => {
                            return {
                                uid: chance.guid(),
                                ...item
                            }
                        }),
                    }
                });
            }

        } catch (err) {
            console.log('err', err)
            this.setState({
                cdt: {
                    ...this.state.cdt,
                    fetching: false,
                }
            });

        }
    }

    getAgegroups = async () => {
        if (this.getAgegroupsReq) {
            this.getAgegroupsReq.abort();
        }
        this.getAgegroupsReq = new AbortController();
        const res = await GET('gradeslevel/get-age-groups',
            {
                signal: this.getAgegroupsReq.signal,
            }
        );
        if (res) {
            let resData = res.data
            this.setState({ agegroups: resData.map((v) => { return { value: v.agegroup_id, label: v.agegroup_code, ...v } }) })
        }
    };
    showHideColumn = (column_index) => {
        const columns = this.state.cdt.columns;
        columns[column_index].visibility = !columns[column_index].visibility;
        this.setState({ ctd: { ...this.state.ctd, columns } })
    }

    exportExcel = async () => {

        const excelData = this.state.cdt.data.map((item) => {
            const row = {};
            this.state.cdt.columns
                .filter((column) => column.visibility)
                .map((column) => {
                    if (column.name !== 'Actions') {
                        row[column.name] = item[column.column];
                    }
                });
            return row;
        });

        const fileName = 'Grades Level Export ' + moment().format('DD-MM-YYYY');
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';
        const ws = XLSX.utils.json_to_sheet(excelData);

        const cellStyle = {
            font: {
                bold: true,
            },
        }

        Array.from('A').map((letter) => {
            ws[letter + "1"].s = cellStyle;
        });

        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });

        FileSaver.saveAs(data, fileName + fileExtension);
    }

    renderCell = (column_data, column_id, column_index, dataObj) => {
        const { authData } = this.props;
        const { center_id } = authData.centerData;

        let tdData;

        // --------------------------------------------
        if (column_id == 'gradeslevel_id') { // Action Buttons
            tdData = <>
                <Button
                    onClick={() => this.delete(dataObj)}
                    variant="danger" size='sm' className='me-1'>
                    <FontAwesomeIcon icon={faTrash} />
                </Button>
                <Button
                    onClick={() => this.edit(dataObj)}
                    variant="warning" size='sm' className='me-1'>
                    <FontAwesomeIcon icon={faPencil} />
                </Button>
            </>;
            // ------------------------------------------------------------------------
        } else {
            tdData = column_data;
        }


        return <td key={column_index}>{tdData}</td>;
    }

    saveUpdate = async () => {
        let modalFormfData = { ...this.state.modalForm.fData }
        modalFormfData.agegroup_id = modalFormfData.agegroup_id.map((v, i) => v.agegroup_id)
        this.setState({ working: true });

        if (this.saveUpdateReq) {
            this.saveUpdateReq.abort();
        }
        this.saveUpdateReq = new AbortController();

        const fData = new FormData();
        fData.append('gradeslevelData', JSON.stringify(modalFormfData));
        const res = await POST('gradeslevel/save', fData, {
            signal: this.saveUpdateReq.signal,
        });
        if (res.data.type) {
            this.setState({ working: false, modalForm: { ...this.state.modalForm, show: !this.state.modalForm.show, fData: this.fData } });
            toast.success(t(res.data.message));
            this.fetch({
                search: '',
                limit: this.state.cdt.rows,
                order: this.state.cdt.order,
                dir: this.state.cdt.dir,
                page: 0,
            });
        } else {
            // toast.error(t(res.data.message));
            handleFormErrors(res.data.data)
            this.setState({ working: false });
        }
    }

    edit = async (data) => {
        console.log('data', data);
        const res = await GET('gradeslevel/edit', {
            params: {
                agegroup_id: data.agegroup_id
            }
        });
        if (res) {
            let resData = res.data
            let fData = {
                edit: true,
                agegroup_id: [{ value: data.agegroup_id, label: data.agegroup_code, agegroup_id: data.agegroup_id, agegroup_code: data.agegroup_code }],
                gradeslevel: resData.map((v) => { return { ...v, open_colorpicker: false } })
            }
            this.state.modalForm.toggle(fData)
        }
    }

    delete = async (data) => {
        this.setState({
            deleteModal: {
                ...this.state.deleteModal, show: true, id: data, action: async () => {
                    const res = await GET('gradeslevel/delete', {
                        params: {
                            agegroup_id: data.agegroup_id
                        }
                    });
                    if (res) {
                        let resData = res.data
                        if (resData.type) {
                            this.setState({
                                cdt: {
                                    ...this.state.cdt,
                                    data: this.state.cdt.data.filter(item => item.uid !== data.uid)
                                }, deleteModal: {
                                    ...this.state.deleteModal, show: false, id: null
                                }
                            });
                        } else {
                            this.setState({
                                deleteModal: {
                                    ...this.state.deleteModal, show: false, id: null
                                }
                            });
                        }
                        toast[resData.messageType](t(resData.message));
                    }
                }
            }
        })
    }

    render() {
        console.log('GRADESLEVEL', this.state);
        const { modalForm, cdt } = this.state
        return (
            <>
                <div className='shadow-box mt-1' style={{
                    marginInline: 5
                }}>
                    <Card
                        className='border-0'
                        style={{ borderRadius: '20px' }}>
                        <Card.Header style={{
                            backgroundColor: '#fff',
                            borderTopLeftRadius: '20px',
                            borderTopRightRadius: '20px',
                            fontWeight: '700'
                        }}>
                            <Row>
                                <Col md={6} className='d-flex align-items-center'>
                                    {t('Marks Definition')}
                                </Col>
                                <Col md={6} className='d-flex justify-content-end'>
                                    <div className='cdt-search'>
                                        <input type='text'
                                            ref={this.searchFieldRef}
                                            defaultValue={cdt.search}
                                            onChange={(e) => this.fetch({
                                                search: e.target.value,
                                                limit: cdt.rows,
                                                order: cdt.order,
                                                dir: cdt.dir,
                                                page: 0
                                            })}
                                            placeholder={t('Search...')} />
                                        <button
                                            onClick={() => {
                                                this.fetch({
                                                    search: '',
                                                    limit: cdt.rows,
                                                    order: cdt.order,
                                                    dir: cdt.dir,
                                                    page: 0
                                                });
                                                this.searchFieldRef.current.value = '';
                                            }}>
                                            <FontAwesomeIcon
                                                icon={cdt.search == '' ? faSearch : faXmark}
                                                color={brand_colors[this.props.defaultTheme.theme_id].color18}
                                            />
                                        </button>
                                    </div>
                                    <button
                                        onClick={() => modalForm.toggle()}
                                        className='btn btn-success btn-sm ms-3'><FontAwesomeIcon icon={faPlus} color={brand_colors[this.props.defaultTheme.theme_id].color8} style={{ fontSize: 12 }} /></button>
                                </Col>
                            </Row>
                        </Card.Header>
                        <Card.Body style={{ paddingTop: 8 }}>
                            <Row>
                                <Col md={6}>
                                    <ButtonGroup size='sm' className='me-3 float-left'>
                                        {/* <Button><FontAwesomeIcon icon={faFilePdf} /> {t('PDF')}</Button> */}
                                        <Button onClick={() => this.exportExcel()}>
                                            <FontAwesomeIcon icon={faFileExcel} /> {t('Excel')}
                                        </Button>
                                        {/* <Button><FontAwesomeIcon icon={faPrint} /> {t('Print')}</Button> */}
                                        <DropdownButton
                                            autoClose={'outside'}
                                            size='sm'
                                            as={ButtonGroup}
                                            title={t('Column Visibility')}>
                                            {cdt.columns.map((column, column_index) => (
                                                <Dropdown.Item key={column_index} className={column.visibility ? 'column-name-item' : 'column-name-item active'} onClick={() => this.showHideColumn(column_index)}>
                                                    <FontAwesomeIcon icon={column.visibility ? faEye : faEyeSlash} /> {column.name}
                                                </Dropdown.Item>
                                            ))}
                                        </DropdownButton>
                                    </ButtonGroup>
                                </Col>
                                <Col md={6} className='d-flex justify-content-end'>
                                    <div style={{ display: 'inline-block' }}>
                                        {t('Show')}
                                        <Form.Select
                                            value={cdt.rows}
                                            size="sm"
                                            onChange={(e) => this.fetch({
                                                search: cdt.search,
                                                limit: e.target.value,
                                                order: cdt.order,
                                                dir: cdt.dir,
                                                page: cdt.page
                                            })}
                                            style={{ width: 70, marginInline: 5, display: 'inline-block' }}>
                                            {cdt.rowsOptions.map((row, row_index) => (<option key={row_index} >{row}</option>))}
                                        </Form.Select>
                                        {t('Records')}
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                {cdt.fetching &&
                                    <Col md={12} className='py-5 d-flex flex-column align-items-center'>
                                        <Spinner as='span' animation='grow' size='sm' /> {t('Loading Data')}
                                    </Col>
                                }
                                {!cdt.fetching && cdt.data.length === 0 &&
                                    <Col md={12} className='py-5 d-flex flex-column align-items-center'>
                                        {t('No Data Found')}
                                    </Col>
                                }
                                {!cdt.fetching && cdt.data.length !== 0 &&
                                    <Col md={12} className='pt-2'>
                                        <div id="datatable-list" className='mb-3' style={{ width: 'initial' }}>
                                            <Table responsive>
                                                {renderTableHead(this)}
                                                {renderTableBody(this)}
                                            </Table>
                                        </div>
                                    </Col>
                                }
                            </Row>
                            <Pagination
                                pageCount={cdt.lastPage || 1}
                                forcePage={cdt.page}
                                callbackParams={{
                                    search: cdt.search,
                                    limit: cdt.rows,
                                    order: cdt.order,
                                    dir: cdt.dir,
                                }}
                                callback={this.fetch}
                            />
                        </Card.Body>
                    </Card>
                </div>
                <Modal size='lg' show={modalForm.show} onHide={modalForm.toggle} backdrop="static"
        keyboard={false}>
                    <Modal.Header closeButton>
                        <Modal.Title>{modalForm.title}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className='custom-styled-form'>
                        <Row className="mb-3">
                            <Col md={12}>
                                {modalForm.fData.edit
                                    ? <div style={{ fontSize: 18, fontWeight: 'bold' }}>{modalForm.fData.agegroup_id[0].label}</div>
                                    : <Select
                                        isMulti={true}
                                        closeMenuOnSelect={false}
                                        value={modalForm.fData.agegroup_id}
                                        menuPortalTarget={document.body}
                                        styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999, }), }}
                                        options={this.state.agegroups}
                                        onChange={(obj) => this.setState({ modalForm: { ...this.state.modalForm, fData: { ...this.state.modalForm.fData, agegroup_id: obj } } })}
                                    />
                                }
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Button variant='info' onClick={() => {
                                    let new_modalForm = this.state.modalForm
                                    new_modalForm.fData.gradeslevel.push({
                                        gradeslevel_id: '',
                                        gradeslevel_from: '',
                                        gradeslevel_to: '',
                                        gradeslevel_color: '',
                                        gradeslevel_name: '',
                                        gradeslevel_desc: '',
                                        gradeslevel_code: '',
                                        gradeslevel_grade: '',
                                        open_colorpicker: false
                                    })
                                    this.setState({ modalForm: new_modalForm })
                                }}>
                                    <FontAwesomeIcon icon={faPlus} />
                                </Button>
                            </Col>
                        </Row>
                        {modalForm.fData.gradeslevel.map((v, i) => {
                            return <div className='d-flex flex-column mb-2 pb-2 border-bottom'>
                                <div className='d-flex flex-row'>
                                    <div className='p-1 d-flex align-items-center'>
                                        <Button variant='danger' size="sm" onClick={() => {
                                            let new_modalForm = this.state.modalForm
                                            new_modalForm.fData.gradeslevel.splice(i, 1)
                                            this.setState({ modalForm: new_modalForm })
                                        }}>
                                            <FontAwesomeIcon icon={faTimes} />
                                        </Button>
                                    </div>
                                    <div className='p-1'>
                                        <Form.Group>
                                            <Form.Label>{t('Name')}</Form.Label>
                                            <Form.Control type="text"
                                                defaultValue={v.gradeslevel_name}
                                                onChange={(e) => modalForm.setState(i, 'gradeslevel_name', e.target.value)} />
                                        </Form.Group>
                                    </div>
                                    <div className='p-1'>
                                        <Form.Group>
                                            <Form.Label>{t('Abbreviation')}</Form.Label>
                                            <Form.Control type="text"
                                                defaultValue={v.gradeslevel_code}
                                                onChange={(e) => modalForm.setState(i, 'gradeslevel_code', e.target.value)} />
                                        </Form.Group>
                                    </div>
                                    <div className='p-1'>
                                        <Form.Group>
                                            <Form.Label>{t('Marks From')}</Form.Label>
                                            <Form.Control type="text"
                                                defaultValue={v.gradeslevel_from}
                                                onChange={(e) => modalForm.setState(i, 'gradeslevel_from', e.target.value)} />
                                        </Form.Group>
                                    </div>
                                    <div className='p-1'>
                                        <Form.Group>
                                            <Form.Label>{t('Marks To')}</Form.Label>
                                            <Form.Control type="text"
                                                defaultValue={v.gradeslevel_to}
                                                onChange={(e) => modalForm.setState(i, 'gradeslevel_to', e.target.value)} />
                                        </Form.Group>
                                    </div>
                                    <div className='p-1'>
                                        <Form.Group>
                                            <Form.Label>{t('Grade')}</Form.Label>
                                            <Form.Control type="text"
                                                defaultValue={v.gradeslevel_grade}
                                                onChange={(e) => modalForm.setState(i, 'gradeslevel_grade', e.target.value)} />
                                        </Form.Group>
                                    </div>
                                </div>
                                <div className='d-flex flex-row' >
                                    <div className='p-1'>
                                        <Form.Group >
                                            <Form.Label>{t('Color')}</Form.Label>
                                            <div className='selected-color' onClick={() => modalForm.setState(i, 'open_colorpicker', true)}>
                                                <div style={{ backgroundColor: v.gradeslevel_color }}></div>
                                            </div>
                                            {v.open_colorpicker &&
                                                <div className='color-palette'>
                                                    {colorPalette.map((color, color_i) => (
                                                        <div
                                                            key={color_i}
                                                            onClick={() => {
                                                                let new_modalForm = this.state.modalForm
                                                                modalForm.fData.gradeslevel[i]['open_colorpicker'] = false
                                                                modalForm.fData.gradeslevel[i]['gradeslevel_color'] = color.code
                                                                this.setState({ modalForm: new_modalForm })
                                                            }}
                                                            style={{ backgroundColor: color.code }}
                                                        ></div>
                                                    ))}
                                                </div>
                                            }
                                        </Form.Group>
                                    </div>
                                    <Form.Group style={{ width: '100%' }}>
                                        <Form.Label>{t('Description')}</Form.Label>
                                        <Form.Control as="textarea" rows={2}
                                            defaultValue={v.gradeslevel_desc}
                                            onChange={(e) => modalForm.setState(i, 'gradeslevel_desc', e.target.value)} />
                                    </Form.Group>
                                </div>
                            </div>

                        })}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => modalForm.toggle()}>
                            {t('Close')}
                        </Button>
                        <Button disabled={this.state.working} variant='success' onClick={() => modalForm.save()}>
                            {t('Save')} {this.state.working ? <Spinner as='span' animation='grow' size='sm' role='status' aria-hidden='true' /> : ''}
                        </Button>
                    </Modal.Footer>
                </Modal >
                <SweetAlert show={this.state.deleteModal.show} warning showCancel confirmBtnText={t('Yes, delete it!')} confirmBtnBsStyle='danger' title={t('Are you sure?')} onConfirm={this.state.deleteModal.action} onCancel={() => this.setState({ deleteModal: { ...this.state.deleteModal, show: false, id: 0, action: () => { } } })} focusCancelBtn>
                    {t('Confirm Delete!')}
                </SweetAlert>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    authData: state.auth.authData,
    defaultLanguage: state.language.defaultLanguage,
    defaultTheme: state.theme.defaultTheme,
});

export default connect(mapStateToProps, null)(Gradeslevel);
