import React, { Component } from 'react';
import { Container, Card, Row, Col, Button, Badge, Modal, Form, Spinner, Image } from 'react-bootstrap';
import { t } from '../helpers/translation_helper';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBook, faEdit, faHomeUser, faListOl, faPencil, faPlus, faSave, faSearch, faTrashCan, faXmark, faEllipsisV, faTrashAlt, faCheck, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { toast } from 'react-toastify';
import Select from 'react-select'
import { Chance } from 'chance';
import { brand_colors } from '../helpers/brand_colors_helper';
import { Link, NavLink } from 'react-router-dom';
import { getExtension } from '../helpers/general_helpers';
import { faThumbsDown, faThumbsUp } from '@fortawesome/free-regular-svg-icons';
const chance = new Chance();

const apiURL = process.env.REACT_APP_API_URL;
const portalURL = process.env.REACT_APP_PORTAL_URL;
class HorizontalCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }
  render() {
    // let linkTo = ''
    // let linkToStates = {}
    // let cardImage = require('../assets/images/noimage.png')
    // let name = 'Name Name Name'
    // let code = 'CODE'
    // let text1 = 'Name2'
    // let text2 = 'Name3'
    // let selected = 0
    // let actions = [
    //     { type: 'selection', name: 'Selection', iconName: null, iconColor: brand_colors[this.props.defaultTheme.theme_id].color4, onClick: () => { } },
    //     { type: 'status', name: 'Status', image: null, iconName: faThumbsDown, iconColor: brand_colors[this.props.defaultTheme.theme_id].color11, onClick: () => { } },
    //     { type: 'edit', name: 'Edit', image: require('../assets/images/edit.png'), iconName: null, iconColor: brand_colors[this.props.defaultTheme.theme_id].color16, onClick: () => { } },
    //     { type: 'delete', name: 'Delete', image: require('../assets/images/remove-red.png'), iconName: null, iconColor: brand_colors[this.props.defaultTheme.theme_id].color11, onClick: () => { } },
    // ]
    if (!this.props.data) {
      return null
    }
    let { data, size, attributes } = this.props
    let { linkTo, linkToStates, cardImage, name, code, text1, text2, selected, actions, text3, text4, text5, text6, text7, text8 } = data
    return (<div {...this.props.attributes} key={this.props.key} style={{
      minWidth: (size == 'sm') ? '20vw' : '30vw', backgroundColor: brand_colors[this.props.defaultTheme.theme_id].color8, borderRadius: (size == 'sm') ? '0.5rem' : '1rem', padding: (size == 'sm') ? '0.5rem' : '1rem', margin: (size == 'sm') ? '0.5rem' : '1rem', position: 'relative', boxShadow: '-1px 2px 16px 2px rgba(0,0,0,0.1)',
    }}>
      <div className='d-flex flex-row justify-content-between align-items-center'>
        <div className='d-flex flex-row ' style={{ flex: 1 }}>
          {cardImage &&
            <div className='d-flex align-items-center me-3'>
              <Link to={linkTo} state={linkToStates}>
                <Image src={cardImage} style={{ objectFit: 'cover', width: (size == 'sm') ? '2rem' : '3rem', height: (size == 'sm') ? '2rem' : '3rem', backgroundColor: brand_colors[this.props.defaultTheme.theme_id].color8, borderRadius: '100%' }} />
              </Link>
            </div>
          }
          <div className='d-flex align-items-center me-3' style={{ flex: 1 }}>
            <div>
              <div>
                <strong style={{ fontSize: (size == 'sm') ? '0.8rem' : '1rem' }}>{name}</strong>
                {code && <span className='mx-1 text-muted' style={{ fontSize: (size == 'sm') ? '0.85rem' : '0.9rem' }}>{code}</span>}
              </div>
              {(text1 || text2) &&
                <div className="d-flex flex-row">
                  {text1 && <div style={{ fontSize: (size == 'sm') ? '0.75rem' : '0.8rem' }}>{text1}</div>}
                  {text2 && <div className='mx-1 text-muted' style={{ fontSize: (size == 'sm') ? '0.75rem' : '0.8rem' }}>{text2}</div>}
                </div>
              }
              {(text3 || text4) &&
                <div className="d-flex flex-row">
                  {text3 && <div style={{ fontSize: (size == 'sm') ? '0.75rem' : '0.8rem' }}>{text3}</div>}
                  {text4 && <div className='mx-1 text-muted' style={{ fontSize: (size == 'sm') ? '0.75rem' : '0.8rem' }}>{text4}</div>}
                </div>
              }
              {(text5 && text6) &&
                <div className="d-flex flex-row">
                  {text5 && <div style={{ fontSize: (size == 'sm') ? '0.75rem' : '0.8rem' }}>{text5}</div>}
                  {text6 && <div className='mx-1 text-muted' style={{ fontSize: (size == 'sm') ? '0.75rem' : '0.8rem' }}>{text6}</div>}
                </div>
              }
              {(text7 && text8) &&
                <div className="d-flex flex-row">
                  {text7 && <div style={{ fontSize: (size == 'sm') ? '0.75rem' : '0.8rem' }}>{text7}</div>}
                  {text8 && <div className='mx-1 text-muted' style={{ fontSize: (size == 'sm') ? '0.75rem' : '0.8rem' }}>{text8}</div>}
                </div>
              }
            </div>
          </div>
        </div>
        {(actions && actions.length > 0) &&
          // style={{flex:1}}
          <div className='d-flex justify-content-between align-items-center'>
            {actions.map((v, i) => {
              if (v.type == 'selection') {
                return <div key={i} className='d-flex flex-row align-items-center me-2 cursor-pointer' style={{ backgroundColor: v.state ? brand_colors[this.props.defaultTheme.theme_id].color4_light : brand_colors[this.props.defaultTheme.theme_id].color7, borderRadius: '1rem', padding: (size == 'sm') ? '0.2rem 0.6rem' : '0.2rem 0.8rem' }} onClick={v.onClick}>
                  <div className='d-flex align-items-center justify-content-center me-2' style={{ backgroundColor: brand_colors[this.props.defaultTheme.theme_id].color8, borderRadius: '100%', height: (size == 'sm') ? '0.9rem' : '1.1rem', width: (size == 'sm') ? '0.9rem' : '1.1rem' }}>
                    {v.state && <FontAwesomeIcon icon={faCheckCircle} color={brand_colors[this.props.defaultTheme.theme_id].color4} style={{ fontSize: (size == 'sm') ? '0.8rem' : '1rem' }} />}
                  </div>
                  <div style={{ color: v.state ? brand_colors[this.props.defaultTheme.theme_id].color4 : brand_colors[this.props.defaultTheme.theme_id].color18, fontWeight: '600', fontSize: (size == 'sm') ? '0.8rem' : '1rem' }}>
                    {v.state ? t('Preview') : t('Preview')}
                  </div>
                </div>
              } else {
                return <div key={i} style={{ margin: (size == 'sm') ? '0 0.4rem' : '0 0.5rem' }} className='cursor-pointer d-flex align-items-center' title={v.name} onClick={v.onClick}>
                  {v.image && <Image src={v.image} style={{ width: (size == 'sm') ? '1rem' : '1.5rem', height: (size == 'sm') ? '1rem' : '1.5rem' }} />}
                  {v.iconName && <FontAwesomeIcon icon={v.iconName} color={v.iconColor} style={{ fontSize: (size == 'sm') ? '0.95rem' : '1.45rem' }} />}
                </div>
              }
            })}
          </div>
        }
      </div>
    </div>);
  }
}

const mapStateToProps = (state) => ({
  authData: state.auth.authData,
  defaultLanguage: state.language.defaultLanguage,
  defaultTheme: state.theme.defaultTheme
});

const mapDispatchToProps = () => ({
});

export default connect(mapStateToProps, mapDispatchToProps())(HorizontalCard);
