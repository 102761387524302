import React, { Component } from 'react';
import { Container, Card, Row, Col, Button, Tab, Tabs, Table, Badge, Modal, InputGroup, Form, Spinner } from 'react-bootstrap';
import 'chart.js/auto'
import { connect } from 'react-redux';
import { t } from '../../../helpers/translation_helper';
import { brand_colors } from '../../../helpers/brand_colors_helper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDashboard, faUsers, faAddressCard, faUserTie, faChevronDown, faBook, faListCheck, faThumbsDown, faThumbsUp, faPlus, faCopy, faLock, faLockOpen } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import { parse } from '@fortawesome/fontawesome-svg-core';
import moment from 'moment';
import { GET, POST } from '../../../api';
import { faCalendarAlt, faStickyNote } from '@fortawesome/free-regular-svg-icons';
import Paginate from '../Paginate/_Paginate';
import { sendCloudMessage } from '../../../helpers/cloud_message';
import { setExamGrades } from '../../../redux/slices/examGradesModalSlice';
import { setExamCopy } from '../../../redux/slices/examCopyModalSlice';
import ExamGradesModal from '../../_common/ExamGradesModal/_ExamGradesModal';
import ExamCopyModal from '../../_common/ExamCopyModal/_ExamCopyModal';
import { changeColorOpacity, DDL_ExamstypegrpTypes } from '../../../helpers/general_helpers';
import AddEditExam from '../../_common/AddEditExam/_AddEditExam';
import { Link } from 'react-router-dom';

// API URL
class GradeBookWidget extends Component {
  constructor(props) {
    super(props);
    this.state = {
      DDL_examstypegrp_types: DDL_ExamstypegrpTypes(),
      data: null,
      limit: (this.props.size == 'sm') ? 5 : 10,
      total_records: 0,
      showAllClassesSubjects: false,
      examstypegrp_type: '',//FOR NEW EXAM
    }
    this.AddEditExam = React.createRef();

  }

  componentDidMount() {
    this.getData();
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.authData.centerData.center_id !== this.props.authData.centerData.center_id) {
      this.getData();
    }
    if (this.props.selectedClass.class.class_id != prevProps.selectedClass.class.class_id) {
      this.getData()
    }
  }

  getData = async (limit = this.state.limit, offset = 0) => {
    this.setState({
      data: null
    });

    const { authData } = this.props;
    const { userData } = authData;


    let classesIDs = this.props.selectedClass.class.class_id
    let acs_IDs = []
    if (this.state.showAllClassesSubjects) {
      classesIDs = authData.classes.map((item) => {
        return item.class.class_id
      })
      authData.classes.map((item) => {
        item.academic_classmain_subject.map((v, i) => {
          acs_IDs.push(v.acs_id)
        })
      })
    } else {
      authData.classes.map((item) => {
        if (item.class.class_id == classesIDs) {
          item.academic_classmain_subject.map((v, i) => {
            acs_IDs.push(v.acs_id)
          })
        }
      })
      classesIDs = [classesIDs]
    }



    if (this.getDataReq) {
      this.getDataReq.abort();
    }
    this.getDataReq = new AbortController();

    let fData = new FormData()
    fData.append('class_ids', JSON.stringify(classesIDs))
    fData.append('acs_ids', JSON.stringify(acs_IDs))
    try {
      const res = await POST('exams/fetch-exams-homework-table', fData, {
        params: {
          search: '',
          order: 'exams.exams_id',
          dir: 'DESC',
          limit,
          offset,
          employee_id: userData.employee_id,
        },
        signal: this.getDataReq.signal,
      });
      console.log('res', res);
      this.setState({
        data: res.data.data, total_records: parseInt(res.data.total_count)
      });
    } catch (err) {
      console.log('err', err)
    }
  }
  handleExamStatus = async (current_exams_status, exams) => {

    if (![1, 12, 19, 15, 22].includes(parseInt(this.props.authData.loginData.role_id)) && exams.exams_grade_lock == 1) {
      toast.warning(t('You are not allowed for this action!'));
      return
    }

    const { auth_key, user_id } = this.props.authData.loginData;
    const { center_id, center_timezone } = this.props.authData.centerData;
    const fData = new FormData();
    fData.append('center_id', center_id);
    fData.append('center_timezone', center_timezone);
    fData.append('user_id', user_id);
    fData.append('exams_id', exams.exams_id);
    fData.append('current_exams_status', current_exams_status);

    if (this.handleExamStatusReq) {
      this.handleExamStatusReq.abort();
    }
    this.handleExamStatusReq = new AbortController();

    const res = await POST('exams/publish-exam', fData, {
      signal: this.handleExamStatusReq.signal,
    });

    if (res.data.type) {
      if (current_exams_status == 0) {
        res.data.data.map((v, i) => {
          sendCloudMessage(v.user_id, v.title, v.body, v.data)
        })
      }
      let data = this.state.data
      data = data.map((v, i) => {
        if (v.exams_id == exams.exams_id) {
          return { ...v, exams_status: res.data.exams_status }
        }
        return v
      })
      toast[res.data.messageType](t(res.data.message));
      this.setState({ data })
    } else {
      toast.error(t(res.data.message));
    }
  }
  handleExamLock = async (exams_id) => {

    if (![1, 12, 19, 15, 22].includes(parseInt(this.props.authData.loginData.role_id))) {
      toast.warning(t('You are not allowed for this action!'));
      return
    }

    const fData = new FormData();
    fData.append('exams_id', exams_id);

    if (this.handleExamLockReq) {
      this.handleExamLockReq.abort();
    }
    this.handleExamLockReq = new AbortController();

    const res = await POST('exams/toggle-lock-exams-grade2', fData, {
      signal: this.handleExamLockReq.signal,
    });

    if (res.data.type) {
      let data = this.state.data
      data = data.map((v, i) => {
        if (v.exams_id == exams_id) {
          return { ...v, exams_grade_lock: res.data.data }
        }
        return v
      })
      toast[res.data.messageType](t(res.data.message));
      this.setState({ data })
    } else {
      toast.error(t(res.data.message));
    }
  }

  render() {
    console.log('GradeBookWidget', this.state);
    let { data, limit, total_records } = this.state
    let { lang_orientation: dir } = this.props.defaultLanguage;
    return (
      <>
        <div className='widget-box' style={{ borderRadius: 20, marginBottom: 20, height: (limit == 10) ? 500 : 315 }}>
          <div className='d-flex flex-row align-items-center justify-content-between' style={{ backgroundColor: brand_colors[this.props.defaultTheme.theme_id].color1, borderRadius: 20, borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}>

            <div style={{}}>
              <div style={{ color: '#fff', paddingInline: 15, fontWeight: 700 }}>{t('Grade Book')}</div>
              <div style={{ paddingInline: 15 }}>
                <Form.Check
                  onChange={() => this.setState({
                    showAllClassesSubjects: this.state.showAllClassesSubjects ? false : true
                  }, () => this.getData())}
                  checked={this.state.showAllClassesSubjects}
                  type="switch"
                  label={(this.state.showAllClassesSubjects) ? t('Showing all Classes and Subjects') : t('Showing Active Class Subjects')}
                  style={{ color: brand_colors[this.props.defaultTheme.theme_id].color8 }}
                />
              </div>
            </div>


            <div className='d-flex flex-row align-items-start' style={{ borderRadius: 10, padding: '0.2rem 0.5rem' }}>
              {/* INPUT RUBRIC */}
              <Link to={'/subject-rubric-input'} style={{ textDecoration: 'none' }}>
                <span className='mx-1 cursor-pointer' style={{ backgroundColor: '#fff', fontSize: 14, fontWeight: 'bold', color: brand_colors[this.props.defaultTheme.theme_id].color1, borderRadius: 10, padding: '0.1rem 0.5rem', textAlign: 'center' }} title={t('Input Rubric')} onClick={() => { }}>
                  <FontAwesomeIcon icon={faStickyNote} className='me-1' />
                  {t('Input Rubric')}
                </span>
              </Link>

              {this.state.DDL_examstypegrp_types.map((v, i) => {
                return <span key={i} className='mx-1 cursor-pointer' style={{ backgroundColor: '#fff', fontSize: 14, fontWeight: 'bold', color: brand_colors[this.props.defaultTheme.theme_id].color1, borderRadius: 10, padding: '0.1rem 0.5rem', textAlign: 'center' }} title={t('New ' + v.label)} onClick={() => this.setState({ examstypegrp_type: v.label }, () => this.AddEditExam.current.handleShowCreateUpdateExamCanvas())}>
                  <FontAwesomeIcon icon={faPlus} className='me-1' />
                  {v.label}
                </span>
              })}
            </div>
          </div>
          <Row>
            <Col md={12}>
              <div className='widget-box-body' style={{ height: (limit == 10) ? 380 : 190 }}>
                {!data &&
                  <div style={{ paddingTop: 50, textAlign: 'center' }}>
                    <Spinner animation="grow" />
                  </div>
                }
                {data?.length == 0 &&
                  <div style={{ paddingTop: 50, textAlign: 'center' }}>
                    {t('No Data Found!')}
                  </div>
                }
                {data?.length > 0 &&
                  <div className='widget-box-list'>
                    {data.map((item, index) => {
                      let examstypegrp_type = this.state.DDL_examstypegrp_types.find((v, i) => v.value == item.examstypegrp_type)

                      return (
                        <div key={index} className={'widget-box-exam-list-item'} style={{ backgroundColor: (parseInt(item.exams_grade_lock) == 1) ? changeColorOpacity(brand_colors[this.props.defaultTheme.theme_id].color11, 0.2) : '' }}>
                          <div style={{ flex: 0.5 }}>
                            <span className='me-2' style={{ fontSize: 14, fontWeight: 'bold', backgroundColor: examstypegrp_type ? changeColorOpacity(examstypegrp_type.color, 0.1) : '#fff', color: (examstypegrp_type) ? examstypegrp_type.color : '#000', borderRadius: 10, padding: '0.1rem 0.5rem', textAlign: 'center' }}>{examstypegrp_type?.label}</span>
                          </div>
                          <div style={{ paddingLeft: 10, paddingRight: 20 }}>
                            <button
                              title={t('Copy')}
                              onClick={() => this.props.setExamCopy({ showModal: true, exam: item })}
                              style={{ border: 0, background: 'none' }}>
                              <FontAwesomeIcon icon={faCopy} color={'#818181'} />
                            </button>
                          </div>
                          <div style={{ flex: 1 }}>
                            <small className='text-muted'>{item.acs_name} | {item.class_theme}</small>
                          </div>
                          <div style={{ flex: 2 }}>
                            <strong className={'mx-1'}>{item.exams_name}</strong>
                          </div>
                          <div style={{ flex: 1 }}>
                            <Badge pill bg="info" className='mx-1'>{item.examstype_name}</Badge>
                          </div>
                          <div style={{ flex: 1 }}>
                            <div className='mx-1'>{moment(item.exams_date).format('DD-MM-YYYY')}</div>
                          </div>
                          <div style={{ flex: 1, textAlign: dir == 1 ? 'left' : 'right' }}>

                            <button
                              title={(parseInt(item.exams_grade_lock) == 0) ? t('Lock Now') : t('Un-Lock Now')}
                              onClick={() => this.handleExamLock(item.exams_id)}
                              style={{ border: 0, background: 'none' }}>
                              <FontAwesomeIcon icon={(item.exams_grade_lock == 1) ? faLock : faLockOpen} color={(item.exams_grade_lock == 1) ? brand_colors[this.props.defaultTheme.theme_id].color11 : brand_colors[this.props.defaultTheme.theme_id].color4} className='cursor-pointer' />
                            </button>

                            <button
                              title={(item.exams_status == 0) ? t('Publish Now') : t('Un-Publish Now')}
                              onClick={() => this.handleExamStatus(item.exams_status, item)}
                              style={{ border: 0, background: 'none' }}>
                              <FontAwesomeIcon icon={item.exams_status == 0 ? faThumbsUp : faThumbsDown} color={item.exams_status == 0 ? brand_colors[this.props.defaultTheme.theme_id].color13 : brand_colors[this.props.defaultTheme.theme_id].color11} />
                            </button>
                            {(item.exams_status == 0)
                              ? <div className='badge badge-outline-danger ms-2'>{'Not Published'}</div>
                              : <div className='badge badge-outline-success ms-2'>{'Published'}</div>
                            }

                            {item.exams_status == 1 &&
                              <button
                                title={t('Grading')}
                                onClick={() => this.props.setExamGrades({ showModal: true, exam: item })}
                                style={{ border: 0, background: 'none' }}>
                                <FontAwesomeIcon icon={faListCheck} color={'#000000'} />
                              </button>
                            }
                          </div>
                        </div>
                      )
                    }
                    )}
                  </div>
                }
              </div>
              <Paginate
                limit={limit}
                totalRecords={total_records}
                pageChange={this.getData}
              />
            </Col>
          </Row>
        </div>
        <ExamGradesModal />
        <ExamCopyModal callBack={() => this.getData()} />
        <AddEditExam ref={this.AddEditExam} authData={this.props.authData} examstypegrp_type={this.state.examstypegrp_type}
          selectedClass={this.props.selectedClass}
          selectedSubject={this.props.selectedSubject}
          callBack={() => this.getData()} defaultTheme={this.props.defaultTheme} />
      </>
    );
  }
}


const mapStateToProps = (state) => ({
  authData: state.auth.authData,
  defaultLanguage: state.language.defaultLanguage,
  defaultTheme: state.theme.defaultTheme,
  selectedClass: state.selectedClass.data,
  selectedSubject: state.selectedClass.subject,
});
const mapDispatchToProps = () => ({
  setExamGrades,
  setExamCopy
});

export default connect(mapStateToProps, mapDispatchToProps())(GradeBookWidget);