
// ALL ICONS LIST 
// https://react-icons.github.io/react-icons/
import * as aiIcons from "react-icons/ai"
import * as fa6Icons from "react-icons/fa6";
import * as piIcons from "react-icons/pi";
import * as mdIcons from "react-icons/md";
import * as tfiIcons from "react-icons/tfi";
import * as hiIcons from "react-icons/hi";
import * as siIcons from "react-icons/si";
import * as giIcons from "react-icons/gi"
import * as tbIcons from "react-icons/tb"

// import * as hi2Icons from "react-icons/hi2";
// import * as bsIcons from "react-icons/bs"
// import * as biIcons from "react-icons/bi"
// import * as faIcons from "react-icons/fa"
// import * as ciIcons from "react-icons/ci"
// import *  as cgIcons from "react-icons/cg";
// import * as diIcons from "react-icons/di";
// import * as fiIcons from "react-icons/fi";
// import * as fcIcons from "react-icons/fc";
// import * as goIcons from "react-icons/go";
// import * as grIcons from "react-icons/gr";
// import * as imIcons from "react-icons/im";
// import * as liaIcons from "react-icons/lia";
// import * as ioIcons from "react-icons/io";
// import * as io5Icons from "react-icons/io5";
// import * as luIcons from "react-icons/lu";
// import * as rxIcons from "react-icons/rx";
// import * as riIcons from "react-icons/ri";
// import * as slIcons from "react-icons/sl";
// import * as tiIcons from "react-icons/ti";
// import * as vscIcons from "react-icons/vsc";
// import * as wiIcons from "react-icons/wi";
// import { IconType } from "react-icons"

const GetIcon = ({ icon, className, style }) => {
    const getIcon = (iconName) => {
        const iconsMap = new Map()
        iconsMap.set("Fa", fa6Icons)
        iconsMap.set("Hi", hiIcons)
        iconsMap.set("Ai", aiIcons)
        iconsMap.set("Gi", giIcons)
        iconsMap.set("Tb", tbIcons)
        iconsMap.set("Md", mdIcons)
        iconsMap.set("Pi", piIcons)
        iconsMap.set("Si", siIcons)
        iconsMap.set("Tfi", tfiIcons)

        // iconsMap.set("Rx", rxIcons)
        // iconsMap.set("Fc", fcIcons)
        // iconsMap.set("Lu", luIcons)
        // iconsMap.set("Fa", faIcons)
        // iconsMap.set("Hi", hi2Icons)
        // iconsMap.set("Io", ioIcons)
        // iconsMap.set("Io", io5Icons)
        // iconsMap.set("Bs", bsIcons)
        // iconsMap.set("Bi", biIcons)
        // iconsMap.set("Ci", ciIcons)
        // iconsMap.set("Cg", cgIcons)
        // iconsMap.set("Di", diIcons)
        // iconsMap.set("Fi", fiIcons)
        // iconsMap.set("Go", goIcons)
        // iconsMap.set("Gr", grIcons)
        // iconsMap.set("Im", imIcons)
        // iconsMap.set("Lia", liaIcons)
        // iconsMap.set("Ri", riIcons)
        // iconsMap.set("Sl", slIcons)
        // iconsMap.set("Ti", tiIcons)
        // iconsMap.set("Vsc", vscIcons)
        // iconsMap.set("Wi", wiIcons)

        let iconNameArr = iconName.split(/(?=[A-Z])/)
        return iconsMap.get(iconNameArr[0])
    }

    if (icon) {
        const icons = getIcon(icon)
        const TheIcon = icons[icon]
        return (TheIcon) ? <TheIcon className={className} style={style} /> : <aiIcons.AiOutlineQuestion className={className} style={style} />
    } else {
        return <aiIcons.AiOutlineQuestion className={className} style={style} />
    }
}

export default GetIcon