import React, { Component } from 'react';
import { Container, Card, Row, Col } from 'react-bootstrap';
import { t } from '../../helpers/translation_helper';
import NavBar from '../_partials/NavBar/_NavBar';
import Header from '../_partials/Header/_Header';
import { connect } from 'react-redux';
import { faPercent } from '@fortawesome/free-solid-svg-icons';
import { brand_colors } from '../../helpers/brand_colors_helper';
import { NavLink } from 'react-router-dom';
import { changeColorOpacity, ifNavAllowed } from '../../helpers/general_helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAddressCard, faObjectGroup, faStarHalfStroke } from '@fortawesome/free-regular-svg-icons';
import ExamGroups from './ExamGroups';
import ExamTypes from './ExamTypes';
import Gradeslevel from './Gradeslevel';
import TermAgegroupAssessment from './TermAgegroupAssessment';


class ExamGroupsTypes extends Component {

  state = {
    activeTab: '',
    nav: [
      {
        id: 'exam-groups-types-1',
        name: 'Groups',
        icon: faObjectGroup,
        element: <ExamGroups />
      },
      {
        id: 'exam-groups-types-2',
        name: 'Types',
        icon: faObjectGroup,
        element: <ExamTypes />
      },
      {
        id: 'exam-groups-types-4',
        name: 'Marks Percentage',
        icon: faPercent,
        element: <TermAgegroupAssessment />
      },
      {
        id: 'exam-groups-types-3',
        name: 'Marks Definition',
        icon: faStarHalfStroke,
        element: <Gradeslevel />
      },
    ],
    // elements: [
    //     {
    //         id: 'registrations-list',
    //         linkTo: '/registrations-list',
    //         linkToStates: '',
    //         cardImage: require('../../assets/images/setup/curriculumbuilder.png'),
    //         name: t('Report Attendance'),
    //         parent: 'registrations-list'
    //     },
    // ]
  }


  render() {

    const { lang_orientation: dir } = this.props.defaultLanguage;

    return (
      <Container fluid>
        <div id='iedu-layout'>
          <NavBar />
          <div id='page-content'>
            <Header lite={true} heading={t('Assessment Groups & Types')} backBtn={true} showCenterSelector={true} />
            <div style={{ display: 'flex' }}>
              <div className='inner-blue-bar'
                style={{
                  backgroundColor: changeColorOpacity(brand_colors[this.props.defaultTheme.theme_id].sidebar_bg, 0.15),
                  marginRight: dir == 1 ? 0 : 7,
                  marginLeft: dir == 0 ? 0 : 7,
                }}>
                <ul>
                  {this.state.nav.map((item, index) => (
                    <li key={index}>
                      <button
                        style={{
                          backgroundColor: item.id === this.state.activeTab.id ? changeColorOpacity(brand_colors[this.props.defaultTheme.theme_id].sidebar_bg, 0.5) : 'transparent'
                        }}
                        onClick={() => this.setState({ activeTab: item })}>
                        <FontAwesomeIcon
                          icon={item.icon}
                          color={item.id === this.state.activeTab.id ? brand_colors[this.props.defaultTheme.theme_id].color8 : changeColorOpacity(brand_colors[this.props.defaultTheme.theme_id].sidebar_bg, 0.7)}
                        />
                        <div style={{
                          color: item.id === this.state.activeTab.id ? brand_colors[this.props.defaultTheme.theme_id].color8 : brand_colors[this.props.defaultTheme.theme_id].sidebar_bg
                        }}>{t(item.name)}</div>
                      </button>
                    </li>
                  )
                  )}
                </ul>
              </div>
              <div style={{
                flex: 1,
                height: 'calc(100vh - 113px)',
                overflowY: 'auto',
                overflowX: 'hidden',
                paddingBottom: 10,
              }}>
                {this.state.activeTab.element}
              </div>
            </div>
          </div>
        </div>
      </Container >
    );
  }
}

const mapStateToProps = (state) => ({
  authData: state.auth.authData,
  defaultLanguage: state.language.defaultLanguage,
  defaultTheme: state.theme.defaultTheme
});

export default connect(mapStateToProps, null)(ExamGroupsTypes);
