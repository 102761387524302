import React, { Component } from 'react';
import { Badge, Button, Col, Modal, Row, Spinner, Table } from "react-bootstrap";
import { t } from '../../helpers/translation_helper';
import moment from 'moment';
import { GET, POST } from '../../api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faCheckCircle, faSearch, faUser, faXmark, faLayerGroup, faHandsHoldingChild, faPeopleGroup, faListCheck, faPrint, faPencil, faTrash, faCheckDouble, faSortDown, faSortUp, faThumbsUp, faThumbsDown, faL, faChevronUp, faChevronDown, faTimes } from '@fortawesome/free-solid-svg-icons';
import { faCalendar, faEye, faEyeSlash, faFileExcel, faFilePdf } from '@fortawesome/free-regular-svg-icons';
import { toast } from 'react-toastify';
import { renderTableBody, renderTableHead } from '../../helpers/table_helper';

class SessionsModal extends Component {
  state = {
    show: false,
    data: null,
    cdt: {
      fetching: false,
      search: '',
      rows: -1,
      offset: 0,
      order: 'sessions.session_id',
      dir: 'DESC',
      lastPage: 0,
      page: 0,
      totalRecords: 0,
      rowsOptions: [10, 25, 50, 'All'],
      columns: [
        {
          id: 'sessions.session_id',
          column: 'session_id',
          name: 'Actions',
          visibility: true
        },
        {
          id: 'sessions.session_name',
          column: 'session_name',
          name: 'Name',
          visibility: true
        },
        {
          id: 'sessioncat.sessioncat_name',
          column: 'sessioncat_name',
          name: 'Category Name',
          visibility: true
        },
        {
          id: 'sessions.session_day_number',
          column: 'session_day_number',
          name: 'Days Count',
          visibility: true
        },
        {
          id: 'sessions.session_child_count',
          column: 'session_child_count',
          name: 'Students Count',
          visibility: true
        },
        {
          id: 'sessions.session_mon',
          column: 'session_mon',
          name: 'Monday',
          visibility: true
        },
        {
          id: 'sessions.session_tue',
          column: 'session_tue',
          name: 'Tuesday',
          visibility: true
        },
        {
          id: 'sessions.session_wed',
          column: 'session_wed',
          name: 'Wednesday',
          visibility: true
        },
        {
          id: 'sessions.session_thu',
          column: 'session_thu',
          name: 'Thursday',
          visibility: true
        },
        {
          id: 'sessions.session_fri',
          column: 'session_fri',
          name: 'Friday',
          visibility: true
        },
        {
          id: 'sessions.session_sat',
          column: 'session_sat',
          name: 'Saturday',
          visibility: true
        },
        {
          id: 'sessions.session_sun',
          column: 'session_sun',
          name: 'Sunday',
          visibility: true
        },
      ],
      data: [],
    },
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.data && prevState.data?.timesch_id !== this.state.data?.timesch_id) {
      const {
        search,
        rows: limit,
        order,
        dir
      } = this.state.cdt;
      this.fetch({
        search, limit, order, dir, page: 0
      });
    }
  }

  handleOpen = (data) => {
    this.setState({
      show: true,
      data
    });
  }

  handleClose = () => {
    this.setState({
      show: false,
      data: null
    });
  }

  fetch = async (params) => {

    this.setState({
      cdt: {
        ...this.state.cdt,
        fetching: true,
      }
    });

    try {
      const res = await GET('timeschedule/tabledata-sessions', {
        params: {
          timesch_id: this.state.data.timesch_id,
          search: params.search,
          order: params.order,
          dir: params.dir,
          limit: params.limit,
          offset: parseInt(params.page) * parseInt(params.limit)
        }
      });

      this.setState({
        cdt: {
          ...this.state.cdt,
          fetching: false,
          search: params.search,
          rows: parseInt(params.limit),
          offset: parseInt(params.page) * parseInt(params.limit),
          order: params.order,
          dir: params.dir,
          page: params.page,
          totalRecords: parseInt(res.data.total_count_filtered),
          lastPage: Math.ceil(parseInt(res.data.total_count_filtered) / parseInt(params.limit)),
          data: res.data.data,
        }
      });
    } catch (err) {
      console.log(err);
      this.setState({
        cdt: {
          ...this.state.cdt,
          fetching: false,
        }
      });
    }
  }

  sessionActivate = async (session_id, session_status) => {

    let data = this.state.cdt.data;

    const findIndex = data.findIndex(item => item.session_id == session_id);

    data[findIndex].session_status = session_status;

    this.setState({
      cdt: {
        ...this.state.cdt,
        data
      }
    });

    const fData = new FormData();
    fData.append('session_id', session_id);
    fData.append('session_status', session_status);

    try {
      const res = await POST('timeschedule/session-status', fData);
      toast.success(t(res.data.status));
    } catch (err) {
      console.log('err', err)
      toast.error(t('Something went wrong!'));

    }

  }

  renderCell = (column_data, column_id, column_index, dataObj) => {

    let tdData;

    // --------------------------------------------
    if (column_id == 'session_id') { // Action Buttons
      tdData = <>
        <Button
          onClick={() => this.sessionActivate(column_data, (dataObj.session_status == 0 ? 1 : 0))}
          variant={dataObj.session_status == 1 ? 'success' : 'danger'} size='sm'>
          <FontAwesomeIcon icon={dataObj.session_status == 1 ? faThumbsUp : faThumbsDown} />
        </Button>
      </>;
      // ------------------------------------------------------------------------
    } else {
      tdData = column_data;
    }

    return <td key={column_index}>{tdData}</td>;
  }

  render() {
    const { cdt } = this.state
    return (<Modal
      backdrop="static"
      keyboard={false}
      size='xl'
      show={this.state.show}
      onHide={this.handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{this.state.data && moment(this.state.data.timesch_start_time, 'HH:mm A').format('hh:mm A')} - {this.state.data && moment(this.state.data.timesch_end_time, 'HH:mm A').format('hh:mm A')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {this.state.cdt.fetching &&
          <Row>
            <Col md={12} className='py-5 d-flex flex-column align-items-center'>
              <Spinner as='span' animation='grow' size='sm' /> {t('Loading Data')}
            </Col>
          </Row>
        }
        {!this.state.cdt.fetching && this.state.cdt.data.length === 0 &&
          <Row>
            <Col md={12} className='py-5 d-flex flex-column align-items-center'>
              {t('No Data Found')}
            </Col>
          </Row>
        }
        {!this.state.cdt.fetching && this.state.cdt.data.length !== 0 &&
          <div id="datatable-list" className='mb-3' style={{ width: 'initial' }}>
            <Table responsive hover>
              {renderTableHead(this)}
              {renderTableBody(this)}
            </Table>
          </div>
        }
      </Modal.Body>
    </Modal >);
  }
}



export default SessionsModal;