/**
 * Children Slice
 */
import { createSlice } from '@reduxjs/toolkit';

export const childrenSlice = createSlice({
  name: 'children',
  initialState: {
    list: [],
  },
  reducers: {
    setChildren: (state, action) => {
      state.list = action.payload;
    },
  },
});

export const { setChildren } = childrenSlice.actions;
export default childrenSlice.reducer;
