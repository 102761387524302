import React, { Component } from 'react';
import { Container, Card, Row, Col, ButtonGroup, Button, DropdownButton, Dropdown, Table, Form, Spinner, Modal, Tabs, Tab, Badge, Offcanvas, ToggleButton, Image, Alert } from 'react-bootstrap';
import { t } from '../../helpers/translation_helper';
import NavBar from '../_partials/NavBar/_NavBar';
import Header from '../_partials/Header/_Header';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faCheckCircle, faSearch, faUser, faXmark, faLayerGroup, faHandsHoldingChild, faPeopleGroup, faListCheck, faPrint, faBus, faInfoCircle, faUserShield, faChartArea, faChartPie, faEllipsis, faAngleRight, faAngleLeft, faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { NavLink } from 'react-router-dom';
import { changeColorOpacity, generateQRCode, getUrlParam, ifNavAllowed, isTeacher } from '../../helpers/general_helpers';
import { getStudents } from '../../redux/slices/studentListSlice';
import ReactPaginate from 'react-paginate';
import Pagination from '../_partials/Pagination';
import { brand_colors } from '../../helpers/brand_colors_helper';
import moment from 'moment';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import { GET, POST } from '../../api';
import { toast } from 'react-toastify';
import { DateRangePicker } from 'react-date-range';
import Flatpickr from 'react-flatpickr';
import { Fragment } from 'react';
import { faEdit, faSave, faTrashCan } from '@fortawesome/free-regular-svg-icons';
import SweetAlert from 'react-bootstrap-sweetalert';
import { toggleRegistrationAdvanceModal } from '../../redux/slices/addEditRegistrationAdvanceModalSlice';
import AddEditRegistrationAdvance from '../_common/AddEditRegistrationAdvance/_AddEditRegistrationAdvance';


var Chance = require('chance');
var chance = new Chance();

const portalURL = process.env.REACT_APP_PORTAL_URL;

class StudentRegistrationAdvance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstLoad: true,
      deleteModal: { show: false, id: 0, action: () => { } },
      searchExpanded: false,
      cdt: {
        order: { column: 'child_id', type: 'DESC' },
        rows: [10, 25, 50, 'All'],
      },
      showRegistration: [],
      showAllClasses: [1, 2, 8, 12, 22].includes(props.authData.loginData.role_id) ? true : false,
    }
    this.searchFieldRef = React.createRef();
  }


  componentDidMount() {
    this.getStudents({
      rows: this.props.studentListRows,
      page: this.props.studentListPage,
      search: this.props.studentListSearch,
      status: true,
    })
  }

  componentDidUpdate(prevProps, prevState) {
    if ((prevProps.authData.centerData.center_id !== this.props.authData.centerData.center_id) || (this.props.selectedClass && prevProps.selectedClass.class.class_id !== this.props.selectedClass.class.class_id)) {
      this.getStudents({
        rows: this.props.studentListRows,
        page: 0,
        search: '',
        status: true
      });
      this.setState({ searchExpanded: false });
      this.searchFieldRef.current.value = '';
    }

    if (prevProps.studentListFetching != this.props.studentListFetching && !this.props.studentListFetching && this.state.firstLoad) {
      this.setState({ firstLoad: false }, () => {
        this.addRegistrationFromURL()
      })
    }
  }

  getStudents = (params) => {
    this.props.getStudents({
      child_status: params.status,
      search: params.search,
      limit: params.rows,
      page: params.page,
      registrations: true,
      showAllClasses: this.state.showAllClasses,
    });
  }

  addRegistrationFromURL = () => {
    let getUrlParamData = getUrlParam()
    console.log('getUrlParamData.id', getUrlParamData.id);
    if (getUrlParamData.id) {
      let student = this.props.studentListData.find((v) => v.child_id == getUrlParamData.id)
      console.log('student.id', this.props.studentListData, student);
      if (student) {
        let weekend_days = this.props.authData.centerData.weekend_days
        if (weekend_days) {
          weekend_days = weekend_days.split(',')
        }
        this.props.toggleRegistrationAdvanceModal({ title: 'New Registration', child: { label: student.child_name, value: student.child_id, ...student }, registrations: [], todayDate: moment().format('YYYY-MM-DD'), addRegCount: 1, addservices: [] })
      }
    }
  }

  printRegistration = async (registration) => {

    if (this.editRegistrationReq) {
      this.editRegistrationReq.abort();
    }
    this.editRegistrationReq = new AbortController();
    const res = await GET('registration-advance/edit-registration', {
      params: {
        child_id: registration.child_id,
        reg_num: registration.reg_num,
        fee_type: 1,
      },
      signal: this.editRegistrationReq.signal,
    });

    if (res) {
      let rowdata = res.data
      console.log('rowdata', rowdata);
      let centerData = this.props.authData.centerData
      let generateQRCodeData = generateQRCode(
        centerData.center_name,
        centerData.vat_num,
        moment(rowdata.addservicesheaderData.addservicesheader_date).format(),
        (parseFloat(rowdata.addservicesheaderData.addservicesheader_amount) + parseFloat(rowdata.addservicesheaderData.addservicesheader_vat)
        ).toFixed(2),
        parseFloat(rowdata.addservicesheaderData.addservicesheader_vat).toFixed(2)
      );


      let URL = process.env.REACT_APP_API_URL + "registration-advance/print-registrationadvance?" +
        "auth_key=" + this.props.authData.authKey +
        "&reg_num=" + rowdata.addservicesheaderData.addservices_num +
        "&fee_type=" + rowdata.addservicesheaderData.addservices_type +
        "&addservicesheader_date=" + rowdata.addservicesheaderData.addservicesheader_date +
        "&reg_date=" + registration.reg_date +
        "&qr=" + generateQRCodeData +
        "&center_id=" + registration.center_id +
        "&user_id=" + this.props.authData.loginData.user_id

      window.open(
        URL,
        "PDF" + "_" + rowdata.addservicesheaderData.addservices_num + "_" + rowdata.addservicesheaderData.addservices_type
      );
    }
  }

  deleteRegistration = (registration, index = null) => {
    this.setState({
      deleteModal: {
        ...this.state.deleteModal, show: true, id: registration, action: async () => {
          if (this.deleteRegistrationReq) {
            this.deleteRegistrationReq.abort()
          }

          this.deleteRegistrationReq = new AbortController();
          let fData = new FormData()
          fData.append('reg_id', registration.reg_id)
          fData.append('child_id', registration.child_id)
          fData.append('reg_num', registration.reg_num)
          let res = await POST('registration-advance/delete-registration', fData, {
            signal: this.deleteRegistrationReq.signal
          })
          console.log('res', res);
          if (res) {
            let resData = res.data
            if (resData.type) {
              let registrations = this.state.registrations
              if (index != null) {
                registrations.splice(index, 1)
              }
              console.log('registrations', registrations, index);
              this.setState({
                registrations,
                deleteModal: {
                  ...this.state.deleteModal, show: false, id: null,
                }
              }, () => {
                this.getStudents({
                  rows: this.props.studentListRows,
                  page: this.props.studentListPage,
                  search: this.props.studentListSearch,
                  status: true,
                })
              })
            }
            toast[resData.messageType](resData.message)
          }
        }
      }
    })
  }

  editRegistration = async (student, registration) => {
    console.log('student, registration', student, registration);
    if (this.editRegistrationReq) {
      this.editRegistrationReq.abort();
    }
    this.editRegistrationReq = new AbortController();
    const res = await GET('registration-advance/edit-registration', {
      params: {
        child_id: registration.child_id,
        reg_num: registration.reg_num,
        fee_type: 1,
      },
      signal: this.editRegistrationReq.signal,
    });

    if (res) {
      let resData = res.data
      console.log('resData', resData);

      let registrations = resData.registrationData
      let addservices = resData.addservicesData
      let addservicesheaderData = resData.addservicesheaderData
      let todayDate = addservicesheaderData.addservicesheader_date
      if (registrations.length > 0) {
        registrations = registrations.map((v) => {
          let discount_amount = 0
          let term_amount_modified = false
          if (parseFloat(v.session_fee) != parseFloat(v.term_amount)) {
            term_amount_modified = true;
            if (parseFloat(v.discount_rate) > 0) {
              discount_amount = (parseFloat(v.session_fee) * parseFloat(v.discount_rate)) / 100
              let checkmodified = parseFloat(v.session_fee) - discount_amount;
              if (parseFloat(checkmodified).toFixed(2) == parseFloat(v.term_amount).toFixed(2)) {
                term_amount_modified = false;
              }
            }
          }
          let term_amount_text = "None";
          let term_fee_value = "";
          let term_fee_type = "none";
          if (v.prorate_amount != null && v.prorate_amount != "") {
            term_amount_text = "Prorated @ " + parseFloat(v.prorate_amount).toFixed(2) + " per day";
            term_fee_value = v.prorate_amount;
            term_fee_type = "prorated";
          } else if (v.policy_discount != null) {
            term_amount_text = "Policy discount @ " + v.policy_discount + "%";
            term_fee_value = v.policy_discount;
            term_fee_type = "policy";
          }

          let vat_amount = (parseFloat(v.term_amount) * parseFloat(v.reg_vat)) / 100;
          let total = (parseFloat(v.term_amount) + parseFloat(vat_amount)).toFixed(2);
          return {
            ...v,
            term_id: { value: v.term_id, label: v.term_name, term_id: v.term_id, term_name: v.term_name, term_from: v.term_from, term_to: v.term_to, term_active: v.term_active, term_fee_type },
            discount_id: (v.discount_id) ? { value: v.discount_id, label: v.discount_name, discount_id: v.discount_id, discount_name: v.discount_name, discount_rate: v.discount_rate } : null,
            term_amount_text,
            term_fee_value,
            term_amount_modified,
            discount_amount: discount_amount,
            vat_amount: vat_amount,
            total: total,
            child: v.child_id
          }
        })

        addservices = addservices.map((v, i) => {
          let addservices_discount_amount = 0
          let addservices_fee_modified = false
          if (parseFloat(v.services_fee) != parseFloat(v.addservices_fee)) {
            addservices_fee_modified = true;
            if (parseFloat(v.addservices_discount) > 0) {
              addservices_discount_amount = (parseFloat(v.services_fee) * parseFloat(v.addservices_discount)) / 100
              let checkmodified = parseFloat(v.services_fee) - addservices_discount_amount;
              if (parseFloat(checkmodified).toFixed(2) == parseFloat(v.addservices_fee).toFixed(2)) {
                addservices_fee_modified = false;
              }
            }
          }
          let addservices_vat_amount = (parseFloat(v.addservices_fee) * parseFloat(v.addservices_vat)) / 100;
          let total = (parseFloat(v.addservices_fee) + parseFloat(addservices_vat_amount)).toFixed(2);

          return {
            ...v,
            services_id: { value: v.services_id, label: v.services_name, services_id: v.services_id, services_name: v.services_name, services_vat: v.services_vat, services_fee: v.services_fee, services_cost: v.services_cost, acc_num: v.acc_num, chart_id: v.chart_id, chapt_id: v.chapt_id, services_type: v.services_type },
            addservices_fee: v.addservices_fee,
            addservices_fee_modified: addservices_fee_modified,
            addservices_discount_amount: addservices_discount_amount,
            addservices_vat_amount: addservices_vat_amount,
            total: total,
            service_acc_num: v.acc_num,
            services_cost: v.services_cost,
            service_chapt_id: v.chapt_id,
            service_name: v.service_name,
            services_type: v.services_type,
          }
        })

        let this_registration = registrations[0]

        this.props.toggleRegistrationAdvanceModal({
          title: 'Update Registration',
          child: { label: student.child_name, value: student.child_id, ...student },
          registrations: registrations,
          todayDate: todayDate,
          addRegCount: 1,
          addservices: addservices,
          selectedClass: { value: this_registration.class_id, label: this_registration.class_theme, class_id: this_registration.class_id, class_theme: this_registration.class_theme, classmain_id: this_registration.classmain_id },
          selectedSession: { value: this_registration.session_id, label: this_registration.session_name, session_id: this_registration.session_id, session_name: this_registration.session_name, session_day_number: this_registration.session_day_number },
          selectedSessionCat: { value: this_registration.sessioncat_id, label: this_registration.sessioncat_name, sessioncat_id: this_registration.sessioncat_id, sessioncat_name: this_registration.sessioncat_name, vat_rate: this_registration.vat_rate, expats_only: this_registration.expats_only },
          editMode: { reg_num: addservicesheaderData.addservices_num, fee_type: addservicesheaderData.addservices_type, addservicesheader_id: addservicesheaderData.addservicesheader_id }
        })


      } else {
        // NO REG FOUND
        // {
        //     reg_id: 0,
        //     center_id: this.props.authData.loginData.center_id,
        //     child: (modalRegistration.child) ? modalRegistration.child.child_id : null,
        //     class_id: (this.state.getClassesData.selected) ? this.state.getClassesData.selected.class_id : null,
        //     session_id: (this.state.getSessionsData.selected) ? this.state.getSessionsData.selected.session_id : null,
        //     sessioncat_id: (this.state.getSessioncatData.selected) ? this.state.getSessioncatData.selected.sessioncat_id : null,
        //     term_id: { label: v.term_name, value: v.term_id, ...v },
        //     discount_id: null,
        //     discount_rate: 0.00,
        //     discount_amount: 0.00,
        //     term_amount: 0.00,
        //     term_amount_modified: false,
        //     session_fee: 0.00,
        //     reg_vat: 0.00,
        //     vat_amount: 0.00,
        //     total: 0.00,
        //     reg_type: 0,
        //     is_earned: (v.term_active == 1) ? 1 : 0,
        //     centerlic_id: 0,
        //     reg_year: 0,
        //     acc_num: null,
        //     reg_notes: '',
        //     term_fee_type: null,
        //     term_fee_value: null,
        //     session_mon: weekend_days.includes('monday') ? false : true,
        //     session_tue: weekend_days.includes('tuesday') ? false : true,
        //     session_wed: weekend_days.includes('wednesday') ? false : true,
        //     session_thu: weekend_days.includes('thursday') ? false : true,
        //     session_fri: weekend_days.includes('friday') ? false : true,
        //     session_sat: weekend_days.includes('saturday') ? false : true,
        //     session_sun: weekend_days.includes('sunday') ? false : true,
        //     reg_num: 0,
        //     date_from: v.term_from,
        //     date_to: v.term_to,
        // }
      }
    }
  }

  render() {
    let { showRegistration, showAllClasses } = this.state
    const dir = this.props.defaultLanguage.lang_orientation
    let weekend_days = this.props.authData.centerData.weekend_days
    let defaultTheme = this.props.defaultTheme
    if (weekend_days) {
      weekend_days = weekend_days.split(',')
    }
    console.log('this.state', this.state, this.props);
    return (
      <>
        <Card className='border-0' style={{ borderRadius: '20px' }}>
          <Card.Body>
            <Row>
              <Col md={6} className='d-flex flex-column'>
                <div className='d-flex flex-row align-items-center mb-2'>
                  <strong>{t("Registration Advance")}</strong>
                  <div className='expandable-search ms-2' style={{ width: this.state.searchExpanded ? 200 : 35 }}>
                    <input
                      ref={this.searchFieldRef}
                      defaultValue={this.props.studentListSearch}
                      onChange={(e) => this.getStudents({
                        rows: this.props.studentListRows,
                        page: 0,
                        search: e.target.value,
                        status: true,
                      })}
                      type='text'
                      style={{ width: this.state.searchExpanded ? 165 : 0, paddingLeft: this.state.searchExpanded ? 15 : 0, opacity: this.state.searchExpanded ? 1 : 0 }}
                      placeholder={t('Search Student...')} />
                    <button
                      data-tooltip-id="tooltip"
                      data-tooltip-content={t('Search Student')}
                      data-tooltip-place="top"
                      onClick={() => {
                        this.setState({ searchExpanded: !this.state.searchExpanded });
                        this.props.studentListSearch != '' && this.state.searchExpanded && this.getStudents({
                          rows: this.props.studentListRows,
                          page: 0,
                          search: '',
                          status: true,
                        });
                        this.searchFieldRef.current.value = '';
                        !this.state.searchExpanded && this.searchFieldRef.current.focus();
                      }}>
                      <FontAwesomeIcon
                        icon={this.state.searchExpanded ? faXmark : faSearch}
                        color={brand_colors[defaultTheme.theme_id].color18}
                      />
                    </button>
                  </div>
                </div>
                <div className='d-flex flex-row align-items-center'>
                  <div>
                    {t('Show')}
                    <Form.Select
                      value={this.props.studentListRows}
                      size="sm"
                      onChange={(e) => this.getStudents({
                        rows: e.target.value,
                        page: 0,
                        search: this.props.studentListSearch,
                        status: true,
                      })}
                      style={{ width: 70, marginInline: 5, display: 'inline-block' }}>
                      {this.state.cdt.rows.map((row, row_index) => (<option key={row_index} >{row}</option>))}
                    </Form.Select>
                    {t('Records')}
                  </div>
                </div>
              </Col>
              <Col md={6} className='d-flex justify-content-end align-items-start'>
                {[1, 2, 8, 12, 22].includes(this.props.authData.loginData.role_id) &&
                  <Button onClick={() => this.setState({ showAllClasses: !showAllClasses }, () => {
                    this.getStudents({
                      rows: this.props.studentListRows,
                      status: this.props.studentListStatus,
                      order: this.props.studentListOrder,
                      dir: this.props.studentListDir,
                      page: 0,
                      search: this.props.studentListSearch
                    })
                  })} variant={(showAllClasses) ? "success" : "outline-success"} size='sm' className='me-3' title={(showAllClasses) ? t('Show Selected Class only') : t('Show All Classes')}>
                    {showAllClasses && <FontAwesomeIcon icon={faCheckCircle} color={brand_colors[this.props.defaultTheme.theme_id].color8} className='me-1' />}
                    {(showAllClasses) ? t('Showing All Classes') : t('Showing Selected Class')}
                  </Button>
                }
                <Button variant='warning' size='sm' onClick={() => {
                  this.props.toggleRegistrationAdvanceModal()
                }}>
                  <FontAwesomeIcon icon={faPlus} color={brand_colors[defaultTheme.theme_id].color8} />
                </Button>
              </Col>
            </Row>
            <Row>
              {this.props.studentListFetching &&
                <Col md={12} className='py-5 d-flex flex-column align-items-center'>
                  <Spinner as='span' animation='grow' size='sm' /> {t('Loading Students')}
                </Col>
              }
              {!this.props.studentListFetching &&
                <Col md={12} className='pt-1 student-attendance-list'>
                  {this.props.studentListData.map((student, student_index) => {
                    if (!student) {
                      return null
                    }
                    let showRegistrationFind = showRegistration.findIndex((v) => v == student.child_id)
                    return <Row key={student_index}
                      className='student-attendance-list-item'>
                      <Col md={3} className='d-flex align-items-start'>
                        <div className='d-flex align-items-center'>
                          <div style={{
                            backgroundImage: `url(${(student.picture) ? portalURL + student.center_id + '/children/' + student.picture : 'https://via.placeholder.com/150x150?text=' + student.child_name[0].toUpperCase()})`,
                            width: 50,
                            height: 50,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center center',
                            borderRadius: 10,
                            marginRight: 15
                          }}></div>
                          {student.child_name}
                        </div>
                      </Col>
                      <Col>
                        <div >
                          <Card className='p-0' border="light">
                            <Card.Header style={{ backgroundColor: changeColorOpacity(brand_colors[defaultTheme.theme_id].color4, 0.1), borderBottom: 0 }}>
                              <div className='d-flex flex-row justify-content-between align-items-center'>

                                <div className='d-flex flex-row align-items-center'>
                                  <strong>{t('Total Registrations')}:</strong>
                                  <div className='ms-1'>{student.registrations && student.registrations.length}</div>
                                </div>
                                <div className='d-flex flex-row align-items-center'>
                                  <div className='d-flex flex-row align-items-center me-2 cursor-pointer' style={{ backgroundColor: (showRegistrationFind !== -1) ? changeColorOpacity(brand_colors[defaultTheme.theme_id].color4, 0.1) : changeColorOpacity(brand_colors[defaultTheme.theme_id].color4, 0.1), borderRadius: '0.8rem', padding: '0.2rem 0.8rem' }} onClick={() => {
                                    if (showRegistrationFind !== -1) {
                                      showRegistration.splice(showRegistrationFind, 1)
                                    } else {
                                      showRegistration.push(student.child_id)
                                    }
                                    this.setState({ showRegistration })
                                  }}>
                                    <div className='d-flex align-items-center justify-content-center me-2' style={{ backgroundColor: brand_colors[defaultTheme.theme_id].color8, borderRadius: '100%', height: '0.9rem', width: '0.9rem' }}>
                                      {(showRegistrationFind !== -1) && <FontAwesomeIcon icon={faCheckCircle} color={brand_colors[defaultTheme.theme_id].color4} style={{ fontSize: '0.8rem', }} />}
                                    </div>
                                    <div style={{ color: (showRegistrationFind !== -1) ? brand_colors[defaultTheme.theme_id].color4 : brand_colors[defaultTheme.theme_id].color18, fontWeight: '600', fontSize: '0.8rem' }}>
                                      {t('Preview')}
                                    </div>
                                  </div>
                                  <Button variant='success' size='sm' onClick={() =>
                                    this.props.toggleRegistrationAdvanceModal({ title: 'New Registration', child: { label: student.child_name, value: student.child_id, ...student }, registrations: [], todayDate: moment().format('YYYY-MM-DD'), addRegCount: 1, addservices: [] })
                                  }>
                                    <FontAwesomeIcon icon={faPlus} color={brand_colors[defaultTheme.theme_id].color8} />
                                  </Button>
                                </div>
                              </div>
                            </Card.Header>
                            {showRegistrationFind !== -1 &&
                              <Card.Body className='p-1'>
                                <Table striped hover responsive size='sm'>
                                  <tbody>
                                    {student.registrations.map((v, i) => {
                                      return <tr key={i} style={{ fontSize: 13, opacity: 0.9 }}>
                                        <td>
                                          <FontAwesomeIcon icon={faEdit} color={brand_colors[defaultTheme.theme_id].color16} className='cursor-pointer ms-3'
                                            onClick={() => this.editRegistration(student, v)}
                                          />
                                          {/* DELETE */}
                                          <FontAwesomeIcon icon={faTrashCan} color={brand_colors[defaultTheme.theme_id].color11} className='cursor-pointer ms-3' onClick={() => this.deleteRegistration(v)} />

                                          {/* PRINT */}
                                          <FontAwesomeIcon icon={faPrint} color={brand_colors[defaultTheme.theme_id].color4}
                                            style={{
                                              cursor: v.reg_num == 0 ? 'not-allowed' : 'pointer',
                                              opacity: v.reg_num == 0 ? 0.3 : 1
                                            }}
                                            className='cursor-pointer ms-3' onClick={() => v.reg_num == 0 ? null : this.printRegistration(v)} />
                                        </td>
                                        <td>
                                          <strong>{v.reg_num}</strong>
                                        </td>
                                        <td>
                                          <strong>{v.class_theme}</strong>
                                        </td>
                                        <td>
                                          {moment(v.reg_date, 'YYYY-MM-DD').format('YYYY-MM-DD')}
                                        </td>
                                        <td>
                                          {v.reg_year} - {v.term_name}
                                        </td>
                                        <td>
                                          {v.sessioncat_name}
                                        </td>
                                        <td>
                                          {v.session_name}
                                        </td>
                                        <td>
                                          <Badge bg="success">{moment(v.date_from).format('YYYY-MM-DD')}</Badge>
                                          <Badge className='ms-1' bg="warning">{moment(v.date_to).format('YYYY-MM-DD')}</Badge>
                                        </td>
                                      </tr>
                                    })}
                                  </tbody>
                                </Table>
                              </Card.Body>
                            }
                          </Card>
                        </div>
                      </Col>
                    </Row>
                  })}
                </Col>
              }
            </Row>
            <Pagination
              pageCount={this.props.studentListLastPage}
              forcePage={this.props.studentListPage}
              callbackParams={{
                rows: this.props.studentListRows,
                search: this.props.studentListSearch,
                status: true
              }}
              callback={this.getStudents}
            />
          </Card.Body>
        </Card >


        {/* REGISTRATION MODAL */}
        <AddEditRegistrationAdvance callbackFunction={() => this.getStudents({
          rows: this.props.studentListRows,
          page: this.props.studentListPage,
          search: this.props.studentListSearch,
          status: true,
        })} />


        <SweetAlert show={this.state.deleteModal.show} warning showCancel confirmBtnText={t('Yes, delete it!')} confirmBtnBsStyle='danger' title={t('Are you sure?')} onConfirm={this.state.deleteModal.action} onCancel={() => this.setState({ deleteModal: { ...this.state.deleteModal, show: false, id: 0, action: () => { } } })} focusCancelBtn>
          {t('Confirm Delete!')}
        </SweetAlert>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  authData: state.auth.authData,
  selectedClass: state.selectedClass.data,
  defaultLanguage: state.language.defaultLanguage,
  defaultTheme: state.theme.defaultTheme,
  studentListFetching: state.studentList.fetching,
  studentListData: state.studentList.data,
  studentListSearch: state.studentList.query,
  studentListRows: state.studentList.rows,
  studentListOffset: state.studentList.offset,
  studentListStatus: state.studentList.status,
  studentListOrder: state.studentList.order,
  studentListDir: state.studentList.dir,
  studentListLastPage: state.studentList.last_page,
  studentListPage: state.studentList.page,
  studentListTotalRecords: state.studentList.total_records,
});

const mapDispatchToProps = () => ({
  getStudents,
  toggleRegistrationAdvanceModal
});

export default connect(mapStateToProps, mapDispatchToProps())(StudentRegistrationAdvance);
