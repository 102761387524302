import { Component } from 'react';
import { Button, Col, Container, Row, Image, ListGroup, Spinner, Badge, Card, Tabs, Tab, Table, Offcanvas } from 'react-bootstrap';
import { connect } from 'react-redux';
import NavBar from '../_partials/NavBar/_NavBar';
import Timeline from './Timeline';
import { unsetAuthentication } from '../../redux/slices/authSlice';
import { setSelectedClass } from '../../redux/slices/selectedClassSlice';
import { setDefaultLanguage } from '../../redux/slices/langSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPowerOff, faBell, faSearch, faChevronLeft, faChevronRight, faLanguage, faCheckCircle, faCalendarAlt, faCalendarDay, faTable, faChartBar, faUserCheck, faListCheck, faPuzzlePiece, faAngleLeft, faAngleRight, } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import axios from 'axios';
import { t } from '../../helpers/translation_helper';
import Header from '../_partials/Header/_Header';
import Subjects from '../Subjects/_Subjects';
import { NavLink, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import Timetable from './Timetable';
import { brand_colors } from '../../helpers/brand_colors_helper';
import { ifNavAllowed } from '../../helpers/general_helpers';
import { GET, POST } from '../../api';
import { faCalendar, faThumbsDown, faThumbsUp } from '@fortawesome/free-regular-svg-icons';
import { Doughnut, Bar, Chart } from 'react-chartjs-2';
import SchoolAttendanceWidget from '../_partials/Widgets/SchoolAttendanceWidget';
import ExamsHomeworksWidget from '../_partials/Widgets/GradeBookWidget';


const doughnutChartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  cutout: 40,
  plugins: {
    legend: {
      position: 'right',
      labels: {
        generateLabels: (chart) => {
          const datasets = chart.data.datasets;
          return datasets[0].data.map((data, i) => ({
            text: `${chart.data.labels[i]}: ${data}`,
            fillStyle: datasets[0].backgroundColor[i],
            lineWidth: 0,
            index: i
          }))
        }
      }
    },
  },
};

// API URL
const apiURL = process.env.REACT_APP_API_URL;

// Portal URL
const portalURL = process.env.REACT_APP_PORTAL_URL;

let controller; // For axios abort controller

/**
 * Dashboard Component
 */
class Dashboard extends Component {
  /**
   * @constructor
   * @param {any} props
   */
  constructor(props) {
    super(props);

    const defaultUtTypes = [
      {
        label: "Notification",
        value: "Notification",
        picture: require('../../assets/images/user-timeline/notification.png'),
        active: true,
      },
      {
        label: "Event",
        value: "Event",
        picture: require('../../assets/images/user-timeline/event.png'),
        active: true,
      },
      {
        label: "Newsletter",
        value: "Newsletter",
        picture: require('../../assets/images/user-timeline/newsletter.png'),
        active: true,
      },
      {
        label: "Media",
        value: "Media",
        picture: require('../../assets/images/user-timeline/photos.png'),
        active: true,
      },
      {
        label: "Video",
        value: "Video",
        picture: require('../../assets/images/user-timeline/videos.png'),
        active: true,
      },
      {
        label: "Note",
        value: "Note",
        picture: require('../../assets/images/user-timeline/attachment-pink.png'),
        active: true,
      },
      {
        label: "Online Class",
        value: "Online Class",
        picture: require('../../assets/images/user-timeline/online-class.png'),
        active: true,
      },
      {
        label: "Resources",
        value: "Resources",
        picture: require('../../assets/images/user-timeline/download.png'),
        active: true,
      },
      {
        label: "Policy",
        value: "Policy",
        picture: require('../../assets/images/user-timeline/policy.png'),
        active: true,
      },
      {
        label: "Incident Report",
        value: "Incident Report",
        picture: require('../../assets/images/user-timeline/nurse-2.png'),
        active: true,
      },
      {
        label: "Poll",
        value: "Poll",
        picture: require('../../assets/images/user-timeline/poll.png'),
        active: true,
      },
      {
        label: "Survey",
        value: "Survey",
        picture: require('../../assets/images/user-timeline/survey.png'),
        active: true,
      },
      {
        label: "Observation",
        value: "Observation",
        picture: require('../../assets/images/user-timeline/grade.png'),
        active: true,
      },
      {
        label: "Agenda",
        value: "Agenda",
        picture: require('../../assets/images/user-timeline/agenda.png'),
        active: true,
      },
      {
        label: "Assignment",
        value: "Assignment",
        picture: require('../../assets/images/user-timeline/assignment.png'),
        active: true,
      },
      {
        label: "Daily Report",
        value: "Daily Report",
        picture: require('../../assets/images/user-timeline/daily-report.png'),
        active: true,
      },
      {
        label: "Exam",
        value: "Exam",
        picture: require('../../assets/images/user-timeline/exam.png'),
        active: true,
      },
      {
        label: "Homework",
        value: "Online Homework",
        picture: require('../../assets/images/user-timeline/exam.png'),
        active: true,
      },
      {
        label: "Activity",
        value: "Activity",
        picture: require('../../assets/images/user-timeline/activity.png'),
        active: true,
      },
      {
        label: "BMI",
        value: "BMI",
        picture: require('../../assets/images/user-timeline/profile.png'),
        active: true,
      },
      {
        label: "Medication Consent",
        value: "Medication Consent",
        picture: require('../../assets/images/user-timeline/nurse-2.png'),
        active: true,
      },
      {
        label: "Medicine Dose",
        value: "Medicine Dose",
        picture: require('../../assets/images/user-timeline/nurse-2.png'),
        active: true,
      },
      {
        label: "Upload",
        value: "Upload",
        picture: require('../../assets/images/user-timeline/mydocument.png'),
        active: true,
      },
      {
        label: "Exam Retake",
        value: "Exam Retake",
        picture: require('../../assets/images/user-timeline/mydocument.png'),
        active: true,
      },
      {
        label: "Academic Lesson",
        value: "Academic Lesson",
        picture: require('../../assets/images/user-timeline/journal.png'),
        active: true,
      },
      {
        label: "Pastoral Care",
        value: "Pastoral Care",
        picture: require('../../assets/images/user-timeline/nurse-2.png'),
        active: true,
      },
      {
        label: "Result",
        value: "Result",
        picture: require('../../assets/images/user-timeline/grade.png'),
        active: true,
      },
    ];

    this.state = {
      utWorking: false,
      startDate: '',
      endDate: '',
      utTypes: defaultUtTypes,
      selectedUtTypes: defaultUtTypes,
      userTimelineData: [],
      offset: 0,
      finishedData: false,
      loadMore: false,
      firstLoad: true,
      calendarList: null,
      todayAtt: null,
      showDetailAttModal: false,
      showUT: false,
    };
  }
  /**
   * Component Did Mount
   */
  componentDidMount() {
    // this.getTimeline();
    this.calendarList();
    this.getTodayAtt();
  }

  /**
   * Handle Selected User Timeline Types
   * @param {array} types Array of user timeline types.
   */
  handleSelectUtTypes = (types) => {
    this.setState({
      selectedUtTypes: types,
    });
  };

  /**
   * Handle Date Range
   * @param {string} dateType Name of the date field in state
   * @param {any} date Date
   */
  handleUtDateRange = (dateType, date) => {
    this.setState({
      [dateType]: date,
    });
  };

  handleOpenUT = () => {
    this.setState({
      showUT: true,
      startDate: '',
      endDate: '',
      offset: 0,
      finishedData: false,
      selectedUtTypes: this.state.utTypes,
    }, () => this.getTimeline(true));

  }

  handleCloseUT = () => {
    this.setState({
      showUT: false,
      userTimelineData: [],
    });
  }

  /**
   * Get Timeline
   * @param {boolean} reset Reset the filter
   */
  getTimeline = async (reset = false) => {
    if (this.state.finishedData || this.state.loadMore) {
      return false
    }
    this.setState({
      loadMore: true,
    }, async () => {
      if (this.state.offset === 0) {
        this.setState({ utWorking: true });
      }

      if (this.getTimelineReq) { this.getTimelineReq.abort(); }
      this.getTimelineReq = new AbortController();

      const { selectedUtTypes, startDate, endDate, userTimelineData } =
        this.state;
      const { authData, selectedClass } = this.props;
      const { class_id } = selectedClass.class;
      const { center_id, center_timezone } = authData.centerData;
      const { user_id, auth_key } = authData.loginData;

      const fData = new FormData();
      fData.append('user_id', user_id);
      fData.append('center_id', center_id);
      fData.append('center_timezone', center_timezone);
      fData.append('class_id', class_id);
      // fData.append('child_id', child_id);
      fData.append(
        'activeFilterDateFrom',
        startDate ? moment(startDate).format('YYYY-MM-DD') + ' 00:00:00' : 'null'
      );
      fData.append(
        'activeFilterDateTo',
        endDate ? moment(endDate).format('YYYY-MM-DD') + ' 23:59:59' : 'null'
      );
      fData.append(
        'selectedTimelineTypes',
        JSON.stringify(
          selectedUtTypes.map((item) => {
            return { ut_type: item.value, active: true };
          })
        )
      );
      fData.append('offset', this.state.offset);


      const req = await POST('user-timeline', fData, {
        signal: this.getTimelineReq.signal,
      });
      if (req) {
        if (req.status === 200) {
          let data = [];
          if (reset) {
            data = req.data.data;
          } else {
            data = [...userTimelineData, ...req.data.data];
          }

          this.setState({
            utWorking: false,
            userTimelineData: data,
            offset: this.state.offset + 10,
            loadMore: false,
            finishedData: (req.data.length <= 0) ? true : false,
            firstLoad: false
          });
          // setTimeout(() => {
          //     if (document.querySelector('.timeline-reel')) {
          //         document
          //             .querySelector('.timeline-reel')
          //             .setAttribute('style', 'height:' + (document.querySelector('.page-content-inner').offsetHeight - 60) + 'px');
          //     }
          // }, 1000);
        }
      }
    });
  };


  /**
   * Reset Filter
   */
  resetFilter = () => {
    this.setState(
      {
        startDate: '',
        endDate: '',
        offset: 0,
        finishedData: false,
        selectedUtTypes: this.state.utTypes,
      },
      () => this.getTimeline(true)
    );
  };

  applyFilter = () => {
    this.setState(
      {
        offset: 0,
        finishedData: false
      },
      () => this.getTimeline(true)
    );
  };

  calendarList = async () => {
    this.setState({
      calendarList: null
    });
    const { selectedClass } = this.props;
    const date = moment().format('YYYY-MM-DD');
    try {
      const res = await GET('center-calendar/get-center-calendar-day', {
        params: {
          date,
          class_id: selectedClass.class.class_id
        }
      });

      this.setState({
        calendarList: res.data
      });
    } catch (err) {
      console.log('err', err)
    }
  }

  getTodayAtt = async () => {

    this.setState({ todayAtt: null });

    const { class_id } = this.props.selectedClass.class;

    try {
      const res = await GET('admin/report-class-attendance', {
        params: {
          class_id
        }
      });

      let todayAtt = {
        labels: [t('Present'), t('Absent'), t('Not Marked')],
        datasets: [
          {
            data: [],
            backgroundColor: [
              brand_colors[this.props.defaultTheme.theme_id].color1,
              brand_colors[this.props.defaultTheme.theme_id].color2,
              brand_colors[this.props.defaultTheme.theme_id].color16
            ]
          }
        ]
      };

      todayAtt.datasets[0].data = [
        parseInt(res.data.total_present),
        parseInt(res.data.total_absent),
        parseInt(res.data.total_not_marked),
      ];

      this.setState({
        todayAtt
      });

    } catch (err) {
      console.log('err', err)

    }

  }





  /**
   * Render Component
   * @returns {HTMLElement}
   */
  render() {
    // Language Direction
    let { lang_orientation: dir } = this.props.defaultLanguage;
    const { authData } = this.props;
    const { role_id } = authData.loginData;
    let center_tags = JSON.parse(authData.loginData.center_tags)
    // State
    const {
      utWorking,
      startDate,
      endDate,
      utTypes,
      selectedUtTypes,
      userTimelineData,
      loadMore,
    } = this.state;

    // Slick Button Component
    const SlickButton = ({
      currentSlide,
      slideCount,
      children,
      ...props
    }) => (
      <FontAwesomeIcon
        {...props}
        icon={props.dir == 'next' ? faChevronRight : faChevronLeft}
        color={brand_colors[this.props.defaultTheme.theme_id].color2}
      />
    );

    // Slick General Settings
    const generalSliderSettings = {
      infinite: true,
      speed: 500,
      slidesToScroll: 1,
      nextArrow: <SlickButton dir='next' />,
      prevArrow: <SlickButton dir='prev' />,
    };

    // Slicer one additional settings
    const oneSliderSettings = {
      ...generalSliderSettings,
      slidesToShow: 4,
    };

    // Slicer two additional settings
    const twoSliderSettings = {
      ...generalSliderSettings,
      slidesToShow: 4,
    };

    // Slicer three additional settings
    const threeSliderSettings = {
      ...generalSliderSettings,
      slidesToShow: 4,
    };

    return (
      <>
        <Container fluid>
          <div id='iedu-layout'>
            <NavBar />
            <div id='page-content' style={{ padding: (dir == 1) ? '20px 118px 20px 10px' : '20px 10px 20px 118px' }}>
              <div className='page-content-inner'>
                <Header centerSelectorFunc={[this.resetFilter]} classSelectorFunc={[this.calendarList, this.getTodayAtt]} showClassSelector={(center_tags.length > 0) ? true : false} />
                <Timetable />
                <Row>
                  {![27, 2].includes(role_id) &&
                    <Col md={4}>
                      <div className='widget-box' style={{
                        marginBottom: 23
                      }}>
                        <Link to='/calendar' className='widget-box-header'>
                          <Row>
                            <Col md={1} className='pe-0' style={{ textAlign: dir == 1 ? 'left' : 'right' }}>
                              <FontAwesomeIcon icon={faCalendar} style={{ color: '#fff', marginRight: 7 }} />
                            </Col>
                            <Col style={{ textAlign: dir == 1 ? 'right' : 'left', fontWeight: 700, color: '#fff' }}>{moment().format('dddd, D MMM YYYY')}</Col>
                            <Col md={'auto'}>
                              <FontAwesomeIcon icon={dir == 1 ? faChevronLeft : faChevronRight} style={{ color: '#fff', marginRight: 7 }} />
                            </Col>
                          </Row>
                        </Link>
                        <div className='widget-box-body'>
                          {!this.state.calendarList &&
                            <div style={{ paddingTop: 50, textAlign: 'center' }}>
                              <Spinner animation="grow" />
                            </div>
                          }
                          {this.state.calendarList?.length == 0 &&
                            <div style={{ paddingTop: 50, textAlign: 'center' }}>
                              {t('No Data Found!')}
                            </div>
                          }
                          {this.state.calendarList?.length > 0 &&
                            <div className='widget-box-list'>
                              {this.state.calendarList.map((item, index) => {
                                const body = item.body.replace(/(<([^>]+)>)/gi, "");
                                return (
                                  <div key={index} className={dir == 1 ? 'widget-box-list-item-ar' : 'widget-box-list-item'}>
                                    {item.title} - {body.length > 50 ? body.substring(0, 50) + '...' : body}
                                  </div>
                                )
                              }
                              )}
                            </div>
                          }
                        </div>
                      </div>
                    </Col>
                  }
                  <Col md={4}>
                    <div className='widget-box'>
                      <Link to={[27, 2].includes(this.props.authData.userData.role_id) ? '/students/custom-attendance' : '/students/attendance'} className='widget-box-header'>
                        <Row>
                          <Col md={1} className='pe-0' style={{ textAlign: dir == 1 ? 'left' : 'right' }}>
                            <FontAwesomeIcon icon={faUserCheck} style={{ color: '#fff', marginRight: 7 }} />
                          </Col>
                          <Col style={{ textAlign: dir == 1 ? 'right' : 'left', fontWeight: 700, color: '#fff' }}>{t("Class Attendance")}</Col>
                          <Col md={'auto'}>
                            <FontAwesomeIcon icon={dir == 1 ? faChevronLeft : faChevronRight} style={{ color: '#fff', marginRight: 7 }} />
                          </Col>
                        </Row>
                      </Link>
                      <div className='widget-box-body'>
                        {!this.state.todayAtt &&
                          <div style={{ paddingTop: 50, textAlign: 'center' }}>
                            <Spinner animation="grow" />
                          </div>
                        }
                        <div style={{
                          paddingTop: 15,
                          paddingRight: '10%'
                        }}>
                          {this.state.todayAtt && <Doughnut
                            data={this.state.todayAtt}
                            options={doughnutChartOptions}
                            height={110}
                          />}
                        </div>
                      </div>
                    </div>
                  </Col>

                  {[3, 15, 19, 22].includes(role_id) &&
                    <Col md={4}>
                      <div className='widget-box' style={{
                        marginBottom: 23
                      }}>
                        <Link to='/early-childhood' className='widget-box-full-btn'>
                          <FontAwesomeIcon
                            icon={faPuzzlePiece}
                            color={'#fff'}
                            style={{
                              fontSize: 60,
                              marginBottom: 10
                            }}
                          />
                          {t('Objectives & Journals')}
                        </Link>
                      </div>
                    </Col>
                  }

                  {[1, 3, 12, 15, 19, 22].includes(role_id) &&
                    <Col md={12}>
                      <ExamsHomeworksWidget size={'sm'} />
                    </Col>
                  }
                </Row>
                <Row>
                  {[27, 2].includes(role_id) &&
                    <Col md={4}>
                      <div className='widget-box' style={{ marginBottom: 23, marginTop: 23, height: 'auto' }}>
                        <SchoolAttendanceWidget />
                      </div>
                    </Col>
                  }
                </Row>


                {/* <div className='slider-wrap two-slider' style={{}}>
                  <Slider {...twoSliderSettings}>
                    <div className='two-slide'>
                      <div className='slide-inner'>
                        <Link to='/calendar'>
                          <div className='slide-icon'>
                            <FontAwesomeIcon
                              icon={faCalendarAlt}
                              color={brand_colors[this.props.defaultTheme.theme_id].color8}
                            />
                          </div>
                          <h6>{t('Main')}</h6>
                          <span>{t('Calendar')}</span>
                        </Link>
                      </div>
                    </div>
                    <div className='two-slide'>
                      <div className='slide-inner'>
                        <Link to='/exam-calendar'>
                          <div className='slide-icon'>
                            <FontAwesomeIcon
                              icon={faCalendarDay}
                              color={brand_colors[this.props.defaultTheme.theme_id].color8}
                            />
                          </div>
                          <h6>{t('Exam')}</h6>
                          <span>{t('Calendar')}</span>
                        </Link>
                      </div>
                    </div>
                    <div className='two-slide'>
                      <div className='slide-inner'>
                        <Link to='/timetable'>
                          <div className='slide-icon'>
                            <FontAwesomeIcon
                              icon={faTable}
                              color={brand_colors[this.props.defaultTheme.theme_id].color8}
                            />
                          </div>
                          <h6>{t('Student')}</h6>
                          <span>
                            {t('Timetable')}
                          </span>
                        </Link>
                      </div>
                    </div>
                    <div className='two-slide'>
                      <div className='slide-inner'>
                        <Link to='/analysis'>
                          <div className='slide-icon'>
                            <FontAwesomeIcon
                              icon={faChartBar}
                              color={brand_colors[this.props.defaultTheme.theme_id].color8}
                            />
                          </div>
                          <h6>{t('Analysis')}</h6>
                          <span>{t('Detail')}</span>
                        </Link>
                      </div>
                    </div>
                  </Slider>
                </div> */}

                {/* SUBJECTS */}
                {![27, 2].includes(role_id) &&
                  <div className='slider-wrap' style={{ padding: '10px 0' }} >
                    <Subjects hideHeader={true} agegroup_id={this.props.selectedClass.class.agegroup_id} />
                  </div>
                }
              </div>
            </div>
          </div>
        </Container >
        <Offcanvas
          id='dashboard-ut'
          show={this.state.showUT} onHide={this.handleCloseUT} placement={(dir == 1) ? 'start' : 'end'}>
          <Offcanvas.Body className='p-0'>
            <Timeline
              utWorking={utWorking}
              utTypes={utTypes}
              selectedUtTypes={selectedUtTypes}
              startDate={startDate}
              endDate={endDate}
              handleUtDateRange={this.handleUtDateRange}
              handleSelectUtTypes={this.handleSelectUtTypes}
              getTimeline={this.getTimeline}
              resetFilter={this.resetFilter}
              applyFilter={this.applyFilter}
              userTimelineData={userTimelineData}
              loadMore={loadMore}
              handleCloseUT={this.handleCloseUT}
            />
          </Offcanvas.Body>
        </Offcanvas>
        <button
          onClick={this.handleOpenUT}
          title={t('Open News Feed')}
          className='ut-toggle'>
          <FontAwesomeIcon icon={(dir == 1) ? faAngleRight : faAngleLeft} />
        </button>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  authData: state.auth.authData,
  selectedClass: state.selectedClass.data,
  languages: state.language.languages,
  defaultLanguage: state.language.defaultLanguage,
  defaultTheme: state.theme.defaultTheme,
});

const mapDispatchToProps = () => ({
  unsetAuthentication,
  setSelectedClass,
  setDefaultLanguage,
});

export default connect(mapStateToProps, mapDispatchToProps())(Dashboard);
