import React, { Component, Fragment } from 'react';
import { Container, Card, Table, Row, Col, Spinner, Image, Badge, Button, Form, Offcanvas, Accordion, Modal, Alert } from 'react-bootstrap';
import { t } from '../../helpers/translation_helper';
import NavBar from '../_partials/NavBar/_NavBar';
import Header from '../_partials/Header/_Header';
import { connect } from 'react-redux';
import axios from 'axios';
import { toast } from 'react-toastify';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight, faCalendarAlt, faCheck, faClock, faEdit, faExternalLink, faFileAlt, faGear, faPaperclip, faSave, faSearch, faTimes, faTrashCan, } from '@fortawesome/free-solid-svg-icons';
import { Scrollbar } from 'react-scrollbars-custom';
import imgDescription from '../../assets/images/description.png';
import { brand_colors } from '../../helpers/brand_colors_helper';
import SweetAlert from 'react-bootstrap-sweetalert';
import Select from 'react-select';
import { handleFormErrors } from '../../helpers/form_helpers';
import { GET } from '../../api';
import { Chart } from 'react-chartjs-2';
var Chance = require('chance');
var chance = new Chance();


// API URL
const apiURL = process.env.REACT_APP_API_URL;

// Portal URL
const portalURL = process.env.REACT_APP_PORTAL_URL;

class ReportCohortExams extends Component {
    constructor(props) {
        super(props);
        this.state = {
            centerlicData: { data: [], selected: null },
            termsData: { data: [], selected: [] },
            ageGroupsData: { data: [], selected: null },
            reRenderTime: moment(),
            reportData: {
                processing: false,
                data: {},
                finished: false
            },
        }
    }

    componentDidMount() {
        this.getCenterlic()
        if (this.props.authData.classes && this.props.authData.classes.length > 0) {
            let uniqueAgeGroups = []
            let selectedAgeGroup = null
            this.props.authData.classes.map((v) => {
                if (v.class.agegroup_id && v.class.agegroup_code) {
                    let findUnique = uniqueAgeGroups.find((vv) => vv.agegroup_id == v.class.agegroup_id)
                    if (!findUnique) {
                        uniqueAgeGroups.push({ agegroup_id: v.class.agegroup_id, agegroup_code: v.class.agegroup_code, agegroup_color: v.class.agegroup_color })
                    }
                    if (this.props.selectedClass.class.agegroup_id == v.class.agegroup_id) {
                        selectedAgeGroup = { value: v.class.agegroup_id, label: v.class.agegroup_code, agegroup_color: v.class.agegroup_color }
                    }
                }
            })
            this.setState({ ageGroupsData: { ...this.state.ageGroupsData, data: uniqueAgeGroups, selected: selectedAgeGroup } })
        }
    }

    getCenterlic = () => {
        try {
            if (this.getCenterlicReq) {
                this.getCenterlicReq.abort();
            }
            this.getCenterlicReq = new AbortController();
            GET('report-exams/get-centerlic', {
                signal: this.getCenterlicReq.signal,
            }).then((res) => {

                if (res) {
                    let selected = res.data.find((v) => v.reg_status == 1)
                    this.setState({ centerlicData: { ...this.state.centerlicData, data: res.data, selected: { value: selected.centerlic_id, label: selected.reg_year, reg_status: selected.reg_status } } }, () => this.getTerms())
                }
            })
        } catch (err) {
            console.log('err', err)
            toast.error(t('Something went wrong while deleting!'));

        }
    }
    getTerms = () => {
        try {
            if (this.getTermsReq) {
                this.getTermsReq.abort();
            }
            this.getTermsReq = new AbortController();
            GET('report-exams/get-terms', {
                signal: this.getTermsReq.signal,
            }).then((res) => {

                if (res) {
                    let selected = []
                    res.data.map((v, i) => {
                        if (v.centerlic_id == this.state.centerlicData.selected.value) {
                            selected.push({ value: v.term_id, label: v.term_name, term_active: v.term_active })
                        }
                    })
                    this.setState({ termsData: { ...this.state.termsData, data: res.data, selected: selected } })
                }
            })
        } catch (err) {
            console.log('err', err)
            toast.error(t('Something went wrong while deleting!'));

        }
    }

    fetchReport = () => {
        let reportData = this.state.reportData
        let centerlicData = this.state.centerlicData
        let termsData = this.state.termsData
        let ageGroupsData = this.state.ageGroupsData
        if (!this.props.selectedClass) {
            toast.error(t('No Class Selected'));
            return
        }
        if (!this.props.selectedSubject) {
            toast.error(t('No Subject Selected'));
            return
        }
        if (!centerlicData.selected) {
            toast.error(t('Please select a Year'));
            return
        }
        if (termsData.selected.length <= 0) {
            toast.error(t('Please select a Term'));
            return
        }
        if (!ageGroupsData.selected) {
            toast.error(t('Please select a Grade'));
            return
        }




        this.setState({ reportData: { ...reportData, processing: true, data: {} } })

        try {
            if (this.fetchReportReq) {
                this.fetchReportReq.abort();
            }
            this.fetchReportReq = new AbortController();
            GET('exams/get-report-cohort-exams', {
                params: {
                    class_id: this.props.selectedClass.class.class_id,
                    agegroup_id: ageGroupsData.selected.value,
                    acs_id: this.props.selectedSubject.acs_id,
                    centerlic_id: centerlicData.selected.value,
                    term_ids: JSON.stringify(termsData.selected.map((v, i) => v.value)),
                },
                signal: this.fetchReportReq.signal,
            }).then((res) => {

                if (res) {
                    this.setState({ reportData: { ...reportData, processing: false, data: res.data, finished: true }, reRenderTime: moment() })
                } else {
                    toast.error(t('Something went wrong while deleting!'));
                    this.setState({ reportData: { ...reportData, processing: false, data: {} } })
                }
            })
        } catch (err) {
            console.log('err', err)
            toast.error(t('Something went wrong while deleting!'));

            this.setState({ reportData: { ...reportData, processing: false, data: {} } })
        }
    }

    render() {

        const { reportData, reRenderTime, centerlicData, termsData, ageGroupsData } = this.state
        let filteredTerms = []
        if (centerlicData.selected) {
            filteredTerms = termsData.data.filter((v, i) => v.centerlic_id == centerlicData.selected.value)
        }
        return (
            <Container fluid>
                <div id='iedu-layout'>
                    <NavBar />
                    <div id='page-content'>
                        <Header lite={true} heading={t('Cohort Exams')} backBtn={true} showClassSelector={true} showSubjectSelector={true} classSelectorFunc={[this.fetchReport]} subjectSelectorFunc={[this.fetchReport]} />
                        <div className='grey-section'>
                            <Row className='justify-content-between'>
                                <Col>
                                    <Row>
                                        <Col>
                                            <Select
                                                placeholder={t('Select Grade')}
                                                options={ageGroupsData.data.map((v, i) => { return { label: v.agegroup_code, value: v.agegroup_id, agegroup_color: v.agegroup_color } })}
                                                onChange={(event) => this.setState({ ageGroupsData: { ...ageGroupsData, selected: event } })
                                                }
                                                value={ageGroupsData.selected}
                                                menuPortalTarget={document.body}
                                                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                                formatOptionLabel={option => (
                                                    <div className='d-flex align-items-center'>
                                                        <span style={{ fontSize: 16, color: option.agegroup_color }} className='me-1'>{option.label}</span>
                                                    </div>
                                                )}
                                            />
                                        </Col>
                                        <Col>
                                            <Select
                                                placeholder={t('Select Year')}
                                                options={centerlicData.data.map((v, i) => { return { label: v.reg_year, value: v.centerlic_id, reg_status: v.reg_status } })}
                                                onChange={(event) => this.setState({ centerlicData: { ...centerlicData, selected: event }, termsData: { ...termsData, selected: [] } })
                                                }
                                                value={centerlicData.selected}
                                                menuPortalTarget={document.body}
                                                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                                formatOptionLabel={option => (
                                                    <div className='d-flex align-items-center'>
                                                        <span style={{ fontSize: 16 }} className='me-1'>{option.label}</span>
                                                        {option.reg_status == 1 && <Badge pill bg="success">{t('Active')}</Badge>}
                                                    </div>
                                                )}
                                            />
                                        </Col>
                                        <Col md="6">
                                            <Select
                                                isMulti
                                                isClearable
                                                placeholder={t('Select Term')}
                                                options={filteredTerms.map((v, i) => { return { label: v.term_name, value: v.term_id, term_active: v.term_active } })}
                                                onChange={(event) => this.setState({ termsData: { ...termsData, selected: event } })
                                                }
                                                value={termsData.selected}
                                                menuPortalTarget={document.body}
                                                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                                formatOptionLabel={option => (
                                                    <div className='d-flex align-items-center'>
                                                        <span style={{ fontSize: 16 }} className='me-1'>{option.label}</span>
                                                        {option.term_active == 1 && <Badge pill bg="success">{t('Active')}</Badge>}
                                                    </div>
                                                )}
                                            />
                                        </Col>
                                        <Col>
                                            <Button variant={'success'} onClick={() => this.fetchReport()}>
                                                {reportData.processing
                                                    ? <Spinner as='span' animation='grow' size='sm' role='status' aria-hidden='true' />
                                                    : <FontAwesomeIcon icon={faSearch} style={{ fontSize: 16, color: brand_colors[this.props.defaultTheme.theme_id].color8, }} />
                                                }
                                            </Button>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col md="auto">

                                </Col>
                            </Row>
                        </div>
                        <div className='grey-section'>

                            {reportData.processing
                                ? <div className='text-center'>
                                    <Spinner as='span' animation='grow' size='sm' role='status' aria-hidden='true' />
                                </div>
                                : <RenderReport reportData={reportData} defaultTheme={this.props.defaultTheme} reRenderTime={reRenderTime} />
                            }
                        </div>
                    </div>
                </div>

            </Container>
        );
    }
}

const mapStateToProps = (state) => ({
    authData: state.auth.authData,
    defaultLanguage: state.language.defaultLanguage,
    selectedClass: state.selectedClass.data,
    selectedSubject: state.selectedClass.subject,
    children: state.children.list,
    defaultTheme: state.theme.defaultTheme,
});

export default connect(mapStateToProps, null)(ReportCohortExams);


class RenderReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }


    shouldComponentUpdate(nextProps) {
        if (moment(nextProps.reRenderTime).isSame(moment(this.props.reRenderTime))) {

            return false
        }

        return true
    }

    render() {
        let reportData = this.props.reportData
        if (!reportData.finished) {
            return null
        }

        return (
            <div className='d-flex flex-row flex-wrap'>
                {Object.values(reportData.data.data).map((v, i) => {

                    let exams = Object.values(v.exams)
                    const labels = exams.map((vv, ii) => vv.examData.exams_name)
                    const data = {
                        labels,
                        datasets: [
                            {
                                type: 'line',
                                label: 'Total',
                                borderColor: '#0dcaf0',
                                borderWidth: 2,
                                fill: false,
                                data: exams.map((vv, ii) => parseFloat(vv.examData.exams_grade).toFixed(2)),
                            },
                            {
                                type: 'bar',
                                label: 'Highest',
                                backgroundColor: '#8dc572',
                                data: exams.map((vv, ii) => parseFloat(vv.highest).toFixed(2)),
                                borderColor: 'white',
                                borderWidth: 2,
                            },
                            {
                                type: 'bar',
                                label: 'Lowest',
                                backgroundColor: '#be6464',
                                data: exams.map((vv, ii) => parseFloat(vv.lowest).toFixed(2)),
                                borderColor: 'white',
                                borderWidth: 2,
                            },
                            {
                                type: 'bar',
                                label: 'Average',
                                backgroundColor: '#f0ad4e',
                                data: exams.map((vv, ii) => parseFloat(vv.average).toFixed(2)),
                                borderColor: 'white',
                                borderWidth: 2,
                            },
                        ],
                    };


                    return <Card style={{ width: '50%' }}>
                        <Card.Header>{v.class_data.class_theme}</Card.Header>
                        <Card.Body>
                            <Chart type='bar' data={data} options={{
                                barValueSpacing: 0
                            }} />
                        </Card.Body>
                        <Card.Body>
                            {exams.length > 0
                                ? <Table striped bordered hover responsive>
                                    <thead>
                                        <tr>
                                            <th>{t('Exam')}</th>
                                            <th>{t('Total')}</th>
                                            <th>{t('Highest')}</th>
                                            <th>{t('Average')}</th>
                                            <th>{t('Lowest')}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {exams.map((vv, ii) => {
                                            return <tr>
                                                <td>{vv.examData.exams_name}</td>
                                                <td><Badge pill bg="info">{parseFloat(vv.examData.exams_grade).toFixed(2)}</Badge></td>
                                                <td><Badge pill bg="success">{parseFloat(vv.highest).toFixed(2)}</Badge></td>
                                                <td><Badge pill bg="warning">{parseFloat(vv.average).toFixed(2)}</Badge></td>
                                                <td><Badge pill bg="danger">{parseFloat(vv.lowest).toFixed(2)}</Badge></td>
                                            </tr>
                                        })}
                                    </tbody>
                                </Table>
                                : <Alert variant={'warning'}>
                                    {t('No Exams Found')}
                                </Alert>
                            }
                        </Card.Body>
                    </Card>
                })}

                {/* <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th >{t('Objectives')}</th>
                            {reportData.data.children.map((v, i) => {
                                return <th>{v.child_name}</th>
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {reportData.data.rowsData.map((v, i) => {
                            return (<Fragment key={i}>
                                <tr className='cursor-pointer'>
                                    <td style={{ width: '30rem' }}>
                                        {v.curriculum_desc}
                                    </td>
                                    {reportData.data.children.map((vv) => {
                                        return <td>{'--'}</td>
                                    })}
                                </tr>
                            </Fragment>)
                        })}
                    </tbody>
                </Table> */}
            </div>
        )
    }
}
