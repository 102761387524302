import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { GET } from '../../api';
import { store } from '../store';
let getStudentsReq;

export const getStudents = createAsyncThunk('users/getStudents', async ({ child_status, search, order, dir, limit, page, attendance_today, attendance_subject, attendance_custom, attendance_startdate, attendance_enddate, pastoral_care, studentgroup, registrations, showAllClasses }) => {

  if (getStudentsReq) {
    getStudentsReq.abort();
  }
  getStudentsReq = new AbortController();

  let state = store.getState()
  let authData = state.auth.authData
  let class_ids = false;
  if (showAllClasses == undefined) {
    if ([1, 3, 4, 8, 12, 15, 27, 19, 22].includes(authData.loginData.role_id)) {
      class_ids = state.selectedClass.data.class.class_id
    }
  } else {
    if (showAllClasses == false) {
      class_ids = state.selectedClass.data.class.class_id
    }
  }

  let employee_id = authData.userData.employee_id
  let studentList = state.studentList
  if (search == undefined) {
    search = studentList.query
  }
  if (child_status == undefined) {
    child_status = studentList.status
  }
  if (order == undefined) {
    order = studentList.order
  }
  if (dir == undefined) {
    dir = studentList.dir
  }
  if (limit == undefined) {
    limit = studentList.rows
  }
  if (page == undefined) {
    page = studentList.page
  }
  try {

    const response = await GET('admin/children-list-paginate', {
      params: {
        employee_id,
        class_ids,
        child_status,
        search,
        order,
        dir,
        limit,
        offset: page * limit,
        attendance_today,
        attendance_subject,
        attendance_custom,
        attendance_startdate,
        attendance_enddate,
        pastoral_care,
        studentgroup,
        registrations,
      },
      signal: getStudentsReq.signal,
    });

    return {
      page,
      order,
      dir,
      search,
      limit,
      status: child_status,
      data: response.data.data,
      total_records: response.data.total_count_filtered,
      total_count: response.data.total_count,
    };

  } catch (error) {
    if (axios.isCancel(error)) {

    } else {

    }
  }

})

export const studentListSlice = createSlice({
  name: 'studentList',
  initialState: {
    fetching: false,
    query: '',
    rows: 10,
    data: [],
    order: 'children.child_name',
    dir: 'ASC',
    status: true,
    total_records: 0,
    total_count: 0,
    last_page: 0,
    page: 0,
  },
  reducers: {
    fetchingStudents: (state, action) => {
      state.fetching = action.payload;
    },
    setStudents: (state, action) => {
      state.data = action.payload;
      state.fetching = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getStudents.pending, (state, action) => {
      if (state.fetching === false) {
        state.fetching = true;
      }
    })
    builder.addCase(getStudents.fulfilled, (state, action) => {
      console.log('res.typeSTUDENT', state, state.fetching, action);
      if (state.fetching === true && action.payload) {
        state.data = action.payload.data;
        state.order = action.payload.order;
        state.dir = action.payload.dir;
        state.query = action.payload.search;
        state.rows = action.payload.limit;
        state.status = action.payload.status;
        state.total_records = parseInt(action.payload.total_records);
        state.total_count = parseInt(action.payload.total_count);
        state.page = parseInt(action.payload.page);
        state.last_page = Math.ceil(parseInt(action.payload.total_records) / parseInt(action.payload.limit));
        state.fetching = false;
      }
    })
    builder.addCase(getStudents.rejected, (state, action) => {
      if (state.fetching === true) {
        state.fetching = false;
        state.error = 'Error occurred';
      }
    })
  }
});

export const { setStudents, fetchingStudents } = studentListSlice.actions;
export default studentListSlice.reducer;
