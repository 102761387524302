import { createSlice } from '@reduxjs/toolkit';

export const addEditParentModalSlice = createSlice({
  name: 'addEditParentModal',
  initialState: {
    showModal: false,
    parent_id: 0,
    isEditing: false,
  },
  reducers: {
    setAddEditParentModal: (state, action) => {
      state.showModal = action.payload.showModal;
      state.parent_id = action.payload.parent_id;
      state.isEditing = action.payload.isEditing;
    },
  },
});

export const { setAddEditParentModal } =
  addEditParentModalSlice.actions;
export default addEditParentModalSlice.reducer;
