import { toast } from 'react-toastify';
import { t } from './translation_helper';
/**
 * Set Value for Text Field
 * @param {String} field Name of the field
 * @param {String} data Data to set for the field
 * @param {Object} thisObj Object that field exist in
 */
export const setTxtField = (field, data, thisObj) => {
  thisObj.setState({
    [field]: data,
  });
};

/**
 * Set Value for Switch Field
 * @param {String} field
 * @param {Integer} data
 * @param Object} thisObj
 */
export const setSwitchField = (field, data, thisObj) => {
  thisObj.setState({
    [field]: data == 1 ? 0 : 1,
  });
};

/**
 * Handle Form Errors
 * @param {Object} errorData
 */
export const handleFormErrors = (errorData) => {
  const errors = [];

  for (const key in errorData) {
    errors.push(errorData[key]);
  }

  const Msg = ({ closeToast, toastProps }) => (
    <div>
      {t('Error!')}
      <ul style={{ paddingLeft: 15, marginBottom: 0 }}>
        {errors.length === 0 &&
          <li>{t('Something went wrong!')}</li>
        }
        {errors.map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ul>
    </div>
  );
  toast.error(<Msg />);
};


export const readFile = (selectedFile) => {
  return new Promise(function (resolve, reject) {
    let reader = new FileReader();
    const allowedFiles = ['application/pdf', 'image/jpeg', 'image/jpg', 'image/png', 'audio/x-m4a', 'audio/mp3', 'audio/mp4'];
    const allowedFilesDisplay = ['PDF', 'Image'];
    const allowedSize = 5000000;

    if (selectedFile.size) {

      if (selectedFile.size >= allowedSize) {
        toast.error(t('Max file size allowed is ' + allowedSize / 1000000 + ' MB!'));
        resolve({ type: 'error' });
        return;
      } else if (!allowedFiles.includes(selectedFile.type)) {
        toast.error(t('Allowed file type are ' + allowedFilesDisplay.toString()));
        resolve({ type: 'error' });
        return;
      } else {
        if (selectedFile.type.includes('image')) {
          reader.readAsDataURL(selectedFile);
        } else if (selectedFile.type.includes('audio')) {
          reader.readAsArrayBuffer(selectedFile);
        } else {
          reader.readAsText(selectedFile);
        }
      }

      reader.onload = async (readerEvent) => {
        resolve({ type: 'success', data: selectedFile, url: readerEvent.target.result });
      };
      reader.onerror = (error) => {
        resolve({ type: 'error' });
      };
    } else {
      resolve({ type: 'error' });
    }
  });
};