import React, { Component } from 'react';
import { Card, Col, Badge } from 'react-bootstrap';
import { t } from '../../helpers/translation_helper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEdit,
  faEllipsisV,
  faTrashAlt,
} from '@fortawesome/free-solid-svg-icons';
import { color9 } from '../../helpers/brand_colors_helper';
import { NavLink, Link } from 'react-router-dom';
import SweetAlert from 'react-bootstrap-sweetalert';

// Vars
const apiURL = process.env.REACT_APP_API_URL;
const portalURL = process.env.REACT_APP_PORTAL_URL;
const controller = 'academic-classmain-subject';
const imgDir = 'academic_classmain_subject';

/**
 * Subject Card
 */
class SubjectCard extends Component {
  // States
  state = {
    actions: false,
    delAlert: false,
  };

  /**
   * Render Component
   * @returns {HTMLElement}
   */
  render() {
    const { actions, delAlert } = this.state;
    const {
      acs_code,
      acs_coefficient,
      acs_desc,
      acs_id,
      acs_image,
      acs_mandatory,
      acs_name,
      acs_sessions_per_week,
      acs_status,
      acs_url,
      as_id,
      classmain_id,
      published,
      tp_id,
      tt_id,
    } = this.props.data;
    const { center_id } = this.props;

    return (
      <Col md={3} className='d-flex align-items-stretch' key={this.props.index}>
        <Card className='border-0 subject-card'>
          <Card.Body>
            <Link
              to={'/weekly-lesson-plans/' + acs_id + '/' + tp_id + '/' + tt_id + '/' + acs_name}
              state={{
                as_id,
                acs_id,
              }}>
              <div
                style={{
                  backgroundImage: `url(${acs_image
                    ? portalURL +
                    center_id +
                    '/' +
                    imgDir +
                    '/' +
                    acs_image
                    : 'https://via.placeholder.com/300x150?text=No+Thumbnail'
                    })`,
                }}
                className='subject-image'></div>
            </Link>
            <div className='subject-card-header'>
              <div className='subject-basic-info'>
                <strong>{acs_name}</strong> <span className='text-muted'>{acs_code}</span>{' '}
              </div>
              <div className='subject-status'>
                <Badge
                  bg={
                    acs_status == 1
                      ? 'primary'
                      : 'secondary'
                  }>
                  {acs_status == 1
                    ? t('Active')
                    : t('Inactive')}
                </Badge>
              </div>
            </div>
            <div className='subject-partial-info'>
              {/* {acs_url && (
                <p className='subject-url'>
                  <a href={acs_url} target='_blank'>
                    {acs_url}
                  </a>
                </p>
              )} */}
            </div>
          </Card.Body>
        </Card>
      </Col>
    );
  }
}

export default SubjectCard;
