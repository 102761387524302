import React, { Component } from 'react';
import { Row, Col, Button, Spinner, Modal, Form, Table } from 'react-bootstrap';
import { t } from '../../../helpers/translation_helper';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCircle,
  faCircleCheck,
  faPenToSquare,
  faPlus,
} from '@fortawesome/free-solid-svg-icons';
import { GET, POST } from '../../../api';
import { setEvalTopic } from '../../../redux/slices/evaluationSlice';
import { toast } from 'react-toastify';
import { handleFormErrors } from '../../../helpers/form_helpers';
import { Scrollbar } from 'react-scrollbars-custom';
import { brand_colors } from '../../../helpers/brand_colors_helper';

class Topics extends Component {
  defaultFormFields = {
    appraisaltopic_id: 0,
    appraisaltopic_code: '',
    appraisaltopic_desc: '',
    appraisaltopic_desc2: '',
  };

  state = {
    ...this.defaultFormFields,
    topics: [],
    working: false,
    isEditing: false,
    showFormModal: false,
  };

  componentDidUpdate(prevProps) {
    if (this.props.evalCat && prevProps.evalCat !== this.props.evalCat) {
      this.getTopics();
    } else if (
      !this.props.evalCat &&
      prevProps.evalCat !== this.props.evalCat
    ) {
      this.setState({ topics: [] });
    }
  }

  getTopics = async () => {
    this.setState({ topics: null });

    try {
      const res = await GET('evaluation-setup/get-topics', {
        params: {
          appraisalcat_id: this.props.evalCat,
        },
      });

      if (res.status == 200) {
        this.setState({
          topics: res.data,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  topicCheck = (id) => {
    return this.props.evalTopic == id;
  };

  handleOpenFormModal = () => {
    this.setState({
      showFormModal: true,
    });
  };

  handleCloseFormModal = () => {
    this.setState({
      showFormModal: false,
      isEditing: false,
      ...this.defaultFormFields,
    });
  };

  saveUpdate = async () => {
    const { authData } = this.props;
    const { center_id } = authData.centerData;

    this.setState({ working: true });

    const {
      appraisaltopic_id,
      appraisaltopic_code,
      appraisaltopic_desc,
      appraisaltopic_desc2,
    } = this.state;

    const fData = new FormData();
    fData.append('center_id', center_id);
    fData.append(
      'evalTopicData',
      JSON.stringify({
        appraisaltopic_id,
        appraisalcat_id: this.props.evalCat,
        appraisaltopic_code,
        appraisaltopic_desc,
        appraisaltopic_desc2,
      })
    );

    try {
      const res = await POST('evaluation-setup/save-topic', fData);
      if (res.data.type) {
        this.setState({ working: false });
        toast.success(t(res.data.message));
        this.getTopics();
        this.handleCloseFormModal();
      } else {
        // toast.error(t(res.data.message));
        handleFormErrors(res.data.data);
        this.setState({ working: false });
      }
    } catch (err) {
      console.log('err', err);
      toast.error(t('Something went wrong!'));

      this.setState({ working: false });
    }
  };

  edit = (data) => {
    this.setState({
      isEditing: true,
      appraisaltopic_id: data.appraisaltopic_id,
      appraisaltopic_code: data.appraisaltopic_code,
      appraisaltopic_desc: data.appraisaltopic_desc,
      appraisaltopic_desc2: data.appraisaltopic_desc2,
    });
    this.handleOpenFormModal();
  };

  delete = async (data) => {
    try {
      const res = await GET('evaluation-setup/delete-group', {
        params: {
          appraisalgrp_id: data.appraisalgrp_id,
        },
      });
      if (res.data.type) {
        this.setState({
          groups: this.state.groups.filter(
            (item) => item.appraisalgrp_id !== data.appraisalgrp_id
          ),
        });
        toast.success(t(res.data.message));
        this.props.setEvalGroup(null);
      } else {
        toast.error(t(res.data.message));
      }
    } catch (err) {
      console.log(err);
      toast.error(t('Something went wrong!'));
    }
  };

  render() {
    return (
      <div>
        <div className='grey-section-header px-0'>
          <h5>
            <span className='me-1'>{t('Topics')}</span>
            <span
              className='mx-1'
              style={{ opacity: 0.6, fontWeight: 'normal' }}>
              |
            </span>
            <span
              className='mx-1'
              style={{
                opacity: 0.8,
                fontSize: '1rem',
                fontWeight: 'normal',
              }}>
              {t('Please select a Topic')}
            </span>
          </h5>
          <Button
            variant='success'
            size='sm'
            title={t('Add Topic')}
            disabled={!this.props.evalCat}
            onClick={this.handleOpenFormModal}>
            <FontAwesomeIcon icon={faPlus} /> {t('Topic')}
          </Button>
        </div>
        <div
          style={{
            height: 250,
            overflowY: 'auto',
          }}>
          {this.state.topics == null && (
            <div
              className='text-center p-4'
              style={{
                flex: 1,
              }}>
              <Spinner animation='grow' size='sm' className='me-2' />
              {t('Loading')}
            </div>
          )}
          {this.state.topics && this.state.topics.length == 0 && (
            <div
              className='text-center p-4'
              style={{
                flex: 1,
              }}>
              {t('No Data Found')}
            </div>
          )}
          {this.state.topics && (
            <Scrollbar style={{ width: '100%', height: '100%' }}>
              {this.state.topics.map((topic, topic_i) => (
                <div key={topic_i} className='scroll-list-card'>
                  <div className='d-flex flex-row justify-content-between align-items-center p-0 m-0'>
                    <div className='me-2'>
                      <FontAwesomeIcon
                        title={t('Edit Sub-Topic')}
                        onClick={() => this.edit(topic)}
                        style={{
                          cursor: 'pointer',
                        }}
                        icon={faPenToSquare}
                        color={
                          brand_colors[this.props.defaultTheme.theme_id].color16
                        }
                        className='ms-1'
                      />
                    </div>
                    <div className='me-2'>{topic.appraisaltopic_code}</div>
                  </div>
                  <div className='me-2'>
                    <Button
                      variant='light'
                      size='sm'
                      style={{
                        borderRadius: 15,
                        color: this.topicCheck(topic.appraisaltopic_id)
                          ? brand_colors[this.props.defaultTheme.theme_id]
                              .color4
                          : brand_colors[this.props.defaultTheme.theme_id]
                              .color18,
                        backgroundColor: this.topicCheck(
                          topic.appraisaltopic_id
                        )
                          ? brand_colors[this.props.defaultTheme.theme_id]
                              .color4_light
                          : brand_colors[this.props.defaultTheme.theme_id]
                              .color7,
                        borderColor: this.topicCheck(topic.appraisaltopic_id)
                          ? brand_colors[this.props.defaultTheme.theme_id]
                              .color4_light
                          : brand_colors[this.props.defaultTheme.theme_id]
                              .color7,
                      }}
                      onClick={() =>
                        this.props.setEvalTopic(
                          this.topicCheck(topic.appraisaltopic_id)
                            ? null
                            : topic.appraisaltopic_id
                        )
                      }>
                      <FontAwesomeIcon
                        icon={
                          this.topicCheck(topic.appraisaltopic_id)
                            ? faCircleCheck
                            : faCircle
                        }
                        color={
                          this.topicCheck(topic.appraisaltopic_id)
                            ? brand_colors[this.props.defaultTheme.theme_id]
                                .color4
                            : brand_colors[this.props.defaultTheme.theme_id]
                                .color8
                        }
                        className='me-1'
                      />
                      {t('Preview')}
                    </Button>
                  </div>
                </div>
              ))}
            </Scrollbar>
          )}
        </div>
        <Modal
          backdrop='static'
          keyboard={false}
          size={'lg'}
          show={this.state.showFormModal}
          onHide={this.handleCloseFormModal}>
          <Modal.Header closeButton>
            <Modal.Title>
              {this.state.isEditing ? t('Edit') : t('Add')} {t('Topic')}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className='custom-styled-form'>
            <Row>
              <Col md={4}>
                <Form.Group className='mb-3'>
                  <Form.Label>{t('Topic')}</Form.Label>
                  <Form.Control
                    type='text'
                    defaultValue={this.state.appraisaltopic_code}
                    onChange={(e) =>
                      this.setState({ appraisaltopic_code: e.target.value })
                    }
                  />
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group className='mb-3'>
                  <Form.Label>{t('Description')}</Form.Label>
                  <Form.Control
                    type='text'
                    defaultValue={this.state.appraisaltopic_desc}
                    onChange={(e) =>
                      this.setState({ appraisaltopic_desc: e.target.value })
                    }
                  />
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group className='mb-3'>
                  <Form.Label>{t('Description 2')}</Form.Label>
                  <Form.Control
                    type='text'
                    defaultValue={this.state.appraisaltopic_desc2}
                    onChange={(e) =>
                      this.setState({ appraisaltopic_desc2: e.target.value })
                    }
                  />
                </Form.Group>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' onClick={this.handleCloseFormModal}>
              {t('Close')}
            </Button>
            <Button
              disabled={this.state.working}
              variant='success'
              onClick={() => this.saveUpdate()}>
              {t('Save')}{' '}
              {this.state.working ? (
                <Spinner
                  as='span'
                  animation='grow'
                  size='sm'
                  role='status'
                  aria-hidden='true'
                />
              ) : (
                ''
              )}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  authData: state.auth.authData,
  defaultLanguage: state.language.defaultLanguage,
  evalGroup: state.evaluation.group,
  evalCat: state.evaluation.cat,
  evalTopic: state.evaluation.topic,
  defaultTheme: state.theme.defaultTheme,
});

const mapDispatchToProps = () => ({
  setEvalTopic,
});

export default connect(mapStateToProps, mapDispatchToProps())(Topics);
