/**
 * Lesson Planner Slice
 */
import { createSlice } from '@reduxjs/toolkit';

export const lessonPlannerSlice = createSlice({
    name: 'lessonPlanner',
    initialState: {
        lesson: undefined,
    },
    reducers: {
        setLesson: (state, action) => {
            state.data = action.payload;
        },
    },
});

export const { setLesson } = lessonPlannerSlice.actions;
export default lessonPlannerSlice.reducer;
