export const themes = [
  {
    theme_name: 'Primary Theme',
    theme_id: 'primary_theme'
  },
  {
    theme_name: 'Purple Theme',
    theme_id: 'purple_theme'
  },
  {
    theme_name: 'Navy Theme',
    theme_id: 'navy_theme'
  },
]