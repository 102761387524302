import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';

export const addEditReceiptModalSlice = createSlice({
    name: 'addEditReceiptModal',
    initialState: {
        showModal: false,
        title: 'New Receipt',
        child: null,
        todayDate: moment().format('YYYY-MM-DD'),
        editMode: false,
        feeregisterdetails: [],
        fee_type: null,
        fee_ref: '',
        selectedServicesData: [],
        selectedJournalTypeData: null,
        fee_desc: ''
    },
    reducers: {
        toggleReceiptModal: (state, action) => {
            state.showModal = !state.showModal
            state.title = (action.payload && action.payload.title) ? action.payload.title : 'New Registration'
            state.todayDate = (action.payload && action.payload.todayDate) ? action.payload.todayDate : moment().format('YYYY-MM-DD')
            state.child = (action.payload && action.payload.child) ? action.payload.child : null
            state.editMode = (action.payload && action.payload.editMode) ? action.payload.editMode : false
            state.feeregisterdetails = (action.payload && action.payload.feeregisterdetails) ? action.payload.feeregisterdetails : []
            state.fee_type = (action.payload && action.payload.fee_type) ? action.payload.fee_type : null
            state.fee_ref = (action.payload && action.payload.fee_ref) ? action.payload.fee_ref : ''
            state.fee_desc = (action.payload && action.payload.fee_desc) ? action.payload.fee_desc : ''
            state.selectedServicesData = (action.payload && action.payload.selectedServicesData) ? action.payload.selectedServicesData : []
            state.selectedJournalTypeData = (action.payload && action.payload.selectedJournalTypeData) ? action.payload.selectedJournalTypeData : null
        },
        updateChild: (state, action) => {
            state.child = (action.payload) ? action.payload : null
        }
    },
});

export const { toggleReceiptModal, updateChild } = addEditReceiptModalSlice.actions;
export default addEditReceiptModalSlice.reducer;
