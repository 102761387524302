import React, { Component, Fragment } from 'react';
import { Container, Card, Table, Row, Col, Spinner, Image, Badge, Button, Form, Offcanvas, Accordion, Modal, ButtonGroup } from 'react-bootstrap';
import { t } from '../../helpers/translation_helper';
import NavBar from '../_partials/NavBar/_NavBar';
import Header from '../_partials/Header/_Header';
import { connect } from 'react-redux';
import axios from 'axios';
import { toast } from 'react-toastify';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight, faCalendarAlt, faCheck, faClock, faEdit, faExternalLink, faFileAlt, faFileExcel, faGear, faPaperclip, faSave, faSearch, faTimes, faTrashCan, } from '@fortawesome/free-solid-svg-icons';
import { Scrollbar } from 'react-scrollbars-custom';
import imgDescription from '../../assets/images/description.png';
import { brand_colors } from '../../helpers/brand_colors_helper';
import SweetAlert from 'react-bootstrap-sweetalert';
import Select from 'react-select';
import { handleFormErrors } from '../../helpers/form_helpers';
import { GET } from '../../api';
import { Bar, Pie } from 'react-chartjs-2';
import { changeColorOpacity, DDL_ExamstypegrpTypes, getUrlParam } from '../../helpers/general_helpers';
import { useDownloadExcel } from 'react-export-table-to-excel';
import { exportExcel } from '../../helpers/table_helper';
import { useRef } from 'react';
var Chance = require('chance');
var chance = new Chance();

// API URL
const apiURL = process.env.REACT_APP_API_URL;

// Portal URL
const portalURL = process.env.REACT_APP_PORTAL_URL;

class ReportCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            centerlicData: { data: [], selected: null },
            termsData: { data: [], selected: [] },
            reRenderTime: moment(),
            reportData: {
                processing: false,
                data: {},
                finished: false
            },
            selectedStudent: null
        }

    }

    componentDidMount() {
        this.getCenterlic()
        let getUrlParamData = getUrlParam()
        if (getUrlParamData.id) {
            let findStudent = this.props.studentListData.find((v) => v.child_id == getUrlParamData.id)

            if (findStudent) {
                this.setState({ selectedStudent: findStudent })
            }
        }
    }

    getCenterlic = () => {
        try {
            if (this.getCenterlicReq) {
                this.getCenterlicReq.abort();
            }
            this.getCenterlicReq = new AbortController();
            GET('report-exams/get-centerlic', {
                signal: this.getCenterlicReq.signal,
            }).then((res) => {

                if (res) {
                    let selected = res.data.find((v) => v.reg_status == 1)
                    this.setState({ centerlicData: { ...this.state.centerlicData, data: res.data, selected: { value: selected.centerlic_id, label: selected.reg_year, reg_status: selected.reg_status } } }, () => this.getTerms())
                }
            })
        } catch (err) {
            console.log('err', err)
            toast.error(t('Something went wrong while deleting!'));

        }
    }
    getTerms = () => {
        try {
            if (this.getTermsReq) {
                this.getTermsReq.abort();
            }
            this.getTermsReq = new AbortController();
            GET('report-exams/get-terms', {
                signal: this.getTermsReq.signal,
            }).then((res) => {

                if (res) {
                    let selected = []
                    res.data.map((v, i) => {
                        if (v.centerlic_id == this.state.centerlicData.selected.value) {
                            selected.push({ value: v.term_id, label: v.term_name, term_active: v.term_active })
                        }
                    })
                    this.setState({ termsData: { ...this.state.termsData, data: res.data, selected: selected } })
                }
            })
        } catch (err) {
            console.log('err', err)
            toast.error(t('Something went wrong while deleting!'));

        }
    }

    fetchReport = () => {
        let reportData = this.state.reportData
        let centerlicData = this.state.centerlicData
        let selectedSubject = this.props.selectedSubject
        let termsData = this.state.termsData
        let selectedStudent = this.state.selectedStudent
        if (!this.props.selectedClass) {
            toast.error(t('No Class Selected'));
            return
        }
        if (!selectedSubject) {
            toast.error(t('No Subject Selected'));
            return
        }
        if (!centerlicData.selected) {
            toast.error(t('Please select a Year'));
            return
        }
        if (termsData.selected.length <= 0) {
            toast.error(t('Please select a Term'));
            return
        }
        if (!selectedStudent) {
            toast.error(t('Please select a Student'));
            return
        }


        this.setState({ reportData: { ...reportData, processing: true, data: {} } })

        try {
            if (this.fetchReportReq) {
                this.fetchReportReq.abort();
            }
            this.fetchReportReq = new AbortController();
            GET('academic-classmain-subject/get-student-report-card', {
                params: {
                    class_id: this.props.selectedClass.class.class_id,
                    agegroup_id: this.props.selectedClass.class.agegroup_id,
                    acs_ids: JSON.stringify([selectedSubject.acs_id]),
                    centerlic_id: centerlicData.selected.value,
                    term_ids: JSON.stringify(termsData.selected.map((v, i) => v.value)),
                    child_id: selectedStudent.child_id
                },
                signal: this.fetchReportReq.signal,
            }).then((res) => {
                if (res) {
                    this.setState({ reportData: { ...reportData, processing: false, data: res.data, finished: true }, reRenderTime: moment() })
                } else {
                    this.setState({ reportData: { ...reportData, processing: false, data: {} } })
                }
            })
        } catch (err) {
            console.log('err', err)
            toast.error(t('Something went wrong while deleting!'));

            this.setState({ reportData: { ...reportData, processing: false, data: {} } })
        }
    }

    render() {
        console.log('this.state', this.state);
        const { reportData, reRenderTime, centerlicData, termsData, selectedStudent } = this.state
        let filteredTerms = []
        if (centerlicData.selected) {
            filteredTerms = termsData.data.filter((v, i) => v.centerlic_id == centerlicData.selected.value)
        }
        return (
            <Container fluid>
                <div id='iedu-layout'>
                    <NavBar />
                    <div id='page-content'>
                        <Header lite={true} heading={t('Report Card')} backBtn={true} />
                        <div className='grey-section'>
                            <Row className='mb-2'>
                                {selectedStudent
                                    ? <Col className='d-flex flex-row align-items-center'>
                                        <div style={{
                                            backgroundImage: `url(${(selectedStudent.picture) ? portalURL + selectedStudent.center_id + '/children/' + selectedStudent.picture : 'https://via.placeholder.com/150x150?text=' + selectedStudent.child_name[0].toUpperCase()})`,
                                            width: 50, height: 50, backgroundSize: 'cover', backgroundPosition: 'center center', borderRadius: 30, marginRight: 15
                                        }}></div>
                                        <div>
                                            <div style={{ fontSize: 18, fontWeight: 'bold', color: brand_colors[this.props.defaultTheme.theme_id].color9, opacity: 0.8 }}>{selectedStudent.child_name}</div>
                                            <div style={{ fontSize: 12, fontWeight: 'bold', color: brand_colors[this.props.defaultTheme.theme_id].color9, opacity: 0.8 }}>{selectedStudent.class_theme}</div>
                                        </div>
                                    </Col>
                                    : <Col>
                                        <h4>{t('No Student Selected')}</h4>
                                    </Col>
                                }
                            </Row>
                            <Row className='justify-content-between'>
                                <Col>
                                    <Row>
                                        <Col>
                                            <Select
                                                placeholder={t('Select Year')}
                                                options={centerlicData.data.map((v, i) => { return { label: v.reg_year, value: v.centerlic_id, reg_status: v.reg_status } })}
                                                onChange={(event) => this.setState({ centerlicData: { ...centerlicData, selected: event }, termsData: { ...termsData, selected: [] } })
                                                }
                                                value={centerlicData.selected}
                                                menuPortalTarget={document.body}
                                                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                                formatOptionLabel={option => (
                                                    <div className='d-flex align-items-center'>
                                                        <span style={{ fontSize: 16 }} className='me-1'>{option.label}</span>
                                                        {option.reg_status == 1 && <Badge pill bg="success">{t('Active')}</Badge>}
                                                    </div>
                                                )}
                                            />
                                        </Col>
                                        <Col md="7">
                                            <Select
                                                isMulti
                                                isClearable
                                                placeholder={t('Select Term')}
                                                options={filteredTerms.map((v, i) => { return { label: v.term_name, value: v.term_id, term_active: v.term_active } })}
                                                onChange={(event) => this.setState({ termsData: { ...termsData, selected: event } })
                                                }
                                                value={termsData.selected}
                                                menuPortalTarget={document.body}
                                                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                                formatOptionLabel={option => (
                                                    <div className='d-flex align-items-center'>
                                                        <span style={{ fontSize: 16 }} className='me-1'>{option.label}</span>
                                                        {option.term_active == 1 && <Badge pill bg="success">{t('Active')}</Badge>}
                                                    </div>
                                                )}
                                            />
                                        </Col>
                                        <Col>
                                            <Button variant={'success'} onClick={() => this.fetchReport()}>
                                                {reportData.processing
                                                    ? <Spinner as='span' animation='grow' size='sm' role='status' aria-hidden='true' />
                                                    : <FontAwesomeIcon icon={faSearch} style={{ fontSize: 16, color: brand_colors[this.props.defaultTheme.theme_id].color8, }} />
                                                }
                                            </Button>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col md="auto">

                                </Col>
                            </Row>
                        </div>
                        <div className='grey-section'>

                            {reportData.processing
                                ? <div className='text-center'>
                                    <Spinner as='span' animation='grow' size='sm' role='status' aria-hidden='true' />
                                </div>
                                : <RenderReport reportData={reportData} termsData={termsData} authData={this.props.authData} defaultTheme={this.props.defaultTheme} reRenderTime={reRenderTime} selectedStudent={selectedStudent} />
                            }
                        </div>
                    </div>
                </div>

            </Container>
        );
    }
}

const mapStateToProps = (state) => ({
    authData: state.auth.authData,
    defaultLanguage: state.language.defaultLanguage,
    selectedClass: state.selectedClass.data,
    selectedSubject: state.selectedClass.subject,
    children: state.children.list,
    defaultTheme: state.theme.defaultTheme,
    studentListData: state.studentList.data,
});

export default connect(mapStateToProps, null)(ReportCard);


class RenderReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            DDL_examstypegrp_types: DDL_ExamstypegrpTypes(),
        }
    }


    shouldComponentUpdate(nextProps) {
        if (moment(nextProps.reRenderTime).isSame(moment(this.props.reRenderTime))) {

            return false
        }
        return true
    }


    render() {
        let reportData = this.props.reportData
        if (!reportData.finished) {
            return null
        }
        let selectedStudent = this.props.selectedStudent

        let totalColspan = []
        reportData.data.dataRubric.map((v) => {
            this.state.DDL_examstypegrp_types.map((vv) => {
                totalColspan.push(1)
            })
            totalColspan.push(1)
        })
        return (
            <div>
                <RenderTable totalColspan={totalColspan} reportData={reportData} selectedStudent={selectedStudent} />
            </div>
        )
    }
}



const RenderTable = (props) => {
    const totalColspan = props.totalColspan
    const reportData = props.reportData
    const selectedStudent = props.selectedStudent
    const DDL_examstypegrp_types = DDL_ExamstypegrpTypes()
    let gTotalCalcAverage = []

    const tableRef = useRef(null);

    const { onDownload } = useDownloadExcel({
        currentTableRef: tableRef.current,
        filename: selectedStudent.child_name + '(Report-Card)',
        sheet: 'S1'
    })

    return (
        <Card>
            <Card.Header>
                <Row>
                    <Col md={12}>
                        <ButtonGroup size='sm' className='me-3 float-left'>
                            {/* <Button><FontAwesomeIcon icon={faFilePdf} /> {t('PDF')}</Button> */}
                            <Button onClick={() => onDownload()}>
                                <FontAwesomeIcon icon={faFileExcel} /> {t('Excel')}
                            </Button>
                            {/* <Button><FontAwesomeIcon icon={faPrint} /> {t('Print')}</Button> */}
                        </ButtonGroup>
                    </Col>
                </Row>
            </Card.Header>
            <Card.Body>
                <Table size='sm' striped responsive bordered ref={tableRef}>
                    <thead>
                        <tr>
                            <th rowSpan={"2"}><div style={{ fontSize: 20 }}>{t('Subjects')}</div></th>
                            {/* TERMS */}
                            {reportData.data.dataRubric.map((v, i) => {
                                let count = 0
                                DDL_examstypegrp_types.map((vv, ii) => { count++ })
                                let rowOpacity = ((i % 2) == 0) ? 0 : 0.5
                                return <th key={i} colSpan={count + 1} style={{ backgroundColor: changeColorOpacity('#cccccc', rowOpacity) }}>
                                    {v.term['term_name']}
                                </th>
                            })}
                            <th rowSpan={"2"}><div style={{ fontSize: 20, textWrap: 'nowrap' }}>{t('Total Average')}</div></th>
                        </tr>
                        {/* GRP TYPE */}
                        <tr>
                            {reportData.data.dataRubric.map((v, i) => {
                                return <>
                                    {DDL_examstypegrp_types.map((vv, ii) => {
                                        return <th key={ii} style={{ backgroundColor: changeColorOpacity(vv?.color, 0.1) }}>{vv.value}</th>
                                    })}
                                    <th style={{}}>
                                        <div className='d-flex flex-column' style={{ textAlign: 'center' }}>
                                            <div>{t('Average')}</div>
                                        </div>
                                    </th>
                                </>

                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {reportData.data.academic_classmain_subject.map((v, i) => {
                            let totalCalcAverage = []
                            let exams_ids = [];
                            return <tr key={i}>
                                <td>
                                    <div style={{ fontWeight: '600', textWrap: 'nowrap', opacity: 0.9 }}>
                                        {v.acs_name}
                                    </div>
                                    <div class={(parseInt(v.acs_mandatory) == 1) ? 'badge badge-light-success' : 'badge badge-light-warning'}>{(parseInt(v.acs_mandatory) == 1) ? 'Mandatory' : 'Elective'}</div>
                                </td>
                                {reportData.data.dataRubric.map((vv, ii) => {
                                    let totalCalcAverageTerm = [];
                                    return <>{DDL_examstypegrp_types.map((this_examstypegrp_type, iii) => {
                                        let calc_average = 0
                                        let count_all_exams = 0

                                        vv.exams.map((vvvv, iiii) => {
                                            exams_ids.push(vvvv.exams_id);
                                            if (vvvv.examstypegrp_type == this_examstypegrp_type.value && v.acs_id == vvvv.acs_id) {
                                                let examsgrade = vv.examsgrade.find((vvvvv) => vvvvv.exams_id == vvvv.exams_id)
                                                count_all_exams++;
                                                if (examsgrade?.absence_id != 0) {
                                                    calc_average += parseFloat(0) / parseFloat(vvvv.exams_grade)
                                                } else {
                                                    calc_average += parseFloat(examsgrade?.examsgrade_grade) / parseFloat(vvvv.exams_grade)
                                                }
                                            }
                                        })
                                        console.log('count_all_exams', count_all_exams);
                                        console.log('calc_average', calc_average);
                                        console.log('calc_average / count_all_exams', calc_average / count_all_exams);
                                        console.log('exams_ids', exams_ids.join(','));
                                        if (count_all_exams > 0) {
                                            totalCalcAverageTerm.push((calc_average / count_all_exams) * 100)
                                            totalCalcAverage.push((calc_average / count_all_exams) * 100)
                                            gTotalCalcAverage.push((calc_average / count_all_exams) * 100)
                                        }

                                        return <td style={{ backgroundColor: changeColorOpacity(this_examstypegrp_type?.color, 0.05), fontSize: 18 }}>
                                            <div style={{ textWrap: 'nowrap' }}>{(count_all_exams > 0) && (((calc_average / count_all_exams) * 100).toFixed(2) + ' %')}</div>
                                        </td>
                                    })}
                                        <td>
                                            <div style={{ fontWeight: '600', textAlign: 'center', fontSize: 18, textWrap: 'nowrap' }}>{(totalCalcAverageTerm.length > 0) && ((totalCalcAverageTerm.reduce((p, c) => p + c) / totalCalcAverageTerm.length).toFixed(2) + ' %')} </div>
                                        </td>
                                    </>
                                })}
                                <td>
                                    <div style={{ fontWeight: '600', textAlign: 'center', fontSize: 18 }}>{(totalCalcAverage.length > 0) && ((totalCalcAverage.reduce((p, c) => p + c) / totalCalcAverage.length).toFixed(2) + ' %')} </div>
                                </td>
                            </tr>
                        })}

                    </tbody>
                    <tfoot>
                        <tr>
                            <th colSpan={totalColspan.length + 1} className='text-right'>
                                <div style={{ fontWeight: '600', fontSize: 20, textWrap: 'nowrap' }}>
                                    Total
                                </div>
                            </th>
                            <th>
                                <div style={{ fontWeight: '600', textAlign: 'center', fontSize: 18 }}>{(gTotalCalcAverage.length > 0) && ((gTotalCalcAverage.reduce((p, c) => p + c) / gTotalCalcAverage.length).toFixed(2) + ' %')} </div>
                            </th>
                        </tr>
                    </tfoot>
                </Table>
            </Card.Body>
        </Card >
    )
}