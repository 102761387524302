import React, { Component } from 'react';
import { Container, Card, Row, Col, Button, Badge, Modal, Form, Spinner } from 'react-bootstrap';
import { t } from '../../../helpers/translation_helper';
import NavBar from '../../_partials/NavBar/_NavBar';
import Header from '../../_partials/Header/_Header';
import { connect } from 'react-redux';
import Terms from '../../../components/Terms/_Terms';
import TimeSchedule from '../../../components/TimeSchedule/_TimeSchedule';
import SessionCategories from '../../../components/SessionCategories/_SessionCategories';
import { setUpdateSchoolModal } from '../../../redux/slices/updateSchoolModalSlice';
import Centerlic from "../../Centerlic/_Centerlic";


class RegistrationPeriods extends Component {

    render() {
        return (
            <Container fluid>
                <div id='iedu-layout'>
                    <NavBar />
                    <div id='page-content' style={{ width: '100%' }}>
                        <Header lite={true} heading={t('Registration Periods')} backBtn={true} />
                        <div className='d-flex flex-row mb-4'>
                            <div style={{ width: '49%' }}>
                               <div class='mb-4'>
                                   <Terms />
                               </div>
                                <Centerlic/>
                            </div>
                            <div style={{ width: '1.5%' }}></div>

                            <div style={{ width: '49%' }}>
                                <div className='mb-4'>
                                    <SessionCategories />
                                </div>
                                <TimeSchedule />
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        )
    }

}

const mapStateToProps = (state) => ({
    authData: state.auth.authData,
    defaultLanguage: state.language.defaultLanguage,
    defaultTheme: state.theme.defaultTheme
});

const mapDispatchToProps = () => ({
    setUpdateSchoolModal
});

export default connect(mapStateToProps, mapDispatchToProps())(RegistrationPeriods);
