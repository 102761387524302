import React, { Component } from 'react';
import { Container, Card, Row, Col, Button, Badge, Modal, Form, Spinner, Offcanvas, Alert, Table, Image, ButtonGroup } from 'react-bootstrap';
import { t } from '../../../helpers/translation_helper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faHomeUser, faPencil, faPlus, faArrowAltCircleLeft, faFilePdf, faTimesCircle, faCheck, faBan, faTrash, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { faCircle, faCheckCircle, faCircleDot, faComments } from '@fortawesome/free-regular-svg-icons';
import Select from 'react-select';
import { connect } from 'react-redux';
import { brand_colors } from '../../../helpers/brand_colors_helper';
import { toast } from 'react-toastify';
import { setAddEditClassModal } from '../../../redux/slices/addEditClassModalSlice';
import { handleFormErrors } from '../../../helpers/form_helpers';

import axios from 'axios';
import { GET } from '../../../api';
import { capitalizeFirstLetter, getExtension } from '../../../helpers/general_helpers';
var Chance = require('chance');
var chance = new Chance();

const apiURL = process.env.REACT_APP_API_URL;
const portalURL = process.env.REACT_APP_PORTAL_URL;


class AddEditClass extends Component {

  coverRefs = [];

  formSections = [
    {
      uid: chance.guid(),
      active: true,
      completed: false,
      name: 'General',
      desc: null,
      data: [
        {
          uid: chance.guid(),
          active: false,
          completed: false,
          name: 'Class',
          key: 'class_theme',
          desc: null,
          image: require('../../../assets/images/lesson/lesson-focus.png'),
        },
        {
          uid: chance.guid(),
          active: false,
          completed: false,
          name: 'Financial Group',
          key: 'classmain_id',
          desc: null,
          image: require('../../../assets/images/lesson/lesson-focus.png'),
        },
        {
          uid: chance.guid(),
          active: false,
          completed: false,
          name: 'Class Capacity',
          key: 'child_count',
          desc: null,
          image: require('../../../assets/images/lesson/lesson-focus.png'),
        },
        {
          uid: chance.guid(),
          active: false,
          completed: false,
          name: 'Educator Per Student',
          key: 'teacher_ratio',
          desc: null,
          image: require('../../../assets/images/lesson/lesson-focus.png'),
        },
        {
          uid: chance.guid(),
          active: false,
          completed: false,
          name: 'Online Class',
          key: 'class_online_url',
          desc: null,
          image: require('../../../assets/images/lesson/lesson-focus.png'),
        },
        {
          uid: chance.guid(),
          active: false,
          completed: false,
          name: 'Class Description',
          key: 'class_desc',
          desc: null,
          image: require('../../../assets/images/lesson/lesson-focus.png'),
        },
        {
          uid: chance.guid(),
          active: false,
          completed: false,
          name: 'Third Party Code',
          key: 'class_thirdparty',
          desc: null,
          image: require('../../../assets/images/lesson/lesson-focus.png'),
        },
        {
          uid: chance.guid(),
          active: false,
          completed: false,
          name: 'Class Image',
          key: 'class_logo',
          desc: null,
          image: require('../../../assets/images/lesson/lesson-focus.png'),
        },
        {
          uid: chance.guid(),
          active: false,
          completed: false,
          name: 'Status',
          key: 'status',
          desc: null,
          image: require('../../../assets/images/lesson/lesson-focus.png'),
        },
        {
          uid: chance.guid(),
          active: false,
          completed: false,
          name: 'Class Type',
          key: 'class_type',
          desc: null,
          image: require('../../../assets/images/lesson/lesson-focus.png'),
        },
      ],
    },
    {
      uid: chance.guid(),
      active: true,
      completed: false,
      name: 'Albums',
      desc: null,
      data: []
    },
    {
      uid: chance.guid(),
      active: true,
      completed: false,
      name: 'Daily Report Settings',
      desc: null,
      data: []
    },
    {
      uid: chance.guid(),
      active: true,
      completed: false,
      name: 'Educators',
      desc: null,
      data: []
    }
  ];

  dailyReport = [
    {
      mood_visible: {
        label: 'Mood',
        icon: require('../../../assets/images/daily-report/bear-happy.png'),
        checked: true,
        blocked: false,
      },
      food_visible: {
        label: 'Food',
        icon: require('../../../assets/images/daily-report/lunch-less-pink.png'),
        checked: true,
        blocked: false,
      },
      water_visible: {
        label: 'Water',
        icon: require('../../../assets/images/daily-report/water-less.png'),
        checked: true,
        blocked: false,
      },
      juice_visible: {
        label: 'Juice',
        icon: require('../../../assets/images/daily-report/juice-yellow-all.png'),
        checked: true,
        blocked: false,
      },
      need_diapers_visible: {
        label: 'Need Diapers',
        icon: require('../../../assets/images/daily-report/need-diaper.png'),
        checked: false,
        blocked: false,
      },
      need_milk_formula_visible: {
        label: 'Need Milk Formula',
        icon: require('../../../assets/images/daily-report/milk-multi.png'),
        checked: false,
        blocked: false,
      },
      need_clothes_visible: {
        label: 'Need Clothes',
        icon: require('../../../assets/images/daily-report/clothes.png'),
        checked: false,
        blocked: false,
      },
      need_wipes_visible: {
        label: 'Need Wipes',
        icon: require('../../../assets/images/daily-report/wipes.png'),
        checked: false,
        blocked: false,
      },
      need_cream_visible: {
        label: 'Need Cream',
        icon: require('../../../assets/images/daily-report/cream.png'),
        checked: false,
        blocked: false,
      },
      temperature_visible: {
        label: 'Take Temperature',
        icon: require('../../../assets/images/daily-report/temperature.png'),
        checked: false,
        blocked: false,
      },
      nap_visible: {
        label: 'Nap',
        icon: require('../../../assets/images/daily-report/nap.png'),
        checked: true,
        blocked: false,
      },
      potty_training_visible: {
        label: 'Potty Training',
        icon: require('../../../assets/images/daily-report/potty-training.png'),
        checked: false,
        blocked: false,
      },
      toilet_visits_visible: {
        label: 'Toilets Visits',
        icon: require('../../../assets/images/daily-report/toilet-visits.png'),
        checked: false,
        blocked: false,
      },
    },
    {
      bf_visible: {
        label: 'Breakfast',
        icon: require('../../../assets/images/daily-report/breakfast-more-green.png'),
        checked: true,
        blocked: false,
      },
      bf_comment_visible: {
        label: 'Comment',
        icon: require('../../../assets/images/daily-report/comment.png'),
        checked: false,
        blocked: false,
      },
      bt_visible: {
        label: 'Time',
        icon: require('../../../assets/images/daily-report/time.png'),
        checked: false,
        blocked: false,
      },
    },
    {
      snack1_visible: {
        label: 'Snack 1',
        icon: require('../../../assets/images/daily-report/snack.png'),
        checked: true,
        blocked: false,
      },
      snack1_comment_visible: {
        label: 'Comment',
        icon: require('../../../assets/images/daily-report/comment.png'),
        checked: false,
        blocked: false,
      },
      sn1t_visible: {
        label: 'Time',
        icon: require('../../../assets/images/daily-report/time.png'),
        checked: false,
        blocked: false,
      },
    },
    {
      snack2_visible: {
        label: 'PM Snack',
        icon: require('../../../assets/images/daily-report/snack2.png'),
        checked: false,
        blocked: false,
      },
      snack2_comment_visible: {
        label: 'Comment',
        icon: require('../../../assets/images/daily-report/comment.png'),
        checked: false,
        blocked: true,
      },
      sn2t_visible: {
        label: 'Time',
        icon: require('../../../assets/images/daily-report/time.png'),
        checked: false,
        blocked: true,
      },
    },
    {
      lunch_visible: {
        label: 'Lunch',
        icon: require('../../../assets/images/daily-report/lunch-more-pink.png'),
        checked: true,
        blocked: false,
      },
      lunch_comment_visible: {
        label: 'Comment',
        icon: require('../../../assets/images/daily-report/comment.png'),
        checked: false,
        blocked: false,
      },
      luncht_visible: {
        label: 'Time',
        icon: require('../../../assets/images/daily-report/time.png'),
        checked: false,
        blocked: false,
      },
    },
    {
      hygiene_visible: {
        label: 'Hygiene',
        icon: require('../../../assets/images/daily-report/hygiene-peepoop.png'),
        checked: true,
        blocked: false,
      },
      hgnt_visible: {
        label: 'Time',
        icon: require('../../../assets/images/daily-report/time.png'),
        checked: false,
        blocked: false,
      },
    },
    {
      milk_visible: {
        label: 'Milk',
        icon: require('../../../assets/images/daily-report/milk-multi.png'),
        checked: true,
        blocked: false,
      },
      milk_ml_visible: {
        label: 'ML',
        icon: require('../../../assets/images/daily-report/milk-multi.png'),
        checked: false,
        blocked: false,
      },
      milkt_visible: {
        label: 'Time',
        icon: require('../../../assets/images/daily-report/time.png'),
        checked: false,
        blocked: false,
      },
    },
  ];

  album = {
    album_id: 0,
    album_picture: '',
    album_picture_prev: '',
    album_picture_delete_flag: null,
    album_name: '',
    album_desc: '',
    classPicAsCover: false,
  }
  constructor(props) {
    super(props);
    this.state = {
      formSections: this.formSections,
      formSectionActive: {
        parent: this.formSections[0].uid,
        child: '1.1',
      },
      classmains: null,
      educators: null,
      subjects: [],
      educator: null,
      selectedSubjects: [],
      // General form fields
      class_theme: '',
      class_type: 'class',
      classmain_id: '',
      status: true,
      child_count: '',
      teacher_ratio: 0,
      class_online_url: '',
      class_desc: '',
      class_thirdparty: '',
      class_logo: null,
      // Daily report form fields
      dreport_gen_id: 0,
      dailyReport: this.dailyReport,
      // Albums
      albums: [],
      // Educators/Subjects
      educators_subjects: [],
      dragAreaColor: {
        class_logo: brand_colors[this.props.defaultTheme.theme_id].color18,
        toggleColor: (field, value) => this.setState({ dragAreaColor: { ...this.state.dragAreaColor, [field]: value } })
      },
      class_id: 0,
      classTypes: [
        { label: 'Class', value: 'class' },
        { label: 'Club', value: 'club' },
      ]
    }
    this.class_logo_ref = React.createRef();
  }

  componentDidMount() {
    this.getClassmain();
    this.getEducators();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.authData.centerData.center_id !== this.props.authData.centerData.center_id) {
      this.getClassmain();
      this.getEducators();
    }
    if (prevProps.agegroup_id !== this.props.agegroup_id && this.props.agegroup_id !== 0) {
      this.getAcademicSubjects();
    }
    if (prevProps.class_id !== this.props.class_id && this.props.class_id !== 0) {
      this.editClass();
    }
  }

  getClassmain = async () => {
    const { authData } = this.props;

    try {
      const res = await GET('classes/get-classmain');
      if (res.status == 200) {
        const classmains = res.data.map((v) => {
          v.value = v.classmain_id;
          v.label = v.classmain_name;
          return v;
        });

        this.setState({ classmains });
      }


    } catch (err) {
      console.log('err', err)
      toast.error(t('Something went wrong!'));

    }
  }

  getEducators = async () => {

    const { authData } = this.props;
    const { auth_key } = authData.loginData;
    const { center_id } = authData.centerData;

    try {
      const res = await GET('classes/get-educators');
      if (res.status == 200) {
        const educators = res.data.map((educator) => {
          educator.value = educator.employee_id;
          educator.label = educator.employee_name;
          educator.role_id = educator.role_id;
          educator.role_name = educator.role_name;
          return educator;
        });

        this.setState({ educators });
      }

    } catch (err) {
      console.log('err', err)
      toast.error(t('Something went wrong!'));

    }
  }

  getAcademicSubjects = async () => {

    const { authData } = this.props;
    const { auth_key } = authData.loginData;
    const { center_id } = authData.centerData;

    const request_params = {
      agegroup_id: this.props.agegroup_id,
    };

    try {
      const res = await GET('classes/get-academic-subjects', {
        params: request_params,
      });

      if (res.status == 200) {
        const subjects = res.data.map((subject) => {
          subject.value = subject.acs_id;
          subject.label = subject.acs_name;
          return subject;
        });

        this.setState({ subjects });
      }

    } catch (err) {
      console.log('err', err)
      toast.error(t('Something went wrong!'));

    }
  }

  checkCompletedFormSections = () => {
    let formSections = this.state.formSections;
    formSections = formSections.map((v, i) => {
      let data = v.data;
      let sectionComplete = true;
      data = data.map((vv, ii) => {
        let subSectionComplete = false;
        if (vv.key == 'classmain_id') {
          if (this.state[vv.key].value != '') {
            subSectionComplete = true;
          }
        }
        else if (this.state[vv.key] != '') {
          subSectionComplete = true;
        } else {
          subSectionComplete = false;
          sectionComplete = false;
        }
        return { ...vv, completed: subSectionComplete };

      });

      if (
        v.uid === this.formSections[1].uid
        || v.uid === this.formSections[2].uid
        || v.uid === this.formSections[3].uid
      ) {
        sectionComplete = false;
      }

      return { ...v, data, completed: sectionComplete };
    });
    this.setState({ formSections: formSections });
  };

  changeToFromSection = (parent, child) => {
    this.setState(
      {
        formSectionActive: { parent: parent, child: child },
      },
      () => {
        setTimeout(() => {
          const element = document.getElementById('form_section_' + parent);
          if (element) {
            // 👇 Will scroll smoothly to the top of the next section
            element.scrollIntoView({ behavior: 'smooth' });
          }
        }, 300);
      }
    );
  };

  handleClose = () => {
    this.props.setAddEditClassModal({ showModal: false, agegroup_id: 0, gcycle_id: 0, class_id: 0, isEditing: false });
    this.setState({
      formSections: this.formSections,
      formSectionActive: {
        parent: this.formSections[0].uid,
        child: '1.1',
      },
      educator: null,
      selectedSubjects: [],
      dailyReport: this.dailyReport,
      albums: [],
      classModal: true,
      class_theme: '',
      class_type: 'class',
      classmain_id: '',
      status: true,
      child_min_age: '',
      child_max_age: '',
      child_count: '',
      teacher_ratio: '',
      class_online_url: '',
      class_desc: '',
      class_thirdparty: '',
      class_logo: null,
      class_id: 0,
      dreport_gen_id: 0,
      educators_subjects: [],
    });
  }

  previewFile = (e) => {
    const reader = new FileReader();

    const selectedFile = e.target.files[0];

    if (selectedFile) {
      reader.readAsDataURL(selectedFile);
    }

    if (
      !selectedFile.type.includes('image')
    ) {
      toast.error(t('Only image file is allowed!'));
      return;
    }

    reader.onload = (readerEvent) => {
      if (selectedFile.type.includes('image')) {
        this.setState({
          [e.target.name + '_prev']: readerEvent.target.result,
          [e.target.name]: e.target.files[0],
        }, () => this.checkCompletedFormSections());
      }
    };
  };

  coverPreviewFile = (e, index) => {
    const reader = new FileReader();

    const selectedFile = e.target.files[0];

    if (selectedFile) {
      reader.readAsDataURL(selectedFile);
    }

    if (
      !selectedFile.type.includes('image')
    ) {
      toast.error(t('Only image file is allowed!'));
      return;
    }

    reader.onload = (readerEvent) => {
      if (selectedFile.type.includes('image')) {
        const albums = this.state.albums;
        albums[index].album_picture = e.target.files[0];
        albums[index].album_picture_prev = readerEvent.target.result;
        this.setState({ albums });
      }
    };
  };

  renderDrButtons = () => {
    let btns = [];
    this.state.dailyReport.map((item, index) => {
      for (const key in this.state.dailyReport[index]) {

        let label = this.state.dailyReport[index][key].label;
        let icon = this.state.dailyReport[index][key].icon;
        let checked = this.state.dailyReport[index][key].checked;
        let blocked = this.state.dailyReport[index][key].blocked;
        let color = brand_colors[this.props.defaultTheme.theme_id];
        let className = 'dr-btn-icon';
        if (checked) {
          className = 'dr-btn-icon checked';
        }
        if (blocked) {
          className = 'dr-btn-icon blocked';
        }

        if (key !== 'checked') {
          btns.push(<Col md={2} key={key} id={key} className='d-flex justify-content-center align-items-top'>
            <button className='dr-btn' onClick={() => blocked ? toast.error(t('This setting is blocked! Enable parent setting.')) : this.checkUncheckDrSetting(index, key)}>
              <div className={className}>
                {checked && <div className='dr-btn-check'><FontAwesomeIcon icon={faCheck} color={color.color8} /></div>}
                {blocked && <div className='dr-btn-blocked'><FontAwesomeIcon icon={faBan} color={'#dc3545'} /></div>}
                <div><img src={icon} style={{ opacity: checked ? 1 : 0.5 }} alt='Daily Report' /></div>
              </div>
              <small>{t(label)}</small>
            </button>
          </Col>)
        }
      }
      btns.push(<Col key={'sep-' + index} md={12}><hr /></Col>);
    });
    return btns;
  }

  checkUncheckDrSetting = (index, key) => {

    const dailyReport = JSON.parse(JSON.stringify(this.state.dailyReport));

    dailyReport[index][key] = {
      ...dailyReport[index][key],
      checked: !dailyReport[index][key].checked
    }

    // Food
    if (key === 'food_visible' && dailyReport[0].food_visible.checked) {
      dailyReport[1].bf_visible.checked = false;
      dailyReport[1].bf_visible.blocked = false;
      dailyReport[2].snack1_visible.checked = false;
      dailyReport[2].snack1_visible.blocked = false;
      dailyReport[3].snack2_visible.checked = false;
      dailyReport[3].snack2_visible.blocked = false;
      dailyReport[4].lunch_visible.checked = false;
      dailyReport[4].lunch_visible.blocked = false;
    }
    if (key === 'food_visible' && !dailyReport[0].food_visible.checked) {
      dailyReport[1].bf_visible.checked = false;
      dailyReport[1].bf_visible.blocked = true;
      dailyReport[1].bf_comment_visible.checked = false;
      dailyReport[1].bf_comment_visible.blocked = true;
      dailyReport[1].bt_visible.checked = false;
      dailyReport[1].bt_visible.blocked = true;
      dailyReport[2].snack1_visible.checked = false;
      dailyReport[2].snack1_visible.blocked = true;
      dailyReport[2].snack1_comment_visible.checked = false;
      dailyReport[2].snack1_comment_visible.blocked = true;
      dailyReport[2].sn1t_visible.checked = false;
      dailyReport[2].sn1t_visible.blocked = true;
      dailyReport[3].snack2_visible.checked = false;
      dailyReport[3].snack2_visible.blocked = true;
      dailyReport[3].snack2_comment_visible.checked = false;
      dailyReport[3].snack2_comment_visible.blocked = true;
      dailyReport[3].sn2t_visible.checked = false;
      dailyReport[3].sn2t_visible.blocked = true;
      dailyReport[4].lunch_visible.checked = false;
      dailyReport[4].lunch_visible.blocked = true;
      dailyReport[4].lunch_comment_visible.checked = false;
      dailyReport[4].lunch_comment_visible.blocked = true;
      dailyReport[4].luncht_visible.checked = false;
      dailyReport[4].luncht_visible.blocked = true;
    }

    // Breakfast
    if (key === 'bf_visible' && dailyReport[1].bf_visible.checked) {
      dailyReport[1].bf_comment_visible.checked = false;
      dailyReport[1].bf_comment_visible.blocked = false;
      dailyReport[1].bt_visible.checked = false;
      dailyReport[1].bt_visible.blocked = false;
    }
    if (key === 'bf_visible' && !dailyReport[1].bf_visible.checked) {
      dailyReport[1].bf_comment_visible.checked = false;
      dailyReport[1].bf_comment_visible.blocked = true;
      dailyReport[1].bt_visible.checked = false;
      dailyReport[1].bt_visible.blocked = true;
    }

    // Snack 1
    if (key === 'snack1_visible' && dailyReport[2].snack1_visible.checked) {
      dailyReport[2].snack1_comment_visible.checked = false;
      dailyReport[2].snack1_comment_visible.blocked = false;
      dailyReport[2].sn1t_visible.checked = false;
      dailyReport[2].sn1t_visible.blocked = false;
    }
    if (key === 'snack1_visible' && !dailyReport[2].snack1_visible.checked) {
      dailyReport[2].snack1_comment_visible.checked = false;
      dailyReport[2].snack1_comment_visible.blocked = true;
      dailyReport[2].sn1t_visible.checked = false;
      dailyReport[2].sn1t_visible.blocked = true;
    }

    // Snack 2
    if (key === 'snack2_visible' && dailyReport[3].snack2_visible.checked) {
      dailyReport[3].snack2_comment_visible.checked = false;
      dailyReport[3].snack2_comment_visible.blocked = false;
      dailyReport[3].sn2t_visible.checked = false;
      dailyReport[3].sn2t_visible.blocked = false;
    }
    if (key === 'snack2_visible' && !dailyReport[3].snack2_visible.checked) {
      dailyReport[3].snack2_comment_visible.checked = false;
      dailyReport[3].snack2_comment_visible.blocked = true;
      dailyReport[3].sn2t_visible.checked = false;
      dailyReport[3].sn2t_visible.blocked = true;
    }

    // Lunch
    if (key === 'lunch_visible' && dailyReport[4].lunch_visible.checked) {
      dailyReport[4].lunch_comment_visible.checked = false;
      dailyReport[4].lunch_comment_visible.blocked = false;
      dailyReport[4].luncht_visible.checked = false;
      dailyReport[4].luncht_visible.blocked = false;
    }
    if (key === 'lunch_visible' && !dailyReport[4].lunch_visible.checked) {
      dailyReport[4].lunch_comment_visible.checked = false;
      dailyReport[4].lunch_comment_visible.blocked = true;
      dailyReport[4].luncht_visible.checked = false;
      dailyReport[4].luncht_visible.blocked = true;
    }

    // Hygiene
    if (key === 'hygiene_visible' && dailyReport[5].hygiene_visible.checked) {
      dailyReport[5].hgnt_visible.checked = false;
      dailyReport[5].hgnt_visible.blocked = false;
    }
    if (key === 'hygiene_visible' && !dailyReport[5].hygiene_visible.checked) {
      dailyReport[5].hgnt_visible.checked = false;
      dailyReport[5].hgnt_visible.blocked = true;
    }

    // Milk
    if (key === 'milk_visible' && dailyReport[6].milk_visible.checked) {
      dailyReport[6].milk_ml_visible.checked = false;
      dailyReport[6].milk_ml_visible.blocked = false;
      dailyReport[6].milkt_visible.checked = false;
      dailyReport[6].milkt_visible.blocked = false;
    }
    if (key === 'milk_visible' && !dailyReport[6].milk_visible.checked) {
      dailyReport[6].milk_ml_visible.checked = false;
      dailyReport[6].milk_ml_visible.blocked = true;
      dailyReport[6].milkt_visible.checked = false;
      dailyReport[6].milkt_visible.blocked = true;
    }

    this.setState({ dailyReport });
  }

  addAlbum = () => {
    const albums = this.state.albums;
    albums.push(JSON.parse(JSON.stringify(this.album)));
    this.setState({ albums });
  }

  removeAlbum = (index) => {
    const albums = this.state.albums;
    albums.splice(index, 1);
    this.setState({ albums });
  }

  addEducatorSubjects = () => {

    let educators_subjects = []
    // if (this.state.educator == null || this.state.selectedSubjects.length == 0) {
    // Sir Moustafa asked to remove the subject condition here 👆 @ 25-04-24 - 12:20PM
    if (this.state.educator == null) {
      toast.error(t('Educator is required!'));
      return;
    }

    if (this.state.selectedSubjects.length > 0) {
      educators_subjects = this.state.selectedSubjects.map((subject) => {
        return { educator: this.state.educator, subject, acs_id: subject.acs_id, employee_id: this.state.educator.employee_id }
      });
    } else {
      educators_subjects = [{
        educator: this.state.educator,
        subject: null,
        acs_id: null,
        employee_id: this.state.educator.employee_id
      }]
    }


    this.setState({
      educator: null,
      selectedSubjects: [],
      educators_subjects: [...this.state.educators_subjects, ...educators_subjects]
    });
  }

  removeEducatorSubject = (index) => {
    const educators_subjects = this.state.educators_subjects;
    educators_subjects.splice(index, 1);
    this.setState({ educators_subjects });
  }

  handleDragFileArea = (e, name) => {
    e.preventDefault();
    e.stopPropagation();

    if (e.type === "dragover") {
      this.state.dragAreaColor.toggleColor(name, brand_colors[this.props.defaultTheme.theme_id].color1)
    } else if (e.type === "dragleave") {
      this.state.dragAreaColor.toggleColor(name, brand_colors[this.props.defaultTheme.theme_id].color18)
    }
  }

  saveUpdateClass = async () => {

    this.setState({ working: true });

    const { authData } = this.props;
    const { auth_key } = authData.loginData;
    const { center_id, center_timezone } = authData.centerData;

    const {
      class_theme,
      class_type,
      classmain_id,
      status,
      child_count,
      teacher_ratio,
      class_online_url,
      class_desc,
      class_thirdparty,
      class_logo,
      class_id,
      educators_subjects,
      albums,
      dreport_gen_id,
    } = this.state;

    const fData = new FormData();
    fData.append('center_id', center_id);
    fData.append('center_timezone', center_timezone);
    fData.append('agegroup_id', this.props.agegroup_id);
    fData.append('class_id', class_id);
    if (class_logo) {
      if (class_logo.data) {
        fData.append('class_logo', class_logo.data);
      } else {
        fData.append('class_logo', class_logo);
      }
    }
    fData.append('Classes', JSON.stringify({ center_id, center_timezone, class_theme, class_type, classmain_id: classmain_id.value, status: status ? 1 : 0, child_count, teacher_ratio, class_online_url, class_desc, class_thirdparty, agegroup_id: this.props.agegroup_id }));

    // If gcycle_id = Kindergarten (1)
    if (this.props.gcycle_id == 1) {
      fData.append('dreport_gen_id', dreport_gen_id)
    }

    // Albums
    fData.append('Albums', JSON.stringify(albums.map(({ album_id, album_picture, album_name, album_desc, album_picture_delete_flag }) => {
      return { album_id, album_picture: album_picture_delete_flag, album_name, album_desc };
    })));
    albums.map(({ album_picture }, index) => {
      if (album_picture != '') {
        fData.append(`album_picture[${index}]`, album_picture);
      }
    });

    // Daily Report
    const DrObj = {};
    this.state.dailyReport.map((drgrp) => {
      for (const dr_key in drgrp) {
        if (drgrp[dr_key].checked) {
          DrObj[dr_key] = 1
        }
      }
    });

    fData.append(`Dreportgeneral`, JSON.stringify(DrObj));

    // Assignment
    fData.append('Assignmentsch', JSON.stringify(educators_subjects.map(({ acs_id, employee_id }) => {
      return { acs_id, employee_id };
    })));


    try {
      const res = await axios.post(apiURL + 'classes/save', fData, {
        params: {
          auth_key,
          appname: process.env.REACT_APP_NAME,
        },
      });

      if (res.data.type) {
        // this.setState({ show: false });
        // this.props.callBack();
        // toast.success(t('Saved successfully!'));
        this.handleClose();
        this.props.hideShowClasses(this.props.agegroup_id, true, 'class');
      } else {
        handleFormErrors(res.data.data);
      }

      this.setState({ working: false });

    } catch (err) {
      console.log('err', err)

      this.setState({ working: false });
      toast.error(t('Something went wrong!'));
    }
  }

  editClass = async () => {

    const { authData } = this.props;
    const { auth_key } = authData.loginData;
    const { center_id } = authData.centerData;

    const request_params = {
      class_id: this.props.class_id,
    };

    try {
      const res = await GET('classes/edit-class', {
        params: request_params,
      });

      if (res.status == 200) {



        // Albums
        const albums = res.data.albums.map((album) => {
          return {
            album_id: album.album_id,
            album_picture: album.album_picture,
            album_picture_prev: portalURL + album.center_id + '/albums/' + album.album_picture,
            album_picture_delete_flag: album.album_picture,
            album_name: album.album_name,
            album_desc: album.album_desc,
            classPicAsCover: false,
          }
        });

        // Daily Report
        let dailyReport = this.checkUncheckDrSettingEdit(res.data.dreportgeneral);

        // Assignment
        const educators_subjects = res.data.assignmentsch.map(({ acs_id, employee_id, acs_name, employee_name, role_name, role_id, parent_acs_name }) => {
          return {
            acs_id,
            employee_id,
            educator: {
              employee_name,
              role_name,
              role_id
            },
            subject: (acs_id) ? { acs_name, parent_acs_name } : null
          }
        });

        this.setState({
          class_id: res.data.classes.class_id,
          class_theme: res.data.classes.class_theme,
          class_type: res.data.classes.class_type,
          classmain_id: {
            value: res.data.classes.classmain_id,
            label: res.data.classes.classmain_name,
          },
          status: res.data.classes.status == 1 ? true : false,
          child_count: res.data.classes.child_count,
          teacher_ratio: res.data.classes.teacher_ratio,
          class_online_url: res.data.classes.class_online_url,
          class_desc: res.data.classes.class_desc,
          class_thirdparty: res.data.classes.class_thirdparty,
          class_logo: (res.data.classes.class_logo) ? { url: portalURL + res.data.classes.center_id + '/classes/' + res.data.classes.class_logo, data: { type: getExtension(res.data.classes.class_logo) } } : null,
          albums,
          dreport_gen_id: res.data.dreportgeneral ? res.data.dreportgeneral.dreport_gen_id : 0,
          dailyReport,
          educators_subjects,
        }, () => this.checkCompletedFormSections());

      }

    } catch (err) {
      console.log('err', err)
      toast.error(t('Something went wrong!'));

    }

  }

  checkUncheckDrSettingEdit = (dreportgeneral) => {

    const dailyReport = JSON.parse(JSON.stringify(this.state.dailyReport));
    dailyReport.map((item, index) => {
      for (const key in dailyReport[index]) {
        dailyReport[index][key] = {
          ...dailyReport[index][key],
          checked: dreportgeneral[key] == 1 ? true : false
        }

        // Food
        if (key === 'food_visible' && dailyReport[0].food_visible.checked) {
          dailyReport[1].bf_visible.blocked = false;
          dailyReport[2].snack1_visible.blocked = false;
          dailyReport[3].snack2_visible.blocked = false;
          dailyReport[4].lunch_visible.blocked = false;
        }
        if (key === 'food_visible' && !dailyReport[0].food_visible.checked) {
          dailyReport[1].bf_visible.blocked = true;
          dailyReport[1].bf_comment_visible.blocked = true;
          dailyReport[1].bt_visible.blocked = true;
          dailyReport[2].snack1_visible.blocked = true;
          dailyReport[2].snack1_comment_visible.blocked = true;
          dailyReport[2].sn1t_visible.blocked = true;
          dailyReport[3].snack2_visible.blocked = true;
          dailyReport[3].snack2_comment_visible.blocked = true;
          dailyReport[3].sn2t_visible.blocked = true;
          dailyReport[4].lunch_visible.blocked = true;
          dailyReport[4].lunch_comment_visible.blocked = true;
          dailyReport[4].luncht_visible.blocked = true;
        }

        // Breakfast
        if (key === 'bf_visible' && dailyReport[1].bf_visible.checked) {
          dailyReport[1].bf_comment_visible.blocked = false;
          dailyReport[1].bt_visible.blocked = false;
        }
        if (key === 'bf_visible' && !dailyReport[1].bf_visible.checked) {
          dailyReport[1].bf_comment_visible.blocked = true;
          dailyReport[1].bt_visible.blocked = true;
        }

        // Snack 1
        if (key === 'snack1_visible' && dailyReport[2].snack1_visible.checked) {
          dailyReport[2].snack1_comment_visible.blocked = false;
          dailyReport[2].sn1t_visible.blocked = false;
        }
        if (key === 'snack1_visible' && !dailyReport[2].snack1_visible.checked) {
          dailyReport[2].snack1_comment_visible.blocked = true;
          dailyReport[2].sn1t_visible.blocked = true;
        }

        // Snack 2
        if (key === 'snack2_visible' && dailyReport[3].snack2_visible.checked) {
          dailyReport[3].snack2_comment_visible.blocked = false;
          dailyReport[3].sn2t_visible.blocked = false;
        }
        if (key === 'snack2_visible' && !dailyReport[3].snack2_visible.checked) {
          dailyReport[3].snack2_comment_visible.blocked = true;
          dailyReport[3].sn2t_visible.blocked = true;
        }

        // Lunch
        if (key === 'lunch_visible' && dailyReport[4].lunch_visible.checked) {
          dailyReport[4].lunch_comment_visible.blocked = false;
          dailyReport[4].luncht_visible.blocked = false;
        }
        if (key === 'lunch_visible' && !dailyReport[4].lunch_visible.checked) {
          dailyReport[4].lunch_comment_visible.blocked = true;
          dailyReport[4].luncht_visible.blocked = true;
        }

        // Hygiene
        if (key === 'hygiene_visible' && dailyReport[5].hygiene_visible.checked) {
          dailyReport[5].hgnt_visible.blocked = false;
        }
        if (key === 'hygiene_visible' && !dailyReport[5].hygiene_visible.checked) {
          dailyReport[5].hgnt_visible.blocked = true;
        }

        // Milk
        if (key === 'milk_visible' && dailyReport[6].milk_visible.checked) {
          dailyReport[6].milk_ml_visible.blocked = false;
          dailyReport[6].milkt_visible.blocked = false;
        }
        if (key === 'milk_visible' && !dailyReport[6].milk_visible.checked) {
          dailyReport[6].milk_ml_visible.blocked = true;
          dailyReport[6].milkt_visible.blocked = true;
        }
      }
    })
    return dailyReport
  }

  /**
   * Preview Upload File
   * @returns
   */
  readFile = (selectedFile) => {
    return new Promise(function (resolve, reject) {
      let reader = new FileReader();
      const allowedFiles = ['image/jpeg', 'image/jpg', 'image/png',];// 'audio/x-m4a', 'audio/mp3', 'audio/mp4', 'application/pdf',
      const allowedFilesDisplay = ['Image'];//'PDF', 
      const allowedSize = 5000000;
      if (selectedFile.size) {
        if (selectedFile.size >= allowedSize) {
          toast.error(t('Max file size allowed is ' + allowedSize / 1000000 + ' MB!'));
          resolve({ type: 'error' });
          return;
        } else if (!allowedFiles.includes(selectedFile.type)) {
          toast.error(t('Allowed file type are ' + allowedFilesDisplay.toString()));
          resolve({ type: 'error' });
          return;
        } else {
          if (selectedFile.type.includes('image')) {
            reader.readAsDataURL(selectedFile);
          } else if (selectedFile.type.includes('audio')) {
            reader.readAsArrayBuffer(selectedFile);
          } else {
            reader.readAsText(selectedFile);
          }
        }
        reader.onload = async (readerEvent) => {
          resolve({ type: 'success', data: selectedFile, url: readerEvent.target.result });
        };
        reader.onerror = (error) => {
          resolve({ type: 'error' });
        };
      } else {
        resolve({ type: 'error' });
      }
    });
  };
  addAttachment = (e) => {
    const selectedFiles = e.target.files;
    let allFiles = [];
    for (let i = 0; i < selectedFiles.length; i++) {
      allFiles.push(this.readFile(selectedFiles[i]));
    }
    Promise.all(allFiles).then((values) => {
      let attachments = e.target.multiple ? this.state[e.target.name] : null;
      let selectedFiles = values;
      selectedFiles.map((v, i) => {
        if (v.data.type) {
          if (e.target.multiple) {
            attachments.push({ url: v.url, data: v.data });
          } else {
            attachments = { url: v.url, data: v.data };
          }
        }
      });
      this.setState({ [e.target.name]: attachments, dragAreaColor: { ...this.state.dragAreaColor, [e.target.name]: brand_colors[this.props.defaultTheme.theme_id].color18 } }, () => this.checkCompletedFormSections());
    });
  };

  render() {
    if (!this.props.addEditClassModal) {
      return null
    }

    let class_logo = this.state.class_logo
    console.log('this.props.gcycle_id', this.state);

    return (
      <Offcanvas
        show={this.props.addEditClassModal}
        onHide={this.handleClose}
        placement='bottom'
        style={{ height: '100%' }}>
        <Offcanvas.Header id='iedu-header' style={{ marginTop: '1rem', marginRight: '1rem', marginLeft: '1rem', marginBottom: 0 }}>
          <div className='d-flex flex-row'>
            <span className='header-back-btn' onClick={this.handleClose} style={{ marginRight: 10 }}>
              <FontAwesomeIcon icon={faArrowAltCircleLeft} color={brand_colors[this.props.defaultTheme.theme_id].color9} />
            </span>
            <Offcanvas.Title>{t('Class')}</Offcanvas.Title>
          </div>
          <div>
            <Button disabled={this.state.working} variant='success' onClick={() => this.saveUpdateClass()}>
              {t('Save')} {this.state.working ? <Spinner as='span' animation='grow' size='sm' role='status' aria-hidden='true' /> : ''}
            </Button>
          </div>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div
            className={'d-flex flex-row grey-section'}
            style={{
              overflow: 'initial',
              backgroundImage: 'url(https://academic.educore.io/static/media/pegboard-bg.9b84e4936acef29bbcd9.png)',
              backgroundRepeat: 'repeat',
              backgroundPosition: '10px 12px',
              overflow: 'hidden',
            }}
          >
            <div style={{ backgroundColor: brand_colors[this.props.defaultTheme.theme_id].color8, marginRight: 10, borderRadius: '20px', width: '250px', height: '100%', top: '5px', position: 'sticky', paddingLeft: 20 }}>
              <div style={{ paddingRight: '1.5rem', paddingBottom: '1.5rem', paddingTop: '1rem' }}>
                {this.state.formSections && this.state.formSections.map((v, i) => {
                  if (this.props.gcycle_id != 1 && v.name !== 'Daily Report Settings') {
                    return (
                      <div key={i}>
                        <div className='d-flex flex-row align-items-center' style={{ cursor: 'pointer', borderRadius: '20px', paddingLeft: '5px', paddingRight: '5px', backgroundColor: v.uid == this.state.formSectionActive.parent ? brand_colors[this.props.defaultTheme.theme_id].color19 : brand_colors[this.props.defaultTheme.theme_id].color8, zIndex: 1, position: 'relative' }} onClick={() => this.changeToFromSection(v.uid, v.uid + '.1')}>
                          <FontAwesomeIcon
                            icon={v.completed ? faCheckCircle : faCircle}
                            color={v.uid == this.state.formSectionActive.parent ? brand_colors[this.props.defaultTheme.theme_id].color20 : v.completed ? brand_colors[this.props.defaultTheme.theme_id].color4 : brand_colors[this.props.defaultTheme.theme_id].color9}
                            style={{
                              fontSize: 12,
                              marginRight: 5,
                              opacity: v.uid == this.state.formSectionActive.parent || v.completed ? 1 : 0.7,
                            }}
                          />
                          <span style={{ fontSize: 18, fontWeight: '600', color: v.uid == this.state.formSectionActive.parent ? brand_colors[this.props.defaultTheme.theme_id].color20 : brand_colors[this.props.defaultTheme.theme_id].color9, opacity: v.uid == this.state.formSectionActive.parent ? 1 : 0.7 }}>{v.name}</span>
                        </div>

                        <div style={{ paddingLeft: '1rem', borderLeft: 'solid 1px' + brand_colors[this.props.defaultTheme.theme_id].color18, marginLeft: '1.5rem' }}>
                          {v.data.map((vv, ii) => {
                            return (
                              <div key={ii} className='d-flex flex-row align-items-center' style={{ marginBottom: 5, marginTop: 2, cursor: 'pointer' }} onClick={() => this.changeToFromSection(v.uid, vv.uid)}>
                                <FontAwesomeIcon icon={vv.completed ? faCheckCircle : faCircle} color={vv.completed ? brand_colors[this.props.defaultTheme.theme_id].color4 : brand_colors[this.props.defaultTheme.theme_id].color18} style={{ fontSize: 12, marginRight: 5 }} />
                                <span style={{ fontSize: 14, color: vv.completed ? brand_colors[this.props.defaultTheme.theme_id].color4 : brand_colors[this.props.defaultTheme.theme_id].color18 }}>{vv.name}</span>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    );
                  }

                  if (this.props.gcycle_id == 1) {
                    return (
                      <div key={i}>
                        <div className='d-flex flex-row align-items-center' style={{ cursor: 'pointer', borderRadius: '20px', paddingLeft: '5px', paddingRight: '5px', backgroundColor: v.uid == this.state.formSectionActive.parent ? brand_colors[this.props.defaultTheme.theme_id].color19 : brand_colors[this.props.defaultTheme.theme_id].color8, zIndex: 1, position: 'relative' }} onClick={() => this.changeToFromSection(v.uid, v.uid + '.1')}>
                          <FontAwesomeIcon
                            icon={v.completed ? faCheckCircle : faCircle}
                            color={v.uid == this.state.formSectionActive.parent ? brand_colors[this.props.defaultTheme.theme_id].color20 : v.completed ? brand_colors[this.props.defaultTheme.theme_id].color4 : brand_colors[this.props.defaultTheme.theme_id].color9}
                            style={{
                              fontSize: 12,
                              marginRight: 5,
                              opacity: v.uid == this.state.formSectionActive.parent || v.completed ? 1 : 0.7,
                            }}
                          />
                          <span style={{ fontSize: 18, fontWeight: '600', color: v.uid == this.state.formSectionActive.parent ? brand_colors[this.props.defaultTheme.theme_id].color20 : brand_colors[this.props.defaultTheme.theme_id].color9, opacity: v.uid == this.state.formSectionActive.parent ? 1 : 0.7 }}>{v.name}</span>
                        </div>

                        <div style={{ paddingLeft: '1rem', borderLeft: 'solid 1px' + brand_colors[this.props.defaultTheme.theme_id].color18, marginLeft: '1.5rem' }}>
                          {v.data.map((vv, ii) => {
                            return (
                              <div key={ii} className='d-flex flex-row align-items-center' style={{ marginBottom: 5, marginTop: 2, cursor: 'pointer' }} onClick={() => this.changeToFromSection(v.uid, vv.uid)}>
                                <FontAwesomeIcon icon={vv.completed ? faCheckCircle : faCircle} color={vv.completed ? brand_colors[this.props.defaultTheme.theme_id].color4 : brand_colors[this.props.defaultTheme.theme_id].color18} style={{ fontSize: 12, marginRight: 5 }} />
                                <span style={{ fontSize: 14, color: vv.completed ? brand_colors[this.props.defaultTheme.theme_id].color4 : brand_colors[this.props.defaultTheme.theme_id].color18 }}>{vv.name}</span>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    );
                  }
                })}
              </div>
            </div>
            <Col className='custom-styled-form hide-scrollbar' style={{ backgroundColor: brand_colors[this.props.defaultTheme.theme_id].color8, marginLeft: 10, borderRadius: '20px', padding: '1rem', overflow: 'auto', height: '75vh' }}>
              {/* GENERAL */}
              {this.state.formSectionActive.parent == this.formSections[0].uid && (
                <div id={`form_section_${this.formSections[0].uid}`}>
                  <h5>{t('General')}</h5>
                  <Row>
                    <Col>
                      <Row>
                        <Col md={3}>
                          <Form.Group className="mb-3">
                            <Form.Label>{t('Class')}</Form.Label>
                            <Form.Control type="text"
                              defaultValue={this.state.class_theme}
                              onBlur={(e) => this.setState({ class_theme: e.target.value }, () => this.checkCompletedFormSections())} />
                          </Form.Group>
                        </Col>
                        <Col md={3}>
                          <Form.Group className="mb-3">
                            <Form.Label>{t('Financial Group')}</Form.Label>
                            <Select
                              value={this.state.classmain_id}
                              menuPortalTarget={document.body}
                              styles={{
                                menuPortal: (
                                  base
                                ) => ({
                                  ...base,
                                  zIndex: 9999,
                                }),
                              }}
                              options={this.state.classmains}
                              onChange={(obj) => this.setState({ classmain_id: obj }, () => this.checkCompletedFormSections())}
                            />
                          </Form.Group>
                        </Col>
                        <Col md={3}>
                          <Form.Group className="mb-3">
                            <Form.Label>{t('Class Capacity')}</Form.Label>
                            <Form.Control type="text"
                              defaultValue={this.state.child_count}
                              onBlur={(e) => this.setState({ child_count: e.target.value }, () => this.checkCompletedFormSections())} />
                          </Form.Group>
                        </Col>
                        {this.props.gcycle_id == 1 &&
                          <Col md={3}>
                            <Form.Group className="mb-3">
                              <Form.Label>{t('Educator Per Student')}</Form.Label>
                              <Form.Control type="text"
                                defaultValue={this.state.teacher_ratio}
                                onBlur={(e) => this.setState({ teacher_ratio: e.target.value }, () => this.checkCompletedFormSections())} />
                            </Form.Group>
                          </Col>
                        }
                        <Col md={3}>
                          <Form.Group className="mb-3">
                            <Form.Label>{t('Online Class')}</Form.Label>
                            <Form.Control type="text"
                              defaultValue={this.state.class_online_url}
                              onBlur={(e) => this.setState({ class_online_url: e.target.value }, () => this.checkCompletedFormSections())} />
                          </Form.Group>
                        </Col>
                        <Col md={3}>
                          <Form.Group className="mb-3">
                            <Form.Label>{t('Third Party Code')}</Form.Label>
                            <Form.Control type="text"
                              defaultValue={this.state.class_thirdparty}
                              onBlur={(e) => this.setState({ class_thirdparty: e.target.value }, () => this.checkCompletedFormSections())} />
                          </Form.Group>
                        </Col>
                        <Col md={3}>
                          <Form.Group className="mb-3">
                            <Form.Label>{t('Class Type')}</Form.Label>
                            <Select
                              options={this.state.classTypes}
                              value={{ value: this.state.class_type, label: capitalizeFirstLetter(this.state.class_type) }}
                              menuPortalTarget={document.body}
                              styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999, }) }}
                              onChange={(obj) => this.setState({ class_type: obj.value }, () => this.checkCompletedFormSections())}
                            />
                          </Form.Group>
                        </Col>
                        <Col md={3} style={{ paddingTop: 30 }}>
                          <Form.Group className="mb-3">
                            <Form.Check
                              type="switch"
                              id="status"
                              label={t('Status')}
                              onChange={() => this.setState({ status: !this.state.status }, () => this.checkCompletedFormSections())}
                              checked={this.state.status}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                    </Col>
                    <Col md={3}>
                      <div style={{ flexDirection: 'column' }} className='pic-prev d-flex justify-content-center mb-3' id='form_section_2.3'>
                        <div style={{ height: 175, padding: 10, overflow: 'hidden', borderStyle: 'dashed', borderColor: this.state.dragAreaColor.class_logo, borderWidth: 2, borderRadius: 20, position: 'relative' }} className='d-flex justify-content-center align-items-center'
                          onDragEnter={(e) => this.handleDragFileArea(e, 'class_logo')} onDragLeave={(e) => this.handleDragFileArea(e, 'class_logo')} onDragOver={(e) => this.handleDragFileArea(e, 'class_logo')} onDrop={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            if (e.dataTransfer.files && e.dataTransfer.files[0]) {
                              this.addAttachment({ target: { files: e.dataTransfer.files, multiple: false, name: 'class_logo' } })
                            }
                          }}>
                          {class_logo && class_logo != 'deleted' && (!class_logo.data.type.includes('pdf')) && (
                            <img src={class_logo.url} alt={t('Preview')} style={{ height: '100%' }} />
                          )}
                          {(class_logo == null || class_logo == 'deleted') &&
                            <button onClick={() => this.class_logo_ref.current.click()} style={{ background: 'none', border: 0, flex: 1 }}>
                              {/* <FontAwesomeIcon icon={faPlus} style={{ fontSize: 30, color: color18 }} /> */}
                              <span style={{ fontSize: 20, fontWeight: 'bold', color: brand_colors[this.props.defaultTheme.theme_id].color18 }}>{t('Custom Icon')}</span>
                            </button>
                          }
                          {class_logo && class_logo != 'deleted' && (
                            <FontAwesomeIcon
                              icon={faTrashCan}
                              style={{ fontSize: 14, color: brand_colors[this.props.defaultTheme.theme_id].color11, position: 'absolute', bottom: 6, right: 6, cursor: 'pointer', backgroundColor: brand_colors[this.props.defaultTheme.theme_id].color8, borderRadius: 100, padding: 3 }}
                              onClick={() => this.setState({ class_logo: 'deleted' })}
                            />
                          )}
                        </div>
                        <Form.Control className='d-none' ref={this.class_logo_ref} type='file' onChange={this.addAttachment} name='class_logo' />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>{t('Class Description')}</Form.Label>
                        <Form.Control as="textarea" rows={6}
                          defaultValue={this.state.class_desc}
                          onBlur={(e) => this.setState({ class_desc: e.target.value }, () => this.checkCompletedFormSections())} />
                      </Form.Group>
                    </Col>
                  </Row>
                </div>)}
              {/* ALBUMS */}
              {this.state.formSectionActive.parent == this.formSections[1].uid && (
                <div id={`form_section_${this.formSections[1].uid}`}>
                  <Row>
                    <Col md='auto'><h5>{t('Albums')}</h5></Col>
                    <Col className='text-end'>
                      <Button size='sm' variant="success" onClick={() => this.addAlbum()}><FontAwesomeIcon icon={faPlus} /></Button>
                    </Col>
                  </Row>
                  {this.state.albums.length > 0 ? this.state.albums.map((album, index) => (
                    <div key={index} className='my-4'>
                      <Row>
                        <Col md={4}>
                          <div
                            style={{
                              flexDirection:
                                'column',
                            }}
                            className='pic-prev d-flex justify-content-center'>
                            <div
                              style={{ borderRadius: 5, borderStyle: 'dashed', borderWidth: 2, borderColor: '#ced4da', height: 149, display: 'flex', overflow: 'hidden', }}>
                              {this.state.albums[index].album_picture &&
                                this.state.albums[index].album_picture_prev !==
                                '' ? (
                                <div
                                  className='d-flex justify-content-center align-items-center'
                                  style={{
                                    flex: 1,
                                    position:
                                      'relative',
                                  }}>
                                  <button
                                    style={{ padding: 3, border: 3, background: 'none', position: 'absolute', top: 0, right: 0, }}
                                    onClick={() => {
                                      const albums = this.state.albums;
                                      albums[index].album_picture = '';
                                      albums[index].album_picture_prev = '';
                                      albums[index].album_picture_delete_flag = false;
                                      this.setState({ albums });
                                      this.coverRefs[index].value =
                                        null;
                                    }}>
                                    <FontAwesomeIcon icon={faTimesCircle} color='rgb(220, 53, 69)' style={{ fontSize: 20, }} />
                                  </button>
                                  <img src={this.state.albums[index].album_picture_prev} alt={t('Preview')} width='100' />
                                </div>
                              ) : (
                                <button
                                  onClick={() =>
                                    this.coverRefs[index].click()
                                  }
                                  style={{
                                    background:
                                      'none',
                                    border: 0,
                                    flex: 1,
                                  }}>
                                  <FontAwesomeIcon icon={faPlus} style={{ fontSize: 30, }} />
                                </button>
                              )}
                            </div>
                            <Form.Control
                              className='d-none'
                              ref={ref => {
                                this.coverRefs[index] = ref
                              }}
                              type='file'
                              onChange={(e) => this.coverPreviewFile(e, index)}
                              name='cover'
                              accept='.jpg, .jpeg, .png'
                            />
                          </div>
                        </Col>
                        <Col md={8}>
                          <Row>
                            <Col md={6} className='mb-4'>
                              <Form.Control
                                type="text"
                                onBlur={(e) => {
                                  const albums = this.state.albums;
                                  albums[index].album_name = e.target.value;
                                  this.setState({ albums });
                                }}
                                defaultValue={this.state.albums[index].album_name}
                                placeholder={t('Album Name')} />
                            </Col>
                            <Col md={5} className='pt-2'>
                              <Form.Check
                                type="switch"
                                onChange={(e) => {
                                  const albums = this.state.albums;
                                  albums[index].classPicAsCover = !albums[index].classPicAsCover;
                                  this.setState({ albums });
                                }}
                                checked={this.state.albums[index].classPicAsCover}
                                label={t('Use class picture as album cover')}
                              />
                            </Col>
                            <Col md={1} className='text-end pt-1'><Button size='sm' variant="danger" onClick={() => this.removeAlbum(index)}><FontAwesomeIcon icon={faTrash} /></Button></Col>
                          </Row>
                          <Row>
                            <Col md={12}>
                              <Form.Control as="textarea"
                                onBlur={(e) => {
                                  const albums = this.state.albums;
                                  albums[index].album_desc = e.target.value;
                                  this.setState({ albums });
                                }}
                                defaultValue={this.state.albums[index].album_desc}
                                rows={3} placeholder={t('Album Description')} />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <hr />
                    </div>
                  )) : (
                    <Row className="justify-content-md-center">
                      <Col md={4} className='text-center'><Alert variant='info'>{t('If there is no album created by the user, our system will create a default album with the class name.')}</Alert></Col>
                    </Row>
                  )}
                </div>)}
              {/* DAILY REPORT SETTINGS */}
              {this.state.formSectionActive.parent == this.formSections[2].uid && (
                <div id={`form_section_${this.formSections[2].uid}`}>
                  <h5>{t('Daily Report')}</h5>
                  <Row>
                    {this.renderDrButtons()}
                  </Row>
                </div>)}
              {/* EDUCATORS */}
              {this.state.formSectionActive.parent == this.formSections[3].uid && (
                <div id={`form_section_${this.formSections[3].uid}`}>
                  <h5>{t('Educators')}</h5>
                  <Row>
                    <Col md={4}>
                      <Form.Group className="mb-3">
                        <Form.Label>{t('Educator')}</Form.Label>
                        <Select
                          closeMenuOnSelect={true}
                          isMulti={false}
                          options={this.state.educators}
                          onChange={(obj) => this.setState({ educator: obj })}
                          value={this.state.educator}
                          menuPortalTarget={document.body}
                          styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999, }) }}
                          formatOptionLabel={option => (
                            <div>
                              <span style={{ fontSize: 14 }}>{option.label}</span>
                              {option.role_name && <div>
                                <Badge bg={(option.role_id == '3') ? 'warning' : 'success'}>{option.role_name}</Badge>
                              </div>}
                            </div>
                          )}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={7}>
                      <Form.Group className="mb-3">
                        <Form.Label>{t('Subjects')}</Form.Label>
                        <ButtonGroup size="sm" className='ms-3'>
                          <Button variant="primary" onClick={() => this.setState({ selectedSubjects: this.state.subjects })}>Select All</Button>
                          <Button variant="warning" onClick={() => this.setState({ selectedSubjects: [] })}>De-Select All</Button>
                        </ButtonGroup>
                        <Select
                          closeMenuOnSelect={false}
                          isMulti
                          options={this.state.subjects}
                          onChange={(event) => {
                            this.setState({ selectedSubjects: event })
                          }}
                          value={this.state.selectedSubjects}
                          menuPortalTarget={document.body}
                          styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999, }) }}
                          formatOptionLabel={option => (
                            <div>
                              <span style={{ fontSize: 14 }}>{option.label}</span>
                              {option.parent_acs_name && <div>
                                <Badge bg='success'>Focus</Badge>
                                <span style={{ marginLeft: 2 }}>{option.parent_acs_name}</span>
                              </div>}
                            </div>
                          )}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={1} style={{ paddingTop: 23 }}>
                      <Button onClick={() => this.addEducatorSubjects()} className='w-100' variant='success'>Add</Button>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={8}>
                      <Table>
                        <thead>
                          <tr>
                            <th>{t('Educator')}</th>
                            {<th>{t('Subject')}</th>}
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.educators_subjects.length > 0 ? <>
                            {this.state.educators_subjects.map((item, index) => <tr key={index}>
                              <td>
                                <div>{item.educator.employee_name}</div>
                                <div><Badge bg={(item.educator.role_id != 3) ? "success" : "warning"}>{item.educator.role_name}</Badge></div>
                              </td>
                              <td>
                                {item.subject && <div>
                                  {item.subject.acs_name}
                                  {item.subject.parent_acs_name && <div>
                                    <Badge bg='success'>Focus</Badge>
                                    <span style={{ marginLeft: 2 }}>{item.subject.parent_acs_name}</span>
                                  </div>}
                                </div>}
                              </td>
                              <td align='right'>
                                <Image className='cursor-pointer' onClick={() => this.removeEducatorSubject(index)} src={require('../../../assets/images/remove-red.png')} style={{ width: '1.5rem', height: '1.5rem' }} />
                              </td>
                            </tr>)}
                          </> : <tr>
                            <td align='center' colSpan={4}>{t('No Data Available!')}</td>
                          </tr>}
                        </tbody>
                      </Table>
                    </Col>
                  </Row>
                </div>)}
            </Col>
          </div>
        </Offcanvas.Body >
      </Offcanvas >);
  }
}

const mapStateToProps = (state) => ({
  authData: state.auth.authData,
  defaultTheme: state.theme.defaultTheme,
  addEditClassModal: state.addEditClassModal.showModal,
  agegroup_id: state.addEditClassModal.agegroup_id,
  gcycle_id: state.addEditClassModal.gcycle_id,
  class_id: state.addEditClassModal.class_id,
  isEditing: state.addEditClassModal.isEditing,
});

const mapDispatchToProps = () => ({
  setAddEditClassModal,
});

export default connect(mapStateToProps, mapDispatchToProps())(AddEditClass);
