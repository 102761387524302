/**
 * Authorization Slice
 */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { GET } from '../../api';
import axios from 'axios';
let cancelToken;

// GETTING CALLED WHEN:
// CENTER IS CHANGED
// ON FIRST TIME WEBSITE LOADED
export const refreshLogin = createAsyncThunk('login/refresh-login', async (center_id = null) => {
    if (cancelToken) {
        cancelToken.abort();
    }
    cancelToken = new AbortController();
    try {
        let req_params = {}
        if (center_id) {
            req_params = { center_id }
        }
        const response = await GET('login/refresh-login', {
            params: req_params,
            signal: cancelToken.signal,
        });
        return {
            data: response.data
        };
    } catch (error) {
        if (axios.isCancel(error)) {

        } else {

        }
        return {
            data: {
                type: false
            }
        };
    }

})

export const authSlice = createSlice({
    name: 'auth',
    initialState: {
        authenticated: false,
        authData: {},
        firebaseToken: '',
        refreshing: false
    },
    reducers: {
        setAuthentication: (state, action) => {
            state.authData = action.payload;
            state.authenticated = true;
        },
        unsetAuthentication: (state) => {
            state.authData = {};
            state.authenticated = false;
            state.firebaseToken = '';
        },
        setFirebaseToken: (state, action) => {
            state.firebaseToken = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(refreshLogin.pending, (state, action) => {
            if (!state.refreshing) {
                state.refreshing = true;
            }
        })
        builder.addCase(refreshLogin.fulfilled, (state, action) => {
            console.log('res.type', state, state.refreshing, action);
            if (state.refreshing === true && action.payload) {
                let res = action.payload.data
                if (res.type) {
                    state.authData = res.data
                    state.refreshing = false;
                } else {
                    state.authenticated = false
                    state.authData = {}
                    state.refreshing = false;
                    window.location.reload();
                }
            }
        })
        builder.addCase(refreshLogin.rejected, (state, action) => {
            // console.log('refreshLogin.rejected', refreshLogin.rejected);
            // if (axios.isCancel(thrown)) {

            // } else 
            if (state.refreshing === true) {
                state.authenticated = false
                state.authData = {}
                state.refreshing = false;
                window.location.reload();
            }
        })
    }
});

export const { setAuthentication, unsetAuthentication, setFirebaseToken } =
    authSlice.actions;
export default authSlice.reducer;
