import React, { Component } from 'react';
import { Container, Card, Row, Col, Button, Tab, Tabs, Table, Badge, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDashboard, faUsers, faAddressCard, faUserTie, faChevronDown, faSchool, faInfoCircle, faEye } from '@fortawesome/free-solid-svg-icons';
import { t } from '../../../../helpers/translation_helper';

import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
} from 'chart.js';
import { Doughnut, Bar } from 'react-chartjs-2';
import { connect } from 'react-redux';
import { brand_colors } from '../../../../helpers/brand_colors_helper';
import axios from 'axios';
import { toast } from 'react-toastify';
import { LoadingPlaceholder } from '../../../_common/Loading/Loading';
import { GET } from '../../../../api';

// API URL
const apiURL = process.env.REACT_APP_API_URL;

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const barChartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
      position: 'bottom',
    },
  },
};

class Admissions extends Component {
  state = {
    reportAdmissionStatusData: null
  }

  componentDidMount() {
    this.reportAdmissionStatus();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.authData.centerData.center_id !== this.props.authData.centerData.center_id) {
      this.reportAdmissionStatus();
    }
  }

  reportAdmissionStatus = async () => {
    const { authData } = this.props;
    const { auth_key } = authData.loginData;
    const { center_id } = authData.centerData;

    try {
      const res = await GET('admin/report-admission-status');

      if (res.status == 200) {

        let reportAdmissionStatusData = {
          labels: [],
          datasets: [
            {
              data: [],
              backgroundColor: []
            }
          ]
        };

        reportAdmissionStatusData.labels = res.data.admission_status;
        reportAdmissionStatusData.datasets[0].data = res.data.admission_status_count;
        reportAdmissionStatusData.datasets[0].backgroundColor = [
          brand_colors[this.props.defaultTheme.theme_id].color1,
          brand_colors[this.props.defaultTheme.theme_id].color2,
          brand_colors[this.props.defaultTheme.theme_id].color4
        ];

        this.setState({ reportAdmissionStatusData });

      }

    } catch (err) {
      console.log('err', err)

      toast.error(t('Something went wrong!'));
    }
  }

  render() {

    // const labels = ['New', 'In Review', 'Need More Details', 'Placement Test', 'Test Passed', 'Accepted', 'Registered', 'Rejected', 'Parent Review', 'Parent Submit', 'Form Sent To'];

    // const data = {
    //   labels,
    //   datasets: [
    //     {
    //       label: 'Registrations',
    //       data: [20, 65, 34, 69, 40, 35, 55, 34, 20, 65, 34, 69, 40, 35, 55, 34],
    //       backgroundColor: [
    //         brand_colors[this.props.defaultTheme.theme_id].color1,
    //         brand_colors[this.props.defaultTheme.theme_id].color2,
    //         brand_colors[this.props.defaultTheme.theme_id].color4,
    //         brand_colors[this.props.defaultTheme.theme_id].color16,
    //         brand_colors[this.props.defaultTheme.theme_id].color22,
    //       ],
    //     },
    //   ],
    // };

    return (<>
      <Row>
        <Col md={12}>
          <Card
            className='border-0'
            style={{ borderRadius: 20 }}>
            <Card.Body>
              <div>
                {this.state.reportAdmissionStatusData ? <Bar options={barChartOptions} data={this.state.reportAdmissionStatusData} height={430} /> : <LoadingPlaceholder height={430} />}
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>);
  }
}

const mapStateToProps = (state) => ({
  authData: state.auth.authData,
  defaultLanguage: state.language.defaultLanguage,
  defaultTheme: state.theme.defaultTheme,
});

export default connect(mapStateToProps, null)(Admissions);