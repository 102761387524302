import React, { Component } from 'react';
import { Container, Card, Row, Col, Spinner } from 'react-bootstrap';
import { t } from '../../helpers/translation_helper';
import NavBar from '../_partials/NavBar/_NavBar';
import Header from '../_partials/Header/_Header';
import { connect } from 'react-redux';
import SubjectCard from './SubjectCard';
import axios from 'axios';
import { toast } from 'react-toastify';
import Paginate from '../_partials/Paginate/_Paginate';
import { GET } from '../../api';
import GeneralCard from '../../helpers/GeneralCard';
import { brand_colors } from '../../helpers/brand_colors_helper';

const apiURL = process.env.REACT_APP_API_URL;
const portalURL = process.env.REACT_APP_PORTAL_URL;

class WeeklyLessonPlans extends Component {

  state = {
    academic_classmain_subject: [],
    // Paginate
    limit: 5,
    totalRecords: 0,
  }

  componentDidMount() {
    this.getSubjects();
  }

  getSubjects = async (limit = this.state.limit, offset = 0) => {
    const { authData, selectedClass } = this.props;
    const { employee_id } = authData.userData;
    const { class_id } = selectedClass.class;

    try {
      const res = await GET('timetable/get-class-subject-timetables', {
        params: {
          search: '',
          limit,
          offset,
          employee_id,
          class_id,
        },
      });

      if (res.status == 200) {
        this.setState({
          academic_classmain_subject: res.data.data,
          totalRecords: parseInt(res.data.total_count_filtered),
        });

      }
    } catch (err) {
      console.log('err', err)
      toast.error(t('Something went wrong while fetching academic subjects!'));

    }

  }

  render() {

    const { academic_classmain_subject, limit, totalRecords } = this.state;

    return (
      <Container fluid>
        <div id='iedu-layout'>
          <NavBar />
          <div id='page-content'>
            <Header lite={true} heading={t('Weekly Lesson Plans')} classSelectorFunc={[this.getSubjects]} showClassSelector={true} showCenterSelector={true} backBtn={true} />
            <Row className='me-0'>
              {academic_classmain_subject ? (
                <>
                  {academic_classmain_subject.length > 0 ? (
                    <>
                      {academic_classmain_subject.map((v, i) => {

                        let data = {
                          allData: v,
                          name: v.acs_name,
                          code: v.acs_code,
                          text1: (v.parent_acs_name) ? 'Focus:' : null,
                          text2: v.parent_acs_name,
                          status: (v.acs_status == 1) ? true : false,
                          description: v.acs_desc,
                          // linkTo: '/subject/lessons/' + v.acs_name,
                          // linkToStates: { as_id: v.as_id, acs_id: v.acs_id, ab_id: v.ab_id, },
                          linkTo: '/weekly-lesson-plans/' + v.acs_id + '/' + v.tp_id + '/' + v.tt_id + '/' + v.acs_name,
                          // onClick: () => this.props.setSubject(v),
                          cardImage: (v.acs_image) ? portalURL + v.center_id + '/academic_classmain_subject/' + v.acs_image : require('../../assets/images/noimage.png'),
                          actions: [
                            {
                              type: 'edit', name: 'Edit', image: require('../../assets/images/edit.png'), iconName: null, iconColor: brand_colors[this.props.defaultTheme.theme_id].color16, onClick: () => this.handleAddEditFormOpen(true, v)
                            },
                            {
                              type: 'delete', name: 'Delete', image: require('../../assets/images/remove-red.png'), iconName: null, iconColor: brand_colors[this.props.defaultTheme.theme_id].color11, onClick: () => this.setState({ deleteModal: { ...this.state.deleteModal, show: true, id: v.acs_id, action: () => { } } })
                            },
                          ],
                          extraTexts: [
                            { type: 'url', name: 'URL', value: v.acs_url, iconName: null, image: require('../../assets/images/hyperlink.png'), iconColor: brand_colors[this.props.defaultTheme.theme_id].color9, onClick: () => { } },
                            { type: 'text', name: 'Type', value: (v.acs_mandatory == 1) ? 'Mandatory' : 'Elective', icon: null, image: require('../../assets/images/asterisk.png'), iconColor: brand_colors[this.props.defaultTheme.theme_id].color9, onClick: () => { } }
                          ]
                        }

                        return <GeneralCard data={data} key={i} />
                        // <SubjectCard key={item} data={item} center_id={this.props.authData.centerData.center_id} defaultTheme={this.props.defaultTheme} />
                      })}
                    </>
                  ) : (
                    <Col md={12} className='pt-5 pb-4 pb-0 d-flex align-items-center flex-column'>
                      {t('No Subjects Found')}
                    </Col>
                  )}
                </>
              ) : (
                <Col md={12} className='pt-5 pb-0 d-flex align-items-center flex-column'>
                  <Spinner as='span' animation='grow' size='lg' role='status' aria-hidden='true' className='mb-1' />
                  {t('Loading Subjects...')}
                </Col>
              )}
            </Row>
            <div className='my-3'>
              <Paginate limit={limit} totalRecords={totalRecords} pageChange={this.getSubjects} />
            </div>
          </div>
        </div>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  authData: state.auth.authData,
  defaultLanguage: state.language.defaultLanguage,
  selectedClass: state.selectedClass.data,
  defaultTheme: state.theme.defaultTheme,
});

export default connect(mapStateToProps, null)(WeeklyLessonPlans);
