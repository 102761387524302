import React, { Component } from 'react';
import { Container, Card, Table, Badge, Button, Modal } from 'react-bootstrap';
import { t } from '../../helpers/translation_helper';
import NavBar from '../_partials/NavBar/_NavBar';
import Header from '../_partials/Header/_Header';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleDown,
  faAngleUp,
  faArrowDown,
  faCheck,
  faCircleInfo,
  faFilePdf,
  faPersonChalkboard,
  faRefresh,
  faTable,
  faUser,
  faXmark,
} from '@fortawesome/free-solid-svg-icons';
import { brand_colors } from '../../helpers/brand_colors_helper';
import { handleFormErrors } from '../../helpers/form_helpers';
import { colorPalette } from '../../constants/colorsPalette';
import { getUrlParam } from '../../helpers/general_helpers';
import HorizontalCard from '../../helpers/HorizontalCard';
import { GET, POST } from '../../api';
import Dragula from 'react-dragula';
import html2canvas from 'html2canvas';

var Chance = require('chance');
var chance = new Chance();

class TimetableDetailsGenerator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deleteModal: {
        show: false,
        id: 0,
        action: () => {},
        title: null,
        desc: null,
      },
      days: [
        { name: 'Sunday', active: true },
        { name: 'Monday', active: true },
        { name: 'Tuesday', active: true },
        { name: 'Wednesday', active: true },
        { name: 'Thursday', active: true },
        { name: 'Friday', active: true },
        { name: 'Saturday', active: true },
      ],
      timetable_period_timings: [],
      timetable: [],
      employee_timetable_availability: [],
      employees: {
        data: [],
        search: '',
        changeSearch: (search) =>
          this.setState({ employees: { ...this.state.employees, search } }),
      },
      timetableData: {
        classes: [],
        classOpen: [],
        agegroup: [],
        agegroup_classes_ids: [],
        toggleAgegroup: (agegroup_id) =>
          this.setState(
            {
              timetableData: {
                ...this.state.timetableData,
                agegroup_id,
                agegroup_classes_ids: this.state.timetableData.classes
                  .filter((item) => item.agegroup_id == agegroup_id)
                  .map((item) => item.class_id),
              },
            },
            () => {
              if (this.drake) {
                this.drake.destroy();
                this.Dragula();
              }
            }
          ),
        agegroup_id: null,
      },
      timetablePeriods: {
        tp_id: null,
      },
      timetableDetails: [],
      classes_assignments: [],
      tab: 0,
      generateTT: false,
      viewEmployeeTimetableAvailability: {
        show: false,
        employee_id: 0,
        employe_name: '',
        timetableData: {
          availability: [],
          timetables: [],
        },
      },
      slotEmployeesModal: {
        show: false,
        availableTeachers: [],
      },
      deleteETARecordList: [],
    };
    this.timetableDetails = [];
  }

  componentDidMount() {
    let getUrlParamData = getUrlParam();
    if (getUrlParamData.id) {
      let tp_id = getUrlParamData.id;
      this.setState({ timetablePeriods: { tp_id: tp_id } }, () => {
        this.getTimetableDetails();
      });
    }
    this.checkWeekendDays();
  }

  isSticky = (e) => {
    const scrollTop = e.target.scrollTop;
    const header = document.querySelector('#make-sticky');
    scrollTop >= 290
      ? header.classList.add('is-sticky')
      : header.classList.remove('is-sticky');
  };

  getTimetableDetails = async () => {
    let timetablePeriods = this.state.timetablePeriods;
    if (!timetablePeriods.tp_id) {
      return null;
    }
    try {
      const res = await GET('timetable/get-timetable-details', {
        params: {
          tp_id: timetablePeriods.tp_id,
        },
      });
      if (res.status == 200) {
        let resData = res.data;
        this.setState(
          {
            timetable_period_timings: resData.timetable_period_timings,
            timetable: resData.timetable,
            employees: {
              ...this.state.employees,
              data: resData.employees,
              search: '',
            },
            employee_timetable_availability:
              resData.employee_timetable_availability,
          },
          () => this.getTimetable()
        );
      }
    } catch (err) {
      console.log('err', err);
      toast.error(t('Something went wrong while fetching academic subjects!'));
    }
  };
  checkWeekendDays = () => {
    let weekend_days = this.props.authData.centerData.weekend_days;
    if (weekend_days) {
      weekend_days = weekend_days.split(',');
    }
    let days = this.state.days.map((v, i) => {
      if (weekend_days.includes(v.name.toLowerCase())) {
        return { ...v, active: false };
      }
      return v;
    });
    this.setState({ days });
  };
  getTimetable = async () => {
    let timetablePeriods = this.state.timetablePeriods;
    if (!timetablePeriods.tp_id) {
      return null;
    }
    try {
      const res = await GET('timetable/get-timetable2', {
        params: {
          tp_id: timetablePeriods.tp_id,
        },
      });
      if (res.status == 200) {
        let resData = res.data;

        resData.agegroup = resData.agegroup.map((v) => {
          let total_acs_sessions_per_week = v.academic_classmain_subject.reduce(
            (total, currentValue) => {
              return total + parseFloat(currentValue['acs_sessions_per_week']);
            },
            0
          );
          let total_periods =
            this.state.days.reduce((t, v) => {
              if (v.active) {
                return t + 1;
              }
              return t;
            }, 0) * this.state.timetable_period_timings.length;

          return {
            ...v,
            total_periods: total_periods,
            total_acs_sessions_per_week: total_acs_sessions_per_week,
          };
        });

        let merge_timetableDetails = [];
        resData.classes.map((v, i) => {
          merge_timetableDetails = [
            ...merge_timetableDetails,
            ...v.timetable_details,
          ];
        });
        this.timetableDetails = merge_timetableDetails;

        let classes_assignments = resData.classes_assignments;

        this.setState({
          timetableData: { ...this.state.timetableData, ...resData },
          classes_assignments,
        });
      }
    } catch (err) {
      console.log('err', err);
      toast.error(t('Something went wrong while fetching academic subjects!'));
    }
  };

  clickAndDrag = (
    selector,
    scroll_speed = 2,
    classOnEvent = 'grabbed_elem'
  ) => {
    const slider = document.querySelector(selector);
    this.isDown = false;
    this.startX = 0;
    this.scrollTop = 0;

    slider.addEventListener('mousedown', (e) => {
      e.preventDefault();
      this.isDown = true;
      slider.classList.add(classOnEvent);
      this.startX = e.pageY - slider.offsetTop;
      this.scrollTop = slider.scrollTop;

      // prevent default child behavior
      document.body.addEventListener('click', function (event) {
        if (slider.contains(event.target)) {
          event.preventDefault();
        }
      });
    });
    slider.addEventListener('mouseleave', () => {
      this.isDown = false;
      slider.classList.remove(classOnEvent);
    });
    slider.addEventListener('mouseup', () => {
      this.isDown = false;
      slider.classList.remove(classOnEvent);
    });
    slider.addEventListener('mousemove', (e) => {
      if (this.drake) {
        if (!this.isDown || !this.drake.dragging) return;
        e.preventDefault();
        const x = e.pageY - slider.offsetTop;
        const walk = (x - this.startX) * scroll_speed; //scroll-fast
        slider.scrollTop = this.scrollTop + walk;
      }
    });
  };

  Dragula = () => {
    const $this = this;

    let timetableDetails = this.timetableDetails;
    console.log('INITIALIZE DRAGULA');
    if (document.getElementById('dragable-reels')) {
      this.drake = Dragula(
        [document.getElementById('dragable-reels')].concat(
          Array.from(document.querySelectorAll('.dropable-target'))
        ),
        {
          accepts: function (el, target, source, sibling) {
            let tt_id = target.getAttribute('tt_id');
            let tpt_id = target.getAttribute('tpt_id');
            let dayname = target.getAttribute('dayname');
            let employee_id = el.getAttribute('employee_id');
            let acs_id = el.getAttribute('acs_id');
            let findtimetableDetails = timetableDetails.find(
              (v, i) =>
                v.tt_id == tt_id && v.tpt_id == tpt_id && v.dayname == dayname
            );
            // let findtimetableDetails = timetableDetails.find((v, i) => v.tt_id == tt_id && v.tpt_id == tpt_id && v.dayname == dayname && v.acs_id == acs_id);

            let class_id_target = target.getAttribute('class_id');
            let class_id_el = el.getAttribute('class_id');

            if (class_id_el && class_id_target !== class_id_el) {
              return;
            }

            if (employee_id) {
              // const checkAvailability = $this.state.employee_timetable_availability.findIndex(item => (item.day == dayname.toLowerCase() && item.tpt_id == tpt_id && item.employee_id == employee_id && item.locked == 0) || !(item.day == dayname.toLowerCase() && item.tpt_id == tpt_id && item.employee_id == employee_id));

              // const checkAvailability = $this.state.employee_timetable_availability.findIndex(item => (item.day == dayname.toLowerCase() && item.tpt_id == tpt_id && item.employee_id == employee_id && item.locked == 0));

              // if (checkAvailability === -1) {
              //   return;
              // }

              const availabilities =
                $this.state.employee_timetable_availability.filter(
                  (item) =>
                    item.employee_id == employee_id &&
                    item.day == dayname.toLowerCase()
                );

              const time = $this.state.timetable_period_timings.find(
                (item) => item.tpt_id == tpt_id
              );

              console.log('time', time);
              console.log('availabilities', availabilities);

              let foundConflict = true;

              availabilities.map((item) => {
                let slot_time_start = time.time_start;
                let slot_time_end = time.time_end;
                let empl_avail_start = item.time_start;
                let empl_avail_end = item.time_end;

                // const checkConflict =
                //   (slot_time_start <= empl_avail_start &&
                //     slot_time_end >= empl_avail_start) ||
                //   (slot_time_start <= empl_avail_end &&
                //     slot_time_end >= empl_avail_end) ||
                //   (slot_time_end >= empl_avail_start &&
                //     slot_time_end <= empl_avail_end) ||
                //   (empl_avail_start == slot_time_start &&
                //     empl_avail_end == slot_time_end);

                const checkConflict = $this.timeOverlap(
                  slot_time_start,
                  slot_time_end,
                  empl_avail_start,
                  empl_avail_end
                );

                if (checkConflict) {
                  console.log('true');
                  foundConflict = false;
                }
              });

              return foundConflict;
            }

            // console.log('accept', tt_id, tpt_id, dayname, timetableDetails, findtimetableDetails);
            // return source.classList[0] === 'dragable-reels' && !findtimetableDetails
            return source.classList[0] === 'dragable-reels';
          },
          copy: function (el, source) {
            return source.classList[0] === 'dragable-reels';
          },
          moves: function (el, container, handle, siblings) {
            // console.log('MOVES', el.classList[0] === 'dragable-reel');
            return el.classList[0] === 'dragable-reel';
          },

          removeOnSpill: true,
          mirrorContainer: document.getElementById('page-content'),
        }
      );

      this.drake.on('drop', function (el, target, source) {
        let acs_id = el.getAttribute('acs_id');
        let acs_name = el.getAttribute('acs_name');
        let tt_id = target.getAttribute('tt_id');
        let tpt_id = target.getAttribute('tpt_id');
        let dayname = target.getAttribute('dayname');
        let employee_id = el.getAttribute('employee_id');
        // console.log('target', target);
        // console.log('source', source);
        // console.log('el', el);

        if (
          source.classList[0] == 'dragable-reels' &&
          target.classList[0] == 'dropable-target'
        ) {
          timetableDetails.push({
            tt_id: chance.unique(chance.integer, 1, {
              min: 99999999999,
              max: 999999999999,
            })[0],
            employee_id,
            tt_id,
            tpt_id,
            dayname: dayname,
            locked: 0,
            acs_id,
            acs_name,
          });
          el.setAttribute('tt_id', tt_id);
          el.setAttribute('tpt_id', tpt_id);
          el.setAttribute('dayname', dayname);
          el.style.minWidth = 'unset';
          var eta_btn = el.querySelector('.btn-primary');
          if (eta_btn) {
            eta_btn.style.display = 'none';
          }

          $this.setState({ timetableDetails });
        }
      });

      $this.drake.on('remove', function (el, container, source) {
        // console.log('REMOVE el, container, source', el, container, source);
        let tt_id = el.getAttribute('tt_id');
        let tpt_id = el.getAttribute('tpt_id');
        let dayname = el.getAttribute('dayname');
        let employee_id = el.getAttribute('employee_id');
        let findtimetableDetails = timetableDetails.findIndex(
          (v, i) =>
            v.tt_id == tt_id && v.tpt_id == tpt_id && v.dayname == dayname
        );
        // console.log('REMOVE ', tt_id, tpt_id, dayname, findtimetableDetails, employee_id);

        if (employee_id) {
          // $this.setLocked(tpt_id, dayname, employee_id);
          // $this.deleteETARecord(tpt_id, dayname, employee_id);
          $this.setState({
            deleteETARecordList: [
              ...$this.state.deleteETARecordList,
              { tpt_id, dayname, employee_id },
            ],
          });
        }

        timetableDetails.splice(findtimetableDetails, 1);
      });
    }
  };

  setLocked = async (tpt_id, dayname, employee_id) => {
    try {
      const res = GET('timetable/set-locked', {
        params: {
          tpt_id,
          dayname: dayname.toLowerCase(),
          employee_id,
        },
      });
    } catch (err) {
      console.log(err);
    }
  };

  deleteETARecord = async (tpt_id, dayname, employee_id) => {
    try {
      const res = GET('timetable/delete-eta-record', {
        params: {
          tpt_id,
          dayname: dayname.toLowerCase(),
          employee_id,
        },
      });
    } catch (err) {
      console.log(err);
    }
  };

  toggleTimetablePublish = async (params) => {
    if (this.saveTimetableDetailsClassReq) {
      this.saveTimetableDetailsClassReq.abort();
    }
    this.saveTimetableDetailsClassReq = new AbortController();
    const res = await GET('timetable/toggle-timetable-publish', {
      params: {
        class_id: params.class_id,
        tt_id: params.tt_id,
        published: params.published,
      },
      signal: this.saveTimetableDetailsClassReq.signal,
    });
    if (!res.data.type) {
      if (res.data.data != null) {
        handleFormErrors(res.data.data);
      } else {
        toast.error(t('Something went wrong while saving!'));
      }
    } else {
      let timetableData = this.state.timetableData;
      timetableData.classes = timetableData.classes.map((v, i) => {
        if (v.class_id == params.class_id) {
          return { ...v, published: params.published };
        }
        return v;
      });
      this.setState({ timetableData });
      toast.success(t(res.data.message));
    }
  };

  saveTimetableDetailsClass = async (params) => {
    let getUrlParamData = getUrlParam();
    const tp_id = getUrlParamData.id;

    if (this.saveTimetableDetailsClassReq) {
      this.saveTimetableDetailsClassReq.abort();
    }
    this.saveTimetableDetailsClassReq = new AbortController();
    let this_timetableDetails = JSON.parse(
      JSON.stringify(this.timetableDetails)
    );
    this_timetableDetails = this_timetableDetails.filter(
      (v, i) => v.tt_id == params.tt_id && v.dayname != ''
    );

    const fData = new FormData();
    fData.append('timetable_details', JSON.stringify(this_timetableDetails));
    fData.append('tt_id', params.tt_id);
    fData.append('tp_id', tp_id);
    fData.append(
      'deleteETARecordList',
      JSON.stringify(this.state.deleteETARecordList)
    );
    const res = await POST('timetable/save-timetable-details-class', fData, {
      signal: this.saveTimetableDetailsClassReq.signal,
    });
    if (!res.data.type) {
      if (res.data.data != null) {
        handleFormErrors(res.data.data);
      } else {
        toast.error(t('Something went wrong while saving!'));
      }
    } else {
      // this.setState({
      //     modalEmployees: {
      //         ...this.state.modalEmployees,
      //         show: false,
      //         saving: false,
      //         data: null
      //     },
      //     employees: employees
      // })
      this.setState({ deleteETARecordList: [] });
      toast.success(t(res.data.message));
    }
  };

  generatePDF = (id, filename) => {
    html2canvas(document.querySelector('#' + id)).then((canvas) => {
      canvas.toBlob((blob) => {
        window.saveAs(blob, filename + '.jpg');
      });
      // const imgData = canvas.toDataURL('image/png');
      // const pdf = new jsPDF({
      //   orientation: "landscape"
      // });
      // pdf.addImage(imgData, 'PNG', 5, 5, 287, 200);
      // pdf.save(id + ".pdf");
    });
  };

  checkAvailability = (tp_id, tpt_id, day, employee_id) => {
    const availabilities = this.state.employee_timetable_availability.filter(
      (item) => item.employee_id == employee_id && item.day == day.toLowerCase()
    );

    const time = this.state.timetable_period_timings.find(
      (item) => item.tpt_id == tpt_id
    );

    let foundConflict = true;

    if (time) {
      availabilities.map((item) => {
        let slot_time_start = time.time_start;
        let slot_time_end = time.time_end;
        let empl_avail_start = item.time_start;
        let empl_avail_end = item.time_end;

        // const checkConflict =
        //   (slot_time_start <= empl_avail_start && slot_time_end >= empl_avail_start) ||
        //   (slot_time_start <= empl_avail_end && slot_time_end >= empl_avail_end) ||
        //   (slot_time_end >= empl_avail_start && slot_time_end <= empl_avail_end) ||
        //   (empl_avail_start == slot_time_start && empl_avail_end == slot_time_end);

        const checkConflict = this.timeOverlap(
          slot_time_start,
          slot_time_end,
          empl_avail_start,
          empl_avail_end
        );

        if (checkConflict) {
          console.log('true');
          foundConflict = false;
        }
        // else {
        //   console.log('false');
        //   foundConflict = false;
        // }
      });
    }

    const findIndex =
      this.state.viewEmployeeTimetableAvailability.timetableData.availability.findIndex(
        (item) =>
          item.tp_id == tp_id &&
          item.tpt_id == tpt_id &&
          item.day == day &&
          item.employee_id == employee_id
      );

    // let icon = <FontAwesomeIcon style={{ color: '#F45959' }} icon={faXmark} title={t('Not Available')} />
    let icon = (
      <FontAwesomeIcon
        style={{ color: '#5DDA8B' }}
        icon={faCheck}
        title={t('Available')}
      />
    );

    if (findIndex !== -1) {
      // icon = <FontAwesomeIcon style={{ color: '#5DDA8B' }} icon={faCheck} title={t('Available')} />
      // if (this.state.viewEmployeeTimetableAvailability.timetableData.availability[findIndex].locked == 1) {
      //   icon = <FontAwesomeIcon style={{ color: '#4BCBF1' }} icon={faCircleInfo} title={t('Busy')} />
      // }
      icon = (
        <FontAwesomeIcon
          style={{ color: '#F45959' }}
          icon={faXmark}
          title={t('Available')}
        />
      );
      if (
        this.state.viewEmployeeTimetableAvailability.timetableData.availability[
          findIndex
        ].locked == 1
      ) {
        icon = (
          <FontAwesomeIcon
            style={{ color: '#4BCBF1' }}
            icon={faCircleInfo}
            title={t('Busy')}
          />
        );
      }
    }

    return icon;
  };

  getAvailabilityTimetables = async (employee_name, employee_id) => {
    try {
      const res = await GET(
        'employee-timetable-availability/get-timetables-and-availability',
        {
          params: {
            employee_id,
          },
        }
      );

      this.setState({
        viewEmployeeTimetableAvailability: {
          show: true,
          employee_name: employee_name,
          employee_id: employee_id,
          timetableData: res.data,
        },
      });
    } catch (err) {
      console.log(err);
    }
  };

  viewAvailableTeachers = (class_id, tpt_id, day) => {
    const { classes_assignments, employee_timetable_availability } = this.state;
    day = day.toLocaleLowerCase();
    console.log(
      'class_id, tpt_id, day, employee_timetable_availability',
      class_id,
      tpt_id,
      day,
      employee_timetable_availability
    );
    const availableTeachers = classes_assignments
      .filter((item) => item.class_id == class_id)
      .map((teacher) => {
        let available = true;

        const availabilities =
          this.state.employee_timetable_availability.filter(
            (item) => item.employee_id == teacher.employee_id && item.day == day
          );

        const time = this.state.timetable_period_timings.find(
          (item) => item.tpt_id == tpt_id
        );

        availabilities.map((item) => {
          let slot_time_start = time.time_start;
          let slot_time_end = time.time_end;
          let empl_avail_start = item.time_start;
          let empl_avail_end = item.time_end;

          const checkConflict = this.timeOverlap(
            slot_time_start,
            slot_time_end,
            empl_avail_start,
            empl_avail_end
          );

          if (checkConflict) {
            console.log('true');
            available = false;
          }
        });

        return {
          ...teacher,
          available,
        };
      });
    console.log('availableTeachers', availableTeachers);

    availableTeachers.sort((a, b) => a.employee_name.localeCompare(b.employee_name))

    this.setState({
      slotEmployeesModal: {
        show: true,
        availableTeachers,
      },
    });
  };

  timeOverlap = (slot_start, slot_end, empl_start, empl_end) => {
    // return !(slot_end <= empl_start || slot_start >= empl_end);
    return empl_start <= slot_start && empl_end >= slot_end;
  };

  render() {
    console.log(
      '%cTHIS.STATE',
      'font-size:20px;color:orange;font-weight:bold;'
    );
    console.log(this.state);
    const { defaultTheme } = this.props;
    const {
      days,
      timetable_period_timings,
      timetable,
      employee_timetable_availability,
      employees,
      timetableData,
      timetablePeriods,
    } = this.state;

    const colCount = this.state.days.filter((item) => item.active).length + 1;

    return (
      <Container fluid>
        <div id='iedu-layout'>
          <NavBar />
          <div id='page-content' onScroll={(e) => this.isSticky(e)}>
            <Header
              lite={true}
              heading={t('Timetable Details Generator')}
              backBtn={true}
            />

            <div className='grey-section'>
              <div className='grey-section-header'>
                <h5>
                  <span className='me-1'>{t('Grades')}</span>
                  <span
                    className='mx-1'
                    style={{ opacity: 0.6, fontWeight: 'normal' }}>
                    |
                  </span>
                  <span
                    className='mx-1'
                    style={{
                      opacity: 0.8,
                      fontSize: '1rem',
                      fontWeight: 'normal',
                    }}>
                    {t(
                      'Please make sure that all sessions are less or equal to the periods'
                    )}
                  </span>
                </h5>
                <div
                  className='d-flex flex-row align-items-center px-1 justify-content-end'
                  style={{ flex: 1 }}>
                  {/* <Button variant='success' onClick={() => { }}>{t('Save')}</Button> */}
                </div>
              </div>
              <div>
                {/* GRADES */}
                <div style={{ overflowX: 'auto', overflowY: 'hidden' }}>
                  <div className='d-flex flex-row'>
                    {timetableData.agegroup.map((v, i) => {
                      let data = {
                        allData: v,
                        name: v.agegroup_code,
                        text1: (
                          <div className='d-flex flex-column align-items-center'>
                            <Badge
                              bg={
                                v.total_periods < v.total_acs_sessions_per_week
                                  ? 'danger'
                                  : 'success'
                              }>
                              {'Subject Session: ' +
                                v.total_acs_sessions_per_week +
                                ', ' +
                                'Periods: ' +
                                v.total_periods}
                            </Badge>
                            <Badge
                              bg={
                                v.total_periods < v.total_acs_sessions_per_week
                                  ? 'danger'
                                  : 'success'
                              }>
                              {v.total_periods <
                                v.total_acs_sessions_per_week &&
                                'Subject Session are more than the Periods.'}
                            </Badge>
                          </div>
                        ),

                        cardImage: require('../../assets/images/timetable.png'),
                        actions: [
                          {
                            type: 'selection',
                            name: 'Selection',
                            iconName: null,
                            iconColor:
                              brand_colors[this.props.defaultTheme.theme_id]
                                .color4,
                            onClick: () =>
                              timetableData.toggleAgegroup(
                                timetableData.agegroup_id == v.agegroup_id
                                  ? null
                                  : v.agegroup_id
                              ),
                            state: timetableData.agegroup_id == v.agegroup_id,
                          },
                          {
                            type: 'generate',
                            name: 'Generate Timetable',
                            image: null,
                            iconName: faRefresh,
                            iconColor:
                              brand_colors[this.props.defaultTheme.theme_id]
                                .color16,
                            onClick: () => {},
                          },
                        ],
                      };
                      return <HorizontalCard data={data} key={i} />;
                    })}
                  </div>
                </div>
              </div>

              {this.state.generateTT ? (
                <div>
                  <Table>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>First Name</th>
                        <th>Last Name</th>
                        <th>Username</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>1</td>
                        <td>Mark</td>
                        <td>Otto</td>
                        <td>@mdo</td>
                      </tr>
                      <tr>
                        <td>2</td>
                        <td>Jacob</td>
                        <td>Thornton</td>
                        <td>@fat</td>
                      </tr>
                      <tr>
                        <td>3</td>
                        <td colSpan={2}>Larry the Bird</td>
                        <td>@twitter</td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              ) : (
                <div
                  className='d-flex flex-row align-items-start justify-content-start'
                  style={{ width: '100%' }}>
                  <div
                    className='mt-1'
                    id='dragula-scrollable-container'
                    style={{ width: '75%' }}>
                    {timetableData.classes.map((classData, i) => {
                      let classOpened = timetableData.classOpen.findIndex(
                        (classOpen) => classOpen == classData.class_id
                      );
                      if (classData.agegroup_id == timetableData.agegroup_id) {
                        return (
                          <Card key={i} style={{ width: '100%' }}>
                            <Card.Header>
                              <div className='d-flex flex-row justify-content-between align-items-center'>
                                <div
                                  className='d-flex flex-row'
                                  style={{ fontSize: 18, fontWeight: '600' }}>
                                  {classData.class_theme}
                                  <div className='mx-1'>
                                    {classData.published == 1 ? (
                                      <Badge bg='success'>
                                        {t('Published')}
                                      </Badge>
                                    ) : (
                                      <Badge bg='warning'>
                                        {t('Not Published')}
                                      </Badge>
                                    )}
                                  </div>
                                  {classOpened != -1 && (
                                    <Button
                                      className='ms-1'
                                      variant={'danger'}
                                      onClick={() =>
                                        this.generatePDF(
                                          'timetable_class_' +
                                            classData.class_id,
                                          classData.class_theme + ' Timetable'
                                        )
                                      }>
                                      <FontAwesomeIcon
                                        icon={faFilePdf}
                                        style={{
                                          fontSize: 16,
                                          color:
                                            brand_colors[
                                              this.props.defaultTheme.theme_id
                                            ].color8,
                                        }}
                                      />
                                    </Button>
                                  )}
                                </div>
                                <div className='d-flex flex-row align-items-center'>
                                  {classOpened != -1 &&
                                    classData.published == 0 && (
                                      <Button
                                        className='mx-2'
                                        size='sm'
                                        variant='success'
                                        onClick={() =>
                                          this.saveTimetableDetailsClass({
                                            class_id: classData.class_id,
                                            tt_id: classData.tt_id,
                                          })
                                        }>
                                        {t('Save')}
                                      </Button>
                                    )}
                                  {classOpened != -1 && (
                                    <Button
                                      className='mx-2'
                                      size='sm'
                                      variant='warning'
                                      onClick={() =>
                                        this.toggleTimetablePublish({
                                          class_id: classData.class_id,
                                          tt_id: classData.tt_id,
                                          published:
                                            classData.published == 1 ? 0 : 1,
                                        })
                                      }>
                                      {classData.published == 1
                                        ? t('Un-Publish')
                                        : t('Publish')}
                                    </Button>
                                  )}
                                  <FontAwesomeIcon
                                    icon={
                                      classOpened != -1
                                        ? faAngleUp
                                        : faAngleDown
                                    }
                                    className='mx-2 cursor-pointer'
                                    onClick={() => {
                                      if (classOpened != -1) {
                                        timetableData.classOpen.splice(
                                          classOpened,
                                          1
                                        );
                                      } else {
                                        timetableData.classOpen.push(
                                          classData.class_id
                                        );
                                      }
                                      this.setState({
                                        timetableData: timetableData,
                                      });
                                    }}
                                  />
                                </div>
                              </div>
                            </Card.Header>
                            <Card.Body
                              style={{
                                display: classOpened != -1 ? 'block' : 'none',
                              }}>
                              <Table
                                bordered
                                size='sm'
                                responsive
                                id={'timetable_class_' + classData.class_id}>
                                <thead>
                                  <tr>
                                    <th
                                      className='text-center'
                                      style={{ width: '10vw' }}>
                                      <div className='d-flex flex-column'>
                                        {t('Time')}
                                      </div>
                                    </th>
                                    {days.map((v, i) => {
                                      return (
                                        <th
                                          className={
                                            v.active
                                              ? 'text-center table-success'
                                              : 'text-center table-danger'
                                          }>
                                          {v.name.slice(0, 3)}
                                        </th>
                                      );
                                    })}
                                  </tr>
                                </thead>
                                <tbody>
                                  {timetable_period_timings.map((v, i) => {
                                    return (
                                      <tr>
                                        <th>
                                          <div className='d-flex flex-column align-items-center justify-content-center'>
                                            <Badge
                                              bg='success'
                                              className='my-1'>
                                              {v.time_start}
                                            </Badge>
                                            <Badge
                                              bg='warning'
                                              className='my-1'>
                                              {v.time_end}
                                            </Badge>
                                          </div>
                                        </th>
                                        {days.map((vv) => {
                                          return (
                                            <td
                                              style={{
                                                minWidth: vv.active
                                                  ? '12rem'
                                                  : '6rem',
                                                position: 'relative',
                                                paddingTop: 40,
                                              }}
                                              className={
                                                vv.active
                                                  ? 'dropable-target'
                                                  : 'no-drop table-danger'
                                              }
                                              dayname={vv.name}
                                              tpt_id={v.tpt_id}
                                              tt_id={classData.tt_id}
                                              class_id={classData.class_id}>
                                              {vv.active && (
                                                <Button
                                                  size='sm'
                                                  style={{
                                                    position: 'absolute',
                                                    top: 7,
                                                    right: 7,
                                                  }}
                                                  onClick={() =>
                                                    this.viewAvailableTeachers(
                                                      classData.class_id,
                                                      v.tpt_id,
                                                      vv.name
                                                    )
                                                  }
                                                  title={t(
                                                    'View available teachers for this time slot'
                                                  )}>
                                                  <FontAwesomeIcon
                                                    icon={faPersonChalkboard}
                                                    style={{
                                                      fontSize: 16,
                                                      color:
                                                        brand_colors[
                                                          this.props
                                                            .defaultTheme
                                                            .theme_id
                                                        ].color8,
                                                    }}
                                                  />
                                                </Button>
                                              )}
                                              <div
                                                style={{
                                                  position: 'absolute',
                                                  top: '50%',
                                                  left: '50%',
                                                  transform:
                                                    'translate(-50%, -50%)',
                                                }}>
                                                <span
                                                  style={{
                                                    fontSize: 10,
                                                    fontWeight: 700,
                                                    backgroundColor:
                                                      v.tp_type_color,
                                                    display: 'inline-block',
                                                    padding: 5,
                                                    paddingInline: 10,
                                                    borderRadius: 8,
                                                    textAlign: 'center',
                                                    color:
                                                      colorPalette.findIndex(
                                                        (item) =>
                                                          item.code ==
                                                          v.tp_type_color
                                                      )?.is_text_white !== -1
                                                        ? '#fff'
                                                        : '#000',
                                                  }}>
                                                  {v.tp_type_name}
                                                </span>
                                              </div>
                                              {classData.timetable_details.map(
                                                (
                                                  timetable_details_data,
                                                  timetable_details_key
                                                ) => {
                                                  if (
                                                    classData.tt_id ==
                                                      timetable_details_data.tt_id &&
                                                    v.tpt_id ==
                                                      timetable_details_data.tpt_id &&
                                                    timetable_details_data.dayname ==
                                                      vv.name
                                                  ) {
                                                    let data = {
                                                      allData:
                                                        timetable_details_data,
                                                      name: timetable_details_data.acs_name,
                                                      code: timetable_details_data.acs_code,
                                                      text1:
                                                        'Sessions Per Week: ',
                                                      text2:
                                                        timetable_details_data.acs_sessions_per_week,
                                                      text3: 'Coefficient: ',
                                                      text4:
                                                        timetable_details_data.acs_coefficient,
                                                      text5: 'Teacher: ',
                                                      text6:
                                                        timetable_details_data.employee_name,
                                                      // cardImage: (vv.acs_image) ? portalURL + vv.center_id + '/academic_classmain_subject/' + vv.acs_image : require('../../assets/images/noimage.png'),
                                                      actions: [],
                                                    };
                                                    return (
                                                      <HorizontalCard
                                                        data={data}
                                                        key={
                                                          timetable_details_key
                                                        }
                                                        size='sm'
                                                        attributes={{
                                                          acs_id:
                                                            timetable_details_data.acs_id,
                                                          acs_name:
                                                            timetable_details_data.acs_name,
                                                          tt_id:
                                                            timetable_details_data.tt_id,
                                                          tpt_id:
                                                            timetable_details_data.tpt_id,
                                                          dayname:
                                                            timetable_details_data.dayname,
                                                          employee_id:
                                                            timetable_details_data.employee_id,
                                                          className:
                                                            'dragable-reel cursor-grab',
                                                        }}
                                                      />
                                                    );
                                                  }
                                                }
                                              )}
                                            </td>
                                          );
                                        })}
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </Table>
                            </Card.Body>
                          </Card>
                        );
                      }
                    })}
                  </div>
                  <div
                    style={{
                      width: '25%',
                      height: '95vh',
                      paddingLeft: 5,
                      paddingTop: 3,
                    }}>
                    <Button
                      variant={this.state.tab ? 'outline-secondary' : 'primary'}
                      onClick={() => this.setState({ tab: 0 })}
                      className='me-2'>
                      Subjects
                    </Button>
                    <Button
                      variant={this.state.tab ? 'primary' : 'outline-secondary'}
                      onClick={() => this.setState({ tab: 1 })}>
                      Subjects w/ Teacher
                    </Button>
                    <div
                      className='grey-section-header'
                      style={{
                        padding: 0,
                      }}>
                      <h5>
                        <span
                          style={{
                            opacity: 0.8,
                            fontSize: '1rem',
                            fontWeight: 'normal',
                          }}>
                          {t('Drag & Drop subjects in respective Periods')}
                        </span>
                      </h5>
                    </div>
                    <div
                      id='make-sticky'
                      style={{
                        backgroundColor:
                          brand_colors[this.props.defaultTheme.theme_id].color7,
                        overflowX: 'hidden',
                        overflowY: 'auto',
                        height: '95vh',
                        borderLeft:
                          'solid 2px ' +
                          brand_colors[this.props.defaultTheme.theme_id]
                            .color10,
                      }}>
                      {/* SUBJECTS */}
                      {timetableData.agegroup_id && (
                        <div
                          className='dragable-reels d-flex flex-column'
                          id='dragable-reels'
                          ref={this.Dragula}>
                          {!this.state.tab &&
                            timetableData.agegroup.map((v, i) => {
                              if (timetableData.agegroup_id == v.agegroup_id) {
                                return v.academic_classmain_subject.map(
                                  (vv, ii) => {
                                    let data = {
                                      allData: vv,
                                      name: vv.acs_name,
                                      code: vv.acs_code,
                                      text1: 'Sessions Per Week: ',
                                      text2: vv.acs_sessions_per_week,
                                      text3: 'Coefficient: ',
                                      text4: vv.acs_coefficient,
                                      // cardImage: (vv.acs_image) ? portalURL + vv.center_id + '/academic_classmain_subject/' + vv.acs_image : require('../../assets/images/noimage.png'),
                                      actions: [],
                                    };
                                    return (
                                      <HorizontalCard
                                        data={data}
                                        key={ii}
                                        size='sm'
                                        attributes={{
                                          acs_id: vv.acs_id,
                                          acs_name: vv.acs_name,
                                          className:
                                            'dragable-reel cursor-grab',
                                        }}
                                      />
                                    );
                                  }
                                );
                              }
                            })}
                          {this.state.tab &&
                            this.state.classes_assignments.map(
                              (item, index) => {
                                if (
                                  this.state.timetableData.agegroup_classes_ids.includes(
                                    item.class_id
                                  )
                                ) {
                                  return (
                                    <div
                                      style={{
                                        backgroundColor: '#fff',
                                        borderRadius: 7,
                                        margin: 8,
                                        padding: 10,
                                        fontSize: '0.8rem',
                                        boxShadow:
                                          '-1px 2px 16px 2px rgba(0,0,0,0.1)',
                                        position: 'relative',
                                      }}
                                      acs_id={item.acs_id}
                                      acs_name={item.acs_name}
                                      class_id={item.class_id}
                                      employee_id={item.employee_id}
                                      className='dragable-reel cursor-grab'>
                                      <Button
                                        size='sm'
                                        onClick={() =>
                                          this.getAvailabilityTimetables(
                                            item.employee_name,
                                            item.employee_id
                                          )
                                        }
                                        title={t(
                                          'View Employee Timetable Availability'
                                        )}
                                        style={{
                                          position: 'absolute',
                                          right: 10,
                                          top: 10,
                                        }}>
                                        <FontAwesomeIcon
                                          icon={faTable}
                                          style={{
                                            fontSize: 16,
                                            color:
                                              brand_colors[
                                                this.props.defaultTheme.theme_id
                                              ].color8,
                                          }}
                                        />
                                      </Button>
                                      <strong>{item.acs_name}</strong>{' '}
                                      {item.acs_code}
                                      <br />
                                      {t('Sessions Per Week:') +
                                        ' ' +
                                        item.acs_sessions_per_week}{' '}
                                      <br />
                                      {t('Teacher:') +
                                        ' ' +
                                        item.employee_name}{' '}
                                      <br />
                                      {t('Class:')}{' '}
                                      <strong style={{ color: 'red' }}>
                                        {item.class_theme}
                                      </strong>
                                    </div>
                                  );
                                }
                              }
                            )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <Modal
          backdrop='static'
          keyboard={false}
          size='xl'
          show={this.state.viewEmployeeTimetableAvailability.show}
          onHide={() =>
            this.setState({
              viewEmployeeTimetableAvailability: {
                show: false,
                employee_name: '',
                employee_id: 0,
                timetableData: {
                  availability: [],
                  timetables: [],
                },
              },
            })
          }>
          <Modal.Header closeButton>
            <Modal.Title>
              {this.state.viewEmployeeTimetableAvailability.employee_name}{' '}
              {t('Timetable Availability')}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.state.viewEmployeeTimetableAvailability.timetableData.timetables.map(
              (tp, tp_index) => (
                <Table
                  key={tp_index}
                  bordered
                  className='teacher-timetable-availability mb-3'>
                  <thead>
                    <tr>
                      <th
                        style={{
                          backgroundColor: '#F1F1F1',
                          textAlign: 'left',
                        }}
                        colSpan={colCount}>
                        {tp.tp_name}
                      </th>
                    </tr>
                    <tr>
                      <th>{t('PERIODS')}</th>
                      {this.state.days
                        .filter((item) => item.active)
                        .map((day, day_index) => (
                          <th
                            key={day_index}
                            style={{ backgroundColor: '#E3ECFF' }}>
                            {day.name.toLocaleUpperCase()}
                          </th>
                        ))}
                    </tr>
                  </thead>
                  <tbody>
                    {tp.periods.map((period, period_index) => (
                      <tr key={period_index} style={{ height: 1 }}>
                        <td
                          className='d-flex flex-column align-items-center position-relative'
                          style={{ fontSize: 14, paddingLeft: 25 }}>
                          <Badge
                            style={{
                              position: 'absolute',
                              top: 0,
                              left: 0,
                              borderRadius: 0,
                              height: '100%',
                              display: 'flex',
                              alignItems: 'center',
                              backgroundColor: '#F1F1F1',
                              color: '#000',
                            }}
                            bg='light'>
                            {period_index + 1}
                          </Badge>
                          {moment(period.time_start, 'HH:mm:ss').format(
                            'HH:mm'
                          )}{' '}
                          <FontAwesomeIcon
                            className='teacher-search-icon'
                            icon={faArrowDown}
                            style={{ fontSize: 12, color: '#A9A6A6' }}
                          />{' '}
                          {moment(period.time_end, 'HH:mm:ss').format('HH:mm')}
                        </td>
                        {this.state.days
                          .filter((item) => item.active)
                          .map((day, day_index) => (
                            <td
                              key={day_index}
                              style={{
                                height: 'inherit',
                                verticalAlign: 'middle',
                              }}
                              className='p-0 text-center'>
                              {this.checkAvailability(
                                tp.tp_id,
                                period.tpt_id,
                                day.name.toLocaleLowerCase(),
                                this.state.viewEmployeeTimetableAvailability
                                  .employee_id
                              )}
                            </td>
                          ))}
                      </tr>
                    ))}
                  </tbody>
                </Table>
              )
            )}
          </Modal.Body>
        </Modal>
        <Modal
          centered
          size='sm'
          backdrop='static'
          keyboard={false}
          show={this.state.slotEmployeesModal.show}
          onHide={() =>
            this.setState({
              slotEmployeesModal: {
                show: false,
                availableTeachers: [],
              },
            })
          }>
          <Modal.Header closeButton>
            <Modal.Title>{t('Available Teachers')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.state.slotEmployeesModal.availableTeachers.map(
              (item, index) => (
                <div key={index}>
                  <FontAwesomeIcon
                    icon={faUser}
                    color={item.available ? '#48CF48' : 'red'}
                  />{' '}
                  <strong>{item.employee_name}</strong> ({item.acs_name})
                </div>
              )
            )}
          </Modal.Body>
        </Modal>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  authData: state.auth.authData,
  defaultLanguage: state.language.defaultLanguage,
  selectedClass: state.selectedClass.data,
  defaultTheme: state.theme.defaultTheme,
});

export default connect(mapStateToProps, null)(TimetableDetailsGenerator);
