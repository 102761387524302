import React, { Component } from 'react';
import { Container, Card, Row, Col, Alert, Form, Button, Spinner, Table } from 'react-bootstrap';
import { t } from '../../helpers/translation_helper';
import NavBar from '../_partials/NavBar/_NavBar';
import Header from '../_partials/Header/_Header';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import Select from 'react-select';
import axios from 'axios';
import { GET, POST } from '../../api';
import { toast } from 'react-toastify';

class FeeStructure extends Component {

  state = {
    fetching: false,
    working: {
      status: false,
      id: 0,
    },
    copying: false,
    terms: [],
    sessionCats: [],
    maxDays: 0,
    data: [],
    uniqueClassMain: [],
    uniqueClassMainData: [],
    unique_session_timesch_ids: [],
    unique_session_timesch_data: [],
    term_id: '',
    sessioncat_id: '',
    to_term_id: '',
    copy_to_overwrite: 0,
  }

  componentDidMount() {
    this.getSetDDLs();
  }

  getSetDDLs = async () => {

    try {
      const response = await axios.all([
        GET('catfees/get-terms'),
        GET('catfees/get-sessioncat'),
      ]);

      // Terms
      const terms = response[0].data.map((item) => {
        return {
          value: item.term_id,
          label: item.term_name,
        }
      });

      // Session Categories
      const sessionCats = response[1].data.map((item) => {
        return {
          value: item.sessioncat_id,
          label: item.sessioncat_name,
        }
      });

      this.setState({
        terms,
        sessionCats,
      });

    } catch (err) {
      console.log('err', err)

    }


  }

  getCatFees = async () => {

    this.setState({
      fetching: true
    });

    const { authData } = this.props;
    const { center_id, weekend_days } = authData.centerData;
    const { term_id, sessioncat_id } = this.state;

    try {
      const res = await GET('catfees/get-cat-fees', {
        params: {
          center_id,
          term_id: term_id.value,
          sessioncat_id: sessioncat_id.value,
          weekend_days
        }
      });

      let data = res.data;
      let maxDays = 0;
      let uniqueClassMain = [];
      let uniqueClassMainData = [];
      let unique_session_timesch_ids = [];
      let unique_session_timesch_data = [];

      data.data.map((v, i) => {
        if (v.session_day_number > maxDays) {
          maxDays = v.session_day_number;
        }
        if (!uniqueClassMain.includes(v.classmain_id)) {
          uniqueClassMain.push(v.classmain_id);
          uniqueClassMainData.push({
            classmain_id: v.classmain_id,
            classmain_name: v.classmain_name,
            session_id: v.session_id,
          });
        }
        if (!unique_session_timesch_ids.includes(v.session_timesch_id)) {
          unique_session_timesch_ids.push(v.session_timesch_id);
          unique_session_timesch_data.push({
            start: v.session_start_time,
            end: v.session_end_time,
          });
        }
      });

      this.setState({
        fetching: false,
        data: data.data,
        maxDays,
        uniqueClassMain,
        uniqueClassMainData,
        unique_session_timesch_ids,
        unique_session_timesch_data
      });


    } catch (err) {
      console.log('err', err)

      this.setState({
        fetching: false
      });
    }
  }

  updateCell = (index, value) => {
    let data = this.state.data;
    data[index].catfees_fee = value;
    this.setState({ data });
  }

  updateCatFees = async (classmain_id) => {

    this.setState({
      working: {
        status: true,
        id: classmain_id
      },
    });

    const { data } = this.state;
    const classData = data
      .filter(item => item.classmain_id == classmain_id)
      .map((item) => {
        return {
          catfees_id: item.catfees_id,
          catfees_fee: item.catfees_fee
        }
      });

    const fData = new FormData();
    fData.append('CatfeesData', JSON.stringify(classData));

    try {
      const res = await POST('catfees/update-cat-fees', fData);

      if (res.data.type) {
        toast.success(t('Fee structure updated successfully!'));
      }

      this.setState({
        working: false,
        id: 0
      });
    } catch (err) {
      console.log('err', err)


      this.setState({
        working: false,
        id: 0,
      });
    }

  }

  copyCatFees = async () => {

    this.setState({
      copying: true
    });

    const { authData } = this.props;
    const { center_id } = authData.centerData;
    const { term_id, sessioncat_id, to_term_id, copy_to_overwrite } = this.state;

    const fData = new FormData();
    fData.append('center_id', center_id);
    fData.append('from_term_id', term_id.value);
    fData.append('from_sessioncat_id', sessioncat_id.value);
    fData.append('to_term_id', to_term_id.value);
    fData.append('copy_to_overwrite', copy_to_overwrite);

    try {
      const res = await POST('catfees/copy-cat-fees', fData);
      if (res.data.type) {
        toast.success(t('Copied successfully!'));
      }


      this.setState({
        copying: false
      });

    } catch (err) {
      console.log('err', err)
      this.setState({
        copying: false
      });

    }

  }

  render() {
    return (
      <Container fluid>
        <div id='iedu-layout'>
          <NavBar />
          <div id='page-content'>
            <Header backBtn={true} lite={true} heading={t('Fee Structure')} />
            <div className='grey-section'>
              <Card
                className='border-0'
                style={{ borderRadius: '20px' }}>
                <Card.Body>
                  <Row>
                    <Col className='pe-1'>
                      <Alert variant='info' className='mb-1'>
                        <FontAwesomeIcon icon={faCircleInfo} style={{ marginRight: 7 }} />
                        {t('Make sure to set your term fees for all below categories selections.')}
                      </Alert>
                    </Col>
                    <Col>
                      <Alert variant='info' className='mb-1'>
                        <FontAwesomeIcon icon={faCircleInfo} style={{ marginRight: 7 }} />
                        {t('Click on Update button for each class level.')}
                      </Alert>
                    </Col>
                  </Row>
                  <Row className='custom-styled-form'>
                    <Col md={4}>
                      <Row>
                        <Col md={12}>
                          <Form.Group>
                            <Form.Label>{t('Term')}</Form.Label>
                            <Select
                              value={this.state.term_id}
                              menuPortalTarget={document.body}
                              styles={{
                                menuPortal: (
                                  base
                                ) => ({
                                  ...base,
                                  zIndex: 9999,
                                }),
                              }}
                              options={this.state.terms}
                              onChange={(obj) => this.setState({ term_id: obj })}
                            />
                          </Form.Group>
                        </Col>
                        <Col md={12} className='mb-3'>
                          <Form.Group>
                            <Form.Label>{t('Category')}</Form.Label>
                            <Select
                              value={this.state.sessioncat_id}
                              menuPortalTarget={document.body}
                              styles={{
                                menuPortal: (
                                  base
                                ) => ({
                                  ...base,
                                  zIndex: 9999,
                                }),
                              }}
                              options={this.state.sessionCats}
                              onChange={(obj) => this.setState({ sessioncat_id: obj })}
                            />
                          </Form.Group>
                        </Col>
                        <Col md={12}>
                          <Button variant="primary"
                            onClick={this.getCatFees}
                            disabled={this.state.fetching}
                          >{t('Generate') + ' '}
                            {this.state.fetching && <Spinner as='span' animation='grow' size='sm' role='status' aria-hidden='true' />}
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                    <Col md={4}></Col>
                    <Col md={4}>
                      <Row>
                        <Col md={12}>
                          <Form.Group>
                            <Form.Label>{t('Copy To Term')}</Form.Label>
                            <Select
                              value={this.state.to_term_id}
                              menuPortalTarget={document.body}
                              styles={{
                                menuPortal: (
                                  base
                                ) => ({
                                  ...base,
                                  zIndex: 9999,
                                }),
                              }}
                              options={this.state.terms}
                              onChange={(obj) => this.setState({ to_term_id: obj })}
                            />
                          </Form.Group>
                        </Col>
                        <Col md={12} className='ps-0 mb-2'>
                          <Form.Group>
                            <Form.Label>{t('Overwrite Previous Values')}</Form.Label>
                            <div className='p-3 pb-0'>
                              <Form.Check
                                type="switch"
                                id="copy_to_overwrite"
                                label={false}
                                onChange={() => this.setState({ copy_to_overwrite: this.state.copy_to_overwrite == 1 ? 0 : 1 })}
                                checked={this.state.copy_to_overwrite == 1}
                              />
                            </div>
                          </Form.Group>
                        </Col>
                        <Col md={12}>
                          <Button
                            onClick={() => this.copyCatFees()}
                            disabled={
                              (this.state.term_id == '' ||
                                this.state.sessioncat_id == '' ||
                                this.state.to_term_id == '')
                            }
                            variant="primary">{t('Copy To')}</Button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
              {this.state.uniqueClassMainData.length > 0 && this.state.uniqueClassMainData.map((class_item, class_index) => (
                <Card
                  key={class_index}
                  className='border-0 mt-3'
                  style={{ borderRadius: '20px' }}>
                  <Card.Body>
                    <Row>
                      <Col><Card.Title>{class_item.classmain_name}</Card.Title></Col>
                      <Col className='d-flex justify-content-end'>
                        <Button
                          disabled={this.state.working.status && this.state.working.id == class_item.classmain_id}
                          onClick={() => this.updateCatFees(class_item.classmain_id)} size='sm' variant="warning">{t('Update') + ' '}  {this.state.working.status && this.state.working.id == class_item.classmain_id && <Spinner as='span' animation='grow' size='sm' role='status' aria-hidden='true' />}</Button>
                      </Col>
                    </Row>
                    <Table>
                      <thead>
                        <tr>
                          <th>{t('Day')}</th>
                          {this.state.unique_session_timesch_data.map((time_item, time_index) => (
                            <th style={{ textAlign: 'center' }}>{time_item.start} - {time_item.end}</th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>

                        {Array.from({ length: this.state.maxDays }).map((_, index) => (
                          <tr key={index}>
                            <td align='center'>{index + 1}</td>
                            {this.state.data.map((data_item, data_index) => {
                              if (data_item.classmain_id == class_item.classmain_id && data_item.session_day_number == (index + 1) && parseInt(data_item.session_status) == 1) {

                                return (<td>
                                  <Form.Control
                                    onChange={(e) => this.updateCell(data_index, e.target.value)}
                                    className='text-center'
                                    size="sm"
                                    type="text"
                                    value={this.state.data[data_index].catfees_fee} />
                                </td>)
                              } else if (data_item.classmain_id == class_item.classmain_id && data_item.session_day_number == (index + 1)) {
                                return <td></td>
                              }
                            })}
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Card.Body>
                </Card>
              ))}
            </div>
          </div>
        </div>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  authData: state.auth.authData,
  defaultLanguage: state.language.defaultLanguage,
});

export default connect(mapStateToProps, null)(FeeStructure);
