import React, { Component } from 'react';
import {
  Container,
  Card,
  Row,
  Col,
  Button,
  Spinner,
  InputGroup,
  Form,
  Table,
  Badge,
  ButtonGroup,
  DropdownButton,
  Dropdown,
} from 'react-bootstrap';
import { t } from '../../helpers/translation_helper';
import NavBar from '../_partials/NavBar/_NavBar';
import Header from '../_partials/Header/_Header';
import { connect } from 'react-redux';
import { brand_colors } from '../../helpers/brand_colors_helper';
import {
  faBell,
  faBellConcierge,
  faCalendar,
  faConciergeBell,
  faDumbbell,
  faEye,
  faEyeSlash,
  faNoteSticky,
  faSearch,
  faWarning,
  faXmark,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DateRangePicker } from 'react-date-range';
import moment from 'moment';
import { GET } from '../../api';
import { numberFormat } from '../../helpers/general_helpers';
import {
  faCalendarAlt,
  faFileExcel,
} from '@fortawesome/free-regular-svg-icons';
import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';
import { renderTableBody, renderTableHead } from '../../helpers/table_helper';
import Pagination from '../_partials/Pagination';
import Select from 'react-select';
import { toast } from 'react-toastify';
import Flatpickr from 'react-flatpickr';
import SweetAlert from 'react-bootstrap-sweetalert';
let cancelToken;

class EmployeeLeavesReport extends Component {
  state = {
    sendNotificationModal: { show: false, id: 0, action: () => {} },
    fetching: false,
    classes: [],
    class_id: '',
    till_date: moment().format('YYYY-MM-DD'),
    employeeSalary: '',
    cdt: {
      fetching: false,
      search: '',
      rows: 25,
      offset: 0,
      order: 'employee_name',
      dir: 'ASC',
      lastPage: 0,
      page: 0,
      totalRecords: 0,
      rowsOptions: [10, 25, 50, 'All'],
      columns: [
        {
          id: 'employee_name',
          column: 'employee_name',
          name: 'Employee',
          visibility: true,
          sortable: true,
        },
        {
          id: 'empl_code',
          column: 'empl_code',
          name: 'Employee Code',
          visibility: true,
          sortable: true,
        },
        {
          id: 'joining_date',
          column: 'joining_date',
          name: 'Joining Date',
          visibility: true,
          sortable: true,
        },
        {
          id: 'yearly_vacation',
          column: 'yearly_vacation',
          name: 'Allowed Yearly Vacations',
          visibility: true,
          sortable: true,
        },
        {
          id: 'prev_yearly_vacations',
          column: 'prev_yearly_vacations',
          name: 'Prev Yearly Vacations',
          visibility: true,
          sortable: true,
        },
        {
          id: 'vacation_to_date',
          column: 'vacation_to_date',
          name: 'Vacation to Date',
          visibility: true,
          sortable: false,
        },
        {
          id: 'total_yearly_vacations',
          column: 'total_yearly_vacations',
          name: 'Total Yearly Vacations',
          visibility: true,
          sortable: false,
        },
        {
          id: 'taken_yearly_vacations',
          column: 'taken_yearly_vacations',
          name: 'Taken Yearly Vacations',
          visibility: true,
          sortable: false,
        },
        {
          id: 'remaining_yearly_vacations',
          column: 'remaining_yearly_vacations',
          name: 'Remaining Yearly Vacations',
          visibility: true,
          sortable: false,
        },
        {
          id: 'allowed_sick_vacations',
          column: 'allowed_sick_vacations',
          name: 'Allowed Sick Vacations',
          visibility: true,
          sortable: false,
        },
        {
          id: 'taken_sick_vacations',
          column: 'taken_sick_vacations',
          name: 'Taken Sick Vacations',
          visibility: true,
          sortable: false,
        },
        {
          id: 'remaining_sick_vacations',
          column: 'remaining_sick_vacations',
          name: 'Remaining Sick Vacations',
          visibility: true,
          sortable: false,
        },
      ],
      data: [],
    },
  };

  componentDidMount() {
    this.fetch({
      search: this.state.cdt.search,
      limit: this.state.cdt.rows,
      order: this.state.cdt.order,
      dir: this.state.cdt.dir,
      page: this.state.cdt.page,
    });
  }

  fetch = async (params) => {
    this.setState({
      cdt: {
        ...this.state.cdt,
        fetching: true,
      },
    });

    if (this.fetchReq) {
      this.fetchReq.abort();
    }
    this.fetchReq = new AbortController();

    try {
      const res = await GET('employee-leaves-report/get', {
        signal: this.fetchReq.signal,
        params: {
          search: params.search,
          order: params.order,
          dir: params.dir,
          limit: params.limit,
          offset: parseInt(params.page) * parseInt(params.limit),
        },
      });
      this.setState({
        cdt: {
          ...this.state.cdt,
          fetching: false,
          search: params.search,
          rows: parseInt(params.limit),
          offset: parseInt(params.page) * parseInt(params.limit),
          order: params.order,
          dir: params.dir,
          page: params.page,
          totalRecords: parseInt(res.data.total_count_filtered),
          lastPage: Math.ceil(
            parseInt(res.data.total_count_filtered) / parseInt(params.limit)
          ),
          data: res.data.data,
        },
      });
    } catch (err) {
      this.setState({
        cdt: {
          ...this.state.cdt,
          fetching: false,
        },
      });
    }
  };

  exportExcel = async () => {
    const excelData = this.state.cdt.data.map((item) => {
      const row = {};
      this.state.cdt.columns
        .filter((column) => column.visibility)
        .map((column) => {
          const center_contract_startdate =
            this.props.authData.centerData.center_contract_startdate;

          let yearly_vacation = parseInt(item['yearly_vacation']);
          let monthly_vacation = parseInt(item['yearly_vacation']) / 12;

          let employee_sick_leaves = parseInt(item['employee_sick_leaves']);

          let joined_years = 0;
          let joined_months = 0;
          if (
            moment(center_contract_startdate).isSameOrAfter(
              moment(item['joining_date'])
            )
          ) {
            joined_years = moment().diff(
              moment(center_contract_startdate),
              'years'
            );
            joined_months = moment().diff(
              moment(center_contract_startdate),
              'months'
            );
          } else {
            joined_years = moment().diff(moment(item['joining_date']), 'years');
            joined_months = moment().diff(
              moment(item['joining_date']),
              'months'
            );
          }

          // let total_yearly_leaves = parseInt(item['prev_yearly_vacations']) + (parseInt(yearly_vacation) * joined_years)
          let total_yearly_leaves = Math.floor(
            parseInt(item['prev_yearly_vacations']) +
              parseFloat(monthly_vacation) * joined_months
          );

          let plusYears = moment(item['joining_date'])
            .add(joined_years, 'years')
            .format('YYYY-MM-DD'); // 14-01-1979

          let taken_employee_sick_leaves = 0;
          let taken_employee_yearly_leaves = 0;
          item['emplleaveData'].map((vv, ii) => {
            if (vv.annual_leave == '1') {
              taken_employee_yearly_leaves += parseInt(vv.emplleave_period);
            } else if (vv.sick_leave == '1') {
              if (
                moment(vv.emplleave_date_from).isSameOrAfter(moment(plusYears))
              ) {
                taken_employee_sick_leaves += parseInt(vv.emplleave_period);
              }
            }
          });
          let remaining_yearly_leaves =
            parseInt(total_yearly_leaves) -
            parseInt(taken_employee_yearly_leaves);

          if (column.id == 'vacation_to_date') {
            row[column.name] = Math.floor(
              parseFloat(monthly_vacation) * joined_months
            );
          } else if (column.id == 'total_yearly_vacations') {
            row[column.name] = total_yearly_leaves;
          } else if (column.id == 'taken_yearly_vacations') {
            row[column.name] = taken_employee_yearly_leaves;
          } else if (column.id == 'remaining_yearly_vacations') {
            row[column.name] = remaining_yearly_leaves;
          } else if (column.id == 'allowed_sick_vacations') {
            row[column.name] = employee_sick_leaves;
          } else if (column.id == 'taken_sick_vacations') {
            row[column.name] = taken_employee_sick_leaves;
          } else if (column.id == 'remaining_sick_vacations') {
            row[column.name] = Math.floor(
              parseFloat(employee_sick_leaves) -
                parseFloat(taken_employee_sick_leaves)
            );
          } else {
            row[column.name] = item[column.column];
          }
        });
      return row;
    });

    const fileName = 'Employee Leaves Report ' + moment().format('DD-MM-YYYY');
    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const ws = XLSX.utils.json_to_sheet(excelData);

    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });

    FileSaver.saveAs(data, fileName + fileExtension);
  };

  showHideColumn = (column_index) => {
    const columns = this.state.cdt.columns;
    columns[column_index].visibility = !columns[column_index].visibility;
    this.setState({ ctd: { ...this.state.ctd, columns } });
  };

  renderCell = (column_data, column_id, column_index, dataObj) => {
    const { authData } = this.props;
    const { center_id } = authData.centerData;

    const center_contract_startdate =
      authData.centerData.center_contract_startdate;

    let yearly_vacation = parseInt(dataObj.yearly_vacation);
    let monthly_vacation = parseInt(dataObj.yearly_vacation) / 12;

    let employee_sick_leaves = parseInt(dataObj.employee_sick_leaves);

    let joined_years = 0;
    let joined_months = 0;
    if (
      moment(center_contract_startdate).isSameOrAfter(
        moment(dataObj.joining_date)
      )
    ) {
      joined_years = moment().diff(moment(center_contract_startdate), 'years');
      joined_months = moment().diff(
        moment(center_contract_startdate),
        'months'
      );
    } else {
      joined_years = moment().diff(moment(dataObj.joining_date), 'years');
      joined_months = moment().diff(moment(dataObj.joining_date), 'months');
    }

    // let total_yearly_leaves = parseInt(dataObj.prev_yearly_vacations) + (parseInt(yearly_vacation) * joined_years)
    let total_yearly_leaves = Math.floor(
      parseInt(dataObj.prev_yearly_vacations) +
        parseFloat(monthly_vacation) * joined_months
    );

    let plusYears = moment(dataObj.joining_date)
      .add(joined_years, 'years')
      .format('YYYY-MM-DD'); // 14-01-1979

    let taken_employee_sick_leaves = 0;
    let taken_employee_yearly_leaves = 0;
    dataObj.emplleaveData.map((vv, ii) => {
      if (vv.annual_leave == '1') {
        taken_employee_yearly_leaves += parseInt(vv.emplleave_period);
      } else if (vv.sick_leave == '1') {
        if (moment(vv.emplleave_date_from).isSameOrAfter(moment(plusYears))) {
          taken_employee_sick_leaves += parseInt(vv.emplleave_period);
        }
      }
    });
    let remaining_yearly_leaves =
      parseInt(total_yearly_leaves) - parseInt(taken_employee_yearly_leaves);

    let tdData;

    if (column_id == 'vacation_to_date') {
      tdData = Math.floor(parseFloat(monthly_vacation) * joined_months);
    } else if (column_id == 'total_yearly_vacations') {
      tdData = <span style={{ color: '#5A8DEE' }}>{total_yearly_leaves}</span>;
    } else if (column_id == 'taken_yearly_vacations') {
      tdData = (
        <span style={{ color: '#FF5B5C' }}>{taken_employee_yearly_leaves}</span>
      );
    } else if (column_id == 'remaining_yearly_vacations') {
      tdData = (
        <span style={{ color: '#FDAC41' }}>{remaining_yearly_leaves}</span>
      );
    } else if (column_id == 'allowed_sick_vacations') {
      tdData = <span style={{ color: '#5A8DEE' }}>{employee_sick_leaves}</span>;
    } else if (column_id == 'taken_sick_vacations') {
      tdData = (
        <span style={{ color: '#FF5B5C' }}>{taken_employee_sick_leaves}</span>
      );
    } else if (column_id == 'remaining_sick_vacations') {
      tdData = (
        <span style={{ color: '#FDAC41' }}>
          {Math.floor(
            parseFloat(employee_sick_leaves) -
              parseFloat(taken_employee_sick_leaves)
          )}
        </span>
      );
    } else {
      tdData = column_data;
    }

    return <td key={column_index}>{tdData}</td>;
  };

  render() {
    return (
      <Container fluid>
        <div id='iedu-layout'>
          <NavBar />
          <div id='page-content'>
            <Header
              lite={true}
              heading={t('Employee Leaves Report')}
              backBtn={true}
            />
            {/* <div className='grey-section'>
              <Row>
                <Col md={12} className='mx-auto' style={{ maxWidth: '100px' }}>
                  <Button
                    variant={'success'}
                    onClick={() =>
                      this.fetch({
                        search: this.state.cdt.search,
                        limit: this.state.cdt.rows,
                        order: this.state.cdt.order,
                        dir: this.state.cdt.dir,
                        page: this.state.cdt.page,
                      })
                    }
                    size='lg'>
                    {this.state.fetching ? (
                      <Spinner
                        as='span'
                        animation='grow'
                        size='sm'
                        role='status'
                        aria-hidden='true'
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faSearch}
                        style={{
                          fontSize: 16,
                          color:
                            brand_colors[this.props.defaultTheme.theme_id]
                              .color8,
                        }}
                      />
                    )}
                  </Button>
                </Col>
              </Row>
            </div> */}
            <Card className='border-0' style={{ borderRadius: '20px' }}>
              <Card.Header
                style={{
                  backgroundColor: '#fff',
                  borderTopLeftRadius: '20px',
                  borderTopRightRadius: '20px',
                }}>
                <Row>
                  <Col md={6} className='d-flex align-items-center'>
                    <ButtonGroup size='sm' className='me-3 float-left'>
                      {/* <Button><FontAwesomeIcon icon={faFilePdf} /> {t('PDF')}</Button> */}
                      <Button onClick={() => this.exportExcel()}>
                        <FontAwesomeIcon icon={faFileExcel} /> {t('Excel')}
                      </Button>
                      {/* <Button><FontAwesomeIcon icon={faPrint} /> {t('Print')}</Button> */}
                      <DropdownButton
                        autoClose={'outside'}
                        size='sm'
                        as={ButtonGroup}
                        title={t('Column Visibility')}>
                        {this.state.cdt.columns.map((column, column_index) => (
                          <Dropdown.Item
                            key={column_index}
                            className={
                              column.visibility
                                ? 'column-name-item'
                                : 'column-name-item active'
                            }
                            onClick={() => this.showHideColumn(column_index)}>
                            <FontAwesomeIcon
                              icon={column.visibility ? faEye : faEyeSlash}
                            />{' '}
                            {column.name}
                          </Dropdown.Item>
                        ))}
                      </DropdownButton>
                    </ButtonGroup>
                    <div style={{ display: 'inline-block' }}>
                      {t('Show')}
                      <Form.Select
                        value={this.state.cdt.rows}
                        size='sm'
                        onChange={(e) =>
                          this.fetch({
                            search: this.state.cdt.search,
                            limit: e.target.value,
                            order: this.state.cdt.order,
                            dir: this.state.cdt.dir,
                            page: this.state.cdt.page,
                          })
                        }
                        style={{
                          width: 70,
                          marginInline: 5,
                          display: 'inline-block',
                        }}>
                        {this.state.cdt.rowsOptions.map((row, row_index) => (
                          <option key={row_index}>{row}</option>
                        ))}
                      </Form.Select>
                      {t('Records')}
                    </div>
                  </Col>
                  <Col md={6} className='d-flex justify-content-end'>
                    <div className='cdt-search'>
                      <input
                        type='text'
                        ref={this.searchFieldRef}
                        defaultValue={this.state.cdt.search}
                        onChange={(e) =>
                          this.fetch({
                            search: e.target.value,
                            limit: this.state.cdt.rows,
                            order: this.state.cdt.order,
                            dir: this.state.cdt.dir,
                            page: 0,
                          })
                        }
                        placeholder={t('Search...')}
                      />
                      <button
                        onClick={() => {
                          this.fetch({
                            search: '',
                            limit: this.state.cdt.rows,
                            order: this.state.cdt.order,
                            dir: this.state.cdt.dir,
                            page: 0,
                          });
                          this.searchFieldRef.current.value = '';
                        }}>
                        <FontAwesomeIcon
                          icon={
                            this.state.cdt.search == '' ? faSearch : faXmark
                          }
                          color={
                            brand_colors[this.props.defaultTheme.theme_id]
                              .color18
                          }
                        />
                      </button>
                    </div>
                  </Col>
                </Row>
              </Card.Header>
              <Card.Body style={{ paddingTop: 8 }}>
                <Row>
                  {this.state.cdt.fetching && (
                    <Col
                      md={12}
                      className='py-5 d-flex flex-column align-items-center'>
                      <Spinner as='span' animation='grow' size='sm' />{' '}
                      {t('Loading Data')}
                    </Col>
                  )}
                  {!this.state.cdt.fetching &&
                    this.state.cdt.data.length === 0 && (
                      <Col
                        md={12}
                        className='py-5 d-flex flex-column align-items-center'>
                        {t('No Data Found')}
                      </Col>
                    )}
                  {!this.state.cdt.fetching &&
                    this.state.cdt.data.length !== 0 && (
                      <Col md={12} className='pt-2'>
                        <div
                          id='datatable-list'
                          className='mb-3'
                          style={{ width: 'initial' }}>
                          <Table responsive>
                            {renderTableHead(this)}
                            {renderTableBody(this)}
                          </Table>
                        </div>
                      </Col>
                    )}
                </Row>
                <Pagination
                  pageCount={this.state.cdt.lastPage || 1}
                  forcePage={this.state.cdt.page}
                  callbackParams={{
                    search: this.state.cdt.search,
                    limit: this.state.cdt.rows,
                    order: this.state.cdt.order,
                    dir: this.state.cdt.dir,
                  }}
                  callback={this.fetch}
                />
              </Card.Body>
            </Card>
          </div>
        </div>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  authData: state.auth.authData,
  selectedClass: state.selectedClass.data,
  defaultLanguage: state.language.defaultLanguage,
  defaultTheme: state.theme.defaultTheme,
});

export default connect(mapStateToProps, null)(EmployeeLeavesReport);
