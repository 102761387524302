import React, { Component } from 'react';
import {
  Container,
  Card,
  Row,
  Col,
  ButtonGroup,
  Button,
  DropdownButton,
  Dropdown,
  Form,
  Modal,
  Spinner,
  Table,
  InputGroup,
  Badge,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlus,
  faCheckCircle,
  faSearch,
  faUser,
  faXmark,
  faLayerGroup,
  faHandsHoldingChild,
  faPeopleGroup,
  faListCheck,
  faPrint,
  faPencil,
  faTrash,
  faCheckDouble,
  faSortDown,
  faSortUp,
  faThumbsUp,
  faThumbsDown,
  faL,
  faChevronUp,
  faChevronDown,
  faTimes,
  faList,
  faTrashAlt,
} from '@fortawesome/free-solid-svg-icons';
import {
  faCalendar,
  faEye,
  faEyeSlash,
  faFileExcel,
  faFilePdf,
} from '@fortawesome/free-regular-svg-icons';
import { t } from '../../helpers/translation_helper';
import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';
import Select from 'react-select';
import NavBar from '../_partials/NavBar/_NavBar';
import Header from '../_partials/Header/_Header';
import { connect } from 'react-redux';
import moment from 'moment';
import { brand_colors } from '../../helpers/brand_colors_helper';
import { GET, POST } from '../../api';
import { toast } from 'react-toastify';
import { handleFormErrors } from '../../helpers/form_helpers';
import { DateRangePicker } from 'react-date-range';
import Pagination from '../_partials/Pagination';
import { renderTableBody, renderTableHead } from '../../helpers/table_helper';
import AccountsSetupNav from '../Accounts/_AccountsSetupNav';
import Flatpickr from 'react-flatpickr';
var Chance = require('chance');
var chance = new Chance();
let cancelToken;

const apiURL = process.env.REACT_APP_API_URL;

class Journal extends Component {
  searchFieldRef = React.createRef();

  defaultFormFields = {
    jvh_id: 0,
    jvh_value_date: '',
    jvh_type: '',
    jvh_desc: '',
    jv_total: 0,
    // ----
    jv_details: null,
    totalCredit: 0,
    totalDebit: 0,
    // ----
    jv_id: 0,
    j_date: '',
    j_chart: '',
    j_description: '',
    j_db_cr: '',
    amount: 0,
  };

  state = {
    ...this.defaultFormFields,
    working: false,
    isEditing: false,
    showFormModal: false,
    showDetailModal: false,
    jvTypes: [],
    charts: [],
    cdt: {
      fetching: false,
      search: '',
      rows: 7,
      offset: 0,
      order: 'jvheader.jvh_id',
      dir: 'DESC',
      lastPage: 0,
      page: 0,
      totalRecords: 0,
      rowsOptions: [10, 25, 50, 'All'],
      columns: [
        {
          id: 'jvheader.jvh_id',
          column: 'jvh_id',
          name: 'Actions',
          visibility: true,
          sortable: true,
        },
        {
          id: 'jvheader.is_locked',
          column: 'is_locked',
          name: 'Locked',
          visibility: true,
          sortable: true,
        },
        {
          id: 'jvheader.jvh_date',
          column: 'jvh_date',
          name: 'Entry Date',
          visibility: true,
          sortable: true,
        },
        {
          id: 'jvheader.jvh_value_date',
          column: 'jvh_value_date',
          name: 'Value Date',
          visibility: true,
          sortable: true,
        },
        {
          id: 'journaltype.jvtype_desc',
          column: 'jvtype_desc',
          name: 'Type',
          visibility: true,
          sortable: true,
        },
        {
          id: 'jvheader.jvh_num',
          column: 'jvh_num',
          name: 'Number',
          visibility: true,
          sortable: true,
        },
        {
          id: 'jvheader.jvh_desc',
          column: 'jvh_desc',
          name: 'Description',
          visibility: true,
          sortable: true,
        },
        {
          id: 'jvheader.jvh_tdc',
          column: 'jvh_tdc',
          name: 'Amount',
          visibility: true,
          sortable: true,
        },
      ],
      data: [],
    },
  };

  componentDidMount() {
    this.getJVTypes();
    this.getCharts();

    const { search, rows: limit, order, dir } = this.state.cdt;
    this.fetch({
      search,
      limit,
      order,
      dir,
      page: 0,
    });
  }

  fetch = async (params) => {
    this.setState({
      cdt: {
        ...this.state.cdt,
        fetching: true,
      },
    });

    if (cancelToken) {
      cancelToken.abort();
    }
    cancelToken = new AbortController();

    try {
      const res = await GET('journal/get', {
        signal: cancelToken.signal,
        params: {
          search: params.search,
          order: params.order,
          dir: params.dir,
          limit: params.limit,
          offset: parseInt(params.page) * parseInt(params.limit),
        },
      });

      if (res.status === 200) {
        this.setState({
          cdt: {
            ...this.state.cdt,
            fetching: false,
            search: params.search,
            rows: parseInt(params.limit),
            offset: parseInt(params.page) * parseInt(params.limit),
            order: params.order,
            dir: params.dir,
            page: params.page,
            totalRecords: parseInt(res.data.total_count_filtered),
            lastPage: Math.ceil(
              parseInt(res.data.total_count_filtered) / parseInt(params.limit)
            ),
            data: res.data.data.map((item) => {
              return {
                uid: chance.guid(),
                ...item,
              };
            }),
          },
        });
      }
    } catch (err) {
      console.log('err', err);
      this.setState({
        cdt: {
          ...this.state.cdt,
          fetching: false,
        },
      });
    }
  };

  getJVTypes = async () => {
    try {
      const res = await GET('journal/journal-type');
      this.setState({
        jvTypes: res.data.map((item) => {
          return {
            value: item.jvtype_id,
            label: item.jvtype_desc,
          };
        }),
      });
    } catch (err) {
      console.log(err);
    }
  };

  getCharts = async () => {
    try {
      const res = await GET('journal/all-chart');
      this.setState({
        charts: res.data.map((item) => {
          return {
            value: item.id,
            label: `${item.acc_num} - ${item.chart_desc}`,
          };
        }),
      });
    } catch (err) {
      console.log(err);
    }
  };

  handleOpenFormModal = () => {
    this.setState({
      showFormModal: true,
      jv_details: null,
    });
  };

  handleCloseFormModal = () => {
    this.setState({
      showFormModal: false,
      isEditing: false,
      ...this.defaultFormFields,
      jv_details: [],
    });
  };

  showHideColumn = (column_index) => {
    const columns = this.state.cdt.columns;
    columns[column_index].visibility = !columns[column_index].visibility;
    this.setState({ ctd: { ...this.state.ctd, columns } });
  };

  exportExcel = async () => {
    const excelData = this.state.cdt.data.map((item) => {
      const row = {};
      this.state.cdt.columns
        .filter((column) => column.visibility)
        .map((column) => {
          if (column.name !== 'Actions') {
            row[column.name] = item[column.column];
          }
        });
      return row;
    });

    const fileName =
      'Communication Source Export ' + moment().format('DD-MM-YYYY');
    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const ws = XLSX.utils.json_to_sheet(excelData);

    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });

    FileSaver.saveAs(data, fileName + fileExtension);
  };

  renderCell = (column_data, column_id, column_index, dataObj) => {
    const { authData } = this.props;
    const { auth_key } = authData.loginData;
    const { center_id } = authData.centerData;

    let tdData;

    // Action Buttons
    if (column_id == 'jvh_id') {
      const pdf = `${apiURL}pdfgenerator/journalvoucher?jvh_id=${dataObj.jvh_id}&auth_key=${auth_key}&center_id=${center_id}`;

      tdData = (
        <>
          <Button
            onClick={() => this.handleOpenDetailModal(dataObj)}
            variant='primary'
            size='sm'
            className='me-1'>
            <FontAwesomeIcon icon={faList} />
          </Button>
          {dataObj.jvtype_status == 2 && (
            <Button
              onClick={() => this.edit(dataObj)}
              variant='warning'
              size='sm'
              className='me-1'>
              <FontAwesomeIcon icon={faPencil} />
            </Button>
          )}
          <Button
            onClick={() => window.open(pdf)}
            variant='primary'
            size='sm'
            className='me-1'>
            <FontAwesomeIcon icon={faPrint} />
          </Button>
        </>
      );
    } else if (column_id == 'is_locked') {
      tdData =
        column_data == 1 ? (
          <Badge bg='success' size='sm'>
            {t('Yes')}
          </Badge>
        ) : (
          <Badge bg='danger' size='sm'>
            {t('No')}
          </Badge>
        );
    } else if (column_id == 'jvh_date' || column_id == 'jvh_value_date') {
      tdData = moment(column_data).format('DD-MM-YYYY');
    } else {
      tdData = column_data;
    }

    return <td key={column_index}>{tdData}</td>;
  };

  saveUpdate = async () => {
    const { authData } = this.props;
    const { center_id } = authData.centerData;
    const { jv_details, totalCredit, totalDebit } = this.state;

    if (!jv_details) {
      toast.error(t('No journal voucher added!'));
      return;
    }

    if (totalCredit !== totalDebit) {
      toast.error(t('Total debit and credit must be equal!'));
      return;
    }

    this.setState({ working: true });

    const { isEditing, jvh_id, jvh_value_date, jvh_type, jvh_desc, jv_total } =
      this.state;

    console.log('jv_details', jv_details);

    const fData = new FormData();
    fData.append('jvh_id', jvh_id);
    fData.append('jvh_value_date', jvh_value_date);
    fData.append('jvh_type', jvh_type.value);
    fData.append('jvh_desc', jvh_desc);
    fData.append('jv_total', jv_total);

    jv_details.map((item) => {
      fData.append('j_chart[]', item.j_chart.value);
      fData.append('j_date[]', item.j_date);
      fData.append('j_description[]', item.j_description);
      fData.append('j_db_cr[]', item.j_db_cr.value);
      fData.append('amount[]', item.amount);

      if (isEditing) {
        fData.append('jv_id[]', item.jv_id);
      }
    });

    try {
      const res = await POST(
        `journal/${isEditing ? 'update' : 'insert'}`,
        fData
      );
      if (res.data.type) {
        this.setState({ working: false });
        toast.success(t(res.data.message));
        this.fetch({
          search: '',
          limit: this.state.cdt.rows,
          order: this.state.cdt.order,
          dir: this.state.cdt.dir,
          page: 0,
        });
        this.handleCloseFormModal();
      } else {
        // toast.error(t(res.data.message));
        handleFormErrors(res.data.data);
        this.setState({ working: false });
      }
    } catch (err) {
      console.log('err', err);
      toast.error(t('Something went wrong!'));

      this.setState({ working: false });
    }
  };

  edit = async (data) => {
    console.log('data', data);

    this.setState({
      isEditing: true,
      jvh_id: data.jvh_id,
      jvh_value_date: data.jvh_value_date,
      jvh_type: { value: data.jvh_type, label: data.jvtype_desc },
      jvh_desc: data.jvh_desc,
    });

    this.getJVData(data.jvh_id);

    this.handleOpenFormModal();
  };

  handleOpenDetailModal = async (data) => {
    this.setState({
      showDetailModal: true,
      jv_details: null,
    });

    this.getJVData(data.jvh_id);
  };

  handleCloseDetailModal = (data) => {
    this.setState({
      showDetailModal: false,
    });
  };

  getJVData = async (jvh_id) => {
    try {
      const res = await GET('journal/get-journal-data', {
        params: { jvh_id },
      });

      const jv_details = res.data.data.map((item) => {
        return {
          ...item,
          j_chart: { value: item.chart_id, label: item.chart_desc },
          j_db_cr: {
            value: item.jv_dbcr,
            label: item.jv_dbcr == 'D' ? 'Debit' : 'Credit',
          },
          j_description: item.jv_desc,
          j_date: item.jv_date,
          uid: chance.guid(),
        };
      });
      const totalCredit = this.sumUpJV(jv_details, 'C');
      const totalDebit = this.sumUpJV(jv_details, 'D');

      this.setState({
        jv_details,
        totalCredit,
        totalDebit,
        jv_total: totalCredit,
      });
    } catch (err) {
      console.log(err);
    }
  };

  addJVDetail = () => {
    const {
      jv_id,
      jv_details,
      j_date,
      j_chart,
      j_description,
      j_db_cr,
      amount,
    } = this.state;

    const existing_jv_details = jv_details ? jv_details : [];

    if (j_date && j_chart && j_description && j_db_cr && amount) {
      existing_jv_details.push({
        uid: chance.guid(),
        jv_id,
        j_date,
        j_chart,
        j_description,
        j_db_cr,
        amount,
      });

      const totalCredit = this.sumUpJV(existing_jv_details, 'C');
      const totalDebit = this.sumUpJV(existing_jv_details, 'D');

      this.setState({
        jv_details: existing_jv_details,
        totalCredit,
        totalDebit,
        jv_total: totalCredit,
        jv_id: 0,
        j_date: '',
        j_chart: '',
        j_description: '',
        j_db_cr: '',
        amount: 0,
      });
    } else {
      toast.error(t('Please fill in fields!'));
    }
  };

  delJVDetail = (uid) => {
    const { jv_details } = this.state;
    const new_jv_details = jv_details.filter((item) => item.uid !== uid);
    const totalCredit = this.sumUpJV(new_jv_details, 'C');
    const totalDebit = this.sumUpJV(new_jv_details, 'D');

    this.setState({
      jv_details: new_jv_details,
      totalCredit,
      totalDebit,
      jv_total: totalCredit,
    });
  };

  sumUpJV = (jv_details, type) => {
    return jv_details
      .filter((item) => item.j_db_cr.value === type)
      .reduce((total, jv) => parseFloat(total) + parseFloat(jv.amount), 0);
  };

  render() {
    console.log('this.state.jv_details', this.state.jv_details);

    return (
      <Container fluid>
        <div id='iedu-layout'>
          <NavBar />
          <div id='page-content' style={{ width: '100%' }}>
            <Header lite={true} heading={t('Journal Voucher')} backBtn={true} />
            <div className='grey-section'>
              <Row>
                <Col md={12}>
                  <Card className='border-0' style={{ borderRadius: '20px' }}>
                    <Card.Header
                      style={{
                        backgroundColor: '#fff',
                        borderTopLeftRadius: '20px',
                        borderTopRightRadius: '20px',
                      }}>
                      <Row>
                        <Col md={6} className='d-flex align-items-center'>
                          {/* <strong>{t('Chart of Sub Accounts')}</strong> */}
                          <div style={{ display: 'inline-block' }}>
                            {t('Show')}
                            <Form.Select
                              value={this.state.cdt.rows}
                              size='sm'
                              onChange={(e) =>
                                this.fetch({
                                  search: this.state.cdt.search,
                                  limit: e.target.value,
                                  order: this.state.cdt.order,
                                  dir: this.state.cdt.dir,
                                  page: this.state.cdt.page,
                                })
                              }
                              style={{
                                width: 70,
                                marginInline: 5,
                                display: 'inline-block',
                              }}>
                              {this.state.cdt.rowsOptions.map(
                                (row, row_index) => (
                                  <option key={row_index}>{row}</option>
                                )
                              )}
                            </Form.Select>
                            {t('Records')}
                          </div>
                        </Col>
                        <Col md={6} className='d-flex justify-content-end'>
                          <div className='cdt-search'>
                            <input
                              type='text'
                              ref={this.searchFieldRef}
                              defaultValue={this.state.cdt.search}
                              onChange={(e) =>
                                this.fetch({
                                  search: e.target.value,
                                  limit: this.state.cdt.rows,
                                  order: this.state.cdt.order,
                                  dir: this.state.cdt.dir,
                                  page: 0,
                                })
                              }
                              placeholder={t('Search...')}
                            />
                            <button
                              onClick={() => {
                                this.fetch({
                                  search: '',
                                  limit: this.state.cdt.rows,
                                  order: this.state.cdt.order,
                                  dir: this.state.cdt.dir,
                                  page: 0,
                                });
                                this.searchFieldRef.current.value = '';
                              }}>
                              <FontAwesomeIcon
                                icon={
                                  this.state.cdt.search == ''
                                    ? faSearch
                                    : faXmark
                                }
                                color={
                                  brand_colors[this.props.defaultTheme.theme_id]
                                    .color18
                                }
                              />
                            </button>
                          </div>
                          <button
                            onClick={this.handleOpenFormModal}
                            className='btn btn-success btn-sm ms-3'>
                            <FontAwesomeIcon
                              icon={faPlus}
                              color={
                                brand_colors[this.props.defaultTheme.theme_id]
                                  .color8
                              }
                              style={{ fontSize: 12 }}
                            />
                          </button>
                        </Col>
                      </Row>
                    </Card.Header>
                    <Card.Body style={{ paddingTop: 8 }}>
                      <Row>
                        {this.state.cdt.fetching && (
                          <Col
                            md={12}
                            className='py-5 d-flex flex-column align-items-center'>
                            <Spinner as='span' animation='grow' size='sm' />{' '}
                            {t('Loading Data')}
                          </Col>
                        )}
                        {!this.state.cdt.fetching &&
                          this.state.cdt.data.length === 0 && (
                            <Col
                              md={12}
                              className='py-5 d-flex flex-column align-items-center'>
                              {t('No Data Found')}
                            </Col>
                          )}
                        {!this.state.cdt.fetching &&
                          this.state.cdt.data.length !== 0 && (
                            <Col md={12} className='pt-2'>
                              <div
                                id='datatable-list'
                                className='mb-3'
                                style={{ width: 'initial' }}>
                                <Table responsive>
                                  {renderTableHead(this)}
                                  {renderTableBody(this)}
                                </Table>
                              </div>
                            </Col>
                          )}
                      </Row>
                      <Pagination
                        pageCount={this.state.cdt.lastPage || 1}
                        forcePage={this.state.cdt.page}
                        callbackParams={{
                          search: this.state.cdt.search,
                          limit: this.state.cdt.rows,
                          order: this.state.cdt.order,
                          dir: this.state.cdt.dir,
                        }}
                        callback={this.fetch}
                      />
                    </Card.Body>
                  </Card>
                  <Modal
                    backdrop='static'
                    keyboard={false}
                    size={'xl'}
                    show={this.state.showFormModal}
                    onHide={this.handleCloseFormModal}>
                    <Modal.Header closeButton>
                      <Modal.Title>
                        {this.state.isEditing ? t('Edit') : t('Add')}{' '}
                        {t('Journal')}
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className='custom-styled-form'>
                      <Row>
                        <Col md={3}>
                          <Form.Group className='mb-3'>
                            <Form.Label>{t('Date')}</Form.Label>
                            <Flatpickr
                              style={{ width: '100%' }}
                              className='form-control'
                              value={this.state.jvh_value_date}
                              onChange={([date]) => {
                                this.setState({
                                  jvh_value_date:
                                    moment(date).format('YYYY-MM-DD'),
                                });
                              }}
                              options={{
                                static: true,
                              }}
                            />
                          </Form.Group>
                        </Col>
                        <Col md={3}>
                          <Form.Group className='mb-3'>
                            <Form.Label>{t('Type')}</Form.Label>
                            <Select
                              value={this.state.jvh_type}
                              menuPortalTarget={document.body}
                              styles={{
                                menuPortal: (base) => ({
                                  ...base,
                                  zIndex: 9999,
                                }),
                              }}
                              options={this.state.jvTypes}
                              onChange={(obj) =>
                                this.setState({ jvh_type: obj })
                              }
                            />
                          </Form.Group>
                        </Col>
                        <Col md={6}>
                          <Form.Group className='mb-3'>
                            <Form.Label>{t('Description')}</Form.Label>
                            <Form.Control
                              type='text'
                              value={this.state.jvh_desc}
                              onChange={(e) =>
                                this.setState({ jvh_desc: e.target.value })
                              }
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <hr />
                      <Table>
                        <thead>
                          <tr>
                            <th width='15%'>Date</th>
                            <th width='25%'>Chart</th>
                            <th width='25%'>Description</th>
                            <th width='15%'>Debit/Credit</th>
                            <th width='15%'>Amount</th>
                            <th width='5%'>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <Flatpickr
                                style={{ width: '100%' }}
                                className='form-control'
                                value={this.state.j_date}
                                onChange={([date]) => {
                                  this.setState({
                                    j_date: moment(date).format('YYYY-MM-DD'),
                                  });
                                }}
                                options={{
                                  static: true,
                                }}
                              />
                            </td>
                            <td>
                              <Select
                                value={this.state.j_chart}
                                menuPortalTarget={document.body}
                                styles={{
                                  menuPortal: (base) => ({
                                    ...base,
                                    zIndex: 9999,
                                  }),
                                }}
                                options={this.state.charts}
                                onChange={(obj) =>
                                  this.setState({ j_chart: obj })
                                }
                              />
                            </td>
                            <td>
                              <Form.Control
                                type='text'
                                value={this.state.j_description}
                                onChange={(e) =>
                                  this.setState({
                                    j_description: e.target.value,
                                  })
                                }
                              />
                            </td>
                            <td>
                              <Select
                                value={this.state.j_db_cr}
                                menuPortalTarget={document.body}
                                styles={{
                                  menuPortal: (base) => ({
                                    ...base,
                                    zIndex: 9999,
                                  }),
                                }}
                                options={[
                                  { value: 'C', label: 'Credit' },
                                  { value: 'D', label: 'Debit' },
                                ]}
                                onChange={(obj) =>
                                  this.setState({ j_db_cr: obj })
                                }
                              />
                            </td>
                            <td>
                              <Form.Control
                                className='no-arrows-num'
                                type='number'
                                value={this.state.amount}
                                onChange={(e) =>
                                  this.setState({ amount: e.target.value })
                                }
                                min={0}
                              />
                            </td>
                            <td>
                              <Button
                                variant='success'
                                onClick={this.addJVDetail}
                                style={{
                                  width: '100%',
                                }}>
                                <FontAwesomeIcon icon={faPlus} />
                              </Button>
                            </td>
                          </tr>
                          {!this.state.jv_details && this.state.isEditing && (
                            <tr>
                              <td className='text-center' colSpan={6}>
                                <div className='py-5 d-flex flex-column align-items-center'>
                                  <Spinner
                                    as='span'
                                    animation='grow'
                                    size='sm'
                                  />{' '}
                                  {t('Loading Data')}
                                </div>
                              </td>
                            </tr>
                          )}
                          {this.state.jv_details &&
                            this.state.jv_details.map((item, index) => (
                              <tr>
                                <td
                                  style={{
                                    paddingLeft: 17,
                                    verticalAlign: 'middle',
                                  }}>
                                  {moment(item.j_date).format('DD-MM-YYYY')}
                                </td>
                                <td
                                  style={{
                                    paddingLeft: 17,
                                    verticalAlign: 'middle',
                                  }}>
                                  {item.j_chart.label}
                                </td>
                                <td
                                  style={{
                                    paddingLeft: 17,
                                    verticalAlign: 'middle',
                                  }}>
                                  {item.j_description}
                                </td>
                                <td
                                  style={{
                                    paddingLeft: 17,
                                    verticalAlign: 'middle',
                                  }}>
                                  {item.j_db_cr.label}
                                </td>
                                <td
                                  style={{
                                    paddingLeft: 17,
                                    verticalAlign: 'middle',
                                  }}>
                                  {parseFloat(item.amount).toFixed(2)}
                                </td>
                                <td>
                                  <Button
                                    variant='danger'
                                    onClick={() => this.delJVDetail(item.uid)}
                                    style={{
                                      width: '100%',
                                    }}>
                                    <FontAwesomeIcon icon={faTrashAlt} />
                                  </Button>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </Table>
                      <Row>
                        <Col
                          style={{
                            marginRight: 70,
                          }}
                          className='d-flex justify-content-end align-items-center'>
                          <h4
                            style={{
                              marginRight: 50,
                            }}>
                            {t('Total')}
                          </h4>
                          <div
                            style={{
                              width: 170,
                              textAlign: 'center',
                            }}>
                            <strong className='text-muted'>{t('Debit')}</strong>
                            <h4>
                              {parseFloat(this.state.totalDebit).toFixed(2)}
                            </h4>
                          </div>
                          <div
                            style={{
                              width: 170,
                              textAlign: 'center',
                            }}>
                            <strong className='text-muted'>
                              {t('Credit')}
                            </strong>
                            <h4>
                              {parseFloat(this.state.totalCredit).toFixed(2)}
                            </h4>
                          </div>
                        </Col>
                      </Row>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button
                        variant='secondary'
                        onClick={this.handleCloseFormModal}>
                        {t('Close')}
                      </Button>
                      <Button
                        disabled={this.state.working}
                        variant='success'
                        onClick={() => this.saveUpdate()}>
                        {t('Save')}{' '}
                        {this.state.working ? (
                          <Spinner
                            as='span'
                            animation='grow'
                            size='sm'
                            role='status'
                            aria-hidden='true'
                          />
                        ) : (
                          ''
                        )}
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </Col>
              </Row>
            </div>
          </div>
        </div>
        <Modal
          size={'lg'}
          show={this.state.showDetailModal}
          onHide={this.handleCloseDetailModal}
          backdrop='static'
          keyboard={false}>
          <Modal.Header closeButton>
            <Modal.Title>{t('Journal Detail')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Table size='sm'>
              <thead>
                <tr>
                  <th width='20%'>Date</th>
                  <th width='20%'>Chart</th>
                  <th width='20%'>Description</th>
                  <th width='20%'>Debit/Credit</th>
                  <th width='20%'>Amount</th>
                </tr>
              </thead>
              <tbody>
                {!this.state.jv_details && (
                  <tr>
                    <td className='text-center' colSpan={5}>
                      <div className='py-5 d-flex flex-column align-items-center'>
                        <Spinner as='span' animation='grow' size='sm' />{' '}
                        {t('Loading Data')}
                      </div>
                    </td>
                  </tr>
                )}
                {this.state.jv_details && this.state.jv_details.length == 0 && (
                  <tr>
                    <td className='text-center' colSpan={5}>
                      No Data Found!
                    </td>
                  </tr>
                )}
                {this.state.jv_details &&
                  this.state.jv_details.length > 0 &&
                  this.state.jv_details.map((item, index) => (
                    <tr key={index}>
                      <td>{moment(item.jv_value_date).format('DD-MM-YYYY')}</td>
                      <td>{item.chart_desc}</td>
                      <td>{item.jv_desc}</td>
                      <td>{item.jv_dbcr == 'D' ? t('Debit') : t('Credit')}</td>
                      <td>{parseFloat(item.amount).toFixed(2)}</td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Modal.Body>
        </Modal>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  authData: state.auth.authData,
  defaultLanguage: state.language.defaultLanguage,
  defaultTheme: state.theme.defaultTheme,
  mainChart: state.accountsSetup.mainChart,
});

export default connect(mapStateToProps, null)(Journal);
