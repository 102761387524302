import React, { Component } from 'react';
import { Container, Card, Row, Col, Button, Spinner, InputGroup, Form, Table, Badge } from 'react-bootstrap';
import { t } from '../../helpers/translation_helper';
import NavBar from '../_partials/NavBar/_NavBar';
import Header from '../_partials/Header/_Header';
import { connect } from 'react-redux';
import { brand_colors } from '../../helpers/brand_colors_helper';
import { faCalendar, faFileExcel, faInfoCircle, faSearch, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DateRangePicker } from 'react-date-range';
import moment from 'moment';
import { GET } from '../../api';
import { uniqueByAttribute, generateColors, changeColorOpacity } from "../../helpers/general_helpers";
import { faCalendarAlt, faCheckSquare } from '@fortawesome/free-regular-svg-icons';
import Select from 'react-select';
import { toast } from 'react-toastify';
import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';
import { Doughnut } from "react-chartjs-2";
import { LoadingPlaceholder } from "../_common/Loading/Loading";
const doughnutChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    cutout: 90,
    plugins: {
        legend: {
            position: 'bottom',
        },
    },
};
class NationalitiesByClass extends Component {

    state = {
        fetching: false,
        children: [],
        uniqueNationality: [],
        uniqueClass: [],
        CenterlicData: [],
        terms: [],
        grades: [],
        classes: [],
        selectedCenterlic: null,
        selectedTerm: null,
        selectedGrade: null,
        selectedClass: null
    }

    componentDidMount() {

        this.getCenterlic()
    }

    fetch = async () => {

        const { selectedTerm, selectedGrade, selectedClass, selectedCenterlic } = this.state;

        this.setState({
            fetching: true,
            showDateRange: false,
            children: [],
        });
        try {
            const res = await GET('report-student-nationalities/get-children', {
                params: {
                    centerlic_id: selectedCenterlic ? selectedCenterlic.centerlic_id : null,
                    term_id: selectedTerm ? selectedTerm.term_id : null,
                    classmain_id: selectedGrade ? selectedGrade.classmain_id : null,
                    class_id: selectedClass ? selectedClass.class_id : null
                }
            });


            let all_children_before_check = res.data.data;
            let all_unique_children = uniqueByAttribute(all_children_before_check, (v) => v.child_id)
            let uniqueNationality = uniqueByAttribute(all_unique_children, (v) => v.country_id)
            let uniqueClass = uniqueByAttribute(all_unique_children, (v) => v.class_id)

            this.setState({
                fetching: false,
                children: all_unique_children,
                uniqueNationality: uniqueNationality,
                uniqueClass: uniqueClass
            });
        } catch (err) {
            console.log(err);
            this.setState({ fetching: false });
        }

    }

    getCenterlic = async () => {
        try {
            const res = await GET('report-student-nationalities/get-centerlic');
            console.log("rescenterlic", res)
            this.setState({
                CenterlicData: res.data.data.map(item => {
                    return {
                        ...item,
                        value: item.centerlic_id,
                        label: item.reg_year
                    }
                })
            });
        } catch (err) {
            console.log(err);
        }
    }
    handleRegYearChange = async (obj) => {
        this.setState({
            selectedCenterlic: obj
        });

        try {
            const res = await GET('report-student-nationalities/get-terms', {
                params: {
                    centerlic_id: obj.centerlic_id
                }
            });
            console.log("handleRegYearChange", res)
            this.setState({
                terms: res.data.data.map(item => {
                    return {
                        ...item,
                        value: item.term_id,
                        label: item.term_name,
                    }
                }),
                selectedTerm: []
            });
        } catch (err) {
            console.log(err);
        }

    }
    handleTermSelection = async (selectedTerm) => {
        console.log("handleTermSelection", selectedTerm)
        this.setState({ selectedTerm });

        try {
            const res = await GET('report-student-nationalities/get-classmain', {
                // params: {
                //     class_id: selectedClass.value
                // }
            });
            console.log("handleTermSelectionRESPONSE", res)
            this.setState({
                grades: res.data.data.map((item) => {
                    return {
                        ...item,
                        value: item.classmain_id,
                        label: item.classmain_name
                    }
                }),
                selectedGrade: null
            });

        } catch (err) {
            console.log(err);
        }
    }
    handleClassSelection = async (selectedGrade) => {
        this.setState({ selectedGrade });
        console.log("selectedGrade", selectedGrade)
        try {
            const res = await GET('report-student-nationalities/get-classes', {
                params: {
                    classmain_id: selectedGrade.classmain_id
                }
            });
            console.log("selectedClassRESPONSE", res)
            this.setState({
                classes: res.data.data.map((item) => {
                    return {
                        ...item,
                        value: item.class_id,
                        label: item.class_theme
                    }
                }),
                selectedClass: null
            });

        } catch (err) {
            console.log(err);
        }
    }


    render() {
        let sum = 0;
        let filterChildGraph = [];
        let labelsGraph = [];
        let dataSumGraph = [];
        this.state.uniqueNationality.map((v, i) => {
            let countrySumGraph = 0
            labelsGraph[i] = v.name_en
            this.state.uniqueClass.map((vv, ii) => {
                filterChildGraph = this.state.children.filter((vvv, iii) => {
                    return (vvv.class_id == vv.class_id && v.country_id == vvv.country_id)
                });
                countrySumGraph += filterChildGraph.length
            })
            dataSumGraph[i] = countrySumGraph
        })

        let namePlusCount = labelsGraph.map((v, i) => {
            return v + ' - ' + dataSumGraph[i]
        }
        )
        const DataTest = {
            labels: namePlusCount,

            datasets: [
                {
                    data: dataSumGraph,
                    backgroundColor: generateColors(dataSumGraph.length),
                    hoverBackgroundColor: generateColors(dataSumGraph.length),
                },
            ],

        }

        return (
            <Container fluid>
                <div id='iedu-layout'>
                    <NavBar />
                    <div id='page-content'>
                        <Header lite={true} heading={t('Nationalities By Class')} backBtn={true} />
                        <div className='grey-section'>
                            <Row>
                                <Col md={11}>
                                    <Row>
                                        <Col md={3} className='pe-0 custom-styled-form'>
                                            <Form.Label>{t('Reg Year')}</Form.Label>
                                            <Select
                                                placeholder={t('Reg Year')}
                                                options={this.state.CenterlicData}
                                                onChange={this.handleRegYearChange}
                                                value={this.state.selectedCenterlic}
                                                menuPortalTarget={document.body}
                                                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                                formatOptionLabel={option => (
                                                    <div className='d-flex align-items-center'>
                                                        <span style={{ fontSize: 16 }} className='me-1'>{option.label}</span>
                                                        {option.reg_status == 1 && <Badge pill bg="success">{t('Active')}</Badge>}
                                                    </div>
                                                )}
                                            />
                                        </Col>
                                        <Col md={3} className='pe-0 custom-styled-form'>
                                            <Form.Label>{t('Term')}</Form.Label>
                                            <Select
                                                placeholder={t('Select Term')}
                                                options={this.state.terms}
                                                onChange={this.handleTermSelection}
                                                value={this.state.selectedTerm}
                                                defaultValue={this.state.selectedTerm}
                                                menuPortalTarget={document.body}
                                                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                                                formatOptionLabel={option => (
                                                    <div className='d-flex align-items-center'>
                                                        <span style={{ fontSize: 16 }} className='me-1'>{option.label}</span>
                                                        {option.term_active == 1 && <Badge pill bg="success">{t('Active')}</Badge>}
                                                    </div>
                                                )}
                                            />
                                        </Col>
                                        <Col md={3} className='pe-0 custom-styled-form'>
                                            <Form.Label>{t('Grade')}</Form.Label>
                                            <Select
                                                placeholder={t('Select Grade')}
                                                menuPortalTarget={
                                                    document.body
                                                }
                                                styles={{
                                                    menuPortal: (
                                                        base
                                                    ) => ({
                                                        ...base,
                                                        zIndex: 9999,
                                                    }),
                                                }}
                                                options={this.state.grades}
                                                // isDisabled={
                                                //   disableLessons
                                                // }
                                                onChange={this.handleClassSelection}
                                                defaultValue={
                                                    this.state.selectedGrade
                                                }
                                                value={
                                                    this.state.selectedGrade
                                                }
                                                className='basic-multi-select'
                                                classNamePrefix='select'
                                            />
                                        </Col>
                                        <Col md={3} className='pe-0 custom-styled-form'>
                                            <Form.Label>{t('Class')}</Form.Label>
                                            <Select
                                                placeholder={t('Select Class')}
                                                menuPortalTarget={
                                                    document.body
                                                }
                                                styles={{
                                                    menuPortal: (
                                                        base
                                                    ) => ({
                                                        ...base,
                                                        zIndex: 9999,
                                                    }),
                                                }}
                                                options={this.state.classes}
                                                // isDisabled={
                                                //   disableLessons
                                                // }
                                                onChange={(obj) => this.setState({
                                                    selectedClass: obj
                                                })}
                                                defaultValue={
                                                    this.state.selectedClass
                                                }
                                                value={
                                                    this.state.selectedClass
                                                }
                                                className='basic-multi-select'
                                                classNamePrefix='select'
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col style={{ marginTop: 22 }}>
                                    <Button variant={'success'}
                                        style={{
                                            width: '100%',
                                            borderTopRightRadius: 20,
                                            borderBottomRightRadius: 20,
                                        }}
                                        onClick={() => this.fetch()}>
                                        {this.state.fetching
                                            ? <Spinner as='span' animation='grow' size='sm' role='status' aria-hidden='true' />
                                            : <FontAwesomeIcon icon={faSearch} style={{ fontSize: 16, color: brand_colors[this.props.defaultTheme.theme_id].color8, }} />
                                        }
                                    </Button>
                                </Col>
                            </Row>
                        </div>
                        {this.state.children.length > 0 &&
                            <div className='grey-section'>
                                <Row>
                                    <Col md="12">
                                        <Card
                                            className='border-0'
                                            style={{ borderRadius: '20px' }}>
                                            <Card.Body>
                                                <div>
                                                    <Doughnut
                                                        data={DataTest}
                                                        options={doughnutChartOptions}
                                                        height={278}
                                                    />
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>
                                <Card
                                    className='border-0'
                                    style={{
                                        borderRadius: '20px',
                                        marginBottom: 20
                                    }}>
                                    <Card.Body>
                                        <Row>
                                            <Col md={12}>
                                                <Table hover responsive className='mb-0'>
                                                    <thead>
                                                        <tr key={1 + 1}>
                                                            <th style={{ textAlign: 'center' }}>{t('Grade')}</th>
                                                            <th style={{ textAlign: 'center' }}>{t('Class')}</th>
                                                            <th style={{ textAlign: 'center' }}>{t('Count')}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.uniqueNationality.map((v, i) => {
                                                            let filterChild = [];
                                                            let countrySum = 0
                                                            return <>
                                                                <tr key={i} >
                                                                    <td colSpan="3" style={{ textAlign: 'left', backgroundColor: changeColorOpacity(brand_colors[this.props.defaultTheme.theme_id].color4, 0.3), width: '100%' }}><strong>{v.name_en}</strong></td>
                                                                </tr>
                                                                {
                                                                    this.state.uniqueClass.map((vv, ii) => {
                                                                        filterChild = this.state.children.filter((vvv, iii) => {
                                                                            return (vvv.class_id == vv.class_id && v.country_id == vvv.country_id)
                                                                        });
                                                                        countrySum += filterChild.length
                                                                        sum += filterChild.length
                                                                        return filterChild.length > 0 && (
                                                                            <tr key={ii}>
                                                                                <td style={{ textAlign: 'center' }}>{vv.classmain_name}</td>
                                                                                <td style={{ textAlign: 'center' }}>{vv.class_theme}</td>
                                                                                <td style={{ textAlign: 'center' }}>{filterChild.length}</td>
                                                                            </tr>
                                                                        )
                                                                    })
                                                                }
                                                                <tr>
                                                                    <td colSpan="2" style={{ textAlign: 'right', backgroundColor: changeColorOpacity(brand_colors[this.props.defaultTheme.theme_id].color1, 0.3) }}><strong>Total</strong></td>
                                                                    <td style={{ textAlign: 'center', backgroundColor: changeColorOpacity(brand_colors[this.props.defaultTheme.theme_id].color1, 0.3) }}><strong>{countrySum}</strong> </td>
                                                                </tr>
                                                            </>

                                                        })}
                                                        <tr>
                                                            <td colSpan="2" style={{ textAlign: 'right', backgroundColor: changeColorOpacity(brand_colors[this.props.defaultTheme.theme_id].color3, 0.3) }}><strong>{t('Sum')}</strong></td>
                                                            <td style={{ textAlign: 'center', backgroundColor: changeColorOpacity(brand_colors[this.props.defaultTheme.theme_id].color3, 0.3) }}><strong>{sum}</strong> </td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>

                            </div>
                        }
                    </div>
                </div>
            </Container>
        );
    }
}

const mapStateToProps = (state) => ({
    authData: state.auth.authData,
    selectedClass: state.selectedClass.data,
    defaultLanguage: state.language.defaultLanguage,
    defaultTheme: state.theme.defaultTheme,
});

export default connect(mapStateToProps, null)(NationalitiesByClass);
