import React, { Component, Fragment, useRef } from 'react';
import {
  Container,
  Card,
  Table,
  Row,
  Col,
  Spinner,
  Image,
  Badge,
  Button,
  Form,
  Offcanvas,
  Accordion,
  Modal,
  ButtonGroup,
} from 'react-bootstrap';
import { t } from '../../helpers/translation_helper';
import NavBar from '../_partials/NavBar/_NavBar';
import Header from '../_partials/Header/_Header';
import { connect } from 'react-redux';
import axios from 'axios';
import { toast } from 'react-toastify';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleLeft,
  faAngleRight,
  faCalendarAlt,
  faCheck,
  faClock,
  faEdit,
  faExternalLink,
  faFileAlt,
  faFileExcel,
  faGear,
  faPaperclip,
  faPieChart,
  faSave,
  faSearch,
  faTimes,
  faTrashCan,
} from '@fortawesome/free-solid-svg-icons';
import { Scrollbar } from 'react-scrollbars-custom';
import imgDescription from '../../assets/images/description.png';
import {
  brand_colors,
  colorsArray,
  generateColor,
} from '../../helpers/brand_colors_helper';
import SweetAlert from 'react-bootstrap-sweetalert';
import Select from 'react-select';
import { handleFormErrors } from '../../helpers/form_helpers';
import { GET } from '../../api';
import { Bar } from 'react-chartjs-2';
import {
  changeColorOpacity,
  DDL_ExamstypegrpTypes,
  DDL_ExamstypeModes,
  generateColors,
} from '../../helpers/general_helpers';
import { useDownloadExcel } from 'react-export-table-to-excel';
import themeSlice from '../../redux/slices/themeSlice';

var Chance = require('chance');
var chance = new Chance();

// API URL
const apiURL = process.env.REACT_APP_API_URL;

// Portal URL
const portalURL = process.env.REACT_APP_PORTAL_URL;

class ReportExamAnalysis extends Component {
  constructor(props) {
    super(props);
    this.state = {
      centerlicData: { data: [], selected: null },
      ageGroupData: { data: [], selected: null },
      termsData: { data: [], selected: [] },
      reRenderTime: moment(),
      reportData: {
        processing: false,
        data: {},
        finished: false,
      },
      filter_examstypegrp_type2: {
        data: [
          // { name: 'Show All', value: 'All' },
          { name: 'Only Internal', value: 'Internal' },
          { name: 'Only External', value: 'External' },
        ],
        selected: { name: 'Only Internal', value: 'Internal' },
      },
      filter_1: {
        data: [
          { name: 'General', value: 'general' },
          { name: 'Local / Non Local', value: 'country' },
          { name: 'Boys / Girls', value: 'gender' },
        ],
        selected: { name: 'General', value: 'general' },
      },
      modalGraph: {
        show: false,
        data: null,
        toggle: (data = null) =>
          this.setState({
            modalGraph: {
              ...this.state.modalGraph,
              show: !this.state.modalGraph.show,
              data: data,
            },
          }),
      },
    };
  }

  componentDidMount() {
    this.getCenterlic();

    let ageGroups = [];
    if (this.props.authData.classes && this.props.authData.classes.length > 0) {
      this.props.authData.classes.map((item, key) => {
        let findAgegroup = ageGroups.find(
          (v, i) => v.agegroup_id == item.class.agegroup_id
        );
        if (!findAgegroup) {
          ageGroups.push({
            agegroup_id: item.class.agegroup_id,
            agegroup_code: item.class.agegroup_code,
            value: item.class.agegroup_id,
            label: item.class.agegroup_code,
          });
        }
      });
    }

    this.setState({
      ageGroupData: {
        ...this.state.ageGroupData,
        data: ageGroups,
        selected: ageGroups.length > 0 ? ageGroups[0] : null,
      },
    });
  }

  getCenterlic = () => {
    try {
      if (this.getCenterlicReq) {
        this.getCenterlicReq.abort();
      }
      this.getCenterlicReq = new AbortController();
      GET('report-exams/get-centerlic', {
        signal: this.getCenterlicReq.signal,
      }).then((res) => {
        if (res) {
          let selected = res.data.find((v) => v.reg_status == 1);
          this.setState(
            {
              centerlicData: {
                ...this.state.centerlicData,
                data: res.data,
                selected: {
                  value: selected.centerlic_id,
                  label: selected.reg_year,
                  reg_status: selected.reg_status,
                },
              },
            },
            () => this.getTerms()
          );
        }
      });
    } catch (err) {
      console.log('err', err);
      toast.error(t('Something went wrong while deleting!'));
    }
  };
  getTerms = () => {
    try {
      if (this.getTermsReq) {
        this.getTermsReq.abort();
      }
      this.getTermsReq = new AbortController();
      GET('report-exams/get-terms', {
        signal: this.getTermsReq.signal,
      }).then((res) => {
        if (res) {
          let selected = [];
          res.data.map((v, i) => {
            if (v.centerlic_id == this.state.centerlicData.selected.value) {
              selected.push({
                value: v.term_id,
                label: v.term_name,
                term_active: v.term_active,
              });
            }
          });
          this.setState({
            termsData: {
              ...this.state.termsData,
              data: res.data,
              selected: selected,
            },
          });
        }
      });
    } catch (err) {
      console.log('err', err);
      toast.error(t('Something went wrong while deleting!'));
    }
  };

  calculateTypeTotal = (
    total_exams_grade,
    total_examsgrade_grade,
    examstype_mode
  ) => {
    let rData = { grade: 0, total_grade: 0 };
    if (examstype_mode == 'Sum') {
      rData['grade'] = total_examsgrade_grade.reduce((p, c) => p + c, 0);
      rData['total_grade'] = total_exams_grade.reduce((p, c) => p + c, 0);
    } else if (examstype_mode == 'Average_Grade') {
      let HCM = total_exams_grade.reduce((p, c) => p * c, 1);
      let total = 0;
      total_examsgrade_grade.map((v, i) => {
        total += (v * HCM) / total_exams_grade[i]; // 3*125/5
      });
      //total=>325
      rData['grade'] =
        (total / total_exams_grade.length / HCM) *
        Math.max(...total_exams_grade); // 325
      rData['total_grade'] = Math.max(...total_exams_grade);
    } else if (examstype_mode == 'Highest_Grade') {
      total_exams_grade.map((v, i) => {
        let divide = total_examsgrade_grade[i] / v;
        let divideR = rData['grade'] / rData['total_grade'];
        if (divide > divideR || rData['total_grade'] == 0) {
          rData['grade'] = total_examsgrade_grade[i];
          rData['total_grade'] = v;
        }
      });
    } else if (examstype_mode == 'Highest_Multiplied') {
      total_exams_grade.map((v, i) => {
        let divide = total_examsgrade_grade[i] / v;
        let divideR = rData['grade'] / rData['total_grade'];
        if (divide > divideR || rData['total_grade'] == 0) {
          rData['grade'] = total_examsgrade_grade[i];
          rData['total_grade'] = v;
        }
      });
      rData['grade'] = rData['grade'] * total_exams_grade.length;
      rData['total_grade'] = rData['total_grade'] * total_exams_grade.length;
    }
    return rData;
  };

  fetchReport = () => {
    let {
      reportData,
      centerlicData,
      termsData,
      filter_examstypegrp_type2,
      filter_1,
      ageGroupData,
    } = this.state;

    if (!ageGroupData.selected) {
      toast.error(t('Please select a Grade'));
      return;
    }
    if (!centerlicData.selected) {
      toast.error(t('Please select a Year'));
      return;
    }
    if (termsData.selected.length <= 0) {
      toast.error(t('Please select a Term'));
      return;
    }

    this.setState({
      reportData: { ...reportData, processing: true, data: {} },
    });

    try {
      if (this.fetchReportReq) {
        this.fetchReportReq.abort();
      }
      this.fetchReportReq = new AbortController();
      GET('academic-classmain-subject/get-exam-analysis', {
        params: {
          agegroup_id: ageGroupData.selected.agegroup_id,
          acs_ids: JSON.stringify(
            this.props.selectedClass.academic_classmain_subject.map(
              (v, i) => v.acs_id
            )
          ),
          centerlic_id: centerlicData.selected.value,
          filter_examstypegrp_type2: filter_examstypegrp_type2.selected.value,
          term_ids: JSON.stringify(termsData.selected.map((v, i) => v.value)),
        },
        signal: this.fetchReportReq.signal,
      }).then((res) => {
        if (res) {
          let dataRubric = res.data.dataRubric;
          let allAcs = res.data.allAcs;
          let allTerms = res.data.allTerms;
          let examstypegrps = res.data.examstypegrps;
          let registrations = res.data.registrations;

          let tableData = [];

          // TERM
          allTerms.map((v, i) => {
            // ACS
            let tBody = [];
            allAcs.map((vv, ii) => {
              let aboveCount = [0, 0, 0, 0];
              if (filter_1.selected.value == 'gender') {
                aboveCount = [0, 0, 0, 0, 0, 0, 0, 0];
              } else if (filter_1.selected.value == 'country') {
                aboveCount = [0, 0, 0, 0, 0, 0, 0, 0];
              }

              registrations.map((child) => {
                var totalInternalByGrade = [];
                var totalInternalByGradeT = [];
                dataRubric.map((vvv, iii) => {
                  let term_id = vvv.term.term_id;
                  let acs_id = vvv.acs.acs_id;
                  if (term_id != v.term_id || acs_id != vv.acs_id) {
                    return;
                  }

                  let exams = vvv.exams;
                  // EXAM GROUP
                  examstypegrps.map((vvvv, iii) => {
                    if (vvvv.examstypegrp_type2 != 'Internal') {
                      return;
                    }
                    let total_exams_grade = [];
                    let total_examsgrade_grade = [];
                    let num_all_exams = 0;

                    // EXAM
                    exams.map((vvvvv, iiii) => {
                      if (
                        vvvvv.examstype_id == vvvv.examstype_id &&
                        vvvvv.examstypegrp_id == vvvv.examstypegrp_id &&
                        vvvvv.examstypegrp_type == vvvv.examstypegrp_type
                      ) {
                        num_all_exams++;
                        let examsgrade = vvvvv.examsgrade.find(
                          (vvvvvv) =>
                            vvvvvv.child_id == child.child_id &&
                            vvvvvv.exams_id == vvvvv.exams_id
                        );
                        if (
                          !examsgrade ||
                          (examsgrade?.absence_id != 0 &&
                            examsgrade?.absence_id != null)
                        ) {
                        } else {
                          total_exams_grade.push(parseFloat(vvvvv.exams_grade));
                          total_examsgrade_grade.push(
                            parseFloat(examsgrade?.examsgrade_grade)
                          );
                        }
                      }
                    });
                    let calculateTypeTotalData = { grade: 0, total_grade: 0 };
                    if (total_exams_grade.length > 0) {
                      calculateTypeTotalData = this.calculateTypeTotal(
                        total_exams_grade,
                        total_examsgrade_grade,
                        vvvv.examstype_mode
                      );
                      totalInternalByGrade.push(
                        calculateTypeTotalData['grade']
                      );
                      totalInternalByGradeT.push(
                        calculateTypeTotalData['total_grade']
                      );
                    }
                  });
                });

                let tPercentage = (
                  (totalInternalByGrade.reduce((p, c) => p + c, 0) /
                    totalInternalByGradeT.reduce((p, c) => p + c, 0)) *
                  100
                ).toFixed(2);
                if (filter_1.selected.value == 'general') {
                  if (tPercentage >= 50) {
                    aboveCount[0]++;
                  }
                  if (tPercentage >= 70) {
                    aboveCount[1]++;
                  }
                  if (tPercentage >= 80) {
                    aboveCount[2]++;
                  }
                  if (tPercentage >= 90) {
                    aboveCount[3]++;
                  }
                } else if (filter_1.selected.value == 'gender') {
                  if (tPercentage >= 50) {
                    if (child.gender == 'M') {
                      aboveCount[0]++;
                    } else {
                      aboveCount[4]++;
                    }
                  }
                  if (tPercentage >= 70) {
                    if (child.gender == 'M') {
                      aboveCount[1]++;
                    } else {
                      aboveCount[5]++;
                    }
                  }
                  if (tPercentage >= 80) {
                    if (child.gender == 'M') {
                      aboveCount[2]++;
                    } else {
                      aboveCount[6]++;
                    }
                  }
                  if (tPercentage >= 90) {
                    if (child.gender == 'M') {
                      aboveCount[3]++;
                    } else {
                      aboveCount[7]++;
                    }
                  }
                } else if (filter_1.selected.value == 'country') {
                  if (tPercentage >= 50) {
                    if (
                      parseInt(child.country_id) ==
                      parseInt(this.props.authData.centerData.country_id)
                    ) {
                      aboveCount[0]++;
                    } else {
                      aboveCount[4]++;
                    }
                  }
                  if (tPercentage >= 70) {
                    if (
                      parseInt(child.country_id) ==
                      parseInt(this.props.authData.centerData.country_id)
                    ) {
                      aboveCount[1]++;
                    } else {
                      aboveCount[5]++;
                    }
                  }
                  if (tPercentage >= 80) {
                    if (
                      parseInt(child.country_id) ==
                      parseInt(this.props.authData.centerData.country_id)
                    ) {
                      aboveCount[2]++;
                    } else {
                      aboveCount[6]++;
                    }
                  }
                  if (tPercentage >= 90) {
                    if (
                      parseInt(child.country_id) ==
                      parseInt(this.props.authData.centerData.country_id)
                    ) {
                      aboveCount[3]++;
                    } else {
                      aboveCount[7]++;
                    }
                  }
                }
              });

              console.log('aboveCount', aboveCount);
              tBody.push({
                acs: vv,
                data: aboveCount,
              });
            });
            tableData.push({
              title: v,
              tBody: tBody,
            });
          });

          let totalMReg = res.data.registrations.filter(
            (v, i) => v.gender == 'M'
          ).length;
          let totalFReg = res.data.registrations.filter(
            (v, i) => v.gender == 'F'
          ).length;
          let totalLocalReg = res.data.registrations.filter(
            (v, i) => v.country_id == this.props.authData.centerData.country_id
          ).length;
          let totalNonLocalReg = res.data.registrations.filter(
            (v, i) => v.country_id != this.props.authData.centerData.country_id
          ).length;

          let totalReg = {
            total: res.data.registrations.length,
            total_male: totalMReg,
            total_female: totalFReg,
            total_local: totalLocalReg,
            total_nonlocal: totalNonLocalReg,
          };

          this.setState({
            reportData: {
              ...reportData,
              processing: false,
              data: res.data,
              tableData: tableData,
              totalReg: totalReg,
              finished: true,
            },
            reRenderTime: moment(),
          });
        } else {
          this.setState({
            reportData: { ...reportData, processing: false, data: {} },
          });
        }
      });
    } catch (err) {
      console.log('err', err);
      toast.error(t('Something went wrong while deleting!'));

      this.setState({
        reportData: { ...reportData, processing: false, data: {} },
      });
    }
  };

  generateGraph = () => {
    const { reportData, modalGraph } = this.state;
    if (modalGraph.data.filter_1.selected.value == 'general') {
      return (
        <div className='d-flex flex-column'>
          <div>
            <Bar
              height={300}
              options={{
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                  legend: {
                    position: 'bottom',
                  },
                  title: {
                    display: true,
                    text: modalGraph.data.title.term_name + ' - General',
                  },
                },
              }}
              data={{
                labels: modalGraph.data.tBody.map((v, i) => {
                  return v.acs.acs_name;
                }),
                datasets: [
                  {
                    label: 'Passed',
                    data: modalGraph.data.tBody.map((v, i) => {
                      return (
                        (v.data[0] * 100) /
                        reportData.totalReg.total
                      ).toFixed(2);
                    }),
                    backgroundColor: colorsArray[0].color_code,
                  },
                  {
                    label: 'Above 70%',
                    data: modalGraph.data.tBody.map((v, i) => {
                      return (
                        (v.data[1] * 100) /
                        reportData.totalReg.total
                      ).toFixed(2);
                    }),
                    backgroundColor: colorsArray[1].color_code,
                  },
                  {
                    label: 'Above 80%',
                    data: modalGraph.data.tBody.map((v, i) => {
                      return (
                        (v.data[2] * 100) /
                        reportData.totalReg.total
                      ).toFixed(2);
                    }),
                    backgroundColor: colorsArray[2].color_code,
                  },
                  {
                    label: 'Above 90%',
                    data: modalGraph.data.tBody.map((v, i) => {
                      return (
                        (v.data[3] * 100) /
                        reportData.totalReg.total
                      ).toFixed(2);
                    }),
                    backgroundColor: colorsArray[3].color_code,
                  },
                ],
              }}
            />
          </div>
        </div>
      );
    } else if (modalGraph.data.filter_1.selected.value == 'country') {
      return (
        <div className='d-flex flex-column'>
          {/* LOCAL */}
          <div>
            <Bar
              height={300}
              options={{
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                  legend: {
                    position: 'bottom',
                  },
                  title: {
                    display: true,
                    text: modalGraph.data.title.term_name + ' - Local',
                  },
                },
              }}
              data={{
                labels: modalGraph.data.tBody.map((v, i) => {
                  return v.acs.acs_name;
                }),
                datasets: [
                  {
                    label: 'Passed',
                    data: modalGraph.data.tBody.map((v, i) => {
                      return (
                        (v.data[0] * 100) /
                        reportData.totalReg.total_local
                      ).toFixed(2);
                    }),
                    backgroundColor: colorsArray[0].color_code,
                  },
                  {
                    label: 'Above 70%',
                    data: modalGraph.data.tBody.map((v, i) => {
                      return (
                        (v.data[1] * 100) /
                        reportData.totalReg.total_local
                      ).toFixed(2);
                    }),
                    backgroundColor: colorsArray[1].color_code,
                  },
                  {
                    label: 'Above 80%',
                    data: modalGraph.data.tBody.map((v, i) => {
                      return (
                        (v.data[2] * 100) /
                        reportData.totalReg.total_local
                      ).toFixed(2);
                    }),
                    backgroundColor: colorsArray[2].color_code,
                  },
                  {
                    label: 'Above 90%',
                    data: modalGraph.data.tBody.map((v, i) => {
                      return (
                        (v.data[3] * 100) /
                        reportData.totalReg.total_local
                      ).toFixed(2);
                    }),
                    backgroundColor: colorsArray[3].color_code,
                  },
                ],
              }}
            />
          </div>
          {/* NONLOCAL */}
          <div>
            <Bar
              height={300}
              options={{
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                  legend: {
                    position: 'bottom',
                  },
                  title: {
                    display: true,
                    text: modalGraph.data.title.term_name + ' - Non-Local',
                  },
                },
              }}
              data={{
                labels: modalGraph.data.tBody.map((v, i) => {
                  return v.acs.acs_name;
                }),
                datasets: [
                  {
                    label: 'Passed',
                    data: modalGraph.data.tBody.map((v, i) => {
                      return (
                        (v.data[4] * 100) /
                        reportData.totalReg.total_nonlocal
                      ).toFixed(2);
                    }),
                    backgroundColor: colorsArray[0].color_code,
                  },
                  {
                    label: 'Above 70%',
                    data: modalGraph.data.tBody.map((v, i) => {
                      return (
                        (v.data[5] * 100) /
                        reportData.totalReg.total_nonlocal
                      ).toFixed(2);
                    }),
                    backgroundColor: colorsArray[1].color_code,
                  },
                  {
                    label: 'Above 80%',
                    data: modalGraph.data.tBody.map((v, i) => {
                      return (
                        (v.data[6] * 100) /
                        reportData.totalReg.total_nonlocal
                      ).toFixed(2);
                    }),
                    backgroundColor: colorsArray[2].color_code,
                  },
                  {
                    label: 'Above 90%',
                    data: modalGraph.data.tBody.map((v, i) => {
                      return (
                        (v.data[7] * 100) /
                        reportData.totalReg.total_nonlocal
                      ).toFixed(2);
                    }),
                    backgroundColor: colorsArray[3].color_code,
                  },
                ],
              }}
            />
          </div>
        </div>
      );
    } else if (modalGraph.data.filter_1.selected.value == 'gender') {
      return (
        <div className='d-flex flex-column'>
          {/* LOCAL */}
          <div>
            <Bar
              height={300}
              options={{
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                  legend: {
                    position: 'bottom',
                  },
                  title: {
                    display: true,
                    text: modalGraph.data.title.term_name + ' - Boys',
                  },
                },
              }}
              data={{
                labels: modalGraph.data.tBody.map((v, i) => {
                  return v.acs.acs_name;
                }),
                datasets: [
                  {
                    label: 'Passed',
                    data: modalGraph.data.tBody.map((v, i) => {
                      return (
                        (v.data[0] * 100) /
                        reportData.totalReg.total_male
                      ).toFixed(2);
                    }),
                    backgroundColor: colorsArray[0].color_code,
                  },
                  {
                    label: 'Above 70%',
                    data: modalGraph.data.tBody.map((v, i) => {
                      return (
                        (v.data[1] * 100) /
                        reportData.totalReg.total_male
                      ).toFixed(2);
                    }),
                    backgroundColor: colorsArray[1].color_code,
                  },
                  {
                    label: 'Above 80%',
                    data: modalGraph.data.tBody.map((v, i) => {
                      return (
                        (v.data[2] * 100) /
                        reportData.totalReg.total_male
                      ).toFixed(2);
                    }),
                    backgroundColor: colorsArray[2].color_code,
                  },
                  {
                    label: 'Above 90%',
                    data: modalGraph.data.tBody.map((v, i) => {
                      return (
                        (v.data[3] * 100) /
                        reportData.totalReg.total_male
                      ).toFixed(2);
                    }),
                    backgroundColor: colorsArray[3].color_code,
                  },
                ],
              }}
            />
          </div>
          {/* NONLOCAL */}
          <div>
            <Bar
              height={300}
              options={{
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                  legend: {
                    position: 'bottom',
                  },
                  title: {
                    display: true,
                    text: modalGraph.data.title.term_name + ' - Girls',
                  },
                },
              }}
              data={{
                labels: modalGraph.data.tBody.map((v, i) => {
                  return v.acs.acs_name;
                }),
                datasets: [
                  {
                    label: 'Passed',
                    data: modalGraph.data.tBody.map((v, i) => {
                      return (
                        (v.data[4] * 100) /
                        reportData.totalReg.total_female
                      ).toFixed(2);
                    }),
                    backgroundColor: colorsArray[0].color_code,
                  },
                  {
                    label: 'Above 70%',
                    data: modalGraph.data.tBody.map((v, i) => {
                      return (
                        (v.data[5] * 100) /
                        reportData.totalReg.total_female
                      ).toFixed(2);
                    }),
                    backgroundColor: colorsArray[1].color_code,
                  },
                  {
                    label: 'Above 80%',
                    data: modalGraph.data.tBody.map((v, i) => {
                      return (
                        (v.data[6] * 100) /
                        reportData.totalReg.total_female
                      ).toFixed(2);
                    }),
                    backgroundColor: colorsArray[2].color_code,
                  },
                  {
                    label: 'Above 90%',
                    data: modalGraph.data.tBody.map((v, i) => {
                      return (
                        (v.data[7] * 100) /
                        reportData.totalReg.total_female
                      ).toFixed(2);
                    }),
                    backgroundColor: colorsArray[3].color_code,
                  },
                ],
              }}
            />
          </div>
        </div>
      );
    }
  };

  render() {
    console.log('thisREPORT', this.state);
    const {
      reportData,
      reRenderTime,
      centerlicData,
      termsData,
      filter_examstypegrp_type2,
      filter_1,
      modalGraph,
      ageGroupData,
    } = this.state;
    let filteredTerms = [];
    if (centerlicData.selected) {
      filteredTerms = termsData.data.filter(
        (v, i) => v.centerlic_id == centerlicData.selected.value
      );
    }

    return (
      <Container fluid>
        <div id='iedu-layout'>
          <NavBar />
          <div id='page-content'>
            <Header lite={true} heading={t('Exam Analysis')} backBtn={true} />
            <div className='grey-section'>
              <Row className='justify-content-between'>
                <Col md='8'>
                  <Row>
                    <Col md='2'>
                      <Select
                        placeholder={t('Select Grade')}
                        options={ageGroupData.data}
                        onChange={(event) =>
                          this.setState({
                            ageGroupData: { ...ageGroupData, selected: event },
                          })
                        }
                        value={ageGroupData.selected}
                        menuPortalTarget={document.body}
                        styles={{
                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        }}
                        formatOptionLabel={(option) => (
                          <div className='d-flex align-items-center'>
                            <span style={{ fontSize: 16 }} className='me-1'>
                              {option.label}
                            </span>
                          </div>
                        )}
                      />
                    </Col>
                    <Col md='4'>
                      <Select
                        placeholder={t('Select Year')}
                        options={centerlicData.data.map((v, i) => {
                          return {
                            label: v.reg_year,
                            value: v.centerlic_id,
                            reg_status: v.reg_status,
                          };
                        })}
                        onChange={(event) =>
                          this.setState({
                            centerlicData: {
                              ...centerlicData,
                              selected: event,
                            },
                            termsData: { ...termsData, selected: [] },
                          })
                        }
                        value={centerlicData.selected}
                        menuPortalTarget={document.body}
                        styles={{
                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        }}
                        formatOptionLabel={(option) => (
                          <div className='d-flex align-items-center'>
                            <span style={{ fontSize: 16 }} className='me-1'>
                              {option.label}
                            </span>
                            {option.reg_status == 1 && (
                              <Badge pill bg='success'>
                                {t('Active')}
                              </Badge>
                            )}
                          </div>
                        )}
                      />
                    </Col>
                    <Col md='6'>
                      <Select
                        isMulti
                        isClearable
                        placeholder={t('Select Term')}
                        options={filteredTerms.map((v, i) => {
                          return {
                            label: v.term_name,
                            value: v.term_id,
                            term_active: v.term_active,
                          };
                        })}
                        onChange={(event) =>
                          this.setState({
                            termsData: { ...termsData, selected: event },
                          })
                        }
                        value={termsData.selected}
                        menuPortalTarget={document.body}
                        styles={{
                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        }}
                        formatOptionLabel={(option) => (
                          <div className='d-flex align-items-center'>
                            <span style={{ fontSize: 16 }} className='me-1'>
                              {option.label}
                            </span>
                            {option.term_active == 1 && (
                              <Badge pill bg='success'>
                                {t('Active')}
                              </Badge>
                            )}
                          </div>
                        )}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col md='4'>
                  <Row>
                    <Col className='d-flex flex-column'>
                      <ButtonGroup size='sm' className='mb-2'>
                        {filter_examstypegrp_type2.data.map((v, i) => {
                          return (
                            <Button
                              onClick={() =>
                                this.setState({
                                  filter_examstypegrp_type2: {
                                    ...filter_examstypegrp_type2,
                                    selected: v,
                                  },
                                })
                              }
                              variant={
                                filter_examstypegrp_type2.selected.value ==
                                v.value
                                  ? 'success'
                                  : 'light'
                              }>
                              {t(v.name)}
                            </Button>
                          );
                        })}
                      </ButtonGroup>
                      <ButtonGroup size='sm' className='mb-2'>
                        {filter_1.data.map((v, i) => {
                          return (
                            <Button
                              onClick={() =>
                                this.setState({
                                  filter_1: { ...filter_1, selected: v },
                                })
                              }
                              variant={
                                filter_1.selected.value == v.value
                                  ? 'success'
                                  : 'light'
                              }>
                              {t(v.name)}
                            </Button>
                          );
                        })}
                      </ButtonGroup>
                    </Col>
                    <Col md='auto'>
                      <Button
                        variant={'success'}
                        onClick={() => this.fetchReport()}>
                        {reportData.processing ? (
                          <Spinner
                            as='span'
                            animation='grow'
                            size='sm'
                            role='status'
                            aria-hidden='true'
                          />
                        ) : (
                          <FontAwesomeIcon
                            icon={faSearch}
                            style={{
                              fontSize: 16,
                              color:
                                brand_colors[this.props.defaultTheme.theme_id]
                                  .color8,
                            }}
                          />
                        )}
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
            <div className='grey-section'>
              {reportData.processing ? (
                <div className='text-center'>
                  <Spinner
                    as='span'
                    animation='grow'
                    size='sm'
                    role='status'
                    aria-hidden='true'
                  />
                </div>
              ) : (
                <RenderReport
                  reportData={reportData}
                  ageGroupData={ageGroupData}
                  authData={this.props.authData}
                  defaultTheme={this.props.defaultTheme}
                  reRenderTime={reRenderTime}
                  modalGraph={modalGraph}
                  filter_1={filter_1}
                />
              )}
            </div>
          </div>
        </div>

        <Modal
          size='xl'
          show={modalGraph.show}
          onHide={modalGraph.toggle}
          backdrop='static'
          keyboard={false}>
          <Modal.Header closeButton>
            <Modal.Title></Modal.Title>
          </Modal.Header>
          <Modal.Body>{modalGraph.show && this.generateGraph()}</Modal.Body>
        </Modal>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  authData: state.auth.authData,
  defaultLanguage: state.language.defaultLanguage,
  selectedClass: state.selectedClass.data,
  selectedSubject: state.selectedClass.subject,
  children: state.children.list,
  defaultTheme: state.theme.defaultTheme,
});

export default connect(mapStateToProps, null)(ReportExamAnalysis);

class RenderReport extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  shouldComponentUpdate(nextProps) {
    if (
      moment(nextProps.reRenderTime).isSame(moment(this.props.reRenderTime))
    ) {
      return false;
    }
    return true;
  }

  render() {
    let reportData = this.props.reportData;
    if (!reportData.finished) {
      return null;
    }
    console.log('reportData', reportData);
    return (
      <div>
        {reportData.tableData.map((v, i) => {
          return (
            <RenderTable
              defaultTheme={this.props.defaultTheme}
              reportData={v}
              ageGroupData={this.props.ageGroupData}
              totalReg={reportData.totalReg}
              filter_1={this.props.filter_1}
              modalGraph={this.props.modalGraph}
            />
          );
        })}
      </div>
    );
  }
}

const RenderTable = (props) => {
  const reportData = props.reportData;
  const ageGroupData = props.ageGroupData;
  const totalReg = props.totalReg;
  const filter_1 = props.filter_1;
  const modalGraph = props.modalGraph;
  const tableRef = useRef(null);

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename:
      ageGroupData.selected.agegroup_code +
      '--' +
      reportData.title.term_name +
      ' - (Exam-Analysis)',
    sheet: 'S1',
  });

  const generateGraph = () => {
    modalGraph.toggle({ ...reportData, filter_1: filter_1 });
  };

  return (
    <Card className='mb-2'>
      <Card.Header>
        <div className='d-flex flex-row align-items-center justify-content-between'>
          <div style={{ fontSize: 16, fontWeight: 'bold' }}>
            {reportData.title.term_name}
          </div>
          <div style={{ fontSize: 16, fontWeight: 'bold' }}>
            {t('Total Registration') + ': ' + totalReg.total}
          </div>
          <div>
            <ButtonGroup size='sm' className='me-3 float-left'>
              {/* <Button><FontAwesomeIcon icon={faFilePdf} /> {t('PDF')}</Button> */}
              <Button onClick={() => onDownload()}>
                <FontAwesomeIcon icon={faFileExcel} /> {t('Excel')}
              </Button>
              <Button onClick={() => generateGraph()}>
                <FontAwesomeIcon icon={faPieChart} /> {t('Graph')}
              </Button>
            </ButtonGroup>
          </div>
        </div>
      </Card.Header>
      <Card.Body>
        <Table size='sm' striped responsive bordered ref={tableRef}>
          <thead>
            {filter_1.selected.value == 'general' && (
              <tr>
                <th>{t('Subject')}</th>
                <th colSpan={2} className='text-center'>
                  {'Passed'}
                </th>
                <th colSpan={2} className='text-center'>
                  {'70%'}
                </th>
                <th colSpan={2} className='text-center'>
                  {'80%'}
                </th>
                <th colSpan={2} className='text-center'>
                  {'90%'}
                </th>
              </tr>
            )}
            {filter_1.selected.value == 'gender' && (
              <>
                <tr>
                  <th rowSpan={2}>{t('Subject')}</th>
                  <th colSpan={8} className='text-center'>
                    {'Boys'} - {totalReg.total_male}
                  </th>
                  <th colSpan={8} className='text-center'>
                    {'Girls'} - {totalReg.total_female}
                  </th>
                </tr>
                <tr>
                  <th colSpan={2} className='text-center'>
                    {'Passed'}
                  </th>
                  <th colSpan={2} className='text-center'>
                    {'70%'}
                  </th>
                  <th colSpan={2} className='text-center'>
                    {'80%'}
                  </th>
                  <th colSpan={2} className='text-center'>
                    {'90%'}
                  </th>
                  <th colSpan={2} className='text-center'>
                    {'Passed'}
                  </th>
                  <th colSpan={2} className='text-center'>
                    {'70%'}
                  </th>
                  <th colSpan={2} className='text-center'>
                    {'80%'}
                  </th>
                  <th colSpan={2} className='text-center'>
                    {'90%'}
                  </th>
                </tr>
              </>
            )}
            {filter_1.selected.value == 'country' && (
              <>
                <tr>
                  <th rowSpan={2}>{t('Subject')}</th>
                  <th colSpan={8} className='text-center'>
                    {'Local'} - {totalReg.total_local}
                  </th>
                  <th colSpan={8} className='text-center'>
                    {'Non-Local'} - {totalReg.total_nonlocal}
                  </th>
                </tr>
                <tr>
                  <th colSpan={2} className='text-center'>
                    {'Passed'}
                  </th>
                  <th colSpan={2} className='text-center'>
                    {'70%'}
                  </th>
                  <th colSpan={2} className='text-center'>
                    {'80%'}
                  </th>
                  <th colSpan={2} className='text-center'>
                    {'90%'}
                  </th>
                  <th colSpan={2} className='text-center'>
                    {'Passed'}
                  </th>
                  <th colSpan={2} className='text-center'>
                    {'70%'}
                  </th>
                  <th colSpan={2} className='text-center'>
                    {'80%'}
                  </th>
                  <th colSpan={2} className='text-center'>
                    {'90%'}
                  </th>
                </tr>
              </>
            )}
          </thead>
          <tbody>
            {reportData.tBody.map((vv, ii) => {
              return (
                <tr>
                  <th>{vv.acs.acs_name}</th>
                  {vv.data.map((vvv, iii) => {
                    return (
                      <>
                        <td className='text-center' style={{ width: '100px' }}>
                          {vvv}
                        </td>
                        {filter_1.selected.value == 'general' && (
                          <td
                            className='text-center'
                            style={{ width: '100px' }}>
                            {((vvv * 100) / totalReg.total).toFixed(2)}%
                          </td>
                        )}
                        {filter_1.selected.value == 'gender' && iii <= 3 && (
                          <td
                            className='text-center'
                            style={{ width: '100px' }}>
                            {((vvv * 100) / totalReg.total_male).toFixed(2)}%
                          </td>
                        )}
                        {filter_1.selected.value == 'gender' && iii > 3 && (
                          <td
                            className='text-center'
                            style={{ width: '100px' }}>
                            {((vvv * 100) / totalReg.total_female).toFixed(2)}%
                          </td>
                        )}
                        {filter_1.selected.value == 'country' && iii <= 3 && (
                          <td
                            className='text-center'
                            style={{ width: '100px' }}>
                            {((vvv * 100) / totalReg.total_local).toFixed(2)}%
                          </td>
                        )}
                        {filter_1.selected.value == 'country' && iii > 3 && (
                          <td
                            className='text-center'
                            style={{ width: '100px' }}>
                            {((vvv * 100) / totalReg.total_nonlocal).toFixed(2)}
                            %
                          </td>
                        )}
                      </>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
};
